import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import Table from 'design-system/components/Table';
import { useDatatableContext } from '../hooks/useDatatableContext';
import { detailedPaginationSelector } from '../selectors/pagination';
import {
  DATA_TABLE_UPDATE_CURRENT_PAGE,
  DATA_TABLE_UPDATE_ITEMS_PER_PAGE,
} from '../events/pagination';
import FormattedMessageWithValidation from '../../FormattedMessageWithValidation/FormattedMessageWithValidation';
import RowsLabel from '../../../pages/Tables/RowsLabel';

export function Pagination() {
  const { collectionName, defaultSorting, updateEvents } =
    useDatatableContext();

  const detailedPagination = useSelector((state) =>
    detailedPaginationSelector(state, { collectionName })
  );

  const handleRowsPerPageChange = (itemsPerPage) =>
    dispatch({
      id: DATA_TABLE_UPDATE_ITEMS_PER_PAGE,
      payload: { collectionName, defaultSorting, updateEvents, itemsPerPage },
    });

  const handlePageChange = (currentPage) =>
    dispatch({
      id: DATA_TABLE_UPDATE_CURRENT_PAGE,
      payload: { collectionName, defaultSorting, updateEvents, currentPage },
    });

  const {
    currentPage,
    totalPages,
    currentItemRange,
    totalItems,
    itemsPerPage,
  } = detailedPagination;

  return (
    <Table.Pagination
      currentPage={currentPage}
      totalPages={totalPages}
      currentRowRange={currentItemRange}
      totalRows={totalItems}
      rowsPerPage={itemsPerPage}
      rowsPerPageLabel={
        <FormattedMessageWithValidation id="enqpage_table_rows" />
      }
      displayRowsLabel={(currentRowRange, totalRows) => (
        <RowsLabel currentRowRange={currentRowRange} totalRows={totalRows} />
      )}
      onPaginate={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
    />
  );
}
