import { useSelector } from 'reffects-store';
import Layout from 'design-system/components/Layout';
import { isDeveloperSelector } from '../../selectors/publisher';
import AgencyLeads from './AgencyLeads';
import DeveloperLeads from './DeveloperLeads';
import { Navigation } from '../../partials';

export function Leads() {
  const publisherIsDeveloper = useSelector(isDeveloperSelector);
  return (
    <Layout>
      <Layout.Header>
        <Navigation />
      </Layout.Header>
      <Layout.Content>
        {publisherIsDeveloper ? <DeveloperLeads /> : <AgencyLeads />}
      </Layout.Content>
    </Layout>
  );
}
