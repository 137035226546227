import styled, { css } from 'styled-components';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { withStylesAndInnerRef } from 'design-system/utils';
import {
  FONT_FAMILY,
  FONT_SIZE,
  typography,
} from 'design-system/styles/typography';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import BaseButton, { BUTTON_SIZE, BUTTON_VARIANT } from './BaseButton';

export const BUTTON_ICON_POSITION = {
  START: Symbol('BUTTON_ICON_POSITION_START'),
  END: Symbol('BUTTON_ICON_POSITION_END'),
};

const Text = styled.span`
  ${typography.font(FONT_FAMILY.PRIMARY)}
`;

/**
 * The purpose of this container is to clip the icon and ensure the same height
 * in buttons with and without it.
 */
const IconContainer = styled.span`
  height: 1px;

  ${Icon} {
    transform: translateY(-50%);
  }
`;

const modSizeSmall = () => css`
  --current-button-spacing-size: ${spacing.value(SPACING_SIZE.M)};

  ${typography.size(FONT_SIZE.S)}
  line-height: 16px;
  ${spacing.insetSquish(SPACING_SIZE.M)}

  ${Icon} {
    ${Icon.mods.size(ICON_SIZE.SMALL)}
  }
`;

const modSizeBase = () => css`
  --current-button-spacing-size: ${spacing.value(SPACING_SIZE.M)};

  font-size: 14px;
  line-height: 20px;
  ${spacing.insetSquish(SPACING_SIZE.M)}

  ${Icon} {
    ${Icon.mods.size(ICON_SIZE.BASE)}
  }
`;

const modSizeLarge = () => css`
  --current-button-spacing-size: ${spacing.value(SPACING_SIZE.L)};

  font-size: 14px;
  line-height: 16px;
  ${spacing.insetSquish(SPACING_SIZE.L)}

  ${Icon} {
    ${Icon.mods.size(ICON_SIZE.BASE)}
  }
`;

const modSize = (sizeName) =>
  ({
    [BUTTON_SIZE.SMALL]: modSizeSmall,
    [BUTTON_SIZE.BASE]: modSizeBase,
    [BUTTON_SIZE.LARGE]: modSizeLarge,
  }[sizeName]);

const modIconPositionStart = () => css`
  flex-direction: row-reverse;

  ${IconContainer} {
    margin-left: calc(-0.5 * var(--current-button-spacing-size));
    margin-right: initial;
  }
`;

const modIconPositionEnd = () => css`
  flex-direction: row;

  ${IconContainer} {
    margin-left: initial;
    margin-right: calc(-0.5 * var(--current-button-spacing-size));
  }
`;

const modIconPosition = (position) =>
  ({
    [BUTTON_ICON_POSITION.START]: modIconPositionStart,
    [BUTTON_ICON_POSITION.END]: modIconPositionEnd,
  }[position]);

const Wrapper = styled(BaseButton)`
  display: inline-flex;
  align-items: center;
  gap: ${spacing.value(SPACING_SIZE.S)};

  ${BaseButton.mods.variant(BUTTON_VARIANT.PRIMARY)}
  ${() => modIconPosition(BUTTON_ICON_POSITION.END)}
  ${() => modSize(BUTTON_SIZE.SMALL)}
`;

function TextAndIconButton({ children, as, icon, innerRef, ...rest }) {
  return (
    <Wrapper {...rest} forwardedAs={as} ref={innerRef}>
      <Text>{children}</Text>
      {icon && <IconContainer>{icon}</IconContainer>}
    </Wrapper>
  );
}

TextAndIconButton.mods = {
  size: modSize,
  iconPosition: modIconPosition,
  variant: BaseButton.mods.variant,
};

export default withStylesAndInnerRef(TextAndIconButton);
