import { dispatch } from 'reffects';
import Menu from 'design-system/components/Menu/Menu';
import { OPEN_DIALOG } from '../../../../../../events/dialogs';
import { GENERIC_CONFIRMATION_MODAL } from '../../../../../../partials/modals/generic/constants';
import useUpdateTableEvent from '../../../../../../partials/DataTable/hooks/useUpdateTableEvent';
import { MARK_PUBLISHER_AS_TEST } from './events';

export default function MarkAsTestAction({ publisher }) {
  const updateTableEvent = useUpdateTableEvent();
  return (
    <Menu.Item
      label="Mark as test"
      onClick={() => {
        dispatch({
          id: OPEN_DIALOG,
          payload: {
            id: GENERIC_CONFIRMATION_MODAL,
            parameters: {
              messages: {
                title: {
                  id: 'Mark agency as test?',
                },
                body: {
                  id: `Are you sure you want to mark the agency ${publisher.name} (${publisher.id}) as test?`,
                },
              },
              actions: {
                confirm: {
                  id: MARK_PUBLISHER_AS_TEST,
                  payload: {
                    publisherId: publisher.id,
                    onSuccessEvent: updateTableEvent,
                  },
                },
              },
            },
          },
        });
      }}
    />
  );
}
