import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import Thumbnail from 'design-system/components/Thumbnail';
import Illustration from 'design-system/components/Illustration/Illustration';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import {
  publisherLogoSelector,
  publisherNameSelector,
} from '../../../selectors/publisher';

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.value(SPACING_SIZE.M)};
  line-height: normal;
  ${typography.weight(FONT_WEIGHT.NORMAL)}
  ${color.text(COLOR_PALETTE.NEUTRAL_A80)}
`;

const ImageWrapper = styled(Thumbnail)`
  width: 48px;
  height: 32px;
  flex-shrink: 0;
`;

export default function PublisherData() {
  const publisherName = useSelector(publisherNameSelector);
  const publisherLogo = useSelector(publisherLogoSelector);
  return (
    <LogoWrapper>
      {publisherName}
      {publisherLogo && (
        <ImageWrapper
          source={publisherLogo}
          placeholder={<Illustration name="agencyAvatar" />}
        />
      )}
    </LogoWrapper>
  );
}
