import Menu from 'design-system/components/Menu/Menu';
import { withoutEventPropagation } from 'design-system/utils';
import { useCallback } from 'react';
import { dispatch } from 'reffects';
import { OPEN_DIALOG } from '../../../../../../events/dialogs';
import { CANCEL_SUBSCRIPTION_REQUEST_MODAL } from '../Modals/CancelSubscriptionRequestModal/constants';

export function CancelSubscriptionRequestAction({ publisher }) {
  const handleClick = useCallback(
    () =>
      dispatch({
        id: OPEN_DIALOG,
        payload: {
          id: CANCEL_SUBSCRIPTION_REQUEST_MODAL,
          parameters: { subscriptionId: publisher.subscriptionId },
        },
      }),
    [publisher]
  );

  return (
    <Menu.Item
      label="Cancel automatic renewal"
      onClick={withoutEventPropagation(handleClick)}
    />
  );
}
