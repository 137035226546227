const MILLIS_PER_DAY = 1000 * 60 * 60 * 24;

function formatDateForSafariCompatibility(dateString) {
  return dateString.replace(/-/g, '/');
}

export function createDate(dateString) {
  return new Date(formatDateForSafariCompatibility(dateString));
}

export function convertToISOStringDate(date) {
  return date.toISOString().split('T')[0];
}

export function firstMomentOf(date) {
  return new Date(date.setHours(0, 0, 0));
}

export function firstMomentOfWeek(date) {
  const first = date.getDate() - date.getDay() + 1;

  return firstMomentOf(new Date(date.setDate(first)));
}

export function lastMomentOfWeek(date) {
  const last = date.getDate() - date.getDay() + 7;

  return new Date(new Date(date.setDate(last)).setHours(23, 59, 59));
}

export function weekRange(date = new Date()) {
  return [firstMomentOfWeek(date), lastMomentOfWeek(date)];
}

export function firstDayOfMonth(now) {
  return new Date(now.getFullYear(), now.getMonth(), 1);
}

export function monthRange(now = new Date()) {
  return [firstDayOfMonth(now), lastMomentOfMonth(firstDayOfMonth(now))];
}

export function lastMomentOfDay(now) {
  const date = new Date(now);
  date.setHours(23, 59, 59);

  return date;
}

export function threeMonthsAgo(now) {
  const date = new Date(now);
  date.setMonth(date.getMonth() - 3);
  return date;
}

export function firstDayOfLastMonth(now) {
  const date = new Date(now);

  // This condition is tested, but test still green if removes it
  if (date.getMonth() === 0) {
    return new Date(date.getFullYear() - 1, 11, 1);
  }

  return new Date(date.getFullYear(), date.getMonth() - 1, 1);
}

export function lastMonthRange(now = new Date()) {
  return [
    firstDayOfLastMonth(now),
    lastMomentOfMonth(firstDayOfLastMonth(now)),
  ];
}

export function firstDayOfNextMonth(now) {
  const date = new Date(now);

  return new Date(date.getFullYear(), date.getMonth() + 1, 1);
}

export function nextMonthRange(now = new Date()) {
  return [
    firstDayOfNextMonth(now),
    lastMomentOfMonth(firstDayOfNextMonth(now)),
  ];
}

export function lastDayOfMonth(date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

export function lastMomentOfMonth(date) {
  const lastDayDate = lastDayOfMonth(date);

  lastDayDate.setHours(23, 59, 59);

  return lastDayDate;
}

export function isSameDay(aDate, anotherDate) {
  return (
    aDate.getDate() === anotherDate.getDate() &&
    aDate.getMonth() === anotherDate.getMonth() &&
    aDate.getFullYear() === anotherDate.getFullYear()
  );
}

export function toUTCDate(date) {
  if (!date) return null;

  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    )
  );
}

export function fromUTCDate(date) {
  if (!date) return null;

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getMilliseconds()
  );
}

export function daysAgo(numberOfDays, date = new Date()) {
  const dateDaysAgo = new Date(date.getTime());
  dateDaysAgo.setDate(date.getDate() - numberOfDays);
  dateDaysAgo.setHours(0, 0, 0, 0);

  return dateDaysAgo;
}

function daysDiff(a, b) {
  const utcA = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utcB = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utcA - utcB) / MILLIS_PER_DAY);
}

export function daysFrom(date, now = new Date()) {
  return Math.abs(daysDiff(date, now));
}
