import { dispatch, registerEffectHandler } from 'reffects';
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillRelativeFormat } from '@formatjs/intl-relativetimeformat/should-polyfill';

const LOAD_TRANSLATIONS = 'loadTranslations';

const DEFAULT_LOCALE = 'en-GB';

export default function registerLoadTranslationsEffect() {
  registerEffectHandler(
    LOAD_TRANSLATIONS,
    async ({ onFinishEventId, locale: givenLocale, redirectTo }) => {
      try {
        const { default: translations } = await import(
          `../../public/translations/${givenLocale}.json`
        );
        const locale = await polyfill(givenLocale);

        dispatch({
          id: onFinishEventId,
          payload: { translations, locale, redirectTo },
        });
      } catch (e) {
        const { default: translations } = await import(
          `../../public/translations/en-GB.json`
        );
        const locale = await polyfill(DEFAULT_LOCALE);
        dispatch({
          id: onFinishEventId,
          payload: { translations, locale, redirectTo },
        });
      }
    }
  );
}

async function polyfill(locale = DEFAULT_LOCALE) {
  if (shouldPolyfillLocale) {
    await import('@formatjs/intl-locale/polyfill');
  }

  const unsupportedLocale = shouldPolyfillRelativeFormat(locale);
  if (!unsupportedLocale) {
    return locale;
  }

  await import('@formatjs/intl-relativetimeformat/polyfill-force');
  try {
    await import(`@formatjs/intl-relativetimeformat/locale-data/${locale}`);
    return locale;
  } catch (e) {
    const [fallbackLocale] = locale.split('-');
    await import(
      `@formatjs/intl-relativetimeformat/locale-data/${fallbackLocale}`
    );
    return fallbackLocale;
  }
}

export function loadTranslations({
  onFinishEventId,
  locale,
  redirectTo = '/',
}) {
  return {
    [LOAD_TRANSLATIONS]: { onFinishEventId, locale, redirectTo },
  };
}
