export function getLocaleByCountry(countryCode) {
  const mappings = {
    AE: ['en-US'],
    AR: ['es-AR'],
    CL: ['es-CL'],
    CO: ['es-CO'],
    EC: ['es-EC'],
    PA: ['es-PA'],
    PE: ['es-PE'],
    PH: ['en-PH'],
    MX: ['es-MX'],
    MY: ['en-US'],
    ID: ['en-US'],
    ES: ['es-ES'],
    TH: ['th-TH', 'en-US'],
    VN: ['en-US'],
  };
  return mappings[countryCode] ?? ['en-US'];
}
