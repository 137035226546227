import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import Dialog from 'design-system/components/Dialog/Dialog';
import InputText from 'design-system/components/InputText';
import {
  FlatButton,
  SecondaryButton,
} from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import { useDialog } from '../../../../../../../hooks/useDialog';
import {
  FormWrapper,
  ItemTwoColumns,
} from '../../../../../../../partials/SimpleFormLayout';
import {
  WASI_API_PUBLISHER_FORM_CHANGE_VALUE,
  WASI_API_PUBLISHER_FORM_SUBMITTED,
  WASI_API_PUBLISHER_MODAL,
} from './events';
import { getDisableForm, getWasiApiPublisher } from './selectors';

function SendPropertiesToWasiModal({ onSuccessEvent }) {
  const { open, closeDialog } = useDialog(WASI_API_PUBLISHER_MODAL);
  const wasiApiPublisher = useSelector(getWasiApiPublisher);
  const disableForm = useSelector(getDisableForm);

  return (
    <Dialog open={open}>
      <Dialog.Header onClose={closeDialog}>
        Migrate content to Wasi
      </Dialog.Header>
      <Dialog.Content>
        <form
          id="change-wasi-api-publisher-form"
          onSubmit={(e) => {
            e.preventDefault();
            dispatch({
              id: WASI_API_PUBLISHER_FORM_SUBMITTED,
              payload: {
                onSuccessEvent,
                wasiApiPublisher,
              },
            });
          }}
        >
          <FormWrapper>
            <ItemTwoColumns>
              <InputText
                label="Wasi Token (API)"
                name="wasiToken"
                value={wasiApiPublisher?.wasiToken ?? ''}
                required
                placeholder="Type the wasi api token..."
                onChange={onInputChange}
              />
            </ItemTwoColumns>
            <ItemTwoColumns>
              <InputText
                label="Wasi Id Company (API)"
                name="idCompany"
                value={wasiApiPublisher?.idCompany ?? ''}
                required
                placeholder="Type the wasi id company..."
                onChange={onInputChange}
              />
            </ItemTwoColumns>
            <ItemTwoColumns>
              <InputText
                label="Wasi Company Id"
                name="companyId"
                value={wasiApiPublisher?.companyId ?? ''}
                required
                placeholder="Type the wasi company id..."
                onChange={onInputChange}
              />
            </ItemTwoColumns>
          </FormWrapper>
        </form>
      </Dialog.Content>
      <Dialog.Footer>
        <FlatButton onClick={closeDialog} $size={BUTTON_SIZE.SMALL}>
          Cancel
        </FlatButton>
        <SecondaryButton
          type="submit"
          form="change-wasi-api-publisher-form"
          $size={BUTTON_SIZE.SMALL}
          disabled={disableForm}
        >
          Migrate content to Wasi
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}

function onInputChange(event) {
  const { name, value } = event.target;
  dispatch({
    id: WASI_API_PUBLISHER_FORM_CHANGE_VALUE,
    payload: { name, value },
  });
}
export default SendPropertiesToWasiModal;
