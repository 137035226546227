import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import Layout from 'design-system/components/Layout';
import { dispatch } from 'reffects';
import { useMount } from 'react-use';
import { Navigation } from '../../partials';
import { UNITS_PAGE_MOUNTED } from './events';
import UnitsTable from './partials/UnitsTable';
import Filters from './partials/Filters';
import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  tableHasNoDataSelector,
  tableIsLoadingSelector,
} from '../Tables/selectors';
import { UNITS_TABLE_NAME } from './constants';
import UnitsEmptyState from './partials/UnitsEmptyState';
import ExportOptions from '../Properties/menu/ExportOptions/ExportOptions';
import PublicationDisabledDialog from './partials/PublicationDisabledDialog';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${spacing.stack(SPACING_SIZE.M)}
`;

const Actions = styled.div`
  & > * {
    ${spacing.inline(SPACING_SIZE.S)}
  }
`;

export default function Units() {
  useMount(() => dispatch(UNITS_PAGE_MOUNTED));
  const hasProperties = useSelector((state) =>
    tableHasNoDataSelector(state, UNITS_TABLE_NAME)
  );
  const isLoadingProperties = useSelector((state) =>
    tableIsLoadingSelector(state, UNITS_TABLE_NAME)
  );

  return (
    <Layout>
      <Layout.Header>
        <Navigation />
      </Layout.Header>
      <Layout.Content>
        {hasProperties && (
          <Layout.Padded>
            <Header>
              <Heading1>
                <FormattedMessageWithValidation id="units_page_header" />
              </Heading1>
              <Actions>
                <ExportOptions />
              </Actions>
            </Header>
            <Filters />
            <UnitsTable />
          </Layout.Padded>
        )}
        {!hasProperties && !isLoadingProperties && (
          <>
            <Layout.Padded>
              <Heading1>
                <FormattedMessageWithValidation id="units_page_header" />
              </Heading1>
            </Layout.Padded>
            <UnitsEmptyState />
          </>
        )}
      </Layout.Content>
      <PublicationDisabledDialog />
    </Layout>
  );
}
