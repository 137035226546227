import { extractCountryAndNumber } from '../fields/ExtractCountryAndNumber';
import { extractTypeOfPerson } from '../fields/extractTypeOfPerson';
import { TYPE_OF_PERSON } from '../../constants/billingEntity';

const INITIAL_FORM_DATA = {
  typeOfPerson: TYPE_OF_PERSON.NATURAL,
  taxId: '',
  legalName: '',
  email: '',
  address: '',
  city: '',
  province: '',
  postalCode: '',
  phone: '',
  retefuente: false,
  reteIca: false,
  reteIva: false,
  country: '',
  id: null,
};

export function formDataFromBillingEntityData(billingEntityData, countryCode) {
  if (billingEntityData == null) {
    return INITIAL_FORM_DATA;
  }
  const { phone, typeOfPerson, ...billingEntityRestData } = billingEntityData;

  return {
    phone: { ...extractCountryAndNumber(phone, countryCode) },
    legalName: billingEntityRestData.name,
    typeOfPerson: extractTypeOfPerson(typeOfPerson),
    ...billingEntityRestData,
  };
}
