import styled, { css } from 'styled-components';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { withStylesAndInnerRef } from 'design-system/utils';
import { spacing } from 'design-system/styles/spacing';
import BaseButton, { BUTTON_SIZE, BUTTON_VARIANT } from './BaseButton';

const modSizeSmall = () => css`
  ${spacing.inset(7)}

  ${Icon} {
    ${Icon.mods.size(ICON_SIZE.SMALL)}
  }
`;

const modSizeBase = () => css`
  ${spacing.inset(6)}

  ${Icon} {
    ${Icon.mods.size(ICON_SIZE.BASE)}
  }
`;

const modSize = (sizeName) =>
  ({
    [BUTTON_SIZE.SMALL]: modSizeSmall,
    [BUTTON_SIZE.BASE]: modSizeBase,
  }[sizeName]);

const Wrapper = styled(BaseButton)`
  display: inline-flex;
  ${BaseButton.mods.variant(BUTTON_VARIANT.PRIMARY)}
  ${() => modSize(BUTTON_SIZE.SMALL)}
`;

function IconOnlyButton({ icon, as, innerRef, ...rest }) {
  return (
    <Wrapper {...rest} forwardedAs={as} ref={innerRef}>
      {icon}
    </Wrapper>
  );
}

IconOnlyButton.mods = {
  size: modSize,
  variant: BaseButton.mods.variant,
};

export default withStylesAndInnerRef(IconOnlyButton);
