import styled from 'styled-components';
import { useEffect } from 'react';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import Loader from 'design-system/components/Loader';
import { useMount } from 'react-use';
import Portal from 'design-system/components/Portal';
import {
  PAYMENT_IFRAME_EVENT_RECEIVED,
  PAYMENT_IFRAME_MOUNTED,
} from './events';
import {
  iframeHeightSelector,
  isFormSubmittedSelector,
  isIframeLoadedSelector,
} from './selectors';

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

const GatewayIframe = styled.iframe`
  width: 100%;
  border: none;
`;

const OverlayLodder = styled.div`
  position: fixed;
  overflow-y: scroll;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 4;

  ::-webkit-scrollbar,
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default function PaymentIframe({
  src,
  onSuccessEvent,
  onBackEvent,
  onCheckoutFailed,
}) {
  useMount(() => dispatch(PAYMENT_IFRAME_MOUNTED));
  useEffect(() => {
    const messageListener = (event) => {
      dispatch({
        id: PAYMENT_IFRAME_EVENT_RECEIVED,
        payload: {
          id: event?.data.id,
          payload: event?.data.payload,
          onSuccessEvent,
          onBackEvent,
          onCheckoutFailed,
        },
      });
    };

    window.addEventListener('message', messageListener);

    return () => window.removeEventListener('message', messageListener);
  });
  const iframeLoaded = useSelector(isIframeLoadedSelector);
  const formSubmitted = useSelector(isFormSubmittedSelector);
  const height = useSelector(iframeHeightSelector);

  return (
    <>
      {!iframeLoaded && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
      <GatewayIframe scrolling="no" height={height} src={src} />
      {formSubmitted && (
        <Portal>
          <OverlayLodder>
            <Loader />
          </OverlayLodder>
        </Portal>
      )}
    </>
  );
}
