import { Children, cloneElement } from 'react';
import styled, { css } from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';

const modColumns = (columns) => css`
  grid-template-columns: repeat(${columns}, 1fr);
`;

const Wrapper = styled.div`
  display: grid;
  column-gap: var(--layout-gutter);

  ${spacing.stack(SPACING_SIZE.L)}

  ${({ columns }) => modColumns(columns)}
`;

function countDefinedChildren(children) {
  return Children.toArray(children).length;
}

function InputRow({ children, columns = 6, arrangement = [], ...rest }) {
  const childrenCount = countDefinedChildren(children);
  return (
    <Wrapper columns={columns} {...rest}>
      {Children.map(children, (child, index) => {
        if (!child) return null;

        const childColumns = arrangement[index] ?? columns / childrenCount;

        return cloneElement(child, {
          style: {
            gridColumnEnd: `span ${childColumns}`,
          },
        });
      })}
    </Wrapper>
  );
}

export default styled(InputRow)``;
