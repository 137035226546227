import styled from 'styled-components';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import Price from '../../../../partials/Price/Price';

const NoWrapText = styled(Body1).attrs({ $weight: FONT_WEIGHT.LIGHT })`
  white-space: nowrap;
  display: block;
`;

function PropertyOperationPrice({ operations }) {
  return (
    <div>
      {operations.map(({ type, price: { amount, currency } }) => (
        <NoWrapText key={type}>
          <Price amount={amount} currency={currency} />
        </NoWrapText>
      ))}
    </div>
  );
}

export default PropertyOperationPrice;
