import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import styled, { css } from 'styled-components';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';

const Wrapper = styled.button`
  height: ${spacing.value(SPACING_SIZE.XXL)};
  border: none;
  margin-right: var(--12px-rem);
  margin-top: 1px;
  padding: 0 1rem 2px;
  font-size: 14px;
  ${typography.weight(FONT_WEIGHT.SEMIBOLD)}
  color: rgb(0 0 0 / 38%);
  transition: color 0.1s ease;
  background-color: unset;
  cursor: pointer;
  text-transform: uppercase;

  &:focus {
    outline: 0;
  }

  &:hover {
    ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
      border-bottom: 2px solid rgb(10, 204, 134);
      padding-bottom: 0;
    `}

  &:last-of-type {
    margin-right: 0;
  }
`;

function Tab({ label, isActive, onClick }) {
  return (
    <Wrapper type="button" onClick={() => onClick(label)} $isActive={isActive}>
      {label}
    </Wrapper>
  );
}

export default Tab;
