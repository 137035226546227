export const THAILAND_PROPERTY_SITE = 'ThailandProperty';
export const TROVIT_SITE = 'Trovit';
export const ICASAS_SITE = 'iCasas';
export const PUNTOPROPIEDAD_SITE = 'PuntoPropiedad';
export const LAENCONTRE_SITE = 'laEncontre';
export const GLOBALIZA_SITE = 'Globaliza';
export const PROPERATI_SITE = 'Properati';

export const BRANDS = {
  THAILAND_PROPERTY: 'thailandproperty',
  DOT_PROPERTY: 'dotproperty',
  HIPFLAT: 'hipflat',
  TROVIT: 'trovit',
  MITULA: 'mitula',
  NESTORIA: 'nestoria',
  NUROA: 'nuroa',
  ICASAS: 'icasas',
  PROPERATI: 'properati',
  LAMUDI: 'lamudi',
  RESEM: 'resem',
};

export const BRANDS_TO_SITES = {
  [BRANDS.THAILAND_PROPERTY]: THAILAND_PROPERTY_SITE,
  [BRANDS.DOT_PROPERTY]: 'DotProperty',
  [BRANDS.HIPFLAT]: 'Hipflat',
  [BRANDS.TROVIT]: TROVIT_SITE,
  [BRANDS.MITULA]: 'Mitula',
  [BRANDS.NESTORIA]: 'Nestoria',
  [BRANDS.NUROA]: 'Nuroa',
  [BRANDS.PROPERATI]: PROPERATI_SITE,
  [BRANDS.LAMUDI]: 'Lamudi',
  [BRANDS.RESEM]: 'Resem',
};

export const DEFAULT_AUTOCOMPLETE_DEBOUNCE = 300;

export const EMAIL_VALIDATION_REGEX =
  '^([a-zA-Z0-9_\\+\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,63})$';
export const CONTACT_LINE_MAX_LENGTH = 255;
export const CONTACT_SKYPE_MAX_LENGTH = 255;
export const CONTACT_FACEBOOK_MESSENGER_MAX_LENGTH = 255;
export const CONTACT_VIBER_MAX_LENGTH = 255;
