import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import styled from 'styled-components';
import { dispatch } from 'reffects';
import ContactCard from 'design-system/components/ContactCard/ContactCard';
import Tooltip from 'design-system/components/Tooltip';
import { OPEN_LINK_IN_A_NEW_TAB } from '../../../../../../App/events';
import VerifiedEmailBadge from '../../../../../../partials/VerifiedEmailBadge';

const Wrapper = styled(ContactCard.Datum)`
  overflow: initial;
  ${({ $clickable }) => $clickable && 'cursor: pointer;'}
  width: fit-content;

  ${Tooltip} {
    width: auto;
  }
`;

const Datum = styled.span`
  ${spacing.inline(SPACING_SIZE.S, false)};
`;

export default function VerifiableEmail({ value, isVerified, glyph, link }) {
  return (
    <Wrapper
      glyph={glyph}
      $clickable={link}
      onClick={
        link &&
        (() =>
          dispatch({
            id: OPEN_LINK_IN_A_NEW_TAB,
            payload: { url: link, target: '_self' },
          }))
      }
    >
      <Datum>{value}</Datum>
      {isVerified && <VerifiedEmailBadge />}
    </Wrapper>
  );
}
