import PlansTable from './PlansTable';
import BenefitsContact from './BenefitsContact';

export default function ActiveSubscription({
  subscriptionHeader,
  subscriptionBenefits,
  showLogoOnWebsiteBenefit,
  websWithoutTranslatedAnd,
}) {
  if (!subscriptionHeader) return null;
  return (
    <PlansTable
      plansHeader={[subscriptionHeader]}
      benefitsByPlan={[subscriptionBenefits]}
      showLogoOnWebsiteBenefit={showLogoOnWebsiteBenefit}
      websWithoutTranslatedAnd={websWithoutTranslatedAnd}
      sideSection={
        <BenefitsContact
          title="subscription_benefits_upsell_title"
          description="subscription_benefits_upsell_text"
        />
      }
    />
  );
}
