import { createSelector } from 'reselect';
import {
  publisherBackgroundImageSelector,
  publisherLogoSelector,
  publisherMultiLangAddressSelector,
  publisherMultiLangDescriptionSelector,
  publisherMultiLangNameSelector,
  publisherWebsiteUrlSelector,
} from '../../selectors/publisher';
import { propertySupportedLanguagesSelector } from '../../selectors/config';

export const publisherProfileCurrentLocaleSelector = createSelector(
  (state) => state.publisherProfile?.currentLocale,
  propertySupportedLanguagesSelector,
  (publisherProfileLocale, supportedLocales) =>
    publisherProfileLocale ?? supportedLocales[0] ?? 'en-US'
);

export const publisherNameByCurrentLocaleSelector = createSelector(
  publisherMultiLangNameSelector,
  publisherProfileCurrentLocaleSelector,
  getLocalizedText
);

export const publisherAddressByCurrentLocaleSelector = createSelector(
  publisherMultiLangAddressSelector,
  publisherProfileCurrentLocaleSelector,
  getLocalizedText
);

export const publisherDescriptionByCurrentLocaleSelector = createSelector(
  publisherMultiLangDescriptionSelector,
  publisherProfileCurrentLocaleSelector,
  getLocalizedText
);

function getLocalizedText(values, locale) {
  const localizedValue = (values || []).find(
    (value) => locale === value.locale
  );

  if (localizedValue) {
    return localizedValue.text;
  }

  return values?.[0]?.text;
}

export const publisherFormDataSelector = createSelector(
  publisherLogoSelector,
  publisherBackgroundImageSelector,
  publisherMultiLangNameSelector,
  publisherMultiLangAddressSelector,
  publisherMultiLangDescriptionSelector,
  publisherWebsiteUrlSelector,
  (companyLogo, companyBackground, names, addresses, descriptions, url) => ({
    companyLogo,
    companyBackground,
    names,
    addresses,
    descriptions,
    url,
  })
);
