import styled, { css } from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Heading2 } from 'design-system/components/Typography/presets/Heading2';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';

const IllustrationBox = styled.div`
  ${spacing.stack(SPACING_SIZE.L)}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const modFullScreen = () => css`
  ${Content} {
    ${spacing.inset(SPACING_SIZE.L)}
    ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
  }
`;

const Title = styled(Heading2)`
  text-align: center;
  ${spacing.stack(SPACING_SIZE.M)}
`;

const Description = styled(Body1)`
  text-align: center;
  width: 50%;
  ${spacing.stack(SPACING_SIZE.M)}
`;

const Action = styled.div`
  margin-top: ${spacing.value(10)};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${spacing.insetSandwich(SPACING_SIZE.XXXL)}

  ${({ fullScreen }) => fullScreen && modFullScreen()}
`;

function EmptyState({ illustration, title, description, action, ...rest }) {
  return (
    <Wrapper {...rest}>
      <IllustrationBox>{illustration}</IllustrationBox>
      <Content>
        <Title $weight={FONT_WEIGHT.BOLD}>{title}</Title>
        <Description $weight={FONT_WEIGHT.NORMAL}>{description}</Description>
        <Action>{action}</Action>
      </Content>
    </Wrapper>
  );
}

export default styled(EmptyState)``;
