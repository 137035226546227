import styled from 'styled-components';
import SkeletonBone from 'design-system/components/SkeletonBone';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import Typography from 'design-system/components/Typography/Typography';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import Price from '../../../../../partials/Price/Price';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const UnitDetailLine = styled(Body2)`
  display: block;
  ${Typography.mods.size(11.5)}
  ${Typography.mods.weight(FONT_WEIGHT.NORMAL)}
  letter-spacing: -0.15px;
  ${Typography.mods.ellipse()}
`;

const UnitDetailPrice = styled(Body2)`
  display: block;
  ${Typography.mods.size(11.5)}
  ${Typography.mods.weight(FONT_WEIGHT.MEDIUM)}
  letter-spacing: -0.15px;
  ${Typography.mods.ellipse()}
`;

function UnitSnippet({ bedrooms, bathrooms, price, ...rest }) {
  return (
    <Wrapper {...rest}>
      {bedrooms && (
        <UnitDetailLine $noWrap>
          {bedrooms}{' '}
          <FormattedMessageWithValidation id="enqpage_table_bedrooms" />
        </UnitDetailLine>
      )}
      {bathrooms && (
        <UnitDetailLine $noWrap>
          {bathrooms}{' '}
          <FormattedMessageWithValidation id="enqpage_table_bathrooms" />
        </UnitDetailLine>
      )}
      {price && (
        <UnitDetailPrice $noWrap>
          <Price amount={price.amount} currency={price.currency} />
        </UnitDetailPrice>
      )}
    </Wrapper>
  );
}

UnitSnippet.Skeleton = ({ ...rest }) => (
  <Wrapper {...rest}>
    <UnitDetailLine $noWrap>
      <SkeletonBone $width="80px" $height="10px" randomWidth />
    </UnitDetailLine>
    <UnitDetailLine $noWrap>
      <SkeletonBone $width="80px" $height="10px" randomWidth />
    </UnitDetailLine>
    <UnitDetailLine $noWrap>
      <SkeletonBone $width="80px" $height="10px" randomWidth />
    </UnitDetailLine>
  </Wrapper>
);

export default styled(UnitSnippet)``;
