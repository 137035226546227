import styled from 'styled-components';
import Dialog from 'design-system/components/Dialog/Dialog';
import Tag, { TAG_VARIANT } from 'design-system/components/Tag';
import { SecondaryButton } from 'design-system/components/Button/presets';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Typography from 'design-system/components/Typography/Typography';
import { FONT_SIZE, typography } from 'design-system/styles/typography';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { useDialog } from '../../../../hooks/useDialog';

export const DETAILS_MODAL = 'DETAILS_MODAL';

const ExternalReferenceWrapper = styled.div`
  display: flex;
  align-items: center;
  ${spacing.stack(SPACING_SIZE.L, false)}
  > * {
    ${spacing.inline(SPACING_SIZE.M)}
  }
  ${Typography} {
    ${typography.size(FONT_SIZE.S)}
  }
`;

const JsonSnippet = styled.div`
  max-height: 256px;
  overflow-y: auto;
  ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
  ${spacing.inset(SPACING_SIZE.XS)}
  ${typography.size(FONT_SIZE.XS)}
`;

function DialogContent({ externalReference, rawError, onClose }) {
  return (
    <>
      <Dialog.Header>
        <FormattedMessageWithValidation id="import_stats_invalid_ads_details_dialog_title" />
      </Dialog.Header>
      <Dialog.Content>
        <ExternalReferenceWrapper>
          <Tag variant={TAG_VARIANT.SECONDARY}>
            <FormattedMessageWithValidation id="import_stats_invalid_ads_details_dialog_external_reference" />
          </Tag>
          <Typography>{externalReference}</Typography>
        </ExternalReferenceWrapper>
        <JsonSnippet>
          <pre>{JSON.stringify(rawError, null, 2)}</pre>
        </JsonSnippet>
      </Dialog.Content>
      <Dialog.Footer>
        <SecondaryButton onClick={onClose}>
          <FormattedMessageWithValidation id="import_stats_invalid_ads_details_dialog_confirm" />
        </SecondaryButton>
      </Dialog.Footer>
    </>
  );
}

export default function DetailsDialog() {
  const { open, parameters, closeDialog } = useDialog(DETAILS_MODAL);

  return (
    <Dialog open={open}>
      {open && <DialogContent {...parameters} onClose={closeDialog} />}
    </Dialog>
  );
}
