import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { environment } from '../../../../../../../coeffects/environment';
import { CLOSE_DIALOG } from '../../../../../../../events/dialogs';
import { toast } from '../../../../../../../effects/toast';

export const REJECT_CANCEL_REQUEST = 'REJECT_CANCEL_REQUEST';
export const ACCEPT_CANCEL_REQUEST = 'ACCEPT_CANCEL_REQUEST';
export const CLOSE_MODAL_SUCCESS = 'CLOSE_MODAL_SUCCESS';
export const CLOSE_MODAL_ERROR = 'CLOSE_MODAL_ERROR';

registerEventHandler(
  REJECT_CANCEL_REQUEST,
  ({ environment: { apiUrl } }, { subscriptionId, onSuccessEvent }) =>
    http.post({
      url: `${apiUrl}/backoffice/subscriptions/${subscriptionId}/cancel-request/reject`,
      successEvent: {
        id: CLOSE_MODAL_SUCCESS,
        payload: { onSuccessEvent },
      },
      errorEvent: {
        id: CLOSE_MODAL_ERROR,
        payload: {
          message: 'There was an error trying to reject cancel request',
        },
      },
    }),
  [environment()]
);

registerEventHandler(
  ACCEPT_CANCEL_REQUEST,
  ({ environment: { apiUrl } }, { subscriptionId, onSuccessEvent }) =>
    http.post({
      url: `${apiUrl}/backoffice/subscriptions/${subscriptionId}/cancel-request/accept`,
      successEvent: {
        id: CLOSE_MODAL_SUCCESS,
        payload: { onSuccessEvent },
      },
      errorEvent: {
        id: CLOSE_MODAL_ERROR,
        payload: {
          message: 'There was an error trying to accept cancel request',
        },
      },
    }),
  [environment()]
);

registerEventHandler(CLOSE_MODAL_SUCCESS, (_, [__, { onSuccessEvent }]) =>
  effects.dispatchMany([CLOSE_DIALOG, onSuccessEvent])
);

registerEventHandler(CLOSE_MODAL_ERROR, (_, [__, { message }]) => ({
  ...effects.dispatch(CLOSE_DIALOG),
  ...toast.show({ text: message }),
}));
