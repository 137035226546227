import { CHECKOUT } from '../constants/routes';

export function homeRoute() {
  return '/';
}

export function loginRoute() {
  return '/login';
}

export function changePasswordRoute() {
  return '/change-password';
}

export function newPropertyForm() {
  return `/properties/new-property`;
}
export function propertiesList() {
  return `/properties`;
}

export function editPropertyForm(adId) {
  return `/properties/${adId}/edit`;
}

export function unitsRoute() {
  return '/units';
}

export function newUnitForm() {
  return `/units/new-unit`;
}

export function editUnitForm(unitId) {
  return `/units/${unitId}/edit`;
}

export function projectsRoute() {
  return '/projects';
}

export function enquiriesRoute(queryString = '') {
  return `/enquiries${queryString}`;
}

export function subscriptionRoute() {
  return '/subscription';
}

export function subscriptionOffersRoute() {
  return '/subscription/offers';
}

export function checkoutBillingInformationRoute({
  subscriptionChangeRequestId,
  subRoute,
}) {
  return `${CHECKOUT}/${subRoute}/${subscriptionChangeRequestId}/billing-information`;
}

export function checkoutPaymentMethodRoute({
  subscriptionChangeRequestId,
  subRoute,
}) {
  return `${CHECKOUT}/${subRoute}/${subscriptionChangeRequestId}/payment-method`;
}

export function checkoutProcessingRoute({
  subscriptionChangeRequestId,
  subRoute,
}) {
  return `${CHECKOUT}/${subRoute}/${subscriptionChangeRequestId}/processing`;
}

export function checkoutSuccessRoute({
  subscriptionChangeRequestId,
  subRoute,
}) {
  return `${CHECKOUT}/${subRoute}/${subscriptionChangeRequestId}/success`;
}

export function checkoutFailedRoute({ subscriptionChangeRequestId, subRoute }) {
  return `${CHECKOUT}/${subRoute}/${subscriptionChangeRequestId}/failed`;
}

export function checkoutAlreadyActiveRoute({
  subscriptionChangeRequestId,
  subRoute,
}) {
  return `${CHECKOUT}/${subRoute}/${subscriptionChangeRequestId}/active`;
}

export function checkoutPaymentCancelledRoute({
  subscriptionChangeRequestId,
  subRoute,
}) {
  return `${CHECKOUT}/${subRoute}/${subscriptionChangeRequestId}/cancelled`;
}

export function checkoutPaymentForbiddenRoute({
  subscriptionChangeRequestId,
  subRoute,
}) {
  return `${CHECKOUT}/${subRoute}/${subscriptionChangeRequestId}/forbidden`;
}

export function managedPublishersRoute() {
  return '/publishers';
}

export function termsAndConditionsRoute() {
  return '/terms-conditions';
}

export function privacyPolicyRoute() {
  return '/privacy-policy';
}

export function cookiesPolicyRoute() {
  return '/cookies-policy';
}

export function androidAppDownloadLink() {
  return 'https://play.google.com/store/apps/details?id=com.proppit.app';
}

export function iosAppDownloadLink() {
  return 'https://apps.apple.com/app/proppit/id1577910566';
}
