export function isIframeLoadedSelector(state) {
  return state['paymentsIframe:loaded'] ?? false;
}

export function isFormSubmittedSelector(state) {
  return state['paymentsIframe:formSubmitted'] ?? false;
}

export function iframeHeightSelector(state) {
  return state['paymentsIframe:height'] ?? null;
}
