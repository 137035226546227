import { TYPE_OF_PERSON } from '../../constants/billingEntity';

export function extractTypeOfPerson(typeOfPerson) {
  if (!typeOfPerson || Object.values(TYPE_OF_PERSON).includes(typeOfPerson)) {
    return typeOfPerson;
  }
  return typeOfPerson.toLowerCase().includes('natural')
    ? TYPE_OF_PERSON.NATURAL
    : TYPE_OF_PERSON.JURIDIC;
}
