import { useFormContext } from 'react-hook-form';
import InputChoice from 'design-system/components/InputChoice/InputChoice';
import { useRegisterFieldErrors } from '../../pages/publication/partials/FieldGroup';

function SingleChoiceField({ name, choices, tip, ...rest }) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  useRegisterFieldErrors({ name, errors: errors[name] });

  return (
    <InputChoice
      error={Boolean(errors[name])}
      tip={errors[name]?.message ?? tip}
      {...rest}
    >
      {choices.map(({ label, value }) => (
        <InputChoice.Option
          key={value}
          label={label}
          value={value}
          {...register(name)}
        />
      ))}
    </InputChoice>
  );
}

export default SingleChoiceField;
