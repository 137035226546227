import { publisherSelector } from '../../selectors/publisher';

export function checkoutPageSCRIdSelector(state) {
  return state.checkoutPage?.subscriptionChangeRequestId;
}

export function hasSelfServicePublisherSelector(state) {
  return selfServicePublisherSelector(state) != null;
}

export function selfServicePublisherEmailSelector(state) {
  return selfServicePublisherSelector(state)?.email;
}

function selfServicePublisherSelector(state) {
  return publisherSelector(state)?.selfServicePublisher;
}
