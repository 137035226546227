import { FormattedDateParts } from 'react-intl';

const formattingOptions = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  calendar: 'gregory',
};

function formatParts(parts) {
  if (!parts) {
    return null;
  }
  const [day, , month, , year] = parts;
  if (!day?.value || !month?.value || !year?.value) {
    return null;
  }
  return `${day.value}-${month.value}-${year.value}`;
}

export function formatDate(intl, value) {
  return formatParts(intl.formatDateToParts(value, formattingOptions));
}

function CustomFormattedDate({ value, ...props }) {
  return (
    <FormattedDateParts value={value} {...formattingOptions} {...props}>
      {(parts) => formatParts(parts)}
    </FormattedDateParts>
  );
}

export default CustomFormattedDate;
