import { store, useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import { useCallback, useEffect, useRef } from 'react';
import { isEqual } from 'lodash';
import { useDatatableContext } from './useDatatableContext';
import { filterByNameSelector, filtersSelector } from '../selectors/filters';
import {
  DATA_TABLE_CLEAR_FILTERS,
  DATA_TABLE_UPDATE_FILTER,
} from '../events/filters';

export function useFilter(filterName) {
  const context = useDatatableContext();
  const { collectionName, registerFilter, unregisterFilter } = context;

  useEffect(() => {
    registerFilter(filterName);

    return () => unregisterFilter(filterName);
  }, [filterName]);

  const value = useSelector((state) =>
    filterByNameSelector(state, { collectionName, filterName })
  );

  const setValue = useCallback(
    (filterValue) => {
      const currentValue = filterByNameSelector(store.getState(), {
        collectionName,
        filterName,
      });
      if (isEqual(currentValue, filterValue)) return;
      dispatch({
        id: DATA_TABLE_UPDATE_FILTER,
        payload: {
          ...context,
          filter: { name: filterName, value: filterValue },
        },
      });
    },
    [...Object.values(context)]
  );

  return {
    value,
    setValue,
  };
}

export function useFilters() {
  const context = useDatatableContext();
  const { collectionName } = context;
  const filtersRef = useRef();

  const filters = useSelector((state) =>
    filtersSelector(state, { collectionName })
  );

  if (!isEqual(filters, filtersRef.current)) {
    filtersRef.current = filters;
  }

  const clearFilters = useCallback(
    () =>
      dispatch({
        id: DATA_TABLE_CLEAR_FILTERS,
        payload: context,
      }),
    [...Object.values(context)]
  );

  return {
    filters: filtersRef.current,
    clearFilters,
  };
}
