import * as React from "react";
const SvgTrovitLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={29}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#TrovitLogo_svg__a)">
      <path
        fill="currentColor"
        d="M11.172 9.206c-1.913 0-3.459 1.695-3.459 3.782s1.546 3.781 3.459 3.781c1.912 0 3.458-1.694 3.458-3.781s-1.546-3.782-3.458-3.782m0 5.4c-.815 0-1.483-.728-1.483-1.618s.668-1.618 1.483-1.618c.814 0 1.482.728 1.482 1.618s-.668 1.618-1.482 1.618M22.078 9.35h1.93v7.266h-1.93zm-.11-2.211c0-.623.485-1.12 1.07-1.12.595 0 1.07.507 1.07 1.12 0 .622-.484 1.12-1.07 1.12-.585 0-1.07-.498-1.07-1.12m5.078 3.925h1.317V9.35h-1.317V7.263l-1.93.488v6.213c0 1.15.155 2.777 2.516 2.777.347 0 .677-.039.732-.048v-1.599h-.513c-.42 0-.805-.24-.805-.929zm-25.115 0h1.317V9.35H1.93V7.263L0 7.742v6.213c0 1.149.156 2.776 2.516 2.776.348 0 .677-.038.732-.048v-1.589h-.512c-.43 0-.806-.24-.806-.929zm16.011 2.958 1.382-4.672h2.14L19.15 15.89c-.192.497-.659.88-1.217.88s-1.016-.383-1.217-.88l-2.306-6.53h2.15zM7.11 9.206c-1.628 0-2.955 1.389-2.955 3.093v4.317h1.93v-4.02c0-.9.531-1.389 1.327-1.389h.174v-2z"
      />
    </g>
    <defs>
      <clipPath id="TrovitLogo_svg__a">
        <path fill="#fff" d="M0 0h28.364v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgTrovitLogo;
