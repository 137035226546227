import styled from 'styled-components';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { COLOR_PALETTE } from 'design-system/styles/color';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Heading1} {
    ${spacing.stack(SPACING_SIZE.S)}
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

function MainKpi({ amount, message }) {
  return (
    <Wrapper>
      <Info>
        <Heading1>{amount}</Heading1>
        <Subtitle3 $color={COLOR_PALETTE.NEUTRAL_A60}>{message}</Subtitle3>
      </Info>
    </Wrapper>
  );
}

export default MainKpi;
