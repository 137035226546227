import { dispatch } from 'reffects';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ENQUIRY_DETAIL_PAGE_MOUNTED } from './events';

export default function EnquiryDetail() {
  const { enquiryId } = useParams();
  useEffect(
    () => dispatch({ id: ENQUIRY_DETAIL_PAGE_MOUNTED, payload: { enquiryId } }),
    [enquiryId]
  );

  return null;
}
