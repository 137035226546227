import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';

export const SELECT_REASON_DELETE_OR_UNPUBLISH =
  'SELECT_REASON_DELETE_OR_UNPUBLISH';
export const REASON_OTHER_TEXT_CHANGED = 'REASON_OTHER_TEXT_CHANGED';
export const RESET_DELETE_OR_UNPUBLISH_REASON =
  'RESET_DELETE_OR_UNPUBLISH_REASON';

registerEventHandler(SELECT_REASON_DELETE_OR_UNPUBLISH, (_, reason) =>
  state.merge({ deleteOrUnpublish: { reason } })
);

registerEventHandler(REASON_OTHER_TEXT_CHANGED, (_, reasonText) =>
  state.merge({ deleteOrUnpublish: { reasonText } })
);

registerEventHandler(RESET_DELETE_OR_UNPUBLISH_REASON, () =>
  state.set({ deleteOrUnpublish: undefined })
);
