import styled from 'styled-components';
import Table, { TABLE_ALIGNMENT } from 'design-system/components/Table';
import SkeletonBone from 'design-system/components/SkeletonBone';
import PropertySnippet from '../../../../../partials/PropertySnippet';

const LastActivitySkeleton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export default function LeadsTableSkeleton() {
  return (
    <Table.Skeleton
      rows={10}
      columns={[
        {
          id: 'name',
          content: <SkeletonBone $width="60px" $height="16px" />,
        },
        {
          id: 'email',
          content: <SkeletonBone $width="60px" $height="16px" />,
        },
        {
          id: 'phone',
          content: <SkeletonBone $width="60px" $height="16px" />,
        },
        {
          id: 'status',
          content: <SkeletonBone $width="60px" $height="16px" />,
        },
        {
          id: 'snippet',
          content: <SkeletonBone $width="90px" $height="16px" />,
        },
        {
          id: 'enquiries',
          content: <SkeletonBone $width="60px" $height="16px" />,
          alignment: TABLE_ALIGNMENT.CENTER,
        },
        {
          id: 'lastActivity',
          content: <SkeletonBone $width="120px" $height="16px" />,
        },
      ]}
    >
      <Table.Row>
        <Table.Cell>
          <SkeletonBone $width="128px" $height="14px" randomWidth />
        </Table.Cell>
        <Table.Cell>
          <SkeletonBone $width="160px" $height="14px" randomWidth />
        </Table.Cell>
        <Table.Cell>
          <SkeletonBone $width="120px" $height="14px" randomWidth />
        </Table.Cell>
        <Table.Cell>
          <SkeletonBone $width="40px" $height="14px" randomWidth />
        </Table.Cell>
        <Table.Cell>
          <PropertySnippet.Skeleton />
        </Table.Cell>
        <Table.Cell>
          <SkeletonBone $width="20px" $height="14px" randomWidth />
        </Table.Cell>
        <Table.Cell>
          <LastActivitySkeleton>
            <SkeletonBone $width="80px" $height="14px" />
            <SkeletonBone $width="120px" $height="14px" randomWidth />
          </LastActivitySkeleton>
        </Table.Cell>
      </Table.Row>
    </Table.Skeleton>
  );
}
