import { useSelector } from 'reffects-store';
import { useCallback } from 'react';
import { FormattedList } from 'react-intl';
import Icon from 'design-system/components/Icon';
import { useBulkAction } from '../../../partials/DataTable/hooks/useBulkAction';
import { useDialog } from '../../../hooks/useDialog';
import { siteWherePublishingNamesSelector } from '../../../selectors/config';
import { ActionsMenu } from '../../../partials/DataTable';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { GENERIC_CONFIRMATION_MODAL } from '../../../partials/modals/generic/constants';

export function DeleteBulkAction() {
  const { executeAction, selectedItemsCount, isProcessing } =
    useBulkAction('delete');
  const { openDialog } = useDialog(GENERIC_CONFIRMATION_MODAL);
  const webs = useSelector(siteWherePublishingNamesSelector);

  const handleDelete = useCallback(() => {
    openDialog({
      messages: {
        title: {
          id: 'propspage_paid_free_bulk_delete_title',
        },
        body: {
          id: 'propspage_paid_free_bulk_delete_text',
          values: {
            n: selectedItemsCount,
            networkSites: <FormattedList type="conjunction" value={webs} />,
          },
        },
      },
      actions: {
        confirm: executeAction,
      },
    });
  }, [webs]);

  return (
    <ActionsMenu.Action
      icon={<Icon glyph="trash" />}
      disabled={isProcessing}
      onClick={handleDelete}
    >
      <FormattedMessageWithValidation id="item_menu_delete" />
    </ActionsMenu.Action>
  );
}
