import styled, { css } from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';

export const EMPHASIS_VARIANT = {
  INTERVENTION: Symbol('EMPHASIS_VARIANT_INTERVENTION'),
  SECONDARY: Symbol('EMPHASIS_VARIANT_SECONDARY'),
  ALERT: Symbol('EMPHASIS_VARIANT_ALERT'),
  INDICATOR: Symbol('EMPHASIS_VARIANT_INDICATOR'),
};

const modInterventionVariant = () => css`
  ${color.background(COLOR_PALETTE.PUMPKIN_A10)}
  ${Subtitle3} {
    ${Subtitle3.mods.color(COLOR_PALETTE.NEUTRAL_BASE)}
  }
`;

const modSecondaryVariant = () => css`
  ${color.background(COLOR_PALETTE.SECONDARY_BASE)}
  ${Subtitle3} {
    ${Subtitle3.mods.color(COLOR_PALETTE.NEUTRAL_A00)}
  }

  ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
`;

const modAlertVariant = () => css`
  ${color.background(COLOR_PALETTE.STRAWBERRY_A10)}
  ${Subtitle3} {
    ${Subtitle3.mods.color(COLOR_PALETTE.NEUTRAL_BASE)}
  }
`;

const modIndicatorVariant = () => css`
  ${color.background(COLOR_PALETTE.PRIMARY_A20)}
  ${Subtitle3} {
    ${Subtitle3.mods.color(COLOR_PALETTE.NEUTRAL_BASE)}
  }
`;

const modVariant = (variantName) =>
  ({
    [EMPHASIS_VARIANT.INTERVENTION]: modInterventionVariant,
    [EMPHASIS_VARIANT.SECONDARY]: modSecondaryVariant,
    [EMPHASIS_VARIANT.ALERT]: modAlertVariant,
    [EMPHASIS_VARIANT.INDICATOR]: modIndicatorVariant,
  }[variantName]);

const Emphasis = styled(Body1).attrs({
  as: 'div',
})`
  padding: ${spacing.value(SPACING_SIZE.S)} ${spacing.value(SPACING_SIZE.XL)};
  ${color.background(COLOR_PALETTE.PUMPKIN_A10)}
  ${spacing.stack(SPACING_SIZE.M)}
  ${({ $weight = FONT_WEIGHT.MEDIUM }) => typography.weight($weight)}
  ${({ variant }) => modVariant(variant)}
`;

Emphasis.defaultProps = {
  variant: EMPHASIS_VARIANT.INTERVENTION,
};

export default Emphasis;
