import { http } from 'reffects-batteries';
import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { environment } from '../../../coeffects/environment';

export const BACKOFFICE_AD_REQUESTED = 'BACKOFFICE_AD_REQUESTED';
export const BACKOFFICE_AD_LOADED = 'BACKOFFICE_AD_LOADED';
export const BACKOFFICE_AD_ADDRESS_GEOLEVELS_CHANGED =
  'BACKOFFICE_AD_ADDRESS_GEOLEVELS_CHANGED';
export const BACKOFFICE_AD_ADDRESS_FORM_ADDRESS_CHANGED =
  'BACKOFFICE_AD_ADDRESS_FORM_ADDRESS_CHANGED';
export const BACKOFFICE_AD_ADDRESS_FORM_LATITUDE_CHANGED =
  'BACKOFFICE_AD_ADDRESS_FORM_LATITUDE_CHANGED';
export const BACKOFFICE_AD_ADDRESS_FORM_LONGITUDE_CHANGED =
  'BACKOFFICE_AD_ADDRESS_FORM_LONGITUDE_CHANGED';
export const BACKOFFICE_AD_ADDRESS_FORM_SUBMITTED =
  'BACKOFFICE_AD_ADDRESS_FORM_SUBMITTED';
export const BACKOFFICE_AD_ADDRESS_FORM_SAVED =
  'BACKOFFICE_AD_ADDRESS_FORM_SAVED';
export const BACKOFFICE_AD_ADDRESS_FORM_FAILED =
  'BACKOFFICE_AD_ADDRESS_FORM_FAILED';

registerEventHandler(
  BACKOFFICE_AD_REQUESTED,
  ({ environment: { apiUrl } }, { adId }) =>
    http.get({
      url: `${apiUrl}/admin/property/${adId}`,
      successEvent: BACKOFFICE_AD_LOADED,
    }),
  [environment()]
);

registerEventHandler(BACKOFFICE_AD_LOADED, (_, [{ data }]) => {
  if (data.error) {
    return state.set({
      adEditor: { ad: {}, error: data.error },
    });
  }

  return state.set({
    adEditor: { ad: data },
  });
});

registerEventHandler(BACKOFFICE_AD_ADDRESS_GEOLEVELS_CHANGED, (_, { value }) =>
  state.set({
    [`adEditor.ad.geoLevels`]: JSON.parse(value),
  })
);
registerEventHandler(
  BACKOFFICE_AD_ADDRESS_FORM_ADDRESS_CHANGED,
  (_, { value }) =>
    state.set({
      [`adEditor.ad.address`]: value,
    })
);
registerEventHandler(
  BACKOFFICE_AD_ADDRESS_FORM_LONGITUDE_CHANGED,
  (_, { value }) =>
    state.set({
      [`adEditor.ad.longitude`]: value,
    })
);
registerEventHandler(
  BACKOFFICE_AD_ADDRESS_FORM_LATITUDE_CHANGED,
  (_, { value }) =>
    state.set({
      [`adEditor.ad.latitude`]: value,
    })
);

registerEventHandler(
  BACKOFFICE_AD_ADDRESS_FORM_SUBMITTED,
  ({ state: { ad }, environment: { apiUrl } }, _) => ({
    ...state.set({
      'adEditor.saved': false,
      'adEditor.failed': false,
    }),
    ...http.patch({
      url: `${apiUrl}/admin/property/${ad.id}/address`,
      body: {
        address: ad.address,
        geolevels: ad.geoLevels,
        latitude: ad.latitude,
        longitude: ad.longitude,
      },
      successEvent: BACKOFFICE_AD_ADDRESS_FORM_SAVED,
      errorEvent: BACKOFFICE_AD_ADDRESS_FORM_FAILED,
    }),
  }),
  [state.get({ ad: 'adEditor.ad' }), environment()]
);

registerEventHandler(BACKOFFICE_AD_ADDRESS_FORM_SAVED, () =>
  state.set({
    'adEditor.saved': true,
  })
);

registerEventHandler(BACKOFFICE_AD_ADDRESS_FORM_FAILED, () =>
  state.set({
    'adEditor.failed': true,
  })
);
