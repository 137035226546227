import styled, { css } from 'styled-components';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Body1 } from 'design-system/components/Typography/presets/Body1';

const Content = styled.span`
  display: flex;

  ${spacing.insetSquish(SPACING_SIZE.M)}
  ${color.background(COLOR_PALETTE.NEUTRAL_A10)}
  ${radius.regular(RADIUS_SIZE.LARGE)}

  ${Body1} {
    ${Body1.mods.color(COLOR_PALETTE.NEUTRAL_A80)}
    ${spacing.inline(SPACING_SIZE.S)}
  }

  ${Icon} {
    cursor: pointer;
    margin-right: -${spacing.value(SPACING_SIZE.S)};
    ${Icon.mods.color(COLOR_PALETTE.NEUTRAL_A80)}
    ${Icon.mods.size(ICON_SIZE.SMALL)}
  }
`;

const modSelected = () => css`
  ${Content} {
    ${color.background(COLOR_PALETTE.NEUTRAL_A20)}
  }
`;

const modError = () => css`
  ${Content} {
    ${color.background(COLOR_PALETTE.STRAWBERRY_A10)}
  }

  ${Body1} {
    ${Body1.mods.color(COLOR_PALETTE.STRAWBERRY_BASE)}
  }

  ${Icon} {
    ${Icon.mods.color(COLOR_PALETTE.STRAWBERRY_BASE)}
  }
`;

const Wrapper = styled.span`
  display: flex;
  align-items: center;

  ${({ error }) => error && modError()}
`;

function Chip({ children, onIconClick, ...rest }) {
  return (
    <Wrapper {...rest}>
      <Content>
        <Body1>{children}</Body1>
        {onIconClick && <Icon glyph="cancel" onClick={onIconClick} />}
      </Content>
    </Wrapper>
  );
}

Chip.mods = {
  selected: modSelected,
};

export default styled(Chip)``;
