import { state } from 'reffects-store';
import { isEmpty } from 'lodash';

export function combineStateSetEffects(...stateEffects) {
  const combinedEffectMutations = stateEffects.reduce((acc, effect) => {
    if (!effect) return acc;

    const [[effectId, effectMutations]] = Object.entries(effect);

    const [stateSetEffectId] = Object.keys(state.set());
    if (effectId !== stateSetEffectId)
      throw new Error(`All effects should be ${stateSetEffectId}`);

    return { ...acc, ...effectMutations };
  }, {});

  if (isEmpty(combinedEffectMutations)) return {};

  return state.set(combinedEffectMutations);
}
