import { Link } from 'react-router-dom';
import { useSelector } from 'reffects-store';
import Dialog from 'design-system/components/Dialog/Dialog';
import {
  FlatButton,
  SecondaryButton,
} from 'design-system/components/Button/presets';
import { useDialog } from '../../../hooks/useDialog';
import { UNIT_PUBLICATION_DISABLED } from './constants';
import { subscriptionRoute } from '../../../utils/proppitWebRouter';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { supportEmailSelector } from '../../../selectors/config';

function PublicationDisabled({ onClose }) {
  const supportEmail = useSelector(supportEmailSelector);
  return (
    <>
      <Dialog.Header>
        <FormattedMessageWithValidation id="free_developer_modal_header" />
      </Dialog.Header>
      <Dialog.Emphasis>
        <FormattedMessageWithValidation id="free_developer_modal_emphasis" />
      </Dialog.Emphasis>
      <Dialog.Content>
        <Dialog.Text>
          <FormattedMessageWithValidation
            id="free_developer_modal_content"
            values={{
              supportEmail: (
                <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
              ),
            }}
          />
        </Dialog.Text>
      </Dialog.Content>
      <Dialog.Footer>
        <FlatButton onClick={onClose}>
          <FormattedMessageWithValidation id="free_developer_modal_cancel" />
        </FlatButton>
        <SecondaryButton
          forwardedAs={Link}
          to={subscriptionRoute()}
          onClick={onClose}
        >
          <FormattedMessageWithValidation id="free_developer_modal_view_subscriptions" />
        </SecondaryButton>
      </Dialog.Footer>
    </>
  );
}

function PublicationDisabledDialog() {
  const { open, parameters, closeDialog } = useDialog(
    UNIT_PUBLICATION_DISABLED
  );

  return (
    <Dialog open={open}>
      {open && <PublicationDisabled {...parameters} onClose={closeDialog} />}
    </Dialog>
  );
}

export default PublicationDisabledDialog;
