import { useCallback } from 'react';
import { dispatch } from 'reffects';

import Dialog from 'design-system/components/Dialog/Dialog';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

import { useDialog } from '../../../hooks/useDialog';

import { AGENCY_DETAIL_EDIT_CONTACT_DIALOG_ID } from './constants';

import DetailForm from './DetailForm';
import { CONTACT_DETAIL_EDIT_SUBMITTED } from './events';

function EditDetailDialog() {
  const { open, parameters, closeDialog } = useDialog(
    AGENCY_DETAIL_EDIT_CONTACT_DIALOG_ID
  );

  const handleFormSubmit = useCallback(
    (newValue) => {
      dispatch({
        id: CONTACT_DETAIL_EDIT_SUBMITTED,
        payload: {
          oldValue: parameters.detail.value,
          newValue,
          contactDetailType: parameters.contactType,
        },
      });

      closeDialog();
    },
    [parameters, closeDialog]
  );

  return (
    <Dialog open={open}>
      <Dialog.Header>
        <FormattedMessageWithValidation id="edit_contact_details_modal_title" />
      </Dialog.Header>
      {open && (
        <DetailForm
          contactType={parameters.contactType}
          detail={parameters.detail}
          onSubmit={handleFormSubmit}
          onCancel={closeDialog}
        />
      )}
    </Dialog>
  );
}

export default EditDetailDialog;
