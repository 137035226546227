export function addObject(obj, formData = new FormData()) {
  return Object.entries(obj).reduce(
    (form, [key, value]) => addItem(key, JSON.stringify(value), form),
    formData
  );
}

export function addArray(arr, arrName, formData = new FormData()) {
  if (!Array.isArray(arr)) {
    return formData;
  }
  return arr.reduce(
    (form, element, idx) => addItem(`${arrName}[${idx}]`, element, form),
    formData
  );
}

export function addItem(name, value, formData = new FormData()) {
  formData.append(name, value);
  return formData;
}
