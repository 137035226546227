import { uniqueId } from 'lodash';
import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import queryString from 'query-string';
import { table } from '../../../../../partials/DataTable/effects/table';
import { COLLECTION_NAME } from '../../constants';
import { environment } from '../../../../../coeffects/environment';
import { mapTableFiltersToApiFilters } from '../../../../../partials/DataTable/utils/mapTableToApiFilters';

export function createAdapter() {
  const eventSuffix = uniqueId(COLLECTION_NAME);

  const UPDATE_ITEMS_TABLE_DATA = `UPDATE_ITEMS_TABLE_DATA_${eventSuffix}`;
  const UPDATE_ITEMS_TABLE_DATA_SUCCESS = `UPDATE_ITEMS_TABLE_DATA_SUCCESS_${eventSuffix}`;

  registerEventHandler(
    UPDATE_ITEMS_TABLE_DATA,
    ({ select, environment: { apiUrl } }, context) => {
      const { collectionName } = context;
      const { currentPage, itemsPerPage } = select.pagination(context);
      const tableFilters = select.filters(context);
      const { field, direction } = select.sorting(context);
      const apiFilters = mapTableFiltersToApiFilters(tableFilters);

      const query = queryString.stringify(
        {
          _page: currentPage,
          _limit: itemsPerPage,
          _sort: field,
          _order: direction,
          ...apiFilters,
        },
        { skipNull: true }
      );

      return {
        ...table.setBulkProcessing(collectionName, {
          isLoading: true,
          isProcessing: false,
        }),
        ...http.get({
          url: `${apiUrl}/backoffice/agencies${query && `?${query}`}`,
          successEvent: [UPDATE_ITEMS_TABLE_DATA_SUCCESS, context],
        }),
      };
    },
    [table.select(), environment()]
  );

  registerEventHandler(
    UPDATE_ITEMS_TABLE_DATA_SUCCESS,
    (_, [response, context]) => {
      const { rows, totalRows, totalFilteredRows } = response.data;
      const visibleItemsIdList = rows.map(({ id }) => id);

      return table.update(context.collectionName, {
        collection: rows,
        totalItems: totalFilteredRows,
        isEmpty: totalRows === 0,
        visibleItemsIdList,
      });
    }
  );

  function registerEvents() {
    return {
      collection: UPDATE_ITEMS_TABLE_DATA,
      events: {
        UPDATE_ITEMS_TABLE_DATA,
        UPDATE_ITEMS_TABLE_DATA_SUCCESS,
      },
    };
  }

  return {
    registerEvents,
  };
}
