import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { environment } from '../../../../../../coeffects/environment';

const MINIMUM_AUTOCOMPLETE_CHARS = 3;
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const VAT_NUMBER_LENGTHS = {
  CO: {
    juridic: { min: 7, max: 9 },
    natural: { min: 5, max: 12 },
  },
  EC: {
    juridic: { min: 13, max: 13 },
    natural: { min: 10, max: 10 },
  },
  PE: {
    juridic: { min: 11, max: 11 },
    natural: { min: 8 },
  },
  MX: {
    juridic: { min: 12, max: 12 },
    natural: { min: 13, max: 13 },
  },
};

const POSTAL_CODE_LENGTHS = {
  MX: 5,
};

const NUMERIC_REGEX = /\D/g;
const ALPHANUMERIC_REGEX = /[^a-zA-Z0-9_&]/g;

export const BILLING_ENTITIES_REQUESTED = 'BILLING_ENTITIES_REQUESTED';
export const BILLING_ENTITIES_REQUEST_SUCCEEDED =
  'BILLING_ENTITIES_REQUEST_SUCCEEDED';
export const BILLING_ENTITY_SUGGESTER_TEXT_INTRODUCED =
  'BILLING_ENTITY_SUGGESTER_TEXT_INTRODUCED';

registerEventHandler(
  BILLING_ENTITIES_REQUESTED,
  ({ environment: { apiUrl } }) =>
    http.get({
      url: `${apiUrl}/backoffice/billing-entities`,
      successEvent: BILLING_ENTITIES_REQUEST_SUCCEEDED,
    }),
  [environment()]
);

registerEventHandler(
  BILLING_ENTITIES_REQUEST_SUCCEEDED,
  (_, [{ data: billingEntities }]) => {
    console.groupCollapsed('BILLING ENTITIES WITH ERRORS');
    const stateSet = state.set({
      'admin:billingEntities': billingEntities.reduce(
        (accum, billingEntity) =>
          billingEntityIsValid(billingEntity)
            ? [...accum, billingEntity]
            : accum,
        []
      ),
    });
    console.groupEnd();
    return stateSet;
  }
);

function billingEntityIsValid(billingEntity) {
  const validators = {
    CO: billingEntityIsValidForColombia,
    EC: billingEntityIsValidForEcuador,
    MX: billingEntityIsValidForMexico,
    PE: billingEntityIsValidForPeru,
    CL: billingEntityIsValidForChile,
  };
  const validator = validators[billingEntity.countryCode] ?? (() => true);
  return validator(billingEntity);
}

function billingEntityIsValidForColombia(billingEntity) {
  return (
    mandatoryFieldsAreNotNull(billingEntity) &&
    emailIsValid(billingEntity) &&
    vatNumberIsValid(billingEntity, NUMERIC_REGEX)
  );
}

function vatNumberIsValidForCL(billingEntity) {
  const regex = new RegExp('^(\\d{1,3}(?:\\.\\d{1,3}){2}-[\\dkK])$');
  return regex.test(billingEntity.vatNumber);
}

function billingEntityIsValidForEcuador(billingEntity) {
  return (
    mandatoryFieldsAreNotNull(billingEntity) &&
    emailIsValid(billingEntity) &&
    vatNumberIsValid(billingEntity, NUMERIC_REGEX) &&
    phoneNumberIsValid(billingEntity)
  );
}

function billingEntityIsValidForPeru(billingEntity) {
  return (
    mandatoryFieldsAreNotNull(billingEntity) &&
    emailIsValid(billingEntity) &&
    hasProvince(billingEntity) &&
    vatNumberIsValid(billingEntity, NUMERIC_REGEX)
  );
}

function billingEntityIsValidForChile(billingEntity) {
  return (
    mandatoryFieldsAreNotNull(billingEntity) &&
    emailIsValid(billingEntity) &&
    hasProvince(billingEntity) &&
    vatNumberIsValidForCL(billingEntity)
  );
}

function billingEntityIsValidForMexico(billingEntity) {
  return (
    mandatoryFieldsAreNotNull(billingEntity) &&
    emailIsValid(billingEntity) &&
    hasProvince(billingEntity) &&
    vatNumberIsValid(billingEntity, ALPHANUMERIC_REGEX) &&
    postalCodeIsValid(billingEntity)
  );
}

function mandatoryFieldsAreNotNull(billingEntity) {
  const valid =
    billingEntity.name !== null &&
    billingEntity.address !== null &&
    billingEntity.taxPayerType !== null &&
    billingEntity.city !== null;
  if (!valid) {
    console.error(`BE ${billingEntity.id} is missing mandatory field`);
  }
  return valid;
}

function vatNumberIsValid(billingEntity, regex) {
  const { countryCode, taxPayerType } = billingEntity;
  if (!VAT_NUMBER_LENGTHS[countryCode][taxPayerType]) {
    console.error(`BE ${billingEntity.id} has invalid vat number`);
    return false;
  }
  const { min, max } = VAT_NUMBER_LENGTHS[countryCode][taxPayerType];
  const vatNumber = billingEntity.vatNumber.replace(regex, '');

  const valid =
    (!min || vatNumber.length >= min) && (!max || vatNumber.length <= max);
  if (!valid) {
    console.warn(`BE ${billingEntity.id} has invalid vat number`);
  }

  return valid;
}

function postalCodeIsValid(billingEntity, regex) {
  const { countryCode } = billingEntity;

  const valid =
    billingEntity.postalCode.replace(regex, '').length ===
    POSTAL_CODE_LENGTHS[countryCode];
  if (!valid) {
    console.warn(`BE ${billingEntity.id} has invalid postal code`);
  }
  return valid;
}

function emailIsValid(billingEntity) {
  if (billingEntity.email === null) {
    console.warn(`BE ${billingEntity.id} has invalid email`);
    return false;
  }

  const valid = !!billingEntity.email.toLowerCase().trim().match(EMAIL_REGEX);
  if (!valid) {
    console.warn(`BE ${billingEntity.id} has invalid email`);
  }
  return valid;
}

function phoneNumberIsValid(billingEntity) {
  const valid = /\d/.test(billingEntity.phone);
  if (!valid) {
    console.warn(`BE ${billingEntity.id} has invalid phone`);
  }

  return valid;
}

function hasProvince(billingEntity) {
  const valid = billingEntity.province !== null;
  if (!valid) {
    console.warn(`BE ${billingEntity.id} has invalid province`);
  }
  return valid;
}

registerEventHandler(
  BILLING_ENTITY_SUGGESTER_TEXT_INTRODUCED,
  ({ state: { billingEntities } }, searchQuery) => {
    if (
      !searchQuery ||
      searchQuery.length < MINIMUM_AUTOCOMPLETE_CHARS ||
      !billingEntities
    ) {
      return state.set({
        'admin:displayableBillingEntityText': searchQuery,
      });
    }
    const filteredBillingEntities = billingEntities.filter(
      (billingEntity) =>
        billingEntity.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        billingEntity.vatNumber
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    );

    return state.set({
      'admin:suggestedBillingEntities': filteredBillingEntities,
      'admin:displayableBillingEntityText': searchQuery,
    });
  },
  [state.get({ billingEntities: 'admin:billingEntities' })]
);
