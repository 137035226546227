import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

export const ENQUIRY_DETAIL_EDIT_NOTE_DIALOG =
  'ENQUIRY_DETAIL_EDIT_NOTE_DIALOG_V2';
export const ENQUIRY_DETAIL_DELETE_NOTE_DIALOG =
  'ENQUIRY_DETAIL_DELETE_NOTE_DIALOG_V2';
export const ENQUIRY_DETAIL_SEND_REPLY_EMAIL_ERROR_DIALOG =
  'ENQUIRY_DETAIL_SEND_REPLY_EMAIL_ERROR_DIALOG';
export const NOTE_MAX_CHARACTERS_LENGTH = 1000;
export const REPLY_EMAIL_MAX_CHARACTERS_LENGTH = 2000;
export const NOTE_MESSAGE_FIELD_NAME = 'message';
export const REPLY_EMAIL_MESSAGE_FIELD_NAME = 'message';

export const LEAD_STATUS_TO_TEXT_MAP = {
  'new lead': <FormattedMessageWithValidation id="enqpage_table_status2" />,
  contacted: <FormattedMessageWithValidation id="enqpage_table_status1" />,
  visit: <FormattedMessageWithValidation id="status_enquiries_visit" />,
  proposal: <FormattedMessageWithValidation id="status_enquiries_proposal" />,
  won: <FormattedMessageWithValidation id="status_enquiries_won" />,
  lost: <FormattedMessageWithValidation id="status_enquiries_lost" />,
};
