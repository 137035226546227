export const LEAD_STATUS = {
  NEW: 'new lead',
  CONTACTED: 'contacted',
  LOST: 'lost',
  PROPOSAL: 'proposal',
  VISIT: 'visit',
  WON: 'won',
};

export const leadStatusTranslationTags = {
  [LEAD_STATUS.NEW]: 'enqpage_table_status2',
  [LEAD_STATUS.LOST]: 'status_enquiries_lost',
  [LEAD_STATUS.CONTACTED]: 'enqpage_table_status1',
  [LEAD_STATUS.WON]: 'status_enquiries_won',
  [LEAD_STATUS.VISIT]: 'status_enquiries_visit',
  [LEAD_STATUS.PROPOSAL]: 'status_enquiries_proposal',
};
