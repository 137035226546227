import { Body1 } from 'design-system/components/Typography/presets/Body1';
import styled from 'styled-components';
import Table from 'design-system/components/Table';
import Tooltip from 'design-system/components/Tooltip';
import { useColumn } from '../../hooks/useColumn';
import FormattedDateTime from '../../../FormattedDate/FormattedDateTime';
import FormattedRelativeDate from '../../../FormattedDate/FormattedRelativeDate';

const Wrapper = styled(Table.Cell)`
  ${Body1}:first-letter {
    text-transform: capitalize;
  }
`;

export function DateRelativeColumn(props) {
  const { value: date } = useColumn(props);

  return (
    <Wrapper>
      <Tooltip
        title={
          <FormattedDateTime value={date} dateStyle="full" timeStyle="short" />
        }
      >
        <Body1 $noWrap>
          <FormattedRelativeDate date={new Date(date)} now={new Date()} />
        </Body1>
      </Tooltip>
    </Wrapper>
  );
}
