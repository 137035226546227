import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Table, { TABLE_ALIGNMENT } from 'design-system/components/Table';
import SkeletonBone from 'design-system/components/SkeletonBone';
import PropertySnippet from '../../../../partials/PropertySnippet';

const PriceCell = styled(Table.Cell)`
  ${SkeletonBone} {
    ${spacing.inline(SPACING_SIZE.L)}
  }
`;

export function TableSkeleton() {
  return (
    <Table.Skeleton
      rows={2}
      columns={[
        {
          id: 'snippet',
          content: <SkeletonBone $width="88px" $height="16px" />,
        },
        {
          id: 'billingCycleInMonths',
          content: <SkeletonBone $width="88px" $height="16px" />,
        },
        {
          id: 'billingDate',
          content: <SkeletonBone $width="64px" $height="16px" />,
        },
        {
          id: 'price',
          content: <SkeletonBone $width="53px" $height="16px" />,
        },
        {
          id: 'details',
          content: null,
          alignment: TABLE_ALIGNMENT.RIGHT,
        },
      ]}
    >
      <Table.Row>
        <Table.Cell>
          <PropertySnippet.Skeleton />
        </Table.Cell>
        <Table.Cell>
          <SkeletonBone $width="60px" $height="14px" randomWidth />
        </Table.Cell>
        <Table.Cell>
          <SkeletonBone $width="60px" $height="14px" randomWidth />
        </Table.Cell>
        <PriceCell>
          <SkeletonBone $width="60px" $height="14px" randomWidth />
          <SkeletonBone $width="40px" $height="14px" randomWidth />
        </PriceCell>
        <Table.Cell>
          <SkeletonBone $width="60px" $height="14px" randomWidth />
        </Table.Cell>
      </Table.Row>
    </Table.Skeleton>
  );
}
