import { RawIntlProvider } from 'react-intl';
import { useSelector } from 'reffects-store';
import { LoadScript } from '@react-google-maps/api';

import { GlobalStyles, IconsSprite } from 'design-system/styles/globals';
import { dispatch } from 'reffects';
import { useMount } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { isCountryConfigLoadedSelector } from './selectors';
import createIntl from './intl';
import registerTranslationsCoeffectHandler from '../coeffects/translations';
import createTranslate from '../infrastructure/translate';
import Modals from '../partials/modals/Modals';
import Toast from '../partials/Toast/Toast';

import { GLOBAL_VALUES_LOADED } from './events';
import AppRoutes from './router/Routes';

import 'normalize.css/normalize.css';
import './fonts.css';
import './base.css';
import { localeSelector } from '../selectors/user';
import registerRouteEffects from '../effects/routing';

function createInternationalization(locale, translations) {
  const onError = (e) => {
    // eslint-disable-next-line no-console
    console.log(
      `%c ${e.code}: ${e.descriptor?.id} `,
      'background: #fffbe5; color: #65470e'
    );
  };
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  return createIntl(locale, translations, timeZone, onError);
}

const libraries = ['geometry'];

function App() {
  const navigate = useNavigate();
  const locale = useSelector(localeSelector);
  const translations = useSelector((state) => state.translations);
  const isConfigLoaded = useSelector(isCountryConfigLoadedSelector);
  const intl = createInternationalization(locale, translations);

  useMount(() => {
    dispatch(GLOBAL_VALUES_LOADED);
    registerTranslationsCoeffectHandler(createTranslate(intl));
    registerRouteEffects(navigate);
  });
  return (
    <LoadScript
      googleMapsApiKey={process.env.GOOGLE_MAPS_API_KEY}
      loadingElement={<div />}
      libraries={libraries}
    >
      <GlobalStyles />
      <IconsSprite />
      <RawIntlProvider value={intl}>
        <AppRoutes />
        {isConfigLoaded && (
          <>
            <Modals />
            <Toast />
          </>
        )}
      </RawIntlProvider>
    </LoadScript>
  );
}

export default App;
