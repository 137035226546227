import { publishersSelector } from '../../../../../selectors';

export function availablePublisherSelector(state, publisherIdToExclude) {
  return publishersSelector(state).filter(
    ({ id }) => id !== publisherIdToExclude
  );
}

export function associateToPublisherIdSelector(state) {
  return state?.associatePublisherModal?.selectedPublisherId;
}

export function enableAssociateButtonSelector(state) {
  return associateToPublisherIdSelector(state) != null;
}
