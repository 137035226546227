import { PhoneNumberUtil } from 'google-libphonenumber';

export function extractCountryAndNumber(rawPhoneNumber, defaultCountryCode) {
  if (!rawPhoneNumber) {
    return {
      countryCode: defaultCountryCode ?? undefined,
      phoneNumber: undefined,
    };
  }

  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const number = phoneUtil.parseAndKeepRawInput(rawPhoneNumber);
    const countryCode = phoneUtil.getRegionCodeForNumber(number);

    return {
      countryCode,
      phoneNumber: getRawNumber(number),
    };
  } catch (e) {
    return {
      countryCode: defaultCountryCode ?? undefined,
      phoneNumber: rawPhoneNumber,
    };
  }
}

function getRawNumber(number) {
  return number.getRawInput().replace(`+${number.getCountryCode()}`, '');
}
