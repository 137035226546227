import { createSelector } from 'reselect';
import { countryConfigSelector } from '../../../../../selectors/config';
import {
  publisherPublishOnThailandPropertySelector,
  publisherSubscriptionPlanIsFreeSelector,
} from '../../../../../selectors/publisher';

const projectIdParameter = (_, { projectId }) => projectId;

export const projectLinksSelector = createSelector(
  countryConfigSelector,
  projectIdParameter,
  publisherSubscriptionPlanIsFreeSelector,
  publisherPublishOnThailandPropertySelector,
  (countryConfig, projectId, publisherIsFree, publishesInThailandProperty) => {
    const sitesToPublish = countryConfig.sitesToPublish ?? [];

    const sites = sitesToPublish
      .filter(({ projectDetailPage }) => projectDetailPage)
      .map(({ site, projectDetailPage }) => ({
        site,
        link: `${projectDetailPage}${projectId}`,
      }));

    if (publisherIsFree || !publishesInThailandProperty) {
      return sites.filter(({ site }) => site !== 'ThailandProperty');
    }

    return sites;
  }
);
