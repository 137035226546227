import Icon from 'design-system/components/Icon';
import styled from 'styled-components';
import { FlatButton } from 'design-system/components/Button/presets';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';

const Wrapper = styled.div`
  position: relative;
  ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
  display: inline-block;

  img {
    height: 7.5rem;
    width: 7.5rem;
    object-fit: cover;
    border-radius: 8px;
  }

  ${FlatButton} {
    position: absolute;
    top: ${spacing.value(SPACING_SIZE.S)};
    right: ${spacing.value(SPACING_SIZE.S)};
    left: auto;
    cursor: pointer;
    display: none;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;

    ${Icon} {
      ${color.text(COLOR_PALETTE.PRIMARY_BASE)}
    }

    &:focus {
      outline: none;
    }
  }

  &:hover ${FlatButton} {
    display: flex;
  }
`;

export default function ImagePreview({ image, onDelete }) {
  const src = isAFile(image) ? URL.createObjectURL(image) : image;

  return (
    <Wrapper>
      <img src={src} aria-hidden alt="property image preview" />
      <FlatButton
        icon={<Icon glyph="trash" $color={COLOR_PALETTE.PRIMARY_BASE} />}
        onClick={onDelete}
      />
    </Wrapper>
  );
}

function isAFile(image) {
  return image instanceof Blob || image instanceof File;
}
