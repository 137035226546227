import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import InputText from 'design-system/components/InputText';
import { FlatButton } from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';
import { crmDealUrl } from '../../utils/crmDealUrl';

const DealOwnerWrapper = styled.div`
  display: flex;
  ${InputText} {
    flex: 1;
    ${spacing.inline(SPACING_SIZE.S)}
  }
  align-items: center;
`;

function DealOwner({ salesPersonEmail, deal }) {
  return (
    <DealOwnerWrapper>
      <InputText
        label="Deal Owner"
        name="dealOwner"
        value={salesPersonEmail}
        type="email"
        placeholder="Select a Hubspot Deal name to view his Deal owner"
        disabled
        required
      />
      <FlatButton
        disabled={!deal}
        forwardedAs="a"
        href={crmDealUrl(deal?.id)}
        icon={<Icon glyph="launch" />}
        target="_blank"
      />
    </DealOwnerWrapper>
  );
}

export default DealOwner;
