import styled from 'styled-components';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import { useMount } from 'react-use';
import { useParams } from 'react-router-dom';
import LogoDotPropertyGroup from 'design-system/components/LogoDotPropertyGroup';
import Logo from 'design-system/components/Logo/Logo';
import Illustration from 'design-system/components/Illustration/Illustration';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import { Subtitle1 } from 'design-system/components/Typography/presets/Subtitle1';
import {
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { userActivationDeeplinkSelector } from './selectors';
import { SIGNUP_ACTIVATION_REQUESTED } from './events';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Wrapper = styled.div`
  ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
  ${spacing.inset(SPACING_SIZE.XXXL)}
  display: flex;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
`;

const Content = styled.div`
  ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
  width: 600px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${spacing.inset(SPACING_SIZE.XL)}
  ${Logo}, ${LogoDotPropertyGroup} {
    display: flex;
    margin: 0 auto;
    ${spacing.stack(SPACING_SIZE.XL, false)}
  }
  ${Illustration} {
    height: 48px;
    ${spacing.stack(SPACING_SIZE.XL)}
  }

  ${Heading1} {
    display: block;
    text-align: center;
    ${spacing.stack(SPACING_SIZE.M, false)}
  }
  ${Subtitle1} {
    ${typography.size(FONT_SIZE.M)};
    ${typography.weight(FONT_WEIGHT.MEDIUM)};
    display: block;
    text-align: center;
    ${spacing.stack(SPACING_SIZE.M, false)}
  }
`;
export function MobileSignUpActivation() {
  const { userId } = useParams();
  useMount(() =>
    dispatch({ id: SIGNUP_ACTIVATION_REQUESTED, payload: { userId } })
  );
  const deepLink = useSelector(userActivationDeeplinkSelector);

  return (
    <Wrapper>
      <Content>
        <Heading1>
          <FormattedMessageWithValidation id="mobile_signup_activation_success_title" />
        </Heading1>
        <Subtitle1>
          <FormattedMessageWithValidation
            id="mobile_signup_activation_success_body"
            values={{
              click_here: (
                <a href={deepLink}>
                  <FormattedMessageWithValidation id="click_here" />
                </a>
              ),
            }}
          />
        </Subtitle1>
      </Content>
    </Wrapper>
  );
}
