import { numberWithoutCallingCodeSelector } from '../../../selectors/country';
import { calculatePagination } from '../../Tables/updateTable';

export function contactEditModalIsOpenSelector(state) {
  return state['companyProfile:contactEditModal']?.open ?? false;
}

export function contactEditModalContactTypeSelector(state) {
  return state['companyProfile:contactEditModal']?.type ?? null;
}

export function contactEditModalNewContactValueSelector(state) {
  const detailType = contactEditModalContactTypeSelector(state);
  const value = state['companyProfile:contactEditModal']?.newValue ?? null;

  if (detailType === 'whatsapp') {
    return value ? numberWithoutCallingCodeSelector(state, value) : '';
  }

  return value;
}

export function contactEditModalLoadingSelector(state) {
  return state['companyProfile:contactEditModal']?.loading ?? false;
}

export function contactEditModalValidationErrorSelector(state) {
  return state['companyProfile:contactEditModal']?.errorTag ?? null;
}

export function availableContactTypesSelector(state) {
  const contacts = state['companyProfile:contacts'] ?? {};
  return Object.keys(contacts).filter(
    (contactType) => contacts[contactType].data?.length > 0
  );
}

export function contactDetailsTableSelector(state, contactType) {
  const table = state['companyProfile:contacts'][contactType] ?? {};

  return {
    ...table,
    pagination: {
      ...table.pagination,
      ...calculatePagination(table.pagination),
    },
  };
}
