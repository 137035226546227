import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { environment } from '../../../coeffects/environment';

export const SIGNUP_ACTIVATION_REQUESTED = 'SIGNUP_ACTIVATION_REQUESTED';
export const SIGNUP_ACTIVATION_SUCCESS = 'SIGNUP_ACTIVATION_SUCCESS';

registerEventHandler(
  SIGNUP_ACTIVATION_REQUESTED,
  ({ environment: { apiUrl } }, { userId }) =>
    http.post({
      url: `${apiUrl}/mobile/user-activation/${userId}`,
      successEvent: SIGNUP_ACTIVATION_SUCCESS,
    }),
  [environment()]
);

registerEventHandler(SIGNUP_ACTIVATION_SUCCESS, (_, [{ data }]) =>
  state.set({
    'mobile.userSignupActivation.linkContinue': data.link,
  })
);
