import styled from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';

const InformationBlock = styled.div`
  ${color.background(COLOR_PALETTE.PUMPKIN_A10)}
  ${spacing.inset(SPACING_SIZE.S)}
  ${radius.regular(RADIUS_SIZE.BASE)}
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default styled(InformationBlock)``;
