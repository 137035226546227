import Checkbox from 'design-system/components/Checkbox/Checkbox';
import { useColumnSelection } from '../hooks/useColumnSelection';

export function BulkCheckbox() {
  const { areAllSelected, areSomeSelected, toggleAllItemsSelection } =
    useColumnSelection();

  return (
    <Checkbox
      checked={areAllSelected}
      indeterminate={areSomeSelected}
      onChange={toggleAllItemsSelection}
    />
  );
}
