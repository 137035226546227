import * as React from "react";
const SvgMapMarker = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={44}
    height={65}
    fill="none"
    {...props}
  >
    <path
      fill="#111B3C"
      fillRule="evenodd"
      d="M19.656 53.02a2.943 2.943 0 0 0 4.11 0Q43.672 33.568 43.672 22c0-12.15-9.832-22-21.96-22S-.248 9.85-.248 22q0 11.568 19.904 31.02"
      clipRule="evenodd"
    />
    <ellipse cx={21.959} cy={61} fill="#111B3C" rx={3.993} ry={4} />
    <path
      fill="#fff"
      d="m22.16 9.778 11.201 7.91v14.09H17.744l4.347-4.493h6.739v-7.267l-6.707-4.682-6.902 4.682v11.744h-4.49V17.688z"
    />
  </svg>
);
export default SvgMapMarker;
