import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import { dispatch, dispatchMany } from 'reffects';
import Dialog, {
  FOOTER_CONTENT_POSITION,
} from 'design-system/components/Dialog/Dialog';
import InputText from 'design-system/components/InputText';
import { OutlineButton } from 'design-system/components/Button/presets';
import RequestSubscriptionChangeDetails from '../../../../../partials/RequestSubscriptionChangeDetails/RequestSubscriptionChangeDetails';
import { useDialog } from '../../../../../../../hooks/useDialog';
import { SUBSCRIPTION_CHANGE_REQUEST_MODAL_ID } from '../constants';
import {
  cancelReasonSelector,
  disableSCRModalButtonsSelector,
  isCancelReasonFilledSelector,
  isReasonFilledSelector,
  markAsPaidButtonTextSelector,
  markAsPaidReasonSelector,
  modalTitleSelector,
  showCancelButtonSelector,
  showCancelReasonSelector,
  showMarkAsPaidButtonSelector,
  showMarkAsPaidReasonSelector,
} from './selectors';
import { subscriptionChangeRequestDetailsSelector } from '../../../../../partials/RequestSubscriptionChangeDetails/selectors';
import {
  REQUEST_SUBSCRIPTION_CHANGE_REQUEST_CANCELLATION,
  SUBSCRIPTION_CHANGE_REQUEST_MARKED_AS_PAID,
  SUBSCRIPTION_CHANGE_REQUEST_SUMMARY_CANCEL_REASON_CHANGE,
  SUBSCRIPTION_CHANGE_REQUEST_SUMMARY_DISABLE_BUTTONS,
  SUBSCRIPTION_CHANGE_REQUEST_SUMMARY_REASON_CHANGE,
} from './events';
import { removeEmojis } from '../../../../../../utils/removeEmojis';

const ButtonWithSpacing = styled(OutlineButton)`
  ${spacing.inline(SPACING_SIZE.M)}
`;

function SubscriptionChangeRequestSummaryModal({ onSuccessEvent }) {
  const { open, closeDialog } = useDialog(SUBSCRIPTION_CHANGE_REQUEST_MODAL_ID);

  const title = useSelector(modalTitleSelector);
  const requestDetails = useSelector(subscriptionChangeRequestDetailsSelector);
  const canMarkRequestAsPaid = useSelector(showMarkAsPaidButtonSelector);
  const modifyScrText = useSelector(markAsPaidButtonTextSelector);
  const showCancelButton = useSelector(showCancelButtonSelector);
  const disableButtons = useSelector(disableSCRModalButtonsSelector);
  const showMarkAsPaidReason = useSelector(showMarkAsPaidReasonSelector);
  const markAsPaidReason = useSelector(markAsPaidReasonSelector);
  const isMarkAsPaidReasonFilled = useSelector(isReasonFilledSelector);
  const showCancelReason = useSelector(showCancelReasonSelector);
  const cancelReason = useSelector(cancelReasonSelector);
  const isCancelReasonFilled = useSelector(isCancelReasonFilledSelector);

  const handleClose = () => {
    dispatchMany([
      {
        id: SUBSCRIPTION_CHANGE_REQUEST_SUMMARY_DISABLE_BUTTONS,
        payload: false,
      },
      {
        id: SUBSCRIPTION_CHANGE_REQUEST_SUMMARY_REASON_CHANGE,
        payload: undefined,
      },
    ]);
    closeDialog();
  };

  if (!open) {
    return <></>;
  }

  return (
    <Dialog open={open}>
      <Dialog.Header onClose={handleClose}>{title}</Dialog.Header>
      <Dialog.Content>
        <RequestSubscriptionChangeDetails />
      </Dialog.Content>
      <Dialog.Footer alignment={FOOTER_CONTENT_POSITION.START}>
        {showMarkAsPaidReason && (
          <InputText
            label={`${modifyScrText} reason`}
            required
            placeholder="Type the reason why..."
            value={markAsPaidReason}
            onChange={(event) =>
              dispatch({
                id: SUBSCRIPTION_CHANGE_REQUEST_SUMMARY_REASON_CHANGE,
                payload: removeEmojis(event.target.value),
              })
            }
          />
        )}
        {canMarkRequestAsPaid && (
          <ButtonWithSpacing
            disabled={disableButtons || !isMarkAsPaidReasonFilled}
            onClick={() =>
              dispatch({
                id: SUBSCRIPTION_CHANGE_REQUEST_MARKED_AS_PAID,
                payload: {
                  subscriptionChangeRequestId: requestDetails.id,
                  onSuccessEvent,
                },
              })
            }
          >
            {modifyScrText}
          </ButtonWithSpacing>
        )}
        {showCancelReason && (
          <InputText
            label="Cancel reason"
            required
            placeholder="Type the reason why..."
            value={cancelReason}
            onChange={(event) =>
              dispatch({
                id: SUBSCRIPTION_CHANGE_REQUEST_SUMMARY_CANCEL_REASON_CHANGE,
                payload: removeEmojis(event.target.value),
              })
            }
          />
        )}
        {showCancelButton && (
          <ButtonWithSpacing
            disabled={
              disableButtons || (showCancelReason && !isCancelReasonFilled)
            }
            onClick={() =>
              dispatch({
                id: REQUEST_SUBSCRIPTION_CHANGE_REQUEST_CANCELLATION,
                payload: {
                  subscriptionChangeRequestId: requestDetails.id,
                  onSuccessEvent,
                },
              })
            }
          >
            Cancel request
          </ButtonWithSpacing>
        )}
      </Dialog.Footer>
    </Dialog>
  );
}

export default SubscriptionChangeRequestSummaryModal;
