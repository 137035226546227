import styled from 'styled-components';
import BreakdownTable from 'design-system/components/BreakdownTable/BreakdownTable';
import Tip from 'design-system/components/Tip';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Typography, {
  TYPOGRAPHY_VARIANT,
} from 'design-system/components/Typography/Typography';
import { useSelector } from 'reffects-store';
import { useEffect } from 'react';
import { dispatch } from 'reffects';
import useInternationalization from '../../../../hooks/useInternationalization';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import Price from '../../../../partials/Price/Price';
import {
  CURRENT_SUBSCRIPTION_DISCOUNT_NAME,
  CUSTOM_DISCOUNT_NAME,
  PREVIOUS_SUBSCRIPTION_DISCOUNT_NAME,
  TAX_DISCOUNT,
  UPFRONT_DISCOUNT_NAME,
} from '../../../../constants/subscription';
import {
  checkoutPublisherCountry,
  isEligibleForRoundAllPricesSelector,
} from './selectors';
import { COUNTRY_LOCALE } from '../../../../constants/country';
import { FORCE_LOCALE } from '../../../../App/events';

const Wrapper = styled(Tip)`
  ${spacing.stack(SPACING_SIZE.M)};
`;

const Heading = styled(Typography)`
  display: block;
  margin-bottom: ${SPACING_SIZE.M}px;

  ${Typography.mods.variant(TYPOGRAPHY_VARIANT.OVERLINE)}
`;

const DiscountRow = styled(BreakdownTable.DiscountRow)`
  td:first-child {
    display: flex;
    align-items: center;
  }
`;

function findDiscount(discountName, discounts) {
  return discounts.find(({ name }) => name === discountName);
}

export default function CheckoutSummary({
  proppit,
  wasi,
  totalBasePriceWithDiscounts,
  taxApplied,
  totalPrice: finalPrice,
}) {
  const intl = useInternationalization();
  const { unusedPreviousSubscriptionDays, unusedCurrentSubscriptionDays } =
    proppit.products[0];

  const checkoutCountry = useSelector(checkoutPublisherCountry);
  useEffect(() => {
    if (
      checkoutCountry &&
      COUNTRY_LOCALE[checkoutCountry] &&
      !localStorage.getItem('localeForced')
    ) {
      dispatch({
        id: FORCE_LOCALE,
        payload: {
          locale: COUNTRY_LOCALE[checkoutCountry],
        },
      });
      localStorage.setItem('localeForced', 'true');
    }
  }, [checkoutCountry]);

  const needRound = useSelector(isEligibleForRoundAllPricesSelector);

  useEffect(() => {
    localStorage.removeItem('localeForced');
  }, []);

  return (
    <Wrapper>
      <Heading>
        <FormattedMessageWithValidation id="checkout_summary_header" />
      </Heading>
      <BreakdownTable>
        {proppit.products.map(
          (
            {
              boostableProperties,
              nameTag,
              discounts: productDiscounts,
              totalPrice,
              billingDetail: {
                proportionalPriceForRemainingDays,
                unusedPreviousSubscriptionDays: remainingDays,
              } = {},
            },
            index
          ) => (
            <>
              {!proportionalPriceForRemainingDays && (
                <BreakdownTable.Row
                  title={`${intl.formatMessageWithValidation({
                    id: nameTag,
                  })} ${boostableProperties} ${intl.formatMessageWithValidation(
                    {
                      id: 'subscription_superboosted_properties',
                    }
                  )}`}
                  price={
                    <Price
                      amount={roundIfEligible(totalPrice.amount, needRound)}
                      currency={totalPrice.currency}
                    />
                  }
                  key="product-name"
                />
              )}
              {proportionalPriceForRemainingDays && (
                <BreakdownTable.Row
                  title={
                    <FormattedMessageWithValidation
                      id="checkout_summary_proportional_price_for_remaining_days"
                      values={{
                        boostableProperties,
                        days: remainingDays,
                      }}
                    />
                  }
                  price={
                    <Price
                      currency={proportionalPriceForRemainingDays.currency}
                      amount={roundIfEligible(
                        proportionalPriceForRemainingDays.amount,
                        needRound
                      )}
                    />
                  }
                  key="proportional-price-for-remaining-days"
                />
              )}
              <Discount
                needRound={needRound}
                discount={findDiscount(CUSTOM_DISCOUNT_NAME, productDiscounts)}
                title={
                  <FormattedMessageWithValidation id="checkout_summary_discount_custom_v2" />
                }
                key={index}
              />
            </>
          )
        )}

        <BreakdownTable.Row
          title={intl.formatMessageWithValidation({
            id: 'checkout_summary_plan_duration_label',
          })}
          price={intl.formatMessageWithValidation({
            id:
              Number(proppit.billingCycleInMonths) === 1
                ? 'mail_plan_renewed_month'
                : 'mail_plan_renewed_months',
            values: { planDuration: proppit.billingCycleInMonths },
          })}
        />

        {proppit.discounts && (
          <>
            <Discount
              needRound={needRound}
              discount={findDiscount(UPFRONT_DISCOUNT_NAME, proppit.discounts)}
              title={
                <FormattedMessageWithValidation id="checkout_summary_discount_duration_v2" />
              }
            />
            <Discount
              needRound={needRound}
              discount={findDiscount(
                PREVIOUS_SUBSCRIPTION_DISCOUNT_NAME,
                proppit.discounts
              )}
              title={intl.formatMessageWithValidation({
                id: 'checkout_upsell_discount_unused_days',
                values: {
                  currentSubscriptionRemainingDays:
                    unusedPreviousSubscriptionDays,
                },
              })}
            />
            <Discount
              needRound={needRound}
              discount={findDiscount(
                CURRENT_SUBSCRIPTION_DISCOUNT_NAME,
                proppit.discounts
              )}
              title={intl.formatMessageWithValidation({
                id: 'checkout_upsell_discount_remaining_days',
                values: {
                  newSubscriptionRemainingDays: unusedCurrentSubscriptionDays,
                },
              })}
            />
          </>
        )}
        {wasi && (
          <>
            <BreakdownTable.Row
              title={
                <FormattedMessageWithValidation id="checkout_summary_wasi_crm" />
              }
              price={
                <Price
                  currency={wasi.planBillingDetail.basePrice.currency}
                  amount={wasi.planBillingDetail.basePrice.amount}
                />
              }
            />
            <Discount
              needRound={needRound}
              discount={findDiscount(
                CUSTOM_DISCOUNT_NAME,
                wasi.planBillingDetail.discounts
              )}
              title={
                <FormattedMessageWithValidation id="checkout_summary_discount_custom_v2" />
              }
            />
            {wasi.extraUsers && (
              <>
                <BreakdownTable.Row
                  title={
                    <FormattedMessageWithValidation
                      id="checkout_summary_wasi_extra_users"
                      values={{ users: wasi.extraUsers }}
                    />
                  }
                  price={
                    <Price
                      currency={wasi.extraUsersBillingDetail.basePrice.currency}
                      amount={wasi.extraUsersBillingDetail.basePrice.amount}
                    />
                  }
                />
                <Discount
                  needRound={needRound}
                  discount={findDiscount(
                    CUSTOM_DISCOUNT_NAME,
                    wasi.extraUsersBillingDetail.discounts
                  )}
                  title={
                    <FormattedMessageWithValidation id="checkout_summary_discount_custom_v2" />
                  }
                />
              </>
            )}
            <BreakdownTable.Row
              title={
                <FormattedMessageWithValidation id="checkout_summary_wasi_billing_cycle" />
              }
              price={intl.formatMessageWithValidation({
                id:
                  Number(wasi.billingCycleInMonths) === 1
                    ? 'mail_plan_renewed_month'
                    : 'mail_plan_renewed_months',
                values: { planDuration: wasi.billingCycleInMonths },
              })}
            />
            <Discount
              needRound={needRound}
              discount={findDiscount(UPFRONT_DISCOUNT_NAME, wasi.discounts)}
              title={
                <FormattedMessageWithValidation id="checkout_summary_discount_duration_v2" />
              }
            />
          </>
        )}
        <BreakdownTable.Row
          title={
            <FormattedMessageWithValidation id="checkout_summary_subtotal" />
          }
          price={
            <Price
              currency={totalBasePriceWithDiscounts.currency}
              amount={roundIfEligible(
                totalBasePriceWithDiscounts.amount,
                needRound
              )}
            />
          }
        />
        {taxApplied.amount > 0 && (
          <BreakdownTable.Row
            title={`${taxApplied.name} ${taxApplied.percentage}%`}
            price={
              <Price
                currency={taxApplied.currency}
                amount={roundIfEligible(taxApplied.amount, needRound)}
              />
            }
          />
        )}
        <TaxDiscount
          taxDiscount={findDiscount(
            TAX_DISCOUNT.RETEFUENTE,
            proppit.taxDiscounts
          )}
          nameTag="checkout_summary_tax_retefuente_colombian_discount"
        />
        <TaxDiscount
          taxDiscount={findDiscount(
            TAX_DISCOUNT.RETE_IVA,
            proppit.taxDiscounts
          )}
          nameTag="checkout_summary_tax_reteiva_colombian_discount"
        />
        <TaxDiscount
          taxDiscount={findDiscount(
            TAX_DISCOUNT.RETE_ICA,
            proppit.taxDiscounts
          )}
          nameTag="checkout_summary_tax_reteica_colombian_discount"
        />
        <BreakdownTable.TotalRow
          title={intl.formatMessageWithValidation({
            id: 'checkout_summary_total',
          })}
          price={
            <Price amount={finalPrice.amount} currency={finalPrice.currency} />
          }
        />
      </BreakdownTable>
    </Wrapper>
  );
}

function Discount({ discount, title, needRound }) {
  if (!discount) {
    return null;
  }
  const discountsWithoutPercentage = [
    PREVIOUS_SUBSCRIPTION_DISCOUNT_NAME,
    CURRENT_SUBSCRIPTION_DISCOUNT_NAME,
  ];
  const roundedPercentage = roundIfEligible(discount.percentage, true);
  if (!roundedPercentage) {
    return null;
  }
  return (
    <DiscountRow
      title={title}
      price={
        <Price
          amount={roundIfEligible(discount.amount * -1, needRound)}
          currency={discount.currency}
        />
      }
      discount={
        !discountsWithoutPercentage.includes(discount.name) && (
          <FormattedMessageWithValidation
            id="subscription_summary_price_discount_v2"
            values={{
              percentage: roundedPercentage,
            }}
          />
        )
      }
    />
  );
}

function TaxDiscount({ taxDiscount, nameTag }) {
  if (!taxDiscount) {
    return null;
  }

  return (
    <BreakdownTable.Row
      price={
        <Price currency={taxDiscount.currency} amount={taxDiscount.amount} />
      }
      title={<FormattedMessageWithValidation id={nameTag} />}
    />
  );
}

function roundIfEligible(price, isEligible) {
  if (isEligible) {
    return Math.round(price);
  }
  return price;
}
