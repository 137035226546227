import { useSelector } from 'reffects-store';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { PrimaryButton } from 'design-system/components/Button/presets';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle2 } from 'design-system/components/Typography/presets/Subtitle2';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';
import { subscriptionRoute } from '../../../utils/proppitWebRouter';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { publisherSubscriptionPlanIsFreeSelector } from '../../../selectors/publisher';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
  ${radius.regular(RADIUS_SIZE.LARGE)}
  ${spacing.inset(SPACING_SIZE.M)}
  div {
    display: flex;
    align-items: center;
    flex: 1;
    ${spacing.inline(SPACING_SIZE.M)}

    ${Icon} {
      ${spacing.inline(SPACING_SIZE.S)}
    }

    ${Subtitle2} {
      ${typography.weight(FONT_WEIGHT.MEDIUM)};
    }
  }
`;

const CTAButton = styled(PrimaryButton)`
  font-weight: 500;
`;

export function UpgradeFreeSubscriptionCard() {
  const hasFreeSubscription = useSelector(
    publisherSubscriptionPlanIsFreeSelector
  );

  if (!hasFreeSubscription) return null;

  return (
    <Wrapper>
      <div>
        <Icon
          glyph="bolt"
          $color={COLOR_PALETTE.PRIMARY_BASE}
          $size={ICON_SIZE.BASE}
        />
        <Subtitle2>
          <FormattedMessageWithValidation id="overview_developer_projects_summary_upgrade_cta_text" />
        </Subtitle2>
      </div>
      <CTAButton forwardedAs={Link} to={subscriptionRoute()}>
        <FormattedMessageWithValidation id="overview_developer_projects_summary_upgrade_cta_button" />
      </CTAButton>
    </Wrapper>
  );
}
