import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { environment } from '../../../../../../../coeffects/environment';
import { toast } from '../../../../../../../effects/toast';
import { CLOSE_DIALOG } from '../../../../../../../events/dialogs';

export const WASI_API_PUBLISHER_MODAL = 'WASI_API_PUBLISHER_MODAL';
export const WASI_API_PUBLISHER_REQUESTED = 'WASI_API_PUBLISHER_REQUESTED';
export const WASI_API_PUBLISHER_LOADED = 'WASI_API_PUBLISHER_LOADED';
export const WASI_API_PUBLISHER_FORM_SUBMITTED =
  'WASI_API_PUBLISHER_FORM_SUBMITTED';
export const WASI_API_PUBLISHER_FORM_CHANGE_VALUE =
  'WASI_API_PUBLISHER_FORM_CHANGE_VALUE';
export const WASI_API_PUBLISHER_FORM_SUBMIT_FAILED =
  'WASI_API_PUBLISHER_FORM_SUBMIT_FAILED';
export const WASI_API_PUBLISHER_FORM_SUBMIT_SUCCESS =
  'WASI_API_PUBLISHER_FORM_SUBMIT_SUCCESS';

registerEventHandler(
  WASI_API_PUBLISHER_REQUESTED,
  ({ environment: { apiUrl } }, { publisherId }) =>
    http.get({
      url: `${apiUrl}/admin/wasi-api-publisher/${publisherId}`,
      successEvent: { id: WASI_API_PUBLISHER_LOADED, payload: publisherId },
    }),
  [environment()]
);

registerEventHandler(WASI_API_PUBLISHER_LOADED, (_, [{ data }, publisherId]) =>
  state.set({
    wasiApiPublisher: {
      publisherId: data.publisherId ?? publisherId,
      wasiToken: data.wasiToken,
      idCompany: data.idCompany,
      companyId: data.companyId,
      lastSynchronizeAmount: data.lastSynchronizeAmount,
      lastSynchronizeDate: data.lastSynchronizeDate,
      lastSynchronizeErrors: data.lastSynchronizeErrors,
      pendingToSynchronize: data.pendingToSynchronize,
      disableForm: false,
    },
  })
);

registerEventHandler(
  WASI_API_PUBLISHER_FORM_SUBMITTED,
  ({ environment: { apiUrl } }, { onSuccessEvent, wasiApiPublisher }) => {
    const { publisherId, wasiToken, idCompany, companyId } = wasiApiPublisher;

    return {
      ...http.put({
        url: `${apiUrl}/admin/wasi-api-publisher/${publisherId}`,
        body: {
          wasiToken,
          idCompany,
          companyId,
        },
        successEvent: {
          id: WASI_API_PUBLISHER_FORM_SUBMIT_SUCCESS,
          payload: { onSuccessEvent },
        },
        errorEvent: WASI_API_PUBLISHER_FORM_SUBMIT_FAILED,
      }),
      ...state.set({
        [`wasiApiPublisher.disableForm`]: true,
      }),
    };
  },
  [environment()]
);

registerEventHandler(
  WASI_API_PUBLISHER_FORM_CHANGE_VALUE,
  (_, { name, value }) =>
    state.set({
      [`wasiApiPublisher.${name}`]: value,
    })
);

registerEventHandler(
  WASI_API_PUBLISHER_FORM_SUBMIT_SUCCESS,
  (_, [__, { onSuccessEvent }]) => ({
    ...toast.show({ text: 'Migration started' }),
    ...effects.dispatchMany([CLOSE_DIALOG, onSuccessEvent]),
    ...state.set({
      [`wasiApiPublisher.disableForm`]: false,
    }),
  })
);

registerEventHandler(WASI_API_PUBLISHER_FORM_SUBMIT_FAILED, () => ({
  ...toast.show({ text: 'Company id or Wasi token are wrong' }),
  ...state.set({
    [`wasiApiPublisher.disableForm`]: false,
  }),
}));
