export function agencySummaryStatsSelector(state) {
  return (
    state?.backoffice?.agencies?.stats ?? {
      renewalsMonth: null,
      renewalsWeek: null,
      paidAgencies: null,
      freeAgencies: null,
    }
  );
}
