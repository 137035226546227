import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { environment } from '../../../../coeffects/environment';
import { analytics } from '../../../../effects/analytics';
import { uuid } from '../../../../coeffects/uuid';
import { toast } from '../../../../effects/toast';
import { OPEN_DIALOG } from '../../../../events/dialogs';
import { ENQUIRY_DETAIL_SEND_REPLY_EMAIL_ERROR_DIALOG } from './constants';
import { parseTimeline } from './helpers';

export const LEAD_ACTIVITY_TIMELINE_REQUEST = 'LEAD_ACTIVITY_TIMELINE_REQUEST';
export const LEAD_ACTIVITY_TIMELINE_REQUEST_SUCCESS =
  'LEAD_ACTIVITY_TIMELINE_REQUEST_SUCCESS';
export const LEAD_ACTIVITY_TIMELINE_UPDATE_REQUESTED =
  'LEAD_ACTIVITY_TIMELINE_UPDATE_REQUESTED';
export const LEAD_ACTIVITY_TIMELINE_OPERATION_FAILED =
  'LEAD_ACTIVITY_TIMELINE_OPERATION_FAILED';
export const LEAD_STATUS_UPDATED = 'LEAD_STATUS_UPDATED';
export const LEAD_STATUS_UPDATED_SUCCESS = 'LEAD_STATUS_UPDATED_SUCCESS';
export const LEAD_NOTE_CREATED = 'LEAD_NOTE_CREATED';
export const LEAD_NOTE_EDITED = 'LEAD_NOTE_EDITED';
export const LEAD_NOTE_DELETED = 'LEAD_NOTE_DELETED';
export const LEAD_EMAIL_SENDING_TRIGGERED = 'LEAD_EMAIL_SENDING_TRIGGERED';
export const LEAD_EMAIL_SENT = 'LEAD_EMAIL_SENT';

registerEventHandler(
  LEAD_ACTIVITY_TIMELINE_REQUEST,
  ({ environment: { apiUrl } }, { leadId }) => ({
    ...state.set({ leadActivityLoading: true }),
    ...getLeadActivityTimelineEffect(apiUrl, leadId),
  }),
  [environment()]
);

registerEventHandler(
  LEAD_ACTIVITY_TIMELINE_UPDATE_REQUESTED,
  ({ environment: { apiUrl } }, [_, { leadId }]) =>
    getLeadActivityTimelineEffect(apiUrl, leadId),
  [environment()]
);

registerEventHandler(LEAD_ACTIVITY_TIMELINE_REQUEST_SUCCESS, (_, [{ data }]) =>
  state.set({
    activityTimeline: data,
    leadActivityLoading: false,
    enquiredPropertiesList: parseTimeline(data),
  })
);

registerEventHandler(
  LEAD_NOTE_CREATED,
  ({ environment: { apiUrl }, uuid: { newUuid } }, { message, leadId }) => {
    const note = { id: newUuid, message };
    return http.post({
      url: `${apiUrl}/leads/${leadId}/notes/${note.id}`,
      body: note,
      successEvent: {
        id: LEAD_ACTIVITY_TIMELINE_UPDATE_REQUESTED,
        payload: { leadId },
      },
      errorEvent: {
        id: LEAD_ACTIVITY_TIMELINE_OPERATION_FAILED,
      },
    });
  },
  [environment(), uuid()]
);

registerEventHandler(
  LEAD_EMAIL_SENDING_TRIGGERED,
  ({ environment: { apiUrl } }, { leadId, message }) =>
    http.post({
      url: `${apiUrl}/leads/${leadId}/emails`,
      body: { leadId, message },
      successEvent: {
        id: LEAD_EMAIL_SENT,
        payload: { leadId },
      },
      errorEvent: {
        id: LEAD_ACTIVITY_TIMELINE_OPERATION_FAILED,
      },
    }),
  [environment(), uuid()]
);

registerEventHandler(LEAD_ACTIVITY_TIMELINE_OPERATION_FAILED, () =>
  effects.dispatch({
    id: OPEN_DIALOG,
    payload: {
      id: ENQUIRY_DETAIL_SEND_REPLY_EMAIL_ERROR_DIALOG,
    },
  })
);

registerEventHandler(
  LEAD_EMAIL_SENT,
  ({ environment: { apiUrl } }, [_, { leadId }]) => ({
    ...getLeadActivityTimelineEffect(apiUrl, leadId),
    ...toast.show({ text: 'enquiry_detail_email_reply_sent' }),
  }),
  [environment()]
);

registerEventHandler(
  LEAD_STATUS_UPDATED,
  ({ environment: { apiUrl } }, payload) => ({
    ...state.set({ 'lead.updateLeadError': undefined }),
    ...http.put({
      url: `${apiUrl}/leads/${payload.leadId}/status`,
      body: JSON.stringify({ status: payload.status }),
      successEvent: { id: LEAD_STATUS_UPDATED_SUCCESS, payload },
    }),
  }),
  [environment()]
);

registerEventHandler(
  LEAD_STATUS_UPDATED_SUCCESS,
  (_, [, { leadId, status }]) => ({
    ...state.set({ 'lead.status': status }),
    ...effects.dispatch({
      id: LEAD_ACTIVITY_TIMELINE_REQUEST,
      payload: { leadId },
    }),
    ...analytics.trackClick('lead-status-selected', 'lead-detail-listing', {
      action: status,
    }),
  })
);

registerEventHandler(
  LEAD_NOTE_EDITED,
  ({ environment: { apiUrl } }, { leadId, noteId, message }) =>
    http.put({
      url: `${apiUrl}/leads/${leadId}/notes/${noteId}`,
      body: { message },
      successEvent: {
        id: LEAD_ACTIVITY_TIMELINE_UPDATE_REQUESTED,
        payload: { leadId },
      },
    }),
  [environment()]
);

registerEventHandler(
  LEAD_NOTE_DELETED,
  ({ environment: { apiUrl } }, { leadId, noteId }) =>
    http.delete({
      url: `${apiUrl}/leads/${leadId}/notes/${noteId}`,
      successEvent: {
        id: LEAD_ACTIVITY_TIMELINE_UPDATE_REQUESTED,
        payload: { leadId },
      },
    }),
  [environment()]
);

function getLeadActivityTimelineEffect(apiUrl, leadId) {
  return http.get({
    url: `${apiUrl}/leads/${leadId}/activity`,
    successEvent: LEAD_ACTIVITY_TIMELINE_REQUEST_SUCCESS,
  });
}
