import { useSelector } from 'reffects-store';
import TabLink from '../TabLink';
import { showSubscriptionBadgeSelector } from './selectors';

export function SubscriptionTabLink({ children }) {
  const showBadge = useSelector(showSubscriptionBadgeSelector);
  return (
    <TabLink
      to="/subscription"
      deep
      eventName="view-subscription-page"
      elementName="subscription-link"
      sectionName="side-menu-top-subscription-link"
      unreadNotifications={showBadge ? 1 : 0}
    >
      {children}
    </TabLink>
  );
}
