import { useFormContext } from 'react-hook-form';
import InputText from 'design-system/components/InputText';
import IconMultiLanguage from '../../../partials/IconMultiLanguage';

function MultiLangNameField({ locale, showFlagIcon, ...rest }) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <InputText
      error={Boolean(errors.names?.[locale])}
      tip={errors.names?.[locale]?.message}
      icon={showFlagIcon && <IconMultiLanguage locale={locale} />}
      {...register(`names[${locale}]`)}
      {...rest}
    />
  );
}

export default MultiLangNameField;
