import styled from 'styled-components';
import { divider, DIVIDER_SIZE } from 'design-system/styles/divider';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Overline } from 'design-system/components/Typography/presets/Overline';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
`;
const Divider = styled.div`
  min-height: 100%;

  ${divider.left(DIVIDER_SIZE.THIN)}
  ${spacing.inline(SPACING_SIZE.L)}
`;
const Content = styled.div`
  flex: 1;
`;
const Header = styled.header`
  ${spacing.stack(SPACING_SIZE.XL, false)}
`;

export function WidgetBlock({ title, withLeftDivider, children }) {
  return (
    <Wrapper>
      {withLeftDivider && <Divider />}
      <Content>
        <Header>
          <Overline $ellipse>{title}</Overline>
        </Header>
        {children}
      </Content>
    </Wrapper>
  );
}
