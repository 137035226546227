import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import CustomFormattedDate from '../../../../../partials/FormattedDate/CustomFormattedDate';
import DisclaimerText from './DisclaimerText';

export default function NextBillingDateDisclaimer({ nextBillingDate }) {
  return (
    <DisclaimerText>
      <FormattedMessageWithValidation
        id="checkout_summary_tip"
        values={{
          nextBillingDate: (
            <CustomFormattedDate timeZone="utc" value={nextBillingDate} />
          ),
        }}
      />
    </DisclaimerText>
  );
}
