import * as yup from 'yup';

import LineField from '../../../../partials/fields/LineField';
import GenericDetailForm from './GenericDetailForm';
import useInternationalization from '../../../../hooks/useInternationalization';

function LineDetailForm({ detail, onSubmit, onCancel }) {
  const intl = useInternationalization();

  const handleFormSubmit = ({ contactLine }) => onSubmit(contactLine);

  return (
    <GenericDetailForm
      defaultValues={{
        contactLine: detail.value,
      }}
      validationSchema={{
        contactLine: yup.string().required(
          intl.formatMessageWithValidation({
            id: 'form_validations_required_field',
          })
        ),
      }}
      onSubmit={handleFormSubmit}
      onCancel={onCancel}
    >
      <LineField name="contactLine" />
    </GenericDetailForm>
  );
}

export default LineDetailForm;
