import { TRANSLATION_NAMES } from './constants';

export class CheckoutConfigurationBuilder {
  constructor() {
    this.data = {
      subRoute: null,
      pageViewNamePrefix: null,
    };
  }

  static create() {
    return new CheckoutConfigurationBuilder()
      .withTextTranslation(
        TRANSLATION_NAMES.BANK_TRANSFER_NOTICE,
        'checkout_bank_transfer_notice'
      )
      .withTextTranslation(
        TRANSLATION_NAMES.CONFIRMATION_CAPTION,
        'checkout_confirmation_caption'
      )
      .withTextTranslation(
        TRANSLATION_NAMES.PAY_BY_LINK_CONFIRMATION,
        'checkout_paybylink_confirmation_body1'
      )
      .withTextTranslation(
        TRANSLATION_NAMES.CONFIRMATION_TITLE,
        'checkout_confirmation_title'
      )
      .withTextTranslation(TRANSLATION_NAMES.PAGE_TITLE, 'checkout_page_title');
  }

  withSubRoute(subRoute) {
    this.data = { ...this.data, subRoute };
    return this;
  }

  withPageViewNamePrefix(pageViewNamePrefix) {
    this.data = { ...this.data, pageViewNamePrefix };
    return this;
  }

  withTextTranslation(name, tag) {
    this.data = {
      ...this.data,
      textTranslations: {
        ...this.data?.textTranslations,
        [name]: tag,
      },
    };
    return this;
  }

  build() {
    this.#validateMandatory(['pageViewNamePrefix', 'subRoute']);

    return this.data;
  }

  #validateMandatory(properties) {
    properties.forEach((property) => {
      if (!this.data[property]) {
        throw new Error(`${property} is mandatory`);
      }
    });
  }
}
