import { dispatch } from 'reffects';
import { useMount } from 'react-use';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import styled from 'styled-components';
import Dialog from 'design-system/components/Dialog/Dialog';
import { CHURN_POLL_NAVIGATED } from './events';
import { BrandLogoForAnonymousUsers } from '../../partials/BrandLogo';
import { useDialog } from '../../hooks/useDialog';
import ChurnModalContent from '../../partials/modals/churn/ChurnModalContent';
import { CHURN_POLL_MODAL } from './constants';

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${spacing.value(SPACING_SIZE.XL)} 0px 0px;
`;

export default function ChurnPoll() {
  const { open, closeDialog } = useDialog(CHURN_POLL_MODAL);

  useMount(() => {
    dispatch(CHURN_POLL_NAVIGATED);
  });

  return (
    <Dialog open={open}>
      <LogoWrapper>
        <BrandLogoForAnonymousUsers />
      </LogoWrapper>
      <ChurnModalContent isFromEmail closeDialog={closeDialog} />
    </Dialog>
  );
}
