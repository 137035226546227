import { createSelector } from 'reselect';
import { collectionSelector } from './collection';
import { DEFAULT_ITEMS_PER_PAGE } from '../constants';
import { calculatePaginationDetails } from '../utils/calculatePaginationDetails';

export const paginationSelector = createSelector(
  collectionSelector,
  (collection) => {
    const {
      currentPage = 1,
      itemsPerPage = DEFAULT_ITEMS_PER_PAGE,
      totalItems = 0,
    } = collection?.pagination ?? {};

    return {
      currentPage,
      itemsPerPage,
      totalItems,
    };
  }
);

export const detailedPaginationSelector = createSelector(
  paginationSelector,
  (pagination) => {
    const details = calculatePaginationDetails(pagination);

    return {
      ...pagination,
      ...details,
    };
  }
);
