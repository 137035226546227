import Grid from 'design-system/components/Grid';
import { useDataTable } from '../../../partials/DataTable/hooks/useDataTable';
import { PublishUnitCard } from './DeveloperCtas/PublishUnitCard';
import { PublishProjectCard } from './DeveloperCtas/PublishProjectCard';

export function DeveloperPublishCards() {
  const { isEmpty: projectsTableIsEmpty } = useDataTable();

  if (projectsTableIsEmpty) return null;

  return (
    <Grid
      areas={({ repeat }) => `
        "${repeat(6, 'publishMoreUnits')} ${repeat(6, 'getInTouch')}"
      `}
    >
      <Grid.Area $id="publishMoreUnits">
        <PublishUnitCard />
      </Grid.Area>
      <Grid.Area $id="getInTouch">
        <PublishProjectCard />
      </Grid.Area>
    </Grid>
  );
}
