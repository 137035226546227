import styled from 'styled-components';
import Layout from 'design-system/components/Layout';
import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import Thumbnail from 'design-system/components/Thumbnail';
import { FlatButton } from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';
import { BUTTON_SIZE } from 'design-system/components/Button';
import Map from 'design-system/components/Map';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Subtitle1 } from 'design-system/components/Typography/presets/Subtitle1';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import Typography from 'design-system/components/Typography/Typography';
import {
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import Section from './Section';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import Subtitle from './Subtitle';
import Description from './Description';
import NearbyLocations from './NearbyLocations';
import Amenities from './Amenities';
import PropertySpaces from './PropertySpaces';
import ProjectStatus from './ProjectStatus';
import { showEnquiredPropertiesListSelector } from './selectors';
import { CLOSE_PROPERTY_DETAIL } from '../events';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${Thumbnail} {
    ${Thumbnail.mods.ratio(300 / 165)}
    ${spacing.stack(SPACING_SIZE.M)}
  }
`;

const Header = styled(Layout.Padded)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 0;

  ${Subtitle3} {
    text-transform: uppercase;
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
  }

  ${FlatButton} {
    padding: 0;
  }
`;

const Title = styled(Subtitle1)`
  ${spacing.stack(SPACING_SIZE.S)}
`;

const LocationMap = styled(Map)`
  height: 280px;
  ${spacing.stack(SPACING_SIZE.M)}
`;

const SectionSpacing = styled.div`
  ${spacing.stack(SPACING_SIZE.M, false)}
`;

const ProjectTitleAddress = styled(Body1)`
  display: flex;
  gap: ${SPACING_SIZE.S}px;

  ${color.text(COLOR_PALETTE.NEUTRAL_A40)}
  ${spacing.stack(SPACING_SIZE.S)}
    ${Body1} {
    ${color.text(COLOR_PALETTE.NEUTRAL_A40)}
  }
`;

const LocationProject = styled(Typography)`
  ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
  ${typography.size(FONT_SIZE.M)}
    ${typography.lineHeight(17)}
    ${typography.weight(FONT_WEIGHT.NORMAL)}
`;

const LocationAddress = styled(Body2)`
  ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
  ${spacing.stack(SPACING_SIZE.M)}
`;

function ProjectDetail({ project, ...props }) {
  const showEnquiredPropertiesList = useSelector(
    showEnquiredPropertiesListSelector
  );
  const {
    name,
    description,
    address,
    buildings,
    units,
    floors,
    latitude,
    longitude,
    image,
    amenities,
    nearbyLocations,
  } = project;
  return (
    <Wrapper {...props}>
      <Header>
        <Subtitle3>
          <FormattedMessageWithValidation id="enqdetail_project_detail_title" />
        </Subtitle3>
        {showEnquiredPropertiesList && (
          <FlatButton
            icon={<Icon glyph="cross" />}
            $size={BUTTON_SIZE.BASE}
            onClick={() => {
              dispatch(CLOSE_PROPERTY_DETAIL);
            }}
          />
        )}
      </Header>
      <Section>
        <Thumbnail source={image} />
        <Title>{name}</Title>
        <ProjectTitleAddress>{address}</ProjectTitleAddress>
      </Section>
      <Section>
        <ProjectStatus project={project} />
      </Section>
      <PropertySpaces>
        <PropertySpaces.Space
          glyph="building"
          value={buildings}
          tag="enqdetail_project_detail_space_buildings"
        />
        <PropertySpaces.Space
          glyph="apartment"
          value={units}
          tag="enqdetail_project_detail_space_units"
        />
        <PropertySpaces.Space
          glyph="lift"
          value={floors}
          tag="enqdetail_project_detail_space_floors"
        />
      </PropertySpaces>
      <Section>
        <Subtitle>
          <FormattedMessageWithValidation id="enqdetail_property_detail_detail_section_subtitle" />
        </Subtitle>
        <Description description={description} />
      </Section>
      <SectionSpacing />
      <Amenities amenities={amenities} />
      <Section>
        <Subtitle>
          <FormattedMessageWithValidation id="enqdetail_property_detail_location_section_subtitle" />
        </Subtitle>
        <LocationProject>{name}</LocationProject>
        <LocationAddress>{address}</LocationAddress>
      </Section>
      <LocationMap
        coordinates={{
          latitude,
          longitude,
        }}
        showMarker
        showCircle
      />
      <NearbyLocations nearbyLocations={nearbyLocations} />
    </Wrapper>
  );
}

export default ProjectDetail;
