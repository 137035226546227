import styled from 'styled-components';
import Tile from 'design-system/components/Tile';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle2 } from 'design-system/components/Typography/presets/Subtitle2';

const Wrapper = styled(Tile)`
  display: flex;
  ${spacing.inset(SPACING_SIZE.M)}
`;

const Illustration = styled.div`
  display: flex;
  align-items: flex-end;
  min-height: 120px;

  ${spacing.inline(SPACING_SIZE.L)}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  ${Subtitle2} {
    ${spacing.stack(SPACING_SIZE.L)}
  }
`;

export default function ActionCard({ illustration, title, cta }) {
  return (
    <Wrapper>
      <Illustration>{illustration}</Illustration>
      <Content>
        <Subtitle2>{title}</Subtitle2>
        {cta}
      </Content>
    </Wrapper>
  );
}
