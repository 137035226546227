import { CHANGE_REQUEST_STATUS } from '../../../../../constants/subscription';

function textForPendingScr(shouldDisplayAutoRenewal) {
  return `Pending to be paid${
    shouldDisplayAutoRenewal ? ' (autorenewal)' : ''
  }`;
}

function textForPaidScr(billingStatus) {
  if (billingStatus === 'conciliated') {
    return 'Upcoming subscription already paid';
  }
  if (billingStatus === 'not-informed') {
    return 'Paid, pending to be conciliated';
  }
  return 'Invoiced, pending to be conciliated';
}

function textForActivatedScr(billingStatus) {
  if (billingStatus === 'billed') {
    return 'Invoiced, pending to be conciliated';
  }
  return 'Activated, pending to be conciliated';
}

export function getSubscriptionChangeRequestLinkText({
  hasActiveFirstPaymentCheckout,
  status,
  billingStatus,
  isSelfServiced,
  shouldDisplayAutoRenewal,
}) {
  if (hasActiveFirstPaymentCheckout) {
    return `First subscription requested${
      isSelfServiced ? ' (selfservice)' : ''
    }`;
  }
  if (status === CHANGE_REQUEST_STATUS.PENDING) {
    return textForPendingScr(shouldDisplayAutoRenewal);
  }
  if (status === CHANGE_REQUEST_STATUS.PAID) {
    return textForPaidScr(billingStatus);
  }
  return textForActivatedScr(billingStatus);
}
