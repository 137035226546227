import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { SCR } from './SCR';
import { PaymentRequest } from './PaymentRequest';
import { BCCommunication } from './BCCommunication';
import { Webhook } from './Webhook';
import { SubscriptionChange } from './SubscriptionChange';

const Item = styled.li`
  list-style: none;
  margin: auto;
  margin-left: 200px;
  min-height: 50px;
  border-left: 1px dashed #86d6ff;
  padding: 0 0 0 30px;
  ${spacing.stack(SPACING_SIZE.S)};

  position: relative;
  &:last-child {
    border-left: 0;
  }

  &::before {
    position: absolute;
    left: -10px;
    top: 10px;
    content: ' ';
    border: 8px solid rgba(255, 255, 255, 0.74);
    border-radius: 500%;
    background: #258cc7;
    height: 20px;
    width: 20px;
    transition: all 500ms ease-in-out;
  }

  &.paymentRequest::before {
    background: #ffc107;
  }
  &.bcCommunication::before {
    background: #6c757d;
  }
  &.webhook::before {
    background: #712cf9;
  }
  &.scr::before {
    background: #0dcaf0;
  }
  &.subscriptionChange::before {
    background: #198754;
  }
`;

const Timestamp = styled.div`
  ${color.text(COLOR_PALETTE.NEUTRAL_A80)}
  ${spacing.inset(SPACING_SIZE.S)};
  position: absolute;
  width: 200px;
  left: -225px;
  text-align: right;
  font-size: 12px;
`;

export function TimelineItem({ event }) {
  const date = new Date(event.date);

  return (
    <Item className={event.type}>
      <Timestamp>
        {date.toLocaleDateString()}
        <br /> {date.toLocaleTimeString()}
      </Timestamp>
      {event.type === 'scr' && <SCR event={event} />}
      {event.type === 'paymentRequest' && <PaymentRequest event={event} />}
      {event.type === 'bcCommunication' && <BCCommunication event={event} />}
      {event.type === 'webhook' && <Webhook event={event} />}
      {event.type === 'subscriptionChange' && (
        <SubscriptionChange event={event} />
      )}
    </Item>
  );
}
