import { useEffect } from 'react';
import { useDatatableContext } from './useDatatableContext';

export function useContextReadyEffect(callback) {
  const { isContextReady, ...context } = useDatatableContext();
  useEffect(() => {
    if (!isContextReady) {
      return undefined;
    }

    return callback({ isContextReady, ...context });
  }, [isContextReady]);
}
