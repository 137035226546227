import styled from 'styled-components';
import { dispatch } from 'reffects';
import DropdownMenu from 'design-system/components/DropdownMenu';
import Menu from 'design-system/components/Menu/Menu';
import Button, {
  BUTTON_SIZE,
  BUTTON_VARIANT,
} from 'design-system/components/Button';
import Icon from 'design-system/components/Icon';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { DOWNLOAD_INVALID_PROPERTIES } from './events';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import useTracking from '../../../../hooks/useTracking';

const DropdownButton = styled(Button)`
  ${Button.mods.variant(BUTTON_VARIANT.OUTLINE)}
  ${Button.mods.size(BUTTON_SIZE.BASE)}
`;

function ExportOptions(props) {
  const ref = useTracking({
    trackClick: {
      eventName: 'export-import-stats-data',
    },
    trackImpression: {
      elementName: 'export-import-stats-btn',
    },
    sectionName: 'import-stats-table',
  });
  const download = (format) => ({
    id: DOWNLOAD_INVALID_PROPERTIES,
    value: {
      id: DOWNLOAD_INVALID_PROPERTIES,
      payload: { format },
    },
  });
  return (
    <DropdownMenu
      button={
        <DropdownButton icon={<Icon glyph="download" />} ref={ref}>
          <FormattedMessageWithValidation id="excel_button_export_data" />
        </DropdownButton>
      }
      onConfirm={({ value }) => dispatch(value)}
      {...props}
    >
      <Menu>
        <Menu.Item
          id="download-excel"
          value={download('xls')}
          label={
            <Body1 $noWrap>
              <FormattedMessageWithValidation id="excel_button_download_excel" />
            </Body1>
          }
        />
        <Menu.Item
          id="download-csv"
          value={download('csv')}
          label={
            <Body1 $noWrap>
              <FormattedMessageWithValidation id="excel_button_download_csv" />
            </Body1>
          }
        />
      </Menu>
    </DropdownMenu>
  );
}

export default styled(ExportOptions)``;
