import { effects as reffects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { location } from '../../../../coeffects/location';
import { navigateTo } from '../../../../effects/routing';
import { composePathWithSearchParams } from '../../../../utils/url';
import { buildTableUnitStatusFilter, buildTableUnitTypeFilter } from './utils';
import { UNITS_TABLE_PAGE_CHANGED } from '../UnitsTable/events';
import {
  UNITS_FILTER_STATUS_URL_PARAM,
  UNITS_FILTER_UNIT_TYPE_URL_PARAM,
  UNITS_SEARCH_TYPE_URL_PARAM,
  UNITS_SEARCH_URL_PARAM,
} from './constants';
import updateTable from '../../../Tables/updateTable';

export const UNITS_FILTERS_MOUNTED = 'UNITS_FILTERS_MOUNTED';
export const UNITS_FILTERED_BY_STATUS = 'UNITS_FILTERED_BY_STATUS';
export const UNITS_FILTERED_BY_UNIT_TYPE = 'UNITS_FILTERED_BY_UNIT_TYPE';
export const UNITS_FILTERS_CLEARED = 'UNITS_FILTERS_CLEARED';

registerEventHandler(
  UNITS_FILTERED_BY_STATUS,
  (
    {
      state: {
        unitsById,
        filters: { published, ...filters },
        pagination,
        sorting,
      },
      location: { href },
    },
    { value }
  ) => {
    const newFilter = { ...filters, ...buildTableUnitStatusFilter(value) };

    const { rows, totalRows } = updateTable(
      unitsById,
      newFilter,
      sorting,
      pagination
    );

    return {
      ...state.set({
        unitStatusFilter: value,
        'unitsTable.filters': newFilter,
        'unitsTable.rows': rows,
        'unitsTable.pagination.totalRows': totalRows,
      }),
      ...reffects.dispatch({ id: UNITS_TABLE_PAGE_CHANGED, payload: 1 }),
      ...navigateTo(
        composePathWithSearchParams(href, {
          [UNITS_FILTER_STATUS_URL_PARAM]: value,
        })
      ),
    };
  },
  [
    state.get({
      unitsById: 'unitsById',
      filters: 'unitsTable.filters',
      sorting: 'unitsTable.sorting',
      pagination: 'unitsTable.pagination',
    }),
    location(),
  ]
);

registerEventHandler(
  UNITS_FILTERED_BY_UNIT_TYPE,
  (
    { state: { unitsById, filters, pagination, sorting }, location: { href } },
    { value }
  ) => {
    const { unitType, ...currentFilters } = filters;
    const newFilter = {
      ...currentFilters,
      ...buildTableUnitTypeFilter(value),
    };

    const { rows, totalRows } = updateTable(
      unitsById,
      newFilter,
      sorting,
      pagination
    );

    return {
      ...state.set({
        unitTypeFilter: value,
        'unitsTable.filters': newFilter,
        'unitsTable.rows': rows,
        'unitsTable.pagination.totalRows': totalRows,
      }),
      ...reffects.dispatch({ id: UNITS_TABLE_PAGE_CHANGED, payload: 1 }),
      ...navigateTo(
        composePathWithSearchParams(href, {
          [UNITS_FILTER_UNIT_TYPE_URL_PARAM]: value,
        })
      ),
    };
  },
  [
    state.get({
      unitsById: 'unitsById',
      filters: 'unitsTable.filters',
      sorting: 'unitsTable.sorting',
      pagination: 'unitsTable.pagination',
    }),
    location(),
  ]
);

registerEventHandler(
  UNITS_FILTERS_CLEARED,
  ({ state: { unitsById, pagination, sorting }, location: { href } }, _) => {
    const { rows, totalRows } = updateTable(unitsById, {}, sorting, pagination);

    return {
      ...state.set({
        'unitsTable.filters': {},
        'unitsTable.rows': rows,
        'unitsTable.pagination.totalRows': totalRows,
        'units:suggesterFilterValue': '',
        unitTypeFilter: '',
        unitStatusFilter: '',
      }),
      ...navigateTo(
        composePathWithSearchParams(href, {
          [UNITS_FILTER_STATUS_URL_PARAM]: undefined,
          [UNITS_FILTER_UNIT_TYPE_URL_PARAM]: undefined,
          [UNITS_SEARCH_URL_PARAM]: undefined,
          [UNITS_SEARCH_TYPE_URL_PARAM]: undefined,
        })
      ),
    };
  },
  [
    state.get({
      unitsById: 'unitsById',
      sorting: 'unitsTable.sorting',
      pagination: 'unitsTable.pagination',
    }),
    location(),
  ]
);
