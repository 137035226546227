import Layout from 'design-system/components/Layout';
import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { BORDER_SIZE } from 'design-system/styles/border';
import { SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import PropertySnippet from '../ActivityTimeline/bubbles/PropertySnippet';
import { newEnquiredPropertiesListSelector } from './PropertyDetail/selectors';
import ProjectSnippet from '../ActivityTimeline/bubbles/ProjectSnippet';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled(Layout.Padded)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: ${SPACING_SIZE.M}px;

  ${Subtitle3} {
    text-transform: uppercase;
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
  }
`;

const PropertySnippetWrapper = styled.div`
  padding: 0 ${SPACING_SIZE.XL}px;

  > * {
    border-bottom: ${BORDER_SIZE.BASE}px solid ${COLOR_PALETTE.NEUTRAL_A10};
    border-radius: initial;

    &:hover {
      ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
      cursor: pointer;
    }
  }
`;

function PropertiesList() {
  const properties = useSelector(newEnquiredPropertiesListSelector);
  return (
    <Wrapper>
      <Header>
        <Subtitle3>
          <FormattedMessageWithValidation id="enqdetail_properties_list_title" />
        </Subtitle3>
      </Header>
      <PropertySnippetWrapper>
        {properties.map(({ type, ...property }) =>
          type === 'ad' ? (
            <PropertySnippet
              key={property.id}
              property={property}
              project={property.project}
              openPropertyDetail={false}
            />
          ) : (
            <ProjectSnippet
              key={property.id}
              project={property}
              openPropertyDetail={false}
            />
          )
        )}
      </PropertySnippetWrapper>
    </Wrapper>
  );
}

export default PropertiesList;
