import { useSelector } from 'reffects-store';
import { ROWS_PER_PAGE } from 'design-system/components/Table';
import Tag, { TAG_VARIANT } from 'design-system/components/Tag';
import { Overline } from 'design-system/components/Typography/presets/Overline';
import {
  DataTable,
  DataTableProvider,
  DateColumn,
  DateRangeColumn,
  EnumColumn,
  MediaLinkColumn,
  PriceColumn,
} from '../../../../partials/DataTable';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { COLLECTION_NAME } from './constants';
import { createAdapter } from './tableAdapter';
import { defaultPriceCurrencySelector } from '../../../publication/selectors';
import Header from '../Layout/Header';
import useTracking from '../../../../hooks/useTracking';

function InvoicesTable() {
  const pdfLinkTrackingRef = useTracking({
    trackClick: {
      eventName: 'download-billing-pdf',
    },
    sectionName: 'subscription-billing-table',
  });
  const xmlLinkTrackingRef = useTracking({
    trackClick: {
      eventName: 'download-billing-xml',
    },
    sectionName: 'subscription-billing-table',
  });
  const tableImpressionRef = useTracking({
    trackImpression: {
      elementName: 'subscription-billing-table',
    },
    sectionName: 'subscription-billing-page',
  });
  const context = {
    collectionName: COLLECTION_NAME,
    defaultSorting: {},
    defaultFilters: {},
    initialItemsPerPage: ROWS_PER_PAGE.TWENTY_FIVE,
    adapter: createAdapter(),
  };
  const publisherCurrency = useSelector(defaultPriceCurrencySelector);
  return (
    <div ref={tableImpressionRef}>
      <DataTableProvider {...context}>
        <Header>
          <Overline>
            <FormattedMessageWithValidation id="subscription_invoices_billing_history" />
          </Overline>
        </Header>

        <DataTable
          allowPagination
          noItemsMessage={
            <FormattedMessageWithValidation id="subscription_invoices_empty_state" />
          }
        >
          <DateColumn
            id="createdAt"
            title={
              <FormattedMessageWithValidation id="subscription_invoices_date" />
            }
          />
          <DateRangeColumn
            id="termRange"
            title={
              <FormattedMessageWithValidation id="subscription_invoices_term" />
            }
            source={{ from: 'termFrom', to: 'termTo' }}
          />
          <EnumColumn
            id="type"
            title={
              <FormattedMessageWithValidation id="subscription_invoices_type" />
            }
            mapping={{
              factura: (
                <Tag>
                  <FormattedMessageWithValidation id="subscription_invoices_tax_invoice" />
                </Tag>
              ),
              proforma: (
                <Tag variant={TAG_VARIANT.COMPLEMENTARY_STRAWBERRY}>
                  <FormattedMessageWithValidation id="subscription_invoices_proforma_invoice" />
                </Tag>
              ),
            }}
          />
          <PriceColumn
            id="amount"
            title={
              <FormattedMessageWithValidation id="subscription_invoices_amount" />
            }
            source={{ amount: 'amount', currency: 'currency' }}
            defaultCurrency={publisherCurrency.code}
          />
          <MediaLinkColumn
            id="downloadPdfLink"
            glyph="downloadDoc"
            text="PDF"
            ref={pdfLinkTrackingRef}
          />
          <MediaLinkColumn
            id="downloadXmlLink"
            glyph="downloadDoc"
            text="XML"
            ref={xmlLinkTrackingRef}
          />
        </DataTable>
      </DataTableProvider>
    </div>
  );
}

export default InvoicesTable;
