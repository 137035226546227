import { useState } from 'react';
import styled, { css } from 'styled-components';
import Menu from 'design-system/components/Menu/Menu';
import InputBase from 'design-system/components/InputBase/InputBase';
import Icon from 'design-system/components/Icon';
import { noop, withStylesAndInnerRef } from '../utils';

import Popover from './Popover';
import { useInputBase } from '../hooks/useInputBase';

const modFullWidth = () => css`
  ${Popover} {
    width: 100%;
  }
`;

const Wrapper = styled(InputBase)`
  cursor: pointer;
  position: relative;

  ${Popover} {
    position: absolute;
    right: 0;
    max-width: 100%;
  }

  ${({ fullWidth }) => fullWidth && modFullWidth()}
`;

const Input = styled.input`
  cursor: pointer;
`;

const InvisibleBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

function InputSelect({
  choices,
  innerRef,
  onSelect = noop,
  fullWidth,
  enableHtmlValidation,
  scrollable,
  ...rest
}) {
  const [open, setOpen] = useState(false);
  const {
    inputBaseProps,
    boxProps: { icon, ...boxProps },
    inputProps: { value: selectionId, ...inputProps },
  } = useInputBase(rest);
  const { disabled } = rest;

  const selectedItem = choices.find((choice) => choice.value === selectionId);

  const handleOpen = () => {
    if (!disabled) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelection = (selection) => {
    handleClose();
    onSelect(selection);
  };

  const handleCancellation = () => {
    handleClose();
  };

  return (
    <Wrapper onMouseDown={handleOpen} fullWidth={fullWidth} {...inputBaseProps}>
      <InputBase.Box icon={<Icon glyph="chevronDown" />} {...boxProps}>
        <Input
          type="text"
          ref={innerRef}
          value={selectedItem?.label ?? ''}
          readOnly={!enableHtmlValidation}
          autoComplete="off"
          {...inputProps}
        />
      </InputBase.Box>
      {open && (
        <>
          <InvisibleBackdrop onClick={handleCancellation} />
          <Popover>
            <Menu
              onConfirm={handleSelection}
              onCancel={handleCancellation}
              scrollable={scrollable}
            >
              {choices.map((choiceProps) => (
                <Menu.Item key={choiceProps.value} {...choiceProps} />
              ))}
            </Menu>
          </Popover>
        </>
      )}
    </Wrapper>
  );
}

export default withStylesAndInnerRef(InputSelect);
