import {
  ADDRESS_INPUT_NAME,
  COORDINATES_INPUT_NAME,
  GEO_LEVELS_INPUT_NAME,
  LOCATION_VISIBILITY_INPUT_NAME,
  PROJECT_ID_INPUT_NAME,
  PROJECT_INPUT_NAME,
} from './constants';
import { PROPERTY_LOCATION_VISIBILITY } from '../../constants/ad';
import {
  createAdImages,
  createFloorPlanFiles,
} from './partials/ImageUploader/adImages';

export function prepareDataFromResponse({ data, whatsappCallingCode }) {
  const ad = {
    [ADDRESS_INPUT_NAME]: data.address,
    [COORDINATES_INPUT_NAME]: {
      latitude: data.latitude,
      longitude: data.longitude,
    },
    [GEO_LEVELS_INPUT_NAME]: data.geoLevels,
    [LOCATION_VISIBILITY_INPUT_NAME]:
      data.locationVisibility ?? PROPERTY_LOCATION_VISIBILITY.ACCURATE,
    floorPlans: createFloorPlanFiles(data.floorPlans),
    propertyImages: createAdImages(data.images, data.mainImage),
    [PROJECT_INPUT_NAME]: data.project
      ? { id: data.project.id, name: data.project.name }
      : undefined,
    [PROJECT_ID_INPUT_NAME]: data.project ? data.project.id : undefined,
    id: data.id,
    referenceId: data.referenceId,
    creationDate: data.creationDate,

    spaces: data.spaces,
    bathrooms: data.bathrooms,
    bedrooms: data.bedrooms,
    toilets: data.toilets,
    floor: data.floor,
    bankProperty: data.bankProperty,
    parkingSpaces: data.parkingSpaces,
    orientation: data.orientation,
    condition: data.condition,
    energyCertificate: data.energyCertificate,
    efficiency: data.efficiency,
    efficiencyValue: data.efficiencyValue,
    emissions: data.emissions,
    emissionsValue: data.emissionsValue,
    furnished: data.furnished,
    constructionYear: data.constructionYear,
    stratum: data.stratum,
    communityFees: data.communityFeesAmount,

    floorArea: data.floorArea,
    floorAreaUnit: data.floorAreaUnit,
    usableArea: data.usableArea,
    usableAreaUnit: data.usableAreaUnit,

    amenities: Array.isArray(data.amenities) ? data.amenities : undefined,
    rules: Array.isArray(data.rules) ? data.rules : undefined,
    nearby: Array.isArray(data.nearbyLocations)
      ? data.nearbyLocations
      : undefined,

    contactEmails: data.contactEmails,
    contactWhatsApp: data.contactWhatsApp
      ? data.contactWhatsApp.replace(whatsappCallingCode, '')
      : data.contactWhatsApp,
    contactPhone: data.contactPhone,
    contactLine: data.contactLine,
    contactViber: data.contactViber,
    contactFacebookMessenger: data.contactFacebookMessenger,

    video: data.video,
    virtualTours: data.virtualTours.pop(),

    propertyType: data.propertyType,
    ownership: data.ownership,
    tenureInYears: data.tenureInYears,
    suitableForCredit: data.suitableForCredit,
  };
  const plotArea = unserializePlotArea(data.plotArea);
  const titleMultiLanguage = unserializeMultiLanguageField(
    'title',
    data.titleMultiLanguage
  );
  const descriptionMultiLanguage = unserializeMultiLanguageField(
    'description',
    data.descriptionMultiLanguage
  );
  const operations = unserializeOperations(data.operations);

  return {
    ...ad,
    ...plotArea,
    ...titleMultiLanguage,
    ...descriptionMultiLanguage,
    ...operations,
  };
}

function unserializePlotArea(plotArea) {
  const isAreaInThaiUnits = plotArea.some(
    ({ unit }) => unit === 'rai' || unit === 'ngan' || unit === 'sqw'
  );
  let plotAreaUnit;
  if (isAreaInThaiUnits) {
    plotAreaUnit = 'rai/ngan/sqw';
  } else {
    const unit = plotArea[0]?.unit;
    plotAreaUnit = unit === 'm2' ? 'sqm' : unit;
  }
  return {
    plotAreaSqm: unserializePlotAreaByUnit(plotArea, 'm2'),
    plotAreaSf: unserializePlotAreaByUnit(plotArea, 'sf'),
    plotAreaRai: unserializePlotAreaByUnit(plotArea, 'rai'),
    plotAreaNgan: unserializePlotAreaByUnit(plotArea, 'ngan'),
    plotAreaSqw: unserializePlotAreaByUnit(plotArea, 'sqw'),
    plotAreaUnit,
  };
}

function unserializePlotAreaByUnit(plotArea, areaUnit) {
  const result = plotArea.find(({ unit }) => unit === areaUnit);
  return result?.value ?? undefined;
}

function unserializeMultiLanguageField(name, field) {
  return Object.fromEntries(
    field.map(({ locale, text }) => [`${name}_${locale}`, text])
  );
}

function unserializeOperations(operations) {
  const operationFields = {};
  operations.forEach(({ price: { amount, currency }, type }) => {
    if (type === 'sell') {
      operationFields.operationType = type;
      operationFields.sellPrice = amount ?? undefined;
      if (currency) {
        operationFields.sellPriceCurrency = currency;
      }
    } else {
      operationFields.operationType = type;
      operationFields.rentPrice = amount ?? undefined;
      if (currency) {
        operationFields.rentPriceCurrency = currency;
      }
    }
  });
  if (operations.length === 2) {
    operationFields.operationType = 'sell-and-rent';
  }
  return operationFields;
}
