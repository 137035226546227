/* eslint-disable no-use-before-define */
import styled, { css } from 'styled-components';
import SkeletonBone from 'design-system/components/SkeletonBone';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { FlatButton } from 'design-system/components/Button/presets';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import {
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import PropertySnippet from './PropertySnippet';
import CustomFormattedDateTime from '../../../../../partials/FormattedDate/CustomFormattedDateTime';

export const BASE_BUBBLE_ALIGNMENT = {
  LEFT: Symbol('BASE_BUBBLE_ALIGNMENT_LEFT'),
  RIGHT: Symbol('BASE_BUBBLE_ALIGNMENT_RIGHT'),
};

const Wrapper = styled.div`
  &:hover {
    ${() => modHover()}
  }

  ${({ $alignment }) => modAlignment($alignment)}
  ${({ $colorScheme }) => modColorScheme($colorScheme)}
`;

export const Cloud = styled.div`
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  ${radius.regular(RADIUS_SIZE.LARGE)}
  ${spacing.inset(SPACING_SIZE.S)}
  ${spacing.stack(SPACING_SIZE.S)}
  
  ${Subtitle3} {
    ${spacing.stack(SPACING_SIZE.XS, false)}
  }

  ${SkeletonBone} {
    ${spacing.stack(SPACING_SIZE.XS, false)}
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  ${Cloud} {
    width: 100%;
  }
  ${Icon} {
    ${spacing.inline(SPACING_SIZE.S)}
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Error = styled(Subtitle3)`
  display: flex;
  justify-content: flex-end;
  ${color.text(COLOR_PALETTE.STRAWBERRY_BASE)}
`;

const Actions = styled.div`
  visibility: hidden;

  ${FlatButton} {
    padding: ${spacing.value(0)} ${spacing.value(10)};
    ${typography.weight(FONT_WEIGHT.MEDIUM)}
    ${typography.size(FONT_SIZE.S)}
  }
`;

const Timestamp = styled(Body2)`
  text-transform: uppercase;
`;

const modAlignmentRight = () => css`
  align-self: flex-end;

  ${Cloud} {
    border-bottom-right-radius: 0;
  }

  ${Footer} {
    flex-direction: row-reverse;
  }
`;

const modAlignmentLeft = () => css`
  align-self: flex-start;

  ${Cloud} {
    border-bottom-left-radius: 0;
  }

  ${Footer} {
    flex-direction: row;
  }
`;

const modAlignment = (alignment) =>
  ({
    [BASE_BUBBLE_ALIGNMENT.LEFT]: modAlignmentLeft,
    [BASE_BUBBLE_ALIGNMENT.RIGHT]: modAlignmentRight,
  }[alignment]);

const modColorScheme = ({ title, background, notch = background }) => css`
  ${Cloud} {
    ${color.background(background)}
  }

  ${Cloud}::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 2px;

    ${color.background(notch)}
  }

  ${Cloud} ${Subtitle3} {
    ${Subtitle3.mods.color(title)}
  }
`;

const modHover = () => css`
  ${Actions} {
    visibility: initial;
  }
`;

function BaseBubble({
  title,
  children,
  error,
  date,
  actions,
  hotnessBubble,
  ...rest
}) {
  const content = (
    <Cloud>
      {title && <Subtitle3>{title}</Subtitle3>}
      {children}
    </Cloud>
  );
  return (
    <Wrapper {...rest}>
      {error ? (
        <ErrorContainer>
          <Icon
            glyph="error"
            $color={COLOR_PALETTE.STRAWBERRY_BASE}
            $size={ICON_SIZE.BASE}
          />
          {content}
        </ErrorContainer>
      ) : (
        content
      )}
      {error && <Error>{error}</Error>}
      {hotnessBubble}
      <Footer>
        <Timestamp $color={COLOR_PALETTE.NEUTRAL_A60}>
          <CustomFormattedDateTime value={new Date(date)} />
        </Timestamp>
        {actions && <Actions>{actions}</Actions>}
      </Footer>
    </Wrapper>
  );
}

function SkeletonBubble({ direction, ...rest }) {
  if (direction === 'inbound') {
    return (
      <Wrapper
        $alignment={BASE_BUBBLE_ALIGNMENT.LEFT}
        $colorScheme={{
          title: COLOR_PALETTE.NONE,
          background: COLOR_PALETTE.NEUTRAL_A05,
        }}
        {...rest}
      >
        <Cloud>
          <SkeletonBone $width="118px" $height="12px" />
          <SkeletonBone $width="224px" $height="17px" />
          <PropertySnippet.Skeleton />
        </Cloud>
        <Footer>
          <SkeletonBone $width="115px" $height="15px" />
        </Footer>
      </Wrapper>
    );
  }
  return (
    <Wrapper
      $alignment={BASE_BUBBLE_ALIGNMENT.RIGHT}
      $colorScheme={{
        title: COLOR_PALETTE.NONE,
        background: COLOR_PALETTE.NEUTRAL_A03,
      }}
      {...rest}
    >
      <Cloud>
        <SkeletonBone $width="293px" $height="10px" />
        <SkeletonBone $width="293px" $height="10px" />
        <SkeletonBone $width="100px" $height="10px" />
      </Cloud>
      <Footer>
        <SkeletonBone $width="115px" $height="15px" />
      </Footer>
    </Wrapper>
  );
}

BaseBubble.Skeleton = SkeletonBubble;
export default BaseBubble;
