import { cloneElement, useEffect, useState } from 'react';
import styled from 'styled-components';

import SkeletonBone from 'design-system/components/SkeletonBone';
import DefaultPlaceholder from './DefaultPlaceholder';

function bindClassname(Component, Wrapper) {
  // eslint-disable-next-line no-param-reassign
  Component.toString = Wrapper.toString;
  Component.bind(Wrapper);

  return Component;
}

const Wrapper = styled.img`
  display: block;

  width: 100%;
  height: 100%;

  object-position: center;
  object-fit: cover;
`;

function isAFile(image) {
  return image instanceof Blob || image instanceof File;
}

function getSourceUrl(source) {
  return isAFile(source) ? URL.createObjectURL(source) : source;
}

function Image({ source, placeholder = <DefaultPlaceholder />, ...rest }) {
  const { width, height } = rest;

  const [isAbleToLoadImage, setIsAbleToLoad] = useState(true);

  useEffect(() => {
    setIsAbleToLoad(true);
  }, [source]);

  const handleImageLoad = () => setIsAbleToLoad(true);
  const handleImageLoadError = () => setIsAbleToLoad(false);

  if (isAbleToLoadImage && source) {
    return (
      <Wrapper
        src={getSourceUrl(source)}
        onLoad={handleImageLoad}
        onError={handleImageLoadError}
        {...rest}
      />
    );
  }

  return cloneElement(placeholder, {
    ...rest,
    style: {
      ...rest.style,
      width,
      height,
    },
  });
}

Image.Skeleton = styled(({ width, height, ...rest }) => (
  <SkeletonBone $width={width} $height={height} {...rest} />
))``;

export default styled(bindClassname(Image, Wrapper))``;
