import styled, { css } from 'styled-components';
import { useIntl } from 'react-intl';
import { useSelector } from 'reffects-store';
import ContactInterest from 'design-system/components/ContactInterest/ContactInterest';
import Image from 'design-system/components/Image/Image';
import SkeletonBone from 'design-system/components/SkeletonBone';
import Icon from 'design-system/components/Icon';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import {
  furnishedTranslationTags,
  propertyTypesTranslationTags,
} from '../../../../../../constants/ad';
import FormattedMessageWithValidation from '../../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import price from './images/price1.png';
import furnished from './images/furniture.png';
import bedroomsImage from './images/bedrooms.png';
import bathroomsImage from './images/bathroms.png';
import areaImage from './images/area.png';
import { publisherCountrySelector } from '../../../../../../selectors/publisher';
import getDefaultCurrency from '../../../../../../Backoffice/utils/defaultCurrency';
import { getSymbolByCurrency } from '../../../../../../utils/currencies';
import { defaultAreaUnitSelector } from '../../../../../publication/selectors';
import Price from '../../../../../../partials/Price/Price';

const SkeletonWrapper = styled.div`
  ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
  ${spacing.inset(SPACING_SIZE.M)}
    > * {
    ${spacing.stack(SPACING_SIZE.S)}
  }
`;

const Wrapper = styled(ContactInterest)`
  padding: ${spacing.value(SPACING_SIZE.M)} ${spacing.value(SPACING_SIZE.XL)};

  > * {
    padding: 0;
  }

  ${({ $blurred }) =>
    $blurred &&
    css`
      ${ContactInterest.Feature} {
        ${Image} {
          ${spacing.inline(SPACING_SIZE.XS, false)}
        }

        ${Body1} {
          display: flex;
          flex-direction: row;
        }
      }
    `}
`;

function Interest({
  interest: {
    propertyType,
    budget,
    bedrooms,
    bathrooms,
    furnishedStatus,
    usableArea,
    floorArea,
    plotArea,
  } = {},
  ribbon,
  blurred,
  ...rest
}) {
  const countryCode = useSelector(publisherCountrySelector);
  const defaultAreaUnit = useSelector(defaultAreaUnitSelector);
  if (blurred) {
    return (
      <Wrapper {...rest} $blurred>
        <ContactInterest.Feature icon={<Icon glyph="price" />}>
          <Image source={price} />
          <span>{getSymbolByCurrency(getDefaultCurrency(countryCode))}</span>
        </ContactInterest.Feature>
        <ContactInterest.Feature icon={<Icon glyph="bedroom" />}>
          <Image source={bedroomsImage} />
          <FormattedMessageWithValidation id="leadpage_enquiry_attribute_bedrooms" />
        </ContactInterest.Feature>
        <ContactInterest.Feature icon={<Icon glyph="bathtub" />}>
          <Image source={bathroomsImage} />
          <FormattedMessageWithValidation id="leadpage_enquiry_attribute_bathrooms" />
        </ContactInterest.Feature>
        <ContactInterest.Feature icon={<Icon glyph="sofa" />}>
          <Image source={furnished} />
        </ContactInterest.Feature>
        <ContactInterest.Feature icon={<Icon glyph="squareFoot" />}>
          <Image source={areaImage} />
          <span>{defaultAreaUnit.name}</span>
        </ContactInterest.Feature>
      </Wrapper>
    );
  }
  return (
    <Wrapper
      title={
        <FormattedMessageWithValidation
          id={propertyTypesTranslationTags.get(propertyType)}
        />
      }
      blurred={blurred}
      ribbon={ribbon}
      {...rest}
    >
      {budget &&
        Object.entries(budget).map(([currency, { minimum, maximum }]) => (
          <ContactInterest.Feature key={currency} icon={<Icon glyph="price" />}>
            {minimum === maximum ? (
              <>
                <span>{currency} </span>
                <Price amount={minimum} currency={currency} />
              </>
            ) : (
              <>
                <span>{currency} </span>
                <Price amount={minimum} currency={currency} /> -{' '}
                <Price amount={maximum} currency={currency} />
              </>
            )}
          </ContactInterest.Feature>
        ))}
      {bedrooms && <Bedrooms bedrooms={bedrooms} />}
      {bathrooms && <Bathrooms bathrooms={bathrooms} />}
      {furnishedStatus?.length > 0 && (
        <Furnished furnishedStatus={furnishedStatus} />
      )}
      <Areas
        usableArea={usableArea}
        floorArea={floorArea}
        plotArea={plotArea}
      />
    </Wrapper>
  );
}

function Bedrooms({ bedrooms: { minimum, maximum } }) {
  const intl = useIntl();
  const bedrooms = minimum === maximum ? minimum : `${minimum} - ${maximum}`;
  return (
    <ContactInterest.Feature icon={<Icon glyph="bedroom" />}>
      {`${bedrooms} ${intl.formatMessage({
        id: 'leadpage_enquiry_attribute_bedrooms',
      })}`}
    </ContactInterest.Feature>
  );
}

function Bathrooms({ bathrooms: { minimum, maximum } }) {
  const intl = useIntl();
  const bathrooms = minimum === maximum ? minimum : `${minimum} - ${maximum}`;
  return (
    <ContactInterest.Feature icon={<Icon glyph="bathtub" />}>
      {`${bathrooms} ${intl.formatMessage({
        id: 'leadpage_enquiry_attribute_bathrooms',
      })}`}
    </ContactInterest.Feature>
  );
}

function Furnished({ furnishedStatus }) {
  const intl = useIntl();
  const status = furnishedStatus.map((x) =>
    intl.formatMessage({ id: furnishedTranslationTags.get(x) })
  );
  return (
    <ContactInterest.Feature icon={<Icon glyph="sofa" />}>
      {status.join(', ')}
    </ContactInterest.Feature>
  );
}

function Areas({ usableArea, floorArea, plotArea }) {
  const hasValue = (area) => area && Object.entries(area).length > 0;
  if (hasValue(usableArea)) {
    return <Area area={usableArea} />;
  }
  if (hasValue(floorArea)) {
    return <Area area={floorArea} />;
  }
  if (hasValue(plotArea)) {
    return <Area area={plotArea} />;
  }
  return null;
}

function Area({ area }) {
  return Object.entries(area).map(([unit, { minimum, maximum }]) => (
    <ContactInterest.Feature key={unit} icon={<Icon glyph="squareFoot" />}>
      {minimum === maximum
        ? `${minimum} ${unit}`
        : `${minimum} - ${maximum} ${unit}`}
    </ContactInterest.Feature>
  ));
}

function Skeleton() {
  return (
    <SkeletonWrapper>
      <SkeletonBone $width="146px" $height="14px" />
      <SkeletonBone $width="120px" $height="14px" />
      <SkeletonBone $width="146px" $height="14px" />
      <SkeletonBone $width="154px" $height="14px" />
    </SkeletonWrapper>
  );
}

Interest.Skeleton = Skeleton;

export default styled(Interest)``;
