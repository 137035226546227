import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { divider, DIVIDER_SIZE } from 'design-system/styles/divider';
import { useSelector } from 'reffects-store';
import { PrimaryButton } from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import Illustration from 'design-system/components/Illustration/Illustration';
import ActionCard from '../../../partials/ActionCard';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { isDeveloperSelector } from '../../../selectors/publisher';

const Wrapper = styled.div`
  ${divider.bottom(DIVIDER_SIZE.THIN)}
  ${spacing.stack(SPACING_SIZE.XL, false)}
  
  > * {
    ${spacing.stack(SPACING_SIZE.L, false)}
  }
`;

export default function EmptyState({ onEditDescription }) {
  const isDeveloper = useSelector(isDeveloperSelector);

  return (
    <Wrapper>
      <ActionCard
        illustration={<Illustration name="edit" />}
        title={
          <FormattedMessageWithValidation
            id={
              isDeveloper
                ? 'company_profile_edit_company_description_empty_state_text_developers'
                : 'company_profile_edit_company_description_empty_state_text'
            }
          />
        }
        cta={
          <PrimaryButton $size={BUTTON_SIZE.SMALL} onClick={onEditDescription}>
            <FormattedMessageWithValidation id="company_profile_edit_company_description_empty_state_cta" />
          </PrimaryButton>
        }
      />
    </Wrapper>
  );
}
