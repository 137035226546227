import { FEATURES_VALUES } from './constants';

export default function featureFlagsState(features) {
  return {
    [FEATURES_VALUES]: features.reduce((acc, feature) => {
      acc[feature.name] = {
        enabled: feature.enabled,
        countries: feature.countries,
      };
      return acc;
    }, {}),
  };
}
