import { useSelector } from 'reffects-store';
import { useCallback } from 'react';
import { dispatch } from 'reffects';
import {
  areAllVisibleItemsSelectedSelector,
  areSomeVisibleItemsSelectedSelector,
  visibleSelectedItemsCountSelector,
} from '../selectors/selection';
import { useDatatableContext } from './useDatatableContext';
import {
  DATA_TABLE_SELECT_ALL_VISIBLE_ITEMS,
  DATA_TABLE_UNSELECT_ALL_VISIBLE_ITEMS,
} from '../events/selection';

export function useColumnSelection() {
  const { collectionName } = useDatatableContext();

  const selectedItemsCount = useSelector((state) =>
    visibleSelectedItemsCountSelector(state, { collectionName })
  );

  const areAllSelected = useSelector((state) =>
    areAllVisibleItemsSelectedSelector(state, { collectionName })
  );

  const areSomeSelected = useSelector((state) =>
    areSomeVisibleItemsSelectedSelector(state, { collectionName })
  );

  const toggleAllItemsSelection = useCallback(() => {
    if (areAllSelected) {
      dispatch({
        id: DATA_TABLE_UNSELECT_ALL_VISIBLE_ITEMS,
        payload: { collectionName },
      });
    } else {
      dispatch({
        id: DATA_TABLE_SELECT_ALL_VISIBLE_ITEMS,
        payload: { collectionName },
      });
    }
  }, [areAllSelected, collectionName]);

  return {
    selectedItemsCount,
    areAllSelected,
    areSomeSelected,
    toggleAllItemsSelection,
  };
}
