import * as React from "react";
const SvgVisaCreditCard = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={38}
    fill="none"
    {...props}
  >
    <rect width={63} height={37} x={0.5} y={0.5} stroke="#E6E6E6" rx={0.5} />
    <path
      fill="#0742A6"
      fillRule="evenodd"
      d="M28.935 25.574h-3.54l2.215-13.332h3.54zm12.832-13.006A8.9 8.9 0 0 0 38.592 12c-3.495 0-5.957 1.816-5.972 4.41-.029 1.916 1.762 2.98 3.102 3.618 1.37.652 1.835 1.078 1.835 1.66-.014.893-1.106 1.305-2.125 1.305-1.414 0-2.17-.213-3.322-.71l-.466-.213-.495 2.993c.83.368 2.36.695 3.948.71 3.714 0 6.132-1.788 6.16-4.553.015-1.518-.931-2.68-2.97-3.63-1.239-.61-1.997-1.023-1.997-1.647.014-.567.641-1.148 2.039-1.148a6.15 6.15 0 0 1 2.637.51l.32.142z"
      clipRule="evenodd"
    />
    <path
      fill="#0742A6"
      d="M46.472 20.85c.291-.765 1.413-3.73 1.413-3.73-.015.03.29-.78.466-1.276l.247 1.15s.67 3.19.816 3.857h-2.942m4.369-8.608h-2.738c-.844 0-1.486.24-1.85 1.106l-5.258 12.226h3.714l.743-2h4.545c.101.468.422 2 .422 2h3.278z"
    />
    <path
      fill="#0742A6"
      fillRule="evenodd"
      d="m22.439 12.242-3.466 9.091-.38-1.844c-.64-2.127-2.65-4.439-4.893-5.588l3.175 11.66h3.743l5.564-13.319z"
      clipRule="evenodd"
    />
    <path
      fill="#0742A6"
      fillRule="evenodd"
      d="M15.753 12.242h-5.694l-.059.27c4.443 1.106 7.385 3.772 8.594 6.978l-1.238-6.127c-.204-.852-.83-1.093-1.602-1.12z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgVisaCreditCard;
