import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { updateBrowserUrl } from '../../../../effects/routing';
import {
  SUBSCRIPTION,
  SUBSCRIPTION_BILLING,
} from '../../../../constants/routes';

export const SWITCH_BUTTON_CLICKED = 'SWITCH_BUTTON_CLICKED';
export const SUBSCRIPTION_TAB = 'subscription';
export const INVOICES_TAB = 'invoices';

const subscriptionTabs = {
  [SUBSCRIPTION_TAB]: {
    name: SUBSCRIPTION_TAB,
    url: SUBSCRIPTION,
  },
  [INVOICES_TAB]: {
    name: INVOICES_TAB,
    url: SUBSCRIPTION_BILLING,
  },
};

registerEventHandler(SWITCH_BUTTON_CLICKED, (_, { currentTab }) => {
  const newTab =
    currentTab === SUBSCRIPTION_TAB
      ? subscriptionTabs[INVOICES_TAB]
      : subscriptionTabs[SUBSCRIPTION_TAB];

  return {
    ...state.set({ 'subscriptionPage:showTab': newTab.name }),
    ...updateBrowserUrl(newTab.url),
  };
});
