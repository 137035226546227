import { debounce } from 'lodash';
import { useCallback } from 'react';
import InputNumber from 'design-system/components/InputNumber';

const DEBOUNCE_INPUT_CHANGE_MILIS = 800;

export function MaxSuperboostablePropertiesSelect({
  selectedMaxSuperboostable,
  onInputChange,
  hasError,
  errorMessage,
}) {
  const debounceInputChange = useCallback(
    debounce(onInputChange, DEBOUNCE_INPUT_CHANGE_MILIS),
    [onInputChange]
  );

  return (
    <InputNumber
      label="Superboostable Properties"
      placeholder="Superboostable Properties"
      value={selectedMaxSuperboostable}
      onChange={debounceInputChange}
      error={hasError}
      tip={errorMessage}
    />
  );
}
