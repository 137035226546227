import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Grid from 'design-system/components/Grid';
import DeveloperKeyResults from './KeyResults/DeveloperKeyResults';
import { ProjectsOverview } from './ProjectsOverview/ProjectsOverview';

const Wrapper = styled.div`
  ${spacing.stack(SPACING_SIZE.XL)}
`;

export function DeveloperStats() {
  return (
    <Wrapper>
      <Grid
        areas={({ repeat }) => `
          "${repeat(4, 'keyResults')} ${repeat(8, 'projectsSummary')}"
        `}
      >
        <Grid.Area $id="keyResults">
          <DeveloperKeyResults />
        </Grid.Area>
        <Grid.Area $id="projectsSummary">
          <ProjectsOverview />
        </Grid.Area>
      </Grid>
    </Wrapper>
  );
}
