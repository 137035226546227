import Dialog, {
  DIALOG_THEME,
  FOOTER_CONTENT_POSITION,
} from 'design-system/components/Dialog/Dialog';
import { PrimaryButton } from 'design-system/components/Button/presets';
import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { dispatch } from 'reffects';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { divider, DIVIDER_SIZE } from 'design-system/styles/divider';
import Typography from 'design-system/components/Typography/Typography';
import { useDialog } from '../../../hooks/useDialog';
import { FREE_PUBLISHER_OFFERS_MODAL } from './constants';
import FormattedMessageWithValidation from '../../FormattedMessageWithValidation/FormattedMessageWithValidation';
import PublisherSites from '../../PublisherSites';
import useTracking from '../../../hooks/useTracking';
import { GO_TO_MY_SUBSCRIPTION_PAGE } from './events';

const Wrapper = styled(Dialog.Content)`
  display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.L)};
  ${PublisherSites} {
    display: flex;
    flex-direction: row;
    gap: ${spacing.value(SPACING_SIZE.M)};
  }
`;

const Intro = styled(Typography)`
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
`;
const Closing = styled(Typography)`
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`;

const BulletWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing.value(SPACING_SIZE.S)};
  ${spacing.inset(SPACING_SIZE.S)};
  ${Typography} {
    display: flex;
    flex-direction: column;
    gap: ${spacing.value(SPACING_SIZE.XS)};
  }
`;

const Bullets = styled.div`
  display: flex;
  flex-direction: column;
  ${BulletWrapper}:not(:last-child) {
    ${divider.bottom(DIVIDER_SIZE.THIN)}
  }
  ${Typography} {
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
  }
`;

function Bullet({ tag, children }) {
  return (
    <BulletWrapper>
      <Icon glyph="check" $size={ICON_SIZE.SMALL} />
      <Typography>
        <FormattedMessageWithValidation id={tag} />
        {children}
      </Typography>
    </BulletWrapper>
  );
}

function DialogContent() {
  const ref = useTracking({
    trackImpression: {
      elementName: 'free-publisher-offers-modal',
    },
    sectionName: 'free-publisher-offers-modal',
  });
  return (
    <Wrapper ref={ref}>
      <Intro>
        <FormattedMessageWithValidation id="subscription_landing_modal_body_intro" />
      </Intro>
      <Bullets>
        <Bullet tag="subscription_landing_modal_body_bullet_1" />
        <Bullet tag="subscription_landing_modal_body_bullet_2" />
        <Bullet tag="subscription_landing_modal_body_bullet_3">
          <PublisherSites />
        </Bullet>
      </Bullets>
      <Closing>
        <FormattedMessageWithValidation id="subscription_landing_modal_body_closing" />
      </Closing>
    </Wrapper>
  );
}

export default function FreePublisherOffersModal() {
  const { open, closeDialog } = useDialog(FREE_PUBLISHER_OFFERS_MODAL);
  return (
    <Dialog open={open} theme={DIALOG_THEME.DARK}>
      <Dialog.Header onClose={closeDialog}>
        <FormattedMessageWithValidation id="subscription_landing_modal_header" />
      </Dialog.Header>
      {open && <DialogContent />}
      <Dialog.Footer alignment={FOOTER_CONTENT_POSITION.START}>
        <PrimaryButton onClick={() => dispatch(GO_TO_MY_SUBSCRIPTION_PAGE)}>
          <FormattedMessageWithValidation id="subscription_landing_modal_cta" />
        </PrimaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}
