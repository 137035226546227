import {
  BANK_TRANSFER,
  CASH,
  CREDIT_CARD,
  DIRECT_DEBIT,
  IN_APP,
  PAYMENT_METHODS,
} from '../../constants/paymentMethod';
import { NO_FILTER_OPTION } from '../pages/partials/Filters/constants';

export const PAYMENT_METHODS_LABELS = {
  [BANK_TRANSFER]: 'Bank transfer',
  [CASH]: 'Cash',
  [CREDIT_CARD]: 'Credit card',
  [DIRECT_DEBIT]: 'Direct debit',
  [IN_APP]: 'In app',
};

export function paymentMethodFilterOptions() {
  return [
    {
      id: NO_FILTER_OPTION,
      value: null,
      label: 'All',
    },
    ...PAYMENT_METHODS.map((paymentMethod) => ({
      id: paymentMethod,
      value: paymentMethod,
      label: PAYMENT_METHODS_LABELS[paymentMethod],
    })),
  ];
}
