import { useCallback, useState } from 'react';
import { noop } from '../../utils/noop';

const emptyArray = [];

const sanitizeValue = (value, charPressed) => {
  if (!charPressed) {
    return value;
  }

  return value.replace(new RegExp(charPressed, 'g'), '');
};

export function useInputChips({
  deleteKeyCodes = emptyArray,
  addKeyCodes = emptyArray,
  values = emptyArray,
  onChange = noop,
}) {
  const [draftValue, setDraftValue] = useState('');
  const [isLastSelected, selectLast] = useState(false);

  const submitDraftValue = useCallback(
    (charPressed) => {
      const sanitizedValue = sanitizeValue(draftValue, charPressed);
      if (sanitizedValue) {
        onChange([...values, sanitizedValue]);
      }

      setDraftValue('');
    },
    [values, onChange, draftValue]
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (
        addKeyCodes.includes('Enter') &&
        e.code === 'Enter' &&
        e.target.value !== ''
      ) {
        e.preventDefault();
      }

      if (deleteKeyCodes.includes(e.code) && e.target.value === '') {
        if (isLastSelected) {
          onChange(values.slice(0, -1));
        }

        selectLast(true);
      } else {
        selectLast(false);
      }
    },
    [isLastSelected, onChange]
  );

  const handleChange = useCallback((e) => {
    setDraftValue(e.target.value);
  }, []);

  const handleKeyUp = useCallback(
    (e) => {
      if (addKeyCodes.includes(e.code)) {
        submitDraftValue(e.key);
      }
    },
    [addKeyCodes, submitDraftValue]
  );

  const handleBlur = useCallback(() => {
    submitDraftValue();
  }, [submitDraftValue]);

  const deleteChipByIndex = useCallback(
    (index) => {
      onChange([...values.slice(0, index), ...values.slice(index + 1)]);
    },
    [values, onChange]
  );

  return {
    isLastSelected,
    deleteChipByIndex,
    inputProps: {
      value: draftValue,
      onKeyDown: handleKeyDown,
      onChange: handleChange,
      onKeyUp: handleKeyUp,
      onBlur: handleBlur,
    },
  };
}
