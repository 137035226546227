import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { environment } from '../../coeffects/environment';
import { redirectTo } from '../../effects/routing';

export const PROPERTY_LINKS_REQUESTED = 'PROPERTY_LINKS_REQUESTED';
export const PROPERTY_LINKS_LOADED = 'PROPERTY_LINKS_LOADED';
export const PROPERTY_LINKS_LOAD_FAILED = 'PROPERTY_LINKS_LOAD_FAILED';

registerEventHandler(
  PROPERTY_LINKS_REQUESTED,
  ({ environment: { apiUrl } }, { adId }) =>
    http.get({
      url: `${apiUrl}/property-links/${adId}`,
      successEvent: PROPERTY_LINKS_LOADED,
      errorEvent: PROPERTY_LINKS_LOAD_FAILED,
    }),
  [environment()]
);

registerEventHandler(PROPERTY_LINKS_LOADED, (_, [{ data }]) =>
  data.length === 1
    ? redirectTo(data[0].link)
    : state.set({
        'propertyLinks:value': data,
        'propertyLinks:failed': undefined,
      })
);

registerEventHandler(PROPERTY_LINKS_LOAD_FAILED, () =>
  state.set({
    'propertyLinks:failed': true,
  })
);
