import styled from 'styled-components';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { border, BORDER_SIZE } from 'design-system/styles/border';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { Heading2 } from 'design-system/components/Typography/presets/Heading2';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ fullWidth }) => !fullWidth && 'width: 295px;'}
  ${spacing.insetStretch(SPACING_SIZE.M)}
  ${border.outer({ size: BORDER_SIZE.BASE, color: COLOR_PALETTE.NEUTRAL_A10 })}
  ${radius.regular(RADIUS_SIZE.LARGE)}
  ${Icon} {
    ${Icon.mods.size(ICON_SIZE.BASE)}
    ${spacing.inline(SPACING_SIZE.S)}
  }
`;

function Card({ children, glyph, ...props }) {
  return (
    <Wrapper {...props}>
      <Icon glyph={glyph} />
      <Heading2>{children}</Heading2>
    </Wrapper>
  );
}

export default styled(Card)``;
