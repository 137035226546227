import { coeffect, registerCoeffectHandler } from 'reffects';
import {
  getFeatureForPublisherCountrySelector,
  getFeatureSelector,
} from '../utils/featuresFlags/selectors';

export default function registerFeatureFlagsCoeffectHandler(store) {
  function feature(valueForFlag, keyName) {
    return (names) => {
      const fullState = store.getState();
      let featureNames = names;
      if (!Array.isArray(names)) {
        featureNames = [names];
      }
      return featureNames.reduce(
        function reducer(acc, name) {
          acc[keyName][name] = valueForFlag(fullState, name);
          return acc;
        },
        { [keyName]: {} }
      );
    };
  }

  registerCoeffectHandler('features', feature(getFeatureSelector, 'features'));
  registerCoeffectHandler(
    'featuresByCountry',
    feature(getFeatureForPublisherCountrySelector, 'featuresByCountry')
  );
}

export function featureFlags(names) {
  return coeffect('features', names);
}

export function featureFlagsByCountry(names) {
  return coeffect('featuresByCountry', names);
}
