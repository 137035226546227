import { dispatch, dispatchMany } from 'reffects';
import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { useMount } from 'react-use';
import { useSelector } from 'reffects-store';
import Dialog from 'design-system/components/Dialog/Dialog';
import {
  FlatButton,
  SecondaryButton,
} from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import { useDialog } from '../../../../../../../hooks/useDialog';
import { ASSIGN_DEAL_MODAL_SUBMITTED } from './events';
import DealOwner from '../../../../../partials/DealOwner';
import { SALES_PEOPLE_REQUESTED } from '../../../../../events';
import DealsAutocomplete from '../../../../../partials/DealsAutocomplete/DealsAutocomplete';
import { DEALS_REQUESTED } from '../../../../../partials/DealsAutocomplete/events';
import { ASSIGN_DEAL_MODAL, ASSIGN_DEAL_MODAL_STATE_REGION } from './constants';
import { selectedDealSelector } from '../../../../../partials/DealsAutocomplete/selectors';
import {
  displayableDealOnEditSelector,
  isAssignModalSubmitButtonEnabledSelector,
  selectedSalesPersonEmailSelector,
} from './selectors';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    ${spacing.stack(SPACING_SIZE.S)}
  }
`;

function AssignDealModal({ onSuccessEvent }) {
  const {
    open,
    closeDialog,
    parameters: { publisherId } = {},
  } = useDialog(ASSIGN_DEAL_MODAL);

  useMount(() => dispatchMany([DEALS_REQUESTED, SALES_PEOPLE_REQUESTED]));
  const selectedDeal = useSelector((state) =>
    selectedDealSelector(state, ASSIGN_DEAL_MODAL_STATE_REGION)
  );
  const displayableValue = useSelector(displayableDealOnEditSelector);
  const selectedSalesPersonEmail = useSelector(
    selectedSalesPersonEmailSelector
  );
  const isAssignModalSubmitButtonEnabled = useSelector(
    isAssignModalSubmitButtonEnabledSelector
  );

  return (
    <Dialog open={open}>
      <Dialog.Header onClose={closeDialog}>
        Associate Hubspot deal
      </Dialog.Header>
      <Dialog.Content>
        <Wrapper>
          <DealsAutocomplete
            stateRegion={ASSIGN_DEAL_MODAL_STATE_REGION}
            displayableValue={displayableValue}
          />
          <DealOwner
            deal={selectedDeal}
            salesPersonEmail={selectedSalesPersonEmail}
          />
        </Wrapper>
      </Dialog.Content>
      <Dialog.Footer>
        <FlatButton onClick={closeDialog} $size={BUTTON_SIZE.SMALL}>
          Cancel
        </FlatButton>
        <SecondaryButton
          onClick={() =>
            dispatch({
              id: ASSIGN_DEAL_MODAL_SUBMITTED,
              payload: {
                publisherId,
                dealId: selectedDeal.id,
                onSuccessEvent,
              },
            })
          }
          disabled={!isAssignModalSubmitButtonEnabled}
          $size={BUTTON_SIZE.SMALL}
        >
          Associate
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}

export default AssignDealModal;
