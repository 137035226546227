import styled from 'styled-components';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import {
  FlatButton,
  OutlineButton,
} from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';
import InputTip from 'design-system/components/InputTip';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Overline } from 'design-system/components/Typography/presets/Overline';
import {
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import {
  ADD_NEW_INVOICING_PERIOD,
  REMOVE_INVOICING_PERIOD,
  UPDATE_INVOICING_PERIOD,
} from '../events';
import {
  canAddInvoicingPeriodSelector,
  invoicingPeriodErrorSelector,
  invoicingPeriodsSelector,
} from '../selectors';
import { CampaignDateInput } from '../../partials/CampaignDateInput';
import { CampaignPriceInput } from '../../partials/CampaignPriceInput';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: ${spacing.value(SPACING_SIZE.S)};
`;

const Text = styled(Overline)`
  ${typography.size(FONT_SIZE.S)}
  opacity: 0.54;
  ${typography.font(FONT_FAMILY.PRIMARY)}
  ${typography.weight(FONT_WEIGHT.NORMAL)}
`;

const InvoicingList = styled.div`
  width: 100%;
`;

const Row = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  > ${FlatButton} {
    margin-top: ${spacing.value(SPACING_SIZE.M)};
  }
`;

const ErrorWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export default function InvoicingPeriod({ currency }) {
  const invoicings = useSelector(invoicingPeriodsSelector);
  const canAddInvoicingPeriod = useSelector(canAddInvoicingPeriodSelector);
  const error = useSelector(invoicingPeriodErrorSelector);

  const handleNewInvoicingPeriod = () => {
    dispatch(ADD_NEW_INVOICING_PERIOD);
  };

  const handleRemoveInvoicingPeriod = (invoiceId) => {
    dispatch({ id: REMOVE_INVOICING_PERIOD, payload: { invoiceId } });
  };

  const handleOnChange = (invoiceId, name) => (value) => {
    dispatch({
      id: UPDATE_INVOICING_PERIOD,
      payload: { invoiceId, target: { name, value } },
    });
  };

  return (
    <Wrapper>
      <Text>Invoicing Period</Text>
      <InvoicingList>
        {invoicings.map((invoicing) => (
          <Row key={invoicing.id}>
            <CampaignDateInput
              name="startDate"
              label="Start Date"
              value={invoicing.date}
              onChange={handleOnChange(invoicing.id, 'date')}
              required
            />
            <Row>
              <CampaignPriceInput
                id="invoicingAmount"
                name="invoicingAmount"
                label="Amount"
                value={invoicing.amount}
                currency={currency}
                onChange={handleOnChange(invoicing.id, 'amount')}
                required
              />
              <FlatButton
                icon={<Icon glyph="trash" />}
                onClick={() => handleRemoveInvoicingPeriod(invoicing.id)}
              />
            </Row>
          </Row>
        ))}
      </InvoicingList>
      <ErrorWrapper>{error && <InputTip error>{error}</InputTip>}</ErrorWrapper>
      <OutlineButton
        disabled={!canAddInvoicingPeriod}
        onClick={handleNewInvoicingPeriod}
      >
        Add new
      </OutlineButton>
    </Wrapper>
  );
}
