import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import Table from 'design-system/components/Table';
import { tableSelector } from '../../../Tables/selectors';
import { UNITS_TABLE_NAME } from '../../constants';
import {
  UNITS_TABLE_PAGE_CHANGED,
  UNITS_TABLE_ROWS_PER_PAGE_CHANGED,
} from './events';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import RowsLabel from '../../../Tables/RowsLabel';

export function Pagination() {
  const { pagination } = useSelector((state) =>
    tableSelector(state, UNITS_TABLE_NAME)
  );

  const handleRowsPerPageChange = (rowsPerPage) =>
    dispatch({
      id: UNITS_TABLE_ROWS_PER_PAGE_CHANGED,
      payload: rowsPerPage,
    });

  const handlePageChange = (page) =>
    dispatch({ id: UNITS_TABLE_PAGE_CHANGED, payload: page });

  return (
    <Table.Pagination
      {...pagination}
      rowsPerPageLabel={
        <FormattedMessageWithValidation id="enqpage_table_rows" />
      }
      displayRowsLabel={(currentRowRange, totalRows) => (
        <RowsLabel currentRowRange={currentRowRange} totalRows={totalRows} />
      )}
      onPaginate={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
    />
  );
}
