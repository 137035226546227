import Icon from 'design-system/components/Icon';
import Section from './Section';
import Subtitle from './Subtitle';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import AmenityGrid from './AmenityGrid/AmenityGrid';
import { getAmenityIconName } from '../../../../../utils/ad';
import { nearbyLocationsTranslationTags } from '../../../../../constants/ad';

function NearbyLocations({ nearbyLocations }) {
  if (nearbyLocations.length === 0) {
    return null;
  }
  return (
    <Section>
      <Subtitle>
        <FormattedMessageWithValidation id="newprop_surroundings_header" />
      </Subtitle>
      <AmenityGrid>
        {nearbyLocations.map((nearbyLocation) => (
          <AmenityGrid.Item
            key={nearbyLocation}
            icon={<Icon glyph={getAmenityIconName(nearbyLocation)} />}
          >
            <FormattedMessageWithValidation
              id={nearbyLocationsTranslationTags.get(nearbyLocation)}
            />
          </AmenityGrid.Item>
        ))}
      </AmenityGrid>
    </Section>
  );
}

export default NearbyLocations;
