import styled from 'styled-components';
import { dispatch } from 'reffects';
import { useEffect } from 'react';
import { useSelector } from 'reffects-store';
import { FormattedNumber } from 'react-intl';
import Tile from 'design-system/components/Tile';
import SkeletonBone from 'design-system/components/SkeletonBone';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import { STATS_CARDS_REQUESTED } from './events';
import { useFilters } from '../../../../../../partials/DataTable/hooks/useFilter';
import { agencySummaryStatsSelector } from './selectors';

const Wrapper = styled.div`
  display: flex;
`;

const StatWrapper = styled(Tile)`
  display: flex;
  flex-basis: 200px;
  align-items: baseline;

  > * {
    ${spacing.inline(SPACING_SIZE.S)}
  }
`;

function Stat({ label, value }) {
  return (
    <StatWrapper>
      {value != null ? (
        <Body1>
          <b>
            <FormattedNumber value={value} />
          </b>
        </Body1>
      ) : (
        <SkeletonBone $width="16px" $height="16px" />
      )}
      <Body2>{label}</Body2>
    </StatWrapper>
  );
}

export default function StatCards() {
  const { filters } = useFilters();
  useEffect(() => {
    dispatch({
      id: STATS_CARDS_REQUESTED,
      payload: { filters },
    });
  }, [filters]);
  const stats = useSelector(agencySummaryStatsSelector);

  return (
    <Wrapper>
      <Stat label="Renewals this month" value={stats.renewalsMonth} />
      <Stat label="Renewals this week" value={stats.renewalsWeek} />
      <Stat label="Paid publishers" value={stats.paidAgencies} />
      <Stat label="Free publishers" value={stats.freeAgencies} />
    </Wrapper>
  );
}
