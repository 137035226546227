import styled from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { divider, DIVIDER_SIZE } from 'design-system/styles/divider';
import SkeletonBone from 'design-system/components/SkeletonBone';
import { border, BORDER_SIZE } from 'design-system/styles/border';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';

const Wrapper = styled.div`
  margin: ${spacing.value(SPACING_SIZE.XL)};
  display: flex;
  flex-direction: column;
  ${radius.regular(RADIUS_SIZE.LARGE)}
  ${border.outer({ color: COLOR_PALETTE.NEUTRAL_A10, size: BORDER_SIZE.BASE })}
`;

const Heading = styled.div`
  height: 48px;
  ${divider.bottom(DIVIDER_SIZE.THIN)}
  ${spacing.inset(SPACING_SIZE.M)}
  ${SkeletonBone} {
    ${spacing.inline(SPACING_SIZE.M)}
  }
`;

const To = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  ${divider.bottom(DIVIDER_SIZE.THIN)}
  ${spacing.inset(SPACING_SIZE.M)}
  ${SkeletonBone} {
    ${spacing.inline(SPACING_SIZE.S, false)}
  }
`;

const Email = styled.div`
  width: 124px;
  height: 30px;
  ${color.background(COLOR_PALETTE.NEUTRAL_A10)}
  border-radius: 4px;
  border: 8px solid ${COLOR_PALETTE.NEUTRAL_A05};
`;

const Content = styled.div`
  height: 98px;
  ${spacing.inset(SPACING_SIZE.M)}

  ${SkeletonBone} {
    ${spacing.inline(SPACING_SIZE.S)}
  }

  > div {
    ${spacing.stack(SPACING_SIZE.L)}
    &:last-child {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

const Button = styled.div`
  width: 112px;
  height: 32px;
  ${color.background(COLOR_PALETTE.NEUTRAL_A10)}
  border-radius: 16px;
  border-width: 10px 16px;
  border-style: solid;
  border-color: ${COLOR_PALETTE.NEUTRAL_A05};
`;

export function Skeleton() {
  return (
    <Wrapper>
      <Heading>
        <SkeletonBone $width="60px" $height="12px" />
        <SkeletonBone $width="44px" $height="12px" />
      </Heading>
      <To>
        <SkeletonBone $width="33px" $height="14px" />
        <Email />
      </To>
      <Content>
        <div>
          <SkeletonBone $width="120px" $height="14px" />
        </div>
        <div>
          <div>
            <SkeletonBone $width="23px" $height="20px" />
            <SkeletonBone $width="23px" $height="20px" />
            <SkeletonBone $width="23px" $height="20px" />
          </div>
          <Button />
        </div>
      </Content>
    </Wrapper>
  );
}
