export function showLeadQualityAwarenessBannerSelector(state) {
  return Boolean(
    verifiedLeadsStatsSelector(state).visible &&
      verifiedLeadsStatsSelector(state).aboveThreshold
  );
}

export function verifiedLeadsStatsSelector(state) {
  return state.verifiedLeadsStats ?? {};
}

export function couldShowBannerSelector(state) {
  return state.verifiedLeadsStats !== undefined;
}
