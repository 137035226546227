import * as React from "react";
const SvgInboxEnvelope = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={272}
    height={161}
    fill="none"
    {...props}
  >
    <path
      fill="#0ACC86"
      fillRule="evenodd"
      d="M127.849 29.645a12.59 12.59 0 0 1 16.158-.121l.144.12 55.707 47.329a4.06 4.06 0 0 1 0 6.188l-55.707 47.328a12.59 12.59 0 0 1-16.302 0L72.142 83.161a4.06 4.06 0 0 1 0-6.188z"
      clipRule="evenodd"
      opacity={0.2}
    />
    <path
      stroke="#0ACC86"
      strokeWidth={2}
      d="M128.768 29.656a11.174 11.174 0 0 1 14.334-.109l.002.002.128.107.006.005 54.549 46.344a7 7 0 0 1 2.467 5.335v75.6a3.06 3.06 0 0 1-3.06 3.06H74.806a3.06 3.06 0 0 1-3.06-3.06v-75.6a7 7 0 0 1 2.467-5.335z"
    />
    <path
      fill="#fff"
      stroke="#0ACC86"
      strokeWidth={2}
      d="M74.761 158.9a.624.624 0 0 0 .404 1.1h121.67c.58 0 .846-.724.404-1.1l-54-45.877a11.18 11.18 0 0 0-14.478 0z"
    />
    <mask id="InboxEnvelope_svg__a" fill="#fff">
      <path d="M7.5 0a7 7 0 0 0-7 7v41a7 7 0 0 0 7 7h65.077L84.5 69l16.751-14H111.5a7 7 0 0 0 7-7V7a7 7 0 0 0-7-7z" />
    </mask>
    <path
      fill="#fff"
      d="M7.5 0a7 7 0 0 0-7 7v41a7 7 0 0 0 7 7h65.077L84.5 69l16.751-14H111.5a7 7 0 0 0 7-7V7a7 7 0 0 0-7-7z"
    />
    <path
      fill="#0ACC86"
      d="m72.577 55 1.522-1.297L73.5 53h-.923zM84.5 69l-1.523 1.297 1.285 1.508 1.52-1.27zm16.751-14v-2h-.726l-.557.465zM2.5 7a5 5 0 0 1 5-5v-4a9 9 0 0 0-9 9zm0 41V7h-4v41zm5 5a5 5 0 0 1-5-5h-4a9 9 0 0 0 9 9zm65.077 0H7.5v4h65.077zm13.446 14.703-11.924-14-3.045 2.594 11.923 14zm13.945-14.238-16.75 14 2.565 3.07 16.751-14zM111.5 53h-10.249v4H111.5zm5-5a5 5 0 0 1-5 5v4a9 9 0 0 0 9-9zm0-41v41h4V7zm-5-5a5 5 0 0 1 5 5h4a9 9 0 0 0-9-9zM7.5 2h104v-4H7.5z"
      mask="url(#InboxEnvelope_svg__a)"
    />
    <circle
      cx={23.5}
      cy={23}
      r={5}
      fill="#F8F8F9"
      stroke="#0ACC86"
      strokeWidth={2}
    />
    <path fill="#0ACC86" d="M35.5 21h60v4h-60zM17.5 35h86v4h-86z" />
    <mask id="InboxEnvelope_svg__b" fill="#fff">
      <path d="M256.5 35a7 7 0 0 1 7 7v41a7 7 0 0 1-7 7h-64l-17 14-12.889-14H152.5a7 7 0 0 1-7-7V42a7 7 0 0 1 7-7z" />
    </mask>
    <path
      fill="#fff"
      d="M256.5 35a7 7 0 0 1 7 7v41a7 7 0 0 1-7 7h-64l-17 14-12.889-14H152.5a7 7 0 0 1-7-7V42a7 7 0 0 1 7-7z"
    />
    <path
      fill="#0ACC86"
      d="m192.5 90-1.272-1.544.554-.456h.718zm-17 14 1.271 1.544-1.461 1.203-1.282-1.392zm-12.889-14v-2h.877l.594.645zM261.5 42a5 5 0 0 0-5-5v-4a9 9 0 0 1 9 9zm0 41V42h4v41zm-5 5a5 5 0 0 0 5-5h4a9 9 0 0 1-9 9zm-64 0h64v4h-64zm-18.272 14.456 17-14 2.543 3.088-17 14zm-10.146-13.81 12.889 13.999-2.943 2.71-12.889-14zM152.5 88h10.111v4H152.5zm-5-5a5 5 0 0 0 5 5v4a9 9 0 0 1-9-9zm0-41v41h-4V42zm5-5a5 5 0 0 0-5 5h-4a9 9 0 0 1 9-9zm104 0h-104v-4h104z"
      mask="url(#InboxEnvelope_svg__b)"
    />
    <circle
      cx={167.5}
      cy={58}
      r={5}
      fill="#F8F8F9"
      stroke="#0ACC86"
      strokeWidth={2}
    />
    <path fill="#0ACC86" d="M179.5 56h60v4h-60zM161.5 70h86v4h-86z" />
  </svg>
);
export default SvgInboxEnvelope;
