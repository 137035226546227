import ChangePaymentMethodModal from './ChangePaymentMethodModal/ChangePaymentMethodModal';
import RequestSubscriptionChangeModal from './RequestSubscriptionChangeModal/RequestSubscriptionChangeModal';
import SubscriptionChangeRequestSummaryModal from './SubscriptionChangeRequestSummaryModal/SubscriptionChangeRequestSummaryModal';
import DisallowedActionModal from './DisallowedActionModal';
import { BillingEntityModal } from './BillingEntityModal/BillingEntityModal';
import useUpdateTableEvent from '../../../../../../partials/DataTable/hooks/useUpdateTableEvent';
import EditListingRulesModal from './EditListingRulesModal/EditListingRulesModal';
import AssignDealModal from './AssignDealModal/AssignDealModal';
import ChangeSubscriptionModal from './ChangeSubscriptionModal/ChangeSubscriptionModal';
import { ConfirmSubscriptionChangesModal } from './ChangeSubscriptionModal/ConfirmSubscriptionChangesModal';
import SendPropertiesToWasiModal from './SendPropertiesToWasiModal/SendPropertiesToWasiModal';
import { CancelSubscriptionRequestModal } from './CancelSubscriptionRequestModal/CancelSubscriptionRequestModal';

export default function Modals() {
  const updateTableEvent = useUpdateTableEvent();

  return (
    <>
      <BillingEntityModal onSuccessEvent={updateTableEvent} />
      <ChangePaymentMethodModal onSuccessEvent={updateTableEvent} />
      <RequestSubscriptionChangeModal onSuccessEvent={updateTableEvent} />
      <ChangeSubscriptionModal onSuccessEvent={updateTableEvent} />
      <ConfirmSubscriptionChangesModal onSuccessEvent={updateTableEvent} />
      <SubscriptionChangeRequestSummaryModal
        onSuccessEvent={updateTableEvent}
      />
      <DisallowedActionModal />
      <EditListingRulesModal onSuccessEvent={updateTableEvent} />
      <AssignDealModal onSuccessEvent={updateTableEvent} />
      <SendPropertiesToWasiModal onSuccessEvent={updateTableEvent} />
      <CancelSubscriptionRequestModal onSuccessEvent={updateTableEvent} />
    </>
  );
}
