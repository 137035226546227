import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { dispatch } from 'reffects';
import styled from 'styled-components';
import Table from 'design-system/components/Table';
import Anchor from 'design-system/components/Anchor';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { useColumn } from '../../../../../../partials/DataTable/hooks/useColumn';
import {
  OPEN_BILLING_ENTITY_DIALOG,
  OPEN_BILLING_ENTITY_DIALOG_FOR_NO_ASSIGNED,
} from '../Modals/BillingEntityModal/events';

const ShowBillingEntity = styled(Anchor)`
  display: flex;
  justify-content: center;
  align-items: center;
  > * {
    ${spacing.inline(SPACING_SIZE.XS)}
  }
`;

export function BillingEntityColumn(props) {
  const {
    value: { name, publisherId, billingEntityExternalId, country: countryCode },
  } = useColumn(props);

  if (billingEntityExternalId == null) {
    return (
      <Table.Cell>
        <ShowBillingEntity
          as="button"
          type="button"
          onClick={() =>
            dispatch({
              id: OPEN_BILLING_ENTITY_DIALOG_FOR_NO_ASSIGNED,
              payload: {
                publisherId,
                countryCode,
              },
            })
          }
        >
          <span>Not associated</span>
          <Icon glyph="pencil" $size={ICON_SIZE.XSMALL} />
        </ShowBillingEntity>
      </Table.Cell>
    );
  }

  return (
    <Table.Cell maxWidth={300}>
      <ShowBillingEntity
        as="button"
        type="button"
        onClick={() => {
          dispatch({
            id: OPEN_BILLING_ENTITY_DIALOG,
            payload: {
              publisherId,
              countryCode,
            },
          });
        }}
      >
        <span>{name}</span>
        <Icon glyph="pencil" $size={ICON_SIZE.XSMALL} />
      </ShowBillingEntity>
    </Table.Cell>
  );
}
