import styled, { css } from 'styled-components';
import { iconography } from 'design-system/tokens/iconography';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { cloneElement } from 'react';
import { noop } from 'design-system/utils';
import { border } from 'design-system/styles/border';
import { increaseSpecificity } from 'design-system/styles/increaseSpecificity';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import {
  color,
  COLOR_PALETTE,
  COLOR_SEMANTIC,
} from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import {
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';

const modHover = () => css`
  ${color.background(COLOR_PALETTE.NEUTRAL_A03)}

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px ${COLOR_PALETTE.NEUTRAL_A03} inset !important;
  }
`;

const modTyping = () => css`
  ${border.inner({
    color: COLOR_PALETTE.NEUTRAL_A80,
    size: 2,
  })}
`;

const modDisabled = () => css`
  ${border.inner({
    color: COLOR_PALETTE.NEUTRAL_A20,
    size: 1,
  })}

  ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
  
  input {
    pointer-events: none;
    cursor: not-allowed;

    ${color.text(COLOR_PALETTE.NEUTRAL_A40)}

    &::placeholder {
      ${color.text(COLOR_PALETTE.NEUTRAL_A20)}
    }
  }

  > ${increaseSpecificity(Icon)} {
    ${Icon.mods.color(COLOR_PALETTE.NEUTRAL_A40)}
  }
`;

const modError = () => css`
  ${border.inner({
    color: COLOR_SEMANTIC.STATUS_ERROR,
  })}

  > ${increaseSpecificity(Icon)} {
    ${Icon.mods.color(COLOR_SEMANTIC.STATUS_ERROR)}
  }
`;

const modClickableIcon = () => css`
  > ${Icon} {
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  transition: background 300ms;

  ${border.inner({
    color: COLOR_PALETTE.NEUTRAL_A20,
    size: 1,
  })}
  display: flex;

  ${spacing.inset(SPACING_SIZE.M)}
  ${spacing.stack(SPACING_SIZE.S)}
  ${radius.regular(RADIUS_SIZE.BASE)}
  ${color.background(COLOR_PALETTE.NEUTRAL_A00)}

  input,
  textarea {
    width: 100%;
    min-height: ${iconography.size.small}px;
    outline: none;
    border: none;

    &::placeholder {
      ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
    }

    ${spacing.inset(SPACING_SIZE.NONE)}
    ${spacing.inline(SPACING_SIZE.M)}
    ${typography.font(FONT_FAMILY.PRIMARY)}
    ${typography.size(FONT_SIZE.M)}
    ${typography.weight(FONT_WEIGHT.LIGHT)}
    ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
    ${color.background(COLOR_PALETTE.NONE)}

    &:-webkit-autofill {
      transition: -webkit-box-shadow 300ms;
    }
  }

  input {
    ${typography.lineHeight(FONT_SIZE.M)}
  }

  > ${Icon} {
    ${Icon.mods.size(ICON_SIZE.SMALL)}
    ${Icon.mods.color(COLOR_PALETTE.NEUTRAL_A60)}
  }

  ${({ clickableIcon }) => clickableIcon && modClickableIcon()}
`;

function Box({ icon, children, onIconClick = noop, ...rest }) {
  return (
    <Wrapper clickableIcon={onIconClick !== noop} {...rest}>
      {children}
      {icon && cloneElement(icon, { onClick: onIconClick })}
    </Wrapper>
  );
}

Box.mods = {
  hover: modHover,
  typing: modTyping,
  disabled: modDisabled,
  error: modError,
};

export default styled(Box)``;
