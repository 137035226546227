import { useMatch } from 'react-router-dom';
import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import Banner, { BANNER_VARIANT } from 'design-system/components/Banner';
import Anchor from 'design-system/components/Anchor';
import { FONT_SIZE, typography } from 'design-system/styles/typography';
import { campaignsBannerSelector } from './selectors';
import FormattedMessageWithValidation from '../FormattedMessageWithValidation/FormattedMessageWithValidation';
import CustomFormattedDate from '../FormattedDate/CustomFormattedDate';
import { SUBSCRIPTION_BANK_TRANSFER_DETAILS } from '../../constants/routes';

const Link = styled(Anchor)`
  ${typography.size(FONT_SIZE.S)}
`;

export default function DeveloperCampaignsBanner() {
  const isInSubscriptionPage = useMatch('/subscription');
  const campaignsBanner = useSelector(campaignsBannerSelector);

  if (!isInSubscriptionPage || !campaignsBanner) return null;
  const { status, billingDate } = campaignsBanner;
  const isAboutToExpire = status === 'about-to-expire';
  return (
    <Banner
      sticky
      variant={
        isAboutToExpire ? BANNER_VARIANT.INTERVENTION : BANNER_VARIANT.ALERT
      }
    >
      <FormattedMessageWithValidation
        id={
          isAboutToExpire
            ? 'about_expire_project_developer_banner_text'
            : 'expired_project_developer_banner_text'
        }
        values={{
          breakingLine: <br />,
          nextBillingDate: (
            <b>
              <CustomFormattedDate value={billingDate} />
            </b>
          ),
          bankTransferDetails: (
            <Link href={SUBSCRIPTION_BANK_TRANSFER_DETAILS}>
              <FormattedMessageWithValidation id="subscription_payment_information_pay_bank_transfer_view_details" />
            </Link>
          ),
        }}
      />
    </Banner>
  );
}
