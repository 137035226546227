import { Link } from 'react-router-dom';
import { useSelector } from 'reffects-store';
import { FormattedList } from 'react-intl';
import { PrimaryButton } from 'design-system/components/Button/presets';
import Illustration from 'design-system/components/Illustration/Illustration';
import EmptyState from 'design-system/components/EmptyState';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import FormattedMessageWithTranslatedCountry from '../../../partials/FormattedMessageWithTranslatedCountry/FormattedMessageWithCountry';
import { siteWherePublishingNamesSelector } from '../../../selectors/config';
import { newUnitForm } from '../../../utils/proppitWebRouter';

function UnitsEmptyState() {
  const webs = useSelector(siteWherePublishingNamesSelector);

  return (
    <EmptyState
      fullScreen
      illustration={<Illustration name="buildings" />}
      title={
        <FormattedMessageWithTranslatedCountry id="overview_units_empty_title" />
      }
      description={
        <FormattedMessageWithValidation
          id="overview_units_empty_body"
          values={{
            webs: <FormattedList type="conjunction" value={webs} />,
          }}
        />
      }
      action={
        <PrimaryButton forwardedAs={Link} to={newUnitForm()}>
          <FormattedMessageWithValidation id="units_empty_cta" />
        </PrimaryButton>
      }
    />
  );
}

export default UnitsEmptyState;
