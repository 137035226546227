import { FlatButton } from 'design-system/components/Button/presets';
import {
  BUTTON_ICON_POSITION,
  BUTTON_SIZE,
} from 'design-system/components/Button';

export function Action({ ...rest }) {
  return (
    <FlatButton
      $size={BUTTON_SIZE.SMALL}
      $iconPosition={BUTTON_ICON_POSITION.START}
      {...rest}
    />
  );
}
