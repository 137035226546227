import { useMount } from 'react-use';
import { useScript } from '../../../hooks/useScript';

export function GoogleTagWasi({ containerId }) {
  if (!containerId) {
    return <></>;
  }

  useScript(`//www.googletagmanager.com/gtag/js?id=${containerId}`, {
    removeOnUnmount: false,
  });
  useMount(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag(...rest) {
      window.dataLayer.push(rest);
    }

    gtag('js', new Date());
    gtag('config', containerId);
  });

  return (
    <noscript>
      <iframe
        title="google-tag-wasi"
        src={`https://www.googletagmanager.com/ns.html?id=${containerId}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>
  );
}
