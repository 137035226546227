import { uniqueId } from 'lodash';
import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { InMemoryTable } from '../../partials/DataTable/adapters/inMemoryTableAdapter/InMemoryTable';
import { table } from '../../partials/DataTable/effects/table';
import { environment } from '../../coeffects/environment';
import { sortAlphabeticallyBy } from '../../partials/DataTable/adapters/inMemoryTableAdapter/sortingStrategies';

export default function createManagedPublishersTableAdapter() {
  const inMemoryTable = InMemoryTable.create({
    sortingStrategies: [
      {
        field: 'name',
        compareFn: sortAlphabeticallyBy('name'),
      },
    ],
  });

  const eventSuffix = uniqueId('managedPublishers');

  const UPDATE_ITEMS_TABLE_DATA = `UPDATE_ITEMS_TABLE_DATA_${eventSuffix}`;
  const UPDATE_ITEMS_TABLE_DATA_SUCCESS = `UPDATE_ITEMS_TABLE_DATA_SUCCESS_${eventSuffix}`;
  const UPDATE_AVAILABLE_SLOTS = `UPDATE_AVAILABLE_SLOTS_${eventSuffix}`;

  registerEventHandler(
    UPDATE_ITEMS_TABLE_DATA,
    ({ environment: { apiUrl } }, context) =>
      http.get({
        url: `${apiUrl}/users/me/publishers`,
        successEvent: [UPDATE_ITEMS_TABLE_DATA_SUCCESS, context],
      }),
    [environment()]
  );

  registerEventHandler(
    UPDATE_ITEMS_TABLE_DATA_SUCCESS,
    (
      { select },
      [
        {
          data: {
            publishers,
            maxBoostableProperties,
            maxSuperboostableProperties,
          },
        },
        context,
      ]
    ) => {
      const { visibleRowsIdList, totalRows } = inMemoryTable
        .setCollection(publishers)
        .setSorting(select.sorting(context))
        .get(select.pagination(context));

      return {
        ...effects.dispatch({
          id: UPDATE_AVAILABLE_SLOTS,
          payload: { maxBoostableProperties, maxSuperboostableProperties },
        }),
        ...table.update(context.collectionName, {
          collection: publishers,
          totalItems: totalRows,
          visibleItemsIdList: visibleRowsIdList,
        }),
      };
    },
    [table.select()]
  );

  registerEventHandler(
    UPDATE_AVAILABLE_SLOTS,
    (_, { maxBoostableProperties, maxSuperboostableProperties }) =>
      state.set({
        availableMaxBoostableSlots: maxBoostableProperties,
        availableMaxSuperboostableSlots: maxSuperboostableProperties,
      })
  );

  return {
    registerEvents() {
      return {
        collection: UPDATE_ITEMS_TABLE_DATA,
        events: {
          UPDATE_ITEMS_TABLE_DATA,
          UPDATE_ITEMS_TABLE_DATA_SUCCESS,
        },
      };
    },
  };
}
