import styled, { css } from 'styled-components';
import { withStylesAndInnerRef } from 'design-system/utils';
import { border, BORDER_SIZE } from 'design-system/styles/border';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import {
  color,
  COLOR_PALETTE,
  COLOR_SEMANTIC,
} from 'design-system/styles/color';
import { Body1 } from 'design-system/components/Typography/presets/Body1';

const Content = styled.div`
  transition: background 300ms, color 300ms;

  ${radius.regular(RADIUS_SIZE.LARGE)}
  ${spacing.insetSquish(SPACING_SIZE.M)}
  ${color.background(COLOR_SEMANTIC.PAPER)}
  ${border.outer({
    size: BORDER_SIZE.BASE,
    color: COLOR_PALETTE.NEUTRAL_A10,
  })}

  :hover {
    ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
  }
`;

const Input = styled.input.attrs({
  type: 'radio',
})`
  display: none;
`;

const modHighlightWhenChecked = () => css`
  ${Input}:checked ~ ${Content} {
    ${Body1} {
      ${Body1.mods.color(COLOR_PALETTE.NEUTRAL_A00)}
    }

    ${color.background(COLOR_PALETTE.SECONDARY_BASE)}
    ${border.inner({
      size: BORDER_SIZE.NONE,
    })}
  }
`;

const Wrapper = styled.label`
  position: relative;
  display: inline-block;

  cursor: pointer;
  user-select: none;

  ${spacing.inline(SPACING_SIZE.S)}
  ${spacing.stack(SPACING_SIZE.S)}

  ${() => modHighlightWhenChecked()}
`;

function Option({ className, label, innerRef, ...rest }) {
  return (
    <Wrapper className={className}>
      <Input ref={innerRef} {...rest} />
      <Content>
        <Body1>{label}</Body1>
      </Content>
    </Wrapper>
  );
}

export default withStylesAndInnerRef(Option);
