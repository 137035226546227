import { dispatch, dispatchMany } from 'reffects';
import { useMount, useUnmount } from 'react-use';
import { useSelector } from 'reffects-store';
import { useParams } from 'react-router-dom';
import {
  EDIT_UNIT_DATA_REQUESTED,
  PROJECTS_REQUESTED,
  RESET_UNIT_FORM_VALUES,
} from './events';
import { adDataSelector } from '../selectors';
import UnitForm from './UnitForm';

function EditUnitForm() {
  const { adId } = useParams();
  useMount(() => {
    dispatchMany([
      { id: EDIT_UNIT_DATA_REQUESTED, payload: { adId } },
      PROJECTS_REQUESTED,
    ]);
  });
  useUnmount(() => dispatch(RESET_UNIT_FORM_VALUES));

  const values = useSelector(adDataSelector);
  const isAdFormInitialized = useSelector(
    (state) => state['adForm:isInitialized']
  );

  return isAdFormInitialized && <UnitForm values={values} />;
}

export default EditUnitForm;
