import styled from 'styled-components';
import { dispatch } from 'reffects';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'reffects-store';
import InputBase from 'design-system/components/InputBase/InputBase';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { PrimaryButton } from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import TimelineFormProvider from './TimelineFormProvider';
import useInternationalization from '../../../../../hooks/useInternationalization';
import { LEAD_NOTE_CREATED } from '../events';
import {
  NOTE_MAX_CHARACTERS_LENGTH,
  NOTE_MESSAGE_FIELD_NAME,
} from '../constants';
import InputField from './InputField';
import useTracking from '../../../../../hooks/useTracking';
import { leadSelector } from '../../../selectors';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${spacing.insetSquish(SPACING_SIZE.M)}
  padding-bottom: ${spacing.value(SPACING_SIZE.M)};
  border-top: 1px solid ${COLOR_PALETTE.NEUTRAL_A10};

  ${InputBase.Box} {
    height: 100px;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
`;

const Notice = styled.div`
  display: flex;
  align-items: center;

  > * {
    ${spacing.inline(SPACING_SIZE.S)}
  }

  ${Body2} {
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
  }
`;

function CreateNote() {
  const lead = useSelector(leadSelector);
  const intl = useInternationalization();

  const handleSubmit = ({ message }) => {
    dispatch({
      id: LEAD_NOTE_CREATED,
      payload: { message, leadId: lead.id },
    });
  };

  return (
    <Wrapper>
      <TimelineFormProvider
        defaultValues={{
          [NOTE_MESSAGE_FIELD_NAME]: '',
        }}
        onSubmit={handleSubmit}
        messageMaxLength={NOTE_MAX_CHARACTERS_LENGTH}
      >
        <InputField
          name={NOTE_MESSAGE_FIELD_NAME}
          maxRows={3}
          placeholder={intl.formatMessageWithValidation({
            id: 'enquiry_detail_note_placeholder',
          })}
        />
        <Actions>
          <Notice>
            <Icon
              glyph="lock"
              $size={ICON_SIZE.SMALL}
              $color={COLOR_PALETTE.NEUTRAL_A40}
            />
            <Body2>
              <FormattedMessageWithValidation id="enquiry_detail_note_visibility_warning" />
            </Body2>
          </Notice>
          <SubmitButton />
        </Actions>
      </TimelineFormProvider>
    </Wrapper>
  );
}

function SubmitButton() {
  const { watch } = useFormContext();
  const [message] = watch([NOTE_MESSAGE_FIELD_NAME]);
  const isSubmitDisabled =
    message.length === 0 || message.length > NOTE_MAX_CHARACTERS_LENGTH;

  const trackingRef = useTracking({
    trackClick: {
      eventName: 'note-added',
    },
    sectionName: 'enquiry-activity-timeline-bottom-input',
  });

  return (
    <PrimaryButton
      type="submit"
      $size={BUTTON_SIZE.SMALL}
      disabled={isSubmitDisabled}
      ref={trackingRef}
    >
      <FormattedMessageWithValidation id="enquiry_detail_note_add" />
    </PrimaryButton>
  );
}

export default CreateNote;
