import { cloneElement, useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useWindowSize } from 'react-use';
import { SPACING_SIZE } from 'design-system/styles/spacing';
import { noop, withoutEventPropagation } from '../utils';
import Popover from './Popover';
import Portal from './Portal';
import getAbsolutePosition from '../utils/getAbsolutePosition';

export const DROPDOWN_POSITION = {
  BOTTOM_LEFT: Symbol('DROPDOWN_POSITION_BOTTOM_LEFT'),
  BOTTOM_RIGHT: Symbol('DROPDOWN_POSITION_BOTTOM_RIGHT'),
  TOP_LEFT: Symbol('DROPDOWN_POSITION_TOP_LEFT'),
  TOP_RIGHT: Symbol('DROPDOWN_POSITION_TOP_RIGHT'),
};

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const modPositionBottomLeft = () => css`
  top: ${({ wrapperPosition }) => wrapperPosition.bottom + SPACING_SIZE.S}px;
  left: ${({ wrapperPosition }) => wrapperPosition.left}px;
`;

const modPositionBottomRight = () => css`
  top: ${({ wrapperPosition }) => wrapperPosition.bottom + SPACING_SIZE.S}px;
  right: calc(100% - ${({ wrapperPosition }) => wrapperPosition.right}px);
`;

const modPositionTopLeft = () => css`
  bottom: calc(
    100% - ${({ wrapperPosition }) => wrapperPosition.top - SPACING_SIZE.S}px
  );
  left: ${({ wrapperPosition }) => wrapperPosition.left}px;
`;

const modPositionTopRight = () => css`
  bottom: calc(100% - ${({ wrapperPosition }) =>
    wrapperPosition.top - SPACING_SIZE.S}px);
  right: calc(100% - ${({ wrapperPosition }) => wrapperPosition.right}px); 
}
`;

const modPosition = (positionName) =>
  ({
    [DROPDOWN_POSITION.BOTTOM_LEFT]: modPositionBottomLeft,
    [DROPDOWN_POSITION.BOTTOM_RIGHT]: modPositionBottomRight,
    [DROPDOWN_POSITION.TOP_LEFT]: modPositionTopLeft,
    [DROPDOWN_POSITION.TOP_RIGHT]: modPositionTopRight,
  }[positionName]);

const Content = styled(Popover)`
  position: absolute;
  ${({ position }) => modPosition(position)}
`;

const InvisibleBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
`;

function Dropdown({
  button,
  tooltip,
  active,
  open,
  children,
  position = DROPDOWN_POSITION.BOTTOM_RIGHT,
  onOpenChange = noop,
  ...rest
}) {
  const handleCloseChange = useCallback(
    (e) => {
      e.stopPropagation();
      onOpenChange(!open);
    },
    [open]
  );
  const wrapperElement = useRef();
  const wrapperPosition = useWrapperElementPosition(wrapperElement, open);

  return (
    <Wrapper
      ref={wrapperElement}
      onClick={withoutEventPropagation(noop)}
      {...rest}
    >
      {withTooltip(
        tooltip,
        cloneElement(button, {
          active: active || open,
          onClick: handleCloseChange,
        })
      )}
      {open && (
        <DropdownContainer
          handleCloseChange={handleCloseChange}
          wrapperPosition={wrapperPosition}
          position={position}
          children={children}
        />
      )}
    </Wrapper>
  );
}

export default styled(Dropdown)``;

function DropdownContainer({
  children,
  handleCloseChange,
  position,
  wrapperPosition,
}) {
  return (
    <Portal>
      <InvisibleBackdrop
        onClick={withoutEventPropagation(handleCloseChange)}
        onAuxClick={withoutEventPropagation(handleCloseChange)}
      />
      <Content position={position} wrapperPosition={wrapperPosition}>
        {children}
      </Content>
    </Portal>
  );
}

function withTooltip(tooltip, button) {
  if (tooltip) {
    return cloneElement(tooltip, {
      children: button,
    });
  }

  return button;
}

function useWrapperElementPosition(wrapperElement, open) {
  const { width, height } = useWindowSize();
  const [wrapperPosition, setWrapperPosition] = useState({});

  useEffect(() => {
    const absolutePosition = getAbsolutePosition(wrapperElement.current);
    setWrapperPosition(absolutePosition);
  }, [width, height, open]);

  return wrapperPosition;
}
