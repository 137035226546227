import { state } from 'reffects-store';
import { navigateTo } from '../../effects/routing';
import { composePathWithSearchParams } from '../../utils/url';
import { SORTING_DIRECTIONS } from './constants';
import updateTable from './updateTable';

export const tablePageChanged =
  (tableName) =>
  (
    {
      state: {
        collectionById,
        filters,
        pagination: currentPagination,
        sorting,
      },
    },
    page
  ) => {
    const pagination = {
      ...currentPagination,
      currentPage: page,
    };
    const { rows, totalRows } = updateTable(
      collectionById,
      filters,
      sorting,
      pagination
    );

    return {
      ...state.set({
        [`${tableName}.rows`]: rows,
        [`${tableName}.pagination.totalRows`]: totalRows,
        [`${tableName}.pagination.currentPage`]: pagination.currentPage,
      }),
    };
  };

export const tablePageChangedWithURLUpdate =
  (tableName) =>
  (
    {
      state: {
        collectionById,
        filters,
        pagination: currentPagination,
        sorting,
      },
      location: { href },
    },
    page
  ) => {
    const pagination = {
      ...currentPagination,
      currentPage: page,
    };
    const { rows, totalRows } = updateTable(
      collectionById,
      filters,
      sorting,
      pagination
    );

    return {
      ...state.set({
        [`${tableName}.rows`]: rows,
        [`${tableName}.pagination.totalRows`]: totalRows,
        [`${tableName}.pagination.currentPage`]: pagination.currentPage,
      }),
      ...navigateTo(generateTablePath(href, pagination, sorting, filters)),
    };
  };

function generateTablePath(href, pagination, sorting) {
  const { direction, field } = sorting || {};
  const { currentPage, rowsPerPage } = pagination;

  return composePathWithSearchParams(href, {
    page: currentPage,
    rowsPerPage,
    orderBy: field,
    order: direction,
  });
}

export const tableRowsPerPageChanged =
  (tableName) =>
  (
    {
      state: {
        collectionById,
        filters,
        pagination: currentPagination,
        sorting,
      },
    },
    rowsPerPage
  ) => {
    const pagination = {
      ...currentPagination,
      rowsPerPage: Number(rowsPerPage),
      currentPage: 1,
    };
    const { rows, totalRows } = updateTable(
      collectionById,
      filters,
      sorting,
      pagination
    );

    return {
      ...state.set({
        [`${tableName}.rows`]: rows,
        [`${tableName}.pagination.totalRows`]: totalRows,
        [`${tableName}.pagination.rowsPerPage`]: pagination.rowsPerPage,
        [`${tableName}.pagination.currentPage`]: pagination.currentPage,
      }),
    };
  };

export const tableRowsPerPageChangedWithURLUpdate =
  (tableName) =>
  (
    {
      state: {
        collectionById,
        filters,
        pagination: currentPagination,
        sorting,
      },
      location: { href },
    },
    rowsPerPage
  ) => {
    const pagination = {
      ...currentPagination,
      rowsPerPage: Number(rowsPerPage),
      currentPage: 1,
    };
    const { rows, totalRows } = updateTable(
      collectionById,
      filters,
      sorting,
      pagination
    );

    return {
      ...state.set({
        [`${tableName}.rows`]: rows,
        [`${tableName}.pagination.totalRows`]: totalRows,
        [`${tableName}.pagination.rowsPerPage`]: pagination.rowsPerPage,
        [`${tableName}.pagination.currentPage`]: pagination.currentPage,
      }),
      ...navigateTo(generateTablePath(href, pagination, sorting)),
    };
  };

export const tableSorted =
  (tableName) =>
  (
    {
      state: { collectionById, filters, pagination, sorting: currentSorting },
      location: { href },
    },
    { field }
  ) => {
    const direction = chooseSortingDirection(field, currentSorting);
    const sorting = { direction, field };
    const { rows, totalRows } = updateTable(
      collectionById,
      filters,
      sorting,
      pagination
    );

    return {
      ...state.set({
        [`${tableName}.sorting.field`]: field,
        [`${tableName}.sorting.direction`]: direction,
        [`${tableName}.rows`]: rows,
        [`${tableName}.pagination.totalRows`]: totalRows,
      }),
      ...navigateTo(generateTablePath(href, pagination, sorting)),
    };
  };

function chooseSortingDirection(field, currentSorting) {
  if (field !== currentSorting.field) {
    return SORTING_DIRECTIONS.ASCENDING;
  }

  return currentSorting.direction === SORTING_DIRECTIONS.ASCENDING
    ? SORTING_DIRECTIONS.DESCENDING
    : SORTING_DIRECTIONS.ASCENDING;
}
