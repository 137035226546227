import styled from 'styled-components';
import Tile from 'design-system/components/Tile';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { FONT_SIZE, typography } from 'design-system/styles/typography';

const Wrapper = styled(Tile)`
  display: flex;
  flex-direction: column;
  ${spacing.stack(SPACING_SIZE.L)}

  ${Subtitle3} {
    display: block;
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
    ${typography.size(FONT_SIZE.S)}
  }
`;

const Amount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${spacing.stack(SPACING_SIZE.XS)}

  > span {
    ${spacing.inline(SPACING_SIZE.S)}
    font-size: 22px;
  }

  ${Icon} {
    ${Icon.mods.size(ICON_SIZE.BASE)}
  }
`;

function Kpi({ icon, message, amount, className }) {
  return (
    <Wrapper className={className}>
      <Amount>
        {amount} {icon}
      </Amount>
      <Subtitle3>{message}</Subtitle3>
    </Wrapper>
  );
}

export default styled(Kpi)``;
