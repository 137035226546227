import styled, { css } from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';

export const CELL_VARIANT = {
  KPI: Symbol('CELL_VARIANT_KPI'),
};

const Wrapper = styled.td`
  ${({ maxWidth }) => maxWidth != null && `max-width: ${maxWidth}px;`}
  ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
`;

const CellContent = styled.div`
  display: flex;
  align-items: center;
`;

const modKpiVariant = () => css`
  ${color.background(COLOR_PALETTE.PRIMARY_A10)}
`;

const modVariant = (variant) =>
  ({
    [CELL_VARIANT.KPI]: modKpiVariant,
  }[variant]);

function Cell({ children, ...rest }) {
  return (
    <Wrapper {...rest}>
      <CellContent>{children}</CellContent>
    </Wrapper>
  );
}

Cell.mods = {
  variant: modVariant,
};

export default styled(Cell)``;
