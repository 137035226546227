import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import { useEffect } from 'react';
import { selectedPropertyDetailSelector } from './PropertyDetail/selectors';
import { LEAD_ENQUIRED_PROPERTIES_REQUEST } from './events';
import PropertiesList from './PropertiesList';
import Detail from './PropertyDetail';
import { Skeleton } from './PropertyDetail/Skeleton';
import { isLoadingSelector } from '../../selectors';

function EnquiredProperties({ leadId }) {
  useEffect(
    () =>
      dispatch({
        id: LEAD_ENQUIRED_PROPERTIES_REQUEST,
        payload: { leadId },
      }),
    [leadId]
  );

  const selectedPropertyDetail = useSelector(selectedPropertyDetailSelector);
  const isLoading = useSelector(isLoadingSelector);
  if (isLoading) {
    return <Skeleton />;
  }
  return selectedPropertyDetail ? <Detail /> : <PropertiesList />;
}

export default EnquiredProperties;
