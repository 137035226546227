import { COLOR_PALETTE } from 'design-system/styles/color';
import BaseBubble, { BASE_BUBBLE_ALIGNMENT } from './BaseBubble';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

function InboundBubble({ isEnquiryActivity, title, message, ...rest }) {
  return (
    <BaseBubble
      title={
        isEnquiryActivity && (
          <>
            <FormattedMessageWithValidation id="enquiry_detail_activity_new_lead" />
            {title && ' - '}
            {title}
          </>
        )
      }
      $alignment={BASE_BUBBLE_ALIGNMENT.LEFT}
      $colorScheme={{
        title: COLOR_PALETTE.PRIMARY_A120,
        background: COLOR_PALETTE.PRIMARY_A10,
      }}
      {...rest}
    />
  );
}

export default InboundBubble;
