import * as React from "react";
const SvgProperatiLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={76}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m9.397 5.163 5.101 2.466a2.17 2.17 0 0 1 1.106 1.87v7.567a1.36 1.36 0 0 1-1.36 1.36H3.36A1.36 1.36 0 0 1 2 17.067V9.5c0-.78.437-1.486 1.105-1.87l5.102-2.466c.37-.217.82-.217 1.19 0m-.34 3.911H6.08v6.802h1.87v-2.04h1.106c1.36 0 2.72-.596 2.72-2.381 0-1.769-1.333-2.369-2.72-2.38m-.255 1.53c.53.007 1.02.244 1.02.936 0 .69-.49.928-1.02.935h-.85v-1.87zM34.734 9.924a3.4 3.4 0 0 1 3.4 3.401 3.4 3.4 0 1 1-6.801 0c0-1.879 1.523-3.4 3.4-3.4m-12.839.255c1.293 0 2.55.551 2.55 2.211 0 1.643-1.258 2.194-2.55 2.21h-1.02v1.871h-1.87v-6.292zm20.235 0c1.291 0 2.551.551 2.551 2.211 0 1.643-1.26 2.194-2.55 2.21h-1.106v1.871h-1.87v-6.292zm8.247 0v1.446h-3.145v1.105h2.805v1.19h-2.805v1.106h3.145v1.445h-5.016v-6.292zm20.15 0v1.616h-1.87v4.676h-1.955v-4.676h-1.87v-1.616zm2.891 0v6.292h-1.87v-6.292zm-11.052-.17 2.55 6.462h-1.87l-.425-1.105h-2.38l-.426 1.105h-1.87l2.55-6.462zm-34.264.17c1.287 0 2.55.551 2.55 2.211 0 .984-.457 1.58-1.105 1.87l1.105 2.211h-1.87l-.935-1.87h-1.02v1.87h-1.701v-6.292zm26.612 0c1.287 0 2.55.551 2.55 2.211 0 .984-.457 1.58-1.105 1.87l1.105 2.211h-1.87l-.935-1.87h-1.02v1.87h-1.701v-6.292zm-19.938 1.446c-.981 0-1.741.762-1.741 1.741 0 .898.762 1.66 1.741 1.66.898 0 1.66-.762 1.66-1.66-.002-.98-.762-1.741-1.66-1.741m26.7.765-.726 1.7h1.36zm-40.516-.765v1.7h.765c.478 0 .935-.212.935-.85 0-.63-.444-.843-.935-.85zm20.065 0v1.7h.765c.478 0 .935-.212.935-.85 0-.63-.443-.843-.935-.85zm-14.199 0v1.615h1.02c.473 0 .936-.202.936-.765 0-.64-.449-.843-.935-.85zm26.612 0v1.615h1.02c.473 0 .936-.202.936-.765 0-.64-.45-.843-.935-.85z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgProperatiLogo;
