import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { SPACING_SIZE } from 'design-system/styles/spacing';
import AreaChartYTick from './AreaChartYTick';
import CustomTooltip from './Tooltip';
import AreaChartXTick from './AreaChartXTick';

const dotProps = {
  stroke: COLOR_PALETTE.NEUTRAL_A00,
  strokeWidth: 2,
  r: 5,
  fill: COLOR_PALETTE.PRIMARY_BASE,
};

const areaGradientId = 'areaGradient';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

function AreaChart({
  data,
  dataKey,
  xTickCount,
  yTickCount,
  renderTooltipContent,
  renderXTickText,
  renderYTickText,
  ...rest
}) {
  const chartDataProps = {
    type: 'linear',
    dataKey,
  };

  return (
    <Wrapper {...rest}>
      <ResponsiveContainer debounce={300}>
        <ComposedChart data={data}>
          <Tooltip
            content={<CustomTooltip renderContent={renderTooltipContent} />}
          />
          <defs>
            <linearGradient id={areaGradientId} x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="0%"
                stopColor={COLOR_PALETTE.PRIMARY_BASE}
                stopOpacity={0.2}
              />
              <stop
                offset="100%"
                stopColor={COLOR_PALETTE.PRIMARY_BASE}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <CartesianGrid stroke={COLOR_PALETTE.NEUTRAL_A10} vertical={false} />
          <XAxis
            dataKey="date"
            tick={<AreaChartXTick renderText={renderXTickText} />}
            tickLine={false}
            axisLine={false}
            tickCount={xTickCount}
            height={SPACING_SIZE.M}
          />
          <YAxis
            tick={<AreaChartYTick renderText={renderYTickText} />}
            tickLine={false}
            axisLine={false}
            interval={0}
            tickCount={yTickCount}
            width={SPACING_SIZE.XL}
          />
          <Area
            fillOpacity={1}
            fill={`url(#${areaGradientId})`}
            {...chartDataProps}
          />
          <Line
            stroke={COLOR_PALETTE.PRIMARY_BASE}
            dot={dotProps}
            activeDot={dotProps}
            strokeWidth={2}
            {...chartDataProps}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Wrapper>
  );
}

export default styled(AreaChart)``;
