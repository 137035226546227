import AreaInformationSection from '../../partials/AreaInformationSection';
import DetailsSection from '../../partials/DetailsSection';
import { FieldGroup } from '../../partials/FieldGroup';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import OperationTypeSection from '../../partials/OperationTypeSection';
import PropertyTypeSection from '../../partials/PropertyTypeSection';
import OperationSection from '../../partials/OperationSection';

export default function UnitDetailsGroup({ order }) {
  return (
    <FieldGroup
      id="unitDetails"
      order={order}
      title={
        <FormattedMessageWithValidation id="ad_form_unit_details_title_developers" />
      }
    >
      <OperationTypeSection />
      <OperationSection />
      <PropertyTypeSection
        choiceLabel={
          <FormattedMessageWithValidation id="newunit_type_emptystate" />
        }
      />
      <AreaInformationSection />
      <DetailsSection />
    </FieldGroup>
  );
}
