import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { BORDER_SIZE } from 'design-system/styles/border';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import BankTransferQrCode from '../BankTransferQrCode';
import { bankTransferDetailsSelector } from '../../../../selectors/config';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

import { COPY_TEXT_TO_CLIPBOARD } from '../../../../events';
import { bankTransferConceptSelector } from './selectors';

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  > *:nth-child(2) {
    margin-left: ${SPACING_SIZE.XL}px;
  }
`;

const PaymentDetails = styled.div`
  flex-grow: 2;
`;

const DetailLine = styled.div`
  ${spacing.insetSandwich(SPACING_SIZE.S)}
  border-bottom: ${BORDER_SIZE.BASE}px solid ${COLOR_PALETTE.NEUTRAL_A10};
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;

  &:last-child {
    border: none;
  }

  ${Body1} {
    ${typography.weight(FONT_WEIGHT.LIGHT)}
  }
`;

const CompanyName = styled.div`
  display: flex;
  flex-direction: row;
  > * {
    ${spacing.inline(SPACING_SIZE.S)}
  }
  ${Icon} {
    cursor: pointer;
  }
`;

export default function BankTransferInformation() {
  const bankTransferDetails = useSelector(bankTransferDetailsSelector);
  const billingEntityName = useSelector(bankTransferConceptSelector);
  return (
    <Grid>
      <BankTransferQrCode />
      <PaymentDetails>
        {billingEntityName && (
          <DetailLine>
            <Subtitle3>
              <FormattedMessageWithValidation id="checkout_bank_transfer_details_payment_reference" />
            </Subtitle3>
            <CompanyName>
              <Body1>{billingEntityName}</Body1>
              <Icon
                glyph="copy"
                $size={ICON_SIZE.XSMALL}
                onClick={() =>
                  dispatch({
                    id: COPY_TEXT_TO_CLIPBOARD,
                    payload: billingEntityName,
                  })
                }
              />
            </CompanyName>
          </DetailLine>
        )}
        {bankTransferDetails.map(({ name, value }) => (
          <DetailLine key={name}>
            <Subtitle3>{name}</Subtitle3>
            <Body1>{value}</Body1>
          </DetailLine>
        ))}
      </PaymentDetails>
    </Grid>
  );
}
