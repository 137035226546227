import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import {
  isMaxBoostablePropertiesDisabledSelector,
  maxBoostablePropertiesSelector,
  selectedMaxBoostablePropertiesSelector,
} from './selectors';
import MaxBoostablePropertiesSelect from '../partials/MaxBoostablePropertiesSelect';
import { CREATE_PUBLISHER_FORM_MAX_BOOSTABLE_PROPERTIES_SELECTED } from './events';

export default function MaxBoostablePropertiesSelectForPublisherCreation() {
  const options = useSelector(maxBoostablePropertiesSelector);
  const selectedOption = useSelector(selectedMaxBoostablePropertiesSelector);
  const isDisabled = useSelector(isMaxBoostablePropertiesDisabledSelector);

  return (
    <MaxBoostablePropertiesSelect
      options={options}
      selectedOption={selectedOption}
      isDisabled={isDisabled}
      required={!isDisabled}
      onChange={onChange}
    />
  );
}

function onChange({ value }) {
  dispatch({
    id: CREATE_PUBLISHER_FORM_MAX_BOOSTABLE_PROPERTIES_SELECTED,
    payload: { maxBoostableProperties: value },
  });
}
