import styled, { css } from 'styled-components';
import { radius } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { FONT_FAMILY, typography } from 'design-system/styles/typography';

const modIsCurrent = () => css`
  &::after {
    content: '';
    position: absolute;
    height: 2px;
    bottom: 0;
    left: ${spacing.value(SPACING_SIZE.M)};
    right: ${spacing.value(SPACING_SIZE.M)};

    ${radius.pill()}
    ${color.background(COLOR_PALETTE.PRIMARY_BASE)}
  }
`;

const TabLink = styled.a`
  font-size: 14px;
  text-decoration: none;
  position: relative;
  display: inline-flex;
  cursor: pointer;

  flex-shrink: 0;
  white-space: nowrap;

  ${typography.font(FONT_FAMILY.PRIMARY)}
  ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
  ${spacing.inset(SPACING_SIZE.S)};

  :hover {
    ${color.text(COLOR_PALETTE.PRIMARY_A140)}
  }

  ${({ $isCurrent }) => $isCurrent && modIsCurrent()}
`;

export default TabLink;
