import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import { useMount } from 'react-use';
import { SecondaryButton } from 'design-system/components/Button/presets';
import Dialog from 'design-system/components/Dialog/Dialog';
import { useDialog } from '../../../../hooks/useDialog';
import {
  ASSIGN_PROJECT_TO_DEVELOPER_DIALOG,
  ASSIGN_PROJECT_TO_DEVELOPER_SUBMITTED,
  PROJECT_NAME_SELECTED,
} from './events';
import ProjectNameAutocomplete from '../../partials/ProjectNameAutocomplete/ProjectNameAutocomplete';
import {
  hasProjectSelectedSelector,
  selectedProjectSelector,
} from './selectors';
import {
  projectNameSuggestionLoadingSelector,
  projectSuggestionsSelector,
} from '../../partials/ProjectNameAutocomplete/selectors';
import { ADMIN_PROJECT_NAME_AUTOCOMPLETE_CLEARED } from '../../partials/ProjectNameAutocomplete/events';

export default function AssignProjectModal() {
  useMount(() => dispatch(ADMIN_PROJECT_NAME_AUTOCOMPLETE_CLEARED));
  const hasSelectedProject = useSelector(hasProjectSelectedSelector);
  const selectedProject = useSelector(selectedProjectSelector);
  const { open, closeDialog, parameters } = useDialog(
    ASSIGN_PROJECT_TO_DEVELOPER_DIALOG
  );
  const loading = useSelector(projectNameSuggestionLoadingSelector);
  const projectSuggestions = useSelector(projectSuggestionsSelector);

  return (
    <Dialog open={open}>
      <Dialog.Header
        onClose={closeDialog}
      >{`Assign project to "${parameters?.developer?.name}"`}</Dialog.Header>
      <Dialog.Content>
        <ProjectNameAutocomplete
          isLoading={loading}
          projectSuggestions={projectSuggestions}
          value={selectedProject?.name ?? ''}
          onSelectProject={selectProject}
          publisherCountry={parameters?.developer?.countryCode}
          required
        />
        {hasSelectedProject && (
          <div className="AssignProjectModal__selectedProjectInfo">
            <b>Detailed info:</b>
            <ul>
              {Object.entries(selectedProject).map(([key, value]) => (
                <li key={key}>
                  <b>{key}: </b>
                  <span>{value ?? 'Not specified'}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </Dialog.Content>
      <Dialog.Footer>
        <SecondaryButton
          disabled={!hasSelectedProject}
          onClick={submit(parameters?.developer)}
        >
          Assign project
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}

function selectProject(project) {
  dispatch({
    id: PROJECT_NAME_SELECTED,
    payload: {
      project,
    },
  });
}

function submit(developer) {
  return (e) => {
    e.preventDefault();
    dispatch({
      id: ASSIGN_PROJECT_TO_DEVELOPER_SUBMITTED,
      payload: { developer },
    });
  };
}
