import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { useSelector } from 'reffects-store';
import { useCallback, useState } from 'react';
import { dispatch } from 'reffects';
import StatusFilter from './StatusFilter';
import ClearFiltersButton from './ClearFiltersButton';
import UnitTypeFilter from './TypeFilter';
import SearchFilter from './SearchFilter/SearchFilter';
import { tableHasFiltersAppliedSelector } from '../../../Tables/selectors';
import { searchFilterQuerySelector } from './SearchFilter/selectors';
import { UNITS_FILTERS_CLEARED } from './events';

const Wrapper = styled.div`
  display: flex;
  ${spacing.stack(SPACING_SIZE.L)}

  & > * {
    ${spacing.inline(SPACING_SIZE.S)}
  }
`;

function Filters() {
  const hasFiltersSelected = useSelector((state) =>
    tableHasFiltersAppliedSelector(state, 'unitsTable')
  );

  const initialQuery = useSelector(searchFilterQuerySelector);
  const [query, setQuery] = useState(initialQuery);

  const handleClear = useCallback(() => {
    setQuery('');
    dispatch(UNITS_FILTERS_CLEARED);
  }, []);

  return (
    <Wrapper>
      <SearchFilter query={query} onQueryChange={setQuery} />
      <StatusFilter />
      <UnitTypeFilter />
      {hasFiltersSelected && <ClearFiltersButton onClick={handleClear} />}
    </Wrapper>
  );
}

export default Filters;
