import styled, { css } from 'styled-components';
import { useSelector } from 'reffects-store';
import Anchor from 'design-system/components/Anchor';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import {
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import appLogo from './app_icon.svg';
import star from './star.svg';
import emptyStar from './empty_star.svg';
import halfStar from './half_star.svg';
import FormattedMessageWithValidation from '../FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  appDownloadLinkSelector,
  isAndroidSelector,
  isIOSSelector,
} from '../../App/selectors';

const Logo = styled.img.attrs(() => ({
  src: appLogo,
}))`
  flex-shrink: 0;
  margin-right: ${spacing.value(SPACING_SIZE.S)};
  overflow: hidden;
  ${(props) =>
    props.ios &&
    css`
      ${radius.regular(RADIUS_SIZE.LARGE)};
    `}

  ${(props) =>
    props.android &&
    css`
      ${radius.pill()}
    `}

  img {
    display: block;
  }
`;

const Description = styled.div`
  flex-grow: 1;
  margin-right: ${spacing.value(SPACING_SIZE.XL)};
`;

const Title = styled(Body1)`
  display: flex;

  ${typography.weight(FONT_WEIGHT.MEDIUM)}
  ${spacing.stack(SPACING_SIZE.XS)}
`;

const AppName = styled.span`
  margin-right: ${spacing.value(SPACING_SIZE.S)};
`;

const Rating = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

function Star() {
  return <img src={star} alt="" width={9} height={10} />;
}

function EmptyStar() {
  return <img src={emptyStar} alt="" width={9} height={10} />;
}

const HalfStar = styled.span`
  position: relative;
  display: block;

  img {
    display: block;
  }

  .halfPortion {
    position: absolute;
    top: 0;
    left: 0.8px;
  }
`;

const DescriptionBody = styled(Body2)`
  ${typography.weight(FONT_WEIGHT.NORMAL)}
  ${typography.lineHeight(12)}
`;

const Download = styled.div`
  align-self: center;
  ${typography.size(FONT_SIZE.XL)}
  ${color.text(COLOR_PALETTE.BLUEBERRY_A60)}
  
  ${Anchor} {
    text-decoration: none;
    ${typography.size(FONT_SIZE.XL)}
  }
`;

const Wrapper = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  width: 100vw;
  height: 97px;
  justify-self: start;

  ${color.background(COLOR_PALETTE.NEUTRAL_A05)}
  padding: ${spacing.value(SPACING_SIZE.M)};
  border-bottom: 1px solid #aaa;
`;

function AppDownloadBanner({ show }) {
  const isAndroid = useSelector(isAndroidSelector);
  const isIOS = useSelector(isIOSSelector);
  const downloadLink = useSelector(appDownloadLinkSelector);

  if (!show || !downloadLink) {
    return null;
  }

  return (
    <Wrapper>
      <Logo width={64} height={64} alt="App" android={isAndroid} ios={isIOS} />
      <Description>
        <Title>
          <AppName>Proppit</AppName>
          <Rating>
            <Star />
            <Star />
            <Star />
            <Star />
            <HalfStar>
              <EmptyStar />
              <img
                className="halfPortion"
                src={halfStar}
                alt=""
                width={4.5}
                height={9}
              />
            </HalfStar>
          </Rating>
        </Title>
        <DescriptionBody>
          <FormattedMessageWithValidation id="app_download_banner" />
        </DescriptionBody>
      </Description>
      <Download>
        <Anchor href={downloadLink}>
          <FormattedMessageWithValidation id="app_download_cta" />
        </Anchor>
      </Download>
    </Wrapper>
  );
}

export default AppDownloadBanner;
