import InputSelect from 'design-system/components/InputSelect';

export default function PublisherSelector({
  publishers,
  onSelect,
  selectedPublisherId,
  label = 'Publisher',
}) {
  return (
    <InputSelect
      name="publisherId"
      label={label}
      onSelect={onSelect}
      required
      defaultValue=""
      disabled={publishers.length === 0}
      value={selectedPublisherId}
      choices={publishers.map(({ name, id }) => ({ label: name, value: id }))}
      scrollable
    />
  );
}
