import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { FormattedNumber } from 'react-intl';
import Grid from 'design-system/components/Grid';
import SkeletonBone from 'design-system/components/SkeletonBone';
import Icon from 'design-system/components/Icon';
import { border, BORDER_SIZE } from 'design-system/styles/border';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { Heading2 } from 'design-system/components/Typography/presets/Heading2';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import Kpi from './Kpi';
import { importStatsSummarySelector } from '../../../../selectors/xmlStats';

const ErrorCard = styled(Kpi)`
  ${border.outer({
    size: BORDER_SIZE.BASE,
    color: COLOR_PALETTE.STRAWBERRY_A20,
  })}

  ${Subtitle3} {
    ${color.text(COLOR_PALETTE.STRAWBERRY_A80)}
  }
`;

function Kpis() {
  const stats = useSelector(importStatsSummarySelector);

  const { total, withErrors, duplicated, new: newAd } = stats;

  return (
    <Grid
      areas={({ repeat }) => `
          "${repeat(3, 'total')} ${repeat(3, 'new')} ${repeat(
        3,
        'errors'
      )} ${repeat(3, 'duplicated')}"
        `}
    >
      <Grid.Area $id="total">
        <Kpi
          icon={<Icon glyph="import" $color={COLOR_PALETTE.NEUTRAL_A80} />}
          message={
            <FormattedMessageWithValidation id="overview_published_properties_card_title_total" />
          }
          amount={
            total != null ? (
              <Heading2>
                <FormattedNumber value={total} />
              </Heading2>
            ) : (
              <SkeletonBone $height="22px" $width="40px" randomWidth />
            )
          }
        />
      </Grid.Area>
      <Grid.Area $id="new">
        <Kpi
          icon={<Icon glyph="add" $color={COLOR_PALETTE.NEUTRAL_A80} />}
          message={
            <Subtitle3>
              <FormattedMessageWithValidation id="import_stats_card_new" />
            </Subtitle3>
          }
          amount={
            newAd != null ? (
              <Heading2>
                <FormattedNumber value={newAd} />
              </Heading2>
            ) : (
              <SkeletonBone $height="22px" $width="40px" randomWidth />
            )
          }
        />
      </Grid.Area>
      <Grid.Area $id="errors">
        <ErrorCard
          icon={<Icon glyph="error" $color={COLOR_PALETTE.STRAWBERRY_BASE} />}
          message={
            <Subtitle3>
              <FormattedMessageWithValidation id="import_stats_errors_title" />
            </Subtitle3>
          }
          amount={
            withErrors != null ? (
              <Heading2 $color={COLOR_PALETTE.STRAWBERRY_BASE}>
                <FormattedNumber value={withErrors} />
              </Heading2>
            ) : (
              <SkeletonBone $height="22px" $width="40px" randomWidth />
            )
          }
        />
      </Grid.Area>
      <Grid.Area $id="duplicated">
        <Kpi
          icon={<Icon glyph="duplicate" $color={COLOR_PALETTE.NEUTRAL_A80} />}
          message={
            <Subtitle3>
              <FormattedMessageWithValidation id="overview_published_properties_card_title_duplicated" />
            </Subtitle3>
          }
          amount={
            duplicated != null ? (
              <Heading2>
                <FormattedNumber value={duplicated} />
              </Heading2>
            ) : (
              <SkeletonBone $height="22px" $width="40px" randomWidth />
            )
          }
        />
      </Grid.Area>
    </Grid>
  );
}

export default Kpis;
