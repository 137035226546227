export function convertPublisherToSelectedOptions({
  subscription,
  superboost,
  isThailandPropertyClient,
  typeOfClient,
}) {
  return {
    billingCycle:
      subscription.productId === 'free' ? 1 : subscription.billingCycleInMonths,
    boostingLimit:
      subscription.productId === 'free'
        ? null
        : subscription.maxBoostableProperties,
    superboostingLimit:
      subscription.productId === 'free'
        ? null
        : superboost?.maxBoostableProperties ?? null,
    publishesInThailandProperty: isThailandPropertyClient,
    planId: subscription.productId === 'free' ? '' : subscription.productId,
    typeOfClient,
  };
}
