import styled, { css } from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { useState } from 'react';
import { border } from 'design-system/styles/border';

const Wrapper = styled.div`
  position: relative;
  ${({ $isDragging }) =>
    $isDragging &&
    css`
      ${border.outer({ size: 1, color: COLOR_PALETTE.SECONDARY_A80 })}
      border-style: dashed;
    `};
`;

const Overlay = styled.div`
  position: absolute;
  pointer-events: none; // https://stackoverflow.com/a/18582960
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${color.background(COLOR_PALETTE.SECONDARY_BASE)}
  ${border.outer({ size: 1, color: COLOR_PALETTE.SECONDARY_A80 })}
  opacity: 0.2;
`;

export default function DropZone({ children, onDrop, ...rest }) {
  const [isDragging, setIsDragging] = useState(false);
  const [enterTarget, setEnterTarget] = useState();
  return (
    <Wrapper
      $isDragging={isDragging}
      onDragEnter={(e) => {
        if (!isDragging) {
          setEnterTarget(e.target);
          setIsDragging(true);
        }
      }}
      onDragLeave={(e) => {
        if (e.target === enterTarget) {
          setIsDragging(false);
        }
      }}
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDrop={(e) => {
        e.preventDefault();
        setIsDragging(false);
        onDrop(Array.from(e.dataTransfer.files));
      }}
      {...rest}
    >
      {children}
      {isDragging && <Overlay />}
    </Wrapper>
  );
}
