import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { COLOR_PALETTE } from 'design-system/styles/color';
import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Overline } from 'design-system/components/Typography/presets/Overline';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import FormattedMessageWithValidation from '../../../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Box = styled.div`
  ${spacing.inset(SPACING_SIZE.M)}
  display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.M)};
  background-color: ${COLOR_PALETTE.NEUTRAL_A03};
  border-radius: ${spacing.value(SPACING_SIZE.S)};
  word-wrap: break-word;
`;

const BoxTitle = styled(Overline)``;

const BoxText = styled(Body1)`
  display: flex;
  gap: ${spacing.value(SPACING_SIZE.S)};
`;

export default function Region({ question, answer }) {
  return (
    <Box>
      <BoxTitle>
        <FormattedMessageWithValidation id={question} />
      </BoxTitle>
      <BoxText>
        <Icon
          glyph="magnifierLens"
          $size={ICON_SIZE.SMALL}
          $color={COLOR_PALETTE.SECONDARY_A40}
        />
        <FormattedMessageWithValidation
          id={answer.label}
          values={{
            region: answer.value.region,
            minRooms: answer.value.minRooms,
            maxRooms: answer.value.maxRooms,
          }}
        />
      </BoxText>
    </Box>
  );
}
