import styled from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Tag, { TAG_VARIANT } from 'design-system/components/Tag';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { OutlineButton } from 'design-system/components/Button/presets';
import { border } from 'design-system/styles/border';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import SortableContainer from './SortableContainer';
import ConfirmDeleteImageDialog from './ConfirmDeleteImageDialog';
import FileInput from '../../../../partials/FileInput';
import DropZone from '../../../../partials/DropZone';

const imageSize = '7.5rem';

const Wrapper = styled(DropZone)`
  display: grid;
  grid-template-columns: 7.5rem 7.5rem 7.5rem 7.5rem;
  grid-template-rows: repeat(auto-fill, 7.5rem);
  column-gap: ${spacing.value(SPACING_SIZE.M)};
  row-gap: ${spacing.value(SPACING_SIZE.M)};
`;

export default function WithImages({
  id,
  images,
  isDisabled,
  onImagesUploaded,
  onImagesSorted,
  onDelete,
  showDeleteImageDialog,
  onConfirmDeleteImage,
  onCancelDeleteImage,
}) {
  return (
    <>
      <Wrapper onDrop={onImagesUploaded}>
        {!isDisabled && (
          <Cover
            id={id}
            onUpload={onImagesUploaded}
            disabled={isDisabled}
            multiple
            accept=".jpg, .jpeg, .png"
          >
            <Icon
              glyph="photoLibrary"
              $size={ICON_SIZE.LARGE}
              $color={COLOR_PALETTE.NEUTRAL_A20}
            />
          </Cover>
        )}
        <SortableContainer onSort={onImagesSorted}>
          {images.map((img) => (
            <ImagePreview key={img.id} onDelete={onDelete} {...img} />
          ))}
        </SortableContainer>
      </Wrapper>
      <ConfirmDeleteImageDialog
        open={showDeleteImageDialog}
        onConfirm={onConfirmDeleteImage}
        onCancel={onCancelDeleteImage}
      />
    </>
  );
}

const Cover = styled(FileInput)`
  height: ${imageSize};
  width: ${imageSize};
  display: flex;
  justify-content: center;
  align-items: center;
  ${border.outer({ size: 1, color: COLOR_PALETTE.SECONDARY_A80 })}
  border-style: dashed;
  ${radius.regular(RADIUS_SIZE.LARGE)}
  ${({ isDisabled }) => !isDisabled && 'cursor: pointer;'}

  &:hover {
    ${color.background(COLOR_PALETTE.SECONDARY_A05)}
    ${border.outer({ size: 1, color: COLOR_PALETTE.SECONDARY_BASE })}
    border-style: dashed;
  }

  ${Icon} {
    ${({ isDisabled }) => !isDisabled && 'cursor: pointer;'}
    margin: 0 auto;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  display: inline-flex;
  img {
    height: ${imageSize};
    width: ${imageSize};
    object-fit: cover;
    ${radius.regular(RADIUS_SIZE.LARGE)}
  }
  ${OutlineButton} {
    position: absolute;
    top: ${spacing.value(SPACING_SIZE.S)};
    right: ${spacing.value(SPACING_SIZE.S)};
    display: none;
  }
  &:hover ${OutlineButton} {
    display: block;
  }
  ${Tag} {
    ${Tag.mods.variant(TAG_VARIANT.SECONDARY)}
    position: absolute;
    bottom: ${spacing.value(SPACING_SIZE.S)};
    left: ${spacing.value(SPACING_SIZE.S)};
  }
`;

function ImagePreview({ id, file, isMain, onDelete }) {
  const src = isAFile(file) ? URL.createObjectURL(file) : file;
  return (
    <ImageWrapper id={id} key={id}>
      <img src={src} aria-hidden alt="property image preview" />
      <OutlineButton
        icon={<Icon glyph="bin" />}
        onMouseDown={() => onDelete(id)}
        $size={ICON_SIZE.SMALL}
      />
      {isMain && (
        <Tag>
          <FormattedMessageWithValidation id="order_photos_main_picture" />
        </Tag>
      )}
    </ImageWrapper>
  );
}

function isAFile(image) {
  return image instanceof Blob || image instanceof File;
}
