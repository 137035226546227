import { useSelector } from 'reffects-store';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Table from 'design-system/components/Table';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import { getProjectUnitSelector } from './selectors';
import PropertyOperationType from './PropertyOperationType';
import PropertyOperationPrice from '../../../Units/partials/UnitsTable/PropertyOperationPrice';
import { editUnitForm } from '../../../../utils/proppitWebRouter';
import PropertySnippet from '../../../../partials/PropertySnippet';
import { propertyTypesTranslationTags } from '../../../../constants/ad';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const NoWrapText = styled(Body1).attrs({
  $weight: FONT_WEIGHT.LIGHT,
  $ellipse: true,
})`
  white-space: nowrap;
`;

function CellLink({ children, id }) {
  return (
    <Table.CellLink forwardedAs={Link} to={editUnitForm(id)}>
      {children}
    </Table.CellLink>
  );
}

function AreaUnit({ unit }) {
  switch (unit) {
    case 'm2':
    case 'sqm':
      return (
        <>
          m<sup>2</sup>
        </>
      );

    default:
      return unit;
  }
}

function UnitRow({ unitId }) {
  const unit = useSelector((state) => getProjectUnitSelector(state, unitId));

  return (
    <Table.Row>
      <CellLink id={unitId}>
        <PropertySnippet image={unit.image} />
      </CellLink>
      <CellLink id={unitId}>
        <NoWrapText>{unit.referenceId}</NoWrapText>
      </CellLink>
      <CellLink id={unitId}>
        <NoWrapText>{unit.bedrooms}</NoWrapText>
      </CellLink>
      <CellLink id={unitId}>
        <NoWrapText>{unit.bathrooms}</NoWrapText>
      </CellLink>
      <CellLink id={unitId}>
        <NoWrapText>
          {unit.area.value} <AreaUnit unit={unit.area.unit} />
        </NoWrapText>
      </CellLink>
      <CellLink id={unitId}>
        <PropertyOperationPrice operations={unit.operations} />
      </CellLink>
      <CellLink id={unitId}>
        <NoWrapText>
          <FormattedMessageWithValidation
            id={propertyTypesTranslationTags.get(unit.unitType)}
          />
        </NoWrapText>
      </CellLink>
      <CellLink id={unitId}>
        <PropertyOperationType operations={unit.operations} />
      </CellLink>
      <CellLink id={unitId}>
        <NoWrapText>
          {unit.published ? (
            <FormattedMessageWithValidation id="project_page_units_table_published" />
          ) : (
            <FormattedMessageWithValidation id="project_page_units_table_unpublished" />
          )}
        </NoWrapText>
      </CellLink>
    </Table.Row>
  );
}

export default UnitRow;
