import Table from 'design-system/components/Table';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import { useColumn } from '../../../../partials/DataTable/hooks/useColumn';

export function ExternalReferenceColumn(props) {
  const { itemId } = props;
  const { value: proppitId } = useColumn({
    itemId,
    source: 'id',
  });
  const { value: referenceId } = useColumn({
    itemId,
    source: 'referenceId',
  });

  return (
    <Table.Cell maxWidth={250}>
      <Body1 $weight={FONT_WEIGHT.LIGHT}>
        {referenceId == null ? proppitId : referenceId}
      </Body1>
    </Table.Cell>
  );
}
