export const layout = {
  minWidth: 1280,
  maxWidth: 1680,
  sidebarWidth: 394,
  gutter: 24,
  colums: {
    fullWidth: 12,
    oneSidebar: 9,
    twoSidebars: 6,
  },
};
