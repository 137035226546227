import styled from 'styled-components';
import { divider, DIVIDER_SIZE } from 'design-system/styles/divider';

const Footer = styled.footer`
  --layout-columns: 12;
  grid-area: footer;
  align-self: end;
  z-index: 1;

  ${divider.top(DIVIDER_SIZE.THIN)}
`;

export default Footer;
