import styled from 'styled-components';
import JSONPrettyCore from 'react-json-pretty';
import JSONPrettyTheme from 'react-json-pretty/dist/1337';
import Tile from 'design-system/components/Tile';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';

export const BodyWrapper = styled(Tile)`
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  ${spacing.inset(SPACING_SIZE.S)};
  ${spacing.stack(SPACING_SIZE.S)};
  padding: ${spacing.value(SPACING_SIZE.S)} ${spacing.value(SPACING_SIZE.M)};
`;

export const Title = styled.div`
  ${color.text(COLOR_PALETTE.NEUTRAL_A80)}
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  ${spacing.stack(SPACING_SIZE.S)};
`;

export const Details = styled.div``;

export const Paragraph = styled.div`
  ${spacing.stack(SPACING_SIZE.S)};
`;

export const List = styled.ul`
  ${spacing.stack(SPACING_SIZE.S)};
  padding-left: ${spacing.value(SPACING_SIZE.M)};

  li {
    ${spacing.stack(SPACING_SIZE.S)};
  }
`;

export const Footer = styled.div`
  font-size: 12px;
  text-align: right;
  margin-top: 1em;
  padding: ${spacing.value(SPACING_SIZE.S)} ${spacing.value(SPACING_SIZE.M)};
  border-top: 1px solid #ddd;
  & > * {
    margin-bottom: 5px;
  }
`;

export const RefList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  * {
    ${spacing.stack(SPACING_SIZE.S)}
  }
`;

export const Action = styled.div`
  display: flex;
  justify-content: center;
  line-height: 1.2rem;

  a {
    flex-grow: 1;
    text-decoration: none;
    text-align: center;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const JSONPrettyWrapper = styled.div`
  max-height: 200px;
  overflow-y: scroll;
`;

export function JSONPretty({ data }) {
  return (
    <JSONPrettyWrapper>
      <JSONPrettyCore theme={JSONPrettyTheme} data={data} />
    </JSONPrettyWrapper>
  );
}

const ByWrapper = styled.div`
  margin-top: 5px;
`;

export function By({ event }) {
  return (
    event.by && (
      <ByWrapper>
        Triggered by <b>{getBy(event)}</b>
      </ByWrapper>
    )
  );
}

function getBy(item) {
  if (item.by === null) {
    return '??';
  }

  return item.by?.email ?? '???';
}
