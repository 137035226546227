import { subscribe } from 'reffects-store';
import InputRow from 'design-system/components/InputRow';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { publisherCountrySelector } from '../../../selectors/publisher';
import {
  callingCodeSelector,
  shouldShowContactFacebookMessengerSelector,
  shouldShowContactLineSelector,
  shouldShowContactViberSelector,
} from '../selectors';
import { FieldGroup } from './FieldGroup';
import ChipsField from '../../../partials/fields/ChipsField';
import PhoneField from '../../../partials/fields/PhoneField';
import ViberField from '../../../partials/fields/ViberField';
import LineField from '../../../partials/fields/LineField';
import FacebookMessengerField from '../../../partials/fields/FacebookMessengerField';
import WhatsappField from '../../../partials/fields/WhatsappField';

function ContactDetailsGroup({
  callingCode,
  countryCode,
  showLine,
  showFacebookMessenger,
  showViber,
  order,
}) {
  return (
    <FieldGroup
      id="contact-detail"
      title={<FormattedMessageWithValidation id="newprop_contact_header" />}
      order={order}
    >
      <InputRow>
        <ChipsField
          required
          label={<FormattedMessageWithValidation id="newprop_contact_email" />}
          name="contactEmails"
          tip={
            <FormattedMessageWithValidation id="helper_adform_contact_details" />
          }
          data-tag="contact_emails"
        />
      </InputRow>
      <InputRow arrangement={[5]}>
        <PhoneField
          required
          label={<FormattedMessageWithValidation id="newprop_contact_phone" />}
          name="contactPhone"
          data-tag="contact_phone"
          data-test="contact_phone"
        />
      </InputRow>
      <InputRow arrangement={[5]}>
        <WhatsappField
          label="Whatsapp"
          name="contactWhatsApp"
          callingCodeChoices={[
            {
              label: callingCode,
              value: callingCode,
            },
          ]}
          phoneRegionCode={countryCode}
          data-tag="contact_whatsapp"
        />
      </InputRow>
      {showFacebookMessenger && (
        <InputRow arrangement={[5]}>
          <FacebookMessengerField
            label="Facebook messenger"
            name="contactFacebookMessenger"
            data-tag="contact_facebook"
          />
        </InputRow>
      )}
      {showLine && (
        <InputRow arrangement={[5]}>
          <LineField label="Line" name="contactLine" data-tag="contact_line" />
        </InputRow>
      )}

      {showViber && (
        <InputRow arrangement={[5]}>
          <ViberField label="Viber" name="contactViber" />
        </InputRow>
      )}
    </FieldGroup>
  );
}

export default subscribe(ContactDetailsGroup, function mapStateToProps(state) {
  return {
    callingCode: callingCodeSelector(state),
    countryCode: publisherCountrySelector(state),
    showLine: shouldShowContactLineSelector(state),
    showFacebookMessenger: shouldShowContactFacebookMessengerSelector(state),
    showViber: shouldShowContactViberSelector(state),
  };
});
