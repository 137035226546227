import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import Autocomplete from 'design-system/components/Autocomplete/Autocomplete';
import InputText from 'design-system/components/InputText';
import Icon from 'design-system/components/Icon';
import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import {
  ADMIN_PROJECT_NAME_AUTOCOMPLETE_CLEARED,
  AUTOCOMPLETE_PROJECT_NAME_TEXT_INTRODUCED,
  PROJECT_NAME_AUTOCOMPLETE_BLURRED,
} from './events';
import { projectSearchTextSelector } from './selectors';

const Wrapper = styled.div.attrs({ className: 'ProjectNameAutocomplete' })`
  ${spacing.stack(SPACING_SIZE.L, false)}
`;

export default function ProjectNameAutocomplete({
  value,
  projectSuggestions,
  isLoading,
  onSelectProject,
  onClear = onClearProject,
  publisherCountry,
  disabled,
  required,
}) {
  const searchText = useSelector(projectSearchTextSelector);
  return (
    <Wrapper>
      <Autocomplete
        id="project-name"
        options={projectSuggestions}
        value={searchText ?? value.name ?? ''}
        onValueChange={onProjectNameTextIntroduced(publisherCountry)}
        onOptionSelected={onSelectProject}
        isLoading={isLoading}
        emptyStateMessage="No projects match with your search"
        loadingStateMessage="Loading..."
        optionToString={(option) => (option === undefined ? '' : option.name)}
        renderInput={({ value: inputValue, getInputProps }) => (
          <InputText
            required={required}
            disabled={disabled}
            label="Search for a project"
            icon={<Icon glyph="cross" />}
            onIconClick={() => {
              onClear();
              onClearProject();
            }}
            {...getInputProps({ value: inputValue, autoComplete: 'off' })}
            onBlur={onAutocompleteBlur}
          />
        )}
        renderOptions={({ options: projectOptions, getOptionProps }) =>
          projectOptions.map((option, index) => (
            <Autocomplete.Option
              key={option.id}
              title={option.name}
              subtitle={option.id}
              icon={<Icon glyph="building" />}
              {...getOptionProps({
                index,
                option,
              })}
            />
          ))
        }
      />
    </Wrapper>
  );
}

function onProjectNameTextIntroduced(publisherCountry) {
  return (projectSearchText) =>
    dispatch({
      id: AUTOCOMPLETE_PROJECT_NAME_TEXT_INTRODUCED,
      payload: {
        searchText: projectSearchText,
        publisherCountry,
      },
    });
}

function onAutocompleteBlur() {
  dispatch(PROJECT_NAME_AUTOCOMPLETE_BLURRED);
}

function onClearProject() {
  dispatch(ADMIN_PROJECT_NAME_AUTOCOMPLETE_CLEARED);
}
