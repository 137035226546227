import styled from 'styled-components';
import Image from 'design-system/components/Image/Image';
import Tooltip from 'design-system/components/Tooltip';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import {
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { Overline } from 'design-system/components/Typography/presets/Overline';
import propertyType2 from './images/propertyType2.png';
import propertyType1 from './images/propertyType1.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.M)};
  align-self: flex-start;
  width: 100%;
`;

const BarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.XXS)};
  align-self: flex-start;
  width: 100%;
`;

const Container = styled.div`
  display: block;
  position: relative;
  width: 100%;
`;

const Fill = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  ${({ $percentage }) => `width: ${$percentage}%;`}
  height: 100%;
  ${color.background(COLOR_PALETTE.SECONDARY_A10)}
  z-index: -1;
  border-radius: 2px;
  ${radius.regular(RADIUS_SIZE.SMALL)}
`;

const PropertyType = styled.div`
  height: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 100px;
  ${spacing.inset(SPACING_SIZE.XS)}
  ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
  ${typography.size(FONT_SIZE.S)}
  ${typography.weight(FONT_WEIGHT.MEDIUM)}
  ${typography.font(FONT_FAMILY.PRIMARY)}
`;

const Percentage = styled.div`
  display: inline-flex;
  ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
  ${typography.size(FONT_SIZE.S)}
  ${typography.weight(FONT_WEIGHT.MEDIUM)}
  ${typography.font(FONT_FAMILY.PRIMARY)}
`;

const BarContainer = styled.div`
  display: flex;
  gap: ${spacing.value(SPACING_SIZE.S)};
  align-items: center;
  width: 100%;
  ${Image} {
    height: 13px;
  }
  > div:first-child {
    ${({ $percentage }) => `width: ${$percentage}%;`}
    min-width: 100px;
    display: block;
  }
`;

function Bar({ percentage, name }) {
  const fixedPercentage = percentage * 0.85;
  return (
    <Container>
      <Fill $percentage={fixedPercentage} />
      <BarContainer $percentage={fixedPercentage}>
        <Tooltip title={name}>
          <PropertyType $percentage={fixedPercentage}>{name}</PropertyType>
        </Tooltip>
        <Percentage>{percentage}%</Percentage>
      </BarContainer>
    </Container>
  );
}

export default function BarChart({ title, elements, blurred = false }) {
  if (blurred) {
    return (
      <Wrapper>
        <Overline>{title}</Overline>
        <BarWrapper>
          <Bar percentage={66} name={<Image source={propertyType1} />} />
          <Bar percentage={34} name={<Image source={propertyType2} />} />
        </BarWrapper>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <Overline>{title}</Overline>
      <BarWrapper>
        {elements.map((element) => (
          <Bar
            key={element.name + element.percentage}
            percentage={element.percentage}
            name={element.name}
          />
        ))}
      </BarWrapper>
    </Wrapper>
  );
}
