import * as React from "react";
const SvgSubscription = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={128}
    height={114}
    fill="none"
    {...props}
  >
    <rect
      width={50}
      height={68}
      x={77}
      y={26}
      fill="#F3FCF9"
      stroke="#0ACC86"
      strokeWidth={2}
      rx={3}
    />
    <path fill="#B6F0DB" d="M123 27h1a2 2 0 0 1 2 2v62a2 2 0 0 1-2 2h-1z" />
    <path stroke="#0ACC86" strokeWidth={2} d="M115.027 37.123h12.489" />
    <rect
      width={50}
      height={88}
      x={65}
      y={14}
      fill="#fff"
      stroke="#0ACC86"
      strokeWidth={2}
      rx={3}
    />
    <path fill="#B6F0DB" d="M109 15h3a2 2 0 0 1 2 2v82a2 2 0 0 1-2 2h-3z" />
    <path
      stroke="#0ACC86"
      strokeLinecap="round"
      strokeWidth={2}
      d="M95 56h12M95 61h12M95 66h12M95 71h12M95 77h12"
    />
    <path stroke="#0ACC86" strokeWidth={2} d="M95 32h20" />
    <rect
      width={50}
      height={68}
      x={1}
      y={26}
      fill="#F3FCF9"
      stroke="#0ACC86"
      strokeWidth={2}
      rx={3}
    />
    <path fill="#B6F0DB" d="M5 93H4a2 2 0 0 1-2-2V29a2 2 0 0 1 2-2h1z" />
    <path stroke="#0ACC86" strokeWidth={2} d="M1.313 37.123h12.488" />
    <rect
      width={50}
      height={88}
      x={13}
      y={14}
      fill="#fff"
      stroke="#0ACC86"
      strokeWidth={2}
      rx={3}
    />
    <path fill="#B6F0DB" d="M19 101h-3a2 2 0 0 1-2-2V17a2 2 0 0 1 2-2h3z" />
    <path
      stroke="#0ACC86"
      strokeLinecap="round"
      strokeWidth={2}
      d="M20.719 55.527h9.831M20.719 60.787h9.831M20.719 66.045h9.831M20.719 71.303h9.831M20.719 76.561h9.831"
    />
    <path stroke="#0ACC86" strokeWidth={2} d="M12.488 32h18.405" />
    <rect
      width={64}
      height={112}
      x={31}
      y={1}
      fill="#fff"
      stroke="#0ACC86"
      strokeWidth={2}
      rx={3}
    />
    <rect
      width={46}
      height={8}
      x={39}
      y={97}
      fill="#0ACC86"
      stroke="#0ACC86"
      strokeWidth={2}
      rx={1}
    />
    <path
      stroke="#0ACC86"
      strokeLinecap="round"
      strokeWidth={2}
      d="M56.211 12.146h22.32M56.211 18.063h30.865"
    />
    <path
      stroke="#9DEBCF"
      strokeLinecap="round"
      strokeWidth={2}
      d="M50 48h31M50 56h31M50 65h31M50 73h31M50 82h31"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeWidth={2}
      d="m39.438 47.146 1.232 1.494 2.711-3.287"
    />
    <path
      stroke="#0ACC86"
      strokeLinecap="round"
      strokeWidth={2}
      d="m39.438 47.146 1.232 1.494 2.711-3.287"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeWidth={2}
      d="m39.438 55.691 1.232 1.494 2.711-3.287"
    />
    <path
      stroke="#0ACC86"
      strokeLinecap="round"
      strokeWidth={2}
      d="m39.438 55.691 1.232 1.494 2.711-3.287"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeWidth={2}
      d="m39.438 64.894 1.232 1.494 2.711-3.286"
    />
    <path
      stroke="#0ACC86"
      strokeLinecap="round"
      strokeWidth={2}
      d="m39.438 64.894 1.232 1.494 2.711-3.286"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeWidth={2}
      d="m39.438 74.095 1.232 1.494 2.711-3.286"
    />
    <path
      stroke="#0ACC86"
      strokeLinecap="round"
      strokeWidth={2}
      d="m39.438 74.095 1.232 1.494 2.711-3.286"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeWidth={2}
      d="m39.438 82.64 1.232 1.494 2.711-3.286"
    />
    <path
      stroke="#0ACC86"
      strokeLinecap="round"
      strokeWidth={2}
      d="m39.438 82.64 1.232 1.494 2.711-3.286"
    />
    <rect
      width={9.174}
      height={9.174}
      x={39.781}
      y={10.859}
      fill="#E7FAF3"
      stroke="#0ACC86"
      strokeWidth={2}
      rx={1}
    />
    <path stroke="#0ACC86" strokeWidth={2} d="M31 28h64" />
  </svg>
);
export default SvgSubscription;
