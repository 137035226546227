import { TYPE_OF_CLIENT_FREE_VALUE } from './constants';
import InputSelectWithHtmlValidation from '../InputSelectWithHtmlValidation';
import filterChoices from './filterChoices';

const propertyName = 'typeOfClient';

const defaultValue = TYPE_OF_CLIENT_FREE_VALUE;

function TypeOfClientSelect({ value, availableTypes, onChange }) {
  return (
    <InputSelectWithHtmlValidation
      name={propertyName}
      label="Type of client"
      value={value}
      required
      choices={filterChoices(availableTypes)}
      onSelect={onChange}
    />
  );
}

TypeOfClientSelect.propertyName = propertyName;
TypeOfClientSelect.defaultValue = defaultValue;

export default TypeOfClientSelect;
