import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import Illustration from 'design-system/components/Illustration/Illustration';
import { sitesIdSelector } from '../selectors/config';

const siteToIllustrationNameMap = {
  nuroa: 'nuroaLogo',
  trovit: 'trovitLogo',
  mitula: 'mitulaLogo',
  nestoria: 'nestoriaLogo',
  laencontre: 'laEncontreLogo',
  icasas: 'iCasasLogo',
  dotproperty: 'dotPropertyLogo',
  puntopropiedad: 'puntoPropiedadLogo',
  hipflat: 'hipFlatLogo',
  thailandproperty: 'thailandPropertyLogo',
  properati: 'properatiLogo',
  lamudi: 'lamudiLogo',
};

const Wrapper = styled.span`
  ${color.text(COLOR_PALETTE.SECONDARY_A40)}
`;
function PublisherSites({ className }) {
  const sites = useSelector(sitesIdSelector);

  return (
    <Wrapper className={className}>
      {sites.map(
        (site) =>
          siteToIllustrationNameMap[site] && (
            <Illustration key={site} name={siteToIllustrationNameMap[site]} />
          )
      )}
    </Wrapper>
  );
}

export default styled(PublisherSites)``;
