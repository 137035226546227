import {
  activeSubscriptionSelector,
  isPublisherFromSEASelector,
  publisherSelector,
} from '../../../selectors/publisher';
import { showSitesToPublishSelector } from '../../../App/selectors';

export function hasPromotedProjectsSelector(state) {
  return state.publisher.hasPromotedProjects;
}

export function activeSubscriptionBenefitsSelector(state) {
  const subscription = activeSubscriptionSelector(state);
  if (!subscription) return {};
  const isFromSea = isPublisherFromSEASelector(state);
  const isPaidSubscription = subscription.type === 'paid';
  if (!isFromSea) {
    return {
      planId: subscription.id,
      isPaidSubscription,
      showPublicationSites: showSitesToPublishSelector(state),
    };
  }
  const publisher = publisherSelector(state);
  return {
    planId: subscription.id,
    showPublicationSites: showSitesToPublishSelector(state),
    isPaidSubscription,
    hasNewsletter: isFromSea && isPaidSubscription,
    publishesOnThailandProperty: publisher.publishesOnThailandProperty,
  };
}
