export const BACKOFFICE_ROOT = 'admin';
export const AGENCIES = 'agencies';
export const DEVELOPERS = 'developers';
export const CAMPAIGNS = 'campaigns';

export const PATHS = {
  BACKOFFICE_ROOT: `/${BACKOFFICE_ROOT}`,
  AGENCIES: `/${BACKOFFICE_ROOT}/${AGENCIES}`,
  DEVELOPERS: `/${BACKOFFICE_ROOT}/${DEVELOPERS}`,
  CAMPAIGNS: `/${BACKOFFICE_ROOT}/${CAMPAIGNS}`,
};
