import { useMount } from 'react-use';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import Anchor from 'design-system/components/Anchor';
import Button from 'design-system/components/Button';
import Tag from 'design-system/components/Tag';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { SUBSCRIPTION_TO_UPGRADE_REQUESTED } from '../SubscriptionTable/events';
import {
  publisherCountryShowRenewalPricesSelector,
  subscriptionToUpgradeSelector,
} from '../../../../selectors/publisher';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import CustomFormattedDate from '../../../../partials/FormattedDate/CustomFormattedDate';
import Price from '../../../../partials/Price/Price';
import { OPEN_DIALOG } from '../../../../events/dialogs';
import { SUBSCRIPTION_DETAILS_DIALOG } from '../SubscriptionDetailsModal';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border: solid 1px ${COLOR_PALETTE.NEUTRAL_A10};
  margin-top: ${spacing.value(SPACING_SIZE.L)};

  ${radius.regular(RADIUS_SIZE.BASE)} ${spacing.inset(SPACING_SIZE.M)};
`;

const Header = styled.div`
  ${spacing.stack(SPACING_SIZE.S)}
`;

const RenewalRow = styled.div`
  display: flex;
  flex-direction: row;

  ${spacing.stack(SPACING_SIZE.L, false)}
  > div {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
  }

  ${Heading1} {
    ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
    ${typography.weight(FONT_WEIGHT.SEMIBOLD)}
  }

  ${Body1} {
    ${color.text(COLOR_PALETTE.NEUTRAL_A80)}
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${Heading1} {
    ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
  }

  ${Button} {
    margin-left: ${spacing.value(SPACING_SIZE.S)};
  }

  ${Tag} {
    margin-left: ${spacing.value(SPACING_SIZE.S)};
  }
`;

export default function RenewalDetails() {
  useMount(() => dispatch(SUBSCRIPTION_TO_UPGRADE_REQUESTED));
  const subscription = useSelector(subscriptionToUpgradeSelector);
  const hidePrices = useSelector(publisherCountryShowRenewalPricesSelector);

  if (!subscription) {
    return null;
  }
  return (
    <Wrapper>
      <Header>
        <Subtitle3 $color={COLOR_PALETTE.NEUTRAL_A60}>
          <FormattedMessageWithValidation id="subscription_renewal_details" />
        </Subtitle3>
      </Header>
      <Content>
        <RenewalRow>
          <div>
            <Heading1>
              <CustomFormattedDate
                value={subscription.startDate}
                timeZone="utc"
              />
            </Heading1>
            <Body1>
              <FormattedMessageWithValidation id="subscription_next_renewal_date" />
            </Body1>
          </div>
          {hidePrices && (
            <div>
              <Heading1>
                <Price
                  amount={subscription.totalPrice.amount}
                  currency={subscription.totalPrice.currency}
                />
              </Heading1>
              <Body1>
                <FormattedMessageWithValidation id="subscription_renewal_price" />
              </Body1>
            </div>
          )}
        </RenewalRow>
        {hidePrices && (
          <Anchor
            href="#"
            onClick={() =>
              dispatch({
                id: OPEN_DIALOG,
                payload: {
                  id: SUBSCRIPTION_DETAILS_DIALOG,
                  parameters: {
                    title: 'subscription_renewal_details',
                    subscription,
                  },
                },
              })
            }
          >
            <FormattedMessageWithValidation id="subscription_view_details" />
          </Anchor>
        )}
      </Content>
    </Wrapper>
  );
}
