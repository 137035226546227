function subscriptionChangeRequestSelector(state) {
  return state?.subscriptionChangeRequest;
}
export function isSubscriptionChangeRequestDataLoaded(state) {
  return subscriptionChangeRequestSelector(state) != null;
}

export function subscriptionChangeRequestDetailsSelector(state) {
  return subscriptionChangeRequestSelector(state)?.requestDetails;
}

export function subscriptionChangeRequestPaymentDetailsSelector(state) {
  return subscriptionChangeRequestSelector(state)?.paymentDetails;
}
