import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import {
  tablePageChangedWithURLUpdate,
  tableRowsPerPageChangedWithURLUpdate,
  tableSorted,
} from '../../../Tables/events';
import { location } from '../../../../coeffects/location';

export const UNITS_TABLE_PAGE_CHANGED = 'UNITS_TABLE_PAGE_CHANGED';
export const UNITS_TABLE_ROWS_PER_PAGE_CHANGED =
  'UNITS_TABLE_ROWS_PER_PAGE_CHANGED';
export const UNITS_TABLE_SORTED = 'UNITS_TABLE_SORTED';

registerEventHandler(
  UNITS_TABLE_PAGE_CHANGED,
  tablePageChangedWithURLUpdate('unitsTable'),
  [
    state.get({
      collectionById: 'unitsById',
      filters: 'unitsTable.filters',
      pagination: 'unitsTable.pagination',
      sorting: 'unitsTable.sorting',
    }),
    location(),
  ]
);

registerEventHandler(
  UNITS_TABLE_ROWS_PER_PAGE_CHANGED,
  tableRowsPerPageChangedWithURLUpdate('unitsTable'),
  [
    state.get({
      collectionById: 'unitsById',
      filters: 'unitsTable.filters',
      pagination: 'unitsTable.pagination',
      sorting: 'unitsTable.sorting',
    }),
    location(),
  ]
);

registerEventHandler(UNITS_TABLE_SORTED, tableSorted('unitsTable'), [
  state.get({
    collectionById: 'unitsById',
    filters: 'unitsTable.filters',
    pagination: 'unitsTable.pagination',
    sorting: 'unitsTable.sorting',
  }),
  location(),
]);
