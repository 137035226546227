import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { environment } from '../../../../../../../coeffects/environment';
import { CLOSE_DIALOG, OPEN_DIALOG } from '../../../../../../../events/dialogs';
import { toast } from '../../../../../../../effects/toast';

export const EDIT_LISTING_RULES_MODAL = 'EDIT_LISTING_RULES_MODAL';
export const PUBLISHER_EDIT_LISTING_RULES_MODAL_OPENED =
  'PUBLISHER_EDIT_LISTING_RULES_MODAL_OPENED';
export const PUBLISHER_LISTING_RULES_LOADED = 'PUBLISHER_LISTING_RULES_LOADED';
export const PUBLISHER_EDIT_LISTING_RULES_MODAL_SUBMITTED =
  'PUBLISHER_EDIT_LISTING_RULES_MODAL_SUBMITTED';
export const PUBLISHER_EDIT_LISTING_RULES_MODAL_SUBMITTED_SUCCESS =
  'PUBLISHER_EDIT_LISTING_RULES_MODAL_SUBMITTED_SUCCESS';
export const PUBLISHER_EDIT_LISTING_RULES_CLOSED =
  'PUBLISHER_EDIT_LISTING_RULES_CLOSED';

registerEventHandler(
  PUBLISHER_EDIT_LISTING_RULES_MODAL_OPENED,
  ({ environment: { apiUrl } }, { publisherId, countryCode }) => ({
    ...http.get({
      url: `${apiUrl}/backoffice/publishers/${publisherId}/listing-rules`,
      successEvent: {
        id: PUBLISHER_LISTING_RULES_LOADED,
        payload: { publisherId, countryCode },
      },
    }),
  }),
  [environment()]
);

registerEventHandler(
  PUBLISHER_LISTING_RULES_LOADED,
  (_, [{ data }, { publisherId, countryCode }]) => ({
    ...effects.dispatch({
      id: OPEN_DIALOG,
      payload: {
        id: EDIT_LISTING_RULES_MODAL,
        parameters: { publisherId, countryCode },
      },
    }),
    ...state.set({
      brandsToPublishNonBoostedListingsTo: data,
    }),
  })
);

registerEventHandler(
  PUBLISHER_EDIT_LISTING_RULES_MODAL_SUBMITTED,
  (
    { environment: { apiUrl }, state: { brandsToPublishNonBoostedListingsTo } },
    { onSuccessEvent, nonBoostedListingsSelectedOptions, publisherId }
  ) => {
    const newBrandsToPublishNonBoostedListingsTo = Object.keys(
      brandsToPublishNonBoostedListingsTo
    ).reduce((acc, brand) => {
      acc[brand] = nonBoostedListingsSelectedOptions.some(
        (sentBrand) => sentBrand === brand
      );

      return acc;
    }, {});

    return {
      ...http.post({
        url: `${apiUrl}/backoffice/publishers/${publisherId}/listing-rules`,
        body: newBrandsToPublishNonBoostedListingsTo,
        successEvent: {
          id: PUBLISHER_EDIT_LISTING_RULES_MODAL_SUBMITTED_SUCCESS,
          payload: { onSuccessEvent },
        },
      }),
    };
  },
  [
    environment(),
    state.get({
      brandsToPublishNonBoostedListingsTo:
        'brandsToPublishNonBoostedListingsTo',
    }),
  ]
);

registerEventHandler(
  PUBLISHER_EDIT_LISTING_RULES_MODAL_SUBMITTED_SUCCESS,
  (_, [__, { onSuccessEvent }]) => ({
    ...toast.show({
      text: 'Success! The changes may take several minutes',
    }),
    ...effects.dispatchMany([
      CLOSE_DIALOG,
      onSuccessEvent,
      PUBLISHER_EDIT_LISTING_RULES_CLOSED,
    ]),
  })
);

registerEventHandler(PUBLISHER_EDIT_LISTING_RULES_CLOSED, () =>
  state.set({
    brandsToPublishNonBoostedListingsTo: null,
  })
);
