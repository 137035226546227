import { useCallback, useEffect, useState, useRef } from 'react';

function createFileUploader(options = {}) {
  const { acceptFileTypes = ['*'] } = options;

  const inMemoryInput = initializeInput();

  function initializeInput() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = acceptFileTypes.join(', ');

    return input;
  }

  function upload() {
    inMemoryInput.click();
  }

  function onChange(handler) {
    inMemoryInput.addEventListener(
      'change',
      (e) => e.target.files.length !== 0 && handler(e.target.files)
    );
  }

  return {
    upload,
    onChange,
  };
}

function useFileUpload(options) {
  const [file, setFile] = useState(null);
  const fileUploaderRef = useRef(null);

  const handleUploaderChange = ([newFile]) => {
    setFile(newFile);
  };

  useEffect(() => {
    const fileUploader = createFileUploader(options);

    fileUploader.onChange(handleUploaderChange);

    fileUploaderRef.current = fileUploader;
  }, []);

  const uploadFile = useCallback(() => {
    fileUploaderRef.current.upload();
  });

  const resetFile = useCallback(() => {
    fileUploaderRef.current.value = null;
    setFile(null);
  });

  return {
    file,
    uploadFile,
    resetFile,
  };
}

export default useFileUpload;
