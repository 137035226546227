import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { localStorage } from '../../effects/localStorage';
import { analytics } from '../../effects/analytics';
import { calendar } from '../../coeffects/calendar';

export const NOT_USING_WHOLE_PLAN_BANNER_CLOSED =
  'NOT_USING_WHOLE_PLAN_BANNER_CLOSED';

registerEventHandler(
  NOT_USING_WHOLE_PLAN_BANNER_CLOSED,
  ({ calendar: { now } }) => ({
    ...analytics.trackClick('banner-closed', 'not-using-whole-plan-banner'),
    ...localStorage.setItem('notUsingWholePlanBannerClosedDate', now),
    ...state.set({
      notUsingWholePlanBannerClosedDate: now,
    }),
  }),
  [calendar()]
);
