import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import Price from '../../../../../partials/Price/Price';
import DisclaimerText from './DisclaimerText';

export function CurrencyConversionDisclaimer({ convertedPrice }) {
  const { amount, currency } = convertedPrice;
  return (
    <DisclaimerText>
      <FormattedMessageWithValidation
        id="checkout_summary_total_converted"
        values={{
          amountWithCurrency: <Price currency={currency} amount={amount} />,
        }}
      />
    </DisclaimerText>
  );
}
