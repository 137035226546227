import styled, { css } from 'styled-components';
import Icon from 'design-system/components/Icon';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import Typography, {
  TYPOGRAPHY_VARIANT,
} from 'design-system/components/Typography/Typography';
import { SortingIcon } from './SortingIcon';
import { COLUMN_SORTING } from './constants';

const modSortingNotSortable = () => css`
  cursor: initial;

  ${Icon} {
    width: 0;
    overflow: hidden;
  }
`;

const modSortingIndeterminate = () => css`
  cursor: pointer;
`;

const modSortingDeterminate = () => css`
  cursor: pointer;
`;

const modSorting = (sortingDirection) =>
  ({
    [COLUMN_SORTING.NOT_SORTABLE]: modSortingNotSortable,
    [COLUMN_SORTING.INDETERMINATE]: modSortingIndeterminate,
    [COLUMN_SORTING.ASCENDING]: modSortingDeterminate,
    [COLUMN_SORTING.DESCENDING]: modSortingDeterminate,
  }[sortingDirection]);

const Wrapper = styled.th`
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}
  ${({ maxWidth }) => maxWidth != null && `max-width: ${maxWidth}px;`}
  ${color.background(COLOR_PALETTE.NEUTRAL_A00)}

  ${Typography} {
    ${Typography.mods.ellipse()}
    ${Typography.mods.variant(TYPOGRAPHY_VARIANT.OVERLINE)}
  }

  ${({ sortingDirection }) => modSorting(sortingDirection)}
`;

const ColumnContent = styled.div`
  display: flex;
  align-items: center;
`;

function ColumnTitle({
  children,
  sortingDirection = COLUMN_SORTING.INDETERMINATE,
  ...rest
}) {
  return (
    <Wrapper sortingDirection={sortingDirection} {...rest}>
      <ColumnContent>
        {children}
        <SortingIcon sortingDirection={sortingDirection} />
      </ColumnContent>
    </Wrapper>
  );
}

export default styled(ColumnTitle)``;
