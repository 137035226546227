import styled, { css } from 'styled-components';
import { divider, DIVIDER_SIZE } from 'design-system/styles/divider';
import { layout } from 'design-system/tokens/layout';
import { color, COLOR_PALETTE } from 'design-system/styles/color';

const modDelimitedColumn = () => css`
  @media only screen and (min-width: ${layout.maxWidth}px) {
    ${divider.left(DIVIDER_SIZE.THIN)}
  }
`;

const LeftSidebar = styled.div`
  --layout-columns: 1;

  grid-area: sidebar_left;
  justify-self: end;
  z-index: 2;

  width: var(--layout-sidebar-width);

  ${divider.right(DIVIDER_SIZE.THIN)}

  ${({ delimitedColumn }) => delimitedColumn && modDelimitedColumn()}

  ${({ supportsMobile }) =>
    supportsMobile &&
    css`
      @media (max-width: 770px) {
        width: 100%;
        justify-self: unset;
        border: none;
        ${color.background(COLOR_PALETTE.SECONDARY_A05)}
      }
    `}
`;

export default LeftSidebar;
