import * as React from "react";
const SvgCreateProperty = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={214}
    height={102}
    fill="none"
    {...props}
  >
    <g clipPath="url(#CreateProperty_svg__a)">
      <path
        fill="#fff"
        stroke="#0ACC86"
        d="M133.5 44a3.5 3.5 0 0 1 3.5-3.5h3a3.5 3.5 0 0 1 3.5 3.5v73.5h-10z"
      />
      <path
        fill="#fff"
        stroke="#0ACC86"
        d="M127.5 46a1.5 1.5 0 0 1 1.5-1.5h18a1.5 1.5 0 0 1 1.5 1.5v71.5h-21z"
      />
      <path fill="#0ACC86" d="M127 48a4 4 0 0 1 4-4h1v74h-5z" opacity={0.3} />
      <rect width={22} height={1} x={126.999} y={54} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={126.999} y={57} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={126.999} y={60} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={126.999} y={63} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={126.999} y={66} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={126.999} y={69} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={126.999} y={72} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={126.999} y={75} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={126.999} y={78} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={126.999} y={81} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={126.999} y={84} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={126.999} y={87} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={126.999} y={90} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={126.999} y={93} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={126.999} y={96} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={126.999} y={99} fill="#0ACC86" rx={0.5} />
      <path
        fill="#fff"
        stroke="#0ACC86"
        d="M178.75 74a1.5 1.5 0 0 0-1.5-1.5h-8a1.5 1.5 0 0 0-1.5 1.5v43.5h11z"
      />
      <rect
        width={6}
        height={1}
        fill="#0ACC86"
        rx={0.5}
        transform="matrix(-1 0 0 1 179.25 75)"
      />
      <rect
        width={6}
        height={1}
        fill="#0ACC86"
        rx={0.5}
        transform="matrix(-1 0 0 1 179.25 78)"
      />
      <rect
        width={6}
        height={1}
        fill="#0ACC86"
        rx={0.5}
        transform="matrix(-1 0 0 1 179.25 81)"
      />
      <rect
        width={6}
        height={1}
        fill="#0ACC86"
        rx={0.5}
        transform="matrix(-1 0 0 1 179.25 84)"
      />
      <rect
        width={6}
        height={1}
        fill="#0ACC86"
        rx={0.5}
        transform="matrix(-1 0 0 1 179.25 87)"
      />
      <rect
        width={6}
        height={1}
        fill="#0ACC86"
        rx={0.5}
        transform="matrix(-1 0 0 1 179.25 90)"
      />
      <rect
        width={6}
        height={1}
        fill="#0ACC86"
        rx={0.5}
        transform="matrix(-1 0 0 1 179.25 93)"
      />
      <rect
        width={6}
        height={1}
        fill="#0ACC86"
        rx={0.5}
        transform="matrix(-1 0 0 1 179.25 96)"
      />
      <path
        fill="#0ACC86"
        fillRule="evenodd"
        d="M155.75 77a.5.5 0 0 1-.5-.5V.5a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        stroke="#0ACC86"
        d="M150.25 19a3.5 3.5 0 0 1 3.5-3.5h4a3.5 3.5 0 0 1 3.5 3.5v63.5h-11z"
      />
      <path
        fill="#fff"
        stroke="#0ACC86"
        d="M145.25 29a3.5 3.5 0 0 1 3.5-3.5h14a3.5 3.5 0 0 1 3.5 3.5v88.5h-21z"
      />
      <path
        fill="#0ACC86"
        d="M145.75 28a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v90h-20z"
        opacity={0.2}
      />
      <path
        fill="#fff"
        stroke="#0ACC86"
        d="M169.25 30a1.5 1.5 0 0 0-1.5-1.5h-24a1.5 1.5 0 0 0-1.5 1.5v87.5h27z"
      />
      <path
        fill="#0ACC86"
        d="M168.75 30a1 1 0 0 0-1-1h-2v89h3z"
        opacity={0.3}
      />
      <path
        fill="#0ACC86"
        fillRule="evenodd"
        d="M145.25 115.015a.5.5 0 0 1-.5-.5v-76a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5M148.25 115.015a.5.5 0 0 1-.5-.5v-76a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5M151.25 115.015a.5.5 0 0 1-.5-.5v-76a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5M154.251 115.015a.5.5 0 0 1-.5-.5v-76a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5M157.25 115.015a.5.5 0 0 1-.5-.5v-76a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5M160.25 115.014a.5.5 0 0 1-.5-.5v-76a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5M163.25 115.015a.5.5 0 0 1-.5-.5v-76a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5M166.25 115a.5.5 0 0 1-.5-.5v-76a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5"
        clipRule="evenodd"
      />
      <path
        fill="#0ACC86"
        d="M115.75 64a2 2 0 0 1 2-2h3v56h-5z"
        opacity={0.3}
      />
      <path
        fill="#fff"
        stroke="#0ACC86"
        d="M113.25 62a1.5 1.5 0 0 1 1.5-1.5h32a1.5 1.5 0 0 1 1.5 1.5v55.5h-35z"
      />
      <rect width={24} height={1} x={118.75} y={65} fill="#0ACC86" rx={0.5} />
      <rect
        width={24}
        height={1}
        x={118.75}
        y={67.995}
        fill="#0ACC86"
        rx={0.5}
      />
      <rect
        width={24}
        height={1}
        x={118.75}
        y={70.99}
        fill="#0ACC86"
        rx={0.5}
      />
      <rect
        width={24}
        height={1}
        x={118.75}
        y={73.985}
        fill="#0ACC86"
        rx={0.5}
      />
      <rect
        width={24}
        height={1}
        x={118.75}
        y={76.981}
        fill="#0ACC86"
        rx={0.5}
      />
      <rect
        width={24}
        height={1}
        x={118.75}
        y={79.976}
        fill="#0ACC86"
        rx={0.5}
      />
      <rect
        width={24}
        height={1}
        x={118.75}
        y={82.971}
        fill="#0ACC86"
        rx={0.5}
      />
      <rect
        width={24}
        height={1}
        x={118.75}
        y={85.966}
        fill="#0ACC86"
        rx={0.5}
      />
      <rect
        width={24}
        height={1}
        x={118.75}
        y={88.961}
        fill="#0ACC86"
        rx={0.5}
      />
      <rect
        width={24}
        height={1}
        x={118.75}
        y={91.957}
        fill="#0ACC86"
        rx={0.5}
      />
      <rect
        width={24}
        height={1}
        x={118.75}
        y={94.951}
        fill="#0ACC86"
        rx={0.5}
      />
      <rect
        width={24}
        height={1}
        x={118.75}
        y={97.947}
        fill="#0ACC86"
        rx={0.5}
      />
      <rect
        width={24}
        height={1}
        x={118.75}
        y={100.941}
        fill="#0ACC86"
        rx={0.5}
      />
      <path fill="#0ACC86" d="M0 101h213v1H0z" />
      <path
        fill="#fff"
        d="M31.375 28.348v56.304A4.355 4.355 0 0 0 35.737 89h81.276a4.355 4.355 0 0 0 4.362-4.348V28.348A4.355 4.355 0 0 0 117.013 24H35.737a4.355 4.355 0 0 0-4.362 4.348"
      />
      <path
        stroke="#0ACC86"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M31.375 67.912V28.348A4.355 4.355 0 0 1 35.737 24h81.276a4.355 4.355 0 0 1 4.362 4.348v56.304A4.355 4.355 0 0 1 117.013 89H35.737a4.355 4.355 0 0 1-4.362-4.348v-8.169"
      />
      <path
        stroke="#0ACC86"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M121.375 28.354v8.098h-90v-8.098A4.36 4.36 0 0 1 35.737 24h81.276a4.36 4.36 0 0 1 4.362 4.354"
      />
      <path
        stroke="#0ACC86"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={3.655}
        d="M102.368 30.226h-.102M108.459 30.226h-.103M114.55 30.226h-.103"
      />
      <path
        fill="#0ACC86"
        d="m38.68 31.505-.9-3.69a.39.39 0 0 1 .072-.342.4.4 0 0 1 .315-.153h.225q.225 0 .396.144a.58.58 0 0 1 .207.36l.558 3.177q0 .009.009.009.018 0 .018-.009l.567-3.177a.63.63 0 0 1 .216-.36.59.59 0 0 1 .387-.144h.45q.225 0 .396.144a.58.58 0 0 1 .207.36l.567 3.177q0 .009.018.009.009 0 .009-.009l.558-3.177a.63.63 0 0 1 .216-.36.59.59 0 0 1 .387-.144h.189q.198 0 .315.153a.36.36 0 0 1 .072.342l-.9 3.69a.654.654 0 0 1-.63.495h-.45a.59.59 0 0 1-.387-.144.63.63 0 0 1-.216-.36l-.585-3.087q0-.009-.009-.009t-.009.009l-.585 3.087a.58.58 0 0 1-.207.36.6.6 0 0 1-.396.144h-.45a.645.645 0 0 1-.63-.495m7.084 0-.9-3.69a.39.39 0 0 1 .072-.342.4.4 0 0 1 .315-.153h.225q.225 0 .396.144a.58.58 0 0 1 .207.36l.558 3.177q0 .009.009.009.018 0 .018-.009l.567-3.177a.63.63 0 0 1 .216-.36.59.59 0 0 1 .387-.144h.45q.225 0 .396.144a.58.58 0 0 1 .207.36l.567 3.177q0 .009.018.009.009 0 .009-.009l.558-3.177a.63.63 0 0 1 .216-.36.59.59 0 0 1 .387-.144h.189q.198 0 .315.153a.36.36 0 0 1 .072.342l-.9 3.69a.654.654 0 0 1-.63.495h-.45a.59.59 0 0 1-.387-.144.63.63 0 0 1-.216-.36l-.585-3.087q0-.009-.009-.009t-.009.009l-.585 3.087a.58.58 0 0 1-.207.36.6.6 0 0 1-.396.144h-.45a.645.645 0 0 1-.63-.495m7.084 0-.9-3.69a.39.39 0 0 1 .072-.342.4.4 0 0 1 .315-.153h.225q.225 0 .396.144a.58.58 0 0 1 .207.36l.558 3.177q0 .009.009.009.018 0 .018-.009l.567-3.177a.63.63 0 0 1 .216-.36.59.59 0 0 1 .387-.144h.45q.225 0 .396.144a.58.58 0 0 1 .207.36l.567 3.177q0 .009.018.009.009 0 .009-.009l.558-3.177a.63.63 0 0 1 .216-.36.59.59 0 0 1 .387-.144h.189q.198 0 .315.153a.36.36 0 0 1 .072.342l-.9 3.69a.654.654 0 0 1-.63.495h-.45a.59.59 0 0 1-.387-.144.63.63 0 0 1-.216-.36l-.585-3.087q0-.009-.009-.009t-.009.009l-.585 3.087a.58.58 0 0 1-.207.36.6.6 0 0 1-.396.144h-.45a.645.645 0 0 1-.63-.495"
      />
      <rect
        width={36}
        height={24.327}
        x={38.875}
        y={43.744}
        fill="#E7FAF3"
        stroke="#0ACC86"
        rx={2.5}
      />
      <mask
        id="CreateProperty_svg__b"
        width={90}
        height={42}
        x={35}
        y={51}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "alpha",
        }}
      >
        <path
          fill="#D9D9D9"
          fillRule="evenodd"
          d="M35.375 89.5v3h89v-41h-2.5v33a5 5 0 0 1-5 5z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#CreateProperty_svg__b)">
        <path
          fill="#9DEBCF"
          d="M37.375 61h87v23a8 8 0 0 1-8 8h-77a2 2 0 0 1-2-2z"
        />
      </g>
      <path
        stroke="#0ACC86"
        d="m74.875 62-4.244-3.149a2 2 0 0 0-2.367-.012l-3.199 2.323a2 2 0 0 1-2.322.02l-10.507-7.357a2 2 0 0 0-2.336.03L38.875 62"
      />
      <circle cx={63.262} cy={49.851} r={3.024} stroke="#0ACC86" />
      <path
        stroke="#0ACC86"
        strokeLinecap="round"
        d="M39.375 74.571h35M39.375 80.571h35M84.375 60h29M84.375 66h29M84.375 72h11"
      />
      <rect
        width={30}
        height={10}
        x={83.875}
        y={44.5}
        stroke="#0ACC86"
        rx={1.482}
      />
      <path
        stroke="#0ACC86"
        strokeLinecap="round"
        d="M26.649 66.75v11M21.25 72.35h11M103.594 4.326v11M98.195 9.925h11M26.695 16.951v6M23.695 19.951h6"
      />
      <circle cx={129.5} cy={18.625} r={2.5} stroke="#0ACC86" />
      <circle cx={20} cy={64.875} r={1.5} stroke="#0ACC86" />
      <circle cx={40.447} cy={13.373} r={2.5} stroke="#0ACC86" />
      <path
        stroke="#0ACC86"
        strokeLinecap="round"
        d="M181.004 54.318v11M175.604 59.918h11"
      />
    </g>
    <defs>
      <clipPath id="CreateProperty_svg__a">
        <path fill="#fff" d="M.5 0h213v102H.5z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCreateProperty;
