import styled from 'styled-components';
import { dispatch } from 'reffects';
import { ROWS_PER_PAGE } from 'design-system/components/Table';
import Layout from 'design-system/components/Layout';
import { OutlineButton } from 'design-system/components/Button/presets';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import { Navigation } from '../../partials';
import { DataTableProvider } from '../../partials/DataTable';
import createManagedPublishersTableAdapter from './tableAdapter';
import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import PublishersTable from './PublishersTable';
import EditBoostingModal from './EditBoostingModal';
import { OPEN_EDIT_BOOSTING_MODAL } from './events';
import useUpdateTableEvent from '../../partials/DataTable/hooks/useUpdateTableEvent';

const adapter = createManagedPublishersTableAdapter();
const collectionName = 'managedPublishers';

export default function ManagedPublishers() {
  const context = {
    collectionName,
    defaultSorting: {
      field: 'name',
      direction: 'asc',
    },
    initialItemsPerPage: ROWS_PER_PAGE.TWENTY_FIVE,
    adapter,
  };
  return (
    <Layout>
      <Layout.Header>
        <Navigation />
      </Layout.Header>
      <DataTableProvider {...context}>
        <Layout.Content>
          <Content />
        </Layout.Content>
      </DataTableProvider>
    </Layout>
  );
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${spacing.stack(SPACING_SIZE.M)}
`;

const Toolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  ${spacing.insetSandwich(SPACING_SIZE.S)}
  ${spacing.stack(SPACING_SIZE.M, false)}
`;

function Content() {
  const updateTableEvent = useUpdateTableEvent();
  return (
    <Layout.Padded>
      <Header>
        <Heading1>
          <FormattedMessageWithValidation id="managed_publishers_page_header" />
        </Heading1>
      </Header>
      <Toolbar>
        <OutlineButton
          onClick={() =>
            dispatch({
              id: OPEN_EDIT_BOOSTING_MODAL,
              payload: { collectionName },
            })
          }
        >
          <FormattedMessageWithValidation id="managed_publishers_edit_boosting" />
        </OutlineButton>
      </Toolbar>
      <PublishersTable />
      <EditBoostingModal onSuccessEvent={updateTableEvent} />
    </Layout.Padded>
  );
}
