import * as React from "react";
const SvgBuildingsGrey = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={154}
    height={124}
    fill="none"
    {...props}
  >
    <path
      fill="#F2F2F2"
      stroke="#CCC"
      d="M32 43.5h3a3.5 3.5 0 0 1 3.5 3.5v73.5h-10V47a3.5 3.5 0 0 1 3.5-3.5Z"
    />
    <path
      fill="#F2F2F2"
      stroke="#CCC"
      d="M24 47.5h18a1.5 1.5 0 0 1 1.5 1.5v71.5h-21V49a1.5 1.5 0 0 1 1.5-1.5Z"
    />
    <path fill="#CCC" d="M22 51a4 4 0 0 1 4-4h1v74h-5z" opacity={0.3} />
    <rect width={22} height={1} x={21.999} y={57} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={60} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={63} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={66} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={69} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={72} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={75} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={78} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={81} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={84} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={87} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={90} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={93} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={96} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={99} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={102} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={105} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={108} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={111} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={114} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={117} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={120} fill="#CCC" rx={0.5} />
    <path
      fill="#F2F2F2"
      stroke="#CCC"
      d="M101 52.5h3a3.5 3.5 0 0 1 3.5 3.5v64.5h-10V56a3.5 3.5 0 0 1 3.5-3.5Z"
    />
    <path
      fill="#F2F2F2"
      stroke="#CCC"
      d="M93 56.5h18a1.5 1.5 0 0 1 1.5 1.5v62.5h-21V58a1.5 1.5 0 0 1 1.5-1.5Z"
    />
    <path fill="#CCC" d="M91 60a4 4 0 0 1 4-4h1v65h-5z" opacity={0.3} />
    <rect width={22} height={1} x={91} y={66} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={91} y={69} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={91} y={72} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={91} y={75} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={91} y={78} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={91} y={81} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={91} y={84} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={91} y={87} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={91} y={90} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={91} y={93} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={91} y={96} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={91} y={99} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={91} y={102} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={91} y={105} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={91} y={108} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={91} y={111} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={91} y={114} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={91} y={117} fill="#CCC" rx={0.5} />
    <rect width={22} height={1} x={91} y={120} fill="#CCC" rx={0.5} />
    <path fill="#CCC" d="M35 67a2 2 0 0 1 2-2h3v56h-5z" opacity={0.3} />
    <path
      fill="#F2F2F2"
      stroke="#CCC"
      d="M18 75.5h8a1.5 1.5 0 0 1 1.5 1.5v43.5h-11V77a1.5 1.5 0 0 1 1.5-1.5Z"
    />
    <rect width={6} height={1} x={16} y={78} fill="#CCC" rx={0.5} />
    <rect width={6} height={1} x={16} y={81} fill="#CCC" rx={0.5} />
    <rect width={6} height={1} x={16} y={84} fill="#CCC" rx={0.5} />
    <rect width={6} height={1} x={16} y={87} fill="#CCC" rx={0.5} />
    <rect width={6} height={1} x={16} y={90} fill="#CCC" rx={0.5} />
    <rect width={6} height={1} x={16} y={93} fill="#CCC" rx={0.5} />
    <rect width={6} height={1} x={16} y={96} fill="#CCC" rx={0.5} />
    <rect width={6} height={1} x={16} y={99} fill="#CCC" rx={0.5} />
    <path
      fill="#CCC"
      fillRule="evenodd"
      d="M80 80a.5.5 0 0 1-.5-.5v-76a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5"
      clipRule="evenodd"
    />
    <path
      fill="#F2F2F2"
      stroke="#CCC"
      d="M78 18.5h4a3.5 3.5 0 0 1 3.5 3.5v63.5h-11V22a3.5 3.5 0 0 1 3.5-3.5Z"
    />
    <path
      fill="#F2F2F2"
      stroke="#CCC"
      d="M73 28.5h14a3.5 3.5 0 0 1 3.5 3.5v88.5h-21V32a3.5 3.5 0 0 1 3.5-3.5Z"
    />
    <path
      fill="#CCC"
      d="M70 31a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v90H70z"
      opacity={0.2}
    />
    <path
      fill="#F2F2F2"
      stroke="#CCC"
      d="M92 31.5H68a1.5 1.5 0 0 0-1.5 1.5v87.5h27V33a1.5 1.5 0 0 0-1.5-1.5Z"
    />
    <path fill="#CCC" d="M93 33a1 1 0 0 0-1-1h-2v89h3z" opacity={0.3} />
    <path
      fill="#CCC"
      fillRule="evenodd"
      d="M69.5 118.015a.5.5 0 0 1-.5-.5v-76a.5.5 0 1 1 1 0v76a.5.5 0 0 1-.5.5M72.5 118.014a.5.5 0 0 1-.5-.5v-76a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5M75.5 118.014a.5.5 0 0 1-.5-.5v-76a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5M78.5 118.015a.5.5 0 0 1-.5-.5v-76a.5.5 0 1 1 1 0v76a.5.5 0 0 1-.5.5M81.5 118.014a.5.5 0 0 1-.5-.5v-76a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5M84.5 118.014a.5.5 0 0 1-.5-.5v-76a.5.5 0 1 1 1 0v76a.5.5 0 0 1-.5.5M87.5 118.015a.5.5 0 0 1-.5-.5v-76a.5.5 0 1 1 1 0v76a.5.5 0 0 1-.5.5M90.5 118a.5.5 0 0 1-.5-.5v-76a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5"
      clipRule="evenodd"
    />
    <path
      fill="#F2F2F2"
      stroke="#CCC"
      d="M39 63.5h32a1.5 1.5 0 0 1 1.5 1.5v55.5h-35V65a1.5 1.5 0 0 1 1.5-1.5Z"
    />
    <rect width={24} height={1} x={43} y={68} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={43} y={70.995} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={43} y={73.99} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={43} y={76.985} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={43} y={79.981} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={43} y={82.976} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={43} y={85.97} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={43} y={88.966} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={43} y={91.961} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={43} y={94.957} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={43} y={97.951} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={43} y={100.947} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={43} y={103.942} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={43} y={106.937} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={43} y={109.932} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={43} y={113} fill="#CCC" rx={0.5} />
    <path fill="#CCC" d="M0 120h154v1H0z" />
    <path fill="#CCC" d="M106 67a2 2 0 0 1 2-2h3v56h-5z" opacity={0.3} />
    <path
      fill="#F2F2F2"
      stroke="#CCC"
      d="M108.5 65a1.5 1.5 0 0 1 1.5-1.5h32a1.5 1.5 0 0 1 1.5 1.5v55.5h-35z"
    />
    <rect width={24} height={1} x={114} y={68} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={114} y={70.995} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={114} y={73.99} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={114} y={76.985} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={114} y={79.981} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={114} y={82.976} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={114} y={85.97} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={114} y={88.966} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={114} y={91.961} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={114} y={94.957} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={114} y={97.951} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={114} y={100.947} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={114} y={103.942} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={114} y={106.937} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={114} y={109.932} fill="#CCC" rx={0.5} />
    <rect width={24} height={1} x={114} y={113} fill="#CCC" rx={0.5} />
  </svg>
);
export default SvgBuildingsGrey;
