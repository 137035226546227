import { useSelector } from 'reffects-store';
import { DropdownFilter } from '../../../../partials/DataTable';
import { NO_FILTER_OPTION } from './constants';
import { availableCountriesForBackofficeUserSelector } from '../../../selectors/countries';

export default function CountryFilter({
  source,
  onSelectOption,
  withoutAllOption = false,
}) {
  const options = [
    ...useSelector(availableCountriesForBackofficeUserSelector).map(
      ({ code, name }) => ({
        id: code,
        value: code,
        label: name,
      })
    ),
  ];

  if (!withoutAllOption) {
    options.unshift({ id: NO_FILTER_OPTION, value: null, label: 'All' });
  }

  return (
    <DropdownFilter
      label="Country"
      source={source}
      options={options}
      onSelectOption={onSelectOption}
    />
  );
}
