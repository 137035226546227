import Banner, { BANNER_VARIANT } from 'design-system/components/Banner';
import { Link } from 'react-router-dom';
import { useSelector } from 'reffects-store';
import FormattedMessageWithValidation from '../../FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  isDeveloperSelector,
  publisherNameSelector,
} from '../../../selectors/publisher';
import { PATHS } from '../../../Backoffice/constants/routes';

export default function ImpersonationWarningBanner() {
  const publisherName = useSelector(publisherNameSelector);
  const isDeveloper = useSelector(isDeveloperSelector);

  return (
    <Banner variant={BANNER_VARIANT.ALERT}>
      <FormattedMessageWithValidation
        id="warning_visualize_mode_communication"
        values={{ publisherName: <b>{publisherName}</b> }}
      />{' '}
      <Link to={isDeveloper ? PATHS.DEVELOPERS : PATHS.AGENCIES}>
        <FormattedMessageWithValidation id="warning_visualize_mode_communication_link" />
      </Link>
    </Banner>
  );
}
