import Layout from 'design-system/components/Layout';
import styled from 'styled-components';
import Illustration from 'design-system/components/Illustration/Illustration';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import {
  FONT_FAMILY,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Anchor from 'design-system/components/Anchor';
import BackLink from 'design-system/components/BackLink';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import SubscriptionLayout from './partials/Layout/Layout';
import { SUBSCRIPTION } from '../../constants/routes';
import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import Header from './partials/Layout/Header';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.L)};
  ${BackLink} {
    text-decoration: none;
  }
`;
const CenteredImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing.value(SPACING_SIZE.L)};
`;

const CustomIllustration = styled(Illustration)`
  width: 287px;
  height: 149px;
  flex-shrink: 0;
`;

const RedirectText = styled(Body1)`
  ${color.text(COLOR_PALETTE.NEUTRAL_A80)}
  ${typography.font(FONT_FAMILY.PRIMARY)}
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
`;

const Title = styled(Header)`
  ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
  ${typography.font(FONT_FAMILY.PRIMARY)}
    ${typography.weight(FONT_WEIGHT.SEMIBOLD)}
    font-size: 22px;
  font-style: normal;
  text-align: left;
`;

export default function CreditCardAdded() {
  const navigate = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(SUBSCRIPTION);
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <SubscriptionLayout>
      <Layout.Padded>
        <Content>
          <Body>
            <BackLink forwardedAs={Link} to={SUBSCRIPTION}>
              <FormattedMessageWithValidation id="subscription_back_to_subscription" />
            </BackLink>
            <Title>
              <FormattedMessageWithValidation id="subscription_credit_card_successfully_added_header" />
            </Title>
            <CenteredImage>
              <CustomIllustration name="creditCardLogo" />
            </CenteredImage>
            <RedirectText>
              <FormattedMessageWithValidation
                id="subscription_credit_card_successfully_added_text"
                values={{
                  pressHere: (
                    <Anchor as={Link} to={SUBSCRIPTION}>
                      <FormattedMessageWithValidation id="press_here" />
                    </Anchor>
                  ),
                }}
              />
            </RedirectText>
          </Body>
        </Content>
      </Layout.Padded>
    </SubscriptionLayout>
  );
}
