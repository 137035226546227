import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import Map from 'design-system/components/Map';
import { Overline } from 'design-system/components/Typography/presets/Overline';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import FormattedMessageWithValidation from '../../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { mostRelevantCoordinatesSelector } from './selectors';

const StyledMap = styled(Map)`
  height: 280px;
`;

const Wrapper = styled.div`
  width: 100%;

  ${Overline} {
    display: block;
    padding: 0 ${spacing.value(SPACING_SIZE.XL)};
    ${spacing.stack(SPACING_SIZE.S, false)};
  }
`;

export default function LocationsMap() {
  const coordinates = useSelector(mostRelevantCoordinatesSelector);
  if (coordinates.length === 0) {
    return null;
  }
  return (
    <Wrapper>
      <Overline>
        <FormattedMessageWithValidation id="lead_detail_activity_locations_title" />
      </Overline>
      <StyledMap draggable zoomControls zoomWithWheel circles={coordinates} />
    </Wrapper>
  );
}
