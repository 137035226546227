import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { toast } from '../../../../../../../effects/toast';
import { environment } from '../../../../../../../coeffects/environment';
import { DISASSOCIATE_MODAL } from '../constants';
import { CLOSE_DIALOG, OPEN_DIALOG } from '../../../../../../../events/dialogs';

export const DISASSOCIATE_PUBLISHER = 'DISASSOCIATE_PUBLISHER';
export const DISASSOCIATE_PUBLISHER_SUCCEEDED =
  'DISASSOCIATE_PUBLISHER_SUCCEEDED';
export const DISASSOCIATE_PUBLISHER_FAILED = 'DISASSOCIATE_PUBLISHER_FAILED';
export const OPEN_DISASSOCIATE_MODAL = 'OPEN_DISASSOCIATE_MODAL';

registerEventHandler(OPEN_DISASSOCIATE_MODAL, (_, __) =>
  effects.dispatch({
    id: OPEN_DIALOG,
    payload: { id: DISASSOCIATE_MODAL },
  })
);

registerEventHandler(
  DISASSOCIATE_PUBLISHER,
  ({ environment: { apiUrl } }, { publisherId, successEvent }) => ({
    ...state.set({
      disassociateModal: {
        inProgress: true,
      },
    }),
    ...http.put({
      url: `${apiUrl}/admin/publishers/${publisherId}/disassociate`,
      successEvent: {
        id: DISASSOCIATE_PUBLISHER_SUCCEEDED,
        payload: { successEvent },
      },
      errorEvent: DISASSOCIATE_PUBLISHER_FAILED,
    }),
  }),
  [environment()]
);

registerEventHandler(
  DISASSOCIATE_PUBLISHER_SUCCEEDED,
  (_, [, { successEvent }]) => ({
    ...state.set({
      disassociateModal: {
        inProgress: false,
      },
    }),
    ...effects.dispatchMany([CLOSE_DIALOG, successEvent]),
    ...toast.show({
      text: 'Publisher has been disassociated successfully!',
    }),
  })
);

registerEventHandler(DISASSOCIATE_PUBLISHER_FAILED, (_, __) => ({
  ...state.set({
    disassociateModal: {
      inProgress: false,
    },
  }),
  ...effects.dispatch(CLOSE_DIALOG),
  ...toast.show({
    text: 'The publisher could not be disassociated',
  }),
}));
