import styled from 'styled-components';
import { FlatButton } from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import { color, COLOR_PALETTE } from 'design-system/styles/color';

const Wrapper = styled.header`
  padding: ${spacing.value(SPACING_SIZE.L)} ${spacing.value(SPACING_SIZE.XL)}
    ${spacing.value(SPACING_SIZE.M)};

  ${Heading1} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}

    ${FlatButton} {
      transform: translateX(${SPACING_SIZE.S}px);
    }
  }
`;

function Header({ onClose, children, ...rest }) {
  return (
    <Wrapper {...rest}>
      <Heading1 as="h1">
        {children}
        {onClose && (
          <FlatButton icon={<Icon glyph="cross" />} onClick={onClose} />
        )}
      </Heading1>
    </Wrapper>
  );
}

export default styled(Header)``;
