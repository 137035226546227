import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle1 } from 'design-system/components/Typography/presets/Subtitle1';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Wrapper = styled.div`
  ${spacing.stack(SPACING_SIZE.XL)}
`;

const PaymentInformationSection = styled.section`
  ${spacing.stack(SPACING_SIZE.L)}
`;

const PaymentDetails = styled.div`
  ${spacing.stack(SPACING_SIZE.L)};

  > * {
    display: block;
  }

  ${Subtitle1} {
    ${spacing.stack(SPACING_SIZE.M)};
  }
`;

const BlockBody1 = styled(Body1)`
  display: block;
  margin-top: ${SPACING_SIZE.XS}px;
  ${typography.weight(FONT_WEIGHT.LIGHT)}
`;

function DirectDebitPaymentInformation() {
  return (
    <Wrapper>
      <PaymentInformationSection>
        <PaymentDetails>
          <Subtitle1>
            <FormattedMessageWithValidation id="subscription_payment_information_title" />
          </Subtitle1>
          <Subtitle3>
            <FormattedMessageWithValidation id="agencyprofile_payments_method" />
          </Subtitle3>
          <BlockBody1>
            <FormattedMessageWithValidation id="payment_method_name_direct_debit" />
          </BlockBody1>
        </PaymentDetails>
      </PaymentInformationSection>
    </Wrapper>
  );
}

export default styled(DirectDebitPaymentInformation)`
  ${Body1} {
    display: block;
  }
`;
