import styled from 'styled-components';
import Stepper, { STEPPER_ITEM_STATUS } from 'design-system/components/Stepper';
import Layout from 'design-system/components/Layout';
import Anchor from 'design-system/components/Anchor';
import { radius } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import Typography, {
  TYPOGRAPHY_VARIANT,
} from 'design-system/components/Typography/Typography';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Overline } from 'design-system/components/Typography/presets/Overline';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import { useGroup, useGroupIdList } from './FieldGroup';

const Wrapper = styled.div`
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;

  justify-content: space-between;
`;

const Header = styled.div`
  ${spacing.stack(SPACING_SIZE.L)}
  ${Heading1} {
    display: block;
    ${spacing.stack(SPACING_SIZE.M)}
  }
`;

const Bullet = styled(Stepper.Item)`
  ${Anchor} {
    text-decoration: none;

    ${Typography.mods.variant(TYPOGRAPHY_VARIANT.SUBTITLE_3)}
    ${color.text(COLOR_PALETTE.SECONDARY_BASE)}
        > * {
      ${spacing.inline(SPACING_SIZE.S)}
    }

    ${Overline} {
      ${color.background(COLOR_PALETTE.STRAWBERRY_BASE)}
      ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
      ${radius.pill()}
      padding: 0 ${spacing.value(SPACING_SIZE.S)};
    }
  }
`;

function GroupValidationBullet({ id }) {
  const { title, errors } = useGroup(id);
  const hasErrors = errors > 0;

  return (
    <Bullet
      key={id}
      status={hasErrors && STEPPER_ITEM_STATUS.ERROR}
      data-test={`sidebar-bullet-${id}`}
    >
      <Anchor href={`#${id}`}>
        <span>{title}</span>
        {hasErrors && <Overline data-test="error-count">{errors}</Overline>}
      </Anchor>
    </Bullet>
  );
}

function Sidebar({ title, description, children }) {
  const groupIdList = useGroupIdList();

  return (
    <Wrapper>
      <Layout.Padded>
        <Header>
          <Heading1>{title}</Heading1>
          <Body2 $color={COLOR_PALETTE.NEUTRAL_A60}>{description}</Body2>
        </Header>
        <Stepper>
          {groupIdList.map((id) => (
            <GroupValidationBullet key={id} id={id} />
          ))}
        </Stepper>
      </Layout.Padded>
      <Layout.Padded>{children}</Layout.Padded>
    </Wrapper>
  );
}

export default Sidebar;
