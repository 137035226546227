import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMount } from 'react-use';
import Autocomplete from 'design-system/components/Autocomplete/Autocomplete';
import BaseMenu from 'design-system/components/Menu/partials/BaseMenu';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { SALES_PERSON_EMAIL_SEARCHED } from './events';
import SearchField from './SearchField';
import { useFilter } from '../../../../../../../partials/DataTable/hooks/useFilter';
import { salesPersonEmailsSelector } from './selectors';

import { SALES_PEOPLE_REQUESTED } from '../../../../../events';

const EMPTY_TEXT = '';

const Wrapper = styled(Autocomplete)`
  ${BaseMenu.Item} {
    ${spacing.inset(SPACING_SIZE.S)};

    &:hover {
      ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
    }
    &:first-of-type:hover,
    &[aria-selected='true'] {
      ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
    }
  }
`;

export default function SalesPersonFilter() {
  useMount(() => dispatch(SALES_PEOPLE_REQUESTED));
  const salesPersonEmails = useSelector(salesPersonEmailsSelector);
  const { value: filterValue, setValue: setFilterValue } =
    useFilter('salesPersonEmail');
  const [query, setQuery] = useState(filterValue);

  useEffect(() => {
    // sync autosuggestion query with current filter value
    setQuery(filterValue ?? EMPTY_TEXT);
  }, [filterValue]);

  return (
    <Wrapper
      value={query}
      options={salesPersonEmails}
      onValueChange={(value) => {
        setQuery(value);
        dispatch({
          id: SALES_PERSON_EMAIL_SEARCHED,
          payload: { searchText: value },
        });
      }}
      optionToString={(option) => option ?? EMPTY_TEXT}
      onOptionSelected={setFilterValue}
      renderInput={({ value = EMPTY_TEXT, getInputProps, onClear }) => (
        <SearchField
          {...getInputProps()}
          value={value}
          onRemove={() => {
            onClear();
            setFilterValue(null);
            setQuery(EMPTY_TEXT);
          }}
        />
      )}
      renderOptions={({ options, getOptionProps }) =>
        options.map((option, index) => (
          <BaseMenu.Item
            {...getOptionProps({ option, index, refKey: 'innerRef' })}
          >
            {option}
          </BaseMenu.Item>
        ))
      }
      emptyStateMessage="No matching results"
    />
  );
}
