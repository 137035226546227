import styled, { css } from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import { withStylesAndInnerRef } from '../../../utils';
import BaseItem from './BaseItem';

const modMultiline = () => css`
  align-self: flex-start;
`;

const Content = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;

  > * {
    ${spacing.stack(SPACING_SIZE.XXS)}
  }

  ${({ multiline }) => multiline && modMultiline()}
`;

function Item({ children, subtitle, as, ...rest }) {
  return (
    <BaseItem {...rest} forwardedAs={as}>
      <Content multiline={Boolean(subtitle)}>
        <Body1
          $weight={FONT_WEIGHT.NORMAL}
          $color={COLOR_PALETTE.NEUTRAL_BASE}
          $noWrap
        >
          {children}
        </Body1>
        {subtitle && <Body2>{subtitle}</Body2>}
      </Content>
    </BaseItem>
  );
}

Item.mods = BaseItem.mods;

export default withStylesAndInnerRef(Item);
