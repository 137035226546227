import styled from 'styled-components';
import Anchor from 'design-system/components/Anchor';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import {
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import useTracking from '../../../hooks/useTracking';
import Section from './Section';
import { MediaLink } from '../../../partials/MediaLink';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  min-width: 290px;
  ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
  ${spacing.inset(SPACING_SIZE.M)}
  margin-bottom: ${spacing.value(SPACING_SIZE.L)};

  ${Anchor} {
    display: flex;
    flex-direction: row;
    align-items: center;
    ${spacing.stack(SPACING_SIZE.S)}
    > * {
      ${spacing.inline(SPACING_SIZE.S)}
    }
    p {
      margin: 0;
      ${typography.size(FONT_SIZE.L)}
      ${typography.weight(FONT_WEIGHT.NORMAL)}
      ${typography.lineHeight(20)}
    }
  }
`;

function hasNoMedia(project) {
  return (
    !project.video &&
    !project.virtualTour &&
    !project.floorPlan &&
    !project.brochure
  );
}

function Media({ project, ...props }) {
  const trackingRefBrochure = useTracking({
    trackClick: {
      eventName: 'click-brochure',
    },
    trackImpression: {
      elementName: 'brochure',
    },
    sectionName: 'project-detail-media',
  });

  const trackingRefVirtualTour = useTracking({
    trackClick: {
      eventName: 'click-virtual-tour',
    },
    trackImpression: {
      elementName: 'virtual-tour',
    },
    sectionName: 'project-detail-media',
  });

  const trackingRefVideo = useTracking({
    trackClick: {
      eventName: 'click-video',
    },
    trackImpression: {
      elementName: 'video',
    },
    sectionName: 'project-detail-media',
  });

  if (hasNoMedia(project)) {
    return null;
  }
  return (
    <Section
      title={<FormattedMessageWithValidation id="project_detail_media_title" />}
      {...props}
    >
      <Wrapper {...props}>
        <MediaLink
          href={project.brochure}
          glyph="pdf"
          ref={trackingRefBrochure}
        >
          <FormattedMessageWithValidation id="project_detail_media_brochure" />
        </MediaLink>
        <MediaLink
          href={project.virtualTour}
          glyph="threeSixty"
          ref={trackingRefVirtualTour}
        >
          <FormattedMessageWithValidation id="project_detail_virtual_tour" />
        </MediaLink>
        <MediaLink href={project.video} glyph="youtube" ref={trackingRefVideo}>
          <FormattedMessageWithValidation id="project_detail_media_video" />
        </MediaLink>
      </Wrapper>
    </Section>
  );
}

export default styled(Media)``;
