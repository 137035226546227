import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import {
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import MapChoicesInput from './MapChoicesInput';
import {
  csvFileErrorsSelector,
  csvFileStepsSelector,
  csvHeadersOptionsSelector,
  proppitFieldsSelector,
} from '../selectors';
import { HEADERS_SCHEMA, selectHeadersFieldGroupId } from '../constants';
import { CSV_CHANGE_HEADER_VALUES, CSV_FILE_ERRORS } from '../events';
import HeaderTable from './HeaderTable';
import { FieldGroup } from '../../../../pages/publication/partials/FieldGroup';

const ContentColumn = styled.div`
  display: flex;
  align-items: center;
  width: ${(props) =>
    props.percentageWidth ? `${props.percentageWidth}%` : '56px'};
`;
const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 58px;
  border-top: 1px solid #e6e6e6;
`;
const SeparatorContentDiv = styled.div`
  height: ${(props) => (props.height ? `${props.height}px` : '58px')};
  width: 56px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ContentDiv = styled.div`
  width: 100%;
  ${spacing.inset(SPACING_SIZE.M)}
`;
const WrapperDiv = styled.div``;
const BodyDiv = styled.div`
  ${typography.size(FONT_SIZE.S)}
`;

const SubTitleWrapper = styled.div`
  ${typography.font(FONT_FAMILY.PRIMARY)};
  ${typography.weight(FONT_WEIGHT.NORMAL)};
  ${typography.size(FONT_SIZE.M)};
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  line-height: normal;
  display: flex;
  flex-direction: column;
  ${spacing.stack(SPACING_SIZE.M, false)};
`;

function HeaderMapper() {
  const id = selectHeadersFieldGroupId;
  const globalErrors = useSelector(csvFileErrorsSelector);
  const valuesToMap = useSelector(proppitFieldsSelector);
  const availableValues = useSelector(csvHeadersOptionsSelector);
  const currentStep = useSelector(csvFileStepsSelector);

  const errors = globalErrors[id];

  if (currentStep !== 2) {
    return <></>;
  }

  if (!availableValues) {
    return <></>;
  }

  const handleSelectChange = (fieldName, value) => {
    dispatch({
      id: CSV_CHANGE_HEADER_VALUES,
      payload: {
        name: fieldName,
        value,
      },
    });
    if (errors.indexOf(fieldName) !== -1) {
      dispatch({
        id: CSV_FILE_ERRORS,
        payload: {
          ...globalErrors,
          [id]: errors.filter((error) => error !== fieldName),
        },
      });
    }
  };

  return (
    <FieldGroup
      id={id}
      title={<FormattedMessageWithValidation id="upload_csv_select_columns" />}
      order={2}
    >
      <SubTitleWrapper>
        <FormattedMessageWithValidation id="upload_csv_select_columns_subtitle" />
        <FormattedMessageWithValidation id="upload_csv_select_columns_subtitle2" />
      </SubTitleWrapper>
      <WrapperDiv>
        <HeaderTable schema={HEADERS_SCHEMA} />
        <BodyDiv>
          {valuesToMap.map(({ fieldName, fieldOptions }) => {
            if (fieldOptions.tag === null) {
              return null;
            }
            return (
              <ContentRow key={`row${fieldName}`}>
                <ContentColumn
                  key={`cell${fieldName}_text`}
                  percentageWidth="30"
                >
                  <ContentDiv>
                    <FormattedMessageWithValidation id={fieldOptions.tag} />
                    {fieldOptions.required !== false && '*'}
                  </ContentDiv>
                </ContentColumn>
                <ContentColumn key={`cell${fieldName}_separator`}>
                  <SeparatorContentDiv>
                    <Icon glyph="arrowLeft" $size={ICON_SIZE.BASE} />
                  </SeparatorContentDiv>
                </ContentColumn>
                <ContentColumn
                  key={`cell${fieldName}_map`}
                  percentageWidth="50"
                >
                  <ContentDiv>
                    <MapChoicesInput
                      key={fieldName}
                      values={availableValues}
                      valueName={fieldName}
                      required={fieldOptions.required}
                      onSelectChange={handleSelectChange}
                      hasError={errors && errors.indexOf(fieldName) !== -1}
                      isHeader
                    />
                  </ContentDiv>
                </ContentColumn>
                <ContentColumn
                  key={`cell${fieldName}_validation`}
                  percentageWidth="10"
                >
                  <ContentDiv>
                    <FormattedMessageWithValidation
                      id={`upload_csv_type_${fieldOptions.type}`}
                    />
                  </ContentDiv>
                </ContentColumn>
              </ContentRow>
            );
          })}
        </BodyDiv>
      </WrapperDiv>
    </FieldGroup>
  );
}

export default HeaderMapper;
