import { DropdownFilter } from '../../../partials/DataTable';

export default function PublishesInTPFilter({ source, onSelectOption }) {
  return (
    <DropdownFilter
      label="publish in TP"
      source={source}
      options={[
        { label: 'publish in TP', value: true },
        { label: 'not publish in TP', value: false },
      ]}
      onSelectOption={onSelectOption}
    />
  );
}
