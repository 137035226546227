import styled from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import BaseMenu from 'design-system/components/Menu/partials/BaseMenu';
import ProjectOptionSnippet from './ProjectOptionSnippet';

const Wrapper = styled(BaseMenu.BaseItem)`
  ${spacing.inset(SPACING_SIZE.M)};

  &:hover {
    ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
  }
  &:first-of-type:hover,
  &[aria-selected='true'] {
    ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
  }
`;

export default function SearchOption({ option, ...props }) {
  return (
    <Wrapper {...props}>
      <ProjectOptionSnippet {...option} />
    </Wrapper>
  );
}
