import styled from 'styled-components';
import {
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import Typography from 'design-system/components/Typography/Typography';

const Anchor = styled.a`
  cursor: pointer;
  line-height: 17px;
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  text-align: inherit;

  ${typography.font(FONT_FAMILY.PRIMARY)}

  ${color.text(COLOR_PALETTE.BLUEBERRY_BASE)}

  &:visited {
    ${color.text(COLOR_PALETTE.BLUEBERRY_BASE)}
  }

  &:active {
    ${color.text(COLOR_PALETTE.PRIMARY_BASE)}
  }

  ${({ $weight }) => typography.weight($weight)}
  ${({ $size }) => typography.size($size)}
  ${({ $ellipse }) => $ellipse && Typography.mods.ellipse()}
`;

Anchor.defaultProps = {
  $weight: FONT_WEIGHT.NORMAL,
  $size: FONT_SIZE.M,
};

export default styled(Anchor)``;
