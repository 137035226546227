import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import styled from 'styled-components';
import Table from 'design-system/components/Table';
import { useColumn } from '../../../../../partials/DataTable/hooks/useColumn';

const UnitCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

const UnitCell = styled.div`
  ${spacing.stack(SPACING_SIZE.XL)}
  min-width: 0;

  height: ${(props) => (props.empty ? '42px' : 'auto')};
`;

function UnitFieldsColumn({
  renderUnitField,
  renderAccumulatedField,
  ...props
}) {
  const source = {
    units: 'units',
    projects: 'projects',
    accumulatedUnitsOrProjects: 'accumulatedUnitsOrProjects',
  };
  const {
    value: { units = [], projects = [], accumulatedUnitsOrProjects },
  } = useColumn({ ...props, source });
  return (
    <Table.Cell maxWidth={225}>
      <UnitCellWrapper>
        {units.map((unit) => (
          <UnitCell key={`${unit.id}_${Object.keys(source).join('_')}`}>
            {renderUnitField(unit)}
          </UnitCell>
        ))}
        {accumulatedUnitsOrProjects && <UnitCell empty />}
        {units.length === 1 && projects.length === 1 && <UnitCell empty />}
      </UnitCellWrapper>
    </Table.Cell>
  );
}

export default UnitFieldsColumn;
