import {
  QUALIFICATION_PREFERRABLE_CHANNELS_EXTRA_DATA,
  QUALIFICATION_QUESTIONS,
  QUALIFICATIONS_WITH_MULTIPLE_ANSWERS,
} from './constants';

export function isLoadingQualificationSelector(state) {
  return state.qualification == null;
}

export function qualificationSelector(state) {
  if (isLoadingQualificationSelector(state)) {
    return {};
  }

  const { qualification } = state;
  return buildQuestions(qualification);
}

function buildQuestions(qualification) {
  const finalQuestions = {};
  QUALIFICATION_QUESTIONS.forEach(({ key, value }) => {
    const question = buildQuestion(key, value, qualification);
    if (question) {
      finalQuestions[key] = question;
    }
  });

  return finalQuestions;
}

function buildQuestion(questionKey, questionTag, qualification) {
  switch (questionKey) {
    case 'budget':
      return buildBudgetQuestion(questionKey, qualification, questionTag);
    case 'region':
      if (isNotValidValue(qualification.region) || !qualification.minRooms) {
        return null;
      }
      return {
        question: questionTag,
        answers: [
          {
            label: QUALIFICATIONS_WITH_MULTIPLE_ANSWERS[questionKey][0],
            value: {
              region: qualification.region,
              minRooms: qualification.minRooms,
              ...(qualification.maxRooms && {
                maxRooms: qualification.maxRooms,
              }),
            },
          },
        ],
      };
    case 'infoRequired':
      return buildInfoRequiredQuestion(questionKey, qualification, questionTag);
    case 'preferredChannel':
      return buildPreferredChannelQuestion(
        questionKey,
        qualification,
        questionTag
      );
    case 'funding':
      return buildFundingQuestion(qualification, questionTag);
    default:
      if (isNotValidValue(qualification[questionKey])) {
        return null;
      }
      return {
        question: questionTag,
        answers: Array.isArray(qualification[questionKey])
          ? qualification[questionKey]
          : [qualification[questionKey]],
      };
  }
}

function buildBudgetQuestion(key, qualification, questionTag) {
  const budgetQuestions = [];
  if (qualification.minBudget) {
    budgetQuestions.push({
      label: QUALIFICATIONS_WITH_MULTIPLE_ANSWERS[key][0],
      value: qualification.minBudget,
    });
  }
  if (qualification.maxBudget) {
    budgetQuestions.push({
      label: QUALIFICATIONS_WITH_MULTIPLE_ANSWERS[key][1],
      value: qualification.maxBudget,
    });
  }

  if (budgetQuestions.length === 0) {
    return null;
  }

  return {
    question: questionTag,
    answers: budgetQuestions,
  };
}

function buildInfoRequiredQuestion(key, qualification, questionTag) {
  const infoRequired = qualification[key];
  if (!infoRequired || infoRequired.length === 0) {
    return null;
  }

  const valuesTranslations = QUALIFICATIONS_WITH_MULTIPLE_ANSWERS[key];
  const infoRequiredQuestions = infoRequired.map((info) => ({
    label: valuesTranslations.find((value) => value.key === info).value,
    value: info,
  }));

  return {
    question: questionTag,
    answers: infoRequiredQuestions,
  };
}

function buildPreferredChannelQuestion(key, qualification, questionTag) {
  const preferredChannel = qualification[key];

  if (isNotValidValue(preferredChannel)) {
    return null;
  }

  const finalPreferredChannel = preferredChannel.map((channel) => {
    const channelData = QUALIFICATION_PREFERRABLE_CHANNELS_EXTRA_DATA[channel];
    return {
      label: channelData.label,
      icon: channelData.icon,
      value: channel,
    };
  });

  return {
    question: questionTag,
    answers: finalPreferredChannel,
  };
}

function buildFundingQuestion(qualification, questionTag) {
  const { funding } = qualification;
  if (isNotValidValue(funding)) {
    return null;
  }

  const valuesTranslations = QUALIFICATIONS_WITH_MULTIPLE_ANSWERS.funding;
  const infoRequiredQuestions = funding.map((fundingValues) => ({
    label: valuesTranslations.find((value) => value.key === fundingValues)
      .value,
    value: fundingValues,
  }));

  return {
    question: questionTag,
    answers: infoRequiredQuestions,
  };
}

function isNotValidValue(value) {
  return !value || (Array.isArray(value) && value.length === 0);
}
