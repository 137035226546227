import { useEffect, useRef, useState } from 'react';

export function useScript(src, options = {}) {
  const [status, setStatus] = useState(() => {
    if (!src) {
      return 'idle';
    }

    return 'loading';
  });

  const cachedScriptStatuses = useRef({});

  useEffect(() => {
    if (!src) {
      return;
    }

    const cachedScriptStatus = cachedScriptStatuses.current[src];
    if (cachedScriptStatus === 'ready' || cachedScriptStatus === 'error') {
      setStatus(cachedScriptStatus);
      return;
    }

    let script = document.querySelector(`script[src="${src}"]`);

    if (script) {
      setStatus(
        script.getAttribute('data-status') ?? cachedScriptStatus ?? 'loading'
      );
    } else {
      script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.setAttribute('data-status', 'loading');
      document.body.appendChild(script);

      const setAttributeFromEvent = (event) => {
        const scriptStatus = event.type === 'load' ? 'ready' : 'error';

        if (script) {
          script.setAttribute('data-status', scriptStatus);
        }
      };

      if (options.id) {
        script.setAttribute('id', options.id);
      }
      script.addEventListener('load', setAttributeFromEvent);
      script.addEventListener('error', setAttributeFromEvent);
    }

    const setStateFromEvent = (event) => {
      const newStatus = event.type === 'load' ? 'ready' : 'error';
      setStatus(newStatus);
      cachedScriptStatuses.current[src] = newStatus;
    };

    script.addEventListener('load', setStateFromEvent);
    script.addEventListener('error', setStateFromEvent);

    // eslint-disable-next-line consistent-return
    return () => {
      if (script) {
        script.removeEventListener('load', setStateFromEvent);
        script.removeEventListener('error', setStateFromEvent);
      }

      if (script && options.removeOnUnmount) {
        script.remove();
      }
    };
  }, [src, options.removeOnUnmount]);

  return status;
}
