import styled from 'styled-components';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const NoWrapText = styled(Body1).attrs({ $weight: FONT_WEIGHT.LIGHT })`
  white-space: nowrap;
  display: block;
`;

const translationsMap = {
  sell: (
    <FormattedMessageWithValidation id="properties_list_operation_type_sale" />
  ),
  rent: (
    <FormattedMessageWithValidation id="properties_list_operation_type_rent" />
  ),
};

function PropertyOperationType({ operations }) {
  return (
    <div>
      {operations.map(({ type }) => (
        <NoWrapText key={type}>{translationsMap[type]}</NoWrapText>
      ))}
    </div>
  );
}

export default PropertyOperationType;
