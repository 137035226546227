import { coeffect, registerCoeffectHandler } from 'reffects';

export default function registerAuthTokenCoeffect(
  cookiesClient,
  authTokenCookieName,
  jwtParser
) {
  function getAuthTokenFromCookies() {
    return authTokenCookieName
      ? cookiesClient.get(authTokenCookieName)
      : undefined;
  }

  registerCoeffectHandler('authToken', () => {
    const authTokenValue = getAuthTokenFromCookies();
    return {
      authToken: authTokenValue,
    };
  });

  registerCoeffectHandler('authTokenPayload', () => {
    const authTokenValue = getAuthTokenFromCookies();
    if (authTokenValue === undefined) {
      return { authTokenPayload: { user: { publisherId: null } } };
    }

    return {
      authTokenPayload: jwtParser.parse(authTokenValue),
    };
  });
}

export function authToken() {
  return coeffect('authToken');
}

export function authTokenPayload() {
  return coeffect('authTokenPayload');
}
