import { useIntl } from 'react-intl';
import FeatureTable from './FeatureTable/FeatureTable';
import Price from '../../../../../partials/Price/Price';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  furnishedTranslationTags,
  propertyTypesTranslationTags,
} from '../../../../../constants/ad';

function AreaFeature({ title, area }) {
  const intl = useIntl();
  const units = {
    sqm: 'm²',
    sqwa: intl.formatMessage({ id: 'ad_form_usable_area_unit_sqwa' }),
  };
  return (
    <FeatureTable.Cell title={title}>
      {area
        .map(({ value, unit }) => `${value} ${units[unit] ?? unit}`)
        .join(' ')}
    </FeatureTable.Cell>
  );
}

export default function Features({ property }) {
  const {
    bathrooms,
    toilets,
    bedrooms,
    floor,
    furnished,
    propertyType,
    usableArea,
    floorArea,
    plotArea,
    stratum,
    constructionYear,
    communityFees,
  } = property;

  return (
    <FeatureTable>
      <FeatureTable.Cell
        title={<FormattedMessageWithValidation id="newprop_type_emptystate" />}
      >
        <FormattedMessageWithValidation
          id={propertyTypesTranslationTags.get(propertyType)}
        />
      </FeatureTable.Cell>
      {furnished && (
        <FeatureTable.Cell
          title={
            <FormattedMessageWithValidation id="newprop_furnished_title" />
          }
        >
          <FormattedMessageWithValidation
            id={furnishedTranslationTags.get(furnished)}
          />
        </FeatureTable.Cell>
      )}
      {floor != null && (
        <FeatureTable.Cell
          title={
            <FormattedMessageWithValidation id="propspage_table_property_floor" />
          }
        >
          {floor}
        </FeatureTable.Cell>
      )}
      {bedrooms != null && (
        <FeatureTable.Cell
          title={
            <FormattedMessageWithValidation id="newprop_rooms_emptystate" />
          }
        >
          {bedrooms}
        </FeatureTable.Cell>
      )}
      {bathrooms != null && (
        <FeatureTable.Cell
          title={
            <FormattedMessageWithValidation id="newprop_bathrooms_emptystate" />
          }
        >
          {bathrooms}
        </FeatureTable.Cell>
      )}
      {toilets != null && (
        <FeatureTable.Cell
          title={
            <FormattedMessageWithValidation id="newprop_toilets_emptystate" />
          }
        >
          {toilets}
        </FeatureTable.Cell>
      )}
      {usableArea && (
        <AreaFeature
          title={
            <FormattedMessageWithValidation id="newprop_area_usable_emptystate" />
          }
          area={usableArea}
        />
      )}
      {floorArea && (
        <AreaFeature
          title={
            <FormattedMessageWithValidation id="newprop_area_total_emptystate" />
          }
          area={floorArea}
        />
      )}
      {plotArea && (
        <AreaFeature
          title={<FormattedMessageWithValidation id="land_area" />}
          area={plotArea}
        />
      )}
      {stratum != null && (
        <FeatureTable.Cell
          title={<FormattedMessageWithValidation id="newprop_estrato_header" />}
        >
          {stratum}
        </FeatureTable.Cell>
      )}
      {constructionYear != null && (
        <FeatureTable.Cell
          title={
            <FormattedMessageWithValidation id="propspage_table_construction_year" />
          }
        >
          {constructionYear}
        </FeatureTable.Cell>
      )}
      {communityFees && (
        <FeatureTable.Cell
          title={
            <FormattedMessageWithValidation id="propspage_table_community_fees" />
          }
        >
          <Price amount={communityFees.value} currency={communityFees.unit} />
        </FeatureTable.Cell>
      )}
    </FeatureTable>
  );
}
