import { createCachedSelector } from 're-reselect';

export const getProjectUnitsByIdSelector = ({ projectUnitsById }) =>
  projectUnitsById;

const cacheKeySelector = (_, unitId) => unitId;

export const getProjectUnitSelector = createCachedSelector(
  getProjectUnitsByIdSelector,
  (state, unitId) => unitId,
  (projectUnitsById, unitId) => projectUnitsById[unitId]
)(cacheKeySelector);
