import styled from 'styled-components';
import { divider, DIVIDER_SIZE } from 'design-system/styles/divider';

const Divider = styled.hr`
  width: auto;
  margin: 0;
  border: none;
  ${divider.bottom(DIVIDER_SIZE.THIN)}
`;

export default Divider;
