import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import Dialog from 'design-system/components/Dialog/Dialog';
import {
  FlatButton,
  SecondaryButton,
} from 'design-system/components/Button/presets';
import { useDialog } from '../../../../hooks/useDialog';
import { AD_FEEDBACK_MODAL } from '../constants';
import FormattedMessageWithValidation from '../../../FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  otherTextDeleteOrUnpublishReasonSelector,
  selectedDeleteOrUnpublishReasonSelector,
} from '../partials/DeleteOrUnpublishReason/selectors';
import DeleteOrUnpublishReason from '../partials/DeleteOrUnpublishReason';
import { TRACK_CLICK } from '../../../Tracking/events';

export default function AdFeedbackModal() {
  const { open, closeDialog, parameters } = useDialog(AD_FEEDBACK_MODAL);
  const reason = useSelector(selectedDeleteOrUnpublishReasonSelector);
  const reasonText = useSelector(otherTextDeleteOrUnpublishReasonSelector);
  return (
    <Dialog open={open}>
      <Dialog.Header onClose={closeDialog}>
        <FormattedMessageWithValidation id={parameters?.titleText} />
      </Dialog.Header>
      <Dialog.Content>
        {open && (
          <>
            <Dialog.Text>
              <FormattedMessageWithValidation id="modal_ad_feedback_body" />
            </Dialog.Text>
            <DeleteOrUnpublishReason
              reason={reason}
              reasonText={reasonText}
              sectionName="ad_feedback_modal"
              impression={parameters.impression}
            />
          </>
        )}
      </Dialog.Content>
      <Dialog.Footer>
        <FlatButton onClick={closeDialog}>
          <FormattedMessageWithValidation id="modal_button_close" />
        </FlatButton>
        <SecondaryButton
          onClick={() => {
            if (reason) {
              dispatch({
                id: TRACK_CLICK,
                payload: {
                  eventName: parameters.eventName,
                  sectionName: 'ad_feedback_modal',
                  payload: { reason, reasonText },
                },
              });
            }
            closeDialog();
          }}
        >
          <FormattedMessageWithValidation id="leadpage_interests_poll_question_save_button" />
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}
