import { useSelector } from 'reffects-store';
import { FormattedList } from 'react-intl';
import { Link } from 'react-router-dom';
import { PrimaryButton } from 'design-system/components/Button/presets';
import Illustration from 'design-system/components/Illustration/Illustration';
import EmptyState from 'design-system/components/EmptyState';
import { siteWherePublishingNamesSelector } from '../../selectors/config';
import useTracking from '../../hooks/useTracking';
import FormattedMessageWithTranslatedCountry from '../../partials/FormattedMessageWithTranslatedCountry/FormattedMessageWithCountry';
import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { newPropertyForm } from '../../utils/proppitWebRouter';

function EmptyPropertiesState() {
  const webs = useSelector(siteWherePublishingNamesSelector);
  const createPropertyTrackingRef = useTracking({
    trackClick: {
      eventName: 'create-new-listing',
    },
    sectionName: 'listings-no-listings-message-bottom-new-listing-button',
  });

  return (
    <EmptyState
      fullScreen
      illustration={<Illustration name="buildings" />}
      title={
        <FormattedMessageWithTranslatedCountry id="overview_properties_empty_title" />
      }
      description={
        <FormattedMessageWithValidation
          id="overview_properties_empty_body"
          values={{
            webs: <FormattedList type="conjunction" value={webs} />,
          }}
        />
      }
      action={
        <PrimaryButton
          forwardedAs={Link}
          to={newPropertyForm()}
          ref={createPropertyTrackingRef}
        >
          <FormattedMessageWithValidation id="properties_empty_cta" />
        </PrimaryButton>
      }
    />
  );
}

export default EmptyPropertiesState;
