import { coeffect, registerCoeffectHandler } from 'reffects';

const COEFFECT_ID = 'historyLength';

export default function registerHistoryCoeffect(globalWindow = window) {
  registerCoeffectHandler(COEFFECT_ID, () => globalWindow.history.length);
}

export const history = {
  length() {
    return coeffect(COEFFECT_ID);
  },
};
