/* eslint-disable no-use-before-define */

import styled, { css } from 'styled-components';

import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Thumbnail from 'design-system/components/Thumbnail';
import { OutlineButton } from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';

const Wrapper = styled.div`
  position: relative;
  display: inline-block;

  ${Thumbnail} {
    ${Thumbnail.mods.ratio(3)}
  }

  :hover {
    ${() => modHover()}
  }
`;

const ButtonBox = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 0;

  ${spacing.inset(SPACING_SIZE.S)}
`;

const modHover = () => css`
  ${ButtonBox} {
    display: initial;
  }
`;

function PublisherLogoThumbnail({
  className,
  style,
  onDeleteButtonClick,
  ...rest
}) {
  const { source } = rest;

  return (
    <Wrapper className={className} style={style}>
      <Thumbnail {...rest} />
      {source && (
        <ButtonBox>
          <OutlineButton
            icon={<Icon glyph="bin" />}
            onClick={onDeleteButtonClick}
          />
        </ButtonBox>
      )}
    </Wrapper>
  );
}

export default styled(PublisherLogoThumbnail)``;
