import { dispatch } from 'reffects';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Grid from 'design-system/components/Grid';
import Layout from 'design-system/components/Layout';
import { AD_SUBMITTED } from './events';
import Sidebar from '../partials/Sidebar';
import LocationGroup from '../partials/Location/LocationGroup';
import { useValidationSchema } from '../validationSchema';
import { useDefaultValues } from '../useDefaultValues';
import { FieldGroupProvider } from '../partials/FieldGroup';
import MediaGroup from '../partials/MediaGroup';
import PropertyOperationGroup from './partials/PropertyOperationGroup';
import PropertyFacilitiesGroup from '../partials/PropertyFacilitiesGroup';
import ContactDetailsGroup from '../partials/ContactDetailsGroup';
import PropertyDescriptionGroup from '../partials/PropertyDescriptionGroup';
import { PropertyDetailsGroup } from './partials/PropertyDetailsGroup';
import { Navigation } from '../../../partials';
import HiddenField from '../../../partials/fields/HiddenField';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import PublishBanner from '../partials/PublishBanner';
import { useInViewportWatcher } from '../../../hooks/useViewportWatcher';
import AnimationFadeSlideOut from '../../../partials/AnimationFadeSlideOut';
import ModalUploadCSV from '../../../Backoffice/pages/UploadCSV/partials/ModalUploadCSV';

function AdForm({ values }) {
  const {
    isInViewport: isLargePublishBannerInViewport,
    watcherRef: largePublishBannerRef,
  } = useInViewportWatcher();
  const schema = useValidationSchema();
  const defaultValues = useDefaultValues(values);

  const methods = useForm({
    defaultValues,
    shouldUnregister: true,
    criteriaMode: 'all',
    resolver: yupResolver(schema),
  });
  const isNewProp = !values;

  const { handleSubmit } = methods;

  const handlePublishSubmit = handleSubmit((data) => {
    dispatch({ id: AD_SUBMITTED, payload: { ...data, published: true } });
  });
  const handleDraftSubmit = handleSubmit((data) => {
    dispatch({ id: AD_SUBMITTED, payload: { ...data, published: false } });
  });

  return (
    <>
      <FormProvider {...methods}>
        <FieldGroupProvider>
          <Layout independentScroll>
            <Layout.Header>
              <Navigation />
            </Layout.Header>
            <Layout.LeftSidebar>
              <Sidebar
                title={
                  isNewProp ? (
                    <FormattedMessageWithValidation id="newprop_header" />
                  ) : (
                    <FormattedMessageWithValidation id="edit_property_header" />
                  )
                }
                description={
                  <FormattedMessageWithValidation id="ad_form_sidebar_inspirational_description" />
                }
              >
                <AnimationFadeSlideOut $in={!isLargePublishBannerInViewport}>
                  <PublishBanner
                    networkBrandsMessage={
                      <FormattedMessageWithValidation id="newprop_networkbrands" />
                    }
                    onDraftSubmit={handleDraftSubmit}
                    onPublishSubmit={handlePublishSubmit}
                  />
                </AnimationFadeSlideOut>
              </Sidebar>
            </Layout.LeftSidebar>
            <Layout.Content>
              <Layout.Padded>
                <Grid
                  areas={({ repeat }) =>
                    `"${repeat(6, 'form')} ${repeat(3, '.')}"`
                  }
                >
                  <Grid.Area $id="form">
                    <form onSubmit={handlePublishSubmit}>
                      <input hidden type="submit" />
                      <HiddenField name="id" />
                      <HiddenField name="creationDate" />
                      <PropertyOperationGroup order={1} />
                      <LocationGroup order={2} />
                      <PropertyDetailsGroup order={3} />
                      <MediaGroup order={4} />
                      <PropertyFacilitiesGroup order={5} />
                      <PropertyDescriptionGroup
                        order={6}
                        fieldGroupTitle={
                          <FormattedMessageWithValidation id="ad_form_group_description" />
                        }
                        sectionTitle={
                          <FormattedMessageWithValidation id="ad_form_section_property_identification" />
                        }
                      />
                      <ContactDetailsGroup order={7} />
                    </form>
                  </Grid.Area>
                </Grid>
              </Layout.Padded>
              <Layout.Padded ref={largePublishBannerRef} $contrast>
                <PublishBanner
                  networkBrandsMessage={
                    <FormattedMessageWithValidation id="newprop_networkbrands" />
                  }
                  onDraftSubmit={handleDraftSubmit}
                  onPublishSubmit={handlePublishSubmit}
                  $layout="inline"
                />
              </Layout.Padded>
            </Layout.Content>
          </Layout>
        </FieldGroupProvider>
      </FormProvider>
      {isNewProp && <ModalUploadCSV />}
    </>
  );
}

export default AdForm;
