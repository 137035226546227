import { SecondaryButton } from 'design-system/components/Button/presets';
import Dialog from 'design-system/components/Dialog/Dialog';
import FormattedMessageWithValidation from '../../../FormattedMessageWithValidation/FormattedMessageWithValidation';
import { PUBLISHED_BUT_BOOSTING_FORBIDDEN_MODAL } from '../events';
import boostingLimitReachedTutorialImage from './boostingLimitReachedTutorial2.jpg';
import { useDialog } from '../../../../hooks/useDialog';

export function PublishedButBoostingForbiddenModal({ supportEmail }) {
  const { open, closeDialog } = useDialog(
    PUBLISHED_BUT_BOOSTING_FORBIDDEN_MODAL
  );
  return (
    <Dialog open={open}>
      <Dialog.Header>
        <FormattedMessageWithValidation id="freelisting_free_modal_published_no_boost_title" />
      </Dialog.Header>
      <Dialog.Emphasis>
        <FormattedMessageWithValidation id="freelisting_free_modal_published_no_boost_message" />
      </Dialog.Emphasis>
      <Dialog.Content>
        <Dialog.Text>
          <FormattedMessageWithValidation
            id="freelisting_free_modal_published_no_boost_text"
            values={{
              supportEmail: (
                <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
              ),
            }}
          />
        </Dialog.Text>
        <img
          src={boostingLimitReachedTutorialImage}
          alt="Boosting limit reached tutorial"
        />
      </Dialog.Content>
      <Dialog.Footer>
        <SecondaryButton onClick={closeDialog}>
          <FormattedMessageWithValidation id="freelistings_button_continue" />
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}
