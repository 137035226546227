import styled, { css } from 'styled-components';
import { FONT_FAMILY, FONT_WEIGHT, typography } from '../../styles/typography';
import { color } from '../../styles/color';

export const TYPOGRAPHY_VARIANT = {
  HEADING_1: Symbol('TYPOGRAPHY_VARIANT_HEADING_1'),
  HEADING_2: Symbol('TYPOGRAPHY_VARIANT_HEADING_2'),
  SUBTITLE_1: Symbol('TYPOGRAPHY_VARIANT_SUBTITLE_1'),
  SUBTITLE_2: Symbol('TYPOGRAPHY_VARIANT_SUBTITLE_2'),
  SUBTITLE_3: Symbol('TYPOGRAPHY_VARIANT_SUBTITLE_3'),
  BODY_1: Symbol('TYPOGRAPHY_VARIANT_BODY_1'),
  BODY_2: Symbol('TYPOGRAPHY_VARIANT_BODY_2'),
  OVERLINE: Symbol('TYPOGRAPHY_VARIANT_OVERLINE'),
};

const Typography = styled.span`
  margin: 0;
  text-decoration: none;
  ${typography.font(FONT_FAMILY.PRIMARY)};
`;

const modHeading1Variant = () => css`
  font-size: 22px;
  line-height: 36px;
  ${typography.weight(FONT_WEIGHT.SEMIBOLD)}
`;

const modHeading2Variant = () => css`
  font-size: 18px;
  line-height: 22px;
  ${typography.weight(FONT_WEIGHT.SEMIBOLD)}
`;

const modSubtitle1Variant = () => css`
  font-size: 18px;
  line-height: 22px;
  ${typography.weight(FONT_WEIGHT.NORMAL)}
`;

const modSubtitle2Variant = () => css`
  font-size: 14px;
  line-height: 17px;
  ${typography.weight(FONT_WEIGHT.MEDIUM)}
`;

const modSubtitle3Variant = () => css`
  font-size: 12px;
  line-height: 15px;
  ${typography.weight(FONT_WEIGHT.MEDIUM)}
`;

const modBody1Variant = () => css`
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.15px;
`;

const modBody2Variant = () => css`
  font-size: 12px;
  line-height: 14px;
  ${typography.weight(FONT_WEIGHT.LIGHT)}
`;

const modOverlineVariant = () => css`
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  opacity: 0.7;
  ${typography.weight(FONT_WEIGHT.MEDIUM)}
`;

const modVariant = (variantName) =>
  ({
    [TYPOGRAPHY_VARIANT.HEADING_1]: modHeading1Variant,
    [TYPOGRAPHY_VARIANT.HEADING_2]: modHeading2Variant,
    [TYPOGRAPHY_VARIANT.SUBTITLE_1]: modSubtitle1Variant,
    [TYPOGRAPHY_VARIANT.SUBTITLE_2]: modSubtitle2Variant,
    [TYPOGRAPHY_VARIANT.SUBTITLE_3]: modSubtitle3Variant,
    [TYPOGRAPHY_VARIANT.BODY_1]: modBody1Variant,
    [TYPOGRAPHY_VARIANT.BODY_2]: modBody2Variant,
    [TYPOGRAPHY_VARIANT.OVERLINE]: modOverlineVariant,
  }[variantName]);

Typography.mods = {
  variant: modVariant,
  weight: typography.weight,
  size: typography.size,
  font: typography.font,
  color: color.text,
  ellipse: typography.ellipsis,
  lineHeight: typography.lineHeight,
  noWrap: typography.noWrap,
  wrap: typography.wrap,
};

export default Typography;
