import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { dispatch, dispatchMany } from 'reffects';
import { useMount } from 'react-use';
import InputText from 'design-system/components/InputText';
import { OutlineButton } from 'design-system/components/Button/presets';
import Button from 'design-system/components/Button';
import { publisherFormInfoSelector } from '../selectors';
import { projectNameSuggestionLoadingSelector } from '../../partials/ProjectNameAutocomplete/selectors';
import ProjectNameAutocomplete from '../../partials/ProjectNameAutocomplete/ProjectNameAutocomplete';
import {
  DEVELOPER_PROJECT_REMOVED,
  DEVELOPER_PROJECT_SELECTED,
} from './events';
import {
  developerCreationSubmitEnabledSelector,
  developerProjectsSelector,
  projectSuggestionsNotAssignedSelector,
} from './selectors';
import { CREATE_DEVELOPER_SUBMITTED } from '../events';
import { selectedProjectSelector } from '../../Developers/AssignProject/selectors';
import {
  ADMIN_PROJECT_NAME_AUTOCOMPLETE_CLEARED,
  PROJECT_NAME_AUTOCOMPLETE_BLURRED,
} from '../../partials/ProjectNameAutocomplete/events';

const Wrapper = styled.div`
  margin: 1rem auto;
  width: 75vw;
`;

const ProjectRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  > * {
    ${spacing.inline(SPACING_SIZE.M)}
  }
`;

function SelectedProjectRow({ project }) {
  return (
    <ProjectRowWrapper>
      <InputText label="Project name" value={project.name} disabled />
      <OutlineButton
        onClick={() =>
          dispatch({ id: DEVELOPER_PROJECT_REMOVED, payload: project.id })
        }
      >
        Remove project
      </OutlineButton>
    </ProjectRowWrapper>
  );
}

function ProjectRow({ countryCode }) {
  const loading = useSelector(projectNameSuggestionLoadingSelector);
  const projectSuggestions = useSelector(projectSuggestionsNotAssignedSelector);
  const selectedProject = useSelector(selectedProjectSelector);

  return (
    <ProjectRowWrapper>
      <ProjectNameAutocomplete
        isLoading={loading}
        projectSuggestions={projectSuggestions}
        value={{
          id: selectedProject?.id ?? '',
          name: selectedProject?.name ?? '',
        }}
        onSelectProject={(project) =>
          dispatchMany([
            {
              id: DEVELOPER_PROJECT_SELECTED,
              payload: { project },
            },
            PROJECT_NAME_AUTOCOMPLETE_BLURRED,
            ADMIN_PROJECT_NAME_AUTOCOMPLETE_CLEARED,
          ])
        }
        publisherCountry={countryCode}
      />
    </ProjectRowWrapper>
  );
}

export default function AssignProjects() {
  useMount(() => dispatch(ADMIN_PROJECT_NAME_AUTOCOMPLETE_CLEARED));
  const publisher = useSelector(publisherFormInfoSelector);
  const projects = useSelector(developerProjectsSelector);
  const enableSubmit = useSelector(developerCreationSubmitEnabledSelector);

  return (
    <Wrapper>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          dispatch(CREATE_DEVELOPER_SUBMITTED);
        }}
      >
        <h2>{`Create Publisher 2/2 Assign project to "${publisher.name}"`}</h2>
        {projects.map((project) => (
          <SelectedProjectRow key={project.name} project={project} />
        ))}
        <ProjectRow countryCode={publisher.country} />
        <Button disabled={!enableSubmit} type="submit">
          Finish
        </Button>
      </form>
    </Wrapper>
  );
}
