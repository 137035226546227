import { SPACING_SIZE } from 'design-system/styles/spacing';
import styled from 'styled-components';
import { useCallback } from 'react';
import { dispatch } from 'reffects';
import Table from 'design-system/components/Table';
import Anchor from 'design-system/components/Anchor';
import Tag, { TAG_VARIANT } from 'design-system/components/Tag';
import Typography from 'design-system/components/Typography/Typography';
import {
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { useColumn } from '../../../../../../partials/DataTable/hooks/useColumn';
import { AGENCY_STATUS } from '../../../constants';
import EmptyCellContent from '../../../../../../partials/DataTable/components/EmptyCellContent';
import { showSubscriptionChangeRequestStatus } from './showSubscriptionChangeRequestStatus';
import { SUBSCRIPTION_CHANGE_REQUEST_DATA_REQUESTED } from '../../../events';
import { getSubscriptionChangeRequestLinkText } from './getSubscriptionChangeRequestLinkText';

const Wrapper = styled(Table.Cell)`
  > div {
    flex-wrap: wrap;
    gap: ${SPACING_SIZE.XS}px;
  }
`;

const AutorenewalCancellationNotice = styled(Typography)`
  ${typography.font(FONT_FAMILY.PRIMARY)}
  ${typography.weight(FONT_WEIGHT.NORMAL)}
  ${typography.size(FONT_SIZE.M)}
`;

export function StatusColumn(props) {
  const {
    value: {
      status,
      hasActiveFirstPaymentCheckout,
      subscriptionChangeRequests,
      isSelfServiced,
      renewalCancelRequestStatus,
    },
  } = useColumn(props);

  const handleClickFactory = (SCRId) =>
    useCallback(
      (event) => {
        event.preventDefault();
        dispatch({
          id: SUBSCRIPTION_CHANGE_REQUEST_DATA_REQUESTED,
          payload: SCRId,
        });
      },
      [SCRId]
    );

  const scrs = subscriptionChangeRequests.map((scr) =>
    showSubscriptionChangeRequestStatus(scr.status, scr.billingStatus) ? (
      <Anchor
        onClick={handleClickFactory(scr.id)}
        href="#"
        target="_blank"
        rel="noopener noreferrer"
        key={scr.id}
      >
        {getSubscriptionChangeRequestLinkText({
          hasActiveFirstPaymentCheckout,
          status: scr.status,
          billingStatus: scr.billingStatus,
          isSelfServiced,
          shouldDisplayAutoRenewal: scr.shouldDisplayAutoRenewal,
        })}
      </Anchor>
    ) : null
  );
  return (
    <Wrapper>
      {renderStatusTag(status)}
      {scrs.every((s) => s == null) &&
        renewalCancelRequestStatus === 'accepted' && (
          <AutorenewalCancellationNotice>
            Autorenewal cancelled
          </AutorenewalCancellationNotice>
        )}
      {scrs}
    </Wrapper>
  );
}

function renderStatusTag(status) {
  const tag = {
    [AGENCY_STATUS.FREE]: <Tag variant={TAG_VARIANT.DEFAULT}>Free</Tag>,
    [AGENCY_STATUS.ACTIVE]: <Tag variant={TAG_VARIANT.PRIMARY}>Active</Tag>,
    [AGENCY_STATUS.ABOUT_TO_EXPIRE]: (
      <Tag variant={TAG_VARIANT.COMPLEMENTARY_SKYBLUE}>About to expire</Tag>
    ),
    [AGENCY_STATUS.GRACE_PERIOD]: (
      <Tag variant={TAG_VARIANT.COMPLEMENTARY_STRAWBERRY}>Grace period</Tag>
    ),
    [AGENCY_STATUS.FREE_SINCE_7_DAYS]: (
      <Tag variant={TAG_VARIANT.DEFAULT}>Free - 7 days</Tag>
    ),
    [AGENCY_STATUS.FREE_SINCE_15_DAYS]: (
      <Tag variant={TAG_VARIANT.DEFAULT}>Free - 15 days</Tag>
    ),
    [AGENCY_STATUS.FREE_SINCE_30_DAYS]: (
      <Tag variant={TAG_VARIANT.DEFAULT}>Free - 30 days</Tag>
    ),
    [AGENCY_STATUS.FREE_SINCE_60_DAYS]: (
      <Tag variant={TAG_VARIANT.DEFAULT}>Free - &gt;30 days</Tag>
    ),
    [AGENCY_STATUS.FREE_SINCE_MORE_THAN_60_DAYS]: (
      <Tag variant={TAG_VARIANT.DEFAULT}>Free - &gt;60 days</Tag>
    ),
  }[status];

  if (tag == null) {
    return (
      <Table.Cell>
        <EmptyCellContent />
      </Table.Cell>
    );
  }

  return tag;
}
