import { Body1 } from 'design-system/components/Typography/presets/Body1';
import Table from 'design-system/components/Table';
import { useColumn } from '../../../../partials/DataTable/hooks/useColumn';
import Price from '../../../../partials/Price/Price';

export function RenewalPriceColumn(props) {
  const {
    value: { amount, currency },
  } = useColumn(props);

  return (
    <Table.Cell>
      <Body1 $noWrap>
        <Price amount={amount} currency={currency} />
      </Body1>
    </Table.Cell>
  );
}
