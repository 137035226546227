import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { environment } from '../../../../coeffects/environment';
import { analytics } from '../../../../effects/analytics';

export const INVALID_PROPERTIES_ERRORS_REQUESTED =
  'INVALID_PROPERTIES_ERRORS_REQUESTED';
export const INVALID_PROPERTIES_ERRORS_LOADED =
  'INVALID_PROPERTIES_ERRORS_LOADED';
export const TRACK_ERROR_OPTION_SELECTED = 'TRACK_ERROR_OPTION_SELECTED';

registerEventHandler(
  INVALID_PROPERTIES_ERRORS_REQUESTED,
  ({ environment: { apiUrl } }) =>
    http.get({
      url: `${apiUrl}/publishers/me/invalid-properties/errors`,
      successEvent: INVALID_PROPERTIES_ERRORS_LOADED,
    }),
  [environment()]
);

registerEventHandler(INVALID_PROPERTIES_ERRORS_LOADED, (_, [{ data }]) =>
  state.set({ 'invalidPropertiesTable:availableErrors': data })
);

registerEventHandler(TRACK_ERROR_OPTION_SELECTED, (_, { id }) =>
  analytics.trackClick('error-type-option-selected', 'import-stats-table', {
    option: id,
  })
);
