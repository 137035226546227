import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import styled from 'styled-components';
import Dialog from 'design-system/components/Dialog/Dialog';
import { EMPHASIS_VARIANT } from 'design-system/components/Dialog/Emphasis';
import InputSelect from 'design-system/components/InputSelect';
import { SecondaryButton } from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import {
  availableLocalesSelector,
  canCurrentUserAssignBackofficeRoleSelector,
  canSubmitCreateUserFormSelector,
  countryOptionsForUserCreationSelector,
  userBeingCreatedSelector,
  userFormErrorSelector,
} from './selectors';
import {
  CREATE_USER_MODAL_COUNTRY_SELECTED,
  CREATE_USER_MODAL_FIELD_CHANGED,
  CREATE_USER_MODAL_REQUEST_CREATE_USER,
  USER_MODAL_FORM_SUBMITTED,
} from './events';
import EmailInput from '../partials/EmailInput';
import { useDialog } from '../../../hooks/useDialog';
import { CREATE_USER_DIALOG } from './constants';
import { userIsSuperadminSelector } from '../../../selectors/user';
import { BACKOFFICE_ROLES, CUSTOMER_ROLES } from '../../../constants/user';
import PublisherAutocomplete from '../partials/PublisherAutocomplete/PublisherAutocomplete';
import { publishersSelector } from '../selectors';

const Form = styled.form`
  display: grid;
  grid-template: 1fr 1fr / 1fr 1fr;
  grid-gap: 1rem;
  margin-bottom: 22px;

  .RoleSelector {
    grid-column: span 2;
  }
`;

export default function CreateUserModal({ onSuccessEvent }) {
  const { open, closeDialog } = useDialog(CREATE_USER_DIALOG);
  const countries = useSelector(countryOptionsForUserCreationSelector);
  const locales = useSelector((state) =>
    availableLocalesSelector(state, userBeingCreatedSelector)
  );
  const publishers = useSelector(publishersSelector);
  const user = useSelector(userBeingCreatedSelector);
  const canCurrentUserAssignBackofficeRole = useSelector(
    canCurrentUserAssignBackofficeRoleSelector
  );
  const canCurrentUserAssignSuperadminRole = useSelector(
    userIsSuperadminSelector
  );
  const canSubmitForm = useSelector(canSubmitCreateUserFormSelector);
  const error = useSelector(userFormErrorSelector);
  const onUserFieldChanged = (field) => (e) =>
    dispatch({
      id: CREATE_USER_MODAL_FIELD_CHANGED,
      payload: { field, value: e.target.value },
    });

  return (
    <Dialog open={open}>
      <Dialog.Header onClose={closeDialog}>Create user</Dialog.Header>
      {error && (
        <Dialog.Emphasis variant={EMPHASIS_VARIANT.ALERT}>
          {error}
        </Dialog.Emphasis>
      )}
      <Dialog.Content>
        <Form
          id="create-user"
          onSubmit={(e) => {
            e.preventDefault();
            dispatch({
              id: USER_MODAL_FORM_SUBMITTED,
              payload: {
                user,
                submitEventId: CREATE_USER_MODAL_REQUEST_CREATE_USER,
                onSuccessEvent,
              },
            });
          }}
        >
          <InputSelect
            name="country"
            label="Country"
            required
            value={user.country}
            choices={countries.map(({ code, name }) => ({
              label: name,
              value: code,
            }))}
            onSelect={({ value }) =>
              dispatch({
                id: CREATE_USER_MODAL_COUNTRY_SELECTED,
                payload: {
                  country: value,
                },
              })
            }
          />
          <PublisherAutocomplete
            publishers={publishers}
            countryCode={user.country}
            onSelect={({ id }) => {
              dispatch({
                id: CREATE_USER_MODAL_FIELD_CHANGED,
                payload: { field: 'publisherId', value: id },
              });
            }}
            onClear={() =>
              dispatch({
                id: CREATE_USER_MODAL_FIELD_CHANGED,
                payload: { field: 'publisherId', value: undefined },
              })
            }
          />
          <InputSelect
            name="language"
            label="Language"
            disabled={locales.length <= 1}
            required
            value={user.locale}
            onSelect={({ value }) =>
              dispatch({
                id: CREATE_USER_MODAL_FIELD_CHANGED,
                payload: { field: 'locale', value },
              })
            }
            choices={(locales ?? []).map((l) => ({ label: l, value: l }))}
          />
          <EmailInput
            name="email"
            label="Email"
            required
            onChange={onUserFieldChanged('email')}
          />
          <InputSelect
            name="role"
            label="Role"
            required
            value={user.role}
            onSelect={({ value }) =>
              dispatch({
                id: CREATE_USER_MODAL_FIELD_CHANGED,
                payload: { field: 'role', value },
              })
            }
            choices={[
              { label: 'Client admin', value: CUSTOMER_ROLES.CLIENT },
              {
                label: 'Client child',
                value: CUSTOMER_ROLES.PUBLISHER_COLLABORATOR,
              },
              canCurrentUserAssignBackofficeRole
                ? { label: 'Support', value: BACKOFFICE_ROLES.SUPPORT }
                : null,
              canCurrentUserAssignBackofficeRole
                ? { label: 'Finance', value: BACKOFFICE_ROLES.FINANCE }
                : null,
              canCurrentUserAssignBackofficeRole
                ? {
                    label: 'Finance Manager',
                    value: BACKOFFICE_ROLES.FINANCE_MANAGER,
                  }
                : null,
              canCurrentUserAssignBackofficeRole
                ? { label: 'Sales', value: BACKOFFICE_ROLES.SALES }
                : null,
              canCurrentUserAssignBackofficeRole
                ? {
                    label: 'Sales Manager',
                    value: BACKOFFICE_ROLES.SALES_MANAGER,
                  }
                : null,
              canCurrentUserAssignBackofficeRole &&
              canCurrentUserAssignSuperadminRole
                ? { label: 'Super admin', value: BACKOFFICE_ROLES.SUPER_ADMIN }
                : null,
            ].filter(Boolean)}
            className="RoleSelector"
          />
        </Form>
      </Dialog.Content>
      <Dialog.Footer>
        <SecondaryButton
          disabled={!canSubmitForm}
          type="submit"
          form="create-user"
          $size={BUTTON_SIZE.SMALL}
        >
          Create user
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}
