import { dispatch } from 'reffects';
import Tooltip from 'design-system/components/Tooltip';
import { FlatButton } from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';
import { IMPERSONATE_PUBLISHER_REQUESTED } from './events';

function ImpersonateButton({ publisherId }) {
  return (
    <Tooltip title="View account">
      <FlatButton
        icon={<Icon glyph="incognito" />}
        onClick={() =>
          dispatch({
            id: IMPERSONATE_PUBLISHER_REQUESTED,
            payload: publisherId,
          })
        }
      />
    </Tooltip>
  );
}

export default ImpersonateButton;
