import { ASSIGN_DEAL_MODAL_STATE_REGION } from './constants';
import {
  displayableDealSelector,
  selectedDealSelector,
} from '../../../../../partials/DealsAutocomplete/selectors';

export function displayableDealOnEditSelector(state) {
  const selectedDeal = selectedDealSelector(
    state,
    ASSIGN_DEAL_MODAL_STATE_REGION
  );
  const displayableValue = displayableDealSelector(
    state,
    ASSIGN_DEAL_MODAL_STATE_REGION
  );
  return (displayableValue || selectedDeal?.name) ?? '';
}

export function selectedSalesPersonEmailSelector(state) {
  const selectedDeal = selectedDealSelector(
    state,
    ASSIGN_DEAL_MODAL_STATE_REGION
  );
  return (
    (state['admin:salesPeople'] ?? []).find(
      ({ id }) => id === selectedDeal?.userId
    )?.email ?? ''
  );
}

export function isAssignModalSubmitButtonEnabledSelector(state) {
  return (
    selectedDealSelector(state, ASSIGN_DEAL_MODAL_STATE_REGION) != null &&
    !isAssignModalLoadin(state)
  );
}

function isAssignModalLoadin(state) {
  return !!state[ASSIGN_DEAL_MODAL_STATE_REGION].loading;
}
