import { useCallback } from 'react';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import styled from 'styled-components';
import { PrimaryButton } from 'design-system/components/Button/presets';
import { PUBLISHER_CONTACTS_SALES } from './events';
import { salesHasAlreadyBeenContactedSelector } from './selectors';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { supportEmailSelector } from '../../../../selectors/config';

const Wrapper = styled(PrimaryButton)`
  ${spacing.stack(SPACING_SIZE.M)}
`;

function ContactSalesButton() {
  const alreadyContacted = useSelector(salesHasAlreadyBeenContactedSelector);
  const supportEmail = useSelector(supportEmailSelector);
  const onClickCTA = useCallback(() => {
    dispatch({ id: PUBLISHER_CONTACTS_SALES });
  }, [supportEmail]);

  return (
    <Wrapper onClick={onClickCTA} disabled={alreadyContacted}>
      <FormattedMessageWithValidation
        id={
          alreadyContacted
            ? 'subscription_benefits_upsell_already_contacted'
            : 'subscription_benefits_upsell_contact_cta'
        }
      />
    </Wrapper>
  );
}

export default ContactSalesButton;
