const { max, min, ceil } = Math;

export function calculatePaginationDetails(pagination) {
  const { currentPage, itemsPerPage, totalItems } = pagination;

  const itemsPerPageLimit = currentPage * itemsPerPage;
  const itemsLimitDelta = max(itemsPerPageLimit - totalItems, 0);
  const firstItemNumber = min((currentPage - 1) * itemsPerPage + 1, totalItems);
  const lastItemNumber = itemsPerPageLimit - itemsLimitDelta;

  return {
    totalPages: ceil(totalItems / itemsPerPage),
    currentItemRange: [firstItemNumber, lastItemNumber],
  };
}
