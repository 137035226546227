import { withoutEventPropagation } from 'design-system/utils/withoutEventPropagation';
import { dispatch } from 'reffects';
import { useMount } from 'react-use';
import { useCallback } from 'react';
import { useSelector } from 'reffects-store';
import Menu from 'design-system/components/Menu/Menu';
import {
  WASI_API_PUBLISHER_MODAL,
  WASI_API_PUBLISHER_REQUESTED,
} from '../Modals/SendPropertiesToWasiModal/events';
import { OPEN_DIALOG } from '../../../../../../events/dialogs';
import { sendWasiPropertiesButtonDisabled } from '../Modals/SendPropertiesToWasiModal/selectors';

export function SendPropertiesToWasiAction({ publisher }) {
  const label = 'Migrate content to Wasi';
  const publisherId = publisher.id;
  useMount(() =>
    dispatch({ id: WASI_API_PUBLISHER_REQUESTED, payload: { publisherId } })
  );

  const showModal = useCallback(() => {
    dispatch({
      id: OPEN_DIALOG,
      payload: {
        id: WASI_API_PUBLISHER_MODAL,
      },
    });
  }, []);

  return useSelector(sendWasiPropertiesButtonDisabled) ? (
    <Menu.Item label={label} disabled />
  ) : (
    <Menu.Item label={label} onClick={withoutEventPropagation(showModal)} />
  );
}
