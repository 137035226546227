import { useIntl } from 'react-intl';
import { formatPrice } from '../../../../partials/Price/Price';
import InputSelectWithHtmlValidation from '../InputSelectWithHtmlValidation';

function PlanToSubscribe({
  name,
  label,
  required,
  disabled,
  value,
  onChange,
  plansByMaxBoostableProperties,
  displayFreeOption,
}) {
  const intl = useIntl();

  const choices = plansByMaxBoostableProperties.map(
    ({ id, nameTag, price, currency }) => ({
      id,
      label: `${intl.formatMessage({
        id: nameTag,
      })} ${price ? formatPrice(intl, price, currency) : null}`,
      value: id,
    })
  );

  if (displayFreeOption) {
    choices.unshift({
      id: 'free',
      label: 'Free',
      value: 'free',
    });
  }

  return (
    <InputSelectWithHtmlValidation
      name={name}
      label={label}
      value={value}
      required={required}
      disabled={disabled}
      choices={choices}
      onSelect={onChange}
    />
  );
}
export default PlanToSubscribe;
