import styled from 'styled-components';
import { useController, useFormContext } from 'react-hook-form';
import { useUpdateEffect } from 'react-use';
import { useCallback } from 'react';
import Tip from 'design-system/components/Tip';
import { TextButton } from 'design-system/components/Button/presets';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import { COLOR_PALETTE } from 'design-system/styles/color';
import Section from '../../Section';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import useFileUpload from '../CompanyLogoField/useFileUpload';
import PublisherBackgroundThumbnail from './PublisherBackgroundThumbnail';

const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${PublisherBackgroundThumbnail} {
    ${spacing.stack(SPACING_SIZE.M)}
  }
`;

function CompanyBackgroundField({ name }) {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  const { file, uploadFile, resetFile } = useFileUpload({
    acceptFileTypes: ['.jpg', '.jpeg', '.png'],
  });

  // onChange is an async function, result can not be returned in the useUpdateEffect callback because it fails
  // do not replace with useUpdateEffect(() => onChange(file), [file])
  useUpdateEffect(() => {
    onChange(file);
  }, [file]);

  const handleResetFile = useCallback(() => {
    onChange(null);
    resetFile();
  }, []);

  return (
    <Section
      title={
        <FormattedMessageWithValidation id="company_profile_background_image_title" />
      }
      cta={
        <TextButton onClick={uploadFile}>
          {!value ? (
            <FormattedMessageWithValidation id="company_profile_add_background_image_cta" />
          ) : (
            <FormattedMessageWithValidation id="company_profile_change_background_image_cta" />
          )}
        </TextButton>
      }
    >
      <Content>
        <PublisherBackgroundThumbnail
          source={value}
          onDeleteButtonClick={handleResetFile}
        />
        <Tip>
          <Body2 $color={COLOR_PALETTE.NEUTRAL_A60}>
            <FormattedMessageWithValidation
              id="company_profile_background_image_caption"
              values={{ breakingLine: <br /> }}
            />
          </Body2>
        </Tip>
      </Content>
    </Section>
  );
}

export default CompanyBackgroundField;
