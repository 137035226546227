import { Children, cloneElement, isValidElement, useState } from 'react';
import styled, { css } from 'styled-components';
import Tooltip, { TOOLTIP_PLACEMENT } from 'design-system/components/Tooltip';
import { BORDER_SIZE } from 'design-system/styles/border';
import { radius } from 'design-system/styles/radius';
import { SPACING_SIZE } from 'design-system/styles/spacing';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import { noop } from '../utils';

const Wrapper = styled.div``;

const Tab = styled.button`
  position: relative;
  border: none;
  cursor: pointer;
  background: transparent;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  padding: ${SPACING_SIZE.M}px ${SPACING_SIZE.S}px;

  ${radius.regular(BORDER_SIZE.NONE)}
  ${Body2} {
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
  }

  &:hover ${Body2} {
    ${color.text(COLOR_PALETTE.PRIMARY_A140)}
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:disabled ${Body2} {
    color: ${COLOR_PALETTE.NEUTRAL_A20};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      ${Body2} {
        color: ${COLOR_PALETTE.NEUTRAL_BASE};
      }

      &::after {
        content: '';
        position: absolute;
        height: 2px;
        bottom: 0;
        left: 16px;
        right: 16px;
        background: ${COLOR_PALETTE.PRIMARY_BASE};
        ${radius.pill}
      }
    `}
`;

const Nav = styled.nav`
  padding: 0 ${SPACING_SIZE.S}px;
  ${Tooltip} {
    z-index: 10;
  }
`;

function Tabs({ children, defaultActive = null }) {
  const [activeTabId, setActiveTabId] = useState(defaultActive);

  const renderTab = (id, onClick, disabled, rest, title) => (
    <Tab
      onClick={(e) => {
        setActiveTabId(id);
        onClick(e);
      }}
      isActive={id === activeTabId}
      disabled={disabled}
      {...rest}
    >
      <Body2 $weight={FONT_WEIGHT.MEDIUM}>{title}</Body2>
    </Tab>
  );

  return (
    <Wrapper>
      <Nav>
        {Children.map(
          children,
          ({
            props: {
              children: _,
              id,
              title,
              disabled,
              tooltip,
              onClick = noop,
              ...rest
            },
          }) =>
            tooltip ? (
              <Tooltip title={tooltip} $placement={TOOLTIP_PLACEMENT.RIGHT}>
                {renderTab(id, onClick, disabled, rest, title)}
              </Tooltip>
            ) : (
              renderTab(id, onClick, disabled, rest, title)
            )
        )}
      </Nav>
      {Children.map(children, ({ props: { id, children: tabChildren } }) =>
        id === activeTabId
          ? isValidElement(tabChildren)
            ? cloneElement(tabChildren)
            : tabChildren
          : null
      )}
    </Wrapper>
  );
}

Tabs.Section = styled.div``;

export default Tabs;
