import EmailDetailForm from './EmailDetailForm';
import FacebookDetailForm from './FacebookDetailForm';
import LineDetailForm from './LineDetailForm';
import PhoneDetailForm from './PhoneDetailForm';
import ViberDetailForm from './ViberDetailForm';
import WhatsappDetailForm from './WhatsappDetailForm';

const FORM_COMPONENT_BY_CONTACT_TYPE = {
  emails: EmailDetailForm,
  phones: PhoneDetailForm,
  whatsapp: WhatsappDetailForm,
  line: LineDetailForm,
  facebook: FacebookDetailForm,
  viber: ViberDetailForm,
};

function DetailForm({ contactType, detail, onSubmit, onCancel }) {
  const FormComponent = FORM_COMPONENT_BY_CONTACT_TYPE[contactType];

  return (
    <FormComponent detail={detail} onSubmit={onSubmit} onCancel={onCancel} />
  );
}

export default DetailForm;
