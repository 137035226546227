import styled from 'styled-components';
import Image from 'design-system/components/Image/Image';
import { border, BORDER_SIZE } from 'design-system/styles/border';
import { divider, DIVIDER_SIZE } from 'design-system/styles/divider';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import Typography from 'design-system/components/Typography/Typography';
import {
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import viewsCount from './images/viewsCount.png';
import enquiriesCount from './images/enquiriesCount.png';
import FormattedMessageWithValidation from '../../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Wrapper = styled.div`
  width: 100%;
  ${({ fullWidth }) => !fullWidth && 'width: 50%;'}
  display: flex;
  flex-direction: row;
  ${border.inner({ color: COLOR_PALETTE.NEUTRAL_A20, size: BORDER_SIZE.BASE })}
  ${radius.regular(RADIUS_SIZE.LARGE)}
`;

const Stat = styled.div`
  ${spacing.inset(SPACING_SIZE.S)}
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${Subtitle3} {
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)};
    text-align: center;
  }
`;

const Value = styled(Typography)`
  ${typography.size(FONT_SIZE.M)};
  ${typography.lineHeight(17)};
  ${typography.weight(FONT_WEIGHT.SEMIBOLD)};
`;

const VerticalDivider = styled.div`
  ${divider.right(DIVIDER_SIZE.THIN)}
`;

export default function Stats({
  views,
  enquiries,
  hasViewsInfo,
  blurred = false,
}) {
  return (
    <Wrapper fullWidth={hasViewsInfo}>
      {hasViewsInfo && (
        <>
          <Stat>
            <Value>{blurred ? <Image source={viewsCount} /> : views}</Value>
            <Subtitle3>
              <FormattedMessageWithValidation id="lead_detail_stats_views" />
            </Subtitle3>
          </Stat>
          <VerticalDivider />
        </>
      )}
      <Stat>
        <Value>{blurred ? <Image source={enquiriesCount} /> : enquiries}</Value>
        <Subtitle3>
          <FormattedMessageWithValidation id="lead_detail_stats_enquiries" />
        </Subtitle3>
      </Stat>
    </Wrapper>
  );
}
