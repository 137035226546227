import { publisherIsFromMexicoSelector } from '../../../pages/Checkout/BillingInformation/partials/Form/selectors';
import { publishedPropertiesSelector } from '../../../selectors/propertiesSummary';
import {
  isAgencySelector,
  publisherSubscriptionPlanIsFreeSelector,
} from '../../../selectors/publisher';
import { getFeatureSelector } from '../../../utils/featuresFlags/selectors';

export function showSubscriptionBadgeSelector(state) {
  if (!getFeatureSelector(state, 'BADGE_MY_SUBSCRIPTION_4133')) {
    return false;
  }
  const isAgency = isAgencySelector(state);
  const isMexico = publisherIsFromMexicoSelector(state);
  const publishedProperties = publishedPropertiesSelector(state);
  const isFree = publisherSubscriptionPlanIsFreeSelector(state);
  return isAgency && isMexico && publishedProperties <= 10 && isFree;
}
