import styled, { css } from 'styled-components';
import {
  color,
  COLOR_PALETTE,
  COLOR_SEMANTIC,
} from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Popover from '../Popover';
import Image from './Image';
import Portal from '../Portal';
import Text from './Text';
import Header from './Header';
import Emphasis from './Emphasis';
import { withoutEventPropagation } from '../../utils';

export const DIALOG_THEME = {
  LIGHT: Symbol('DIALOG_THEME_LIGHT'),
  DARK: Symbol('DIALOG_THEME_DARK'),
};

export const FOOTER_CONTENT_POSITION = {
  START: Symbol('FOOTER_CONTENT_POSITION_START'),
  END: Symbol('FOOTER_CONTENT_POSITION_END'),
};

const FooterContentAlignment = {
  [FOOTER_CONTENT_POSITION.START]: 'left',
  [FOOTER_CONTENT_POSITION.END]: 'right',
};

const Footer = styled.footer.attrs(({ alignment }) => ({
  style: {
    textAlign: FooterContentAlignment[alignment],
  },
}))`
  text-align: right;
  padding: ${spacing.value(SPACING_SIZE.L)} ${spacing.value(SPACING_SIZE.XL)};
`;

const Content = styled.div`
  padding: ${spacing.value(SPACING_SIZE.NONE)} ${spacing.value(SPACING_SIZE.XL)};
`;

const Paper = styled(Popover)`
  overflow: initial;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 130px;
  max-width: 568px;
  ${({ fitContent }) => fitContent && `max-width: fit-content`};
  margin: 0 auto 64px;
  outline: none;

  &${Popover} {
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25);
  }
`;

const modLightTheme = css``;
const modDarkTheme = css`
  ${Paper} {
    ${color.background(COLOR_PALETTE.SECONDARY_BASE)}
  }
  ${Header}, ${Content} {
    * {
      ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
    }
  }
`;

const modTheme = (theme) =>
  ({
    [DIALOG_THEME.LIGHT]: modLightTheme,
    [DIALOG_THEME.DARK]: modDarkTheme,
  }[theme]);

const Wrapper = styled.div`
  position: fixed;
  overflow-y: scroll;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 4;

  ::-webkit-scrollbar,
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;

  ${({ $theme }) => modTheme($theme)}
`;

const BrandedHeader = styled.div`
  text-align: center;
  border-bottom: 1px solid ${COLOR_SEMANTIC.DIVIDER};
  ${spacing.insetSandwich(SPACING_SIZE.XS)}
`;

function Dialog({
  children,
  logo,
  open,
  onClose,
  fitContent,
  theme = DIALOG_THEME.LIGHT,
  ...rest
}) {
  return (
    <Portal>
      {open ? (
        <Wrapper {...rest} $theme={theme} onClick={onClose}>
          <Paper
            role="dialog"
            aria-hidden
            tabIndex="-1"
            fitContent={fitContent}
            onClick={withoutEventPropagation()}
          >
            {logo && <BrandedHeader>{logo}</BrandedHeader>}
            {children}
          </Paper>
        </Wrapper>
      ) : null}
    </Portal>
  );
}

Dialog.Header = Header;
Dialog.Text = Text;
Dialog.Image = Image;
Dialog.Content = Content;
Dialog.Footer = Footer;
Dialog.Emphasis = Emphasis;

export default styled(Dialog)``;
