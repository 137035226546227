import styled from 'styled-components';
import InputSelectWithHtmlValidation from './InputSelectWithHtmlValidation';

export const CampaignBillingCycleSelector = styled(
  ({
    name = 'billingCycleInMonths',
    label = 'Contracted months',
    value,
    required,
    onChange,
    ...props
  }) => (
    <InputSelectWithHtmlValidation
      name={name}
      label={label}
      value={value}
      required={required}
      choices={[1, 3, 6, 12].map((billingCycle) => ({
        id: billingCycle,
        label: `${billingCycle} month`,
        value: billingCycle,
      }))}
      onSelect={(e) => onChange(e.value)}
      {...props}
    />
  )
)``;
