export function developerFilter(value, { developerId, developerName }) {
  return contains(developerId, value) || contains(developerName, value);
}

export function projectFilter(value, { projectId, projectName }) {
  return contains(projectId, value) || contains(projectName, value);
}

function contains(haystack, needle) {
  return haystack.toLowerCase().includes(needle.toLowerCase());
}
