import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Header from './Header';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
`;

const Body = styled.div`
  flex: 1;
  background-color: #f4f6f8;
  ${spacing.stack(SPACING_SIZE.L)}
`;

const Page = styled.div`
  > .card {
    margin: ${spacing.value(SPACING_SIZE.L)};
  }
`;

export default function Layout({ children }) {
  return (
    <Wrapper>
      <Header />
      <Content>
        <Body>
          <Page>{children}</Page>
        </Body>
      </Content>
    </Wrapper>
  );
}
