import { useController, useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { dispatch } from 'reffects';
import { subscribe } from 'reffects-store';
import ImageUploader from './ImageUploader';
import { useRegisterFieldErrors } from '../FieldGroup';
import {
  IMAGE_DELETED,
  IMAGES_SORTED,
  IMAGES_UPLOADED,
  IMAGE_DELETE_CONFIRMED,
  IMAGE_DELETE_CANCELED,
} from './events';
import {
  errorSelector,
  imagesSelector,
  isDisabledSelector,
  showDeleteImageDialogSelector,
} from './selectors';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const ImageUploaderField = ({
  id,
  name,
  value,
  maxNumberOfImages,
  helperText,
  errorText,
  hasMainImage,
  isDisabled,
  onImagesUploaded,
  onImagesSorted,
  onDelete,
  showDeleteImageDialog,
  onConfirmDeleteImage,
  onCancelDeleteImage,
  ...rest
}) => {
  const { control } = useFormContext();
  const {
    fieldState: { error },
    field: { value: formValue, onChange, ref },
  } = useController({
    name,
    control,
    defaultValue: [],
  });

  useEffect(() => {
    onChange(value);
  }, [value]);

  useRegisterFieldErrors({ name, errors: error });

  return (
    <ImageUploader
      id={id}
      images={formValue}
      helperText={helperText}
      errorText={
        error?.message ??
        (errorText && <FormattedMessageWithValidation {...errorText} />)
      }
      isDisabled={isDisabled}
      ref={ref}
      onImagesUploaded={onImagesUploaded(
        name,
        formValue,
        hasMainImage,
        maxNumberOfImages
      )}
      onImagesSorted={onImagesSorted(name, formValue, hasMainImage)}
      onDelete={onDelete(name, formValue, hasMainImage)}
      showDeleteImageDialog={showDeleteImageDialog}
      onConfirmDeleteImage={onConfirmDeleteImage(name, formValue, hasMainImage)}
      onCancelDeleteImage={onCancelDeleteImage(name)}
      {...rest}
    />
  );
};

export default subscribe(
  ImageUploaderField,
  (state, { name, maxNumberOfImages }) => ({
    value: imagesSelector(state, name),
    isDisabled: isDisabledSelector(state, name, maxNumberOfImages),
    showDeleteImageDialog: showDeleteImageDialogSelector(state, name),
    errorText: errorSelector(state, name),
  }),
  {
    onImagesUploaded(field, images, hasMainImage, maxNumberOfImages) {
      return (newImages) =>
        dispatch({
          id: IMAGES_UPLOADED,
          payload: {
            field,
            images,
            newImages,
            hasMainImage,
            maxNumberOfImages,
          },
        });
    },
    onImagesSorted(field, images, hasMainImage) {
      return (e) =>
        dispatch({
          id: IMAGES_SORTED,
          payload: { field, images, hasMainImage, ...e },
        });
    },
    onDelete(field, images) {
      return (imageId) =>
        dispatch({
          id: IMAGE_DELETED,
          payload: { field, images, imageId },
        });
    },
    onConfirmDeleteImage(field, images, hasMainImage) {
      return () =>
        dispatch({
          id: IMAGE_DELETE_CONFIRMED,
          payload: { field, images, hasMainImage },
        });
    },
    onCancelDeleteImage(field) {
      return () => dispatch({ id: IMAGE_DELETE_CANCELED, payload: { field } });
    },
  }
);
