import * as React from "react";
const SvgStarFilled = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={44}
    height={33}
    fill="none"
    {...props}
  >
    <path
      fill="#F2C94C"
      d="M21.453 5.876a1 1 0 0 1 1.713 0l4.191 6.964a1 1 0 0 0 .632.459l7.918 1.834a1 1 0 0 1 .53 1.63L31.109 22.9a1 1 0 0 0-.242.742l.703 8.098a1 1 0 0 1-1.386 1.007l-7.485-3.17a1 1 0 0 0-.78 0l-7.484 3.17a1 1 0 0 1-1.387-1.007l.703-8.098a1 1 0 0 0-.24-.742l-5.33-6.138a1 1 0 0 1 .53-1.63l7.919-1.834a1 1 0 0 0 .631-.459z"
    />
    <path
      fill="#F2C94C"
      d="m22.31 15.452 1.645 2.735 3.11.72-2.093 2.41.276 3.18-2.939-1.245-2.939 1.245.276-3.18-2.092-2.41 3.11-.72z"
    />
    <path
      fill="#F2C94C"
      d="m22.31 15.452 1.645 2.735 3.11.72-2.093 2.41.276 3.18-2.939-1.245-2.939 1.245.276-3.18-2.092-2.41 3.11-.72z"
    />
    <path
      fill="#F2C94C"
      d="m22.31 15.452 1.645 2.735 3.11.72-2.093 2.41.276 3.18-2.939-1.245-2.939 1.245.276-3.18-2.092-2.41 3.11-.72z"
    />
    <path
      fill="#F2C94C"
      d="m22.31 15.452 1.645 2.735 3.11.72-2.093 2.41.276 3.18-2.939-1.245-2.939 1.245.276-3.18-2.092-2.41 3.11-.72z"
    />
    <path
      fill="#F2C94C"
      d="m22.31 15.452 1.645 2.735 3.11.72-2.093 2.41.276 3.18-2.939-1.245-2.939 1.245.276-3.18-2.092-2.41 3.11-.72z"
    />
  </svg>
);
export default SvgStarFilled;
