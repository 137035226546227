import Dialog from 'design-system/components/Dialog/Dialog';
import {
  FlatButton,
  SecondaryButton,
} from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import InputTextarea from 'design-system/components/InputTextarea';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import { useDialog } from '../../../../hooks/useDialog';
import { REQUEST_AUTO_RENEWAL_CANCELLATION_MODAL } from './constants';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import useInternationalization from '../../../../hooks/useInternationalization';
import {
  CANCELLATION_REASON_CHANGED,
  REQUEST_AUTO_RENEWAL_CANCELLATION,
} from './events';
import {
  autoRenewalCancellationReasonSelector,
  canSubmitCancellationRequestSelector,
} from './selectors';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.L)};
`;

export function RequestAutoRenewalCancellationModal() {
  const { open, closeDialog } = useDialog(
    REQUEST_AUTO_RENEWAL_CANCELLATION_MODAL
  );
  const intl = useInternationalization();
  const cancellationReason = useSelector(autoRenewalCancellationReasonSelector);
  const canSubmitRequest = useSelector(canSubmitCancellationRequestSelector);
  return (
    <Dialog open={open}>
      <Dialog.Header onClose={closeDialog}>
        <FormattedMessageWithValidation id="subscription_request_cancellation_modal_title" />
      </Dialog.Header>
      <Dialog.Content>
        <Content>
          <Body1>
            <FormattedMessageWithValidation id="subscription_request_cancellation_modal_body" />
          </Body1>
          <InputTextarea
            placeholder={intl.formatMessageWithValidation({
              id: 'subscription_request_cancellation_placeholder',
            })}
            value={cancellationReason}
            onChange={(e) =>
              dispatch({
                id: CANCELLATION_REASON_CHANGED,
                payload: { reason: e.target.value },
              })
            }
          />
        </Content>
      </Dialog.Content>
      <Dialog.Footer>
        <FlatButton onClick={closeDialog} $size={BUTTON_SIZE.SMALL}>
          <FormattedMessageWithValidation id="modal_button_close" />
        </FlatButton>
        <SecondaryButton
          onClick={() =>
            dispatch({
              id: REQUEST_AUTO_RENEWAL_CANCELLATION,
              payload: { reason: cancellationReason },
            })
          }
          disabled={!canSubmitRequest}
          $size={BUTTON_SIZE.SMALL}
        >
          <FormattedMessageWithValidation id="subscription_request_cancellation_modal_cta" />
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}
