export function vCardFormatter({ name, phone, email }) {
  const cleanName = name?.trim() ?? '';
  const cleanPhone = phone?.trim() ?? '';
  const cleanEmail = email?.trim() ?? '';

  return (
    `BEGIN:VCARD\n` +
    `VERSION:3.0\n` +
    `N;CHARSET=UTF-8:${cleanName}\n` +
    `FN;CHARSET=UTF-8:${cleanName}\n` +
    `TEL;TYPE=CELL:${cleanPhone}\n` +
    `EMAIL:${cleanEmail}\n` +
    `END:VCARD`
  );
}
