import styled, { css } from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { border } from 'design-system/styles/border';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import RadioIcon from './RadioIcon';
import { withStylesAndInnerRef } from '../../utils';

const HiddenInput = styled.input.attrs({
  type: 'radio',
})`
  display: none;
`;

const Input = styled.span`
  width: 16px;
  height: 16px;
  display: inline-block;
  cursor: pointer;
  ${radius.regular(RADIUS_SIZE.LARGE)}
  ${border.inner({ size: 1, color: COLOR_PALETTE.NEUTRAL_A20 })}
  ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
  ${color.background(COLOR_PALETTE.NEUTRAL_A00)}

  &:hover,
  &:active,
  &:focus {
    ${border.inner({
      size: 1,
      color: COLOR_PALETTE.NEUTRAL_A80,
    })}
  }
`;

const modChecked = () => css`
  ${HiddenInput}:checked ~ ${Input} {
    ${border.inner({
      size: 1,
      color: COLOR_PALETTE.NEUTRAL_A80,
    })}
    ${color.background(COLOR_PALETTE.NEUTRAL_A80)}
  }
`;

const modDisabled = () => css`
  ${HiddenInput}:disabled ~ ${Input} {
    cursor: not-allowed;

    ${border.inner({
      size: 1,
      color: COLOR_PALETTE.NEUTRAL_A20,
    })}
    ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
  }

  ${HiddenInput}:disabled:checked ~ ${Input} {
    ${color.background(COLOR_PALETTE.NEUTRAL_A20)}
  }
`;

const Wrapper = styled.label`
  display: inline-flex;

  ${() => modChecked()}
  ${() => modDisabled()}
`;

function RadioButton({ className, style, innerRef, ...rest }) {
  return (
    <Wrapper className={className} style={style}>
      <HiddenInput {...rest} ref={innerRef} />
      <Input>
        <RadioIcon />
      </Input>
    </Wrapper>
  );
}

export default withStylesAndInnerRef(RadioButton);
