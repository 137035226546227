import { useSelector } from 'reffects-store';
import CheckoutSummary from './CheckoutSummary';
import BillingDisclaimer from '../BillingDisclaimer/BillingDisclaimer';
import { isSummaryLoadedSelector, summarySelector } from './selectors';

export default function Summary() {
  const summary = useSelector(summarySelector);
  const isLoaded = useSelector(isSummaryLoadedSelector);
  const { convertedTotalPrice } = summary;

  if (!isLoaded) {
    return null;
  }

  return (
    <>
      <CheckoutSummary {...summary} />
      <BillingDisclaimer
        showBillingDisclaimer={false}
        showBillingConversion={convertedTotalPrice != null}
        convertedTotalPrice={convertedTotalPrice}
      />
    </>
  );
}
