import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import { useMount } from 'react-use';
import { useCallback, useEffect, useState } from 'react';
import Papa from 'papaparse';
import Tip from 'design-system/components/Tip';
import Dialog from 'design-system/components/Dialog/Dialog';
import Anchor from 'design-system/components/Anchor';
import Tag, { TAG_VARIANT } from 'design-system/components/Tag';
import Button, { BUTTON_SIZE } from 'design-system/components/Button';
import {
  FlatButton,
  OutlineButton,
  PrimaryButton,
} from 'design-system/components/Button/presets';
import DetectFileEncodingAndLanguage from 'detect-file-encoding-and-language';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { COLOR_PALETTE } from 'design-system/styles/color';
import {
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { UPLOAD_CSV_MODAL } from '../constants';
import {
  getCsvExampleGuideLinkSelector,
  getCsvExampleLinkSelector,
  getUploadErrorsSelector,
  showModalUploadCSVSelector,
} from '../selectors';
import FileInput from '../../../../partials/FileInput';
import { useDialog } from '../../../../hooks/useDialog';
import { OPEN_DIALOG } from '../../../../events/dialogs';
import useTracking from '../../../../hooks/useTracking';
import useClickTracking from '../../../../hooks/useClickTracking';
import { CSV_FILE_UPLOADED, CSV_PUBLISH_MANUALLY } from '../events';

const Subtitle = styled(Tip)`
  ${Tag.mods.variant(TAG_VARIANT.COMPLEMENTARY_STRAWBERRY)}
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  ${spacing.inset(SPACING_SIZE.S)}
  color: ${COLOR_PALETTE.STRAWBERRY_BASE};
  ${typography.size(FONT_SIZE.M)}
  width 100%
`;

const CustomDialog = styled(Dialog)`
  > div {
    display: flex;
    flex-direction: column;
    gap: ${spacing.value(SPACING_SIZE.M)};
  }
`;

const WrapperModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.XL)};
  margin-top: ${spacing.value(SPACING_SIZE.M)};
`;

const WrapperModalBodyButtons = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.value(SPACING_SIZE.S)};
`;

const WrapperModalBodyText = styled.div`
  ${typography.font(FONT_FAMILY.PRIMARY)};
  ${typography.lineHeight(20)};
  ${typography.weight(FONT_WEIGHT.NORMAL)}
  ${typography.size(FONT_SIZE.M)}
    display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.M)};
`;

const ImportButton = styled(PrimaryButton)`
  ${Button.mods.size(BUTTON_SIZE.SMALL)}
  span {
    ${typography.size(FONT_SIZE.S)}
    ${typography.font(FONT_FAMILY.PRIMARY)};
  }
`;

const ManualButton = styled(OutlineButton)`
  ${Button.mods.size(BUTTON_SIZE.SMALL)}
  span {
    ${typography.font(FONT_FAMILY.PRIMARY)};
    ${typography.size(FONT_SIZE.S)}
  }
`;

const Table = styled.table`
  width: 100%;

  tbody > tr:nth-of-type(odd) {
    background-color: #f3f4f5;
  }
`;
const TableBody = styled.tbody``;
const TableRow = styled.tr``;
const TableCell = styled.td`
  padding: ${spacing.value(SPACING_SIZE.XS)} ${spacing.value(SPACING_SIZE.S)};
`;

export default function ModalUploadCSV() {
  const { open, closeDialog } = useDialog(UPLOAD_CSV_MODAL);
  const showModalUploadCsv = useSelector(showModalUploadCSVSelector);
  const [file, setFile] = useState();
  const hasError = useSelector(getUploadErrorsSelector);
  const [linkElement, setLinkElement] = useState(null);
  const csvExampleLink = useSelector(getCsvExampleLinkSelector);
  const csvExampleGuideLink = useSelector(getCsvExampleGuideLinkSelector);

  const { trackClick } = useClickTracking({
    sectionName: 'upload-csv-section',
  });
  const ref = useCallback((node) => {
    if (node != null && linkElement !== node) setLinkElement(node);
  }, []);
  useMount(() => {
    if (showModalUploadCsv) {
      dispatch({
        id: OPEN_DIALOG,
        payload: { id: UPLOAD_CSV_MODAL },
      });
    }
  });
  useTracking({
    ref: linkElement,
    trackImpression: {
      elementName: 'modal-upload-csv-is-visible',
    },
    sectionName: 'upload-csv-section',
  });

  const loadCSV = async () => {
    const { encoding } = await DetectFileEncodingAndLanguage(file);
    let detectedEncoding = encoding;
    if (!detectedEncoding) {
      detectedEncoding = 'windows-1252';
    }
    const textDecoder = new TextDecoder(detectedEncoding);
    const arrayBuffer = await file.arrayBuffer();
    const utf8String = textDecoder.decode(arrayBuffer);

    Papa.parse(utf8String, {
      header: true,
      skipEmptyLines: true,
      keepEmptyRows: false,
      transform: (value) => value.trim(),
      transformHeader: (header) => {
        if (header.trim() !== '' && !header.includes('undefined')) {
          return header;
        }
        return undefined;
      },
      complete(results) {
        dispatch({
          id: CSV_FILE_UPLOADED,
          payload: {
            data: results.data,
            headers: results.meta.fields,
            errors: results.errors,
            file,
            fileEncoding: detectedEncoding,
            delimiter: results.meta.delimiter,
          },
        });
      },
    });
  };

  useEffect(() => {
    if (file) {
      loadCSV();
    }
  }, [file]);

  return (
    <CustomDialog open={open}>
      <Dialog.Header>
        <span ref={ref}>
          <FormattedMessageWithValidation id="upload_csv_modal_title" />
        </span>
      </Dialog.Header>
      {hasError && (
        <Subtitle>
          <FormattedMessageWithValidation id="upload_csv_modal_error" />
        </Subtitle>
      )}
      <Dialog.Content>
        <WrapperModalBody>
          <WrapperModalBodyText>
            <div>
              <FormattedMessageWithValidation id="upload_csv_modal_body_1" />{' '}
              <b>
                <FormattedMessageWithValidation id="upload_csv_modal_body_2" />
              </b>{' '}
              <FormattedMessageWithValidation id="upload_csv_modal_body_3" />
            </div>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <span>
                      <b>
                        <FormattedMessageWithValidation id="upload_csv_field_property_type" />
                      </b>{' '}
                      <FormattedMessageWithValidation id="upload_csv_field_property_type_example" />
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <span>
                      <b>
                        <FormattedMessageWithValidation id="upload_csv_field_operation_type" />
                      </b>{' '}
                      <FormattedMessageWithValidation id="upload_csv_field_operation_type_example" />
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <span>
                      <b>
                        <FormattedMessageWithValidation id="upload_csv_field_address" />
                      </b>
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <span>
                      <b>
                        <FormattedMessageWithValidation id="upload_csv_field_title" />
                      </b>
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <span>
                      <b>
                        <FormattedMessageWithValidation id="upload_csv_field_description" />
                      </b>
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <span>
                      <b>
                        <FormattedMessageWithValidation id="upload_csv_field_email" />
                      </b>
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <span>
                      <b>
                        <FormattedMessageWithValidation id="upload_csv_field_phone" />
                      </b>
                    </span>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Anchor
              href={csvExampleLink}
              target="_blank"
              onClick={() => {
                trackClick({
                  eventName: 'modal-upload-csv-download-example-clicked',
                });
              }}
            >
              <FormattedMessageWithValidation id="upload_csv_download_example" />
            </Anchor>
            <Anchor
              href={csvExampleGuideLink}
              target="_blank"
              onClick={() => {
                trackClick({
                  eventName: 'modal-upload-csv-download-guide-clicked',
                });
              }}
            >
              <FormattedMessageWithValidation id="upload_csv_download_guide" />
            </Anchor>
          </WrapperModalBodyText>
          <WrapperModalBodyButtons>
            <ImportButton>
              <FileInput
                id="csv-input"
                onUpload={([uploadedFile]) => {
                  setFile(uploadedFile);
                }}
                onClick={() => {
                  trackClick({
                    eventName: 'modal-upload-csv-import-csv-button-clicked',
                  });
                }}
                accept=".csv"
              >
                <FormattedMessageWithValidation id="upload_csv_import_properties" />
              </FileInput>
            </ImportButton>
            o
            <ManualButton onClick={() => dispatch(CSV_PUBLISH_MANUALLY)}>
              <FormattedMessageWithValidation id="upload_csv_import_manually" />
            </ManualButton>
          </WrapperModalBodyButtons>
        </WrapperModalBody>
      </Dialog.Content>
      <Dialog.Footer>
        <FlatButton onClick={closeDialog}>
          <FormattedMessageWithValidation id="modal_button_close" />
        </FlatButton>
      </Dialog.Footer>
    </CustomDialog>
  );
}
