import styled from 'styled-components';
import Layout from 'design-system/components/Layout';
import Illustration from 'design-system/components/Illustration/Illustration';
import { Subtitle1 } from 'design-system/components/Typography/presets/Subtitle1';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { Navigation } from '../../../partials';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import PublisherData from '../PublisherData/PublisherData';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${Subtitle1} {
    ${spacing.stack(SPACING_SIZE.M, false)}
  }

  ${Illustration} {
    ${spacing.stack(SPACING_SIZE.L, false)}
  }
`;

export default function SubscriptionAlreadyActive() {
  return (
    <Layout>
      <Layout.Header>
        <Navigation title={<PublisherData />} hideLinks hideAppBanner />
      </Layout.Header>
      <Layout.Content>
        <Layout.Padded>
          <Content>
            <Subtitle1>
              <FormattedMessageWithValidation id="checkout_forbidden_title" />
            </Subtitle1>
            <Illustration name="welcome" />
            <Body1>
              <FormattedMessageWithValidation id="checkout_forbidden_body" />
            </Body1>
          </Content>
        </Layout.Padded>
      </Layout.Content>
    </Layout>
  );
}
