import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { toast } from '../../../../../effects/toast';
import { environment } from '../../../../../coeffects/environment';
import {
  UNITS_PAGE_MOUNTED,
  UNITS_TABLE_BULK_CHECKBOX_CHANGED,
} from '../../../events';

export const UNITS_BULK_TOGGLE_VISIBLE = 'UNITS_BULK_TOGGLE_VISIBLE';
export const UNITS_BULK_ACTION_REQUESTED = 'UNITS_BULK_ACTION_REQUESTED';
export const UNITS_BULK_ACTION_SUCCEEDED = 'UNITS_BULK_ACTION_SUCCEEDED';
export const UNITS_BULK_ACTION_FAILED = 'UNITS_BULK_ACTION_FAILED';
export const UNITS_BULK_ACTION_SELECTED = 'UNITS_BULK_ACTION_SELECTED';
export const UNITS_BULK_ACTION_CONFIRMATION_CANCELLED =
  'UNITS_BULK_ACTION_CONFIRMATION_CANCELLED';

const PROGRESS_TOAST_TAGS = {
  publish: 'units_page_bulk_toast_progress_publish_activate',
  unpublish: 'units_page_bulk_toast_progress_unpublish',
  delete: 'units_page_bulk_toast_progress_delete',
};

const COMPLETE_TOAST_TAGS = {
  publish: 'units_page_bulk_toast_confirmation_publish_activated',
  unpublish: 'units_page_bulk_toast_confirmation_unpublish',
  delete: 'units_page_bulk_toast_confirmation_delete',
};

registerEventHandler(
  UNITS_BULK_TOGGLE_VISIBLE,
  ({ state: { viewableUnits, bulkUnitIds = [] } }) => {
    const hiddenSelected = bulkUnitIds.filter(
      (item) => !viewableUnits?.includes(item)
    );
    const anyCheckedIsVisible = bulkUnitIds.some((r) =>
      viewableUnits?.includes(r)
    );

    if (anyCheckedIsVisible) {
      return state.set({
        'bulk:unitIds': hiddenSelected,
      });
    }

    return state.set({
      'bulk:unitIds': bulkUnitIds.concat(viewableUnits),
    });
  },
  [
    state.get({
      viewableUnits: 'unitsTable.rows',
      bulkUnitIds: 'bulk:unitIds',
    }),
  ]
);

registerEventHandler(
  UNITS_TABLE_BULK_CHECKBOX_CHANGED,
  ({ state: { bulkUnitIds = [] } }, { unitId }) => {
    const indexOf = bulkUnitIds.indexOf(unitId);
    if (indexOf !== -1) {
      bulkUnitIds.splice(indexOf, 1);
    } else {
      bulkUnitIds.push(unitId);
    }

    return state.set({
      'bulk:unitIds': bulkUnitIds,
    });
  },
  [
    state.get({
      bulkUnitIds: 'bulk:unitIds',
    }),
  ]
);

registerEventHandler(
  UNITS_BULK_ACTION_REQUESTED,
  ({ environment: { apiUrl } }, payload) => {
    let action;
    let bulkUnitIds;
    if (Array.isArray(payload)) {
      action = payload[1].action;
      bulkUnitIds = payload[1].bulkUnitIds;
    } else {
      action = payload.action;
      bulkUnitIds = payload.bulkUnitIds;
    }
    return {
      ...http.put({
        url: `${apiUrl}/properties/${action}`,
        body: {
          adIds: [...bulkUnitIds],
        },
        successEvent: {
          id: UNITS_BULK_ACTION_SUCCEEDED,
          payload: {
            action,
          },
        },
        errorEvent: UNITS_BULK_ACTION_FAILED,
      }),
      ...state.set({
        [`bulkV2:actionSelected:${action}`]: undefined,
        [`bulkV2:actionExecutionInProgress`]: true,
      }),
      ...toast.show({
        text: PROGRESS_TOAST_TAGS[action],
        values: { n: bulkUnitIds.length },
        milliseconds: 300000,
      }),
    };
  },
  [environment()]
);

registerEventHandler(
  UNITS_BULK_ACTION_SUCCEEDED,
  (
    _,
    [
      {
        data: { countProcessedAds },
      },
      { action },
    ]
  ) => {
    if (action === 'delete') {
      return {
        ...effects.dispatch(UNITS_PAGE_MOUNTED),
        ...state.set({
          [`bulkV2:actionExecutionInProgress`]: undefined,
          [`bulk:unitIds`]: [],
        }),
        ...toast.show({
          text: COMPLETE_TOAST_TAGS[action],
          values: { n: countProcessedAds },
          milliseconds: 2000,
        }),
      };
    }
    return {
      ...effects.dispatch(UNITS_PAGE_MOUNTED),
      ...state.set({
        [`bulkV2:actionExecutionInProgress`]: undefined,
      }),
      ...toast.show({
        text: COMPLETE_TOAST_TAGS[action],
        values: { n: countProcessedAds },
        milliseconds: 2000,
      }),
    };
  }
);

registerEventHandler(UNITS_BULK_ACTION_FAILED, () => ({
  ...state.set({
    [`bulkV2:actionExecutionInProgress`]: undefined,
    [`bulk:unitIds`]: [],
  }),
}));

registerEventHandler(UNITS_BULK_ACTION_SELECTED, (_, { action }) => ({
  ...state.set({
    [`bulkV2:actionSelected:${action}`]: true,
  }),
}));

registerEventHandler(
  UNITS_BULK_ACTION_CONFIRMATION_CANCELLED,
  (_, { action }) => ({
    ...state.set({
      [`bulkV2:actionSelected:${action}`]: undefined,
    }),
  })
);
