import { forwardRef, useCallback } from 'react';
import BaseMenu from './partials/BaseMenu';

function Item(
  {
    id,
    label,
    isActive,
    value,
    children,
    onActivate,
    onConfirm,
    onCancel,
    ...rest
  },
  ref
) {
  const isValueHandledByChildren = Boolean(children);
  const isSubmenuValue = (v) => Boolean(v?.id);
  const { disabled } = rest;

  const handleConfirmation = useCallback(
    (v) => {
      if (disabled) {
        return;
      }
      if (isSubmenuValue(v)) {
        onConfirm(v);
      } else {
        onConfirm({ id, value: v });
      }
    },
    [id]
  );

  const handleCancellation = useCallback(() => {
    if (isSubmenuValue(value)) {
      onCancel(value);
    } else {
      onCancel({ id });
    }
  }, [id]);

  const handleActivation = useCallback(() => {
    onActivate({ id });
  }, [id]);

  const handleClick = useCallback(() => {
    if (isValueHandledByChildren) {
      handleActivation();
    } else {
      handleConfirmation(value);
    }
  }, [value, isValueHandledByChildren, handleConfirmation]);

  if (isActive) {
    return children({
      confirmActionHandler: handleConfirmation,
      cancelActionHandler: handleCancellation,
    });
  }

  return (
    <BaseMenu.Item onClick={handleClick} ref={ref} {...rest}>
      {label}
    </BaseMenu.Item>
  );
}

export default forwardRef(Item);
