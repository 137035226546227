import styled, { css } from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';

export const CELL_ALIGNMENT = {
  LEFT: Symbol('CELL_ALIGNMENT_LEFT'),
  CENTER: Symbol('CELL_ALIGNMENT_CENTER'),
};

const Wrapper = styled.td`
  border: 1px solid #e6e6e6;

  ${spacing.inset(SPACING_SIZE.M)}
`;

const Content = styled.div`
  display: flex;
`;

const modEmpty = () => css`
  border: none;
  ${color.background(COLOR_PALETTE.NONE)}
`;

const modHighlight = () => css`
  ${color.background(COLOR_PALETTE.PRIMARY_A10)}
`;

const modAlingnmentLeft = () => css`
  ${Content} {
    justify-content: flex-start;
  }
`;

const modAlingnmentCenter = () => css`
  ${Content} {
    justify-content: center;
  }
`;

const modAlingnment = (alignment) =>
  ({
    [CELL_ALIGNMENT.LEFT]: modAlingnmentLeft,
    [CELL_ALIGNMENT.CENTER]: modAlingnmentCenter,
  }[alignment]);

function Cell({ children, ...rest }) {
  return (
    <Wrapper {...rest}>
      <Content>{children}</Content>
    </Wrapper>
  );
}

Cell.mods = {
  empty: modEmpty,
  highlight: modHighlight,
  alignment: modAlingnment,
};

export default Cell;
