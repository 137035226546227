import styled, { css } from 'styled-components';
import BaseMenu from 'design-system/components/Menu/partials/BaseMenu';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import Typography from 'design-system/components/Typography/Typography';
import { Body2 } from 'design-system/components/Typography/presets/Body2';

const modMultiline = (hasSubtitle) => css`
  align-items: ${hasSubtitle ? 'flex-start' : 'center'};
`;

const modNoInteractive = () => css`
  cursor: initial;
  &:hover {
    ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
  }
  &:first-of-type:hover,
  &[aria-selected='true'] {
    ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
  }
`;

const modSelectedOption = () => css`
  &:hover {
    ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
  }
  &:first-of-type:hover,
  &[aria-selected='true'] {
    ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
  }
`;

const Wrapper = styled(BaseMenu.BaseItem)`
  outline: none;
  ${spacing.inset(SPACING_SIZE.NONE)}

  ${({ hasSubtitle }) => modMultiline(hasSubtitle)}

  ${({ noInteractive }) =>
    noInteractive ? modNoInteractive() : modSelectedOption()}
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  ${spacing.insetSandwich(10)}
  padding-right: ${spacing.value(10)};

  ${Body1} {
    ${spacing.stack(2)}
    ${Typography.mods.ellipse()}
  }
`;

const IconWrapper = styled.span`
  position: relative;
  ${spacing.inset(SPACING_SIZE.S)}

  ${Icon} {
    ${Icon.mods.size(ICON_SIZE.BASE)}
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
  }
`;

function Option({ icon, title, subtitle, ...rest }) {
  return (
    <Wrapper hasSubtitle={Boolean(subtitle)} {...rest}>
      <IconWrapper $color={color.text(COLOR_PALETTE.NEUTRAL_A40)}>
        {icon}
      </IconWrapper>
      <Title>
        <Body1>{title}</Body1>
        {subtitle && <Body2>{subtitle}</Body2>}
      </Title>
    </Wrapper>
  );
}

export default Option;
