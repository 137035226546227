import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { environment } from '../../../coeffects/environment';

export const CHECKOUT_PAGE_REQUESTED = 'CHECKOUT_PAGE_REQUESTED';
export const CHECKOUT_PAGE_LAST_PENDING_REQUEST_LOADED =
  'CHECKOUT_PAGE_LAST_PENDING_REQUEST_LOADED';
export const CHECKOUT_PAGE_LAST_PENDING_REQUEST_FAILED =
  'CHECKOUT_PAGE_LAST_PENDING_REQUEST_FAILED';

registerEventHandler(
  CHECKOUT_PAGE_REQUESTED,
  ({ environment: { apiUrl } }, { subscriptionChangeRequestId }) => ({
    ...http.get({
      url: `${apiUrl}/checkout/subscription-change-requests/${subscriptionChangeRequestId}/lastPending`,
      successEvent: CHECKOUT_PAGE_LAST_PENDING_REQUEST_LOADED,
      errorEvent: {
        id: CHECKOUT_PAGE_LAST_PENDING_REQUEST_FAILED,
        payload: subscriptionChangeRequestId,
      },
    }),
    ...state.set({ checkoutPage: { loaded: false } }),
  }),
  [environment()]
);

registerEventHandler(
  CHECKOUT_PAGE_LAST_PENDING_REQUEST_LOADED,
  (_, [{ data }]) =>
    state.set({
      checkoutPage: {
        subscriptionChangeRequestId: data,
        loaded: true,
      },
    })
);

registerEventHandler(
  CHECKOUT_PAGE_LAST_PENDING_REQUEST_FAILED,
  (_, [__, subscriptionChangeRequestId]) =>
    state.set({
      checkoutPage: {
        subscriptionChangeRequestId,
        loaded: true,
      },
    })
);
