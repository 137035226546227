import { useMount, useUnmount } from 'react-use';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import { useParams } from 'react-router-dom';
import { EDIT_AD_DATA_REQUESTED, RESET_AD_FORM_VALUES } from './events';
import { adDataSelector } from '../selectors';

import AdForm from './AdForm';

function EditAdForm() {
  const { adId } = useParams();
  useMount(() => dispatch({ id: EDIT_AD_DATA_REQUESTED, payload: { adId } }));
  useUnmount(() => dispatch(RESET_AD_FORM_VALUES));

  const values = useSelector(adDataSelector);
  const isAdFormInitialized = useSelector(
    (state) => state['adForm:isInitialized']
  );

  return isAdFormInitialized && <AdForm values={values} />;
}

export default EditAdForm;
