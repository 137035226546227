import { useCallback } from 'react';
import { dispatch } from 'reffects';
import Dialog from 'design-system/components/Dialog/Dialog';
import {
  FlatButton,
  SecondaryButton,
} from 'design-system/components/Button/presets';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { useDialog } from '../../../../../hooks/useDialog';
import { LEAD_NOTE_DELETED } from '../events';
import { ENQUIRY_DETAIL_DELETE_NOTE_DIALOG } from '../constants';
import useTracking from '../../../../../hooks/useTracking';

export default function ConfirmDeleteNoteDialog() {
  const { open, parameters, closeDialog } = useDialog(
    ENQUIRY_DETAIL_DELETE_NOTE_DIALOG
  );

  const onConfirm = useCallback(() => {
    dispatch({ id: LEAD_NOTE_DELETED, payload: parameters });
    closeDialog();
  }, [parameters, closeDialog]);

  return (
    <Dialog open={open}>
      <Dialog.Header>
        <FormattedMessageWithValidation id="enquiry_detail_delete_note_title" />
      </Dialog.Header>
      <Dialog.Content>
        <Dialog.Text>
          <FormattedMessageWithValidation id="enquiry_detail_delete_note_body" />
        </Dialog.Text>
      </Dialog.Content>
      <Dialog.Footer>
        <FlatButton onClick={closeDialog}>
          <FormattedMessageWithValidation id="enquiry_detail_delete_note_no" />
        </FlatButton>
        <ConfirmationButton onClick={onConfirm} />
      </Dialog.Footer>
    </Dialog>
  );
}

function ConfirmationButton({ onClick }) {
  const trackingRef = useTracking({
    trackClick: {
      eventName: 'note-deleted',
    },
    trackImpression: {
      elementName: 'delete-note-dialog',
    },
    sectionName: 'enquiry-activity-timeline-delete-note-dialog',
  });
  return (
    <SecondaryButton onClick={onClick} ref={trackingRef}>
      <FormattedMessageWithValidation id="enquiry_detail_delete_note_yes" />
    </SecondaryButton>
  );
}
