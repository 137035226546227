import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { DataTable, DataTableProvider } from '../../../../partials/DataTable';
import { ExternalReferenceColumn } from './ExternalReferenceColumn';
import { FieldsColumn } from './FieldsColumn';
import { DetailsColumn } from './DetailsColumn';
import createInvalidPropertiesAdapter from './invalidPropertiesAdapter';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import ClearFiltersButton from '../../../../partials/DataTable/components/filters/ClearFiltersButton';
import { ErrorTypeFilter } from './ErrorTypeFilter';
import ExportOptions from '../ExportOptions/ExportOptions';

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  ${spacing.stack(SPACING_SIZE.L)}
`;

const Filters = styled.div`
  display: flex;

  & > * {
    ${spacing.inline(SPACING_SIZE.S)}
  }
`;

export function InvalidPropertiesTable() {
  const context = {
    collectionName: 'invalidProperties',
    initialItemsPerPage: 10,
    adapter: createInvalidPropertiesAdapter(),
  };
  return (
    <DataTableProvider {...context}>
      <Actions>
        <Filters>
          <ErrorTypeFilter />
          <ClearFiltersButton />
        </Filters>
        <ExportOptions />
      </Actions>
      <DataTable
        noItemsMessage={
          <FormattedMessageWithValidation id="import_stats_invalid_ads_empty_results" />
        }
        allowPagination
      >
        <ExternalReferenceColumn
          id="externalReference"
          title={
            <FormattedMessageWithValidation id="import_stats_invalid_ads_external_reference" />
          }
          source="id"
        />
        <FieldsColumn
          id="errors"
          title={
            <FormattedMessageWithValidation id="import_stats_invalid_ads_fields" />
          }
          source="errors"
        />
        <DetailsColumn
          id="rawError"
          title={
            <FormattedMessageWithValidation id="import_stats_invalid_ads_details" />
          }
          source={{
            id: 'id',
            rawError: 'rawError',
          }}
        />
      </DataTable>
    </DataTableProvider>
  );
}
