import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import Dialog from 'design-system/components/Dialog/Dialog';
import {
  FlatButton,
  SecondaryButton,
} from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import FormattedMessageWithValidation from '../../FormattedMessageWithValidation/FormattedMessageWithValidation';
import ChurnForm from './ChurnForm';
import { CHURN_CANCEL_POLL, CHURN_FINISH_POLL } from './events';
import { churnCanSubmitSelector } from './selector';

const ButtonWrapper = styled.div`
  display: flex;
  gap: ${spacing.value(SPACING_SIZE.M)};
  justify-content: flex-end;
`;

export default function ChurnModalContent({ closeDialog, isFromEmail }) {
  const isSubmitEnabled = useSelector(churnCanSubmitSelector);

  return (
    <>
      <Dialog.Header>
        <FormattedMessageWithValidation id="churn_modal_title" />
      </Dialog.Header>
      <Dialog.Content>
        <ChurnForm />
      </Dialog.Content>
      <Dialog.Footer>
        <ButtonWrapper>
          <FlatButton
            onClick={() => {
              dispatch({ id: CHURN_CANCEL_POLL, payload: { isFromEmail } });
              closeDialog();
            }}
            $size={BUTTON_SIZE.SMALL}
          >
            <FormattedMessageWithValidation id="modal_button_close" />
          </FlatButton>
          <SecondaryButton
            onClick={() => {
              dispatch({ id: CHURN_FINISH_POLL, payload: { isFromEmail } });
              closeDialog();
            }}
            disabled={!isSubmitEnabled}
            $size={BUTTON_SIZE.SMALL}
          >
            <FormattedMessageWithValidation
              id={
                isSubmitEnabled
                  ? 'churn_modal_finish_button'
                  : 'churn_modal_continue_button'
              }
            />
          </SecondaryButton>
        </ButtonWrapper>
      </Dialog.Footer>
    </>
  );
}
