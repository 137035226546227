export default function getDefaultCurrency(countryCode) {
  const currencyByCountry = {
    AR: 'ARS',
    CL: 'CLF',
    CO: 'COP',
    EC: 'USD',
    ES: 'EUR',
    ID: 'IDR',
    MX: 'MXN',
    MY: 'MYR',
    PA: 'USD',
    PE: 'PEN',
    PH: 'PHP',
    TH: 'THB',
    UA: 'AED',
    VN: 'VND',
  };
  return currencyByCountry[countryCode];
}
