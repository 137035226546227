import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { environment } from '../../coeffects/environment';

export const ACCEPTING_TERMS_AND_CONDITIONS = 'ACCEPTING_TERMS_AND_CONDITIONS';
export const TERMS_AND_CONDITIONS_ACCEPTED = 'TERMS_AND_CONDITIONS_ACCEPTED';
export const ACCEPTING_TERMS_AND_CONDITIONS_FAILED =
  'ACCEPTING_TERMS_AND_CONDITIONS_FAILED';
export const TERMS_AND_CONDITIONS_CHECKBOX_CHANGED =
  'TERMS_AND_CONDITIONS_CHECKBOX_CHANGED';

registerEventHandler(
  ACCEPTING_TERMS_AND_CONDITIONS,
  ({ environment: { apiUrl } }) =>
    http.post({
      url: `${apiUrl}/terms-and-conditions`,
      successEvent: TERMS_AND_CONDITIONS_ACCEPTED,
      errorEvent: ACCEPTING_TERMS_AND_CONDITIONS_FAILED,
    }),
  [environment()]
);

registerEventHandler(TERMS_AND_CONDITIONS_ACCEPTED, () =>
  state.set({
    'user.acceptedTermsAndConditions': true,
  })
);

registerEventHandler(ACCEPTING_TERMS_AND_CONDITIONS_FAILED, () =>
  state.set({
    'user.acceptedTermsAndConditions': false,
  })
);

registerEventHandler(
  TERMS_AND_CONDITIONS_CHECKBOX_CHANGED,
  (_, { field, value }) =>
    state.set({
      [field]: value,
    })
);
