import { isColombia, isMexico, isPeru } from '../../../../utils/countries';
import { COUNTRY_CODE } from '../../../../constants/country';

export function isSummaryLoadedSelector(state) {
  const { loading = true, proppit: { products = [] } = {} } =
    summarySelector(state);
  return !loading && products.length > 0;
}

export function summarySelector(state) {
  return state?.summary ?? {};
}

export function checkoutPublisherCountry(state) {
  const countryCode = checkoutPublisher(state)?.countryCode;
  return countryCode !== COUNTRY_CODE.THAILAND ? countryCode ?? {} : {};
}

export function checkoutPublisher(state) {
  return state?.publisher ?? {};
}

export function isEligibleForRoundAllPricesSelector(state) {
  const checkoutCountry = checkoutPublisherCountry(state);

  return (
    isMexico(checkoutCountry) ||
    isColombia(checkoutCountry) ||
    isPeru(checkoutCountry)
  );
}
