import { createSelector } from 'reselect';
import { groupBy, uniqBy } from 'lodash';

const unitsByIdSelector = ({ unitsById }) => unitsById;

export function searchFilterQuerySelector(state) {
  return state['units:suggesterFilterValue'] ?? '';
}

export const unitTableFilteredUnitsSelector = createSelector(
  unitsByIdSelector,
  (unitsById) => Object.values(unitsById)
);

export const unitTableAllSuggestionsSelector = createSelector(
  unitTableFilteredUnitsSelector,
  (filteredUnits) => {
    const allUnitsSuggestions = filteredUnits.flatMap(
      ({ referenceId, project }) =>
        [
          referenceId && {
            text: referenceId,
            searchText: referenceId.toLowerCase(),
            type: 'referenceId',
          },
          project.address && {
            text: project.address,
            searchText: project.address.toLowerCase(),
            type: 'address',
          },
          project.name && {
            text: project.name,
            searchText: project.name.toLowerCase(),
            type: 'projectName',
          },
        ].filter(Boolean)
    );

    return uniqBy(
      allUnitsSuggestions,
      ({ type, searchText }) => `${type}_${searchText}`
    );
  }
);

export const unitTableSuggestionsSelector = createSelector(
  unitTableAllSuggestionsSelector,
  (_, query) => query,
  (allSuggestions, query) => {
    const normalizedQuery = query.toLowerCase();
    const foundSuggestions = allSuggestions.filter(({ searchText }) =>
      searchText.includes(normalizedQuery)
    );

    return sortAndTrimSuggestionsByType(foundSuggestions);
  }
);

function sortAndTrimSuggestionsByType(suggestions) {
  const suggestionsByType = groupBy(suggestions, ({ type }) => type);

  return Object.values(suggestionsByType).flatMap((suggestionsOfType) =>
    suggestionsOfType.sort(bySuggestionTextASC).slice(0, 2)
  );
}

function bySuggestionTextASC({ text: textA }, { text: textB }) {
  const unspacedTextA = textA.replace(/\b/, '');
  const unspacedTextB = textB.replace(/\b/, '');

  return unspacedTextA.localeCompare(unspacedTextB);
}
