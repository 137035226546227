import { get, isObject } from 'lodash';
import { useSelector } from 'reffects-store';
import { useCallback, useMemo } from 'react';
import { dispatch } from 'reffects';
import { useDatatableContext } from './useDatatableContext';
import { itemByIdSelector } from '../selectors/item';

export function useColumn({ itemId, source }) {
  const context = useDatatableContext();

  const item = useSelector((state) =>
    itemByIdSelector(state, { collectionName: context.collectionName, itemId })
  );

  const value = useMemo(() => {
    if (isObject(source)) {
      return mapItemPropertiesToSourceObject(item, source);
    }

    return get(item, source, item);
  }, [item, source]);

  const updateItem = useCallback(
    (itemPatch) => {
      dispatch({
        id: context.updateEvents.item,
        payload: {
          ...context,
          item: {
            id: itemId,
            ...itemPatch,
          },
        },
      });
    },
    [itemId, context.updateEvents.item]
  );

  return {
    itemId,
    value,
    updateItem,
  };
}

function mapItemPropertiesToSourceObject(item, source) {
  return Object.entries(source).reduce((acc, [sourceKey, itemKey]) => {
    const sourceValue = get(item, itemKey);

    if (sourceValue == null) return acc;

    return {
      ...acc,
      [sourceKey]: sourceValue,
    };
  }, {});
}
