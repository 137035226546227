import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { divider, DIVIDER_SIZE } from 'design-system/styles/divider';

const Wrapper = styled.tr`
  ${divider.bottom(DIVIDER_SIZE.THIN)}

  :last-of-type {
    ${divider.bottom(DIVIDER_SIZE.NONE)}
  }
`;

const Cell = styled.td`
  :first-of-type {
    text-align: left;
  }

  :last-of-type {
    text-align: right;
  }

  ${spacing.insetSandwich(SPACING_SIZE.S)}
`;

function BaseRow({ leftChild, rightChild, ...rest }) {
  return (
    <Wrapper {...rest}>
      <Cell>{leftChild}</Cell>
      <Cell>{rightChild}</Cell>
    </Wrapper>
  );
}

export default styled(BaseRow)``;
