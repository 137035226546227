import Icon from 'design-system/components/Icon';
import Section from './Section';
import Subtitle from './Subtitle';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import AmenityGrid from './AmenityGrid/AmenityGrid';
import { getAmenityIconName } from '../../../../../utils/ad';
import { amenitiesTranslationTags } from '../../../../../constants/ad';

function Amenities({ amenities }) {
  if (amenities.length === 0) {
    return null;
  }
  return (
    <Section>
      <Subtitle>
        <FormattedMessageWithValidation id="newprop_amenities_header" />
      </Subtitle>
      <AmenityGrid>
        {amenities.map((amenity) => (
          <AmenityGrid.Item
            key={amenity}
            icon={<Icon glyph={getAmenityIconName(amenity)} />}
          >
            <FormattedMessageWithValidation
              id={amenitiesTranslationTags.get(amenity)}
            />
          </AmenityGrid.Item>
        ))}
      </AmenityGrid>
    </Section>
  );
}

export default Amenities;
