import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { environment } from '../../../../coeffects/environment';
import { toast } from '../../../../effects/toast';
import { CLOSE_DIALOG } from '../../../../events/dialogs';

export const PROJECT_NAME_SELECTED = 'PROJECT_NAME_SELECTED';
export const ASSIGN_PROJECT_TO_DEVELOPER_SUBMITTED =
  'ASSIGN_PROJECT_TO_DEVELOPER_SUBMITTED';
export const ASSIGN_PROJECT_TO_DEVELOPER_CLOSE =
  'ASSIGN_PROJECT_TO_DEVELOPER_CLOSE';

export const ASSIGN_PROJECT_TO_DEVELOPER_DIALOG =
  'ASSIGN_PROJECT_TO_DEVELOPER_DIALOG';

registerEventHandler(
  PROJECT_NAME_SELECTED,
  (_, { project: { id, name, latitude, longitude, address } }) =>
    state.set({
      'developersAdmin.selectedProject': {
        id,
        name,
        latitude,
        longitude,
        address,
      },
    })
);

registerEventHandler(
  ASSIGN_PROJECT_TO_DEVELOPER_SUBMITTED,
  ({ state: { project }, environment: { apiUrl } }, { developer }) =>
    http.put({
      url: `${apiUrl}/admin/project/${project.id}`,
      body: { developerId: developer.id },
      successEvent: {
        id: ASSIGN_PROJECT_TO_DEVELOPER_CLOSE,
        payload: `"${project.name}" assigned successfully to "${developer.name}"`,
      },
      errorEvent: {
        id: ASSIGN_PROJECT_TO_DEVELOPER_CLOSE,
        payload: `Error assigning "${project.name}" to "${developer.name}"`,
      },
    }),
  [
    state.get({
      project: 'developersAdmin.selectedProject',
    }),
    environment(),
  ]
);

registerEventHandler(ASSIGN_PROJECT_TO_DEVELOPER_CLOSE, (_, [, message]) => ({
  ...state.set({
    'developersAdmin.projectSearchText': undefined,
    'developersAdmin.selectedProject': undefined,
  }),
  ...toast.show({
    text: message,
    milliseconds: 6000,
  }),
  ...effects.dispatch(CLOSE_DIALOG),
}));
