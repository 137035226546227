import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import useTableState from '../../../partials/DataTable/hooks/useTableState';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { numberOfLeadsSelector, numberOfProjectsSelector } from './selectors';
import { useDataTable } from '../../../partials/DataTable/hooks/useDataTable';
import Kpi from '../../../partials/Kpi';
import Amount from '../../Overview/partials/LeadsSummary/Amount';
import { enquiriesRoute } from '../../../utils/proppitWebRouter';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  ${spacing.stack(SPACING_SIZE.XL)}
  > * {
    ${spacing.inline(SPACING_SIZE.L)}
  }
`;

export function Kpis() {
  const { isLoading } = useDataTable();
  const projectsCplTableState = useTableState();
  const numberOfProjects = numberOfProjectsSelector(projectsCplTableState);
  const numberOfLeads = numberOfLeadsSelector(projectsCplTableState);

  if (isLoading) return null;

  return (
    <Wrapper>
      <Kpi
        amount={<Amount value={numberOfProjects} />}
        message={
          <FormattedMessageWithValidation id="subscription_cpl_kpi_projects" />
        }
      />
      <Kpi
        amount={<Amount value={numberOfLeads} />}
        message={
          <FormattedMessageWithValidation id="subscription_cpl_kpi_leads" />
        }
        linkTo={numberOfLeads > 0 && enquiriesRoute()}
      />
    </Wrapper>
  );
}
