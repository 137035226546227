import { Pie, PieChart } from 'recharts';
import { COLOR_PALETTE } from 'design-system/styles/color';

export default function PercentageGraph({ percentage }) {
  return (
    <PieChart width={50} height={50}>
      <Pie
        stroke="none"
        style={{ outline: 'none' }}
        data={[
          {
            value: percentage,
            fill: COLOR_PALETTE.NEUTRAL_A00,
          },
          {
            value: 100 - percentage,
            fill: COLOR_PALETTE.PRIMARY_A60,
          },
        ]}
        cx={20}
        cy={20}
        innerRadius={16}
        outerRadius={24}
        startAngle={90}
        endAngle={-360}
        dataKey="value"
      />
    </PieChart>
  );
}
