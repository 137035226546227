import Layout from 'design-system/components/Layout';
import { useMount } from 'react-use';
import { dispatch } from 'reffects';
import Grid from 'design-system/components/Grid';
import { Navigation } from '../../partials';
import ContactDetailsSection from './ContactDetailsSection';
import Hero from './Hero/Hero';
import { PublishInSitesTip } from './PublishInSitesTip';
import EditPublisherModal from './EditPublisherModal/EditPublisherModal';
import DescriptionSection from './DescriptionSection/DescriptionSection';
import { INIT_PUBLISHER_DEFAULT_SELECTED_LOCALE } from './events';

function PublisherProfile() {
  useMount(() => dispatch(INIT_PUBLISHER_DEFAULT_SELECTED_LOCALE));

  return (
    <Layout>
      <Layout.Header>
        <Navigation />
      </Layout.Header>
      <Layout.Content>
        <Layout.Padded noVerticalSpacing>
          <Grid
            areas={({ repeat }) => `
              ". . ${repeat(8, 'hero')} . ."
              ". . ${repeat(2, 'tip')} ${repeat(5, 'sections')} . . ."
            `}
          >
            <Grid.Area $id="hero">
              <Hero />
            </Grid.Area>
            <Grid.Area $id="sections">
              <DescriptionSection />
              <ContactDetailsSection />
            </Grid.Area>
            <Grid.Area $id="tip">
              <PublishInSitesTip />
            </Grid.Area>
          </Grid>
        </Layout.Padded>
        <EditPublisherModal />
      </Layout.Content>
    </Layout>
  );
}

export default PublisherProfile;
