import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Layout from 'design-system/components/Layout';
import styled from 'styled-components';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import { useMount } from 'react-use';
import Grid from 'design-system/components/Grid';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import SubscriptionLayout from '../partials/Layout/Layout';
import Header from '../partials/Layout/Header';
import DeveloperBenefitsTable from './DeveloperBenefitsTable';
import { DEVELOPER_BENEFITS_INITIALIZED } from './events';
import { hasPromotedProjectsSelector } from './selectors';

const Content = styled.div`
  grid-column: 3 / span 8;

  > * {
    ${spacing.stack(SPACING_SIZE.M)}
  }
`;

export default function DeveloperBenefits() {
  useMount(() => dispatch(DEVELOPER_BENEFITS_INITIALIZED));
  const hasPromotedProjects = useSelector(hasPromotedProjectsSelector);

  return (
    <SubscriptionLayout>
      <Layout.Padded>
        <Grid>
          <Content>
            <Header>
              <FormattedMessageWithValidation id="subscription_developer_title" />
            </Header>
            <DeveloperBenefitsTable hasPromotedProjects={hasPromotedProjects} />
          </Content>
        </Grid>
      </Layout.Padded>
    </SubscriptionLayout>
  );
}
