import styled from 'styled-components';
import Badge, {
  BADGE_SIZE,
  BADGE_VARIANT,
} from 'design-system/components/Badge/Badge';
import Icon from 'design-system/components/Icon';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Heading2 } from 'design-system/components/Typography/presets/Heading2';
import Typography from 'design-system/components/Typography/Typography';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 154px;

  ${spacing.insetSquish(SPACING_SIZE.M)}
  ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
    ${radius.regular(RADIUS_SIZE.LARGE)}
    > * {
    ${spacing.stack(SPACING_SIZE.S)}
  }

  ${Heading2} {
    ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
  }

  ${Badge} {
    ${Badge.mods.variant(BADGE_VARIANT.PRIMARY)}
    ${Badge.mods.size(BADGE_SIZE.MEDIUM)}
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;

  ${Typography} {
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    ${({ $color }) => color.text($color)};
  }

  > * {
    ${spacing.inline(SPACING_SIZE.S)}
  }
`;

const ExtraInfoIcon = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 9px;
  ${color.background(COLOR_PALETTE.GOLD_BASE)}
  ${color.text(COLOR_PALETTE.GOLD_A120)}
    ${radius.pill()}
`;

export default function KpiCard({
  aboveThreshold,
  count,
  glyph,
  text,
  $color,
}) {
  if (!aboveThreshold) {
    return null;
  }

  const getIcon = () => {
    if (glyph === 'leadPlus') {
      return <ExtraInfoIcon>L+</ExtraInfoIcon>;
    }

    if (glyph === 'qualification') {
      return <ExtraInfoIcon>Q+</ExtraInfoIcon>;
    }

    return <Badge icon={<Icon glyph={glyph} />} />;
  };

  return (
    <Wrapper>
      <Heading2>{count}</Heading2>
      <Content $color={$color}>
        {getIcon()}
        <Typography>{text}</Typography>
      </Content>
    </Wrapper>
  );
}
