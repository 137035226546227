import { dispatchMany } from 'reffects';
import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import { FormattedNumber } from 'react-intl';
import DropdownMenu from 'design-system/components/DropdownMenu';
import { DROPDOWN_POSITION } from 'design-system/components/Dropdown';
import { OutlineButton } from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Overline } from 'design-system/components/Typography/presets/Overline';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import Kpi from '../../../../partials/Kpi';
import ImpressionsChart from './ImpressionsChart';
import {
  isPropertiesSummaryLoading,
  kpisCardsDataSelector,
  publisherHasAnyCreatedAdSelector,
  selectedDateRangeSelector,
} from '../../selectors';
import { KPIS_DATA_REQUESTED } from '../../events';
import FilterDatepicker from '../../../../partials/FilterDatepicker';
import StatisticsDisclaimer from './StatisticsDisclaimer';
import { TRACK_CLICK } from '../../../../partials/Tracking/events';
import CustomFormattedDate from '../../../../partials/FormattedDate/CustomFormattedDate';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  ${spacing.stack(SPACING_SIZE.M, false)}
  ${Overline} {
    ${spacing.inline(SPACING_SIZE.M)}
  }
`;

const Content = styled.div`
  > * {
    ${spacing.stack(SPACING_SIZE.L)}
  }
`;

function DeveloperKeyResults() {
  const kpis = useSelector(kpisCardsDataSelector);
  const selectedDate = useSelector(selectedDateRangeSelector);
  const isLoading = useSelector(isPropertiesSummaryLoading);
  const hasAnyPropertyCreated = useSelector(publisherHasAnyCreatedAdSelector);

  return (
    <Wrapper>
      <Header>
        <Overline $ellipse>
          <FormattedMessageWithValidation id="developer_overview_key_results_section_title" />
        </Overline>
        <DropdownMenu
          position={DROPDOWN_POSITION.BOTTOM_LEFT}
          button={
            <OutlineButton icon={<Icon glyph="chevronDown" />}>
              {selectedDate.translation ? (
                <FormattedMessageWithValidation id={selectedDate.translation} />
              ) : (
                <>
                  <CustomFormattedDate value={selectedDate.period.startDate} />
                  {' - '}
                  <CustomFormattedDate value={selectedDate.period.endDate} />
                </>
              )}
            </OutlineButton>
          }
          onConfirm={requestKpisData}
        >
          <FilterDatepicker
            start={selectedDate?.period?.startDate}
            end={selectedDate?.period?.endDate}
            maxDate={new Date()}
          />
        </DropdownMenu>
      </Header>
      <Content>
        {kpis && (
          <Kpi
            amount={<FormattedNumber value={kpis.impressions} />}
            message={
              <FormattedMessageWithValidation id="developer_overview_kpis_views" />
            }
          />
        )}
        <ImpressionsChart $height="240px" />
        {kpis && (
          <Kpi
            amount={<FormattedNumber value={kpis.visits} />}
            message={
              <FormattedMessageWithValidation id="developer_overview_kpis_visits" />
            }
          />
        )}
        {!isLoading && !hasAnyPropertyCreated && <StatisticsDisclaimer />}
      </Content>
    </Wrapper>
  );
}

function requestKpisData({ startDate, endDate }) {
  const format = (date) =>
    `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  dispatchMany([
    {
      id: KPIS_DATA_REQUESTED,
      payload: {
        period: {
          startDate,
          endDate,
        },
      },
    },
    {
      id: TRACK_CLICK,
      payload: {
        eventName: 'filter-unit-kpis',
        sectionName: 'overview-units-kpis',
        payload: { period: { from: format(startDate), to: format(endDate) } },
      },
    },
  ]);
}

export default DeveloperKeyResults;
