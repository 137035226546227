export const typography = {
  font: {
    primary: {
      family:
        'Inter, -apple-system, BlinkMacSystemFont, "Apple Color Emoji", "Helvetica", "Helvetica Neue", "Arial", sans-serif;',
    },
  },
  weight: {
    light: '300',
    normal: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
  },
  size: {
    xs: 10,
    s: 12,
    m: 14,
    l: 16,
    xl: 18,
  },
};
