import styled, { css } from 'styled-components';
import Badge, {
  BADGE_SIZE,
  BADGE_VARIANT,
} from 'design-system/components/Badge/Badge';
import Icon from 'design-system/components/Icon';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import Typography, {
  TYPOGRAPHY_VARIANT,
} from 'design-system/components/Typography/Typography';

export const STEPPER_ITEM_STATUS = {
  COMPLETED: Symbol('STEPPER_ITEM_STATUS_COMPLETED'),
  ERROR: Symbol('STEPPER_ITEM_STATUS_ERROR'),
  CURRENT: Symbol('STEPPER_ITEM_STATUS_CURRENT'),
};

const Wrapper = styled.ol`
  display: inline-flex;
  counter-reset: stepper;
  list-style-type: none;
  flex-direction: column;

  margin: 0;
  padding: 0;
`;

const SubItem = styled.li`
  margin-left: ${spacing.value(SPACING_SIZE.L)};
  ${spacing.stack(SPACING_SIZE.M, false)}
`;

const ItemBullet = styled(Badge)`
  ${Badge.mods.variant(BADGE_VARIANT.OUTLINE_BASE)}
  ${Badge.mods.size(BADGE_SIZE.LARGE)}
  ${spacing.inline(SPACING_SIZE.S)}
  ${Typography.mods.variant(TYPOGRAPHY_VARIANT.SUBTITLE_3)}

  ::before {
    content: counters(stepper, '.') ' ';
  }
`;

const modCompletedStatus = () => css`
  ${ItemBullet} {
    ${Badge.mods.variant(BADGE_VARIANT.PRIMARY)}

    ::before {
      content: initial;
    }
  }
`;

const modErrorStatus = () => css`
  ${ItemBullet} {
    ${Badge.mods.variant(BADGE_VARIANT.ERROR)}

    ::before {
      content: initial;
    }
  }
`;

const modCurrentStatus = () => css`
  ${ItemBullet} {
    ${Badge.mods.variant(BADGE_VARIANT.OUTLINE_SECONDARY)}
  }
`;

const modStatus = (statusName) =>
  ({
    [STEPPER_ITEM_STATUS.COMPLETED]: modCompletedStatus,
    [STEPPER_ITEM_STATUS.ERROR]: modErrorStatus,
    [STEPPER_ITEM_STATUS.CURRENT]: modCurrentStatus,
  }[statusName]);

const ItemWrapper = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  counter-increment: stepper;
  cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'default')};

  ::before {
    content: '';
    width: 1px;
    height: ${spacing.value(SPACING_SIZE.M)};
    position: absolute;
    left: 12px;
    top: 0;
    transform: translate(-50%, -100%);

    ${color.background(COLOR_PALETTE.EGGPLANT_A10)}
  }

  :first-of-type::before,
  ${SubItem} + &::before {
    content: initial;
  }

  ${spacing.stack(SPACING_SIZE.M)}

  ${({ status }) => status && modStatus(status)}
`;

const iconByStatusMap = new Map([
  [STEPPER_ITEM_STATUS.COMPLETED, <Icon glyph="check" />],
  [STEPPER_ITEM_STATUS.ERROR, <Icon glyph="error" />],
]);

function Item({ children, ...rest }) {
  const { status } = rest;

  return (
    <ItemWrapper {...rest}>
      <ItemBullet icon={iconByStatusMap.get(status)} />
      <Subtitle3>{children}</Subtitle3>
    </ItemWrapper>
  );
}

function Stepper({ children, ...rest }) {
  return <Wrapper {...rest}>{children}</Wrapper>;
}

Stepper.Item = Item;
Stepper.SubItem = SubItem;

export default styled(Stepper)``;
