import { Id, IdMark } from './Id';
import {
  BodyWrapper,
  By,
  Details,
  Footer,
  List,
  Paragraph,
  RefList,
  Title,
} from './ItemComponents';

export function PaymentRequest({ event }) {
  let title = 'Payment';
  let description = '...';
  let isFirst = false;
  const bullets = [];
  const refs = [];

  bullets.push(
    <li>
      Method: <b>{event.paymentRequest.paymentMethod}</b>
    </li>
  );
  bullets.push(
    <li>
      Amount:{' '}
      <b>
        {event.paymentRequest.price.amount}{' '}
        {event.paymentRequest.price.currency}
      </b>
    </li>
  );

  switch (event.typeDetailed) {
    case 'PAYMENT_REQUEST_CREATED':
      isFirst = true;
      title = 'Payment Request New';

      description = 'New payment prepared.';
      break;

    case 'PAYMENT_REQUEST_UPDATED':
      title = 'Payment Request updated';
      description = (
        <>
          Payment status has been updated: <b>{event.paymentRequest.status}</b>
        </>
      );
      break;
    default:
      break;
  }

  if (event.paymentRequest.scrId) {
    refs.push(
      <li>
        For SCR: <Id id={event.paymentRequest.scrId} />
      </li>
    );
  }

  return (
    <BodyWrapper>
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
      {isFirst && <a id={event.id} />}
      <Title id={event.paymentRequest.id}>{title}</Title>
      <Details>
        <Paragraph>{description}</Paragraph>
        <List>{bullets}</List>
      </Details>
      <Footer>
        <By event={event} />
        <IdMark id={event.id} />
        <RefList>{refs}</RefList>
      </Footer>
    </BodyWrapper>
  );
}
