import styled from 'styled-components';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import Price from '../../../../partials/Price/Price';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border: solid 1px ${COLOR_PALETTE.NEUTRAL_A10};
  margin-top: ${spacing.value(SPACING_SIZE.L)};

  ${radius.regular(RADIUS_SIZE.BASE)} ${spacing.inset(SPACING_SIZE.M)};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${Subtitle3} {
    display: block;
    ${spacing.stack(SPACING_SIZE.S)}
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  ${spacing.stack(SPACING_SIZE.L, false)}
  ${Heading1} {
    ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
    &:last-of-type {
      ${typography.weight(FONT_WEIGHT.NORMAL)}
    }
    &:first-of-type {
      ${typography.weight(FONT_WEIGHT.SEMIBOLD)}
    }
  }
  ${Body1} {
    ${color.text(COLOR_PALETTE.NEUTRAL_A80)}
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;

  ${Column} {
    ${spacing.stack(SPACING_SIZE.M, false)}
  }
`;

export const TwoColumns = styled.div`
  display: flex;
  flex-direction: row;
  > ${Column} {
    flex: 1 1 0;
  }
`;

export const PriceSection = styled.div`
  ${Heading1} {
    display: flex;

    ${Price} {
      ${spacing.inline(SPACING_SIZE.S, false)}
    }
  }
`;
