import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import { environment } from '../../../../coeffects/environment';

export const AUTOCOMPLETE_PROJECT_NAME_TEXT_INTRODUCED =
  'AUTOCOMPLETE_PROJECT_NAME_TEXT_INTRODUCED';
export const AUTOCOMPLETE_PROJECT_NAME_SUGGESTIONS_OBTAINED =
  'AUTOCOMPLETE_PROJECT_NAME_SUGGESTIONS_OBTAINED';
export const AUTOCOMPLETE_PROJECT_NAME_SUGGESTIONS_RETRIEVAL_FAILED =
  'AUTOCOMPLETE_PROJECT_NAME_SUGGESTIONS_RETRIEVAL_FAILED';
export const PROJECT_NAME_AUTOCOMPLETE_BLURRED =
  'PROJECT_NAME_AUTOCOMPLETE_BLURRED';
export const ADMIN_PROJECT_NAME_AUTOCOMPLETE_CLEARED =
  'ADMIN_PROJECT_NAME_AUTOCOMPLETE_CLEARED';

const MAX_SUGGESTIONS_NUMBER = 7;

registerEventHandler(
  AUTOCOMPLETE_PROJECT_NAME_TEXT_INTRODUCED,
  ({ environment: { apiUrl } }, { searchText, publisherCountry }) => {
    const encodedProjectName = encodeURIComponent(searchText);

    return {
      ...state.set({
        'developersAdmin.projectSearchText': searchText,
        'developersAdmin.projectSuggestionLoading': true,
      }),
      ...http.get({
        url: `${apiUrl}/admin/projects/suggestions?query=${encodedProjectName}&country=${publisherCountry}`,
        successEvent: AUTOCOMPLETE_PROJECT_NAME_SUGGESTIONS_OBTAINED,
        errorEvent: AUTOCOMPLETE_PROJECT_NAME_SUGGESTIONS_RETRIEVAL_FAILED,
      }),
    };
  },
  [environment()]
);

registerEventHandler(
  AUTOCOMPLETE_PROJECT_NAME_SUGGESTIONS_OBTAINED,
  (_, [{ data }]) =>
    state.set({
      'developersAdmin.projectSuggestions': data.slice(
        0,
        MAX_SUGGESTIONS_NUMBER
      ),
      'developersAdmin.projectSuggestionLoading': false,
    })
);

registerEventHandler(
  AUTOCOMPLETE_PROJECT_NAME_SUGGESTIONS_RETRIEVAL_FAILED,
  () =>
    state.set({
      'developersAdmin.projectSuggestionLoading': false,
    })
);

registerEventHandler(PROJECT_NAME_AUTOCOMPLETE_BLURRED, () =>
  state.set({
    'developersAdmin.projectSuggestions': undefined,
    'developersAdmin.projectSearchText': undefined,
  })
);
registerEventHandler(ADMIN_PROJECT_NAME_AUTOCOMPLETE_CLEARED, () =>
  state.set({
    'developersAdmin.projectSearchText': undefined,
    'developersAdmin.selectedProject': undefined,
  })
);
