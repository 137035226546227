import { useParams } from 'react-router-dom';
import { useMount } from 'react-use';
import { dispatchMany } from 'reffects';
import { useSelector } from 'reffects-store';
import { CHECKOUT_PAGE_REQUESTED } from './events';
import { checkoutPageLoadingSelector } from './selectors';
import { CheckSelfService } from '../CheckSelfService';

export default function CheckoutPageLoader({ ...props }) {
  const { page: CheckoutPage } = props;
  const { subscriptionChangeRequestId } = useParams();

  const loaded = useSelector(checkoutPageLoadingSelector);
  useMount(() => {
    dispatchMany([
      {
        id: CHECKOUT_PAGE_REQUESTED,
        payload: { subscriptionChangeRequestId },
      },
    ]);
  });

  if (!loaded) {
    return null;
  }

  return (
    <CheckSelfService>
      <CheckoutPage {...props} />
    </CheckSelfService>
  );
}
