import styled from 'styled-components';
import { useCallback, useState } from 'react';
import Dialog from 'design-system/components/Dialog/Dialog';
import { useDialog } from '../../../../../../hooks/useDialog';
import { QR_MODAL } from './constants';
import ContactQr from '../../../ContactQr';
import FormattedMessageWithValidation from '../../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import useTracking from '../../../../../../hooks/useTracking';

const Content = styled(Dialog.Content)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function QrModal() {
  const { open, parameters, closeDialog } = useDialog(QR_MODAL);
  const [linkElement, setLinkElement] = useState(null);
  const ref = useCallback((node) => {
    if (node != null && linkElement !== node) setLinkElement(node);
  }, []);
  useTracking({
    ref: linkElement,
    sectionName: 'lead-detail-contact',
    trackImpression: { elementName: 'lead-detail-contact-qr' },
  });
  return (
    <Dialog open={open} fitContent>
      <Dialog.Header onClose={closeDialog}>
        <FormattedMessageWithValidation id="enquiry_detail_lead_contact_scan_qr" />
      </Dialog.Header>
      <Content>
        <ContactQr ref={ref} contact={parameters} />
      </Content>
      <Dialog.Footer />
    </Dialog>
  );
}
