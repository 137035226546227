import { get } from 'lodash';

export function numberOfProjectsSelector(tableState) {
  const { collection } = tableState;
  return collection?.length ?? 0;
}

export function numberOfLeadsSelector(tableState) {
  const { collection = [] } = tableState;
  return extractOperationFieldSum(collection, 'leads');
}

function extractOperationFieldSum(projects, field) {
  return projects
    .flatMap(({ operations }) =>
      Object.values(operations).map((operation) => get(operation, field, 0))
    )
    .reduce((a, b) => a + b, 0);
}
