import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Typography, {
  TYPOGRAPHY_VARIANT,
} from 'design-system/components/Typography/Typography';
import {
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import Tag, { TAG_VARIANT } from '../Tag';
import Cell, { CELL_ALIGNMENT } from './Cell';

const Wrapper = styled(Cell)`
  ${Cell.mods.alignment(CELL_ALIGNMENT.CENTER)}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${spacing.insetSandwich(SPACING_SIZE.S)}
`;

const Discount = styled(Tag)`
  ${Tag.mods.variant(TAG_VARIANT.PRIMARY)}
  ${spacing.stack(SPACING_SIZE.S)}
`;

const NoDiscount = styled(Discount)`
  visibility: hidden;
`;

const Name = styled(Typography)`
  ${Typography.mods.variant(TYPOGRAPHY_VARIANT.HEADING_2)}
  ${typography.weight(FONT_WEIGHT.MEDIUM)}
  ${spacing.stack(SPACING_SIZE.S)}
  ${color.text(COLOR_PALETTE.SECONDARY_BASE)}
`;

const Amount = styled.div`
  display: flex;
`;

const Currency = styled(Typography)`
  ${typography.size(FONT_SIZE.L)}
  ${typography.weight(FONT_WEIGHT.MEDIUM)}
  ${typography.lineHeight(26)}
  margin-right: ${SPACING_SIZE.XS}px;
`;

const AmountInteger = styled(Typography)`
  ${typography.size(32)}
  ${typography.weight(FONT_WEIGHT.SEMIBOLD)}
  ${typography.lineHeight(38)}
`;

const AmountFraction = styled(Typography)`
  ${typography.size(FONT_SIZE.L)}
  ${typography.weight(FONT_WEIGHT.MEDIUM)}
  ${typography.lineHeight(26)}
`;

const TaxCaption = styled(Typography)`
  ${color.text(COLOR_PALETTE.NEUTRAL_A80)}
  ${typography.size(FONT_SIZE.S)}
  ${typography.weight(FONT_WEIGHT.LIGHT)}
  ${spacing.stack(SPACING_SIZE.M)}
`;

const CallToActionContainer = styled.div`
  ${spacing.stack(SPACING_SIZE.S, false)}
`;

function HeadingCell({
  name,
  priceParts,
  taxCaption,
  discountCaption,
  callToAction,
  ...rest
}) {
  const amountInteger = composeParts(priceParts, ['integer', 'group']);
  const amountFraction = composeParts(priceParts, ['decimal', 'fraction']);
  const currency = composeParts(priceParts, ['currency']);

  return (
    <Wrapper {...rest}>
      <Content>
        {discountCaption ? (
          <Discount>{discountCaption}</Discount>
        ) : (
          <NoDiscount>-</NoDiscount>
        )}
        <Name>{name}</Name>
        {priceParts && (
          <Amount>
            <Currency>{currency}</Currency>
            <AmountInteger>{amountInteger}</AmountInteger>
            <AmountFraction>{amountFraction}</AmountFraction>
          </Amount>
        )}
        {taxCaption && <TaxCaption>{taxCaption}</TaxCaption>}
        <CallToActionContainer>{callToAction}</CallToActionContainer>
      </Content>
    </Wrapper>
  );
}

export default styled(HeadingCell)``;

function composeParts(parts, partsToCompose) {
  return (parts ?? []).reduce((acc, { type, value }) => {
    if (partsToCompose.includes(type)) {
      // eslint-disable-next-line no-param-reassign
      acc += value;
      return acc;
    }

    return acc;
  }, '');
}
