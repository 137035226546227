import { coeffect, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';

export const DEVELOPER_BENEFITS_INITIALIZED = 'DEVELOPER_BENEFITS_INITIALIZED';
export const DEVELOPER_BENEFITS_INITIALIZED_SUCCESS =
  'DEVELOPER_BENEFITS_INITIALIZED_SUCCESS';
export const DEVELOPER_BENEFITS_INITIALIZED_FAILED =
  'DEVELOPER_BENEFITS_INITIALIZED_FAILED';

registerEventHandler(
  DEVELOPER_BENEFITS_INITIALIZED,
  ({ environment: { apiUrl } }) =>
    http.get({
      url: `${apiUrl}/developers/me/promoted-projects`,
      successEvent: [DEVELOPER_BENEFITS_INITIALIZED_SUCCESS],
      errorEvent: [DEVELOPER_BENEFITS_INITIALIZED_FAILED],
    }),
  [coeffect('environment')]
);

registerEventHandler(
  DEVELOPER_BENEFITS_INITIALIZED_SUCCESS,
  (_, [response]) => {
    const hasPromotedProjects = response.data.length !== 0;

    return state.merge({
      publisher: {
        hasPromotedProjects,
      },
    });
  }
);

registerEventHandler(DEVELOPER_BENEFITS_INITIALIZED_FAILED, () =>
  state.merge({
    publisher: {
      hasPromotedProjects: undefined,
    },
  })
);
