import { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';

function HiddenField({ name, value }) {
  const { control } = useFormContext();
  const {
    field: { onChange },
  } = useController({
    name,
    control,
  });

  useEffect(() => {
    onChange(value);
  }, [value]);

  return null;
}

export default HiddenField;
