import { BILLING_CYCLES } from '../../constants/subscription';
import { NO_FILTER_OPTION } from '../pages/partials/Filters/constants';

export function billingCycleOptions() {
  return BILLING_CYCLES.map((billingCycle) => [
    billingCycle,
    billingCycle === 1 ? `${billingCycle} month` : `${billingCycle} months`,
  ]);
}

export function billingCycleFilterOptions() {
  return [
    { id: NO_FILTER_OPTION, value: null, label: 'All' },
    ...billingCycleOptions().map(([value, label]) => ({
      id: value,
      value: `${value}`,
      label,
    })),
  ];
}
