import { useSelector } from 'reffects-store';
import { useDatatableContext } from './useDatatableContext';
import {
  hasNoItemsSelector,
  isEmptySelector,
  isLoadingSelector,
} from '../selectors/loading';

export function useDataTable(collectionName) {
  const context = {
    collectionName: collectionName ?? useDatatableContext().collectionName,
  };
  const withContext = (f) => (state) => f(state, context);
  return {
    isLoading: useSelector(withContext(isLoadingSelector)),
    isEmpty: useSelector(withContext(isEmptySelector)),
    hasNoItems: useSelector(withContext(hasNoItemsSelector)),
  };
}
