import { Link } from 'react-router-dom';
import { PrimaryButton } from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import Illustration from 'design-system/components/Illustration/Illustration';
import ActionCard from '../../../../partials/ActionCard';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { NEW_UNIT } from '../../../../constants/routes';
import useTracking from '../../../../hooks/useTracking';

export function PublishUnitCard() {
  const trackClickRef = useTracking({
    trackClick: {
      eventName: 'create-new-unit',
    },
    trackImpression: {
      elementName: 'new-unit-btn',
    },
    sectionName: 'overview-publish-unit-card',
  });
  return (
    <ActionCard
      illustration={<Illustration name="newProperty" />}
      title={
        <FormattedMessageWithValidation id="developer_publish_more_units_title" />
      }
      cta={
        <PrimaryButton
          $size={BUTTON_SIZE.SMALL}
          forwardedAs={Link}
          to={NEW_UNIT}
          ref={trackClickRef}
        >
          <FormattedMessageWithValidation id="developer_publish_more_units_button" />
        </PrimaryButton>
      }
    />
  );
}
