import { useParams } from 'react-router-dom';
import { ROUTE_TYPE, RouteProvider } from './context';
import TrackPageView from '../../partials/Tracking/TrackPageView';
import RouteWithInterstitial from './RouteWithInterstitial';
import { retrieveQueryParams } from '../../coeffects/queryParamsAll';

function PublicRoute({ as: Component, disableInterstitial, ...props }) {
  const { pageViewName, pageViewPayloadSelector } = props;
  const params = useParams();
  const { queryParamsAll } = retrieveQueryParams(window);

  return (
    <RouteProvider routeType={ROUTE_TYPE.PUBLIC}>
      <RouteWithInterstitial disable={disableInterstitial}>
        <TrackPageView
          pageViewName={pageViewName}
          payload={
            pageViewPayloadSelector &&
            pageViewPayloadSelector({ ...props, ...params, ...queryParamsAll })
          }
        >
          <Component {...props} />
        </TrackPageView>
      </RouteWithInterstitial>
    </RouteProvider>
  );
}

export default PublicRoute;
