export const BILLING_ENTITY_FILE_DATA = 'billingEntityFileData';
export const BILLING_ENTITY_FILE = 'billingEntityFile';
export const BILLING_ENTITY_FILE_IS_LOADED = 'billingEntityFileLoaded';
export const BILLING_ENTITY_FILE_LOADING_ERROR = 'billingEntityFileError';
export const BILLING_ENTITY_FILE_DISABLE_SUBMIT =
  'billingEntityFileDisableSubmit';
export const BILLING_ENTITY_EDIT_BUTTON_HAS_BEEN_CLICKED =
  'billingEntityEditButtonHasBeenClicked';

export const CEDULA_FIELD_TAGS = {
  legalName: 'Razón Social',
  typeOfPerson: 'Tipo de persona',
  taxId: 'RFC',
  email: 'Correo Electrónico',
  address: 'Dirección',
  city: 'Municipio o delegación',
  province: 'Entidad Federativa',
  country: 'Código de País',
  postalCode: 'Código Postal',
  taxRegime: 'Régimen Fiscal',
};
