import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { select } from '../../../pages/Properties/coeffects/select';
import {
  isItemSelectedSelector,
  selectedItemIdsSelector,
} from '../selectors/selection';
import { visibleItemsIdListSelector } from '../selectors/collection';

export const DATA_TABLE_TOGGLE_ITEM_SELECTION =
  'DATA_TABLE_TOGGLE_ITEM_SELECTION';
export const DATA_TABLE_SELECT_ALL_VISIBLE_ITEMS =
  'DATA_TABLE_SELECT_ALL_VISIBLE_ITEMS';
export const DATA_TABLE_UNSELECT_ALL_VISIBLE_ITEMS =
  'DATA_TABLE_UNSELECT_ALL_VISIBLE_ITEMS';

registerEventHandler(
  DATA_TABLE_TOGGLE_ITEM_SELECTION,
  (coeffects, { collectionName, itemId }) => {
    const currentSelectedItemIds = coeffects.select.currentSelectedItemIds({
      collectionName,
    });

    const isItemSelected = coeffects.select.isItemSelected({
      collectionName,
      itemId,
    });

    if (isItemSelected) {
      const newSelection = currentSelectedItemIds.filter(
        (selectedItemId) => selectedItemId !== itemId
      );

      return state.set({
        [`dataTables.${collectionName}.selection`]: newSelection,
      });
    }

    return state.set({
      [`dataTables.${collectionName}.selection`]: [
        ...currentSelectedItemIds,
        itemId,
      ],
    });
  },
  [
    select({
      currentSelectedItemIds: selectedItemIdsSelector,
      isItemSelected: isItemSelectedSelector,
    }),
  ]
);

registerEventHandler(
  DATA_TABLE_SELECT_ALL_VISIBLE_ITEMS,
  (coeffects, { collectionName }) => {
    const visibleItems = coeffects.select.visibleItems({
      collectionName,
    });

    return state.set({
      [`dataTables.${collectionName}.selection`]: visibleItems,
    });
  },
  [
    select({
      visibleItems: visibleItemsIdListSelector,
    }),
  ]
);

registerEventHandler(
  DATA_TABLE_UNSELECT_ALL_VISIBLE_ITEMS,
  (_, { collectionName }) =>
    state.set({
      [`dataTables.${collectionName}.selection`]: [],
    })
);
