import * as React from "react";
const SvgFacebook = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <rect
      width={31.3}
      height={31.3}
      x={0.35}
      y={0.35}
      stroke="#111B3C"
      strokeWidth={0.7}
      rx={3.65}
    />
    <path
      fill="#000"
      fillRule="evenodd"
      d="M13.818 25.137h3.72v-9.336h2.6l.274-3.127H17.54v-1.78c0-.732.16-1.027.867-1.027h2.008V6.625h-2.577c-2.783 0-4.015 1.21-4.015 3.562v2.487h-1.94v3.17h1.94v9.29z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFacebook;
