import * as React from "react";
const SvgLinkedIn = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <rect
      width={31.3}
      height={31.3}
      x={0.35}
      y={0.35}
      stroke="#111B3C"
      strokeWidth={0.7}
      rx={3.65}
    />
    <path fill="#000" d="M11.964 13.37H8.793v10.25h3.17z" />
    <path
      fill="#000"
      fillRule="evenodd"
      d="M10.369 12.024a1.884 1.884 0 0 0 1.871-1.893c0-1.05-.843-1.872-1.871-1.872s-1.893.846-1.893 1.872c0 1.025.843 1.893 1.893 1.893M17.03 18.233c0-1.438.663-2.284 1.94-2.284 1.164 0 1.712.822 1.712 2.284v5.386h3.149v-6.482c0-2.761-1.552-4.087-3.719-4.087s-3.079 1.69-3.079 1.69v-1.37h-3.035v10.25h3.035v-5.387z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLinkedIn;
