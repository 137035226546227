import styled, { css } from 'styled-components';
import { Overline } from 'design-system/components/Typography/presets/Overline';
import { COLOR_PALETTE, COLOR_SEMANTIC } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';

const modDisabled = () => css`
  ${Overline.mods.color(COLOR_PALETTE.NEUTRAL_A20)}
`;

const modError = () => css`
  ${Overline.mods.color(COLOR_SEMANTIC.STATUS_ERROR)}
`;

const Wrapper = styled(Overline)`
  display: block;

  ${spacing.stack(SPACING_SIZE.M)}

  ${({ error }) => error && modError()}
  ${({ disabled }) => disabled && modDisabled()}
`;

function InputSectionLabel({ children, required, ...rest }) {
  return (
    <Wrapper {...rest}>
      {children}
      {required && '*'}
    </Wrapper>
  );
}

export default styled(InputSectionLabel)``;
