import QRCode from 'react-qr-code';
import styled from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { forwardRef } from 'react';
import { vCardFormatter } from './vCardFormatter';

const QR_SIZE = 100;

const SkeletonWrapper = styled.div`
  ${color.background(COLOR_PALETTE.NEUTRAL_A05)}
  ${spacing.inset(SPACING_SIZE.S)}
  width: ${QR_SIZE}px;
  height: ${QR_SIZE}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const QrBox = styled.div`
  ${color.background(COLOR_PALETTE.NEUTRAL_A05)}
  border: 4px solid white;
  width: 16px;
  height: 16px;
`;

function ContactQr({ contact }, ref) {
  const formatedString = vCardFormatter(contact);
  return <QRCode value={formatedString} size={QR_SIZE} ref={ref} />;
}

function Skeleton() {
  return (
    <SkeletonWrapper>
      <div>
        <QrBox />
        <QrBox />
      </div>
      <div>
        <QrBox />
      </div>
    </SkeletonWrapper>
  );
}

const withForwardedRef = forwardRef(ContactQr);
withForwardedRef.Skeleton = Skeleton;
export default withForwardedRef;
