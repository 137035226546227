import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import Tip from 'design-system/components/Tip';
import { ItemFullWidth } from '../../SimpleFormLayout';
import FormattedMessageWithValidation from '../../FormattedMessageWithValidation/FormattedMessageWithValidation';
import { TYPE_OF_PERSON } from '../../../constants/billingEntity';
import CheckboxField from '../../fields/CheckboxField';

const RETEFUENTE = 'retefuente';
const RETE_ICA_DISCOUNT = 'reteIcaDiscount';
const RETE_IVA_DISCOUNT = 'reteIvaDiscount';
const POSTAL_CODE = 'postalCode';
const BOGOTA_POSTAL_CODE = '11001';

const RetentionField = styled(CheckboxField)`
  ${spacing.stack(SPACING_SIZE.M, false)}
`;

export default function Retentions({
  configFields,
  disabled,
  disableRetentions,
  typeOfPerson,
}) {
  const {
    watch,
    formState: {
      errors: { [RETEFUENTE]: errorsRetefuente },
    },
  } = useFormContext();

  const isNaturalPerson = typeOfPerson === TYPE_OF_PERSON.NATURAL;
  const retefuenteProperty = RETEFUENTE;
  const isBogotaCountryCode = watch(POSTAL_CODE) === BOGOTA_POSTAL_CODE;
  const disallowCheckingRetentions =
    disabled || disableRetentions || isNaturalPerson;

  return (
    <>
      {configFields.some(
        (fieldName) =>
          fieldName === retefuenteProperty ||
          fieldName === RETE_ICA_DISCOUNT ||
          fieldName === RETE_IVA_DISCOUNT
      ) && (
        <ItemFullWidth>
          {disableRetentions && (
            <Tip>
              <FormattedMessageWithValidation id="checkout_billing_retentions_disabled_scrs" />
            </Tip>
          )}
          {isNaturalPerson && (
            <Tip>
              <FormattedMessageWithValidation id="checkout_billing_retentions_natural_person" />
            </Tip>
          )}
          {errorsRetefuente && (
            <Tip>
              <FormattedMessageWithValidation id={errorsRetefuente.message} />
            </Tip>
          )}
          {configFields.includes(retefuenteProperty) && (
            <RetentionField
              name="retefuente"
              disabled={disallowCheckingRetentions}
            >
              <FormattedMessageWithValidation id="checkout_billing_retefuente_label" />
            </RetentionField>
          )}

          {configFields.includes(RETE_ICA_DISCOUNT) && (
            <RetentionField
              name="reteIca"
              disabled={disallowCheckingRetentions || !isBogotaCountryCode}
            >
              <FormattedMessageWithValidation id="checkout_billing_reteica_label" />
            </RetentionField>
          )}

          {configFields.includes(RETE_IVA_DISCOUNT) && (
            <RetentionField
              name="reteIva"
              disabled={disallowCheckingRetentions}
            >
              <FormattedMessageWithValidation id="checkout_billing_reteiva_label" />
            </RetentionField>
          )}
        </ItemFullWidth>
      )}
    </>
  );
}
