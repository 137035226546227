import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { navigateTo } from '../../../../effects/routing';
import {
  SUBSCRIPTION_ADD_CARD_FAILED,
  SUBSCRIPTION_ADD_CARD_SUCCESS,
} from '../../../../constants/routes';
import { environment } from '../../../../coeffects/environment';

export const REQUEST_CREDIT_CARD_FORM = 'REQUEST_CREDIT_CARD_FORM';
export const CREDIT_CARD_SAVED_SUCCESS = 'CREDIT_CARD_SAVED_SUCCESS';
export const CREDIT_CARD_SAVED_FAILED = 'CREDIT_CARD_SAVED_FAILED';

registerEventHandler(
  REQUEST_CREDIT_CARD_FORM,
  ({ environment: { paymentsApiUrl }, state: { countryCode, publisherId } }) =>
    state.set({
      'creditCardForm.formUrl': `${paymentsApiUrl}/payment-methods/${countryCode}/direct/${publisherId}`,
    }),
  [
    environment(),
    state.get({
      countryCode: 'publisher.countryCode',
      publisherId: 'publisher.id',
    }),
  ]
);

registerEventHandler(CREDIT_CARD_SAVED_SUCCESS, () =>
  navigateTo(SUBSCRIPTION_ADD_CARD_SUCCESS)
);

registerEventHandler(CREDIT_CARD_SAVED_FAILED, () =>
  navigateTo(SUBSCRIPTION_ADD_CARD_FAILED)
);
