import styled from 'styled-components';
import InputNumber from 'design-system/components/InputNumber';

const MAX_AMOUNT = 9999999999;

export const CampaignLeadTargetInput = styled(
  ({
    id = 'leadsTarget',
    name = 'leadsTarget',
    label = 'Leads to deliver',
    value,
    required,
    onChange,
    ...props
  }) => (
    <InputNumber
      id={id}
      label={label}
      name={name}
      value={value}
      min={0}
      max={MAX_AMOUNT}
      maxDecimals={0}
      placeholder={label}
      onChange={(e) => onChange(e.target.rawValue)}
      required={required}
      {...props}
    />
  )
)``;
