import * as React from "react";
const SvgEllipse = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={46}
    height={46}
    fill="none"
    {...props}
  >
    <path
      stroke="#0ACC86"
      strokeLinecap="square"
      strokeWidth={7}
      d="M42 23c0 10.493-8.507 19-19 19S4 33.493 4 23 12.507 4 23 4"
    />
  </svg>
);
export default SvgEllipse;
