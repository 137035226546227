import styled from 'styled-components';
import { FlatButton } from 'design-system/components/Button/presets';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import BaseBubble, { BASE_BUBBLE_ALIGNMENT } from './BaseBubble';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { useDialog } from '../../../../../hooks/useDialog';
import {
  ENQUIRY_DETAIL_DELETE_NOTE_DIALOG,
  ENQUIRY_DETAIL_EDIT_NOTE_DIALOG,
} from '../constants';
import RenderHtml from '../../../../../partials/RenderHtml';

const Divider = styled.span.attrs({ children: '-' })`
  ${color.text(COLOR_PALETTE.NEUTRAL_A20)}
`;

function NoteActions({ id, message, leadId }) {
  const { openDialog: openDeleteDialog } = useDialog(
    ENQUIRY_DETAIL_DELETE_NOTE_DIALOG
  );
  const { openDialog: openEditDialog } = useDialog(
    ENQUIRY_DETAIL_EDIT_NOTE_DIALOG
  );

  return (
    <>
      <FlatButton
        onClick={() =>
          openEditDialog({
            message,
            leadId,
            noteId: id,
          })
        }
      >
        <FormattedMessageWithValidation id="enquiry_detail_note_edit" />
      </FlatButton>
      <Divider />
      <FlatButton
        onClick={() =>
          openDeleteDialog({
            leadId,
            noteId: id,
          })
        }
      >
        <FormattedMessageWithValidation id="enquiry_detail_note_delete" />
      </FlatButton>
    </>
  );
}

function NoteBubble(props) {
  const { message, ...rest } = props;
  return (
    <BaseBubble
      {...rest}
      title={<FormattedMessageWithValidation id="enquiry_detail_note_header" />}
      $alignment={BASE_BUBBLE_ALIGNMENT.RIGHT}
      $colorScheme={{
        title: COLOR_PALETTE.PUMPKIN_BASE,
        background: COLOR_PALETTE.PUMPKIN_A05,
        notch: COLOR_PALETTE.PUMPKIN_BASE,
      }}
      actions={<NoteActions {...props} />}
    >
      <Body1 $color={COLOR_PALETTE.NEUTRAL_BASE} $weight={FONT_WEIGHT.LIGHT}>
        <RenderHtml>{message}</RenderHtml>
      </Body1>
    </BaseBubble>
  );
}

export default NoteBubble;
