import { css } from 'styled-components';

import { elevation as elevationTokens } from '../tokens/elevation';

export const ELEVATION_INDEX = {
  NONE: null,
  BASE: elevationTokens.base,
};

const buildBoxShadowValueFromIndex = (index) => {
  if (!index) return 'none';

  return index
    .map(
      ([hOffset, vOffset, blur, spread, color]) =>
        `${hOffset}px ${vOffset}px ${blur}px ${spread}px ${color}`
    )
    .join(',');
};

const regular = (index) => css`
  box-shadow: ${buildBoxShadowValueFromIndex(index)};
`;

export const elevation = {
  regular,
};
