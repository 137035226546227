import { useController, useFormContext } from 'react-hook-form';
import { useSelector } from 'reffects-store';
import InputPhone from 'design-system/components/InputPhone/InputPhone';
import { useRegisterFieldErrors } from '../../pages/publication/partials/FieldGroup';
import { publisherCountrySelector } from '../../selectors/publisher';

function PhoneField({ name, tip, phoneRegionCode, hideErrors, ...rest }) {
  const { control } = useFormContext();
  const defaultCountryCode = useSelector(publisherCountrySelector);
  const {
    fieldState: { error },
    field: { onChange, ref, value, ...inputProps },
  } = useController({
    name,
    control,
  });

  useRegisterFieldErrors({ name, errors: error });

  const errorMessage = !hideErrors && error?.message;

  return (
    <InputPhone
      htmlRef={ref}
      defaultCountryCode={defaultCountryCode}
      error={Boolean(error)}
      tip={errorMessage ?? tip}
      onChange={onChange}
      value={value}
      {...inputProps}
      {...rest}
    />
  );
}

export default PhoneField;
