import { createSelector } from 'reselect';
import { operationTypesByCountrySelector } from '../../../../selectors/country';
import {
  OPERATION_TYPE_RENT,
  OPERATION_TYPE_SELL,
  OPERATION_TYPE_SELL_AND_RENT,
} from '../../../../constants/ad';

const operationTypeCaptions = new Map([
  [OPERATION_TYPE_SELL, 'properties_list_filter_operation_type_sell'],
  [OPERATION_TYPE_RENT, 'properties_list_filter_operation_type_rent'],
  [
    OPERATION_TYPE_SELL_AND_RENT,
    'properties_list_filter_operation_type_sell_and_rent',
  ],
]);

export function operationTypeFilterOptionsSelector(state) {
  return operationTypesByCountrySelector(state).reduce(
    (options, operationType) => ({
      ...options,
      [operationType]: operationTypeCaptions.get(operationType),
    }),
    {}
  );
}

export function unitStatusFilterOptionSelector(state) {
  return state.unitStatusFilter ?? undefined;
}

export function unitTypeFilterOptionSelector(state) {
  return state.unitTypeFilter ?? undefined;
}

export const unitStatusFilterLabelSelector = createSelector(
  unitStatusFilterOptionSelector,
  (_, options) => options,
  (unitStatusFilter, options) => {
    const selectedTag = options.find(
      (option) => option.value === unitStatusFilter
    );

    return selectedTag ? selectedTag.label : 'filter_units_status_default';
  }
);
