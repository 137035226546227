import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { environment } from '../../../../coeffects/environment';
import { UPDATE_UNIT_STATUS } from '../../events';
import { toast } from '../../../../effects/toast';
import { analytics } from '../../../../effects/analytics';

export const PUBLISH_UNIT = 'PUBLISH_UNIT';
export const PUBLISH_UNIT_SUCCEEDED = 'PUBLISH_UNIT_SUCCEEDED';
export const PUBLISH_UNIT_FAILED = 'PUBLISH_UNIT_FAILED';

export const ATTEMPT_UNPUBLISH_UNIT = 'ATTEMPT_UNPUBLISH_UNIT';
export const UNPUBLISH_UNIT = 'UNPUBLISH_UNIT';
export const UNPUBLISH_UNIT_SUCCEEDED = 'UNPUBLISH_UNIT_SUCCEEDED';
export const UNPUBLISH_UNIT_FAILED = 'UNPUBLISH_UNIT_FAILED';

registerEventHandler(
  PUBLISH_UNIT,
  ({ environment: { apiUrl } }, { unitId }) => ({
    ...http.put({
      url: `${apiUrl}/properties/${unitId}/published`,
      body: JSON.stringify({ published: true }),
      successEvent: {
        id: PUBLISH_UNIT_SUCCEEDED,
        payload: {
          unitId,
        },
      },
      errorEvent: PUBLISH_UNIT_FAILED,
    }),
    ...analytics.trackClick(
      'publish-unit-confirmed',
      'units-table-icon-action'
    ),
  }),
  [environment()]
);

registerEventHandler(PUBLISH_UNIT_SUCCEEDED, (_, [, { unitId }]) => ({
  ...toast.show({
    text: 'units_list_unit_published_toast',
  }),
  ...effects.dispatch({
    id: UPDATE_UNIT_STATUS,
    payload: {
      unitId,
    },
  }),
}));

registerEventHandler(PUBLISH_UNIT_FAILED, () => {});

registerEventHandler(ATTEMPT_UNPUBLISH_UNIT, () =>
  analytics.trackClick('unpublish-unit-attempt', 'units-table-icon-action')
);

registerEventHandler(
  UNPUBLISH_UNIT,
  ({ environment: { apiUrl } }, { unitId }) => ({
    ...http.put({
      url: `${apiUrl}/properties/${unitId}/published`,
      body: JSON.stringify({ published: false }),
      successEvent: {
        id: UNPUBLISH_UNIT_SUCCEEDED,
        payload: { unitId },
      },
      errorEvent: UNPUBLISH_UNIT_FAILED,
    }),
    ...analytics.trackClick(
      'unpublish-unit-confirmed',
      'units-table-publishing-modal'
    ),
  }),
  [environment()]
);

registerEventHandler(UNPUBLISH_UNIT_SUCCEEDED, (_, [, { unitId }]) => ({
  ...toast.show({
    text: 'units_list_unit_unpublished_toast',
  }),
  ...effects.dispatch({
    id: UPDATE_UNIT_STATUS,
    payload: {
      unitId,
    },
  }),
}));

registerEventHandler(UNPUBLISH_UNIT_FAILED, () => {});
