import { forwardRef } from 'react';
import styled from 'styled-components';
import hoistNonReactStatics from 'hoist-non-react-statics';

export function withStylesAndInnerRef(Component) {
  const ComponentWithInnerRef = forwardRef((props, ref) => (
    <Component innerRef={ref} {...props} />
  ));

  const StyledComponent = styled(ComponentWithInnerRef)``;

  hoistNonReactStatics(StyledComponent, Component);

  return StyledComponent;
}
