import { collectionItemsByIdSelector } from '../DataTable/selectors/collection';
import { isDeveloperSelector } from '../../selectors/publisher';

export function campaignsBannerSelector(state) {
  if (!shouldShowAnyBanner(state)) {
    return null;
  }
  const projects = projectsSelector(state);
  if (projects.some(({ status }) => status === 'grace period')) {
    return {
      status: 'expired',
      billingDate: findClosestBillingDate(
        projects.filter(({ status }) => status === 'grace period')
      ),
    };
  }
  return {
    status: 'about-to-expire',
    billingDate: findClosestBillingDate(
      projects.filter(({ status }) => status === 'about to expire')
    ),
  };
}

function shouldShowAnyBanner(state) {
  if (!isDeveloperSelector(state)) {
    return false;
  }
  const projects = projectsSelector(state);
  return (
    projects.length > 0 && projects.some(({ status }) => status !== 'active')
  );
}

function projectsSelector(state) {
  return Object.values(
    collectionItemsByIdSelector(state, { collectionName: 'promotedProjects' })
  );
}

function findClosestBillingDate(projects) {
  const dates = projects.map(
    ({ nextBillingDate }) => new Date(nextBillingDate)
  );
  return new Date(Math.min.apply(null, dates));
}
