import styled from 'styled-components';
import AreaChart from 'design-system/components/AreaChart/AreaChart';
import { FONT_SIZE } from 'design-system/styles/typography';
import FormattedDate from '../../../../partials/FormattedDate/FormattedDate';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Wrapper = styled.div`
  ${AreaChart} {
    height: 200px;
  }
`;

function DailyLeadsChart({ data, ...props }) {
  if (!data || data.length === 0) return null;
  return (
    <Wrapper {...props}>
      <AreaChart
        dataKey="enquiries"
        data={data}
        renderTooltipContent={({ payload }) =>
          !payload ? null : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <p
                style={{
                  fontSize: `${FONT_SIZE.S}px`,
                  margin: '0',
                }}
              >
                <FormattedDate
                  value={payload.date}
                  weekday="short"
                  day="2-digit"
                  month="short"
                  year="numeric"
                />
              </p>
              <p
                style={{
                  fontSize: `${FONT_SIZE.S}px`,
                  margin: '0',
                }}
              >
                <FormattedMessageWithValidation id="overview_developer_key_results_chart_tooltip_enquiries" />
                : {payload.enquiries}
              </p>
            </div>
          )
        }
        renderXTickText={({ value }) => (
          <FormattedDate value={value} month="short" day="numeric" />
        )}
        renderYTickText={({ value }) => value}
      />
    </Wrapper>
  );
}

export default DailyLeadsChart;
