import { chunk } from 'lodash';

export class InMemoryTable {
  #filterStrategies = [];

  #sortingStrategies = [];

  #collection = [];

  #filters = {};

  #sorting = {};

  #rows = [];

  constructor({ filterStrategies = [], sortingStrategies = [] } = {}) {
    this.#filterStrategies = filterStrategies;
    this.#sortingStrategies = sortingStrategies;
  }

  static create(...args) {
    return new InMemoryTable(...args);
  }

  setCollection(collection) {
    this.#collection = collection;
    return this;
  }

  setFilters(filters = {}) {
    this.#filters = filters;
    return this;
  }

  setSorting(sorting = {}) {
    this.#sorting = sorting;
    return this;
  }

  get(pagination) {
    this.#resetRows();
    this.#filterRows();
    this.#sortRows();

    return {
      visibleRowsIdList: this.#getVisibleRowsIdList(pagination),
      totalRows: this.#calculateTotalRows(),
    };
  }

  #resetRows() {
    this.#rows = this.#collection;
  }

  #filterRows() {
    const combinedFilters = this.#getCombinedFilters();

    this.#rows = this.#rows.filter(combinedFilters);
  }

  #getCombinedFilters() {
    const curriedFiltersWithValues = this.#getCurriedFiltersWithValues();

    return (item) =>
      curriedFiltersWithValues.every((curriedFilter) => curriedFilter(item));
  }

  #getCurriedFiltersWithValues() {
    return Object.entries(this.#filters)
      .map(([filterId, filterValue]) =>
        this.#getCurriedFilterWithValueById(filterId, filterValue)
      )
      .filter(Boolean);
  }

  #getCurriedFilterWithValueById(filterId, value) {
    const filterStrategy = this.#getFilterStrategyById(filterId);

    if (!filterStrategy) return null;

    return (item) => filterStrategy.matchFn(value, item);
  }

  #getFilterStrategyById(filterId) {
    return this.#filterStrategies.find(({ id }) => id === filterId);
  }

  #sortRows() {
    const { direction = 'asc' } = this.#sorting;
    const sortingCompareFn = this.#getSortingCompareFn();

    if (!sortingCompareFn) return;

    this.#rows = this.#rows.sort(sortingCompareFn);

    if (direction === 'desc') {
      this.#rows.reverse();
    }
  }

  #getSortingCompareFn() {
    const { field } = this.#sorting;
    if (!field) return null;

    const sortingStrategy = this.#getSortingStrategyByField(field);
    if (!sortingStrategy) return null;

    return sortingStrategy.compareFn;
  }

  #getSortingStrategyByField(sortingField) {
    return this.#sortingStrategies.find(({ field }) => field === sortingField);
  }

  #getVisibleRowsIdList(pagination = {}) {
    const { currentPage = 1, itemsPerPage = this.#calculateTotalRows() } =
      pagination;
    const pages = chunk(this.#rows, itemsPerPage);
    const currentPageRows = pages[currentPage - 1] ?? [];

    return currentPageRows.map(({ id }) => id);
  }

  #calculateTotalRows() {
    return this.#rows.length;
  }
}
