import styled from 'styled-components';
import Typography from 'design-system/components/Typography/Typography';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import BaseRow from './BaseRow';

const Subtitle = styled(Typography)`
  ${typography.size(10)}
  ${typography.lineHeight(12)}
  ${typography.weight(FONT_WEIGHT.NORMAL)}
  ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    ${spacing.stack(SPACING_SIZE.XS)}
  }
`;

function Row({ title, subtitle, price, ...rest }) {
  return (
    <BaseRow
      {...rest}
      leftChild={
        <Description>
          <Subtitle3>{title}</Subtitle3>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </Description>
      }
      rightChild={<Body1 $weight={FONT_WEIGHT.LIGHT}>{price}</Body1>}
    />
  );
}

export default styled(Row)``;
