export default function buildBoostingLevelName(boostingLevel) {
  const boostingLevelNumber = Number(boostingLevel);

  if (boostingLevelNumber === 0) {
    return 'Free';
  }

  if (boostingLevelNumber === 1) {
    return 'Professional';
  }

  const prefix = 'Boosted';

  if (boostingLevelNumber === 2) {
    return prefix;
  }

  return `${prefix} X${boostingLevel - 1}`;
}
