import styled from 'styled-components';
import Table from 'design-system/components/Table';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { useColumn } from '../../../../../../partials/DataTable/hooks/useColumn';
import CopyTextButton from '../../../../partials/CopyTextButton/CopyTextButton';

const Wrapper = styled(Table.Cell)`
  min-width: 370px;

  > div {
    justify-content: space-between;
  }

  ${CopyTextButton} {
    visibility: hidden;
  }

  :hover {
    ${CopyTextButton} {
      visibility: visible;
    }
  }
`;

export default function IdColumn({ placeholder, ...props }) {
  const { value: id } = useColumn(props);

  return (
    <Wrapper>
      <Body1>{id}</Body1> <CopyTextButton text={id} title={placeholder} />
    </Wrapper>
  );
}
