export const COLLECTION_NAME = 'agencies';
export const AGENCY_STATUS = {
  FREE: 'free',
  ABOUT_TO_EXPIRE: 'about-to-expire',
  GRACE_PERIOD: 'grace-period',
  ACTIVE: 'active',
  FREE_SINCE_7_DAYS: 'free-since-7-days',
  FREE_SINCE_15_DAYS: 'free-since-15-days',
  FREE_SINCE_30_DAYS: 'free-since-30-days',
  FREE_SINCE_60_DAYS: 'free-since-60-days',
  FREE_SINCE_MORE_THAN_60_DAYS: 'free-since-more-than-60-days',
};

export const FILTERS = {
  COUNTRY: 'country',
};
