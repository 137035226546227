import { dispatch } from 'reffects';
import { useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { useEffect } from 'react';
import InputRow from 'design-system/components/InputRow';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import Map from 'design-system/components/Map';
import { FieldGroup } from '../../partials/FieldGroup';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import SelectField from '../../../../partials/fields/SelectField';
import ProjectSnippet from '../../../../partials/ProjectSnippet';
import { projectByIdSelector, projectChoicesSelector } from './selectors';
import { UNIT_FORM_PROJECT_SELECTED } from '../events';
import {
  ADDRESS_INPUT_NAME,
  COORDINATES_INPUT_NAME,
  GEO_LEVELS_INPUT_NAME,
  LOCATION_VISIBILITY_INPUT_NAME,
  PROJECT_ID_INPUT_NAME,
  PROJECT_INPUT_NAME,
} from '../../constants';
import { PROPERTY_LOCATION_VISIBILITY } from '../../../../constants/ad';
import HiddenField from '../../partials/Location/HiddenField';

const PropertyMap = styled(Map)`
  aspect-ratio: 56 / 30;
  & > div {
    ${radius.regular(RADIUS_SIZE.LARGE)}
  }
`;

export default function ProjectDetailsGroup({ order }) {
  const intl = useIntl();
  const { watch } = useFormContext();
  const [projectId] = watch([PROJECT_ID_INPUT_NAME]);
  const projectChoices = useSelector(projectChoicesSelector);
  const selectedProject = useSelector((state) =>
    projectByIdSelector(state, projectId)
  );

  useEffect(
    () =>
      selectedProject &&
      dispatch({
        id: UNIT_FORM_PROJECT_SELECTED,
        payload: { selectedProject },
      }),
    [selectedProject]
  );

  return (
    <FieldGroup
      id="projectDetails"
      order={order}
      title={
        <FormattedMessageWithValidation id="ad_form_project_name_title_developers" />
      }
    >
      <InputRow>
        <SelectField
          label={
            <FormattedMessageWithValidation id="ad_form_group_project_developers" />
          }
          name={PROJECT_ID_INPUT_NAME}
          placeholder={intl.formatMessage({
            id: 'ad_form_project_placeholder_developers',
          })}
          choices={projectChoices}
        />
      </InputRow>
      {selectedProject && (
        <>
          <InputRow>
            <ProjectSnippet
              image={selectedProject.mainImage}
              name={selectedProject.name}
              address={selectedProject.address}
              geoLevels={selectedProject.geoLevels}
            />
          </InputRow>
          <HiddenField
            name={ADDRESS_INPUT_NAME}
            value={selectedProject.address}
          />
          <HiddenField
            name={COORDINATES_INPUT_NAME}
            value={{
              latitude: selectedProject.latitude,
              longitude: selectedProject.longitude,
            }}
          />
          <HiddenField
            name={GEO_LEVELS_INPUT_NAME}
            value={selectedProject.geoLevels}
          />
          <HiddenField
            name={LOCATION_VISIBILITY_INPUT_NAME}
            value={PROPERTY_LOCATION_VISIBILITY.ACCURATE}
          />
          <HiddenField
            name={PROJECT_INPUT_NAME}
            value={{
              id: selectedProject.id,
              name: selectedProject.name,
            }}
          />
        </>
      )}
      {selectedProject && (
        <InputRow>
          <PropertyMap
            coordinates={{
              latitude: selectedProject?.latitude,
              longitude: selectedProject?.longitude,
            }}
            showMarker
          />
        </InputRow>
      )}
    </FieldGroup>
  );
}
