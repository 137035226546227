import styled from 'styled-components';
import { subscribe } from 'reffects-store';
import { dispatch } from 'reffects';
import { OutlineButton } from 'design-system/components/Button/presets';
import Button from 'design-system/components/Button';
import { FONT_SIZE, typography } from 'design-system/styles/typography';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import CountryFilter from '../partials/Filters/CountryFilter';
import { hasValidationErrorsSelector } from './selectors';
import {
  PLAN_PRICES_SUBMITTED_TABLE,
  PLANS_PRICES_CANCEL,
  PLANS_PRICES_REQUESTED,
  PUBLISHES_IN_TP_SELECTED,
  SUBSCRIPTION_PLANS_MANAGEMENT_COUNTRY_SELECTED,
} from './events';
import useUpdateTableEvent from '../../../partials/DataTable/hooks/useUpdateTableEvent';
import { useFilters } from '../../../partials/DataTable/hooks/useFilter';
import PublishesInTPFilter from './PublishesInTPFilter';

const SaveButton = styled(Button)`
  ${typography.size(FONT_SIZE.M)}
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${spacing.stack(SPACING_SIZE.M, false)}
`;

const Filters = styled.div`
  display: flex;
  gap: ${spacing.value(SPACING_SIZE.M)};
`;

const Actions = styled.div`
  display: flex;
  gap: ${spacing.value(SPACING_SIZE.M)};
`;

function SubscriptionPlanHeader({
  onCountryChange,
  onPublishesInTPChange,
  submitEnabled,
  onClickCancel,
}) {
  const updateTable = useUpdateTableEvent();
  const {
    filters: { country },
  } = useFilters();
  return (
    <Header>
      <Filters>
        <CountryFilter
          source="country"
          onSelectOption={onCountryChange}
          withoutAllOption
        />

        {country === 'TH' && (
          <PublishesInTPFilter
            source="publishesInTP"
            onSelectOption={onPublishesInTPChange}
          />
        )}
      </Filters>
      <Actions>
        <SaveButton
          disabled={submitEnabled}
          onClick={() =>
            dispatch({
              id: PLAN_PRICES_SUBMITTED_TABLE,
              payload: { onSuccessEvent: updateTable },
            })
          }
        >
          Save
        </SaveButton>
        <OutlineButton
          onClick={() => {
            onClickCancel();
            dispatch({
              id: PLANS_PRICES_CANCEL,
              payload: { updateTable },
            });
          }}
        >
          Cancel
        </OutlineButton>
      </Actions>
    </Header>
  );
}
export default subscribe(
  SubscriptionPlanHeader,
  (state) => ({
    submitEnabled: hasValidationErrorsSelector(state),
  }),
  {
    onCountryChange({ value }) {
      dispatch({
        id: SUBSCRIPTION_PLANS_MANAGEMENT_COUNTRY_SELECTED,
        payload: value,
      });
    },

    onPublishesInTPChange({ value }) {
      dispatch({
        id: PUBLISHES_IN_TP_SELECTED,
        payload: value,
      });
    },

    onClickCancel() {
      dispatch(PLANS_PRICES_REQUESTED);
    },
  }
);
