import { effects as reffects, registerEventHandler } from 'reffects';
import { CLOSE_DIALOG } from '../../../events/dialogs';
import { analytics } from '../../../effects/analytics';
import { navigateTo } from '../../../effects/routing';
import { subscriptionRoute } from '../../../utils/proppitWebRouter';

export const GO_TO_MY_SUBSCRIPTION_PAGE = 'GO_TO_MY_SUBSCRIPTION_PAGE';

registerEventHandler(GO_TO_MY_SUBSCRIPTION_PAGE, () => ({
  ...reffects.dispatch(CLOSE_DIALOG),
  ...analytics.trackClick('go-to-offers-page', 'free-publisher-offers-modal'),
  ...navigateTo(subscriptionRoute()),
}));
