import styled, { css } from 'styled-components';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle2 } from 'design-system/components/Typography/presets/Subtitle2';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import Feature from './Feature';
import Ribbon from './Ribbon';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
  ${radius.regular(RADIUS_SIZE.LARGE)}
  
  ${Ribbon} {
    ${spacing.stack(SPACING_SIZE.M, false)}
  }
`;

const modBlurred = (blur) => css`
  filter: blur(${blur}px);
`;

const Content = styled.div`
  ${spacing.inset(SPACING_SIZE.M)}

  ${Subtitle2} {
    display: inline-block;
    ${spacing.stack(SPACING_SIZE.M)}

    ${({ blurred }) => blurred && modBlurred(5)}
  }

  ${Body1} {
    ${({ blurred }) => blurred && modBlurred(5)}
  }
`;

function ContactInterest({ children, title, blurred, ribbon, ...rest }) {
  return (
    <Wrapper {...rest}>
      <Content blurred={blurred}>
        {title && <Subtitle2 blurred>{title}</Subtitle2>}
        <div>{children}</div>
      </Content>
      {ribbon}
    </Wrapper>
  );
}

ContactInterest.Feature = Feature;
ContactInterest.Ribbon = Ribbon;

export default ContactInterest;
