import { coeffect, registerCoeffectHandler } from 'reffects';

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}

export function registerRandomNumberCoeffects(randomIntBetween = getRandomInt) {
  registerCoeffectHandler(
    'randomInt',
    function getRandomIntBetween({ min, max }) {
      return {
        randomInt: randomIntBetween(min, max),
      };
    }
  );
}

export const randomNumber = {
  intBetween(min, max) {
    return coeffect('randomInt', { min, max });
  },
};
