import Icon from 'design-system/components/Icon';
import TextField from './TextField';
import FormattedMessageWithValidation from '../FormattedMessageWithValidation/FormattedMessageWithValidation';

function FacebookMessengerField({ tip, ...rest }) {
  return (
    <TextField
      icon={<Icon glyph="vendorFacebook" />}
      tip={tip || <FormattedMessageWithValidation id="facebook_id_hint" />}
      {...rest}
    />
  );
}

export default FacebookMessengerField;
