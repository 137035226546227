import { effects as reffects, effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { cloneDeep } from 'lodash';
import { environment } from '../../../coeffects/environment';
import {
  COLLECTION_NAME,
  CREATE_CAMPAIGN_MODAL,
  RENEW_CAMPAIGN_MODAL,
} from './constants';
import { toast } from '../../../effects/toast';
import { uuid } from '../../../coeffects/uuid';
import getDefaultCurrency from '../../utils/defaultCurrency';
import { CLOSE_DIALOG, OPEN_DIALOG } from '../../../events/dialogs';
import { table } from '../../../partials/DataTable/effects/table';
import { isEmpty } from '../../../utils/strings';

export const DEVELOPERS_REQUESTED = 'DEVELOPERS_REQUESTED';
export const DEVELOPERS_LOADED = 'DEVELOPERS_LOADED';
export const CREATE_NEW_CAMPAIGN = 'CREATE_NEW_CAMPAIGN';
export const CAMPAIGN_SELECTED = 'CAMPAIGN_SELECTED';
export const RENEW_CAMPAIGN_MODAL_OPENED = 'RENEW_CAMPAIGN_MODAL_OPENED';
export const CAMPAIGN_FIELD_CHANGED = 'CAMPAIGN_FIELD_CHANGED';
export const CAMPAIGN_DEVELOPER_SELECTED = 'CAMPAIGN_DEVELOPER_SELECTED';
export const CAMPAIGN_SAVED = 'CAMPAIGN_SAVED';
export const CAMPAIGN_SAVED_SUCCESS = 'CAMPAIGN_SAVED_SUCCESS';
export const CAMPAIGN_NEXT_BILLING_DATE_CALCULATION_REQUIRED =
  'CAMPAIGN_NEXT_BILLING_DATE_CALCULATION_REQUIRED';
export const ADD_NEW_INVOICING_PERIOD = 'ADD_NEW_INVOICING_PERIOD';
export const REMOVE_INVOICING_PERIOD = 'REMOVE_INVOICING_PERIOD';
export const UPDATE_INVOICING_PERIOD = 'UPDATE_INVOICING_PERIOD';
export const FINISH_CAMPAIGN = 'FINISH_CAMPAIGN';
export const CAMPAIGN_FINISHED_SUCCESS = 'CAMPAIGN_FINISHED_SUCCESS';
export const CAMPAIGN_FINISHED_FAILED = 'CAMPAIGN_FINISHED_FAILED';

registerEventHandler(
  DEVELOPERS_REQUESTED,
  ({ environment: { apiUrl } }) =>
    http.get({
      url: `${apiUrl}/admin/developers`,
      successEvent: DEVELOPERS_LOADED,
    }),
  [environment()]
);

registerEventHandler(DEVELOPERS_LOADED, (_, [{ data }]) =>
  state.set({ 'admin:developers': data })
);

registerEventHandler(
  CREATE_NEW_CAMPAIGN,
  ({ uuid: { newUuid } }) => ({
    ...state.set({
      'campaignsBackoffice:selectedCampaign': {
        id: newUuid,
        projectId: '',
        developerId: '',
        leadTarget: '',
        price: { amount: '', currency: '' },
        billingDate: '',
        startDate: '',
        billingCycleInMonths: '',
        invoicingPeriods: [],
      },
    }),
    ...reffects.dispatch({
      id: OPEN_DIALOG,
      payload: { id: CREATE_CAMPAIGN_MODAL },
    }),
  }),
  [uuid()]
);

registerEventHandler(
  CAMPAIGN_SELECTED,
  ({ select }, { id, modal }) => ({
    ...state.set({
      'campaignsBackoffice:selectedCampaign': Object.values(
        select.itemsById({ collectionName: COLLECTION_NAME })
      ).find((c) => c.id === id),
    }),
    ...reffects.dispatch({
      id: OPEN_DIALOG,
      payload: { id: modal },
    }),
  }),
  [table.select()]
);

registerEventHandler(
  RENEW_CAMPAIGN_MODAL_OPENED,
  ({ select }, { id }) => {
    const campaign = Object.values(
      select.itemsById({ collectionName: COLLECTION_NAME })
    ).find((c) => c.id === id);
    return {
      ...state.set({
        'campaignsBackoffice:selectedCampaign': {
          id: campaign.id,
          projectId: campaign.projectId,
          developerId: campaign.developerId,
          leadTarget: campaign.leadTarget,
          price: campaign.price,
          projectName: campaign.projectName,
          startDate: '',
          billingDate: '',
          billingCycleInMonths: '',
          invoicingPeriods: [],
        },
      }),
      ...effects.dispatch({
        id: OPEN_DIALOG,
        payload: { id: RENEW_CAMPAIGN_MODAL },
      }),
    };
  },
  [table.select()]
);

registerEventHandler(CAMPAIGN_FIELD_CHANGED, (_, { name, value }) =>
  state.set({
    [`campaignsBackoffice:selectedCampaign.${name}`]: value,
  })
);

registerEventHandler(
  CAMPAIGN_DEVELOPER_SELECTED,
  ({ state: { developers } }, { developerId }) => {
    const developer = developers.find(({ id }) => id === developerId);
    const changeField = (name, value) => ({
      id: CAMPAIGN_FIELD_CHANGED,
      payload: { name, value },
    });
    return effects.dispatchMany([
      changeField('developerId', developerId),
      changeField('countryCode', developer.countryCode),
      changeField('price.currency', getDefaultCurrency(developer.countryCode)),
    ]);
  },
  [state.get({ developers: 'admin:developers' })]
);

registerEventHandler(
  CAMPAIGN_SAVED,
  ({ environment: { apiUrl }, state: { campaign } }, { onSuccessEvent }) => {
    const invoicingPeriodsPrice = campaign.invoicingPeriods.reduce(
      (acc, { amount }) => acc + Number(amount),
      0
    );
    if (invoicingPeriodsPrice !== Number(campaign.price.amount)) {
      return state.set({
        'campaignsBackoffice:selectedCampaign.error':
          'Invoice periods amount does not match Total Contract Value',
      });
    }
    const invoicingPeriodsWithoutId = campaign.invoicingPeriods.reduce(
      (acc, invoicingPeriod) => {
        const { id, ...rest } = invoicingPeriod;
        acc.push(rest);
        return acc;
      },
      []
    );
    const { invoicingPeriods, ...rest } = campaign;
    return http.post({
      url: `${apiUrl}/backoffice/campaigns/${campaign.id}`,
      body: { ...rest, invoicingPeriods: invoicingPeriodsWithoutId },
      successEvent: {
        id: CAMPAIGN_SAVED_SUCCESS,
        payload: { onSuccessEvent },
      },
    });
  },
  [
    environment(),
    state.get({ campaign: 'campaignsBackoffice:selectedCampaign' }),
  ]
);

registerEventHandler(CAMPAIGN_SAVED_SUCCESS, (_, [__, { onSuccessEvent }]) => ({
  ...effects.dispatchMany([CLOSE_DIALOG, onSuccessEvent]),
  ...toast.show({ text: 'Campaign saved' }),
}));

registerEventHandler(
  CAMPAIGN_NEXT_BILLING_DATE_CALCULATION_REQUIRED,
  ({ state: { campaign } }, _) => {
    if (isEmpty(campaign.startDate) || isEmpty(campaign.billingCycleInMonths)) {
      return {};
    }

    const startDate = new Date(campaign.startDate);
    const nextBillingDate = new Date(
      startDate.setMonth(startDate.getMonth() + campaign.billingCycleInMonths)
    );
    return effects.dispatch({
      id: CAMPAIGN_FIELD_CHANGED,
      payload: {
        name: 'billingDate',
        value: nextBillingDate.toISOString().split('T')[0],
      },
    });
  },
  [state.get({ campaign: 'campaignsBackoffice:selectedCampaign' })]
);

registerEventHandler(
  ADD_NEW_INVOICING_PERIOD,
  ({ state: { invoicingPeriods } }) => {
    const INITIAL_ID = 1;
    const previousInvoicingPeriods = invoicingPeriods ?? [];
    const lastId =
      previousInvoicingPeriods[previousInvoicingPeriods.length - 1]?.id;

    return state.set({
      'campaignsBackoffice:selectedCampaign.invoicingPeriods': [
        ...previousInvoicingPeriods,
        {
          date: new Date().toISOString().split('T')[0],
          amount: '0',
          id: lastId ? lastId + 1 : INITIAL_ID,
        },
      ],
    });
  },
  [
    state.get({
      invoicingPeriods: 'campaignsBackoffice:selectedCampaign.invoicingPeriods',
    }),
  ]
);

registerEventHandler(
  REMOVE_INVOICING_PERIOD,
  ({ state: { invoicingPeriods } }, { invoiceId }) => {
    const newInvoicingPeriods = cloneDeep(invoicingPeriods);
    const invoiceIndex = newInvoicingPeriods.findIndex(
      ({ id }) => id === invoiceId
    );

    if (invoiceIndex === -1) return {};
    newInvoicingPeriods.splice(invoiceIndex, 1);

    return state.set({
      'campaignsBackoffice:selectedCampaign.invoicingPeriods':
        newInvoicingPeriods,
    });
  },
  [
    state.get({
      invoicingPeriods: 'campaignsBackoffice:selectedCampaign.invoicingPeriods',
    }),
  ]
);

registerEventHandler(
  UPDATE_INVOICING_PERIOD,
  ({ state: { invoicingPeriods } }, { invoiceId, target: { name, value } }) => {
    const newInvoicingPeriods = cloneDeep(invoicingPeriods);
    const invoiceIndex = newInvoicingPeriods.findIndex(
      ({ id }) => id === invoiceId
    );

    newInvoicingPeriods[invoiceIndex][name] = value;
    return state.set({
      'campaignsBackoffice:selectedCampaign.invoicingPeriods':
        newInvoicingPeriods,
    });
  },
  [
    state.get({
      invoicingPeriods: 'campaignsBackoffice:selectedCampaign.invoicingPeriods',
    }),
  ]
);

registerEventHandler(
  FINISH_CAMPAIGN,
  ({ environment: { apiUrl } }, { campaignId, onSuccessEvent }) =>
    http.post({
      url: `${apiUrl}/backoffice/campaigns/${campaignId}/finish`,
      successEvent: {
        id: CAMPAIGN_FINISHED_SUCCESS,
        payload: { onSuccessEvent },
      },
      errorEvent: CAMPAIGN_FINISHED_FAILED,
    }),
  [environment()]
);

registerEventHandler(
  CAMPAIGN_FINISHED_SUCCESS,
  (_, [__, { onSuccessEvent }]) => ({
    ...effects.dispatch(onSuccessEvent),
    ...toast.show({ text: 'Campaign finished' }),
  })
);

registerEventHandler(CAMPAIGN_FINISHED_FAILED, () =>
  toast.show({ text: 'Finishing campaign failed' })
);
