import { tableRowsSelector } from '../../../../Tables/selectors';
import { UNITS_TABLE_NAME } from '../../../constants';

export function isBulkMenuOpenSelector(state) {
  return countSelectedViewableUnitsSelector(state) > 0;
}

export function isBulkCheckboxCheckedSelector(state) {
  const bulkUnitIds = selectedPropertiesBulkSelector(state);
  const viewableUnits = viewableUnitIdsSelector(state);
  if (viewableUnits.length === 0) {
    return false;
  }
  return viewableUnits.every((unit) => bulkUnitIds.includes(unit));
}

export function numberSelectedPropertiesBulkSelector(state) {
  return selectedPropertiesBulkSelector(state).length;
}

export function isUnitBulkCheckboxIndeterminateSelector(state) {
  if (isBulkCheckboxCheckedSelector(state)) {
    return false;
  }
  const selectedunitIds = selectedPropertiesBulkSelector(state);
  return viewableUnitIdsSelector(state).some((id) =>
    selectedunitIds.includes(id)
  );
}

export function selectedPropertiesBulkSelector(state) {
  return state['bulk:unitIds'] ?? [];
}

export function selectedViewableUnitsSelector(state) {
  const bulkUnitIds = selectedPropertiesBulkSelector(state);
  const viewableUnitIds = viewableUnitIdsSelector(state);
  return viewableUnitIds.filter((unitId) => bulkUnitIds.includes(unitId));
}

export function countSelectedViewableUnitsSelector(state) {
  return selectedViewableUnitsSelector(state).length;
}

export function isBulkActionInProgressAction(state) {
  return state['bulkV2:actionExecutionInProgress'] ?? false;
}

export function isUnitBulkSelectedSelector(state) {
  const selectedIds = selectedPropertiesBulkSelector(state);

  return (unitId) => selectedIds.includes(unitId);
}

export function areAllPropertiesChecked(state) {
  return (
    countSelectedViewableUnitsSelector(state) ===
    viewableUnitIdsSelector(state).length
  );
}

function viewableUnitIdsSelector(state) {
  return tableRowsSelector(state, UNITS_TABLE_NAME);
}
