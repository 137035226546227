import { effects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import { environment } from '../../../coeffects/environment';
import { toast } from '../../../effects/toast';
import { table } from '../../../partials/DataTable/effects/table';
import { SUBSCRIPTION_PLANS_TABLE_ID } from './constants';

export const SUBSCRIPTION_PLANS_MANAGEMENT_INIT =
  'SUBSCRIPTION_PLANS_MANAGEMENT_INIT';
export const PLAN_CHANGED = 'PLAN_CHANGED';
export const RESET_PLAN_FIELD = 'RESET_PLAN_FIELD';
export const PLANS_PRICES_REQUESTED = 'PLANS_PRICES_REQUESTED';
export const PLANS_PRICES_LOADED = 'PLANS_PRICES_LOADED';
export const PLANS_PRICES_FAILED = 'PLANS_PRICES_FAILED';
export const SUBSCRIPTION_PLANS_MANAGEMENT_COUNTRY_SELECTED =
  'SUBSCRIPTION_PLANS_MANAGEMENT_COUNTRY_SELECTED';
export const PLAN_PRICES_SUBMITTED_TABLE = 'PLAN_PRICES_SUBMITTED_TABLE';
export const PLAN_PRICES_SAVED_TABLE = 'PLAN_PRICES_SAVED_TABLE';
export const PLAN_PRICES_FAILED = 'PLAN_PRICES_FAILED';
export const PLANS_PRICES_CANCEL = 'PLANS_PRICES_CANCEL';
export const PLAN_CHANGED_TABLE = 'PLAN_CHANGED_TABLE';
export const PUBLISHES_IN_TP_SELECTED = 'PUBLISHES_IN_TP_SELECTED';

const originalFieldByField = {
  price: 'originalPrice',
  cpcPrice: 'originalCpcPrice',
};

export default function registerSubscriptionPlansManagementEvents() {
  registerEventHandler(
    SUBSCRIPTION_PLANS_MANAGEMENT_INIT,
    (_, { countryCode }) => ({
      ...state.set({
        formValidations: {},
        'plansManagement:selectedCountry': countryCode,
        'plansManagement:publishesInTP': false,
      }),
      ...effects.dispatch(PLANS_PRICES_REQUESTED),
    })
  );

  registerEventHandler(
    PLANS_PRICES_REQUESTED,
    ({ environment: { apiUrl }, state: { countryCode, publishesInTP } }) => {
      const url =
        countryCode === 'TH'
          ? `${apiUrl}/admin/plans?countryCode=${countryCode}&publishesInTP=${publishesInTP}`
          : `${apiUrl}/admin/plans?countryCode=${countryCode}`;
      return {
        ...state.set({
          'plansManagement:plans': [],
          'plansManagement:publishesInTP': publishesInTP,
        }),
        ...http.get({
          url,
          successEvent: PLANS_PRICES_LOADED,
          errorEvent: PLANS_PRICES_FAILED,
        }),
      };
    },
    [
      environment(),
      state.get({
        countryCode: 'plansManagement:selectedCountry',
        publishesInTP: 'plansManagement:publishesInTP',
      }),
    ]
  );

  registerEventHandler(PLANS_PRICES_LOADED, (_, [{ data: plans }]) =>
    state.set({
      'plansManagement:plans': plans.map((plan) => ({
        ...plan,
        originalPrice: plan.price,
        originalCpcPrice: plan.cpcPrice,
      })),
    })
  );

  registerEventHandler(PLANS_PRICES_FAILED, () =>
    state.set({
      'plansManagement:plans': [],
    })
  );

  registerEventHandler(PLAN_CHANGED_TABLE, (__, { planId, field, value }) => {
    if (value === '' || value === 0 || value == null) {
      return {};
    }

    return table.patchItem(SUBSCRIPTION_PLANS_TABLE_ID, {
      id: planId,
      [field]: value,
      hasBeenModified: true,
    });
  });

  registerEventHandler(
    PLAN_CHANGED,
    ({ state: { plans } }, { planId, field, value }) => {
      if (value === 0 || value == null) {
        return {};
      }
      const planToModifyIndex = findPlanIndexById(plans, planId);
      const planToModify = plans[planToModifyIndex];

      const modifiedPlan = { ...planToModify, [field]: value };
      modifiedPlan.hasBeenModified = hasPlanBeenModified(field, modifiedPlan);
      const updatedPlans = plans;
      updatedPlans[planToModifyIndex] = modifiedPlan;

      return state.set({
        'plansManagement:plans': updatedPlans,
      });
    },
    [state.get({ plans: 'plansManagement:plans' })]
  );

  registerEventHandler(
    RESET_PLAN_FIELD,
    ({ state: { plans } }, { planId, field }) => {
      const planToResetIndex = findPlanIndexById(plans, planId);
      const planToReset = plans[planToResetIndex];
      let modifiedPlan;

      if (field === 'price') {
        modifiedPlan = resetPlanByField(field, 'originalPrice', planToReset);
      }

      if (field === 'cpcPrice') {
        modifiedPlan = resetPlanByField(field, 'originalCpcPrice', planToReset);
      }

      if (modifiedPlan == null) {
        return {};
      }

      plans[planToResetIndex] = modifiedPlan;

      return state.set({ 'plansManagement:plans': plans });
    },
    [state.get({ plans: 'plansManagement:plans' })]
  );

  registerEventHandler(
    SUBSCRIPTION_PLANS_MANAGEMENT_COUNTRY_SELECTED,
    (_, selectedCountry) => {
      if (selectedCountry === 'TH') {
        return {
          ...state.set({
            'plansManagement:selectedCountry': selectedCountry,
            'plansManagement:publishesInTP': true,
          }),
          ...effects.dispatch(PLANS_PRICES_REQUESTED),
        };
      }
      return {
        ...state.set({
          'plansManagement:selectedCountry': selectedCountry,
        }),
        ...effects.dispatch(PLANS_PRICES_REQUESTED),
      };
    }
  );

  registerEventHandler(
    PLAN_PRICES_SUBMITTED_TABLE,
    ({ environment: { apiUrl }, select }, { onSuccessEvent }) => {
      const items = select.itemsById({
        collectionName: SUBSCRIPTION_PLANS_TABLE_ID,
      });
      const { publishesInTP } = select.filters({
        collectionName: SUBSCRIPTION_PLANS_TABLE_ID,
      });
      const plans = Object.values(items)
        .flatMap((x) =>
          Object.entries(x).map(([k, v]) => (k.startsWith('b') ? v : null))
        )
        .filter((x) => x != null);

      const modifiedPlans = plans.filter((x) => x.hasBeenModified);

      return http.put({
        url: `${apiUrl}/admin/plans`,
        body: { modifiedPlans, publishesInTP },
        successEvent: {
          id: PLAN_PRICES_SAVED_TABLE,
          payload: onSuccessEvent,
        },
        errorEvent: PLAN_PRICES_FAILED,
      });
    },
    [environment(), table.select()]
  );

  registerEventHandler(
    PLAN_PRICES_SAVED_TABLE,
    ({ state: { plans } }, [_, onSuccessEvent]) => ({
      ...state.set({
        'plansManagement:plans': plans.map((plan) => ({
          ...plan,
          originalPrice: plan.price,
          originalCpcPrice: plan.cpcPrice,
          hasBeenModified: false,
        })),
      }),
      ...toast.show({
        text: 'Succesfully changed plans prices',
      }),
      ...effects.dispatch(onSuccessEvent),
    }),
    [
      state.get({
        plans: 'plansManagement:plans',
      }),
    ]
  );

  registerEventHandler(PLAN_PRICES_FAILED, () =>
    toast.show({
      text: 'An error has occurred',
    })
  );

  registerEventHandler(
    PUBLISHES_IN_TP_SELECTED,
    ({ state: { publishesInTP } }) => ({
      ...state.set({
        'plansManagement:publishesInTP': publishesInTP,
      }),
      ...effects.dispatch(PLANS_PRICES_REQUESTED),
    }),
    [state.get({ publishesInTP: 'plansManagement:publishesInTP' })]
  );

  registerEventHandler(
    PLANS_PRICES_CANCEL,
    (
      { environment: { apiUrl }, state: { countryCode, publishesInTP } },
      { updateTable }
    ) => {
      const url =
        countryCode === 'TH'
          ? `${apiUrl}/admin/plans?countryCode=${countryCode}&publishesInTP=${publishesInTP}`
          : `${apiUrl}/admin/plans?countryCode=${countryCode}`;
      return {
        ...state.set({
          'plansManagement:plans': [],
          'plansManagement:publishesInTP': publishesInTP,
        }),
        ...http.get({
          url,
          successEvent: PLANS_PRICES_LOADED,
          errorEvent: PLANS_PRICES_FAILED,
        }),
        ...effects.dispatch(updateTable),
      };
    },
    [
      environment(),
      state.get({
        countryCode: 'plansManagement:selectedCountry',
        publishesInTP: 'plansManagement:publishesInTP',
      }),
    ]
  );
}

function hasPlanBeenModified(field, modifiedPlan) {
  return (
    modifiedPlan[field] !== modifiedPlan[originalFieldByField[field]] &&
    modifiedPlan[field] !== null
  );
}

function resetPlanByField(field, originalField, plan) {
  let modifiedPlan;

  if (plan[field] === '') {
    modifiedPlan = {
      ...plan,
      [field]: plan[originalFieldByField[field]],
    };
  }
  return modifiedPlan;
}

function findPlanIndexById(plans, planId) {
  return plans.findIndex((plan) => String(plan.id) === String(planId));
}
