import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { localStorage } from '../../effects/localStorage';
import { analytics } from '../../effects/analytics';

export const INTERSTITIAL_CLOSED = 'INTERSTITIAL_CLOSED';

export default function registerInterstitialEvents() {
  registerEventHandler(INTERSTITIAL_CLOSED, () => ({
    ...analytics.trackClick('interstitial-continue-desktop'),
    ...localStorage.setItem('interstitialClosed', 'true'),
    ...state.set({
      interstitialClosed: true,
    }),
  }));
}
