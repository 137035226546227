import { registerEffectHandler } from 'reffects';
import { TOAST_BOTTOM_POSITION } from '../partials/Toast/selectors';

const SHOW_TOAST = 'toast';
const HIDE_TOAST = 'hideToast';

export default function registerToastEffect(store, timer) {
  function showToast(text, values, verticalPosition) {
    store.setState({
      path: ['toast'],
      newValue: {
        text,
        values,
        position: { vertical: verticalPosition, horizontal: 'center' },
        visible: true,
      },
    });
  }

  function hideToast(verticalPosition) {
    const { toast: { visible: alreadyShown, timeoutId: toastTimeoutId } = {} } =
      store.getState();
    if (alreadyShown) {
      timer.clear(toastTimeoutId);
      store.setState({
        path: ['toast'],
        newValue: {
          text: '',
          values: {},
          visible: false,
          position: { vertical: verticalPosition, horizontal: 'center' },
          timeoutId: null,
        },
      });
    }
  }

  registerEffectHandler(
    SHOW_TOAST,
    ({ text, values, milliseconds, verticalPosition }) => {
      hideToast(verticalPosition);
      showToast(text, values, verticalPosition);

      const timeoutId = timer.set(
        () => hideToast(verticalPosition),
        milliseconds
      );

      store.setState({ path: ['toast', 'timeoutId'], newValue: timeoutId });
    }
  );

  registerEffectHandler(HIDE_TOAST, () => hideToast(TOAST_BOTTOM_POSITION));
}

export const toast = {
  show({
    text,
    values = {},
    milliseconds = 3000,
    verticalPosition = TOAST_BOTTOM_POSITION,
  }) {
    return {
      [SHOW_TOAST]: {
        text,
        values,
        milliseconds,
        verticalPosition,
      },
    };
  },
  hide() {
    return { [HIDE_TOAST]: {} };
  },
};
