import { useCallback } from 'react';
import { dispatch } from 'reffects';
import { TRACK_CLICK } from '../partials/Tracking/events';

export default function useClickTracking({ sectionName }) {
  const trackClick = useCallback(
    ({ eventName }) => {
      dispatch({
        id: TRACK_CLICK,
        payload: {
          eventName,
          sectionName,
        },
      });
    },
    [sectionName]
  );

  return { trackClick };
}
