import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import DropdownMenu from 'design-system/components/DropdownMenu';
import { DROPDOWN_POSITION } from 'design-system/components/Dropdown';
import Menu from 'design-system/components/Menu/Menu';
import { OutlineButton } from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { UNITS_FILTERED_BY_UNIT_TYPE } from './events';
import { propertyTypesWithTranslationTagsSelector } from '../../../../selectors/config';
import { unitTypeFilterOptionSelector } from './selectors';

function UnitTypeFilter() {
  const selectedOption = useSelector(unitTypeFilterOptionSelector);
  const options = useSelector(propertyTypesWithTranslationTagsSelector);

  return (
    <DropdownMenu
      position={DROPDOWN_POSITION.BOTTOM_LEFT}
      active={Boolean(selectedOption)}
      button={
        <OutlineButton icon={<Icon glyph="chevronDown" />}>
          <FormattedMessageWithValidation
            id={options[selectedOption] || 'filter_units_type_default'}
          />
        </OutlineButton>
      }
      onConfirm={({ value }) =>
        dispatch({
          id: UNITS_FILTERED_BY_UNIT_TYPE,
          payload: { value },
        })
      }
    >
      <Menu>
        <Menu.Item
          id="filter_all_unit_types"
          label={
            <Body1 $noWrap>
              <FormattedMessageWithValidation id="filter_units_operation_type_all" />
            </Body1>
          }
        />
        {Object.entries(options).map(([value, label]) => (
          <Menu.Item
            key={value}
            id={value}
            label={
              <Body1 $noWrap>
                <FormattedMessageWithValidation id={label} />
              </Body1>
            }
            value={value}
          />
        ))}
      </Menu>
    </DropdownMenu>
  );
}

export default UnitTypeFilter;
