import { useMount } from 'react-use';
import { dispatch } from 'reffects';
import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import Anchor from 'design-system/components/Anchor';
import Illustration from 'design-system/components/Illustration/Illustration';
import { Subtitle1 } from 'design-system/components/Typography/presets/Subtitle1';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import useTracking from '../../../../hooks/useTracking';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  checkoutFailedRoute,
  checkoutPaymentMethodRoute,
  checkoutSuccessRoute,
} from '../../../../utils/proppitWebRouter';
import { CHECKOUT_FIRST_PAYMENT_PENDING_PAGE_MOUNTED } from './events';
import {
  checkoutSubRouteSelector,
  checkoutTranslationTagSelector,
} from '../../configuration/selectors';
import { TRANSLATION_NAMES } from '../../configuration/constants';

const Title = styled(Subtitle1)`
  display: block;
  ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
  ${spacing.stack(SPACING_SIZE.M)}
`;

const WelcomeIllustration = styled(Illustration)`
  margin: auto;
  ${spacing.stack(SPACING_SIZE.L)}
`;

export default function Pending({ subscriptionChangeRequestId }) {
  const subRoute = useSelector(checkoutSubRouteSelector);
  useMount(() => {
    dispatch({
      id: CHECKOUT_FIRST_PAYMENT_PENDING_PAGE_MOUNTED,
      payload: {
        subscriptionChangeRequestId,
        successUrl: checkoutSuccessRoute({
          subscriptionChangeRequestId,
          subRoute,
        }),
        failedUrl: checkoutFailedRoute({
          subscriptionChangeRequestId,
          subRoute,
        }),
      },
    });
  });
  const ref = useTracking({
    trackClick: {
      eventName: 'retry-payment',
      payload: { subscriptionChangeRequestId },
    },
    trackImpression: {
      elementName: 'retry-payment-btn',
      payload: { subscriptionChangeRequestId },
    },
    sectionName: 'first-checkout-pending-confirmation',
  });
  const payByLinkConfirmationTag = useSelector((state) =>
    checkoutTranslationTagSelector(
      state,
      TRANSLATION_NAMES.PAY_BY_LINK_CONFIRMATION
    )
  );

  return (
    <>
      <Title>
        <FormattedMessageWithValidation id="checkout_paybylink_confirmation_title" />
      </Title>
      <WelcomeIllustration name="welcome" />
      <Body1>
        <FormattedMessageWithValidation
          id={payByLinkConfirmationTag}
          values={{
            breakingLine: <br />,
          }}
        />{' '}
        <Anchor
          $weight={FONT_WEIGHT.BOLD}
          href={checkoutPaymentMethodRoute({
            subscriptionChangeRequestId,
            subRoute,
          })}
          ref={ref}
        >
          <FormattedMessageWithValidation id="checkout_paybylink_confirmation_body2" />
        </Anchor>
      </Body1>
    </>
  );
}
