import { http } from 'reffects-batteries';
import { state } from 'reffects-store';

function request({ apiUrl, successEvent, errorEvent }) {
  return http.get({
    url: `${apiUrl}/users/me`,
    successEvent,
    errorEvent,
  });
}

export function setIntoState({
  id,
  email,
  publisherId,
  acceptedTermsAndConditions,
  role,
  hasActiveFirstPaymentCheckout,
  firstPaymentSubscriptionChangeRequestId,
  managesMultiplePublishers,
  managedCountries,
  askChurnReason,
  churnPollAttempts,
}) {
  const userData = {
    'publisher:id': publisherId,
    user: {
      id,
      email,
      role,
      acceptedTermsAndConditions,
      managesMultiplePublishers,
      managedCountries,
    },
    'publisher:hasActiveFirstPaymentCheckout': hasActiveFirstPaymentCheckout,
    'publisher:firstPaymentSubscriptionChangeRequestId':
      firstPaymentSubscriptionChangeRequestId,
    'publisher:askChurnReason': askChurnReason,
    'publisher:churnPollAttempts': churnPollAttempts,
  };

  return state.set(userData);
}

export default { request, setIntoState };
