import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import { dispatch } from 'reffects';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { BORDER_SIZE } from 'design-system/styles/border';
import Illustration from 'design-system/components/Illustration/Illustration';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Overline } from 'design-system/components/Typography/presets/Overline';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import {
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import Typography from 'design-system/components/Typography/Typography';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { bankTransferDetailsSelector } from '../../../../../selectors/config';
import FileUploader from './FileUploader';
import { FILE_DELETED, FILE_UPLOADED } from './events';
import {
  bankTransferConceptSelector,
  bankTransferReceiptFileSizeLimitExceededErrorSelector,
  bankTransferReceiptSelector,
} from './selectors';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { ACCEPTED_FILE_EXTENSIONS } from './constants';
import { checkoutTranslationTagSelector } from '../../../configuration/selectors';
import { TRANSLATION_NAMES } from '../../../configuration/constants';
import InformationBlock from '../../../partials/InformationBlock';
import { COPY_TEXT_TO_CLIPBOARD } from '../../../../../events';
import { isPublisherFromCountrySelector } from '../../../../../selectors/publisher';

const Wrapper = styled.div`
  flex-grow: 2;

  ${InformationBlock} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    ${spacing.stack(SPACING_SIZE.M, false)}
    > * {
      ${spacing.stack(SPACING_SIZE.XS)}
    }
  }
`;

const CompanyName = styled.div`
  display: flex;
  flex-direction: row;
  > * {
    ${spacing.inline(SPACING_SIZE.S)}
  }
  ${Icon} {
    cursor: pointer;
  }
`;

const Block = styled.div`
  ${spacing.stack(SPACING_SIZE.M)}
  ${Overline} {
    display: inline-block;
  }

  > * {
    ${spacing.stack(SPACING_SIZE.S)}
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: row;
  ${spacing.stack(SPACING_SIZE.M, false)}

  > * {
    ${spacing.inline(SPACING_SIZE.M)}
  }

  > div {
    flex-grow: 2;
    flex-shrink: 1;
  }

  ${Illustration} {
    flex-shrink: 0;
  }
`;

const DetailLine = styled.div`
  ${spacing.insetSandwich(SPACING_SIZE.S)}
  border-bottom: ${BORDER_SIZE.BASE}px solid ${COLOR_PALETTE.NEUTRAL_A10};
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;

  &:last-child {
    border: none;
  }

  ${Body1} {
    ${typography.weight(FONT_WEIGHT.LIGHT)}
  }
`;

const TransferNotice = styled(Typography)`
  ${typography.size(FONT_SIZE.XS)}
  ${typography.weight(FONT_WEIGHT.NORMAL)}
  ${typography.lineHeight(12)}
  ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
`;

const MaxFileSizeLimitExceededNotice = styled(Typography)`
  display: block;
  margin-top: ${spacing.value(SPACING_SIZE.S)};
  ${typography.size(FONT_SIZE.S)}
  ${color.text(COLOR_PALETTE.STRAWBERRY_BASE)}
`;

function BankTransfer(props) {
  const bankTransferDetails = useSelector(bankTransferDetailsSelector);
  const bankTransferReceipt = useSelector(bankTransferReceiptSelector);
  const bankTransferReceiptFileSizeLimitExceededError = useSelector(
    bankTransferReceiptFileSizeLimitExceededErrorSelector
  );
  const bankTransferNoticeTag = useSelector((state) =>
    checkoutTranslationTagSelector(
      state,
      TRANSLATION_NAMES.BANK_TRANSFER_NOTICE
    )
  );
  const legalName = useSelector(bankTransferConceptSelector);
  const showQr = useSelector((state) =>
    isPublisherFromCountrySelector(state, 'TH')
  );
  return (
    <Wrapper {...props}>
      <InformationBlock>
        <Body2>
          <FormattedMessageWithValidation id="checkout_bank_transfer_details_concept" />
        </Body2>
        <CompanyName>
          <Subtitle3>{legalName}</Subtitle3>
          <Icon
            glyph="copy"
            $size={ICON_SIZE.XSMALL}
            onClick={() =>
              dispatch({
                id: COPY_TEXT_TO_CLIPBOARD,
                payload: legalName,
              })
            }
          />
        </CompanyName>
      </InformationBlock>
      <Block>
        <Overline>
          <FormattedMessageWithValidation id="checkout_bank_transfer_details_title" />
        </Overline>
        <Details>
          <div>
            <DetailLine>
              <Subtitle3>
                <FormattedMessageWithValidation id="checkout_bank_transfer_details_payment_reference" />
              </Subtitle3>
              <CompanyName>
                <Body1>{legalName}</Body1>
                <Icon
                  glyph="copy"
                  $size={ICON_SIZE.XSMALL}
                  onClick={() =>
                    dispatch({
                      id: COPY_TEXT_TO_CLIPBOARD,
                      payload: legalName,
                    })
                  }
                />
              </CompanyName>
            </DetailLine>
            {bankTransferDetails.map(({ name, value }) => (
              <DetailLine key={name}>
                <Subtitle3>{name}</Subtitle3>
                <Body1>{value}</Body1>
              </DetailLine>
            ))}
          </div>
          {showQr && <Illustration name="thailandBankTransferQrSmall" />}
        </Details>
      </Block>
      <Block>
        <Overline>
          <FormattedMessageWithValidation id="checkout_bank_transfer_receipt_title" />
        </Overline>
        <FileUploader
          id="bank-transfer"
          value={bankTransferReceipt}
          accept={ACCEPTED_FILE_EXTENSIONS.join(', ')}
          onFileUploaded={(file) =>
            dispatch({ id: FILE_UPLOADED, payload: { file } })
          }
          onFileDeleted={() => dispatch(FILE_DELETED)}
          inputMessage={
            <FormattedMessageWithValidation id="checkout_file_upload_processing" />
          }
        />
        <TransferNotice>
          <FormattedMessageWithValidation id={bankTransferNoticeTag} />
        </TransferNotice>
        {bankTransferReceiptFileSizeLimitExceededError && (
          <MaxFileSizeLimitExceededNotice>
            <FormattedMessageWithValidation id="checkout_bank_transfer_filesize" />
          </MaxFileSizeLimitExceededNotice>
        )}
      </Block>
    </Wrapper>
  );
}

export default styled(BankTransfer)``;
