import { publisherSelector } from '../../../../selectors/publisher';

export function bankTransferConceptSelector(state) {
  const publisher = publisherSelector(state);
  if (!publisher) {
    return null;
  }
  const { billingEntityName, hasGenericBillingEntity, name } = publisher;
  return hasGenericBillingEntity ? name : billingEntityName;
}
