import * as yup from 'yup';
import { number, string } from 'yup';

import { useSelector } from 'reffects-store';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { publisherCountrySelector } from '../../../../selectors/publisher';
import { callingCodeSelector } from '../../../publication/selectors';
import WhatsappField from '../../../../partials/fields/WhatsappField';
import GenericDetailForm from './GenericDetailForm';
import useInternationalization from '../../../../hooks/useInternationalization';
import { extractCountryAndNumber } from '../../../../partials/fields/ExtractCountryAndNumber';

function WhatsappDetailForm({ detail, onSubmit, onCancel }) {
  const intl = useInternationalization();
  const callingCode = useSelector(callingCodeSelector);
  const countryCode = useSelector(publisherCountrySelector);
  const defaultWhatsappCallingCode = useSelector(callingCodeSelector);

  const handleFormSubmit = ({ contactWhatsApp }) => {
    onSubmit(`${contactWhatsApp.dialCode}${contactWhatsApp.phoneNumber}`);
  };

  const phoneUtil = PhoneNumberUtil.getInstance();

  return (
    <GenericDetailForm
      defaultValues={{
        contactWhatsAppCallingCode: defaultWhatsappCallingCode,
        contactWhatsApp: extractCountryAndNumber(detail.value),
      }}
      validationSchema={{
        contactWhatsApp: yup
          .object({
            phoneNumber: number().required(
              intl.formatMessageWithValidation({
                id: 'form_validations_required_field',
              })
            ),
            countryCode: string().required(),
          })
          .test({
            name: 'contactWhatsapp',
            message: intl.formatMessageWithValidation({
              id: 'form_validations_invalid_phone_number',
            }),
            test: (value) => {
              try {
                const phoneWithPrefix = `${value.dialCode}${value.phoneNumber}`;
                return phoneUtil.isValidNumber(
                  phoneUtil.parseAndKeepRawInput(
                    phoneWithPrefix,
                    value.countryCode
                  )
                );
              } catch (e) {
                return false;
              }
            },
          }),
      }}
      onSubmit={handleFormSubmit}
      onCancel={onCancel}
    >
      <WhatsappField
        name="contactWhatsApp"
        callingCodeChoices={[
          {
            label: callingCode,
            value: callingCode,
          },
        ]}
        phoneRegionCode={countryCode}
      />
    </GenericDetailForm>
  );
}

export default WhatsappDetailForm;
