import styled from 'styled-components';
import Phases from './Phases';
import Section from '../Section';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Wrapper = styled(Section)`
  display: flex;
  flex-direction: column;
`;

function Status({ project, ...props }) {
  return (
    <Wrapper
      title={
        <FormattedMessageWithValidation id="project_detail_stages_title" />
      }
      {...props}
    >
      <div>
        <Phases project={project} />
      </div>
    </Wrapper>
  );
}

export default styled(Status)``;
