import styled from 'styled-components';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  ${spacing.stack(SPACING_SIZE.S)}

  ${Icon} {
    ${Icon.mods.size(ICON_SIZE.SMALL)}
    ${spacing.inline(SPACING_SIZE.S)}
    ${color.text(COLOR_PALETTE.SECONDARY_A40)}
  }
`;

function Feature({ className, icon, children }) {
  return (
    <Wrapper className={className}>
      {icon} <Body1 $weight={FONT_WEIGHT.LIGHT}>{children}</Body1>
    </Wrapper>
  );
}

export default styled(Feature)``;
