import { useIntl } from 'react-intl';
import Section from 'design-system/components/Section';
import InputRow from 'design-system/components/InputRow';
import TextField from '../../../partials/fields/TextField';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { FieldGroup } from './FieldGroup';
import ImageUploader from './ImageUploader';

export default function MediaGroup({ order }) {
  const intl = useIntl();
  return (
    <FieldGroup
      id="media"
      title={<FormattedMessageWithValidation id="ad_form_group_media" />}
      order={order}
    >
      <Section
        title={<FormattedMessageWithValidation id="newprop_pics_header" />}
      >
        <InputRow>
          <ImageUploader
            id="property-images-uploader"
            name="propertyImages"
            maxNumberOfImages={200}
            helperText={
              <FormattedMessageWithValidation id="order_photos_helper" />
            }
            hasMainImage
          />
        </InputRow>
      </Section>
      <Section
        title={<FormattedMessageWithValidation id="propspage_floor_plan" />}
      >
        <InputRow>
          <ImageUploader
            id="floor-plans-uploader"
            name="floorPlans"
            maxNumberOfImages={10}
            helperText={
              <FormattedMessageWithValidation id="propspage_floor_plan_tip" />
            }
          />
        </InputRow>
      </Section>
      <InputRow>
        <TextField
          label="Youtube Url"
          placeholder={intl.formatMessage({
            id: 'propspage_placeholder_youtube_url',
          })}
          name="video"
        />
      </InputRow>
      <InputRow>
        <TextField
          label={
            <FormattedMessageWithValidation id="propspage_virtual_tour_url" />
          }
          placeholder={intl.formatMessage({
            id: 'propspage_placeholder_virtual_tour_url',
          })}
          name="virtualTours"
        />
      </InputRow>
    </FieldGroup>
  );
}
