import { dispatch } from 'reffects';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Layout from 'design-system/components/Layout';
import Grid from 'design-system/components/Grid';
import { Navigation } from '../../../partials';
import Sidebar from '../partials/Sidebar';
import { useUnitValidationSchema } from '../validationSchema';
import { useDefaultValues } from '../useDefaultValues';
import { FieldGroupProvider } from '../partials/FieldGroup';
import MediaGroup from '../partials/MediaGroup';
import PropertyFacilitiesGroup from '../partials/PropertyFacilitiesGroup';
import ContactDetailsGroup from '../partials/ContactDetailsGroup';
import PropertyDescriptionGroup from '../partials/PropertyDescriptionGroup';
import HiddenField from '../../../partials/fields/HiddenField';
import ProjectDetailsGroup from './partials/ProjectDetailsGroup';
import UnitDetailsGroup from './partials/UnitDetailsGroup';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { UNIT_SUBMITTED } from './events';
import { useInViewportWatcher } from '../../../hooks/useViewportWatcher';
import AnimationFadeSlideOut from '../../../partials/AnimationFadeSlideOut';
import PublishBanner from '../partials/PublishBanner';

function UnitForm({ values }) {
  const {
    isInViewport: isLargePublishBannerInViewport,
    watcherRef: largePublishBannerRef,
  } = useInViewportWatcher();
  const schema = useUnitValidationSchema();
  const defaultValues = useDefaultValues(values);
  const isNewUnit = !values;

  const methods = useForm({
    defaultValues,
    shouldUnregister: true,
    criteriaMode: 'all',
    resolver: yupResolver(schema),
  });

  const { handleSubmit } = methods;

  const handlePublishSubmit = handleSubmit((data) => {
    dispatch({
      id: UNIT_SUBMITTED,
      payload: { ...data, published: true, isNew: isNewUnit },
    });
  });
  const handleDraftSubmit = handleSubmit((data) => {
    dispatch({ id: UNIT_SUBMITTED, payload: { ...data, published: false } });
  });

  return (
    <FormProvider {...methods}>
      <FieldGroupProvider>
        <Layout independentScroll>
          <Layout.Header>
            <Navigation />
          </Layout.Header>
          <Layout.LeftSidebar>
            <Sidebar
              title={
                isNewUnit ? (
                  <FormattedMessageWithValidation id="ad_form_title_developers" />
                ) : (
                  <FormattedMessageWithValidation id="ad_form_edit_title_developers" />
                )
              }
              description={
                <FormattedMessageWithValidation id="ad_form_sidebar_inspirational_description_developers" />
              }
            >
              <AnimationFadeSlideOut $in={!isLargePublishBannerInViewport}>
                <PublishBanner
                  networkBrandsMessage={
                    <FormattedMessageWithValidation id="newunit_networkbrands" />
                  }
                  onDraftSubmit={handleDraftSubmit}
                  onPublishSubmit={handlePublishSubmit}
                />
              </AnimationFadeSlideOut>
            </Sidebar>
          </Layout.LeftSidebar>
          <Layout.Content>
            <Layout.Padded>
              <Grid
                areas={({ repeat }) =>
                  `"${repeat(6, 'form')} ${repeat(3, '.')}"`
                }
              >
                <Grid.Area $id="form">
                  <form onSubmit={handlePublishSubmit}>
                    <input hidden type="submit" />
                    <HiddenField name="id" />
                    <HiddenField name="creationDate" />
                    <ProjectDetailsGroup order={1} />
                    <UnitDetailsGroup order={2} />
                    <MediaGroup order={3} />
                    <PropertyFacilitiesGroup order={4} />
                    <PropertyDescriptionGroup
                      order={5}
                      fieldGroupTitle={
                        <FormattedMessageWithValidation id="unit_form_group_description" />
                      }
                      sectionTitle={
                        <FormattedMessageWithValidation id="unit_form_section_unit_identification" />
                      }
                    />
                    <ContactDetailsGroup order={6} />
                  </form>
                </Grid.Area>
              </Grid>
            </Layout.Padded>
            <Layout.Padded ref={largePublishBannerRef} $contrast>
              <PublishBanner
                networkBrandsMessage={
                  <FormattedMessageWithValidation id="newprop_networkbrands" />
                }
                onDraftSubmit={handleDraftSubmit}
                onPublishSubmit={handlePublishSubmit}
                $layout="inline"
              />
            </Layout.Padded>
          </Layout.Content>
        </Layout>
      </FieldGroupProvider>
    </FormProvider>
  );
}

export default UnitForm;
