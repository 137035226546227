import { withoutEventPropagation } from 'design-system/utils/withoutEventPropagation';
import { useCallback } from 'react';
import { dispatch } from 'reffects';
import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { FormattedList } from 'react-intl';
import Table from 'design-system/components/Table';
import Tooltip from 'design-system/components/Tooltip';
import { FlatButton } from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';
import { useColumn } from '../../../partials/DataTable/hooks/useColumn';
import { useDialog } from '../../../hooks/useDialog';
import { TRACK_CLICK } from '../../../partials/Tracking/events';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  adHasBannedBrandsSelector,
  numberOfSitesPublishingForAdSelector,
  siteDetailLinksWithBannedBrandsForAdSelector,
  siteWherePublishingNamesSelector,
} from '../../../selectors/config';
import DropdownSitesMenu from '../../../partials/DropdownSitesMenu';
import {
  hasBoostingEnabledSelector,
  hasSuperboostSubscriptionSelector,
} from '../../../selectors/publisher';
import { isBoostableSelector } from './selectors';
import noop from '../../../utils/noop';
import { useFeatureForPublisherCountrySelector } from '../../../utils/featuresFlags/selectors';
import { GENERIC_CONFIRMATION_MODAL } from '../../../partials/modals/generic/constants';

const RemovePropertyButton = styled(FlatButton)``;

const Wrapper = styled(Table.Cell)`
  ${RemovePropertyButton} {
    visibility: hidden;
  }

  ${Table.Row}:hover & ${RemovePropertyButton} {
    visibility: initial;
  }
`;

const NotBoostableSuperboostedButton = styled(FlatButton)`
  cursor: not-allowed;
`;

function BoostAction({
  boosted,
  superboosted,
  published,
  updateItem,
  sitesPublishingBoosted,
  openDialog,
}) {
  const isBoostable = useSelector(isBoostableSelector);
  const handleUnboost = useCallback(() => {
    openDialog({
      messages: {
        title: {
          id: 'Unboost_Confirmation_Modal_title',
        },
        body: {
          id: 'Unboost_Confirmation_Modal_body',
          values: {
            networkSites: (
              <FormattedList
                type="conjunction"
                value={sitesPublishingBoosted}
              />
            ),
          },
        },
      },
      actions: {
        confirm: () =>
          updateItem({
            action: 'unboost',
          }),
      },
    });
    dispatch({
      id: TRACK_CLICK,
      payload: {
        eventName: 'unboost-listing-attempt',
        sectionName: 'listings-table-item-boosting-toggle',
      },
    });
  }, [updateItem, sitesPublishingBoosted]);

  const handleBoost = useCallback(() => {
    updateItem({
      action: 'boost',
    });
  }, [updateItem]);

  const handleBoostButtonClick = useCallback(() => {
    if (boosted) {
      handleUnboost();
    } else {
      handleBoost();
    }
  }, [boosted]);

  if (!isBoostable && superboosted) {
    return (
      <NotBoostableSuperboostedButton
        active
        icon={<Icon glyph="bolt" />}
        onClick={(e) => {
          e.stopPropagation();
          return noop();
        }}
      />
    );
  }

  return (
    <Tooltip
      title={
        <FormattedMessageWithValidation
          id={getBoostingTooltipText(boosted, published)}
        />
      }
    >
      <FlatButton
        active={boosted}
        disabled={!published}
        icon={<Icon glyph={boosted ? 'bolt' : 'boltCrossed'} />}
        onClick={withoutEventPropagation(handleBoostButtonClick)}
      />
    </Tooltip>
  );
}

function SuperboostAction({
  superboosted,
  published,
  updateItem,
  sitesPublishingBoosted,
  openDialog,
}) {
  const handleUnsuperboost = useCallback(() => {
    openDialog({
      messages: {
        title: {
          id: 'superboosting_unboost_older_modal_title',
        },
        body: {
          id: 'superboosting_unboost_older_modal_text',
          values: {
            networkSites: (
              <FormattedList
                type="conjunction"
                value={sitesPublishingBoosted}
              />
            ),
          },
        },
      },
      actions: {
        confirm: () =>
          updateItem({
            action: 'unsuperboost',
          }),
      },
    });
    dispatch({
      id: TRACK_CLICK,
      payload: {
        eventName: 'unsuperboost-listing-attempt',
        sectionName: 'listings-table-item-superboosting-toggle',
      },
    });
  }, [updateItem, sitesPublishingBoosted]);

  const handleSuperboost = useCallback(() => {
    updateItem({
      action: 'superboost',
    });
  }, [updateItem]);
  const handleClick = useCallback(
    () => (superboosted ? handleUnsuperboost() : handleSuperboost()),
    [superboosted]
  );
  return (
    <Tooltip
      title={
        <FormattedMessageWithValidation
          id={getSuperboostingTooltipText(superboosted, published)}
        />
      }
    >
      <FlatButton
        active={superboosted}
        disabled={!published}
        icon={<Icon glyph="superboost" />}
        onClick={withoutEventPropagation(handleClick)}
      />
    </Tooltip>
  );
}

function PublishAction({
  published,
  updateItem,
  sitesPublishingBoosted,
  openDialog,
}) {
  const handleUnpublish = useCallback(() => {
    openDialog({
      messages: {
        title: {
          id: 'notification_visibility_disable_header',
        },
        body: {
          id: 'notification_visibility_disable_description_with_sites',
          values: {
            networkSites: (
              <FormattedList
                type="conjunction"
                value={sitesPublishingBoosted}
              />
            ),
          },
        },
      },
      actions: {
        confirm: () =>
          updateItem({
            action: 'unpublish',
          }),
      },
    });
    dispatch({
      id: TRACK_CLICK,
      payload: {
        eventName: 'publish-listing-attempt',
        sectionName: 'listings-table-item-elipsis-actions-option',
      },
    });
  }, [updateItem, sitesPublishingBoosted]);

  const handlePublish = useCallback(() => {
    updateItem({
      action: 'publish',
    });
  }, [updateItem]);

  const handlePublishButtonClick = useCallback(() => {
    if (published) {
      handleUnpublish();
    } else {
      handlePublish();
    }
  }, [published]);

  return (
    <Tooltip
      title={
        <FormattedMessageWithValidation
          id={getPublishingTooltipText(published)}
        />
      }
    >
      <FlatButton
        icon={<Icon glyph={published ? 'eye' : 'eyeCrossed'} />}
        onClick={withoutEventPropagation(handlePublishButtonClick)}
      />
    </Tooltip>
  );
}

function DeleteAction({ updateItem, openDialog }) {
  const handleDeleteButtonClick = useCallback(() => {
    openDialog({
      messages: {
        title: {
          id: 'modal_delete_title',
        },
        body: {
          id: 'modal_delete_body',
        },
      },
      actions: {
        confirm: () =>
          updateItem({
            action: 'delete',
          }),
      },
    });
    dispatch({
      id: TRACK_CLICK,
      payload: {
        eventName: 'delete-listing-attempt',
        sectionName: 'listings-table-item-elipsis-actions-option',
      },
    });
  }, []);

  return (
    <Tooltip
      title={
        <FormattedMessageWithValidation id="properties_list_remove_property" />
      }
    >
      <RemovePropertyButton
        icon={<Icon glyph="trash" />}
        onClick={withoutEventPropagation(handleDeleteButtonClick)}
        data-tag="delete_property_button"
      />
    </Tooltip>
  );
}

export function ActionsColumn(props) {
  const hasBoostingEnabled = useSelector(hasBoostingEnabledSelector);
  const SUPERBOOST_ALL_COUNTRIES_3119 = useFeatureForPublisherCountrySelector(
    'SUPERBOOST_ALL_COUNTRIES_3119'
  );
  const hasSuperboostSubscription = useSelector(
    hasSuperboostSubscriptionSelector
  );
  const sitesPublishingBoosted = useSelector(siteWherePublishingNamesSelector);
  const {
    value: { published, superboosted, boosted, id },
    updateItem,
  } = useColumn(props);
  const sitesWithBannedBrands = useSelector(
    siteDetailLinksWithBannedBrandsForAdSelector
  )({
    id,
    isBoosted: boosted,
  });
  const hasBannedBrands = useSelector((state) =>
    adHasBannedBrandsSelector(state, boosted)
  );
  const numberOfSitesPublishing = useSelector((state) =>
    numberOfSitesPublishingForAdSelector(state, {
      id,
      isBoosted: boosted,
    })
  );
  const { openDialog } = useDialog(GENERIC_CONFIRMATION_MODAL);

  return (
    <Wrapper>
      {hasBoostingEnabled && (
        <BoostAction
          boosted={boosted}
          superboosted={superboosted}
          published={published}
          sitesPublishingBoosted={sitesPublishingBoosted}
          openDialog={openDialog}
          updateItem={updateItem}
        />
      )}
      {(SUPERBOOST_ALL_COUNTRIES_3119 || hasSuperboostSubscription) && (
        <SuperboostAction
          superboosted={superboosted}
          published={published}
          updateItem={updateItem}
          sitesPublishingBoosted={sitesPublishingBoosted}
          openDialog={openDialog}
        />
      )}
      <PublishAction
        published={published}
        sitesPublishingBoosted={sitesPublishingBoosted}
        openDialog={openDialog}
        updateItem={updateItem}
      />
      <DropdownSitesMenu
        active={published}
        sites={sitesWithBannedBrands}
        numberOfSites={numberOfSitesPublishing}
        hasBannedBrands={hasBannedBrands}
        section="properties-table-site-links"
        elementName="property-site-link"
        eventName="click-property-site-link"
      />
      <DeleteAction openDialog={openDialog} updateItem={updateItem} />
    </Wrapper>
  );
}

function getBoostingTooltipText(boosted, published) {
  if (!published && !boosted) return 'properties_list_ad_unavailable_to_boost';
  if (!boosted) return 'properties_list_boost_ad';
  return 'properties_list_unboost_ad';
}

function getSuperboostingTooltipText(boosted, published) {
  if (!published && !boosted)
    return 'properties_list_ad_unavailable_to_superboost';
  if (!boosted) return 'properties_list_superboost_ad';
  return 'properties_list_unsuperboost_ad';
}

function getPublishingTooltipText(published) {
  return !published ? 'item_menu_publish_ad' : 'item_menu_unpublish_ad';
}
