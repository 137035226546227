import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { PrimaryButton } from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Typography, {
  TYPOGRAPHY_VARIANT,
} from 'design-system/components/Typography/Typography';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';
import { LOGIN } from '../../../constants/routes';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${Heading1} {
    ${spacing.stack(SPACING_SIZE.M)}
  }
`;

const Message = styled(Typography)`
  ${Typography.mods.variant(TYPOGRAPHY_VARIANT.BODY_1)}
  ${typography.weight(FONT_WEIGHT.LIGHT)}
  ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}

  ${spacing.stack(SPACING_SIZE.XL)}
`;

function PasswordChangedConfirmation() {
  return (
    <Wrapper>
      <Heading1>
        <FormattedMessageWithValidation id="createpwdpage_success_header" />
      </Heading1>
      <Message>
        <FormattedMessageWithValidation id="createpwdpage_success_body" />
      </Message>
      <PrimaryButton $size={BUTTON_SIZE.LARGE} forwardedAs={Link} to={LOGIN}>
        <FormattedMessageWithValidation id="createpwdpage_success_login_cta" />
      </PrimaryButton>
    </Wrapper>
  );
}

export default PasswordChangedConfirmation;
