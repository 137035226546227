import * as React from "react";
const SvgHipFlatLogoColored = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 51 24"
    {...props}
  >
    <path
      fill="#333"
      d="M35.493 8.802a6 6 0 0 0-1.377-.057c-.723.058-1.18.473-1.339 1.178a4.8 4.8 0 0 0-.093 1.03c0 .24-.048.372-.315.358a1 1 0 0 0-.268.04l-.022.005a.68.68 0 0 0-.523.71c.033.343.283.558.658.563h.46299999999999997v1.293q-.003 1.201.001 2.403c.002.589.33.947.872.975.676.034 1.053-.319 1.056-1.006q.005-1.176.002-2.352v-1.314l.18.002c.115.002.217.004.317-.004.395-.029.672-.299.682-.654.012-.407-.18-.622-.62-.684q-.09-.01-.181-.011c-.073-.002-.146-.004-.217-.018-.06-.01-.143-.075-.154-.127-.14-.702.081-.976.794-.999.403-.013.635-.24.64-.63.005-.37-.166-.642-.556-.7M20.332 12.857c-.137-.925-.7-1.512-1.622-1.67-.748-.128-1.435.01-2.04.617v-1.83q.002-.172-.017-.345c-.08-.616-.422-.878-1.101-.845-.506.024-.837.386-.838.95q-.006 3.297 0 6.596c.001.589.33.941.878.97q.948.052 1.061-.866a3 3 0 0 0 .017-.346q.003-.357.003-.716c.002-.58.004-1.16.026-1.74.007-.219.099-.451.207-.647.183-.33.516-.391.861-.35.326.04.537.241.61.556.052.222.084.455.086.683q.006.621.004 1.242-.003.621.003 1.242c.005.373.109.726.502.865.65.23 1.412.022 1.421-.9q.011-1.335 0-2.67a6 6 0 0 0-.061-.796"
    />
    <path
      fill="#333"
      fillRule="evenodd"
      d="M29.757 11.372c-1.108-.476-2.22-.264-3.076.58l-.027-.023q-.019-.014-.025-.027c-.209-.537-.6-.756-1.157-.646-.415.082-.635.406-.636.949v.371q-.004 2.886.002 5.77c0 .614.338.935.95.935s.974-.349.98-.95a80 80 0 0 0 .001-.878v-.592q.001-.06.015-.13l.011-.078.047.033a.2.2 0 0 1 .045.036c.803.959 3.007.945 3.84-.349.635-.986.719-2.053.463-3.153-.19-.818-.616-1.498-1.433-1.849m-.75 3.991c-.45.753-1.483.754-1.903-.016-.37-.68-.375-1.4-.033-2.097.208-.425.568-.649 1.06-.62.461.025.767.282.943.68.08.182.125.38.158.526q.017.08.032.135c.012.574-.026 1.005-.257 1.392M45.967 15.28c0 .35.058.422.397.527.23.07.395.203.427.458.04.325 0 .625-.29.836-.447.325-1.45.31-1.872-.048a2 2 0 0 1-.28-.326 4 4 0 0 0-.126-.165c-.611.84-1.49.93-2.432.773-.839-.14-1.413-.728-1.498-1.488-.11-.991.253-1.679 1.091-2.043.698-.303 1.435-.332 2.18-.286l.361.024.185.013c.056-.737-.454-1.053-1.253-1.005-.26.016-.52.055-.779.095q-.232.037-.467.066c-.543.065-.879-.245-.841-.761.023-.317.232-.476.504-.551.29-.08.59-.14.89-.177.745-.091 1.492-.138 2.235.03.995.222 1.561.921 1.567 1.944q.002.59 0 1.18zm-3.233.727c.63.181 1.283-.25 1.372-.906.055-.405.055-.406-.34-.452-.132-.016-.898.008-1.129.09-.29.104-.407.355-.378.691.025.29.194.496.474.577"
      clipRule="evenodd"
    />
    <path
      fill="#333"
      d="M49.444 15.333c.005.541.245.732.786.649.341-.053.6.059.7.302a.71.71 0 0 1-.262.848c-.645.431-2.094.325-2.652-.205-.418-.397-.519-.922-.527-1.465-.011-.722-.006-2.212-.004-2.674v-.136l-.11-.014q-.104-.011-.196-.028c-.389-.08-.556-.257-.566-.596-.01-.378.12-.582.516-.658.287-.055.407-.177.424-.465a4 4 0 0 1 .136-.732c.127-.486.5-.726 1.008-.669.453.052.725.362.742.862.006.2.005.399.003.609l-.002.3h.27l.521-.001c.45-.005.76.267.769.677.008.411-.293.692-.745.694h-.787c-.01.065-.032 1.857-.024 2.702M39.224 15.888c-.35 0-.479-.127-.517-.476a4 4 0 0 1-.016-.373v-2.415l-.001-2.741V9.87c0-.12 0-.242-.017-.36-.05-.35-.3-.644-.643-.703-.766-.134-1.282.16-1.278.99.002.432.001 1.433 0 2.5 0 1.35-.001 2.808.003 3.35.003.431.056.857.33 1.215.478.622 1.985.727 2.556.188.255-.24.275-.537.188-.838-.083-.288-.334-.325-.605-.324M23.004 11.314c.388.031.646.335.658.756.021.738.03 3.587.02 4.272-.01.806-.607 1.06-1.294.921-.412-.084-.64-.443-.642-.96q-.01-1.521.002-3.044c.002-.2-.04-.319-.24-.408-.44-.194-.606-.551-.52-1.024.047-.263.27-.5.574-.517.48-.027.964-.034 1.442.004"
    />
    <path
      fill="#FD6E6E"
      d="M23.688 9.34c0-.55-.476-.994-1.063-.994s-1.063.445-1.063.994c0 .263.11.502.289.68h-.002l.012.01.026.025.74.688.738-.69.022-.02.013-.013h-.001a.96.96 0 0 0 .288-.68"
    />
    <path
      fill="#BB4949"
      d="M4.805 10.565A3.5 3.5 0 0 1 5.85 8.89 3.512 3.512 0 0 0 0 11.512c0 .93.364 1.774.954 2.402H.951l.038.038q.043.045.088.087l2.195 2.186a3.5 3.5 0 0 1-.882-2.323c0-.56.131-1.09.365-1.561a1.122 1.122 0 1 1 1.586-1.585q.224-.113.464-.191"
    />
    <path
      fill="#D85D5D"
      d="M7.36 10.755a1.122 1.122 0 1 1 1.585 1.585c.233.471.365 1.001.365 1.562 0 .93-.364 1.774-.954 2.402h.003l2.275-2.274q.036-.035.071-.072l.044-.044h-.004c.59-.628.955-1.471.955-2.402a3.512 3.512 0 0 0-6.895-.947q.473-.156.993-.173v-.002h.104v.002a3.5 3.5 0 0 1 1.457.363"
    />
    <path
      fill="#FD8B8B"
      d="m5.851 18.854 2.393-2.434.071-.071.044-.045h-.004c.59-.628.955-1.471.955-2.401A3.51 3.51 0 0 0 5.85 10.39v2.39a1.122 1.122 0 0 1 0 2.242z"
    />
    <path
      fill="#FD6E6E"
      fillRule="evenodd"
      d="M4.729 13.902c0-.62.502-1.122 1.122-1.122v-2.39a3.51 3.51 0 0 0-3.512 3.512c0 .93.364 1.774.955 2.402H3.29l.038.039.087.086 2.436 2.425v-3.83c-.62 0-1.122-.502-1.122-1.122"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHipFlatLogoColored;
