import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { useMount } from 'react-use';
import { dispatch } from 'reffects';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import OfferCard from './OffersCard';
import { offersSelector } from '../selectors';
import { CONTACT_SELECTED, OFFER_SELECTED, OFFERS_MOUNTED } from '../events';
import useTracking from '../../../hooks/useTracking';
import { supportEmailSelector } from '../../../selectors/config';

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.XL)};
  background-color: #0acc86;
  padding: ${spacing.value(SPACING_SIZE.XL)} 0;

  p {
    margin: 0;
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${spacing.value(SPACING_SIZE.XL)} 0;
  gap: ${spacing.value(SPACING_SIZE.S)};

  ${Heading1} {
    color: #fff;
    font-size: 36px;
  }
`;

const Disclaimer = styled.div`
  span {
    color: #fff;
  }
`;

const Cards = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

const ContactUs = styled.div`
  padding: 40px 10px;
  display: flex;
  width: 280px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 48px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 2px solid #0acc86;
  background: #fff;
`;

const ContactUsMessage = styled.div`
  color: #414963;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

export default function OffersCards({ pageName }) {
  const offers = useSelector(offersSelector);
  const trackingSection = pageName
    ? `${pageName}-subscription-offers`
    : 'subscription-offers';
  const trackImpression = useTracking({
    trackImpression: {
      elementName: 'offers',
    },
    sectionName: trackingSection,
  });
  const supportEmail = useSelector(supportEmailSelector) || 'hola@proppit.com';

  useMount(() => {
    dispatch(OFFERS_MOUNTED);
  });

  const handleOfferClick = (offerId) => {
    dispatch({
      id: OFFER_SELECTED,
      payload: { offerId, trackingSection },
    });
  };

  const handleContactClick = () => {
    dispatch({
      id: CONTACT_SELECTED,
      payload: { trackingSection },
    });
  };

  return (
    <Content>
      <Title ref={trackImpression}>
        <Heading1>
          <FormattedMessageWithValidation id="subscription_landing_title1" />
        </Heading1>
        <Heading1>
          <FormattedMessageWithValidation id="subscription_landing_title2" />
        </Heading1>
      </Title>
      <Cards>
        {offers?.map((offer) => (
          <OfferCard
            key={offer.id}
            offerData={offer}
            onClick={() => handleOfferClick(offer.id)}
          />
        ))}
        <ContactUs>
          <ContactUsMessage>
            <FormattedMessageWithValidation
              id="subscription_landing_contact_us_message"
              values={{
                supportEmail: (
                  <a
                    href={`mailto:${supportEmail}`}
                    onClick={handleContactClick}
                  >
                    {supportEmail}
                  </a>
                ),
              }}
            />
          </ContactUsMessage>
        </ContactUs>
      </Cards>
      <Disclaimer>
        <FormattedMessageWithValidation id="subscription_landing_disclaimer" />
      </Disclaimer>
    </Content>
  );
}
