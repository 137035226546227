import Icon from 'design-system/components/Icon';
import { useBulkAction } from '../../../partials/DataTable/hooks/useBulkAction';
import { ActionsMenu } from '../../../partials/DataTable';

export function BulkAction({ action, iconGlyph, children }) {
  const { executeAction, isProcessing } = useBulkAction(action);

  return (
    <ActionsMenu.Action
      icon={<Icon glyph={iconGlyph} />}
      disabled={isProcessing}
      onClick={executeAction}
    >
      {children}
    </ActionsMenu.Action>
  );
}
