import * as React from "react";
const SvgImagePlaceholder = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.5 5.898v4.93l1.487-1.882.013-3.78L13.725 3l-2.163.255zm-2.369 7.718-.023-6.383-5.277-3.606 2.583-.26 4.378 2.989.05 5.256zM3.223 14.63H1V7.68l5.66-3.905 5.547 3.905v6.956H4.473l2.153-2.218h3.337V8.83L6.641 6.52 3.223 8.83z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgImagePlaceholder;
