import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'reffects-store';
import camelCase from 'camelcase';
import { useUpdateEffect } from 'react-use';
import { intersection } from 'lodash';
import InputRow from 'design-system/components/InputRow';
import CardsField from '../../../partials/fields/CardsField';
import {
  amenitiesOfPropertyTypeSelector,
  nearbyLocationsOfPropertyTypeSelector,
  projectAmenitiesOfPropertyTypeSelector,
  rulesOfPropertyTypeSelector,
} from '../selectors';
import { getAmenityIconName } from '../../../utils/ad';
import { FieldGroup } from './FieldGroup';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  amenitiesTranslationTags,
  nearbyLocationsTranslationTags,
  rulesTranslationTags,
} from '../../../constants/ad';

function useResetFormValues(fields, deps = []) {
  const { setValue } = useFormContext();

  useUpdateEffect(() => {
    Object.entries(fields).forEach(([key, value]) => {
      setValue(key, value, { shouldDirty: true });
    });
  }, deps);
}

function PropertyFacilitiesGroup({ order, propertyType, projectAmenities }) {
  const [
    operationType,
    selectedAmenities = [],
    selectedNearbyLocations = [],
    selectedRules = [],
  ] = useWatch({
    name: ['operationType', 'amenities', 'nearby', 'rules'],
  });

  useResetFormValues(
    {
      amenities: projectAmenities,
    },
    [JSON.stringify(projectAmenities)]
  );

  const amenities = useSelector((state) =>
    amenitiesOfPropertyTypeSelector(state, propertyType)
  );

  const nearbyLocations = useSelector((state) =>
    nearbyLocationsOfPropertyTypeSelector(state, propertyType)
  );

  const rules = useSelector((state) =>
    rulesOfPropertyTypeSelector(state, propertyType, operationType)
  );

  useResetFormValues(
    {
      amenities: intersection(
        [...new Set([...projectAmenities, ...selectedAmenities])],
        amenities
      ),
      nearby: intersection(selectedNearbyLocations, nearbyLocations),
      rules: intersection(selectedRules, rules),
    },
    [propertyType, operationType]
  );

  if (!amenities.length && !nearbyLocations.length && !rules.length)
    return null;

  return (
    <FieldGroup
      id="property-facilities"
      title={<FormattedMessageWithValidation id="ad_form_group_facilities" />}
      order={order}
    >
      {amenities.length > 0 && (
        <InputRow>
          <CardsField
            label={
              <FormattedMessageWithValidation id="newprop_amenities_header" />
            }
            name="amenities"
            choices={amenities.map((amenity) => ({
              icon: getAmenityIconName(amenity),
              label: (
                <FormattedMessageWithValidation
                  id={amenitiesTranslationTags.get(amenity)}
                />
              ),
              value: amenity,
            }))}
          />
        </InputRow>
      )}
      {nearbyLocations.length > 0 && (
        <InputRow>
          <CardsField
            label={
              <FormattedMessageWithValidation id="newprop_surroundings_header" />
            }
            name="nearby"
            choices={nearbyLocations.map((nearbyLocation) => ({
              icon: getAmenityIconName(nearbyLocation),
              label: (
                <FormattedMessageWithValidation
                  id={nearbyLocationsTranslationTags.get(nearbyLocation)}
                />
              ),
              value: nearbyLocation,
            }))}
          />
        </InputRow>
      )}
      {rules.length > 0 && (
        <InputRow>
          <CardsField
            label={
              <FormattedMessageWithValidation id="propspage_table_rules" />
            }
            name="rules"
            choices={rules.map((rule) => ({
              icon: camelCase(rule),
              label: (
                <FormattedMessageWithValidation
                  id={rulesTranslationTags.get(rule)}
                />
              ),
              value: rule,
            }))}
          />
        </InputRow>
      )}
    </FieldGroup>
  );
}

export default function ThisComponentIsUsedToAvoidABugWithUseWatchAndUseSelector({
  order,
}) {
  const propertyType = useWatch({
    name: 'propertyType',
  });
  const projectAmenities = useSelector((state) =>
    projectAmenitiesOfPropertyTypeSelector(state, propertyType)
  );
  return (
    <PropertyFacilitiesGroup
      order={order}
      propertyType={propertyType}
      projectAmenities={projectAmenities}
    />
  );
}
