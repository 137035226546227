import * as React from "react";
const SvgAgencyAvatar = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={60}
    height={60}
    fill="none"
    {...props}
  >
    <rect width={60} height={60} fill="#E6E6E6" rx={4} />
    <path
      fill="#CCC"
      d="M30 30c3.315 0 6-2.685 6-6s-2.685-6-6-6-6 2.685-6 6 2.685 6 6 6m0 3c-4.005 0-12 2.01-12 6v1.5c0 .825.675 1.5 1.5 1.5h21c.825 0 1.5-.675 1.5-1.5V39c0-3.99-7.995-6-12-6"
    />
  </svg>
);
export default SvgAgencyAvatar;
