import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import styled from 'styled-components';
import Table from 'design-system/components/Table';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { useDialog } from '../../../../hooks/useDialog';
import { DETAILS_MODAL } from './DetailsDialog';
import { useColumn } from '../../../../partials/DataTable/hooks/useColumn';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import useTracking from '../../../../hooks/useTracking';

const ShowDetails = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
  ${Icon} {
    ${spacing.inline(SPACING_SIZE.S, true)}
    ${color.text(COLOR_PALETTE.NEUTRAL_A80)}
  }
`;

export function DetailsColumn(props) {
  const {
    value: { id, rawError },
  } = useColumn(props);
  const { openDialog } = useDialog(DETAILS_MODAL);
  const ref = useTracking({
    trackClick: {
      eventName: 'show-error-details',
    },
    sectionName: 'import-stats-table-row',
  });
  return (
    <Table.Cell maxWidth={150}>
      <ShowDetails
        onClick={() => openDialog({ externalReference: id, rawError })}
        ref={ref}
      >
        <Icon glyph="integrationInstructions" $size={ICON_SIZE.BASE} />
        <span>
          <FormattedMessageWithValidation id="import_stats_invalid_ads_show_details" />
        </span>
      </ShowDetails>
    </Table.Cell>
  );
}
