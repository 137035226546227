import styled, { css } from 'styled-components';
import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import InputSelect from 'design-system/components/InputSelect';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { COLOR_PALETTE } from 'design-system/styles/color';
import useInternationalization from '../../../../hooks/useInternationalization';
import { getSelectedValue } from '../selectors';

const InputSelectCustom = styled(InputSelect)`
  div:has(> input) {
    ${spacing.inset(SPACING_SIZE.XS)}
    margin: 0;
  }

  div:nth-child(3) {
    width: 100%;
  }

  ${(props) =>
    props.hasError &&
    css`
      div::after {
        border-color: ${COLOR_PALETTE.STRAWBERRY_BASE};
        color: ${COLOR_PALETTE.STRAWBERRY_BASE};
      }

      div > svg > use {
        color: ${COLOR_PALETTE.STRAWBERRY_BASE};
      }

      input,
      input::placeholder {
        border-color: ${COLOR_PALETTE.STRAWBERRY_BASE};
        color: ${COLOR_PALETTE.STRAWBERRY_BASE};
      }

      border-color: ${COLOR_PALETTE.STRAWBERRY_BASE};
    `}
`;

const ErrorIcon = styled(Icon)`
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  color: ${COLOR_PALETTE.STRAWBERRY_BASE};
  background-color: ${COLOR_PALETTE.STRAWBERRY_A20};
  border-radius: 50%;
`;
const Wrapper = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  label {
    min-width: 90%;
  }
`;

function MapChoicesInput({
  values,
  valueName,
  fieldNameToMap,
  required,
  onSelectChange,
  hasError,
  isHeader,
}) {
  const intl = useInternationalization();
  // Order values alphabetically
  let options = values;

  if (!isHeader) {
    options = values.map(({ value, label }) => ({
      label: intl.formatMessageWithValidation({ id: label }),
      value,
    }));
    try {
      options.sort((a, b) => a.label.localeCompare(b.label));
    } catch (e) {
      console.error('Error sorting options', e);
    }
  }
  let translatePlaceholder = 'upload_csv_select_equivalent_proppit_field';
  if (isHeader) {
    fieldNameToMap = 'header';
    translatePlaceholder = 'upload_csv_select_equivalent_column_field';
  }

  const selectedValue = useSelector((state) =>
    getSelectedValue(state, valueName, fieldNameToMap)
  );

  const handleSelect = (event) => {
    dispatch({
      id: 'CSV_SELECTED_VALUES_SETTER',
      payload: { name: valueName, value: event.value, fieldNameToMap },
    });
    onSelectChange(valueName, event.value);
  };

  return (
    <Wrapper>
      <InputSelectCustom
        name={valueName}
        value={selectedValue}
        choices={options}
        onSelect={handleSelect}
        required={required}
        hasError={hasError}
        scrollable
        placeholder={intl.formatMessageWithValidation({
          id: translatePlaceholder,
        })}
      />
      {hasError && <ErrorIcon glyph="error" $size={ICON_SIZE.BASE} />}
    </Wrapper>
  );
}

export default MapChoicesInput;
