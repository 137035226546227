import { coeffect, registerCoeffectHandler } from 'reffects';

export function registerLocationCoeffect(globalObject = window) {
  registerCoeffectHandler('location', function currentLocation() {
    const {
      location: { origin, pathname, href, search },
    } = globalObject;

    return {
      location: {
        origin,
        url: `${origin}${pathname}`,
        href,
        path: pathname,
        queryString: search,
      },
    };
  });
}

export function location() {
  return coeffect('location');
}
