export default function findPlanByTypeOfClientAndMaxBoostableProperties(
  plans,
  typeOfClient,
  maxBoostableProperties
) {
  return plans.find(
    ({
      typeOfClient: planTypeOfClient,
      maxBoostableProperties: planMaxBoostableProperties,
    }) =>
      planTypeOfClient === typeOfClient &&
      planMaxBoostableProperties === maxBoostableProperties
  );
}
