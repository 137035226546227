import { subscribe } from 'reffects-store';
import ActiveSubscription from './ActiveSubscription';
import {
  activeSubscriptionBenefitsSelector,
  activeSubscriptionHeaderSelector,
  benefitsBySubscriptionSelector,
  formattedOfferedSitesToPublishSelector,
  subscriptionsHeadersSelector,
} from './selectors';
import SubscriptionComparing from './SubscriptionComparing';
import { showLogoOnWebsiteBenefitSelector } from '../selectors';

export const ActiveSubscriptionTable = subscribe(
  ActiveSubscription,
  (state) => ({
    subscriptionHeader: activeSubscriptionHeaderSelector(state),
    subscriptionBenefits: activeSubscriptionBenefitsSelector(state),
    showLogoOnWebsiteBenefit: showLogoOnWebsiteBenefitSelector(state),
    websWithoutTranslatedAnd: formattedOfferedSitesToPublishSelector(state),
  })
);

export const SubscriptionComparingTable = subscribe(
  SubscriptionComparing,
  (state) => ({
    subscriptionsHeader: subscriptionsHeadersSelector(state),
    benefitsBySubscription: benefitsBySubscriptionSelector(state),
    showLogoOnWebsiteBenefit: showLogoOnWebsiteBenefitSelector(state),
    websWithoutTranslatedAnd: formattedOfferedSitesToPublishSelector(state),
  })
);
