import Datepicker from 'design-system/components/Datepicker/Datepicker';
import FormattedDate from './FormattedDate/FormattedDate';
import FormattedMessageWithValidation from './FormattedMessageWithValidation/FormattedMessageWithValidation';

function FilterDatepicker(props) {
  return (
    <>
      <Datepicker
        submitButtonText={
          <FormattedMessageWithValidation id="enquiries_filter_custom_date_datepicker_apply" />
        }
        cancelButtonText={
          <FormattedMessageWithValidation id="enquiries_filter_custom_date_datepicker_cancel" />
        }
        formatWeekday={(date) => <FormattedDate value={date} weekday="short" />}
        formatMonth={(date) => (
          <FormattedDate value={date} month="long" year="numeric" />
        )}
        {...props}
      />
    </>
  );
}
export default FilterDatepicker;
