import Layout from 'design-system/components/Layout';
import styled from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import {
  FONT_FAMILY,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import Anchor from 'design-system/components/Anchor';
import { Link } from 'react-router-dom';
import BackLink from 'design-system/components/BackLink';
import SubscriptionLayout from './partials/Layout/Layout';
import Header from './partials/Layout/Header';
import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { SUBSCRIPTION, SUBSCRIPTION_ADD_CARD } from '../../constants/routes';
import useClickTracking from '../../hooks/useClickTracking';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;

  ${BackLink} {
    text-decoration: none;
  }
`;

const RedirectText = styled(Body1)`
  ${color.text(COLOR_PALETTE.NEUTRAL_A80)}
  ${typography.font(FONT_FAMILY.PRIMARY)}
    font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
`;

const ClickHere = styled(Anchor)`
  ${color.text(COLOR_PALETTE.BLUEBERRY_BASE)}
  ${typography.font(FONT_FAMILY.PRIMARY)}
    font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
`;

const Title = styled(Header)`
  ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
  ${typography.font(FONT_FAMILY.PRIMARY)}
    ${typography.weight(FONT_WEIGHT.SEMIBOLD)}
    font-size: 22px;
  font-style: normal;
  text-align: left;
`;

export default function CreditCardFailed() {
  const { trackClick } = useClickTracking({
    sectionName: 'subscription-credit-card-failed',
  });
  return (
    <SubscriptionLayout>
      <Layout.Padded>
        <Content>
          <Body>
            <BackLink forwardedAs={Link} to={SUBSCRIPTION}>
              <FormattedMessageWithValidation id="subscription_back_to_subscription" />
            </BackLink>
            <Title>
              <FormattedMessageWithValidation id="subscription_credit_card_failed_header" />
            </Title>
            <RedirectText>
              <FormattedMessageWithValidation
                id="subscription_credit_card_failed_text"
                values={{
                  try_again: (
                    <ClickHere
                      as={Link}
                      to={SUBSCRIPTION_ADD_CARD}
                      onClick={() =>
                        trackClick({
                          eventName: 'retry-change-card',
                        })
                      }
                    >
                      <FormattedMessageWithValidation id="try_again" />
                    </ClickHere>
                  ),
                }}
              />
            </RedirectText>
          </Body>
        </Content>
      </Layout.Padded>
    </SubscriptionLayout>
  );
}
