export function creditCardSelector({ payment }) {
  return payment?.creditCard;
}

export function hasCreditCardSavedSelector(state) {
  const hasCreditCardSaved = creditCardSelector(state)?.creditCardType;
  return !!hasCreditCardSaved;
}

export function paymentInformationIsLoadingSelector(state) {
  return state.payment?.loading ?? false;
}
