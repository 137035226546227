import styled from 'styled-components';
import { getAmenityIconName } from '../../../utils/ad';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import Section from './Section';
import IconGrid from './IconGrid';
import { nearbyLocationsTranslationTags } from '../../../constants/ad';

function NearbyLocations({ project, ...props }) {
  if (project.nearbyLocations.length === 0) {
    return null;
  }
  return (
    <Section
      title={
        <FormattedMessageWithValidation id="project_detail_nearby_locations_title" />
      }
      {...props}
    >
      <IconGrid
        items={project.nearbyLocations.map((id) => ({
          id,
          tag: nearbyLocationsTranslationTags.get(id),
          glyph: getAmenityIconName(id),
        }))}
      />
    </Section>
  );
}

export default styled(NearbyLocations)``;
