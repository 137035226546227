import { useSelector } from 'reffects-store';
import { FormProvider } from 'react-hook-form';
import styled from 'styled-components';
import { dispatch } from 'reffects';
import { useCallback, useEffect } from 'react';
import BreakdownTable from 'design-system/components/BreakdownTable/BreakdownTable';
import Dialog from 'design-system/components/Dialog/Dialog';
import {
  FlatButton,
  SecondaryButton,
} from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Typography from 'design-system/components/Typography/Typography';
import { FONT_SIZE, typography } from 'design-system/styles/typography';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import {
  billingEntityDataSelector,
  billingEntityFileDisableSubmitForEditSelector,
  billingEntityTaxIdSelector,
  billingEntityValidationsSelector,
  emphasisMessageSelector,
  formCountryCodeValueSelector,
  hasPendingChangeRequestsSelector,
  loadingBillingEntitySelector,
  selectedPublisherIsFromMexicoSelector,
  shouldShowFormOnMxEditSelector,
  shouldShowUploadCedulaFiscalSelector,
  submitButtonEnabledSelector,
  taxIdSearchTextSelector,
} from '../selectors';
import { setUpForm } from '../../../../../../../../partials/BillingEntityFields/setUpForm';
import BillingEntityFields from '../../../../../../../../partials/BillingEntityFields';
import {
  BILLING_ENTITY_DIALOG_CANCEL_EDITING,
  BILLING_ENTITY_DIALOG_SAVE_EDIT,
  REQUEST_BILLING_ENTITY_DATA,
} from '../events';
import { formDataFromBillingEntityData } from '../../../../../../../../partials/BillingEntityFields/formDataFromBillingEntityData';
import { INITIAL_BILLING_ENTITY_DATA } from '../constants';
import {
  billingEntityFileDataSelector,
  billingEntityFileErrorLoadingSelector,
  billingEntityFileLoadingSelector,
  billingEntityFileSelector,
  billRequiredSelector,
  currentBillingEntityRequiresBillSelector,
  hasHaciendaRequestFailedSelector,
  shouldDisableFormSelector,
} from '../../../../../../../../pages/Checkout/BillingInformation/partials/Form/selectors';
import SwitchField from '../../../../../../../../partials/fields/SwitchField';
import {
  BILL_REQUIRED_SWITCH_CHANGED,
  BILLING_ENTITY_FILE_DELETED,
  BILLING_ENTITY_FILE_SELECTED,
} from '../../../../../../../../pages/Checkout/BillingInformation/partials/Form/events';
import FormattedMessageWithValidation from '../../../../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import InformationBlock from '../../../../../../../../pages/Checkout/partials/InformationBlock';
import FileUploader from '../../../../../../../../pages/Checkout/PaymentMethod/partials/Form/FileUploader';
import { ACCEPTED_FILE_EXTENSIONS } from '../../../../../../../../pages/Checkout/PaymentMethod/partials/Form/constants';
import { CEDULA_FIELD_TAGS } from '../../../../../../../../pages/Checkout/BillingInformation/constants';
import { TYPE_OF_PERSON } from '../../../../../../../../constants/billingEntity';

const Wrapper = styled.div`
  > * {
    ${spacing.stack(SPACING_SIZE.L)}
  }
`;

const BillingInformationWrapper = styled.div`
  ${spacing.stack(SPACING_SIZE.L)}
  padding:${spacing.value(SPACING_SIZE.S)} ${spacing.value(SPACING_SIZE.L)};
`;

const SwitchWrapper = styled.div`
  padding: ${spacing.value(SPACING_SIZE.S)} ${spacing.value(SPACING_SIZE.L)};
`;
const BillingEntityFileErrorLoadingNotice = styled(Typography)`
  display: block;
  ${typography.size(FONT_SIZE.S)}
  ${color.text(COLOR_PALETTE.STRAWBERRY_BASE)}
  margin-bottom: ${spacing.value(SPACING_SIZE.S)};
`;
const FileUploadWrapper = styled.div`
  ${spacing.insetSandwich(SPACING_SIZE.M)}
`;
const BillingEntityTable = styled(BreakdownTable)`
  ${spacing.stack(SPACING_SIZE.M)}
  ${BreakdownTable.Row} {
    padding: ${SPACING_SIZE.S}px 0;
    td {
      text-align: left;
    }
    td:nth-of-type(1) {
      width: 160px;
    }
  }
`;

const Subtitle = styled(Subtitle3)`
  ${spacing.stack(SPACING_SIZE.M)}
  ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
`;

function EditModeForMx({ onSuccessEvent }) {
  const hasHaciendaRequestFailed = useSelector(
    hasHaciendaRequestFailedSelector
  );
  const billingEntity = useSelector(billingEntityDataSelector);
  const loadingBillingEntity = useSelector(loadingBillingEntitySelector);
  const configFieldValidations = useSelector(billingEntityValidationsSelector);
  const taxId = useSelector(billingEntityTaxIdSelector);
  const submitButtonEnabled = useSelector(submitButtonEnabledSelector);
  const billRequired = useSelector(billRequiredSelector);
  const countryCode = useSelector(formCountryCodeValueSelector);
  const emphasisMessage = useSelector(emphasisMessageSelector);
  const taxIdSearchText = useSelector(taxIdSearchTextSelector);

  const noValidations = {};
  const shouldShowForm = useSelector(shouldShowFormOnMxEditSelector);
  const shouldShowUploadCedulaFiscal = useSelector(
    shouldShowUploadCedulaFiscalSelector
  );

  const validations = shouldShowForm ? configFieldValidations : noValidations;

  const methods = setUpForm(billingEntity, countryCode, validations);

  const billingEntityData = useSelector(billingEntityDataSelector);

  const disabled = useSelector(shouldDisableFormSelector);

  const currentBillingEntityRequiresBill = useSelector(
    currentBillingEntityRequiresBillSelector
  );
  const billingEntityFile = useSelector(billingEntityFileSelector);
  const billingEntityFileData = useSelector(billingEntityFileDataSelector);
  const billingEntityIsLoading = useSelector(billingEntityFileLoadingSelector);
  const billingEntityFileErrorLoading = useSelector(
    billingEntityFileErrorLoadingSelector
  );

  const submitIsDisabled = useSelector(
    billingEntityFileDisableSubmitForEditSelector
  );

  const { reset, watch, trigger, handleSubmit } = methods;

  const formTypeOfPerson = watch('typeOfPerson');

  const clearForm = useCallback(
    (countryCodeToReset, typeOfPerson) => {
      reset(
        formDataFromBillingEntityData(
          {
            ...INITIAL_BILLING_ENTITY_DATA,
            taxId: taxIdSearchText,
            typeOfPerson: typeOfPerson ?? '',
            country: countryCodeToReset,
          },
          countryCodeToReset
        )
      );
      if (taxIdSearchText) {
        trigger('taxId');
      }
    },
    [reset, trigger, taxIdSearchText]
  );

  const hydrateForm = useCallback(
    (billingEntityToHydrate, countryCodeToHydrate) => {
      reset(
        formDataFromBillingEntityData(
          billingEntityToHydrate,
          countryCodeToHydrate
        )
      );
      if (billingEntityToHydrate.taxId) {
        trigger('taxId');
      }
    },
    [reset, trigger]
  );

  useEffect(() => {
    if (billingEntity.id == null || loadingBillingEntity) {
      clearForm(countryCode, formTypeOfPerson);
    } else {
      hydrateForm(billingEntity, countryCode);
    }
  }, [billingEntity, countryCode]);

  return (
    <>
      <Dialog.Header
        onClose={() => dispatch(BILLING_ENTITY_DIALOG_CANCEL_EDITING)}
      >
        Edit billing entity
      </Dialog.Header>
      {emphasisMessage != null && (
        <Dialog.Emphasis>{emphasisMessage}</Dialog.Emphasis>
      )}
      <FormProvider {...methods}>
        <form>
          <SwitchWrapper>
            <SwitchField
              name="billRequired"
              defaultChecked={currentBillingEntityRequiresBill}
              disabled={disabled}
              onChange={(event) =>
                dispatch({
                  id: BILL_REQUIRED_SWITCH_CHANGED,
                  payload: event.target.checked,
                })
              }
            >
              <Body1>
                <FormattedMessageWithValidation id="checkout_billing_bill_required_switch_label" />
              </Body1>
              {!billRequired && (
                <InformationBlock>
                  <Body1>
                    <FormattedMessageWithValidation id="checkout_billing_no_bill_required_message" />
                  </Body1>
                </InformationBlock>
              )}
            </SwitchField>
          </SwitchWrapper>
          <BillingInformationWrapper>
            {shouldShowForm && (
              <BillingEntityFields
                configFields={configFieldValidations}
                taxId={taxId}
                formFieldMargins={SPACING_SIZE.S}
                onChangeTaxId={(e) =>
                  dispatch({
                    id: REQUEST_BILLING_ENTITY_DATA,
                    payload: {
                      taxId: e.target.value,
                      countryCode,
                    },
                  })
                }
              />
            )}
            {shouldShowUploadCedulaFiscal && (
              <>
                <Subtitle>
                  <FormattedMessageWithValidation id="checkout_file_upload_title" />
                </Subtitle>
                <FileUploadWrapper>
                  <FileUploader
                    id="cedula"
                    value={billingEntityFile}
                    isLoading={billingEntityIsLoading}
                    accept={ACCEPTED_FILE_EXTENSIONS.join(', ')}
                    onFileUploaded={(file) =>
                      dispatch({
                        id: BILLING_ENTITY_FILE_SELECTED,
                        payload: { file },
                      })
                    }
                    onFileDeleted={() => dispatch(BILLING_ENTITY_FILE_DELETED)}
                    inputMessage={
                      <FormattedMessageWithValidation id="checkout_file_placeholder" />
                    }
                  />
                </FileUploadWrapper>
              </>
            )}
            {billingEntityData && (
              <BillingEntityTable>
                {Object.entries(billingEntityFileData || {})?.map((entry) => {
                  const [key, value] = entry;
                  return value !== null &&
                    typeof value !== 'boolean' &&
                    CEDULA_FIELD_TAGS[key] ? (
                    <BreakdownTable.Row
                      title={CEDULA_FIELD_TAGS[key]}
                      price={value}
                    />
                  ) : (
                    []
                  );
                })}
              </BillingEntityTable>
            )}
            {billingEntityFileErrorLoading && (
              <BillingEntityFileErrorLoadingNotice>
                {!hasHaciendaRequestFailed ? (
                  <FormattedMessageWithValidation id="checkout_file_upload_load_error" />
                ) : (
                  <FormattedMessageWithValidation id="checkout_file_upload_load_external_error" />
                )}
              </BillingEntityFileErrorLoadingNotice>
            )}

            <Dialog.Footer>
              <FlatButton
                onClick={() => dispatch(BILLING_ENTITY_DIALOG_CANCEL_EDITING)}
                $size={BUTTON_SIZE.SMALL}
              >
                Cancel
              </FlatButton>
              <SecondaryButton
                onClick={
                  billRequired
                    ? handleSubmit((data) => {
                        dispatch({
                          id: BILLING_ENTITY_DIALOG_SAVE_EDIT,
                          payload: {
                            billingEntity: billingEntityFileData ?? data,
                            billingEntityFile,
                            onSuccessEvent,
                          },
                        });
                      })
                    : () =>
                        dispatch({
                          id: BILLING_ENTITY_DIALOG_SAVE_EDIT,
                          payload: {
                            billingEntity: {
                              ...billingEntityData,
                              billRequired,
                            },
                            billingEntityFile,
                            onSuccessEvent,
                          },
                        })
                }
                $size={BUTTON_SIZE.SMALL}
                disabled={submitIsDisabled || !submitButtonEnabled}
              >
                Save
              </SecondaryButton>
            </Dialog.Footer>
          </BillingInformationWrapper>
        </form>
      </FormProvider>
    </>
  );
}

function EditModeForNonMx({ onSuccessEvent }) {
  const billingEntity = useSelector(billingEntityDataSelector);
  const loadingBillingEntity = useSelector(loadingBillingEntitySelector);
  const validations = useSelector(billingEntityValidationsSelector);
  const taxId = useSelector(billingEntityTaxIdSelector);
  const submitButtonEnabled = useSelector(submitButtonEnabledSelector);
  const countryCode = useSelector(formCountryCodeValueSelector);
  const hasPendingChangeRequests = useSelector(
    hasPendingChangeRequestsSelector
  );
  const emphasisMessage = useSelector(emphasisMessageSelector);
  const taxIdSearchText = useSelector(taxIdSearchTextSelector);

  const methods = setUpForm(billingEntity, countryCode, validations);

  const { reset, watch, trigger, handleSubmit, setValue } = methods;

  const formTypeOfPerson = watch('typeOfPerson');

  const clearForm = useCallback(
    (countryCodeToReset, typeOfPerson) => {
      reset(
        formDataFromBillingEntityData(
          {
            ...INITIAL_BILLING_ENTITY_DATA,
            taxId: taxIdSearchText,
            typeOfPerson: typeOfPerson ?? '',
            country: countryCodeToReset,
          },
          countryCodeToReset
        )
      );
      if (taxIdSearchText) {
        trigger('taxId');
      }
    },
    [reset, trigger, taxIdSearchText]
  );

  const hydrateForm = useCallback(
    (billingEntityToHydrate, countryCodeToHydrate) => {
      reset(
        formDataFromBillingEntityData(
          billingEntityToHydrate,
          countryCodeToHydrate
        )
      );
      if (billingEntityToHydrate.taxId) {
        trigger('taxId');
      }
    },
    [reset, trigger]
  );

  useEffect(() => {
    if (billingEntity.id == null || loadingBillingEntity) {
      clearForm(countryCode, formTypeOfPerson);
    } else {
      hydrateForm(billingEntity, countryCode);
    }
  }, [billingEntity, countryCode]);
  useEffect(() => {
    if (formTypeOfPerson === TYPE_OF_PERSON.NATURAL) {
      setValue('retefuente', false);
      setValue('reteIva', false);
      setValue('reteIca', false);
    }
  }, [formTypeOfPerson]);
  return (
    <>
      <Dialog.Header
        onClose={() => {
          dispatch(BILLING_ENTITY_DIALOG_CANCEL_EDITING);
        }}
      >
        Edit billing entity
      </Dialog.Header>
      {emphasisMessage != null && (
        <Dialog.Emphasis>{emphasisMessage}</Dialog.Emphasis>
      )}
      <Dialog.Content>
        <Wrapper>
          <FormProvider {...methods}>
            <BillingEntityFields
              configFields={validations}
              taxId={taxId}
              formFieldMargins={SPACING_SIZE.S}
              onChangeTaxId={(e) =>
                dispatch({
                  id: REQUEST_BILLING_ENTITY_DATA,
                  payload: {
                    taxId: e.target.value,
                    countryCode,
                  },
                })
              }
              disableRetentions={hasPendingChangeRequests}
              typeOfPerson={formTypeOfPerson}
            />
          </FormProvider>
        </Wrapper>
      </Dialog.Content>
      <Dialog.Footer>
        <FlatButton
          onClick={() => dispatch(BILLING_ENTITY_DIALOG_CANCEL_EDITING)}
          $size={BUTTON_SIZE.SMALL}
        >
          Cancel
        </FlatButton>
        <SecondaryButton
          onClick={handleSubmit((data) => {
            dispatch({
              id: BILLING_ENTITY_DIALOG_SAVE_EDIT,
              payload: {
                billingEntity: data,
                onSuccessEvent,
              },
            });
          })}
          $size={BUTTON_SIZE.SMALL}
          disabled={!submitButtonEnabled}
        >
          Save
        </SecondaryButton>
      </Dialog.Footer>
    </>
  );
}

export function EditMode({ onSuccessEvent }) {
  const isMexicanPublisher = useSelector(selectedPublisherIsFromMexicoSelector);

  return isMexicanPublisher ? (
    <EditModeForMx onSuccessEvent={onSuccessEvent} />
  ) : (
    <EditModeForNonMx onSuccessEvent={onSuccessEvent} />
  );
}
