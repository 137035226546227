import Table from 'design-system/components/Table';
import Anchor from 'design-system/components/Anchor';
import { useColumn } from '../../../../../../partials/DataTable/hooks/useColumn';

export default function EmailColumn(props) {
  const { value } = useColumn(props);

  return (
    <Table.Cell maxWidth={300}>
      <Anchor
        href={`mailto:${value}`}
        target="_blank"
        rel="noopener noreferrer"
        $ellipse
      >
        {value}
      </Anchor>
    </Table.Cell>
  );
}
