import { subscribe } from 'reffects-store';
import BaseAuthenticationPage from './BaseAuthenticationPage';
import CreateNewPasswordForm from './forms/CreateNewPasswordForm';
import { passwordResetSelector } from './selectors';

import PasswordChangedConfirmation from './forms/PasswordChangedConfirmation';

function CreateNewPassword({ passwordReset }) {
  return (
    <BaseAuthenticationPage>
      {!passwordReset ? (
        <CreateNewPasswordForm />
      ) : (
        <PasswordChangedConfirmation />
      )}
    </BaseAuthenticationPage>
  );
}

export default subscribe(CreateNewPassword, (state) => ({
  passwordReset: passwordResetSelector(state),
}));
