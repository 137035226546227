import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { border, BORDER_SIZE } from 'design-system/styles/border';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import LegalLinks from '../../../partials/LegalLinks/LegalLinks';
import { BrandLogoForAnonymousUsers } from '../../../partials/BrandLogo';
import { userFromLamudiSelector } from '../../../selectors/publisher';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  svg {
    ${spacing.stack(SPACING_SIZE.M)}
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 464px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${border.inner({
    size: BORDER_SIZE.BASE,
    color: COLOR_PALETTE.NEUTRAL_A10,
  })}
  ${spacing.inset(SPACING_SIZE.XL)}
  ${spacing.stack(SPACING_SIZE.XL)}
  ${radius.regular(RADIUS_SIZE.LARGE)}
`;

const Footer = styled.footer`
  display: flex;
  justify-content: center;
`;

function LoginCard({ children }) {
  const userFromLamudi = useSelector(userFromLamudiSelector);
  return (
    <Wrapper>
      <Content>
        <Card>
          {userFromLamudi && (
            <Heading1>
              <FormattedMessageWithValidation id="loginpage_header_lamudi" />
            </Heading1>
          )}
          <BrandLogoForAnonymousUsers />
          {children}
        </Card>
        <Footer>
          <LegalLinks />
        </Footer>
      </Content>
    </Wrapper>
  );
}

export default LoginCard;
