import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import { useMount } from 'react-use';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { withoutEventPropagation } from 'design-system/utils/withoutEventPropagation';
import styled from 'styled-components';
import { ROWS_PER_PAGE } from 'design-system/components/Table';
import DropdownMenu from 'design-system/components/DropdownMenu';
import Tooltip from 'design-system/components/Tooltip';
import Menu from 'design-system/components/Menu/Menu';
import Button from 'design-system/components/Button';
import Tag, { TAG_VARIANT } from 'design-system/components/Tag';
import Icon from 'design-system/components/Icon';
import { FlatButton } from 'design-system/components/Button/presets';
import registerUsersListEvents, {
  OPEN_CREATE_USER_MODAL,
  RESEND_WELCOME_EMAIL_REQUESTED,
  USER_TO_MODIFY_SELECTED,
} from './events';
import Layout from '../partials/Layout/Layout';
import { canDeleteUserSelector } from './selectors';
import { PUBLISHERS_REQUESTED } from '../events';
import { COLLECTION_NAME, DELETE_USER_CONFIRMATION_MODAL } from './constants';
import UsersFilters from './UsersFilters';
import CustomFormattedDate from '../../../partials/FormattedDate/CustomFormattedDate';
import { OPEN_DIALOG } from '../../../events/dialogs';
import CreateUserModal from './CreateUserModal';
import DeleteUserConfirmationDialog from './DeleteUserConfirmationDialog';
import UpdateUserModal from './UpdateUserModal';
import { publisherCountrySelector } from '../../../selectors/publisher';
import {
  DataTable,
  DataTableProvider,
  TextColumn,
} from '../../../partials/DataTable';
import { createUsersTableAdapter } from './usersTableAdapter';
import useUpdateTableEvent from '../../../partials/DataTable/hooks/useUpdateTableEvent';

function Modals() {
  const updateTableEvent = useUpdateTableEvent();
  return (
    <>
      <CreateUserModal onSuccessEvent={updateTableEvent} />
      <UpdateUserModal onSuccessEvent={updateTableEvent} />
      <DeleteUserConfirmationDialog onSuccessEvent={updateTableEvent} />
    </>
  );
}

const Wrapper = styled.div`
  margin: 2rem auto;
  width: 80%;
`;

const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${spacing.stack(SPACING_SIZE.M, false)}
`;

function UsersList() {
  useMount(() => {
    registerUsersListEvents();
    dispatch(PUBLISHERS_REQUESTED);
  });
  const defaultCountry = useSelector(publisherCountrySelector);
  if (defaultCountry == null) {
    return null;
  }
  const context = {
    collectionName: COLLECTION_NAME,
    defaultSorting: {},
    defaultFilters: {
      country: defaultCountry,
    },
    initialItemsPerPage: ROWS_PER_PAGE.TWENTY_FIVE,
    adapter: createUsersTableAdapter(),
  };

  return (
    <Layout>
      <DataTableProvider {...context}>
        <Wrapper>
          <Toolbar>
            <UsersFilters />
            <Button onClick={onClickCreateUser}>Add user</Button>
          </Toolbar>
          <DataTable allowPagination>
            <TextColumn id="email" source="email" title="Email" />
            <TextColumn id="country" source="country" title="Country" />
            <TextColumn id="locale" source="locale" title="Lang" />
            <TextColumn
              id="publisherName"
              source="publisherName"
              title="Agency"
            />
            <TextColumn
              id="status"
              renderField={({ acceptedTermsAndConditions }) =>
                acceptedTermsAndConditions ? (
                  <Tag variant={TAG_VARIANT.PRIMARY}>Active</Tag>
                ) : (
                  <Tag variant={TAG_VARIANT.COMPLEMENTARY_ORANGE}>Pending</Tag>
                )
              }
              title="Status"
            />
            <TextColumn
              id="creationDate"
              renderField={(creationDate) => (
                <CustomFormattedDate
                  value={new Date(creationDate.replace(/-/g, '/'))}
                />
              )}
              title="Creation Date"
            />
            <TextColumn
              id="actions"
              source={{
                id: 'id',
                email: 'email',
                acceptedTermsAndConditions: 'acceptedTermsAndConditions',
              }}
              renderField={({ id, email, acceptedTermsAndConditions }) => (
                <UserActions user={{ id, email, acceptedTermsAndConditions }} />
              )}
              title="Actions"
            />
          </DataTable>
          <Modals />
        </Wrapper>
      </DataTableProvider>
    </Layout>
  );
}

const UserActionsWrapper = styled.div`
  display: flex;
  ${Icon} {
    ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
  }
`;

function UserActions({ user: { acceptedTermsAndConditions, ...user } }) {
  const canDelete = useSelector(canDeleteUserSelector);
  return (
    <UserActionsWrapper>
      <Tooltip title="Edit user">
        <FlatButton
          icon={<Icon glyph="pencil" />}
          onClick={() => onClickEditUser(user)}
        />
      </Tooltip>
      {canDelete && (
        <Tooltip title="Delete user">
          <FlatButton
            icon={<Icon glyph="trash" />}
            onClick={() => onClickDeleteUser(user)}
          />
        </Tooltip>
      )}
      <DropdownMenu
        tooltip={<Tooltip title="Actions" />}
        button={<FlatButton icon={<Icon glyph="moreVert" />} />}
      >
        <Menu>
          <Menu.Item
            label="Resend welcome email"
            disabled={acceptedTermsAndConditions}
            onClick={withoutEventPropagation(() => onClickResendEmail(user))}
          />
        </Menu>
      </DropdownMenu>
    </UserActionsWrapper>
  );
}

export default UsersList;

function onClickResendEmail(user) {
  dispatch({ id: RESEND_WELCOME_EMAIL_REQUESTED, payload: user });
}
function onClickEditUser(user) {
  dispatch({ id: USER_TO_MODIFY_SELECTED, payload: user });
}

function onClickDeleteUser(user) {
  dispatch({
    id: OPEN_DIALOG,
    payload: { id: DELETE_USER_CONFIRMATION_MODAL, parameters: { user } },
  });
}

function onClickCreateUser() {
  dispatch(OPEN_CREATE_USER_MODAL);
}
