import Layout from 'design-system/components/Layout';
import styled from 'styled-components';
import Grid from 'design-system/components/Grid';
import BackLink from 'design-system/components/BackLink';
import { Link } from 'react-router-dom';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { ActiveSubscriptionTable } from './partials/SubscriptionTable';
import { SUBSCRIPTION } from '../../constants/routes';
import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import SubscriptionLayout from './partials/Layout/Layout';
import Header from './partials/Layout/Header';

const Content = styled.div`
  grid-column: 3 / span 8;
  ${BackLink} {
    ${spacing.stack(SPACING_SIZE.L, false)}
    text-decoration: none;
  }
`;

function Benefits() {
  return (
    <SubscriptionLayout>
      <Layout.Padded>
        <Grid>
          <Content>
            <BackLink forwardedAs={Link} to={SUBSCRIPTION}>
              <FormattedMessageWithValidation id="subscription_back_to_subscription" />
            </BackLink>
            <Header>
              <FormattedMessageWithValidation id="subscription_benefits_header" />
            </Header>
            <ActiveSubscriptionTable />
          </Content>
        </Grid>
      </Layout.Padded>
    </SubscriptionLayout>
  );
}

export default Benefits;
