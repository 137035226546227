import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import { environment } from '../../../../../../coeffects/environment';
import {
  CREATE_PUBLISHER_PAGE,
  MULTIPLE_ACCOUNT_CLIENT_SUBSCRIPTION_TYPE,
} from '../../../constants';

const MINIMUM_AUTOCOMPLETE_CHARS = 3;

export const SIBLING_PUBLISHER_SUGGESTIONS_RECEIVED =
  'SIBLING_PUBLISHER_SUGGESTIONS_RECEIVED';
export const SIBLING_PUBLISHER_SEARCH_SUGGESTIONS =
  'SIBLING_PUBLISHER_SEARCH_SUGGESTIONS';
export const SIBLING_PUBLISHER_SELECTED = 'SIBLING_PUBLISHER_SELECTED';
export const SIBLING_PUBLISHER_TEXT_INTRODUCED =
  'SIBLING_PUBLISHER_TEXT_INTRODUCED';
registerEventHandler(
  SIBLING_PUBLISHER_SEARCH_SUGGESTIONS,
  ({ environment: { apiUrl } }, query) => {
    if (!query || query.length < MINIMUM_AUTOCOMPLETE_CHARS) {
      return undefined;
    }

    return http.get({
      url: `${apiUrl}/backoffice/publishers-with-billing-entity?q=${query}`,
      successEvent: SIBLING_PUBLISHER_SUGGESTIONS_RECEIVED,
    });
  },
  [environment()]
);

registerEventHandler(SIBLING_PUBLISHER_SUGGESTIONS_RECEIVED, (_, [{ data }]) =>
  state.set({ suggestedSiblingPublishers: data })
);

registerEventHandler(
  SIBLING_PUBLISHER_SELECTED,
  ({ state: { multipleAccountClient } }, payload) => {
    let pageState = {
      selectedSiblingPublisher: payload,
      billingContactEmail: payload.billingEntityEmail,
      selectedBillingEntity: { id: payload.billingEntityId },
    };

    if (
      multipleAccountClient === MULTIPLE_ACCOUNT_CLIENT_SUBSCRIPTION_TYPE.SHARED
    ) {
      pageState = {
        ...pageState,
        maxBoostablePropertiesSelected:
          payload.subscription.maxBoostableProperties,
        plan: payload.subscription.productId,
        billingDate: payload.subscription.nextBillingDate,
        paymentMethod: payload.subscription.paymentMethod,
        billingCycle: payload.subscription.billingCycleInMonths,
        displayableSiblingPublisher: payload.name,
      };
    }

    return state.merge({
      [CREATE_PUBLISHER_PAGE]: pageState,
    });
  },
  [
    state.get({
      multipleAccountClient: `${CREATE_PUBLISHER_PAGE}.multipleAccountClient`,
    }),
  ]
);

registerEventHandler(SIBLING_PUBLISHER_TEXT_INTRODUCED, (_, { text }) => ({
  ...state.merge({
    [CREATE_PUBLISHER_PAGE]: {
      displayableSiblingPublisher: text,
    },
  }),
}));
