/* eslint-disable no-use-before-define */
import styled, { css } from 'styled-components';
import SkeletonBone from 'design-system/components/SkeletonBone';
import { bindClassname } from 'design-system/utils';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle1 } from 'design-system/components/Typography/presets/Subtitle1';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${spacing.inset(SPACING_SIZE.S)}

  > * {
    ${spacing.stack(SPACING_SIZE.XS)}
  }

  ${Subtitle1} {
    ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
  }

  ${Subtitle3} {
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
  }

  ${({ $alignment }) => $alignment && modAlignment($alignment)}
`;

const modAlignmentCenter = () => css`
  align-items: center;
  position: relative;
`;

const modAlignment = (alignment) =>
  ({
    center: modAlignmentCenter,
  }[alignment]);

function Counter({ title, count, loading, ...props }) {
  return (
    <Wrapper {...props}>
      {loading && <SkeletonBone $width="24px" $height="22px" />}
      {!loading && <Subtitle1>{count}</Subtitle1>}
      <Subtitle3>{title}</Subtitle3>
    </Wrapper>
  );
}

export default bindClassname(Counter, Wrapper);
