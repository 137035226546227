import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import styled from 'styled-components';
import Dialog from 'design-system/components/Dialog/Dialog';
import {
  FlatButton,
  SecondaryButton,
} from 'design-system/components/Button/presets';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { Form } from '../../Form';

const FieldBox = styled.div`
  ${spacing.stack(SPACING_SIZE.XL, false)}

  --layout-columns: 6;
  --layout-gutter: 24px;
`;

function GenericDetailForm({ onCancel, children, ...rest }) {
  return (
    <Form {...rest}>
      <Dialog.Content>
        <Dialog.Text>
          <FormattedMessageWithValidation id="edit_contact_details_modal_text" />
        </Dialog.Text>
        <FieldBox>{children}</FieldBox>
      </Dialog.Content>
      <Dialog.Footer>
        <FlatButton onClick={onCancel}>
          <FormattedMessageWithValidation id="edit_contact_details_modal_CTA_cancel" />
        </FlatButton>
        <SecondaryButton type="submit">
          <FormattedMessageWithValidation id="edit_contact_details_modal_CTA_Save" />
        </SecondaryButton>
      </Dialog.Footer>
    </Form>
  );
}

export default GenericDetailForm;
