import Icon from 'design-system/components/Icon';
import PhoneField from './PhoneField';
import HiddenField from './HiddenField';

function WhatsappField({
  className,
  style,
  label,
  tip,
  callingCodeChoices,
  phoneRegionCode,
  ...rest
}) {
  const { name } = rest;

  return (
    <>
      <HiddenField name={`${name}CallingCode`} />
      <div className={className} style={style}>
        <PhoneField
          label={label}
          prefix={callingCodeChoices?.[0]?.label}
          icon={<Icon glyph="vendorWhatsapp" />}
          phoneRegionCode={phoneRegionCode}
          {...rest}
        />
      </div>
    </>
  );
}

export default WhatsappField;
