import { useSelector } from 'reffects-store';
import { OutlineButton } from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import Illustration from 'design-system/components/Illustration/Illustration';
import { supportEmailSelector } from '../../../../selectors/config';
import ActionCard from '../../../../partials/ActionCard';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

export function PublishProjectCard() {
  const supportEmail = useSelector(supportEmailSelector);

  return (
    <ActionCard
      illustration={<Illustration name="helpCenter" />}
      title={
        <FormattedMessageWithValidation id="developer_get_in_touch_title" />
      }
      cta={
        <OutlineButton
          $size={BUTTON_SIZE.SMALL}
          forwardedAs="a"
          href={`mailto:${supportEmail}`}
          target="_blank"
        >
          <FormattedMessageWithValidation id="developer_get_in_touch_button" />
        </OutlineButton>
      }
    />
  );
}
