import styled, { css } from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import Icon from 'design-system/components/Icon';
import { withStylesAndInnerRef } from 'design-system/utils';
import { border, BORDER_SIZE } from 'design-system/styles/border';
import { radius } from 'design-system/styles/radius';

export const BUTTON_VARIANT = {
  PRIMARY: Symbol('BUTTON_VARIANT_PRIMARY'),
  SECONDARY: Symbol('BUTTON_VARIANT_SECONDARY'),
  OUTLINE: Symbol('BUTTON_VARIANT_OUTLINE'),
  OUTLINE_LIGHT: Symbol('BUTTON_VARIANT_OUTLINE_LIGHT'),
  OUTLINE_PRIMARY: Symbol('BUTTON_VARIANT_OUTLINE_PRIMARY'),
  FLAT: Symbol('BUTTON_VARIANT_FLAT'),
  TEXT: Symbol('BUTTON_VARIANT_TEXT'),
};

export const BUTTON_SIZE = {
  SMALL: Symbol('BUTTON_SIZE_SMALL'),
  BASE: Symbol('BUTTON_SIZE_BASE'),
  LARGE: Symbol('BUTTON_SIZE_LARGE'),
};

const Wrapper = styled.button`
  border: none;
  outline: none;
  text-decoration: none;
  cursor: pointer;

  flex-shrink: 0;
  flex-grow: 0;
  white-space: nowrap;

  appearance: none;

  ${radius.pill()}
`;

const modPrimaryVariant = () => css`
  ${color.background(COLOR_PALETTE.PRIMARY_BASE)}
  ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
  ${border.inner({
    size: BORDER_SIZE.BASE,
    color: COLOR_PALETTE.NONE,
  })}

  :hover {
    ${color.background(COLOR_PALETTE.PRIMARY_A160)}
  }

  :active,
  &[data-active] {
    ${color.background(COLOR_PALETTE.PRIMARY_A140)}
  }

  &[data-disabled] {
    cursor: not-allowed;
    ${color.background(COLOR_PALETTE.NEUTRAL_A05)}
    ${color.text(COLOR_PALETTE.NEUTRAL_A20)}
  }
`;

const modSecondaryVariant = () => css`
  ${color.background(COLOR_PALETTE.SECONDARY_BASE)}
  ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
  ${border.inner({
    size: BORDER_SIZE.BASE,
    color: COLOR_PALETTE.NONE,
  })}

  :hover {
    ${color.background(COLOR_PALETTE.SECONDARY_A80)}
  }

  :active,
  &[data-active] {
    ${color.background(COLOR_PALETTE.SECONDARY_A60)}
  }

  &[data-disabled] {
    cursor: not-allowed;
    ${color.background(COLOR_PALETTE.NEUTRAL_A05)}
    ${color.text(COLOR_PALETTE.NEUTRAL_A20)}
  }
`;

const modOutlineVariant = () => css`
  ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
  ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
  ${border.inner({
    size: BORDER_SIZE.BASE,
    color: COLOR_PALETTE.NEUTRAL_A20,
  })}

  ${Icon} {
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
  }

  :hover {
    ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
  }

  :active,
  &[data-active] {
    ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
    ${border.inner({
      size: BORDER_SIZE.LARGE,
      color: COLOR_PALETTE.NEUTRAL_A80,
    })}
  }

  &[data-disabled] {
    cursor: not-allowed;
    ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
    ${color.text(COLOR_PALETTE.NEUTRAL_A20)}
    ${border.inner({
      size: BORDER_SIZE.BASE,
      color: COLOR_PALETTE.NEUTRAL_A20,
    })}
  }
`;

const modOutlineLightVariant = () => css`
  ${color.background(COLOR_PALETTE.NONE)}
  ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
  ${border.inner({
    size: BORDER_SIZE.BASE,
    color: COLOR_PALETTE.NEUTRAL_A00,
  })}

  :hover {
    ${color.background('rgba(255, 255, 255, 0.10)')}
  }

  &[data-disabled] {
    cursor: not-allowed;
    ${color.text(COLOR_PALETTE.NEUTRAL_A20)}
    ${border.inner({
      size: BORDER_SIZE.BASE,
      color: COLOR_PALETTE.NEUTRAL_A20,
    })}
  }
`;

const modOutlinePrimaryVariant = () => css`
  ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
  ${color.text(COLOR_PALETTE.PRIMARY_BASE)}
  ${border.inner({
    size: BORDER_SIZE.BASE,
    color: COLOR_PALETTE.PRIMARY_BASE,
  })}

  :hover {
    ${color.background(COLOR_PALETTE.PRIMARY_A05)}
    ${color.text(COLOR_PALETTE.PRIMARY_BASE)}
  }

  :active,
  &[data-active] {
    ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
    ${color.text(COLOR_PALETTE.PRIMARY_A140)}
    ${border.inner({
      size: BORDER_SIZE.LARGE,
      color: COLOR_PALETTE.PRIMARY_A140,
    })}
  }

  &[data-disabled] {
    cursor: not-allowed;
    ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
    ${color.text(COLOR_PALETTE.NEUTRAL_A20)}
    ${border.inner({
      size: BORDER_SIZE.BASE,
      color: COLOR_PALETTE.NEUTRAL_A20,
    })}
  }
`;

const modTextVariant = () => css`
  ${color.background('transparent')}
  ${color.text(COLOR_PALETTE.PRIMARY_BASE)}
  ${border.inner({
    size: BORDER_SIZE.BASE,
    color: COLOR_PALETTE.NONE,
  })}

  :hover {
    ${color.background('transparent')}
    ${color.text(COLOR_PALETTE.PRIMARY_A120)}
  }

  :active,
  &[data-active] {
    ${color.background('transparent')}
    ${color.text(COLOR_PALETTE.PRIMARY_A140)}
  }

  &[data-disabled] {
    cursor: not-allowed;
    ${color.background('transparent')}
    ${color.text(COLOR_PALETTE.NEUTRAL_A20)}
  }
`;

const modFlatVariant = () => css`
  ${color.background('transparent')}
  ${color.text(COLOR_PALETTE.NEUTRAL_A80)}
  ${border.inner({
    size: BORDER_SIZE.BASE,
    color: COLOR_PALETTE.NONE,
  })}

  :hover {
    ${color.background('transparent')}
    ${color.text(COLOR_PALETTE.PRIMARY_A140)}

    ${Icon} {
      ${color.text(COLOR_PALETTE.PRIMARY_A140)}
    }
  }

  :active,
  &[data-active] {
    ${color.background('transparent')}
    ${color.text(COLOR_PALETTE.PRIMARY_BASE)}

    ${Icon} {
      ${color.text(COLOR_PALETTE.PRIMARY_BASE)}
    }
  }

  &[data-disabled] {
    cursor: not-allowed;
    ${color.background('transparent')}
    ${color.text(COLOR_PALETTE.NEUTRAL_A20)}

    ${Icon} {
      ${color.text(COLOR_PALETTE.NEUTRAL_A20)}
    }
  }

  ${Icon} {
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
  }
`;

const modVariant = (variantName) =>
  ({
    [BUTTON_VARIANT.PRIMARY]: modPrimaryVariant,
    [BUTTON_VARIANT.SECONDARY]: modSecondaryVariant,
    [BUTTON_VARIANT.OUTLINE]: modOutlineVariant,
    [BUTTON_VARIANT.OUTLINE_PRIMARY]: modOutlinePrimaryVariant,
    [BUTTON_VARIANT.OUTLINE_LIGHT]: modOutlineLightVariant,
    [BUTTON_VARIANT.FLAT]: modFlatVariant,
    [BUTTON_VARIANT.TEXT]: modTextVariant,
  }[variantName]);

function BaseButton({ children, icon, disabled, active, innerRef, ...rest }) {
  return (
    <Wrapper
      data-active={active || undefined}
      data-disabled={disabled || undefined}
      disabled={disabled}
      ref={innerRef}
      {...rest}
    >
      {children}
    </Wrapper>
  );
}

BaseButton.mods = {
  variant: modVariant,
};

export default withStylesAndInnerRef(BaseButton);
