import { useLifecycles } from 'react-use';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import Grid from 'design-system/components/Grid';
import Layout from 'design-system/components/Layout';
import { Navigation } from '../../../partials';
import Sidebar from '../partials/Sidebar';
import { CHECKOUT_STEPS } from '../partials/CheckoutStepper/CheckoutStepper';
import Form from './partials/Form/Form';
import Summary from './partials/Summary';
import {
  PAYMENT_METHOD_PAGE_MOUNTED,
  PAYMENT_METHOD_PAGE_UNMOUNTED,
} from './events';
import { isFormLoadedSelector } from '../BillingInformation/partials/Form/selectors';
import { checkoutPageSCRIdSelector } from '../selectors';
import PublisherData from '../PublisherData/PublisherData';

export default function PaymentMethod() {
  const subscriptionChangeRequestId = useSelector(checkoutPageSCRIdSelector);
  useLifecycles(
    () =>
      dispatch({
        id: PAYMENT_METHOD_PAGE_MOUNTED,
        payload: { subscriptionChangeRequestId },
      }),
    () => dispatch(PAYMENT_METHOD_PAGE_UNMOUNTED)
  );

  const formLoaded = useSelector(isFormLoadedSelector);
  return (
    <Layout independentScroll>
      <Layout.Header>
        <Navigation title={<PublisherData />} hideLinks hideAppBanner />
      </Layout.Header>
      <Layout.LeftSidebar>
        <Sidebar currentStep={CHECKOUT_STEPS.PAYMENT} />
      </Layout.LeftSidebar>
      <Layout.Content>
        <Layout.Padded>
          <Grid
            areas={({ repeat }) => `
            "${repeat(5, 'form')} ${repeat(3, 'summary')} ."
          `}
          >
            <Grid.Area $id="form">
              {formLoaded && (
                <Form
                  subscriptionChangeRequestId={subscriptionChangeRequestId}
                />
              )}
            </Grid.Area>
            <Grid.Area $id="summary">
              <Summary
                subscriptionChangeRequestId={subscriptionChangeRequestId}
              />
            </Grid.Area>
          </Grid>
        </Layout.Padded>
      </Layout.Content>
    </Layout>
  );
}
