import { mapTableFiltersToApiFilters } from '../../../../partials/DataTable/utils/mapTableToApiFilters';
import { fromUTCDate } from '../../../../utils/dates';

const filterMappers = {
  'lead-suggestion': mapSuggestion,
  leadsProperty: mapLeadsProperty,
  leadsProject: mapLeadsProject,
  lastActivityDateRange: mapDateRange,
};

export function mapLeadFilters(tableFilters) {
  return mapTableFiltersToApiFilters(tableFilters, filterMappers);
}

function mapSuggestion({ type, text }) {
  return {
    [type]: text,
  };
}

function mapLeadsProperty({ id }) {
  return { adId: id };
}

function mapLeadsProject({ id }) {
  return { projectId: id };
}

function mapDateRange({ startDate, endDate }) {
  const toDate = (date) => (typeof date === 'string' ? new Date(date) : date);
  const toString = (date) => fromUTCDate(toDate(date)).toISOString();
  return {
    from: startDate ? toString(startDate) : null,
    to: endDate ? toString(endDate) : null,
  };
}
