import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import SkeletonBone from 'design-system/components/SkeletonBone';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { BORDER_SIZE } from 'design-system/styles/border';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import Typography from 'design-system/components/Typography/Typography';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import { propertyTypesTranslationTags } from '../../../../../constants/ad';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  isDeveloperSelector,
  isPublisherFromMxSelector,
} from '../../../../../selectors/publisher';

const Item = styled.div`
  display: flex;
  align-items: center;
  padding-right: 12px;
  border-right: ${BORDER_SIZE.BASE}px solid ${COLOR_PALETTE.NEUTRAL_A10};

  ${Icon} {
    ${Icon.mods.size(ICON_SIZE.SMALL)}
    ${spacing.inline(SPACING_SIZE.S)}
  }

  ${Body1} {
    ${Typography.mods.weight(FONT_WEIGHT.SEMIBOLD)}
  }

  ${Body1}, ${Icon} {
    ${({ active }) => active && color.text(COLOR_PALETTE.PRIMARY_BASE)}
  }

  &:last-child {
    padding-right: initial;
    border-right: initial;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 18px;
  grid-gap: 12px;
`;

function AdStatus({ property }) {
  const { propertyType, isPublished, isBoosted } = property;
  const isDeveloper = useSelector(isDeveloperSelector);
  const isMXPublisher = useSelector(isPublisherFromMxSelector);
  return (
    <Wrapper>
      <Item>
        <Body1>
          <FormattedMessageWithValidation
            id={propertyTypesTranslationTags.get(propertyType)}
          />
        </Body1>
      </Item>
      {!isMXPublisher &&
        !isDeveloper &&
        (isBoosted ? (
          <Item active>
            <Icon glyph="bolt" />
            <Body1>
              <FormattedMessageWithValidation id="enqdetail_property_detail_boosted" />
            </Body1>
          </Item>
        ) : (
          <Item>
            <Icon glyph={isPublished ? 'eye' : 'eyeCrossed'} />
            <Body1>
              <FormattedMessageWithValidation
                id={
                  isPublished
                    ? 'enqdetail_property_detail_listed'
                    : 'enqdetail_property_detail_unlisted'
                }
              />
            </Body1>
          </Item>
        ))}
    </Wrapper>
  );
}

function Skeleton() {
  return (
    <Wrapper>
      <Item>
        <SkeletonBone $width="68px" $height="14px" />
      </Item>
      <Item>
        <SkeletonBone $width="68px" $height="14px" />
      </Item>
    </Wrapper>
  );
}

AdStatus.Skeleton = Skeleton;

export default AdStatus;
