import { withoutEventPropagation } from 'design-system/utils/withoutEventPropagation';
import styled from 'styled-components';
import Table from 'design-system/components/Table';
import Checkbox from 'design-system/components/Checkbox/Checkbox';
import { useRowSelection } from '../../hooks/useRowSelection';

const CheckboxCell = styled(Table.Cell)`
  padding-right: 0;
`;

export function CheckboxColumn(props) {
  const { isSelected, toggleSelection } = useRowSelection(props);

  return (
    <CheckboxCell onClick={withoutEventPropagation()}>
      <Checkbox checked={isSelected} onChange={toggleSelection} />
    </CheckboxCell>
  );
}
