import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { environment } from '../../../../../coeffects/environment';
import { navigateTo } from '../../../../../effects/routing';
import { checkoutPaymentMethodRoute } from '../../../../../utils/proppitWebRouter';
import { analytics } from '../../../../../effects/analytics';
import { ACCEPTED_FILE_EXTENSIONS } from '../../../PaymentMethod/partials/Form/constants';
import { addItem } from '../../../../../utils/formDataUtils';
import {
  BILLING_ENTITY_EDIT_BUTTON_HAS_BEEN_CLICKED,
  BILLING_ENTITY_FILE,
  BILLING_ENTITY_FILE_DATA,
  BILLING_ENTITY_FILE_DISABLE_SUBMIT,
  BILLING_ENTITY_FILE_IS_LOADED,
  BILLING_ENTITY_FILE_LOADING_ERROR,
} from '../../constants';
import { CLOSE_DIALOG, OPEN_DIALOG } from '../../../../../events/dialogs';
import { EDIT_CONFIRMATION } from './constants';
import { REDIRECTED_TO_LOGIN } from '../../../../../App/events';
import { COUNTRY_CODE } from '../../../../../constants/country';
import { IS_LOGGED_IN } from '../../../../Authentication/constants';

export const INITIALIZE_FORM = 'INITIALIZE_FORM';
export const BILLING_INFORMATION_SUBMITTED = `BILLING_INFORMATION_SUBMITTED`;
export const BILLING_INFORMATION_SAVED = `BILLING_INFORMATION_SAVED`;
export const BILLING_INFORMATION_FORM_ERROR = `BILLING_INFORMATION_FORM_ERROR`;
export const BILL_REQUIRED_SWITCH_CHANGED = 'BILL_REQUIRED_SWITCH_CHANGED';
export const BILLING_ENTITY_FILE_SELECTED = 'BILLING_ENTITY_FILE_SELECTED';
export const BILLING_ENTITY_FILE_LOADED = 'BILLING_ENTITY_FILE_LOADED';
export const BILLING_ENTITY_FILE_ERROR = 'BILLING_ENTITY_FILE_ERROR';
export const BILLING_ENTITY_FILE_DELETED = 'BILLING_ENTITY_FILE_DELETED';
export const BILLING_INFORMATION_FILE_HAS_BEEN_UPLOADED =
  'BILLING_INFORMATION_FILE_HAS_BEEN_UPLOADED';
export const BILLING_INFORMATION_FILE_UPLOAD_FAIL =
  'BILLING_INFORMATION_FILE_UPLOAD_FAIL';
export const BILLING_INFORMATION_EDIT_FILE_DATA =
  'BILLING_INFORMATION_EDIT_FILE_DATA';
export const BILLING_INFORMATION_EDIT_BILLING_BUTTON_CLICKED =
  'BILLING_INFORMATION_EDIT_BILLING_BUTTON_CLICKED';
export const BILLING_INFORMATION_EDIT_LOGIN_REQUIRED =
  'BILLING_INFORMATION_EDIT_LOGIN_REQUIRED';
export const BILLING_INFORMATION_EDIT_FORM = 'BILLING_INFORMATION_EDIT_FORM';

const MAX_FILE_SIZE_IN_MEGABYTES = 5;

registerEventHandler(
  INITIALIZE_FORM,
  (_, { currentBillingEntityRequiresBill }) =>
    state.set({
      billingInformationFormBillRequired: currentBillingEntityRequiresBill,
    })
);

registerEventHandler(
  BILLING_INFORMATION_SUBMITTED,
  (
    {
      environment: { apiUrl },
      state: {
        hasHaciendaRequestFailed,
        subRoute,
        billingEntityEditButtonHasBeenClicked,
        billingEntityId,
      },
    },
    { subscriptionChangeRequestId, billingEntityFile, ...formData }
  ) => {
    if (billingEntityId && !billingEntityEditButtonHasBeenClicked) {
      return {
        ...billingInformationStepFinishedEffects(
          subscriptionChangeRequestId,
          subRoute
        ),
      };
    }
    return {
      ...state.set({ billingInformationFormSubmitted: true }),
      ...http.post({
        url: `${apiUrl}/subscription-change-requests/${subscriptionChangeRequestId}/billing-entity`,
        body:
          hasHaciendaRequestFailed || formData.billRequired !== false
            ? allFormDataBody(formData)
            : billRequirementOnlyBody(formData),
        successEvent: {
          id: BILLING_INFORMATION_SAVED,
          payload: {
            billingEntityId: formData.id,
            subscriptionChangeRequestId,
            billingEntityFile,
          },
        },
        errorEvent: {
          id: BILLING_INFORMATION_FORM_ERROR,
          payload: subscriptionChangeRequestId,
        },
      }),
    };
  },
  [
    environment(),
    state.get({
      hasHaciendaRequestFailed: 'hasHaciendaRequestFailed',
      subRoute: 'checkoutConfig.subRoute',
      billingEntityEditButtonHasBeenClicked:
        'checkoutPage.billingEntityEditButtonHasBeenClicked',
      billingEntityId: 'publisher.billingEntityId',
    }),
  ]
);

registerEventHandler(
  BILLING_INFORMATION_SAVED,
  (
    { environment: { apiUrl }, state: { subRoute } },
    [__, { subscriptionChangeRequestId, billingEntityId, billingEntityFile }]
  ) => ({
    ...(billingEntityFile
      ? http.post({
          url: `${apiUrl}/billing-entities/${billingEntityId}/file`,
          body: addItem('billingEntityFile', billingEntityFile),
          successEvent: {
            id: BILLING_INFORMATION_FILE_HAS_BEEN_UPLOADED,
            payload: { subscriptionChangeRequestId },
          },
          errorEvent: {
            id: BILLING_INFORMATION_FILE_UPLOAD_FAIL,
          },
        })
      : billingInformationStepFinishedEffects(
          subscriptionChangeRequestId,
          subRoute
        )),
    ...analytics.trackClick(
      'submit-billing-entity',
      'first-payment-checkout-billing-entity',
      { subscriptionChangeRequestId }
    ),
  }),
  [environment(), state.get({ subRoute: 'checkoutConfig.subRoute' })]
);

function billingInformationStepFinishedEffects(
  subscriptionChangeRequestId,
  subRoute
) {
  const url = checkoutPaymentMethodRoute({
    subscriptionChangeRequestId,
    subRoute,
  });

  return {
    ...state.set({ billingInformationFormSubmitted: false }),
    ...navigateTo(url, true),
  };
}

registerEventHandler(
  BILLING_INFORMATION_FILE_HAS_BEEN_UPLOADED,
  ({ state: { subRoute } }, [__, { subscriptionChangeRequestId }]) =>
    billingInformationStepFinishedEffects(
      subscriptionChangeRequestId,
      subRoute
    ),
  [
    state.get({
      subRoute: 'checkoutConfig.subRoute',
      origin: 'selfService.origin',
      cta: 'selfService.cta',
    }),
  ]
);

registerEventHandler(BILLING_INFORMATION_FILE_UPLOAD_FAIL, () =>
  state.set({ billingInformationFormSubmitted: true })
);

registerEventHandler(
  BILLING_INFORMATION_FORM_ERROR,
  (_, [__, subscriptionChangeRequestId]) => ({
    ...state.set({ billingInformationFormSubmitted: false }),
    ...analytics.trackClick(
      'submit-billing-entity-error',
      'first-payment-checkout-billing-entity',
      { subscriptionChangeRequestId }
    ),
  })
);

registerEventHandler(BILL_REQUIRED_SWITCH_CHANGED, (_, checked) =>
  state.set({
    billingInformationFormBillRequired: checked,
    [BILLING_ENTITY_FILE_DATA]: undefined,
    [BILLING_ENTITY_FILE]: undefined,
    [BILLING_ENTITY_FILE_LOADING_ERROR]: undefined,
    [BILLING_ENTITY_FILE_DISABLE_SUBMIT]: checked,
  })
);

registerEventHandler(
  BILLING_ENTITY_FILE_SELECTED,
  ({ environment: { apiUrl } }, { file, subscriptionChangeRequestId }) => {
    const filename = file.name.toLowerCase();
    if (ACCEPTED_FILE_EXTENSIONS.some((ext) => filename.endsWith(ext))) {
      const fileSizeInMegaBytes = file.size / 1024 / 1024;
      if (fileSizeInMegaBytes > MAX_FILE_SIZE_IN_MEGABYTES) {
        return state.set({ billingEntityFileSizeLimitExceededError: true });
      }
      return {
        ...http.post({
          url: `${apiUrl}/load-billing-entity-file`,
          body: addItem('billingEntityFile', file),

          successEvent: {
            id: BILLING_ENTITY_FILE_LOADED,
            payload: subscriptionChangeRequestId,
          },
          errorEvent: {
            id: BILLING_ENTITY_FILE_ERROR,
            payload: subscriptionChangeRequestId,
          },
        }),
        ...state.set({
          [BILLING_ENTITY_FILE]: file,
          [BILLING_ENTITY_FILE_IS_LOADED]: true,
          [BILLING_ENTITY_FILE_LOADING_ERROR]: false,
        }),
      };
    }
    return state.set({
      billingEntityFileSizeLimitExceededError: undefined,
      [BILLING_ENTITY_FILE_LOADING_ERROR]: undefined,
    });
  },
  [environment()]
);

registerEventHandler(
  BILLING_ENTITY_FILE_LOADED,
  (_, [response, subscriptionChangeRequestId]) => ({
    ...state.merge({
      [BILLING_ENTITY_FILE_DATA]: response.data,
      [BILLING_ENTITY_FILE_IS_LOADED]: false,
      [BILLING_ENTITY_FILE_DISABLE_SUBMIT]: false,
      publisher: { billingEntityId: response.data.id },
    }),
    ...analytics.trackClick(
      'submit-payment-success',
      'first-payment-checkout-billing-information',
      { subscriptionChangeRequestId }
    ),
  })
);

registerEventHandler(
  BILLING_ENTITY_FILE_ERROR,
  (_, [response, subscriptionChangeRequestId]) => ({
    ...state.set({
      [BILLING_ENTITY_FILE]: undefined,
      [BILLING_ENTITY_FILE_IS_LOADED]: false,
      [BILLING_ENTITY_FILE_LOADING_ERROR]: true,
      [BILLING_ENTITY_FILE_DISABLE_SUBMIT]: true,
      hasHaciendaRequestFailed: response.status === 422,
    }),
    ...analytics.trackClick(
      'billing-entity-load-file-error',
      'first-payment-checkout-billing-information',
      { subscriptionChangeRequestId }
    ),
  })
);

registerEventHandler(BILLING_ENTITY_FILE_DELETED, () =>
  state.set({
    [BILLING_ENTITY_FILE_DATA]: undefined,
    [BILLING_ENTITY_FILE]: undefined,
    [BILLING_ENTITY_FILE_DISABLE_SUBMIT]: true,
    'publisher.billingEntityId': undefined,
  })
);

registerEventHandler(BILLING_INFORMATION_EDIT_FILE_DATA, () =>
  effects.dispatchMany([
    BILLING_ENTITY_FILE_DELETED,
    BILLING_INFORMATION_EDIT_FORM,
    CLOSE_DIALOG,
  ])
);

registerEventHandler(BILLING_INFORMATION_EDIT_FORM, () =>
  state.merge({
    checkoutPage: {
      [BILLING_ENTITY_EDIT_BUTTON_HAS_BEEN_CLICKED]: true,
    },
  })
);

registerEventHandler(
  BILLING_INFORMATION_EDIT_BILLING_BUTTON_CLICKED,
  ({ state: { isLoggedIn, countryCode } }) => {
    if (isLoggedIn) {
      if (countryCode === COUNTRY_CODE.MEXICO) {
        return effects.dispatch({
          id: OPEN_DIALOG,
          payload: { id: EDIT_CONFIRMATION },
        });
      }
      return effects.dispatch(BILLING_INFORMATION_EDIT_FORM);
    }
    return effects.dispatch(BILLING_INFORMATION_EDIT_LOGIN_REQUIRED);
  },
  [
    state.get({
      isLoggedIn: IS_LOGGED_IN,
      countryCode: 'publisher.countryCode',
    }),
  ]
);

registerEventHandler(BILLING_INFORMATION_EDIT_LOGIN_REQUIRED, () =>
  effects.dispatch(REDIRECTED_TO_LOGIN)
);

function allFormDataBody(formData) {
  return {
    id: formData.id,
    vatNumber: formData.taxId,
    name: formData.legalName,
    countryCode: formData.country,
    address: formData.address,
    city: formData.city,
    province: formData.province,
    postalCode: formData.postalCode,
    email: formData.email,
    phone:
      formData.phone?.dialCode && formData.phone?.phoneNumber
        ? `${formData.phone.dialCode}${formData.phone.phoneNumber}`
        : undefined,
    taxPayerType: formData.typeOfPerson,
    retefuente: formData.retefuente,
    reteIca: formData.reteIca,
    reteIva: formData.reteIva,
    billRequired: formData.billRequired,
    taxRegime: formData.taxRegime,
  };
}

function billRequirementOnlyBody(formData) {
  return {
    billRequired: formData.billRequired,
  };
}
