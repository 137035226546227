import styled from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';

const Wrapper = styled.div`
  ${spacing.inset(SPACING_SIZE.M)}
  ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
  ${radius.regular(RADIUS_SIZE.LARGE)}
`;

function Tip({ children, ...rest }) {
  return <Wrapper {...rest}>{children}</Wrapper>;
}

export default styled(Tip)``;
