import * as React from "react";
const SvgNewProperty = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={106}
    height={118}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#0ACC86"
      d="M28.5 44a3.5 3.5 0 0 1 3.5-3.5h3a3.5 3.5 0 0 1 3.5 3.5v73.5h-10z"
    />
    <path
      fill="#fff"
      stroke="#0ACC86"
      d="M22.5 46a1.5 1.5 0 0 1 1.5-1.5h18a1.5 1.5 0 0 1 1.5 1.5v71.5h-21z"
    />
    <path
      fill="#0ACC86"
      d="M22 48a4 4 0 0 1 4-4h1v74h-5zM35 64a2 2 0 0 1 2-2h3v56h-5z"
      opacity={0.3}
    />
    <rect width={22} height={1} x={21.999} y={54} fill="#0ACC86" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={57} fill="#0ACC86" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={60} fill="#0ACC86" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={63} fill="#0ACC86" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={66} fill="#0ACC86" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={69} fill="#0ACC86" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={72} fill="#0ACC86" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={75} fill="#0ACC86" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={78} fill="#0ACC86" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={81} fill="#0ACC86" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={84} fill="#0ACC86" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={87} fill="#0ACC86" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={90} fill="#0ACC86" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={93} fill="#0ACC86" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={96} fill="#0ACC86" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={99} fill="#0ACC86" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={102} fill="#0ACC86" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={105} fill="#0ACC86" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={108} fill="#0ACC86" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={111} fill="#0ACC86" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={114} fill="#0ACC86" rx={0.5} />
    <rect width={22} height={1} x={21.999} y={117} fill="#0ACC86" rx={0.5} />
    <path
      fill="#fff"
      stroke="#0ACC86"
      d="M16.5 74a1.5 1.5 0 0 1 1.5-1.5h8a1.5 1.5 0 0 1 1.5 1.5v43.5h-11z"
    />
    <rect width={6} height={1} x={16} y={75} fill="#0ACC86" rx={0.5} />
    <rect width={6} height={1} x={16} y={78} fill="#0ACC86" rx={0.5} />
    <rect width={6} height={1} x={16} y={81} fill="#0ACC86" rx={0.5} />
    <rect width={6} height={1} x={16} y={84} fill="#0ACC86" rx={0.5} />
    <rect width={6} height={1} x={16} y={87} fill="#0ACC86" rx={0.5} />
    <rect width={6} height={1} x={16} y={90} fill="#0ACC86" rx={0.5} />
    <rect width={6} height={1} x={16} y={93} fill="#0ACC86" rx={0.5} />
    <rect width={6} height={1} x={16} y={96} fill="#0ACC86" rx={0.5} />
    <path
      fill="#0ACC86"
      fillRule="evenodd"
      d="M80 77a.5.5 0 0 1-.5-.5V.5a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      stroke="#0ACC86"
      d="M74.5 19a3.5 3.5 0 0 1 3.5-3.5h4a3.5 3.5 0 0 1 3.5 3.5v63.5h-11z"
    />
    <path
      fill="#fff"
      stroke="#0ACC86"
      d="M69.5 29a3.5 3.5 0 0 1 3.5-3.5h14a3.5 3.5 0 0 1 3.5 3.5v88.5h-21z"
    />
    <path
      fill="#0ACC86"
      d="M70 28a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v90H70z"
      opacity={0.2}
    />
    <path
      fill="#fff"
      stroke="#0ACC86"
      d="M93.5 30a1.5 1.5 0 0 0-1.5-1.5H68a1.5 1.5 0 0 0-1.5 1.5v87.5h27z"
    />
    <path fill="#0ACC86" d="M93 30a1 1 0 0 0-1-1h-2v89h3z" opacity={0.3} />
    <path
      fill="#0ACC86"
      fillRule="evenodd"
      d="M69.5 115.015a.5.5 0 0 1-.5-.5v-76a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5M72.5 115.015a.5.5 0 0 1-.5-.5v-76a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5M75.5 115.015a.5.5 0 0 1-.5-.5v-76a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5M78.5 115.015a.5.5 0 0 1-.5-.5v-76a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5M81.5 115.015a.5.5 0 0 1-.5-.5v-76a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5M84.5 115.014a.5.5 0 0 1-.5-.5v-76a.5.5 0 1 1 1 0v76a.5.5 0 0 1-.5.5M87.5 115.015a.5.5 0 0 1-.5-.5v-76a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5M90.5 115a.5.5 0 0 1-.5-.5v-76a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      stroke="#0ACC86"
      d="M37.5 62a1.5 1.5 0 0 1 1.5-1.5h32a1.5 1.5 0 0 1 1.5 1.5v55.5h-35z"
    />
    <rect width={24} height={1} x={43} y={65} fill="#0ACC86" rx={0.5} />
    <rect width={24} height={1} x={43} y={67.995} fill="#0ACC86" rx={0.5} />
    <rect width={24} height={1} x={43} y={70.99} fill="#0ACC86" rx={0.5} />
    <rect width={24} height={1} x={43} y={73.985} fill="#0ACC86" rx={0.5} />
    <rect width={24} height={1} x={43} y={76.981} fill="#0ACC86" rx={0.5} />
    <rect width={24} height={1} x={43} y={79.976} fill="#0ACC86" rx={0.5} />
    <rect width={24} height={1} x={43} y={82.971} fill="#0ACC86" rx={0.5} />
    <rect width={24} height={1} x={43} y={85.966} fill="#0ACC86" rx={0.5} />
    <rect width={24} height={1} x={43} y={88.961} fill="#0ACC86" rx={0.5} />
    <rect width={24} height={1} x={43} y={91.957} fill="#0ACC86" rx={0.5} />
    <rect width={24} height={1} x={43} y={94.951} fill="#0ACC86" rx={0.5} />
    <rect width={24} height={1} x={43} y={97.947} fill="#0ACC86" rx={0.5} />
    <rect width={24} height={1} x={43} y={100.941} fill="#0ACC86" rx={0.5} />
    <rect width={24} height={1} x={43} y={103.938} fill="#0ACC86" rx={0.5} />
    <rect width={24} height={1} x={43} y={106.932} fill="#0ACC86" rx={0.5} />
    <rect width={24} height={1} x={43} y={110} fill="#0ACC86" rx={0.5} />
    <path fill="#0ACC86" d="M0 117h106v1H0z" />
  </svg>
);
export default SvgNewProperty;
