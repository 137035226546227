export const SUBSCRIPTION_TYPES = {
  BASE_SUBSCRIPTION: 'base-subscription',
  SUPERBOOST: 'superboost',
};

export const BILLING_CYCLES = [1, 3, 6, 12];

export const CUSTOM_DISCOUNT_NAME = 'custom';
export const UPFRONT_DISCOUNT_NAME = 'upfrontPayment';
export const PREVIOUS_SUBSCRIPTION_DISCOUNT_NAME = 'previousSubscription';
export const CURRENT_SUBSCRIPTION_DISCOUNT_NAME = 'currentSubscription';
export const PROPORTIONAL_SUPERBOOST_DISCOUNT_NAME = 'proportionalSuperboost';
export const REFUND_DISCOUNTS = [
  PREVIOUS_SUBSCRIPTION_DISCOUNT_NAME,
  CURRENT_SUBSCRIPTION_DISCOUNT_NAME,
  PROPORTIONAL_SUPERBOOST_DISCOUNT_NAME,
];
export const DISCOUNT_NAMES = {
  CUSTOM: CUSTOM_DISCOUNT_NAME,
  UPFRONT: UPFRONT_DISCOUNT_NAME,
  PREVIOUS_SUBSCRIPTION: PREVIOUS_SUBSCRIPTION_DISCOUNT_NAME,
  CURRENT_SUBSCRIPTION: CURRENT_SUBSCRIPTION_DISCOUNT_NAME,
  PROPORTIONAL_SUPERBOOST: PROPORTIONAL_SUPERBOOST_DISCOUNT_NAME,
};

export const TAX_DISCOUNT = {
  RETEFUENTE: 'retefuenteCO',
  RETE_IVA: 'reteIvaCO',
  RETE_ICA: 'reteIcaCO',
};
