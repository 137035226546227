import { registerEffectHandler } from 'reffects';

const CLIPBOARD_COPY = 'clipboard.copy';

export default function registerClipboardEffects(clipboard) {
  registerEffectHandler(CLIPBOARD_COPY, ({ text }) =>
    // clipboard does not work on http requests. https://web.dev/async-clipboard/#security-and-permissions
    clipboard.writeText(text)
  );
}

export const clipboard = {
  copy(text) {
    return {
      [CLIPBOARD_COPY]: { text },
    };
  },
};
