export function createAnalyticsClient(clientProvider) {
  return {
    track({ name, userId, locale, ...properties }) {
      const client = clientProvider();
      client.track(name, properties, {
        userId,
        context: { locale },
      });
    },
    page({ name, userId, locale, ...properties }) {
      const client = clientProvider();
      client.page(name, properties, {
        userId,
        context: { locale },
      });
    },
  };
}
