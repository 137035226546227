import { useSelector } from 'reffects-store';
import { PrimaryButton } from 'design-system/components/Button/presets';
import Illustration from 'design-system/components/Illustration/Illustration';
import { supportEmailSelector } from '../../../selectors/config';
import FormattedMessageWithTranslatedCountry from '../../../partials/FormattedMessageWithTranslatedCountry/FormattedMessageWithCountry';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import ActionCard from '../../../partials/ActionCard';

export function CreateNewProjectBanner() {
  const supportEmail = useSelector(supportEmailSelector);

  return (
    <ActionCard
      illustration={<Illustration name="newProject" />}
      title={
        <FormattedMessageWithTranslatedCountry id="overview_developer_projects_summary_table_empty_state_title" />
      }
      cta={
        <PrimaryButton
          forwardedAs="a"
          href={`mailto:${supportEmail}`}
          target="_blank"
        >
          <FormattedMessageWithValidation id="overview_developer_projects_summary_table_empty_state_cta" />
        </PrimaryButton>
      }
    />
  );
}
