import { subscribe, useSelector } from 'reffects-store';
import Banner from 'design-system/components/Banner';
import Anchor from 'design-system/components/Anchor';
import { FONT_SIZE } from 'design-system/styles/typography';
import FormattedMessageWithValidation from '../FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  checkoutBillingInformationRoute,
  subscriptionRoute,
} from '../../utils/proppitWebRouter';
import {
  daysToExpireSuperboostSelector,
  nextBillingDateSelector,
  nextBillingDateSuperboostSelector,
  publisherSubscriptionPlanIsPaidSelector,
  subscriptionChangeRequestSelector,
} from '../../selectors/publisher';
import CustomFormattedDate from '../FormattedDate/CustomFormattedDate';
import useTracking from '../../hooks/useTracking';
import { UPSELL_SUBROUTE } from '../../pages/Checkout/configuration/constants';
import {
  shouldShowPendingSCRBannerSelector,
  shouldShowSuperboostBannerSelector,
} from './selectors';

function PendingSubscriptionChangeRequestBanner({ daysToExpire }) {
  const shouldBeShown = useSelector(shouldShowPendingSCRBannerSelector);
  const shouldBeShownSuperboost = useSelector(
    shouldShowSuperboostBannerSelector
  );

  const nextBillingSuperboostDate = useSelector(
    nextBillingDateSuperboostSelector
  );
  const nextBillingDate = useSelector(nextBillingDateSelector);
  const hasPaidSubscription = useSelector(
    publisherSubscriptionPlanIsPaidSelector
  );
  if (!shouldBeShown) {
    return null;
  }
  if (shouldBeShownSuperboost) {
    return (
      <BannerSuperboostPendingSubscription
        nextBillingDates={nextBillingSuperboostDate}
        daysToExpire={daysToExpire}
      />
    );
  }

  const translationTag = hasPaidSubscription
    ? 'banner_subscription_change_pending'
    : 'banner_subscription_change_pending_free';

  return (
    <BannerPendingSubscription
      nextBillingDates={nextBillingDate}
      translationTags={translationTag}
    />
  );
}

function BannerPendingSubscription({ nextBillingDates, translationTags }) {
  const pendingSubscriptionChangeRequest = useSelector(
    subscriptionChangeRequestSelector
  );

  const paymentTrackingRef = useTracking({
    trackClick: {
      eventName: 'pay-request',
    },
    trackImpression: {
      elementName: 'pay-request-btn',
    },
    sectionName: 'banner-pending-subscription-change-request',
  });
  const subscriptionTrackingRef = useTracking({
    trackClick: {
      eventName: 'go-to-subscription-page',
    },
    trackImpression: {
      elementName: 'go-to-subscription-page-btn',
    },
    sectionName: 'banner-pending-subscription-change-request',
  });
  if (!pendingSubscriptionChangeRequest) {
    return null;
  }

  return (
    <Banner sticky>
      <FormattedMessageWithValidation
        id={translationTags}
        values={{
          make_the_payment: (
            <Anchor
              href={checkoutBillingInformationRoute({
                subscriptionChangeRequestId:
                  pendingSubscriptionChangeRequest.id,
                subRoute: UPSELL_SUBROUTE,
              })}
              $size={FONT_SIZE.S}
              ref={paymentTrackingRef}
            >
              <FormattedMessageWithValidation id="make_the_payment" />
            </Anchor>
          ),
          nextBillingDate: (
            <CustomFormattedDate value={nextBillingDates} timeZone="utc" />
          ),
          my_subscription: (
            <Anchor
              href={subscriptionRoute()}
              $size={FONT_SIZE.S}
              ref={subscriptionTrackingRef}
            >
              <FormattedMessageWithValidation id="my_subscription" />
            </Anchor>
          ),
        }}
      />
    </Banner>
  );
}
function BannerSuperboostPendingSubscription({
  nextBillingDates,
  daysToExpire,
}) {
  const pendingSubscriptionChangeRequest = useSelector(
    subscriptionChangeRequestSelector
  );

  const paymentTrackingRef = useTracking({
    trackClick: {
      eventName: 'pay-request',
    },
    trackImpression: {
      elementName: 'pay-request-btn',
    },
    sectionName: 'banner-pending-subscription-change-request',
  });

  if (!pendingSubscriptionChangeRequest) {
    return null;
  }

  return (
    <Banner sticky>
      <FormattedMessageWithValidation
        id="banner_superboost_about_to_expire"
        values={{
          daysToExpire,
          make_the_payment: (
            <Anchor
              href={checkoutBillingInformationRoute({
                subscriptionChangeRequestId:
                  pendingSubscriptionChangeRequest.id,
                subRoute: UPSELL_SUBROUTE,
              })}
              $size={FONT_SIZE.S}
              ref={paymentTrackingRef}
            >
              <FormattedMessageWithValidation id="make_the_payment" />
            </Anchor>
          ),
          nextBillingDate: (
            <CustomFormattedDate value={nextBillingDates} timeZone="utc" />
          ),
        }}
      />
    </Banner>
  );
}

export default subscribe(PendingSubscriptionChangeRequestBanner, (state) => ({
  daysToExpire: daysToExpireSuperboostSelector(state, new Date()),
}));
