import * as yup from 'yup';
import { number, string } from 'yup';
import { PhoneNumberUtil } from 'google-libphonenumber';
import PhoneField from '../../../../partials/fields/PhoneField';
import GenericDetailForm from './GenericDetailForm';
import useInternationalization from '../../../../hooks/useInternationalization';
import { extractCountryAndNumber } from '../../../../partials/fields/ExtractCountryAndNumber';

function PhoneDetailForm({ detail, onSubmit, onCancel }) {
  const intl = useInternationalization();
  const phoneUtil = PhoneNumberUtil.getInstance();

  const handleFormSubmit = ({ contactPhone }) => {
    onSubmit(`${contactPhone.dialCode}${contactPhone.phoneNumber}`);
  };

  return (
    <GenericDetailForm
      defaultValues={{
        contactPhone: extractCountryAndNumber(detail.value),
      }}
      validationSchema={{
        contactPhone: yup
          .object({
            phoneNumber: number().required(
              intl.formatMessageWithValidation({
                id: 'form_validations_required_field',
              })
            ),
            countryCode: string().required(),
          })
          .test({
            name: 'contactPhone',
            message: intl.formatMessageWithValidation({
              id: 'form_validations_invalid_phone_number',
            }),
            test: (value) => {
              try {
                const phoneWithPrefix = `${value.dialCode}${value.phoneNumber}`;
                return phoneUtil.isValidNumber(
                  phoneUtil.parseAndKeepRawInput(
                    phoneWithPrefix,
                    value.countryCode
                  )
                );
              } catch (e) {
                return false;
              }
            },
          }),
      }}
      onSubmit={handleFormSubmit}
      onCancel={onCancel}
    >
      <PhoneField name="contactPhone" />
    </GenericDetailForm>
  );
}

export default PhoneDetailForm;
