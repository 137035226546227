import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { environment } from '../../../../coeffects/environment';
import { publisherContactSalesRoute } from '../../../../utils/apiRouter';
import { toast } from '../../../../effects/toast';
import { navigateTo } from '../../../../effects/routing';
import { checkoutBillingInformationRoute } from '../../../../utils/proppitWebRouter';
import { UPSELL_SUBROUTE } from '../../../Checkout/configuration/constants';

export const SUBSCRIPTION_TO_UPGRADE_REQUESTED =
  'SUBSCRIPTION_TO_UPGRADE_REQUESTED';
export const SUBSCRIPTION_TO_UPGRADE_LOADED = 'SUBSCRIPTION_TO_UPGRADE_LOADED';
export const PUBLISHER_CONTACTS_SALES = 'PUBLISHER_CONTACTS_SALES';
export const PUBLISHER_CONTACTS_SALES_SUCCEED =
  'PUBLISHER_CONTACTS_SALES_SUCCEED';
export const PUBLISHER_CONTACTS_SALES_FAILED =
  'PUBLISHER_CONTACTS_SALES_FAILED';

export const SUBSCRIBE_BUTTON_CLICKED = 'SUBSCRIBE_BUTTON_CLICKED';

export const SCR_FROM_RECOMMENDED_PLAN_CREATED =
  'SCR_FROM_RECOMMENDED_PLAN_CREATED';

registerEventHandler(
  SUBSCRIPTION_TO_UPGRADE_REQUESTED,
  ({ environment: { apiUrl } }) =>
    http.get({
      url: `${apiUrl}/subscription-to-upgrade`,
      successEvent: SUBSCRIPTION_TO_UPGRADE_LOADED,
    }),
  [environment()]
);

registerEventHandler(SUBSCRIPTION_TO_UPGRADE_LOADED, (_, [{ data }]) =>
  state.set({ subscriptionToUpgrade: data })
);

registerEventHandler(
  PUBLISHER_CONTACTS_SALES,
  ({ environment: { apiUrl } }, _) => ({
    ...http.post({
      url: publisherContactSalesRoute(apiUrl),
      successEvent: PUBLISHER_CONTACTS_SALES_SUCCEED,
      errorEvent: PUBLISHER_CONTACTS_SALES_FAILED,
    }),
  }),
  [environment()]
);

registerEventHandler(PUBLISHER_CONTACTS_SALES_SUCCEED, () => ({
  ...state.set({
    'upsell-sales-contacted': true,
  }),
  ...toast.show({
    text: 'subscription_benefits_upsell_contacted_feedback',
  }),
}));

registerEventHandler(PUBLISHER_CONTACTS_SALES_FAILED, () => ({}));

registerEventHandler(
  SUBSCRIBE_BUTTON_CLICKED,
  ({ environment: { apiUrl } }, _) =>
    http.post({
      url: `${apiUrl}/subscription-change-requests/from-recommended-subscription`,
      successEvent: SCR_FROM_RECOMMENDED_PLAN_CREATED,
    }),
  [environment()]
);

registerEventHandler(SCR_FROM_RECOMMENDED_PLAN_CREATED, (_, [request]) =>
  navigateTo(
    checkoutBillingInformationRoute({
      subscriptionChangeRequestId: request.data,
      subRoute: UPSELL_SUBROUTE,
    })
  )
);
