import { useCallback, useEffect, useState } from 'react';
import { memoize } from 'lodash';
import {
  useFilter,
  useFilters,
} from '../../../partials/DataTable/hooks/useFilter';
import PropertiesSuggestions from './PropertiesSuggestions/PropertiesSuggestions';

const removeOwnFilters = memoize((filters) => {
  const {
    'ad-suggestion': _,
    'ad-suggestion-type': __,
    ...restFilters
  } = filters;

  return restFilters;
});

export function SuggestionsFilter() {
  const { filters: allFilters } = useFilters();
  const [query, setQuery] = useState(null);

  const { value: filterValue, setValue: setFilterValue } =
    useFilter('ad-suggestion');

  useEffect(() => {
    // sync autosuggestion query with current filter value
    setQuery(filterValue?.text ?? '');
  }, [filterValue]);

  const handleSuggestionSelected = useCallback(({ type, text }) => {
    setFilterValue({ type, text });
  }, []);

  const handleClearSelection = useCallback(() => {
    setFilterValue(null);
    setQuery(null);
  }, []);

  return (
    <PropertiesSuggestions
      filters={removeOwnFilters(allFilters)}
      query={query}
      onQueryChange={setQuery}
      onSuggestionSelected={handleSuggestionSelected}
      onClearSelection={handleClearSelection}
    />
  );
}
