import { withoutEventPropagation } from 'design-system/utils/withoutEventPropagation';
import { useCallback } from 'react';
import { dispatch } from 'reffects';
import Menu from 'design-system/components/Menu/Menu';
import { PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_OPENED } from '../Modals/ChangePaymentMethodModal/events';

export function ChangePaymentMethodAction({ publisher }) {
  const handleClick = useCallback(() => {
    dispatch({
      id: PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_OPENED,
      payload: {
        publisherId: publisher.id,
        currentPaymentMethod: publisher.paymentMethod,
        isMultiAccount: publisher.numberOfPublishersSharingSubscription > 1,
      },
    });
  }, [publisher]);

  return (
    <Menu.Item
      label="Change payment method"
      onClick={withoutEventPropagation(handleClick)}
    />
  );
}
