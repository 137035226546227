import { Link } from 'react-router-dom';
import { useSelector } from 'reffects-store';
import NavigationBar from 'design-system/components/NavigationBar/NavigationBar';
import { Heading2 } from 'design-system/components/Typography/presets/Heading2';
import { isMobileSelector, isUserLoggedInSelector } from '../../App/selectors';
import SuperuserBanner from './partials/SuperuserBanner';
import Banners from './Banners';
import AppDownloadBanner from '../AppDownloadBanner/AppDownloadBanner';
import { homeRoute } from '../../utils/proppitWebRouter';
import { NavigationLinks } from './partials/NavigationLinks';
import { BrandLogo } from '../BrandLogo';

function Navigation({ logo, title, hideLinks, hideAppBanner = false }) {
  const isLogged = useSelector(isUserLoggedInSelector);
  const isMobileDevice = useSelector(isMobileSelector);

  return (
    <>
      <AppDownloadBanner show={isMobileDevice && hideAppBanner === false} />
      <SuperuserBanner />
      <NavigationBar
        logo={<Link to={homeRoute()}>{logo || <BrandLogo />}</Link>}
      >
        {!hideLinks && isLogged && <NavigationLinks />}
        {title && <Heading2>{title}</Heading2>}
      </NavigationBar>
      {isLogged && <Banners />}
    </>
  );
}

export default Navigation;
