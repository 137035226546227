export function selectedPaymentMethodSelector(state) {
  return state['change-payment-method-modal']?.paymentMethod ?? null;
}

export function availablePaymentMethodsSelector(state) {
  return state['change-payment-method-modal']?.availablePaymentMethods ?? [];
}

export function isDisabledPaymentMethodSelectSelector(state) {
  return (
    availablePaymentMethodsSelector(state).length === 0 ||
    isMultipleAccountClientSharingSubscriptionSelector(state) === true
  );
}

export function isMultipleAccountClientSharingSubscriptionSelector(state) {
  return state['change-payment-method-modal']?.publisherIsMultiAccount ?? false;
}
