import { useFormContext } from 'react-hook-form';
import InputTextarea from 'design-system/components/InputTextarea';
import IconMultiLanguage from '../../../partials/IconMultiLanguage';

export default function MultiLangDescriptionField({
  locale,
  showFlagIcon,
  ...rest
}) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <InputTextarea
      error={Boolean(errors.descriptions?.[locale])}
      tip={errors.descriptions?.[locale]?.message}
      icon={showFlagIcon && <IconMultiLanguage locale={locale} />}
      {...register(`descriptions[${locale}]`)}
      {...rest}
    />
  );
}
