import styled from 'styled-components';
import Layout from 'design-system/components/Layout';
import SkeletonBone from 'design-system/components/SkeletonBone';
import { FlatButton } from 'design-system/components/Button/presets';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { Subtitle1 } from 'design-system/components/Typography/presets/Subtitle1';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import Subtitle from './Subtitle';
import Section from './Section';
import Description from './Description';
import PropertySpaces from './PropertySpaces';
import AdStatus from './AdStatus';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageSkeleton = styled.div`
  ${color.background(COLOR_PALETTE.NEUTRAL_A05)}
  ${radius.regular(RADIUS_SIZE.BASE)}
    ${spacing.stack(SPACING_SIZE.M)}
    width: 300px;
  height: 155px;
`;

const Header = styled(Layout.Padded)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 0;

  ${Subtitle3} {
    text-transform: uppercase;
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
  }

  ${FlatButton} {
    padding: 0;
  }
`;

const Title = styled(Subtitle1)`
  ${spacing.stack(SPACING_SIZE.S)}
`;

const TitleAddress = styled(Body1)`
  ${color.text(COLOR_PALETTE.NEUTRAL_A40)}
  ${spacing.stack(SPACING_SIZE.S)}
`;

const CreationData = styled(Body2)`
  ${spacing.stack(SPACING_SIZE.M)}
`;

export function Skeleton() {
  return (
    <Wrapper>
      <Header>
        <SkeletonBone $width="120px" $height="12px" />
      </Header>
      <Section>
        <ImageSkeleton />
        <Title>
          <SkeletonBone $width="209px" $height="22px" />
        </Title>
        <TitleAddress>
          <SkeletonBone $width="154px" $height="14px" />
        </TitleAddress>
        <CreationData>
          <SkeletonBone $width="124px" $height="14px" />
        </CreationData>
      </Section>
      <Section>
        <AdStatus.Skeleton />
      </Section>
      <PropertySpaces.Skeleton />
      <Section>
        <Subtitle>
          <SkeletonBone $width="209px" $height="22px" />
        </Subtitle>
        <Description.Skeleton />
      </Section>
      <Section>
        <Subtitle>
          <SkeletonBone $width="209px" $height="22px" />
        </Subtitle>
        <Description.Skeleton />
      </Section>
    </Wrapper>
  );
}
