/* eslint-disable no-use-before-define */

import styled, { css } from 'styled-components';

const AnimationFadeSlideOut = styled.div`
  transform: translateY(100%);
  opacity: 0;

  transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;

  ${({ $in }) => !$in && modOut()}
`;

const modOut = () => css`
  transform: none;
  opacity: 1;
`;

export default AnimationFadeSlideOut;
