import FormattedMessageWithValidation from '../FormattedMessageWithValidation/FormattedMessageWithValidation';
import FormattedMessageWithSuffixCountry from '../FormattedMessageWithSuffixCountry/FormattedMessageWithSuffixCountry';

function FormattedMessageWithTranslatedCountry({ id }) {
  return (
    <FormattedMessageWithValidation
      id={id}
      values={{ country: <FormattedMessageWithSuffixCountry id="country" /> }}
    />
  );
}

export default FormattedMessageWithTranslatedCountry;
