import {
  activeSubscriptionSelector,
  publisherCountrySelector,
  publisherSelector,
  subscriptionChangeRequestSelector,
  subscriptionToUpgradeSelector,
} from '../../../../selectors/publisher';
import { localeSelector } from '../../../../selectors/user';
import { showSitesToPublishSelector } from '../../../../App/selectors';
import { formattedListWithLastConjunction } from '../../../../utils/strings';
import { allSitesSelector } from '../../../../selectors/config';
import { THAILAND_PROPERTY_SITE } from '../../../../constants';
import { SUBSCRIPTION_TYPES } from '../../../../constants/subscription';
import { isArgentina } from '../../../../utils/countries';

export function activeSubscriptionHeaderSelector(state) {
  const activeSubscription = activeSubscriptionSelector(state);
  if (!activeSubscription) return null;
  const locale = localeSelector(state);
  return subscriptionHeader({ ...activeSubscription, isActive: true }, locale);
}

export function activeSubscriptionBenefitsSelector(state) {
  return benefitsFor(state, activeSubscriptionSelector(state));
}

export function subscriptionsHeadersSelector(state) {
  const activeSubscription = activeSubscriptionSelector(state);
  const subscriptionToUpgrade =
    scrSubscriptionOrElseSubscriptionToUpgrade(state);
  if (!activeSubscription || !subscriptionToUpgrade) return [];
  const locale = localeSelector(state);
  const headers = [
    subscriptionHeader({ ...activeSubscription, isActive: true }, locale),
  ];

  if (isArgentina(publisherCountrySelector(state))) {
    return headers;
  }

  return [
    ...headers,
    subscriptionHeader({ ...subscriptionToUpgrade, isActive: false }, locale),
  ];
}

function scrSubscriptionOrElseSubscriptionToUpgrade(state) {
  const pendingScr = subscriptionChangeRequestSelector(state);
  if (!pendingScr) {
    return subscriptionToUpgradeSelector(state);
  }

  const baseSubscriptionProduct = pendingScr.products.find(
    ({ type }) => type === SUBSCRIPTION_TYPES.BASE_SUBSCRIPTION
  );

  const { monthlyPriceWithDiscount } = baseSubscriptionProduct;
  return {
    id: baseSubscriptionProduct.id,
    maxBoostableProperties: baseSubscriptionProduct.boostableProperties,
    type: 'paid',
    upsellLevel: baseSubscriptionProduct.boostingLevel,
    nameTag: baseSubscriptionProduct.nameTag,
    billingCycleInMonths:
      baseSubscriptionProduct.billingDetail.billingCycleInMonths,
    discounts: {},
    taxApplied: {
      amount: baseSubscriptionProduct.taxApplied.amount,
      currency: baseSubscriptionProduct.taxApplied.currency,
      percent: baseSubscriptionProduct.taxApplied.percentage,
      name: baseSubscriptionProduct.taxApplied.name,
    },
    monthlyPriceWithDiscount,
    price: monthlyPriceWithDiscount,
  };
}

export function benefitsBySubscriptionSelector(state) {
  const benefits = [
    benefitsFor(
      state,
      activeSubscriptionSelector(state),
      publisherSelector(state)
    ),
  ];

  if (isArgentina(publisherCountrySelector(state))) {
    return benefits;
  }

  return [
    ...benefits,
    benefitsFor(state, scrSubscriptionOrElseSubscriptionToUpgrade(state)),
  ];
}

function subscriptionHeader(
  {
    id,
    nameTag,
    billingCycleInMonths,
    discounts,
    taxApplied,
    isActive,
    monthlyPriceWithDiscount,
  },
  locale
) {
  const { amount, currency } = monthlyPriceWithDiscount;
  return {
    id,
    nameTag,
    billingCycleInMonths,
    priceParts: new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
    }).formatToParts(amount),
    discount: Object.entries(discounts)
      .map(([_, { percentage }]) => percentage)
      .reduce((x, y) => x + y, 0),
    tax: taxApplied,
    isActive,
  };
}

function benefitsFor(state, subscription, publisher) {
  if (!subscription) return {};
  const { id, maxBoostableProperties, type, upsellLevel } = subscription;
  return {
    planId: id,
    highlight: type === 'paid',
    showPublicationSites: showSitesToPublishSelector(state),
    publishInAllSites: type !== 'free',
    sitePublication: !(publisher?.isThailandPropertyClient && type === 'free'),
    prioritizedProperties: type === 'free' ? 0 : maxBoostableProperties,
    hasVerifiedPhone: type === 'paid',
    websitePositionTag: getWebsitePositionTag(upsellLevel),
    hasLogoInWebsites: upsellLevel >= 1,
    hasDedicatedAccountManager: upsellLevel >= 2,
    hasEmailSupport: upsellLevel >= 1,
    hasQuarterlyReport: upsellLevel >= 3,
    hasLeadPlus: type === 'paid',
  };
}

function getWebsitePositionTag(upsellLevel) {
  if (upsellLevel >= 4) {
    return 'subscription_plans_position_websites_value_level_up_to_4';
  }
  return upsellLevel === 0
    ? 'subscription_plans_position_websites_value_free'
    : `subscription_plans_position_websites_value_level_${upsellLevel}`;
}

export function salesHasAlreadyBeenContactedSelector(state) {
  return state['upsell-sales-contacted'] ?? false;
}

export const formattedOfferedSitesToPublishSelector =
  (state) => (lastConjunctionTranslation) => {
    const { isThailandPropertyClient = false } = publisherSelector(state) ?? {};
    const sites = allSitesSelector(state)
      .map(({ site }) => site)
      .filter((s) => s !== THAILAND_PROPERTY_SITE || isThailandPropertyClient);

    return formattedListWithLastConjunction(sites, lastConjunctionTranslation);
  };
