import styled, { css } from 'styled-components';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import {
  color,
  COLOR_PALETTE,
  COLOR_SEMANTIC,
} from 'design-system/styles/color';
import { FONT_WEIGHT } from 'design-system/styles/typography';

const modError = () => css`
  ${Body2.mods.color(COLOR_SEMANTIC.STATUS_ERROR)}
  ${Body2.mods.weight(FONT_WEIGHT.NORMAL)}
`;

const modDisabled = () => css`
  ${Body2.mods.color(COLOR_PALETTE.NEUTRAL_A20)}
  ${Body2.mods.weight(FONT_WEIGHT.LIGHT)}
`;

const InputTip = styled(Body2)`
  display: block;
  ${color.text(COLOR_PALETTE.NEUTRAL_A60)}

  ${({ error }) => error && modError()}
  ${({ disabled }) => disabled && modDisabled()}
`;

export default InputTip;
