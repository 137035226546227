import styled from 'styled-components';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { animation } from 'design-system/styles/animation';
import { border } from 'design-system/styles/border';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import Illustration from 'design-system/components/Illustration/Illustration';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import DropZone from '../../../../../partials/DropZone';
import FileInput from '../../../../../partials/FileInput';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const WithoutImages = styled(DropZone)`
  ${spacing.inset(SPACING_SIZE.L)}
  ${border.outer({ size: 1, color: COLOR_PALETTE.SECONDARY_A80 })}
  border-style: dashed;
  ${radius.regular(RADIUS_SIZE.BASE)}
  cursor: pointer;
  &:hover {
    ${color.background(COLOR_PALETTE.SECONDARY_A05)}
    ${border.outer({ size: 1, color: COLOR_PALETTE.SECONDARY_BASE })}
    border-style: dashed;
  }
  ${Body1} {
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
    margin-bottom: ${SPACING_SIZE.XS}px;
  }
  ${FileInput} {
    width: 100%;
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    ${spacing.stack(SPACING_SIZE.M)}
  }
  ${Body2} {
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
  }
`;

const UploadedFile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${Icon} {
    ${spacing.inline(SPACING_SIZE.S)}
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
  }
  ${Body1} {
    flex-grow: 2;
  }
`;

const Spinner = styled(Illustration)`
  ${animation.spinClockwise()}
`;

const DeleteFileIcon = styled(Icon)`
  cursor: pointer;
`;

export default function FileUploader({
  id,
  value,
  isLoading,
  accept,
  onFileUploaded,
  onFileDeleted,
  inputMessage,
}) {
  if (value && !isLoading) {
    const glyph = value.name.toLowerCase().endsWith('.pdf') ? 'pdf' : 'image';
    return (
      <UploadedFile>
        <Icon glyph={glyph} $size={ICON_SIZE.BASE} />
        <Body1>{value.name}</Body1>
        <DeleteFileIcon
          glyph="cross"
          $size={ICON_SIZE.BASE}
          onClick={onFileDeleted}
        />
      </UploadedFile>
    );
  }
  return (
    <WithoutImages onDrop={([file]) => onFileUploaded(file)}>
      <FileInput
        id={id}
        onUpload={([file]) => onFileUploaded(file)}
        accept={accept}
      >
        <Block>
          {isLoading ? (
            <>
              <Spinner name="ellipse" />
              <Body2>
                <FormattedMessageWithValidation id="checkout_file_upload_processing" />
              </Body2>
            </>
          ) : (
            <>
              <Icon
                glyph="photoLibrary"
                $color={COLOR_PALETTE.NEUTRAL_A20}
                $size={ICON_SIZE.XLARGE}
              />
              <Body1>{inputMessage}</Body1>
            </>
          )}
        </Block>
      </FileInput>
    </WithoutImages>
  );
}
