import styled, { css } from 'styled-components';

import { increaseSpecificity } from 'design-system/styles/increaseSpecificity';
import InputTip from 'design-system/components/InputTip';
import InputLabel from 'design-system/components/InputLabel';
import Box from './Box';

const modDisabled = () => css`
  cursor: not-allowed;
  user-select: none;

  ${increaseSpecificity(Box)} {
    ${Box.mods.disabled()}
  }
`;

const modError = () => css`
  ${increaseSpecificity(Box)} {
    ${Box.mods.error()}
  }
`;

const Wrapper = styled.label`
  display: block;
  cursor: text;

  &:hover {
    ${Box} {
      ${Box.mods.hover()}
    }
  }

  :active,
  &[data-focus] {
    ${Box} {
      ${Box.mods.typing()}
    }
  }

  ${({ error }) => error && modError()}
  ${({ disabled }) => disabled && modDisabled()}
`;

function InputBase({ children, label, tip, focused, ...rest }) {
  const { disabled, error, required } = rest;

  return (
    <Wrapper data-focus={focused || undefined} {...rest}>
      {label && (
        <InputLabel disabled={disabled} error={error} required={required}>
          {label}
        </InputLabel>
      )}
      {children}
      <InputTip error={error} disabled={disabled} data-test="input-tip">
        {tip}
      </InputTip>
    </Wrapper>
  );
}

InputBase.Box = Box;

export default styled(InputBase)``;
