const MAX_VISIBLE_ADS = 2;

export function mapAgencyLeads(leads) {
  return leads.map((lead) => {
    const { ads, ...rest } = lead;

    if (hasLessThanMaxVisibleProperties(ads)) {
      return leadWithTotalEnquiryCount(lead, ads);
    }

    const [firstAd, ...restAds] = ads;
    return {
      totalEnquiriesCount: lead.ads.reduce(
        (acc, { enquiriesCount }) => acc + enquiriesCount,
        0
      ),
      ads: [firstAd],
      accumulatedAds: {
        enquiriesCount: restAds.reduce(
          (acc, { enquiriesCount }) => acc + enquiriesCount,
          0
        ),
        restAds,
        total: restAds.length,
      },
      ...rest,
    };
  });
}

export function mapDevelopersLeads(leads) {
  return leads.map((lead) => {
    const { units, projects, ...rest } = lead;
    const properties = [...units, ...projects];

    if (hasLessThanMaxVisibleProperties(properties)) {
      return leadWithTotalEnquiryCount(lead, properties);
    }

    const sortedUnitsAndProjects = properties.sort(
      (elem, anotherElem) =>
        new Date(anotherElem.lastActivity) - new Date(elem.lastActivity)
    );

    const [firstUnitOrProject] = sortedUnitsAndProjects;

    const newUnits = units.some((unit) => unit.id === firstUnitOrProject.id)
      ? [firstUnitOrProject]
      : [];
    const newProjects = projects.some(
      (project) => project.id === firstUnitOrProject.id
    )
      ? [firstUnitOrProject]
      : [];

    const accumulatedUnits = units.filter(
      (unit) => unit.id !== firstUnitOrProject.id
    );
    const accumulatedProjects = projects.filter(
      (project) => project.id !== firstUnitOrProject.id
    );

    return {
      totalEnquiriesCount:
        units.reduce((acc, { enquiriesCount }) => acc + enquiriesCount, 0) +
        projects.reduce((acc, { enquiriesCount }) => acc + enquiriesCount, 0),
      units: newUnits,
      projects: newProjects,
      accumulatedUnitsOrProjects: {
        enquiriesCount:
          accumulatedUnits.reduce(
            (acc, { enquiriesCount }) => acc + enquiriesCount,
            0
          ) +
          accumulatedProjects.reduce(
            (acc, { enquiriesCount }) => acc + enquiriesCount,
            0
          ),
        accumulatedUnits,
        accumulatedProjects,
        total: accumulatedUnits.length + accumulatedProjects.length,
      },
      ...rest,
    };
  });
}

function hasLessThanMaxVisibleProperties(properties) {
  return properties.length <= MAX_VISIBLE_ADS;
}

function leadWithTotalEnquiryCount(lead, properties) {
  return {
    ...lead,
    totalEnquiriesCount: properties.reduce(
      (acc, { enquiriesCount }) => acc + enquiriesCount,
      0
    ),
  };
}
