import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import InputText from 'design-system/components/InputText';
import Icon from 'design-system/components/Icon';
import { useFilter } from '../../../../../../partials/DataTable/hooks/useFilter';

const Wrapper = styled.form`
  min-width: 30%;
  ${spacing.inline(SPACING_SIZE.M)}
`;

export default function SearchFilter() {
  const { value, setValue } = useFilter('q');
  const ref = useRef();

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    setValue(ref.current.value);
  }, []);

  useEffect(() => {
    ref.current.value = value ?? '';
  }, [value]);

  return (
    <Wrapper onSubmit={handleSubmit}>
      <InputText
        ref={ref}
        icon={<Icon glyph="magnifierLens" />}
        onIconClick={handleSubmit}
        placeholder="Search by agency name or uuid"
        defaultValue={value}
      />
    </Wrapper>
  );
}
