import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import styled from 'styled-components';
import Table from 'design-system/components/Table';
import { useColumn } from '../../../../../partials/DataTable/hooks/useColumn';

const ProjectCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

const ProjectCell = styled.div`
  ${spacing.stack(SPACING_SIZE.XL)}
  min-width: 0;
`;

function EnquiryFieldsColumn({
  renderProjectField,
  renderUnitField,
  renderAccumulatedField,
  ...props
}) {
  const source = {
    units: 'units',
    projects: 'projects',
    accumulatedUnitsOrProjects: 'accumulatedUnitsOrProjects',
  };
  const {
    value: { units = [], projects = [], accumulatedUnitsOrProjects },
  } = useColumn({ ...props, source });

  return (
    <Table.Cell maxWidth={225}>
      <ProjectCellWrapper>
        {units.map((unit) => (
          <ProjectCell key={`${unit.id}_${Object.keys(source).join('_')}`}>
            {renderUnitField(unit)}
          </ProjectCell>
        ))}
        {projects.map((project) => (
          <ProjectCell key={`${project.id}_${Object.keys(source).join('_')}`}>
            {renderProjectField(project)}
          </ProjectCell>
        ))}
        {accumulatedUnitsOrProjects && (
          <ProjectCell>
            {renderAccumulatedField(accumulatedUnitsOrProjects)}
          </ProjectCell>
        )}
      </ProjectCellWrapper>
    </Table.Cell>
  );
}

export default EnquiryFieldsColumn;
