import { dispatch } from 'reffects';
import { useEffect } from 'react';
import { TRACK_PAGE_VIEW_MOUNTED } from './events';

export default function TrackPageView({ pageViewName, payload, children }) {
  useEffect(
    () =>
      dispatch({
        id: TRACK_PAGE_VIEW_MOUNTED,
        payload: { pageViewName, payload },
      }),
    [pageViewName, JSON.stringify(payload)]
  );

  return <>{children}</>;
}
