import styled from 'styled-components';
import Table from 'design-system/components/Table';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const FullWithCell = styled(Table.Cell).attrs({ colSpan: 12 })`
  ${spacing.inset(SPACING_SIZE.M)}
`;

function EmptyUnitRow() {
  return (
    <Table.Row>
      <FullWithCell>
        <Body1>
          <FormattedMessageWithValidation id="propspage_table_empty_results" />
        </Body1>
      </FullWithCell>
    </Table.Row>
  );
}

export default styled(EmptyUnitRow)``;
