export function billingEntitiesSelector(state) {
  return state['admin:billingEntities'] ?? [];
}

export function suggestedBillingEntitiesSelector(state) {
  return state['admin:suggestedBillingEntities'] ?? [];
}

export function displayableBillingEntitySelector(state) {
  return state['admin:displayableBillingEntityText'];
}
