import { Body1 } from 'design-system/components/Typography/presets/Body1';
import styled from 'styled-components';
import DropdownMenu from 'design-system/components/DropdownMenu';
import { DROPDOWN_POSITION } from 'design-system/components/Dropdown';
import Menu from 'design-system/components/Menu/Menu';
import Button, {
  BUTTON_SIZE,
  BUTTON_VARIANT,
} from 'design-system/components/Button';
import Icon from 'design-system/components/Icon';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import FilterDatepicker from '../../../../partials/FilterDatepicker';
import CustomFormattedDate from '../../../../partials/FormattedDate/CustomFormattedDate';
import { useFilter } from '../../../../partials/DataTable/hooks/useFilter';
import {
  firstDayOfLastMonth,
  firstDayOfMonth,
  lastMomentOfDay,
  lastMomentOfMonth,
  threeMonthsAgo,
  toUTCDate,
} from '../../../../utils/dates';
import { DEFAULT_DATE_OPTION } from './constants';
import { LEAD_FILTERS } from '../../constants';

const DropdownButton = styled(Button)`
  ${Button.mods.variant(BUTTON_VARIANT.OUTLINE)}
  ${Button.mods.size(BUTTON_SIZE.BASE)}
`;

const now = new Date();
const currentMonthRange = [
  toUTCDate(firstDayOfMonth(now)),
  toUTCDate(lastMomentOfDay(now)),
];
const lastMonthRange = [
  toUTCDate(firstDayOfLastMonth(now)),
  toUTCDate(lastMomentOfMonth(firstDayOfLastMonth(now))),
];
const lastThreeMonthsRange = [
  toUTCDate(threeMonthsAgo(now)),
  toUTCDate(lastMomentOfDay(now)),
];

const trackingEventByFilterOption = {
  [DEFAULT_DATE_OPTION]: () => ({ selectedOption: 'all' }),
  filter_last_month: () => ({ selectedOption: 'last-month' }),
  filter_current_month: () => ({ selectedOption: 'current-month' }),
  filter_last_3_month: () => ({ selectedOption: 'last-3-month' }),
  filter_custom_date_range: ({ startDate, endDate }) => ({
    selectedOption: 'custom-date',
    from: startDate.toISOString(),
    to: endDate.toISOString(),
  }),
};

function DateFilters({ onSelect }) {
  const { value, setValue } = useFilter(LEAD_FILTERS.LAST_ACTIVITY_DATE_RANGE);

  const handleConfirmation = ({ id, value: dateRange }) => {
    if (dateRange) {
      const { startDate, endDate } = dateRange;

      setValue({
        id,
        startDate,
        endDate,
      });
    } else {
      setValue(null);
    }

    onSelect?.(trackingEventByFilterOption[id](dateRange));
  };

  return (
    <DropdownMenu
      button={
        <DropdownButton icon={<Icon glyph="chevronDown" />}>
          {selectedOptionsText(value)}
        </DropdownButton>
      }
      active={value?.id && value.id !== DEFAULT_DATE_OPTION}
      onConfirm={handleConfirmation}
      position={DROPDOWN_POSITION.BOTTOM_LEFT}
    >
      <Menu>
        <Menu.Item
          id={DEFAULT_DATE_OPTION}
          label={
            <Body1 $noWrap>
              <FormattedMessageWithValidation id="enquiries_filter_all" />
            </Body1>
          }
          value={null}
        />
        <Menu.Item
          id="filter_current_month"
          label={
            <Body1 $noWrap>
              <FormattedMessageWithValidation id="enquiries_filter_this_month" />
            </Body1>
          }
          value={{
            startDate: currentMonthRange[0],
            endDate: currentMonthRange[1],
          }}
        />
        <Menu.Item
          id="filter_last_month"
          label={
            <Body1 $noWrap>
              <FormattedMessageWithValidation id="enquiries_filter_last_month" />
            </Body1>
          }
          value={{
            startDate: lastMonthRange[0],
            endDate: lastMonthRange[1],
          }}
        />
        <Menu.Item
          id="filter_last_3_month"
          label={
            <Body1 $noWrap>
              <FormattedMessageWithValidation id="enquiries_filter_last_three_months" />
            </Body1>
          }
          value={{
            startDate: lastThreeMonthsRange[0],
            endDate: lastThreeMonthsRange[1],
          }}
        />
        <Menu.Item
          id="filter_custom_date_range"
          label={
            <Body1 $noWrap>
              <FormattedMessageWithValidation id="enquiries_filter_custom_date" />
            </Body1>
          }
        >
          {({ confirmActionHandler, cancelActionHandler }) => (
            <FilterDatepicker
              onConfirm={confirmActionHandler}
              onCancel={cancelActionHandler}
              start={value?.startDate ? new Date(value.startDate) : null}
              end={value?.endDate ? new Date(value.endDate) : null}
              maxDate={new Date()}
              showLastYearWarning
            />
          )}
        </Menu.Item>
      </Menu>
    </DropdownMenu>
  );
}

export default DateFilters;

function selectedOptionsText(dateFilter) {
  const filterByAll = (
    <FormattedMessageWithValidation id="enquiries_filter_helper_box" />
  );

  return (
    {
      [DEFAULT_DATE_OPTION]: filterByAll,
      filter_current_month: (
        <FormattedMessageWithValidation id="enquiries_filter_this_month" />
      ),
      filter_last_month: (
        <FormattedMessageWithValidation id="enquiries_filter_last_month" />
      ),
      filter_last_3_month: (
        <FormattedMessageWithValidation id="enquiries_filter_last_three_months" />
      ),
      filter_custom_date_range: (
        <>
          <CustomFormattedDate value={dateFilter?.startDate} timeZone="utc" /> -{' '}
          <CustomFormattedDate value={dateFilter?.endDate} timeZone="utc" />
        </>
      ),
    }[dateFilter?.id] ?? filterByAll
  );
}
