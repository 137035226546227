import { Body1 } from 'design-system/components/Typography/presets/Body1';
import Table from 'design-system/components/Table';
import { useColumn } from '../../hooks/useColumn';
import FormattedDate from '../../../FormattedDate/FormattedDate';

export function DateColumn({ timeZone, ...props }) {
  const { value: date } = useColumn(props);

  return (
    <Table.Cell>
      <Body1 $noWrap>
        {date == null ? (
          '-'
        ) : (
          <FormattedDate
            value={new Date(date)}
            year="numeric"
            month="short"
            day="2-digit"
            timeZone={timeZone}
          />
        )}
      </Body1>
    </Table.Cell>
  );
}
