import * as React from "react";
const SvgDeal = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={124}
    height={124}
    fill="none"
    {...props}
  >
    <path
      fill="#CEF5E7"
      d="m115.974 88.289 3.423-2.041A5.33 5.33 0 0 0 122 81.666V45.408c0-1.594-1.736-2.58-3.105-1.764l-32.482 19.36a3.857 3.857 0 0 0-1.343 5.28l14.853 25.07a3.856 3.856 0 0 0 5.292 1.348l3.429-2.044M5.105 43.643l32.482 19.362a3.857 3.857 0 0 1 1.343 5.279l-14.852 25.07a3.857 3.857 0 0 1-5.293 1.348L4.603 86.248A5.33 5.33 0 0 1 2 81.666V45.409c0-1.594 1.736-2.58 3.105-1.765"
      style={{
        mixBlendMode: "multiply",
      }}
    />
    <path
      stroke="#0ACC86"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="m86.064 102.14-12.972-7.685M77.992 107.964l-9.558-5.663M68.21 112.774l-3.745-2.218M61.8 70.296l-.66-.39c-3.788-2.174-5.56-2.576-8.98-1.254l-3.725 1.44a7.93 7.93 0 0 1-6.903-.574l-2.305-1.359M24.953 92.007l3.861 2.28M57.377 116.963l6.182 3.662a4.562 4.562 0 0 0 4.65-7.851l5.132 3.04a4.562 4.562 0 1 0 4.651-7.85l3.42 2.026a4.56 4.56 0 0 0 6.251-1.599v-.001a4.56 4.56 0 0 0-1.6-6.25l4.186 2.48a4.562 4.562 0 0 0 4.65-7.85"
    />
    <path
      fill="#F3FCF9"
      d="m85.32 69.206-.538.312a7.93 7.93 0 0 1-6.903.574l-4.202-1.65a6.75 6.75 0 0 0-4.127-.262l-6.438 1.63a6.754 6.754 0 0 0-5.096 6.546v8.595c0 1.215.985 2.203 2.2 2.204a6.63 6.63 0 0 0 6.636-6.631v-.364l27.557 16.32a8 8 0 0 1 1.74-1.396L99.713 93"
    />
    <path
      stroke="#0ACC86"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="m85.32 69.206-.538.312a7.93 7.93 0 0 1-6.903.574l-4.202-1.65a6.75 6.75 0 0 0-4.127-.262l-6.438 1.63a6.754 6.754 0 0 0-5.096 6.546v8.595c0 1.215.985 2.203 2.2 2.204a6.63 6.63 0 0 0 6.636-6.631v-.364l27.557 16.32a8 8 0 0 1 1.74-1.396L99.713 93"
    />
    <path
      fill="#F3FCF9"
      d="M33.178 103.746a4.561 4.561 0 0 1-7.41-5.32l2.583-3.599a4.561 4.561 0 0 1 7.41 5.32"
    />
    <path
      stroke="#0ACC86"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M33.178 103.746a4.561 4.561 0 0 1-7.41-5.32l2.583-3.599a4.561 4.561 0 0 1 7.41 5.32"
    />
    <path
      fill="#F3FCF9"
      d="M51.018 110.179a4.561 4.561 0 0 1 7.41 5.32l-1.807 2.517a4.561 4.561 0 0 1-7.41-5.32"
    />
    <path
      stroke="#0ACC86"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M51.018 110.179a4.561 4.561 0 0 1 7.41 5.32l-1.807 2.517a4.561 4.561 0 0 1-7.41-5.32M115.974 88.289l3.423-2.041A5.33 5.33 0 0 0 122 81.666V45.408c0-1.594-1.736-2.58-3.105-1.764l-32.482 19.36a3.857 3.857 0 0 0-1.343 5.28l14.853 25.07a3.856 3.856 0 0 0 5.292 1.348l3.429-2.044M5.105 43.643l32.482 19.362a3.857 3.857 0 0 1 1.343 5.279l-14.852 25.07a3.857 3.857 0 0 1-5.293 1.348L4.603 86.248A5.33 5.33 0 0 1 2 81.666V45.409c0-1.594 1.736-2.58 3.105-1.765"
    />
    <path
      fill="#F3FCF9"
      d="M46.023 101.495a4.56 4.56 0 1 1 7.41 5.32l-5.435 7.57a4.561 4.561 0 1 1-7.41-5.32"
    />
    <path
      stroke="#0ACC86"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M46.023 101.495a4.56 4.56 0 1 1 7.41 5.32l-5.435 7.57a4.561 4.561 0 1 1-7.41-5.32"
    />
    <path
      fill="#F3FCF9"
      stroke="#0ACC86"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="m46.023 101.495-5.435 7.57a4.56 4.56 0 1 1-7.41-5.319l5.435-7.57a4.56 4.56 0 1 1 7.41 5.319"
    />
    <path fill="#0ACC86" d="M38 50h48v1H38z" />
    <path
      fill="#fff"
      stroke="#0ACC86"
      d="M54.5 18a3.5 3.5 0 0 1 3.5-3.5h3a3.5 3.5 0 0 1 3.5 3.5v31.5h-10z"
    />
    <path
      fill="#fff"
      stroke="#0ACC86"
      d="M52.5 20a1.5 1.5 0 0 1 1.5-1.5h11a1.5 1.5 0 0 1 1.5 1.5v30.5h-14z"
    />
    <path fill="#0ACC86" d="M52 22a4 4 0 0 1 4-4h1v33h-5z" opacity={0.3} />
    <rect width={14} height={1} x={52} y={21} fill="#0ACC86" rx={0.5} />
    <rect width={14} height={1} x={52} y={24} fill="#0ACC86" rx={0.5} />
    <rect width={14} height={1} x={52} y={27} fill="#0ACC86" rx={0.5} />
    <rect width={14} height={1} x={52} y={30} fill="#0ACC86" rx={0.5} />
    <rect width={14} height={1} x={52} y={33} fill="#0ACC86" rx={0.5} />
    <rect width={14} height={1} x={52} y={36} fill="#0ACC86" rx={0.5} />
    <rect width={14} height={1} x={52} y={39} fill="#0ACC86" rx={0.5} />
    <rect width={14} height={1} x={52} y={42} fill="#0ACC86" rx={0.5} />
    <path
      fill="#fff"
      stroke="#0ACC86"
      d="M43.5 28a1.5 1.5 0 0 1 1.5-1.5h8a1.5 1.5 0 0 1 1.5 1.5v22.5h-11z"
    />
    <rect width={6} height={1} x={43} y={29} fill="#0ACC86" rx={0.5} />
    <rect width={6} height={1} x={43} y={32} fill="#0ACC86" rx={0.5} />
    <rect width={6} height={1} x={43} y={35} fill="#0ACC86" rx={0.5} />
    <rect width={6} height={1} x={43} y={38} fill="#0ACC86" rx={0.5} />
    <rect width={6} height={1} x={43} y={41} fill="#0ACC86" rx={0.5} />
    <rect width={6} height={1} x={43} y={44} fill="#0ACC86" rx={0.5} />
    <rect width={6} height={1} x={43} y={47} fill="#0ACC86" rx={0.5} />
    <path
      fill="#0ACC86"
      fillRule="evenodd"
      d="M72 11a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 1 0v9a.5.5 0 0 1-.5.5"
      clipRule="evenodd"
    />
    <rect
      width={3}
      height={9}
      x={70.5}
      y={5.5}
      fill="#fff"
      stroke="#0ACC86"
      rx={1.5}
    />
    <rect
      width={9}
      height={40}
      x={67.5}
      y={8.5}
      fill="#fff"
      stroke="#0ACC86"
      rx={2.5}
    />
    <path
      fill="#E7FAF3"
      stroke="#0ACC86"
      d="M63.5 15a3.5 3.5 0 0 1 3.5-3.5h10a3.5 3.5 0 0 1 3.5 3.5v35.5h-17z"
    />
    <path
      fill="#0ACC86"
      fillRule="evenodd"
      d="M67.5 48a.5.5 0 0 1-.5-.5v-32a.5.5 0 0 1 1 0v32a.5.5 0 0 1-.5.5M70.5 48a.5.5 0 0 1-.5-.5v-32a.5.5 0 0 1 1 0v32a.5.5 0 0 1-.5.5M73.5 48a.5.5 0 0 1-.5-.5v-32a.5.5 0 0 1 1 0v32a.5.5 0 0 1-.5.5M76.5 48a.5.5 0 0 1-.5-.5v-32a.5.5 0 0 1 1 0v32a.5.5 0 0 1-.5.5"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDeal;
