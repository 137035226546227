import { effects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { randomNumber } from '../../../../../coeffects/randomNumber';

export const PUBLICATION_STEPPER_ANIMATION_TRIGGERED =
  'PUBLICATION_STEPPER_ANIMATION_TRIGGERED';
export const PUBLICATION_STEPPER_ITEM_ANIMATION_TRIGGERED =
  'PUBLICATION_STEPPER_ITEM_ANIMATION_TRIGGERED';

const randomAnimationTimeCoeffect = randomNumber.intBetween(100, 1000);

registerEventHandler(
  PUBLICATION_STEPPER_ANIMATION_TRIGGERED,
  ({ randomInt }, { sites }) => {
    if (!sites?.length) {
      return undefined;
    }

    return {
      ...state.merge({
        sitePublicationStepper: {
          sites: sites.map((site, idx) => ({
            ...site,
            status: idx === 0 ? 'current' : 'pending',
          })),
        },
      }),
      ...effects.dispatchLater({
        id: PUBLICATION_STEPPER_ITEM_ANIMATION_TRIGGERED,
        milliseconds: randomInt,
      }),
    };
  },
  [randomAnimationTimeCoeffect]
);

registerEventHandler(
  PUBLICATION_STEPPER_ITEM_ANIMATION_TRIGGERED,
  ({ state: { sites }, randomInt }) => {
    let currentHasBeenSet = false;
    let allDone = false;

    const updatedSites = sites.map(({ status, banned, ...site }, idx) => {
      let newStatus = status;
      if (status === 'current') {
        newStatus = 'done';
      } else if (!currentHasBeenSet && status === 'pending' && !banned) {
        newStatus = 'current';
        currentHasBeenSet = true;
      } else {
        const isLastSite = idx === sites.length - 1;
        const nextSiteIsBanned = sites[idx + 1]?.banned === true;
        if (status === 'done' && (isLastSite || nextSiteIsBanned)) {
          allDone = true;
        }
      }

      if (allDone && banned) {
        newStatus = 'failed';
      }

      return {
        ...site,
        banned,
        status: newStatus,
      };
    });

    const updateSitesEffect = state.merge({
      sitePublicationStepper: {
        sites: updatedSites,
      },
    });

    if (allDone) {
      return updateSitesEffect;
    }

    return {
      ...updateSitesEffect,
      ...effects.dispatchLater({
        id: PUBLICATION_STEPPER_ITEM_ANIMATION_TRIGGERED,
        milliseconds: randomInt,
      }),
    };
  },
  [
    state.get({ sites: 'sitePublicationStepper.sites' }),
    randomAnimationTimeCoeffect,
  ]
);
