import {
  PrimaryButton,
  SecondaryButton,
} from 'design-system/components/Button/presets';
import Dialog from 'design-system/components/Dialog/Dialog';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import FormattedMessageWithValidation from '../../../FormattedMessageWithValidation/FormattedMessageWithValidation';
import { NOT_PUBLISHED_ERROR_MODAL } from '../events';
import { useDialog } from '../../../../hooks/useDialog';
import { editPropertyForm } from '../../../../utils/proppitWebRouter';
import { NAVIGATE_TO } from '../../../../events/routing';
import { getLastErrorAdId } from '../selectors';

const DialogFooter = styled(Dialog.Footer)`
  display: flex;
  flex-direction: row-reverse;
  gap: ${spacing.value(SPACING_SIZE.M)};
`;

export function NotPublishedErrorModal() {
  const { open, closeDialog } = useDialog(NOT_PUBLISHED_ERROR_MODAL);
  const adId = useSelector(getLastErrorAdId);
  return (
    <Dialog open={open}>
      <Dialog.Header>
        <FormattedMessageWithValidation id="not_published_error_modal_title" />
      </Dialog.Header>
      <Dialog.Content>
        <Dialog.Text>
          <FormattedMessageWithValidation id="not_published_error_modal_text" />
        </Dialog.Text>
      </Dialog.Content>
      <DialogFooter>
        <SecondaryButton onClick={closeDialog}>
          <FormattedMessageWithValidation id="generic_cancel" />
        </SecondaryButton>
        <PrimaryButton
          onClick={() => {
            dispatch({
              id: NAVIGATE_TO,
              payload: { path: editPropertyForm(adId) },
            });
          }}
        >
          <FormattedMessageWithValidation id="savedprop_edit_cta" />
        </PrimaryButton>
      </DialogFooter>
    </Dialog>
  );
}
