import styled from 'styled-components';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import InboundBubble from './InboundBubble';
import OutboundBubble from './OutboundBubble';
import RenderHtml from '../../../../../partials/RenderHtml';
import PropertySnippet from './PropertySnippet';
import ProjectSnippet from './ProjectSnippet';

const DIRECTION_TO_BUBBLE_COMPONENT = {
  inbound: InboundBubble,
  outbound: OutboundBubble,
};

const ERROR_MESSAGES = {
  bounced: (
    <FormattedMessageWithValidation id="lead_detail_enquiry_email_bounced" />
  ),
  unsubscribed: (
    <FormattedMessageWithValidation id="lead_detail_enquiry_email_unsubscribed" />
  ),
};

const Message = styled(Body1)`
  ${spacing.stack(SPACING_SIZE.XS, false)}
  ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
  ${typography.weight(FONT_WEIGHT.LIGHT)}
`;

function EmailLeadBubble({ message, direction, error, ad, project, ...rest }) {
  const BubbleComponent = DIRECTION_TO_BUBBLE_COMPONENT[direction];

  return (
    <BubbleComponent
      title={
        <FormattedMessageWithValidation id="enquiry_detail_activity_message" />
      }
      error={error && ERROR_MESSAGES[error]}
      {...rest}
    >
      <Message>
        {message ? (
          <RenderHtml>{message}</RenderHtml>
        ) : (
          <FormattedMessageWithValidation id="enquiry_detail_activity_lead_type_email" />
        )}
      </Message>
      {ad ? (
        <PropertySnippet property={ad} project={project} />
      ) : (
        <ProjectSnippet project={project} />
      )}
    </BubbleComponent>
  );
}

export default EmailLeadBubble;
