import styled from 'styled-components';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';

const DisclaimerText = styled(Body2)`
  display: block;
  ${spacing.stack(SPACING_SIZE.M)};
`;

export default DisclaimerText;
