import { effects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { location } from '../../../../coeffects/location';
import {
  PENDING_SUBSCRIPTION_CHANGE_REQUEST_REQUESTED,
  PUBLISHER_PAYMENT_METHODS_REQUESTED,
  PUBLISHER_REQUESTED,
} from '../../../../events';
import { INVOICES_TAB, SUBSCRIPTION_TAB } from '../BillingSwitch/events';
import { SUBSCRIPTION_BILLING } from '../../../../constants/routes';

export const SUBSCRIPTION_PAGE_MOUNTED = 'SUBSCRIPTION_PAGE_MOUNTED';
export const SUBSCRIPTION_PAGE_UNMOUNTED = 'SUBSCRIPTION_PAGE_UNMOUNTED';
export const SUBSCRIPTION_TABS_MOUNTED = 'SUBSCRIPTION_TABS_MOUNTED';

registerEventHandler(SUBSCRIPTION_PAGE_MOUNTED, () =>
  effects.dispatchMany([
    PUBLISHER_REQUESTED,
    PUBLISHER_PAYMENT_METHODS_REQUESTED,
    SUBSCRIPTION_TABS_MOUNTED,
    PENDING_SUBSCRIPTION_CHANGE_REQUEST_REQUESTED,
  ])
);

registerEventHandler(SUBSCRIPTION_PAGE_UNMOUNTED, () =>
  state.set({ 'publisher.loading': undefined })
);

registerEventHandler(
  SUBSCRIPTION_TABS_MOUNTED,
  ({ location: { path } }) =>
    state.set({
      'subscriptionPage:showTab':
        path === SUBSCRIPTION_BILLING ? INVOICES_TAB : SUBSCRIPTION_TAB,
    }),
  [location()]
);
