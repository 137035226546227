import { registerEffectHandler } from 'reffects';

export default function registerAuthTokenEffects(
  cookieClient,
  authTokenCookieName,
  globalVariable = window
) {
  registerEffectHandler('authToken.remove', function remove() {
    cookieClient.remove(authTokenCookieName, {
      path: '/',
      domain: globalVariable.location.host,
    });
  });
}

export function authTokenRemove() {
  return {
    'authToken.remove': {},
  };
}
