import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import queryString from 'query-string';
import { environment } from '../../../../../coeffects/environment';

export const LEADS_PROPERTY_SEARCHED = 'LEADS_PROPERTY_SEARCHED';
export const LEADS_PROPERTIES_RETRIEVED = 'LEADS_PROPERTIES_RETRIEVED';

const MIN_CHARACTERS_TO_SEARCH = 3;

registerEventHandler(
  LEADS_PROPERTY_SEARCHED,
  ({ environment: { apiUrl } }, { searchText = '' }) => {
    if (searchText.length < MIN_CHARACTERS_TO_SEARCH) return undefined;

    const query = queryString.stringify(
      {
        q: searchText,
      },
      { skipNull: true }
    );
    return http.get({
      url: `${apiUrl}/leads/properties${query && `?${query}`}`,
      successEvent: { id: LEADS_PROPERTIES_RETRIEVED },
    });
  },
  [environment()]
);

registerEventHandler(
  LEADS_PROPERTIES_RETRIEVED,
  (_, [{ data }]) =>
    state.set({
      leadsProperties: data,
    }),
  []
);
