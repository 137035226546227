import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { navigateTo } from '../../../effects/routing';
import { checkoutPaymentMethodRoute } from '../../../utils/proppitWebRouter';

export const CHECKOUT_CONFIMRATION_GO_BACK = `CHECKOUT_CONFIMRATION_GO_BACK`;

registerEventHandler(
  CHECKOUT_CONFIMRATION_GO_BACK,
  ({ state: { subscriptionChangeRequestId, subRoute } }) =>
    navigateTo(
      checkoutPaymentMethodRoute({
        subscriptionChangeRequestId,
        subRoute,
      }),
      true
    ),
  [
    state.get({
      subscriptionChangeRequestId: 'checkoutPage.subscriptionChangeRequestId',
      subRoute: 'checkoutConfig.subRoute',
    }),
  ]
);
