import { useFormContext } from 'react-hook-form';
import InputText from 'design-system/components/InputText';
import { useRegisterFieldErrors } from '../../pages/publication/partials/FieldGroup';

function TextField({ name, tip, ...rest }) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  useRegisterFieldErrors({ name, errors: errors[name] });

  return (
    <InputText
      error={Boolean(errors[name])}
      tip={errors[name]?.message ?? tip}
      {...register(name)}
      {...rest}
    />
  );
}

export default TextField;
