import styled from 'styled-components';
import Checkbox from 'design-system/components/Checkbox/Checkbox';
import { Body1 } from 'design-system/components/Typography/presets/Body1';

const CheckboxLabel = styled(Checkbox.Label)`
  display: inline-flex;
`;

export function PublishesInThailandPropertyCheckbox({
  onChange,
  disabled,
  checked,
}) {
  return (
    <CheckboxLabel>
      <Checkbox
        name="publishInThailandProperty"
        onChange={onChange}
        disabled={disabled}
        checked={checked}
      />
      <Body1>Thailand Property Client</Body1>
    </CheckboxLabel>
  );
}
