import { Body1 } from 'design-system/components/Typography/presets/Body1';
import Table from 'design-system/components/Table';
import { useColumn } from '../../../../partials/DataTable/hooks/useColumn';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

export function BillingCycleColumn(props) {
  const { value: billingCycleInMonths } = useColumn(props);

  return (
    <Table.Cell>
      <Body1>
        <FormattedMessageWithValidation
          id="subscription_summary_contract_type_value"
          values={{
            duration: billingCycleInMonths,
          }}
        />
      </Body1>
    </Table.Cell>
  );
}
