import Menu from 'design-system/components/Menu/Menu';
import { dispatch } from 'reffects';
import MoveToFreeModal from '../../Modals/MoveToFreeModal/MoveToFreeModal';
import { OPEN_DIALOG } from '../../../../../../../events/dialogs';
import { MOVE_TO_FREE_MODAL } from '../../Modals/MoveToFreeModal/constants';

export function MoveToFreeAction({ publisher }) {
  return (
    <>
      <Menu.Item
        label="Move to free"
        onClick={() => {
          dispatch({
            id: OPEN_DIALOG,
            payload: {
              id: MOVE_TO_FREE_MODAL,
              parameters: { publisherId: publisher.id },
            },
          });
        }}
      />
      <MoveToFreeModal />
    </>
  );
}
