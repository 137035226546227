import Table from './Table';
import { Skeleton } from './Skeleton';
import Row from './Row';
import Pagination from './Pagination';
import Cell from './Cell';
import { KpiCell } from './presets';
import CellLink from './CellLink';

Table.Skeleton = Skeleton;
Table.Row = Row;
Table.Pagination = Pagination;
Table.Cell = Cell;
Table.KpiCell = KpiCell;
Table.CellLink = CellLink;

export * from './Table';
export {
  COLUMN_SORTING,
  ROWS_PER_PAGE,
  TABLE_VARIANT,
  TABLE_ROW_SEPARATION,
} from './constants';

export default Table;
