import * as React from "react";
const SvgBuildings = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={212}
    height={207}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#0ACC86"
      strokeWidth={2}
      d="M62 70a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v128H62z"
    />
    <path
      fill="#fff"
      stroke="#0ACC86"
      strokeWidth={2}
      d="M52 78a3 3 0 0 1 3-3h30a3 3 0 0 1 3 3v128H52z"
    />
    <path
      fill="#0ACC86"
      d="M51 78a4 4 0 0 1 4-4h4v133h-8zM73 110a4 4 0 0 1 4-4h4v101h-8z"
      opacity={0.3}
    />
    <g fill="#0ACC86">
      <rect width={37} height={2} x={52} y={90.001} rx={1} />
      <rect width={37} height={2} x={52} y={95.001} rx={1} />
      <rect width={37} height={2} x={52} y={100} rx={1} />
      <rect width={37} height={2} x={52} y={105} rx={1} />
      <rect width={37} height={2} x={52} y={110} rx={1} />
      <rect width={37} height={2} x={52} y={115} rx={1} />
      <rect width={37} height={2} x={52} y={120} rx={1} />
      <rect width={37} height={2} x={52} y={125} rx={1} />
      <rect width={37} height={2} x={52} y={130} rx={1} />
      <rect width={37} height={2} x={52} y={135} rx={1} />
      <rect width={37} height={2} x={52} y={140} rx={1} />
      <rect width={37} height={2} x={52} y={145} rx={1} />
      <rect width={37} height={2} x={52} y={150} rx={1} />
      <rect width={37} height={2} x={52} y={155} rx={1} />
      <rect width={37} height={2} x={52} y={160} rx={1} />
      <rect width={37} height={2} x={52} y={165} rx={1} />
      <rect width={37} height={2} x={52} y={170} rx={1} />
      <rect width={37} height={2} x={52} y={175} rx={1} />
      <rect width={37} height={2} x={52} y={180} rx={1} />
      <rect width={37} height={2} x={52} y={185} rx={1} />
      <rect width={37} height={2} x={52} y={190} rx={1} />
      <rect width={37} height={2} x={52} y={195} rx={1} />
    </g>
    <path
      fill="#fff"
      stroke="#0ACC86"
      strokeWidth={2}
      d="M42 124a3 3 0 0 1 3-3h11a3 3 0 0 1 3 3v82H42z"
    />
    <g fill="#0ACC86">
      <rect width={11} height={2} x={42} y={126} rx={1} />
      <rect width={11} height={2} x={42} y={131} rx={1} />
      <rect width={11} height={2} x={42} y={136} rx={1} />
      <rect width={11} height={2} x={42} y={141} rx={1} />
      <rect width={11} height={2} x={42} y={146} rx={1} />
      <rect width={11} height={2} x={42} y={151} rx={1} />
      <rect width={11} height={2} x={42} y={156} rx={1} />
      <rect width={11} height={2} x={42} y={161} rx={1} />
    </g>
    <path
      fill="#0ACC86"
      fillRule="evenodd"
      d="M147.5 129a1 1 0 0 1-1-1V1a1 1 0 0 1 2 0v127a1 1 0 0 1-1 1"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      stroke="#0ACC86"
      strokeWidth={2}
      d="M138 30.084a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v109.833h-18z"
    />
    <path
      fill="#fff"
      stroke="#0ACC86"
      strokeWidth={2}
      d="M130 46a3 3 0 0 1 3-3h28a3 3 0 0 1 3 3v154h-34z"
    />
    <path
      fill="#0ACC86"
      d="M129 46a4 4 0 0 1 4-4h28a4 4 0 0 1 4 4v155h-36z"
      opacity={0.2}
    />
    <path
      fill="#fff"
      stroke="#0ACC86"
      strokeWidth={2}
      d="M170 52a3 3 0 0 0-3-3h-40a3 3 0 0 0-3 3v154h46z"
    />
    <path fill="#0ACC86" d="M171 52a4 4 0 0 0-4-4h-3v159h7z" opacity={0.3} />
    <g fill="#0ACC86" fillRule="evenodd" clipRule="evenodd">
      <path d="M130 192a1 1 0 0 1-1-1V64a1 1 0 0 1 2 0v127a1 1 0 0 1-1 1M135 192a1 1 0 0 1-1-1V64a1 1 0 0 1 2 0v127a1 1 0 0 1-1 1M140 192a1 1 0 0 1-1-1V64a1 1 0 0 1 2 0v127a1 1 0 0 1-1 1M145 192a1 1 0 0 1-1-1V64a1 1 0 0 1 2 0v127a1 1 0 0 1-1 1M150 192a1 1 0 0 1-1-1V64a1 1 0 0 1 2 0v127a1 1 0 0 1-1 1M155 192a1 1 0 0 1-1-1V64a1 1 0 0 1 2 0v127a1 1 0 0 1-1 1M160 192a1 1 0 0 1-1-1V64a1 1 0 0 1 2 0v127a1 1 0 0 1-1 1M165 192a1 1 0 0 1-1-1V64a1 1 0 0 1 2 0v127a1 1 0 0 1-1 1" />
    </g>
    <path
      fill="#fff"
      stroke="#0ACC86"
      strokeWidth={2}
      d="M78 104a3 3 0 0 1 3-3h51a3 3 0 0 1 3 3v102H78z"
    />
    <g fill="#0ACC86">
      <rect width={39} height={2} x={87} y={108} rx={1} />
      <rect width={39} height={2} x={87} y={113} rx={1} />
      <rect width={39} height={2} x={87} y={118} rx={1} />
      <rect width={39} height={2} x={87} y={123} rx={1} />
      <rect width={39} height={2} x={87} y={128} rx={1} />
      <rect width={39} height={2} x={87} y={133} rx={1} />
      <rect width={39} height={2} x={87} y={138} rx={1} />
      <rect width={39} height={2} x={87} y={143} rx={1} />
      <rect width={39} height={2} x={87} y={148} rx={1} />
      <rect width={39} height={2} x={87} y={153} rx={1} />
      <rect width={39} height={2} x={87} y={158} rx={1} />
      <rect width={39} height={2} x={87} y={163} rx={1} />
      <rect width={39} height={2} x={87} y={168} rx={1} />
      <rect width={39} height={2} x={87} y={173} rx={1} />
      <rect width={39} height={2} x={87} y={178} rx={1} />
      <rect width={39} height={2} x={87} y={183} rx={1} />
    </g>
    <path fill="#0ACC86" d="M0 205h212v2H0z" />
  </svg>
);
export default SvgBuildings;
