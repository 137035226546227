import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { PUBLISHER_PAYMENT_METHODS_REQUESTED } from '../../../../events';
import { environment } from '../../../../coeffects/environment';
import { analytics } from '../../../../effects/analytics';
import { navigateTo } from '../../../../effects/routing';
import { SUBSCRIPTION_ADD_CARD } from '../../../../constants/routes';

export const DELETE_CREDIT_CARD = 'DELETE_CREDIT_CARD';
export const ADD_CREDIT_CARD = 'ADD_CREDIT_CARD';

registerEventHandler(
  DELETE_CREDIT_CARD,
  ({ environment: { apiUrl } }) => ({
    ...analytics.trackClick('confirm-delete-credit-card', 'my-subscription'),
    ...http.delete({
      url: `${apiUrl}/publishers/me/saved-card`,
      successEvent: PUBLISHER_PAYMENT_METHODS_REQUESTED,
    }),
  }),
  [environment()]
);

registerEventHandler(ADD_CREDIT_CARD, () => ({
  ...navigateTo(SUBSCRIPTION_ADD_CARD),
  ...analytics.trackClick('change-credit-card', 'my-subscription'),
}));
