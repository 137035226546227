import { dispatchMany } from 'reffects';
import { useCallback } from 'react';
import Dialog from 'design-system/components/Dialog/Dialog';
import {
  FlatButton,
  SecondaryButton,
} from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import { useDialog } from '../../../../../../../hooks/useDialog';
import { CONFIRM_SUBSCRIPTION_CHANGES_MODAL } from './constants';
import { CHANGE_SUBSCRIPTION_ACTION_REQUESTED } from './events';
import { PLANS_PER_COUNTRY_REQUESTED } from '../../../../../events';

export function ConfirmSubscriptionChangesModal() {
  const { open, parameters, closeDialog } = useDialog(
    CONFIRM_SUBSCRIPTION_CHANGES_MODAL
  );
  const publisher = parameters?.publisher;
  const showModal = useCallback(() => {
    dispatchMany([
      {
        id: PLANS_PER_COUNTRY_REQUESTED,
        payload: {
          countryCode: publisher.country,
          publishInThailandProperty: publisher.publishesInThailandProperty,
        },
      },
      {
        id: CHANGE_SUBSCRIPTION_ACTION_REQUESTED,
        payload: {
          publisherId: publisher.id,
        },
      },
    ]);
  }, [publisher]);
  return (
    <Dialog open={open}>
      <Dialog.Header onClose={closeDialog}>
        Are you sure you want to edit the current subscription?
      </Dialog.Header>
      <Dialog.Content>
        This client has a subscription pending to be activated that you can’t
        modify until the Start date. Do you want to continue to edit the current
        subscription?
      </Dialog.Content>
      <Dialog.Footer>
        <FlatButton onClick={closeDialog} $size={BUTTON_SIZE.SMALL}>
          Cancel
        </FlatButton>
        <SecondaryButton onClick={showModal} $size={BUTTON_SIZE.SMALL}>
          Edit current subscription
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}
