import styled from 'styled-components';
import { color, COLOR_SEMANTIC } from 'design-system/styles/color';
import { elevation, ELEVATION_INDEX } from 'design-system/styles/elevation';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';

const Popover = styled.div`
  display: inline-block;
  overflow: hidden;
  z-index: 3;
  max-width: inherit;

  ${color.background(COLOR_SEMANTIC.PAPER)}
  ${elevation.regular(ELEVATION_INDEX.BASE)}
  ${radius.regular(RADIUS_SIZE.LARGE)}
`;

export default Popover;
