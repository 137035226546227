import styled from 'styled-components';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import { FONT_WEIGHT } from 'design-system/styles/typography';

const Wrapper = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;

  flex-shrink: 0;

  ${Icon} {
    ${spacing.inline(SPACING_SIZE.S)}
    ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
  }

  ${Body2} {
    ${Body2.mods.color(COLOR_PALETTE.NEUTRAL_BASE)}
  }

  :hover > ${Icon} {
    ${color.text(COLOR_PALETTE.PRIMARY_A140)}
  }

  :hover > ${Body2} {
    ${Body2.mods.color(COLOR_PALETTE.PRIMARY_A140)}
  }
`;

function BackLink({ children, ...rest }) {
  return (
    <Wrapper {...rest}>
      <Icon glyph="chevronLeft" $size={ICON_SIZE.XSMALL} />
      <Body2 $weight={FONT_WEIGHT.MEDIUM}>{children}</Body2>
    </Wrapper>
  );
}

export default styled(BackLink)``;
