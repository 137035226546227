export const BANK_TRANSFER = 'bank-transfer';
export const CASH = 'cash';
export const CREDIT_CARD = 'credit-card';
export const DIRECT_DEBIT = 'direct-debit';
export const IN_APP = 'in-app';

export const PAYMENT_METHODS = [
  BANK_TRANSFER,
  CASH,
  CREDIT_CARD,
  DIRECT_DEBIT,
  IN_APP,
];
