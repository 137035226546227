import ConfirmationDialog from './ConfirmationDialog';
import AlertDialog from './AlertDialog';

function GenericModals() {
  return (
    <>
      <AlertDialog />
      <ConfirmationDialog />
    </>
  );
}

export default GenericModals;
