import styled from 'styled-components';
import { COLOR_PALETTE } from '../../../styles/color';
import Typography, { TYPOGRAPHY_VARIANT } from '../Typography';

import BasePresetTypography from './BasePresetTypography';

export const Overline = styled(BasePresetTypography)`
  ${Typography.mods.variant(TYPOGRAPHY_VARIANT.OVERLINE)}
`;
Overline.defaultProps = {
  $color: COLOR_PALETTE.NEUTRAL_BASE,
};
