import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import styled from 'styled-components';
import { dispatch } from 'reffects';
import { useMount } from 'react-use';
import { useParams } from 'react-router-dom';
import { useSelector } from 'reffects-store';
import InputText from 'design-system/components/InputText';
import InputTextarea from 'design-system/components/InputTextarea';
import Button from 'design-system/components/Button';
import Layout from '../partials/Layout/Layout';
import Section from '../CreatePublisher/Section';
import {
  BACKOFFICE_AD_ADDRESS_FORM_ADDRESS_CHANGED,
  BACKOFFICE_AD_ADDRESS_FORM_LATITUDE_CHANGED,
  BACKOFFICE_AD_ADDRESS_FORM_LONGITUDE_CHANGED,
  BACKOFFICE_AD_ADDRESS_FORM_SUBMITTED,
  BACKOFFICE_AD_ADDRESS_GEOLEVELS_CHANGED,
  BACKOFFICE_AD_REQUESTED,
} from './events';
import {
  adEditorFormCouldNotLoadAdSelector,
  adFailedSelector,
  adSavedSelector,
  adSelector,
} from './selectors';

const Form = styled.form`
  margin: 1rem auto;
  width: 75vw;

  ${Section} {
    ${spacing.stack(SPACING_SIZE.L, false)};
  }
`;

function AdEditor() {
  const { adId } = useParams();
  useMount(() => dispatch({ id: BACKOFFICE_AD_REQUESTED, payload: { adId } }));
  const ad = useSelector(adSelector);
  const isSaved = useSelector(adSavedSelector);
  const saveFailed = useSelector(adFailedSelector);
  const errorLoading = useSelector(adEditorFormCouldNotLoadAdSelector);

  if (errorLoading) {
    return (
      <Layout>
        <h2>Edit ad</h2>
        <p>{errorLoading}</p>
      </Layout>
    );
  }

  return (
    <Layout>
      <Form onSubmit={onSubmit}>
        <h2>Edit Ad {ad.id}</h2>
        <InputText
          name="latitude"
          label="latitude"
          value={ad.latitude}
          required
          onChange={onLatitudeChange}
        />

        <InputText
          name="longitude"
          label="longitude"
          value={ad.longitude}
          required
          onChange={onLongitudeChange}
        />

        <InputText
          name="address"
          label="Address"
          value={ad.address}
          required
          onChange={onAddressChange}
        />
        <InputTextarea
          name="geoLevels"
          label="geoLevels"
          autoResize
          value={JSON.stringify(ad.geoLevels, null, 4)}
          onChange={onInputChange}
        />
        <Button type="submit">Save</Button>

        {isSaved && <p>Ad saved</p>}
        {saveFailed && <p>Ad save failed</p>}
      </Form>
    </Layout>
  );
}

function onSubmit(event) {
  event.preventDefault();
  dispatch(BACKOFFICE_AD_ADDRESS_FORM_SUBMITTED);
}

function onInputChange(event) {
  const { name, value } = event.target;
  dispatch({
    id: BACKOFFICE_AD_ADDRESS_GEOLEVELS_CHANGED,
    payload: { name, value },
  });
}

function onLatitudeChange(event) {
  const { value } = event.target;
  dispatch({
    id: BACKOFFICE_AD_ADDRESS_FORM_LATITUDE_CHANGED,
    payload: { value },
  });
}

function onLongitudeChange(event) {
  const { value } = event.target;
  dispatch({
    id: BACKOFFICE_AD_ADDRESS_FORM_LONGITUDE_CHANGED,
    payload: { value },
  });
}

function onAddressChange(event) {
  const { name, value } = event.target;
  dispatch({
    id: BACKOFFICE_AD_ADDRESS_FORM_ADDRESS_CHANGED,
    payload: { name, value },
  });
}

export default AdEditor;
