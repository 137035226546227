import { http } from 'reffects-batteries';
import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { analytics } from '../../effects/analytics';
import { environment } from '../../coeffects/environment';
import { queryParamsGetAll } from '../../coeffects/queryParamsAll';

export const UNSUBSCRIBE_REQUESTED = 'UNSUBSCRIBE_REQUESTED';
export const UNSUBSCRIBE_SUCCESS = 'UNSUBSCRIBE_SUCCESS';
export const UNSUBSCRIBE_FAILED = 'UNSUBSCRIBE_FAILED';

registerEventHandler(
  UNSUBSCRIBE_REQUESTED,
  ({ environment: { apiUrl }, queryParamsAll: { enquiryId } }) =>
    http.patch({
      url: `${apiUrl}/enquiry/${enquiryId}/unsubscribe`,
      successEvent: UNSUBSCRIBE_SUCCESS,
      errorEvent: UNSUBSCRIBE_FAILED,
    }),
  [environment(), queryParamsGetAll()]
);

registerEventHandler(
  UNSUBSCRIBE_SUCCESS,
  ({ queryParamsAll: { publisherName, brand, enquiryId } }) => ({
    ...state.set({
      unsubscribe: {
        success: true,
        publisherName,
        brand,
      },
    }),
    ...analytics.trackPageView('unsubscribe-enquiry', {
      enquiryId,
    }),
  }),
  [queryParamsGetAll()]
);

registerEventHandler(UNSUBSCRIBE_FAILED, () =>
  state.set({
    unsubscribe: {
      success: false,
    },
  })
);
