import { useSelector } from 'reffects-store';
import GlobalizaLogo from 'design-system/components/GlobalizaLogo';
import LogoDotPropertyGroup from 'design-system/components/LogoDotPropertyGroup';
import LogoLamudi from 'design-system/components/LogoLamudi';
import Logo from 'design-system/components/Logo/Logo';
import {
  showByDotPropertyLogoSelector,
  showByLamudiLogoSelector,
} from '../selectors/publisher';
import {
  isCountryConfigLoadedSelector,
  showGlobalizaLogoSelector,
} from '../App/selectors';

export function BrandLogo() {
  const isCountryLoaded = useSelector(isCountryConfigLoadedSelector);
  const showDotPropertyLogo = useSelector(showByDotPropertyLogoSelector);
  const showLamudiLogo = useSelector(showByLamudiLogoSelector);
  const showGlobalizaLogo = useSelector(showGlobalizaLogoSelector);
  if (!isCountryLoaded) return null;

  if (showDotPropertyLogo) {
    return <LogoDotPropertyGroup />;
  }

  if (showLamudiLogo) {
    return <LogoLamudi />;
  }

  if (showGlobalizaLogo) {
    return <GlobalizaLogo />;
  }

  return <Logo />;
}

export function BrandLogoForAnonymousUsers({ ...rest }) {
  const showDotPropertyLogo = useSelector(showByDotPropertyLogoSelector);
  const showLamudiLogo = useSelector(showByLamudiLogoSelector);
  const showGlobalizaLogo = useSelector(showGlobalizaLogoSelector);
  if (showDotPropertyLogo) {
    return <LogoDotPropertyGroup {...rest} />;
  }

  if (showLamudiLogo) {
    return <LogoLamudi {...rest} />;
  }

  if (showGlobalizaLogo) {
    return <GlobalizaLogo {...rest} />;
  }

  return <Logo {...rest} />;
}
