import styled from 'styled-components';
import { useUnmount } from 'react-use';
import { dispatch } from 'reffects';
import { useIntl } from 'react-intl';
import RadioButton from 'design-system/components/RadioButton/RadioButton';
import InputTextarea from 'design-system/components/InputTextarea';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';
import FormattedMessageWithValidation from '../../../../FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  REASON_OTHER_TEXT_CHANGED,
  RESET_DELETE_OR_UNPUBLISH_REASON,
  SELECT_REASON_DELETE_OR_UNPUBLISH,
} from './events';
import useTracking from '../../../../../hooks/useTracking';

const REASON = 'REASON';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.M)};
  ${spacing.stack(SPACING_SIZE.L, false)};
`;

const RadioOption = styled.label`
  display: flex;
  gap: ${spacing.value(SPACING_SIZE.S)};
  align-items: center;

  ${Body1} {
    ${typography.weight(FONT_WEIGHT.LIGHT)}
  }
`;

const reasons = [
  {
    name: 'reason_deal_closed_proppit',
    value: 'deal_closed_proppit',
  },
  {
    name: 'reason_deal_closed_other_channel',
    value: 'deal_closed_other_channel',
  },
  {
    name: 'reason_deal_closed_not_me',
    value: 'deal_closed_not_me',
  },
  { name: 'reason_other', value: 'other' },
];

export default function DeleteOrUnpublishReason({
  reason,
  reasonText,
  sectionName,
  impression,
}) {
  useUnmount(() => dispatch(RESET_DELETE_OR_UNPUBLISH_REASON));
  const intl = useIntl();
  const ref = useTracking({
    sectionName,
    trackImpression: { elementName: impression },
  });
  return (
    <Wrapper ref={ref}>
      {reasons.map((r) => (
        <RadioOption key={r.value}>
          <RadioButton
            name={REASON}
            onChange={(e) =>
              dispatch({
                id: SELECT_REASON_DELETE_OR_UNPUBLISH,
                payload: e.target.value,
              })
            }
            defaultChecked={reason === r.value}
            value={r.value}
          />
          <Body1>
            <FormattedMessageWithValidation id={r.name} />
          </Body1>
        </RadioOption>
      ))}
      {reason === 'other' && (
        <InputTextarea
          placeholder={intl.formatMessage({
            id: 'reason_other_text_placeholder',
          })}
          value={reasonText}
          onChange={(e) =>
            dispatch({ id: REASON_OTHER_TEXT_CHANGED, payload: e.target.value })
          }
        />
      )}
    </Wrapper>
  );
}
