import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { environment } from '../../coeffects/environment';
import { redirectTo } from '../../effects/routing';
import { homeRoute } from '../../utils/proppitWebRouter';
import { table } from '../../partials/DataTable/effects/table';
import { CLOSE_DIALOG, OPEN_DIALOG } from '../../events/dialogs';

export const EDIT_BOOSTING_MODAL = 'editBoostingModal';

export const OPEN_EDIT_BOOSTING_MODAL = 'OPEN_EDIT_BOOSTING_MODAL';

export const LOG_WITH_MANAGED_PUBLISHER = 'LOG_WITH_MANAGED_PUBLISHER';
export const LOG_WITH_MANAGED_PUBLISHER_SUCCESS =
  'LOG_WITH_MANAGED_PUBLISHER_SUCCESS';

export const BOOSTING_SLOTS_MODIFIED = 'BOOSTING_SLOTS_MODIFIED';
export const SAVE_BOOSTING_SLOTS = 'SAVE_BOOSTING_SLOTS';
export const SAVE_BOOSTING_SLOTS_SUCCESS = 'SAVE_BOOSTING_SLOTS_SUCCESS';
export const SAVE_BOOSTING_SLOTS_FAILED = 'SAVE_BOOSTING_SLOTS_FAILED';

registerEventHandler(
  OPEN_EDIT_BOOSTING_MODAL,
  ({ select }, { collectionName }) => ({
    ...state.set({
      boostingSlots: select.itemsById({ collectionName }),
    }),
    ...effects.dispatch({
      id: OPEN_DIALOG,
      payload: { id: EDIT_BOOSTING_MODAL },
    }),
  }),
  [table.select()]
);

registerEventHandler(
  LOG_WITH_MANAGED_PUBLISHER,
  ({ environment: { apiUrl } }, { publisherId }) =>
    http.put({
      url: `${apiUrl}/users/me/publisher/${publisherId}`,
      successEvent: LOG_WITH_MANAGED_PUBLISHER_SUCCESS,
    }),
  [environment()]
);

registerEventHandler(LOG_WITH_MANAGED_PUBLISHER_SUCCESS, () =>
  redirectTo(homeRoute())
);

registerEventHandler(
  BOOSTING_SLOTS_MODIFIED,
  (_, { publisherId, boostingSlots, field }) =>
    state.merge({
      boostingSlots: {
        [publisherId]: { [field]: boostingSlots ?? 0 },
      },
    })
);

registerEventHandler(
  SAVE_BOOSTING_SLOTS,
  (
    { environment: { apiUrl }, state: { boostingSlots, submitting } },
    { onSuccessEvent }
  ) =>
    submitting
      ? {}
      : {
          ...state.set({ 'boostingSlots:submitting': true }),
          ...http.post({
            url: `${apiUrl}/users/me/publishers/boosting-slots`,
            body: Object.values(boostingSlots),
            successEvent: {
              id: SAVE_BOOSTING_SLOTS_SUCCESS,
              payload: { onSuccessEvent },
            },
            errorEvent: SAVE_BOOSTING_SLOTS_FAILED,
          }),
        },
  [
    environment(),
    state.get({
      boostingSlots: 'boostingSlots',
      submitting: 'boostingSlots:submitting',
    }),
  ]
);

registerEventHandler(
  SAVE_BOOSTING_SLOTS_SUCCESS,
  (_, [__, { onSuccessEvent }]) => ({
    ...state.set({ 'boostingSlots:submitting': false }),
    ...effects.dispatchMany([onSuccessEvent, CLOSE_DIALOG]),
  })
);

registerEventHandler(SAVE_BOOSTING_SLOTS_FAILED, () =>
  state.set({ 'boostingSlots:submitting': false })
);
