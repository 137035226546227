import InputSelect from 'design-system/components/InputSelect';

export default function PaymentMethodSelect({
  availablePaymentMethods,
  value,
  onChange,
  disabled,
}) {
  return (
    <InputSelect
      name="paymentMethod"
      label="Payment Method"
      value={value}
      disabled={disabled}
      required
      enableHtmlValidation
      choices={availablePaymentMethods.map(({ id, label }) => ({
        id,
        label,
        value: id,
      }))}
      onSelect={onChange}
    />
  );
}
