import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import Tip from 'design-system/components/Tip';
import Anchor from 'design-system/components/Anchor';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { sitePublisherPageLinksSelector } from '../../selectors/config';

const Wrapper = styled(Tip)`
  display: flex;
  flex-direction: column;

  > * {
    ${spacing.stack(SPACING_SIZE.M)}
  }
`;

const Sites = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    ${spacing.stack(SPACING_SIZE.S)}
  }
`;

export function PublishInSitesTip() {
  const sites = useSelector(sitePublisherPageLinksSelector);
  return (
    <Wrapper>
      <Subtitle3>
        <FormattedMessageWithValidation id="company_profile_published_in_sites_title" />
      </Subtitle3>
      <Sites>
        {sites.map(({ site, publisherPageUrl }) => (
          <Anchor key={site} href={publisherPageUrl} target="_blank">
            {site}
          </Anchor>
        ))}
      </Sites>
    </Wrapper>
  );
}
