import { registerEffectHandler } from 'reffects';

const LOCAL_STORAGE_SET_ITEM = 'localStorage.setItem';

export default function registerLocalStorageEffects(window) {
  registerEffectHandler(LOCAL_STORAGE_SET_ITEM, ({ key, value }) => {
    window.localStorage.setItem(key, value);
  });
}

export const localStorage = {
  setItem(key, value) {
    return { [LOCAL_STORAGE_SET_ITEM]: { key, value } };
  },
};
