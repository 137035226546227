import { debounce } from 'lodash';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Autocomplete from 'design-system/components/Autocomplete/Autocomplete';
import { DEFAULT_AUTOCOMPLETE_DEBOUNCE } from '../../../../../../constants';
import SearchField from './SearchField';
import SearchOption from './SearchOption';
import { useFilter } from '../../../../../../partials/DataTable/hooks/useFilter';
import FormattedMessageWithValidation from '../../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { LEAD_FILTERS } from '../../../../constants';
import { LEADS_PROJECTS_SEARCHED } from '../events';
import { leadsProjectsSelector } from '../selectors';

const debouncedSearchProperties = debounce((query) => {
  dispatch({ id: LEADS_PROJECTS_SEARCHED, payload: { query } });
}, DEFAULT_AUTOCOMPLETE_DEBOUNCE);

const EMPTY_TEXT = '';

const Wrapper = styled(Autocomplete)`
  min-width: 280px;
`;

export default function LeadsProjectFilter({ onSelect }) {
  const leadsProjects = useSelector(leadsProjectsSelector);
  const { value: filterValue, setValue: setFilterValue } = useFilter(
    LEAD_FILTERS.LEADS_PROJECT
  );
  const [query, setQuery] = useState(filterValue?.name);

  useEffect(() => {
    // sync autosuggestion query with current filter value
    setQuery(filterValue?.projectName ?? EMPTY_TEXT);
  }, [filterValue?.id]);

  return (
    <Wrapper
      value={query}
      options={leadsProjects}
      onValueChange={(value) => {
        setQuery(value);
        debouncedSearchProperties(value);
      }}
      optionToString={(option) => option?.name ?? EMPTY_TEXT}
      onOptionSelected={(option) => {
        setFilterValue({ id: option.id, projectName: option.name });
        onSelect?.();
      }}
      renderInput={({ value = EMPTY_TEXT, getInputProps, onClear }) => (
        <SearchField
          {...getInputProps()}
          value={value}
          onRemove={() => {
            onClear();
            setFilterValue(null);
            clearQuery(setQuery);
          }}
        />
      )}
      renderOptions={({ options, getOptionProps }) =>
        options.map((option, index) => (
          <SearchOption
            option={option}
            {...getOptionProps({ option, index, refKey: 'innerRef' })}
          />
        ))
      }
      emptyStateMessage={
        <FormattedMessageWithValidation id="enqpage_prop_filters_noresult" />
      }
    />
  );
}

function clearQuery(setQuery) {
  setQuery(EMPTY_TEXT);
}
