import styled from 'styled-components';
import Thumbnail from 'design-system/components/Thumbnail';
import Image from 'design-system/components/Image/Image';
import SkeletonBone from 'design-system/components/SkeletonBone';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import Typography from 'design-system/components/Typography/Typography';
import { FONT_WEIGHT } from 'design-system/styles/typography';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;

  ${Thumbnail} {
    ${color.background(COLOR_PALETTE.NEUTRAL_A05)}
  }

  ${Thumbnail}, ${Image.Skeleton} {
    ${Thumbnail.mods.size(57, 38)}
    ${spacing.inline(SPACING_SIZE.S)}
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;

  ${SkeletonBone} {
    ${spacing.stack(SPACING_SIZE.XXS)}
  }
`;

const Title = styled(Body2)`
  ${Typography.mods.size(11.5)}
  ${Typography.mods.ellipse({ lineClamp: 2 })}
`;

function ProjectSnippet({ title, image, ...rest }) {
  return (
    <Wrapper {...rest}>
      <Thumbnail source={image} />
      <Info>
        <Title title={title} $weight={FONT_WEIGHT.NORMAL}>
          {title}
        </Title>
      </Info>
    </Wrapper>
  );
}

ProjectSnippet.Skeleton = ({ ...rest }) => (
  <Wrapper {...rest}>
    <Image.Skeleton width="57px" height="38px" />
    <Info>
      <SkeletonBone $width="90px" $height="10px" randomWidth />
      <SkeletonBone $width="90px" $height="10px" randomWidth />
      <SkeletonBone $width="90px" $height="10px" randomWidth />
    </Info>
  </Wrapper>
);

export default styled(ProjectSnippet)``;
