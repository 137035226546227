import { useCallback } from 'react';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import { useMount } from 'react-use';
import Icon from 'design-system/components/Icon';
import { AGENCY_DETAIL_EDIT_CONTACT_DIALOG_ID } from './constants';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { useDialog } from '../../../hooks/useDialog';

import DetailTable from './DetailTable';
import {
  CONTACT_DETAIL_TABLE_MOUNTED,
  CONTACT_DETAIL_TABLE_PAGE_CHANGED,
} from './events';
import { contactDetailsTableSelector } from './selectors';

const ICONS_GLYPH_BY_CONTACT_TYPE = {
  whatsapp: 'vendorWhatsapp',
  line: 'vendorLine',
  facebook: 'vendorFacebook',
  viber: 'vendorViber',
};

const TAG_ID_BY_CONTACT_TYPE = {
  emails: 'hover_bulk_contact_detail_email',
  phones: 'hover_bulk_contact_detail_phone',
  whatsapp: 'hover_bulk_contact_detail_whastapp',
  line: 'hover_bulk_contact_detail_line',
  facebook: 'hover_bulk_contact_detail_facebook',
  viber: 'hover_bulk_contact_detail_viber',
};

function ContactDetailsTable({ contactType }) {
  const { openDialog } = useDialog(AGENCY_DETAIL_EDIT_CONTACT_DIALOG_ID);
  const {
    pagination,
    rows,
    data: fullContactList,
  } = useSelector((state) => contactDetailsTableSelector(state, contactType));
  useMount(() => {
    dispatch({
      id: CONTACT_DETAIL_TABLE_MOUNTED,
      payload: { contactType, fullContactList },
    });
  });

  const handleRowClick = useCallback(
    (clickedRow) => {
      const detail = clickedRow.reduce(
        (acc, { column, value }) => ({
          ...acc,
          [column]: value,
        }),
        {}
      );

      openDialog({
        contactType,
        detail,
      });
    },
    [contactType]
  );

  return (
    <DetailTable
      onRowClick={handleRowClick}
      columns={[
        {
          id: 'value',
          title: (
            <FormattedMessageWithValidation
              id={TAG_ID_BY_CONTACT_TYPE[contactType]}
            />
          ),
          icon: ICONS_GLYPH_BY_CONTACT_TYPE[contactType] && (
            <Icon glyph={ICONS_GLYPH_BY_CONTACT_TYPE[contactType]} />
          ),
        },
        {
          id: 'associatedAds',
          title: (
            <FormattedMessageWithValidation id="hover_bulk_contact_detail_associated_properties" />
          ),
        },
      ]}
      details={rows.map(({ value, ads }) => [
        {
          column: 'associatedAds',
          value: ads,
        },
        {
          column: 'value',
          value,
        },
      ])}
      pagination={pagination}
      onPaginate={(page) => {
        dispatch({
          id: CONTACT_DETAIL_TABLE_PAGE_CHANGED,
          payload: { contactType, page },
        });
      }}
    />
  );
}

export default ContactDetailsTable;
