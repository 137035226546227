import { FormattedMessage } from 'react-intl';
import { subscribe } from 'reffects-store';
import translationIsPresentSelector from './selectors';

function FormattedMessageWithValidation({ id, values, translationIsPresent }) {
  if (translationIsPresent) {
    return (
      <span data-tag={id}>
        <FormattedMessage id={id} values={values} />
      </span>
    );
  }

  if (id) {
    console.info(
      `%c 🔠 Translation '${id}' does not exist`,
      'background: #fffbe5; color: #65450e'
    );
    return <span data-tag={id}>{id}</span>;
  }
  console.warn(`Translation id is not a valid value`);
  return null;
}

export default subscribe(FormattedMessageWithValidation, (state, { id }) => ({
  translationIsPresent: translationIsPresentSelector(state, id),
}));
