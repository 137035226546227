import styled from 'styled-components';
import { iconography } from 'design-system/tokens/iconography';
import Tooltip from 'design-system/components/Tooltip';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import Typography, {
  TYPOGRAPHY_VARIANT,
} from 'design-system/components/Typography/Typography';
import { FONT_WEIGHT } from 'design-system/styles/typography';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ${spacing.stack(SPACING_SIZE.S)}

  ${Icon} {
    ${Icon.mods.size(ICON_SIZE.SMALL)}
    ${spacing.inline(SPACING_SIZE.S)}
    ${color.text(COLOR_PALETTE.PRIMARY_BASE)}
  }

  a {
    ${color.text(COLOR_PALETTE.BLUEBERRY_BASE)}
    text-decoration: underline;
  }

  ${Tooltip} {
    width: calc(100% - ${iconography.size.base}px);
  }
`;

const Text = styled(Typography)`
  ${Typography.mods.ellipse()}
  ${Typography.mods.weight(FONT_WEIGHT.LIGHT)}
  ${Typography.mods.variant(TYPOGRAPHY_VARIANT.BODY_1)}
  width: 100%
`;

function Datum({ glyph, children, as, tooltip, ...rest }) {
  const content = tooltip ? (
    <Tooltip title={tooltip}>
      <Text as={as} {...rest}>
        {children}
      </Text>
    </Tooltip>
  ) : (
    <Text as={as} {...rest}>
      {children}
    </Text>
  );
  return (
    <Wrapper>
      <Icon glyph={glyph} $size={ICON_SIZE.BASE} /> {content}
    </Wrapper>
  );
}

export default styled(Datum)``;
