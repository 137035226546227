import styled, { css } from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';

const modSticky = () => css`
  position: sticky;
  bottom: 0;
  box-shadow: 0px 0 10px ${COLOR_PALETTE.NEUTRAL_A20};
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  ${spacing.insetSquish(SPACING_SIZE.XL)}
  ${({ highlight }) => highlight && color.background(COLOR_PALETTE.NEUTRAL_A03)}
  ${({ sticky }) => sticky && modSticky()}
`;

export default Section;
