import styled from 'styled-components';
import Illustration from 'design-system/components/Illustration/Illustration';
import Image from 'design-system/components/Image/Image';
import { radius } from 'design-system/styles/radius';

const Wrapper = styled.div`
  width: 32px;
  height: 32px;

  overflow: hidden;

  ${radius.pill()}
`;

function Avatar({ className, style, ...rest }) {
  return (
    <Wrapper className={className} style={style}>
      <Image placeholder={<Illustration name="avatar" />} {...rest} />
    </Wrapper>
  );
}

export default styled(Avatar)``;
