import styled from 'styled-components';
import {
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const HeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

const HeaderColumn = styled.div`
  align-items: flex-start;
  width: ${(props) =>
    props.percentageWidth ? `${props.percentageWidth}%` : '56px'};
`;

const HeadDivContent = styled.div`
  text-align: left;
  ${typography.font(FONT_FAMILY.PRIMARY)}
  ${typography.size(FONT_SIZE.S)}
  ${typography.weight(FONT_WEIGHT.MEDIUM)}  
    display: flex;
  padding: 8px 16px;
  align-items: center;
  align-self: stretch;
  font-style: normal;
  line-height: 24px;
  text-transform: uppercase;
  height: 40px;
`;
const SeparatorContentDiv = styled.div`
  height: ${(props) => (props.height ? `${props.height}px` : '58px')};
  width: 56px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ContentCell = styled.div``;

function HeaderTable({ schema }) {
  return (
    <HeaderDiv>
      <HeaderColumn
        key={schema.column1.key}
        percentageWidth={schema.column1.width}
      >
        <HeadDivContent>
          <ContentCell>
            <FormattedMessageWithValidation id={schema.column1.translationId} />
          </ContentCell>
        </HeadDivContent>
      </HeaderColumn>
      <HeaderColumn key="space">
        <SeparatorContentDiv height="40" />
      </HeaderColumn>
      <HeaderColumn
        key={schema.column2.key}
        percentageWidth={schema.column2.width}
      >
        <HeadDivContent>
          <ContentCell>
            <FormattedMessageWithValidation id={schema.column2.translationId} />
          </ContentCell>
        </HeadDivContent>
      </HeaderColumn>
      {schema.column3 && (
        <HeaderColumn
          key={schema.column3.key}
          percentageWidth={schema.column3.width}
        >
          <HeadDivContent>
            <ContentCell>
              <FormattedMessageWithValidation
                id={schema.column3.translationId}
              />
            </ContentCell>
          </HeadDivContent>
        </HeaderColumn>
      )}
    </HeaderDiv>
  );
}

export default HeaderTable;
