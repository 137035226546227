import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from 'design-system/components/Button';
import Illustration from 'design-system/components/Illustration/Illustration';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Heading2 } from 'design-system/components/Typography/presets/Heading2';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { newPropertyForm } from '../../../../utils/proppitWebRouter';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import FormattedMessageWithTranslatedCountry from '../../../../partials/FormattedMessageWithTranslatedCountry/FormattedMessageWithCountry';
import useTracking from '../../../../hooks/useTracking';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    max-width: 595px;
  }

  ${Illustration} {
    ${spacing.stack(SPACING_SIZE.M, false)}
  }

  ${Heading2}, ${Body1} {
    text-align: center;
    ${spacing.stack(SPACING_SIZE.M, false)}
  }

  ${Button} {
    margin-top: ${spacing.value(SPACING_SIZE.L)};
    margin-bottom: ${spacing.value(SPACING_SIZE.L)};
  }
`;

export default function NoPropertiesCreated() {
  const createPropertyTrackingRef = useTracking({
    trackClick: {
      eventName: 'create-new-listing',
    },
    trackImpression: {
      elementName: 'create-new-listing-btn',
    },
    sectionName: 'overview-no-listings-message-bottom-new-listing-button',
  });

  return (
    <Wrapper>
      <Illustration name="buildings" />
      <Heading2>
        <FormattedMessageWithTranslatedCountry id="overview_properties_empty_title" />
      </Heading2>
      <Body1>
        <FormattedMessageWithValidation id="overview_kpis_chart_empty_tip" />
      </Body1>
      <Button
        forwardedAs={Link}
        to={newPropertyForm()}
        ref={createPropertyTrackingRef}
      >
        <FormattedMessageWithValidation id="overview_kpis_chart_empty_cta" />
      </Button>
    </Wrapper>
  );
}
