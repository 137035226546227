import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { environment } from '../../coeffects/environment';
import { navigateTo } from '../../effects/routing';

export const ENQUIRY_DETAIL_PAGE_MOUNTED = 'ENQUIRY_DETAIL_PAGE_MOUNTED';
export const LEAD_DATA_LOADED_TO_REDIRECT_TO_LEAD_DETAILS_PAGE =
  'LEAD_DATA_LOADED_TO_REDIRECT_TO_LEAD_DETAILS_PAGE';

registerEventHandler(
  ENQUIRY_DETAIL_PAGE_MOUNTED,
  ({ environment: { apiUrl } }, { enquiryId }) =>
    http.get({
      url: `${apiUrl}/enquiries/${enquiryId}`,
      successEvent: LEAD_DATA_LOADED_TO_REDIRECT_TO_LEAD_DETAILS_PAGE,
    }),
  [environment()]
);

registerEventHandler(
  LEAD_DATA_LOADED_TO_REDIRECT_TO_LEAD_DETAILS_PAGE,
  (_, [{ data }]) => navigateTo(`/leads/${data.leadId}`)
);
