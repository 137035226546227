import Dialog from 'design-system/components/Dialog/Dialog';
import {
  FlatButton,
  SecondaryButton,
} from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import { dispatch } from 'reffects';
import { useDialog } from '../../../../../../../hooks/useDialog';
import { CANCEL_SUBSCRIPTION_REQUEST_MODAL } from './constants';
import { ACCEPT_CANCEL_REQUEST, REJECT_CANCEL_REQUEST } from './events';

export function CancelSubscriptionRequestModal({ onSuccessEvent }) {
  const { open, closeDialog, parameters } = useDialog(
    CANCEL_SUBSCRIPTION_REQUEST_MODAL
  );
  return (
    <Dialog open={open}>
      <Dialog.Header onClose={closeDialog}>
        Are you sure that you want to cancel automatic renewal?
      </Dialog.Header>
      <Dialog.Footer>
        <FlatButton
          $size={BUTTON_SIZE.SMALL}
          onClick={() =>
            dispatch({
              id: REJECT_CANCEL_REQUEST,
              payload: {
                subscriptionId: parameters.subscriptionId,
                onSuccessEvent,
              },
            })
          }
        >
          Reject
        </FlatButton>
        <SecondaryButton
          $size={BUTTON_SIZE.SMALL}
          onClick={() =>
            dispatch({
              id: ACCEPT_CANCEL_REQUEST,
              payload: {
                subscriptionId: parameters.subscriptionId,
                onSuccessEvent,
              },
            })
          }
        >
          Accept
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}
