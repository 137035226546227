import styled from 'styled-components';
import GlobalizaLogo from 'design-system/components/GlobalizaLogo';
import Logo from 'design-system/components/Logo/Logo';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import { Subtitle1 } from 'design-system/components/Typography/presets/Subtitle1';
import { BrandLogoForAnonymousUsers } from '../../partials/BrandLogo';
import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Wrapper = styled.div`
  ${spacing.inset(SPACING_SIZE.XXXL)}
  display: flex;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
`;

const Content = styled.div`
  ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
  width: 600px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${spacing.inset(SPACING_SIZE.XL)}
  ${Logo}, ${GlobalizaLogo} {
    display: flex;
    margin: 0 auto;
    ${spacing.stack(SPACING_SIZE.XL, false)}
  }

  ${Heading1} {
    ${spacing.stack(SPACING_SIZE.M, false)}
  }

  ${Subtitle1} {
    text-align: center;
  }
`;

export function Maintenance() {
  return (
    <Wrapper>
      <Content>
        <BrandLogoForAnonymousUsers />
        <Heading1>
          <FormattedMessageWithValidation id="maintenance_page_title" />
        </Heading1>
        <Subtitle1>
          <FormattedMessageWithValidation
            id="maintenance_page_message"
            values={{
              breakingLine: (
                <>
                  <br />
                </>
              ),
            }}
          />
        </Subtitle1>
      </Content>
    </Wrapper>
  );
}
