import styled, { css } from 'styled-components';
import { border } from 'design-system/styles/border';
import { radius } from 'design-system/styles/radius';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle2 } from 'design-system/components/Typography/presets/Subtitle2';
import ReleaseDate from './ReleaseDate';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Dot = styled.div`
  ${radius.pill()}
  ${({ active }) =>
    active
      ? css`
          width: 24px;
          height: 24px;
          ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
          ${border.outer({ size: 4, color: COLOR_PALETTE.PRIMARY_A60 })}
        `
      : css`
          width: 10px;
          height: 10px;
          ${color.background(COLOR_PALETTE.NEUTRAL_A80)}
        `}
`;

const StageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ active }) =>
    !active &&
    css`
      &:not(:last-of-type) > div {
        padding: 0 ${spacing.value(SPACING_SIZE.XS)};
      }
      &:first-of-type {
        margin-left: ${spacing.value(SPACING_SIZE.M)};
        > div {
          padding-left: 0;
        }
      }
      &:last-of-type {
        margin-right: ${spacing.value(SPACING_SIZE.M)};
        padding-right: 0;
      }
    `}

  &:not(:last-of-type) {
    &:after {
      content: '';
      width: 90px;
      height: 1px;
      ${color.background(COLOR_PALETTE.NEUTRAL_A20)}
      margin-left: ${spacing.value(SPACING_SIZE.XS)};
      margin-right: ${spacing.value(SPACING_SIZE.XS)};
    }
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    ${spacing.inset(SPACING_SIZE.S)}
    ${radius.regular(114)}
    ${({ active }) =>
      active &&
      css`
        padding-right: ${spacing.value(SPACING_SIZE.M)};
        ${color.background(COLOR_PALETTE.PRIMARY_BASE)}
      `}
    
    ${Dot} {
      ${spacing.inline(SPACING_SIZE.S, false)}
    }

    ${Subtitle2} {
      ${({ active }) =>
        color.text(
          active ? COLOR_PALETTE.NEUTRAL_A00 : COLOR_PALETTE.NEUTRAL_A80
        )}
    }
  }
`;

const Stage = ({ children, active, ...props }) => (
  <StageWrapper active={active}>
    <div {...props}>
      <Dot active={active} />
      <Subtitle2 active={active}>{children}</Subtitle2>
    </div>
  </StageWrapper>
);

const Stages = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 0;
  ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
  ${radius.regular(72)}
`;

const Wrapper = styled.div`
  display: flex;
`;

function Phases({ project, ...props }) {
  return (
    <Wrapper {...props}>
      <Stages>
        <Stage active={project.currentPhase === 'off-plan'}>
          <FormattedMessageWithValidation id="project_detail_phase_off_plan" />
        </Stage>
        <Stage active={project.currentPhase === 'in-construction'}>
          <FormattedMessageWithValidation id="project_detail_phase_construction" />
        </Stage>
        <Stage active={project.currentPhase === 'ready-release'}>
          <FormattedMessageWithValidation id="project_detail_phase_ready_release" />
        </Stage>
        <Stage active={project.currentPhase === 'release'}>
          <FormattedMessageWithValidation
            id="project_detail_phase_release"
            values={{
              endDate: <ReleaseDate date={project.endDate} />,
            }}
          />
        </Stage>
      </Stages>
    </Wrapper>
  );
}

export default styled(Phases)``;
