import { Id } from './Id';
import { BodyWrapper, Details, Paragraph, Title } from './ItemComponents';

export function Webhook({ event }) {
  const title = `BC information received`;
  const description = `${event.webhook.documentType} received.`;
  let scrLink = null;

  if (event.webhook.scrId) {
    scrLink = (
      <>
        For SCR: <Id id={event.webhook.scrId} />
      </>
    );
  }

  return (
    <BodyWrapper>
      <Title>{title}</Title>
      <Details>
        <Paragraph>{description}</Paragraph>
        <Paragraph>{scrLink}</Paragraph>
      </Details>
    </BodyWrapper>
  );
}
