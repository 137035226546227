import {
  oneOf,
  userIsFinanceManagerSelector,
  userIsFinanceSelector,
  userIsSalesManagerSelector,
  userIsSalesSelector,
  userIsSuperadminSelector,
  userIsSupportSelector,
} from '../../../../../../../selectors/user';

export const showChangePaymentMethodSelector = userIsSuperadminSelector;

export function showAssociatePublisherSelector(state) {
  return oneOf(userIsSuperadminSelector, userIsSalesManagerSelector)(state);
}

export function showDisassociatePublisherSelector(state) {
  return oneOf(
    userIsSuperadminSelector,
    userIsSalesManagerSelector,
    userIsSalesSelector,
    userIsSupportSelector
  )(state);
}

export function showSendPropertiesToWasiSelector(state) {
  return oneOf(
    userIsSuperadminSelector,
    userIsSalesManagerSelector,
    userIsSalesSelector,
    userIsSupportSelector
  )(state);
}

export function showCancelSubscriptionRequestSelector(state, agency) {
  return (
    agency.renewalCancelRequestStatus === 'pending' &&
    oneOf(
      userIsSuperadminSelector,
      userIsSalesManagerSelector,
      userIsSupportSelector,
      userIsFinanceSelector,
      userIsFinanceManagerSelector
    )(state)
  );
}

export function showMoveToFreeSelector(state, planType) {
  return checkUserIsSuperadminOrFinanceAndPaidPlan(state, planType);
}

export function showMarkAsFreeFromBeginningSelector(state, planType) {
  return checkUserIsSuperadminOrFinanceAndPaidPlan(state, planType);
}

function checkUserIsSuperadminOrFinanceAndPaidPlan(state, planType) {
  return (
    (userIsSuperadminSelector(state) || userIsFinanceSelector(state)) &&
    planType === 'paid'
  );
}

export function showMarkAsTestSelector(state, agency) {
  return userIsSuperadminSelector(state) && !agency.isForTesting;
}
