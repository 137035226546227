import {effects, registerEventHandler} from 'reffects';
import {DATA_TABLE_UPDATE_URI} from './uri';
import {table} from '../effects/table';
import {combineStateSetEffects} from '../utils/combineStateSetEffects';
import {FIRST_PAGE} from '../constants';

export const DATA_TABLE_UPDATE_FILTER = 'DATA_TABLE_UPDATE_FILTER';
export const DATA_TABLE_CLEAR_FILTERS = 'DATA_TABLE_CLEAR_FILTERS';

registerEventHandler(
  DATA_TABLE_UPDATE_FILTER,
  (_, { filter: { name, value }, ...context }) => {
    const { collectionName, updateEvents } = context;

    return {
      ...combineStateSetEffects(
        table.setFilters(collectionName, {
          [name]: value,
        }),
        table.setPagination(collectionName, { currentPage: FIRST_PAGE })
      ),
      ...effects.dispatchMany([
        {
          id: updateEvents.collection,
          payload: context,
        },
        { id: DATA_TABLE_UPDATE_URI, payload: context },
      ]),
    };
  }
);

registerEventHandler(DATA_TABLE_CLEAR_FILTERS, (_, context) => {
  const { collectionName, updateEvents } = context;

  return {
    ...combineStateSetEffects(
      table.setFilters(collectionName, {}),
      table.setPagination(collectionName, { currentPage: FIRST_PAGE })
    ),
    ...effects.dispatchMany([
      {
        id: updateEvents.collection,
        payload: context,
      },
      { id: DATA_TABLE_UPDATE_URI, payload: context },
    ]),
  };
});
