import {
  CURRENT_SUBSCRIPTION_DISCOUNT_NAME,
  CUSTOM_DISCOUNT_NAME,
  PREVIOUS_SUBSCRIPTION_DISCOUNT_NAME,
  UPFRONT_DISCOUNT_NAME,
} from '../../constants/subscription';

export default function getDiscountNameString(
  discountName,
  unusedPreviousSubscriptionDays,
  unusedCurrentSubscriptionDays
) {
  return {
    [CUSTOM_DISCOUNT_NAME]: 'Custom discount',
    [UPFRONT_DISCOUNT_NAME]: 'Upfront discount',
    [PREVIOUS_SUBSCRIPTION_DISCOUNT_NAME]: `Previous subscription discount${getPreviousTail(
      unusedPreviousSubscriptionDays
    )}`,
    [CURRENT_SUBSCRIPTION_DISCOUNT_NAME]: `New subscription discount${getCurrentTail(
      unusedCurrentSubscriptionDays
    )}`,
  }[discountName];
}

function getPreviousTail(days) {
  return days ? ` for ${days} unused days` : '';
}

function getCurrentTail(days) {
  return days ? ` for ${days} unused days` : '';
}
