import { useSelector } from 'reffects-store';
import { isBackofficeUserSelector } from '../../../selectors/user';
import { useIsPublicRoute } from '../../../App/router';
import ImpersonationWarningBanner from './ImpersonationWarningBanner';

function SuperuserBanner() {
  const isPublicRoute = useIsPublicRoute();
  const isBackofficeUser = useSelector(isBackofficeUserSelector);

  if (isPublicRoute || !isBackofficeUser) return null;

  return <ImpersonationWarningBanner />;
}

export default SuperuserBanner;
