import { debounce } from 'lodash';
import { useEffect } from 'react';

function useOnWindowResize(callback, debounceMs) {
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      callback({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, debounceMs);

    window.addEventListener('resize', debouncedHandleResize);

    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });
}

export default useOnWindowResize;
