import JSONPretty from 'react-json-pretty';
import JSONPrettyTheme from 'react-json-pretty/dist/1337';
import { Id, IdMark } from './Id';
import {
  BodyWrapper,
  By,
  Details,
  Footer,
  Paragraph,
  RefList,
  Title,
} from './ItemComponents';

export function SubscriptionChange({ event }) {
  const title = `Subscription Change`;
  const refs = [];
  if (event.subscriptionChange.scrId) {
    refs.push(
      <li>
        For SCR: <Id id={event.subscriptionChange.scrId} />
      </li>
    );
  }

  return (
    <BodyWrapper>
      <Title>{title}</Title>
      <Details>
        <Paragraph>
          <b>Action: </b> {event.subscriptionChange.action}
          <br />
          <b>Comment: </b> {event.subscriptionChange.comment ?? 'none'}
          <br />
        </Paragraph>
        <JSONPretty
          data={event.subscriptionChange.active_subscription}
          theme={JSONPrettyTheme}
        />
      </Details>

      <Footer>
        <By event={event} />
        <IdMark id={event.id} />
        <RefList>{refs}</RefList>
      </Footer>
    </BodyWrapper>
  );
}
