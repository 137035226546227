import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import InputBase from 'design-system/components/InputBase/InputBase';
import InputTextarea from 'design-system/components/InputTextarea';
import { border } from 'design-system/styles/border';
import { color } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { useRegisterFieldErrors } from '../../../../publication/partials/FieldGroup';

const Textarea = styled(InputTextarea)`
  &:hover {
    ${InputBase.Box} {
      ${color.background('transparent')}

      input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 0 transparent inset !important;
      }
    }
  }

  ${InputBase.Box} {
    padding-left: 0;
    ${border.inner({
      color: 'transparent',
      size: 1,
    })}
  }

  :active,
  &[data-focus] {
    ${InputBase.Box} {
      ${border.inner({ color: 'transparent', size: 1 })}
    }
  }
`;

const ErrorMessage = styled.div`
  ${spacing.stack(SPACING_SIZE.S, false)}
`;

function InputField({ name, tip, ...props }) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = errors[name];

  useRegisterFieldErrors({ name, errors: error });

  return (
    <Textarea
      error={Boolean(error)}
      tip={<ErrorMessage>{error?.message ?? tip}</ErrorMessage>}
      {...register(name)}
      {...props}
    />
  );
}

export default InputField;
