import { Body1 } from 'design-system/components/Typography/presets/Body1';
import Table from 'design-system/components/Table';
import { useColumn } from '../../../partials/DataTable/hooks/useColumn';
import Price from '../../../partials/Price/Price';
import { sortOperations } from './operations';

export function OperationPricesColumn(props) {
  const { value: operations = [] } = useColumn(props);

  return (
    <Table.Cell {...props}>
      {sortOperations(operations).map(
        ({ type, price: { amount, currency } }, index) => (
          <Body1 key={type} $noWrap>
            <Price amount={amount} currency={currency} />
            {operations.length - 1 !== index && <span>&nbsp;/&nbsp;</span>}
          </Body1>
        )
      )}
    </Table.Cell>
  );
}
