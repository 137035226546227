import Compressorjs from 'compressorjs';

export default function optimizer(
  {
    images,
    onSuccess,
    onError,
    options = {
      maxWidth: 1500,
      maxHeight: 1500,
    },
  },
  Compressor = Compressorjs
) {
  function compressorPromise(image) {
    return new Promise((resolve, reject) => {
      const optimizerOptions = {
        maxWidth: options.maxWidth,
        maxHeight: options.maxHeight,
        beforeDraw(context, canvas) {
          // eslint-disable-next-line no-param-reassign
          context.fillStyle = '#FFF';
          context.fillRect(0, 0, canvas.width, canvas.height);
        },
        success(compressedImage) {
          resolve(compressedImage);
        },
        error(err) {
          reject(err);
        },
      };

      if (options.mimeType) {
        optimizerOptions.mimeType = options.mimeType;
      }

      // eslint-disable-next-line no-new
      new Compressor(image, optimizerOptions);
    });
  }

  return Promise.all(images.map(compressorPromise))
    .then(onSuccess)
    .catch(onError);
}
