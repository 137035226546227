import { dispatch } from 'reffects';
import Anchor from 'design-system/components/Anchor';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import Price from '../../../../partials/Price/Price';
import SubscriptionChangeRequestStatusAction from '../SubscriptionChangeRequestStatusAction/SubscriptionChangeRequestStatusAction';
import { OPEN_DIALOG } from '../../../../events/dialogs';
import { SUBSCRIPTION_DETAILS_DIALOG } from '../SubscriptionDetailsModal';
import {
  Column,
  Content,
  PriceSection,
  Row,
  TwoColumns,
  Wrapper,
} from './partials';
import {
  getSuperboostProductAsSubscription,
  isForSuperboost,
} from '../../../../utils/subscriptionChangeRequest';

export default function PendingSuperboostBanner({ scr }) {
  const showSuperboostBanner = isForSuperboost(scr);
  const superboostProduct = getSuperboostProductAsSubscription(scr);

  if (!showSuperboostBanner) return null;
  return (
    <Wrapper>
      <Content>
        <Subtitle3 $color={COLOR_PALETTE.NEUTRAL_BASE}>
          <FormattedMessageWithValidation id="subscription_superboost_pending_product_activation" />
        </Subtitle3>
        <Row>
          <TwoColumns>
            <Column>
              <div>
                <Heading1>
                  <FormattedMessageWithValidation id="subscription_superboost_title" />
                </Heading1>
                <Heading1>
                  {' '}
                  {superboostProduct.boostableProperties}{' '}
                  <FormattedMessageWithValidation id="subscription_superboosted_properties" />
                </Heading1>
              </div>
              <Body1 $weight={FONT_WEIGHT.LIGHT}>
                <FormattedMessageWithValidation
                  id="subscription_summary_contract_type_value"
                  values={{
                    duration: scr.billingCycleInMonths,
                  }}
                />
              </Body1>
            </Column>
            <Column>
              <PriceSection>
                <Heading1>
                  <Price
                    amount={superboostProduct.totalPrice.amount}
                    currency={superboostProduct.totalPrice.currency}
                  />
                  <SubscriptionChangeRequestStatusAction scr={scr} />
                </Heading1>
              </PriceSection>
              <Body1>
                <FormattedMessageWithValidation id="subscription_superboost_price" />
              </Body1>
            </Column>
          </TwoColumns>
        </Row>
        <Anchor
          href="#"
          onClick={(e) => {
            e.preventDefault();
            dispatch({
              id: OPEN_DIALOG,
              payload: {
                id: SUBSCRIPTION_DETAILS_DIALOG,
                parameters: {
                  title: 'subscription_superboost_details',
                  subscription: superboostProduct,
                },
              },
            });
          }}
        >
          <FormattedMessageWithValidation id="subscription_view_details" />
        </Anchor>
      </Content>
    </Wrapper>
  );
}
