import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { environment } from '../../../../../coeffects/environment';

export const LEADS_PROJECTS_SEARCHED = 'LEADS_PROJECT_SEARCHED';
export const LEADS_PROJECTS_RETRIEVED = 'LEADS_PROJECTS_RETRIEVED';

const MIN_CHARACTERS_TO_SEARCH = 3;

registerEventHandler(
  LEADS_PROJECTS_SEARCHED,
  ({ environment: { apiUrl } }, { query = '' }) => {
    if (query.length < MIN_CHARACTERS_TO_SEARCH) return undefined;

    return http.get({
      url: `${apiUrl}/developer/project-suggestions?query=${query}`,
      successEvent: { id: LEADS_PROJECTS_RETRIEVED },
    });
  },
  [environment()]
);

registerEventHandler(
  LEADS_PROJECTS_RETRIEVED,
  (_, [{ data }]) =>
    state.set({
      leadsProjects: data,
    }),
  []
);
