import styled from 'styled-components';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import Typography from 'design-system/components/Typography/Typography';
import {
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';

const Wrapper = styled.div`
  ${spacing.insetSquish(SPACING_SIZE.S)}
  ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
  ${color.background(COLOR_PALETTE.NEUTRAL_A80)}
  ${radius.regular(RADIUS_SIZE.BASE)}
  
  ${Typography} {
    ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
    ${typography.size(FONT_SIZE.S)}
    ${typography.weight(FONT_WEIGHT.MEDIUM)}
  }
`;

function Tooltip({ active, payload, renderContent }) {
  if (active) {
    return <Wrapper>{renderContent({ ...payload[0] })}</Wrapper>;
  }

  return null;
}

export default Tooltip;
