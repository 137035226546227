// noinspection ES6UnusedImports
import { UAParser } from 'ua-parser-js';

export function isMobile(userAgent) {
  const {
    device: { type },
  } = UAParser(userAgent);
  return type === 'mobile';
}

export function isAndroid(userAgent) {
  const {
    os: { name },
  } = UAParser(userAgent);

  return name === 'Android';
}

export function isIOS(userAgent) {
  const {
    os: { name },
  } = UAParser(userAgent);

  return name === 'iOS';
}
