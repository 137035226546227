import { Body1 } from 'design-system/components/Typography/presets/Body1';
import Table, { TABLE_ALIGNMENT } from 'design-system/components/Table';
import { DataTable, SnippetColumn } from '../../partials/DataTable';
import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import OperationFieldColumn from './OperationFieldColumn';
import { ActionsColumn } from '../../partials/DataTable/components/columns/ActionsColumn';
import buildTableRowEventsRedirectingTo from '../../partials/DataTable/utils/buildTableRowEventsRedirectingTo';
import { shouldShowPaginationSelector } from './selectors';
import useTableState from '../../partials/DataTable/hooks/useTableState';
import { operationTypeTranslationsTags } from '../../constants/ad';
import PriceRange from '../../partials/PriceRange';
import EmptyCellContent from '../../partials/DataTable/components/EmptyCellContent';
import buildTableRowEventsWithTracking from '../../partials/DataTable/utils/buildTableRowEventsWithTracking';
import PromotionStatusColumn from './PromotionStatusColumn';

function ProjectTable() {
  const shouldShowPagination = shouldShowPaginationSelector(useTableState());
  return (
    <DataTable
      allowPagination={shouldShowPagination}
      clickableRows
      rowEvents={buildTableRowEventsWithTracking(
        buildTableRowEventsRedirectingTo((itemId) => `/project/${itemId}`),
        {
          eventName: 'click-project-link',
          sectionName: 'projects-list-table',
        }
      )}
    >
      <SnippetColumn
        id="snippet"
        title={
          <FormattedMessageWithValidation id="projects_list_project_header" />
        }
        source={{
          title: 'name',
          address: 'address',
          image: 'image',
        }}
        maxWidth={225}
      />
      <OperationFieldColumn
        id="operationType"
        title={
          <FormattedMessageWithValidation id="projects_list_operation_type_header" />
        }
        renderField={({ type }) => (
          <ProjectOperationType operationType={type} />
        )}
      />
      <OperationFieldColumn
        id="units"
        title={
          <FormattedMessageWithValidation id="projects_list_units_header" />
        }
        renderField={({ units }) => <ProjectText text={units} />}
      />
      <OperationFieldColumn
        id="prices"
        title={
          <FormattedMessageWithValidation id="projects_list_prices_header" />
        }
        renderField={({ priceRange }) => <ProjectPrices prices={priceRange} />}
      />
      <OperationFieldColumn
        id="leads"
        title={
          <FormattedMessageWithValidation id="projects_list_leads_header" />
        }
        renderField={({ leads }) => <ProjectText text={leads} />}
        cellComponent={Table.KpiCell}
        alignment={TABLE_ALIGNMENT.CENTER}
      />
      <PromotionStatusColumn
        id="promotionStatus"
        title={
          <FormattedMessageWithValidation id="projects_list_promotion_status_header" />
        }
        source="isPromoted"
        alignment={TABLE_ALIGNMENT.CENTER}
      />
      <ActionsColumn
        id="actions"
        section="projects-list-table"
        title={
          <FormattedMessageWithValidation id="projects_list_actions_header" />
        }
        alignment={TABLE_ALIGNMENT.RIGHT}
      />
    </DataTable>
  );
}

export default ProjectTable;

function ProjectText({ text }) {
  return <Body1>{text}</Body1>;
}

function ProjectOperationType({ operationType }) {
  return (
    <Body1>
      <FormattedMessageWithValidation
        id={operationTypeTranslationsTags.get(operationType)}
      />
    </Body1>
  );
}

function ProjectPrices({ prices }) {
  const { min, max } = prices ?? {};
  return (
    <PriceRange
      minPrice={min}
      maxPrice={max}
      emptyComponent={<EmptyCellContent />}
    />
  );
}
