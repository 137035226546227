import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import styled from 'styled-components';
import Thumbnail from 'design-system/components/Thumbnail';
import Image from 'design-system/components/Image/Image';
import SkeletonBone from 'design-system/components/SkeletonBone';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { FONT_FAMILY, typography } from 'design-system/styles/typography';
import { Subtitle2 } from 'design-system/components/Typography/presets/Subtitle2';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import LinkButton from '../../../../Overview/partials/LinkButton';
import { OPEN_PROPERTY_DETAIL } from '../../EnquiredProperties/events';
import { showEnquiredPropertiesListSelector } from '../../EnquiredProperties/PropertyDetail/selectors';

const Wrapper = styled.div`
  ${spacing.inset(SPACING_SIZE.S)}
  ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
  ${radius.regular(RADIUS_SIZE.LARGE)}
  ${typography.font(FONT_FAMILY.PRIMARY)}
  ${({ $clickable }) => $clickable && 'cursor: pointer;'}
  display: flex;
  flex-direction: row;

  ${Thumbnail}, ${Image.Skeleton} {
    ${Thumbnail.mods.size(102, 68)}
    ${spacing.inline(SPACING_SIZE.M)}
  }

  ${SkeletonBone} {
    display: block;
  }

  ${Subtitle2} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 230px;
    ${spacing.stack(SPACING_SIZE.XS)}
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const LinkButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

function ProjectSnippet({ project, openPropertyDetail = true, ...props }) {
  const showEnquiredPropertiesList = useSelector(
    showEnquiredPropertiesListSelector
  );
  if (!project) return null;
  return (
    <Wrapper
      $clickable={showEnquiredPropertiesList}
      onClick={() => {
        if (showEnquiredPropertiesList) {
          dispatch({
            id: OPEN_PROPERTY_DETAIL,
            payload: { propertyId: project.id },
          });
        }
      }}
      {...props}
    >
      <Thumbnail source={project.image} />
      <Info>
        <Subtitle2>{project.title}</Subtitle2>
        <Body2>{project.address}</Body2>
        {openPropertyDetail && showEnquiredPropertiesList && (
          <LinkButtonWrapper>
            <LinkButton />
          </LinkButtonWrapper>
        )}
      </Info>
    </Wrapper>
  );
}

function ProjectSkeleton(props) {
  return (
    <Wrapper {...props}>
      <Image.Skeleton width="102px" height="68px" />
      <div>
        <SkeletonBone $width="293px" $height="10px" />
        <SkeletonBone $width="100px" $height="10px" />
      </div>
    </Wrapper>
  );
}

ProjectSnippet.Skeleton = ProjectSkeleton;

export default ProjectSnippet;
