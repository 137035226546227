import { dispatch } from 'reffects';
import { useMount } from 'react-use';
import { useSelector } from 'reffects-store';
import Autocomplete from 'design-system/components/Autocomplete/Autocomplete';
import InputText from 'design-system/components/InputText';
import Icon from 'design-system/components/Icon';
import {
  BILLING_ENTITIES_REQUESTED,
  BILLING_ENTITY_SUGGESTER_TEXT_INTRODUCED,
} from './events';
import {
  displayableBillingEntitySelector,
  suggestedBillingEntitiesSelector,
} from './selectors';
import {
  BILLING_ENTITY_SELECTED,
  MULTI_ACCOUNT_CLIENT_INFORMATION_CLEARED,
} from '../../../events';

export default function BillingEntityAutocomplete({ required }) {
  useMount(() => dispatch(BILLING_ENTITIES_REQUESTED));

  const displayableValue = useSelector(displayableBillingEntitySelector);

  const suggestedBillingEntities = useSelector(
    suggestedBillingEntitiesSelector
  );

  return (
    <Autocomplete
      value={displayableValue}
      options={suggestedBillingEntities}
      onValueChange={(text) =>
        dispatch({
          id: BILLING_ENTITY_SUGGESTER_TEXT_INTRODUCED,
          payload: text,
        })
      }
      onOptionSelected={onSelectOption}
      optionToString={(option) => (option === undefined ? '' : option.name)}
      isLoading={false}
      minCharactersToOpen={2}
      renderInput={({ value, getInputProps, onClear }) => (
        <InputText
          required={required}
          label="Billing Entity"
          icon={<Icon glyph="cross" />}
          onIconClick={() => {
            onClear();
            dispatch(MULTI_ACCOUNT_CLIENT_INFORMATION_CLEARED);
          }}
          tip="Search billing entities by their name"
          {...getInputProps({ value, autoComplete: 'off' })}
        />
      )}
      renderOptions={({ options: publisherOptions, getOptionProps }) =>
        publisherOptions.map((option, index) => (
          <Autocomplete.Option
            key={option.id}
            title={option.name}
            subtitle={`${option.vatNumber} (${option.countryCode})`}
            icon={<Icon glyph="building" />}
            {...getOptionProps({
              index,
              option,
            })}
          />
        ))
      }
      emptyStateMessage="No billing entities match with your search"
      loadingStateMessage="Loading..."
    />
  );
}

function onSelectOption(billingEntity) {
  dispatch({
    id: BILLING_ENTITY_SELECTED,
    payload: billingEntity,
  });
}
