import styled from 'styled-components';
import { BORDER_SIZE } from 'design-system/styles/border';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { COLOR_PALETTE } from 'design-system/styles/color';

const Title = styled(Body2)`
  ${spacing.stack(SPACING_SIZE.XS)}
`;

const Value = styled(Body1)`
  ${spacing.stack(SPACING_SIZE.S, false)}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: ${BORDER_SIZE.BASE}px solid ${COLOR_PALETTE.NEUTRAL_A10};

  &:nth-last-child(1),
  &:nth-last-child(2):nth-child(odd) {
    border-bottom: initial;

    ${Value} {
      ${spacing.stack(SPACING_SIZE.NONE, false)}
    }
  }
`;

function Cell({ children, title }) {
  return (
    <Wrapper>
      <Title $color={COLOR_PALETTE.NEUTRAL_A60}>{title}</Title>
      <Value $color={COLOR_PALETTE.NEUTRAL_A80}>{children}</Value>
    </Wrapper>
  );
}

export default Cell;
