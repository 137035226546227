export const SUBSCRIPTION_PLANS_CPC_TABLE_ID = 'subscriptionPlansCpcTable';
export const SUBSCRIPTION_PLANS_PRICE_TABLE_ID = 'subscriptionPlansPriceTable';
export const SUBSCRIPTION_PLANS_TABLE_ID = 'subscriptionPlansTable';

export const ROWS_PER_PAGE = 10000;

export const HEADCELLS = [
  {
    id: 'listings',
    label: 'Listings',
    className: 'PublishersList__table-header',
  },
  {
    id: 1,
    label: 'B-1',
    className: 'PublishersList__table-header',
  },
  {
    id: 2,
    label: 'B-2',
    className: 'PublishersList__table-header',
  },
  {
    id: 3,
    label: 'B-3',
    className: 'PublishersList__table-header',
  },
  {
    id: 4,
    label: 'B-4',
    className: 'PublishersList__table-header',
  },
  {
    id: 5,
    label: 'B-5',
    className: 'PublishersList__table-header',
  },
  {
    id: 6,
    label: 'B-6',
    className: 'PublishersList__table-header',
  },
  {
    id: 7,
    label: 'B-7',
    className: 'PublishersList__table-header',
  },
  {
    id: 8,
    label: 'B-8',
    className: 'PublishersList__table-header',
  },
  {
    id: 9,
    label: 'B-9',
    className: 'PublishersList__table-header',
  },
  {
    id: 10,
    label: 'B-10',
    className: 'PublishersList__table-header',
  },
  {
    id: 11,
    label: 'B-11',
    className: 'PublishersList__table-header',
  },
  {
    id: 12,
    label: 'B-12',
    className: 'PublishersList__table-header',
  },
  {
    id: 13,
    label: 'B-13',
    className: 'PublishersList__table-header',
  },
  {
    id: 14,
    label: 'B-14',
    className: 'PublishersList__table-header',
  },
];

export const FILTERS = {
  COUNTRY: 'country',
  PUBLISHES_IN_TP: 'publishesInTP',
};
