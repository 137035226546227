import { COLOR_PALETTE } from 'design-system/styles/color';
import { dispatch } from 'reffects';
import styled from 'styled-components';
import Table from 'design-system/components/Table';
import Anchor from 'design-system/components/Anchor';
import Icon from 'design-system/components/Icon';
import { FlatButton } from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import { ASSIGN_DEAL_MODAL_OPENED } from '../Modals/AssignDealModal/events';
import { useColumn } from '../../../../../../partials/DataTable/hooks/useColumn';

const ButtonIcon = styled(Icon)`
  &&& {
    ${Icon.mods.color(COLOR_PALETTE.BLUEBERRY_BASE)}
  }
`;

export default function SalesPersonColumn(props) {
  const {
    value: { publisherId, salesPersonEmail, dealId },
  } = useColumn(props);
  return (
    <Table.Cell maxWidth={300}>
      {salesPersonEmail && (
        <Anchor
          href={`mailto:${salesPersonEmail}`}
          target="_blank"
          rel="noopener noreferrer"
          $ellipse
        >
          {salesPersonEmail}
        </Anchor>
      )}
      <FlatButton
        icon={<ButtonIcon glyph={dealId ? 'pencil' : 'addOutline'} />}
        $size={BUTTON_SIZE.BASE}
        onClick={() =>
          dispatch({
            id: ASSIGN_DEAL_MODAL_OPENED,
            payload: { publisherId, dealId },
          })
        }
      />
    </Table.Cell>
  );
}
