import { effects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';

import { http } from 'reffects-batteries';
import { environment } from '../../../../../../../coeffects/environment';
import { CLOSE_DIALOG, OPEN_DIALOG } from '../../../../../../../events/dialogs';

export const PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_OPENED =
  'PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_OPENED';
export const PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_OPTIONS_LOADED =
  'PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_OPTIONS_LOADED';
export const PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_OPTIONS_FAILED =
  'PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_OPTIONS_FAILED';
export const PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_PAYMENT_METHOD_CHANGED =
  'PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_PAYMENT_METHOD_CHANGED';
export const PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_SUBMITTED =
  'PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_SUBMITTED';
export const PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_SAVED =
  'PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_SAVED';
export const PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_FAILED =
  'PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_FAILED';

export const PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL =
  'PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL';

registerEventHandler(
  PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_OPENED,
  (
    { environment: { apiUrl } },
    { publisherId, currentPaymentMethod, isMultiAccount }
  ) =>
    http.get({
      url: `${apiUrl}/backoffice/publishers/${publisherId}/available-payment-methods`,
      successEvent: {
        id: PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_OPTIONS_LOADED,
        payload: {
          publisherId,
          currentPaymentMethod,
          isMultiAccount,
        },
      },
      errorEvent: PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_OPTIONS_FAILED,
    }),
  [environment()]
);

registerEventHandler(
  PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_OPTIONS_LOADED,
  (_, [{ data }, { publisherId, currentPaymentMethod, isMultiAccount }]) => ({
    ...effects.dispatch({
      id: OPEN_DIALOG,
      payload: { id: PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL },
    }),
    ...state.merge({
      'change-payment-method-modal': {
        publisherId,
        publisherIsMultiAccount: isMultiAccount,
        paymentMethod: currentPaymentMethod,
        availablePaymentMethods: data,
        loading: null,
        errors: null,
      },
    }),
  })
);

registerEventHandler(
  PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_OPTIONS_FAILED,
  () => ({})
);

registerEventHandler(
  PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_PAYMENT_METHOD_CHANGED,
  (_, { paymentMethod }) => ({
    ...state.set({
      'change-payment-method-modal.paymentMethod': paymentMethod,
    }),
  })
);

registerEventHandler(
  PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_SUBMITTED,
  (
    { environment: { apiUrl }, state: { publisherId, newPaymentMethod } },
    payload
  ) => ({
    ...state.set({
      'change-payment-method-modal.errors': null,
      'change-payment-method-modal.loading': true,
    }),
    ...http.put({
      url: `${apiUrl}/backoffice/publishers/${publisherId}/paymentMethod`,
      body: { paymentMethod: newPaymentMethod },
      successEvent: {
        id: PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_SAVED,
        payload,
      },
      errorEvent: PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_FAILED,
    }),
  }),
  [
    environment(),
    state.get({
      publisherId: 'change-payment-method-modal.publisherId',
      newPaymentMethod: 'change-payment-method-modal.paymentMethod',
    }),
  ]
);

registerEventHandler(
  PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_SAVED,
  (_, [__, { onSuccessEvent } = {}]) => ({
    ...state.set({
      'change-payment-method-modal.errors': null,
      'change-payment-method-modal.loading': false,
    }),
    ...effects.dispatchMany([onSuccessEvent, CLOSE_DIALOG]),
  })
);

registerEventHandler(PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_FAILED, () => ({
  ...state.set({
    'change-payment-method-modal.errors': true,
    'change-payment-method-modal.loading': false,
  }),
}));
