import { Children, useState } from 'react';
import { useMount } from 'react-use';
import styled from 'styled-components';
import Tab from './Tab';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TabsSelector = styled.div`
  display: flex;
`;

const CurrentTab = styled.div``;

function Tabs({ children }) {
  const [activeTab, setActiveTab] = useState();
  const tabs = Children.toArray(children).filter(Boolean);

  function setFirstTabAsActiveByDefault([
    {
      props: { label },
    },
  ]) {
    setActiveTab(label);
  }

  useMount(() => {
    setFirstTabAsActiveByDefault(tabs);

    tabs.forEach(({ props: { label, isInitiallyActive } }) => {
      if (isInitiallyActive) {
        setActiveTab(label);
      }
    });
  });

  return (
    <Wrapper>
      <TabsSelector>
        {tabs.map(({ props: { label } }) => (
          <Tab
            key={label}
            label={label}
            isActive={activeTab === label}
            onClick={() => setActiveTab(label)}
          />
        ))}
      </TabsSelector>
      <CurrentTab>
        {tabs.map(({ props: { label, children: tabsContent } }) =>
          label !== activeTab ? undefined : tabsContent
        )}
      </CurrentTab>
    </Wrapper>
  );
}

export default Tabs;
