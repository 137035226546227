export function selectedDeleteOrUnpublishReasonSelector(state) {
  return state.deleteOrUnpublish?.reason ?? undefined;
}

export function otherTextDeleteOrUnpublishReasonSelector(state) {
  if (!isReasonOther(state)) {
    return undefined;
  }
  return state.deleteOrUnpublish?.reasonText ?? '';
}

function isReasonOther(state) {
  return selectedDeleteOrUnpublishReasonSelector(state) === 'other';
}
