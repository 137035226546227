import { useSelector } from 'reffects-store';
import { ActionsMenu } from '../../../partials/DataTable';
import { BulkAction } from './BulkAction';
import { UnPublishBulkAction } from './UnPublishBulkAction';
import { UnBoostBulkAction } from './UnBoostBulkAction';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { DeleteBulkAction } from './DeleteBulkAction';
import { BoostBulkAction } from './BoostBulkAction';
import { isPublisherFromMxSelector } from '../../../selectors/publisher';

export function Menu() {
  const isMXPublisher = useSelector(isPublisherFromMxSelector);
  return (
    <ActionsMenu>
      <BulkAction action="publish" iconGlyph="eye">
        <FormattedMessageWithValidation id="newprop_publish_cta" />
      </BulkAction>
      <UnPublishBulkAction />
      {!isMXPublisher && <BoostBulkAction />}
      {!isMXPublisher && <UnBoostBulkAction />}
      <DeleteBulkAction />
    </ActionsMenu>
  );
}
