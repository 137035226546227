import { useSelector } from 'reffects-store';
import TabLink from '../TabLink';
import FormattedMessageWithValidation from '../../FormattedMessageWithValidation/FormattedMessageWithValidation';
import { unreadLeadsCountSelector } from '../../../selectors/leads';

export function InboxTabLink() {
  const unreadCount = useSelector(unreadLeadsCountSelector);
  return (
    <TabLink
      to="/enquiries"
      deep
      eventName="view-enquiries-page"
      elementName="enquiries-link"
      sectionName="side-menu-top-enquiries-link"
      unreadNotifications={unreadCount}
    >
      <FormattedMessageWithValidation id="menu_inbox" />
    </TabLink>
  );
}
