import styled from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { useSelector } from 'reffects-store';
import { useEffect } from 'react';
import { dispatch } from 'reffects';
import { divider, DIVIDER_SIZE } from 'design-system/styles/divider';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  enquiriesReceivedSelector,
  leadsByStatusLoadingSelector,
  leadsByStatusSelector,
  leadsReceivedSelector,
} from './selectors';
import { useFilters } from '../../../../partials/DataTable/hooks/useFilter';
import { LOAD_LEADS_STATS } from './events';
import Counter from './Counter';
import { useDataTable } from '../../../../partials/DataTable/hooks/useDataTable';

const TotalContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  min-width: 360px;
  ${divider.right(DIVIDER_SIZE.THIN)}
`;

const StatusContainer = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 16px;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(6, 1fr);

  ${Counter}:not(:last-of-type) {
    &:after {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateX(75%);
      content: '';
      width: 32px;
      height: 1px;
      ${color.background(COLOR_PALETTE.NEUTRAL_A20)}
    }
  }
`;

const Wrapper = styled.div`
  ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
  ${spacing.inset(SPACING_SIZE.M)}
  ${spacing.stack(SPACING_SIZE.L, false)}
  ${radius.regular(RADIUS_SIZE.LARGE)}
  display: flex;
  flex-direction: row;
`;

export default function LeadsStats(props) {
  const { filters } = useFilters();

  const leadsReceived = useSelector(leadsReceivedSelector);
  const enquiriesReceived = useSelector(enquiriesReceivedSelector);
  const { newLead, contacted, visit, proposal, won, lost } = useSelector(
    leadsByStatusSelector
  );
  const { isLoading: isDataTableLoading } = useDataTable();
  const isLoading = useSelector(leadsByStatusLoadingSelector);

  useEffect(() => {
    if (!isDataTableLoading) {
      dispatch({
        id: LOAD_LEADS_STATS,
        payload: { filters },
      });
    }
  }, [filters, isDataTableLoading]);

  return (
    <Wrapper {...props}>
      <TotalContainer>
        <Counter
          count={leadsReceived}
          title={<FormattedMessageWithValidation id="enqpage_stats_leads" />}
          loading={isLoading}
        />
        <Counter
          count={enquiriesReceived}
          title={
            <FormattedMessageWithValidation id="enqpage_stats_enquiries" />
          }
          loading={isLoading}
        />
      </TotalContainer>
      <StatusContainer>
        <Counter
          count={newLead}
          title={<FormattedMessageWithValidation id="enqpage_table_status2" />}
          loading={isLoading}
          $alignment="center"
        />
        <Counter
          count={contacted}
          title={<FormattedMessageWithValidation id="enqpage_table_status1" />}
          loading={isLoading}
          $alignment="center"
        />
        <Counter
          count={visit}
          title={<FormattedMessageWithValidation id="status_enquiries_visit" />}
          loading={isLoading}
          $alignment="center"
        />
        <Counter
          count={proposal}
          title={
            <FormattedMessageWithValidation id="status_enquiries_proposal" />
          }
          loading={isLoading}
          $alignment="center"
        />
        <Counter
          count={won}
          title={<FormattedMessageWithValidation id="status_enquiries_won" />}
          loading={isLoading}
          $alignment="center"
        />
        <Counter
          count={lost}
          title={<FormattedMessageWithValidation id="status_enquiries_lost" />}
          loading={isLoading}
          $alignment="center"
        />
      </StatusContainer>
    </Wrapper>
  );
}
