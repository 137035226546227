import styled from 'styled-components';
import { dispatch } from 'reffects';
import BackLink from 'design-system/components/BackLink';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import ProjectSites from './ProjectSites';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { GO_BACK } from '../../../../events/routing';
import { PROJECTS } from '../../../../constants/routes';
import ImageGallery from './ImageGallery';

const Wrapper = styled.div`
  > * {
    ${spacing.stack(SPACING_SIZE.L, false)}
  }

  ${BackLink} {
    ${spacing.stack(SPACING_SIZE.S)}
  }

  ${Heading1} {
    display: block;
  }
`;

function Header({ project, sites, ...props }) {
  return (
    <Wrapper {...props}>
      <BackLink onClick={goBack}>
        <FormattedMessageWithValidation id="project_detail_back" />
      </BackLink>
      <Heading1>{project.name}</Heading1>
      <ImageGallery images={project.images} />
      <ProjectSites project={project} sites={sites} />
    </Wrapper>
  );
}

function goBack() {
  dispatch({ id: GO_BACK, payload: { fallback: PROJECTS } });
}

export default styled(Header)``;
