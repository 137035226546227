import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { environment } from '../../../coeffects/environment';

export const AGENCY_PAGE_MOUNTED = 'AGENCY_PAGE_MOUNTED';
export const AGENCY_PAGE_TIMELINE_LOADED = 'AGENCY_PAGE_TIMELINE_LOADED';
export const AGENCY_PAGE_TIMELINE_LOAD_FAILED =
  'AGENCY_PAGE_TIMELINE_LOAD_FAILED';

registerEventHandler(
  AGENCY_PAGE_MOUNTED,
  ({ environment: { apiUrl } }, { publisherId }) =>
    http.get({
      url: `${apiUrl}/dev-tools/agency/timeline/${publisherId}.json`,
      successEvent: AGENCY_PAGE_TIMELINE_LOADED,
      errorEvent: AGENCY_PAGE_TIMELINE_LOAD_FAILED,
    }),
  [environment()]
);

registerEventHandler(AGENCY_PAGE_TIMELINE_LOADED, (_, [{ data }]) =>
  state.set({
    'admin:agency.timeline': data,
  })
);
registerEventHandler(AGENCY_PAGE_TIMELINE_LOAD_FAILED, () =>
  state.set({
    'admin:agency.timeline': null,
  })
);
