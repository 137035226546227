import { css } from 'styled-components';
import { typography as typographyTokens } from '../tokens/typography';

export const FONT_FAMILY = {
  PRIMARY: typographyTokens.font.primary.family,
};

export const FONT_WEIGHT = {
  LIGHT: typographyTokens.weight.light,
  NORMAL: typographyTokens.weight.normal,
  MEDIUM: typographyTokens.weight.medium,
  SEMIBOLD: typographyTokens.weight.semibold,
  BOLD: typographyTokens.weight.bold,
};

export const FONT_SIZE = {
  XS: typographyTokens.size.xs,
  S: typographyTokens.size.s,
  M: typographyTokens.size.m,
  L: typographyTokens.size.l,
  XL: typographyTokens.size.xl,
};

const ellipsisStyles = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: inherit;
  display: inline-block;
`;

const ellipsisLineClampStyles = (lineClamp) => css`
  display: -webkit-box;
  -webkit-line-clamp: ${lineClamp};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const typography = {
  font: (fontFamily) => css`
    font-family: ${fontFamily};
  `,
  weight: (weight) => css`
    font-weight: ${weight};
  `,
  size: (size) => css`
    font-size: ${size}px;
  `,
  ellipsis: ({ lineClamp } = {}) => css`
    ${lineClamp ? ellipsisLineClampStyles(lineClamp) : ellipsisStyles}
  `,
  lineHeight: (size) => css`
    line-height: ${size}px;
  `,
  noWrap: () => css`
    white-space: nowrap;
  `,
  wrap: () => css`
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  `,
};
