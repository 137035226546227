export const SUBSCRIPTION_CHANGE_TYPE = {
  UPSELL_CURRENT_CYCLE: 'upsell-current-cycle',
  UPSELL_NEXT_CYCLE: 'upsell-upgrade',
  UPSELL_NEW_CYCLE: 'upsell-new-cycle',
  RENEW: 'renew',
  NEW_CONTRACT: 'new-contract',
  DOWNGRADE_NEXT_CYCLE: 'downgrade-next-cycle',
};

export const CHANGE_REQUEST_STATUS = {
  PENDING: 'pending',
  PAID: 'paid',
  EXPIRED: 'expired',
  ACTIVATED: 'activated',
};
