import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import { collectionNameParameter, collectionSelector } from './collection';

const filterNameParameter = (_, { filterName }) => filterName;

const filterCacheKey = createSelector(
  collectionNameParameter,
  filterNameParameter,
  (collectionName, filterName) => `${collectionName}_${filterName}`
);

export const filtersSelector = createSelector(
  collectionSelector,
  (collection) => {
    if (!collection?.filters) return {};

    const filterEntriesWithoutNull = Object.entries(collection.filters).filter(
      ([_, value]) => value != null
    );

    return Object.fromEntries(filterEntriesWithoutNull);
  }
);

export const filterByNameSelector = createCachedSelector(
  filtersSelector,
  filterNameParameter,
  (filters, filterName) => (filterName in filters ? filters[filterName] : null)
)(filterCacheKey);
