import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import styled from 'styled-components';
import { dispatch } from 'reffects';
import { ROWS_PER_PAGE } from 'design-system/components/Table';
import Layout from 'design-system/components/Layout';
import { Navigation } from '../../partials';
import { DataTableProvider } from '../../partials/DataTable';
import { Filters } from './filters/Filters';
import { PropertiesTable } from './PropertiesTable';
import { createPropertiesTableAdapter } from './propertiesTableAdapter';
import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import ExportOptions from './menu/ExportOptions/ExportOptions';
import { useDataTable } from '../../partials/DataTable/hooks/useDataTable';
import EmptyState from './EmptyState';
import BulkBoostNotAllowedDialog from './menu/BulkBoostNotAllowedDialog';
import { useContextReadyEffect } from '../../partials/DataTable/hooks/useContextReadyEffect';
import { DATA_TABLE_INIT } from '../../partials/DataTable/events/init';
import AdModals from '../../partials/modals/ad/AdModals';
import ModalUploadCSV from '../../Backoffice/pages/UploadCSV/partials/ModalUploadCSV';

const propertiesTableAdapter = createPropertiesTableAdapter();

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${spacing.stack(SPACING_SIZE.M)}
`;

const Actions = styled.div`
  & > * {
    ${spacing.inline(SPACING_SIZE.S)}
  }
`;

function Content() {
  const { isLoading, isEmpty } = useDataTable();
  useContextReadyEffect(
    (context) => {
      if (isEmpty) {
        dispatch({
          id: DATA_TABLE_INIT,
          payload: context,
        });
      }
    },
    [isEmpty]
  );

  if (isEmpty && !isLoading) {
    return (
      <>
        <Layout.Padded>
          <Header>
            <Heading1>
              <FormattedMessageWithValidation id="propspage_header" />
            </Heading1>
          </Header>
        </Layout.Padded>
        <EmptyState />
      </>
    );
  }

  return (
    <Layout.Padded>
      <Header>
        <Heading1>
          <FormattedMessageWithValidation id="propspage_header" />
        </Heading1>
        <Actions>
          <ExportOptions />
        </Actions>
      </Header>
      <Filters />
      <PropertiesTable />
      <BulkBoostNotAllowedDialog />
    </Layout.Padded>
  );
}

export function Properties() {
  const context = {
    collectionName: 'properties',
    defaultSorting: {
      field: 'date',
      direction: 'desc',
    },
    initialItemsPerPage: ROWS_PER_PAGE.TWENTY_FIVE,
    adapter: propertiesTableAdapter,
  };

  return (
    <>
      <Layout>
        <Layout.Header>
          <Navigation />
        </Layout.Header>
        <DataTableProvider {...context}>
          <Layout.Content>
            <Content />
            <AdModals />
          </Layout.Content>
        </DataTableProvider>
      </Layout>
      <ModalUploadCSV />
    </>
  );
}
