import styled from 'styled-components';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import InboundBubble from './InboundBubble';
import PropertySnippet from './PropertySnippet';
import ProjectSnippet from './ProjectSnippet';

const Message = styled(Body1)`
  ${spacing.stack(SPACING_SIZE.XS, false)}
  ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
  ${typography.weight(FONT_WEIGHT.LIGHT)}
`;

function PhoneLeadBubble({ ad, project, ...rest }) {
  return (
    <InboundBubble {...rest}>
      <Message>
        <FormattedMessageWithValidation id="enquiry_detail_activity_lead_type_phone" />
      </Message>
      {ad ? (
        <PropertySnippet property={ad} project={project} />
      ) : (
        <ProjectSnippet project={project} />
      )}
    </InboundBubble>
  );
}

export default PhoneLeadBubble;
