import { color, COLOR_PALETTE } from 'design-system/styles/color';
import styled from 'styled-components';
import Table from 'design-system/components/Table';
import { radius } from 'design-system/styles/radius';
import { useColumn } from '../../../../partials/DataTable/hooks/useColumn';

const Wrapper = styled(Table.Cell)`
  padding: 0;
`;

const UnreadNotification = styled.div`
  width: 10px;
  height: 10px;
  ${color.background(COLOR_PALETTE.PRIMARY_BASE)}
  ${radius.pill()}
`;

export function UnreadColumn(props) {
  const {
    value: { isUnread },
  } = useColumn(props);
  return <Wrapper>{isUnread && <UnreadNotification />}</Wrapper>;
}
