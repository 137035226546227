import styled, { css, keyframes } from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';

const multiplier = () => {
  const max = 1 + 0.2;
  const min = 1 - 0.2;

  return Math.random() * (max - min) + min;
};

const fadeAnimation = keyframes`
  from {
    ${color.background(COLOR_PALETTE.NEUTRAL_A05)}
  }
  to {
    ${color.background(COLOR_PALETTE.NEUTRAL_A10)}
  }
`;

const modFadeAnimation = () => css`
  animation-name: ${fadeAnimation};
  animation-duration: 0.9s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
`;

export default styled.span.attrs((props) => ({
  style: {
    height: props.$height,
    width: props.randomWidth
      ? `calc(${props.$width} * ${multiplier()})`
      : props.$width,
  },
}))`
  display: inline-block;

  ${color.background(COLOR_PALETTE.NEUTRAL_A05)}
  ${radius.regular(RADIUS_SIZE.SMALL)}
  
  ${() => modFadeAnimation()}
`;
