import FormattedMessageWithValidation from './FormattedMessageWithValidation/FormattedMessageWithValidation';

const operationTypeTranslations = {
  rent: 'enquiries_list_type_of_lead_rent',
  sell: 'enquiries_list_type_of_lead_sale',
  unknown: 'enquiries_unknokwn_value',
};

export default function OperationType({ operationType }) {
  return (
    <FormattedMessageWithValidation
      id={operationTypeTranslations[operationType]}
    />
  );
}
