import { createContext, useContext, useEffect, useState } from 'react';
import Group from 'design-system/components/Group';

const noop = () => {};

const ensureArray = (array) => {
  if (!array) return [];
  return Array.isArray(array) ? array : [array];
};

export const FieldGroupContext = createContext({
  groups: [],
  registerGroup: noop,
  registerGroupError: noop,
});

export function FieldGroupProvider({ children }) {
  const [groups, setGroups] = useState([]);

  const unregisterGroup = (id) => {
    setGroups((value) => value.filter((group) => group.id === id));
  };

  const registerGroup = ({ id, title, order }) => {
    function insertInOrder(array, value) {
      if (array.length === 0 || array[0].order > value.order) {
        return [value, ...array];
      }
      return [array[0], ...insertInOrder(array.slice(1), value)];
    }
    setGroups((value) =>
      insertInOrder(value, {
        id,
        title,
        order,
        errorsByFieldName: {},
      })
    );

    return () => unregisterGroup(id);
  };

  const setGroupFieldErrors = (id, { name, errors }) =>
    setGroups((currentGroups) => {
      const groupIndex = currentGroups.findIndex((group) => group.id === id);

      const group = currentGroups[groupIndex];

      return [
        ...currentGroups.slice(0, groupIndex),
        {
          ...group,
          errorsByFieldName: {
            ...group.errorsByFieldName,
            [name]: errors,
          },
        },
        ...currentGroups.slice(groupIndex + 1),
      ];
    });

  const unregisterGroupFieldErrors = (id, { name }) =>
    setGroupFieldErrors(id, { name, errors: [] });

  const registerGroupFieldErrors = (id, field) => {
    setGroupFieldErrors(id, field);

    return () => unregisterGroupFieldErrors(id, field);
  };

  return (
    <FieldGroupContext.Provider
      value={{
        groups,
        registerGroup,
        registerGroupFieldErrors,
      }}
    >
      {children}
    </FieldGroupContext.Provider>
  );
}

export const GroupContext = createContext({
  registerFieldErrors: noop,
});

export function useRegisterFieldErrors({ name, errors }) {
  const { registerFieldErrors } = useContext(GroupContext);

  useEffect(() => {
    const errorsArray = ensureArray(errors);
    if (errorsArray.length) {
      return registerFieldErrors({ name, errors: errorsArray });
    }
    return undefined;
  }, [name, errors]);
}

export function useGroupIdList() {
  const { groups } = useContext(FieldGroupContext);

  return groups.map((group) => group.id);
}

export function useGroup(id) {
  const { groups } = useContext(FieldGroupContext);

  const group = groups.find((g) => g.id === id);

  return {
    ...group,
    errors: errorsFrom(group),
  };
}

function errorsFrom({ errorsByFieldName }) {
  return Object.values(errorsByFieldName).reduce(
    (acc, errors) => acc + errors.length,
    0
  );
}

export function FieldGroup(groupProps) {
  const { id, title, order, children } = groupProps;

  const { registerGroup, registerGroupFieldErrors } =
    useContext(FieldGroupContext);

  useEffect(() => registerGroup({ id, title, order }), []);

  const registerFieldErrors = (field) => registerGroupFieldErrors(id, field);

  return (
    <Group {...groupProps}>
      <GroupContext.Provider
        value={{
          registerFieldErrors,
        }}
      >
        {children}
      </GroupContext.Provider>
    </Group>
  );
}
