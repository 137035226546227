import styled from 'styled-components';
import { divider, DIVIDER_SIZE } from 'design-system/styles/divider';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle2 } from 'design-system/components/Typography/presets/Subtitle2';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;

  ${spacing.stack(SPACING_SIZE.M, false)}
  ${divider.bottom(DIVIDER_SIZE.THIN)}

  :last-of-type {
    ${divider.bottom(DIVIDER_SIZE.NONE)}
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${spacing.stack(SPACING_SIZE.M, false)}
`;

const NoButtonHeight = styled.div`
  height: 1px;

  > * {
    transform: translateY(-50%);
  }
`;

function Section({ title, children, cta }) {
  return (
    <Wrapper>
      <Header>
        <Subtitle2>{title}</Subtitle2>
        {cta && <NoButtonHeight>{cta}</NoButtonHeight>}
      </Header>
      {children}
    </Wrapper>
  );
}

export default Section;
