import styled from 'styled-components';
import Thumbnail from 'design-system/components/Thumbnail';
import Image from 'design-system/components/Image/Image';
import SkeletonBone from 'design-system/components/SkeletonBone';
import Counter, { COUNTER_VARIANT } from 'design-system/components/Counter';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import Typography from 'design-system/components/Typography/Typography';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;

  ${Thumbnail} {
    ${color.background(COLOR_PALETTE.NEUTRAL_A05)}
  }

  ${Thumbnail}, ${Image.Skeleton} {
    ${Thumbnail.mods.size(57, 38)}
    ${spacing.inline(SPACING_SIZE.S)}
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;

  ${SkeletonBone} {
    ${spacing.stack(SPACING_SIZE.XXS)}
  }
`;

const InfoText = styled(Body2)`
  ${Typography.mods.size(11.5)}
`;

const TotalPropertiesCounter = styled(Counter)`
  ${spacing.stack(SPACING_SIZE.XS)}
  ${Counter.mods.variant(COUNTER_VARIANT.SECONDARY)}
`;

function AccumulatedPropertiesSnippet({ total }) {
  return (
    <Wrapper>
      <Thumbnail />
      <Info>
        <TotalPropertiesCounter>+{total}</TotalPropertiesCounter>
        <InfoText $weight={FONT_WEIGHT.NORMAL}>
          <FormattedMessageWithValidation id="enqpage_table_more_properties" />
        </InfoText>
      </Info>
    </Wrapper>
  );
}

export default styled(AccumulatedPropertiesSnippet)``;
