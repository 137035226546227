import { useSelector } from 'reffects-store';
import { useCallback } from 'react';
import { dispatch } from 'reffects';

import { useDatatableContext } from './useDatatableContext';

import { isItemSelectedSelector } from '../selectors/selection';
import { DATA_TABLE_TOGGLE_ITEM_SELECTION } from '../events/selection';

export function useRowSelection({ itemId }) {
  const { collectionName } = useDatatableContext();

  const isSelected = useSelector((state) =>
    isItemSelectedSelector(state, { collectionName, itemId })
  );

  const toggleSelection = useCallback(() => {
    dispatch({
      id: DATA_TABLE_TOGGLE_ITEM_SELECTION,
      payload: {
        collectionName,
        itemId,
      },
    });
  }, [collectionName, itemId]);

  return {
    isSelected,
    toggleSelection,
  };
}
