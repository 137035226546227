import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { Overline } from 'design-system/components/Typography/presets/Overline';
import { OutlineButton } from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';
import {
  BUTTON_ICON_POSITION,
  BUTTON_SIZE,
} from 'design-system/components/Button';
import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import FormattedMessageWithValidation from '../../../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { leadSelector } from '../../../../../selectors';
import { OPEN_LINK_IN_A_NEW_TAB } from '../../../../../../../App/events';

const Box = styled.div`
  ${spacing.inset(SPACING_SIZE.M)}
  display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.M)};
  background-color: ${COLOR_PALETTE.NEUTRAL_A03};
  border-radius: ${spacing.value(SPACING_SIZE.S)};
  word-wrap: break-word;
`;

const BoxTitle = styled(Overline)``;

const BoxButtons = styled.div`
  display: flex;
  gap: ${spacing.value(SPACING_SIZE.S)};
`;

const BoxButton = styled(OutlineButton)`
  background-color: unset;
  word-break: break-word;
`;

export default function VisitInterest({ question, answer }) {
  const { phones, whatsAppLink } = useSelector(leadSelector);

  if (!answer) {
    return null;
  }

  return (
    <Box>
      <BoxTitle>
        <FormattedMessageWithValidation id={question} />
      </BoxTitle>
      <BoxButtons>
        {whatsAppLink && (
          <BoxButton
            icon={<Icon glyph="whatsapp" />}
            $size={BUTTON_SIZE.SMALL}
            $iconPosition={BUTTON_ICON_POSITION.START}
            onClick={() =>
              dispatch({
                id: OPEN_LINK_IN_A_NEW_TAB,
                payload: {
                  url: whatsAppLink,
                  target: '_self',
                },
              })
            }
          >
            <FormattedMessageWithValidation id="lead_qualification_visit_interest_send_whatsapp" />
          </BoxButton>
        )}
        {phones && phones.length > 0 && (
          <BoxButton
            icon={<Icon glyph="phone" />}
            $size={BUTTON_SIZE.SMALL}
            $iconPosition={BUTTON_ICON_POSITION.START}
            onClick={() =>
              dispatch({
                id: OPEN_LINK_IN_A_NEW_TAB,
                payload: {
                  url: `tel:${phones[0].number}`,
                  target: '_self',
                },
              })
            }
          >
            <FormattedMessageWithValidation id="lead_qualification_visit_interest_call" />
          </BoxButton>
        )}
      </BoxButtons>
    </Box>
  );
}
