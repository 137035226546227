import { registerEffectHandler } from 'reffects';

const RENDER_APP = 'renderApp';

export default function registerRenderAppEffects(render) {
  registerEffectHandler(RENDER_APP, render);
}

export function renderApp() {
  return {
    [RENDER_APP]: {},
  };
}
