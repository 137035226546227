import { projectSelector } from '../../selectors';
import { countryConfigSelector } from '../../../../selectors/config';

export function projectLinksSelector(state) {
  const project = projectSelector(state);
  if (!project) {
    return [];
  }

  const sites = countryConfigSelector(state).sitesToPublish ?? [];

  return sites
    .filter(({ projectDetailPage }) => projectDetailPage)
    .map(({ site, projectDetailPage }) => ({
      site,
      link: `${projectDetailPage}${project.id}`,
    }));
}
