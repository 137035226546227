import { state } from 'reffects-store';
import { effects, registerEventHandler } from 'reffects';
import { location } from '../../../../../coeffects/location';
import { navigateTo } from '../../../../../effects/routing';
import { composePathWithSearchParams } from '../../../../../utils/url';
import updateTable from '../../../../Tables/updateTable';
import {
  UNITS_SEARCH_TYPE_URL_PARAM,
  UNITS_SEARCH_URL_PARAM,
} from '../constants';
import { buildTablePropertiesSearchSuggestionTypeFilter } from '../utils';

export const UNITS_SUGGESTER_SEARCH_SELECTED =
  'UNITS_SUGGESTER_SEARCH_SELECTED';
export const UNITS_SUGGESTER_CLEAR = 'UNITS_SUGGESTER_CLEAR';

registerEventHandler(
  UNITS_SUGGESTER_SEARCH_SELECTED,
  (
    {
      state: {
        unitsById,
        filters: { search, ...filters },
        sorting,
        pagination,
      },
      location: { href },
    },
    { type, text }
  ) => {
    const newFilters = {
      ...filters,
      ...buildTablePropertiesSearchSuggestionTypeFilter(type, text),
    };

    const { rows, totalRows } = updateTable(
      unitsById,
      newFilters,
      sorting,
      pagination
    );

    return {
      ...state.set({
        'unitsTable.filters.search': newFilters.search,
        'units:suggesterFilterValue': text,
        'unitsTable.rows': rows,
        'unitsTable.pagination.totalRows': totalRows,
      }),
      ...navigateTo(
        composePathWithSearchParams(href, {
          [UNITS_SEARCH_URL_PARAM]: text,
          [UNITS_SEARCH_TYPE_URL_PARAM]: type,
        })
      ),
    };
  },
  [
    state.get({
      unitsById: 'unitsById',
      filters: 'unitsTable.filters',
      sorting: 'unitsTable.sorting',
      pagination: 'unitsTable.pagination',
    }),
    location(),
  ]
);

registerEventHandler(UNITS_SUGGESTER_CLEAR, () =>
  effects.dispatch({
    id: UNITS_SUGGESTER_SEARCH_SELECTED,
    payload: { text: undefined },
  })
);
