import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import InputSelect from 'design-system/components/InputSelect';
import { SUBSCRIPTION_CHANGE_TYPE } from '../../../../../../constants/subscription';
import { SUBSCRIPTION_CHANGE_REQUEST_TYPE_CHANGED } from './events';
import { hasCurrentSubscriptionAndPendingToActivateNextCycleSelector } from './selectors';

export function RequestTypeSelect({
  subscriptionChangeType,
  canRequestUpsellNewCycle,
  selectedPublisherIsFree,
  disabled,
}) {
  const canRequestOnlyCurrentCycle = useSelector(
    hasCurrentSubscriptionAndPendingToActivateNextCycleSelector
  );

  const options = retrieveOptions({
    canRequestUpsellNewCycle,
    selectedPublisherIsFree,
    canRequestOnlyCurrentCycle,
  });
  return (
    <InputSelect
      label="Change type"
      value={subscriptionChangeType ?? ''}
      disabled={disabled}
      choices={options}
      onSelect={({ value }) => {
        onChangeSubscriptionChangeType(value);
      }}
      required
    />
  );
}

function retrieveOptions({
  selectedPublisherIsFree,
  canRequestUpsellNewCycle,
  canRequestOnlyCurrentCycle,
}) {
  if (selectedPublisherIsFree) {
    return [
      {
        label: 'New contract',
        value: SUBSCRIPTION_CHANGE_TYPE.NEW_CONTRACT,
      },
    ];
  }

  if (canRequestOnlyCurrentCycle) {
    return [
      {
        label: 'Current subscription',
        value: SUBSCRIPTION_CHANGE_TYPE.UPSELL_CURRENT_CYCLE,
      },
    ];
  }

  if (!canRequestUpsellNewCycle) {
    return [
      {
        label: 'Current subscription',
        value: SUBSCRIPTION_CHANGE_TYPE.UPSELL_CURRENT_CYCLE,
      },
      {
        label: 'Upcoming subscription',
        value: SUBSCRIPTION_CHANGE_TYPE.UPSELL_NEXT_CYCLE,
      },
    ];
  }
  return [
    {
      label: 'Current subscription',
      value: SUBSCRIPTION_CHANGE_TYPE.UPSELL_CURRENT_CYCLE,
    },
    {
      label: 'Upcoming subscription',
      value: SUBSCRIPTION_CHANGE_TYPE.UPSELL_NEXT_CYCLE,
    },
    {
      label: 'Upsell new cycle',
      value: SUBSCRIPTION_CHANGE_TYPE.UPSELL_NEW_CYCLE,
    },
  ];
}

function onChangeSubscriptionChangeType(type) {
  dispatch({
    id: SUBSCRIPTION_CHANGE_REQUEST_TYPE_CHANGED,
    payload: { type },
  });
}
