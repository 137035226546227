import { getLocaleByCountry } from './localesMapping';
import { orderAlphabetically, publishersSelector } from '../selectors';
import { publisherCountrySelector } from '../../../selectors/publisher';
import {
  oneOf,
  userIsFinanceSelector,
  userIsSalesSelector,
  userIsSuperadminSelector,
  userIsSupportSelector,
  userManagedCountriesSelector,
} from '../../../selectors/user';
import { CUSTOMER_ROLES } from '../../../constants/user';
import { availableCountriesForBackofficeUserSelector } from '../../selectors/countries';

export function selectedUserSelector(state) {
  return state['admin:users:selectedUser'];
}

export function availablePublishersSelector(
  state,
  userSelector = selectedUserSelector
) {
  const user = userSelector(state);
  if (!user) {
    return [];
  }
  return orderedPublishersSelector(state).filter(
    ({ countryCode }) => countryCode === user.country
  );
}

function orderedPublishersSelector(state) {
  const publishers = publishersSelector(state);
  return publishers.sort(orderAlphabetically);
}

export function availableLocalesSelector(
  state,
  userSelector = selectedUserSelector
) {
  const user = userSelector(state);
  if (!user) {
    return [];
  }
  return getLocaleByCountry(user.country);
}

export function userBeingCreatedSelector(state) {
  return (
    state['admin:users:userBeingCreated'] ?? {
      country: '',
      publisherId: '',
      locale: '',
      email: '',
      role: CUSTOMER_ROLES.CLIENT,
    }
  );
}

export function canSubmitCreateUserFormSelector(state) {
  const user = userBeingCreatedSelector(state);
  const submittingForm = state['admin:users:submittingUserCreation'] ?? false;

  return Boolean(
    !submittingForm &&
      user.country &&
      user.publisherId &&
      user.email &&
      user.locale &&
      user.role
  );
}

export const canDeleteUserSelector = oneOf(
  userIsSupportSelector,
  userIsFinanceSelector,
  userIsSuperadminSelector
);

export function countryOptionsForUserCreationSelector(state) {
  const countries = availableCountriesForBackofficeUserSelector(state);
  const countryCode = publisherCountrySelector(state);
  const isSales = userIsSalesSelector(state);

  if (isSales) {
    return countries.filter(({ code }) => code === countryCode);
  }
  return countries;
}

export function canCurrentUserAssignBackofficeRoleSelector(state) {
  const hasValidRole = oneOf(userIsSupportSelector, userIsSuperadminSelector);
  return (
    hasValidRole(state) && userManagedCountriesSelector(state).length === 0
  );
}

export function userFormErrorSelector(state) {
  return state['admin:users:form']?.error;
}
