export function projectSuggestionsSelector(state) {
  const suggestions = state.developersAdmin?.projectSuggestions;
  return (suggestions ?? []).filter(
    (suggestion) => Boolean(suggestion.developerId) === false
  );
}

export function projectNameSuggestionLoadingSelector(state) {
  return Boolean(state.developersAdmin?.projectNameSuggestionLoading);
}

export function projectSearchTextSelector(state) {
  return state.developersAdmin?.projectSearchText;
}
