export const COUNTRY_CODE = {
  THAILAND: 'TH',
  MEXICO: 'MX',
  CHILE: 'CL',
  ARGENTINA: 'AR',
  SPAIN: 'ES',
  UNITED_ARAB_EMIRATES: 'AE',
  COLOMBIA: 'CO',
  PERU: 'PE',
  ECUADOR: 'EC',
};

export const COUNTRY_LOCALE = {
  MX: 'es-MX',
  CL: 'es-CL',
  AR: 'es-AR',
  ES: 'es-ES',
  AE: 'ar-AE',
  CO: 'es-CO',
  PE: 'es-PE',
  EC: 'es-EC',
  US: 'en-US',
  GB: 'en-GB',
};
