import { Route, Routes } from 'react-router-dom';
import PrivateRedirect from '../../App/router/PrivateRedirect';
import BackofficeRoute from './BackofficeRoute';
import CreatePublisher from '../pages/CreatePublisher/CreatePublisher';
import { AGENCIES, CAMPAIGNS, DEVELOPERS } from '../constants/routes';
import Developers from '../pages/Developers/Developers';
import Agencies from '../pages/Agencies/Agencies';
import UsersList from '../pages/UsersList/UsersList';
import SubscriptionPlansManagement from '../pages/SubscriptionPlansManagement/SubscriptionPlansManagement';
import { Campaigns } from '../pages/Campaigns';
import ProjectEditor from '../pages/Project/ProjectEditor';
import AdEditor from '../pages/Ad/AdEditor';
import Agency from '../pages/Agency/Agency';

function BackofficeRoutes() {
  return (
    <Routes>
      <Route
        path="soporte"
        element={<PrivateRedirect to="https://info.proppit.com/saleshub" />}
      />
      <Route
        path="support"
        element={
          <PrivateRedirect to="https://6693398.hs-sites.com/en/en/internal-support" />
        }
      />
      <Route
        path="create-user"
        element={<BackofficeRoute as={CreatePublisher} />}
      />
      <Route path={DEVELOPERS} element={<BackofficeRoute as={Developers} />} />
      <Route path={AGENCIES} element={<BackofficeRoute as={Agencies} />} />
      <Route path={AGENCIES} element={<BackofficeRoute as={Agencies} />} />
      <Route
        path="agencies/:publisherId"
        element={<BackofficeRoute as={Agency} />}
      />
      <Route path={CAMPAIGNS} element={<BackofficeRoute as={Campaigns} />} />
      <Route path="users" element={<BackofficeRoute as={UsersList} />} />
      <Route
        path="subscription-plans-management"
        element={<BackofficeRoute as={SubscriptionPlansManagement} />}
      />
      <Route
        path="tools/property/:adId"
        element={<BackofficeRoute as={AdEditor} />}
      />
      <Route
        path="tools/project/:projectId"
        element={<BackofficeRoute as={ProjectEditor} />}
      />
    </Routes>
  );
}

export default BackofficeRoutes;
