import { registerEventHandler } from 'reffects';
import { clipboard } from '../../../../effects/clipboard';
import { toast } from '../../../../effects/toast';

export const COPY_TEXT = 'COPY_TEXT';

registerEventHandler(COPY_TEXT, (_, text) => ({
  ...clipboard.copy(text),
  ...toast.show({
    text: 'Copied to clipboard!',
  }),
}));
