import { css } from 'styled-components';

import { border as borderTokens } from '../tokens/border';

export const BORDER_SIZE = {
  NONE: 'none',
  BASE: borderTokens.base,
  LARGE: borderTokens.large,
};

const outer = ({ size, color } = {}) => css`
  border-style: solid;

  ${color &&
  css`
    border-color: ${color};
  `}

  ${size &&
  css`
    border-width: ${size}px;
  `}

  ${size === 'none' &&
  css`
    border: none;
  `}
`;

const inner = ({ size, color } = {}) => css`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: inherit;
    pointer-events: none;

    ${outer({ size, color })}
  }
`;
export const border = {
  inner,
  outer,
};
