import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import Thumbnail from 'design-system/components/Thumbnail';
import DropdownMenu from 'design-system/components/DropdownMenu';
import { DROPDOWN_POSITION } from 'design-system/components/Dropdown';
import Anchor from 'design-system/components/Anchor';
import Menu from 'design-system/components/Menu/Menu';
import { OutlineButton } from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';
import { border, BORDER_SIZE } from 'design-system/styles/border';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { COLOR_SEMANTIC } from 'design-system/styles/color';
import { Heading2 } from 'design-system/components/Typography/presets/Heading2';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import {
  isMultilangCountrySelector,
  propertySupportedLanguagesSelector,
} from '../../../selectors/config';
import { PUBLISHER_CURRENT_LOCALE_SELECTED } from '../events';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  publisherAddressByCurrentLocaleSelector,
  publisherNameByCurrentLocaleSelector,
  publisherProfileCurrentLocaleSelector,
} from '../selectors';
import {
  publisherLogoSelector,
  publisherWebsiteUrlSelector,
} from '../../../selectors/publisher';

const Wrapper = styled.header`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  column-gap: var(--layout-gutter);

  ${spacing.stack(SPACING_SIZE.L, false)}

  ${Thumbnail} {
    grid-column-start: span 2;
    justify-self: center;

    ${border.outer({
      size: BORDER_SIZE.LARGE,
      color: COLOR_SEMANTIC.PAPER,
    })}

    ${Thumbnail.mods.size(150, 100)}
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;

  overflow: hidden;

  ${spacing.inline(SPACING_SIZE.M)}
  ${Heading2} {
    ${spacing.stack(SPACING_SIZE.S)}
  }
`;

const Container = styled.div`
  grid-column: 3 / span 6;
  align-self: end;

  display: flex;
  justify-content: space-between;
  align-items: start;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > * {
    ${spacing.inline(SPACING_SIZE.S)}
  }
`;

const Body2Placeholder = styled(Body2).attrs({ children: <>&nbsp;</> })`
  pointer-events: none;
  visibility: hidden;
`;

const AnchorPlaceholder = styled(Anchor).attrs({ children: <>&nbsp;</> })`
  pointer-events: none;
  visibility: hidden;
`;

export default function PublisherInfo({ cta }) {
  const image = useSelector(publisherLogoSelector);
  const name = useSelector(publisherNameByCurrentLocaleSelector);
  const address = useSelector(publisherAddressByCurrentLocaleSelector);
  const siteUrl = useSelector(publisherWebsiteUrlSelector);
  const isPublisherMultilang = useSelector(isMultilangCountrySelector);

  return (
    <Wrapper>
      <Thumbnail source={image} />
      <Container>
        <Info>
          <Heading2 $ellipse>{name}</Heading2>
          {address ? <Body2 $ellipse>{address}</Body2> : <Body2Placeholder />}
          {siteUrl ? (
            <Anchor $weight={FONT_WEIGHT.LIGHT} href={siteUrl} target="_blank">
              {siteUrl}
            </Anchor>
          ) : (
            <AnchorPlaceholder />
          )}
        </Info>
        <Actions>
          {isPublisherMultilang && <LocaleSelector />}
          {cta}
        </Actions>
      </Container>
    </Wrapper>
  );
}

const LOCALE_TRANSLATIONS_MAP = {
  'en-US': 'company_profile_lang_en',
  'th-TH': 'company_profile_lang_th',
  'id-ID': 'company_profile_lang_id',
  'vi-VN': 'company_profile_lang_vn',
};

function LocaleSelector() {
  const currentLocale = useSelector(publisherProfileCurrentLocaleSelector);
  const locales = useSelector(propertySupportedLanguagesSelector);

  const handleConfirm = ({ value }) =>
    dispatch({
      id: PUBLISHER_CURRENT_LOCALE_SELECTED,
      payload: { locale: value },
    });

  return (
    <DropdownMenu
      position={DROPDOWN_POSITION.BOTTOM_RIGHT}
      button={
        <OutlineButton icon={<Icon glyph="chevronDown" />}>
          <FormattedMessageWithValidation
            id={LOCALE_TRANSLATIONS_MAP[currentLocale]}
          />
        </OutlineButton>
      }
      onConfirm={handleConfirm}
    >
      <Menu>
        {locales.map((locale) => (
          <Menu.Item
            key={locale}
            id={locale}
            value={locale}
            label={
              <FormattedMessageWithValidation
                id={LOCALE_TRANSLATIONS_MAP[locale]}
              />
            }
          />
        ))}
      </Menu>
    </DropdownMenu>
  );
}
