import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import SkeletonBone from 'design-system/components/SkeletonBone';
import Icon from 'design-system/components/Icon';
import { BUTTON_SIZE } from 'design-system/components/Button';
import { TextButton } from 'design-system/components/Button/presets';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import Typography from 'design-system/components/Typography/Typography';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import useOnWindowResize from '../../../../../hooks/useOnWindowResize';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${SkeletonBone} {
    ${spacing.stack(SPACING_SIZE.S)}
  }
`;

const Text = styled(Body1)`
  ${Typography.mods.weight(FONT_WEIGHT.LIGHT)}
  ${({ showMore }) => !showMore && Typography.mods.ellipse({ lineClamp: 6 })}
  ${spacing.stack(SPACING_SIZE.S)}
`;

const ShowMoreButton = styled(TextButton)`
  align-self: center;

  ${Icon} {
    ${({ showMore }) =>
      showMore && `transform: rotate(180deg) translateY(50%);`}
  }
`;

function Description({ description }) {
  const [displayButton, setDisplayButton] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const descriptionRef = useRef();

  useShouldDisplayShowMoreButton(setDisplayButton, descriptionRef);

  return (
    <Wrapper>
      <Text ref={descriptionRef} showMore={showMore}>
        {description}
      </Text>
      {displayButton && (
        <ShowMoreButton
          showMore={showMore}
          $size={BUTTON_SIZE.SMALL}
          icon={<Icon glyph="chevronDown" />}
          onClick={() => setShowMore(!showMore)}
        >
          <FormattedMessageWithValidation
            id={
              showMore
                ? 'enqdetail_property_detail_description_show_less'
                : 'enqdetail_property_detail_description_show_more'
            }
          />
        </ShowMoreButton>
      )}
    </Wrapper>
  );
}

function Skeleton() {
  return (
    <Wrapper>
      <SkeletonBone $width="300px" $height="14px" />
      <SkeletonBone $width="295px" $height="14px" />
      <SkeletonBone $width="300px" $height="14px" />
      <SkeletonBone $width="295px" $height="14px" />
      <SkeletonBone $width="281px" $height="14px" />
      <SkeletonBone $width="292px" $height="14px" />
      <SkeletonBone $width="286px" $height="14px" />
      <SkeletonBone $width="300px" $height="14px" />
    </Wrapper>
  );
}

Description.Skeleton = Skeleton;

export default Description;

function useShouldDisplayShowMoreButton(setDisplayButton, descriptionRef) {
  useEffect(
    () => setDisplayButton(isEllipsisActive(descriptionRef.current)),
    []
  );

  useOnWindowResize(() => {
    setDisplayButton(isEllipsisActive(descriptionRef.current));
  }, 200);
}

function isEllipsisActive(node) {
  if (!node) {
    return false;
  }
  return node.offsetHeight < node.scrollHeight;
}
