import { createSelector } from 'reselect';
import { collectionSelector, visibleItemsIdListSelector } from './collection';

export const isLoadingSelector = createSelector(
  collectionSelector,
  (collection) => collection?.isLoading ?? true
);

export const isEmptySelector = createSelector(
  collectionSelector,
  isLoadingSelector,
  (collection, isLoading) => {
    if (isLoading) {
      return false;
    }

    return collection?.isEmpty ?? false;
  }
);

export const hasNoItemsSelector = createSelector(
  visibleItemsIdListSelector,
  (itemIds) => itemIds.length === 0
);

export const isBulkProcessingSelector = createSelector(
  collectionSelector,
  (collection) => collection?.bulkIsProcessing ?? false
);
