import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import { debounce } from 'lodash';
import Autocomplete from 'design-system/components/Autocomplete/Autocomplete';
import InputText from 'design-system/components/InputText';
import Icon from 'design-system/components/Icon';
import {
  SIBLING_PUBLISHER_SEARCH_SUGGESTIONS,
  SIBLING_PUBLISHER_SELECTED,
  SIBLING_PUBLISHER_TEXT_INTRODUCED,
} from './events';
import {
  displayableSiblingPublisherSelector,
  suggestedSiblingPublishersSelector,
} from './selectors';
import { MULTI_ACCOUNT_CLIENT_INFORMATION_CLEARED } from '../../../events';
import { publisherFormInfoSelector } from '../../../selectors';
import { MULTIPLE_ACCOUNT_CLIENT_SUBSCRIPTION_TYPE } from '../../../constants';

const searchSuggestions = debounce((inputValue) => {
  dispatch({
    id: SIBLING_PUBLISHER_SEARCH_SUGGESTIONS,
    payload: inputValue,
  });
}, 1000);

export default function SiblingPublisherAutocomplete() {
  const options = useSelector(suggestedSiblingPublishersSelector);
  const publisher = useSelector(publisherFormInfoSelector);
  const displayableValue = useSelector(displayableSiblingPublisherSelector);

  return (
    <Autocomplete
      value={displayableValue}
      options={options}
      onValueChange={onQueryChange}
      onOptionSelected={(siblingPublisher) =>
        dispatch({
          id: SIBLING_PUBLISHER_SELECTED,
          payload: siblingPublisher,
        })
      }
      optionToString={(option) => (option === undefined ? '' : option.name)}
      isLoading={false}
      minCharactersToOpen={2}
      renderInput={({ value, getInputProps, onClear }) => (
        <InputText
          required
          label={
            publisher.multipleAccountClient ===
            MULTIPLE_ACCOUNT_CLIENT_SUBSCRIPTION_TYPE.SHARED
              ? 'Main publisher account'
              : 'Sibling publisher account'
          }
          icon={<Icon glyph="cross" />}
          onIconClick={() => {
            onClear();
            dispatch(MULTI_ACCOUNT_CLIENT_INFORMATION_CLEARED);
          }}
          tip="Search available sibling publishers by their name or id"
          {...getInputProps({ value, autoComplete: 'off' })}
        />
      )}
      renderOptions={({ options: publisherOptions, getOptionProps }) =>
        publisherOptions.map((option, index) => (
          <Autocomplete.Option
            key={option.id}
            title={option.name}
            subtitle={option.id}
            icon={<Icon glyph="building" />}
            {...getOptionProps({
              index,
              option,
            })}
          />
        ))
      }
      emptyStateMessage="No options"
      loadingStateMessage="Loading..."
    />
  );
}

function onQueryChange(inputValue) {
  dispatch({
    id: SIBLING_PUBLISHER_TEXT_INTRODUCED,
    payload: { text: inputValue },
  });

  searchSuggestions(inputValue);
}
