import { useSelector } from 'reffects-store';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { chunk } from 'lodash';
import Section from 'design-system/components/Section';
import InputRow from 'design-system/components/InputRow';
import { INPUT_NUMBER_TYPE } from 'design-system/components/InputNumber';
import NumberField, {
  useCurrencyPosition,
} from '../../../partials/fields/NumberField';
import SelectField from '../../../partials/fields/SelectField';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  defaultCommunityFeesSelector,
  detailsOfPropertyTypeSelector,
} from '../selectors';
import HiddenField from '../../../partials/fields/HiddenField';

export default function DetailsSection() {
  const intl = useIntl();
  const { watch } = useFormContext();
  const [propertyType] = watch(['propertyType']);
  const details = useSelector((state) =>
    detailsOfPropertyTypeSelector(state, propertyType)
  );
  const defaultCommunityFees = useSelector(defaultCommunityFeesSelector);
  const communityFeesCurrencyPosition = useCurrencyPosition(
    defaultCommunityFees.code
  );
  const selectChoice = (label, value) => ({ label, value });
  const translatedChoice = (label, value) =>
    selectChoice(
      intl.formatMessage({
        id: label,
      }),
      value
    );

  const detailFields = [
    details.includes('spaces') && (
      <NumberField
        type={INPUT_NUMBER_TYPE.INTEGER}
        label={<FormattedMessageWithValidation id="newprop_spaces_title" />}
        name="spaces"
      />
    ),
    details.includes('bedrooms') && (
      <NumberField
        label={<FormattedMessageWithValidation id="newprop_rooms_emptystate" />}
        type={INPUT_NUMBER_TYPE.INTEGER}
        name="bedrooms"
      />
    ),
    details.includes('bathrooms') && (
      <NumberField
        type={INPUT_NUMBER_TYPE.INTEGER}
        label={
          <FormattedMessageWithValidation id="newprop_bathrooms_emptystate" />
        }
        name="bathrooms"
      />
    ),
    details.includes('toilets') && (
      <NumberField
        type={INPUT_NUMBER_TYPE.INTEGER}
        label={
          <FormattedMessageWithValidation id="newprop_toilets_emptystate" />
        }
        name="toilets"
      />
    ),
    details.includes('floor') && (
      <NumberField
        type={INPUT_NUMBER_TYPE.INTEGER}
        label={
          <FormattedMessageWithValidation id="propspage_table_property_floor" />
        }
        name="floor"
      />
    ),
    details.includes('parkingSpaces') && (
      <NumberField
        type={INPUT_NUMBER_TYPE.INTEGER}
        label={
          <FormattedMessageWithValidation id="newprop_parking_spaces_title" />
        }
        name="parkingSpaces"
      />
    ),
    details.includes('orientation') && (
      <SelectField
        label={
          <FormattedMessageWithValidation id="newprop_orientation_title" />
        }
        name="orientation"
        placeholder={intl.formatMessage({
          id: 'newprop_orientation_emptystate',
        })}
        choices={[
          translatedChoice('newprop_orientation_north', 'north'),
          translatedChoice('newprop_orientation_south', 'south'),
          translatedChoice('newprop_orientation_east', 'east'),
          translatedChoice('newprop_orientation_west', 'west'),
          translatedChoice('newprop_orientation_northeast', 'northeast'),
          translatedChoice('newprop_orientation_northwest', 'northwest'),
          translatedChoice('newprop_orientation_southeast', 'southeast'),
          translatedChoice('newprop_orientation_southwest', 'southwest'),
        ]}
      />
    ),
    details.includes('furnished') && (
      <SelectField
        label={<FormattedMessageWithValidation id="newprop_furnished_title" />}
        name="furnished"
        placeholder={intl.formatMessage({
          id: 'newprop_furnished_emptystate',
        })}
        choices={[
          translatedChoice('newprop_furnished_fully', 'fully'),
          translatedChoice('newprop_furnished_partly', 'partly'),
          translatedChoice('newprop_furnished_unfurnished', 'unfurnished'),
        ]}
      />
    ),
    details.includes('stratum') && (
      <SelectField
        label={<FormattedMessageWithValidation id="newprop_estrato_header" />}
        name="stratum"
        placeholder={intl.formatMessage({
          id: 'newprop_furnished_emptystate',
        })}
        choices={[
          translatedChoice('newprop_estrato_sin_estrato', null),
          selectChoice(1, 1),
          selectChoice(2, 2),
          selectChoice(3, 3),
          selectChoice(4, 4),
          selectChoice(5, 5),
          selectChoice(6, 6),
          selectChoice(7, 7),
        ]}
      />
    ),
    details.includes('constructionYear') && (
      <NumberField
        type={INPUT_NUMBER_TYPE.INTEGER}
        label={
          <FormattedMessageWithValidation id="propspage_table_construction_year" />
        }
        name="constructionYear"
      />
    ),
    details.includes('communityFees') && (
      <NumberField
        label={
          <FormattedMessageWithValidation id="propspage_table_community_fees" />
        }
        name="communityFees"
        unit={defaultCommunityFees.symbol}
        unitPosition={communityFeesCurrencyPosition}
      />
    ),
    details.includes('condition') && (
      <SelectField
        label={<FormattedMessageWithValidation id="newprop_condition_title" />}
        name="condition"
        placeholder={intl.formatMessage({
          id: 'newprop_condition_emptystate',
        })}
        choices={[
          translatedChoice('newprop_condition_second_hand', 'second hand'),
          translatedChoice('newprop_condition_new', 'new'),
          translatedChoice('newprop_condition_ruin', 'ruin'),
          translatedChoice(
            'newprop_condition_semi_renovated',
            'semi-renovated'
          ),
          translatedChoice('newprop_condition_semi_new', 'semi-new'),
          translatedChoice(
            'newprop_condition_in_construction',
            'in construction'
          ),
          translatedChoice('newprop_condition_renovated', 'renovated'),
          translatedChoice('newprop_condition_normal', 'normal'),
          translatedChoice('newprop_condition_excellent', 'excellent'),
          translatedChoice('newprop_condition_good', 'good'),
          translatedChoice('newprop_condition_to_renew', 'to renew'),
        ]}
      />
    ),
  ].filter(Boolean);

  if (!detailFields.length) return null;

  const detailFieldRows = chunk(detailFields, 2);
  return (
    <Section
      title={<FormattedMessageWithValidation id="newprop_details_header" />}
    >
      {detailFieldRows.map(([fieldLeft, fieldRight], index) => (
        <InputRow key={index} arrangement={fieldRight ? undefined : [3]}>
          {fieldLeft}
          {fieldRight}
        </InputRow>
      ))}
      <HiddenField name="communityFeesCurrency" />
    </Section>
  );
}
