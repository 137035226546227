import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import Dialog from 'design-system/components/Dialog/Dialog';
import { EMPHASIS_VARIANT } from 'design-system/components/Dialog/Emphasis';
import InputSelect from 'design-system/components/InputSelect';
import { SecondaryButton } from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import {
  availableLocalesSelector,
  availablePublishersSelector,
  canCurrentUserAssignBackofficeRoleSelector,
  selectedUserSelector,
  userFormErrorSelector,
} from './selectors';
import {
  USER_FIELD_CHANGED,
  USER_MODAL_FORM_SUBMITTED,
  USER_MODIFICATION_REQUESTED,
  USER_TO_MODIFY_UNSELECTED,
} from './events';
import PublisherSelector from '../partials/PublisherSelector';
import EmailInput from '../partials/EmailInput';
import { useDialog } from '../../../hooks/useDialog';
import { UPDATE_USER_DIALOG } from './constants';
import { userIsSuperadminSelector } from '../../../selectors/user';
import { BACKOFFICE_ROLES, CUSTOMER_ROLES } from '../../../constants/user';

const Form = styled.form`
  display: grid;
  grid-template: 1fr 1fr / 1fr 1fr;
  grid-gap: 1rem;
`;

export default function UpdateUserModal({ onSuccessEvent }) {
  const { open } = useDialog(UPDATE_USER_DIALOG);
  const user = useSelector(selectedUserSelector);
  const availablePublishers = useSelector((state) =>
    availablePublishersSelector(state, selectedUserSelector)
  );
  const availableLocales = useSelector((state) =>
    availableLocalesSelector(state, selectedUserSelector)
  );
  const canCurrentUserAssignBackofficeRole = useSelector(
    canCurrentUserAssignBackofficeRoleSelector
  );
  const canCurrentUserAssignSuperadminRole = useSelector(
    userIsSuperadminSelector
  );
  const onUserFieldChanged = (field) => (e) =>
    dispatch({
      id: USER_FIELD_CHANGED,
      payload: { field, value: e.target.value },
    });
  const error = useSelector(userFormErrorSelector);

  return (
    <Dialog open={open}>
      <Dialog.Header onClose={() => dispatch(USER_TO_MODIFY_UNSELECTED)}>
        Update user
      </Dialog.Header>
      {error && (
        <Dialog.Emphasis variant={EMPHASIS_VARIANT.ALERT}>
          {error}
        </Dialog.Emphasis>
      )}
      <Dialog.Content>
        {user && (
          <Form
            autoComplete="off"
            id="update-user"
            onSubmit={(e) => {
              e.preventDefault();
              dispatch({
                id: USER_MODAL_FORM_SUBMITTED,
                payload: {
                  user,
                  submitEventId: USER_MODIFICATION_REQUESTED,
                  onSuccessEvent,
                },
              });
            }}
          >
            <PublisherSelector
              publishers={availablePublishers}
              selectedPublisherId={user.publisherId}
              onSelect={({ value }) =>
                dispatch({
                  id: USER_FIELD_CHANGED,
                  payload: { field: 'publisherId', value },
                })
              }
            />
            <InputSelect
              name="language"
              label="Language"
              disabled={availableLocales.length <= 1}
              required
              value={user.locale}
              onSelect={({ value }) =>
                dispatch({
                  id: USER_FIELD_CHANGED,
                  payload: { field: 'locale', value },
                })
              }
              choices={availableLocales.map((l) => ({
                label: l,
                value: l,
              }))}
            />
            <EmailInput
              name="email"
              label="Email"
              onChange={onUserFieldChanged('email')}
              value={user.email}
              required
            />
            <InputSelect
              name="role"
              label="Role"
              required
              value={user.role}
              onSelect={({ value }) =>
                dispatch({
                  id: USER_FIELD_CHANGED,
                  payload: { field: 'role', value },
                })
              }
              choices={[
                { label: 'Client admin', value: CUSTOMER_ROLES.CLIENT },
                {
                  label: 'Client child',
                  value: CUSTOMER_ROLES.PUBLISHER_COLLABORATOR,
                },
                canCurrentUserAssignBackofficeRole
                  ? { label: 'Support', value: BACKOFFICE_ROLES.SUPPORT }
                  : null,
                canCurrentUserAssignBackofficeRole
                  ? { label: 'Finance', value: BACKOFFICE_ROLES.FINANCE }
                  : null,
                canCurrentUserAssignBackofficeRole
                  ? {
                      label: 'Finance Manager',
                      value: BACKOFFICE_ROLES.FINANCE_MANAGER,
                    }
                  : null,
                canCurrentUserAssignBackofficeRole
                  ? { label: 'Sales', value: BACKOFFICE_ROLES.SALES }
                  : null,
                canCurrentUserAssignBackofficeRole
                  ? {
                      label: 'Sales Manager',
                      value: BACKOFFICE_ROLES.SALES_MANAGER,
                    }
                  : null,
                canCurrentUserAssignBackofficeRole &&
                canCurrentUserAssignSuperadminRole
                  ? {
                      label: 'Super admin',
                      value: BACKOFFICE_ROLES.SUPER_ADMIN,
                    }
                  : null,
              ].filter(Boolean)}
              className="RoleSelector"
            />
          </Form>
        )}
      </Dialog.Content>
      <Dialog.Footer>
        <SecondaryButton
          type="submit"
          form="update-user"
          $size={BUTTON_SIZE.SMALL}
        >
          Update user
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}
