import { useSelector } from 'reffects-store';
import { useMount } from 'react-use';
import ReactPixel from 'react-facebook-pixel';
import BaseAuthenticationPage from './BaseAuthenticationPage';
import LoginForm from './forms/LoginForm';

export default function Login() {
  const pixel = useSelector((state) => state.metaPixel);
  useMount(() => {
    if (pixel) {
      ReactPixel.init(pixel);
      ReactPixel.pageView();
    }
  });
  return (
    <BaseAuthenticationPage>
      <LoginForm />
    </BaseAuthenticationPage>
  );
}
