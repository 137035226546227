import { useCallback } from 'react';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import { useDatatableContext } from './useDatatableContext';
import { visibleSelectedItemsCountSelector } from '../selectors/selection';
import { isBulkProcessingSelector } from '../selectors/loading';

export function useBulkAction(action) {
  const context = useDatatableContext();
  const selectedItemsCount = useSelector((state) =>
    visibleSelectedItemsCountSelector(state, {
      collectionName: context.collectionName,
    })
  );

  const isProcessing = useSelector((state) =>
    isBulkProcessingSelector(state, { collectionName: context.collectionName })
  );

  const executeAction = useCallback(
    () =>
      dispatch({
        id: context.updateEvents.bulk,
        payload: { ...context, action },
      }),
    [context.collectionName, action, context.updateEvents.bulk]
  );

  return {
    executeAction,
    selectedItemsCount,
    isProcessing,
  };
}
