import { CheckoutConfigurationBuilder } from './checkoutConfigurationBuilder';
import { TRANSLATION_NAMES, UPSELL_SUBROUTE } from './constants';

export default function upsellCheckoutConfigurationProvider() {
  return CheckoutConfigurationBuilder.create()
    .withSubRoute(UPSELL_SUBROUTE)
    .withPageViewNamePrefix('upsell')
    .withTextTranslation(
      TRANSLATION_NAMES.PAGE_TITLE,
      'checkout_page_title_general'
    )
    .withTextTranslation(
      TRANSLATION_NAMES.BANK_TRANSFER_NOTICE,
      'checkout_bank_transfer_notice_general'
    )
    .withTextTranslation(
      TRANSLATION_NAMES.CONFIRMATION_CAPTION,
      'checkout_confirmation_caption_general'
    )
    .withTextTranslation(
      TRANSLATION_NAMES.CONFIRMATION_CAPTION_SELF_SERVICE,
      'checkout_confirmation_caption_general'
    )
    .withTextTranslation(
      TRANSLATION_NAMES.CONFIRMATION_TITLE,
      'checkout_confirmation_title_general'
    )
    .withTextTranslation(
      TRANSLATION_NAMES.CONFIRMATION_TITLE_SELF_SERVICE,
      'checkout_confirmation_title_general'
    )
    .withTextTranslation(
      TRANSLATION_NAMES.PAY_BY_LINK_CONFIRMATION,
      'checkout_paybylink_confirmation_body1_general'
    )
    .build();
}
