import { withoutEventPropagation } from 'design-system/utils/withoutEventPropagation';
import { useCallback } from 'react';
import { dispatch } from 'reffects';
import Menu from 'design-system/components/Menu/Menu';
import { RESEND_FIRST_PAYMENT_CHECKOUT_EMAIL } from './events';

export function ResendFirstPaymentEmailAction({ publisher }) {
  const { id, hasActiveFirstPaymentCheckout } = publisher;

  const handleClick = useCallback(() => {
    dispatch({
      id: RESEND_FIRST_PAYMENT_CHECKOUT_EMAIL,
      payload: {
        publisherId: id,
      },
    });
  }, []);

  if (!hasActiveFirstPaymentCheckout) {
    return null;
  }

  return (
    <Menu.Item
      label="Resend First Payment Email"
      onClick={withoutEventPropagation(handleClick)}
    />
  );
}
