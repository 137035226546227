import styled from 'styled-components';
import Anchor from 'design-system/components/Anchor';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import InboundBubble from './InboundBubble';
import PropertySnippet from './PropertySnippet';
import ProjectSnippet from './ProjectSnippet';

const Wrapper = styled.div`
  > ${Anchor}, > ${Body1} {
    display: inline-block;
    ${spacing.stack(SPACING_SIZE.XS)}
  }
`;

function WhatsappLeadBubble({ linkToConversation, ad, project, ...rest }) {
  return (
    <InboundBubble {...rest}>
      <Wrapper>
        {linkToConversation ? (
          <Anchor href={linkToConversation} target="_blank">
            <FormattedMessageWithValidation id="enquiry_detail_activity_open_whatsapp" />
          </Anchor>
        ) : (
          <Body1
            $color={COLOR_PALETTE.NEUTRAL_BASE}
            $weight={FONT_WEIGHT.LIGHT}
          >
            <FormattedMessageWithValidation id="enquiry_detail_activity_lead_type_whatsapp" />
          </Body1>
        )}
        {ad ? (
          <PropertySnippet property={ad} project={project} />
        ) : (
          <ProjectSnippet project={project} />
        )}
      </Wrapper>
    </InboundBubble>
  );
}

export default WhatsappLeadBubble;
