import { collectionItemsByIdSelector } from '../../../partials/DataTable/selectors/collection';
import { COLLECTION_NAME } from './constants';

const MAX_INVOICING_PERIODS = 12;
export function developersSelectorSortedByName(state) {
  return (state['admin:developers'] ?? []).sort((a, b) =>
    a.name.localeCompare(b.name)
  );
}

export function selectedCampaignSelector(state) {
  return state['campaignsBackoffice:selectedCampaign'];
}

export function projectsSuggestionsSelector(state) {
  const projectSuggestions = state.developersAdmin?.projectSuggestions ?? [];
  const selectedDeveloperId = selectedCampaignSelector(state)?.developerId;
  const campaigns = Object.values(
    collectionItemsByIdSelector(state, { collectionName: COLLECTION_NAME })
  );
  const projectsWithCampaigns = new Set(
    campaigns.map(({ projectId }) => projectId)
  );
  return projectSuggestions
    .filter(
      ({ id, developerId }) =>
        (developerId == null || developerId === selectedDeveloperId) &&
        !projectsWithCampaigns.has(id)
    )
    .map(({ id, name }) => ({ id, name }));
}

export function canSaveCampaignSelector(state) {
  const campaign = selectedCampaignSelector(state);
  if (!campaign) {
    return false;
  }
  if (
    campaign.price.amount == null ||
    campaign.price.amount === '' ||
    campaign.price.currency == null
  ) {
    return false;
  }

  return Object.keys(campaign).every((k) => Boolean(campaign[k]));
}

export function invoicingPeriodsSelector(state) {
  return selectedCampaignSelector(state)?.invoicingPeriods ?? [];
}

export function canAddInvoicingPeriodSelector(state) {
  const periods = invoicingPeriodsSelector(state);
  return periods.length < MAX_INVOICING_PERIODS;
}

export function invoicingPeriodErrorSelector(state) {
  return selectedCampaignSelector(state)?.error;
}
