import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Overline } from 'design-system/components/Typography/presets/Overline';

const Wrapper = styled.section`
  ${spacing.stack(SPACING_SIZE.XL)}

  > ${Overline} {
    display: block;
    ${spacing.stack(SPACING_SIZE.M)}
  }
`;

function Section({ title, children, ...rest }) {
  return (
    <Wrapper {...rest}>
      <Overline>{title}</Overline>
      <div>{children}</div>
    </Wrapper>
  );
}

export default styled(Section)``;
