import styled from 'styled-components';
import Grid from 'design-system/components/Grid';
import Layout from 'design-system/components/Layout';
import MarkdownDocument from '../../partials/MarkdownDocument';
import { Navigation } from '../../partials';
import LegalLinks from '../../partials/LegalLinks/LegalLinks';
import { BrandLogoForAnonymousUsers } from '../../partials/BrandLogo';

const CenteredContent = ({ children }) => (
  <Grid areas={({ repeat }) => `". . ${repeat(8, 'content')} . ."`}>
    <Grid.Area $id="content">{children}</Grid.Area>
  </Grid>
);

const FooterContent = styled.div`
  display: flex;
  justify-content: flex-end;
`;

function GenericLegalPage({ id, documentPath }) {
  return (
    <Layout>
      <Layout.Header>
        <Navigation logo={<BrandLogoForAnonymousUsers />} />
      </Layout.Header>
      <Layout.Content>
        <Layout.Padded>
          <CenteredContent>
            <MarkdownDocument id={id} path={documentPath} />
          </CenteredContent>
        </Layout.Padded>
      </Layout.Content>
      <Layout.Footer>
        <Layout.Padded>
          <CenteredContent>
            <FooterContent>
              <LegalLinks />
            </FooterContent>
          </CenteredContent>
        </Layout.Padded>
      </Layout.Footer>
    </Layout>
  );
}

export default GenericLegalPage;
