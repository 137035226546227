import styled from 'styled-components';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { Heading2 } from 'design-system/components/Typography/presets/Heading2';
import BaseRow from './BaseRow';

function Row({ title, price, ...rest }) {
  return (
    <BaseRow
      {...rest}
      leftChild={<Subtitle3>{title}</Subtitle3>}
      rightChild={<Heading2>{price}</Heading2>}
    />
  );
}

export default styled(Row)``;
