import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import Checkbox from 'design-system/components/Checkbox/Checkbox';
import { Body1 } from 'design-system/components/Typography/presets/Body1';

function CheckboxField({ id, name, disabled, children, ...rest }) {
  const { register } = useFormContext();
  return (
    <Checkbox.Label {...rest}>
      <Checkbox id={id} disabled={disabled} {...register(name)} />
      <Body1>{children}</Body1>
    </Checkbox.Label>
  );
}

export default styled(CheckboxField)``;
