import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { environment } from '../../../../../../../coeffects/environment';
import { toast } from '../../../../../../../effects/toast';
import { CLOSE_DIALOG } from '../../../../../../../events/dialogs';

export const REQUEST_SUBSCRIPTION_CHANGE_REQUEST_CANCELLATION =
  'REQUEST_SUBSCRIPTION_CHANGE_REQUEST_CANCELLATION';
export const SUBSCRIPTION_CHANGE_REQUEST_CANCELLATION_SUCCESSFULLY =
  'SUBSCRIPTION_CHANGE_REQUEST_CANCELLATION_SUCCESSFULLY';
export const SUBSCRIPTION_CHANGE_REQUEST_CANCELLATION_FAILED =
  'SUBSCRIPTION_CHANGE_REQUEST_CANCELLATION_FAILED';
export const SUBSCRIPTION_CHANGE_REQUEST_MARKED_AS_PAID =
  'SUBSCRIPTION_CHANGE_REQUEST_MARKED_AS_PAID';
export const SUBSCRIPTION_CHANGE_REQUEST_MARKED_AS_PAID_SUCCESS =
  'SUBSCRIPTION_CHANGE_REQUEST_MARKED_AS_PAID_SUCCESS';
export const SUBSCRIPTION_CHANGE_REQUEST_MARKED_AS_PAID_ERROR =
  'SUBSCRIPTION_CHANGE_REQUEST_MARKED_AS_PAID_ERROR';
export const SUBSCRIPTION_CHANGE_REQUEST_SUMMARY_DISABLE_BUTTONS =
  'SUBSCRIPTION_CHANGE_REQUEST_SUMMARY_DISABLE_BUTTONS';
export const SUBSCRIPTION_CHANGE_REQUEST_SUMMARY_REASON_CHANGE =
  'SUBSCRIPTION_CHANGE_REQUEST_SUMMARY_REASON_CHANGE';
export const SUBSCRIPTION_CHANGE_REQUEST_SUMMARY_CANCEL_REASON_CHANGE =
  'SUBSCRIPTION_CHANGE_REQUEST_SUMMARY_CANCEL_REASON_CHANGE';

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_MARKED_AS_PAID,
  (
    { environment: { apiUrl }, state: { reason, paymentMethod } },
    { subscriptionChangeRequestId, onSuccessEvent }
  ) => ({
    ...effects.dispatch({
      id: SUBSCRIPTION_CHANGE_REQUEST_SUMMARY_DISABLE_BUTTONS,
      payload: true,
    }),
    ...http.post({
      url: `${apiUrl}/admin/subscription-change-request-paid/${subscriptionChangeRequestId}`,
      body: { reason, paymentMethod },
      successEvent: {
        id: SUBSCRIPTION_CHANGE_REQUEST_MARKED_AS_PAID_SUCCESS,
        payload: { onSuccessEvent },
      },
      errorEvent: SUBSCRIPTION_CHANGE_REQUEST_MARKED_AS_PAID_ERROR,
    }),
  }),
  [
    environment(),
    state.get({
      reason: 'subscriptionChangeRequest.requestDetails.reason',
      paymentMethod: 'subscriptionChangeRequest.suggestedPaymentMethod',
    }),
  ]
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_MARKED_AS_PAID_SUCCESS,
  (_, [__, { onSuccessEvent }]) => ({
    ...toast.show({
      text: 'Subscription change request marked as paid!',
    }),
    ...effects.dispatchMany([
      CLOSE_DIALOG,
      onSuccessEvent,
      {
        id: SUBSCRIPTION_CHANGE_REQUEST_SUMMARY_DISABLE_BUTTONS,
        payload: false,
      },
      {
        id: SUBSCRIPTION_CHANGE_REQUEST_SUMMARY_REASON_CHANGE,
        payload: undefined,
      },
      {
        id: SUBSCRIPTION_CHANGE_REQUEST_SUMMARY_CANCEL_REASON_CHANGE,
        payload: undefined,
      },
    ]),
  })
);

registerEventHandler(SUBSCRIPTION_CHANGE_REQUEST_MARKED_AS_PAID_ERROR, () => ({
  ...toast.show({
    text: 'Subscription change request marked as paid failed!',
  }),
  ...effects.dispatch({
    id: SUBSCRIPTION_CHANGE_REQUEST_SUMMARY_DISABLE_BUTTONS,
    payload: false,
  }),
}));

registerEventHandler(
  REQUEST_SUBSCRIPTION_CHANGE_REQUEST_CANCELLATION,
  (
    { environment: { apiUrl }, state: { reason } },
    { subscriptionChangeRequestId, onSuccessEvent }
  ) => ({
    ...effects.dispatch({
      id: SUBSCRIPTION_CHANGE_REQUEST_SUMMARY_DISABLE_BUTTONS,
      payload: true,
    }),
    ...http.post({
      url: `${apiUrl}/admin/subscription-change-request-cancellation/${subscriptionChangeRequestId}`,
      body: { reason },
      successEvent: {
        id: SUBSCRIPTION_CHANGE_REQUEST_CANCELLATION_SUCCESSFULLY,
        payload: { onSuccessEvent },
      },
      errorEvent: SUBSCRIPTION_CHANGE_REQUEST_CANCELLATION_FAILED,
    }),
  }),
  [
    environment(),
    state.get({
      reason: 'subscriptionChangeRequest.requestDetails.cancelReason',
    }),
  ]
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_CANCELLATION_SUCCESSFULLY,
  (_, [__, { onSuccessEvent }]) => ({
    ...toast.show({
      text: 'Subscription change request cancelled successfully!',
    }),
    ...effects.dispatchMany([
      CLOSE_DIALOG,
      onSuccessEvent,
      {
        id: SUBSCRIPTION_CHANGE_REQUEST_SUMMARY_DISABLE_BUTTONS,
        payload: false,
      },
      {
        id: SUBSCRIPTION_CHANGE_REQUEST_SUMMARY_REASON_CHANGE,
        payload: undefined,
      },
      {
        id: SUBSCRIPTION_CHANGE_REQUEST_SUMMARY_CANCEL_REASON_CHANGE,
        payload: undefined,
      },
    ]),
  })
);

registerEventHandler(SUBSCRIPTION_CHANGE_REQUEST_CANCELLATION_FAILED, () => ({
  ...toast.show({ text: 'Subscription change request cancellation failed!' }),
  ...effects.dispatch({
    id: SUBSCRIPTION_CHANGE_REQUEST_SUMMARY_DISABLE_BUTTONS,
    payload: false,
  }),
}));

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_SUMMARY_DISABLE_BUTTONS,
  (_, areDisabled) =>
    state.set({
      'scrModal.disableButtons': areDisabled,
    })
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_SUMMARY_REASON_CHANGE,
  (_, reason) =>
    state.set({
      'subscriptionChangeRequest.requestDetails.reason': reason,
    })
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_SUMMARY_CANCEL_REASON_CHANGE,
  (_, reason) =>
    state.set({
      'subscriptionChangeRequest.requestDetails.cancelReason': reason,
    })
);
