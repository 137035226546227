import * as React from "react";
const SvgTrovitLogoColored = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 36 24"
    {...props}
  >
    <path
      fill="#2E374F"
      fillRule="evenodd"
      d="M9.769 12.79c0-2.525 1.958-4.577 4.38-4.577s4.38 2.052 4.38 4.578-1.958 4.577-4.38 4.577-4.38-2.051-4.38-4.577m2.503 0c0 1.078.845 1.959 1.877 1.959 1.031 0 1.877-.88 1.877-1.958s-.846-1.959-1.877-1.959-1.877.88-1.877 1.959"
      clipRule="evenodd"
    />
    <path
      fill="#2E374F"
      d="M27.962 8.387h2.445v8.795h-2.445zM27.823 5.71c0-.753.614-1.356 1.355-1.356.754 0 1.356.615 1.356 1.356 0 .754-.614 1.356-1.355 1.356a1.357 1.357 0 0 1-1.356-1.356M34.254 10.461h1.669V8.387h-1.669V5.861l-2.445.59v7.521c0 1.391.197 3.36 3.187 3.36.44 0 .857-.045.927-.057V15.34h-.65c-.532 0-1.019-.29-1.019-1.124zM2.445 10.461h1.669V8.387H2.445V5.861L0 6.44v7.52c0 1.39.197 3.36 3.187 3.36.44 0 .857-.046.927-.058V15.34h-.65c-.544 0-1.019-.29-1.019-1.124zM22.724 14.042l1.75-5.655h2.711l-2.931 7.915c-.244.602-.835 1.066-1.542 1.066-.706 0-1.286-.464-1.54-1.066L18.25 8.399h2.723zM9.004 8.213a3.75 3.75 0 0 0-3.743 3.743v5.227h2.445v-4.867c0-1.09.672-1.68 1.68-1.68h.22V8.212z"
    />
  </svg>
);
export default SvgTrovitLogoColored;
