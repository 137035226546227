import ExcellentExport from 'excellentexport';

function parseJsonToXls(data) {
  const dataMapped = data.map((lead) =>
    lead.reduce((acc, { header, value }) => {
      acc[header] = value;
      return acc;
    }, {})
  );

  const headers = Object.keys(dataMapped[0]);
  const headersTableFormatted = headers.reduce(
    (acc, field) => `${acc}<th title="${field}">${field}</th>`,
    ''
  );

  const valuesTableFormatted = dataMapped.reduce((acc, lead) => {
    const fields = Object.keys(lead).reduce(
      (accum, field) => `${accum}<td>${lead[field] ?? ''}</td>`,
      ''
    );

    return `${acc}<tr>${fields}</tr>`;
  }, '');

  return `<thead><tr>${headersTableFormatted}</tr></thead><tbody>${valuesTableFormatted}</tbody>`;
}

function downloadXls(content, filename) {
  const table = document.createElement('table');
  table.innerHTML = content;
  const downloadLink = document.createElement('a');
  downloadLink.download = filename;

  ExcellentExport.excel(downloadLink, table);

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

export { downloadXls, parseJsonToXls };
