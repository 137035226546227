import * as glyphs from './glyphs';

export const iconography = {
  size: {
    xsmall: 16,
    small: 18,
    base: 24,
    large: 32,
    xlarge: 48,
  },
  glyphs,
};
