import { dispatch } from 'reffects';
import { useCallback } from 'react';
import Dialog from 'design-system/components/Dialog/Dialog';
import {
  FlatButton,
  SecondaryButton,
} from 'design-system/components/Button/presets';
import FormattedMessageWithValidation from '../../FormattedMessageWithValidation/FormattedMessageWithValidation';
import { useDialog } from '../../../hooks/useDialog';
import { GENERIC_CONFIRMATION_MODAL } from './constants';

function DialogContent({ messages, actions, onClose }) {
  const { title, emphasis, body, confirmButton, cancelButton } = messages;

  const { confirm: confirmAction, cancel: cancelAction } = actions;

  const handleConfirmClick = useCallback(() => {
    if (confirmAction) {
      if (confirmAction instanceof Function) {
        confirmAction();
      } else {
        dispatch(confirmAction);
      }
    }
    onClose();
  }, [confirmAction]);

  const handleCancelClick = useCallback(() => {
    if (cancelAction) dispatch(cancelAction);
    onClose();
  }, [cancelAction]);

  return (
    <>
      <Dialog.Header>
        <FormattedMessageWithValidation id={title.id} values={title.values} />
      </Dialog.Header>
      {emphasis && (
        <Dialog.Emphasis>
          <FormattedMessageWithValidation
            id={emphasis.id}
            values={emphasis.values}
          />
        </Dialog.Emphasis>
      )}
      <Dialog.Content>
        <Dialog.Text>
          <FormattedMessageWithValidation id={body.id} values={body.values} />
        </Dialog.Text>
      </Dialog.Content>
      <Dialog.Footer>
        <FlatButton onClick={handleCancelClick}>
          {cancelButton ? (
            <FormattedMessageWithValidation
              id={cancelButton.id}
              values={cancelButton.values}
            />
          ) : (
            <FormattedMessageWithValidation id="notification_visibility_disable_cancel" />
          )}
        </FlatButton>
        <SecondaryButton onClick={handleConfirmClick}>
          {confirmButton ? (
            <FormattedMessageWithValidation
              id={confirmButton.id}
              values={confirmButton.values}
            />
          ) : (
            <FormattedMessageWithValidation id="notification_visibility_disable_confirm" />
          )}
        </SecondaryButton>
      </Dialog.Footer>
    </>
  );
}

function ConfirmationDialog() {
  const { open, parameters, closeDialog } = useDialog(
    GENERIC_CONFIRMATION_MODAL
  );

  return (
    <Dialog open={open}>
      {open && <DialogContent {...parameters} onClose={closeDialog} />}
    </Dialog>
  );
}

export default ConfirmationDialog;
