import styled, { css } from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { iconography } from 'design-system/tokens/iconography';
import { useInputBase } from 'design-system/hooks/useInputBase';
import InputBase from 'design-system/components/InputBase/InputBase';
import { noop, withStylesAndInnerRef } from '../../utils';
import { useInputChips } from './useInputChips';
import Chip from '../Chip';

const modIsLastSelected = () => css`
  ${Chip}:last-of-type {
    ${Chip.mods.selected()}
  }
`;

const Wrapper = styled(InputBase)`
  > ${InputBase.Box} {
    align-items: center;
    flex-wrap: wrap;
    row-gap: ${spacing.value(SPACING_SIZE.L)};
    column-gap: ${spacing.value(SPACING_SIZE.M)};
    display: flex;
  }

  ${Chip} {
    height: ${iconography.size.small}px;
    margin-left: calc(-0.5 * ${spacing.value(SPACING_SIZE.M)});
  }

  ${({ isLastSelected }) => isLastSelected && modIsLastSelected()}
`;

const Input = styled.input.attrs({
  type: 'text',
})`
  flex: 1;
  min-width: 50px;
`;

function InputChips({
  children,
  value: values = [],
  errors = [],
  innerRef,
  onChange = noop,
  ...props
}) {
  const {
    inputBaseProps: { error, ...inputBaseProps },
    boxProps: { icon, ...boxProps },
    inputProps,
  } = useInputBase(props);

  const {
    isLastSelected,
    deleteChipByIndex,
    inputProps: inputChipsProps,
  } = useInputChips({
    addKeyCodes: ['Space', 'Comma', 'Enter'],
    deleteKeyCodes: ['Backspace'],
    values,
    onChange,
  });

  return (
    <Wrapper
      error={errors.some(Boolean)}
      isLastSelected={isLastSelected}
      {...inputBaseProps}
    >
      <InputBase.Box {...boxProps}>
        {values.map((value, index) => (
          <Chip
            key={index}
            error={errors[index] !== undefined && errors[index]}
            onIconClick={() => deleteChipByIndex(index)}
          >
            {value}
          </Chip>
        ))}
        <Input ref={innerRef} {...inputProps} {...inputChipsProps} />
      </InputBase.Box>
    </Wrapper>
  );
}

export default withStylesAndInnerRef(InputChips);
