import styled from 'styled-components';

const Wrapper = styled.svg`
  flex-shrink: 0;
`;

function DarkLogo(props) {
  return (
    <Wrapper
      xmlns="http://www.w3.org/2000/svg"
      width="171"
      height="34"
      fill="none"
      viewBox="0 0 171 34"
      {...props}
    >
      <g clipPath="url(#clip0_859:3329)">
        <path
          d="M60.1715 13.3606C62.1705 13.3606 63.2222 12.1 63.2222 10.491C63.2342 10.1068 63.1665 9.72421 63.0234 9.36739C62.8804 9.01057 62.665 8.68722 62.3909 8.41768C62.1168 8.14815 61.7898 7.93826 61.4307 7.80122C61.0715 7.66418 60.6878 7.60295 60.3038 7.6214H56.682V13.3606H60.1715ZM60.3038 1.78467C63.4939 1.78467 65.8759 2.64136 67.3873 4.31298C68.962 6.01308 69.8029 8.26547 69.7276 10.5816C69.7633 11.7159 69.5688 12.8456 69.1558 13.9027C68.7428 14.9597 68.1199 15.9221 67.3247 16.7318C65.7296 18.3337 63.2222 19.1208 59.865 19.1208H56.682V27.0471H50.3438V1.78467H60.3038Z"
          fill="white"
        />
        <path
          d="M71.6917 27.026V9.13264H77.1662V11.3406C77.8591 10.1875 78.9491 9.32684 80.2315 8.92032C81.5139 8.51379 82.9006 8.5893 84.1313 9.13264L83.6786 14.5794C82.9185 14.3073 82.1178 14.166 81.3105 14.1614C78.6916 14.1614 77.3612 15.8748 77.3612 19.3086V27.026H71.6917Z"
          fill="white"
        />
        <path
          d="M96.5571 20.681C96.9307 20.2983 97.2242 19.8449 97.4203 19.3474C97.6165 18.8498 97.7113 18.3182 97.6994 17.7835C97.7021 17.2592 97.601 16.7396 97.4019 16.2546C97.2027 15.7695 96.9095 15.3288 96.5391 14.9577C96.1686 14.5867 95.7284 14.2927 95.2438 14.0927C94.7591 13.8927 94.2396 13.7906 93.7153 13.7925C93.1936 13.7817 92.6756 13.8817 92.1954 14.0858C91.7151 14.2899 91.2836 14.5935 90.9293 14.9765C90.5577 15.3379 90.264 15.7715 90.0663 16.2506C89.8687 16.7298 89.7712 17.2443 89.78 17.7626C89.7678 18.2979 89.8632 18.8302 90.0607 19.3279C90.2581 19.8257 90.5535 20.2787 90.9293 20.6601C91.2904 21.032 91.7239 21.3259 92.2031 21.5236C92.6823 21.7213 93.197 21.8185 93.7153 21.8093C94.2423 21.8212 94.7661 21.7254 95.2548 21.5278C95.7434 21.3302 96.1866 21.0349 96.5571 20.6601V20.681ZM86.806 24.3934C85.0998 22.613 84.1472 20.2424 84.1472 17.7765C84.1472 15.3106 85.0998 12.94 86.806 11.1597C88.6869 9.41846 91.1556 8.45117 93.7188 8.45117C96.282 8.45117 98.7507 9.41846 100.632 11.1597C102.359 12.9291 103.326 15.3038 103.326 17.7765C103.326 20.2492 102.359 22.6239 100.632 24.3934C98.7425 26.1181 96.2769 27.0744 93.7188 27.0744C91.1607 27.0744 88.6951 26.1181 86.806 24.3934Z"
          fill="white"
        />
        <path
          d="M115.203 13.7228C114.667 13.714 114.134 13.8143 113.638 14.0176C113.142 14.2208 112.692 14.5228 112.316 14.9052C111.94 15.2876 111.646 15.7424 111.452 16.2419C111.257 16.7415 111.166 17.2755 111.184 17.8113C111.175 18.884 111.579 19.9189 112.312 20.7019C112.683 21.0932 113.132 21.4016 113.63 21.6069C114.128 21.8122 114.664 21.9097 115.203 21.8929C115.738 21.9129 116.27 21.8168 116.765 21.6112C117.259 21.4056 117.702 21.0954 118.065 20.7019C118.8 19.9201 119.202 18.8838 119.187 17.8113C119.209 17.2774 119.121 16.7447 118.93 16.2456C118.739 15.7466 118.448 15.2919 118.075 14.9091C117.702 14.5264 117.255 14.2238 116.761 14.0199C116.267 13.8159 115.737 13.7148 115.203 13.7228ZM122.53 11.1457C124.173 12.9422 125.05 15.3086 124.975 17.7417C125.026 20.1947 124.142 22.5753 122.502 24.4003C121.732 25.2727 120.781 25.9664 119.715 26.4332C118.649 26.9 117.494 27.1285 116.331 27.1028C114.461 27.1427 112.649 26.4504 111.281 25.1735V33.0092H105.591V8.89604H111.163V10.5398C111.828 9.86133 112.624 9.32599 113.503 8.96665C114.382 8.60731 115.326 8.43158 116.275 8.45026C117.449 8.41821 118.615 8.64356 119.692 9.11045C120.769 9.57733 121.73 10.2744 122.509 11.1527"
          fill="white"
        />
        <path
          d="M136.551 13.7228C136.015 13.714 135.482 13.8143 134.986 14.0175C134.49 14.2208 134.04 14.5228 133.665 14.9051C133.289 15.2875 132.994 15.7423 132.8 16.2419C132.605 16.7415 132.514 17.2754 132.532 17.8113C132.517 18.8838 132.919 19.9201 133.653 20.7018C134.025 21.094 134.475 21.403 134.974 21.6083C135.474 21.8136 136.011 21.9106 136.551 21.8929C137.085 21.9128 137.616 21.8166 138.109 21.611C138.602 21.4054 139.045 21.0952 139.406 20.7018C140.14 19.9188 140.544 18.8839 140.535 17.8113C140.557 17.2774 140.469 16.7446 140.278 16.2456C140.087 15.7466 139.796 15.2918 139.423 14.9091C139.05 14.5264 138.603 14.2238 138.109 14.0198C137.615 13.8159 137.085 13.7148 136.551 13.7228ZM143.878 11.1457C145.516 12.9444 146.391 15.3099 146.316 17.7417C146.367 20.1947 145.483 22.5753 143.843 24.4003C143.074 25.2734 142.123 25.9677 141.057 26.4345C139.991 26.9013 138.836 27.1294 137.672 27.1028C135.802 27.1419 133.99 26.4497 132.622 25.1734V33.0092H126.939V8.89601H132.511V10.5398C133.175 9.86217 133.97 9.32734 134.848 8.96803C135.726 8.60872 136.668 8.43251 137.616 8.45024C138.795 8.41448 139.968 8.63798 141.051 9.10494C142.134 9.57189 143.102 10.2709 143.885 11.1527"
          fill="white"
        />
        <path
          d="M154.451 27.0261H148.719V8.48505H154.451V27.0261ZM148.252 4.24331C148.255 3.59429 148.45 2.9606 148.812 2.42211C149.174 1.88362 149.688 1.46441 150.288 1.21731C150.888 0.970217 151.548 0.906285 152.184 1.03358C152.821 1.16087 153.405 1.47371 153.864 1.93263C154.323 2.39156 154.636 2.97606 154.763 3.61248C154.891 4.2489 154.827 4.90875 154.58 5.5089C154.332 6.10904 153.913 6.62261 153.375 6.9849C152.836 7.34719 152.203 7.542 151.554 7.54476C151.117 7.55525 150.683 7.47703 150.278 7.31483C149.873 7.15263 149.504 6.90983 149.196 6.60115C148.887 6.29246 148.644 5.92431 148.482 5.51902C148.32 5.11372 148.242 4.67973 148.252 4.24331Z"
          fill="white"
        />
        <path
          d="M171 25.5634C169.264 26.5511 167.293 27.0516 165.296 27.0122C160.866 27.0122 158.653 24.5605 158.658 19.657V13.2143H155.914V8.50594H158.7V5.63632L164.362 2.43237V8.50594H169.969V13.2143H164.362V18.9814C164.362 20.8829 165.108 21.8162 166.633 21.8162C167.612 21.7758 168.568 21.5012 169.419 21.0152L171 25.5634Z"
          fill="white"
        />
        <path
          d="M5.94819 32.8834H0V14.2936L15.1421 3.84595L29.9847 14.2936V32.9043H9.29144L15.0516 26.9701H23.9808V17.3722L15.0934 11.1872L5.94819 17.3722V32.8834Z"
          fill="#0ACC86"
        />
        <path
          d="M32.4573 30.1745L32.3946 13.0961L18.2764 3.44944L25.1857 2.75293L36.901 10.7489L37.0334 24.8114L32.4573 30.1745Z"
          fill="#0ACC86"
        />
        <path
          d="M38.7955 22.7148V9.5229L28.2573 2.45333L34.0453 1.77075L42.8074 7.56571L42.7726 17.679L38.7955 22.7148Z"
          fill="#0ACC86"
        />
      </g>
      <defs>
        <clipPath id="clip0_859:3329">
          <rect
            width="171"
            height="32.0046"
            fill="white"
            transform="translate(0 0.997559)"
          />
        </clipPath>
      </defs>
    </Wrapper>
  );
}

export default styled(DarkLogo)``;
