import { useSelector } from 'reffects-store';
import PropertyDetail from './PropertyDetail';
import {
  doesPropertyForLeadBelongToProjectSelector,
  propertyForLeadSelector,
  propertySpacesSelector,
} from './selectors';
import ProjectDetail from './ProjectDetail';

function Detail() {
  const property = useSelector(propertyForLeadSelector);
  const propertyBelongsToProject = useSelector(
    doesPropertyForLeadBelongToProjectSelector
  );
  const propertySpaces = useSelector(propertySpacesSelector);

  if (!property) return null;
  return property.type === 'project' ? (
    <ProjectDetail project={property} />
  ) : (
    <PropertyDetail
      property={property}
      propertySpaces={propertySpaces}
      propertyBelongsToProject={propertyBelongsToProject}
    />
  );
}

export default Detail;
