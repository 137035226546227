import { effects as reffects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import { calendar } from '../../coeffects/calendar';
import { environment } from '../../coeffects/environment';
import { createDefaultDateRange } from './partials/KeyResults/createDefaultDateRange';
import { convertToISOStringDate } from '../../utils/dates';
import { XML_IMPORT_STATS_SUMMARY_REQUESTED } from '../../events';

export const OVERVIEW_PAGE_MOUNTED = 'OVERVIEW_PAGE_MOUNTED';
export const INITIALIZE_KPIS_DATE_RANGE_PICKER =
  'INITIALIZE_KPIS_DATE_RANGE_PICKER';
export const KPIS_DATA_REQUESTED = 'KPIS_DATA_REQUESTED';
export const KPIS_DATA_LOADED = 'KPIS_DATA_LOADED';
export const KPIS_DATA_LOAD_FAILED = 'KPIS_DATA_LOAD_FAILED';
export const PROJECTS_SUMMARY_REQUESTED = 'PROJECTS_SUMMARY_REQUESTED';
export const PROJECTS_SUMMARY_LOADED = 'PROJECTS_SUMMARY_LOADED';
export const PROJECTS_SUMMARY_LOAD_FAILED = 'PROJECTS_SUMMARY_LOAD_FAILED';

registerEventHandler(
  OVERVIEW_PAGE_MOUNTED,
  ({ state: { isDeveloper } }) =>
    reffects.dispatchMany(
      isDeveloper
        ? [
            INITIALIZE_KPIS_DATE_RANGE_PICKER,
            PROJECTS_SUMMARY_REQUESTED,
            XML_IMPORT_STATS_SUMMARY_REQUESTED,
          ]
        : [
            INITIALIZE_KPIS_DATE_RANGE_PICKER,
            XML_IMPORT_STATS_SUMMARY_REQUESTED,
          ]
    ),
  [state.get({ isDeveloper: 'publisher.isDeveloper' })]
);

registerEventHandler(
  INITIALIZE_KPIS_DATE_RANGE_PICKER,
  ({ calendar: { now } }) => {
    const defaultRange = createDefaultDateRange(now);

    return {
      ...state.set({
        'overview.kpisData.selectedDateRange': defaultRange,
      }),
      ...reffects.dispatch({ id: KPIS_DATA_REQUESTED, payload: defaultRange }),
    };
  },
  [calendar()]
);

registerEventHandler(
  KPIS_DATA_REQUESTED,
  ({ environment: { apiUrl } }, { period, translation }) => {
    const startDate = convertToISOStringDate(period.startDate);
    const endDate = convertToISOStringDate(period.endDate);

    return {
      ...http.get({
        url: `${apiUrl}/kpis?startDate=${startDate}&endDate=${endDate}`,
        successEvent: [KPIS_DATA_LOADED],
        errorEvent: [KPIS_DATA_LOAD_FAILED],
      }),
      ...state.set({
        'overview.kpisData.loading': true,
        'overview.kpisData.loadFailed': false,
        'overview.kpisData.selectedDateRange': {
          period,
          translation,
        },
      }),
    };
  },
  [environment()]
);

registerEventHandler(KPIS_DATA_LOADED, (_, [response]) =>
  state.set({
    'overview.kpisData.data': response.data,
    'overview.kpisData.loadFailed': false,
    'overview.kpisData.loading': false,
  })
);

registerEventHandler(KPIS_DATA_LOAD_FAILED, () =>
  state.set({
    'overview.kpisData.data': false,
    'overview.kpisData.loadFailed': true,
    'overview.kpisData.loading': false,
  })
);

registerEventHandler(
  PROJECTS_SUMMARY_REQUESTED,
  ({ environment: { apiUrl } }) => ({
    ...http.get({
      url: `${apiUrl}/projects-summary`,
      successEvent: [PROJECTS_SUMMARY_LOADED],
      errorEvent: [PROJECTS_SUMMARY_LOAD_FAILED],
    }),
    ...state.set({
      'overview.projectsSummary': {
        loaded: false,
        loading: true,
      },
    }),
  }),
  [environment()]
);

registerEventHandler(PROJECTS_SUMMARY_LOADED, (_, [response]) => ({
  ...state.set({
    'overview.projectsSummary': {
      loaded: true,
      loading: false,
      loadFailed: false,
      totalProjects: response.data.totalProjects,
      totalUnits: response.data.totalUnits,
    },
  }),
}));

registerEventHandler(PROJECTS_SUMMARY_LOAD_FAILED, () =>
  state.set({
    'overview.projectsSummary': {
      loaded: true,
      loading: false,
      loadFailed: true,
    },
  })
);
