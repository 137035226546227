import { createContext, useCallback, useMemo, useState } from 'react';
import { useEffectOnce, useUnmount } from 'react-use';
import { dispatch } from 'reffects';
import noop from '../../../utils/noop';
import { DATA_TABLE_UNMOUNT } from '../events/init';

export const DataTableContext = createContext({
  isContextReady: false,
  collectionName: '',
  defaultSorting: {
    field: null,
    direction: null,
  },
  defaultFilters: {},
  initialItemsPerPage: null,
  updateEvents: {
    collection: '',
    item: '',
    bulk: '',
  },
  registeredFilters: [],
  registerFilter: noop,
  unregisterFilter: noop,
});

export function DataTableProvider({
  collectionName,
  defaultSorting,
  defaultFilters,
  initialItemsPerPage,
  adapter,
  updateEvents,
  children,
}) {
  const [isContextReady, setIsContextReady] = useState(false);
  const [registeredFilters, setRegisteredFilters] = useState([]);

  const updateEventsEnsured = useMemo(
    () => (adapter ? adapter.registerEvents() : updateEvents),
    [adapter, updateEvents]
  );

  const registerFilter = useCallback((filterName) => {
    setRegisteredFilters((currentFilters) => [...currentFilters, filterName]);
  }, []);

  const unregisterFilter = useCallback((filterName) => {
    setRegisteredFilters((currentFilters) =>
      currentFilters.filter((filter) => filter !== filterName)
    );
  }, []);

  useEffectOnce(() => {
    setIsContextReady(true);
  });

  useUnmount(() => {
    dispatch({
      id: DATA_TABLE_UNMOUNT,
      payload: { collectionName },
    });
  });

  return (
    <DataTableContext.Provider
      value={{
        isContextReady,
        collectionName,
        defaultSorting,
        defaultFilters,
        initialItemsPerPage,
        updateEvents: updateEventsEnsured,
        registeredFilters,
        registerFilter,
        unregisterFilter,
      }}
    >
      {children}
    </DataTableContext.Provider>
  );
}
