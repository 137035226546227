import styled from 'styled-components';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import Anchor from 'design-system/components/Anchor';
import Logo from 'design-system/components/Logo/Logo';
import {
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import {
  color,
  COLOR_PALETTE,
  COLOR_SEMANTIC,
} from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Heading2 } from 'design-system/components/Typography/presets/Heading2';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import registerInterstitialEvents, { INTERSTITIAL_CLOSED } from './events';
import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  appleAppStoreLogoUrlSelector,
  googlePlayStoreLogoUrlSelector,
} from './selectors';
import useClickTracking from '../../hooks/useClickTracking';
import {
  androidAppDownloadLink,
  iosAppDownloadLink,
} from '../../utils/proppitWebRouter';

registerInterstitialEvents();

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;

  z-index: 5;

  ${Anchor} {
    ${typography.size(FONT_SIZE.S)};
    ${typography.weight(FONT_WEIGHT.MEDIUM)};
    text-decoration: underline;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  flex: 1;

  overflow-y: auto;

  ${color.background(COLOR_SEMANTIC.PAPER)}
  ${spacing.insetSquish(SPACING_SIZE.XXL)}

  ${Logo} {
    ${spacing.stack(SPACING_SIZE.XL)}
  }

  ${Heading2} {
    text-align: center;
    ${spacing.stack(SPACING_SIZE.S)}
  }

  ${Body1} {
    text-align: center;
    ${spacing.stack(SPACING_SIZE.XL)}
  }
`;

const AppStores = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${Anchor} {
    ${spacing.stack(SPACING_SIZE.L)}
  }
`;

const Footer = styled.footer`
  flex-grow: 0;

  position: sticky;
  bottom: 0;

  display: flex;
  justify-content: center;
  text-align: center;

  ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
  ${spacing.insetSquish(SPACING_SIZE.XXL)}
`;

function Interstitial() {
  const handleClose = () => dispatch(INTERSTITIAL_CLOSED);
  const googlePlayStoreLogoUrl = useSelector(googlePlayStoreLogoUrlSelector);
  const appleAppStoreLogoUrl = useSelector(appleAppStoreLogoUrlSelector);
  const { trackClick } = useClickTracking({
    sectionName: 'interstitial',
  });
  return (
    <Wrapper>
      <Content>
        <Logo />
        <Heading2 $color={COLOR_PALETTE.SECONDARY_BASE}>
          <FormattedMessageWithValidation id="interstitial_app_proppit_text1_1" />
        </Heading2>
        <Body1 $color={COLOR_PALETTE.NEUTRAL_A60}>
          <FormattedMessageWithValidation id="interstitial_app_proppit_text1_2" />
        </Body1>
        <AppStores>
          <Anchor
            href={iosAppDownloadLink()}
            target="_blank"
            onClick={() =>
              trackClick({
                eventName: 'download-ios-app',
              })
            }
          >
            <img src={appleAppStoreLogoUrl} alt="App Store" />
          </Anchor>
          <Anchor
            href={androidAppDownloadLink()}
            target="_blank"
            onClick={() =>
              trackClick({
                eventName: 'download-android-app',
              })
            }
          >
            <img src={googlePlayStoreLogoUrl} alt="Play Store" />
          </Anchor>
        </AppStores>
      </Content>
      <Footer>
        <Subtitle3 $color={COLOR_PALETTE.NEUTRAL_A60}>
          <FormattedMessageWithValidation
            id="interstitial_app_proppit_text2"
            values={{
              desktopVersion: (
                <Anchor onClick={handleClose}>
                  <FormattedMessageWithValidation id="interstitial_app_proppit_text2_variable" />
                </Anchor>
              ),
            }}
          />
        </Subtitle3>
      </Footer>
    </Wrapper>
  );
}

export default Interstitial;
