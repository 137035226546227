import styled, { css } from 'styled-components';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import HeadingCell from './HeadingCell';
import Cell, { CELL_ALIGNMENT } from './Cell';

const Wrapper = styled.table`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
`;

const Row = styled.tr``;

const TitleRow = styled.tr`
  ${color.background(COLOR_PALETTE.NEUTRAL_A05)}
`;

const EmptyCell = styled(Cell)`
  ${Cell.mods.empty()}
`;

const TitleCell = styled(Cell)`
  ${spacing.insetSquish(SPACING_SIZE.M)}
`;

const BenefitTitleCell = styled(Cell)`
  ${Cell.mods.alignment(CELL_ALIGNMENT.LEFT)}
`;

const BenefitValueCell = styled(Cell)`
  ${Cell.mods.alignment(CELL_ALIGNMENT.CENTER)}
`;

const SideSection = styled(BenefitValueCell)`
  ${spacing.inset(SPACING_SIZE.L)}
`;

const BenefitHighlightedValueCell = styled(Cell)`
  ${Cell.mods.highlight()}
  ${Cell.mods.alignment(CELL_ALIGNMENT.CENTER)}
`;

const IconContainer = styled.span`
  height: 1px;

  ${Icon} {
    ${Icon.mods.size(ICON_SIZE.BASE)}
    transform: translateY(-50%);
  }
`;

function PricingPlansTable({ title, plans, benefits, sideSection, ...rest }) {
  return (
    <Wrapper {...rest}>
      <tbody>
        <Row>
          <EmptyCell />
          {plans}
          {sideSection && (
            <SideSection rowSpan={benefits.length + 3}>
              {sideSection}
            </SideSection>
          )}
        </Row>
        <TitleRow>
          <TitleCell colSpan={plans.length + 1}>
            <Subtitle3>{title}</Subtitle3>
          </TitleCell>
        </TitleRow>
        {benefits.map((benefit) => (
          <Row key={benefit.id}>
            <BenefitTitleCell>
              <Body1 $weight={FONT_WEIGHT.LIGHT}>{benefit.name}</Body1>
            </BenefitTitleCell>
            {benefit.value.map(
              (benefitValue, index) =>
                ({
                  text: benefitValue.highlight ? (
                    <BenefitHighlightedValueCell key={index}>
                      <Body1 $weight={FONT_WEIGHT.MEDIUM}>
                        {benefitValue.value}
                      </Body1>
                    </BenefitHighlightedValueCell>
                  ) : (
                    <BenefitValueCell key={index}>
                      <Body1 $weight={FONT_WEIGHT.LIGHT}>
                        {benefitValue.value}
                      </Body1>
                    </BenefitValueCell>
                  ),
                  boolean: benefitValue.value ? (
                    <BenefitValueCell key={index}>
                      <IconContainer>
                        <Icon
                          glyph="check"
                          $color={COLOR_PALETTE.PRIMARY_BASE}
                        />
                      </IconContainer>
                    </BenefitValueCell>
                  ) : (
                    <BenefitValueCell key={index}>
                      <IconContainer>
                        <Icon
                          glyph="cross"
                          $color={COLOR_PALETTE.NEUTRAL_A20}
                        />
                      </IconContainer>
                    </BenefitValueCell>
                  ),
                  number:
                    benefitValue.value === 0 ? (
                      <BenefitValueCell key={index}>
                        <Body2>-</Body2>
                      </BenefitValueCell>
                    ) : (
                      <BenefitValueCell key={index}>
                        <Body2>{benefitValue.value}</Body2>
                      </BenefitValueCell>
                    ),
                }[benefitValue.type])
            )}
          </Row>
        ))}
        {sideSection && <Row />}
      </tbody>
    </Wrapper>
  );
}

const wideMod = () => css`
  ${EmptyCell} {
    width: 25%;
  }
`;

PricingPlansTable.mods = {
  wide: wideMod,
};

PricingPlansTable.HeadingCell = HeadingCell;

export default styled(PricingPlansTable)``;
