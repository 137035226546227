import { SecondaryButton } from 'design-system/components/Button/presets';
import Dialog from 'design-system/components/Dialog/Dialog';
import { useDialog } from '../../../../../../hooks/useDialog';
import { DISALLOWED_ACTION_MODAL_ID } from './constants';

export default function DisallowedActionModal() {
  const { open, parameters, closeDialog } = useDialog(
    DISALLOWED_ACTION_MODAL_ID
  );
  return (
    <Dialog open={open}>
      <Dialog.Header onClose={closeDialog}>{parameters?.title}</Dialog.Header>
      <Dialog.Content>{parameters?.text}</Dialog.Content>
      <Dialog.Footer>
        <SecondaryButton onClick={closeDialog}>Close</SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}
