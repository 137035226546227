import { Body1 } from 'design-system/components/Typography/presets/Body1';
import Table from 'design-system/components/Table';
import { useColumn } from '../../../partials/DataTable/hooks/useColumn';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { sortOperations } from './operations';

export function OperationsColumn(props) {
  const { value: operations = [] } = useColumn(props);
  return (
    <Table.Cell>
      {sortOperations(operations).map(({ type }, index) => (
        <Body1 key={type} $noWrap>
          <FormattedMessageWithValidation
            id={
              {
                sell: 'properties_list_operation_type_sale',
                rent: 'properties_list_operation_type_rent',
              }[type]
            }
          />
          {operations.length - 1 !== index && <span>&nbsp;/&nbsp;</span>}
        </Body1>
      ))}
    </Table.Cell>
  );
}
