import { withoutEventPropagation } from 'design-system/utils/withoutEventPropagation';
import { Link } from 'react-router-dom';
import { useSelector } from 'reffects-store';
import Table from 'design-system/components/Table';
import Tooltip from 'design-system/components/Tooltip';
import { FlatButton } from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';
import { projectLinksSelector } from '../../../../pages/Overview/partials/ProjectsOverview/LastProjectsTable/selectors';
import { useColumn } from '../../hooks/useColumn';
import FormattedMessageWithValidation from '../../../FormattedMessageWithValidation/FormattedMessageWithValidation';
import useTracking from '../../../../hooks/useTracking';
import DropdownLinksMenu from '../../../DropdownSitesMenu';

function SitesAction({ itemId, section }) {
  const sites = useSelector((state) =>
    projectLinksSelector(state, { projectId: itemId })
  );

  return (
    <DropdownLinksMenu
      active
      sites={sites.map(({ site, link }) => ({ site, detailUrl: link }))}
      section={section}
      elementName="project-site-link"
      eventName="click-project-site-link"
    />
  );
}

function NewUnitAction({ itemId, section }) {
  const trackClickRef = useTracking({
    trackClick: {
      eventName: 'create-new-unit-for-project',
    },
    trackImpression: {
      elementName: 'new-unit-for-project-btn',
    },
    sectionName: section,
  });
  return (
    <Tooltip
      title={
        <FormattedMessageWithValidation id="overview_developer_projects_summary_table_create_unit_tooltip" />
      }
    >
      <FlatButton
        icon={<Icon glyph="plus" />}
        forwardedAs={Link}
        to={`/units/new-unit?projectId=${itemId}`}
        onClick={withoutEventPropagation()}
        onAuxClick={withoutEventPropagation()}
        ref={trackClickRef}
      />
    </Tooltip>
  );
}

export function ActionsColumn({ section, ...props }) {
  useColumn(props);
  const { itemId } = props;

  return (
    <Table.Cell>
      <SitesAction itemId={itemId} section={section} />
      <NewUnitAction itemId={itemId} section={section} />
    </Table.Cell>
  );
}
