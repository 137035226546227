import styled from 'styled-components';
import { TABLE_ALIGNMENT } from 'design-system/components/Table';
import { Overline } from 'design-system/components/Typography/presets/Overline';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { DataTable, SnippetColumn } from '../../../../partials/DataTable';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { RenewalPriceColumn } from './RenewalPriceColumn';
import { BillingDateColumn } from './BillingDateColumn';
import { ViewDetailsColumn } from './ViewDetailsColumn';
import { TableSkeleton } from './PromotedProjectsTableSkeleton';
import PromotedProjectDetailsDialog from './PromotedProjectDetailsDialog';
import { BillingCycleColumn } from './BillingCycleColumn';
import StatusColumn from './StatusColumn';

const Wrapper = styled.div`
  ${Overline} {
    display: block;
    ${spacing.stack(SPACING_SIZE.M)}
  }
`;

export function PromotedProjectsTable() {
  return (
    <Wrapper>
      <Overline $color={COLOR_PALETTE.NEUTRAL_BASE}>
        <FormattedMessageWithValidation id="developer_subscription_promoted_projects" />
      </Overline>
      <DataTable
        loadingComponent={<TableSkeleton />}
        noItemsMessage="No promoted projects"
        allowPagination
      >
        <SnippetColumn
          id="snippet"
          title={
            <FormattedMessageWithValidation id="projects_list_project_header" />
          }
          source={{
            title: 'name',
            address: 'address',
            image: 'image',
          }}
          maxWidth={225}
        />
        <BillingCycleColumn
          id="billingCycleInMonths"
          title={
            <FormattedMessageWithValidation id="subscription_summary_contract_type" />
          }
          source="billingCycleInMonths"
        />
        <BillingDateColumn
          id="billingDate"
          title={
            <FormattedMessageWithValidation id="subscription_summary_next_billing_date" />
          }
          source="nextBillingDate"
        />
        <RenewalPriceColumn
          id="price"
          title={
            <FormattedMessageWithValidation id="promoted_projects_renewal_price" />
          }
          source={{
            amount: 'price.amount',
            currency: 'price.currency',
          }}
        />
        <StatusColumn id="status" title="" source="status" />
        <ViewDetailsColumn
          id="details"
          title=""
          source={{
            title: 'name',
            price: 'price',
            tax: 'taxApplied',
            totalPrice: 'totalPrice',
            billingDate: 'nextBillingDate',
            billingCycleInMonths: 'billingCycleInMonths',
          }}
          alignment={TABLE_ALIGNMENT.RIGHT}
        />
      </DataTable>
      <PromotedProjectDetailsDialog />
    </Wrapper>
  );
}
