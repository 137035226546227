import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { dispatch } from 'reffects';
import styled from 'styled-components';
import DropdownMenu from 'design-system/components/DropdownMenu';
import Menu from 'design-system/components/Menu/Menu';
import Button, {
  BUTTON_SIZE,
  BUTTON_VARIANT,
} from 'design-system/components/Button';
import Icon from 'design-system/components/Icon';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  DOWNLOAD_PROPERTIES_CSV_REQUESTED,
  DOWNLOAD_PROPERTIES_EXCEL_REQUESTED,
} from './events';
import useTracking from '../../../../hooks/useTracking';

const DropdownButton = styled(Button)`
  ${Button.mods.variant(BUTTON_VARIANT.OUTLINE)}
  ${Button.mods.size(BUTTON_SIZE.BASE)}
`;

function ExportOptions() {
  const ref = useTracking({
    trackClick: {
      eventName: 'export-listings-data',
    },
    trackImpression: {
      elementName: 'export-listings-btn',
    },
    sectionName: 'listings-table',
  });
  return (
    <DropdownMenu
      button={
        <DropdownButton icon={<Icon glyph="download" />} ref={ref}>
          <FormattedMessageWithValidation id="excel_button_export_data" />
        </DropdownButton>
      }
      onConfirm={({ value }) => dispatch(value)}
    >
      <Menu>
        <Menu.Item
          id="download-excel"
          label={
            <Body1 $noWrap>
              <FormattedMessageWithValidation id="excel_button_download_excel" />
            </Body1>
          }
          value={DOWNLOAD_PROPERTIES_EXCEL_REQUESTED}
        />
        <Menu.Item
          id="download-csv"
          label={
            <Body1 $noWrap>
              <FormattedMessageWithValidation id="excel_button_download_csv" />
            </Body1>
          }
          value={DOWNLOAD_PROPERTIES_CSV_REQUESTED}
        />
      </Menu>
    </DropdownMenu>
  );
}

export default ExportOptions;
