import * as React from "react";
const SvgEdit = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={117}
    height={107}
    fill="none"
    {...props}
  >
    <path
      fill="#0ACC86"
      fillRule="evenodd"
      d="M58.5 10a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 1 0v9a.5.5 0 0 1-.5.5"
      clipRule="evenodd"
    />
    <path
      fill="#0ACC86"
      fillRule="evenodd"
      d="M61.906 10.25h-6.68c-.614 0-1.113.487-1.113 1.086v59.1h8.906v-59.1c0-.6-.498-1.085-1.113-1.085m-6.68-1.086c-1.23 0-2.226.973-2.226 2.172v60.186h11.132V11.336c0-1.2-.997-2.172-2.226-2.172z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      stroke="#0ACC86"
      d="M49.5 21.807a3.5 3.5 0 0 1 3.5-3.5h12.038a3.5 3.5 0 0 1 3.5 3.5v81.842H49.5z"
    />
    <path
      fill="#0ACC86"
      d="M49 21.5c0-1.195.834-3 2-3h14.5c1.166 0 2.5 1.305 2.5 2.5v83H49z"
      opacity={0.2}
    />
    <path
      fill="#E7FAF3"
      d="M46 22.935a1 1 0 0 1 1-1h22.781a1 1 0 0 1 1 1v42a1 1 0 0 1-1 1H47a1 1 0 0 1-1-1z"
    />
    <path
      fill="#0ACC86"
      fillRule="evenodd"
      d="M48.167 22.07h21.666c.599 0 1.084.478 1.084 1.069v81.792H47.083V23.139c0-.591.485-1.07 1.084-1.07M69.833 21C71.03 21 72 21.957 72 23.14V106H46V23.139c0-1.181.97-2.139 2.167-2.139z"
      clipRule="evenodd"
    />
    <path fill="#0ACC86" d="M72 25a4 4 0 0 0-4-4v86h4z" opacity={0.3} />
    <path
      fill="#0ACC86"
      fillRule="evenodd"
      d="M49.5 99a.5.5 0 0 1-.5-.5v-69a.5.5 0 0 1 1 0v69a.5.5 0 0 1-.5.5M52.5 99a.5.5 0 0 1-.5-.5v-69a.5.5 0 0 1 1 0v69a.5.5 0 0 1-.5.5M55.5 99a.5.5 0 0 1-.5-.5v-69a.5.5 0 0 1 1 0v69a.5.5 0 0 1-.5.5M58.5 99a.5.5 0 0 1-.5-.5v-69a.5.5 0 0 1 1 0v69a.5.5 0 0 1-.5.5M61.5 99a.5.5 0 0 1-.5-.5v-69a.5.5 0 0 1 1 0v69a.5.5 0 0 1-.5.5M64.5 99a.5.5 0 0 1-.5-.5v-69a.5.5 0 0 1 1 0v69a.5.5 0 0 1-.5.5M67.5 99a.5.5 0 0 1-.5-.5v-69a.5.5 0 0 1 1 0v69a.5.5 0 0 1-.5.5"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      stroke="#0ACC86"
      d="M11.5 57a1.5 1.5 0 0 1 1.5-1.5h68a1.5 1.5 0 0 1 1.5 1.5v45.5h-71z"
    />
    <rect
      width={50}
      height={10}
      x={18.5}
      y={62.5}
      fill="#9DEBCF"
      stroke="#0ACC86"
      rx={1.5}
    />
    <path stroke="#0ACC86" d="M14.5 58.5h65v41h-65z" />
    <path
      fill="#fff"
      stroke="#0ACC86"
      strokeLinecap="round"
      d="M95.5 103v3a.5.5 0 0 1-.5.5H1a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5h94a.5.5 0 0 1 .5.5Z"
    />
    <rect width={37} height={1} x={25} y={77} fill="#0ACC86" rx={0.5} />
    <rect width={15} height={1} x={19} y={82} fill="#0ACC86" rx={0.5} />
    <rect width={15} height={1} x={41} y={82} fill="#0ACC86" rx={0.5} />
    <rect width={37} height={1} x={19} y={87} fill="#0ACC86" rx={0.5} />
    <rect width={37} height={1} x={19} y={92} fill="#0ACC86" rx={0.5} />
    <path
      fill="#6CE0B6"
      d="m68.464 86.89-.663 4.272a1 1 0 0 0 1.183 1.134l4.207-.678z"
    />
    <path
      fill="#fff"
      stroke="#0ACC86"
      d="M103.708 45.768a1.5 1.5 0 0 1 2.122 0l8.485 8.485a1.5 1.5 0 0 1 0 2.121L79.313 91.376 68.707 80.77z"
    />
    <path
      fill="#fff"
      stroke="#0ACC86"
      d="m78.625 91.661.009-.001.009-.002c1.326-.263 1.823-1.9.866-2.856l-8.452-8.452c-.979-.979-2.655-.431-2.868.937l-.78 5.032-.782 5.033a1.692 1.692 0 0 0 1.992 1.92z"
    />
    <path
      fill="#0ACC86"
      d="M103.355 45.414a2 2 0 0 1 2.828 0l8.486 8.486a2 2 0 0 1 0 2.828l-6.364 6.364-11.314-11.314z"
      opacity={0.3}
    />
    <rect
      width={15.255}
      height={1}
      x={97.707}
      y={52}
      fill="#0ACC86"
      rx={0.5}
      transform="rotate(45 97.707 52)"
    />
    <rect
      width={22}
      height={1}
      x={103.263}
      y={57.707}
      fill="#0ACC86"
      rx={0.5}
      transform="rotate(135 103.263 57.707)"
    />
    <path
      fill="#6CE0B6"
      d="m68 85-.962 6.197a1.45 1.45 0 0 0 1.716 1.646l6.106-.983z"
    />
  </svg>
);
export default SvgEdit;
