export function propertiesSummarySelector(state) {
  return state.propertiesSummary ?? {};
}

export function propertiesWithBoostingSelector(state) {
  const { boostedProperties = 0 } = propertiesSummarySelector(state);
  return boostedProperties;
}

export function maxBoostablePropertiesSelector(state) {
  const { maxBoostableProperties = 0 } = propertiesSummarySelector(state);
  return maxBoostableProperties;
}

export function propertiesWithSuperboostingSelector(state) {
  const { superboostedProperties = 0 } = propertiesSummarySelector(state);
  return superboostedProperties;
}

export function maxSuperboostablePropertiesSelector(state) {
  const { maxSuperboostableProperties = 0 } = propertiesSummarySelector(state);
  return maxSuperboostableProperties;
}

export function publishedPropertiesSelector(state) {
  const { publishedProperties = 0 } = propertiesSummarySelector(state);
  return publishedProperties;
}

export function createdPropertiesSelector(state) {
  const { createdProperties = 0 } = propertiesSummarySelector(state);
  return createdProperties;
}

export function propertiesWithoutBoostingSelector(state) {
  const publishedProperties = publishedPropertiesSelector(state);
  const boostedProperties = propertiesWithBoostingSelector(state);
  return publishedProperties - boostedProperties;
}
