import { SPACING_SIZE } from 'design-system/styles/spacing';
import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import Table from 'design-system/components/Table';
import DropdownMenu from 'design-system/components/DropdownMenu';
import Tooltip from 'design-system/components/Tooltip';
import Menu from 'design-system/components/Menu/Menu';
import { FlatButton } from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';
import { useColumn } from '../../../../../../../partials/DataTable/hooks/useColumn';
import ImpersonateButton from '../../../../../partials/ImpersonateButton/ImpersonateButton';
import { ChangePaymentMethodAction } from '../../Actions/ChangePaymentMethodAction';
import { RequestSubscriptionChangeAction } from '../../Actions/RequestSubscriptionChangeAction';
import { canMakeManualChanges } from '../../Actions/canMakeManualChanges';
import { ResendFirstPaymentEmailAction } from '../../Actions/ResendFirstPaymentEmailAction';
import { EditListingRulesAction } from '../../Actions/EditListingRulesAction';
import { ChangeSubscriptionAction } from '../../Actions/ChangeSubscriptionAction';
import { SendPropertiesToWasiAction } from '../../Actions/SendPropertiesToWasiAction';
import { DisassociatePublisherToMultiaccount } from '../../Actions/DisassociatePublisher/DisassociatePublisherToMultiaccount';
import { shouldShowDisassociatePublisherToMultiaccount } from '../../Actions/DisassociatePublisher/shouldShowDisassociatePublisherToMultiaccount';
import { AssociatePublisherToMultiaccount } from '../../Actions/DisassociatePublisher/AssociatePublisherToMultiaccount';
import { shouldShowAssociatePublisherToMultiaccount } from '../../Actions/DisassociatePublisher/shouldShowAssociatePublisherToMultiaccount';
import {
  showAssociatePublisherSelector,
  showCancelSubscriptionRequestSelector,
  showChangePaymentMethodSelector,
  showDisassociatePublisherSelector,
  showMarkAsFreeFromBeginningSelector,
  showMarkAsTestSelector,
  showMoveToFreeSelector,
  showSendPropertiesToWasiSelector,
} from './selectors';
import { MoveToFreeAction } from '../../Actions/MoveToFree/MoveToFreeAction';
import { MarkAsFreeFromBeginningAction } from '../../Actions/MarkAsFreeFromBeginning/MarkAsFreeFromBeginningAction';
import { CancelSubscriptionRequestAction } from '../../Actions/CancelSubscriptionRequestAction';
import MarkAsTestAction from '../../Actions/MarkAsTestAction';

const Wrapper = styled(Table.Cell)`
  > div {
    gap: ${SPACING_SIZE.S}px;
  }
`;

export function ActionsColumn({ section, ...props }) {
  const { value: agency } = useColumn(props);
  const showChangePaymentMethod = useSelector(showChangePaymentMethodSelector);
  const showAssociatePublisherByRole = useSelector(
    showAssociatePublisherSelector
  );
  const showDisassociatePublisherByRole = useSelector(
    showDisassociatePublisherSelector
  );
  const showSendPropertiesToWasi = useSelector(
    showSendPropertiesToWasiSelector
  );
  if (!agency) {
    return null;
  }
  const allowManualChanges = canMakeManualChanges(
    agency.subscriptionChangeRequests
  );
  const showRequestSubscriptionChangeAction =
    !agency.hasActiveFirstPaymentCheckout;
  const showAssociatePublisherToMultiaccount =
    shouldShowAssociatePublisherToMultiaccount(agency);
  const showDisassociatePublisherToMultiaccount =
    shouldShowDisassociatePublisherToMultiaccount(agency);
  const showWasiActionButton = ['MX', 'CO'].includes(agency.country);
  const showCancelSubscriptionRequest = useSelector((state) =>
    showCancelSubscriptionRequestSelector(state, agency)
  );
  const showMoveToFree = useSelector((state) =>
    showMoveToFreeSelector(state, agency.planType)
  );
  const showMarkAsFreeFromBeginning = useSelector((state) =>
    showMarkAsFreeFromBeginningSelector(state, agency.planType)
  );
  const showMarkAsTest = useSelector((state) =>
    showMarkAsTestSelector(state, agency)
  );

  return (
    <Wrapper>
      <ImpersonateButton publisherId={agency.id} />
      <DropdownMenu
        tooltip={<Tooltip title="Actions" />}
        button={<FlatButton icon={<Icon glyph="moreVert" />} />}
      >
        <Menu>
          <ResendFirstPaymentEmailAction publisher={agency} />
          {showRequestSubscriptionChangeAction && (
            <RequestSubscriptionChangeAction publisher={agency} />
          )}
          <ChangeSubscriptionAction publisher={agency} />
          {showChangePaymentMethod && allowManualChanges && (
            <ChangePaymentMethodAction publisher={agency} />
          )}
          <EditListingRulesAction publisher={agency} />
          {showSendPropertiesToWasi && showWasiActionButton && (
            <SendPropertiesToWasiAction publisher={agency} />
          )}
          {showAssociatePublisherToMultiaccount &&
            showAssociatePublisherByRole && (
              <AssociatePublisherToMultiaccount publisher={agency} />
            )}
          {showDisassociatePublisherToMultiaccount &&
            showDisassociatePublisherByRole && (
              <DisassociatePublisherToMultiaccount publisher={agency} />
            )}
          {showCancelSubscriptionRequest && (
            <CancelSubscriptionRequestAction publisher={agency} />
          )}
          {showMoveToFree && <MoveToFreeAction publisher={agency} />}
          {showMarkAsFreeFromBeginning && (
            <MarkAsFreeFromBeginningAction publisher={agency} />
          )}
          {showMarkAsTest && <MarkAsTestAction publisher={agency} />}
        </Menu>
      </DropdownMenu>
    </Wrapper>
  );
}
