import { css } from 'styled-components';
import { spacing as spacingTokens } from '../tokens/spacing';

export const SPACING_SIZE = {
  NONE: 0,
  XXS: spacingTokens.xxs,
  XS: spacingTokens.xs,
  S: spacingTokens.s,
  M: spacingTokens.m,
  L: spacingTokens.l,
  XL: spacingTokens.xl,
  XXL: spacingTokens.xxl,
  XXXL: spacingTokens.xxxl,
};

const value = (size) =>
  css`
    ${size}px
  `;

const inset = (size) => css`
  padding: ${size}px;
`;

const insetSquish = (size) => css`
  padding: ${size / 2}px ${size}px;
`;

const insetStretch = (size) => css`
  padding: ${size + size / 2}px ${size}px;
`;

const insetSandwich = (size) => css`
  padding: ${size}px 0;
`;

const stack = (size, excludeLast = true) => css`
  margin-bottom: ${size}px;

  ${excludeLast &&
  css`
    &:last-child {
      margin-bottom: initial;
    }
  `}
`;

const inline = (size, excludeLast = true) => css`
  margin-right: ${size}px;

  ${excludeLast &&
  css`
    &:last-child {
      margin-right: initial;
    }
  `}
`;

export const spacing = {
  value,
  inset,
  insetSquish,
  insetStretch,
  insetSandwich,
  stack,
  inline,
};
