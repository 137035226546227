import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import Dialog from 'design-system/components/Dialog/Dialog';
import {
  FlatButton,
  SecondaryButton,
} from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import { useDialog } from '../../../../../../../hooks/useDialog';
import { DISASSOCIATE_MODAL } from '../constants';
import { DISASSOCIATE_PUBLISHER } from './events';
import { disassociatePublisherIsInProgressSelector } from './selectors';
import useUpdateTableEvent from '../../../../../../../partials/DataTable/hooks/useUpdateTableEvent';

export default function DisassociateModal({ publisherId }) {
  const updateTableEvent = useUpdateTableEvent();
  const { open, closeDialog } = useDialog(DISASSOCIATE_MODAL);
  const isDisabled = useSelector(disassociatePublisherIsInProgressSelector);

  return (
    <Dialog open={open}>
      <Dialog.Header onClose={closeDialog}>
        Disassociate publisher
      </Dialog.Header>
      <Dialog.Content>
        Are you sure you want to disassociate this publisher?
      </Dialog.Content>
      <Dialog.Footer>
        <FlatButton onClick={closeDialog} $size={BUTTON_SIZE.SMALL}>
          Cancel
        </FlatButton>
        <SecondaryButton
          onClick={() =>
            dispatch({
              id: DISASSOCIATE_PUBLISHER,
              payload: { publisherId, successEvent: updateTableEvent },
            })
          }
          disabled={isDisabled}
          $size={BUTTON_SIZE.SMALL}
        >
          Disassociate
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}
