import { subscribe } from 'reffects-store';
import { dispatch } from 'reffects';
import { useMount } from 'react-use';
import MarkdownDocument from 'design-system/components/MarkdownDocument';
import { MARKDOWN_DOCUMENT_LOAD } from './events';

export default subscribe(
  ({ content, id, path }) => {
    useMount(() => {
      dispatch({ id: MARKDOWN_DOCUMENT_LOAD, payload: { id, path } });
    });

    return <MarkdownDocument content={content} />;
  },
  (state, { id }) => ({
    content: state?.markdownDocument?.[id],
  })
);
