import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import Autocomplete from 'design-system/components/Autocomplete/Autocomplete';
import BaseMenu from 'design-system/components/Menu/partials/BaseMenu';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import Typography from 'design-system/components/Typography/Typography';
import SearchField from './SearchField';
import { unitTableSuggestionsSelector } from './selectors';
import {
  UNITS_SUGGESTER_CLEAR,
  UNITS_SUGGESTER_SEARCH_SELECTED,
} from './events';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import noop from '../../../../../utils/noop';

const QueryOption = styled(BaseMenu.BaseItem)`
  outline: none;
  align-items: flex-start;
  ${spacing.inset(SPACING_SIZE.NONE)}

  &:hover {
    ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
  }
  &:first-of-type:hover,
  &[aria-selected='true'] {
    ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
  }
`;

const QueryOptionTitle = styled.div`
  display: flex;
  padding: 10px 10px 10px 0;
  overflow: hidden;

  ${Body1}:first-of-type {
    margin-bottom: 0;
    ${spacing.inline(3)}
    white-space: nowrap;
  }
  ${Body1}:last-of-type {
    ${Typography.mods.ellipse()}
  }
`;

const IconWrapper = styled.span`
  position: relative;
  ${spacing.inset(SPACING_SIZE.S)}

  ${Icon} {
    ${Icon.mods.size(ICON_SIZE.BASE)}
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
  }
`;

const iconMap = new Map([
  ['referenceId', 'tag'],
  ['title', 'textFormat'],
  ['address', 'dropPin'],
  ['projectName', 'building'],
]);

const EMPTY_TEXT = '';

function SearchFilter({ query, onQueryChange = noop }) {
  const intl = useIntl();
  const suggestedOptions = useSelector((state) =>
    unitTableSuggestionsSelector(state, query)
  );

  const queryItem = {
    type: 'query',
    text: query,
  };

  return (
    <Autocomplete
      value={query}
      onValueChange={onQueryChange}
      onOptionSelected={handleSelectValue}
      options={[queryItem, ...suggestedOptions]}
      optionToString={(option) => (option === undefined ? '' : option.text)}
      renderInput={({ value, getInputProps, onClear }) => (
        <SearchField
          {...getInputProps()}
          value={value}
          placeholder={intl.formatMessage({
            id: 'properties_list_full_text_search',
          })}
          onRemove={() => {
            onClear();
            onQueryChange(EMPTY_TEXT);
            dispatch(UNITS_SUGGESTER_CLEAR);
          }}
        />
      )}
      renderOptions={({ options, getOptionProps }) =>
        options.map((option, index) => {
          if (option.type === 'query') {
            return (
              <QueryOption
                key="query"
                {...getOptionProps({
                  index,
                  option,
                  refKey: 'innerRef',
                })}
              >
                <IconWrapper>
                  <Icon glyph="magnifierLens" />
                </IconWrapper>
                <QueryOptionTitle>
                  <Body1>{option.text}</Body1>{' '}
                  <Body1 $color={COLOR_PALETTE.NEUTRAL_A40}>
                    -{' '}
                    <FormattedMessageWithValidation id="units_list_full_text_search" />
                  </Body1>
                </QueryOptionTitle>
              </QueryOption>
            );
          }

          return (
            <Autocomplete.Option
              key={`${option.type}_${option.text}`}
              title={option.text}
              icon={<Icon glyph={iconMap.get(option.type)} />}
              {...getOptionProps({
                index,
                option,
                refKey: 'innerRef',
              })}
            />
          );
        })
      }
    />
  );
}

function handleSelectValue(value) {
  if (value.type === 'query') {
    dispatch({
      id: UNITS_SUGGESTER_SEARCH_SELECTED,
      payload: { type: 'fullSearchValue', text: value.text },
    });
  } else {
    dispatch({ id: UNITS_SUGGESTER_SEARCH_SELECTED, payload: value });
  }
}

export default SearchFilter;
