import {createSelector} from 'reselect';
import {createCachedSelector} from 're-reselect';
import {collectionItemsByIdSelector, collectionNameParameter,} from './collection';

export const itemIdParameter = (_, { itemId }) => itemId;

const itemCacheKey = createSelector(
  collectionNameParameter,
  itemIdParameter,
  (collectionName, itemId) => `${collectionName}_${itemId}`
);

export const itemByIdSelector = createCachedSelector(
  collectionItemsByIdSelector,
  itemIdParameter,
  (itemsById, itemId) => itemsById[itemId]
)(itemCacheKey);
