import styled from 'styled-components';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import { useController, useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { debounce } from 'lodash';
import Autocomplete from 'design-system/components/Autocomplete/Autocomplete';
import InputText from 'design-system/components/InputText';
import Icon from 'design-system/components/Icon';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { useRegisterFieldErrors } from '../FieldGroup';
import {
  displayableProjectNameSelector,
  projectNameSuggestionLoadingSelector,
  projectNameSuggestionsSelector,
} from './selectors';
import {
  MINIMUM_LENGTH_TO_RETRIEVE_SUGGESTIONS,
  PROJECT_NAME_AUTOCOMPLETE_CLEARED,
  PROJECT_NAME_SEARCH_SUGGESTIONS,
  PROJECT_NAME_SUGGESTIONS_SELECTED,
  PROJECT_NAME_TEXT_INTRODUCED,
} from './events';
import useInternationalization from '../../../../hooks/useInternationalization';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { PROJECT_INPUT_NAME } from '../../constants';
import { supportEmailSelector } from '../../../../selectors/config';
import { projectSelector } from '../../selectors';

const Wrapper = styled.div`
  grid-column: 1 / span 6;
`;

const NoProjectsFoundMessage = styled.div`
  display: flex;
  flex-direction: column;

  ${Body2} {
    ${color.text(COLOR_PALETTE.BLUEBERRY_BASE)}
  }
`;

const optionToString = (option) => (option === undefined ? '' : option.name);

function ProjectNameField() {
  const intl = useInternationalization();
  const itemsSuggestions = useSelector(projectNameSuggestionsSelector);
  const suggestionsLoading = useSelector(projectNameSuggestionLoadingSelector);
  const project = useSelector(projectSelector);
  const displayableProjectName = useSelector(displayableProjectNameSelector);
  const supportEmail = useSelector(supportEmailSelector);

  const {
    control,
    formState: { errors },
  } = useFormContext();
  const {
    field: { onChange },
  } = useController({
    name: PROJECT_INPUT_NAME,
    control,
  });

  useRegisterFieldErrors({
    name: PROJECT_INPUT_NAME,
    errors: errors[PROJECT_INPUT_NAME],
  });

  useEffect(() => {
    onChange(project);
  }, [project]);

  return (
    <Wrapper>
      <Autocomplete
        value={displayableProjectName}
        options={itemsSuggestions}
        onValueChange={onQueryChange}
        onOptionSelected={handleSelectOption}
        optionToString={optionToString}
        isLoading={suggestionsLoading}
        minCharactersToOpen={MINIMUM_LENGTH_TO_RETRIEVE_SUGGESTIONS}
        renderInput={({ value, getInputProps, onClear }) => (
          <InputText
            label={
              <FormattedMessageWithValidation id="property_project_name_field_title" />
            }
            placeholder={intl.formatMessageWithValidation({
              id: 'propspage_placeholder_project_name',
            })}
            error={Boolean(errors[PROJECT_INPUT_NAME])}
            tip={errors[PROJECT_INPUT_NAME]?.message}
            icon={displayableProjectName && <Icon glyph="cross" />}
            onIconClick={
              displayableProjectName &&
              (() => {
                onClear();
                dispatch(PROJECT_NAME_AUTOCOMPLETE_CLEARED);
              })
            }
            {...getInputProps({ value, autoComplete: 'nope' })}
          />
        )}
        renderOptions={({ options, getOptionProps }) =>
          options.map((option, index) => (
            <Autocomplete.Option
              key={option.id}
              title={option.name}
              subtitle={option.address}
              icon={<Icon glyph="building" />}
              {...getOptionProps({
                index,
                option,
                refKey: 'innerRef',
              })}
            />
          ))
        }
        emptyStateMessage={
          <NoProjectsFoundMessage>
            <Body1>
              <FormattedMessageWithValidation id="property_project_name_no_results" />
            </Body1>
            <Body2
              as="a"
              href={`mailto:${supportEmail}`}
              rel="noreferrer"
              target="_blank"
            >
              <FormattedMessageWithValidation
                id="property_project_name_no_results_contact_info"
                values={{ contactEmail: supportEmail }}
              />
            </Body2>
          </NoProjectsFoundMessage>
        }
        loadingStateMessage={
          <FormattedMessageWithValidation id="publishing_form_suggester_component_loading" />
        }
      />
    </Wrapper>
  );
}

function handleSelectOption(selectedOption) {
  dispatch({ id: PROJECT_NAME_SUGGESTIONS_SELECTED, payload: selectedOption });
}

const searchSuggestions = debounce((inputValue) => {
  dispatch({
    id: PROJECT_NAME_SEARCH_SUGGESTIONS,
    payload: inputValue,
  });
}, 500);

function onQueryChange(inputValue) {
  dispatch({
    id: PROJECT_NAME_TEXT_INTRODUCED,
    payload: inputValue,
  });

  searchSuggestions(inputValue);
}

export default ProjectNameField;
