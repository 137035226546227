import { validate as validateUUID } from 'uuid';

export function isValidUUID(maybeUUID) {
  return validateUUID(maybeUUID);
}

export function formattedListWithLastConjunction(
  items,
  lastConjunctionTranslation
) {
  return items.reduce((acc, item, idx) => {
    if (idx === 0) {
      return acc + item;
    }
    if (idx + 1 === items.length) {
      return `${acc} ${lastConjunctionTranslation} ${item}`;
    }
    return `${acc}, ${item}`;
  }, '');
}

export function isEmpty(value) {
  return value === null || value === undefined || value === '';
}
