import styled from 'styled-components';

const Wrapper = styled.svg`
  flex-shrink: 0;
`;

function LightLogo(props) {
  return (
    <>
      <Wrapper
        xmlns="http://www.w3.org/2000/svg"
        width="171"
        height="56"
        fill="none"
        viewBox="0 0 171 56"
        {...props}
      >
        <g clipPath="url(#clip0)">
          <path
            fill="#111B3C"
            d="M60.172 27.36c1.998 0 3.05-1.26 3.05-2.869a2.785 2.785 0 00-2.918-2.87h-3.622v5.74h3.49zm.132-11.575c3.19 0 5.572.856 7.083 2.528a8.804 8.804 0 012.34 6.269 8.4 8.4 0 01-2.402 6.15c-1.595 1.602-4.103 2.389-7.46 2.389h-3.183v7.926h-6.338V15.785h9.96zM71.692 41.026V23.133h5.474v2.208a5.524 5.524 0 016.965-2.208l-.452 5.446a7.147 7.147 0 00-2.368-.418c-2.62 0-3.95 1.714-3.95 5.148v7.717h-5.67zM96.557 34.681a4.019 4.019 0 001.142-2.897 3.97 3.97 0 00-3.983-3.991 3.69 3.69 0 00-2.787 1.184 3.797 3.797 0 00-1.149 2.786 3.997 3.997 0 001.15 2.897 3.79 3.79 0 002.785 1.15 3.873 3.873 0 002.842-1.15v.021zm-9.75 3.712a9.563 9.563 0 010-13.233 10.176 10.176 0 0113.825 0 9.473 9.473 0 010 13.233 10.254 10.254 0 01-13.826 0zM115.203 27.723a3.957 3.957 0 00-4.019 4.088 4.178 4.178 0 001.128 2.89 3.825 3.825 0 002.891 1.192 3.708 3.708 0 002.862-1.191 4.135 4.135 0 001.122-2.89 3.929 3.929 0 00-3.984-4.09zm7.327-2.577a9.347 9.347 0 012.445 6.596 9.661 9.661 0 01-2.473 6.658 7.994 7.994 0 01-6.171 2.703 7.177 7.177 0 01-5.05-1.93v7.836h-5.69V22.896h5.572v1.644a6.968 6.968 0 015.112-2.09 8.039 8.039 0 016.234 2.703M136.551 27.723a3.959 3.959 0 00-3.751 2.519 3.951 3.951 0 00-.268 1.57 4.138 4.138 0 001.121 2.89 3.82 3.82 0 002.898 1.19 3.689 3.689 0 002.855-1.19 4.184 4.184 0 001.129-2.89 3.928 3.928 0 00-3.984-4.09zm7.327-2.577a9.373 9.373 0 012.438 6.596 9.661 9.661 0 01-2.473 6.658 7.976 7.976 0 01-6.171 2.703 7.183 7.183 0 01-5.05-1.93v7.836h-5.683V22.896h5.572v1.644a6.966 6.966 0 015.105-2.09 8.062 8.062 0 016.269 2.703M154.451 41.026h-5.732V22.485h5.732v18.541zm-6.199-22.783a3.292 3.292 0 012.036-3.026 3.286 3.286 0 014.291 4.292 3.289 3.289 0 01-3.026 2.036 3.222 3.222 0 01-3.071-2.026 3.237 3.237 0 01-.23-1.276zM171 39.563a11.093 11.093 0 01-5.704 1.45c-4.43 0-6.643-2.453-6.638-7.356v-6.443h-2.744v-4.708h2.786v-2.87l5.662-3.204v6.074h5.607v4.708h-5.607v5.767c0 1.902.746 2.835 2.271 2.835a6.129 6.129 0 002.786-.8L171 39.562z"
          />
          <path
            fill="#0ACC86"
            d="M5.948 46.883H0v-18.59l15.142-10.447 14.843 10.448v18.61H9.29l5.76-5.934h8.93v-9.598l-8.888-6.185-9.145 6.185v15.511z"
          />
          <path
            fill="#0ACC86"
            d="M32.457 44.175l-.062-17.079-14.119-9.647 6.91-.696L36.9 24.749l.132 14.062-4.576 5.364z"
          />
          <path
            fill="#0ACC86"
            d="M38.796 36.715V23.523l-10.539-7.07 5.788-.682 8.762 5.795-.034 10.113-3.977 5.036z"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <path
              fill="#fff"
              d="M0 0H171V32.005H0z"
              transform="translate(0 14.998)"
            />
          </clipPath>
        </defs>
      </Wrapper>
    </>
  );
}

export default LightLogo;
