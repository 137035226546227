import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import Dialog from 'design-system/components/Dialog/Dialog';
import InputSelect from 'design-system/components/InputSelect';
import {
  FlatButton,
  SecondaryButton,
} from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import { selectedCampaignSelector } from './selectors';
import {
  CAMPAIGN_FIELD_CHANGED,
  CAMPAIGN_NEXT_BILLING_DATE_CALCULATION_REQUIRED,
  CAMPAIGN_SAVED,
} from './events';
import { CampaignBillingCycleSelector } from '../partials/CampaignBillingCycleSelector';
import { CampaignPriceInput } from '../partials/CampaignPriceInput';
import { CampaignDateInput } from '../partials/CampaignDateInput';
import { CampaignLeadTargetInput } from '../partials/CampaignLeadTargetInput';
import { useDialog } from '../../../hooks/useDialog';
import { RENEW_CAMPAIGN_MODAL } from './constants';
import { isEmpty } from '../../../utils/strings';
import InvoicingPeriod from './partials/InvoicingPeriod';

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
    ${InputSelect},
  ${CampaignBillingCycleSelector},
  ${CampaignDateInput},
  ${CampaignLeadTargetInput},
  ${CampaignPriceInput} {{
    width: 100%;
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

function ModalContent({ campaign }) {
  useEffect(() => {
    if (campaign.action) return;
    dispatch({
      id: CAMPAIGN_FIELD_CHANGED,
      payload: { name: 'action', value: 'renew-campaign' },
    });
  }, [campaign]);

  useEffect(() => {
    if (
      !isEmpty(campaign.startDate) &&
      !isEmpty(campaign.billingCycleInMonths)
    ) {
      dispatch(CAMPAIGN_NEXT_BILLING_DATE_CALCULATION_REQUIRED);
    }
  }, [campaign.startDate, campaign.billingCycleInMonths]);

  const onChange = (name) => (value) =>
    dispatch({
      id: CAMPAIGN_FIELD_CHANGED,
      payload: { name, value },
    });

  return (
    <>
      <Row>
        <InputSelect
          name="projectId"
          label="Project"
          value={campaign.projectId}
          choices={[{ label: campaign.projectName, value: campaign.projectId }]}
          required
          disabled
        />
      </Row>
      <Row>
        <CampaignDateInput
          name="startDate"
          label="Start Date"
          value={campaign.startDate}
          onChange={onChange('startDate')}
          required
        />
        <CampaignBillingCycleSelector
          value={campaign.billingCycleInMonths}
          onChange={onChange('billingCycleInMonths')}
          required
        />
      </Row>
      <Row>
        <CampaignDateInput
          name="billingDate"
          label="End date"
          value={campaign.billingDate}
          onChange={onChange('billingDate')}
          disabled
          required
        />
        <CampaignLeadTargetInput
          value={campaign.leadTarget}
          onChange={onChange('leadTarget')}
          required
        />
      </Row>
      <Row>
        <CampaignPriceInput
          id="amount"
          value={campaign.price.amount}
          currency={campaign.price.currency}
          onChange={onChange('price.amount')}
          required
        />
      </Row>
      <Row>
        <InvoicingPeriod currency={campaign.price.currency} />
      </Row>
    </>
  );
}

export function RenewCampaignModal({ onSuccessEvent }) {
  const { open, closeDialog } = useDialog(RENEW_CAMPAIGN_MODAL);
  const campaign = useSelector(selectedCampaignSelector);
  const onSave = useCallback((event) => {
    event.preventDefault();
    dispatch({ id: CAMPAIGN_SAVED, payload: { onSuccessEvent } });
  }, []);
  return (
    <Dialog open={open}>
      <form onSubmit={onSave} id="renew-campaign">
        <Dialog.Header onClose={closeDialog}>
          Renew project campaign
        </Dialog.Header>
        <Dialog.Content>
          <ModalContent campaign={campaign} />
        </Dialog.Content>
        <Dialog.Footer>
          <FlatButton onClick={closeDialog} $size={BUTTON_SIZE.SMALL}>
            Cancel
          </FlatButton>
          <SecondaryButton
            type="submit"
            form="renew-campaign"
            $size={BUTTON_SIZE.SMALL}
          >
            Renew campaign
          </SecondaryButton>
        </Dialog.Footer>
      </form>
    </Dialog>
  );
}
