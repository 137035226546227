import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import { useMount } from 'react-use';
import Dialog from 'design-system/components/Dialog/Dialog';
import {
  FlatButton,
  SecondaryButton,
} from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import { useDialog } from '../../../../../../../hooks/useDialog';
import { ASSOCIATE_MODAL } from './constants';
import {
  associateToPublisherIdSelector,
  availablePublisherSelector,
  enableAssociateButtonSelector,
} from './selectors';
import {
  ASSOCIATE_PUBLISHER_TO_MULTIACCOUNT,
  PUBLISHER_TO_JOIN_ACCOUNT_SELECTED,
  PUBLISHER_TO_JOIN_ACCOUNT_UNSELECTED,
} from './events';
import PublisherAutocomplete from '../../../../../partials/PublisherAutocomplete/PublisherAutocomplete';
import { PUBLISHERS_REQUESTED } from '../../../../../events';
import useUpdateTableEvent from '../../../../../../../partials/DataTable/hooks/useUpdateTableEvent';

export default function AssociateModal({ publisherId, countryCode }) {
  const { open, closeDialog } = useDialog(ASSOCIATE_MODAL);
  const updateTableEvent = useUpdateTableEvent();
  useMount(() => dispatch(PUBLISHERS_REQUESTED));
  const enabled = useSelector(enableAssociateButtonSelector);
  const publishers = useSelector((state) =>
    availablePublisherSelector(state, publisherId)
  );
  const associateToPublisherId = useSelector(associateToPublisherIdSelector);

  return (
    <Dialog open={open}>
      <Dialog.Header onClose={closeDialog}>
        Associate to Multiaccount
      </Dialog.Header>
      <Dialog.Content>
        <PublisherAutocomplete
          publishers={publishers}
          countryCode={countryCode}
          onSelect={({ id }) =>
            dispatch({
              id: PUBLISHER_TO_JOIN_ACCOUNT_SELECTED,
              payload: { publisherId: id },
            })
          }
          onClear={() => dispatch(PUBLISHER_TO_JOIN_ACCOUNT_UNSELECTED)}
        />
      </Dialog.Content>
      <Dialog.Footer>
        <FlatButton onClick={closeDialog} $size={BUTTON_SIZE.SMALL}>
          Cancel
        </FlatButton>
        <SecondaryButton
          onClick={() =>
            dispatch({
              id: ASSOCIATE_PUBLISHER_TO_MULTIACCOUNT,
              payload: {
                publisherId,
                associateToPublisherId,
                successEvent: updateTableEvent,
              },
            })
          }
          disabled={!enabled}
          $size={BUTTON_SIZE.SMALL}
        >
          Continue
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}
