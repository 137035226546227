import { createCachedSelector } from 're-reselect';

export function projectChoicesSelector(state) {
  return (state['adForm:projectNameSuggestions'] ?? []).map(({ id, name }) => ({
    value: id,
    label: name,
  }));
}

const projectIdParameter = (_, projectId) => projectId;

const adFormProjectNameSuggestionsSelector = (state) =>
  state['adForm:projectNameSuggestions'] ?? [];

export const projectByIdSelector = createCachedSelector(
  projectIdParameter,
  adFormProjectNameSuggestionsSelector,
  (projectId, projectNameSuggestions) =>
    projectNameSuggestions.find(({ id }) => id === projectId)
)(projectIdParameter);
