import { registerEffectHandler } from 'reffects';

const PAGE_RELOAD = 'pageReload';
export default function registerPageReloadEffect(window) {
  registerEffectHandler(PAGE_RELOAD, function resetStateEffect() {
    window.location.reload();
  });
}

export function pageReload() {
  return {
    [PAGE_RELOAD]: {},
  };
}
