import { coeffect, registerCoeffectHandler } from 'reffects';
import { v7 as uuidV7 } from 'uuid';

export default function registerUuidCoeffect() {
  registerCoeffectHandler('uuid', () => ({
    uuid: {
      newUuid: uuidV7(),
    },
  }));
}

export function uuid() {
  return coeffect('uuid');
}
