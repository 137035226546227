import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

const dataTablesSelector = ({ dataTables }) => dataTables;

export const collectionNameParameter = (_, { collectionName }) =>
  collectionName;

const collectionCacheKey = (_, { collectionName }) => collectionName;

export const collectionSelector = createCachedSelector(
  dataTablesSelector,
  collectionNameParameter,
  (dataTables, collectionName) => dataTables?.[collectionName] ?? {}
)(collectionCacheKey);

export const collectionItemsByIdSelector = createSelector(
  collectionSelector,
  (collection) => collection?.itemsById ?? {}
);

export const visibleItemsIdListSelector = createSelector(
  collectionSelector,
  (collection) => collection?.visibleItemsIdList ?? []
);
