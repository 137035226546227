import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import { useMount, useUnmount } from 'react-use';
import Autocomplete from 'design-system/components/Autocomplete/Autocomplete';
import InputText from 'design-system/components/InputText';
import Icon from 'design-system/components/Icon';
import {
  INIT_PUBLISHER_AUTOCOMPLETE,
  PUBLISHER_SUGGESTER_TEXT_INTRODUCED,
} from './events';
import {
  displayablePublisherSelector,
  suggestedPublishersSelector,
} from './selectors';

export default function PublisherAutocomplete({
  publishers,
  countryCode,
  onSelect,
  onClear,
  label = 'Publisher',
}) {
  useMount(() => dispatch(INIT_PUBLISHER_AUTOCOMPLETE));
  useUnmount(() => dispatch(INIT_PUBLISHER_AUTOCOMPLETE));
  const displayableValue = useSelector(displayablePublisherSelector);
  const suggestedPublishers = useSelector(suggestedPublishersSelector);

  return (
    <Autocomplete
      name="publisherId"
      label={label}
      options={suggestedPublishers.map(({ name, ...rest }) => ({
        name,
        text: name,
        ...rest,
      }))}
      value={displayableValue}
      isLoading={false}
      minCharactersToOpen={2}
      required
      onValueChange={(searchQuery) =>
        dispatch({
          id: PUBLISHER_SUGGESTER_TEXT_INTRODUCED,
          payload: { publishers, searchQuery, countryCode },
        })
      }
      onOptionSelected={onSelect}
      optionToString={(option) => (option === undefined ? '' : option.name)}
      renderInput={({ value, getInputProps, onClear: onInputClear }) => (
        <InputText
          required
          label="Publisher"
          icon={value && <Icon glyph="cross" />}
          onIconClick={() => {
            onInputClear();
            dispatch(INIT_PUBLISHER_AUTOCOMPLETE);
            if (onClear) {
              onClear();
            }
          }}
          tip="Search publishers by their name"
          {...getInputProps({ value, autoComplete: 'off' })}
        />
      )}
      renderOptions={({ options: publisherOptions, getOptionProps }) =>
        publisherOptions.map((option, index) => (
          <Autocomplete.Option
            key={option.id}
            title={option.name}
            subtitle=""
            icon={<Icon glyph="building" />}
            {...getOptionProps({
              index,
              option,
            })}
          />
        ))
      }
      emptyStateMessage="No publishers match with your search"
      loadingStateMessage="Loading..."
    />
  );
}
