import styled from 'styled-components';

import { useSelector } from 'reffects-store';
import DiscreteAnchor from './DiscreteAnchor';
import FormattedMessageWithValidation from '../FormattedMessageWithValidation/FormattedMessageWithValidation';
import { legalLinksSelector } from '../../selectors/config';
import { isPublisherFromLATAMSelector } from '../../selectors/publisher';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

function LegalLinks(props) {
  const showBlog = useSelector(isPublisherFromLATAMSelector);

  const { termsAndConditions, privacyPolicy, cookiesPolicy } =
    useSelector(legalLinksSelector);
  return (
    <Wrapper {...props}>
      {showBlog && (
        <DiscreteAnchor href="https://blog.proppit.com/">
          <FormattedMessageWithValidation id="blog" />
        </DiscreteAnchor>
      )}
      <DiscreteAnchor href={termsAndConditions}>
        <FormattedMessageWithValidation id="termsandconds_footer_tandco" />
      </DiscreteAnchor>
      <DiscreteAnchor href={privacyPolicy}>
        <FormattedMessageWithValidation id="termsandconds_footer_privacy" />
      </DiscreteAnchor>
      <DiscreteAnchor href={cookiesPolicy}>
        <FormattedMessageWithValidation id="termsandconds_footer_cookies" />
      </DiscreteAnchor>
    </Wrapper>
  );
}

export default LegalLinks;
