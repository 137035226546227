import { useSelector } from 'reffects-store';

import { useEffect } from 'react';
import { dispatch } from 'reffects';
import { RouteProvider } from '../../App/router';
import { ROUTE_TYPE } from '../../App/router/context';
import { userRoleSelector } from '../../selectors/user';
import { CHECK_USER_IS_BACKOFFICE_ROLE } from './events';

function BackofficeRoute({ as: Component, ...rest }) {
  const role = useSelector(userRoleSelector);

  useEffect(() => {
    dispatch({ id: CHECK_USER_IS_BACKOFFICE_ROLE, payload: { role } });
  }, [role]);

  return (
    <RouteProvider routeType={ROUTE_TYPE.BACKOFFICE}>
      <Component {...rest} />
    </RouteProvider>
  );
}

export default BackofficeRoute;
