import BreakdownTable from 'design-system/components/BreakdownTable/BreakdownTable';
import Price from '../../../../partials/Price/Price';

export default function Taxes({ taxes }) {
  if (!taxes || taxes.amount === 0) {
    return null;
  }
  return (
    <BreakdownTable.Row
      title={`${taxes.name} ${taxes.percentage ?? taxes.percent}%`}
      price={<Price amount={taxes.amount} currency={taxes.currency} />}
    />
  );
}
