import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useCallback, useState } from 'react';
import Anchor from 'design-system/components/Anchor';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Typography, {
  TYPOGRAPHY_VARIANT,
} from 'design-system/components/Typography/Typography';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { newUnitForm } from '../../../../utils/proppitWebRouter';
import useTracking from '../../../../hooks/useTracking';

const Wrapper = styled.div`
  display: block;
  width: 100%;
  ${radius.regular(RADIUS_SIZE.LARGE)}
  ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
  ${spacing.inset(SPACING_SIZE.M)}
  margin-top: ${spacing.value(SPACING_SIZE.M)};

  ${Anchor} {
    ${Typography.mods.variant(TYPOGRAPHY_VARIANT.BODY_2)}
  }
`;

function PublishFirstUnitDisclaimer({ ...props }) {
  const [linkElement, setLinkElement] = useState(null);
  const ref = useCallback((node) => {
    if (node != null && linkElement !== node) setLinkElement(node);
  }, []);
  useTracking({
    ref: linkElement,
    trackClick: {
      eventName: 'create-new-unit',
    },
    trackImpression: {
      elementName: 'create-new-unit-btn',
    },
    sectionName: 'overview-kpis-right-new-unit-button',
  });
  return (
    <Wrapper {...props}>
      <Body2>
        <FormattedMessageWithValidation
          id="overview_developer_key_results_publish_first_unit_banner_text"
          values={{
            link: (
              <Anchor as={Link} to={newUnitForm()} ref={ref}>
                <FormattedMessageWithValidation id="overview_developer_key_results_publish_first_unit_banner_link_text" />
              </Anchor>
            ),
          }}
        />
      </Body2>
    </Wrapper>
  );
}

export default PublishFirstUnitDisclaimer;
