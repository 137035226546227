import { localeSelector, userIdSelector } from '../../selectors/user';
import { isWhiteLabelSelector } from '../../App/selectors';

export function hubspotUserLocaleSelector(state) {
  const locale = localeSelector(state);

  if (locale.includes('es')) {
    return 'es';
  }

  if (locale === 'th-TH') {
    return 'th';
  }

  return 'en';
}

export function enableHubspotSelector(state) {
  const userId = userIdSelector(state);
  return Boolean(userId && !isWhiteLabelSelector(state));
}
