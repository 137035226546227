import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import {
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import MapChoicesInput from './MapChoicesInput';
import {
  csvFileErrorsSelector,
  getChoicesByStepIdSelector,
  getFieldNameByStepIdSelector,
  getRowValuesByStepIdSelector,
  isCurrentCSVStepSelector,
} from '../selectors';
import { GROUP_CHILDRENS, STEP_BY_ID, VALUES_SCHEMA } from '../constants';
import { CSV_CHANGE_CHOICES_VALUES, CSV_FILE_ERRORS } from '../events';
import HeaderTable from './HeaderTable';
import { FieldGroup } from '../../../../pages/publication/partials/FieldGroup';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const ContentColumn = styled.div`
  display: flex;
  align-items: center;
  width: ${(props) =>
    props.percentageWidth ? `${props.percentageWidth}%` : '56px'};
`;
const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 58px;
  border-top: 1px solid #e6e6e6;
`;
const SeparatorContentDiv = styled.div`
  height: ${(props) => (props.height ? `${props.height}px` : '58px')};
  width: 56px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ContentDiv = styled.div`
  width: 100%;
  ${spacing.inset(SPACING_SIZE.M)}
`;
const WrapperDiv = styled.div``;
const BodyDiv = styled.div`
  ${typography.size(FONT_SIZE.S)}
`;

const SubTitleWrapper = styled.div`
  ${typography.font(FONT_FAMILY.PRIMARY)};
  ${typography.weight(FONT_WEIGHT.NORMAL)};
  ${typography.size(FONT_SIZE.M)};
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  line-height: normal;
`;

function ValuesMapper({ id }) {
  const step = STEP_BY_ID[id];
  const globalErrors = useSelector(csvFileErrorsSelector);
  const isCurrentStep = useSelector((state) =>
    isCurrentCSVStepSelector(state, id)
  );
  const availableValues = useSelector((state) =>
    getChoicesByStepIdSelector(state, id)
  );
  const fieldNameToMap = useSelector((state) =>
    getFieldNameByStepIdSelector(state, id)
  );
  const valuesToMap = useSelector((state) =>
    getRowValuesByStepIdSelector(state, id)
  );

  if (!isCurrentStep) {
    return <></>;
  }
  if (!availableValues) {
    return <></>;
  }

  const errors = globalErrors[id];

  const handleSelectChange = (fieldName, value) => {
    dispatch({
      id: CSV_CHANGE_CHOICES_VALUES,
      payload: {
        name: fieldName,
        value,
        fieldNameToMap,
      },
    });
    if (errors && errors.indexOf(fieldName) !== -1) {
      dispatch({
        id: CSV_FILE_ERRORS,
        payload: {
          ...globalErrors,
          [id]: errors.filter((error) => error !== fieldName),
        },
      });
    }
  };

  const currentStep = GROUP_CHILDRENS.find((object) => object.id === id);

  valuesToMap.sort((a, b) => a.fieldName.localeCompare(b.fieldName));

  return (
    <FieldGroup
      id={id}
      title={
        <FormattedMessageWithValidation id="upload_csv_value_mapping_title" />
      }
      order={step}
    >
      <SubTitleWrapper>
        <FormattedMessageWithValidation
          id="upload_csv_value_mapping_subtitle"
          values={{
            columnTitle: (
              <b>
                <FormattedMessageWithValidation id={currentStep.title} />
              </b>
            ),
            columnRequired: (
              <FormattedMessageWithValidation
                id={
                  currentStep.required === true
                    ? 'upload_csv_required'
                    : 'upload_csv_optional'
                }
              />
            ),
          }}
        />
      </SubTitleWrapper>
      <WrapperDiv>
        <HeaderTable schema={VALUES_SCHEMA} />
        <BodyDiv>
          {valuesToMap.map(({ fieldName, fieldOptions }) => {
            if (fieldOptions.tag === null) {
              return null;
            }
            return (
              <ContentRow key={`row${fieldName}`}>
                <ContentColumn
                  key={`cell${fieldName}_text`}
                  percentageWidth="30"
                >
                  <ContentDiv>
                    <span>{fieldOptions.tag}</span>
                  </ContentDiv>
                </ContentColumn>
                <ContentColumn key={`cell${fieldName}_separator`}>
                  <SeparatorContentDiv>
                    <Icon glyph="arrowLeft" $size={ICON_SIZE.BASE} />
                  </SeparatorContentDiv>
                </ContentColumn>
                <ContentColumn
                  key={`cell${fieldName}_map`}
                  percentageWidth="50"
                >
                  <ContentDiv>
                    <MapChoicesInput
                      key={fieldName}
                      values={availableValues}
                      valueName={fieldName}
                      fieldNameToMap={fieldNameToMap}
                      required={fieldOptions.required}
                      onSelectChange={handleSelectChange}
                      hasError={errors && errors.indexOf(fieldName) !== -1}
                    />
                  </ContentDiv>
                </ContentColumn>
              </ContentRow>
            );
          })}
        </BodyDiv>
      </WrapperDiv>
    </FieldGroup>
  );
}

export default ValuesMapper;
