import { useSelector } from 'reffects-store';
import { Link } from 'react-router-dom';
import { FormattedList, useIntl } from 'react-intl';
import { dispatch } from 'reffects';
import PricingPlansTable from 'design-system/components/PricingPlansTable/PricingPlansTable';
import {
  OutlineButton,
  PrimaryButton,
} from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import useTracking from '../../../hooks/useTracking';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  isDeveloperSelector,
  isPublisherFromMexicoSelector,
  subscriptionChangeRequestIdSelector,
} from '../../../selectors/publisher';
import ContactSalesButton from './SubscriptionTable/ContactSalesButton';
import { checkoutBillingInformationRoute } from '../../../utils/proppitWebRouter';
import { UPSELL_SUBROUTE } from '../../Checkout/configuration/constants';

export function createHeadingCell(plan) {
  const { id, nameTag, discount, tax, billingCycleInMonths, isActive } = plan;
  const isMexico = useSelector(isPublisherFromMexicoSelector);

  if (id !== 'free' && isMexico) {
    return (
      <PricingPlansTable.HeadingCell
        key={id}
        name={<FormattedMessageWithValidation id={nameTag} />}
      />
    );
  }
  return (
    <PricingPlansTable.HeadingCell
      {...plan}
      key={id}
      name={<FormattedMessageWithValidation id={nameTag} />}
      discountCaption={
        discount > 0 && (
          <FormattedMessageWithValidation
            id="subscription_plans_discount"
            values={{ percentage: discount }}
          />
        )
      }
      taxCaption={
        tax.amount > 0 && (
          <>
            <FormattedMessageWithValidation
              id="subscription_price_month"
              values={{ taxId: tax.name }}
            />
            {billingCycleInMonths > 1 && (
              <>
                &nbsp;x&nbsp;
                <FormattedMessageWithValidation
                  id="subscription_summary_contract_type_value"
                  values={{
                    duration: billingCycleInMonths,
                  }}
                />
              </>
            )}
          </>
        )
      }
      callToAction={isActive ? <CurrentPlanButton /> : <ContractPlan />}
    />
  );
}

const ContractPlan = () => {
  const isDeveloper = useSelector(isDeveloperSelector);
  if (isDeveloper) {
    return <ContactSalesButton />;
  }
  return <CheckoutLink />;
};

export function createAgencyBenefits(
  benefitsBySubscription,
  websWithoutTranslatedAnd,
  showLogoOnWebsiteBenefit
) {
  const intl = useIntl();
  const networkSites = websWithoutTranslatedAnd(
    intl.formatMessage({ id: 'andWebs' })
  );
  return [
    publicationSites(benefitsBySubscription, networkSites),
    prioritizedProperties(benefitsBySubscription),
    verifiedPhone(benefitsBySubscription),
    hasLeadPlusBenefit(benefitsBySubscription),
    showLogoOnWebsiteBenefit && logo(benefitsBySubscription),
    dedicatedAccountManager(benefitsBySubscription),
    emailSupport(benefitsBySubscription),
    quarterlyReport(benefitsBySubscription),
  ].filter(Boolean);
}

export function createSEADeveloperBenefits(benefitsBySubscription, siteNames) {
  return [
    projectsInAllSites(benefitsBySubscription, siteNames),
    developerWithPrioritizedProperties(benefitsBySubscription),
    projectsInHome(benefitsBySubscription),
    developerZoneBanners(benefitsBySubscription),
    developerBanner(benefitsBySubscription),
    developerNewsletter(benefitsBySubscription),
  ].filter(Boolean);
}

export function createLATAMDeveloperBenefits(
  benefitsBySubscription,
  siteNames
) {
  return [
    projectsInAllSites(benefitsBySubscription, siteNames),
    developerWithPrioritizedProperties(benefitsBySubscription),
    projectsInHome(benefitsBySubscription),
    developerZoneBanners(benefitsBySubscription),
    developerBanner(benefitsBySubscription),
  ].filter(Boolean);
}

const CurrentPlanButton = () => (
  <OutlineButton disabled $size={BUTTON_SIZE.LARGE}>
    <FormattedMessageWithValidation id="agencyprofile_subscription_header" />
  </OutlineButton>
);

const CheckoutLink = () => {
  const scrId = useSelector(subscriptionChangeRequestIdSelector);
  const trackingRef = useTracking({
    trackClick: {
      eventName: 'open-checkout',
    },
    trackImpression: {
      elementName: 'cta-subscription-btn',
    },
    sectionName: 'subscription-table-highlighted-plan-button',
  });

  return (
    <>
      {scrId ? (
        <PrimaryButton
          forwardedAs={Link}
          to={checkoutBillingInformationRoute({
            subscriptionChangeRequestId: scrId,
            subRoute: UPSELL_SUBROUTE,
          })}
          $size={BUTTON_SIZE.LARGE}
          ref={trackingRef}
        >
          <FormattedMessageWithValidation id="subscription_plans_cta_upgrade" />
        </PrimaryButton>
      ) : (
        <PrimaryButton
          $size={BUTTON_SIZE.LARGE}
          ref={trackingRef}
          onClick={() => dispatch('SUBSCRIBE_BUTTON_CLICKED')}
        >
          <FormattedMessageWithValidation id="subscription_plans_cta_upgrade" />
        </PrimaryButton>
      )}
    </>
  );
};

function publicationSites(subscriptions, networkSites) {
  if (subscriptions.some((s) => !s.showPublicationSites)) {
    return null;
  }
  return benefit(
    'publication_in_brands',
    <FormattedMessageWithValidation
      id="subscription_plans_networkSites"
      values={{ networkSites }}
    />,
    subscriptions,
    (s) => ({
      type: s.publishInAllSites ? 'boolean' : 'text',
      value: s.publishInAllSites ? (
        s.sitePublication
      ) : (
        <FormattedMessageWithValidation id="subscription_plans_networkSites_limited" />
      ),
    })
  );
}

function hasLeadPlusBenefit(subscriptions) {
  return benefit(
    'has_lead_plus',
    <FormattedMessageWithValidation id="subscription_plans_lead_plus" />,
    subscriptions,
    (s) => ({
      type: 'boolean',
      value: s.hasLeadPlus,
    })
  );
}

function prioritizedProperties(subscriptions) {
  return benefit(
    'prioritiezed_properties',
    <FormattedMessageWithValidation id="subscription_plans_number_properties" />,
    subscriptions,
    (s) => ({
      type: s.prioritizedProperties === 0 ? 'text' : 'number',
      value: s.prioritizedProperties === 0 ? '-' : s.prioritizedProperties,
    })
  );
}
function verifiedPhone(subscriptions) {
  return benefit(
    'verified_phone',
    <FormattedMessageWithValidation id="subscription_plans_verified_phone" />,
    subscriptions,
    (s) => ({
      type: 'boolean',
      value: s.hasVerifiedPhone,
    })
  );
}

function logo(subscriptions) {
  return benefit(
    'logo_in_websites',
    <FormattedMessageWithValidation id="subscription_plans_brand" />,
    subscriptions,
    (s) => ({
      type: 'boolean',
      value: s.hasLogoInWebsites,
    })
  );
}

function dedicatedAccountManager(subscriptions) {
  return benefit(
    'dedicated_account_manager',
    <FormattedMessageWithValidation id="subscription_plans_account_manager" />,
    subscriptions,
    (s) => ({
      type: 'boolean',
      value: s.hasDedicatedAccountManager,
    })
  );
}

function emailSupport(subscriptions) {
  return benefit(
    'email_support',
    <FormattedMessageWithValidation id="subscription_plans_email_support" />,
    subscriptions,
    (s) => ({
      type: 'boolean',
      value: s.hasEmailSupport,
    })
  );
}

function quarterlyReport(subscriptions) {
  return benefit(
    'quarterly_report',
    <FormattedMessageWithValidation id="subscription_plans_quarterly_report" />,
    subscriptions,
    (s) => ({
      type: 'boolean',
      value: s.hasQuarterlyReport,
    })
  );
}

function projectsInAllSites(subscriptions, siteNames) {
  if (subscriptions.some((s) => !s.showPublicationSites)) {
    return null;
  }
  return benefit(
    'projects_all_sites',
    <FormattedMessageWithValidation
      id="subscription_plans_project_in_all_sites"
      values={{ sites: <FormattedList type="conjunction" value={siteNames} /> }}
    />,
    subscriptions,
    (s) => ({
      type: 'boolean',
      value: s.isPaidSubscription,
    })
  );
}
function developerWithPrioritizedProperties(subscriptions) {
  return benefit(
    'prioritized_properties',
    <FormattedMessageWithValidation id="subscription_plans_developer_prioritized_properties" />,
    subscriptions,
    (s) => ({
      type: 'boolean',
      value: s.isPaidSubscription,
    })
  );
}

function projectsInHome(subscriptions) {
  return benefit(
    'projects_in_home',
    <FormattedMessageWithValidation id="subscription_plans_developer_projects_in_home" />,
    subscriptions,
    (s) => ({
      type: 'boolean',
      value: s.isPaidSubscription,
    })
  );
}

function developerZoneBanners(subscriptions) {
  return benefit(
    'zone_banners',
    <FormattedMessageWithValidation id="subscription_plans_developer_zone_banners" />,
    subscriptions,
    (s) => ({
      type: 'boolean',
      value: s.isPaidSubscription,
    })
  );
}

function developerBanner(subscriptions) {
  return benefit(
    'banner',
    <FormattedMessageWithValidation id="subscription_plans_developer_banner" />,
    subscriptions,
    (s) => ({
      type: 'boolean',
      value: s.isPaidSubscription,
    })
  );
}

function developerNewsletter(subscriptions) {
  return benefit(
    'newsletter',
    <FormattedMessageWithValidation id="subscription_plans_developer_newsletter" />,
    subscriptions,
    (s) => ({
      type: 'boolean',
      value: s.hasNewsletter,
    })
  );
}

function benefit(id, name, subscriptions, f) {
  return {
    id,
    name,
    value: subscriptions.map((s) => ({
      id: s.planId,
      ...f(s),
    })),
  };
}
