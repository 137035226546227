import { registerEventHandler } from 'reffects';
import { environment } from '../../../../coeffects/environment';
import { analytics } from '../../../../effects/analytics';
import { redirectTo } from '../../../../effects/routing';

export const DOWNLOAD_INVALID_PROPERTIES = 'DOWNLOAD_INVALID_PROPERTIES';

registerEventHandler(
  DOWNLOAD_INVALID_PROPERTIES,
  ({ environment: { apiUrl } }, { format }) => ({
    ...analytics.trackClick(
      format === 'csv'
        ? 'export-invalid-properties-to-csv'
        : 'export-invalid-properties-to-excel',
      'import-stats-table'
    ),
    ...redirectTo(
      `${apiUrl}/publishers/me/invalid-properties-to-export?format=${format}`
    ),
  }),
  [environment()]
);
