import styled from 'styled-components';

const Wrapper = styled.span`
  white-space: pre-line;
`;

export default function RenderHtml({ children }) {
  return (
    <Wrapper
      dangerouslySetInnerHTML={{
        __html: children,
      }}
    />
  );
}
