import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import Table from 'design-system/components/Table';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Heading2 } from 'design-system/components/Typography/presets/Heading2';
import Typography from 'design-system/components/Typography/Typography';
import {
  tableIsLoadingSelector,
  tableRowsSelector,
  tableSelector,
} from '../../../Tables/selectors';
import { PROJECT_UNITS_TABLE } from './constants';
import { publisherCountrySelector } from '../../../../selectors/publisher';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import RowsLabel from '../../../Tables/RowsLabel';
import {
  PROJECT_UNITS_TABLE_PAGE_CHANGED,
  PROJECT_UNITS_TABLE_ROWS_PER_PAGE_CHANGED,
} from './events';
import UnitRow from './UnitRow';
import { isLATAM, isSEA } from '../../../../utils/countries';

const Wrapper = styled.div`
  ${spacing.stack(SPACING_SIZE.L)}
`;

const Header = styled(Heading2)`
  display: block;
  ${spacing.stack(SPACING_SIZE.L)}
`;

function ColumnTitle({ id }) {
  return (
    <Typography>
      <FormattedMessageWithValidation id={id} />
    </Typography>
  );
}

const COLUMNS = (countryCode) => {
  let areaColumnTag = '';
  switch (true) {
    case countryCode === 'PH':
      areaColumnTag = 'project_page_units_table_total_area';
      break;

    case isSEA(countryCode):
      areaColumnTag = 'project_page_units_table_usable_area';
      break;

    case isLATAM(countryCode):
    default:
      areaColumnTag = 'project_units_page_table_total_area';
      break;
  }

  return [
    {
      id: 'image',
      content: <ColumnTitle id="project_page_units_table_photo" />,
    },
    {
      id: 'referenceId',
      content: <ColumnTitle id="project_page_units_table_reference_id" />,
    },
    {
      id: 'rooms',
      content: <ColumnTitle id="project_page_units_table_rooms" />,
    },
    {
      id: 'baths',
      content: <ColumnTitle id="project_page_units_table_baths" />,
    },
    {
      id: 'area',
      content: <ColumnTitle id={areaColumnTag} />,
    },
    {
      id: 'price',
      content: <ColumnTitle id="project_page_units_table_price" />,
    },
    {
      id: 'propertyType',
      content: <ColumnTitle id="project_page_units_table_property_type" />,
    },
    {
      id: 'operation',
      content: <ColumnTitle id="project_page_units_table_operation" />,
    },
    {
      id: 'status',
      content: <ColumnTitle id="project_page_units_table_status" />,
    },
  ];
};

const UnitsTable = styled(Table)`
  width: 100%;
  table {
    table-layout: fixed;
  }
`;

function Units() {
  const units = useSelector((state) =>
    tableRowsSelector(state, PROJECT_UNITS_TABLE)
  );
  const isLoading = useSelector((state) =>
    tableIsLoadingSelector(state, PROJECT_UNITS_TABLE)
  );

  const country = useSelector(publisherCountrySelector);
  const { pagination } = useSelector((state) =>
    tableSelector(state, PROJECT_UNITS_TABLE)
  );

  if (isLoading || units.length === 0) {
    return null;
  }

  const paginationProps = {
    ...pagination,
    rowsPerPageLabel: (
      <FormattedMessageWithValidation id="enqpage_table_rows" />
    ),
    displayRowsLabel: (currentRowRange, totalRows) => (
      <RowsLabel currentRowRange={currentRowRange} totalRows={totalRows} />
    ),
    onPaginate: (page) =>
      dispatch({
        id: PROJECT_UNITS_TABLE_PAGE_CHANGED,
        payload: page,
      }),
    onRowsPerPageChange: (rowsPerPage) =>
      dispatch({
        id: PROJECT_UNITS_TABLE_ROWS_PER_PAGE_CHANGED,
        payload: rowsPerPage,
      }),
  };

  return (
    <Wrapper>
      <Header>
        <FormattedMessageWithValidation id="units_page_header" />
      </Header>
      <UnitsTable
        columns={COLUMNS(country)}
        sorting={{}}
        pagination={<Table.Pagination {...paginationProps} />}
      >
        {units.map((unitId) => (
          <UnitRow unitId={unitId} key={`unit-${unitId}`} />
        ))}
      </UnitsTable>
    </Wrapper>
  );
}

export default Units;
