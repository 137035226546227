import styled from 'styled-components';
import InputBase from 'design-system/components/InputBase/InputBase';
import { useInputBase } from 'design-system/hooks/useInputBase';
import { withStylesAndInnerRef } from 'design-system/utils';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle2 } from 'design-system/components/Typography/presets/Subtitle2';
import { COLOR_PALETTE } from 'design-system/styles/color';

const Wrapper = styled(InputBase)`
  > ${InputBase.Box} {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${spacing.value(SPACING_SIZE.XS)};

    input {
      ${spacing.inline(SPACING_SIZE.NONE)}
    }

    ${Subtitle2} {
      user-select: none;
      text-align: left;
      max-width: 5ex;
    }
  }
`;

function InputText({ Component = 'input', prefix, innerRef, ...props }) {
  const { inputBaseProps, boxProps, inputProps } = useInputBase(props);

  return (
    <Wrapper {...inputBaseProps}>
      <InputBase.Box {...boxProps}>
        {prefix && (
          <Subtitle2 $color={COLOR_PALETTE.NEUTRAL_A40}>{prefix}</Subtitle2>
        )}
        <Component type="text" ref={innerRef} {...inputProps} />
      </InputBase.Box>
    </Wrapper>
  );
}

export default withStylesAndInnerRef(InputText);
