import Dialog from 'design-system/components/Dialog/Dialog';
import { BUTTON_SIZE } from 'design-system/components/Button';
import { SecondaryButton } from 'design-system/components/Button/presets';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { AUTO_RENEWAL_CANCELLATION_REQUESTED_MODAL } from './constants';
import { useDialog } from '../../../../hooks/useDialog';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

export function AutoRenewalCancellationRequestedModal() {
  const { open, closeDialog } = useDialog(
    AUTO_RENEWAL_CANCELLATION_REQUESTED_MODAL
  );
  return (
    <Dialog open={open}>
      <Dialog.Header onClose={closeDialog}>
        <FormattedMessageWithValidation id="cancellation_request_sent_header" />
      </Dialog.Header>
      <Dialog.Content>
        <Body1>
          <FormattedMessageWithValidation id="cancellation_request_sent_body" />
        </Body1>
      </Dialog.Content>
      <Dialog.Footer>
        <SecondaryButton onClick={closeDialog} $size={BUTTON_SIZE.SMALL}>
          <FormattedMessageWithValidation id="modal_button_close" />
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}
