import * as React from "react";
const SvgMitulaLogoColored = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 55 24"
    {...props}
  >
    <g
      fillRule="evenodd"
      clipPath="url(#MitulaLogoColored_svg__a)"
      clipRule="evenodd"
    >
      <path
        fill="#FF5050"
        d="M47.308 15.4a2.675 2.675 0 0 1-2.683-2.666 2.675 2.675 0 0 1 2.683-2.667 2.675 2.675 0 0 1 2.683 2.667 2.675 2.675 0 0 1-2.683 2.666m7.588-2.666L51.15 9.012a5.37 5.37 0 0 0-3.843-1.611c-2.963 0-5.366 2.388-5.366 5.333s2.403 5.333 5.366 5.333a5.37 5.37 0 0 0 3.844-1.612z"
      />
      <path
        fill="#552D73"
        d="M31.863 18.157c-1.287 0-2.15-.617-2.15-2.106V7.188h1.908v8.547c0 .451.106.752.757.752.092 0 .148-.005.196-.009.04-.003.075-.006.122-.006v1.58l-.159.032c-.251.052-.355.073-.674.073m-17.704-.09h-1.922v-7.824h1.922zm.046-8.907h-2.029V7.219h2.029zm-5.288 1.293v7.614h1.892V7.399H8.311l-2.83 7.599-2.817-7.599H.03v10.668h1.893v-7.704l2.77 7.554h1.438zm10.172 7.72c-1.59 0-2.377-.904-2.377-2.408v-3.972h-1.438v-1.55h1.438V8.257l1.892-.467v2.453h1.983v1.55h-1.983v3.806c0 .602.273.858.878.858.394 0 .712-.015 1.226-.075v1.58c-.53.135-1.06.21-1.62.21m7.578-1.13.136 1.024h1.575v-7.824h-1.893v4.138c0 .631-.12 1.339-.575 1.76-.227.226-.53.391-.969.391-.999 0-1.362-.572-1.362-1.49v-4.8h-1.893v5.222c0 1.67 1.045 2.798 2.726 2.798.999 0 1.877-.45 2.255-1.218m9.765-2.301 1.998-.15v.36c0 1.159-.833 1.79-2.013 1.79-.59 0-1.12-.375-1.12-.977 0-.632.56-.978 1.135-1.023m2.255 2.347c.06.587.515 1.098 1.333 1.098.444 0 .613-.072.918-.202l.02-.008v-1.385s-.12.03-.242.03c-.318 0-.439-.135-.439-.496v-2.904c0-.527-.03-1.083-.227-1.58-.454-1.173-1.605-1.595-2.952-1.595-1.756 0-3.164.783-3.27 2.543h1.832c.045-.722.56-1.113 1.438-1.113 1.075 0 1.332.496 1.332 1.233v.482l-2.316.165c-1.499.106-2.665.963-2.665 2.453 0 1.279.97 2.452 2.846 2.452 1.12 0 2.029-.541 2.392-1.173"
      />
    </g>
    <defs>
      <clipPath id="MitulaLogoColored_svg__a">
        <path fill="#fff" d="M0 0h55v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgMitulaLogoColored;
