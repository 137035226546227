import styled from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import InputText from 'design-system/components/InputText';
import { FlatButton } from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';
import Section from './Section';
import {
  mandatoryPublisherMappingsErrorSelector,
  publisherMappingsSelector,
} from './selectors';
import {
  PUBLISHER_MAPPING_DELETED,
  PUBLISHER_MAPPING_VALUE_CHANGED,
} from './events';
import InputSelectWithHtmlValidation from '../partials/InputSelectWithHtmlValidation';

const availableBrands = [
  { id: '5', label: 'Resem' },
  { id: '6', label: 'Dot Property' },
  { id: '7', label: 'Thailand Property' },
  { id: '8', label: 'Hipflat' },
  { id: '9', label: 'Properati' },
];

const Brands = styled((props) => (
  <InputSelectWithHtmlValidation
    {...props}
    name="brand"
    label={`Brand${props.required ? '' : ' *'}`}
    choices={availableBrands.map(({ id, label }) => ({ label, value: id }))}
  />
))``;

const PublisherId = styled(({ required, ...props }) => (
  <InputText
    {...props}
    type="text"
    label="Internal brand id"
    required={required}
  />
))``;

const ClientSince = styled(({ required, ...props }) => (
  <InputText
    {...props}
    type="date"
    label="Client since"
    shrink
    required={required}
  />
))``;

const Row = styled.div`
  grid-column: span 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  &:hover {
    ${FlatButton} {
      visibility: visible;
    }
  }
  > div {
    display: flex;
    ${spacing.inline(SPACING_SIZE.M)}
    > * {
      ${spacing.inline(SPACING_SIZE.M)}
    }
    ${Brands}, ${ClientSince} {
      flex: 1;
    }
    ${PublisherId} {
      flex: 3;
    }
    ${FlatButton} {
      visibility: hidden;
      align-self: flex-end;
      ${Icon} {
        ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
      }
    }
  }
`;

function MappingRow({ mapping, required, error, ...props }) {
  return (
    <Row {...props}>
      <div>
        <Brands
          value={mapping?.brandId ?? ''}
          required={required}
          error={error}
          onSelect={({ value }) =>
            dispatch({
              id: PUBLISHER_MAPPING_VALUE_CHANGED,
              payload: {
                brandId: mapping?.brandId ?? value,
                newValue: { brandId: value },
              },
            })
          }
        />
        <PublisherId
          value={mapping?.id ?? ''}
          disabled={mapping == null}
          required={required}
          onChange={(e) =>
            dispatch({
              id: PUBLISHER_MAPPING_VALUE_CHANGED,
              payload: {
                brandId: mapping.brandId,
                newValue: { id: e.target.value },
              },
            })
          }
        />
      </div>
      <div>
        <ClientSince
          value={mapping?.clientSince ?? ''}
          disabled={mapping == null}
          onChange={(e) =>
            dispatch({
              id: PUBLISHER_MAPPING_VALUE_CHANGED,
              payload: {
                brandId: mapping.brandId,
                newValue: { clientSince: e.target.value },
              },
            })
          }
        />
        <FlatButton
          icon={<Icon glyph="bin" />}
          onClick={() =>
            mapping?.brandId &&
            dispatch({
              id: PUBLISHER_MAPPING_DELETED,
              payload: { brandId: mapping.brandId },
            })
          }
        />
      </div>
    </Row>
  );
}

export default function PublisherMapping() {
  const mappings = useSelector(publisherMappingsSelector);
  const error =
    useSelector(mandatoryPublisherMappingsErrorSelector) &&
    'Add at least one mapping';

  return (
    <Section title="Client migration mapping">
      {mappings.map((mapping) => (
        <MappingRow key={mapping.brandId} mapping={mapping} required />
      ))}
      <MappingRow error={error} />
    </Section>
  );
}
