import { Link } from 'react-router-dom';
import { useSelector } from 'reffects-store';
import { OutlinePrimaryButton } from 'design-system/components/Button/presets';
import FormattedMessageWithValidation from '../../FormattedMessageWithValidation/FormattedMessageWithValidation';
import TabLink from '../TabLink';
import UserMenu from '../UserMenu';
import useTracking from '../../../hooks/useTracking';
import { newPropertyForm } from '../../../utils/proppitWebRouter';
import { SubscriptionTabLink } from './SubscriptionTabLink';
import { CompanyProfileTabLink } from './CompanyProfileTabLink';
import { OverviewTabLink } from './OverviewTabLink';
import { InboxTabLink } from './InboxTabLink';
import { ReloadingUnreadLeads } from './ReloadingUnreadLeads';
import { userIsPublisherCollaboratorSelector } from '../../../selectors/user';

function Navigation() {
  const isPublisherCollaborator = useSelector(
    userIsPublisherCollaboratorSelector
  );
  const createPropertyTrackingRef = useTracking({
    trackClick: {
      eventName: 'create-new-listing',
    },
    trackImpression: {
      elementName: 'new-property-btn',
      payload: { visible: true },
    },
    sectionName: 'side-menu-top-new-listing-button',
  });

  const links = [
    <OutlinePrimaryButton
      forwardedAs={Link}
      to={newPropertyForm()}
      ref={createPropertyTrackingRef}
    >
      <FormattedMessageWithValidation id="menu_cta_new_property" />
    </OutlinePrimaryButton>,
    <OverviewTabLink />,
    <TabLink
      to="/properties"
      deep
      eventName="view-properties-page"
      elementName="properties-link"
      sectionName="side-menu-top-properties-link"
    >
      <FormattedMessageWithValidation id="menu_properties" />
    </TabLink>,
    <InboxTabLink />,
    <CompanyProfileTabLink>
      <FormattedMessageWithValidation id="menu_company_profile" />
    </CompanyProfileTabLink>,
    !isPublisherCollaborator && (
      <SubscriptionTabLink>
        <FormattedMessageWithValidation id="menu_subscription" />
      </SubscriptionTabLink>
    ),
    <UserMenu />,
  ];

  return links.map((component, i) => (
    <ReloadingUnreadLeads key={i}>{component}</ReloadingUnreadLeads>
  ));
}

export default Navigation;
