import { useSelector } from 'reffects-store';
import { DropdownFilter } from '../../../partials/DataTable';
import { propertyTypeFilterOptionsSelector } from './selectors';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

export function TypeFilter() {
  const options = useSelector(propertyTypeFilterOptionsSelector);
  return (
    <DropdownFilter
      label={<FormattedMessageWithValidation id="newprop_type_emptystate" />}
      source="propertyType"
      scrollable
      options={options.map(({ tag, ...option }) => ({
        ...option,
        label: <FormattedMessageWithValidation id={tag} />,
      }))}
    />
  );
}
