import { uniqueId } from 'lodash';
import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { table } from '../../../../partials/DataTable/effects/table';
import { COLLECTION_NAME } from './constants';
import { InMemoryTable } from '../../../../partials/DataTable/adapters/inMemoryTableAdapter/InMemoryTable';
import { environment } from '../../../../coeffects/environment';

export function createAdapter() {
  const inMemoryTable = InMemoryTable.create();

  const eventSuffix = uniqueId(COLLECTION_NAME);

  const UPDATE_ITEMS_TABLE_DATA = `UPDATE_ITEMS_TABLE_DATA_${eventSuffix}`;
  const UPDATE_ITEMS_TABLE_DATA_SUCCESS = `UPDATE_ITEMS_TABLE_DATA_SUCCESS_${eventSuffix}`;

  registerEventHandler(
    UPDATE_ITEMS_TABLE_DATA,
    ({ environment: { apiUrl } }, context) =>
      http.get({
        url: `${apiUrl}/publishers/me/invoices`,
        successEvent: [UPDATE_ITEMS_TABLE_DATA_SUCCESS, context],
      }),
    [table.select(), environment()]
  );

  registerEventHandler(
    UPDATE_ITEMS_TABLE_DATA_SUCCESS,
    (_, [response, context]) => {
      const invoices = response.data.rows;

      const { visibleRowsIdList, totalRows } = inMemoryTable
        .setCollection(invoices)
        .get();

      return table.update(context.collectionName, {
        collection: invoices,
        totalItems: totalRows,
        visibleItemsIdList: visibleRowsIdList,
      });
    }
  );

  function registerEvents() {
    return {
      collection: UPDATE_ITEMS_TABLE_DATA,
      events: {
        UPDATE_ITEMS_TABLE_DATA,
        UPDATE_ITEMS_TABLE_DATA_SUCCESS,
      },
    };
  }

  return {
    registerEvents,
  };
}
