import { withoutEventPropagation } from 'design-system/utils/withoutEventPropagation';
import styled, { css } from 'styled-components';
import { useCallback } from 'react';
import { useSelector } from 'reffects-store';
import Table from 'design-system/components/Table';
import Tooltip from 'design-system/components/Tooltip';
import Anchor from 'design-system/components/Anchor';
import { FlatButton } from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';
import { useColumn } from '../../partials/DataTable/hooks/useColumn';
import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { useDialog } from '../../hooks/useDialog';
import { GENERIC_ALERT_MODAL } from '../../partials/modals/generic/AlertDialog';
import { supportEmailSelector } from '../../selectors/config';

const Cell = styled(Table.Cell)`
  cursor: initial;
`;

const BoltButton = styled(FlatButton)`
  ${({ active }) =>
    active &&
    css`
      cursor: initial;
      pointer-events: none;
    `}
`;

export default function PromotionStatusColumn(props) {
  const { value: isPromoted } = useColumn(props);
  const supportEmail = useSelector(supportEmailSelector);
  const { openDialog } = useDialog(GENERIC_ALERT_MODAL);
  const showUpgradeSubscription = useCallback(() => {
    openDialog({
      messages: {
        title: {
          id: 'projects_list_promotion_status_promoted_dialog_title',
        },
        emphasis: {
          id: 'projects_list_promotion_status_promoted_dialog_emphasis',
        },
        body: {
          id: 'projects_list_promotion_status_promoted_dialog_body',
          values: {
            supportEmail: (
              <Anchor href={`mailto:${supportEmail}`}>{supportEmail}</Anchor>
            ),
          },
        },
      },
    });
  }, []);
  return (
    <Cell onClick={withoutEventPropagation()}>
      <Tooltip
        title={
          isPromoted ? (
            <FormattedMessageWithValidation id="projects_list_promotion_status_promoted_tooltip" />
          ) : (
            <FormattedMessageWithValidation id="projects_list_promotion_status_not_promoted_tooltip" />
          )
        }
      >
        <BoltButton
          active={isPromoted}
          icon={<Icon glyph={isPromoted ? 'bolt' : 'boltCrossed'} />}
          onClick={isPromoted ? undefined : showUpgradeSubscription}
        />
      </Tooltip>
    </Cell>
  );
}
