import styled from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import lgThumbnail from 'lightgallery/plugins/thumbnail';

import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import { useCallback, useRef } from 'react';
import Image from 'design-system/components/Image/Image';
import { OutlineButton } from 'design-system/components/Button/presets';
import { RADIUS_SIZE } from 'design-system/styles/radius';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Wrapper = styled.div`
  width: 100%;
  height: 405px;
  border-radius: ${RADIUS_SIZE.LARGE}px;
  overflow: hidden;

  .lg-react-element {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(4, 1fr);
    grid-gap: ${spacing.value(SPACING_SIZE.S)};

    > div {
      ${Image} {
        cursor: pointer;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      &:first-of-type {
        grid-row: span 2;
        grid-column: span ${({ count }) => (count === 1 ? 4 : 2)};
      }

      &:nth-child(2) {
        ${({ count }) => count < 5 && 'grid-row: span 2;'}
        ${({ count }) => count < 3 && 'grid-column: span 2;'}
      }

      &:nth-child(3) {
        ${({ count }) => count === 3 && 'grid-row: span 2;'}
      }
    }
  }
`;

const IMAGES_TO_SHOW = 5;

const LastImage = styled.div`
  position: relative;
  ${Image} {
    filter: brightness(0.5);
  }
  ${OutlineButton} {
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    ${color.background('transparent')}
    ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
  }
`;

const OpenGallery = ({ images, onClick }) => (
  <OutlineButton onClick={onClick}>
    <FormattedMessageWithValidation
      id="project_detail_gallery_show_more"
      values={{ count: images.length - IMAGES_TO_SHOW }}
    />
  </OutlineButton>
);

function ImageGallery({ images, ...props }) {
  const lightGalleryRef = useRef(null);
  const onInit = useCallback((detail) => {
    if (detail) {
      lightGalleryRef.current = detail.instance;
    }
  }, []);
  if (images.length === 0) {
    return null;
  }
  return (
    <Wrapper {...props} count={images.length}>
      <LightGallery
        plugins={[lgZoom, lgThumbnail]}
        onInit={onInit}
        showZoomInOutIcons
        actualSizeIcons={{
          zoomIn: 'lg-actual-size',
          zoomOut: 'lg-actual-size',
        }}
        ref={lightGalleryRef}
      >
        {images.map((image, index) => {
          if (index === IMAGES_TO_SHOW - 1 && images.length > IMAGES_TO_SHOW) {
            return (
              <LastImage key={image} data-src={image}>
                <Image source={image} />
                <OpenGallery
                  images={images}
                  onClick={() => lightGalleryRef.current.goToNextSlide()}
                />
              </LastImage>
            );
          }
          return (
            <div
              data-src={image}
              style={index >= IMAGES_TO_SHOW ? { display: 'none' } : null}
            >
              <Image source={image} />
            </div>
          );
        })}
      </LightGallery>
    </Wrapper>
  );
}

export default styled(ImageGallery)``;
