import { FormattedDate as FormattedDateLib } from 'react-intl';

export default function FormattedDateTime({ value, ...rest }) {
  return (
    <FormattedDateLib
      calendar="gregory"
      dateStyle="short"
      timeStyle="short"
      value={value}
      {...rest}
    />
  );
}
