import * as yup from 'yup';
import { number, string } from 'yup';
import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();
let intl;

export default function configValidationToYup(
  configValidations,
  intlComponent
) {
  const objectSchema = {};
  intl = intlComponent;

  Object.entries(configValidations).forEach(([fieldName, fieldValidation]) => {
    objectSchema[fieldName] = convertConfigValidationToYup(fieldValidation);
  });

  return yup.object(objectSchema).required();
}

function convertConfigValidationToYup({
  originalSchema,
  type,
  exactLength,
  minLength,
  maxLength,
  when,
  required,
  pattern,
  errorMessage,
  oneOf,
}) {
  let schema = originalSchema || yup.string();
  if (type != null) {
    schema = addTypeConstrains(type, required, schema, pattern);
  }

  if (exactLength != null) {
    schema = schema.length(
      exactLength,
      intl.formatMessageWithValidation({
        id: 'form_validations_length',
        values: { length: exactLength },
      })
    );
  }

  if (minLength != null) {
    schema = schema.min(
      minLength,
      intl.formatMessageWithValidation({
        id: 'form_validations_min_length',
        values: { minLength },
      })
    );
  }

  if (maxLength != null) {
    schema = schema.max(
      maxLength,
      intl.formatMessageWithValidation({
        id: 'form_validations_max_length',
        values: { maxLength },
      })
    );
  }

  if (when != null) {
    Object.entries(when).forEach(([siblingName, { is, then, otherwise }]) => {
      schema = schema.when(siblingName, {
        is,
        then: (fieldSchema) =>
          convertConfigValidationToYup({
            originalSchema: fieldSchema,
            ...then,
          }),
        otherwise: (fieldSchema) =>
          convertConfigValidationToYup({
            originalSchema: fieldSchema,
            ...otherwise,
          }),
      });
    });
  }

  if (required) {
    schema = applyRequiredConstrain(schema);
  }

  if (errorMessage) {
    schema = schema.required(
      intl.formatMessageWithValidation({
        id: errorMessage,
      })
    );
  }

  if (oneOf) {
    schema = yup
      .boolean()
      .required(
        intl.formatMessageWithValidation({
          id: errorMessage ?? 'form_validations_required_field',
        })
      )
      .oneOf(
        oneOf,
        intl.formatMessageWithValidation({
          id: errorMessage ?? 'form_validations_invalid_value',
        })
      );
  }

  return schema;
}

function addTypeConstrains(type, required, schema, pattern) {
  let updatedSchema = schema;
  if (type === 'numeric') {
    updatedSchema = updatedSchema.matches(
      /^[0-9]*$/,
      intl.formatMessageWithValidation({
        id: 'form_validations_numeric',
      })
    );
  }

  if (type === 'email') {
    updatedSchema = updatedSchema.email(
      intl.formatMessageWithValidation({
        id: 'validation_error_invalid_email',
      })
    );
  }

  if (type === 'phone') {
    updatedSchema = yup
      .object({
        phoneNumber: required
          ? applyRequiredConstrain(number())
          : number()
              .transform((value) => (Number.isNaN(value) ? undefined : value))
              .nullable(),
        countryCode: required ? applyRequiredConstrain(string()) : string(),
      })
      .test({
        name: 'contactPhone',
        message: intl.formatMessageWithValidation({
          id: 'form_validations_invalid_phone_number',
        }),
        test: (value) => {
          try {
            if (!required && Boolean(value.phoneNumber) === false) {
              return true;
            }

            return phoneUtil.isValidNumber(
              phoneUtil.parseAndKeepRawInput(
                value.phoneNumber.toString(),
                value.countryCode
              )
            );
          } catch (e) {
            return false;
          }
        },
      });
  }

  if (type === 'regex') {
    if (!pattern) {
      throw new Error('Pattern is not defined');
    }
    updatedSchema = updatedSchema.matches(
      new RegExp(pattern),
      intl.formatMessageWithValidation({
        id: 'form_validations_wrong_format',
      })
    );
  }

  if (type === 'boolean') {
    updatedSchema = yup.boolean();
  }

  return updatedSchema;
}

function applyRequiredConstrain(schema) {
  return schema.required(
    intl.formatMessageWithValidation({
      id: 'form_validations_required_field',
    })
  );
}
