import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';

export const MARKDOWN_DOCUMENT_LOAD = 'MARKDOWN_DOCUMENT_LOAD';
export const MARKDOWN_DOCUMENT_LOADED = 'MARKDOWN_DOCUMENT_LOADED';

registerEventHandler(
  MARKDOWN_DOCUMENT_LOAD,
  ({ state: { locale } }, { id, path }) => {
    const [language] = locale.split('-');
    const url = `${path}/${language}.md`;

    return http.get({
      url,
      successEvent: {
        id: MARKDOWN_DOCUMENT_LOADED,
        payload: {
          id,
        },
      },
      errorEvent: {
        id: MARKDOWN_DOCUMENT_LOAD,
        payload: {
          id,
          locale: 'en',
          path,
        },
      },
    });
  },
  [state.get({ locale: 'locale' })]
);

registerEventHandler(MARKDOWN_DOCUMENT_LOADED, (_, [document, { id }]) =>
  state.set({
    markdownDocument: {
      [id]: document,
    },
  })
);
