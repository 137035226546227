import { uniqueId } from 'lodash';
import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { SUBSCRIPTION_PLANS_PRICE_TABLE_ID } from './constants';
import { table } from '../../../partials/DataTable/effects/table';
import { environment } from '../../../coeffects/environment';

export default function createSubscriptionPlanTableAdapter() {
  const eventSuffix = uniqueId(SUBSCRIPTION_PLANS_PRICE_TABLE_ID);
  const UPDATE_ITEMS_TABLE_DATA = `UPDATE_ITEMS_TABLE_DATA_${eventSuffix}`;
  const UPDATE_ITEMS_TABLE_DATA_SUCCESS = `UPDATE_ITEMS_TABLE_DATA_SUCCESS_${eventSuffix}`;

  registerEventHandler(
    UPDATE_ITEMS_TABLE_DATA,
    ({ select, environment: { apiUrl } }, context) => {
      const { country, publishesInTP } = select.filters(context);
      const url =
        country === 'TH'
          ? `${apiUrl}/admin/plans?countryCode=${country}&publishesInTP=${publishesInTP}`
          : `${apiUrl}/admin/plans?countryCode=${country}`;

      return {
        ...http.get({
          url,
          successEvent: [UPDATE_ITEMS_TABLE_DATA_SUCCESS, context],
        }),
      };
    },
    [table.select(), environment()]
  );

  registerEventHandler(
    UPDATE_ITEMS_TABLE_DATA_SUCCESS,
    (__, [{ data: plans }, context]) => {
      const collection = planToTable(plans);

      return table.update(context.collectionName, {
        collection,
        totalItems: collection.length,
      });
    }
  );

  function registerEvents() {
    return {
      collection: UPDATE_ITEMS_TABLE_DATA,
      events: {
        UPDATE_ITEMS_TABLE_DATA,
        UPDATE_ITEMS_TABLE_DATA_SUCCESS,
      },
    };
  }

  return {
    registerEvents,
  };
}

function planToTable(plans) {
  return plans
    .filter((plan) => plan.active)
    .reduce((acc, current) => {
      const displayOrderProperty = `b_${current.displayOrder}`;
      const displayOrderElement = {
        id: current.id,
        name: current.name,
        price: current.price,
        currency: current.currency,
        cpcPrice: current.cpcPrice,
        countryCode: current.countryCode,
        cpcCurrency: '$',
      };

      const index = acc.findIndex(
        (element) =>
          element.id === current.name &&
          element.listings === current.maxBoostableProperties
      );
      if (index < 0) {
        const plan = {
          id: current.name,
          listings: current.maxBoostableProperties,
        };
        plan[displayOrderProperty] = displayOrderElement;
        acc.push(plan);
        return acc;
      }

      acc[index][displayOrderProperty] = displayOrderElement;
      return acc;
    }, [])
    .sort((a, b) => a.listings - b.listings);
}
