import styled, { css } from 'styled-components';
import Image from 'design-system/components/Image/Image';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';

const Thumbnail = styled(Image)`
  overflow: hidden;
  flex-shrink: 0;

  ${radius.regular(RADIUS_SIZE.BASE)}
`;

const modSize = (width, height = width) => css`
  width: ${width}px;
  height: ${height}px;
`;

const modRatio = (ratio) => css`
  width: 100%;
  aspect-ratio: ${ratio};
`;

const modMaxWidthRatio = (height, ratio) => css`
  overflow: hidden;
  width: auto;
  height: ${height}px;
  min-width: ${height}px;
  max-width: ${height * ratio}px;
`;

Thumbnail.mods = {
  size: modSize,
  ratio: modRatio,
  maxWidthRatio: modMaxWidthRatio,
};

export default Thumbnail;
