import styled from 'styled-components';

function Radio({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={className}
    >
      <circle
        xmlns="http://www.w3.org/2000/svg"
        cx="8"
        cy="8"
        r="3"
        fill="white"
      />
    </svg>
  );
}

export default styled(Radio)``;
