import { subscribe } from 'reffects-store';
import { dispatch } from 'reffects';
import { useMount } from 'react-use';
import BaseAuthenticationPage from './BaseAuthenticationPage';
import ForgotPasswordForm from './forms/ForgotPasswordForm';
import VerifyYourEmail from './forms/VerifyYourEmail';
import { passwordRecoveryMailIsSentSelector } from './selectors';
import { FORGOT_PASSWORD_PAGE_MOUNTED } from './events';

function ForgotPassword({ mailSent }) {
  useMount(() => dispatch(FORGOT_PASSWORD_PAGE_MOUNTED));
  return (
    <BaseAuthenticationPage>
      {!mailSent ? <ForgotPasswordForm /> : <VerifyYourEmail />}
    </BaseAuthenticationPage>
  );
}

export default subscribe(ForgotPassword, (state) => ({
  mailSent: passwordRecoveryMailIsSentSelector(state),
}));
