import Table from 'design-system/components/Table';
import PropertySnippet from '../../../PropertySnippet';
import { useColumn } from '../../hooks/useColumn';

export function SnippetColumn(props) {
  const {
    value: { title, address, image },
  } = useColumn(props);

  return (
    <Table.Cell {...props}>
      <PropertySnippet title={title} address={address} image={image} />
    </Table.Cell>
  );
}
