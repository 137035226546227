import { subscribe, useSelector } from 'reffects-store';
import { useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import Section from 'design-system/components/Section';
import InputRow from 'design-system/components/InputRow';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { currencySelector } from '../../../selectors/country';
import SelectField from '../../../partials/fields/SelectField';
import NumberField, {
  useCurrencyPosition,
} from '../../../partials/fields/NumberField';
import {
  OPERATION_TYPE_SELL,
  ownershipTranslationTags,
} from '../../../constants/ad';
import CheckboxField from '../../../partials/fields/CheckboxField';
import { SUITABLE_FOR_CREDIT_NAME } from '../constants';
import HiddenField from '../../../partials/fields/HiddenField';
import {
  shouldShowOwnershipSelector,
  shouldShowTenureSelector,
  showSuitableForCreditSelector,
} from '../selectors';

function OperationSection({ currencies }) {
  const intl = useIntl();
  const { watch, clearErrors } = useFormContext();

  const [operationType, propertyType, rentPriceCurrency, sellPriceCurrency] =
    watch([
      'operationType',
      'propertyType',
      'rentPriceCurrency',
      'sellPriceCurrency',
    ]);

  const showSuitableForCredit = useSelector(showSuitableForCreditSelector);
  const showOwnership = useSelector(shouldShowOwnershipSelector);
  const showTenure = useSelector(shouldShowTenureSelector);

  useEffect(clearErrors, [propertyType]);

  const hasMultipleCurrencies = Object.keys(currencies).length > 1;

  return (
    <>
      {(operationType === 'sell' || operationType === 'sell-and-rent') && (
        <Section
          title={
            <FormattedMessageWithValidation id="ad_form_section_price_sell" />
          }
        >
          <InputRow
            arrangement={
              !showOwnership && hasMultipleCurrencies ? [2, 4] : undefined
            }
          >
            {hasMultipleCurrencies && (
              <SelectField
                label={
                  <FormattedMessageWithValidation id="newprop_currency_emptystate" />
                }
                name="sellPriceCurrency"
                choices={Object.entries(currencies).map(([code, symbol]) => ({
                  value: code,
                  label: symbol,
                }))}
                data-tag="operation_sellPriceCurrency"
              />
            )}
            <NumberField
              label={
                <FormattedMessageWithValidation id="newprop_price_emptystate" />
              }
              name="sellPrice"
              unit={currencies[sellPriceCurrency]}
              unitPosition={useCurrencyPosition(sellPriceCurrency)}
              data-tag="operation_sellPrice"
              data-test="operation_sellPrice"
            />
            {showOwnership && (
              <SelectField
                name="ownership"
                label={
                  <FormattedMessageWithValidation id="newprop_ownership_header" />
                }
                placeholder={intl.formatMessage({
                  id: 'newprop_furnished_emptystate',
                })}
                choices={[
                  {
                    value: 'freehold',
                    label: intl.formatMessage({
                      id: ownershipTranslationTags.get('freehold'),
                    }),
                  },
                  {
                    value: 'leasehold',
                    label: intl.formatMessage({
                      id: ownershipTranslationTags.get('leasehold'),
                    }),
                  },
                ]}
              />
            )}
          </InputRow>
        </Section>
      )}
      {(operationType === 'rent' || operationType === 'sell-and-rent') && (
        <Section
          title={
            <FormattedMessageWithValidation id="ad_form_section_price_rent" />
          }
        >
          <InputRow
            arrangement={
              !showOwnership && hasMultipleCurrencies ? [2, 4] : undefined
            }
          >
            {hasMultipleCurrencies && (
              <SelectField
                label={
                  <FormattedMessageWithValidation id="newprop_currency_emptystate" />
                }
                name="rentPriceCurrency"
                choices={Object.entries(currencies).map(([code, symbol]) => ({
                  value: code,
                  label: symbol,
                }))}
                data-tag="operation_rentPriceCurrency"
              />
            )}
            <NumberField
              label={
                <FormattedMessageWithValidation id="newprop_price_emptystate" />
              }
              name="rentPrice"
              unit={currencies[rentPriceCurrency]}
              unitPosition={useCurrencyPosition(rentPriceCurrency)}
              data-tag="operation_rentPrice"
            />
            {showTenure && (
              <SelectField
                name="tenureInYears"
                label={
                  <FormattedMessageWithValidation id="newprop_duration_header" />
                }
                placeholder={intl.formatMessage({
                  id: 'newprop_furnished_emptystate',
                })}
                choices={[
                  {
                    value: 1,
                    label: intl.formatMessage({
                      id: 'newprop_duration_1',
                    }),
                  },
                  {
                    value: 3,
                    label: intl.formatMessage({
                      id: 'newprop_duration_3',
                    }),
                  },
                  {
                    value: 6,
                    label: intl.formatMessage({
                      id: 'newprop_duration_6',
                    }),
                  },
                  {
                    value: 9,
                    label: intl.formatMessage({
                      id: 'newprop_duration_9',
                    }),
                  },
                  {
                    value: 12,
                    label: intl.formatMessage({
                      id: 'newprop_duration_12',
                    }),
                  },
                ]}
              />
            )}
          </InputRow>
        </Section>
      )}
      {showSuitableForCredit && operationType === OPERATION_TYPE_SELL && (
        <Section
          title={
            <FormattedMessageWithValidation id="ad_form_section_financing" />
          }
        >
          <InputRow>
            <CheckboxField
              id={SUITABLE_FOR_CREDIT_NAME}
              name={SUITABLE_FOR_CREDIT_NAME}
            >
              <FormattedMessageWithValidation id="adform_field_apto_credito" />
            </CheckboxField>
          </InputRow>
        </Section>
      )}
      <HiddenField name="sellPriceCurrency" />
      <HiddenField name="rentPriceCurrency" />
    </>
  );
}

export default subscribe(OperationSection, function mapStateToProps(state) {
  const currencies = currencySelector(state).reduce(
    (acc, { code, symbol }) => ({
      ...acc,
      [code]: symbol,
    }),
    {}
  );
  return {
    currencies,
  };
});
