import styled from 'styled-components';
import {
  FONT_FAMILY,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';

const DiscreteAnchor = styled.a`
  font-size: 12px;
  line-height: 15px;
  text-decoration: none;
  white-space: nowrap;

  ${typography.font(FONT_FAMILY.PRIMARY)}
  ${typography.weight(FONT_WEIGHT.NORMAL)}
  ${spacing.inline(SPACING_SIZE.M)}
  ${color.text(COLOR_PALETTE.NEUTRAL_A80)}

  &:visited,
  &:active {
    ${color.text(COLOR_PALETTE.NEUTRAL_A80)}
  }

  &:hover {
    text-decoration: underline;
  }
`;

export default DiscreteAnchor;
