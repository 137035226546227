import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import {
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import Typography, {
  TYPOGRAPHY_VARIANT,
} from 'design-system/components/Typography/Typography';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';

const Wrapper = styled(ReactMarkdown)`
  h1,
  h2,
  h3,
  p,
  li,
  a,
  th,
  td {
    ${typography.font(FONT_FAMILY.PRIMARY)}
  }

  h1 {
    ${Typography.mods.variant(TYPOGRAPHY_VARIANT.HEADING_1)}
    ${color.text(COLOR_PALETTE.SECONDARY_BASE)}
    
    & + p {
      margin-bottom: ${spacing.value(SPACING_SIZE.M)};
    }
  }

  h2 {
    ${Typography.mods.variant(TYPOGRAPHY_VARIANT.HEADING_2)}
    ${color.text(COLOR_PALETTE.SECONDARY_BASE)}
    margin-top: ${spacing.value(SPACING_SIZE.XL)}
  }

  h3 {
    ${Typography.mods.variant(TYPOGRAPHY_VARIANT.SUBTITLE_1)}
    ${color.text(COLOR_PALETTE.SECONDARY_BASE)}
  }

  ul,
  ol,
  p {
    ${Typography.mods.variant(TYPOGRAPHY_VARIANT.BODY_1)}
    ${color.text(COLOR_PALETTE.NEUTRAL_A80)}
    ${typography.weight(FONT_WEIGHT.LIGHT)}
  }

  ul,
  ol {
    list-style-position: outside;
    padding-left: 20px;

    li {
      padding-left: ${spacing.value(SPACING_SIZE.XXS)};
      ${spacing.stack(SPACING_SIZE.S)};
    }

    ul {
      padding-left: ${spacing.value(SPACING_SIZE.L)};
    }
  }

  a {
    cursor: pointer;
    ${typography.weight(FONT_WEIGHT.NORMAL)}
    ${color.text(COLOR_PALETTE.BLUEBERRY_BASE)}
    
      &:visited {
      ${color.text(COLOR_PALETTE.BLUEBERRY_BASE)}
    }

    &:active {
      ${color.text(COLOR_PALETTE.PRIMARY_BASE)}
    }
  }

  table {
    margin-bottom: 1rem;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    width: 100%;
  }

  td,
  th {
    ${spacing.inset(SPACING_SIZE.M)}
    ${color.text(COLOR_PALETTE.NEUTRAL_A80)}
    width: 50%;
  }

  th {
    ${Typography.mods.variant(TYPOGRAPHY_VARIANT.OVERLINE)}
    ${color.background(COLOR_PALETTE.NEUTRAL_A05)}
    opacity: 1;
    text-align: left;
  }

  td {
    ${typography.weight(FONT_WEIGHT.LIGHT)}
    ${typography.size(FONT_SIZE.M)}
    vertical-align: top;

    ul {
      margin-top: 0;
    }
  }
`;

const MarkdownDocument = ({ content }) => (
  <Wrapper rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
    {content}
  </Wrapper>
);

export default MarkdownDocument;
