import styled from 'styled-components';
import { elevation, ELEVATION_INDEX } from 'design-system/styles/elevation';
import { radius } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import Typography, {
  TYPOGRAPHY_VARIANT,
} from 'design-system/components/Typography/Typography';

const Wrapper = styled.div`
  position: fixed;
  left: 50%;
  bottom: ${spacing.value(SPACING_SIZE.XXL)};

  transform: translateX(-50%);

  user-select: none;
  overflow: hidden;

  z-index: 10;

  ${radius.pill()}
  ${spacing.insetSquish(SPACING_SIZE.XL)}
  ${color.background(COLOR_PALETTE.NEUTRAL_BASE)}
  ${elevation.regular(ELEVATION_INDEX.BASE)}
`;

const Text = styled(Typography)`
  ${Typography.mods.variant(TYPOGRAPHY_VARIANT.BODY_1)}
  ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
`;

function Toast({ open, children, ...rest }) {
  if (!open) return null;
  return (
    <Wrapper {...rest}>
      <Text>{children}</Text>
    </Wrapper>
  );
}

export default Toast;
