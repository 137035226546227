import { createSelector } from 'reselect';
import { HEADCELLS } from './constants';

export function subscriptionPlansManagementCountrySelector(state) {
  return state['plansManagement:selectedCountry'] ?? 'CL';
}

function plansSelector(state) {
  return state['plansManagement:plans'] ?? [];
}

export function planTableRowsSelector(state) {
  const plans = plansSelector(state);

  return [
    ...new Set(
      plans
        .map((plan) => plan.maxBoostableProperties)
        .sort((number, anotherNumber) => number - anotherNumber)
    ),
  ];
}

export const plansPricesSelector = createSelector(
  [plansSelector, planTableRowsSelector],
  (plans, rows) => {
    const columns = HEADCELLS.map((cell) => cell.id).filter(Number.isInteger);

    return rows.map((row) =>
      columns.map(
        (column) =>
          plans.find(
            ({ maxBoostableProperties, displayOrder }) =>
              maxBoostableProperties === row && displayOrder === column
          ) ?? null
      )
    );
  }
);

export function hasValidationErrorsSelector(state) {
  const { formValidations } = state;
  return !(!formValidations || Object.keys(formValidations).length === 0);
}
