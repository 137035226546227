import * as React from "react";
const SvgTwitter = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <rect
      width={31.3}
      height={31.3}
      x={0.35}
      y={0.35}
      stroke="#111B3C"
      strokeWidth={0.7}
      rx={3.65}
    />
    <path
      fill="#000"
      fillRule="evenodd"
      d="M7.964 21.006a9.4 9.4 0 0 0 5.132 1.506c6.228 0 9.74-5.251 9.513-9.952a7.2 7.2 0 0 0 1.687-1.733 7.8 7.8 0 0 1-1.94.526A3.3 3.3 0 0 0 23.84 9.48a6.4 6.4 0 0 1-2.121.822 3.4 3.4 0 0 0-2.441-1.05c-2.168 0-3.765 2.01-3.263 4.11-2.783-.136-5.246-1.484-6.912-3.491a3.356 3.356 0 0 0 1.05 4.472 3.4 3.4 0 0 1-1.53-.434c-.046 1.552 1.072 3.014 2.694 3.331a3.04 3.04 0 0 1-1.506.046 3.29 3.29 0 0 0 3.125 2.328c-1.413 1.117-3.193 1.597-4.972 1.391"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTwitter;
