import { css } from 'styled-components';

import { COLOR_SEMANTIC } from './color';

export const DIVIDER_SIZE = {
  NONE: 0,
  THIN: 1,
};

const right = (size) => css`
  border-right: ${size}px solid ${COLOR_SEMANTIC.DIVIDER};
`;
const left = (size) => css`
  border-left: ${size}px solid ${COLOR_SEMANTIC.DIVIDER};
`;
const bottom = (size) => css`
  border-bottom: ${size}px solid ${COLOR_SEMANTIC.DIVIDER};
`;
const top = (size) => css`
  border-top: ${size}px solid ${COLOR_SEMANTIC.DIVIDER};
`;

export const divider = {
  right,
  left,
  bottom,
  top,
};
