import { effects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { IS_LOGGED_IN } from '../../pages/Authentication/constants';
import { redirectTo } from '../../effects/routing';
import { REDIRECTED_TO_LOGIN } from '../events';
import { BACKOFFICE_ROLES } from '../../constants/user';
import { checkoutBillingInformationRoute } from '../../utils/proppitWebRouter';
import { FIRST_PAYMENT_SUBROUTE } from '../../pages/Checkout/configuration/constants';

export const PRIVATE_REDIRECT = 'PRIVATE_REDIRECT';
export const CHECK_IS_LOGGED_IN = 'CHECK_IS_LOGGED_IN';
export const CHECK_HAS_FIRST_PAYMENT_PENDING =
  'CHECK_HAS_FIRST_PAYMENT_PENDING';
export const CHECKOUT_ROUTE_NAVIGATED = 'CHECKOUT_ROUTE_NAVIGATED';

registerEventHandler(
  PRIVATE_REDIRECT,
  ({ state: { isLoggedIn } }, { to }) =>
    !isLoggedIn ? effects.dispatch(REDIRECTED_TO_LOGIN) : redirectTo(to),
  [state.get({ isLoggedIn: IS_LOGGED_IN })]
);

registerEventHandler(
  CHECK_IS_LOGGED_IN,
  ({ state: { isLoggedIn } }) =>
    !isLoggedIn ? effects.dispatch(REDIRECTED_TO_LOGIN) : {},
  [state.get({ isLoggedIn: IS_LOGGED_IN })]
);

registerEventHandler(
  CHECK_HAS_FIRST_PAYMENT_PENDING,
  ({ state: { role, hasFirstPaymentPending, scrId } }) => {
    if (
      hasFirstPaymentPending &&
      !Object.values(BACKOFFICE_ROLES).includes(role)
    ) {
      return redirectTo(
        checkoutBillingInformationRoute({
          subscriptionChangeRequestId: scrId,
          subRoute: FIRST_PAYMENT_SUBROUTE,
        })
      );
    }
    return {};
  },
  [
    state.get({
      role: 'user.role',
      hasFirstPaymentPending: 'publisher:hasActiveFirstPaymentCheckout',
      scrId: 'publisher:firstPaymentSubscriptionChangeRequestId',
    }),
  ]
);

registerEventHandler(CHECKOUT_ROUTE_NAVIGATED, (_, checkoutConfig) =>
  state.set({ checkoutConfig })
);
