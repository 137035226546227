import styled from 'styled-components';

const QrImage = styled.img`
  max-width: 160px;
  max-height: 224px;
`;

export default function BankTransferQrCode({ showQr }) {
  return showQr ? (
    <QrImage src="/images/subscriptionBankTransferQr.png" />
  ) : null;
}
