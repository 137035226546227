import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { environment } from '../../coeffects/environment';

export const DEFAULT_CONTACT_REQUESTED = 'DEFAULT_CONTACT_REQUESTED';
export const DEFAULT_CONTACT_LOADED = 'DEFAULT_CONTACT_LOADED';

registerEventHandler(
  DEFAULT_CONTACT_REQUESTED,
  ({ environment: { apiUrl } }) => ({
    ...state.set({
      'adForm:isInitialized': false,
    }),
    ...http.get({
      url: `${apiUrl}/users/me/default-contact`,
      successEvent: DEFAULT_CONTACT_LOADED,
    }),
  }),
  [environment()]
);

registerEventHandler(
  DEFAULT_CONTACT_LOADED,
  ({ state: { whatsappCallingCode } }, [{ data }]) =>
    state.set({
      'adForm:isInitialized': true,
      'adForm:defaultContact': {
        contactEmails: data.contactEmails,
        contactPhone: data.contactPhone,
        contactFacebookMessenger: data.contactFacebookMessenger,
        contactLine: data.contactLine,
        contactViber: data.contactViber,
        contactWhatsApp: data.contactWhatsApp
          ? data.contactWhatsApp.replace(whatsappCallingCode, '')
          : undefined,
      },
    }),
  [
    state.get({
      whatsappCallingCode: 'countryConfig.phoneNumber.callingCode',
    }),
  ]
);
