export const COLUMN_SORTING = {
  ASCENDING: Symbol('COLUMN_SORTING_ASCENDING'),
  DESCENDING: Symbol('COLUMN_SORTING_DESCENDING'),
  INDETERMINATE: Symbol('COLUMN_SORTING_INDETERMINATE'),
  NOT_SORTABLE: Symbol('COLUMN_SORTING_NOT_SORTABLE'),
};

export const ROWS_PER_PAGE = {
  FIVE: 5,
  TEN: 10,
  TWENTY_FIVE: 25,
  HUNDRED: 100,
};

export const TABLE_VARIANT = {
  DEFAULT: Symbol('TABLE_VARIANT_DEFAULT'),
  SMALL: Symbol('TABLE_VARIANT_DEFAULT'),
};

export const TABLE_ROW_SEPARATION = {
  DEFAULT: Symbol('TABLE_ROW_SEPARATION_DEFAULT'),
  EVEN_AND_ODD: Symbol('TABLE_ROW_SEPARATION_EVEN_AND_ODD'),
};
