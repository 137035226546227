import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Kpi from '../../../../partials/Kpi';
import { enquiriesRoute } from '../../../../utils/proppitWebRouter';
import Amount from './Amount';
import { LeadCounter } from '../KeyResults/LeadCounter';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);

  ${Kpi} {
    ${spacing.inline(SPACING_SIZE.L, false)};
  }
`;

function LeadsKpis({ kpis, ...props }) {
  return (
    <Wrapper {...props}>
      <Kpi
        amount={<Amount value={kpis.applies} />}
        linkTo={kpis.applies > 0 && enquiriesRoute()}
      >
        <LeadCounter value={kpis.leads} />
      </Kpi>
    </Wrapper>
  );
}

export default LeadsKpis;
