import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import Tile from 'design-system/components/Tile';
import { Link } from 'react-router-dom';
import { PrimaryButton } from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import { border } from 'design-system/styles/border';
import Illustration from 'design-system/components/Illustration/Illustration';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import Typography from 'design-system/components/Typography/Typography';
import {
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { publisherIdSelector } from '../../../../selectors/publisher';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import useTracking from '../../../../hooks/useTracking';

const Wrapper = styled(Tile)`
  display: flex;
  ${spacing.inset(SPACING_SIZE.M)}
  ${border.outer({ color: COLOR_PALETTE.PRIMARY_BASE })}
`;

const IllustrationWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  min-height: 120px;

  ${spacing.inline(SPACING_SIZE.L)}
`;

const Title = styled(Typography)`
  ${spacing.stack(SPACING_SIZE.XS, false)}
  ${color.text(COLOR_PALETTE.PRIMARY_A120)}
  ${typography.size(FONT_SIZE.L)}
  ${typography.weight(FONT_WEIGHT.SEMIBOLD)}
  ${typography.lineHeight(18)}
`;

const Body = styled(Typography)`
  ${spacing.stack(SPACING_SIZE.M, false)}
  ${color.text(COLOR_PALETTE.PRIMARY_A120)}
  ${typography.size(FONT_SIZE.S)}
  ${typography.weight(FONT_WEIGHT.MEDIUM)}
  ${typography.lineHeight(14)}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  ${PrimaryButton} {
    &:visited {
      ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
    }
  }
`;

export default function WasiCard() {
  const publisherId = useSelector(publisherIdSelector);
  const [linkElement, setLinkElement] = useState(null);
  const ref = useCallback((node) => {
    if (node != null && linkElement !== node) setLinkElement(node);
  }, []);
  useTracking({
    ref: linkElement,
    trackClick: {
      eventName: 'get-crm-info',
    },
    trackImpression: {
      elementName: 'get-crm-info-btn',
    },
    sectionName: 'overview-listings-counter-right-new-listing-button',
  });
  return (
    <Wrapper>
      <IllustrationWrapper>
        <Illustration name="deal" />
      </IllustrationWrapper>
      <Content>
        <Title>
          <FormattedMessageWithValidation id="overview_crm_info_cta_title" />
        </Title>
        <Body>
          <FormattedMessageWithValidation id="overview_crm_info_text" />
        </Body>
        <PrimaryButton
          forwardedAs={Link}
          target="_blank"
          $size={BUTTON_SIZE.SMALL}
          href={`https://wasi.co/softwareinmobiliario/?publisherId=${publisherId}`}
          ref={ref}
        >
          <FormattedMessageWithValidation id="overview_crm_info_cta" />
        </PrimaryButton>
      </Content>
    </Wrapper>
  );
}
