import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { useState } from 'react';
import {
  BodyWrapper,
  By,
  Details,
  Footer,
  List,
  Paragraph,
  RefList,
  Title,
  Action,
  JSONPretty,
} from './ItemComponents';
import { Id, IdMark } from './Id';

function extracted(event) {
  let title = 'SCR';
  let description = '...';
  let isFirst = false;

  switch (event.typeDetailed) {
    // SCR
    case 'CHANGE_REQUEST_CREATED':
      isFirst = true;
      title = `SCR ${event.scr.scrType} New`;
      description = 'New SCR has been created.';
      break;
    case 'CHANGE_REQUEST_PAID':
      title = `SCR ${event.scr.scrType} payed`;
      description = 'SCR marked as payed.';
      break;
    case 'CHANGE_REQUEST_CANCELLED':
      title = `SCR ${event.scr.scrType} cancelled`;
      description = 'SCR has been cancelled.';
      break;
    case 'CHANGE_REQUEST_EXPIRED':
      title = `SCR ${event.scr.scrType} expired`;
      description = 'SCR has expired.';
      break;
    case 'CHANGE_REQUEST_ACTIVATED':
      title = `SCR ${event.scr.scrType} activated`;
      description = 'SCR has been activated.';
      break;
    default:
      break;
  }

  const details = [];
  const products = [[], []];

  event.scr.products.forEach((scrProducts, i) => {
    if (scrProducts.id === 'superboost') {
      products[i].push(
        <li>
          <b>Product Superboost</b>
        </li>
      );
    } else {
      products[i].push(
        <li>
          <b>Product Base</b>
        </li>
      );
    }

    products[i].push(<li>Name: {scrProducts.name}</li>);
    products[i].push(<li>Units: {scrProducts.maxBoosteableProperties}</li>);
    products[i].push(<li>Start date: {scrProducts.startDate}</li>);
    products[i].push(<li>Next billing date: {scrProducts.nextBillingDate}</li>);
  });

  // References
  if (event.scr.bankReceipt) {
    details.push(
      <li>
        Bank receipt uploaded: <a href={event.scr.bankReceipt}>open</a>
      </li>
    );
  }

  const refs = [];
  if (event.scr.lineId) {
    refs.push(
      <li>
        Assigned Line ID: <Id id={event.scr.lineId} />
      </li>
    );
  }
  if (event.scr.paymentRequestId) {
    refs.push(
      <li>
        Payment Request ID: <Id id={event.scr.paymentRequestId} />
      </li>
    );
  }
  return { isFirst, title, description, details, products, refs };
}

export function SCR({ event }) {
  const [expanded, expand] = useState(false);
  const { isFirst, title, description, details, products, refs } =
    extracted(event);

  function onClick(e) {
    expand(!expanded);
    e.preventDefault();
  }

  return (
    <BodyWrapper>
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
      {isFirst && <a id={event.id} />}
      <Title>{title}</Title>
      <Details>
        <Paragraph>{description}</Paragraph>
        <Products>
          {products.map((p) => (
            <List>{p}</List>
          ))}
        </Products>
        <List>{details}</List>
        {!expanded && (
          <Action>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" onClick={onClick}>
              expand
            </a>
          </Action>
        )}
        {expanded && (
          <Details>
            <JSONPretty data={event} />
            <Action>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#" onClick={onClick}>
                close
              </a>
            </Action>
          </Details>
        )}
      </Details>
      <Footer>
        <RefList>
          <li>
            <By event={event} />
          </li>
          <li>
            <IdMark id={event.id} />
          </li>
          {refs.map((r) => r)}
        </RefList>
      </Footer>
    </BodyWrapper>
  );
}

const Products = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${spacing.stack(SPACING_SIZE.S)};
`;
