import { useController, useFormContext } from 'react-hook-form';

import InputChips from 'design-system/components/InputChips/InputChips';
import { useRegisterFieldErrors } from '../../pages/publication/partials/FieldGroup';

const ensureArrayValue = (value = []) =>
  Array.isArray(value) ? value : [value];

const getFirstError = (errors) => errors.find(Boolean);

const mapErrorsToBooleanFlags = (errors) => Array.from(errors, Boolean);

function ChipsField({ name, tip, ...rest }) {
  const { control } = useFormContext();
  const {
    fieldState: { error },
    field: { value, onChange, ref },
  } = useController({
    name,
    control,
    defaultValue: [],
  });

  useRegisterFieldErrors({ name, errors: error });

  const errors = ensureArrayValue(error);
  const firstError = getFirstError(errors);
  const errorFlags = mapErrorsToBooleanFlags(errors);

  return (
    <InputChips
      ref={ref}
      value={value}
      tip={firstError?.message ?? tip}
      errors={errorFlags}
      onChange={onChange}
      {...rest}
    />
  );
}

export default ChipsField;
