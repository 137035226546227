import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import { environment } from '../../../../coeffects/environment';

export const LEAD_ENQUIRED_PROPERTIES_REQUEST =
  'LEAD_ENQUIRED_PROPERTIES_REQUEST';
export const LEAD_ENQUIRED_PROPERTIES_REQUEST_SUCCESS =
  'LEAD_ENQUIRED_PROPERTIES_REQUEST_SUCCESS';

export const OPEN_PROPERTY_DETAIL = 'OPEN_PROPERTY_DETAIL';
export const CLOSE_PROPERTY_DETAIL = 'CLOSE_PROPERTY_DETAIL';

registerEventHandler(
  LEAD_ENQUIRED_PROPERTIES_REQUEST,
  ({ environment: { apiUrl } }, { leadId }) => ({
    ...state.set({ leadEnquiredPropertiesLoading: true }),
    ...http.get({
      url: `${apiUrl}/leads/${leadId}/properties`,
      successEvent: LEAD_ENQUIRED_PROPERTIES_REQUEST_SUCCESS,
    }),
  }),
  [environment()]
);

registerEventHandler(
  LEAD_ENQUIRED_PROPERTIES_REQUEST_SUCCESS,
  (_, [{ data }]) =>
    state.set({
      leadEnquiredProperties: data,
      leadEnquiredPropertiesLoading: false,
    })
);

registerEventHandler(OPEN_PROPERTY_DETAIL, (_, { propertyId }) =>
  state.set({ selectedPropertyId: propertyId })
);

registerEventHandler(CLOSE_PROPERTY_DETAIL, () =>
  state.set({ selectedPropertyId: undefined })
);
