import { createSelector } from 'reselect';
import {
  hasSuperboostSubscriptionSelector,
  publisherSelector,
  publisherSubscriptionPlanIsFreeSelector,
} from '../../../selectors/publisher';

export function isBoostableSelector(state) {
  return !(
    publisherSubscriptionPlanIsFreeSelector(state) &&
    hasSuperboostSubscriptionSelector(state)
  );
}

export const publisherLoadingAdsModeSelector = createSelector(
  publisherSelector,
  (publisher) => publisher?.loadingAdsMode
);
