import styled from 'styled-components';
import { COLOR_PALETTE } from '../../../styles/color';
import Typography, { TYPOGRAPHY_VARIANT } from '../Typography';

import BasePresetTypography from './BasePresetTypography';

export const Heading2 = styled(BasePresetTypography)`
  ${Typography.mods.variant(TYPOGRAPHY_VARIANT.HEADING_2)}
`;

Heading2.defaultProps = {
  $color: COLOR_PALETTE.SECONDARY_NEUTRAL_BASEBASE,
};
