import styled from 'styled-components';
import { PrimaryButton } from 'design-system/components/Button/presets';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { FONT_WEIGHT } from 'design-system/styles/typography';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  ${spacing.inset(SPACING_SIZE.M)}
  ${color.background(COLOR_PALETTE.PRIMARY_A20)}
  > * {
    ${spacing.inline(SPACING_SIZE.M)}
  }
`;

const RibbonCta = styled(PrimaryButton)`
  margin-left: auto;
  height: 36px;
`;

function Ribbon({ children, buttonLabel, onClick, ...rest }) {
  return (
    <Wrapper {...rest}>
      <Subtitle3 $weight={FONT_WEIGHT.MEDIUM}>{children}</Subtitle3>
      <RibbonCta onClick={onClick}>{buttonLabel}</RibbonCta>
    </Wrapper>
  );
}

export default styled(Ribbon)``;
