import BreakdownTable from 'design-system/components/BreakdownTable/BreakdownTable';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { TAX_DISCOUNT } from '../../../../constants/subscription';
import Price from '../../../../partials/Price/Price';

const colombiaTaxesDiscount = {
  [TAX_DISCOUNT.RETEFUENTE]: ({ percentage }) => (
    <FormattedMessageWithValidation
      id="subscription_summary_tax_retefuente_colombia_code_discount"
      values={{
        discount: percentage,
      }}
    />
  ),
  [TAX_DISCOUNT.RETE_IVA]: ({ percentage }) => (
    <FormattedMessageWithValidation
      id="subscription_summary_tax_reteiva_colombia_code_discount"
      values={{
        discount: percentage,
      }}
    />
  ),
  [TAX_DISCOUNT.RETE_ICA]: ({ percentage }) => (
    <FormattedMessageWithValidation
      id="subscription_summary_tax_reteica_colombia_code_discount"
      values={{
        discount: percentage,
      }}
    />
  ),
};
export default function TaxDiscounts({ taxDiscounts }) {
  return (
    <>
      {Object.values(taxDiscounts).map(
        ({ name, amount, currency, percentage }) => (
          <BreakdownTable.Row
            key={name}
            title={colombiaTaxesDiscount[name]({ percentage })}
            price={
              <>
                -<Price amount={amount} currency={currency} />
              </>
            }
          />
        )
      )}
    </>
  );
}
