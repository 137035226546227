import { useSelector } from 'reffects-store';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import Section from 'design-system/components/Section';
import InputRow from 'design-system/components/InputRow';
import { FieldGroup } from '../../partials/FieldGroup';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import CheckboxField from '../../../../partials/fields/CheckboxField';
import { detailsOfPropertyTypeSelector } from '../../selectors';
import { getPublisherIsRematesSelector } from '../../../../selectors/publisher';
import OperationSection from '../../partials/OperationSection';
import OperationTypeSection from '../../partials/OperationTypeSection';
import PropertyTypeSection from '../../partials/PropertyTypeSection';

const Wrapper = styled.div`
  display: none;
`;

export default function PropertyOperationGroup({ order }) {
  const { watch } = useFormContext();
  const [propertyType] = watch(['propertyType']);
  const details = useSelector((state) =>
    detailsOfPropertyTypeSelector(state, propertyType)
  );
  const publisherHasRematesSubscription = useSelector(
    getPublisherIsRematesSelector
  );
  return (
    <FieldGroup
      id="operation"
      title={<FormattedMessageWithValidation id="ad_form_group_operation" />}
      order={order}
    >
      <OperationTypeSection />
      <PropertyTypeSection
        choiceLabel={
          <FormattedMessageWithValidation id="newprop_type_emptystate" />
        }
      />
      {details.includes('bankProperty') &&
        getRematesCheckBoxField(publisherHasRematesSubscription)}
      <OperationSection />
    </FieldGroup>
  );
}

function getRematesCheckBoxField(isRemates) {
  if (isRemates) {
    return (
      <Wrapper>
        <Section
          title={<FormattedMessageWithValidation id="ad_form_section_bank" />}
        >
          <InputRow>
            <CheckboxField name="bankProperty" disabled>
              <FormattedMessageWithValidation id="adform_field_bank_property" />
            </CheckboxField>
          </InputRow>
        </Section>
      </Wrapper>
    );
  }
  return (
    <Section
      title={<FormattedMessageWithValidation id="ad_form_section_bank" />}
    >
      <InputRow>
        <CheckboxField name="bankProperty">
          <FormattedMessageWithValidation id="adform_field_bank_property" />
        </CheckboxField>
      </InputRow>
    </Section>
  );
}
