import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import queryString from 'query-string';
import { environment } from '../../../../coeffects/environment';
import { LEAD_FILTERS } from '../../constants';
import { fromUTCDate } from '../../../../utils/dates';

export const LOAD_LEADS_STATS = 'LOAD_LEADS_STATS';
export const LEADS_STATS_LOADED = 'LEADS_STATS_LOADED';

function buildUrl(
  apiUrl,
  {
    [LEAD_FILTERS.LAST_ACTIVITY_DATE_RANGE]: { startDate, endDate } = {},
    [LEAD_FILTERS.STATUS]: status,
    [LEAD_FILTERS.LEAD_SUGGESTION]: { type, text } = {},
    [LEAD_FILTERS.LEADS_PROPERTY]: { id } = {},
    [LEAD_FILTERS.LEADS_PROJECT]: { id: projectId } = {},
  }
) {
  const endpointUrl = `${apiUrl}/leads/count-by-status`;
  const toString = (date) =>
    fromUTCDate(typeof date === 'string' ? new Date(date) : date).toISOString();

  const query = queryString.stringify(
    {
      from: startDate ? toString(startDate) : null,
      to: endDate ? toString(endDate) : null,
      status: status !== 'all-enquiries' ? status : null,
      [type]: text,
      adId: id,
      projectId,
    },
    { skipNull: true }
  );

  if (query) return `${endpointUrl}?${query}`;

  return endpointUrl;
}

registerEventHandler(
  LOAD_LEADS_STATS,
  ({ environment: { apiUrl } }, { filters = {} } = {}) => ({
    ...http.get({
      url: buildUrl(apiUrl, filters),
      successEvent: LEADS_STATS_LOADED,
    }),
    ...state.set({
      leadsStatsLoading: true,
    }),
  }),
  [environment()]
);

registerEventHandler(LEADS_STATS_LOADED, (_, [{ data }]) =>
  state.set({
    leadsStats: data,
    leadsStatsLoading: false,
  })
);
