import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import PricingPlansTable from 'design-system/components/PricingPlansTable/PricingPlansTable';
import {
  OutlineButton,
  PrimaryButton,
} from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Heading2 } from 'design-system/components/Typography/presets/Heading2';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import { activeSubscriptionBenefitsSelector } from './selectors';
import { isPublisherFromSEASelector } from '../../../selectors/publisher';
import {
  siteWherePublishingNamesSelector,
  supportEmailSelector,
} from '../../../selectors/config';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  createLATAMDeveloperBenefits,
  createSEADeveloperBenefits,
} from '../partials/benefits';
import { activeSubscriptionHeaderSelector } from '../partials/SubscriptionTable/selectors';

const BenefitsTable = styled(PricingPlansTable)`
  ${PricingPlansTable.mods.wide()}
`;

export default function DeveloperBenefitsTable({ hasPromotedProjects }) {
  const benefits = useSelector(activeSubscriptionBenefitsSelector);
  const isFromSEA = useSelector(isPublisherFromSEASelector);
  const siteNames = useSelector(siteWherePublishingNamesSelector);

  return (
    <BenefitsTable
      title={
        <FormattedMessageWithValidation id="subscription_benefits_table_header" />
      }
      plans={[<HeadingCell hasPromotedProjects={hasPromotedProjects} />]}
      benefits={
        isFromSEA
          ? createSEADeveloperBenefits([benefits], siteNames)
          : createLATAMDeveloperBenefits([benefits], siteNames)
      }
    />
  );
}

const Wrapper = styled.td`
  border: 1px solid #e6e6e6;

  ${spacing.inset(SPACING_SIZE.L)}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${Heading2} {
    ${spacing.stack(SPACING_SIZE.M)}
  }
`;

const CallToActionContainer = styled.div`
  ${spacing.stack(SPACING_SIZE.S, false)}
`;

function HeadingCell({ hasPromotedProjects }) {
  const supportEmail = useSelector(supportEmailSelector);
  const subscription = useSelector(activeSubscriptionHeaderSelector);
  const { nameTag } = subscription;

  return (
    <Wrapper>
      <Content>
        <Heading2
          $color={COLOR_PALETTE.SECONDARY_BASE}
          $weight={FONT_WEIGHT.MEDIUM}
        >
          <FormattedMessageWithValidation id={nameTag} />
        </Heading2>
        <CallToActionContainer>
          {hasPromotedProjects === false && (
            <PrimaryButton
              forwardedAs="a"
              href={`mailto:${supportEmail}`}
              $size={BUTTON_SIZE.LARGE}
            >
              <FormattedMessageWithValidation
                id="subscription_benefits_upsell_contact"
                values={{ supportEmail }}
              />
            </PrimaryButton>
          )}
          {hasPromotedProjects && (
            <OutlineButton disabled $size={BUTTON_SIZE.LARGE}>
              <FormattedMessageWithValidation id="agencyprofile_subscription_header" />
            </OutlineButton>
          )}
        </CallToActionContainer>
      </Content>
    </Wrapper>
  );
}
