import { DropdownFilter } from '../../../../../../partials/DataTable';
import { paymentMethodFilterOptions } from '../../../../../utils/paymentMethodOptions';

const OPTIONS = paymentMethodFilterOptions();

export default function PaymentMethodFilter() {
  return (
    <DropdownFilter
      label="Payment method"
      source="paymentMethod"
      options={OPTIONS}
    />
  );
}
