import styled from 'styled-components';
import { COLOR_PALETTE } from '../../../styles/color';
import Typography, { TYPOGRAPHY_VARIANT } from '../Typography';

import BasePresetTypography from './BasePresetTypography';

export const Body1 = styled(BasePresetTypography)`
  ${Typography.mods.variant(TYPOGRAPHY_VARIANT.BODY_1)}

  ${({ $weight }) => $weight && Typography.mods.weight($weight)}
`;

Body1.defaultProps = {
  $color: COLOR_PALETTE.NEUTRAL_BASE,
};
