import styled from 'styled-components';
import BreakdownTable from 'design-system/components/BreakdownTable/BreakdownTable';
import Dialog from 'design-system/components/Dialog/Dialog';
import { SecondaryButton } from 'design-system/components/Button/presets';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import { DEVELOPER_PROMOTED_PROJECT_DETAILS_DIALOG } from './constants';
import { useDialog } from '../../../../hooks/useDialog';
import Price from '../../../../partials/Price/Price';
import CustomFormattedDate from '../../../../partials/FormattedDate/CustomFormattedDate';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const CampaignInformation = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: ${SPACING_SIZE.L}px;

  ${spacing.stack(SPACING_SIZE.L)}
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  ${spacing.stack(SPACING_SIZE.L)}

  ${Subtitle3} {
    ${spacing.stack(SPACING_SIZE.XS)}
  }
`;

export default function PromotedProjectDetailsDialog() {
  const { open, closeDialog, parameters } = useDialog(
    DEVELOPER_PROMOTED_PROJECT_DETAILS_DIALOG
  );

  return (
    <Dialog open={open} onClose={() => closeDialog()}>
      <Dialog.Header>{parameters?.title}</Dialog.Header>
      <Dialog.Content>
        <CampaignInformation>
          <Group>
            <Subtitle3>
              <FormattedMessageWithValidation id="subscription_summary_contract_type" />
            </Subtitle3>
            <Body1 $weight={FONT_WEIGHT.LIGHT}>
              <FormattedMessageWithValidation
                id="subscription_summary_contract_type_value"
                values={{
                  duration: parameters?.billingCycleInMonths,
                }}
              />
            </Body1>
          </Group>
          <Group>
            <Subtitle3>
              <FormattedMessageWithValidation id="subscription_summary_next_billing_date" />
            </Subtitle3>
            <Body1 $weight={FONT_WEIGHT.LIGHT}>
              <CustomFormattedDate value={parameters?.billingDate} />
            </Body1>
          </Group>
        </CampaignInformation>
      </Dialog.Content>
      <Dialog.Content>
        <BreakdownTable>
          <BreakdownTable.Row
            title={parameters?.title}
            price={
              <Price
                amount={parameters?.price?.amount}
                currency={parameters?.price?.currency}
              />
            }
          />
          {parameters?.tax > 0 && (
            <BreakdownTable.Row
              title={`${parameters.tax.name} ${parameters.tax.percentage}%`}
              price={
                <Price
                  amount={parameters.tax.amount}
                  currency={parameters.tax.currency}
                />
              }
            />
          )}
          <BreakdownTable.TotalRow
            title="Total"
            price={
              <Price
                amount={parameters?.totalPrice?.amount}
                currency={parameters?.totalPrice?.currency}
              />
            }
          />
        </BreakdownTable>
      </Dialog.Content>
      <Dialog.Footer>
        <SecondaryButton onClick={() => closeDialog()}>
          Continue
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}
