import { isObject } from 'lodash';

export function mapTableFiltersToApiFilters(
  tableFilters,
  apiFilterMappers = {}
) {
  return Object.entries(tableFilters).reduce(
    (acc, [filterName, filterValue]) => {
      const mapper = apiFilterMappers[filterName];

      if (mapper) {
        return {
          ...acc,
          ...mapper(filterValue),
        };
      }

      return {
        ...acc,
        [filterName]: isObject(filterValue)
          ? JSON.stringify(filterValue)
          : filterValue,
      };
    },
    {}
  );
}
