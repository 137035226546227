import { COLOR_PALETTE } from 'design-system/styles/color';
import BaseBubble, { BASE_BUBBLE_ALIGNMENT } from './BaseBubble';

function OutboundBubble({ message, title, ...rest }) {
  return (
    <BaseBubble
      {...rest}
      $alignment={BASE_BUBBLE_ALIGNMENT.RIGHT}
      $colorScheme={{
        title: COLOR_PALETTE.BLUEBERRY_BASE,
        background: COLOR_PALETTE.BLUEBERRY_A10,
      }}
    />
  );
}

export default OutboundBubble;
