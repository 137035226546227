import { registerEffectHandler } from 'reffects';

export const DOWNLOAD_CSV = 'downloadCsv';
export const DOWNLOAD_XLS = 'downloadXls';

export default function registerDownloadFileEffect(
  parser,
  downloader,
  effectName = DOWNLOAD_CSV
) {
  registerEffectHandler(effectName, ({ filename, content }) => {
    if (content.length === 0) {
      return;
    }
    const parserContent = parser(content);
    downloader(parserContent, filename);
  });
}

export function downloadCsv({ filename, content }) {
  return {
    [DOWNLOAD_CSV]: { filename, content },
  };
}

export function downloadXls({ filename, content }) {
  return {
    [DOWNLOAD_XLS]: { filename, content },
  };
}
