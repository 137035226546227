import * as React from "react";
const SvgProperatiLogoColored = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 96 24"
    {...props}
  >
    <path
      fill="#EE4252"
      fillRule="evenodd"
      d="M1.51 6.584 8.3 3.194a1.82 1.82 0 0 1 1.63 0l6.789 3.39a2.73 2.73 0 0 1 1.51 2.444V19.18A1.82 1.82 0 0 1 16.406 21H1.823A1.82 1.82 0 0 1 0 19.18V9.027a2.73 2.73 0 0 1 1.51-2.444"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M9.406 8.468c1.87 0 3.694.798 3.694 3.201 0 2.382-1.823 3.179-3.694 3.202H7.929v2.709H5.466V8.466h3.94zm-.37 2.094H7.93v2.463h1.108c.69 0 1.353-.307 1.353-1.232.002-.91-.642-1.222-1.353-1.23"
      clipRule="evenodd"
    />
    <path
      fill="#313037"
      fillRule="evenodd"
      d="M43.86 9.607a4.556 4.556 0 0 1 4.557 4.558 4.556 4.556 0 0 1-4.557 4.557 4.556 4.556 0 0 1-4.557-4.558 4.56 4.56 0 0 1 4.557-4.557m-17.203.342c1.732 0 3.418.739 3.418 2.962 0 2.201-1.686 2.94-3.418 2.962H25.29v2.507h-2.507V9.95zm27.115 0c1.73 0 3.418.739 3.418 2.962 0 2.201-1.689 2.94-3.418 2.962H52.29v2.507h-2.507V9.95zm11.05 0v1.937h-4.215v1.481h3.76v1.595h-3.76v1.481h4.216v1.937H58.1V9.95zm27.001 0v2.165h-2.506v6.266h-2.62v-6.266H84.19V9.949zm3.874 0v8.43h-2.506V9.95zm-14.81-.228 3.417 8.659h-2.506l-.57-1.481h-3.19l-.57 1.48h-2.506l3.418-8.658zm-45.913.228c1.725 0 3.417.739 3.417 2.962 0 1.32-.612 2.117-1.48 2.507l1.48 2.962h-2.506l-1.253-2.507h-1.367v2.507h-2.279V9.95zm35.659 0c1.725 0 3.418.739 3.418 2.962 0 1.32-.613 2.117-1.481 2.507l1.48 2.962h-2.506l-1.253-2.507h-1.367v2.507h-2.279V9.95zm-26.716 1.937a2.3 2.3 0 0 0-2.333 2.333c0 1.203 1.02 2.224 2.333 2.224 1.203 0 2.224-1.02 2.224-2.224-.002-1.312-1.021-2.333-2.224-2.333m35.778 1.025-.973 2.279h1.823zm-54.291-1.025v2.279h1.025c.64 0 1.253-.285 1.253-1.14 0-.843-.594-1.13-1.253-1.139zm26.887 0v2.279h1.025c.64 0 1.253-.285 1.253-1.14 0-.843-.595-1.13-1.253-1.139zm-19.026 0v2.165h1.367c.633 0 1.253-.271 1.253-1.026 0-.857-.602-1.13-1.253-1.139zm35.659 0v2.165h1.367c.634 0 1.253-.271 1.253-1.026 0-.857-.601-1.13-1.253-1.139z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgProperatiLogoColored;
