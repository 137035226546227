import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'reffects-store';
import { PrivateRoute, PublicRoute } from '.';
import {
  CHANGE_PASSWORD,
  CHECKOUT,
  ENQUIRIES,
  ENQUIRY_DETAIL,
  FORGOT_PASSWORD,
  IMPORT_STATS,
  LEAD_DETAIL,
  LOGIN,
  NEW_PROPERTY,
  NEW_UNIT,
  OVERVIEW,
  PROPERTIES,
  PUBLISHERS,
  RESET_PASSWORD,
  SELF_SERVICE_CREATE,
  SELF_SERVICE_ERROR,
  SUBSCRIPTION,
  SUBSCRIPTION_ADD_CARD,
  SUBSCRIPTION_ADD_CARD_FAILED,
  SUBSCRIPTION_ADD_CARD_SUCCESS,
  SUBSCRIPTION_BANK_TRANSFER_DETAILS,
  SUBSCRIPTION_BENEFITS,
  SUBSCRIPTION_BILLING,
  SUBSCRIPTION_OFFERS,
  UNITS,
  UPLOAD_CSV,
  UPLOAD_CSV_FINAL,
} from '../../constants/routes';
import {
  cookiesPolicyRoute,
  privacyPolicyRoute,
  termsAndConditionsRoute,
} from '../../utils/proppitWebRouter';
import {
  BankTransferDetails,
  Benefits,
  ChangePassword,
  CookiesPolicy,
  CreateAdForm,
  CreateUnitForm,
  DeveloperBenefits,
  DeveloperOverview,
  DeveloperSubscription,
  EditAdForm,
  EditUnitForm,
  EnquiryDetail,
  ForgotPassword,
  LeadDetail,
  Leads,
  Login,
  NotFound,
  Overview,
  PrivacyPolicy,
  ProjectDetail,
  Projects,
  Properties,
  PropertyLinks,
  PublisherProfile,
  Subscription,
  TermsAndConditions,
  Units,
} from '../../pages';
import { showMaintenanceSelector } from '../selectors';
import { isDeveloperSelector } from '../../selectors/publisher';
import ImportStats from '../../pages/ImportStats/ImportStats';
import { Maintenance } from '../../pages/Maintenance';
import CheckoutRoutes from './CheckoutRoutes';
import { ManagedPublishers } from '../../pages/ManagedPublishers';
import CreateNewPassword from '../../pages/Authentication/CreateNewPassword';
import { Unsubscribe } from '../../pages/Unsubscribe';
import { MobileSignUpActivation } from '../../pages/Mobile/SignupActivation';
import BackofficeRoutes from '../../Backoffice/router/Routes';
import { BACKOFFICE_ROOT } from '../../Backoffice/constants/routes';
import firstPaymentCheckoutConfigurationProvider from '../../pages/Checkout/configuration/firstPaymentCheckoutConfigurationProvider';
import upsellCheckoutConfigurationProvider from '../../pages/Checkout/configuration/upsellCheckoutConfigurationProvider';
import ChurnPoll from '../../pages/ChurnPoll/ChurnPoll';
import ChurnPollThanks from '../../pages/ChurnPoll/ChurnPollThanks';
import UploadCSV from '../../Backoffice/pages/UploadCSV/UploadCSV';
import UploadCSVFinalStage from '../../Backoffice/pages/UploadCSV/UploadCSVFinalStage';
import LeadAwarenessThanks from '../../pages/LeadAwareness/LeadAwarenessThanks';
import CreditCardDetails from '../../pages/Subscription/CreditCardDetails';
import CreditCardAdded from '../../pages/Subscription/CreditCardAdded';
import CreditCardFailed from '../../pages/Subscription/CreditCardFailed';
import SelfServiceCreate from '../../pages/SelfService/SelfServiceCreate';
import SelfServiceError from '../../pages/SelfService/SelfServiceError';
import OffersPage from '../../pages/Offers/OffersPage';

const firstPaymentCheckoutConfiguration =
  firstPaymentCheckoutConfigurationProvider();
const upsellCheckoutConfiguration = upsellCheckoutConfigurationProvider();

function AppRoutes() {
  const showMaintenance = useSelector(showMaintenanceSelector);
  const isDeveloper = useSelector(isDeveloperSelector);
  if (showMaintenance) {
    return <Maintenance />;
  }

  return (
    <Routes>
      {/* PRIVATE ROUTES */}
      <Route path="*" element={<PrivateRoute as={NotFound} />} />
      <Route
        path={OVERVIEW}
        element={
          <PrivateRoute
            as={isDeveloper ? DeveloperOverview : Overview}
            pageViewName="overview"
          />
        }
      />
      <Route
        path={IMPORT_STATS}
        element={<PrivateRoute as={ImportStats} pageViewName="import-stats" />}
      />
      <Route
        path={ENQUIRIES}
        element={<PrivateRoute as={Leads} pageViewName="enquiries" />}
      />
      <Route
        path={ENQUIRY_DETAIL}
        element={
          <PrivateRoute
            as={EnquiryDetail}
            pageViewName="lead"
            pageViewPayloadSelector={({ enquiryId }) => ({ enquiryId })}
          />
        }
      />
      <Route
        path={LEAD_DETAIL}
        element={
          <PrivateRoute
            as={LeadDetail}
            pageViewName="lead"
            pageViewPayloadSelector={({ leadId }) => ({ leadId })}
          />
        }
      />
      <Route
        path={PROPERTIES}
        element={<PrivateRoute as={Properties} pageViewName="ads" />}
      />
      <Route
        path={UNITS}
        element={<PrivateRoute as={Units} pageViewName="units" />}
      />
      <Route
        path={NEW_PROPERTY}
        element={
          <PrivateRoute as={CreateAdForm} pageViewName="create-new-listing" />
        }
      />
      <Route
        path="/properties/:adId/edit"
        element={<PrivateRoute as={EditAdForm} pageViewName="edit-listing" />}
      />
      <Route
        path="/company-profile"
        element={
          <PrivateRoute
            as={PublisherProfile}
            pageViewName="company-profile-page"
          />
        }
      />
      <Route
        path={NEW_UNIT}
        element={
          <PrivateRoute as={CreateUnitForm} pageViewName="create-new-unit" />
        }
      />
      <Route
        path="/units/:adId/edit"
        element={<PrivateRoute as={EditUnitForm} pageViewName="edit-unit" />}
      />
      <Route
        path="/projects"
        element={<PrivateRoute as={Projects} pageViewName="projects" />}
      />
      <Route
        path="/project/:projectId"
        element={
          <PrivateRoute as={ProjectDetail} pageViewName="project-page" />
        }
      />
      <Route
        path={SUBSCRIPTION}
        element={
          <PrivateRoute
            as={isDeveloper ? DeveloperSubscription : Subscription}
            pageViewName="Subscription"
          />
        }
      />
      <Route
        path={SUBSCRIPTION_BILLING}
        element={
          <PrivateRoute
            as={isDeveloper ? DeveloperSubscription : Subscription}
            pageViewName="subscription-billing"
          />
        }
      />
      <Route
        path={SUBSCRIPTION_BENEFITS}
        element={
          <PrivateRoute
            as={isDeveloper ? DeveloperBenefits : Benefits}
            pageViewName="subscription-benefits"
          />
        }
      />
      <Route
        path={SUBSCRIPTION_BANK_TRANSFER_DETAILS}
        element={
          <PrivateRoute
            as={BankTransferDetails}
            pageViewName="subscription-bank-transfer-details"
          />
        }
      />
      <Route
        path={SUBSCRIPTION_ADD_CARD}
        element={
          <PrivateRoute
            as={CreditCardDetails}
            pageViewName="subscription-credit-card-details"
          />
        }
      />
      <Route
        path={SUBSCRIPTION_ADD_CARD_SUCCESS}
        element={
          <PrivateRoute
            as={CreditCardAdded}
            pageViewName="subscription-credit-card-added"
          />
        }
      />
      <Route
        path={SUBSCRIPTION_ADD_CARD_FAILED}
        element={
          <PrivateRoute
            as={CreditCardFailed}
            pageViewName="subscription-credit-card-failed"
          />
        }
      />
      <Route
        path={CHANGE_PASSWORD}
        element={<PrivateRoute as={ChangePassword} />}
      />
      <Route
        path={PUBLISHERS}
        element={<PrivateRoute as={ManagedPublishers} />}
      />
      {/* PUBLIC ROUTES */}
      <Route
        path={UPLOAD_CSV}
        element={<PrivateRoute as={UploadCSV} pageViewName="upload-csv" />}
      />
      <Route
        path={UPLOAD_CSV_FINAL}
        element={
          <PrivateRoute
            as={UploadCSVFinalStage}
            pageViewName="upload-csv-final"
          />
        }
      />
      <Route
        path={LOGIN}
        element={<PublicRoute as={Login} pageViewName="login" />}
      />
      <Route
        path={SELF_SERVICE_CREATE}
        element={
          <PublicRoute
            as={SelfServiceCreate}
            pageViewName="self-service-create"
            pageViewPayloadSelector={({ origin, cta }) => ({
              origin,
              cta,
            })}
          />
        }
      />
      <Route
        path={SELF_SERVICE_ERROR}
        element={
          <PublicRoute
            as={SelfServiceError}
            pageViewName="self-service-error"
            pageViewPayloadSelector={({ origin, cta }) => ({
              origin,
              cta,
            })}
          />
        }
      />
      <Route
        path={SUBSCRIPTION_OFFERS}
        element={<PublicRoute as={OffersPage} />}
      />
      <Route
        path={FORGOT_PASSWORD}
        element={<PublicRoute as={ForgotPassword} />}
      />
      <Route
        path={RESET_PASSWORD}
        element={<PublicRoute as={CreateNewPassword} disableInterstitial />}
      />
      <Route
        path={privacyPolicyRoute()}
        element={<PublicRoute as={PrivacyPolicy} />}
      />
      <Route
        path={termsAndConditionsRoute()}
        element={<PublicRoute as={TermsAndConditions} />}
      />
      <Route
        path={cookiesPolicyRoute()}
        element={<PublicRoute as={CookiesPolicy} />}
      />
      <Route
        path="/property-links/:adId"
        element={
          <PublicRoute
            as={PropertyLinks}
            pageViewName="property-links"
            pageViewPayloadSelector={({ adId }) => ({ adId })}
          />
        }
      />
      <Route path="/unsubscribe" element={<PublicRoute as={Unsubscribe} />} />
      <Route
        path="/user-activation/:userId"
        element={
          <PublicRoute as={MobileSignUpActivation} disableInterstitial />
        }
      />
      <Route
        path={`${CHECKOUT}/${firstPaymentCheckoutConfiguration.subRoute}/:subscriptionChangeRequestId/*`}
        element={
          <CheckoutRoutes
            path={`${CHECKOUT}/${firstPaymentCheckoutConfiguration.subRoute}/:subscriptionChangeRequestId/*`}
            config={firstPaymentCheckoutConfiguration}
          />
        }
      />
      <Route
        path={`${CHECKOUT}/${upsellCheckoutConfiguration.subRoute}/:subscriptionChangeRequestId/*`}
        element={
          <CheckoutRoutes
            path={`${CHECKOUT}/${upsellCheckoutConfiguration.subRoute}/:subscriptionChangeRequestId/*`}
            config={upsellCheckoutConfiguration}
          />
        }
      />
      <Route
        path="/churn-poll"
        element={<PublicRoute as={ChurnPoll} disableInterstitial />}
      />
      <Route
        path="/churn-poll/thanks"
        element={<PublicRoute as={ChurnPollThanks} disableInterstitial />}
      />
      <Route
        path="/lead-awareness/thanks"
        element={<PublicRoute as={LeadAwarenessThanks} disableInterstitial />}
      />
      <Route
        path={`${BACKOFFICE_ROOT}/*`}
        element={<BackofficeRoutes path={`${BACKOFFICE_ROOT}/*`} />}
      />
    </Routes>
  );
}

export default AppRoutes;
