import { CheckoutConfigurationBuilder } from './checkoutConfigurationBuilder';
import { FIRST_PAYMENT_SUBROUTE, TRANSLATION_NAMES } from './constants';

export default function firstPaymentCheckoutConfigurationProvider() {
  return CheckoutConfigurationBuilder.create()
    .withSubRoute(FIRST_PAYMENT_SUBROUTE)
    .withPageViewNamePrefix('first')
    .withTextTranslation(
      TRANSLATION_NAMES.BANK_TRANSFER_NOTICE,
      'checkout_bank_transfer_notice'
    )
    .withTextTranslation(
      TRANSLATION_NAMES.CONFIRMATION_CAPTION,
      'checkout_confirmation_caption'
    )
    .withTextTranslation(
      TRANSLATION_NAMES.CONFIRMATION_CAPTION_SELF_SERVICE,
      'checkout_confirmation_caption_1_self_service'
    )
    .withTextTranslation(
      TRANSLATION_NAMES.PAY_BY_LINK_CONFIRMATION,
      'checkout_paybylink_confirmation_body1'
    )
    .withTextTranslation(
      TRANSLATION_NAMES.CONFIRMATION_TITLE,
      'checkout_confirmation_title'
    )
    .withTextTranslation(
      TRANSLATION_NAMES.CONFIRMATION_TITLE_SELF_SERVICE,
      'checkout_confirmation_title_self_service'
    )
    .withTextTranslation(
      TRANSLATION_NAMES.PAGE_TITLE,
      'first_payment_checkout_page_title'
    )
    .build();
}
