import { Body1 } from 'design-system/components/Typography/presets/Body1';
import Table from 'design-system/components/Table';
import { useColumn } from '../../hooks/useColumn';
import FormattedDate from '../../../FormattedDate/FormattedDate';

export function DateRangeColumn(props) {
  const {
    value: { from, to },
  } = useColumn(props);

  return (
    <Table.Cell>
      <Body1 $noWrap>
        <FormattedDate
          value={new Date(from)}
          year="numeric"
          month="short"
          day="2-digit"
        />
        {' - '}
        <FormattedDate
          value={new Date(to)}
          year="numeric"
          month="short"
          day="2-digit"
        />
      </Body1>
    </Table.Cell>
  );
}
