import styled from 'styled-components';

import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { StatusFilter } from './StatusFilter';
import { TypeFilter } from './TypeFilter';
import { OperationFilter } from './OperationFilter';
import { SuggestionsFilter } from './SuggestionsFilter';
import ClearFiltersButton from '../../../partials/DataTable/components/filters/ClearFiltersButton';
import { PriceRangeFilter } from './PriceRangeFilter';

const Wrapper = styled.div`
  display: flex;

  & > * {
    ${spacing.inline(SPACING_SIZE.S)}
  }

  ${spacing.stack(SPACING_SIZE.L)}
`;

export function Filters() {
  return (
    <Wrapper>
      <SuggestionsFilter />
      <StatusFilter />
      <TypeFilter />
      <OperationFilter />
      <PriceRangeFilter />
      <ClearFiltersButton />
    </Wrapper>
  );
}
