import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { FlatButton } from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';
import {
  BUTTON_ICON_POSITION,
  BUTTON_SIZE,
} from 'design-system/components/Button';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_SEMANTIC } from 'design-system/styles/color';
import FormattedMessageWithValidation from '../../../FormattedMessageWithValidation/FormattedMessageWithValidation';
import { useFilters } from '../../hooks/useFilter';

const Divider = styled.span`
  height: inherit;
  width: 1px;
  margin-left: ${spacing.value(SPACING_SIZE.S)};
  ${color.background(COLOR_SEMANTIC.DIVIDER)}
`;

function ClearFiltersButton({ text }) {
  const { filters, clearFilters } = useFilters();

  if (isEmpty(filters)) return null;

  return (
    <>
      <Divider />
      <FlatButton
        icon={<Icon glyph="cross" />}
        $size={BUTTON_SIZE.BASE}
        $iconPosition={BUTTON_ICON_POSITION.START}
        onClick={clearFilters}
      >
        {text ?? <FormattedMessageWithValidation id="clear_filters_button" />}
      </FlatButton>
    </>
  );
}

export default ClearFiltersButton;
