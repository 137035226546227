import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import { useMount } from 'react-use';
import { SPACING_SIZE } from 'design-system/styles/spacing';
import PaymentIframe from './PaymentIframe';
import {
  BACK_TO_BILLING_INFO,
  CHECKOUT_ERROR_PAGE,
  PAYMENT_GATEWAY_FORM_MOUNTED,
  PAYMENT_GATEWAY_FORM_OPERATION_SUCCEED,
} from './events';
import { paymentGatewayIframeSrcSelector } from './selectors';

const Wrapper = styled.div`
  margin-left: ${SPACING_SIZE.XL}px;
`;

function CreditCard({ subscriptionChangeRequestId }) {
  useMount(() => {
    dispatch({
      id: PAYMENT_GATEWAY_FORM_MOUNTED,
      payload: { subscriptionChangeRequestId },
    });
  });

  const src = useSelector(paymentGatewayIframeSrcSelector);
  return (
    <Wrapper>
      <PaymentIframe
        src={src}
        onSuccessEvent={{
          id: PAYMENT_GATEWAY_FORM_OPERATION_SUCCEED,
          payload: {
            subscriptionChangeRequestId,
            paymentMethod: 'credit-card',
          },
        }}
        onBackEvent={{
          id: BACK_TO_BILLING_INFO,
          payload: { subscriptionChangeRequestId },
        }}
        onCheckoutFailed={{
          id: CHECKOUT_ERROR_PAGE,
          payload: { subscriptionChangeRequestId },
        }}
      />
    </Wrapper>
  );
}

export default CreditCard;
