import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { useSelector } from 'reffects-store';
import { FormattedNumber } from 'react-intl';
import Grid from 'design-system/components/Grid';
import {
  isProjectsSummaryLoaded,
  totalProjectsSelector,
  totalUnitsSelector,
} from '../../selectors';
import Kpi from '../../../../partials/Kpi';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { projectsRoute, unitsRoute } from '../../../../utils/proppitWebRouter';
import { UpgradeFreeSubscriptionCard } from '../UpgradeFreeSubscriptionCard';

const Wrapper = styled.div`
  ${spacing.stack(SPACING_SIZE.L)}
`;

export function ProjectsStats() {
  const projectsStatsLoaded = useSelector(isProjectsSummaryLoaded);

  const totalProjects = useSelector(totalProjectsSelector);
  const totalUnits = useSelector(totalUnitsSelector);

  if (!projectsStatsLoaded) return null;

  return (
    <Wrapper>
      <Grid
        areas={({ repeat }) => `
        "${repeat(3, 'totalProjects')} ${repeat(3, 'totalUnits')} ${repeat(
          6,
          'upgradeSubscription'
        )}"
      `}
      >
        <Grid.Area $id="totalProjects">
          <Kpi
            amount={<FormattedNumber value={totalProjects} />}
            message={
              <FormattedMessageWithValidation id="overview_developer_projects_summary_total_projects" />
            }
            linkTo={totalProjects > 0 && projectsRoute()}
            track={{
              trackClick: {
                eventName: 'click-project-list-link',
              },
              trackImpression: {
                elementName: 'project-list-link',
              },
              sectionName: 'overview-project-stats',
            }}
          />
        </Grid.Area>
        <Grid.Area $id="totalUnits">
          <Kpi
            amount={<FormattedNumber value={totalUnits} />}
            message={
              <FormattedMessageWithValidation id="overview_developer_projects_summary_total_units" />
            }
            linkTo={totalUnits > 0 && unitsRoute()}
            track={{
              trackClick: {
                eventName: 'click-unit-list-link',
              },
              trackImpression: {
                elementName: 'unit-list-link',
              },
              sectionName: 'overview-project-stats',
            }}
          />
        </Grid.Area>
        <Grid.Area $id="upgradeSubscription">
          <UpgradeFreeSubscriptionCard />
        </Grid.Area>
      </Grid>
    </Wrapper>
  );
}
