import { createSelector } from 'reselect';
import { difference, intersection } from 'lodash';
import { collectionSelector, visibleItemsIdListSelector } from './collection';
import { itemIdParameter } from './item';

export const selectedItemIdsSelector = createSelector(
  collectionSelector,
  (collection) => collection?.selection ?? []
);

export const isItemSelectedSelector = createSelector(
  selectedItemIdsSelector,
  itemIdParameter,
  (selectedItemIds, itemId) => selectedItemIds.includes(itemId)
);

export const visibleSelectedItemIdsSelector = createSelector(
  visibleItemsIdListSelector,
  selectedItemIdsSelector,
  (visibleItemIds, selectedItemIds) =>
    intersection(visibleItemIds, selectedItemIds)
);

export const areSomeVisibleItemsSelectedSelector = createSelector(
  visibleSelectedItemIdsSelector,
  (visibleSelectedItemIds) => Boolean(visibleSelectedItemIds.length)
);

export const areAllVisibleItemsSelectedSelector = createSelector(
  visibleItemsIdListSelector,
  selectedItemIdsSelector,
  (visibleItemIds, selectedItemIds) => {
    if (!visibleItemIds.length) return false;

    const differences = difference(visibleItemIds, selectedItemIds);

    return !differences.length;
  }
);

export const visibleSelectedItemsCountSelector = createSelector(
  visibleSelectedItemIdsSelector,
  (visibleSelectedItemIds) => visibleSelectedItemIds.length
);
