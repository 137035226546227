import { createSelector } from 'reselect';
import { adDataSelector, projectSelector } from '../../selectors';
import { PROPERTY_LOCATION_VISIBILITY } from '../../../../constants/ad';

export function propertyCoordinatesSelector(state) {
  return adDataSelector(state)?.coordinates;
}

export const locationVisibilitySelector = createSelector(
  adDataSelector,
  (adData) => adData?.locationVisibility
);

export const addressSelector = createSelector(
  adDataSelector,
  (adData) => adData?.address
);

export const displayableAddressSelector = (state) =>
  state['adForm:displayableAddress'] ?? '';

export function geoLevelsSelector(state) {
  return adDataSelector(state)?.geoLevels;
}

export function geoLevelsListSelector(state) {
  const geoLevels = geoLevelsSelector(state)?.filter((geoLevel) =>
    geoLevel.level.startsWith('aws_')
  );

  const filteredGeoLevels =
    geoLevels?.length > 0 ? geoLevels : geoLevelsSelector(state);

  if (filteredGeoLevels == null) return null;

  if (filteredGeoLevels.some((geoLevel) => geoLevel.level.startsWith('aws_'))) {
    return filteredGeoLevels.map(({ name }) => name).join(', ') || null;
  }

  return (
    filteredGeoLevels
      .map(({ name }) => name)
      .reverse()
      .join(', ') || null
  );
}

export function addressSuggestionsSelector(state) {
  return state['adForm:addressSuggestions'] ?? [];
}

export function addressSuggestionsLoadingSelector(state) {
  return state['adForm:addressSuggestionLoading'] ?? false;
}

export const displayableProjectNameSelector = (state) =>
  state['adForm:displayableProjectName'] ?? '';

export function projectNameSuggestionsSelector(state) {
  return state['adForm:projectNameSuggestions'] ?? [];
}

export function projectNameSuggestionLoadingSelector(state) {
  return state['adForm:projectNameSuggestionLoading'] ?? false;
}

export function shouldBeMapMarkerDraggableSelector(state) {
  return (
    locationVisibilitySelector(state) ===
      PROPERTY_LOCATION_VISIBILITY.ACCURATE && projectSelector(state) == null
  );
}
