import styled from 'styled-components';
import { dispatch } from 'reffects';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'reffects-store';
import Anchor from 'design-system/components/Anchor';
import InputText from 'design-system/components/InputText';
import { PrimaryButton } from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Typography, {
  TYPOGRAPHY_VARIANT,
} from 'design-system/components/Typography/Typography';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

import { LOGIN } from '../../../constants/routes';
import { PASSWORD_RECOVERY_REQUESTED } from '../events';
import { EMAIL_VALIDATION_REGEX } from '../../../constants';
import { userFromLamudiSelector } from '../../../selectors/publisher';

const Wrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${InputText},
  ${Heading1} {
    ${spacing.stack(SPACING_SIZE.M)}
  }
`;

const Message = styled(Typography)`
  align-self: flex-start;

  ${Typography.mods.variant(TYPOGRAPHY_VARIANT.BODY_1)}
  ${typography.weight(FONT_WEIGHT.LIGHT)}
  ${color.text(COLOR_PALETTE.NEUTRAL_A80)}

  ${spacing.stack(SPACING_SIZE.M)}
`;

const Inputs = styled.div`
  width: 100%;
  ${spacing.stack(SPACING_SIZE.M)}
`;

const Actions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${spacing.insetSandwich(SPACING_SIZE.M)}
`;

const schema = yup
  .object({
    email: yup
      .string()
      .email(
        <FormattedMessageWithValidation id="forgotpwdpage_error_email_invalid" />
      )
      .matches(
        EMAIL_VALIDATION_REGEX,
        <FormattedMessageWithValidation id="forgotpwdpage_error_email_invalid" />
      )
      .required(
        <FormattedMessageWithValidation id="forgotpwdpage_error_email_required" />
      ),
  })
  .required();

function ForgotPasswordForm({ ...rest }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });
  const userFromLamudi = useSelector(userFromLamudiSelector);

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)} {...rest}>
      <Heading1>
        <FormattedMessageWithValidation id="forgotpwdpage_header" />
      </Heading1>
      <Message>
        <FormattedMessageWithValidation id="forgotpwdpage_body" />
      </Message>
      <Inputs>
        <InputText
          label={
            <FormattedMessageWithValidation id="forgotpwdpage_email_emptystate" />
          }
          error={Boolean(errors.email)}
          tip={errors.email?.message}
          {...register('email')}
        />
      </Inputs>
      <Actions>
        <Anchor
          as={Link}
          to={`${LOGIN}${userFromLamudi ? '?user-from-lamudi=true' : ''}`}
        >
          ← <FormattedMessageWithValidation id="forgotpwdpage_back" />
        </Anchor>
        <PrimaryButton $size={BUTTON_SIZE.LARGE} type="submit">
          <FormattedMessageWithValidation id="forgotpwdpage_reset_cta" />
        </PrimaryButton>
      </Actions>
    </Wrapper>
  );
}

export default ForgotPasswordForm;

function onSubmit({ email }) {
  dispatch({ id: PASSWORD_RECOVERY_REQUESTED, payload: email });
}
