import styled from 'styled-components';
import DropdownMenu from 'design-system/components/DropdownMenu';
import { DROPDOWN_POSITION } from 'design-system/components/Dropdown';
import Menu from 'design-system/components/Menu/Menu';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import Button, {
  BUTTON_SIZE,
  BUTTON_VARIANT,
} from 'design-system/components/Button';
import { border } from 'design-system/styles/border';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import { FONT_SIZE, typography } from 'design-system/styles/typography';
import { ROWS_PER_PAGE } from './constants';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > * {
    ${spacing.inline(SPACING_SIZE.M)}
  }
`;

const PreviousButton = styled(Button).attrs({
  icon: <Icon glyph="chevronLeft" />,
})`
  ${Button.mods.variant(BUTTON_VARIANT.FLAT)}
  ${Button.mods.size(BUTTON_SIZE.SMALL)}
`;

const NextButton = styled(Button).attrs({
  icon: <Icon glyph="chevronRight" />,
})`
  ${Button.mods.variant(BUTTON_VARIANT.FLAT)}
  ${Button.mods.size(BUTTON_SIZE.SMALL)}
`;

const RowsPerPageDropdownButton = styled.button`
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: none;
  background-color: transparent;
  cursor: pointer;

  ${border.inner({ size: 1, color: COLOR_PALETTE.NEUTRAL_A20 })}
  ${spacing.inset(SPACING_SIZE.XS)}
  ${Body2} {
    min-width: 20px;
    text-align: right;
    ${spacing.inline(SPACING_SIZE.XS)}
  }
`;

const RowPerPageOption = styled(Menu.Item)`
  ${typography.size(FONT_SIZE.S)}
`;

function Pagination({
  currentPage,
  totalPages,
  currentRowRange,
  totalRows,
  rowsPerPage,
  rowsPerPageLabel,
  selectableRowsPerPage = true,
  displayRowsLabel = () => {},
  onPaginate,
  onRowsPerPageChange,
}) {
  return (
    <Wrapper>
      <Body2>{rowsPerPageLabel}</Body2>
      {selectableRowsPerPage && (
        <DropdownMenu
          button={
            <RowsPerPageDropdownButton>
              <Body2>{rowsPerPage}</Body2>
              <Icon
                glyph="chevronDown"
                $size={ICON_SIZE.SMALL}
                $color={COLOR_PALETTE.NEUTRAL_A80}
              />
            </RowsPerPageDropdownButton>
          }
          position={DROPDOWN_POSITION.TOP_RIGHT}
          onConfirm={({ value }) => onRowsPerPageChange(value)}
        >
          <Menu>
            <RowPerPageOption
              id={ROWS_PER_PAGE.FIVE}
              value={ROWS_PER_PAGE.FIVE}
              label={ROWS_PER_PAGE.FIVE}
            />
            <RowPerPageOption
              id={ROWS_PER_PAGE.TEN}
              value={ROWS_PER_PAGE.TEN}
              label={ROWS_PER_PAGE.TEN}
            />
            <RowPerPageOption
              id={ROWS_PER_PAGE.TWENTY_FIVE}
              value={ROWS_PER_PAGE.TWENTY_FIVE}
              label={ROWS_PER_PAGE.TWENTY_FIVE}
            />
            <RowPerPageOption
              id={ROWS_PER_PAGE.HUNDRED}
              value={ROWS_PER_PAGE.HUNDRED}
              label={ROWS_PER_PAGE.HUNDRED}
            />
          </Menu>
        </DropdownMenu>
      )}
      <Body2>{displayRowsLabel(currentRowRange, totalRows)}</Body2>
      <div>
        <PreviousButton
          disabled={isPreviousButtonDisabled({ currentPage })}
          onClick={() => onPaginate(Number.parseInt(currentPage, 10) - 1)}
        />
        <NextButton
          disabled={isNextButtonDisabled({ currentPage, totalPages })}
          onClick={() => onPaginate(Number.parseInt(currentPage, 10) + 1)}
        />
      </div>
    </Wrapper>
  );
}

export default styled(Pagination)``;

function isPreviousButtonDisabled({ currentPage }) {
  return currentPage === 1;
}

function isNextButtonDisabled({ currentPage, totalPages }) {
  return totalPages === 0 || currentPage === totalPages;
}
