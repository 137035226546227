import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { OutlineLightButton } from 'design-system/components/Button/presets';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Typography from 'design-system/components/Typography/Typography';
import {
  showLeadQualityAwarenessBannerSelector,
  verifiedLeadsStatsSelector,
} from './selectors';
import KpiCard from './KpiCard';
import PercentageGraph from './PercentageGraph';
import { enquiriesRoute } from '../../utils/proppitWebRouter';
import FormattedMessageWithValidation from '../FormattedMessageWithValidation/FormattedMessageWithValidation';
import useTracking from '../../hooks/useTracking';
import useClickTracking from '../../hooks/useClickTracking';
import { isDeveloperSelector } from '../../selectors/publisher';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  ${color.background(COLOR_PALETTE.PRIMARY_BASE)}
  ${spacing.inset(SPACING_SIZE.M)}
    ${radius.regular(RADIUS_SIZE.LARGE)}
    > * {
    ${spacing.inline(SPACING_SIZE.L)}
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${Typography}.heading {
    ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
  }

  > * {
    ${spacing.stack(SPACING_SIZE.M)}
  }
`;

const Kpis = styled.div`
  display: flex;
  flex-direction: row;

  > * {
    ${spacing.inline(SPACING_SIZE.M)}
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;

  ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
  > ${Typography} {
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;

    & .bold {
      font-weight: 700;
    }
  }

  ${OutlineLightButton} {
    align-self: baseline;
  }

  > * {
    ${spacing.stack(SPACING_SIZE.S)}
  }
`;

function LeadQualityAwareness({ section, showLink, ...props }) {
  const showBanner = useSelector((state) =>
    showLeadQualityAwarenessBannerSelector(state)
  );
  const isDeveloper = useSelector(isDeveloperSelector);
  const {
    leadsWithVerifiedPhone,
    leadsWithVerifiedEmail,
    leadsWithLeadPlus,
    leadsWithQualification,
    percentage,
  } = useSelector(verifiedLeadsStatsSelector);
  const ref = useTracking({
    trackImpression: {
      elementName: 'leads-quality-awareness-banner',
    },
    sectionName: section,
  });
  const { trackClick } = useClickTracking({ sectionName: section });
  if (!showBanner) {
    return null;
  }
  return (
    <Wrapper ref={ref} {...props}>
      <PercentageGraph percentage={percentage} />
      <Content>
        <Typography className="heading">
          <FormattedMessageWithValidation
            id="lead_quality_awareness_leads_heading"
            values={{ percentage }}
          />
        </Typography>
        <Kpis>
          <KpiCard
            aboveThreshold={leadsWithVerifiedPhone?.aboveThreshold}
            count={leadsWithVerifiedPhone?.count}
            glyph="check"
            text={
              <FormattedMessageWithValidation id="lead_quality_awareness_leads_with_verified_phone" />
            }
            $color={COLOR_PALETTE.PRIMARY_A120}
          />
          <KpiCard
            aboveThreshold={leadsWithVerifiedEmail?.aboveThreshold}
            count={leadsWithVerifiedEmail?.count}
            glyph="check"
            text={
              <FormattedMessageWithValidation id="lead_quality_awareness_leads_with_verified_email" />
            }
            $color={COLOR_PALETTE.PRIMARY_A120}
          />
          {isDeveloper ? (
            <KpiCard
              aboveThreshold={leadsWithQualification?.aboveThreshold}
              count={leadsWithQualification?.count}
              glyph="qualification"
              text={
                <FormattedMessageWithValidation id="lead_quality_awareness_leads_with_qualification" />
              }
              $color={COLOR_PALETTE.GOLD_A120}
            />
          ) : (
            <KpiCard
              aboveThreshold={leadsWithLeadPlus?.aboveThreshold}
              count={leadsWithLeadPlus?.count}
              glyph="leadPlus"
              text={
                <FormattedMessageWithValidation id="lead_quality_awareness_leads_with_lead_plus" />
              }
              $color={COLOR_PALETTE.GOLD_A120}
            />
          )}
          <Info>
            {leadsWithVerifiedPhone?.aboveThreshold && (
              <Typography>
                <FormattedMessageWithValidation
                  id="lead_quality_awareness_leads_with_verified_phone_description"
                  values={{
                    verifiedContact: (
                      <span className="bold">
                        <FormattedMessageWithValidation id="lead_quality_awareness_leads_with_verified_phone_description_1" />
                      </span>
                    ),
                    verifiedPhone: (
                      <span className="bold">
                        <FormattedMessageWithValidation id="lead_quality_awareness_leads_with_verified_phone_description_2" />
                      </span>
                    ),
                  }}
                />
              </Typography>
            )}
            {leadsWithVerifiedEmail?.aboveThreshold && (
              <Typography>
                <FormattedMessageWithValidation
                  id="lead_quality_awareness_leads_with_verified_email_description"
                  values={{
                    verifiedContact: (
                      <span className="bold">
                        <FormattedMessageWithValidation id="lead_quality_awareness_leads_with_verified_email_description_1" />
                      </span>
                    ),
                    verifiedEmail: (
                      <span className="bold">
                        <FormattedMessageWithValidation id="lead_quality_awareness_leads_with_verified_email_description_2" />
                      </span>
                    ),
                  }}
                />
              </Typography>
            )}
            {isDeveloper
              ? leadsWithQualification?.aboveThreshold && (
                  <Typography>
                    <FormattedMessageWithValidation
                      id="lead_quality_awareness_leads_with_qualification_description"
                      values={{
                        qualificationContact: (
                          <span className="bold">
                            <FormattedMessageWithValidation id="lead_quality_awareness_leads_with_qualification_description_1" />
                          </span>
                        ),
                      }}
                    />
                  </Typography>
                )
              : leadsWithLeadPlus?.aboveThreshold && (
                  <Typography>
                    <FormattedMessageWithValidation
                      id="lead_quality_awareness_leads_with_lead_plus_description"
                      values={{
                        leadPlusContact: (
                          <span className="bold">
                            <FormattedMessageWithValidation id="lead_quality_awareness_leads_with_lead_plus_description_1" />
                          </span>
                        ),
                      }}
                    />
                  </Typography>
                )}
            {showLink && (
              <OutlineLightButton
                forwardedAs={Link}
                to={enquiriesRoute()}
                onClick={() => trackClick({ eventName: 'click-inbox-link' })}
              >
                <FormattedMessageWithValidation id="lead_quality_awareness_show_leads" />
              </OutlineLightButton>
            )}
          </Info>
        </Kpis>
      </Content>
    </Wrapper>
  );
}

export default styled(LeadQualityAwareness)``;
