import { effects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import {
  CREATE_PUBLISHER_FORM_COUNTRY_SELECTED,
  NEW_CLIENT_CHECKBOX_CHANGED,
} from '../../CreatePublisher/events';
import { environment } from '../../../../coeffects/environment';

export const DEALS_FILTERED = 'DEALS_FILTERED';
export const DEAL_SELECTED = 'DEAL_SELECTED';
export const DEAL_REMOVED = 'DEAL_REMOVED';
export const DEAL_TEXT_INTRODUCED = 'DEAL_TEXT_INTRODUCED';
export const DEALS_REQUESTED = 'DEALS_REQUESTED';
export const DEALS_LOADED = 'DEALS_LOADED';

registerEventHandler(DEALS_FILTERED, (_, { filterText }) =>
  state.set({ 'admin:dealsFilter': filterText })
);

registerEventHandler(
  DEAL_SELECTED,
  ({ state: { salesPeople } }, { deal, stateRegion }) => ({
    ...effects.dispatchMany([
      {
        id: CREATE_PUBLISHER_FORM_COUNTRY_SELECTED,
        payload: deal.countryCode,
      },
      { id: NEW_CLIENT_CHECKBOX_CHANGED, payload: { isNewPublisher: true } },
    ]),
    ...state.set({
      [`${stateRegion}.selectedDeal`]: deal,
      [`${stateRegion}.name`]: deal.name,
      [`${stateRegion}.type`]: deal.type,
      [`${stateRegion}.loadingAdsMode`]: deal.loadingAdsMode,
      [`${stateRegion}.billingContactEmail`]: deal.billingEmail,
      [`${stateRegion}.selectedSalesPerson`]: salesPeople.find(
        ({ id }) => id === deal.userId
      ),
    }),
  }),
  [state.get({ salesPeople: 'admin:salesPeople' })]
);

registerEventHandler(DEAL_REMOVED, (_, { stateRegion }) =>
  state.set({
    [`${stateRegion}.selectedDeal`]: undefined,
    [`${stateRegion}.selectedSalesPerson`]: undefined,
    [`${stateRegion}.displayableDeal`]: '',
    [`${stateRegion}.previouslySelectedDealId`]: undefined,
  })
);

registerEventHandler(DEAL_TEXT_INTRODUCED, (_, { text, stateRegion }) => ({
  ...state.merge({
    [stateRegion]: {
      displayableDeal: text,
    },
  }),
}));

registerEventHandler(
  DEALS_REQUESTED,
  ({ environment: { apiUrl } }) =>
    http.get({ url: `${apiUrl}/admin/deals`, successEvent: DEALS_LOADED }),
  [environment()]
);

registerEventHandler(DEALS_LOADED, (_, [{ data }]) =>
  state.set({ 'admin:deals': data })
);
