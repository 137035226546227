import styled from 'styled-components';

import * as illustrations from './illustrations';

const Wrapper = styled.svg`
  display: block;
`;

function Illustration({ name, ...rest }) {
  return <Wrapper as={illustrations[name]} {...rest} />;
}

export default styled(Illustration)``;
