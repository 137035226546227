import { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import InputText from 'design-system/components/InputText';
import Icon from 'design-system/components/Icon';
import { useFilter } from '../../../../partials/DataTable/hooks/useFilter';
import { DEFAULT_AUTOCOMPLETE_DEBOUNCE } from '../../../../constants';

function TextFilter({ name, placeholder, ...props }) {
  const { value, setValue } = useFilter(name);
  const ref = useRef();

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    setValue(ref.current.value);
  }, []);

  useEffect(() => {
    ref.current.value = value ?? '';
  }, [value]);

  return (
    <form onSubmit={handleSubmit} {...props}>
      <InputText
        ref={ref}
        icon={<Icon glyph="magnifierLens" />}
        onChange={debounce(handleSubmit, DEFAULT_AUTOCOMPLETE_DEBOUNCE)}
        onIconClick={handleSubmit}
        placeholder={placeholder}
        defaultValue={value}
      />
    </form>
  );
}

export default styled(TextFilter)``;
