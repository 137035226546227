import styled from 'styled-components';
import Table from 'design-system/components/Table';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Typography from 'design-system/components/Typography/Typography';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import { useColumn } from '../../../../../partials/DataTable/hooks/useColumn';

const ProjectCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

const ProjectCell = styled.div`
  ${spacing.stack(SPACING_SIZE.XL)}
  min-width: 0;
  span {
    -webkit-line-clamp: 3;
    ${Typography.mods.weight(FONT_WEIGHT.NORMAL)};
    letter-spacing: -0.15px;
  }
`;

function ProjectFieldsColumn({
  renderProjectField,
  renderUnitProjectField,
  renderAccumulatedField,
  ...props
}) {
  const source = {
    units: 'units',
    projects: 'projects',
    accumulatedUnitsOrProjects: 'accumulatedUnitsOrProjects',
  };
  const {
    value: { units = [], projects = [], accumulatedUnitsOrProjects },
  } = useColumn({ ...props, source });

  return (
    <Table.Cell maxWidth={192}>
      <ProjectCellWrapper>
        {units.map(({ image, project }) => (
          <ProjectCell key={`${project.id}_${Object.keys(source).join('_')}`}>
            {renderUnitProjectField(image, project)}
          </ProjectCell>
        ))}
        {projects.map((project) => (
          <ProjectCell key={`${project.id}_${Object.keys(source).join('_')}`}>
            {renderProjectField(project)}
          </ProjectCell>
        ))}
        {accumulatedUnitsOrProjects && (
          <ProjectCell>
            {renderAccumulatedField(accumulatedUnitsOrProjects)}
          </ProjectCell>
        )}
      </ProjectCellWrapper>
    </Table.Cell>
  );
}

export default ProjectFieldsColumn;
