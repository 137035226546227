import { effects as reffects, effects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { toast } from '../../../effects/toast';
import { analytics } from '../../../effects/analytics';
import { OPEN_DIALOG } from '../../../events/dialogs';
import isTHPropertyTheOnlyBannedBrand from './isTHPropertyTheOnlyBannedBrand';

export const SHOW_AD_PUBLISHING_AND_BOOSTING_STATUS_NOTIFICATION =
  'SHOW_AD_PUBLISHING_AND_BOOSTING_STATUS_NOTIFICATION';
export const SHOW_AD_PUBLISHING_ERROR_NOTIFICATION =
  'SHOW_AD_PUBLISHING_ERROR_NOTIFICATION';
export const SHOW_AD_BULK_STATUS_NOTIFICATION =
  'SHOW_AD_BULK_STATUS_NOTIFICATION';

export const BOOSTING_FORBIDDEN_MODAL = 'boostingForbiddenModal';
export const BOOSTING_LIMIT_REACHED_MODAL = 'boostingLimitReachedModal';
export const PUBLISHED_BUT_BOOSTING_FORBIDDEN_MODAL =
  'publishedButBoostingForbiddenModal';
export const NOT_PUBLISHED_MODAL = 'notPublishedModal';
export const NOT_PUBLISHED_ERROR_MODAL = 'notPublishedErrorModal';
export const PUBLISHED_BUT_BOOSTING_LIMIT_REACHED_MODAL =
  'publishedButBoostingLimitReachedModal';
export const SITES_PUBLISHED_ON_MODAL = 'sitesPublishedOnModal';
export const SUPERBOOSTING_FORBIDDEN_MODAL = 'superboostingForbiddenModal';
export const SUPERBOOSTING_LIMIT_REACHED_MODAL =
  'superboostingLimitReachedModal';
export const SUPERBOOSTING_LIMIT_REACHED_FOR_BOOSTING_MODAL =
  'superboostingLimitReachedForBoostingModal';

const BULK_COMPLETE_TOAST_TAGS = {
  publish: 'propspage_bulk_toast_confirmation_publish_activated',
  unpublish: 'propspage_bulk_toast_confirmation_unpublish',
  boost: 'propspage_bulk_toast_confirmation_boosting',
  unboost: 'propspage_bulk_toast_confirmation_deactivate',
  delete: 'propspage_bulk_toast_confirmation_delete',
};

function showSuperboostingFeedback({
  publisherWithNoSuperboosting,
  section,
  adWasSuperboosted,
  publisherBoostingLimitReached,
  publisherSuperboostingLimitReached,
}) {
  if (publisherWithNoSuperboosting) {
    return openSuperboostingForbiddenModal(section);
  }
  if (
    !publisherWithNoSuperboosting &&
    !adWasSuperboosted &&
    publisherSuperboostingLimitReached
  ) {
    return openSuperboostingLimitReachedModal(section);
  }
  if (
    !publisherWithNoSuperboosting &&
    !adWasSuperboosted &&
    publisherBoostingLimitReached
  ) {
    return openSuperboostingLimitReachedForBoostingModal(section);
  }
  if (!publisherSuperboostingLimitReached && !publisherWithNoSuperboosting) {
    return openSuperboostedToast();
  }
  return {};
}

function showBoostingFeedback({
  publisherSubscribedToFreePlan,
  section,
  adWasBoosted,
  publisherBoostingLimitReached,
  countryCode,
}) {
  if (publisherSubscribedToFreePlan && countryCode !== 'MX') {
    return openBoostingForbiddenModal(section);
  }
  if (
    !publisherSubscribedToFreePlan &&
    !adWasBoosted &&
    publisherBoostingLimitReached &&
    countryCode !== 'MX'
  ) {
    return openBoostingLimitReachedModal(section);
  }
  if (!publisherBoostingLimitReached && !publisherSubscribedToFreePlan) {
    return openBoostedToast();
  }
  return {};
}

function showPublishAndBoostFeedback({
  publisherSubscribedToFreePlan,
  section,
  publisherBoostingLimitReached,
  adWasPublished,
  adWasBoosted,
  countryCode,
  bannedBrandsForFreeListings,
}) {
  if (!adWasPublished) {
    return openNotPublishedModal(section);
  }
  if (publisherSubscribedToFreePlan && countryCode !== 'MX') {
    return openPublishedButBoostingForbiddenModal(section);
  }
  if (!publisherBoostingLimitReached && !publisherSubscribedToFreePlan) {
    return openSitesPublishedOnModal(adWasBoosted);
  }
  if (
    !publisherSubscribedToFreePlan &&
    !adWasBoosted &&
    publisherBoostingLimitReached
  ) {
    if (
      bannedBrandsForFreeListings?.length > 0 &&
      !isTHPropertyTheOnlyBannedBrand(bannedBrandsForFreeListings)
    ) {
      return openSitesPublishedOnModal(adWasBoosted);
    }
    if (countryCode !== 'MX') {
      return openPublishedButBoostingLimitReachedModal(section);
    }
  }

  return {};
}

registerEventHandler(
  SHOW_AD_PUBLISHING_AND_BOOSTING_STATUS_NOTIFICATION,
  ({ state: { countryCode, bannedBrandsForFreeListings } }, payload) => {
    const { userAction } = payload;

    if (userAction === 'superboost') {
      return showSuperboostingFeedback(payload);
    }
    if (userAction === 'boost') {
      return showBoostingFeedback({ ...payload, countryCode });
    }
    if (userAction === 'publish_and_boost') {
      return showPublishAndBoostFeedback({
        ...payload,
        countryCode,
        bannedBrandsForFreeListings,
      });
    }
    if (userAction === 'save_as_draft') {
      return openSavedAsDraftToast();
    }
    return {};
  },
  [
    state.get({
      countryCode: 'publisher.countryCode',
      bannedBrandsForFreeListings: 'publisher.bannedBrandsForFreeListings',
    }),
  ]
);
registerEventHandler(
  SHOW_AD_PUBLISHING_ERROR_NOTIFICATION,
  () => ({
    ...reffects.dispatch({
      id: OPEN_DIALOG,
      payload: {
        id: NOT_PUBLISHED_ERROR_MODAL,
      },
    }),
    ...analytics.trackPageView('not-published-error-modal', {
      section: 'publishing-error-draft',
    }),
  }),
  []
);

registerEventHandler(SHOW_AD_BULK_STATUS_NOTIFICATION, (_, payload) => {
  const { userAction, countProcessedAds } = payload;
  if (
    userAction === 'publish' &&
    countProcessedAds === 0 &&
    payload.publisherSubscribedToFreePlan
  ) {
    return {
      ...toast.hide(),
      ...reffects.dispatch({
        id: OPEN_DIALOG,
        payload: {
          id: NOT_PUBLISHED_MODAL,
        },
      }),
    };
  }
  return toast.show({
    text: BULK_COMPLETE_TOAST_TAGS[userAction],
    values: { n: countProcessedAds },
    milliseconds: 2000,
  });
});

function openSuperboostingForbiddenModal(section) {
  return {
    ...effects.dispatch({
      id: OPEN_DIALOG,
      payload: {
        id: SUPERBOOSTING_FORBIDDEN_MODAL,
      },
    }),
    ...analytics.trackPageView('superboosting-forbidden-modal', { section }),
  };
}

function openBoostingForbiddenModal(section) {
  return {
    ...reffects.dispatch({
      id: OPEN_DIALOG,
      payload: {
        id: BOOSTING_FORBIDDEN_MODAL,
      },
    }),
    ...analytics.trackPageView('boosting-forbidden-modal', { section }),
  };
}

function openNotPublishedModal(section) {
  return {
    ...reffects.dispatch({
      id: OPEN_DIALOG,
      payload: {
        id: NOT_PUBLISHED_MODAL,
      },
    }),
    ...analytics.trackPageView('not-published-modal', {
      section,
    }),
  };
}

function openPublishedButBoostingForbiddenModal(section) {
  return {
    ...reffects.dispatch({
      id: OPEN_DIALOG,
      payload: {
        id: PUBLISHED_BUT_BOOSTING_FORBIDDEN_MODAL,
      },
    }),
    ...analytics.trackPageView('published-but-boosting-forbidden-modal', {
      section,
    }),
  };
}

function openSuperboostingLimitReachedModal(section) {
  return {
    ...effects.dispatch({
      id: OPEN_DIALOG,
      payload: {
        id: SUPERBOOSTING_LIMIT_REACHED_MODAL,
      },
    }),
    ...analytics.trackPageView('superboosting-limit-reached-modal', {
      section,
    }),
  };
}

function openSuperboostingLimitReachedForBoostingModal(section) {
  return {
    ...effects.dispatch({
      id: OPEN_DIALOG,
      payload: {
        id: SUPERBOOSTING_LIMIT_REACHED_FOR_BOOSTING_MODAL,
      },
    }),
    ...analytics.trackPageView(
      'superboosting-limit-reached-for-boosting-modal',
      {
        section,
      }
    ),
  };
}

function openBoostingLimitReachedModal(section) {
  return {
    ...reffects.dispatch({
      id: OPEN_DIALOG,
      payload: {
        id: BOOSTING_LIMIT_REACHED_MODAL,
      },
    }),
    ...analytics.trackPageView('boosting-limit-reached-modal', { section }),
  };
}

function openPublishedButBoostingLimitReachedModal(section) {
  return {
    ...reffects.dispatch({
      id: OPEN_DIALOG,
      payload: {
        id: PUBLISHED_BUT_BOOSTING_LIMIT_REACHED_MODAL,
      },
    }),
    ...analytics.trackPageView('published-but-boosting-limit-reached-modal', {
      section,
    }),
  };
}

function openSitesPublishedOnModal(adWasBoosted) {
  return {
    ...reffects.dispatch({
      id: OPEN_DIALOG,
      payload: {
        id: SITES_PUBLISHED_ON_MODAL,
        parameters: { isBoosted: adWasBoosted },
      },
    }),
  };
}

function openSuperboostedToast() {
  return toast.show({
    text: 'propspage_toast_confirmation_superboosting',
  });
}

function openBoostedToast() {
  return toast.show({
    text: 'propspage_toast_confirmation_boosting',
  });
}

function openSavedAsDraftToast() {
  return toast.show({
    text: 'propspage_saved_draft',
  });
}
