import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import queryString from 'query-string';
import { environment } from '../../../../coeffects/environment';
import { mapPropertyFilters } from '../../mappers/mapPropertyFilters';

export const PROPERTIES_SUGGESTIONS_SEARCHED =
  'PROPERTIES_SUGGESTIONS_SEARCHED';
export const PROPERTIES_SUGGESTIONS_RETRIEVED =
  'PROPERTIES_SUGGESTIONS_RETRIEVED';
export const PROPERTIES_SUGGESTIONS_FAILED = 'PROPERTIES_SUGGESTIONS_FAILED';

registerEventHandler(
  PROPERTIES_SUGGESTIONS_SEARCHED,
  ({ environment: { apiUrl } }, filters) => {
    const mappedFilters = mapPropertyFilters(filters);
    const filteredQueryParams = queryString.stringify(mappedFilters, {
      skipNull: true,
    });

    return http.get({
      url: `${apiUrl}/properties-suggestions?${filteredQueryParams}`,
      successEvent: [PROPERTIES_SUGGESTIONS_RETRIEVED, filters.q],
      errorEvent: PROPERTIES_SUGGESTIONS_FAILED,
    });
  },
  [environment()]
);

registerEventHandler(
  PROPERTIES_SUGGESTIONS_RETRIEVED,
  (_, [response, query]) => {
    const suggestions = Object.values(response.data).reduce(
      (acc, suggestionsOfType) => [...acc, ...suggestionsOfType.flat()],
      []
    );
    const querySuggestion = { type: 'query', text: query };

    return state.set({
      'properties:suggestions.data': [querySuggestion, ...suggestions],
    });
  }
);

registerEventHandler(PROPERTIES_SUGGESTIONS_FAILED, () =>
  state.set({
    'properties:suggestions.data': [],
  })
);
