import { useMemo } from 'react';
import { useDatatableContext } from './useDatatableContext';

export default () => {
  const context = useDatatableContext();
  const updateTableEvent = useMemo(
    () => ({
      id: context.updateEvents.collection,
      payload: {
        ...context,
      },
    }),
    [context]
  );

  return updateTableEvent;
};
