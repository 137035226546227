import { SUBSCRIPTION_TYPES } from '../constants/subscription';

export function isForBaseSubscription(scr) {
  return isFor(scr, SUBSCRIPTION_TYPES.BASE_SUBSCRIPTION);
}

export function isForSuperboost(scr) {
  return isFor(scr, SUBSCRIPTION_TYPES.SUPERBOOST);
}

function isFor(scr, productType) {
  return scr.products.some(({ type }) => type === productType);
}

export function getBaseSubscriptionProductAsSubscription(scr) {
  return getProductAsSubscription(scr, SUBSCRIPTION_TYPES.BASE_SUBSCRIPTION);
}

export function getSuperboostProductAsSubscription(scr) {
  return getProductAsSubscription(scr, SUBSCRIPTION_TYPES.SUPERBOOST);
}

function getProductAsSubscription(scr, productType) {
  const product = getProduct(scr, productType);
  if (product) {
    return scrProductToSubscription(scr, product);
  }
  return undefined;
}

function getProduct(scr, productType) {
  return scr.products.find((product) => product.type === productType);
}

function scrProductToSubscription(scr, product) {
  return {
    products: [product],
    billingCycleInMonths: scr.billingCycleInMonths,
    totalBasePriceWithDiscount: product.basePriceWithDiscounts,
    discounts: product.discounts,
    taxApplied: product.taxApplied,
    taxDiscounts: product.retentions,
    totalPrice: product.totalPrice,
  };
}
