import styled from 'styled-components';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Wrapper = styled.div`
  display: grid;
  grid-row-gap: ${SPACING_SIZE.S}px;
  grid-column-gap: ${SPACING_SIZE.M}px;
  grid-template-columns: repeat(4, 1fr);

  div {
    display: flex;
    align-items: center;

    ${Icon} {
      ${color.text(COLOR_PALETTE.NEUTRAL_A80)}
      ${spacing.inline(SPACING_SIZE.S)}
      ${Icon.mods.size(ICON_SIZE.BASE)}
    }
  }
`;

function IconGrid({ items, ...props }) {
  return (
    <Wrapper {...props}>
      {items.map(({ id, tag, glyph }) => (
        <div key={id}>
          <Icon glyph={glyph} />{' '}
          <Body1
            $weight={FONT_WEIGHT.LIGHT}
            $color={COLOR_PALETTE.NEUTRAL_BASE}
          >
            <FormattedMessageWithValidation id={tag} />
          </Body1>
        </div>
      ))}
    </Wrapper>
  );
}

export default styled(IconGrid)``;
