import * as React from "react";
const SvgMaestroCardCreditCard = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={38}
    fill="none"
    {...props}
  >
    <ellipse cx={24} cy={19.001} fill="#EB001B" rx={12.8} ry={12.667} />
    <ellipse cx={40} cy={19.001} fill="#00A1DF" rx={12.8} ry={12.667} />
    <path
      fill="#7673C0"
      fillRule="evenodd"
      d="M32 28.888C34.926 26.567 36.8 23 36.8 19S34.927 11.433 32 9.11C29.074 11.433 27.2 15 27.2 19s1.874 7.567 4.8 9.888"
      clipRule="evenodd"
    />
    <rect width={63} height={37} x={0.5} y={0.5} stroke="#E6E6E6" rx={0.5} />
  </svg>
);
export default SvgMaestroCardCreditCard;
