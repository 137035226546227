import styled, { css } from 'styled-components';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import { Link } from 'react-router-dom';
import Tile from 'design-system/components/Tile';
import {
  FlatButton,
  PrimaryButton,
} from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import Icon from 'design-system/components/Icon';
import { border } from 'design-system/styles/border';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import Typography from 'design-system/components/Typography/Typography';
import {
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import FormattedMessageWithValidation from '../FormattedMessageWithValidation/FormattedMessageWithValidation';
import { NOT_USING_WHOLE_PLAN_BANNER_CLOSED } from './events';
import {
  availableSlotsFromPublisherSelector,
  showNotUsingYourWholePlanBannerSelector,
} from './selectors';
import useTracking from '../../hooks/useTracking';
import { newPropertyForm } from '../../utils/proppitWebRouter';

import useClickTracking from '../../hooks/useClickTracking';

import { publishedPropertiesSelector } from '../../selectors/propertiesSummary';
import { couldShowBannerSelector } from '../LeadQualityAwareness/selectors';

const Wrapper = styled.div`
  width: 100%;
  ${color.background(COLOR_PALETTE.PRIMARY_A05)}
  ${({ $isOverview }) =>
    $isOverview &&
    css`
      padding: ${SPACING_SIZE.L}px;
      ${radius.regular(RADIUS_SIZE.LARGE)}
    `}
`;

const BannerContentWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: ${spacing.value(SPACING_SIZE.L)};
  flex-direction: row;
  justify-content: space-between;
`;

const WidthLimiter = styled.div`
  width: 100%;
  min-width: 1280px;
  max-width: 1680px;
  margin: 0 auto;

  ${BannerContentWrapper} {
    padding: ${SPACING_SIZE.L}px ${SPACING_SIZE.XL}px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_SIZE.S}px;
`;

const FirstTile = styled(Tile)`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  ${color.background(COLOR_PALETTE.PRIMARY_A05)}
  padding: ${SPACING_SIZE.S}px ${SPACING_SIZE.M}px;
  ${border.outer({ color: COLOR_PALETTE.PRIMARY_BASE })}
`;

const SecondTile = styled(FirstTile)`
  flex-direction: row;
  gap: ${SPACING_SIZE.M}px;
`;

const ListWrapper = styled.div`
  flex-grow: 1;
  padding: ${SPACING_SIZE.S}px 0px;
`;

const Title = styled(Typography)`
  ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
  ${typography.size(FONT_SIZE.XL)}
  ${typography.weight(FONT_WEIGHT.SEMIBOLD)}
  ${typography.lineHeight(18)}
`;

const BoxText = styled(Typography)`
  ${spacing.stack(SPACING_SIZE.XS, false)}
  ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
  ${typography.size(FONT_SIZE.XL)}
  ${typography.weight(FONT_WEIGHT.SEMIBOLD)}
  ${typography.lineHeight(18)}
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing.value(SPACING_SIZE.M)};
  align-items: flex-start;
`;

const ContentInside = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentCTA = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

const Header = styled.div`
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing.value(SPACING_SIZE.S)}px;
`;

const TitleText = styled(Typography)`
  ${typography.size(FONT_SIZE.M)}
  ${typography.weight(FONT_WEIGHT.SEMIBOLD)}
  ${typography.lineHeight(18)}
  ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
  margin-bottom: 4px;
`;

const TitleTextCounters = styled(Typography)`
  ${typography.size(FONT_SIZE.XL)}
  ${typography.weight(FONT_WEIGHT.SEMIBOLD)}
  ${typography.lineHeight(18)}
  ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
  margin-bottom: 4px;
`;

const BodyText = styled(Typography)`
  ${typography.size(FONT_SIZE.S)}
  ${typography.weight(FONT_WEIGHT.NORMAL)}
  ${typography.lineHeight(14)}
  margin-bottom: 4px;
  ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
`;

const List = styled.ul`
  margin: 0;
  ${typography.font(FONT_FAMILY.PRIMARY)}
`;

const ListItem = styled.li`
  ${typography.lineHeight(18)}
  ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
  ${typography.size(FONT_SIZE.M)}
`;

function BannerContent() {
  const publishedProperties = useSelector(publishedPropertiesSelector);
  const availableSlots = useSelector(availableSlotsFromPublisherSelector);
  const { trackClick } = useClickTracking({
    sectionName: 'not-using-whole-plan-banner',
  });
  return (
    <BannerContentWrapper>
      <ContentWrapper>
        <Header>
          <Title>
            <FormattedMessageWithValidation id="banner_publish_all_your_plan_title" />
          </Title>
        </Header>
        <Content>
          <FirstTile>
            <BoxText>
              <TitleTextCounters>{publishedProperties}</TitleTextCounters>
            </BoxText>
            <BodyText>
              <FormattedMessageWithValidation id="banner_publish_all_your_plan_total" />
            </BodyText>
          </FirstTile>
          <SecondTile>
            <ContentInside>
              <BoxText>
                <TitleTextCounters>{availableSlots}</TitleTextCounters>
              </BoxText>
              <BodyText>
                <FormattedMessageWithValidation id="banner_publish_all_your_plan_pending_to_publish" />
              </BodyText>
            </ContentInside>
            <ContentCTA>
              <PrimaryButton
                $size={BUTTON_SIZE.SMALL}
                forwardedAs={Link}
                to={newPropertyForm()}
                onClick={() =>
                  trackClick({
                    eventName: 'create-property-button',
                  })
                }
              >
                <FormattedMessageWithValidation id="banner_publish_all_your_plan_cta" />
              </PrimaryButton>
            </ContentCTA>
          </SecondTile>
          <ListWrapper>
            <List>
              <ListItem>
                <TitleText />
                <FormattedMessageWithValidation id="banner_publish_all_your_plan_text1" />
              </ListItem>
              <ListItem>
                <FormattedMessageWithValidation id="banner_publish_all_your_plan_text2" />
              </ListItem>
            </List>
          </ListWrapper>
        </Content>
      </ContentWrapper>
      <FlatButton
        icon={<Icon glyph="cross" />}
        onClick={() => {
          dispatch({
            id: NOT_USING_WHOLE_PLAN_BANNER_CLOSED,
          });
        }}
      />
    </BannerContentWrapper>
  );
}

function NotUsingYourWholePlanBanner({ isOverview }) {
  const showNotUsingYourWholePlanBanner = useSelector((state) =>
    showNotUsingYourWholePlanBannerSelector(state, new Date())
  );
  const trackImpression = useTracking({
    trackImpression: {
      elementName: 'not-using-whole-plan-banner',
    },
    sectionName: 'not-using-whole-plan-banner-section',
  });

  const couldShowBanner = useSelector(couldShowBannerSelector);

  if (!couldShowBanner) {
    return null;
  }

  if (!showNotUsingYourWholePlanBanner) {
    return null;
  }

  return (
    <Wrapper $isOverview={isOverview} ref={trackImpression}>
      {isOverview ? (
        <BannerContent />
      ) : (
        <WidthLimiter>
          <BannerContent />
        </WidthLimiter>
      )}
    </Wrapper>
  );
}

export default styled(NotUsingYourWholePlanBanner)``;
