import Layout from 'design-system/components/Layout';
import Illustration from 'design-system/components/Illustration/Illustration';
import EmptyState from 'design-system/components/EmptyState';
import { Navigation } from '../../partials';
import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

function NotFound() {
  return (
    <Layout>
      <Layout.Header>
        <Navigation />
      </Layout.Header>
      <Layout.Content>
        <EmptyState
          fullScreen
          illustration={<Illustration name="pageNotFound" />}
          title={
            <FormattedMessageWithValidation id="page_not_found_empty_state_title" />
          }
          description={
            <FormattedMessageWithValidation id="page_not_found_empty_state_body" />
          }
        />
      </Layout.Content>
    </Layout>
  );
}

export default NotFound;
