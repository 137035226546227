import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import Icon from 'design-system/components/Icon';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { ActionsMenu } from '../../../partials/DataTable';
import { useDatatableContext } from '../../../partials/DataTable/hooks/useDatatableContext';
import { publisherSubscriptionPlanIsPaidSelector } from '../../../selectors/publisher';
import { BULK_BOOST_ACTION_SELECTED } from './boostEvents';
import { useBulkAction } from '../../../partials/DataTable/hooks/useBulkAction';

export function BoostBulkAction() {
  const { isProcessing } = useBulkAction('boost');
  const context = useDatatableContext();
  const isPaidPublisher = useSelector(publisherSubscriptionPlanIsPaidSelector);

  const handleBoost = () =>
    dispatch({
      id: BULK_BOOST_ACTION_SELECTED,
      payload: {
        isPaidPublisher,
        ...context,
      },
    });

  return (
    <ActionsMenu.Action
      icon={<Icon glyph="bolt" />}
      disabled={isProcessing}
      onClick={handleBoost}
    >
      <FormattedMessageWithValidation id="propspage_filter_action_activate_boosting" />
    </ActionsMenu.Action>
  );
}
