import Dialog from 'design-system/components/Dialog/Dialog';
import { useDialog } from '../../../hooks/useDialog';
import { CHURN_MODAL } from './constants';
import ChurnModalContent from './ChurnModalContent';

export default function ChurnModal() {
  const { open, closeDialog } = useDialog(CHURN_MODAL);

  return (
    <Dialog open={open}>
      <ChurnModalContent closeDialog={closeDialog} />
    </Dialog>
  );
}
