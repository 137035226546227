import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import queryString from 'query-string';
import { environment } from '../../../../coeffects/environment';

export const LEADS_SUGGESTIONS_SEARCHED = 'LEADS_SUGGESTIONS_SEARCHED';
export const LEADS_SUGGESTIONS_RETRIEVED = 'LEADS_SUGGESTIONS_RETRIEVED';
export const LEADS_SUGGESTIONS_FAILED = 'LEADS_SUGGESTIONS_FAILED';

registerEventHandler(
  LEADS_SUGGESTIONS_SEARCHED,
  ({ environment: { apiUrl } }, filters) => {
    const filteredQueryParams = queryString.stringify(filters, {
      skipNull: true,
    });

    return http.get({
      url: `${apiUrl}/leads/suggestions?${filteredQueryParams}`,
      successEvent: [LEADS_SUGGESTIONS_RETRIEVED, filters.q],
      errorEvent: LEADS_SUGGESTIONS_FAILED,
    });
  },
  [environment()]
);

registerEventHandler(LEADS_SUGGESTIONS_RETRIEVED, (_, [{ data }, query]) => {
  const querySuggestion = { type: 'query', text: query };

  return state.set({
    'leads:suggestions': [querySuggestion, ...data],
  });
});

registerEventHandler(LEADS_SUGGESTIONS_FAILED, () =>
  state.set({
    'leads:suggestions': [],
  })
);
