import { useSelector } from 'reffects-store';
import * as yup from 'yup';
import { number, string } from 'yup';
import { PhoneNumberUtil } from 'google-libphonenumber';
import useInternationalization from '../../hooks/useInternationalization';
import { ADDRESS_INPUT_NAME, PROJECT_ID_INPUT_NAME } from './constants';
import { propertySupportedLanguagesSelector } from '../../selectors/config';
import {
  CONTACT_FACEBOOK_MESSENGER_MAX_LENGTH,
  CONTACT_LINE_MAX_LENGTH,
  CONTACT_SKYPE_MAX_LENGTH,
  CONTACT_VIBER_MAX_LENGTH,
  EMAIL_VALIDATION_REGEX,
} from '../../constants';

const PLOT_AREA_RAI_MAX_SIZE = 1000000;
const PLOT_AREA_NGAN_MAX_SIZE = 3.99;
const PLOT_AREA_SQW_MAX_SIZE = 99.99;
const MIN_PRICE = 1;
const MAX_PRICE = 100000000000000000;
const MIN_COMMUNITY_FEES_PRICE = 1;
const MIN_AREA = 0;
const MAX_AREA = 200000000;
const MAX_BEDROOMS = 500;
const MAX_BATHROOMS = 500;
const MAX_SPACES = 500;
const MAX_TOILETS = 500;
const MAX_PARKING_SPACES = 200;
const CONSTRUCTION_YEAR_MAX_NUMBER = 2100;
const CONSTRUCTION_YEAR_MIN_NUMBER = 1500;
const REFERENCE_ID_MAX_LENGTH = 100;
const MAX_TITLE_LENGTH = 150;
const MAX_DESCRIPTION_LENGTH = 5000;

function isDefinedAndFilled(value) {
  if (!value) {
    return false;
  }
  return value.length > 0;
}

function isUndefinedOrEmpty(value) {
  if (!value) {
    return true;
  }
  return value.length === 0;
}

function buildDescriptionFieldsValidation(propertySupportedLanguages, intl) {
  if (propertySupportedLanguages.length > 2)
    throw new Error('Up to 2 locales supported');
  if (propertySupportedLanguages.length === 1) {
    const locale = propertySupportedLanguages[0];
    return {
      fields: {
        [`title_${locale}`]: yup
          .string()
          .trim()
          .required(
            intl.formatMessageWithValidation({
              id: 'form_validations_required_field',
            })
          )
          .max(
            MAX_TITLE_LENGTH,
            intl.formatMessageWithValidation({
              id: 'form_validations_invalid_text_length',
              values: { maxLength: MAX_TITLE_LENGTH },
            })
          ),
        [`description_${locale}`]: yup
          .string()
          .trim()
          .max(
            MAX_DESCRIPTION_LENGTH,
            intl.formatMessageWithValidation({
              id: 'form_validations_invalid_text_length',
              values: { maxLength: MAX_DESCRIPTION_LENGTH },
            })
          )
          .required(
            intl.formatMessageWithValidation({
              id: 'form_validations_required_field',
            })
          ),
      },
      sort: [],
    };
  }
  const [first, second] = propertySupportedLanguages;
  const otherLocaleIsEmpty = (...fields) => fields.every(isUndefinedOrEmpty);
  return {
    fields: {
      [`title_${first}`]: yup
        .string()
        .trim()
        .when(
          [`description_${first}`, `title_${second}`, `description_${second}`],
          {
            is: (
              sameLocaleDescription,
              otherLocaleTitle,
              otherLocaleDescription
            ) =>
              isDefinedAndFilled(sameLocaleDescription) ||
              otherLocaleIsEmpty(otherLocaleTitle, otherLocaleDescription),
            then: yup.string().required(
              intl.formatMessageWithValidation({
                id: 'form_validations_required_field',
              })
            ),
            otherwise: yup.string().notRequired(),
          }
        )
        .max(
          MAX_TITLE_LENGTH,
          intl.formatMessageWithValidation({
            id: 'form_validations_invalid_text_length',
            values: { maxLength: MAX_TITLE_LENGTH },
          })
        ),
      [`description_${first}`]: yup
        .string()
        .trim()
        .max(
          MAX_DESCRIPTION_LENGTH,
          intl.formatMessageWithValidation({
            id: 'form_validations_invalid_text_length',
            values: { maxLength: MAX_DESCRIPTION_LENGTH },
          })
        )
        .when([`title_${first}`, `title_${second}`, `description_${second}`], {
          is: (sameLocaleTitle, otherLocaleTitle, otherLocaleDescription) =>
            isDefinedAndFilled(sameLocaleTitle) ||
            otherLocaleIsEmpty(otherLocaleTitle, otherLocaleDescription),
          then: yup.string().required(
            intl.formatMessageWithValidation({
              id: 'form_validations_required_field',
            })
          ),
          otherwise: yup.string().notRequired(),
        }),
      [`title_${second}`]: yup
        .string()
        .trim()
        .when(
          [`title_${first}`, `description_${first}`, `description_${second}`],
          {
            is: (
              otherLocaleTitle,
              otherLocaleDescription,
              sameLocaleDescription
            ) =>
              isDefinedAndFilled(sameLocaleDescription) ||
              otherLocaleIsEmpty(otherLocaleTitle, otherLocaleDescription),
            then: yup.string().required(
              intl.formatMessageWithValidation({
                id: 'form_validations_required_field',
              })
            ),
            otherwise: yup.string().notRequired(),
          }
        )
        .max(
          MAX_TITLE_LENGTH,
          intl.formatMessageWithValidation({
            id: 'form_validations_invalid_text_length',
            values: { maxLength: MAX_TITLE_LENGTH },
          })
        ),
      [`description_${second}`]: yup
        .string()
        .trim()
        .max(
          MAX_DESCRIPTION_LENGTH,
          intl.formatMessageWithValidation({
            id: 'form_validations_invalid_text_length',
            values: { maxLength: MAX_DESCRIPTION_LENGTH },
          })
        )
        .ensure()
        .when([`title_${first}`, `description_${first}`, `title_${second}`], {
          is: (otherLocaleTitle, otherLocaleDescription, sameLocaleTitle) =>
            isDefinedAndFilled(sameLocaleTitle) ||
            otherLocaleIsEmpty(otherLocaleTitle, otherLocaleDescription),
          then: yup.string().required(
            intl.formatMessageWithValidation({
              id: 'form_validations_required_field',
            })
          ),
          otherwise: yup.string().notRequired(),
        }),
    },
    sort: [
      [`title_${first}`, `description_${first}`],
      [`title_${first}`, `title_${second}`],
      [`title_${first}`, `description_${second}`],
      [`description_${first}`, `title_${second}`],
      [`description_${first}`, `description_${second}`],
      [`title_${second}`, `description_${second}`],
    ],
  };
}

export function useValidationSchema() {
  const intl = useInternationalization();

  const propertySupportedLanguages = useSelector(
    propertySupportedLanguagesSelector
  );
  const descriptionFieldsValidation = buildDescriptionFieldsValidation(
    propertySupportedLanguages,
    intl
  );

  const locationFieldsValidation = {
    [ADDRESS_INPUT_NAME]: yup
      .string()
      .nullable()
      .required(
        intl.formatMessageWithValidation({
          id: 'form_validations_required_field',
        })
      ),
  };
  const commonFieldsValidation = buildCommonFieldsValidation();

  return yup
    .object()
    .shape(
      {
        ...commonFieldsValidation,
        ...locationFieldsValidation,
        ...descriptionFieldsValidation.fields,
      },
      descriptionFieldsValidation.sort
    )
    .required();
}

export function useUnitValidationSchema() {
  const intl = useInternationalization();

  const propertySupportedLanguages = useSelector(
    propertySupportedLanguagesSelector
  );

  const descriptionFieldsValidation = buildDescriptionFieldsValidation(
    propertySupportedLanguages,
    intl
  );

  const locationFieldsValidation = {
    [PROJECT_ID_INPUT_NAME]: yup
      .string()
      .nullable()
      .required(
        intl.formatMessageWithValidation({
          id: 'form_validations_required_field',
        })
      ),
  };
  const commonFieldsValidation = buildCommonFieldsValidation();

  return yup
    .object()
    .shape(
      {
        ...commonFieldsValidation,
        ...locationFieldsValidation,
        ...descriptionFieldsValidation.fields,
      },
      descriptionFieldsValidation.sort
    )
    .required();
}

function createContactDetailsSchema(intl) {
  const phoneUtil = PhoneNumberUtil.getInstance();

  return {
    contactPhone: yup
      .object({
        phoneNumber: number().required(
          intl.formatMessageWithValidation({
            id: 'form_validations_required_field',
          })
        ),
        countryCode: string().required(),
      })
      .test({
        name: 'contactPhone',
        message: intl.formatMessageWithValidation({
          id: 'form_validations_invalid_phone_number',
        }),
        test: (value) => {
          try {
            const phoneWithPrefix = `${value.dialCode}${value.phoneNumber}`;
            return phoneUtil.isValidNumber(
              phoneUtil.parseAndKeepRawInput(phoneWithPrefix, value.countryCode)
            );
          } catch (e) {
            return false;
          }
        },
      }),
    contactWhatsApp: yup
      .object({
        phoneNumber: number()
          .optional()
          .transform((value) => (Number.isNaN(value) ? undefined : value)),
        countryCode: string().default('').notRequired(),
      })
      .test({
        name: 'contactWhatsApp',
        message: intl.formatMessageWithValidation({
          id: 'form_validations_invalid_phone_number',
        }),
        test: (value) => {
          try {
            const phoneWithPrefix = `${value.dialCode}${value.phoneNumber}`;
            return phoneUtil.isValidNumber(
              phoneUtil.parseAndKeepRawInput(phoneWithPrefix, value.countryCode)
            );
          } catch (e) {
            return !value.phoneNumber;
          }
        },
      }),
    contactLine: yup.string().max(
      CONTACT_LINE_MAX_LENGTH,
      intl.formatMessageWithValidation({
        id: 'form_validations_invalid_text_length',
        values: { maxLength: CONTACT_LINE_MAX_LENGTH },
      })
    ),
    contactSkype: yup.string().max(
      CONTACT_SKYPE_MAX_LENGTH,
      intl.formatMessageWithValidation({
        id: 'form_validations_invalid_text_length',
        values: { maxLength: CONTACT_SKYPE_MAX_LENGTH },
      })
    ),
    contactFacebookMessenger: yup.string().max(
      CONTACT_FACEBOOK_MESSENGER_MAX_LENGTH,
      intl.formatMessageWithValidation({
        id: 'form_validations_invalid_text_length',
        values: { maxLength: CONTACT_FACEBOOK_MESSENGER_MAX_LENGTH },
      })
    ),
    contactViber: yup.string().max(
      CONTACT_VIBER_MAX_LENGTH,
      intl.formatMessageWithValidation({
        id: 'form_validations_invalid_text_length',
        values: { maxLength: CONTACT_VIBER_MAX_LENGTH },
      })
    ),
  };
}

function buildCommonFieldsValidation() {
  const intl = useInternationalization();

  return {
    // Operation
    operationType: yup
      .string()
      .nullable()
      .required(
        intl.formatMessageWithValidation({
          id: 'form_validations_required_field',
        })
      ),
    propertyType: yup
      .string()
      .nullable()
      .required(
        intl.formatMessageWithValidation({
          id: 'form_validations_required_field',
        })
      ),
    // sellPrice: yup
    //   .number()
    //   .required()
    //   .min(
    //     MIN_PRICE,
    //     intl.formatMessageWithValidation({
    //       id: 'form_validations_number_min_surpassed',
    //       values: {
    //         min: MIN_PRICE,
    //       },
    //     })
    //   )
    //   .max(
    //     MAX_PRICE,
    //     intl.formatMessageWithValidation({
    //       id: 'form_validations_number_max_surpassed',
    //       values: {
    //         max: MAX_PRICE,
    //       },
    //     })
    //   ),
    // rentPrice: yup
    //   .number()
    //   .required()
    //   .min(
    //     MIN_PRICE,
    //     intl.formatMessageWithValidation({
    //       id: 'form_validations_number_min_surpassed',
    //       values: {
    //         min: MIN_PRICE,
    //       },
    //     })
    //   )
    //   .max(
    //     MAX_PRICE,
    //     intl.formatMessageWithValidation({
    //       id: 'form_validations_number_max_surpassed',
    //       values: {
    //         max: MAX_PRICE,
    //       },
    //     })
    //   ),
    sellPrice: yup.number().when('operationType', {
      is: 'sell',
      then: yup
        .number()
        .required(
          intl.formatMessageWithValidation({
            id: 'form_validations_required_field',
          })
        )
        .min(
          MIN_PRICE,
          intl.formatMessageWithValidation({
            id: 'form_validations_number_min_surpassed',
            values: {
              min: MIN_PRICE,
            },
          })
        )
        .max(
          MAX_PRICE,
          intl.formatMessageWithValidation({
            id: 'form_validations_number_max_surpassed',
            values: {
              max: MAX_PRICE,
            },
          })
        ),
      otherwise: yup.number().nullable(),
    }),
    rentPrice: yup.number().when('operationType', {
      is: 'rent',
      then: yup
        .number()
        .required(
          intl.formatMessageWithValidation({
            id: 'form_validations_required_field',
          })
        )
        .min(
          MIN_PRICE,
          intl.formatMessageWithValidation({
            id: 'form_validations_number_min_surpassed',
            values: {
              min: MIN_PRICE,
            },
          })
        )
        .max(
          MAX_PRICE,
          intl.formatMessageWithValidation({
            id: 'form_validations_number_max_surpassed',
            values: {
              max: MAX_PRICE,
            },
          })
        ),
      otherwise: yup.number().nullable(),
    }),
    referenceId: yup.string().max(
      REFERENCE_ID_MAX_LENGTH,
      intl.formatMessageWithValidation({
        id: 'form_validations_invalid_text_length',
        values: { maxLength: REFERENCE_ID_MAX_LENGTH },
      })
    ),
    // Areas
    floorArea: yup
      .number()
      .min(
        MIN_AREA,
        intl.formatMessageWithValidation({
          id: 'form_validations_number_out_of_range',
          values: {
            min: MIN_AREA,
            max: MAX_AREA,
          },
        })
      )
      .max(
        MAX_AREA,
        intl.formatMessageWithValidation({
          id: 'form_validations_number_out_of_range',
          values: {
            min: MIN_AREA,
            max: MAX_AREA,
          },
        })
      ),
    usableArea: yup
      .number()
      .min(
        MIN_AREA,
        intl.formatMessageWithValidation({
          id: 'form_validations_number_out_of_range',
          values: {
            min: MIN_AREA,
            max: MAX_AREA,
          },
        })
      )
      .max(
        MAX_AREA,
        intl.formatMessageWithValidation({
          id: 'form_validations_number_out_of_range',
          values: {
            min: MIN_AREA,
            max: MAX_AREA,
          },
        })
      ),
    plotArea: yup
      .number()
      .min(
        MIN_AREA,
        intl.formatMessageWithValidation({
          id: 'form_validations_number_out_of_range',
          values: {
            min: MIN_AREA,
            max: MAX_AREA,
          },
        })
      )
      .max(
        MAX_AREA,
        intl.formatMessageWithValidation({
          id: 'form_validations_number_out_of_range',
          values: {
            min: MIN_AREA,
            max: MAX_AREA,
          },
        })
      ),
    plotAreaSqm: yup
      .number()
      .min(
        MIN_AREA,
        intl.formatMessageWithValidation({
          id: 'form_validations_number_out_of_range',
          values: {
            min: MIN_AREA,
            max: MAX_AREA,
          },
        })
      )
      .max(
        MAX_AREA,
        intl.formatMessageWithValidation({
          id: 'form_validations_number_out_of_range',
          values: {
            min: MIN_AREA,
            max: MAX_AREA,
          },
        })
      ),
    plotAreaRai: yup
      .number()
      .min(
        MIN_AREA,
        intl.formatMessageWithValidation({
          id: 'form_validations_number_out_of_range',
          values: {
            min: MIN_AREA,
            max: PLOT_AREA_RAI_MAX_SIZE,
          },
        })
      )
      .max(
        PLOT_AREA_RAI_MAX_SIZE,
        intl.formatMessageWithValidation({
          id: 'form_validations_number_out_of_range',
          values: {
            min: MIN_AREA,
            max: PLOT_AREA_RAI_MAX_SIZE,
          },
        })
      ),
    plotAreaNgan: yup
      .number()
      .min(
        MIN_AREA,
        intl.formatMessageWithValidation({
          id: 'form_validations_number_out_of_range',
          values: {
            min: MIN_AREA,
            max: PLOT_AREA_NGAN_MAX_SIZE,
          },
        })
      )
      .max(
        PLOT_AREA_NGAN_MAX_SIZE,
        intl.formatMessageWithValidation({
          id: 'form_validations_number_out_of_range',
          values: {
            min: MIN_AREA,
            max: PLOT_AREA_NGAN_MAX_SIZE,
          },
        })
      ),
    plotAreaSqw: yup
      .number()
      .min(
        MIN_AREA,
        intl.formatMessageWithValidation({
          id: 'form_validations_number_out_of_range',
          values: {
            min: MIN_AREA,
            max: PLOT_AREA_SQW_MAX_SIZE,
          },
        })
      )
      .max(
        PLOT_AREA_SQW_MAX_SIZE,
        intl.formatMessageWithValidation({
          id: 'form_validations_number_out_of_range',
          values: {
            min: MIN_AREA,
            max: PLOT_AREA_SQW_MAX_SIZE,
          },
        })
      ),
    // Details
    spaces: yup
      .number()
      .integer()
      .max(
        MAX_SPACES,
        intl.formatMessageWithValidation({
          id: 'form_validations_number_max_surpassed',
          values: {
            max: MAX_SPACES,
          },
        })
      ),
    bedrooms: yup
      .number()
      .integer()
      .max(
        MAX_BEDROOMS,
        intl.formatMessageWithValidation({
          id: 'form_validations_number_max_surpassed',
          values: {
            max: MAX_BEDROOMS,
          },
        })
      ),
    bathrooms: yup
      .number()
      .integer()
      .max(
        MAX_BATHROOMS,
        intl.formatMessageWithValidation({
          id: 'form_validations_number_max_surpassed',
          values: {
            max: MAX_BATHROOMS,
          },
        })
      ),
    toilets: yup
      .number()
      .integer()
      .max(
        MAX_TOILETS,
        intl.formatMessageWithValidation({
          id: 'form_validations_number_max_surpassed',
          values: {
            max: MAX_TOILETS,
          },
        })
      ),
    floor: yup.number().integer(),
    parkingSpaces: yup
      .number()
      .integer()
      .max(
        MAX_PARKING_SPACES,
        intl.formatMessageWithValidation({
          id: 'form_validations_number_max_surpassed',
          values: {
            max: MAX_PARKING_SPACES,
          },
        })
      ),
    energyCertificate: yup.string().when('$exist', {
      is: (exists) => exists,
      when: yup.string().required(
        intl.formatMessageWithValidation({
          id: 'form_validations_required_field',
        })
      ),
      otherwise: yup.string().notRequired(),
    }),
    efficiency: yup.string().when(['energyCertificate'], {
      is: (energyCertificate) => energyCertificate === 'yes',
      then: yup.string().required(
        intl.formatMessageWithValidation({
          id: 'form_validations_required_field',
        })
      ),
      otherwise: yup.string().notRequired(),
    }),
    emissions: yup.string().when(['energyCertificate'], {
      is: (energyCertificate) => energyCertificate === 'yes',
      then: yup.string().required(
        intl.formatMessageWithValidation({
          id: 'form_validations_required_field',
        })
      ),
      otherwise: yup.string().notRequired(),
    }),
    constructionYear: yup
      .number()
      .integer()
      .min(
        CONSTRUCTION_YEAR_MIN_NUMBER,
        intl.formatMessageWithValidation({
          id: 'form_validations_number_out_of_range',
          values: {
            min: CONSTRUCTION_YEAR_MIN_NUMBER,
            max: CONSTRUCTION_YEAR_MAX_NUMBER,
          },
        })
      )
      .max(
        CONSTRUCTION_YEAR_MAX_NUMBER,
        intl.formatMessageWithValidation({
          id: 'form_validations_number_out_of_range',
          values: {
            min: CONSTRUCTION_YEAR_MIN_NUMBER,
            max: CONSTRUCTION_YEAR_MAX_NUMBER,
          },
        })
      ),
    communityFees: yup.number().min(
      MIN_COMMUNITY_FEES_PRICE,
      intl.formatMessageWithValidation({
        id: 'form_validations_number_min_surpassed',
        values: {
          min: MIN_COMMUNITY_FEES_PRICE,
        },
      })
    ),
    // Media
    video: yup.string().url(
      intl.formatMessageWithValidation({
        id: 'form_validations_invalid_url',
      })
    ),
    virtualTours: yup.string().url(
      intl.formatMessageWithValidation({
        id: 'form_validations_invalid_url',
      })
    ),
    // Contact
    contactEmails: yup
      .array()
      .min(
        1,
        intl.formatMessageWithValidation({
          id: 'form_validations_required_field',
        })
      )
      .test(
        'unique-email',
        intl.formatMessageWithValidation({
          id: 'form_validations_duplicated_email',
        }),
        (values) => new Set(values).size === values.length
      )
      .of(
        yup
          .string()
          .email(
            intl.formatMessageWithValidation({
              id: 'form_validations_invalid_email',
            })
          )
          .matches(
            EMAIL_VALIDATION_REGEX,
            intl.formatMessageWithValidation({
              id: 'form_validations_invalid_email',
            })
          )
      ),
    ...createContactDetailsSchema(intl),
    propertyImages: yup.array().test({
      message: intl.formatMessageWithValidation({
        id: 'newprop_image_upload_error_limit',
        values: { count: 10 },
      }),
      test: (images) => images.length <= 200,
    }),
    floorPlans: yup.array().test({
      message: intl.formatMessageWithValidation({
        id: 'newprop_image_upload_error_limit',
        values: { count: 10 },
      }),
      test: (images) => images.length <= 10,
    }),
  };
}
