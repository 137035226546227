import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';

export const OPEN_DIALOG = 'OPEN_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';

registerEventHandler(OPEN_DIALOG, (_, { id, parameters }) =>
  state.set({
    dialogs: {
      [id]: {
        open: true,
        parameters,
      },
    },
  })
);

registerEventHandler(CLOSE_DIALOG, () =>
  state.set({
    dialogs: {},
  })
);
