import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import Anchor from 'design-system/components/Anchor';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { OPEN_DIALOG } from '../../../../events/dialogs';
import { SUBSCRIPTION_DETAILS_DIALOG } from '../SubscriptionDetailsModal';
import {
  publisherCountryShowRenewalPricesSelector,
  publisherHasSuperboostSubscriptionSelector,
  superboostSelector,
} from '../../../../selectors/publisher';
import CustomFormattedDate from '../../../../partials/FormattedDate/CustomFormattedDate';
import Price from '../../../../partials/Price/Price';
import { Column, Content, Row, TwoColumns, Wrapper } from './partials';

export default function ContractedSuperboostBanner() {
  const hasSuperboostSubscription = useSelector(
    publisherHasSuperboostSubscriptionSelector
  );
  const hidePrices = useSelector(publisherCountryShowRenewalPricesSelector);
  const superboost = useSelector(superboostSelector);

  if (!hasSuperboostSubscription) return null;
  return (
    <Wrapper>
      <Content>
        <Subtitle3 $color={COLOR_PALETTE.NEUTRAL_BASE}>
          <FormattedMessageWithValidation id="subscription_product" />
        </Subtitle3>
        <Row>
          <TwoColumns>
            <Column>
              <div>
                <Heading1>
                  <FormattedMessageWithValidation id="subscription_superboost_title" />
                </Heading1>
                <Heading1>
                  {' '}
                  {superboost.maxBoostableProperties}{' '}
                  <FormattedMessageWithValidation id="subscription_superboosted_properties" />
                </Heading1>
              </div>
              <Body1 $weight={FONT_WEIGHT.LIGHT}>
                <FormattedMessageWithValidation
                  id="subscription_summary_contract_type_value"
                  values={{
                    duration: superboost.billingCycleInMonths,
                  }}
                />
              </Body1>
            </Column>
            <Column>
              <Subtitle3>
                <FormattedMessageWithValidation id="subscription_next_renewal_date" />
              </Subtitle3>
              <div>
                <Heading1>
                  <CustomFormattedDate
                    value={superboost.nextBillingDate}
                    timeZone="utc"
                  />{' '}
                </Heading1>
              </div>
              {hidePrices && (
                <Body1>
                  <FormattedMessageWithValidation id="subscription_renewal_price" />{' '}
                  <Price
                    amount={superboost.totalPrice.amount}
                    currency={superboost.totalPrice.currency}
                  />
                </Body1>
              )}
            </Column>
          </TwoColumns>
        </Row>
        <Anchor
          href="#"
          onClick={(e) => {
            e.preventDefault();
            dispatch({
              id: OPEN_DIALOG,
              payload: {
                id: SUBSCRIPTION_DETAILS_DIALOG,
                parameters: {
                  title: 'subscription_superboost_details',
                  subscription: superboost,
                },
              },
            });
          }}
        >
          <FormattedMessageWithValidation id="subscription_view_details" />
        </Anchor>
      </Content>
    </Wrapper>
  );
}
