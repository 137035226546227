import styled from 'styled-components';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Overline } from 'design-system/components/Typography/presets/Overline';

const Wrapper = styled.div`
  ${spacing.inset(SPACING_SIZE.L)};
  ${spacing.stack(SPACING_SIZE.L)};
  ${radius.regular(RADIUS_SIZE.LARGE)}
  ${color.background(COLOR_PALETTE.NEUTRAL_A00)};

  ${Overline} {
    display: block;
    ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
    ${spacing.stack(SPACING_SIZE.S, false)}
  }

  > div {
    display: grid;
    grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
    grid-gap: ${spacing.value(SPACING_SIZE.M)};
  }
`;

function Section({ title, children, columns = 2, ...props }) {
  return (
    <Wrapper columns={columns} {...props}>
      <Overline>{title}</Overline>
      <div>{children}</div>
    </Wrapper>
  );
}

export default styled(Section)``;
