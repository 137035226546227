import { coeffect, registerCoeffectHandler } from 'reffects';

export default function registerLocalStorageCoeffect(window) {
  registerCoeffectHandler('localStorage', (key) => {
    if (Array.isArray(key)) {
      const localStorage = {};
      key.forEach((k) => {
        localStorage[k] = window.localStorage.getItem(k);
      });

      return { localStorage };
    }

    return {
      localStorage: {
        item: window.localStorage.getItem(key),
      },
    };
  });
}

export const localStorage = {
  getItem(key) {
    return coeffect('localStorage', key);
  },
  getItems(keys) {
    return coeffect('localStorage', keys);
  },
};
