import * as React from "react";
const SvgInstagram = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <rect
      width={31.3}
      height={31.3}
      x={0.35}
      y={0.35}
      stroke="#111B3C"
      strokeWidth={0.7}
      rx={3.65}
    />
    <path
      fill="#000"
      fillRule="evenodd"
      d="M23.498 19.7c0 2.007-1.641 3.629-3.65 3.629h-6.98a3.623 3.623 0 0 1-3.627-3.63v-7.006c0-2.007 1.62-3.629 3.627-3.629h6.98c2.007 0 3.65 1.622 3.65 3.63zm1.644-7.006a5.3 5.3 0 0 0-5.292-5.295h-6.98a5.3 5.3 0 0 0-5.292 5.295V19.7a5.3 5.3 0 0 0 5.292 5.294h6.98a5.3 5.3 0 0 0 5.292-5.294zm-8.761 6.39a2.757 2.757 0 0 1-2.762-2.761c0-1.53 1.232-2.786 2.762-2.786a2.78 2.78 0 0 1 2.783 2.786c0 1.553-1.232 2.76-2.783 2.76m0-7.12c-2.374 0-4.335 1.963-4.335 4.359s1.961 4.334 4.335 4.334 4.358-1.963 4.358-4.334-1.96-4.36-4.358-4.36m4.608-1.324c-.57 0-1.05.458-1.05 1.028s.48 1.05 1.05 1.05 1.026-.459 1.026-1.05a1.01 1.01 0 0 0-1.026-1.028"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgInstagram;
