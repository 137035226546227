import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import styled from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import ImagePreview from './ImagePreview';
import { imageSelectorByPage } from './selectors';
import {
  CREATE_PUBLISHER_IMAGE_DELETE_REQUESTED,
  CREATE_PUBLISHER_IMAGE_UPLOADED,
} from '../events';
import Section from '../Section';

const ImageUploaderInput = styled.input`
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
`;

const ImageUploaderLabel = styled.label`
  border-radius: 8px;
  ${color.text(COLOR_PALETTE.PRIMARY_BASE)}
  border: solid 2px ${COLOR_PALETTE.PRIMARY_BASE};
  cursor: pointer;
  width: 7.5rem;
  height: 7.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 1.5rem;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    border: solid 2px rgb(0 109 51);
    color: rgb(0 109 51);
  }
`;

export function ImageUploader() {
  const image = useSelector(imageSelectorByPage);

  return (
    <Section title="Logo">
      <div>
        {!image && (
          <div>
            <ImageUploaderInput
              type="file"
              id="file"
              name="image"
              onChange={(event) => {
                dispatch({
                  id: CREATE_PUBLISHER_IMAGE_UPLOADED,
                  payload: {
                    file: event.target.files[0],
                  },
                });
                resetInputFile(event.target);
              }}
              accept=".jpg, .jpeg, .png"
            />
            <ImageUploaderLabel htmlFor="file">
              <span>+</span>
            </ImageUploaderLabel>
          </div>
        )}

        {image && (
          <ImagePreview
            key={`${image}`}
            image={image}
            onDelete={() => dispatch(CREATE_PUBLISHER_IMAGE_DELETE_REQUESTED)}
          />
        )}
      </div>
    </Section>
  );
}

function resetInputFile(target) {
  // eslint-disable-next-line no-param-reassign
  target.value = null;
}
