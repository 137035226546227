import { effects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { queryParams } from 'reffects-batteries';
import { TRACK_CLICK } from '../../partials/Tracking/events';
import { OPEN_DIALOG } from '../../events/dialogs';
import { CHURN_POLL_MODAL } from './constants';

export const CHURN_POLL_NAVIGATED = 'CHURN_POLL_NAVIGATED';

registerEventHandler(
  CHURN_POLL_NAVIGATED,
  ({ queryParams: { publisherId, subscriptionId, attempts, reason } }) => ({
    ...state.set({
      'publisher.id': publisherId,
      'publisher.subscriptionId': subscriptionId,
      'churnModal.selectedReason': reason,
      'publisher:askChurnReason': true,
    }),
    ...effects.dispatchMany([
      {
        id: TRACK_CLICK,
        payload: {
          eventName: 'churn-reasons-from-email',
          sectionName: 'churn-reasons',
          payload: {
            reason,
            publisherId,
            subscriptionId,
            attempts,
          },
        },
      },
      { id: OPEN_DIALOG, payload: { id: CHURN_POLL_MODAL } },
    ]),
  }),
  [queryParams.get(['reason', 'publisherId', 'subscriptionId', 'attempts'])]
);
