import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import styled from 'styled-components';
import Table from 'design-system/components/Table';
import { useColumn } from '../../partials/DataTable/hooks/useColumn';

const OperationData = styled.div`
  display: flex;
  flex-direction: column;
`;

const OperationCell = styled.div`
  ${spacing.stack(SPACING_SIZE.XS)}
`;

function OperationFieldColumn({
  operationField,
  renderField,
  cellComponent,
  ...props
}) {
  const Cell = cellComponent ?? Table.Cell;
  const source = { operations: 'operations' };
  const {
    value: { operations = {} },
  } = useColumn({ ...props, source });

  return (
    <Cell maxWidth={225}>
      <OperationData>
        <OperationCell>{renderField(operations.sell)}</OperationCell>
        <OperationCell>{renderField(operations.rent)}</OperationCell>
      </OperationData>
    </Cell>
  );
}

export default OperationFieldColumn;
