import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Heading2 } from 'design-system/components/Typography/presets/Heading2';
import { color, COLOR_PALETTE } from 'design-system/styles/color';

const Wrapper = styled.div`
  > * {
    ${spacing.stack(SPACING_SIZE.L, false)}
  }

  ${Heading2} {
    display: block;
    ${color.text(COLOR_PALETTE.SECONDARY_BASE)}
  }
`;

function Section({ children, title, ...props }) {
  return (
    <Wrapper {...props}>
      <Heading2>{title}</Heading2>
      {children}
    </Wrapper>
  );
}

export default styled(Section)``;
