import { coeffect, registerCoeffectHandler } from 'reffects';

export default function registerTranslationsCoeffectHandler(translate) {
  registerCoeffectHandler('translations', (translationIds) => ({
    translations: translationIds.reduce((acc, translationId) => {
      acc[translationId] = translate({ id: translationId });
      return acc;
    }, {}),
  }));
}

export function translations(keys) {
  return coeffect('translations', keys);
}
