import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Dropdown, { DROPDOWN_POSITION } from 'design-system/components/Dropdown';
import Menu from 'design-system/components/Menu/Menu';
import InputBase from 'design-system/components/InputBase/InputBase';
import { OutlineButton } from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';
import InputNumber from 'design-system/components/InputNumber';
import { useFilter } from '../../../partials/DataTable/hooks/useFilter';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Wrapper = styled(Menu)`
  display: flex;
  max-height: 103px;

  ${spacing.inset(SPACING_SIZE.M)};
`;

const Separator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${spacing.inset(SPACING_SIZE.M)};
`;

const PriceInputWrapper = styled(InputNumber)`
  > ${InputBase.Box} {
    max-width: 120px;
    max-height: 50px;
  }
`;

function PriceInput({ labelId, source, value, onChange, onEnter, ...props }) {
  return (
    <PriceInputWrapper
      label={<FormattedMessageWithValidation id={labelId} />}
      options={{
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralPositiveOnly: true,
      }}
      source={source}
      value={value}
      onChange={onChange}
      onKeyUp={(e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
          onEnter();
        }
      }}
      {...props}
    />
  );
}

export function PriceRangeFilter() {
  const { value: priceFilter, setValue: setPriceFilter } = useFilter('price');
  const [minPriceInput, setMinPriceInput] = useState(priceFilter?.min ?? '');
  const [maxPriceInput, setMaxPriceInput] = useState(priceFilter?.max ?? '');
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    if (!isOpen) {
      if (!minPriceInput && !maxPriceInput && priceFilter) {
        setPriceFilter(null);
      } else if (minPriceInput || maxPriceInput) {
        setPriceFilter({
          min: minPriceInput === '' ? undefined : minPriceInput,
          max: maxPriceInput === '' ? undefined : maxPriceInput,
        });
      }
    }
  }, [isOpen]);

  useEffect(() => {
    setMinPriceInput(priceFilter?.min);
    setMaxPriceInput(priceFilter?.max);
  }, [priceFilter]);

  function printButtonContent() {
    const { min, max } = priceFilter ?? {};
    if (min == null && max == null) {
      return (
        <FormattedMessageWithValidation id="properties_list_filter_price" />
      );
    }
    const minPriceText = min == null ? '0' : intl.formatNumber(min);
    const maxPriceText =
      max == null
        ? intl.formatMessage({ id: 'properties_list_filter_price_range_max' })
        : intl.formatNumber(max);
    return `${minPriceText} - ${maxPriceText}`;
  }

  return (
    <Dropdown
      position={DROPDOWN_POSITION.BOTTOM_LEFT}
      button={
        <OutlineButton icon={<Icon glyph="chevronDown" />}>
          {printButtonContent()}
        </OutlineButton>
      }
      open={isOpen}
      onOpenChange={(open) => setIsOpen(open)}
    >
      <Wrapper>
        <PriceInput
          labelId="properties_list_filter_price_range_min"
          value={minPriceInput}
          onChange={(e) => setMinPriceInput(e.target.rawValue)}
          onEnter={() => setIsOpen(false)}
        />
        <Separator>_</Separator>
        <PriceInput
          labelId="properties_list_filter_price_range_max"
          value={maxPriceInput}
          onChange={(e) => setMaxPriceInput(e.target.rawValue)}
          onEnter={() => setIsOpen(false)}
        />
      </Wrapper>
    </Dropdown>
  );
}
