import styled, { css } from 'styled-components';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { border, BORDER_SIZE } from 'design-system/styles/border';
import { radius } from 'design-system/styles/radius';
import {
  color,
  COLOR_PALETTE,
  COLOR_SEMANTIC,
} from 'design-system/styles/color';

export const BADGE_VARIANT = {
  PRIMARY: Symbol('BADGE_VARIANT_PRIMARY'),
  ERROR: Symbol('BADGE_VARIANT_SECONDARY'),
  OUTLINE_BASE: Symbol('BADGE_VARIANT_OUTLINE_BASE'),
  OUTLINE_SECONDARY: Symbol('BADGE_VARIANT_OUTLINE_SECONDARY'),
};

export const BADGE_SIZE = {
  MEDIUM: Symbol('BADGE_SIZE_MEDIUM'),
  LARGE: Symbol('BADGE_SIZE_LARGE'),
};

const modVariantPrimary = () => css`
  ${color.background(COLOR_PALETTE.PRIMARY_A20)}
  ${color.text(COLOR_PALETTE.PRIMARY_A120)}
  ${border.outer({ size: BORDER_SIZE.NONE })}
`;

const modVariantError = () => css`
  ${color.background(COLOR_PALETTE.STRAWBERRY_A20)}
  ${color.text(COLOR_SEMANTIC.STATUS_ERROR)}
  ${border.outer({ size: BORDER_SIZE.NONE })}
`;

const modVariantOutlineBase = () => css`
  ${color.background(COLOR_SEMANTIC.PAPER)}
  ${color.text(COLOR_PALETTE.NEUTRAL_A40)}
  ${border.outer({
    size: BORDER_SIZE.BASE,
    color: COLOR_PALETTE.NEUTRAL_A20,
  })}
`;

const modVariantOutlineSecondary = () => css`
  ${color.background(COLOR_SEMANTIC.PAPER)}
  ${color.text(COLOR_PALETTE.SECONDARY_BASE)}
  ${border.outer({
    size: BORDER_SIZE.BASE,
    color: COLOR_PALETTE.SECONDARY_BASE,
  })}
`;

const modVariant = (variantName) =>
  ({
    [BADGE_VARIANT.PRIMARY]: modVariantPrimary,
    [BADGE_VARIANT.ERROR]: modVariantError,
    [BADGE_VARIANT.OUTLINE_BASE]: modVariantOutlineBase,
    [BADGE_VARIANT.OUTLINE_SECONDARY]: modVariantOutlineSecondary,
  }[variantName]);

const modSizeMedium = () => css`
  width: 16px;
  height: 16px;
  min-width: 16px;

  ${Icon} {
    width: 10px;
    height: 10px;
  }
`;

const modSizeLarge = () => css`
  width: 24px;
  height: 24px;
  min-width: 24px;

  ${Icon} {
    ${Icon.mods.size(ICON_SIZE.SMALL)}
  }
`;

const modSize = (size) =>
  ({
    [BADGE_SIZE.MEDIUM]: modSizeMedium,
    [BADGE_SIZE.LARGE]: modSizeLarge,
  }[size]);

const Badge = styled.span.attrs(({ icon }) => ({
  children: icon,
}))`
  display: flex;
  justify-content: center;
  align-items: center;

  ${radius.pill()}

  ${({ variant }) => variant && modVariant(variant)}
  ${({ size }) => size && modSize(size)}
`;

Badge.mods = {
  variant: modVariant,
  size: modSize,
};

export default Badge;
