import { useFormContext } from 'react-hook-form';
import { useRegisterFieldErrors } from '../../pages/publication/partials/FieldGroup';

function HiddenField({ name, value, ...rest }) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  useRegisterFieldErrors({ name, errors: errors[name] });

  return <input type="hidden" value={value} {...register(name)} {...rest} />;
}

export default HiddenField;
