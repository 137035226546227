import { subscribe } from 'reffects-store';
import FormattedMessageWithValidation from '../FormattedMessageWithValidation/FormattedMessageWithValidation';
import { publisherCountryToLowerCaseSelector } from '../../selectors/publisher';

function FormattedMessageWithSuffixCountry({ countryCode, id, values = {} }) {
  return (
    <FormattedMessageWithValidation
      id={`${id}_${countryCode}`}
      values={values}
    />
  );
}

export default subscribe(FormattedMessageWithSuffixCountry, (state) => ({
  countryCode: publisherCountryToLowerCaseSelector(state),
}));
