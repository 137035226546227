import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { FlatButton } from 'design-system/components/Button/presets';

const LinkButton = styled(FlatButton).attrs({
  icon: <Icon glyph="launch" />,
})`
  &${FlatButton} {
    ${spacing.inset(SPACING_SIZE.NONE)}

    ${Icon} {
      ${Icon.mods.size(ICON_SIZE.SMALL)}
    }
  }
`;

export default LinkButton;
