import * as React from "react";
const SvgAmericanExpressCreditCard = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={38}
    fill="none"
    {...props}
  >
    <rect width={64} height={38} fill="#016FD0" rx={1} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M12.393 31.034v-9.97h10.613l1.14 1.477L25.32 21H64v9.504s-1.165.52-2.33.53H40.34l-1.283-1.571v1.57H34.85v-2.681s-.575.374-1.817.374H31.6v2.308h-6.37l-1.136-1.508-1.155 1.508zM.001 13.55 2.394 8h4.14l1.357 3.11V8h5.145l.808 2.246L14.628 8h23.096v1.129S38.938 8 40.932 8l7.494.025 1.335 3.069V8h4.305l1.185 1.763V8h4.345v9.969h-4.344l-1.136-1.767v1.768H47.79l-.635-1.572h-1.7l-.626 1.572h-4.29c-1.717 0-2.815-1.108-2.815-1.108v1.108h-6.468l-1.284-1.572v1.572H5.92l-.635-1.572H3.59L2.96 17.97H0z"
      clipRule="evenodd"
    />
    <path
      fill="#016FD0"
      d="M3.24 9.23.014 16.694h2.099l.595-1.496h3.463l.593 1.496H8.91L5.688 9.229zm1.195 1.737 1.055 2.612H3.376z"
    />
    <path
      fill="#016FD0"
      fillRule="evenodd"
      d="M9.135 16.693V9.228l2.986.011 1.737 4.813 1.696-4.824h2.962v7.466H16.64v-5.502l-1.99 5.502h-1.644l-1.994-5.502v5.502zm10.665 0V9.228h6.122v1.67h-4.227v1.277h4.128v1.572h-4.128v1.325h4.227v1.622z"
      clipRule="evenodd"
    />
    <path
      fill="#016FD0"
      d="M27.009 9.23v7.465h1.876v-2.653h.79l2.25 2.653h2.293l-2.468-2.75a2.28 2.28 0 0 0 2.057-2.293c0-1.572-1.24-2.423-2.623-2.423zm1.876 1.669h2.145c.514 0 .889.4.889.786 0 .495-.485.786-.861.786h-2.173z"
    />
    <path
      fill="#016FD0"
      fillRule="evenodd"
      d="M36.49 16.693h-1.917V9.228h1.916zm4.542 0h-.414c-2 0-3.216-1.568-3.216-3.702 0-2.186 1.202-3.763 3.728-3.763h2.075v1.768h-2.15c-1.026 0-1.752.797-1.752 2.013 0 1.446.83 2.053 2.025 2.053h.494z"
      clipRule="evenodd"
    />
    <path
      fill="#016FD0"
      d="m45.114 9.23-3.23 7.465h2.102l.596-1.496h3.463l.593 1.496h2.147L47.56 9.229zm1.194 1.737 1.055 2.612h-2.114z"
    />
    <path
      fill="#016FD0"
      fillRule="evenodd"
      d="M51.004 16.693V9.228h2.387l3.045 4.69v-4.69h1.876v7.466h-2.308L52.88 11.88v4.814zM13.678 29.757v-7.465H19.8v1.67h-4.227v1.277h4.128v1.571h-4.128v1.327H19.8v1.62zm30 0v-7.465H49.8v1.67h-4.226v1.277h4.108v1.571h-4.108v1.327H49.8v1.62zm-23.64 0 2.981-3.686-3.053-3.78h2.364l1.817 2.337 1.825-2.336h2.27l-3.012 3.732 2.987 3.733h-2.363l-1.764-2.299-1.723 2.3z"
      clipRule="evenodd"
    />
    <path
      fill="#016FD0"
      d="M28.44 22.293v7.465h1.926v-2.357h1.975c1.67 0 2.938-.882 2.938-2.597 0-1.42-.994-2.511-2.695-2.511zm1.926 1.689h2.08c.54 0 .925.328.925.859 0 .498-.383.86-.932.86h-2.073v-1.72m5.727-1.69v7.465h1.876v-2.652h.79l2.25 2.652h2.293l-2.468-2.75a2.28 2.28 0 0 0 2.058-2.293c0-1.57-1.24-2.422-2.624-2.422zm1.876 1.67h2.145c.515 0 .889.4.889.786 0 .496-.485.785-.861.785h-2.173z"
    />
    <path
      fill="#016FD0"
      fillRule="evenodd"
      d="M50.669 29.757v-1.62h3.754c.556 0 .797-.299.797-.626 0-.314-.24-.632-.797-.632h-1.697c-1.474 0-2.295-.894-2.295-2.236 0-1.197.752-2.351 2.944-2.351h3.653l-.79 1.68h-3.16c-.604 0-.79.315-.79.616 0 .309.23.651.691.651h1.778c1.644 0 2.358.927 2.358 2.142 0 1.306-.795 2.376-2.448 2.376zm6.886 0v-1.62h3.755c.555 0 .797-.299.797-.626 0-.314-.241-.632-.797-.632h-1.697c-1.475 0-2.296-.894-2.296-2.236 0-1.197.752-2.351 2.944-2.351h3.653l-.79 1.68h-3.16c-.604 0-.79.315-.79.616 0 .309.23.651.691.651h1.778c1.644 0 2.358.927 2.358 2.142 0 1.306-.795 2.376-2.447 2.376z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgAmericanExpressCreditCard;
