const currencyLocaleMap = {
  AED: 'en-US',
  ARS: 'es-AR',
  COP: 'es-CO',
  CLF: 'es-CL',
  MXN: 'es-MX',
  PAB: 'es-PA',
  PEN: 'es-PE',
  THB: 'th-TH',
  VND: 'vn-VI',
  MYR: 'ms-MY',
  IDR: 'in-ID',
  PHP: 'en-PH',
  EUR: 'es-ES',
};

const countryCodeToCurrencyMap = {
  US: 'AED',
  AR: 'ARS',
  CO: 'COP',
  CL: 'CLF',
  MX: 'MXN',
  PA: 'PAB',
  PE: 'PEN',
  TH: 'THB',
  VI: 'VND',
  MY: 'MYR',
  ID: 'IDR',
  PH: 'PHP',
  ES: 'EUR',
};

export function getSymbolByCurrency(currency) {
  const parts = formatAmountToPartsByCurrency(0, currency);

  if (!parts) {
    return '$';
  }

  return parts.find((part) => part.type === 'currency').value;
}
export function getSymbolByCountryCode(countryCode) {
  return getSymbolByCurrency(countryCodeToCurrencyMap[countryCode]);
}

export function formatAmountToPartsByCurrency(amount, currency) {
  const locale = currencyLocaleMap[currency];

  if (!locale) {
    return null;
  }

  let parts = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).formatToParts(amount);

  if (currency === 'CLF') {
    parts = replaceCurrencyPart(parts, 'UF');
  }

  if (currency === 'COP') {
    parts = replaceCurrencyPart(parts, currency);
  }

  return parts;
}

function replaceCurrencyPart(parts, value) {
  const newParts = [...parts];
  const symbolIndex = parts.findIndex(({ type }) => type === 'currency');
  newParts[symbolIndex] = { type: 'currency', value };

  return newParts;
}
