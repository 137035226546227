import styled from 'styled-components';
import { dispatch } from 'reffects';
import { useUnmount } from 'react-use';
import { useSelector } from 'reffects-store';
import RadioButton from 'design-system/components/RadioButton/RadioButton';
import InputTextarea from 'design-system/components/InputTextarea';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Heading2 } from 'design-system/components/Typography/presets/Heading2';
import {
  CHURN_EXTRA_INFO_CHANGED,
  CHURN_REASON_SELECTED,
  CHURN_RESET_STATE,
} from './events';
import FormattedMessageWithValidation from '../../FormattedMessageWithValidation/FormattedMessageWithValidation';
import useInternationalization from '../../../hooks/useInternationalization';
import { churnSelectedReasonSelector } from './selector';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.M)};
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.M)};
  margin-bottom: ${spacing.value(SPACING_SIZE.XL)};
`;

const RadioOption = styled.label`
  display: flex;
  gap: ${spacing.value(SPACING_SIZE.S)};
  align-items: center;
`;

const CHURN_REASON = 'CHURN_REASON';

export default function ChurnForm() {
  const intl = useInternationalization();
  const selectedReason = useSelector(churnSelectedReasonSelector);
  const reasons = [
    {
      name: 'churn_poll_reason_expensive_text',
      value: 'churn_poll_reason_expensive',
    },
    {
      name: 'churn_poll_reason_found_other_text',
      value: 'churn_poll_reason_found_other',
    },
    {
      name: 'churn_poll_reason_not_using_text',
      value: 'churn_poll_reason_not_using',
    },
    {
      name: 'churn_poll_reason_lead_quantity_text',
      value: 'churn_poll_reason_lead_quantity',
    },
    {
      name: 'churn_poll_reason_lead_quality_text',
      value: 'churn_poll_reason_lead_quality',
    },
    {
      name: 'churn_poll_reason_needs_improvements_text',
      value: 'churn_poll_reason_needs_improvements',
    },
    { name: 'churn_poll_reason_none_text', value: 'churn_poll_reason_none' },
  ];

  useUnmount(() => {
    dispatch(CHURN_RESET_STATE);
  });

  return (
    <Wrapper>
      <OptionsWrapper>
        {reasons.map((reason) => (
          <RadioOption key={reason.value}>
            <RadioButton
              defaultChecked={selectedReason === reason.value}
              value={reason.value}
              name={CHURN_REASON}
              onChange={() => {
                dispatch({
                  id: CHURN_REASON_SELECTED,
                  payload: reason.value,
                });
              }}
            />
            <FormattedMessageWithValidation id={reason.name} />
          </RadioOption>
        ))}
      </OptionsWrapper>
      {selectedReason && (
        <>
          <Heading2>
            <FormattedMessageWithValidation id="churn_modal_extra_info" />
          </Heading2>
          <InputTextarea
            placeholder={intl.formatMessageWithValidation({
              id: 'churn_modal_extra_info_placeholder',
            })}
            onChange={(event) => {
              dispatch({
                id: CHURN_EXTRA_INFO_CHANGED,
                payload: event.target.value,
              });
            }}
          />
        </>
      )}
    </Wrapper>
  );
}
