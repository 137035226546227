const OBJECT_FILTER_NOTCH = '{}';
const OBJECT_FILTER_NOTCH_REGEXP = new RegExp(`.+${OBJECT_FILTER_NOTCH}$`);

const mapEntries = (object, fn) =>
  Object.fromEntries(Object.entries(object).map(fn));

const serializeOneEntry = ([name, value]) => {
  const isObject = value instanceof Object;

  if (!isObject) return [name, value];

  return [`${name}${OBJECT_FILTER_NOTCH}`, JSON.stringify(value)];
};

const parseOneEntry = ([name, value]) => {
  const matchObject = OBJECT_FILTER_NOTCH_REGEXP.test(name);

  if (!matchObject) return [name, value];

  return [name.replaceAll(OBJECT_FILTER_NOTCH, ''), JSON.parse(value)];
};

const serialize = (filters) => mapEntries(filters, serializeOneEntry);

const parse = (filters) => mapEntries(filters, parseOneEntry);

export default {
  serialize,
  parse,
};
