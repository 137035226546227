import styled, { css } from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { KpiCell } from './presets';
import Cell from './Cell';

const modClickable = () => css`
  user-select: none;
  cursor: pointer;
`;

const Row = styled.tr`
  &:hover {
    ${Cell} {
      ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
    }

    ${KpiCell} {
      ${color.background(COLOR_PALETTE.PRIMARY_A20)}
    }
  }

  ${({ clickable }) => clickable && modClickable()}
`;

const modDisableHover = () => css`
  &:hover {
    ${color.background(COLOR_PALETTE.NONE)}

    ${KpiCell} {
      ${color.background(COLOR_PALETTE.PRIMARY_A10)}
    }
  }
`;

Row.mods = {
  disableHover: modDisableHover,
};

export default Row;
