import { dispatch } from 'reffects';
import { useCallback } from 'react';
import Dialog from 'design-system/components/Dialog/Dialog';
import { SecondaryButton } from 'design-system/components/Button/presets';
import FormattedMessageWithValidation from '../../FormattedMessageWithValidation/FormattedMessageWithValidation';
import { useDialog } from '../../../hooks/useDialog';

export const GENERIC_ALERT_MODAL = 'GENERIC_ALERT_MODAL';

function DialogContent({ messages, actions = {}, onClose }) {
  const { title, emphasis, body, confirmButton } = messages;

  const { confirm: confirmAction } = actions;

  const handleConfirmClick = useCallback(() => {
    if (confirmAction) {
      if (confirmAction instanceof Function) {
        confirmAction();
      } else {
        dispatch(confirmAction);
      }
    }
    onClose();
  }, [confirmAction]);

  return (
    <>
      <Dialog.Header>
        <FormattedMessageWithValidation id={title.id} values={title.values} />
      </Dialog.Header>
      {emphasis && (
        <Dialog.Emphasis>
          <FormattedMessageWithValidation
            id={emphasis.id}
            values={emphasis.values}
          />
        </Dialog.Emphasis>
      )}
      <Dialog.Content>
        <Dialog.Text>
          <FormattedMessageWithValidation id={body.id} values={body.values} />
        </Dialog.Text>
      </Dialog.Content>
      <Dialog.Footer>
        <SecondaryButton onClick={handleConfirmClick}>
          {confirmButton ? (
            <FormattedMessageWithValidation
              id={confirmButton.id}
              values={confirmButton.values}
            />
          ) : (
            <FormattedMessageWithValidation id="notification_visibility_disable_confirm" />
          )}
        </SecondaryButton>
      </Dialog.Footer>
    </>
  );
}

function AlertDialog() {
  const { open, parameters, closeDialog } = useDialog(GENERIC_ALERT_MODAL);

  return (
    <Dialog open={open}>
      {open && <DialogContent {...parameters} onClose={closeDialog} />}
    </Dialog>
  );
}

export default AlertDialog;
