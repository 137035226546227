import { uniqueId } from 'lodash';
import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { table } from '../../partials/DataTable/effects/table';
import { InMemoryTable } from '../../partials/DataTable/adapters/inMemoryTableAdapter/InMemoryTable';
import { COLLECTION_NAME } from './constants';
import { environment } from '../../coeffects/environment';

export function createProjectsTableAdapter() {
  const inMemoryTable = InMemoryTable.create();
  const eventSuffix = uniqueId(COLLECTION_NAME);

  const UPDATE_ITEMS_TABLE_DATA = `UPDATE_ITEMS_TABLE_DATA_${eventSuffix}`;
  const UPDATE_ITEMS_TABLE_DATA_SUCCESS = `UPDATE_ITEMS_TABLE_DATA_SUCCESS_${eventSuffix}`;

  registerEventHandler(
    UPDATE_ITEMS_TABLE_DATA,
    ({ select, environment: { apiUrl } }, context) => {
      const { startDate, endDate } = select.filters(context);

      return http.get({
        url: `${apiUrl}/project-lead-statistics?startDate=${startDate}&endDate=${endDate}`,
        successEvent: [UPDATE_ITEMS_TABLE_DATA_SUCCESS, context],
      });
    },
    [table.select(), environment()]
  );

  registerEventHandler(
    UPDATE_ITEMS_TABLE_DATA_SUCCESS,
    ({ select }, [response, context]) => {
      const projects = response.data.rows;

      const { visibleRowsIdList, totalRows } = inMemoryTable
        .setCollection(projects)
        .setSorting(select.sorting(context))
        .get(select.pagination(context));

      return table.update(context.collectionName, {
        collection: projects,
        totalItems: totalRows,
        visibleItemsIdList: visibleRowsIdList,
      });
    },
    [table.select()]
  );

  function registerEvents() {
    return {
      collection: UPDATE_ITEMS_TABLE_DATA,
      events: {
        UPDATE_ITEMS_TABLE_DATA,
        UPDATE_ITEMS_TABLE_DATA_SUCCESS,
      },
    };
  }

  return {
    registerEvents,
  };
}
