import styled, { css } from 'styled-components';
import { color } from 'design-system/styles/color';
import { iconography } from 'design-system/tokens/iconography';
import Counter, { COUNTER_VARIANT } from './Counter';

export const ICON_SIZE = {
  XSMALL: Symbol('ICON_SIZE_XSMALL'),
  SMALL: Symbol('ICON_SIZE_SMALL'),
  BASE: Symbol('ICON_SIZE_BASE'),
  LARGE: Symbol('ICON_SIZE_LARGE'),
  XLARGE: Symbol('ICON_SIZE_XLARGE'),
};

const SvgIcon = styled.svg.attrs(({ glyph }) => ({
  viewBox: '0 0 24 24',
  children: <use xlinkHref={`#ic-${glyph}`} />,
}))``;

const IconWithCounter = styled.div`
  position: relative;
  display: inline-block;
  ${SvgIcon} {
    position: relative;
  }
  ${Counter} {
    ${Counter.mods.variant(COUNTER_VARIANT.SECONDARY)}
    position: absolute;
    top: -8px;
    right: -16px;
  }
`;

const modExtraSmallSize = () => css`
  width: ${iconography.size.xsmall}px;
  height: ${iconography.size.xsmall}px;
`;

const modSmallSize = () => css`
  width: ${iconography.size.small}px;
  height: ${iconography.size.small}px;
`;

const modBaseSize = () => css`
  width: ${iconography.size.base}px;
  height: ${iconography.size.base}px;
`;

const modLargeSize = () => css`
  width: ${iconography.size.large}px;
  height: ${iconography.size.large}px;
`;

const modExtraLargeSize = () => css`
  width: ${iconography.size.xlarge}px;
  height: ${iconography.size.xlarge}px;
`;

const modSize = (sizeName) =>
  ({
    [ICON_SIZE.XSMALL]: modExtraSmallSize,
    [ICON_SIZE.SMALL]: modSmallSize,
    [ICON_SIZE.BASE]: modBaseSize,
    [ICON_SIZE.LARGE]: modLargeSize,
    [ICON_SIZE.XLARGE]: modExtraLargeSize,
  }[sizeName]);

const modColor = (colorName) => css`
  ${color.text(colorName)}
`;

function Icon({ counterText, ...props }) {
  if (counterText) {
    return (
      <IconWithCounter>
        <Counter>{counterText}</Counter>
        <SvgIcon {...props} />
      </IconWithCounter>
    );
  }
  return <SvgIcon {...props} />;
}

Icon.mods = {
  size: modSize,
  color: modColor,
};

export default styled(Icon)`
  display: block;
  flex-shrink: 0;

  ${({ $size }) => $size && modSize($size)}
  ${({ $color }) => $color && modColor($color)}
`;
