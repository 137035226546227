import FeatureTable from './FeatureTable/FeatureTable';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  OPERATION_TYPE_RENT,
  OPERATION_TYPE_SELL,
  ownershipTranslationTags,
  tenureInYearsTranslationTags,
} from '../../../../../constants/ad';
import Price from '../../../../../partials/Price/Price';
import Section from './Section';

function Operation({ property }) {
  const { tenureInYears, ownership, operations = [] } = property;
  const sellOperation = operations.find(
    ({ type }) => type === OPERATION_TYPE_SELL
  );
  const rentOperation = operations.find(
    ({ type }) => type === OPERATION_TYPE_RENT
  );

  if (
    !sellOperation?.price?.amount &&
    !rentOperation?.price?.amount &&
    !tenureInYears &&
    !ownership
  )
    return null;

  return (
    <Section highlight sticky>
      <FeatureTable>
        {sellOperation?.price?.amount && (
          <FeatureTable.Cell
            title={
              <FormattedMessageWithValidation id="newprop_operation_sell" />
            }
          >
            <Price
              amount={sellOperation.price.amount}
              currency={sellOperation.price.currency}
            />
          </FeatureTable.Cell>
        )}
        {ownership && (
          <FeatureTable.Cell
            title={
              <FormattedMessageWithValidation id="newprop_ownership_header" />
            }
          >
            <FormattedMessageWithValidation
              id={ownershipTranslationTags.get(ownership)}
            />
          </FeatureTable.Cell>
        )}
        {rentOperation?.price?.amount && (
          <FeatureTable.Cell
            title={
              <FormattedMessageWithValidation id="newprop_operation_rent" />
            }
          >
            <Price
              amount={rentOperation.price.amount}
              currency={rentOperation.price.currency}
            />
          </FeatureTable.Cell>
        )}
        {tenureInYears && (
          <FeatureTable.Cell
            title={
              <FormattedMessageWithValidation id="newprop_duration_header" />
            }
          >
            <FormattedMessageWithValidation
              id={tenureInYearsTranslationTags.get(tenureInYears)}
            />
          </FeatureTable.Cell>
        )}
      </FeatureTable>
    </Section>
  );
}

export default Operation;
