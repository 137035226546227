import * as React from "react";
const SvgFreePublisherBanner = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={96}
    height={95}
    fill="none"
    {...props}
  >
    <rect
      width={67.175}
      height={67.175}
      x={48}
      fill="#E7FAF3"
      rx={6}
      transform="rotate(45 48 0)"
    />
    <rect
      width={11.667}
      height={11.667}
      x={14.25}
      y={2}
      fill="#E7FAF3"
      rx={2}
      transform="rotate(45 14.25 2)"
    />
    <rect
      width={7.601}
      height={7.601}
      x={74.75}
      y={7.5}
      fill="#E7FAF3"
      rx={2}
      transform="rotate(45 74.75 7.5)"
    />
    <rect
      width={5.48}
      height={5.48}
      x={24.75}
      y={80.5}
      fill="#E7FAF3"
      rx={1}
      transform="rotate(45 24.75 80.5)"
    />
    <path fill="#E7FAF3" d="m63.414 84 2.207 2.207-2.207 2.207-2.207-2.207z" />
    <path
      fill="#fff"
      stroke="#0ACC86"
      d="M11.5 23a1.5 1.5 0 0 1 1.5-1.5h68a1.5 1.5 0 0 1 1.5 1.5v45.5h-71z"
    />
    <rect
      width={57}
      height={10}
      x={18.5}
      y={28.5}
      fill="#CEF5E7"
      stroke="#0ACC86"
      rx={1.5}
    />
    <rect
      width={11}
      height={8}
      x={21.5}
      y={33.5}
      fill="#9DEBCF"
      stroke="#0ACC86"
      rx={1.5}
    />
    <path stroke="#0ACC86" d="M14.5 24.5h65v41h-65z" />
    <path
      fill="#fff"
      stroke="#0ACC86"
      strokeLinecap="round"
      d="M95.5 69v3a.5.5 0 0 1-.5.5H1a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5h94a.5.5 0 0 1 .5.5Z"
    />
    <rect width={37} height={1} x={19} y={45} fill="#0ACC86" rx={0.5} />
    <rect width={15} height={1} x={19} y={50} fill="#0ACC86" rx={0.5} />
    <rect width={15} height={1} x={41} y={50} fill="#0ACC86" rx={0.5} />
    <rect width={15} height={1} x={61} y={45} fill="#0ACC86" rx={0.5} />
    <rect width={11} height={1} x={65} y={50} fill="#0ACC86" rx={0.5} />
    <rect width={11} height={1} x={65} y={55} fill="#0ACC86" rx={0.5} />
    <rect width={37} height={1} x={19} y={55} fill="#0ACC86" rx={0.5} />
    <rect width={37} height={1} x={19} y={60} fill="#0ACC86" rx={0.5} />
  </svg>
);
export default SvgFreePublisherBanner;
