import { useSelector } from 'reffects-store';
import InputRow from 'design-system/components/InputRow';
import SingleChoiceField from '../../../partials/fields/SingleChoiceField';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { propertyTypesWithTranslationTagsSelector } from '../../../selectors/country';

export default function PropertyTypeSection({ choiceLabel }) {
  const propertyTypesOptions = useSelector(
    propertyTypesWithTranslationTagsSelector
  );

  return (
    <InputRow>
      <SingleChoiceField
        required
        label={choiceLabel}
        name="propertyType"
        choices={Object.entries(propertyTypesOptions).map(([name, tag]) => ({
          label: <FormattedMessageWithValidation id={tag} />,
          value: name,
        }))}
      />
    </InputRow>
  );
}
