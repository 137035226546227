import { css, keyframes } from 'styled-components';

export const ANIMATION = {
  SPIN_CLOCKWISE: 'spinClockwise',
  ALTERNATE_FADE: 'alternateFade',
};

const spinClockwise = ({ duration = 1000 } = {}) => css`
  animation: ${rotate({ from: 0, to: 360 })} ${duration}ms linear infinite;
`;

const alteranteFade = ({ duration = 1000, from = 1, to = 0 } = {}) => css`
  animation: ${fadeInAndOut({ from, to })} ${duration}ms linear infinite;
`;

const animate = (animationName) =>
  ({
    [ANIMATION.SPIN_CLOCKWISE]: spinClockwise,
    [ANIMATION.ALTERNATE_FADE]: alteranteFade,
  }[animationName]);

export const animation = {
  spinClockwise,
  alteranteFade,
  animate,
};

function rotate({ from, to }) {
  return keyframes`
      from {
        transform: rotate(${from}deg);
      }
      to {
        transform: rotate(${to}deg);
      }
    `;
}

function fadeInAndOut({ from, to }) {
  return keyframes`
      0%   { opacity:${from}; }
      50%  { opacity:${to}; }
      100% { opacity:${from}; }
    `;
}
