import styled from 'styled-components';
import { FormattedNumber } from 'react-intl';
import Table from 'design-system/components/Table';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { useColumn } from '../../../partials/DataTable/hooks/useColumn';

const KpiCell = styled(Table.CellLink)`
  ${color.background(COLOR_PALETTE.PRIMARY_A10)}

  & > a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export function KpiColumn(props) {
  const { value: kpi } = useColumn(props);

  return (
    <KpiCell>
      <Body1 $noWrap>
        <FormattedNumber value={kpi} />
      </Body1>
    </KpiCell>
  );
}
