import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { CREATE_PUBLISHER_PAGE } from '../constants';

export const DEVELOPER_PROJECT_SELECTED = 'DEVELOPER_PROJECT_SELECTED';
export const DEVELOPER_PROJECT_REMOVED = 'DEVELOPER_PROJECT_REMOVED';

registerEventHandler(DEVELOPER_PROJECT_SELECTED, (_, { project }) =>
  state.set({
    [`${CREATE_PUBLISHER_PAGE}.selectedProjects.${project.id}`]: project,
  })
);

registerEventHandler(
  DEVELOPER_PROJECT_REMOVED,
  ({ state: { selectedProjects } }, projectId) => {
    const selectedProjectsWithoutRemoved = Object.fromEntries(
      Object.entries(selectedProjects).filter(([key]) => key !== projectId)
    );

    return state.set({
      [`${CREATE_PUBLISHER_PAGE}.selectedProjects`]:
        selectedProjectsWithoutRemoved,
    });
  },
  [
    state.get({
      selectedProjects: `${CREATE_PUBLISHER_PAGE}.selectedProjects`,
    }),
  ]
);
