export function isCreateUnitFormInitializedSelector(state) {
  return (
    state['adForm:isInitialized'] &&
    state['adForm:projectNameSuggestions']?.length > 0
  );
}

export function initialProjectIdSelector(state) {
  return state['adForm:initialProjectId'];
}
