import { useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';

export default function Portal({ children, parent }) {
  if (typeof window === 'undefined') {
    return null;
  }

  const el = useMemo(() => document.createElement('div'), []);
  useEffect(() => {
    const target = parent && parent.appendChild ? parent : document.body;
    target.appendChild(el);

    return () => target.removeChild(el);
  }, [el, parent]);

  return ReactDOM.createPortal(children, el);
}
