import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { withStylesAndInnerRef } from 'design-system/utils';

const Content = styled.div`
  display: flex;
  align-items: center;
  max-width: ${({ maxWidth }) => maxWidth}px;
  ${spacing.inset(SPACING_SIZE.M)};
`;

const Link = styled.a`
  display: flex;
  width: 100%;
  height: 100%;
  text-decoration: none;
`;

function CellLink({
  Component,
  className,
  style,
  children,
  forwardedAs,
  maxWidth,
  innerRef,
  ...rest
}) {
  return (
    <td
      className={className}
      ref={innerRef}
      style={{
        ...style,
        maxWidth: `${maxWidth}px`,
      }}
    >
      <Link as={forwardedAs} {...rest}>
        <Content maxWidth={maxWidth}>{children}</Content>
      </Link>
    </td>
  );
}

export default withStylesAndInnerRef(CellLink);
