import { dispatch } from 'reffects';
import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import GlobalizaLogo from 'design-system/components/GlobalizaLogo';
import Popover from 'design-system/components/Popover';
import Dialog from 'design-system/components/Dialog/Dialog';
import Checkbox from 'design-system/components/Checkbox/Checkbox';
import Anchor from 'design-system/components/Anchor';
import { SecondaryButton } from 'design-system/components/Button/presets';
import ScrollableArea from 'design-system/components/ScrollableArea/ScrollableArea';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { COLOR_SEMANTIC } from 'design-system/styles/color';
import Typography from 'design-system/components/Typography/Typography';
import {
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { useDialog } from '../../hooks/useDialog';
import MarkdownDocument from '../MarkdownDocument';
import FormattedMessageWithValidation from '../FormattedMessageWithValidation/FormattedMessageWithValidation';
import { isAllowedToCloseTCDialogSelector } from './selectors';
import { TERMS_AND_CONDITIONS_DIALOG } from './constants';
import { BrandLogo } from '../BrandLogo';
import { legalLinksSelector } from '../../selectors/config';

import {
  ACCEPTING_TERMS_AND_CONDITIONS,
  TERMS_AND_CONDITIONS_CHECKBOX_CHANGED,
} from './events';

const Wrapper = styled(Dialog)`
  @media (max-height: 770px) {
    display: flex;
    align-items: center;

    ${Popover} {
      top: 0;
      max-height: calc(100vh - 80px);
      position: initial;
      margin: 0 auto;
    }

    ${ScrollableArea} {
      > div {
        overflow-y: unset;
      }
    }
  }

  ${Popover} {
    display: flex;
    flex-direction: column;
  }

  ${GlobalizaLogo} {
    margin: ${spacing.value(SPACING_SIZE.S)} 0;
  }
`;

const Content = styled(Dialog.Content)`
  margin-bottom: ${spacing.value(SPACING_SIZE.XXL)};

  h1 {
    margin-top: ${spacing.value(SPACING_SIZE.L)};
  }
`;

const Footer = styled(Dialog.Footer)`
  text-align: center;
  border-top: 1px solid ${COLOR_SEMANTIC.DIVIDER};
`;

const Checks = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  ${spacing.stack(SPACING_SIZE.L)};

  ${Checkbox} {
    margin-right: ${SPACING_SIZE.M}px;
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;

  ${spacing.stack(SPACING_SIZE.S)};
  ${Typography.mods.weight(FONT_WEIGHT.LIGHT)};
`;

const Copy = styled(Body1)`
  ${Typography.mods.size(FONT_SIZE.S)};

  & ${Anchor} {
    text-decoration: underline;
    ${typography.size(FONT_SIZE.S)};
  }
`;

export default function TermsAndConditionsDialog() {
  const isAllowedToCloseTCDialog = useSelector(
    isAllowedToCloseTCDialogSelector
  );
  const { open, closeDialog } = useDialog(TERMS_AND_CONDITIONS_DIALOG);
  const { termsAndConditions, privacyPolicy, cookiesPolicy } =
    useSelector(legalLinksSelector);

  const submitAgreement = () => {
    dispatch(ACCEPTING_TERMS_AND_CONDITIONS);
    closeDialog();
  };

  return (
    <Wrapper open={open} logo={<BrandLogo />}>
      <ScrollableArea maxHeight={430}>
        <Content>
          <MarkdownDocument
            id="termsAndConditions"
            path="/documents/termsAndConditions"
          />
        </Content>
      </ScrollableArea>
      <Footer>
        <Checks>
          <Label>
            <Checkbox name="acceptPolicies" onChange={onCheckBoxChange} />
            <Copy>
              <FormattedMessageWithValidation
                id="termsandconds_popup_accept_terms"
                values={{
                  termsAndConditions: (
                    <a
                      href={termsAndConditions}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FormattedMessageWithValidation id="termsandconds_footer_tandco" />
                    </a>
                  ),
                  privacyPolicy: (
                    <a href={privacyPolicy} target="_blank" rel="noreferrer">
                      <FormattedMessageWithValidation id="termsandconds_footer_privacy" />
                    </a>
                  ),
                }}
              />
            </Copy>
          </Label>
          <Label>
            <Checkbox name="acceptCookies" onChange={onCheckBoxChange} />
            <Copy>
              <FormattedMessageWithValidation
                id="termsandconds_popup_accept_cookies"
                values={{
                  cookiePolicy: (
                    <a href={cookiesPolicy} target="_blank" rel="noreferrer">
                      <FormattedMessageWithValidation id="termsandconds_footer_cookies" />
                    </a>
                  ),
                }}
              />
            </Copy>
          </Label>
        </Checks>
        <SecondaryButton
          onClick={submitAgreement}
          disabled={!isAllowedToCloseTCDialog}
        >
          <FormattedMessageWithValidation id="termsandconds_cta_agree" />
        </SecondaryButton>
      </Footer>
    </Wrapper>
  );
}

function onCheckBoxChange(event) {
  dispatch({
    id: TERMS_AND_CONDITIONS_CHECKBOX_CHANGED,
    payload: {
      field: event.target.name,
      value: event.target.checked,
    },
  });
}
