import styled, { css } from 'styled-components';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import {
  noop,
  withoutEventPropagation,
  withStylesAndInnerRef,
} from '../../../utils';

export const MENU_ITEM_ICON_POSITION = {
  START: Symbol('MENU_ITEM_ICON_POSITION_START'),
  END: Symbol('MENU_ITEM_ICON_POSITION_END'),
};

const modPositionStart = () => css`
  flex-direction: row;
  ${Icon} {
    ${spacing.inline(SPACING_SIZE.S)};
  }
`;

const modPositionEnd = () => css`
  flex-direction: row-reverse;
  justify-content: flex-end;
  ${Icon} {
    margin-left: ${spacing.value(SPACING_SIZE.S)};
  }
`;

const modIconPosition = (iconPosition) =>
  ({
    [MENU_ITEM_ICON_POSITION.START]: modPositionStart(),
    [MENU_ITEM_ICON_POSITION.END]: modPositionEnd(),
  }[iconPosition]);

/**
 * - {0} As agreed with the design team, this component has custom spacing to preserve visual correctness.
 */
const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  text-decoration: none;
  ${spacing.insetSquish(SPACING_SIZE.M)}
  ${color.background(COLOR_PALETTE.NONE)}
  ${({ $iconPosition }) => modIconPosition($iconPosition)}

  ${Icon} {
    ${Icon.mods.size(ICON_SIZE.BASE)}
  }

  &:hover {
    ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
  }

  &:active {
    ${color.background(COLOR_PALETTE.NEUTRAL_A05)}
  }

  &[data-disabled] {
    cursor: not-allowed;
    ${color.text(COLOR_PALETTE.NEUTRAL_A20)}
    ${Body1} {
      ${color.text(COLOR_PALETTE.NEUTRAL_A20)}
    }
    ${Icon} {
      ${Icon.mods.size(ICON_SIZE.BASE)}
    }
  }
`;

const modSelected = () => css`
  ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
  ${spacing.inline(SPACING_SIZE.S)};
`;

const NoIconWidth = styled.div`
  width: 0;
  ${spacing.inline(SPACING_SIZE.NONE)};
`;

function BaseItem({
  children,
  icon,
  innerRef,
  disabled,
  onClick,
  iconPosition = MENU_ITEM_ICON_POSITION.START,
  ...rest
}) {
  return (
    <Wrapper
      {...rest}
      $iconPosition={iconPosition}
      ref={innerRef}
      onClick={withoutEventPropagation(disabled ? noop : onClick)}
      data-disabled={disabled || undefined}
      disabled={disabled}
    >
      {icon || (
        <NoIconWidth>
          <Icon glyph="noIcon" />
        </NoIconWidth>
      )}
      {children}
    </Wrapper>
  );
}

BaseItem.mods = {
  selected: modSelected,
  iconPosition: modIconPosition,
};

export default withStylesAndInnerRef(BaseItem);
