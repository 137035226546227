import { dispatch } from 'reffects';
import { useMount } from 'react-use';
import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import Layout from 'design-system/components/Layout';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Heading2 } from 'design-system/components/Typography/presets/Heading2';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { Subtitle1 } from 'design-system/components/Typography/presets/Subtitle1';
import { Navigation } from '../../partials';
import Divider from './partials/Divider';
import { InvalidPropertiesTable } from './partials/InvalidPropertiesTable/InvalidPropertiesTable';
import { XML_IMPORT_STATS_SUMMARY_REQUESTED } from '../../events';
import Kpis from './partials/Kpis/Kpis';
import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { lastImportDateSelector } from '../../selectors/xmlStats';
import CustomFormattedDateTime from '../../partials/FormattedDate/CustomFormattedDateTime';
import DetailsDialog from './partials/InvalidPropertiesTable/DetailsDialog';

const Title = styled.div`
  ${spacing.stack(SPACING_SIZE.M)}
  display: flex;
  flex-direction: column;
`;

function ImportStats() {
  useMount(() => dispatch(XML_IMPORT_STATS_SUMMARY_REQUESTED));
  const date = useSelector(lastImportDateSelector);
  return (
    <Layout>
      <Layout.Header>
        <Navigation />
      </Layout.Header>
      <Layout.Content>
        <Layout.Padded>
          <Title>
            <Heading2 $color={COLOR_PALETTE.SECONDARY_BASE}>
              <FormattedMessageWithValidation id="import_stats_title" />
            </Heading2>
            <Subtitle1 $color={COLOR_PALETTE.NEUTRAL_A60}>
              <FormattedMessageWithValidation id="import_stats_errors_updated" />{' '}
              <CustomFormattedDateTime value={new Date(date)} />
            </Subtitle1>
          </Title>
          <Kpis />
        </Layout.Padded>
        <Divider />
        <Layout.Padded>
          <InvalidPropertiesTable />
        </Layout.Padded>
      </Layout.Content>
      <DetailsDialog />
    </Layout>
  );
}

export default ImportStats;
