import styled, { css } from 'styled-components';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { animation } from 'design-system/styles/animation';
import { radius } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import Typography from 'design-system/components/Typography/Typography';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';

const CONNECTION_BAR_SIZE = 10;
const BULLET_SIZE = 32;

export const TASKS_STEPPER_ITEM_STATUS = {
  DONE: Symbol('STEPPER_ITEM_STATUS_COMPLETED'),
  PENDING: Symbol('STEPPER_ITEM_STATUS_ERROR'),
  CURRENT: Symbol('STEPPER_ITEM_STATUS_CURRENT'),
  FAILED: Symbol('STEPPER_ITEM_STATUS_FAILED'),
};

const Wrapper = styled.ol`
  display: inline-flex;
  list-style-type: none;

  margin: 0;
  padding: 0;
`;

const ItemBullet = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${BULLET_SIZE}px;
  min-width: ${BULLET_SIZE}px;
  ${radius.pill()}
  ${spacing.inline(SPACING_SIZE.M)}
  
  ${Icon} {
    ${Icon.mods.size(ICON_SIZE.SMALL)}
  }
`;

const ItemBulletShadow = styled.div`
  position: absolute;
  content: '';
  top: -4px;
  left: -8px;
  width: 48px;
  height: 48px;
  ${color.background(COLOR_PALETTE.PRIMARY_BASE)}
  ${radius.pill()}
  opacity: 0;
`;

const TextWrapper = styled.div`
  padding-top: ${spacing.value(10)};
  display: flex;
  flex-direction: column;
`;

const Title = styled(Typography)`
  ${typography.size(16)}
  ${typography.weight(FONT_WEIGHT.BOLD)}
  ${color.text(COLOR_PALETTE.PRIMARY_A80)}
`;

const Subtitle = styled(Subtitle3)`
  ${typography.weight(FONT_WEIGHT.MEDIUM)}
  ${color.text(COLOR_PALETTE.NEUTRAL_A40)}
`;

const modDoneStatus = () => css`
  ${ItemBullet} {
    ${color.background(COLOR_PALETTE.PRIMARY_A60)}
    ${Icon} {
      ${color.text(COLOR_PALETTE.PRIMARY_A140)}
    }
    ::before {
      content: initial;
    }
  }
`;

const modPendingStatus = () => css`
  ${ItemBullet} {
    ${color.background(COLOR_PALETTE.NEUTRAL_A10)}
    ${Icon} {
      ${color.text(COLOR_PALETTE.PRIMARY_A140)}
    }
    ::before {
      content: initial;
    }
  }

  ${Title} {
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
  }

  ::before {
    ${color.background(COLOR_PALETTE.NEUTRAL_A10)}
  }
`;

const modFailedStatus = () => css`
  ${ItemBullet} {
    ${color.background(COLOR_PALETTE.NEUTRAL_A10)}
    ${Icon} {
      ${color.text(COLOR_PALETTE.PUMPKIN_BASE)}
    }
    ::before {
      content: initial;
    }
  }

  ${Title} {
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
  }

  ::before {
    ${color.background(COLOR_PALETTE.NEUTRAL_A10)}
  }
`;

const modCurrentStatus = () => css`
  ${ItemBullet} {
    position: relative;
    ${color.background(COLOR_PALETTE.PRIMARY_A60)}
    ${Icon} {
      ${animation.spinClockwise()}
      ${color.text(COLOR_PALETTE.PRIMARY_A140)}
    }
  }

  ${ItemBulletShadow} {
    ${animation.alteranteFade({ duration: 1500, from: 0.1, to: 0 })}
  }

  :last-of-type::after {
    content: initial;
  }

  ::after {
    content: '';
    width: 7px;
    height: ${CONNECTION_BAR_SIZE / 2}px;
    position: absolute;
    left: ${spacing.value(SPACING_SIZE.M)};
    top: 0;
    transform: translate(-50%, 35px);
    z-index: 2;

    ${color.background(COLOR_PALETTE.PRIMARY_A60)}
  }
`;

const modStatus = (statusName) =>
  ({
    [TASKS_STEPPER_ITEM_STATUS.DONE]: modDoneStatus,
    [TASKS_STEPPER_ITEM_STATUS.PENDING]: modPendingStatus,
    [TASKS_STEPPER_ITEM_STATUS.CURRENT]: modCurrentStatus,
    [TASKS_STEPPER_ITEM_STATUS.FAILED]: modFailedStatus,
  }[statusName]);

const ItemWrapper = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 40px;

  :last-of-type::before {
    content: initial;
  }

  ::before {
    content: '';
    width: 7px;
    height: ${CONNECTION_BAR_SIZE}px;
    position: absolute;
    left: ${spacing.value(SPACING_SIZE.M)};
    top: 35px;
    transform: translate(-50%, 0);
    z-index: 1;

    ${color.background(COLOR_PALETTE.PRIMARY_A60)}
  }

  ${({ status }) => status && modStatus(status)}
`;

const iconByStatusMap = new Map([
  [TASKS_STEPPER_ITEM_STATUS.DONE, <Icon glyph="check" />],
  [TASKS_STEPPER_ITEM_STATUS.CURRENT, <Icon glyph="refresh" />],
  [TASKS_STEPPER_ITEM_STATUS.PENDING, <Icon glyph="moreHor" />],
  [TASKS_STEPPER_ITEM_STATUS.FAILED, <Icon glyph="error" />],
]);

function Item({ children, ...rest }) {
  const { status, title, subtitle } = rest;
  return (
    <ItemWrapper {...rest}>
      <ItemBulletShadow />
      <ItemBullet>{iconByStatusMap.get(status)}</ItemBullet>
      <TextWrapper>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </TextWrapper>
    </ItemWrapper>
  );
}

function TasksStepper({ children, ...rest }) {
  return (
    <Wrapper {...rest}>
      <Subtitle3>{children}</Subtitle3>
    </Wrapper>
  );
}

TasksStepper.Item = Item;

export default styled(TasksStepper)``;
