import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import styled from 'styled-components';
import Banner from 'design-system/components/Banner';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Wrapper = styled.div`
  ${Banner} {
    margin-top: ${spacing.value(SPACING_SIZE.M)};
    text-align: justify;
    ${radius.regular(RADIUS_SIZE.LARGE)}
    ${spacing.inset(SPACING_SIZE.M)}
    > span {
      ${color.text(COLOR_PALETTE.NEUTRAL_A80)}
    }
  }
`;
function StatsIssuesDisclaimer() {
  return (
    <Wrapper>
      <Banner>
        <FormattedMessageWithValidation id="overview_stats_disclaimer_message" />
      </Banner>
    </Wrapper>
  );
}

export default StatsIssuesDisclaimer;
