import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import Anchor from 'design-system/components/Anchor';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import { typography } from 'design-system/styles/typography';
import { activeSubscriptionSelector } from '../../../selectors/publisher';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { SUBSCRIPTION_BENEFITS } from '../../../constants/routes';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${color.background(COLOR_PALETTE.PRIMARY_A05)}
  ${radius.regular(RADIUS_SIZE.BASE)}
  ${spacing.inset(SPACING_SIZE.M)}
  
  ${Subtitle3} {
    ${spacing.stack(SPACING_SIZE.S, false)}
  }

  ${Heading1} {
    ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
    ${spacing.stack(SPACING_SIZE.L, false)}
    ${typography.lineHeight(31)}
  }
`;

function CurrentPlanBanner(props) {
  const subscription = useSelector(activeSubscriptionSelector);
  if (!subscription) return null;
  return (
    <Wrapper {...props}>
      <Subtitle3 $color={COLOR_PALETTE.NEUTRAL_A60}>
        <FormattedMessageWithValidation id="subscription_current_plan" />
      </Subtitle3>
      <Heading1>
        <FormattedMessageWithValidation id={subscription.nameTag} />
      </Heading1>
      <Anchor href={SUBSCRIPTION_BENEFITS}>
        <FormattedMessageWithValidation id="subscription_benefits" />
      </Anchor>
    </Wrapper>
  );
}

export default CurrentPlanBanner;
