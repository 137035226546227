import { useEffect, useRef, useState } from 'react';
import { useIntersection } from 'react-use';

export function useInViewportWatcher({ visibilityThreshold = 0.3 } = {}) {
  const [isInViewport, setIsInViewport] = useState(false);
  const watcherRef = useRef(null);
  const watcherIntersection = useIntersection(watcherRef, {
    root: null,
    rootMargin: '0px',
    threshold: visibilityThreshold,
  });

  useEffect(() => {
    setIsInViewport(!watcherIntersection?.isIntersecting);
  }, [watcherIntersection]);

  return {
    isInViewport,
    watcherRef,
  };
}
