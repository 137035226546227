import * as React from "react";
const SvgAvatar = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <mask
      id="Avatar_svg__a"
      width={32}
      height={32}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <circle cx={16} cy={16} r={16} fill="#F7F7F7" />
    </mask>
    <g mask="url(#Avatar_svg__a)">
      <circle cx={16} cy={16} r={16} fill="#E6E6E6" />
      <path
        fill="#CCC"
        d="M16 20c3.315 0 6-2.685 6-6s-2.685-6-6-6-6 2.685-6 6 2.685 6 6 6m0 3c-4.005 0-12 2.01-12 6v1.5c0 .825.675 1.5 1.5 1.5h21c.825 0 1.5-.675 1.5-1.5V29c0-3.99-7.995-6-12-6"
      />
    </g>
  </svg>
);
export default SvgAvatar;
