import styled from 'styled-components';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import { COLOR_PALETTE } from 'design-system/styles/color';
import BaseRow from './BaseRow';

function Row({ title, text, ...rest }) {
  return (
    <BaseRow
      {...rest}
      leftChild={<Subtitle3>{title}</Subtitle3>}
      rightChild={
        <Body1
          $weight={FONT_WEIGHT.LIGHT}
          $color={COLOR_PALETTE.SECONDARY_BASE}
        >
          {text}
        </Body1>
      }
    />
  );
}

export default styled(Row)``;
