import { subscribe } from 'reffects-store';
import InputRow from 'design-system/components/InputRow';
import SingleChoiceField from '../../../partials/fields/SingleChoiceField';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { operationTypeOptionsSelector } from '../selectors';

function OperationTypeSection({ operationTypeOptions }) {
  return (
    <InputRow>
      <SingleChoiceField
        required
        label={<FormattedMessageWithValidation id="newprop_operation_type" />}
        name="operationType"
        choices={operationTypeOptions.map(
          ({ operationType: value, caption }) => ({
            label: <FormattedMessageWithValidation id={caption} />,
            value,
          })
        )}
      />
    </InputRow>
  );
}

export default subscribe(OperationTypeSection, (state) => ({
  operationTypeOptions: operationTypeOptionsSelector(state),
}));
