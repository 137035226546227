import { createContext, useContext } from 'react';

export const ROUTE_TYPE = {
  PRIVATE: 'private',
  PUBLIC: 'public',
  BACKOFFICE: 'backoffice',
};

const RouteContext = createContext({
  routeType: ROUTE_TYPE.PRIVATE,
});

export function RouteProvider({ routeType, children }) {
  return (
    <RouteContext.Provider value={{ routeType }}>
      {children}
    </RouteContext.Provider>
  );
}

export function useRouteType() {
  const { routeType } = useContext(RouteContext);

  return routeType ?? ROUTE_TYPE.PRIVATE;
}

export function useIsPublicRoute() {
  const routeType = useRouteType();

  return routeType === ROUTE_TYPE.PUBLIC;
}
