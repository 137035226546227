import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { environment } from '../../../../../../../coeffects/environment';
import { toast } from '../../../../../../../effects/toast';
import { CLOSE_DIALOG } from '../../../../../../../events/dialogs';

export const MOVE_TO_FREE = 'MOVE_TO_FREE';
export const MOVE_TO_FREE_SUCCESS = 'MOVE_TO_FREE_SUCCESS';
export const MOVE_TO_FREE_ERROR = 'MOVE_TO_FREE_ERROR';

registerEventHandler(
  MOVE_TO_FREE,
  ({ environment: { apiUrl } }, { publisherId, reason, updateTableEvent }) =>
    http.put({
      url: `${apiUrl}/backoffice/publishers/${publisherId}/move-to-free`,
      body: { reason },
      successEvent: { id: MOVE_TO_FREE_SUCCESS, payload: { updateTableEvent } },
      errorEvent: { id: MOVE_TO_FREE_ERROR, payload: { updateTableEvent } },
    }),
  [environment()]
);

registerEventHandler(MOVE_TO_FREE_SUCCESS, (_, [__, { updateTableEvent }]) => ({
  ...effects.dispatchMany([CLOSE_DIALOG, updateTableEvent]),
  ...toast.show({ text: 'Publisher moved to free' }),
}));

registerEventHandler(MOVE_TO_FREE_ERROR, (_, [__, { updateTableEvent }]) => ({
  ...effects.dispatchMany([CLOSE_DIALOG, updateTableEvent]),
  ...toast.show({ text: 'Failed to move publisher to free' }),
}));
