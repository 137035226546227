import { css } from 'styled-components';
import { COLOR_PALETTE } from './color';

export const scrollbar = css`
  scrollbar-width: auto;
  scrollbar-color: #ababab ${COLOR_PALETTE.NEUTRAL_A00};

  ::-webkit-scrollbar-thumb {
    background: #ababab;
    height: auto;
    border-radius: 40px;
    min-height: 60px;
    margin: 2px;
    border: solid 4px ${COLOR_PALETTE.NEUTRAL_A00};
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: var(--scrollbar-width);
  }

  &:hover {
    scrollbar-color: #ababab ${COLOR_PALETTE.NEUTRAL_A10};

    ::-webkit-scrollbar-track {
      background-color: ${COLOR_PALETTE.NEUTRAL_A10};
    }
    ::-webkit-scrollbar-thumb {
      border-color: ${COLOR_PALETTE.NEUTRAL_A10};
    }
  }
`;
