import { effects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { mapKeys } from 'lodash';
import { http } from 'reffects-batteries';
import { environment } from '../../coeffects/environment';

export const PUBLISHER_PATCH_ATTRIBUTE = 'PUBLISHER_PATCH_ATTRIBUTE';
export const PUBLISHER_PATCH_ATTRIBUTE_SUCCESS =
  'PUBLISHER_PATCH_ATTRIBUTE_SUCCESS';
export const PUBLISHER_CURRENT_LOCALE_SELECTED =
  'PUBLISHER_CURRENT_LOCALE_SELECTED';
export const INIT_PUBLISHER_DEFAULT_SELECTED_LOCALE =
  'INIT_PUBLISHER_DEFAULT_SELECTED_LOCALE';

const publisher = {
  patch: (attributes) =>
    state.set(mapKeys(attributes, (_, key) => `publisher.${key}`)),
};

registerEventHandler(
  PUBLISHER_PATCH_ATTRIBUTE,
  ({ environment: { apiUrl } }, payload) =>
    http.patch({
      url: `${apiUrl}/company-profile`,
      body: payload.details,
      successEvent: {
        id: PUBLISHER_PATCH_ATTRIBUTE_SUCCESS,
        payload,
      },
    }),
  [environment()]
);

registerEventHandler(
  PUBLISHER_PATCH_ATTRIBUTE_SUCCESS,
  (_, [, { details, successEvent }]) => ({
    ...publisher.patch(details),
    ...(successEvent ? effects.dispatch(successEvent) : {}),
  })
);

registerEventHandler(PUBLISHER_CURRENT_LOCALE_SELECTED, (_, { locale }) =>
  state.set({
    'publisherProfile.currentLocale': locale,
  })
);

registerEventHandler(
  INIT_PUBLISHER_DEFAULT_SELECTED_LOCALE,
  ({ state: { userLocale } }) =>
    state.set({
      'publisherProfile.currentLocale': userLocale,
    }),
  [state.get({ userLocale: 'locale' })]
);
