import { useSelector } from 'reffects-store';
import Interstitial from '../../pages/Interstitial/Interstitial';
import { showInterstitialSelector } from '../selectors';
import TrackPageView from '../../partials/Tracking/TrackPageView';

export default function RouteWithInterstitial({ children, disable }) {
  const showInterstitial = useSelector(showInterstitialSelector);

  if (showInterstitial && !disable) {
    return (
      <TrackPageView pageViewName="interstitial">
        <Interstitial />
      </TrackPageView>
    );
  }

  return children;
}
