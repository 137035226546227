import styled from 'styled-components';
import Table from 'design-system/components/Table';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';

const FullWithCell = styled(Table.Cell)`
  ${spacing.inset(SPACING_SIZE.M)}
`;

function EmptyRow({ children, colSpan }) {
  return (
    <Table.Row>
      <FullWithCell colSpan={colSpan}>
        <Body1>{children}</Body1>
      </FullWithCell>
    </Table.Row>
  );
}

export default styled(EmptyRow)``;
