import * as React from "react";
const SvgLamudiLogoColored = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 62 24"
    {...props}
  >
    <g clipPath="url(#LamudiLogoColored_svg__a)">
      <path
        fill="#FF8700"
        d="M3.443 12.303c.338-.28.644-.529.977-.803v4.435C1.59 14.87-.401 11.622.069 8.273c.56-3.981 4.16-6.695 8.047-6.22 3.966.486 6.369 3.85 6.38 7.145-.112-.074-.207-.127-.291-.19-1.7-1.273-3.406-2.546-5.101-3.824-.29-.216-.285-.221-.55.032q-.917.887-1.842 1.774l-.227.206c0-.412-.006-.781.005-1.146.005-.195-.064-.264-.259-.258-.486.01-.966.01-1.452 0-.185-.006-.238.068-.238.248.006.718.01 1.441-.005 2.16a.74.74 0 0 1-.18.433q-.959 1.037-1.943 2.043c-.137.143-.153.243-.016.396.349.396.682.803 1.046 1.23M26.973 11.162c.708-.703 1.542-1.056 2.54-1.009 1.024.048 1.79.496 2.345 1.4.09-.111.153-.185.21-.264.914-1.242 2.768-1.527 3.966-.608.491.375.771.893.782 1.49.042 1.805.042 3.617.063 5.428 0 .158-.058.216-.216.216-.724-.005-1.442-.01-2.165 0-.212.005-.217-.11-.217-.27 0-1.272.005-2.55 0-3.822 0-.217-.02-.439-.058-.655-.084-.512-.396-.834-.876-.93-.444-.084-.925.133-1.183.529-.248.385-.29.823-.296 1.262-.01 1.193-.01 2.392 0 3.585 0 .233-.069.301-.301.296q-.99-.024-1.98 0c-.227.005-.28-.074-.28-.285.01-1.204.005-2.408 0-3.612 0-.27-.021-.544-.058-.813-.085-.576-.528-.95-1.109-.961-.58-.01-1.072.37-1.178.929a3 3 0 0 0-.063.628c-.005 1.273-.01 2.55 0 3.824 0 .232-.069.3-.3.295-.661-.016-1.321-.01-1.981 0-.211.005-.296-.053-.296-.28.005-2.339.005-4.673 0-7.012 0-.211.08-.27.28-.264q1.013.015 2.033 0c.19 0 .275.053.259.253s-.005.402-.005.602c.042.016.063.032.084.048M54.178 11.009v-.375c0-1.468.006-2.936-.005-4.4 0-.226.058-.305.296-.3.665.016 1.336.01 2.001 0 .195-.005.259.058.259.253-.005 3.797-.005 7.588 0 11.385 0 .18-.058.248-.243.243a91 91 0 0 0-2.08 0c-.175 0-.238-.058-.228-.227.01-.222 0-.449 0-.745-.11.096-.174.138-.221.19-.629.687-1.432.882-2.334.85-1.495-.052-3.063-1.277-3.375-2.92-.354-1.853.164-3.511 1.88-4.493 1.135-.65 2.936-.581 3.865.412.032.031.08.053.185.127M50.81 13.96c0 .982.724 1.738 1.664 1.738 1.019 0 1.78-.73 1.78-1.711.005-.972-.772-1.764-1.728-1.753a1.715 1.715 0 0 0-1.716 1.726"
      />
      <path
        fill="#36B"
        d="M19.813 16.875c-.122.127-.233.259-.36.375-.607.528-1.325.681-2.107.623-1.43-.11-2.481-.834-3.083-2.107-.887-1.88-.386-4.451 1.9-5.423 1.236-.528 2.456-.428 3.517.512q.056.056.127.095c.01.01.032.006.116.016 0-.158.006-.311 0-.46-.005-.163.053-.237.233-.237.697.005 1.389.01 2.085 0 .238-.005.243.121.243.296-.005 1.6 0 3.2 0 4.795 0 .728-.005 1.457.006 2.186.005.21-.069.274-.275.269-.67-.01-1.336-.01-2.006 0-.227.005-.296-.085-.286-.29.01-.206 0-.412 0-.618-.037-.016-.074-.021-.11-.032m.179-2.888c.005-.95-.766-1.754-1.695-1.764-.956-.01-1.769.776-1.774 1.71-.006.94.765 1.754 1.674 1.754 1.008.01 1.79-.734 1.795-1.7"
      />
      <path
        fill="#FF8700"
        d="M46.553 13.01c0 .718.01 1.442 0 2.16-.021 1.441-.935 2.244-1.959 2.53-1.431.4-2.867.432-4.256-.154-1.056-.449-1.595-1.294-1.61-2.44-.017-1.52 0-3.041-.011-4.557 0-.206.042-.295.274-.29q1 .024 2.007 0c.227-.005.28.08.28.29a447 447 0 0 0 0 3.77c0 .217.016.444.069.65.174.703.728.93 1.362.898.892-.042 1.294-.491 1.294-1.384 0-1.31.005-2.619-.006-3.928 0-.227.058-.307.296-.301.665.016 1.336.01 2.007 0 .21-.006.28.068.274.274-.005.824-.005 1.653-.005 2.477-.01.005-.01.005-.016.005"
      />
      <path
        fill="#36B"
        d="M6.696 13.058c0-1.474.005-2.952-.006-4.426 0-.237.059-.322.307-.316q1.07.022 2.133 0c.264-.006.322.09.317.332-.01 2.155-.005 4.304-.005 6.458 0 .386 0 .386.39.386.925 0 1.849.005 2.767-.005.212 0 .275.068.27.274q-.015.896 0 1.79c.005.217-.08.264-.28.264-1.875-.005-3.744-.005-5.619 0-.243 0-.295-.084-.29-.306.021-1.484.016-2.968.016-4.451"
      />
      <path
        fill="#FF8700"
        d="M58.524 9.361c.93.613 1.838.597 2.767-.01.005.1.016.169.016.237 0 2.64 0 5.286.005 7.926 0 .222-.047.312-.29.307-.755-.016-1.51-.011-2.266 0-.174 0-.253-.043-.253-.233.005-2.714.005-5.423.005-8.137 0-.026.005-.042.016-.09"
      />
      <path
        fill="#36B"
        d="M61.465 7.513c.011.803-.718 1.542-1.526 1.547-.86.005-1.594-.729-1.59-1.595 0-.786.73-1.505 1.532-1.52.85-.016 1.574.702 1.584 1.568"
      />
    </g>
    <defs>
      <clipPath id="LamudiLogoColored_svg__a">
        <path fill="#fff" d="M0 2h61.465v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLamudiLogoColored;
