import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { arrayMove } from '@dnd-kit/sortable';
import { optimizeImages } from '../../../../effects/optimizeImages';
import {
  assignMainImageWhenUndefined,
  createImage,
  removeImage,
} from './adImages';

export const IMAGES_UPLOADED = 'IMAGES_UPLOADED';
export const IMAGES_OPTIMIZED = 'IMAGES_OPTIMIZED';
export const IMAGES_OPTIMIZATION_FAILED = 'IMAGES_OPTIMIZATION_FAILED';
export const IMAGES_SORTED = 'IMAGES_SORTED';
export const IMAGE_DELETED = 'IMAGE_DELETED';
export const IMAGE_DELETE_CONFIRMED = 'IMAGE_DELETE_CONFIRMED';
export const IMAGE_DELETE_CANCELED = 'IMAGE_DELETE_CANCELED';

registerEventHandler(
  IMAGES_UPLOADED,
  (_, { field, images, newImages, hasMainImage, maxNumberOfImages }) => {
    if (images.length + newImages.length > maxNumberOfImages) {
      return state.set({
        [`imageUploader.${field}.error`]: {
          id: 'newprop_image_upload_error_limit',
          values: { count: maxNumberOfImages },
        },
      });
    }
    return optimizeImages({
      images: newImages,
      successEvent: {
        id: IMAGES_OPTIMIZED,
        payload: { field, images, hasMainImage },
      },
      errorEvent: { id: IMAGES_OPTIMIZATION_FAILED, payload: { field } },
    });
  }
);

registerEventHandler(
  IMAGES_OPTIMIZED,
  (_, [optimizedImages, { field, images, hasMainImage }]) => {
    const allImages = mergePreviousImagesWithOptimized(images, optimizedImages);

    return state.set({
      [`adForm:ad.${field}`]: hasMainImage
        ? assignMainImageWhenUndefined(allImages)
        : allImages,
      [`imageUploader.${field}.error`]: undefined,
    });
  }
);

registerEventHandler(IMAGES_OPTIMIZATION_FAILED, (_, [{ error }, { field }]) =>
  state.set({ [`imageUploader.${field}.error`]: { id: error.message } })
);

registerEventHandler(
  IMAGES_SORTED,
  (_, { field, images, draggedImageId, destinationImageId, hasMainImage }) => {
    const draggedImageIndex = imageIndex(images, draggedImageId);
    const destinationImageIndex = imageIndex(images, destinationImageId);

    if (draggedImageIndex < 0 || destinationImageIndex < 0) {
      return {};
    }

    if (hasMainImage) {
      const firstImageIndex = 0;
      const secondImageIndex = 1;

      if (destinationImageIndex === firstImageIndex) {
        images[destinationImageIndex].isMain = false;
        images[draggedImageIndex].isMain = true;
      }
      if (draggedImageIndex === firstImageIndex) {
        images[draggedImageIndex].isMain = false;
        images[secondImageIndex].isMain = true;
      }
    }

    const newImages = arrayMove(
      images,
      draggedImageIndex,
      destinationImageIndex
    );
    return state.set({ [`adForm:ad.${field}`]: newImages });
  }
);

registerEventHandler(IMAGE_DELETED, (_, { field, imageId }) =>
  state.set({ [`imageUploader.${field}.imageIdToDelete`]: imageId })
);

registerEventHandler(
  IMAGE_DELETE_CONFIRMED,
  ({ state: { imageUploader } }, { field, images, hasMainImage }) => {
    const imgIndex = imageIndex(images, imageUploader[field].imageIdToDelete);
    if (imgIndex < 0) {
      return {};
    }

    const newImages = removeImage(images, imgIndex);
    return state.set({
      [`adForm:ad.${field}`]: hasMainImage
        ? assignMainImageWhenUndefined(newImages)
        : newImages,
      [`imageUploader.${field}.imageIdToDelete`]: undefined,
      [`imageUploader.${field}.error`]: undefined,
    });
  },
  [state.get({ imageUploader: 'imageUploader' })]
);

registerEventHandler(IMAGE_DELETE_CANCELED, (_, { field }) =>
  state.set({ [`imageUploader.${field}.imageIdToDelete`]: undefined })
);

function mergePreviousImagesWithOptimized(adImages, optimizedImages) {
  const maxId = adImages.reduce(
    (acc, current) => (current.id > acc ? current.id : acc),
    0
  );
  const images = optimizedImages.map((file, idx) =>
    createImage({ id: maxId + idx + 1, file })
  );
  return [...adImages, ...images];
}

function imageIndex(images, id) {
  return images.findIndex((img) => img.id === id);
}
