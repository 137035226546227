export function buildTermFilter(field, value) {
  return {
    field,
    type: 'term',
    value,
  };
}

export function buildRangeFilter(field, value) {
  return {
    field,
    type: 'range',
    value,
  };
}

export function buildFullTextFilter(fields, value) {
  return {
    field: fields,
    type: 'fullText',
    value,
  };
}
