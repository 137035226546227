import { isDeveloperSelector } from '../../selectors/publisher';
import { daysFrom } from '../../utils/dates';

import {
  maxBoostablePropertiesSelector,
  publishedPropertiesSelector,
} from '../../selectors/propertiesSummary';
import { shouldShowPendingSCRBannerSelector } from '../PendingSubscriptionChangeRequestBanner/selectors';

export function availableSlotsFromPublisherSelector(state) {
  const maxBoostableProperties = maxBoostablePropertiesSelector(state);
  const publishedProperties = publishedPropertiesSelector(state);

  return maxBoostableProperties - publishedProperties;
}

export function showNotUsingYourWholePlanBannerSelector(state, now) {
  return (
    getIfUsageOfPlanIsTooLowSelector(state) &&
    !shouldShowPendingSCRBannerSelector(state) &&
    !didUserCloseNotUsingWholePlanBannerSelector(state, now) &&
    !isDeveloperSelector(state)
  );
}

export function planUsagePercentageSelector(state) {
  const publishedProperties = publishedPropertiesSelector(state);
  const maxBoostableProperties = maxBoostablePropertiesSelector(state);
  return (publishedProperties / maxBoostableProperties) * 100;
}

function getIfUsageOfPlanIsTooLowSelector(state) {
  const planUsagePercentage = planUsagePercentageSelector(state);
  return planUsagePercentage < 85;
}

function didUserCloseNotUsingWholePlanBannerSelector(state, now) {
  const closedDate = state.notUsingWholePlanBannerClosedDate;
  if (closedDate == null) {
    return false;
  }
  return daysFrom(new Date(closedDate), now) < 5;
}
