import * as React from "react";
const SvgNewProject = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={106}
    height={118}
    fill="none"
    {...props}
  >
    <g clipPath="url(#NewProject_svg__a)">
      <path
        fill="#fff"
        stroke="#0ACC86"
        d="M28.5 60a3.5 3.5 0 0 1 3.5-3.5h3a3.5 3.5 0 0 1 3.5 3.5v73.5h-10z"
      />
      <path
        fill="#fff"
        stroke="#0ACC86"
        d="M22.5 62a1.5 1.5 0 0 1 1.5-1.5h18a1.5 1.5 0 0 1 1.5 1.5v71.5h-21z"
      />
      <path
        fill="#0ACC86"
        d="M22 64a4 4 0 0 1 4-4h1v74h-5zM35 80a2 2 0 0 1 2-2h3v56h-5z"
        opacity={0.3}
      />
      <rect width={22} height={1} x={21.999} y={70} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={21.999} y={73} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={21.999} y={76} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={21.999} y={79} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={21.999} y={82} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={21.999} y={85} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={21.999} y={88} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={21.999} y={91} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={21.999} y={94} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={21.999} y={97} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={21.999} y={100} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={21.999} y={103} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={21.999} y={106} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={21.999} y={109} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={21.999} y={112} fill="#0ACC86" rx={0.5} />
      <rect width={22} height={1} x={21.999} y={115} fill="#0ACC86" rx={0.5} />
      <path
        fill="#fff"
        stroke="#0ACC86"
        d="M16.5 90a1.5 1.5 0 0 1 1.5-1.5h8a1.5 1.5 0 0 1 1.5 1.5v43.5h-11z"
      />
      <rect width={6} height={1} x={16} y={91} fill="#0ACC86" rx={0.5} />
      <rect width={6} height={1} x={16} y={94} fill="#0ACC86" rx={0.5} />
      <rect width={6} height={1} x={16} y={97} fill="#0ACC86" rx={0.5} />
      <rect width={6} height={1} x={16} y={100} fill="#0ACC86" rx={0.5} />
      <rect width={6} height={1} x={16} y={103} fill="#0ACC86" rx={0.5} />
      <rect width={6} height={1} x={16} y={106} fill="#0ACC86" rx={0.5} />
      <rect width={6} height={1} x={16} y={109} fill="#0ACC86" rx={0.5} />
      <rect width={6} height={1} x={16} y={112} fill="#0ACC86" rx={0.5} />
      <path
        fill="#0ACC86"
        fillRule="evenodd"
        d="M80 93a.5.5 0 0 1-.5-.5v-76a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        stroke="#0ACC86"
        d="M74.5 35a3.5 3.5 0 0 1 3.5-3.5h4a3.5 3.5 0 0 1 3.5 3.5v63.5h-11z"
      />
      <path
        fill="#fff"
        stroke="#0ACC86"
        d="M69.5 45a3.5 3.5 0 0 1 3.5-3.5h14a3.5 3.5 0 0 1 3.5 3.5v88.5h-21z"
      />
      <path
        fill="#0ACC86"
        d="M70 44a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v90H70z"
        opacity={0.2}
      />
      <path
        fill="#fff"
        stroke="#0ACC86"
        d="M93.5 46a1.5 1.5 0 0 0-1.5-1.5H68a1.5 1.5 0 0 0-1.5 1.5v87.5h27z"
      />
      <path fill="#0ACC86" d="M93 46a1 1 0 0 0-1-1h-2v89h3z" opacity={0.3} />
      <path
        fill="#0ACC86"
        fillRule="evenodd"
        d="M69.5 131.015a.5.5 0 0 1-.5-.5v-76a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5M72.5 131.015a.5.5 0 0 1-.5-.5v-76a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5M75.5 131.015a.5.5 0 0 1-.5-.5v-76a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5M78.5 131.015a.5.5 0 0 1-.5-.5v-76a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5M81.5 131.015a.5.5 0 0 1-.5-.5v-76a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5M84.5 131.014a.5.5 0 0 1-.5-.5v-76a.5.5 0 1 1 1 0v76a.5.5 0 0 1-.5.5M87.5 131.015a.5.5 0 0 1-.5-.5v-76a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5M90.5 131a.5.5 0 0 1-.5-.5v-76a.5.5 0 0 1 1 0v76a.5.5 0 0 1-.5.5"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        stroke="#0ACC86"
        d="M37.5 78a1.5 1.5 0 0 1 1.5-1.5h32a1.5 1.5 0 0 1 1.5 1.5v55.5h-35z"
      />
      <rect width={24} height={1} x={43} y={81} fill="#0ACC86" rx={0.5} />
      <rect width={24} height={1} x={43} y={83.995} fill="#0ACC86" rx={0.5} />
      <rect width={24} height={1} x={43} y={86.99} fill="#0ACC86" rx={0.5} />
      <rect width={24} height={1} x={43} y={89.985} fill="#0ACC86" rx={0.5} />
      <rect width={24} height={1} x={43} y={92.981} fill="#0ACC86" rx={0.5} />
      <rect width={24} height={1} x={43} y={95.976} fill="#0ACC86" rx={0.5} />
      <rect width={24} height={1} x={43} y={98.971} fill="#0ACC86" rx={0.5} />
      <rect width={24} height={1} x={43} y={101.966} fill="#0ACC86" rx={0.5} />
      <rect width={24} height={1} x={43} y={104.961} fill="#0ACC86" rx={0.5} />
      <rect width={24} height={1} x={43} y={107.957} fill="#0ACC86" rx={0.5} />
      <rect width={24} height={1} x={43} y={110.951} fill="#0ACC86" rx={0.5} />
      <rect width={24} height={1} x={43} y={113.947} fill="#0ACC86" rx={0.5} />
      <rect width={24} height={1} x={43} y={116.941} fill="#0ACC86" rx={0.5} />
      <path fill="#0ACC86" d="M0 117h106v1H0z" />
      <path
        fill="#CEF5E7"
        stroke="#0ACC86"
        d="m40.041 27.548-.037.282.224.177 3.359 2.64.006.005.006.005c.1.073.148.208.06.372l-3.204 5.542-.006.011a.3.3 0 0 1-.37.14l-3.985-1.601-.266-.107-.227.176a11 11 0 0 1-2.589 1.503l-.266.108-.04.285-.602 4.244-.001.009a.28.28 0 0 1-.29.247h-6.417c-.135 0-.265-.088-.31-.274l-.598-4.226-.04-.285-.267-.108a11.3 11.3 0 0 1-2.593-1.506l-.226-.172-.263.106-3.974 1.596c-.174.05-.316-.017-.38-.135l-.007-.01-3.205-5.543c-.068-.128-.026-.305.071-.38l3.393-2.64.235-.184-.049-.293a9.3 9.3 0 0 1-.11-1.49c0-.478.062-1 .143-1.488l.05-.294-.236-.183-3.392-2.64-.01-.008-.01-.007c-.095-.066-.143-.204-.054-.361l3.192-5.548.005-.01a.3.3 0 0 1 .37-.139l3.985 1.6.262.106.226-.17c.792-.599 1.657-1.126 2.594-1.507l.266-.108.04-.285.601-4.238c.03-.172.152-.262.291-.262h6.418c.152 0 .279.102.308.263l.6 4.237.04.285.267.108c.938.382 1.786.892 2.593 1.506l.226.171.263-.105 3.974-1.597c.174-.05.316.018.38.136l.007.01 3.205 5.543c.068.128.026.304-.071.38l-3.393 2.64-.235.183.049.294c.078.467.11.974.11 1.489 0 .53-.032 1.027-.096 1.505Z"
      />
      <circle cx={28.876} cy={25.875} r={4.513} fill="#fff" stroke="#0ACC86" />
      <path
        stroke="#0ACC86"
        strokeLinecap="round"
        d="m5.253 55.597 4.95 4.949M5.112 60.547l4.949-4.95M97.899 8.5v11M92.5 14.1h11"
      />
    </g>
    <defs>
      <clipPath id="NewProject_svg__a">
        <path fill="#fff" d="M0 0h106v118H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgNewProject;
