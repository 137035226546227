import { effects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import { CLOSE_DIALOG, OPEN_DIALOG } from '../../../../../../../events/dialogs';
import { ASSOCIATE_MODAL } from './constants';
import { GENERIC_CONFIRMATION_MODAL } from '../../../../../../../partials/modals/generic/constants';
import { environment } from '../../../../../../../coeffects/environment';
import { toast } from '../../../../../../../effects/toast';

export const OPEN_ASSOCIATE_PUBLISHER_MODAL = 'OPEN_ASSOCIATE_PUBLISHER_MODAL';
export const PUBLISHER_TO_JOIN_ACCOUNT_SELECTED =
  'PUBLISHER_TO_JOIN_ACCOUNT_SELECTED';
export const PUBLISHER_TO_JOIN_ACCOUNT_UNSELECTED =
  'PUBLISHER_TO_JOIN_ACCOUNT_UNSELECTED';
export const ASSOCIATE_PUBLISHER_TO_MULTIACCOUNT =
  'ASSOCIATE_PUBLISHER_TO_MULTIACCOUNT';
export const REQUEST_ASSOCIATE_MULTIACCOUNT = 'REQUEST_ASSOCIATE_MULTIACCOUNT';
export const ASSOCIATE_MULTIACCOUNT_SUCCESS = 'ASSOCIATE_MULTIACCOUNT_SUCCESS';
export const ASSOCIATE_MULTIACCOUNT_FAILED = 'ASSOCIATE_MULTIACCOUNT_FAILED';

registerEventHandler(OPEN_ASSOCIATE_PUBLISHER_MODAL, () => ({
  ...state.set({ associatePublisherModal: {} }),
  ...effects.dispatch({ id: OPEN_DIALOG, payload: { id: ASSOCIATE_MODAL } }),
}));

registerEventHandler(PUBLISHER_TO_JOIN_ACCOUNT_SELECTED, (_, { publisherId }) =>
  state.set({
    associatePublisherModal: { selectedPublisherId: publisherId },
  })
);

registerEventHandler(PUBLISHER_TO_JOIN_ACCOUNT_UNSELECTED, () =>
  state.set({
    associatePublisherModal: { selectedPublisherId: undefined },
  })
);

registerEventHandler(ASSOCIATE_PUBLISHER_TO_MULTIACCOUNT, (_, payload) =>
  effects.dispatch({
    id: OPEN_DIALOG,
    payload: {
      id: GENERIC_CONFIRMATION_MODAL,
      parameters: {
        messages: {
          title: {
            id: 'Are you sure you want to associate this publisher to multiaccount?',
          },
          body: {
            id: 'The publisher will lose its current subscription',
          },
        },
        actions: {
          confirm: { id: REQUEST_ASSOCIATE_MULTIACCOUNT, payload },
        },
      },
    },
  })
);

registerEventHandler(
  REQUEST_ASSOCIATE_MULTIACCOUNT,
  (
    { environment: { apiUrl } },
    { publisherId, associateToPublisherId, successEvent }
  ) =>
    http.put({
      url: `${apiUrl}/admin/publishers/${publisherId}/associate/${associateToPublisherId}`,
      successEvent: {
        id: ASSOCIATE_MULTIACCOUNT_SUCCESS,
        payload: { successEvent },
      },
      errorEvent: ASSOCIATE_MULTIACCOUNT_FAILED,
    }),
  [environment()]
);

registerEventHandler(
  ASSOCIATE_MULTIACCOUNT_SUCCESS,
  (_, [__, { successEvent }]) => ({
    ...toast.show({ text: 'Publisher linked to multiaccount' }),
    ...effects.dispatchMany([CLOSE_DIALOG, successEvent]),
  })
);

registerEventHandler(ASSOCIATE_MULTIACCOUNT_FAILED, (_, [{ data }]) =>
  toast.show({
    text: data ?? 'Could not associate publisher to multiaccount',
  })
);
