import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import { useUnmount } from 'react-use';
import Tip from 'design-system/components/Tip';
import Layout from 'design-system/components/Layout/Layout';
import {
  OutlineButton,
  PrimaryButton,
} from 'design-system/components/Button/presets';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { CSV_NEXT_STEP, CSV_PAGE_UNMOUNTED, CSV_PREVIOUS_STEP } from './events';
import { csvFileErrorsSelector, csvFileStepsSelector } from './selectors';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

import { Navigation } from '../../../partials';
import Sidebar from './partials/Sidebar';
import { FINAL_STEP_ORDER, HEADER_ID_BY_STEP, STEP_BY_ID } from './constants';
import HeaderMapper from './partials/HeaderMapper';
import ModalUploadCSV from './partials/ModalUploadCSV';
import ValuesMapper from './partials/ValuesMapper';

const FooterEndContent = styled.div`
  display: flex;
  padding: ${spacing.value(SPACING_SIZE.XL)};
  flex-direction: row-reverse;
  align-items: center;
  gap: ${spacing.value(SPACING_SIZE.M)};
  align-self: stretch;
`;

const ErrorMessage = styled(Tip)`
  color: ${COLOR_PALETTE.STRAWBERRY_BASE};
  background: ${COLOR_PALETTE.STRAWBERRY_A10};
  flex-direction: row;
`;

function UploadCSV() {
  const step = useSelector(csvFileStepsSelector);
  const errors = useSelector(csvFileErrorsSelector);

  useUnmount(() => {
    dispatch({ id: CSV_PAGE_UNMOUNTED });
  });

  const handlePreviousStep = () => {
    dispatch({ id: CSV_PREVIOUS_STEP });
  };

  return (
    <>
      <Layout independentScroll>
        <Layout.Header>
          <Navigation />
        </Layout.Header>
        <Layout.LeftSidebar>
          <Sidebar
            title={
              <FormattedMessageWithValidation id="upload_csv_modal_title" />
            }
            description={
              <FormattedMessageWithValidation id="upload_csv_sidebar_description" />
            }
          />
        </Layout.LeftSidebar>
        <Layout.Content>
          <Layout.Padded>
            <ModalUploadCSV />
            <HeaderMapper />
            {Object.keys(STEP_BY_ID).map((key) => (
              <ValuesMapper key={key} id={key} />
            ))}
          </Layout.Padded>
        </Layout.Content>
        <Layout.Footer>
          <FooterEndContent>
            <PrimaryButton
              onClick={() => {
                dispatch({ id: CSV_NEXT_STEP });
              }}
            >
              <FormattedMessageWithValidation id="upload_csv_continue_button" />
            </PrimaryButton>

            {step > 2 && step < FINAL_STEP_ORDER && (
              <OutlineButton onClick={handlePreviousStep}>
                <FormattedMessageWithValidation id="upload_csv_previous_button" />
              </OutlineButton>
            )}
            {step === FINAL_STEP_ORDER && (
              <OutlineButton onClick={handlePreviousStep}>
                <FormattedMessageWithValidation id="upload_csv_final_step_title" />
              </OutlineButton>
            )}

            {errors[HEADER_ID_BY_STEP[step]]?.length > 0 && (
              <ErrorMessage>
                <FormattedMessageWithValidation id="upload_csv_map_errors" />
              </ErrorMessage>
            )}
          </FooterEndContent>
        </Layout.Footer>
      </Layout>
    </>
  );
}

export default UploadCSV;
