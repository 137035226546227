import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Anchor from 'design-system/components/Anchor';
import InputText from 'design-system/components/InputText';
import { PrimaryButton } from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  confirmedPasswordErrorSelector,
  passwordErrorSelector,
} from '../selectors';
import { RESET_PASSWORD_REQUESTED } from '../events';
import { LOGIN } from '../../../constants/routes';

const Wrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${InputText},
  ${Heading1} {
    ${spacing.stack(SPACING_SIZE.M)}
  }
`;

const Inputs = styled.div`
  width: 100%;
  ${spacing.stack(SPACING_SIZE.M)}
`;

const Actions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${spacing.insetSandwich(SPACING_SIZE.M)}
`;

const MIN_PASSWORD_LENGTH = 8;
const schema = yup
  .object({
    password: yup
      .string()
      .min(
        MIN_PASSWORD_LENGTH,
        <FormattedMessageWithValidation id="createpwdpage_pwd_instructions" />
      )
      .required(
        <FormattedMessageWithValidation id="createpwdpage_error_pwd_required" />
      ),
    confirmedPassword: yup
      .string()
      .oneOf(
        [yup.ref('password')],
        <FormattedMessageWithValidation id="createpwdpage_confirm_instructions" />
      )
      .required(
        <FormattedMessageWithValidation id="createpwdpage_error_confirm_pwd_required" />
      ),
  })
  .required();

function CreateNewPasswordForm({ ...rest }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });
  const errorPassword = useSelector(passwordErrorSelector);
  const errorConfirmedPassword = useSelector(confirmedPasswordErrorSelector);

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)} {...rest}>
      <Heading1>
        <FormattedMessageWithValidation id="createpwdpage_header" />
      </Heading1>
      <Inputs>
        <InputText
          type="password"
          label={
            <FormattedMessageWithValidation id="createpwdpage_pwd_emptystate" />
          }
          error={Boolean(errors.password) || errorPassword != null}
          {...register('password')}
          tip={
            errors.password?.message || (
              <FormattedMessageWithValidation id="createpwdpage_pwd_instructions" />
            )
          }
        />
        <InputText
          type="password"
          label={
            <FormattedMessageWithValidation id="createpwdpage_confirm_emptystate" />
          }
          error={
            Boolean(errors.confirmedPassword) || errorConfirmedPassword != null
          }
          {...register('confirmedPassword')}
          tip={
            errors.confirmedPassword?.message || (
              <FormattedMessageWithValidation id="createpwdpage_confirm_instructions" />
            )
          }
        />
      </Inputs>
      <Actions>
        <Anchor as={Link} to={LOGIN}>
          <FormattedMessageWithValidation id="loginpage_cta" />
        </Anchor>
        <PrimaryButton $size={BUTTON_SIZE.LARGE} type="submit">
          <FormattedMessageWithValidation id="createpwdpage_change_cta" />
        </PrimaryButton>
      </Actions>
    </Wrapper>
  );
}

export default CreateNewPasswordForm;

function onSubmit({ password, confirmedPassword }) {
  dispatch({
    id: RESET_PASSWORD_REQUESTED,
    payload: {
      password,
      confirmedPassword,
    },
  });
}
