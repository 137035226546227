import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import { useMount } from 'react-use';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { ERROR_TAGS } from './constants';
import { DropdownFilter } from '../../../../partials/DataTable';
import { availableErrorsSelector } from './selectors';
import {
  INVALID_PROPERTIES_ERRORS_REQUESTED,
  TRACK_ERROR_OPTION_SELECTED,
} from './events';

export function ErrorTypeFilter() {
  useMount(() => dispatch(INVALID_PROPERTIES_ERRORS_REQUESTED));
  const availableErrors = useSelector(availableErrorsSelector);
  return (
    <DropdownFilter
      label={<FormattedMessageWithValidation id="import_stats_errors_filter" />}
      source="error"
      options={[
        {
          id: 'all',
          value: null,
          label: (
            <FormattedMessageWithValidation id="filter_properties_status_all" />
          ),
        },
        ...availableErrors.map((error) => ({
          id: error,
          value: error,
          label: <FormattedMessageWithValidation id={ERROR_TAGS[error]} />,
        })),
      ]}
      scrollable
      onSelectOption={(option) =>
        dispatch({ id: TRACK_ERROR_OPTION_SELECTED, payload: option })
      }
    />
  );
}
