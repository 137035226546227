import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { environment } from '../../../../coeffects/environment';
import { openNewTab } from '../../../../effects/routing';
import {
  PENDING_SUBSCRIPTION_CHANGE_REQUEST_REQUESTED,
  PUBLISHER_REQUESTED,
} from '../../../../events';
import { VERIFIED_LEADS_STATS_REQUESTED } from '../../../../partials/LeadQualityAwareness/events';

export const IMPERSONATE_PUBLISHER_REQUESTED =
  'IMPERSONATE_PUBLISHER_REQUESTED';
export const IMPERSONATE_PUBLISHER_REQUEST_SUCCESSFUL =
  'IMPERSONATE_PUBLISHER_REQUEST_SUCCESSFUL';

registerEventHandler(
  IMPERSONATE_PUBLISHER_REQUESTED,
  ({ environment: { apiUrl } }, publisherId) =>
    http.put({
      url: `${apiUrl}/admin/users/me/publisher/${publisherId}`,
      body: {},
      successEvent: IMPERSONATE_PUBLISHER_REQUEST_SUCCESSFUL,
    }),
  [environment()]
);

registerEventHandler(IMPERSONATE_PUBLISHER_REQUEST_SUCCESSFUL, () => ({
  ...openNewTab('/'),
  ...effects.dispatchMany(
    PUBLISHER_REQUESTED,
    PENDING_SUBSCRIPTION_CHANGE_REQUEST_REQUESTED,
    VERIFIED_LEADS_STATS_REQUESTED
  ),
}));
