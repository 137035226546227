import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import { Overline } from 'design-system/components/Typography/presets/Overline';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import FormattedMessageWithValidation from '../../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { leadPlusInterestsSelector } from './selectors';
import Interest from './Interest';

const Wrapper = styled.div`
  ${Overline} {
    display: block;
    padding: 0 ${spacing.value(SPACING_SIZE.XL)};
    ${spacing.stack(SPACING_SIZE.M, false)};
  }

  ${Interest} {
    ${spacing.stack(SPACING_SIZE.M)};
    border-radius: unset;
  }
`;

function Interests({ blurred, ...props }) {
  const interests = useSelector(leadPlusInterestsSelector);
  if (blurred) {
    return (
      <Wrapper {...props}>
        <Overline>
          <FormattedMessageWithValidation id="lead_detail_activity_interests" />
        </Overline>
        <Interest blurred={blurred} />
      </Wrapper>
    );
  }

  return (
    <Wrapper {...props}>
      <Overline>
        <FormattedMessageWithValidation id="lead_detail_activity_interests" />
      </Overline>
      {interests.map((interest) => (
        <Interest
          interest={interest}
          key={`${interest.operationType}-${interest.propertyType}-${interest.countryCode}`}
        />
      ))}
    </Wrapper>
  );
}

export default styled(Interests)``;
