import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { environment } from '../../../../coeffects/environment';
import { toast } from '../../../../effects/toast';
import { UNITS_PAGE_MOUNTED } from '../../events';

export const DELETE_UNIT = 'DELETE_UNIT';
export const DELETE_UNIT_SUCCEEDED = 'DELETE_UNIT_SUCCEEDED';

registerEventHandler(
  DELETE_UNIT,
  ({ environment: { apiUrl } }, { unitId }) =>
    http.delete({
      url: `${apiUrl}/properties/${unitId}`,
      successEvent: DELETE_UNIT_SUCCEEDED,
    }),
  [environment()]
);

registerEventHandler(DELETE_UNIT_SUCCEEDED, () => ({
  ...toast.show({ text: 'units_page_toast_confirmation_delete' }),
  ...effects.dispatch(UNITS_PAGE_MOUNTED),
}));
