export function canSaveMaxBoostablePropertiesSelector(state) {
  return (
    availableMaxBoostableSlotsSelector(state) >=
    usedMaxBoostableSlotsSelector(state)
  );
}

export function availableMaxBoostableSlotsSelector(state) {
  return state.availableMaxBoostableSlots ?? 0;
}

export function usedMaxBoostableSlotsSelector(state) {
  return usedSlots(state, 'maxBoostableProperties');
}

export function canSaveMaxSuperboostablePropertiesSelector(state) {
  return (
    availableMaxSuperboostableSlotsSelector(state) >=
    usedMaxSuperboostableSlotsSelector(state)
  );
}

export function availableMaxSuperboostableSlotsSelector(state) {
  return state.availableMaxSuperboostableSlots ?? 0;
}

export function usedMaxSuperboostableSlotsSelector(state) {
  return usedSlots(state, 'maxSuperboostableProperties');
}

function usedSlots(state, field) {
  return Object.values(state.boostingSlots ?? {}).reduce(
    (x, y) => x + y[field],
    0
  );
}
