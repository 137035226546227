import styled from 'styled-components';
import { COLOR_PALETTE } from '../../../styles/color';
import Typography, { TYPOGRAPHY_VARIANT } from '../Typography';

import BasePresetTypography from './BasePresetTypography';

export const Subtitle1 = styled(BasePresetTypography)`
  ${Typography.mods.variant(TYPOGRAPHY_VARIANT.SUBTITLE_1)}
`;

Subtitle1.defaultProps = {
  $color: COLOR_PALETTE.SECONDARY_BASE,
};
