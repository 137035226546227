import Dialog from 'design-system/components/Dialog/Dialog';
import {
  FlatButton,
  SecondaryButton,
} from 'design-system/components/Button/presets';
import { dispatch } from 'reffects';
import { useState } from 'react';
import InputText from 'design-system/components/InputText';
import { MARK_AS_FREE_FROM_BEGINNING_MODAL } from './constants';
import { useDialog } from '../../../../../../../hooks/useDialog';
import useUpdateTableEvent from '../../../../../../../partials/DataTable/hooks/useUpdateTableEvent';
import { MARK_AS_FREE_FROM_BEGINNING } from '../../Actions/MarkAsFreeFromBeginning/events';
import { removeEmojis } from '../../../../../../utils/removeEmojis';

export default function MarkAsFreeFromBeginningModal() {
  const updateTableEvent = useUpdateTableEvent();
  const { open, closeDialog, parameters } = useDialog(
    MARK_AS_FREE_FROM_BEGINNING_MODAL
  );
  const [reason, setReason] = useState('');
  return (
    <Dialog open={open}>
      <Dialog.Header>
        Mark publisher as free from the beginning of the subscription
      </Dialog.Header>
      <Dialog.Content>
        <Dialog.Text>
          You are about to change the publishers base subscription and
          superboost to free. Are you sure you want to proceed?
        </Dialog.Text>
        <InputText
          label="Mark as free from beginning reason"
          required
          placeholder="Type the reason why..."
          value={reason}
          onChange={(event) => setReason(removeEmojis(event.target.value))}
        />
      </Dialog.Content>
      <Dialog.Footer>
        <FlatButton onClick={closeDialog}>Cancel</FlatButton>
        <SecondaryButton
          onClick={() =>
            dispatch({
              id: MARK_AS_FREE_FROM_BEGINNING,
              payload: {
                publisherId: parameters.publisherId,
                reason,
                updateTableEvent,
              },
            })
          }
          disabled={!reason}
        >
          Confirm
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}
