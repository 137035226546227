import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { environment } from '../../coeffects/environment';
import { PLANS_STATE_REGION, WASI_PLANS_STATE_REGION } from './constants';
import { COUNTRY_CODE } from '../../constants/country';

export const PUBLISHERS_REQUESTED = 'PUBLISHERS_REQUESTED';
export const PUBLISHERS_LOADED = 'PUBLISHERS_LOADED';
export const PUBLISHERS_LOAD_FAILED = 'PUBLISHERS_LOAD_FAILED';
export const PLANS_PER_COUNTRY_REQUESTED = 'PLANS_PER_COUNTRY_REQUESTED';
export const PLANS_PER_COUNTRY_SUCCEEDED = 'PLANS_PER_COUNTRY_SUCCEEDED';
export const PLANS_PER_COUNTRY_FAILED = 'PLANS_PER_COUNTRY_FAILED';
export const SALES_PEOPLE_REQUESTED = 'SALES_PEOPLE_REQUESTED';
export const SALES_PEOPLE_LOADED = 'SALES_PEOPLE_LOADED';
export const WASI_PLANS_REQUESTED = 'WASI_PLANS_REQUESTED';
export const WASI_PLANS_SUCCEEDED = 'WASI_PLANS_SUCCESS';

registerEventHandler(
  PUBLISHERS_REQUESTED,
  ({ environment: { apiUrl } }) =>
    http.get({
      url: `${apiUrl}/admin/publishers`,
      successEvent: PUBLISHERS_LOADED,
      errorEvent: PUBLISHERS_LOAD_FAILED,
    }),
  [environment()]
);

registerEventHandler(PUBLISHERS_LOADED, (_, [response]) =>
  state.set({
    'admin:publishers': response.data,
  })
);

registerEventHandler(PUBLISHERS_LOAD_FAILED, () =>
  state.set({
    'admin:publishers': [],
  })
);

registerEventHandler(
  PLANS_PER_COUNTRY_REQUESTED,
  (
    { environment: { apiUrl } },
    { countryCode, publishInThailandProperty = false }
  ) => ({
    ...state.set({
      [PLANS_STATE_REGION]: [],
      'financeAdmin:publishesInThailandPropertySelected':
        publishInThailandProperty,
    }),
    ...http.get({
      url: `${apiUrl}/admin/plans?countryCode=${countryCode}&publishesInTP=${publishInThailandProperty}`,
      successEvent: PLANS_PER_COUNTRY_SUCCEEDED,
      errorEvent: PLANS_PER_COUNTRY_FAILED,
    }),
  }),
  [environment()]
);

registerEventHandler(PLANS_PER_COUNTRY_SUCCEEDED, (_, [{ data }]) =>
  state.set({
    [PLANS_STATE_REGION]: data,
  })
);

registerEventHandler(
  SALES_PEOPLE_REQUESTED,
  ({ environment: { apiUrl } }) =>
    http.get({
      url: `${apiUrl}/backoffice/available-account-managers`,
      successEvent: SALES_PEOPLE_LOADED,
    }),
  [environment()]
);

registerEventHandler(SALES_PEOPLE_LOADED, (_, [{ data }]) =>
  state.set({ 'admin:salesPeople': data })
);

registerEventHandler(PLANS_PER_COUNTRY_FAILED, () => {});

registerEventHandler(
  WASI_PLANS_REQUESTED,
  ({ environment: { apiUrl } }, { countryCode }) =>
    countryCode === COUNTRY_CODE.MEXICO
      ? http.get({
          url: `${apiUrl}/admin/wasi-plans`,
          successEvent: WASI_PLANS_SUCCEEDED,
        })
      : {},
  [environment()]
);

registerEventHandler(WASI_PLANS_SUCCEEDED, (_, [{ data }]) =>
  state.set({
    [WASI_PLANS_STATE_REGION]: data,
  })
);
