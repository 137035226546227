import styled from 'styled-components';
import Typography from 'design-system/components/Typography/Typography';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import {
  FONT_FAMILY,
  FONT_SIZE,
  typography,
} from 'design-system/styles/typography';
import { SPACING_SIZE } from 'design-system/styles/spacing';

const Text = styled(Typography)`
  text-anchor: middle;
  ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
  ${typography.size(FONT_SIZE.XS)}
  ${typography.font(FONT_FAMILY.PRIMARY)}
`;

export default function AreaChartXTick({ x, y, payload, renderText }) {
  const correctedY = y + 10;
  return (
    <g className="AreaChartXTick" transform={`translate(${x},${correctedY})`}>
      <Text as="text" x={0} y={0} dy={`-${SPACING_SIZE.XS}`}>
        {renderText(payload)}
      </Text>
    </g>
  );
}
