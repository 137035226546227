import { useCallback, useEffect } from 'react';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import { CLOSE_DIALOG, OPEN_DIALOG } from '../events/dialogs';
import {
  dialogParametersSelector,
  isDialogOpenSelector,
} from '../selectors/dialogs';

export function useDialog(dialogId) {
  const open = useSelector((state) => isDialogOpenSelector(state, dialogId));

  const parameters = useSelector((state) =>
    dialogParametersSelector(state, dialogId)
  );

  useEffect(() => {
    /* This avoids memory leaks warning when closing dialog, to be fixed */
  }, [open]);

  const openDialog = useCallback(
    (newParameters) => {
      dispatch({
        id: OPEN_DIALOG,
        payload: {
          id: dialogId,
          parameters: newParameters,
        },
      });
    },
    [dialogId]
  );

  const closeDialog = useCallback(() => dispatch(CLOSE_DIALOG), []);

  return {
    open,
    parameters,
    openDialog,
    closeDialog,
  };
}
