import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PrimaryButton } from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import { border } from 'design-system/styles/border';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import Illustration from 'design-system/components/Illustration/Illustration';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle2 } from 'design-system/components/Typography/presets/Subtitle2';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';
import { subscriptionRoute } from '../../../utils/proppitWebRouter';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Wrapper = styled.div`
  display: flex;
  ${border.outer({ size: 1, color: COLOR_PALETTE.NEUTRAL_A10 })}
  ${radius.regular(RADIUS_SIZE.LARGE)}
  ${spacing.inset(SPACING_SIZE.XL)}
  ${spacing.stack(SPACING_SIZE.XL, false)}

  ${Illustration} {
    ${spacing.inline(SPACING_SIZE.L)}
  }
`;

const Content = styled.div`
  ${Subtitle2} {
    display: block;
    ${spacing.stack(SPACING_SIZE.M, false)}
  }

  ul {
    padding-left: ${spacing.value(SPACING_SIZE.L)};
    margin: ${spacing.value(SPACING_SIZE.NONE)};
    ${spacing.stack(SPACING_SIZE.L, false)};

    li {
      ${typography.size(13)}
      ${typography.lineHeight(13)}
      ${typography.weight(FONT_WEIGHT.NORMAL)}
      ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
    }
  }
`;

export function FreePublisher() {
  return (
    <Wrapper>
      <Illustration name="freePublisherBanner" />
      <Content>
        <Subtitle2>
          <FormattedMessageWithValidation id="free_publisher_banner_text" />
        </Subtitle2>
        <ul>
          <li>
            <FormattedMessageWithValidation id="free_publisher_banner_bullet1" />
          </li>
          <li>
            <FormattedMessageWithValidation id="free_publisher_banner_bullet2" />
          </li>
          <li>
            <FormattedMessageWithValidation id="free_publisher_banner_bullet3" />
          </li>
        </ul>
        <PrimaryButton
          $size={BUTTON_SIZE.SMALL}
          forwardedAs={Link}
          to={subscriptionRoute()}
        >
          <FormattedMessageWithValidation id="free_publisher_banner_cta" />
        </PrimaryButton>
      </Content>
    </Wrapper>
  );
}
