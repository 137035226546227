import { Body2 } from 'design-system/components/Typography/presets/Body2';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

export function LeadCounter({ value }) {
  return (
    <Body2 $color={COLOR_PALETTE.NEUTRAL_A60} $weight={FONT_WEIGHT.MEDIUM}>
      <FormattedMessageWithValidation
        id="overview_kpis_enquiries_leads"
        values={{
          leadsCount: (
            <Body2
              $color={COLOR_PALETTE.NEUTRAL_BASE}
              $weight={FONT_WEIGHT.BOLD}
            >
              {value}
            </Body2>
          ),
        }}
      />
    </Body2>
  );
}
