import { DropdownFilter } from '../../../../../../partials/DataTable';
import { NO_FILTER_OPTION } from '../../../../partials/Filters/constants';

const OPTIONS = [
  {
    id: NO_FILTER_OPTION,
    value: null,
    label: 'All',
  },
  {
    id: 'active',
    value: 'active',
    label: 'Active',
  },
  {
    id: 'about-to-expire',
    value: 'about-to-expire',
    label: 'About to expire',
  },
  {
    id: 'grace-period',
    value: 'grace-period',
    label: 'Grace period',
  },
  {
    id: 'free',
    value: 'free',
    label: 'Free',
  },
];

export default function StatusFilter() {
  return <DropdownFilter label="Status" source="status" options={OPTIONS} />;
}
