import { useSelector } from 'reffects-store';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { divider, DIVIDER_SIZE } from 'design-system/styles/divider';
import { useCallback } from 'react';
import styled from 'styled-components';
import { OutlineButton } from 'design-system/components/Button/presets';
import { publisherBackgroundImageSelector } from '../../../selectors/publisher';
import PublisherInfo from './PublisherInfo';
import { BackgroundImage } from './BackgroundImage';
import { useDialog } from '../../../hooks/useDialog';
import { PUBLISHER_PROFILE_EDIT_DIALOG } from '../constants';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { publisherFormDataSelector } from '../selectors';

const Wrapper = styled.div`
  ${divider.bottom(DIVIDER_SIZE.THIN)}
  ${spacing.stack(SPACING_SIZE.L, false)}
`;

const VisualShiftUp = styled.div`
  margin-top: -${spacing.value(SPACING_SIZE.L)};
`;

export default function Hero() {
  const { openDialog } = useDialog(PUBLISHER_PROFILE_EDIT_DIALOG);
  const publisherBackgroundSource = useSelector(
    publisherBackgroundImageSelector
  );
  const publisherFormData = useSelector(publisherFormDataSelector);
  const handleEditClick = useCallback(
    () => openDialog(publisherFormData),
    [publisherFormData]
  );

  return (
    <Wrapper>
      <BackgroundImage
        placeholderText={
          <FormattedMessageWithValidation id="company_profile_hero_placeholder_text" />
        }
        source={publisherBackgroundSource}
      />
      <VisualShiftUp>
        <PublisherInfo
          cta={
            <OutlineButton onClick={handleEditClick}>
              <FormattedMessageWithValidation id="company_profile_edit_page_cta" />
            </OutlineButton>
          }
        />
      </VisualShiftUp>
    </Wrapper>
  );
}
