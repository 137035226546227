import { ACTIVITY_PERIOD_LAST_3_MONTHS } from './partials/LeadInformation/partials/LeadPlus/constants';

export function leadSelector(state) {
  return state.lead;
}

export function hasLeadPlusSelector(state) {
  return Boolean(leadSelector(state)?.hasLeadPlus);
}

export function hasQualificationSelector(state) {
  return Boolean(leadSelector(state)?.hasQualification);
}

export function leadIsSuperHotSelector(state) {
  return Boolean(state.leadHotness?.isSuperHot);
}

export function leadIsHotSelector(state) {
  return Boolean(state.leadHotness?.isHot);
}

export function isLoadingSelector(state) {
  return leadSelector(state) == null;
}

export function distinctListingEnquiriesSelector(state) {
  return state.leadPlus?.distinctListingEnquiriesCount ?? 0;
}

export function activityPeriodSelector(state) {
  return state['lead:activityPeriod'] ?? ACTIVITY_PERIOD_LAST_3_MONTHS;
}

export function searchingSinceDateSelector(state) {
  const date = leadSelector(state)?.firstVisitDate;
  return date ? new Date(date) : undefined;
}
