import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import Tabs from 'design-system/components/Tabs';
import { border, BORDER_SIZE } from 'design-system/styles/border';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { COLOR_PALETTE } from 'design-system/styles/color';
import CreateNote from './CreateNote';
import SendReplyEmail from './SendReplyEmail';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { leadSelector } from '../../../selectors';
import useClickTracking from '../../../../../hooks/useClickTracking';
import { Skeleton } from './Skeleton';
import {
  CREATE_NOTE_ID,
  defaultActiveTabSelector,
  emailReplyIsEnableSelector,
  emailReplyTooltipMessageIdSelector,
  SEND_EMAIL_ID,
} from './selectors';

const Wrapper = styled.div`
  margin: ${spacing.value(SPACING_SIZE.XL)};
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  ${radius.regular(RADIUS_SIZE.LARGE)}
  ${border.outer({ color: COLOR_PALETTE.NEUTRAL_A10, size: BORDER_SIZE.BASE })}
`;

function Actions() {
  const lead = useSelector(leadSelector);
  const emailReplyIsEnable = useSelector(emailReplyIsEnableSelector);
  const tooltipMessageId = useSelector(emailReplyTooltipMessageIdSelector);
  const { trackClick } = useClickTracking({
    sectionName: 'enquiry-activity-timeline-bottom-input',
  });
  const disableReplyLead = !emailReplyIsEnable;
  const defaultActive = useSelector(defaultActiveTabSelector);
  return (
    <Wrapper>
      <Box>
        <Tabs defaultActive={defaultActive}>
          <Tabs.Section
            id={SEND_EMAIL_ID}
            title={
              <FormattedMessageWithValidation id="enquiry_detail_email_reply" />
            }
            tooltip={
              disableReplyLead && (
                <FormattedMessageWithValidation id={tooltipMessageId} />
              )
            }
            disabled={disableReplyLead}
            onClick={() =>
              trackClick({
                eventName: 'reply-email-tab-selected',
              })
            }
          >
            <SendReplyEmail />
          </Tabs.Section>
          <Tabs.Section
            id={CREATE_NOTE_ID}
            title={
              <FormattedMessageWithValidation id="enquiry_detail_note_header" />
            }
            onClick={() =>
              trackClick({
                eventName: 'create-note-tab-selected',
              })
            }
          >
            <CreateNote leadId={lead.Id} />
          </Tabs.Section>
        </Tabs>
      </Box>
    </Wrapper>
  );
}

Actions.Skeleton = Skeleton;

export default Actions;
