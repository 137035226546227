import { memoize } from 'lodash';
import { dispatch } from 'reffects';
import { NAVIGATE_TO } from '../../../events/routing';

function buildTableRowEventsRedirectingTo(
  pathTemplate,
  dispatchEvent = dispatch
) {
  const handleRowClick = (itemId, e) => {
    const path = pathTemplate(itemId);

    if (e.ctrlKey) {
      dispatchEvent({ id: NAVIGATE_TO, payload: { path, newTab: true } });
      return;
    }

    dispatchEvent({ id: NAVIGATE_TO, payload: { path } });
  };

  const handleRowAuxClick = (itemId, e) => {
    if (e.button === 1) {
      const path = pathTemplate(itemId);
      dispatchEvent({ id: NAVIGATE_TO, payload: { path, newTab: true } });
    }
  };

  return {
    onClick: handleRowClick,
    onAuxClick: handleRowAuxClick,
  };
}

export default memoize(buildTableRowEventsRedirectingTo);
