import { registerEventHandler } from 'reffects';
import { analytics } from '../../effects/analytics';

export const TRACK_CLICK = 'TRACK_CLICK';
export const TRACK_ELEMENT_IS_VISIBLE = 'TRACK_ELEMENT_IS_VISIBLE';
export const TRACK_PAGE_VIEW_MOUNTED = 'TRACK_PAGE_VIEW_MOUNTED';

export default function registerLinkTrackingEvents() {
  registerEventHandler(TRACK_CLICK, (_, { eventName, sectionName, payload }) =>
    analytics.trackClick(eventName, sectionName, payload)
  );

  registerEventHandler(
    TRACK_PAGE_VIEW_MOUNTED,
    (_, { pageViewName, payload }) =>
      pageViewName ? analytics.trackPageView(pageViewName, payload) : {}
  );

  registerEventHandler(TRACK_ELEMENT_IS_VISIBLE, (_, payload) =>
    analytics.trackImpression(
      payload.name,
      payload.sectionName,
      payload.visible
    )
  );
}
