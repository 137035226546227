import styled from 'styled-components';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import {
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import InboundBubble from './InboundBubble';
import PropertySnippet from './PropertySnippet';
import ProjectSnippet from './ProjectSnippet';
import { Cloud } from './BaseBubble';

const MissedCall = styled.div`
  display: inline-flex;
  align-items: center;
  ${spacing.stack(SPACING_SIZE.XS, false)}

  ${Body1} {
    ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
    ${typography.weight(FONT_WEIGHT.LIGHT)}
  }

  > * {
    ${spacing.inline(SPACING_SIZE.S)}
  }
`;

const MissedCallInboundBubble = styled(InboundBubble)`
  ${Cloud} ${Subtitle3} {
    ${color.text(COLOR_PALETTE.STRAWBERRY_BASE)}
  }
  ${Cloud} {
    ${color.background(COLOR_PALETTE.STRAWBERRY_A10)}
  }
  ${Cloud}::after {
    ${color.background(COLOR_PALETTE.STRAWBERRY_A10)}
  }
`;
const MissedCallStatusIcon = styled(Icon)`
  ${spacing.inline(SPACING_SIZE.S)}
  ${color.text(COLOR_PALETTE.STRAWBERRY_BASE)}
  ${Icon.mods.size(ICON_SIZE.SMALL)}
`;

const CallExplanation = styled.span`
  ${color.text(COLOR_PALETTE.NEUTRAL_A80)}
  ${typography.font(FONT_FAMILY.PRIMARY)}
  ${typography.size(FONT_SIZE.XS)}
  ${spacing.stack(SPACING_SIZE.XS, false)}
`;

function MissedCallLeadBubble({
  phone,
  ad,
  project,
  callStatus,
  duration,
  ...rest
}) {
  return (
    <MissedCallInboundBubble
      title={<FormattedMessageWithValidation id="enquiries_call_incoming" />}
      {...rest}
    >
      <MissedCall>
        <MissedCallStatusIcon glyph="callIncoming" />
        <Body1>{phone}</Body1>
      </MissedCall>
      <CallExplanation>
        <FormattedMessageWithValidation id="enquiry_detail_call_activity" />
      </CallExplanation>
      {ad ? (
        <PropertySnippet property={ad} project={project} />
      ) : (
        <ProjectSnippet project={project} />
      )}
    </MissedCallInboundBubble>
  );
}

export default MissedCallLeadBubble;
