import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { TextButton } from 'design-system/components/Button/presets';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle1 } from 'design-system/components/Typography/presets/Subtitle1';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import useTracking from '../../../../hooks/useTracking';
import { SUBSCRIPTION_BANK_TRANSFER_DETAILS } from '../../../../constants/routes';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Wrapper = styled.div`
  ${spacing.stack(SPACING_SIZE.XL)}
`;

const PaymentInformationSection = styled.section`
  ${spacing.stack(SPACING_SIZE.L)}
`;

const PaymentDetails = styled.div`
  ${spacing.stack(SPACING_SIZE.L)};

  > * {
    display: block;
  }
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  ${spacing.stack(SPACING_SIZE.M)};

  ${Subtitle1} {
    flex: 1;
  }
`;

const BlockBody1 = styled(Body1)`
  display: block;
  margin-top: ${SPACING_SIZE.XS}px;
  ${typography.weight(FONT_WEIGHT.LIGHT)}
`;

function BankTransferPaymentInformation() {
  const trackingRef = useTracking({
    trackClick: {
      eventName: 'link-view-details-bank-transfer',
    },
    sectionName: 'my-subscription',
  });
  return (
    <Wrapper>
      <PaymentInformationSection>
        <PaymentDetails>
          <TitleRow>
            <Subtitle1>
              <FormattedMessageWithValidation id="subscription_payment_information_title" />
            </Subtitle1>
            <TextButton
              forwardedAs={Link}
              to={SUBSCRIPTION_BANK_TRANSFER_DETAILS}
              ref={trackingRef}
            >
              <FormattedMessageWithValidation id="subscription_payment_information_view_details" />
            </TextButton>
          </TitleRow>
          <Subtitle3>
            <FormattedMessageWithValidation id="agencyprofile_payments_method" />
          </Subtitle3>
          <BlockBody1>
            <FormattedMessageWithValidation id="payment_method_name_bank_transfer" />
          </BlockBody1>
        </PaymentDetails>
      </PaymentInformationSection>
    </Wrapper>
  );
}

export default styled(BankTransferPaymentInformation)`
  ${Body1} {
    display: block;
  }
`;
