import { SecondaryButton } from 'design-system/components/Button/presets';
import { useCallback } from 'react';
import Dialog from 'design-system/components/Dialog/Dialog';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { useDialog } from '../../../../../hooks/useDialog';
import { ENQUIRY_DETAIL_SEND_REPLY_EMAIL_ERROR_DIALOG } from '../constants';

export default function SendReplyEmailErrorDialog() {
  const { open, closeDialog } = useDialog(
    ENQUIRY_DETAIL_SEND_REPLY_EMAIL_ERROR_DIALOG
  );

  const onConfirm = useCallback(() => closeDialog(), [closeDialog]);

  return (
    <Dialog open={open}>
      <Dialog.Header>
        <FormattedMessageWithValidation id="Generic_message_sth_went_wrong" />
      </Dialog.Header>
      <Dialog.Footer>
        <SecondaryButton onClick={onConfirm}>
          <FormattedMessageWithValidation id="Generic_message_sth_went_CTA" />
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}
