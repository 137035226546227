import appStoreLogoUrlEN from './logos/app_store_logo_EN.svg';
import appStoreLogoUrlES from './logos/app_store_logo_ES.svg';
import appStoreLogoUrlTH from './logos/app_store_logo_TH.svg';
import googlePlayLogoUrlEN from './logos/google_play_logo_EN.svg';
import googlePlayLogoUrlES from './logos/google_play_logo_ES.svg';
import googlePlayLogoUrlTH from './logos/google_play_logo_TH.svg';

import { localeSelector } from '../../selectors/user';

export function googlePlayStoreLogoUrlSelector(state) {
  const locale = localeSelector(state);
  if (locale === 'th-TH') {
    return googlePlayLogoUrlTH;
  }
  if (locale === 'en-PH' || locale === 'en-US') {
    return googlePlayLogoUrlEN;
  }
  return googlePlayLogoUrlES;
}

export function appleAppStoreLogoUrlSelector(state) {
  const locale = localeSelector(state);
  if (locale === 'th-TH') {
    return appStoreLogoUrlTH;
  }
  if (locale === 'en-PH' || locale === 'en-US') {
    return appStoreLogoUrlEN;
  }
  return appStoreLogoUrlES;
}
