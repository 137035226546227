import { useMount } from 'react-use';
import { dispatch } from 'reffects';
import { PRIVATE_REDIRECT } from './events';

export default function PrivateRedirect({ to }) {
  useMount(() => {
    dispatch({ id: PRIVATE_REDIRECT, payload: { to } });
  });

  return null;
}
