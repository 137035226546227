import { registerEffectHandler, dispatch as reffectsDispatch } from 'reffects';

const OPTIMIZE_IMAGES = 'optimizeImages';

export default function registerOptimizeImagesEffect(
  optimizer,
  dispatch = reffectsDispatch
) {
  function dispatchEvent(event, data) {
    if (typeof event === 'string') {
      dispatch({ id: event, payload: [data] });
    } else if (event.payload) {
      dispatch({ id: event.id, payload: [data, event.payload] });
    } else {
      dispatch({ id: event.id, payload: [data] });
    }
  }
  registerEffectHandler(
    OPTIMIZE_IMAGES,
    ({ images, successEvent, errorEvent, options }) => {
      optimizer({
        images,
        onSuccess(optimizedFiles) {
          dispatchEvent(successEvent, optimizedFiles);
        },
        onError(error) {
          dispatchEvent(errorEvent, { error });
        },
        options,
      });
    }
  );
}

export function optimizeImages({ images, successEvent, errorEvent, options }) {
  return {
    [OPTIMIZE_IMAGES]: {
      images,
      successEvent,
      errorEvent,
      options,
    },
  };
}
