import styled from 'styled-components';
import Typography from 'design-system/components/Typography/Typography';
import {
  FONT_FAMILY,
  FONT_SIZE,
  typography,
} from 'design-system/styles/typography';
import { SPACING_SIZE } from 'design-system/styles/spacing';

const Text = styled(Typography)`
  text-anchor: end;
  ${typography.size(FONT_SIZE.XS)}
  ${typography.font(FONT_FAMILY.PRIMARY)}
`;

export default function AreaChartYTick({
  x,
  y,
  payload,
  index,
  visibleTicksCount,
  renderText,
}) {
  return (
    <g className="AreaChartYTick" transform={`translate(${x},${y})`}>
      <Text
        as="text"
        x={0}
        y={0}
        dy={yPositionByIndex(index, visibleTicksCount)}
      >
        {renderText(payload)}
      </Text>
    </g>
  );
}

function yPositionByIndex(index, visibleTicksCount) {
  return (
    {
      0: 0,
      [visibleTicksCount - 1]: SPACING_SIZE.S,
    }[`${index}`] ?? SPACING_SIZE.XS
  );
}
