import Layout from 'design-system/components/Layout';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Anchor from 'design-system/components/Anchor';
import { PrimaryButton } from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import Illustration from 'design-system/components/Illustration/Illustration';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import Typography, {
  TYPOGRAPHY_VARIANT,
} from 'design-system/components/Typography/Typography';
import { Navigation } from '../../../partials';
import { propertiesList } from '../../../utils/proppitWebRouter';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import useTracking from '../../../hooks/useTracking';

const Container = styled.div`
  display: flex;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
`;

const Button = styled(PrimaryButton)`
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const GreenBox = styled.div`
  ${color.background(COLOR_PALETTE.PRIMARY_A05)}
  display: flex;
  width: 802px;
  padding: 50px 16px 24px 16px;
  gap: 16px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 42px;

  a > span > span {
    color: white;
  }

  ${Anchor} {
    ${Typography.mods.variant(TYPOGRAPHY_VARIANT.BODY_2)}
  }

  svg {
    margin-top: -100px;
  }

  span {
    color: #000;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

function UploadCSVFinalStage() {
  const ref = useTracking({
    trackImpression: {
      elementName: 'upload-csv-final-stage',
    },
    sectionName: 'upload-csv-section',
  });

  return (
    <>
      <Layout independentScroll>
        <Layout.Header>
          <Navigation />
        </Layout.Header>
        <Layout.Content>
          <Layout.Padded>
            <Container>
              <Wrapper>
                <GreenBox>
                  <Illustration name="createProperty" />
                  <span>
                    <FormattedMessageWithValidation id="upload_csv_processing_file" />
                  </span>
                  <span>
                    <Button
                      $size={BUTTON_SIZE.SMALL}
                      forwardedAs={Link}
                      to={propertiesList()}
                      ref={ref}
                    >
                      <FormattedMessageWithValidation id="upload_csv_manage_properties" />
                    </Button>
                  </span>
                </GreenBox>
              </Wrapper>
            </Container>
          </Layout.Padded>
        </Layout.Content>
      </Layout>
    </>
  );
}

export default UploadCSVFinalStage;
