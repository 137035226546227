import { DropdownFilter } from '../../../../../../partials/DataTable';
import { NO_FILTER_OPTION } from '../../../../partials/Filters/constants';
import {
  lastMonthRange,
  monthRange,
  nextMonthRange,
  toUTCDate,
  weekRange,
} from '../../../../../../utils/dates';
import dateFilterRenderLabel from '../../../../../../partials/DataTable/components/filters/dateFilterRenderLabel';

const [currentWeekStart, currentWeekEnd] = weekRange();
const [currentMonthStart, currentMonthEnd] = monthRange();
const [lastMonthStart, lastMontEnd] = lastMonthRange();
const [nextMonthStart, nextMonthEnd] = nextMonthRange();

const OPTIONS = [
  {
    id: NO_FILTER_OPTION,
    value: null,
    label: 'All',
  },
  {
    id: 'this-week',
    value: {
      startDate: toUTCDate(currentWeekStart),
      endDate: toUTCDate(currentWeekEnd),
    },
    label: 'This week',
  },
  {
    id: 'this-month',
    value: {
      startDate: toUTCDate(currentMonthStart),
      endDate: toUTCDate(currentMonthEnd),
    },
    label: 'This month',
  },
  {
    id: 'last-month',
    value: {
      startDate: toUTCDate(lastMonthStart),
      endDate: toUTCDate(lastMontEnd),
    },
    label: 'Last month',
  },
  {
    id: 'next-month',
    value: {
      startDate: toUTCDate(nextMonthStart),
      endDate: toUTCDate(nextMonthEnd),
    },
    label: 'Next month',
  },
];

export default function RenewalFilter() {
  return (
    <DropdownFilter
      label="Renewal"
      source="billingDate"
      options={OPTIONS}
      renderLabel={dateFilterRenderLabel}
    />
  );
}
