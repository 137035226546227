import styled from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { border, BORDER_SIZE } from 'design-system/styles/border';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';

const Tile = styled.div`
  ${spacing.inset(SPACING_SIZE.M)};
  ${radius.regular(RADIUS_SIZE.LARGE)}
  ${color.background(COLOR_PALETTE.NEUTRAL_A00)};
  ${border.outer({
    size: BORDER_SIZE.BASE,
    color: COLOR_PALETTE.NEUTRAL_A10,
  })};
`;

export default Tile;
