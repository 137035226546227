import { withoutEventPropagation } from 'design-system/utils/withoutEventPropagation';
import { useCallback } from 'react';
import { dispatch, dispatchMany } from 'reffects';
import { useSelector } from 'reffects-store';
import Menu from 'design-system/components/Menu/Menu';
import {
  oneOf,
  userIsFinanceManagerSelector,
  userIsFinanceSelector,
  userIsSalesManagerSelector,
  userIsSuperadminSelector,
} from '../../../../../../selectors/user';
import { OPEN_DIALOG } from '../../../../../../events/dialogs';
import { CONFIRM_SUBSCRIPTION_CHANGES_MODAL } from '../Modals/ChangeSubscriptionModal/constants';
import { PLANS_PER_COUNTRY_REQUESTED } from '../../../../events';
import { CHANGE_SUBSCRIPTION_ACTION_REQUESTED } from '../Modals/ChangeSubscriptionModal/events';
import { CHANGE_REQUEST_STATUS } from '../../../../../constants/subscription';
import { GENERIC_ALERT_MODAL } from '../../../../../../partials/modals/generic/AlertDialog';

function shouldShowWarningModal({ subscriptionChangeRequests }) {
  const pendingStatus = [
    CHANGE_REQUEST_STATUS.PENDING,
    CHANGE_REQUEST_STATUS.PAID,
  ];
  return subscriptionChangeRequests.some(({ status }) =>
    pendingStatus.includes(status)
  );
}

function shouldDisallowAction({ subscriptionChangeRequests }) {
  return subscriptionChangeRequests.some(
    ({ status, billingStatus }) =>
      status === CHANGE_REQUEST_STATUS.ACTIVATED &&
      billingStatus !== 'conciliated'
  );
}

function chooseModal(
  publisher,
  showDisallowModal,
  showWarningModal,
  showEditorModal
) {
  if (shouldDisallowAction(publisher)) {
    return showDisallowModal;
  }
  return shouldShowWarningModal(publisher) ? showWarningModal : showEditorModal;
}

export function ChangeSubscriptionAction({ publisher }) {
  const showEditorModal = useCallback(() => {
    dispatchMany([
      {
        id: PLANS_PER_COUNTRY_REQUESTED,
        payload: {
          countryCode: publisher.country,
          publishInThailandProperty: publisher.publishesInThailandProperty,
        },
      },
      {
        id: CHANGE_SUBSCRIPTION_ACTION_REQUESTED,
        payload: {
          publisherId: publisher.id,
        },
      },
    ]);
  }, [publisher]);

  const showWarningModal = useCallback(() => {
    dispatch({
      id: OPEN_DIALOG,
      payload: {
        id: CONFIRM_SUBSCRIPTION_CHANGES_MODAL,
        parameters: {
          publisher,
        },
      },
    });
  }, []);
  const showDisallowModal = useCallback(() => {
    dispatch({
      id: OPEN_DIALOG,
      payload: {
        id: GENERIC_ALERT_MODAL,
        parameters: {
          messages: {
            title: {
              id: "Can't modify current subscription",
            },
            body: {
              id: "This client has a subscription that you can't modify until it is conciliated.",
            },
            confirmButton: { id: 'Close' },
          },
        },
      },
    });
  }, []);
  const canModifySubscription = useSelector(
    oneOf(
      userIsSuperadminSelector,
      userIsFinanceSelector,
      userIsFinanceManagerSelector,
      userIsSalesManagerSelector
    )
  );
  if (publisher.planType === 'free' || !canModifySubscription) {
    return null;
  }
  const showModal = chooseModal(
    publisher,
    showDisallowModal,
    showWarningModal,
    showEditorModal
  );
  return (
    <Menu.Item
      label="Modify current subscription"
      onClick={withoutEventPropagation(showModal)}
    />
  );
}
