import { useMount } from 'react-use';
import { dispatchMany } from 'reffects';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import Tip from 'design-system/components/Tip';
import Section from '../Section';
import {
  displayableDealSelector,
  selectedDealSelector,
} from '../../partials/DealsAutocomplete/selectors';
import { selectedSalesPersonEmailSelector } from './selectors';
import DealsAutocomplete from '../../partials/DealsAutocomplete/DealsAutocomplete';
import { isMultipleAccountClientSharingSubscriptionSelector } from '../selectors';
import DealOwner from '../../partials/DealOwner';
import { SALES_PEOPLE_REQUESTED } from '../../events';
import { CREATE_PUBLISHER_PAGE } from '../constants';
import { DEALS_REQUESTED } from '../../partials/DealsAutocomplete/events';

const DealNotMandatoryTip = styled(Tip)`
  ${color.background(COLOR_PALETTE.PUMPKIN_A10)}
  ${spacing.inset(SPACING_SIZE.S)}
`;

export default function Deals() {
  useMount(() => dispatchMany([DEALS_REQUESTED, SALES_PEOPLE_REQUESTED]));
  const selectedDeal = useSelector((state) =>
    selectedDealSelector(state, CREATE_PUBLISHER_PAGE)
  );
  const displayableValue = useSelector((state) =>
    displayableDealSelector(state, CREATE_PUBLISHER_PAGE)
  );
  const selectedSalesPersonEmail = useSelector(
    selectedSalesPersonEmailSelector
  );
  const isNewPublisherSharingSubscription = useSelector(
    isMultipleAccountClientSharingSubscriptionSelector
  );
  return isNewPublisherSharingSubscription ? (
    <Section columns={1} title="HUBSPOT DEAL">
      <DealNotMandatoryTip>
        Child account will be associated to main account.
      </DealNotMandatoryTip>
    </Section>
  ) : (
    <Section columns={2} title="HUBSPOT DEAL">
      <DealsAutocomplete
        stateRegion={CREATE_PUBLISHER_PAGE}
        displayableValue={displayableValue}
      />
      <DealOwner
        salesPersonEmail={selectedSalesPersonEmail}
        deal={selectedDeal}
      />
    </Section>
  );
}
