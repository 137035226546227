import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import queryString from 'query-string';
import { environment } from '../../../../../../coeffects/environment';
import { mapTableFiltersToApiFilters } from '../../../../../../partials/DataTable/utils/mapTableToApiFilters';

export const STATS_CARDS_REQUESTED = 'STATS_CARDS_REQUESTED';
export const STATS_CARDS_REQUEST_SUCCEED = 'STATS_CARDS_REQUEST_SUCCEED';
export const STATS_CARDS_REQUEST_FAILED = 'STATS_CARDS_REQUEST_FAILED';

registerEventHandler(
  STATS_CARDS_REQUESTED,
  ({ environment: { apiUrl } }, { filters }) => {
    const apiFilters = mapTableFiltersToApiFilters({
      ...filters,
    });
    const query = queryString.stringify(
      {
        ...apiFilters,
      },
      { skipNull: true }
    );

    return {
      ...http.get({
        url: `${apiUrl}/backoffice/agencies-stats${query && `?${query}`}`,
        successEvent: STATS_CARDS_REQUEST_SUCCEED,
        errorEvent: STATS_CARDS_REQUEST_FAILED,
      }),
    };
  },
  [environment()]
);

registerEventHandler(STATS_CARDS_REQUEST_SUCCEED, (_, [response]) => ({
  ...state.set({
    'backoffice.agencies.stats': {
      renewalsMonth: response.data.renewalsMonth,
      renewalsWeek: response.data.renewalsWeek,
      paidAgencies: response.data.paidAgencies,
      freeAgencies: response.data.freeAgencies,
    },
  }),
}));

registerEventHandler(STATS_CARDS_REQUEST_FAILED, () => {});
