import { useSelector } from 'reffects-store';
import { PrimaryButton } from 'design-system/components/Button/presets';
import Illustration from 'design-system/components/Illustration/Illustration';
import EmptyState from 'design-system/components/EmptyState';
import FormattedMessageWithTranslatedCountry from '../../../../../partials/FormattedMessageWithTranslatedCountry/FormattedMessageWithCountry';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { supportEmailSelector } from '../../../../../selectors/config';

export function TableEmptyState() {
  const supportEmail = useSelector(supportEmailSelector);

  return (
    <EmptyState
      illustration={<Illustration name="buildings" />}
      title={
        <FormattedMessageWithTranslatedCountry id="overview_developer_projects_summary_table_empty_state_title" />
      }
      description={
        <FormattedMessageWithValidation id="overview_developer_projects_summary_table_empty_state_description" />
      }
      action={
        <PrimaryButton
          forwardedAs="a"
          href={`mailto:${supportEmail}`}
          target="_blank"
        >
          <FormattedMessageWithValidation id="overview_developer_projects_summary_table_empty_state_cta" />
        </PrimaryButton>
      }
    />
  );
}
