import { dispatch } from 'reffects';
import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { useCallback } from 'react';
import { FormattedList } from 'react-intl';
import Checkbox from 'design-system/components/Checkbox/Checkbox';
import { FlatButton } from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';
import Button, {
  BUTTON_ICON_POSITION,
  BUTTON_SIZE,
} from 'design-system/components/Button';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import Typography from 'design-system/components/Typography/Typography';
import { FONT_SIZE } from 'design-system/styles/typography';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { UNITS_BULK_ACTION_REQUESTED } from './events';
import {
  areAllPropertiesChecked,
  countSelectedViewableUnitsSelector,
  isBulkActionInProgressAction,
  isBulkMenuOpenSelector,
  isUnitBulkCheckboxIndeterminateSelector,
  selectedViewableUnitsSelector,
} from './selectors';
import { useDialog } from '../../../../../hooks/useDialog';
import { siteWherePublishingNamesSelector } from '../../../../../selectors/config';
import { GENERIC_CONFIRMATION_MODAL } from '../../../../../partials/modals/generic/constants';

const Wrapper = styled.tr`
  position: absolute;
  width: 100%;
  top: 0;
  height: 42px;
  ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
  display: ${(props) => (props.open ? 'table' : 'none')};
`;

const Banner = styled.td`
  position: absolute;
  width: 100%;
  top: -2px;
  padding: 0;
  display: flex;
  align-items: center;
  box-shadow: 0px -1px 0px #fff inset;

  ${radius.regular(RADIUS_SIZE.LARGE)}
  ${color.background(COLOR_PALETTE.NEUTRAL_A05)}
`;

const Selections = styled.div`
  display: flex;
  align-items: center;
  padding: ${spacing.value(0)} ${spacing.value(16)};
`;

const SelectionsCheck = styled(Checkbox)`
  ${spacing.inline(SPACING_SIZE.M, false)}
`;

const SelectionsText = styled(Body1)`
  line-height: 16px;
  ${Typography.mods.size(FONT_SIZE.S)}
  ${Typography.mods.color(COLOR_PALETTE.NEUTRAL_A60)}
`;

const Divider = styled.span`
  height: 24px;
  width: 1px;
  opacity: 0.3;
  ${color.background(COLOR_PALETTE.NEUTRAL_A40)}
`;

const Actions = styled.div`
  padding: ${spacing.value(6)} ${spacing.value(16)};
`;

const ActionButton = styled(FlatButton).attrs(({ iconGlyph }) => ({
  icon: <Icon glyph={iconGlyph} />,
}))`
  ${spacing.inline(SPACING_SIZE.S)}
  ${Button.mods.size(BUTTON_SIZE.SMALL)}
  ${Button.mods.iconPosition(BUTTON_ICON_POSITION.START)}
`;

function BulkActionsMenu({ onChangeSelectedItems }) {
  const { openDialog } = useDialog(GENERIC_CONFIRMATION_MODAL);

  const open = useSelector(isBulkMenuOpenSelector);
  const selectedItems = useSelector(countSelectedViewableUnitsSelector);
  const isFullyChecked = useSelector(areAllPropertiesChecked);
  const isIndeterminate = useSelector(isUnitBulkCheckboxIndeterminateSelector);
  const bulkUnitIds = useSelector(selectedViewableUnitsSelector);
  const webs = useSelector(siteWherePublishingNamesSelector);
  const bulkActionInProgress = useSelector(isBulkActionInProgressAction);

  const handlePublish = useCallback(() => {
    dispatch({
      id: UNITS_BULK_ACTION_REQUESTED,
      payload: {
        action: 'publish',
        bulkUnitIds,
      },
    });
  }, [bulkUnitIds]);

  const handleUnpublish = useCallback(() => {
    openDialog({
      messages: {
        title: {
          id: 'units_page_bulk_unpublish_title',
        },
        body: {
          id: 'units_page_bulk_unpublish_text',
          values: {
            n: selectedItems,
            networkSites: <FormattedList type="conjunction" value={webs} />,
          },
        },
      },
      actions: {
        confirm: {
          id: UNITS_BULK_ACTION_REQUESTED,
          payload: {
            action: 'unpublish',
            bulkUnitIds,
          },
        },
      },
    });
  }, [bulkUnitIds, selectedItems, webs]);

  const handleDelete = useCallback(() => {
    openDialog({
      messages: {
        title: {
          id: 'units_page_bulk_delete_title',
        },
        body: {
          id: 'units_page_bulk_delete_text',
          values: {
            n: selectedItems,
          },
        },
      },
      actions: {
        confirm: {
          id: UNITS_BULK_ACTION_REQUESTED,
          payload: {
            action: 'delete',
            bulkUnitIds,
          },
        },
      },
    });
  }, [bulkUnitIds, selectedItems]);

  return (
    <Wrapper open={open}>
      <Banner>
        <Selections>
          <SelectionsCheck
            onChange={onChangeSelectedItems}
            checked={isFullyChecked}
            indeterminate={isIndeterminate}
          />
          <SelectionsText>
            {selectedItems}{' '}
            <FormattedMessageWithValidation id="units_page_bulk_n_units_selected" />
          </SelectionsText>
        </Selections>
        <Divider />
        <Actions>
          <ActionButton
            disabled={bulkActionInProgress}
            onClick={handlePublish}
            iconGlyph="eye"
          >
            <FormattedMessageWithValidation id="unit_bulk_publish_cta" />
          </ActionButton>
          <ActionButton
            disabled={bulkActionInProgress}
            onClick={handleUnpublish}
            iconGlyph="eyeCrossed"
          >
            <FormattedMessageWithValidation id="unit_bulk_unpublish_cta" />
          </ActionButton>
          <ActionButton
            disabled={bulkActionInProgress}
            onClick={handleDelete}
            iconGlyph="trash"
          >
            <FormattedMessageWithValidation id="unit_bulk_delete_cta" />
          </ActionButton>
        </Actions>
      </Banner>
    </Wrapper>
  );
}

export default BulkActionsMenu;
