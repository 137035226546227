import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import { useController, useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import RadioButton from 'design-system/components/RadioButton/RadioButton';
import { locationVisibilitySelector } from './selectors';
import { LOCATION_VISIBILITY_CHANGED } from './events';

function LocationVisibilityField({ name, value: radioValue, ...rest }) {
  const locationVisibility = useSelector(locationVisibilitySelector);

  const { control } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  useEffect(() => {
    onChange(locationVisibility);
  }, [locationVisibility]);

  return (
    <RadioButton
      checked={value === radioValue}
      value={radioValue}
      onChange={(event) => {
        dispatch({
          id: LOCATION_VISIBILITY_CHANGED,
          payload: event.target.value,
        });
      }}
      {...rest}
    />
  );
}

export default LocationVisibilityField;
