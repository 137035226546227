export function excludeKeys(object, keys) {
  return Object.entries(object).reduce((acc, [filterName, filterValue]) => {
    if (keys.includes(filterName)) {
      return acc;
    }

    return {
      ...acc,
      [filterName]: filterValue,
    };
  }, {});
}
