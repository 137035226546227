import { useState } from 'react';
import styled, { css } from 'styled-components';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { noop } from 'design-system/utils';
import { border } from 'design-system/styles/border';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { FONT_SIZE, typography } from 'design-system/styles/typography';
import DialCodeSelectMenu from './DialCodeSelectMenu';

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background 300ms;

  ${spacing.inset(SPACING_SIZE.M)}
  ${spacing.stack(SPACING_SIZE.S)}
    ${radius.regular(RADIUS_SIZE.BASE)}
    ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
    ${typography.size(FONT_SIZE.M)}
    ${typography.lineHeight(FONT_SIZE.M)}
    ${Icon} {
    ${Icon.mods.size(ICON_SIZE.SMALL)}
    ${Icon.mods.color(COLOR_PALETTE.NEUTRAL_A60)}
  }

  ${border.inner({
    color: COLOR_PALETTE.NEUTRAL_A20,
    size: 1,
  })}
`;

const modIsFocused = (error) => css`
    ${Box} {
        ${border.inner({
          color: error
            ? COLOR_PALETTE.STRAWBERRY_BASE
            : COLOR_PALETTE.NEUTRAL_BASE,
          size: 2,
        })}
`;

const modIsError = () => css`
  ${Box} {
    ${border.inner({
      color: COLOR_PALETTE.STRAWBERRY_BASE,
      size: 1,
    })}
  }
`;

const modIsDisabled = () => css`
  svg {
    opacity: 0.2;
  }

  ${Box} {
    cursor: not-allowed;
  }
`;

const Wrapper = styled.div`
  cursor: pointer;
  position: relative;

  ${spacing.inline(SPACING_SIZE.L, false)}

  ${({ focused, error }) => focused && modIsFocused(error)}
    ${({ error }) => error && modIsError()}
    ${({ disabled }) => disabled && modIsDisabled()}
`;

const CountryIcon = styled.div`
  display: inline-flex;
  ${spacing.inline(SPACING_SIZE.S, false)}
`;

const InvisibleBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

function DialCodeSelect({
  error,
  choices,
  onSelect = noop,
  value,
  defaultSelected,
  disabled,
  ...rest
}) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    if (disabled) {
      return;
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelection = (selection) => {
    handleClose();
    onSelect(selection);
  };

  const handleCancellation = () => {
    handleClose();
  };

  return (
    <Wrapper
      data-test="dial_code_select"
      onMouseDown={handleOpen}
      error={error}
      focused={open}
      disabled={disabled}
      {...rest}
    >
      <Box>
        <CountryIcon>{value?.icon}</CountryIcon>
        <Icon glyph="chevronDown" />
      </Box>

      {open && (
        <>
          <InvisibleBackdrop onClick={handleCancellation} />
          <DialCodeSelectMenu
            handleSelection={handleSelection}
            handleCancellation={handleCancellation}
            choices={choices}
          />
        </>
      )}
    </Wrapper>
  );
}

export default styled(DialCodeSelect)``;
