import { SecondaryButton } from 'design-system/components/Button/presets';
import { useSelector } from 'reffects-store';
import Dialog from 'design-system/components/Dialog/Dialog';
import { BULK_BOOST_NOT_ALLOWED_DIALOG } from './constants';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { useDialog } from '../../../hooks/useDialog';
import { useDatatableContext } from '../../../partials/DataTable/hooks/useDatatableContext';
import { visibleSelectedItemsCountSelector } from '../../../partials/DataTable/selectors/selection';
import { supportEmailSelector } from '../../../selectors/config';
import { publisherSubscriptionPlanIsPaidSelector } from '../../../selectors/publisher';

export default function BulkBoostNotAllowedDialog() {
  const { open, closeDialog } = useDialog(BULK_BOOST_NOT_ALLOWED_DIALOG);
  const { collectionName } = useDatatableContext();
  const selectedItemsCount = useSelector((state) =>
    visibleSelectedItemsCountSelector(state, { collectionName })
  );
  const supportEmail = useSelector(supportEmailSelector);
  const isPaidPublisher = useSelector(publisherSubscriptionPlanIsPaidSelector);

  return (
    <Dialog open={open}>
      <Dialog.Header>
        <FormattedMessageWithValidation
          id={
            isPaidPublisher
              ? 'propspage_paid_bulk_deactivate_noboost_modal_title'
              : 'propspage_free_bulk_activate_boost_modal_title'
          }
        />
      </Dialog.Header>
      <Dialog.Emphasis>
        <FormattedMessageWithValidation
          id={
            isPaidPublisher
              ? 'propspage_paid_bulk_deactivate_noboost_modal_message'
              : 'propspage_free_bulk_activate_boost_modal_message'
          }
        />
      </Dialog.Emphasis>
      <Dialog.Content>
        <Dialog.Text>
          <FormattedMessageWithValidation
            id={
              isPaidPublisher
                ? 'propspage_paid_bulk_deactivate_noboost_modal_text'
                : 'propspage_free_bulk_activate_boost_modal_text'
            }
            values={{
              n: selectedItemsCount,
              supportEmail,
            }}
          />
        </Dialog.Text>
      </Dialog.Content>
      <Dialog.Footer>
        <SecondaryButton onClick={closeDialog}>
          <FormattedMessageWithValidation id="freelistings_button_continue" />
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}
