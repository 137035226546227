import styled, { css } from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { bindClassname } from '../utils';

export const BANNER_VARIANT = {
  INTERVENTION: Symbol('BANNER_VARIANTS_INTERVENTION'),
  SECONDARY: Symbol('BANNER_VARIANTS_SECONDARY'),
  ALERT: Symbol('BANNER_VARIANTS_ALERT'),
  INDICATOR: Symbol('BANNER_VARIANTS_INDICATOR'),
};

const modInterventionVariant = () => css`
  ${color.background(COLOR_PALETTE.PUMPKIN_A10)}
  ${Subtitle3} {
    ${Subtitle3.mods.color(COLOR_PALETTE.NEUTRAL_BASE)}
  }
`;

const modSecondaryVariant = () => css`
  ${color.background(COLOR_PALETTE.SECONDARY_BASE)}
  ${Subtitle3} {
    ${Subtitle3.mods.color(COLOR_PALETTE.NEUTRAL_A00)}
  }

  a {
    ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
  }
`;

const modAlertVariant = () => css`
  ${color.background(COLOR_PALETTE.STRAWBERRY_A10)}
  ${Subtitle3} {
    ${Subtitle3.mods.color(COLOR_PALETTE.NEUTRAL_BASE)}
  }
`;

const modIndicatorVariant = () => css`
  ${color.background(COLOR_PALETTE.PRIMARY_A20)}
  ${Subtitle3} {
    ${Subtitle3.mods.color(COLOR_PALETTE.NEUTRAL_BASE)}
  }
`;

const modVariant = (variantName) =>
  ({
    [BANNER_VARIANT.INTERVENTION]: modInterventionVariant,
    [BANNER_VARIANT.SECONDARY]: modSecondaryVariant,
    [BANNER_VARIANT.ALERT]: modAlertVariant,
    [BANNER_VARIANT.INDICATOR]: modIndicatorVariant,
  }[variantName]);

const Wrapper = styled.div`
  width: 100%;
  text-align: center;

  ${spacing.insetSquish(SPACING_SIZE.XL)}

  ${({ variant }) => modVariant(variant)}
`;

const Banner = ({ children, ...rest }) => {
  if (!rest.variant) {
    // eslint-disable-next-line no-param-reassign
    rest.variant = BANNER_VARIANT.INTERVENTION;
  }

  return (
    <Wrapper {...rest}>
      <Subtitle3>{children}</Subtitle3>
    </Wrapper>
  );
};

export default bindClassname(Banner, Wrapper);
