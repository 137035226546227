import { createSelector } from 'reselect';
import { userManagedCountriesSelector } from '../../../../selectors/user';

const allDealsSelector = (state) => state['admin:deals'] ?? [];

const dealsForUserCountries = createSelector(
  allDealsSelector,
  userManagedCountriesSelector,
  (deals, countries) =>
    deals.filter(
      ({ countryCode }) =>
        countries.length === 0 || countries.includes(countryCode)
    )
);

export function dealsSelector(state) {
  const deals = dealsForUserCountries(state);
  const filter = (state['admin:dealsFilter'] ?? '').toLowerCase();
  if (filter.length <= 3) {
    return [];
  }
  return deals
    .filter(({ name }) => name.toLowerCase().includes(filter))
    .slice(0, 5);
}

export function selectedDealSelector(state, stateRegion) {
  return (
    state[stateRegion]?.selectedDeal ??
    previouslySelectedDealIdSelector(state, stateRegion)
  );
}

export function displayableDealSelector(state, stateRegion) {
  return state?.[stateRegion]?.displayableDeal ?? '';
}

function previouslySelectedDealIdSelector(state, stateRegion) {
  const selectedDealId = state[stateRegion]?.previouslySelectedDealId;
  return selectedDealId
    ? allDealsSelector(state).find(({ id }) => id === selectedDealId)
    : null;
}
