import styled from 'styled-components';

function Check({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={className}
    >
      <path
        fill="currentColor"
        d="M6 10.17l6.59-6.59L14 5l-8 8-4-4 1.41-1.41L6 10.17z"
      />
    </svg>
  );
}

export default styled(Check)``;
