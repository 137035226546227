import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import Dialog from 'design-system/components/Dialog/Dialog';
import {
  FlatButton,
  SecondaryButton,
} from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import {
  buttonLabelSelector,
  requestButtonShouldBeDisabledSelector,
  selectedPublisherIsFreeSelector,
  subscriptionChangeRequestTypeValueForSelectSelector,
} from './selectors';
import {
  REQUEST_SUBSCRIPTION_CHANGE_MODAL,
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_ABORTED,
  SUBSCRIPTION_CHANGE_REQUEST_SUBMITTED,
} from './events';
import { FormWrapper } from '../../../../../../../partials/SimpleFormLayout';
import { useDialog } from '../../../../../../../hooks/useDialog';
import { RequestSubscriptionChangeForm } from './RequestSubscriptionChangeForm';

function RequestSubscriptionChangeModal({ onSuccessEvent }) {
  const { open } = useDialog(REQUEST_SUBSCRIPTION_CHANGE_MODAL);
  const buttonLabel = useSelector(buttonLabelSelector);
  const updateButtonDisabled = useSelector(
    requestButtonShouldBeDisabledSelector
  );
  const subscriptionChangeTypeValueForSelect = useSelector(
    subscriptionChangeRequestTypeValueForSelectSelector
  );
  const selectedPublisherIsFree = useSelector(selectedPublisherIsFreeSelector);

  const title = selectedPublisherIsFree
    ? `Request New Subscription`
    : 'Request change on subscription';
  return (
    <Dialog open={open}>
      <Dialog.Header
        onClose={() => dispatch(SUBSCRIPTION_CHANGE_REQUEST_MODAL_ABORTED)}
      >
        {title}
      </Dialog.Header>
      <Dialog.Content>
        <FormWrapper>
          <RequestSubscriptionChangeForm
            subscriptionChangeTypeValueForSelect={
              subscriptionChangeTypeValueForSelect
            }
          />
        </FormWrapper>
      </Dialog.Content>
      <Dialog.Footer>
        <FlatButton
          onClick={() => dispatch(SUBSCRIPTION_CHANGE_REQUEST_MODAL_ABORTED)}
          $size={BUTTON_SIZE.SMALL}
        >
          CANCEL
        </FlatButton>
        <SecondaryButton
          onClick={() =>
            dispatch({
              id: SUBSCRIPTION_CHANGE_REQUEST_SUBMITTED,
              payload: {
                onSuccessEvent,
              },
            })
          }
          $size={BUTTON_SIZE.SMALL}
          disabled={updateButtonDisabled}
        >
          {buttonLabel}
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}

export default RequestSubscriptionChangeModal;
