import { isMexico } from '../../utils/countries';

export default function filterMexicoOptionsByTypeOfClient(
  countryCode,
  selectedTypeOfClient,
  plans
) {
  if (!isMexico(countryCode)) {
    return plans;
  }

  if (!selectedTypeOfClient) {
    return [];
  }

  return plans.filter(
    ({ typeOfClient }) => typeOfClient === selectedTypeOfClient
  );
}
