import styled, { css } from 'styled-components';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { Subtitle2 } from 'design-system/components/Typography/presets/Subtitle2';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

export const Cloud = styled.div`
  border-bottom-left-radius: 0;
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  ${radius.regular(RADIUS_SIZE.LARGE)}
  ${spacing.inset(SPACING_SIZE.S)}
  background-color: var(--hotness-bubble-background-color);

  ${Subtitle3} {
    ${spacing.stack(SPACING_SIZE.XS, false)}
    ${Subtitle3.mods.color('var(--hotness-bubble-text-color)')}
  }

  ${Subtitle2} {
    ${typography.weight(FONT_WEIGHT.LIGHT)}
  }
`;

const HotnessIcon = styled.div`
  ${spacing.inset(SPACING_SIZE.XS)}
  background-color: var(--hotness-bubble-background-color);
  width: fit-content;
  align-self: flex-end;
  ${radius.pill()}
  ${Icon} {
    ${Icon.mods.size(ICON_SIZE.SMALL)}
    ${Icon.mods.color('var(--hotness-bubble-text-color)')}
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  column-gap: ${spacing.value(SPACING_SIZE.S)};
  ${({ $isSuperHot }) =>
    $isSuperHot &&
    css`
      --hotness-bubble-background-color: ${COLOR_PALETTE.STRAWBERRY_A10};
      --hotness-bubble-text-color: ${COLOR_PALETTE.STRAWBERRY_BASE};
    `}
  ${({ $isHot }) =>
    $isHot &&
    css`
      --hotness-bubble-background-color: ${COLOR_PALETTE.PUMPKIN_A10};
      --hotness-bubble-text-color: ${COLOR_PALETTE.PUMPKIN_BASE};
    `}
  ${spacing.stack(SPACING_SIZE.XS)}
`;

export function SuperHotBubble(props) {
  return (
    <Wrapper {...props} $isSuperHot>
      <Cloud>
        <Subtitle3>
          <FormattedMessageWithValidation id="leadpage_activity_bubble_title" />
        </Subtitle3>
        <Subtitle2>
          <FormattedMessageWithValidation id="leadpage_activity_bubble_as_soon_as_possible" />
        </Subtitle2>
      </Cloud>
      <HotnessIcon>
        <Icon glyph="superHot" />
      </HotnessIcon>
    </Wrapper>
  );
}

export function HotBubble(props) {
  return (
    <Wrapper {...props} $isHot>
      <Cloud>
        <Subtitle3>
          <FormattedMessageWithValidation id="leadpage_activity_bubble_title" />
        </Subtitle3>
        <Subtitle2>
          <FormattedMessageWithValidation id="leadpage_activity_bubble_in_3_months" />
        </Subtitle2>
      </Cloud>
      <HotnessIcon>
        <Icon glyph="hot" />
      </HotnessIcon>
    </Wrapper>
  );
}
