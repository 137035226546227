import { state } from 'reffects-store';
import { isEmpty as _isEmpty, keyBy, mapKeys } from 'lodash';
import { select as selectCoeffect } from '../../../pages/Properties/coeffects/select';
import { paginationSelector } from '../selectors/pagination';
import { sortingSelector } from '../selectors/sorting';
import { filtersSelector } from '../selectors/filters';
import {
  selectedItemIdsSelector,
  visibleSelectedItemIdsSelector,
} from '../selectors/selection';
import { collectionItemsByIdSelector } from '../selectors/collection';
import { combineStateSetEffects } from '../utils/combineStateSetEffects';
import { FIRST_PAGE } from '../constants';

const update = (
  collectionName,
  { collection, visibleItemsIdList, totalItems, isEmpty = totalItems === 0 }
) => {
  const indexedCollection = keyBy(collection, ({ id }) => id);

  return state.set({
    [`dataTables.${collectionName}.isEmpty`]: isEmpty,
    [`dataTables.${collectionName}.isLoading`]: false,
    [`dataTables.${collectionName}.itemsById`]: indexedCollection,
    [`dataTables.${collectionName}.visibleItemsIdList`]:
      visibleItemsIdList ?? Object.keys(indexedCollection),
    [`dataTables.${collectionName}.pagination.totalItems`]: totalItems,
  });
};

const patchItem = (collectionName, { id, ...updatedItemData }) => {
  const mutationEntries = Object.entries(updatedItemData).map(
    ([key, value]) => [
      `dataTables.${collectionName}.itemsById.${id}.${key}`,
      value,
    ]
  );

  const mutations = Object.fromEntries(mutationEntries);

  return state.set(mutations);
};

const setBulkProcessing = (
  collectionName,
  { isLoading = false, isProcessing = true } = {}
) =>
  state.set({
    [`dataTables.${collectionName}.isLoading`]: isLoading,
    [`dataTables.${collectionName}.bulkIsProcessing`]: isProcessing,
  });

const patchItemsBulk = (collectionName, items) => {
  const patchBulkItemsEffects = items.map((item) =>
    patchItem(collectionName, item)
  );

  return combineStateSetEffects(...patchBulkItemsEffects);
};

const setFilters = (collectionName, filters) => {
  if (_isEmpty(filters)) {
    return state.set({
      [`dataTables.${collectionName}.filters`]: {},
    });
  }

  const stateMutations = mapKeys(
    filters,
    (_, filterName) => `dataTables.${collectionName}.filters.${filterName}`
  );

  return state.set(stateMutations);
};

const setPagination = (
  collectionName,
  { itemsPerPage, currentPage = FIRST_PAGE }
) => {
  const stateMutationEntries = [
    [`dataTables.${collectionName}.pagination.itemsPerPage`, itemsPerPage],
    [`dataTables.${collectionName}.pagination.currentPage`, currentPage],
  ].filter(([, value]) => Boolean(value));

  return _isEmpty(stateMutationEntries)
    ? {}
    : state.set(Object.fromEntries(stateMutationEntries));
};

const setSorting = (collectionName, { field, direction = 'asc' }) =>
  state.set({
    [`dataTables.${collectionName}.sorting`]: {
      field,
      direction,
    },
  });

const select = () =>
  selectCoeffect({
    itemsById: collectionItemsByIdSelector,
    visibleSelectedItemIds: visibleSelectedItemIdsSelector,
    pagination: paginationSelector,
    sorting: sortingSelector,
    filters: filtersSelector,
  });

const selectItemsBulk = () =>
  selectCoeffect({
    selectedItemIds: selectedItemIdsSelector,
  });

export const table = {
  update,
  patchItem,
  patchItemsBulk,
  setBulkProcessing,
  setFilters,
  setPagination,
  setSorting,
  select,
  selectItemsBulk,
};
