import styled, { keyframes } from 'styled-components';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { radius } from 'design-system/styles/radius';

const spinningAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg);}
`;

const Loader = styled.div`
  position: relative;
  width: 55px;
  height: 55px;

  border: 8px solid ${COLOR_PALETTE.PRIMARY_BASE};
  border-top-color: transparent;

  ${radius.pill()}

  animation-duration: 1s;
  animation-name: ${spinningAnimation};
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

export default Loader;
