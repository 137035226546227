import styled from 'styled-components';
import Layout from 'design-system/components/Layout';
import { useSelector } from 'reffects-store';
import { useIntl } from 'react-intl';
import { dispatch } from 'reffects';
import Thumbnail from 'design-system/components/Thumbnail';
import { FlatButton } from 'design-system/components/Button/presets';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { BUTTON_SIZE } from 'design-system/components/Button';
import Map from 'design-system/components/Map';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Subtitle1 } from 'design-system/components/Typography/presets/Subtitle1';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import Typography from 'design-system/components/Typography/Typography';
import {
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import AdStatus from './AdStatus';
import PropertySpaces from './PropertySpaces';
import Description from './Description';
import Features from './Features';
import Operation from './Operation';
import Section from './Section';
import Subtitle from './Subtitle';
import ProjectInfo from './ProjectInfo';
import Amenities from './Amenities';
import NearbyLocations from './NearbyLocations';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import FormattedRelativeDate from '../../../../../partials/FormattedDate/FormattedRelativeDate';
import { isDeveloperSelector } from '../../../../../selectors/publisher';
import { PROPERTY_LOCATION_VISIBILITY } from '../../../../../constants/ad';
import { CLOSE_PROPERTY_DETAIL } from '../events';
import { showEnquiredPropertiesListSelector } from './selectors';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${Thumbnail} {
    ${Thumbnail.mods.ratio(300 / 165)}
    ${spacing.stack(SPACING_SIZE.M)}
  }
`;

const Header = styled(Layout.Padded)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 0;

  ${Subtitle3} {
    text-transform: uppercase;
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
  }

  ${FlatButton} {
    padding: 0;
  }
`;

const Title = styled(Subtitle1)`
  ${spacing.stack(SPACING_SIZE.S)}
`;

const LocationMap = styled(Map)`
  height: 280px;
  ${spacing.stack(SPACING_SIZE.M)}
`;

const SectionSpacing = styled.div`
  ${spacing.stack(SPACING_SIZE.M, false)}
`;

const TitleAddress = styled(Body1)`
  ${color.text(COLOR_PALETTE.NEUTRAL_A40)}
  ${spacing.stack(SPACING_SIZE.S)}
`;

const ProjectTitleAddress = styled(Body1)`
    display: flex;
    gap: ${SPACING_SIZE.S}px;

    ${color.text(COLOR_PALETTE.NEUTRAL_A40)}
    ${spacing.stack(SPACING_SIZE.S)}
    ${Body1} {
        ${color.text(COLOR_PALETTE.NEUTRAL_A40)}
    }

    >
`;

const LocationProject = styled(Typography)`
  ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
  ${typography.size(FONT_SIZE.M)}
    ${typography.lineHeight(17)}
    ${typography.weight(FONT_WEIGHT.NORMAL)}
`;

const LocationAddress = styled(Body2)`
  ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
  ${spacing.stack(SPACING_SIZE.M)}
`;

const CreationData = styled(Body2)`
  ${spacing.stack(SPACING_SIZE.M)}
`;

const AreaUnit = styled.span`
  margin-right: ${spacing.value(SPACING_SIZE.S)};

  &:last-child {
    margin-right: initial;
  }
`;

function PropertyDetail({
  property,
  propertySpaces,
  propertyBelongsToProject,
}) {
  const {
    imageUrl,
    title,
    address,
    referenceId,
    publishDate,
    description,
    amenities,
    nearbyLocations,
    latitude,
    longitude,
    locationVisibility,
    project,
  } = property;
  const isApproximateLocation =
    locationVisibility === PROPERTY_LOCATION_VISIBILITY.APPROXIMATE;
  const isDeveloper = useSelector(isDeveloperSelector);
  const showEnquiredPropertiesList = useSelector(
    showEnquiredPropertiesListSelector
  );
  const intl = useIntl();
  const units = {
    sqm: 'm²',
    sqwa: intl.formatMessage({ id: 'ad_form_usable_area_unit_sqwa' }),
  };

  return (
    <Wrapper>
      <Header>
        <Subtitle3>
          <FormattedMessageWithValidation id="enqdetail_property_detail_title" />
        </Subtitle3>
        {showEnquiredPropertiesList && (
          <FlatButton
            icon={<Icon glyph="cross" />}
            $size={BUTTON_SIZE.BASE}
            onClick={() => {
              dispatch(CLOSE_PROPERTY_DETAIL);
            }}
          />
        )}
      </Header>
      <Section>
        <Thumbnail source={imageUrl} />
        <Title>{title}</Title>
        {propertyBelongsToProject ? (
          <ProjectTitleAddress>
            <Icon glyph="building" $size={ICON_SIZE.XSMALL} />
            <Body1>{project.name}</Body1>
          </ProjectTitleAddress>
        ) : (
          <TitleAddress>{address}</TitleAddress>
        )}
        <CreationData>
          {referenceId ? `${referenceId} - ` : ''}
          <FormattedRelativeDate
            date={new Date(publishDate)}
            now={new Date()}
          />
        </CreationData>
      </Section>
      <Section>
        <AdStatus property={property} />
      </Section>
      <PropertySpaces>
        {propertySpaces.bedrooms != null && (
          <PropertySpaces.Space
            glyph="bedroom"
            value={propertySpaces.bedrooms}
            tag="newprop_rooms_emptystate"
          />
        )}
        {propertySpaces.bathrooms != null && (
          <PropertySpaces.Space
            glyph="bathtub"
            value={propertySpaces.bathrooms}
            tag="newprop_bathrooms_emptystate"
          />
        )}
        {propertySpaces.area && (
          <PropertySpaces.Space glyph="building" tag={propertySpaces.areaTag}>
            {propertySpaces.area.map(({ value, unit }) => (
              <AreaUnit key={unit}>
                <Heading1>{value}</Heading1>
                {units[unit] ?? unit}
              </AreaUnit>
            ))}
          </PropertySpaces.Space>
        )}
      </PropertySpaces>
      <Section>
        <Subtitle>
          <FormattedMessageWithValidation id="enqdetail_property_detail_detail_section_subtitle" />
        </Subtitle>
        <Description description={description} />
      </Section>
      {propertyBelongsToProject && <ProjectInfo project={project} />}
      <Section>
        <Subtitle>
          <FormattedMessageWithValidation id="enqdetail_property_detail_features_section_subtitle" />
        </Subtitle>
      </Section>
      <Section highlight>
        <Features property={property} />
      </Section>
      <SectionSpacing />
      <Amenities amenities={amenities} />
      <Section>
        <Subtitle>
          <FormattedMessageWithValidation id="enqdetail_property_detail_location_section_subtitle" />
        </Subtitle>
        {isDeveloper && propertyBelongsToProject && (
          <LocationProject>{project.name}</LocationProject>
        )}
        <LocationAddress>{address}</LocationAddress>
      </Section>
      <LocationMap
        coordinates={{
          latitude,
          longitude,
        }}
        showMarker={!isApproximateLocation}
        showCircle={isApproximateLocation}
      />
      <NearbyLocations nearbyLocations={nearbyLocations} />
      <Operation property={property} />
    </Wrapper>
  );
}

export default PropertyDetail;
