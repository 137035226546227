import { effects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import {
  INVALID_PASSWORD,
  MISMATCHED_PASSWORDS,
  SERVER_ERROR,
} from './constants';
import { environment } from '../../coeffects/environment';
import { LOGOUT_USER_REQUESTED } from '../../App/events';

export const CHANGE_PASSWORD_REQUESTED = 'CHANGE_PASSWORD_REQUESTED';
export const PASSWORD_CHANGED = 'PASSWORD_CHANGED';
export const PASSWORD_CHANGE_ERROR = 'PASSWORD_CHANGE_ERROR';

registerEventHandler(
  CHANGE_PASSWORD_REQUESTED,
  ({ environment: { apiUrl } }, { newPassword, confirmedPassword }) => {
    if (newPassword.length < 8) {
      return state.set({
        'changePassword:errorChangePassword': INVALID_PASSWORD,
      });
    }
    if (newPassword !== confirmedPassword) {
      return state.set({
        'changePassword:errorChangePassword': MISMATCHED_PASSWORDS,
      });
    }
    return {
      ...state.set({ 'changePassword:errorChangePassword': undefined }),
      ...http.put({
        url: `${apiUrl}/password`,
        body: JSON.stringify({ password: newPassword }),
        successEvent: PASSWORD_CHANGED,
        errorEvent: PASSWORD_CHANGE_ERROR,
      }),
    };
  },
  [environment()]
);

registerEventHandler(PASSWORD_CHANGED, () =>
  effects.dispatch({
    id: LOGOUT_USER_REQUESTED,
    payload: 'changepwdpage_confirmation',
  })
);

registerEventHandler(PASSWORD_CHANGE_ERROR, () =>
  state.set({ 'changePassword:errorChangePassword': SERVER_ERROR })
);
