import styled from 'styled-components';
import { useState } from 'react';
import { BodyWrapper, Details, JSONPretty, Title } from './ItemComponents';

const Action = styled.div`
  display: flex;
  justify-content: center;
  a {
    flex-grow: 1;
    text-decoration: none;
    text-align: center;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export function BCCommunication({ event }) {
  const [expanded, expand] = useState(false);
  let title = `${ucwords(event.bcCommunication.entity)} sent to BC`;
  function onClick(e) {
    expand(!expanded);
    e.preventDefault();
  }

  if (event.error) {
    title += ' Failed';
  }

  return (
    <BodyWrapper>
      <Title>{title}</Title>
      {!expanded && (
        <Action>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" onClick={onClick}>
            expand
          </a>
        </Action>
      )}
      {expanded && (
        <Details>
          <b>Request:</b>
          <JSONPretty data={event.bcCommunication.request_body} />
          <Action>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" onClick={onClick}>
              close
            </a>
          </Action>
        </Details>
      )}
    </BodyWrapper>
  );
}

function ucwords(str) {
  return str.replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });
}
