import styled from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import Illustration from 'design-system/components/Illustration/Illustration';

const Placeholder = styled.div.attrs({
  children: <Illustration name="imagePlaceholder" />,
})`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  ${color.background(COLOR_PALETTE.NEUTRAL_A05)}

  ${Illustration} {
    max-width: 60%;
    max-height: 60%;
    aspect-ratio: 1;

    @supports not (aspect-ratio: 1) {
      width: 100%;
    }

    ${color.text(COLOR_PALETTE.NEUTRAL_A20)}
  }
`;

export default Placeholder;
