import { isEqual, uniqWith } from 'lodash';

export function isLoadingLeadPlusSelector(state) {
  return state.leadPlus == null;
}

export function leadPlusSelectedOperationTypeSelector(state) {
  const availableOperations = availableLeadPlusOperationsSelector(state);
  if (availableOperations.length === 1) return availableOperations[0];
  const defaultOperationType = 'sell';

  const selectedOperationType = state['leadPlus:selectedOperation'];
  if (selectedOperationType) return selectedOperationType;

  const properties = state.leadEnquiredProperties;
  if (!properties || properties.length === 0) return defaultOperationType;

  const { operations } = properties[0];
  if (!operations || operations.length === 0) return defaultOperationType;

  return operations[0].type ?? defaultOperationType;
}

export function propertyTypeSelector(state) {
  if (isLoadingLeadPlusSelector(state)) {
    return undefined;
  }
  const leadEnquiredProperties = state.leadEnquiredProperties ?? [];
  if (leadEnquiredProperties.length === 0) {
    return undefined;
  }
  if (!state.selectedPropertyId) {
    return leadEnquiredProperties[0].propertyType;
  }
  return leadEnquiredProperties.find((a) => a.id === state.selectedPropertyId)
    ?.propertyType;
}

export function availableLeadPlusOperationsSelector(state) {
  if (isLoadingLeadPlusSelector(state)) {
    return [];
  }
  const operations = [];
  if ('sell' in state.leadPlus) {
    operations.push('sell');
  }
  if ('rent' in state.leadPlus) {
    operations.push('rent');
  }
  return operations;
}

export function leadPlusSummaryStatsSelector(state) {
  if (isLoadingLeadPlusSelector(state)) {
    return null;
  }
  const leadPlusForOperationType =
    leadPlusForCurrentOperationTypeSelector(state);
  if (!leadPlusForOperationType) {
    return null;
  }
  const { enquiries, views } = leadPlusForOperationType;
  return { enquiries: enquiries.count, views: views.count };
}

export function leadPlusInterestsSelector(state) {
  if (isLoadingLeadPlusSelector(state)) {
    return [];
  }
  const interests = [
    ...leadPlusForCurrentOperationTypeSelector(state).propertyTypesSummary,
  ];

  const propertyType = propertyTypeSelector(state);

  const sorted = interests.sort((a) =>
    a.propertyType === propertyType ? -1 : 1
  );
  return sorted ?? [];
}

export function mostRelevantCoordinatesSelector(state) {
  const coordinates =
    leadPlusForCurrentOperationTypeSelector(state)?.coordinates ?? [];
  const uniqueCoordinates = uniqWith(coordinates, isEqual);
  return uniqueCoordinates.slice(0, 10);
}

function leadPlusForCurrentOperationTypeSelector(state) {
  if (isLoadingLeadPlusSelector(state)) {
    return undefined;
  }
  const operationType = leadPlusSelectedOperationTypeSelector(state);
  return state.leadPlus[operationType];
}

export function leadPlusPropertyTypeChartSelector(state) {
  return leadPlusActivityChartSelector(state, 'propertyType');
}

export function leadPlusLocationChartSelector(state) {
  return leadPlusActivityChartSelector(state, 'location');
}

function leadPlusActivityChartSelector(state, valueName) {
  if (!state.leadPlus) {
    return [];
  }
  const leadPlus = leadPlusForCurrentOperationTypeSelector(state);
  const fieldName = `${valueName}sSummary`;
  const activitySummary = leadPlus[fieldName];
  const totalEnquiries = leadPlus.enquiries.count;
  return activitySummary
    .map((summary) => ({
      percentage: Math.trunc((summary.enquiriesCount / totalEnquiries) * 100),
      name: summary[valueName],
    }))
    .sort((a, b) => b.percentage - a.percentage)
    .slice(0, 10);
}
