import { useEffect } from 'react';
import { dispatch } from 'reffects';
import { useParams } from 'react-router-dom';
import Layout from 'design-system/components/Layout';
import { Navigation } from '../../partials';
import { LEAD_DETAIL_PAGE_MOUNTED, LEAD_DETAIL_PAGE_UNMOUNTED } from './events';
import ActivityTimeline from './partials/ActivityTimeline';
import EnquiredProperties from './partials/EnquiredProperties';
import LeadInformation from './partials/LeadInformation/LeadInformation';

export default function LeadDetail() {
  const { leadId } = useParams();

  useEffect(() => {
    dispatch({ id: LEAD_DETAIL_PAGE_MOUNTED, payload: { leadId } });
    return () => dispatch(LEAD_DETAIL_PAGE_UNMOUNTED);
  }, [leadId]);

  return (
    <Layout independentScroll>
      <Layout.Header>
        <Navigation />
      </Layout.Header>
      <Layout.LeftSidebar delimitedColumn>
        <LeadInformation />
      </Layout.LeftSidebar>
      <Layout.Content>
        <ActivityTimeline leadId={leadId} />
      </Layout.Content>
      <Layout.RightSidebar delimitedColumn>
        <EnquiredProperties leadId={leadId} />
      </Layout.RightSidebar>
    </Layout>
  );
}
