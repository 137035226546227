import styled from 'styled-components';
import InputNumber, {
  INPUT_NUMBER_TYPE,
} from 'design-system/components/InputNumber';

const MAX_AMOUNT = 9999999999;

export const CampaignPriceInput = styled(
  ({
    id = 'amount',
    name = 'amount',
    label = 'Total Contract Value',
    value,
    currency,
    required,
    onChange,
    ...props
  }) => (
    <InputNumber
      id={id}
      label={label}
      placeholder={label}
      name={name}
      value={value}
      unit={currency}
      type={INPUT_NUMBER_TYPE.DECIMAL}
      min={0}
      max={MAX_AMOUNT}
      maxDecimals={2}
      onChange={(e) => onChange(e.target.rawValue)}
      required={required}
      {...props}
    />
  )
)``;
