import { useSelector } from 'reffects-store';
import { dispatch, dispatchMany } from 'reffects';
import { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import Dialog from 'design-system/components/Dialog/Dialog';
import InputSelect from 'design-system/components/InputSelect';
import {
  FlatButton,
  SecondaryButton,
} from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import {
  canSaveCampaignSelector,
  developersSelectorSortedByName,
  projectsSuggestionsSelector,
  selectedCampaignSelector,
} from './selectors';
import {
  CAMPAIGN_DEVELOPER_SELECTED,
  CAMPAIGN_FIELD_CHANGED,
  CAMPAIGN_NEXT_BILLING_DATE_CALCULATION_REQUIRED,
  CAMPAIGN_SAVED,
} from './events';
import { CampaignBillingCycleSelector } from '../partials/CampaignBillingCycleSelector';
import { CampaignPriceInput } from '../partials/CampaignPriceInput';
import { CampaignDateInput } from '../partials/CampaignDateInput';
import { CampaignLeadTargetInput } from '../partials/CampaignLeadTargetInput';
import PublisherSelector from '../partials/PublisherSelector';
import { projectNameSuggestionLoadingSelector } from '../partials/ProjectNameAutocomplete/selectors';
import { useDialog } from '../../../hooks/useDialog';
import { CREATE_CAMPAIGN_MODAL } from './constants';
import ProjectNameAutocomplete from '../partials/ProjectNameAutocomplete/ProjectNameAutocomplete';
import { ADMIN_PROJECT_NAME_AUTOCOMPLETE_CLEARED } from '../partials/ProjectNameAutocomplete/events';
import { isEmpty } from '../../../utils/strings';
import InvoicingPeriod from './partials/InvoicingPeriod';

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
  ${InputSelect},
  .ProjectNameAutocomplete,
  ${CampaignBillingCycleSelector},
    ${CampaignDateInput},
    ${CampaignLeadTargetInput},
    ${CampaignPriceInput} {
    width: 100%;
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

function ModalContent({ developers, campaign }) {
  const loading = useSelector(projectNameSuggestionLoadingSelector);
  const projectSuggestions = useSelector(projectsSuggestionsSelector);

  useEffect(() => {
    if (campaign.action) return;

    dispatch({
      id: CAMPAIGN_FIELD_CHANGED,
      payload: { name: 'action', value: 'create-campaign' },
    });
  }, [campaign]);

  useEffect(() => {
    if (
      !isEmpty(campaign.startDate) &&
      !isEmpty(campaign.billingCycleInMonths)
    ) {
      dispatch(CAMPAIGN_NEXT_BILLING_DATE_CALCULATION_REQUIRED);
    }
  }, [campaign.startDate, campaign.billingCycleInMonths]);

  const onChange = (name) => (value) =>
    dispatch({
      id: CAMPAIGN_FIELD_CHANGED,
      payload: { name, value },
    });

  return (
    <>
      <Row>
        <PublisherSelector
          label="Developer"
          publishers={developers}
          selectedPublisherId={campaign.developerId}
          onSelect={({ value }) =>
            dispatch({
              id: CAMPAIGN_DEVELOPER_SELECTED,
              payload: { developerId: value },
            })
          }
        />
      </Row>
      <Row>
        <ProjectNameAutocomplete
          isLoading={loading}
          projectSuggestions={projectSuggestions}
          value={{
            id: campaign.projectId ?? '',
            name: campaign.projectName ?? '',
          }}
          onSelectProject={(project) =>
            dispatchMany([
              {
                id: CAMPAIGN_FIELD_CHANGED,
                payload: { name: 'projectId', value: project.id },
              },
              {
                id: CAMPAIGN_FIELD_CHANGED,
                payload: { name: 'projectName', value: project.name },
              },
            ])
          }
          onClear={() =>
            dispatchMany([
              ADMIN_PROJECT_NAME_AUTOCOMPLETE_CLEARED,
              {
                id: CAMPAIGN_FIELD_CHANGED,
                payload: { name: 'projectId', value: null },
              },
              {
                id: CAMPAIGN_FIELD_CHANGED,
                payload: { name: 'projectName', value: null },
              },
            ])
          }
          publisherCountry={campaign.countryCode}
          disabled={!campaign.developerId}
          required
        />
      </Row>
      <Row>
        <CampaignDateInput
          name="startDate"
          label="Start Date"
          value={campaign.startDate}
          onChange={onChange('startDate')}
          required
        />
        <CampaignBillingCycleSelector
          value={campaign.billingCycleInMonths}
          onChange={(value) => {
            onChange('billingCycleInMonths')(value);
            dispatch({
              id: CAMPAIGN_FIELD_CHANGED,
              payload: { name: 'invoicingPeriods', value: [] },
            });
          }}
          required
        />
      </Row>
      <Row>
        <CampaignDateInput
          name="billingDate"
          label="End date"
          value={campaign.billingDate}
          onChange={onChange('billingDate')}
          disabled
          required
        />
        <CampaignLeadTargetInput
          value={campaign.leadTarget}
          onChange={onChange('leadTarget')}
          required
        />
      </Row>
      <Row>
        <CampaignPriceInput
          id="amount"
          value={campaign.price.amount}
          currency={campaign.price.currency}
          onChange={onChange('price.amount')}
          required
        />
      </Row>
      <Row>
        <InvoicingPeriod currency={campaign.price.currency} />
      </Row>
    </>
  );
}

export function CreateCampaignModal({ onSuccessEvent }) {
  const { open, closeDialog } = useDialog(CREATE_CAMPAIGN_MODAL);
  const developers = useSelector(developersSelectorSortedByName);
  const campaign = useSelector(selectedCampaignSelector);
  const canSave = useSelector(canSaveCampaignSelector);
  const onSave = useCallback(
    () => dispatch({ id: CAMPAIGN_SAVED, payload: { onSuccessEvent } }),
    []
  );
  return (
    <Dialog open={open}>
      <Dialog.Header onClose={closeDialog}>
        Create project campaign
      </Dialog.Header>
      <Dialog.Content>
        <ModalContent developers={developers} campaign={campaign} />
      </Dialog.Content>
      <Dialog.Footer>
        <FlatButton onClick={closeDialog} $size={BUTTON_SIZE.SMALL}>
          Cancel
        </FlatButton>
        <SecondaryButton
          disabled={!canSave}
          onClick={onSave}
          $size={BUTTON_SIZE.SMALL}
        >
          Create campaign
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}
