import { dispatch } from 'reffects';
import { useMount } from 'react-use';
import { SUBSCRIPTION_TO_UPGRADE_REQUESTED } from './events';
import PlansTable from './PlansTable';

export default function SubscriptionComparing({
  subscriptionsHeader,
  benefitsBySubscription,
  showLogoOnWebsiteBenefit,
  websWithoutTranslatedAnd,
}) {
  useMount(() => dispatch(SUBSCRIPTION_TO_UPGRADE_REQUESTED));
  if (subscriptionsHeader.length === 0) return null;
  return (
    <PlansTable
      plansHeader={subscriptionsHeader}
      benefitsByPlan={benefitsBySubscription}
      showLogoOnWebsiteBenefit={showLogoOnWebsiteBenefit}
      websWithoutTranslatedAnd={websWithoutTranslatedAnd}
    />
  );
}
