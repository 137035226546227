import { dispatch } from 'reffects';
import styled from 'styled-components';
import Tooltip from 'design-system/components/Tooltip';
import Icon from 'design-system/components/Icon';
import { FlatButton } from 'design-system/components/Button/presets';
import { COPY_TEXT } from './events';

const Wrapper = styled.div``;

function CopyTextButton({
  text,
  title,
  icon = <Icon glyph="copy" />,
  ...props
}) {
  return (
    <Wrapper {...props}>
      <Tooltip title={title}>
        <FlatButton
          icon={icon}
          onClick={() =>
            dispatch({
              id: COPY_TEXT,
              payload: text,
            })
          }
        />
      </Tooltip>
    </Wrapper>
  );
}

export default styled(CopyTextButton)``;
