import styled from 'styled-components';
import Anchor from 'design-system/components/Anchor';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';

const Wrapper = styled(Anchor)`
  display: flex;
  align-items: center;
`;
export function MediaLink({ glyph, children, href, ...props }) {
  if (!href) {
    return null;
  }

  return (
    <Wrapper href={href} target="_blank" {...props}>
      <Icon glyph={glyph} $size={ICON_SIZE.BASE} />
      <p>{children}</p>
    </Wrapper>
  );
}
