import { mapTableFiltersToApiFilters } from '../../../partials/DataTable/utils/mapTableToApiFilters';

const filterMappers = {
  status: mapTableStatusFilterValueToAPIFilters,
  'ad-suggestion': mapSuggestionFilterValueToAPIFilters,
};

export function mapPropertyFilters(tableFilters) {
  return mapTableFiltersToApiFilters(tableFilters, filterMappers);
}

function mapSuggestionFilterValueToAPIFilters({ type, text }) {
  return {
    [type]: text,
  };
}

function mapTableStatusFilterValueToAPIFilters(tableStatusFilterValue) {
  const filterMapping = {
    published: {
      published: 1,
    },
    unpublished: {
      published: 0,
    },
    'published-boosted': {
      boosted: 1,
      published: 1,
    },
    'published-not-boosted': {
      boosted: 0,
      published: 1,
    },
    'published-superboosted': {
      superboosted: 1,
      published: 1,
    },
  };

  return filterMapping[tableStatusFilterValue];
}
