import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Layout from 'design-system/components/Layout';
import styled from 'styled-components';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import { useMount } from 'react-use';
import Grid from 'design-system/components/Grid';
import { Navigation } from '../../partials';
import { OVERVIEW_PAGE_MOUNTED } from './events';
import Publisher from './partials/Publisher/Publisher';
import KeyResults from './partials/KeyResults/KeyResults';
import Divider from './partials/Divider';
import PublishedProperties from './partials/PublishedProperties/PublishedProperties';
import HelpCenter from './partials/HelpCenter/HelpCenter';
import MobileApp from './partials/MobileApp';
import SocialMedia from './partials/SocialMedia';
import {
  showDownloadAppLinksSelector,
  showHelpCenterSelector,
  showSocialLinksSelector,
} from './selectors';
import { GoogleTagWasi } from './partials/GoogleTagWasi';
import { isPublisherFromLATAMSelector } from '../../selectors/publisher';
import {
  BANNERS,
  showNotUsingYourWholePlanBannerOrLeadQualityAwarenessSelector,
} from '../../selectors/banners';
import NotUsingYourWholePlanBanner from '../../partials/NotUsingYourWholePlanBanner/NotUsingYourWholePlanBanner';
import LeadQualityAwareness from '../../partials/LeadQualityAwareness/LeadQualityAwareness';

const LayoutPadded = styled(Layout.Padded)`
  display: flex;
  gap: ${spacing.value(SPACING_SIZE.XL)};
  flex-direction: column;
`;

function Overview() {
  useMount(() => dispatch(OVERVIEW_PAGE_MOUNTED));
  const showSocialLinks = useSelector(showSocialLinksSelector);
  const showHelpCenter = useSelector(showHelpCenterSelector);
  const showDownloadAppLinks = useSelector(showDownloadAppLinksSelector);
  const isPublisherFromLATAM = useSelector(isPublisherFromLATAMSelector);
  const banner = useSelector((state) =>
    showNotUsingYourWholePlanBannerOrLeadQualityAwarenessSelector(
      state,
      new Date()
    )
  );

  return (
    <>
      <Layout>
        {isPublisherFromLATAM && (
          <GoogleTagWasi containerId={process.env.WASI_GTAG_CONTAINER_ID} />
        )}
        <Layout.Header>
          <Navigation />
        </Layout.Header>
        <Layout.Content>
          <LayoutPadded>
            <Publisher />
            {banner === BANNERS.notUsingYourWholePlan ? (
              <NotUsingYourWholePlanBanner isOverview />
            ) : (
              <LeadQualityAwareness section="overview" showLink />
            )}
            <KeyResults />
          </LayoutPadded>
          <Divider />
          <Layout.Padded>
            <PublishedProperties />
          </Layout.Padded>
          {showHelpCenter && (
            <>
              <Divider />
              <Layout.Padded>
                <HelpCenter />
              </Layout.Padded>
              <Divider />
            </>
          )}
          {showDownloadAppLinks && (
            <Layout.Padded>
              {showSocialLinks ? (
                <Grid
                  areas={({ repeat }) => `
          "${repeat(8, 'mobile')} ${repeat(4, 'social')}"
        `}
                  style={{
                    alignItems: 'center',
                  }}
                >
                  <Grid.Area $id="mobile">
                    <MobileApp />
                  </Grid.Area>
                  <Grid.Area $id="social">
                    <SocialMedia />
                  </Grid.Area>
                </Grid>
              ) : (
                <MobileApp fullWidth />
              )}
            </Layout.Padded>
          )}
        </Layout.Content>
      </Layout>
    </>
  );
}

export default Overview;
