import { adHasBannedBrandsSelector } from '../../../../../selectors/config';
import { publisherBannedBrandsForFreeListingsSelector } from '../../../../../selectors/publisher';
import isTHPropertyTheOnlyBannedBrand from '../../isTHPropertyTheOnlyBannedBrand';

export function shouldShowEmphasisSelector(state, isBoosted) {
  const bannedBrands = publisherBannedBrandsForFreeListingsSelector(state);
  return (
    adHasBannedBrandsSelector(state, isBoosted) &&
    !isTHPropertyTheOnlyBannedBrand(bannedBrands)
  );
}
