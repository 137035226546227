import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useCallback, useState } from 'react';
import Tile from 'design-system/components/Tile';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { border, BORDER_SIZE } from 'design-system/styles/border';
import { radius } from 'design-system/styles/radius';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import useTracking from '../../../../hooks/useTracking';
import LinkButton from '../LinkButton';

const Wrapper = styled(Tile)`
  display: flex;
  justify-content: space-between;
  height: 100%;
  color: initial;
  text-decoration: none;

  ${Subtitle3} {
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const IconWrapper = styled.span`
  display: inline-block;
  align-self: flex-start;
  ${border.outer({ size: BORDER_SIZE.BASE, color: COLOR_PALETTE.NEUTRAL_A05 })}
  ${radius.pill()}
  ${spacing.inset(SPACING_SIZE.S)}
  ${spacing.stack(SPACING_SIZE.S)}
  
  ${Icon} {
    ${Icon.mods.size(ICON_SIZE.SMALL)}
  }
`;

const Amount = styled.div`
  display: flex;
  align-items: center;
  ${spacing.stack(SPACING_SIZE.XS)}
  > * {
    ${spacing.inline(SPACING_SIZE.S)}
  }
`;

function PropertiesCard({
  icon,
  amount,
  message,
  linkTo,
  elementName,
  eventName,
  sectionName,
  ...props
}) {
  const [linkElement, setLinkElement] = useState(null);
  const ref = useCallback((node) => {
    if (node != null && linkElement !== node) setLinkElement(node);
  }, []);
  useTracking({
    ref: linkElement,
    trackClick: {
      eventName,
    },
    trackImpression: {
      elementName,
    },
    sectionName,
  });
  return (
    <Wrapper
      as={linkTo ? Link : undefined}
      to={linkTo || undefined}
      ref={ref}
      {...props}
    >
      <Info>
        {icon && <IconWrapper>{icon}</IconWrapper>}
        <Amount>
          <span>{amount}</span>
          {linkTo && <LinkButton />}
        </Amount>
        <Subtitle3>{message}</Subtitle3>
      </Info>
    </Wrapper>
  );
}

export default styled(PropertiesCard)``;
