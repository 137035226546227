import { FormattedDateParts } from 'react-intl';

export default function ReleaseDate({ date }) {
  return (
    <FormattedDateParts
      value={date}
      year="numeric"
      month="numeric"
      timeZone="utc"
    >
      {([{ value: month }, , { value: year }]) => `${month}/${year}`}
    </FormattedDateParts>
  );
}
