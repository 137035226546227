import {
  effects,
  getEffectHandler,
  registerEffectHandler,
  registerEventHandler,
} from 'reffects';
import { http, registerHttpBatteries } from 'reffects-batteries';
import { REDIRECTED_TO_LOGIN, REDIRECTED_TO_MAINTENANCE } from '../App/events';
import { addObject } from '../utils/formDataUtils';

const HTTP_NOT_AUTHENTICATED = 401;
const HTTP_SERVICE_UNAVAILABLE = 503;

export const REQUESTED_CHECK_AUTHENTICATION = 'REQUESTED_CHECK_AUTHENTICATION';

export default function registerHttpEffects({ httpClient }) {
  registerHttpBatteries(httpClient);
  registerAuthenticationDecoratorEvent();

  ['http.get', 'http.post', 'http.put', 'http.patch'].forEach((effectId) =>
    registerEffectHandler(
      effectId,
      withAuthenticationErrorHandling(getEffectHandler(effectId))
    )
  );

  registerEffectHandler(
    'http.post.multipart',
    withFormDataBody(getEffectHandler('http.post'))
  );
}

export function registerAuthenticationDecoratorEvent() {
  registerEventHandler(
    REQUESTED_CHECK_AUTHENTICATION,
    function requestedCheckAuthentication(
      _,
      [response, ...originalErrorEvent]
    ) {
      if (response.status === HTTP_NOT_AUTHENTICATED) {
        return effects.dispatch(REDIRECTED_TO_LOGIN);
      }

      if (response.status === HTTP_SERVICE_UNAVAILABLE) {
        return effects.dispatch(REDIRECTED_TO_MAINTENANCE);
      }

      const event = createEvent(response, originalErrorEvent);

      if (!event.id) {
        return {};
      }

      return effects.dispatch(event);
    }
  );
}

export function withAuthenticationErrorHandling(effectHandler) {
  return ({ errorEvent: originalErrorEvent, ...data }) => {
    const decoratedData = {
      ...data,
      errorEvent: [REQUESTED_CHECK_AUTHENTICATION, ...originalErrorEvent],
    };

    effectHandler(decoratedData);
  };
}

export function withFormDataBody(effectHandler) {
  return ({ body, files = [], ...data }) => {
    const formData = addObject(body);

    files.forEach(({ name, file }) => {
      formData.append(name, file);
    });

    const decoratedData = {
      ...data,
      body: formData,
    };

    effectHandler(decoratedData);
  };
}

export function postMultipart({ files, ...params }) {
  return {
    'http.post.multipart': {
      ...http.post(params)['http.post'],
      files,
    },
  };
}

function createEvent(response, event) {
  if (!Array.isArray(event)) {
    return {
      id: event,
      payload: [response],
    };
  }

  const [id, ...args] = event;
  return { id, payload: [response].concat(args) };
}
