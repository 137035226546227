import { leadSelector } from '../../../selectors';

export const CREATE_NOTE_ID = 'create-note';
export const SEND_EMAIL_ID = 'send-email';

const EMAIL_EMPTY_MESSAGE_ID = 'enquiry_email_reply_disable';
const NOT_SUBSCRIBED_TO_EMAIL_MESSAGE_ID =
  'enquiry_email_unsubscribe_reply_disable';

export function emailReplyIsEnableSelector(state) {
  const lead = leadSelector(state);
  return Boolean(lead?.email && lead.isSubscribedToEmails);
}

export function emailReplyTooltipMessageIdSelector(state) {
  const lead = leadSelector(state);
  if (!lead) {
    return undefined;
  }

  if (!lead.email) {
    return EMAIL_EMPTY_MESSAGE_ID;
  }

  if (!lead.isSubscribedToEmails) {
    return NOT_SUBSCRIBED_TO_EMAIL_MESSAGE_ID;
  }

  return undefined;
}

export function defaultActiveTabSelector(state) {
  return emailReplyIsEnableSelector(state) ? SEND_EMAIL_ID : CREATE_NOTE_ID;
}
