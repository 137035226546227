import { dispatch } from 'reffects';
import { useMount } from 'react-use';
import { useSelector } from 'reffects-store';
import {
  CREDIT_CARD_SAVED_FAILED,
  CREDIT_CARD_SAVED_SUCCESS,
  REQUEST_CREDIT_CARD_FORM,
} from './events';
import PaymentIframe from '../../../Checkout/PaymentMethod/partials/Form/PaymentIframe';
import { cardFormUrlSelector } from './selectors';

export default function CreditCardForm() {
  useMount(() => dispatch(REQUEST_CREDIT_CARD_FORM));

  const src = useSelector(cardFormUrlSelector);

  return (
    <PaymentIframe
      src={src}
      onSuccessEvent={CREDIT_CARD_SAVED_SUCCESS}
      onBackEvent={CREDIT_CARD_SAVED_FAILED}
      onCheckoutFailed={CREDIT_CARD_SAVED_FAILED}
    />
  );
}
