import styled from 'styled-components';
import { useMount } from 'react-use';
import { dispatch } from 'reffects';
import { useParams } from 'react-router-dom';
import Layout from '../partials/Layout/Layout';
import Timeline from './Timeline';
import { AGENCY_PAGE_MOUNTED } from './events';

const PageContent = styled.div`
  // This may be contained in a mod or find an alternative if we need to reuse it
  margin: 1rem auto;
  width: 75vw;
`;

const HorizontalRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  flex-grow: 1;
`;

function Agency() {
  const { publisherId } = useParams();
  useMount(() => {
    dispatch({
      id: AGENCY_PAGE_MOUNTED,
      payload: { publisherId },
    });
  });

  return (
    <Layout>
      <PageContent>
        <HorizontalRow>
          <Column>
            <h2>Publisher </h2>
          </Column>
          <Column>
            <h2>Current subscription</h2>
            <HorizontalRow>
              <Column>
                <h3>Base subscription</h3>
                <HorizontalRow>
                  <Column>
                    <ul>
                      <li>Boost 3x</li>
                      <li>30 max boostable</li>
                      <li>3 months</li>
                      <li>Started: 2024-01-01</li>
                      <li>Next Billing Date: 2024-03-01</li>
                    </ul>
                  </Column>
                  <Column>
                    <ul>
                      <li>Price: 40,000$</li>
                      <li>Discount 1: 50%</li>
                      <li>Discount 2: 10%</li>
                    </ul>
                  </Column>
                </HorizontalRow>
              </Column>

              <Column>
                <h3>Superboost subscription</h3>
                <HorizontalRow>
                  <Column>
                    <ul>
                      <li>Boost 3x</li>
                      <li>3 months</li>
                      <li>Started: 2024-01-01</li>
                      <li>Next Billing Date: 2024-03-01</li>
                    </ul>
                  </Column>
                  <Column>
                    <ul>
                      <li>Price: 40,000$</li>
                      <li>Discount 1: 50%</li>
                      <li>Discount 2: 10%</li>
                    </ul>
                  </Column>
                </HorizontalRow>
              </Column>
            </HorizontalRow>
          </Column>
        </HorizontalRow>

        <Timeline />
      </PageContent>
    </Layout>
  );
}

export default Agency;
