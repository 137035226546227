import styled from 'styled-components';
import { subscribe } from 'reffects-store';
import { dispatchMany } from 'reffects';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Overline } from 'design-system/components/Typography/presets/Overline';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { KPIS_DATA_REQUESTED } from '../../events';
import DailyLeadsChart from './DailyLeadsChart';
import LeadsKpis from './LeadsKpis';
import DateRangeFilter from '../DateRangeFilter';
import {
  enquiriesByDaySelector,
  isPropertiesSummaryLoading,
  kpisCardsDataSelector,
  publisherHasAnyCreatedAdSelector,
  selectedDateRangeSelector,
} from '../../selectors';
import PublishFirstUnitDisclaimer from './PublishFirstUnitDisclaimer';
import { TRACK_CLICK } from '../../../../partials/Tracking/events';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    ${spacing.stack(SPACING_SIZE.L)}
  }
`;

const Header = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

function LeadsSummary({
  kpis,
  enquiriesByDay,
  selectedDate,
  isLoadingUnitsSummary,
  hasAnyUnitCreated,
  requestEnquiriesSummary,
  ...props
}) {
  if (!kpis) {
    return null;
  }
  return (
    <Wrapper {...props}>
      <Header>
        <Overline>
          <FormattedMessageWithValidation id="overview_developer_cpl_header" />
        </Overline>
        <DateRangeFilter
          selectedRange={selectedDate}
          onRangeSelected={requestEnquiriesSummary}
        />
      </Header>
      <LeadsKpis kpis={kpis} />
      <DailyLeadsChart data={enquiriesByDay} />
      {!isLoadingUnitsSummary && !hasAnyUnitCreated && (
        <PublishFirstUnitDisclaimer />
      )}
    </Wrapper>
  );
}

export default subscribe(
  LeadsSummary,
  (state) => ({
    selectedDate: selectedDateRangeSelector(state),
    kpis: kpisCardsDataSelector(state),
    enquiriesByDay: enquiriesByDaySelector(state),
    isLoadingUnitsSummary: isPropertiesSummaryLoading(state),
    hasAnyUnitCreated: publisherHasAnyCreatedAdSelector(state),
  }),
  {
    requestEnquiriesSummary(period) {
      const format = (date) =>
        `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      dispatchMany([
        {
          id: TRACK_CLICK,
          payload: {
            eventName: 'filter-lead-kpis',
            sectionName: 'overview-lead-kpis',
            payload: {
              period: {
                from: format(period.startDate),
                to: format(period.endDate),
              },
            },
          },
        },
        {
          id: KPIS_DATA_REQUESTED,
          payload: { period },
        },
      ]);
    },
  }
);
