import {
  isPublisherFromLATAMSelector,
  publisherCountrySelector,
} from '../../selectors/publisher';
import { isWhiteLabelSelector } from '../../App/selectors';
import { isUnitedArabEmirates } from '../../utils/countries';
import {
  createdPropertiesSelector,
  propertiesSummarySelector,
} from '../../selectors/propertiesSummary';

function kpiDataSelector(state) {
  return state?.overview?.kpisData?.data;
}

export function kpisCardsDataSelector(state) {
  const kpiData = kpiDataSelector(state);

  if (kpiData == null) return null;

  const { impressions, visits, applies, leads } = kpiData;

  return { impressions, visits, applies, leads };
}

export function impressionsByDaySelector(state) {
  return kpiDataSelector(state)?.impressionsByDay ?? [];
}

export function enquiriesByDaySelector(state) {
  return kpiDataSelector(state)?.enquiriesByDay ?? [];
}

export function selectedDateRangeSelector(state) {
  return (
    state.overview?.kpisData?.selectedDateRange ?? {
      period: {},
    }
  );
}

function projectsSummarySelector(state) {
  return state.overview?.projectsSummary ?? {};
}

export function totalProjectsSelector(state) {
  return projectsSummarySelector(state).totalProjects;
}

export function totalUnitsSelector(state) {
  return projectsSummarySelector(state).totalUnits;
}

export function isProjectsSummaryLoaded(state) {
  return (
    projectsSummarySelector(state).loaded &&
    !projectsSummarySelector(state).loadFailed
  );
}

export function publisherHasAnyCreatedAdSelector(state) {
  const createdProperties = createdPropertiesSelector(state);
  if (isPropertiesSummaryLoading(state)) {
    return undefined;
  }
  return createdProperties > 0;
}

export function isPropertiesSummaryLoading(state) {
  return propertiesSummarySelector(state).loading;
}

export function showHelpCenterSelector(state) {
  return !isWhiteLabelSelector(state);
}

export const showSocialLinksSelector = isPublisherFromLATAMSelector;

export function showDownloadAppLinksSelector(state) {
  return (
    showHelpCenterSelector(state) &&
    !isUnitedArabEmirates(publisherCountrySelector(state))
  );
}
