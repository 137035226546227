import styled from 'styled-components';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { FONT_WEIGHT } from 'design-system/styles/typography';

const Wrapper = styled(Body1)`
  ${spacing.stack(SPACING_SIZE.M, false)}
`;

function Text({ children, ...rest }) {
  return (
    <Wrapper as="p" $weight={FONT_WEIGHT.LIGHT} {...rest}>
      {children}
    </Wrapper>
  );
}

export default styled(Text)``;
