import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import InputRow from 'design-system/components/InputRow';
import { BORDER_SIZE } from 'design-system/styles/border';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import Illustration from 'design-system/components/Illustration/Illustration';
import Map from 'design-system/components/Map';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import { FieldGroup } from '../FieldGroup';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { MAP_MARKER_DRAGGED } from './events';
import {
  geoLevelsSelector,
  locationVisibilitySelector,
  propertyCoordinatesSelector,
  shouldBeMapMarkerDraggableSelector,
} from './selectors';
import { PROPERTY_LOCATION_VISIBILITY } from '../../../../constants/ad';
import AddressField from './AddressField';
import LocationVisibilityField from './LocationVisibilityField';
import {
  COORDINATES_INPUT_NAME,
  GEO_LEVELS_INPUT_NAME,
  LOCATION_VISIBILITY_INPUT_NAME,
} from '../../constants';
import HiddenField from './HiddenField';
import ProjectNameField from './ProjectNameField';
import { projectSelector } from '../../selectors';
import { isPublisherFromSEASelector } from '../../../../selectors/publisher';
import { GeoLevels } from './GeoLevels';

const MapContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PropertyMap = styled(Map)`
  aspect-ratio: 56 / 30;
  & > div {
    ${radius.regular(RADIUS_SIZE.LARGE)}
  }
`;

const LocationVisibilityOption = styled.label`
  display: flex;
  align-items: center;
  gap: ${SPACING_SIZE.M}px;
  ${spacing.insetSandwich(SPACING_SIZE.M)}
  border-bottom: ${BORDER_SIZE.BASE}px solid ${COLOR_PALETTE.NEUTRAL_A10};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:last-of-type {
    border-bottom: initial;
  }

  ${Subtitle3} {
    ${spacing.stack(SPACING_SIZE.XS)}
  }

  ${Body2} {
    ${color.text(COLOR_PALETTE.NEUTRAL_A80)}
  }

  ${Subtitle3}, ${Body2} {
    ${({ disabled }) => disabled && color.text(COLOR_PALETTE.NEUTRAL_A40)}
  }
`;

function LocationGroup({ order }) {
  const coordinates = useSelector(propertyCoordinatesSelector);
  const locationVisibility = useSelector(locationVisibilitySelector);
  const geoLevels = useSelector(geoLevelsSelector);
  const project = useSelector(projectSelector);
  const isPublisherFromSEA = useSelector(isPublisherFromSEASelector);
  const shouldBeMapMarkerDraggable = useSelector(
    shouldBeMapMarkerDraggableSelector
  );

  return (
    <FieldGroup
      id="location"
      title={<FormattedMessageWithValidation id="newprop_address_header" />}
      order={order}
    >
      {isPublisherFromSEA && (
        <InputRow>
          <ProjectNameField />
        </InputRow>
      )}
      <InputRow>
        <AddressField />
        <HiddenField name={COORDINATES_INPUT_NAME} value={coordinates} />
        <HiddenField name={GEO_LEVELS_INPUT_NAME} value={geoLevels} />
      </InputRow>
      {coordinates && (
        <InputRow>
          <MapContainer>
            <PropertyMap
              draggable={!project}
              coordinates={coordinates}
              showMarker={
                locationVisibility === PROPERTY_LOCATION_VISIBILITY.ACCURATE
              }
              showCircle={
                locationVisibility === PROPERTY_LOCATION_VISIBILITY.APPROXIMATE
              }
              draggableMarker={shouldBeMapMarkerDraggable}
              onDragMarker={({ latLng: { lat, lng } }) =>
                dispatch({
                  id: MAP_MARKER_DRAGGED,
                  payload: { latitude: lat(), longitude: lng() },
                })
              }
            />
            <LocationVisibilityOption>
              <LocationVisibilityField
                name={LOCATION_VISIBILITY_INPUT_NAME}
                value={PROPERTY_LOCATION_VISIBILITY.ACCURATE}
              />
              <Illustration name="exactLocation" />
              <div>
                <Subtitle3 as="p">
                  <FormattedMessageWithValidation id="newprop_address_option1" />
                </Subtitle3>
                <Body2 as="p">
                  <FormattedMessageWithValidation id="newprop_address_option1_description" />
                </Body2>
              </div>
            </LocationVisibilityOption>
            <LocationVisibilityOption disabled={project}>
              <LocationVisibilityField
                disabled={project}
                name={LOCATION_VISIBILITY_INPUT_NAME}
                value={PROPERTY_LOCATION_VISIBILITY.APPROXIMATE}
              />
              <Illustration name="area" />
              <div>
                <Subtitle3 as="p">
                  <FormattedMessageWithValidation id="newprop_address_option2" />
                </Subtitle3>
                <Body2 as="p">
                  <FormattedMessageWithValidation id="newprop_address_option2_description" />
                </Body2>
              </div>
            </LocationVisibilityOption>
            <GeoLevels />
          </MapContainer>
        </InputRow>
      )}
    </FieldGroup>
  );
}

export default LocationGroup;
