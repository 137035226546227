import * as React from "react";
const SvgMitulaLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={44}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#MitulaLogo_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M37.602 14.104c-1.179 0-2.134-.99-2.134-2.211 0-1.222.955-2.213 2.134-2.213s2.134.99 2.134 2.213c0 1.22-.956 2.211-2.134 2.211m6.034-2.211-2.978-3.088a4.18 4.18 0 0 0-3.056-1.337c-2.357 0-4.268 1.981-4.268 4.425s1.91 4.423 4.268 4.423a4.18 4.18 0 0 0 3.057-1.337zM18.73 9.826v3.981c0 .762.289 1.236 1.084 1.236.349 0 .59-.137.77-.324.362-.35.458-.936.458-1.46V9.825h1.505v6.49h-1.252l-.109-.848c-.3.636-1 1.01-1.794 1.01-1.336 0-2.167-.936-2.167-2.32V9.825zm10.752-.162c1.072 0 1.987.349 2.348 1.323.156.411.18.873.18 1.31v2.41c0 .299.097.411.35.411.096 0 .192-.025.192-.025v1.148c-.252.113-.385.175-.746.175-.65 0-1.011-.424-1.06-.911-.289.524-1.011.973-1.902.973-1.493 0-2.264-.973-2.264-2.034 0-1.236.927-1.947 2.12-2.035l1.842-.137v-.4c0-.61-.205-1.023-1.06-1.023-.698 0-1.108.325-1.144.924H26.88c.085-1.46 1.204-2.11 2.601-2.11M14.772 7.79v2.035h1.578v1.285h-1.578v3.158c0 .5.217.712.699.712.313 0 .566-.013.975-.063v1.31a5 5 0 0 1-1.288.176c-1.264 0-1.89-.75-1.89-1.998v-3.295h-1.145V9.826h1.144V8.178zm10.353-.499v7.09c0 .374.084.624.602.624.132 0 .169-.013.253-.013v1.31c-.301.063-.361.088-.662.088-1.024 0-1.71-.512-1.71-1.747V7.292zM11.238 9.826v6.49h-1.53v-6.49zm-9.143-2.36 2.24 6.304 2.251-6.303h1.987v8.85H7.068V10l-2.216 6.191H3.708L1.505 9.926v6.39H0v-8.85zm28.446 5.967-1.59.125c-.457.037-.903.324-.903.848 0 .5.422.812.891.812.94 0 1.602-.524 1.602-1.485zM11.273 7.317v1.61H9.66v-1.61z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="MitulaLogo_svg__a">
        <path fill="#fff" d="M0 0h43.636v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgMitulaLogo;
