import styled from 'styled-components';
import { dispatch } from 'reffects';
import Table from 'design-system/components/Table';
import Anchor from 'design-system/components/Anchor';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import Tag, { TAG_VARIANT } from 'design-system/components/Tag';
import { useColumn } from '../../../../../../partials/DataTable/hooks/useColumn';
import { useDatatableContext } from '../../../../../../partials/DataTable/hooks/useDatatableContext';
import { DATA_TABLE_UPDATE_FILTER } from '../../../../../../partials/DataTable/events/filters';

const Name = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: ${spacing.value(SPACING_SIZE.S)};
  ${spacing.stack(SPACING_SIZE.S, false)}
  ${Body1} {
    width: unset;
  }
`;

export function AgencyNameColumn(props) {
  const {
    value: {
      name,
      numberOfPublishersSharingSubscription,
      subscriptionId,
      isForTesting,
    },
  } = useColumn(props);
  const context = useDatatableContext();

  return (
    <Table.Cell {...props}>
      <div>
        <Name>
          <Body1 $ellipse>{name}</Body1>
          {numberOfPublishersSharingSubscription > 1 && (
            <Anchor
              onClick={(e) => {
                e.preventDefault();
                dispatch({
                  id: DATA_TABLE_UPDATE_FILTER,
                  payload: {
                    filter: { name: 'q', value: subscriptionId },
                    ...context,
                  },
                });
              }}
            >
              +{numberOfPublishersSharingSubscription - 1}
            </Anchor>
          )}
        </Name>
        {isForTesting && <Tag variant={TAG_VARIANT.DEFAULT}>Test</Tag>}
      </div>
    </Table.Cell>
  );
}
