export function sortAlphabetically(a, b) {
  if (!a) return 1;
  if (!b) return -1;

  return a.localeCompare(b);
}

export function sortAlphabeticallyBy(fieldName) {
  return ({ [fieldName]: a }, { [fieldName]: b }) => sortAlphabetically(a, b);
}

export function sortDates(a, b) {
  return new Date(a) - new Date(b);
}

export function sortDatesBy(fieldName) {
  return ({ [fieldName]: a }, { [fieldName]: b }) => sortDates(a, b);
}
