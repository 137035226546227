import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { dispatch } from 'reffects';
import { useMount } from 'react-use';
import { useParams } from 'react-router-dom';
import { useSelector } from 'reffects-store';
import InputText from 'design-system/components/InputText';
import InputTextarea from 'design-system/components/InputTextarea';
import Button from 'design-system/components/Button';
import Layout from '../partials/Layout/Layout';
import Section from '../CreatePublisher/Section';
import {
  BACKOFFICE_PROJECT_FORM_ADDRESS_CHANGED,
  BACKOFFICE_PROJECT_FORM_SUBMITTED,
  BACKOFFICE_PROJECT_GEOLEVELS_CHANGED,
  BACKOFFICE_PROJECT_REQUESTED,
} from './events';
import {
  projectFailedSelector,
  projectSavedSelector,
  projectSelector,
} from './selectors';

const Form = styled.form`
  margin: 1rem auto;
  width: 75vw;

  ${Section} {
    ${spacing.stack(SPACING_SIZE.L, false)};
  }
`;
function ProjectEditor() {
  const { projectId } = useParams();
  useMount(() => {
    dispatch({ id: BACKOFFICE_PROJECT_REQUESTED, payload: { projectId } });
  });
  const project = useSelector(projectSelector);
  const isSaved = useSelector(projectSavedSelector);
  const saveFailed = useSelector(projectFailedSelector);

  if (!project) {
    return null;
  }
  return (
    <Layout>
      <Form onSubmit={onSubmit}>
        <h2>Edit Project {project.name}</h2>

        {project.address.map((addressLocale) => (
          <InputText
            key={`address_${addressLocale.locale}`}
            name={`address_${addressLocale.locale}`}
            label={`Address - ${addressLocale.locale}`}
            value={addressLocale.address}
            required
            onChange={onAddressChange}
          />
        ))}

        <InputTextarea
          name="geoLevels"
          label="geoLevels"
          rows={20}
          value={JSON.stringify(project.geoLevels, null, 4)}
          onChange={onInputChange}
        />
        <Button type="submit">Save</Button>

        {isSaved && <p>Project saved</p>}
        {saveFailed && <p>Project save failed</p>}
      </Form>
    </Layout>
  );
}

function onSubmit(event) {
  event.preventDefault();
  dispatch(BACKOFFICE_PROJECT_FORM_SUBMITTED);
}

function onInputChange(event) {
  const { name, value } = event.target;
  dispatch({
    id: BACKOFFICE_PROJECT_GEOLEVELS_CHANGED,
    payload: { name, value },
  });
}

function onAddressChange(event) {
  const { name, value } = event.target;
  dispatch({
    id: BACKOFFICE_PROJECT_FORM_ADDRESS_CHANGED,
    payload: { name, value },
  });
}

export default ProjectEditor;
