import { effects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import { environment } from '../../../coeffects/environment';
import { TRACK_CLICK } from '../../Tracking/events';
import { toast } from '../../../effects/toast';
import { navigateTo, redirectTo } from '../../../effects/routing';

export const CHURN_REASON_SELECTED = 'CHURN_REASON_SELECTED';
export const CHURN_EXTRA_INFO_CHANGED = 'CHURN_EXTRA_INFO_CHANGED';
export const CHURN_RESET_STATE = 'CHURN_RESET_STATE';
export const CHURN_FINISH_POLL = 'CHURN_FINISH_POLL';
export const CHURN_CANCEL_POLL = 'CHURN_CANCEL_POLL';
export const CHURN_SAVE_POLL = 'CHURN_SAVE_POLL';
export const CHURN_TRACK_POLL = 'CHURN_TRACK_POLL';

registerEventHandler(CHURN_REASON_SELECTED, (_, selectedReason) =>
  state.set({
    'churnModal.selectedReason': selectedReason,
  })
);

registerEventHandler(CHURN_EXTRA_INFO_CHANGED, (_, extraInfo) =>
  state.set({
    'churnModal.extraInfo': extraInfo,
  })
);

registerEventHandler(CHURN_RESET_STATE, () =>
  state.set({
    'churnModal.selectedReason': undefined,
    'churnModal.extraInfo': undefined,
  })
);

registerEventHandler(CHURN_FINISH_POLL, (_, { isFromEmail }) =>
  effects.dispatchMany([
    {
      id: CHURN_SAVE_POLL,
      payload: { completed: true, navigateToThanks: isFromEmail },
    },
    { id: CHURN_TRACK_POLL, payload: { isFromEmail, isFinished: true } },
  ])
);

registerEventHandler(
  CHURN_CANCEL_POLL,
  ({ state: { selectedReason } }, { isFromEmail }) => {
    const eventsToDispatch = [
      {
        id: CHURN_SAVE_POLL,
        payload: { completed: false, navigateToThanks: false },
      },
    ];
    if (selectedReason) {
      eventsToDispatch.push({
        id: CHURN_TRACK_POLL,
        payload: { isFromEmail, isFinished: false },
      });
    }
    return {
      ...effects.dispatchMany(eventsToDispatch),
      ...(isFromEmail && redirectTo('/')),
    };
  },
  [
    state.get({
      selectedReason: 'churnModal.selectedReason',
    }),
  ]
);

registerEventHandler(
  CHURN_SAVE_POLL,
  (
    { environment: { apiUrl }, state: { subscriptionId } },
    { completed, navigateToThanks }
  ) => ({
    ...http.post({
      url: `${apiUrl}/subscriptions/${subscriptionId}/poll`,
      body: {
        completed,
      },
    }),
    ...(completed &&
      !navigateToThanks &&
      toast.show({ text: 'churn_modal_thanks_message' })),
    ...(navigateToThanks && navigateTo('/churn-poll/thanks')),
  }),
  [
    environment(),
    state.get({
      subscriptionId: 'publisher.subscriptionId',
    }),
  ]
);

registerEventHandler(
  CHURN_TRACK_POLL,
  (
    { state: { subscriptionId, attempts, selectedReason, extraInfo } },
    { isFromEmail, isFinished }
  ) => {
    let eventSuffix = isFinished ? '-finished' : '-closed';
    eventSuffix += isFromEmail ? '-from-email' : '';
    const eventName = `churn-reasons${eventSuffix}`;
    return effects.dispatch({
      id: TRACK_CLICK,
      payload: {
        eventName,
        sectionName: 'churn-reasons',
        payload: {
          reason: selectedReason,
          ...(extraInfo && { extraInfo }),
          subscriptionId,
          attempts,
        },
      },
    });
  },
  [
    state.get({
      subscriptionId: 'publisher.subscriptionId',
      selectedReason: 'churnModal.selectedReason',
      extraInfo: 'churnModal.extraInfo',
      attempts: 'publisher:churnPollAttempts',
    }),
  ]
);
