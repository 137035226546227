import { subscribe } from 'reffects-store';
import WithCreditCard from './WithCreditCard';
import WithoutCreditCard from './WithoutCreditCard';
import {
  BANK_TRANSFER,
  CASH,
  CREDIT_CARD,
  DIRECT_DEBIT,
} from '../../../../constants/paymentMethod';
import BankTransferPaymentInformation from './BankTransferPaymentInformation';
import CashPaymentInformation from './CashPaymentInformation';
import { paymentMethodSelector } from '../../../../selectors/publisher';
import { isLoadingSavedPaymentMethod } from './selectors';
import {
  creditCardSelector,
  hasCreditCardSavedSelector,
} from '../../../../selectors/payment';
import DirectDebitPaymentInformation from './DirectDebitPaymentInformation';

function CreditCardPaymentInformation({ hasCreditCard, creditCard }) {
  return hasCreditCard ? (
    <WithCreditCard creditCard={creditCard} />
  ) : (
    <WithoutCreditCard />
  );
}

function PaymentInformation({
  isLoading,
  paymentMethod,
  hasCreditCard,
  creditCard,
}) {
  if (isLoading) return null;
  if (paymentMethod === CREDIT_CARD) {
    return (
      <CreditCardPaymentInformation
        creditCard={creditCard}
        hasCreditCard={hasCreditCard}
      />
    );
  }
  if (paymentMethod === BANK_TRANSFER) {
    return <BankTransferPaymentInformation />;
  }
  if (paymentMethod === CASH) {
    return <CashPaymentInformation />;
  }
  if (paymentMethod === DIRECT_DEBIT) {
    return <DirectDebitPaymentInformation />;
  }
  return null;
}

export default subscribe(PaymentInformation, (state) => ({
  isLoading: isLoadingSavedPaymentMethod(state),
  paymentMethod: paymentMethodSelector(state),
  hasCreditCard: hasCreditCardSavedSelector(state),
  creditCard: creditCardSelector(state),
}));
