import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import { dispatch } from 'reffects';
import Dialog from 'design-system/components/Dialog/Dialog';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import {
  FlatButton,
  OutlineButton,
} from 'design-system/components/Button/presets';
import {
  BUTTON_ICON_POSITION,
  BUTTON_SIZE,
} from 'design-system/components/Button';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { COLOR_PALETTE } from 'design-system/styles/color';
import Typography from 'design-system/components/Typography/Typography';
import { useDialog } from '../../../../../../../hooks/useDialog';
import {
  BILLING_ENTITY_DIALOG,
  BILLING_ENTITY_DIALOG_START_EDITING,
  BILLING_ENTITY_FILE_DOWNLOAD,
} from './events';
import {
  billingEntityFileUrlSelector,
  billingEntityHasFileUploadedSelector,
  displayedBillingEntityDataSelector,
  isEditingBillingEntitySelector,
} from './selectors';
import { EditMode } from './partials/EditMode';

const Wrapper = styled.div`
  > * {
    ${spacing.stack(SPACING_SIZE.S)}
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  ${spacing.insetSquish(SPACING_SIZE.M)}
  border: 1px solid ${COLOR_PALETTE.NEUTRAL_A10};
  border-bottom: 0;

  &:first-child {
    border-top-left-radius: ${spacing.value(SPACING_SIZE.XS)};
    border-top-right-radius: ${spacing.value(SPACING_SIZE.XS)};
  }

  &:last-child {
    border-bottom-left-radius: ${spacing.value(SPACING_SIZE.XS)};
    border-bottom-right-radius: ${spacing.value(SPACING_SIZE.XS)};
    border-bottom: 1px solid ${COLOR_PALETTE.NEUTRAL_A10};
  }
`;
const Cell = styled(Typography)`
  flex: 1 1;
  flex-direction: row;
`;

const InnerCell = styled.span`
  display: flex;
  ${Icon} {
    cursor: pointer;
  }
`;

const TwoColumnCell = styled.span`
  display: flex;
  justify-content: space-between;
  flex: 1 1;
  flex-direction: row;

  ${FlatButton} {
    padding: 0;
    gap: 4px;
    color: ${COLOR_PALETTE.BLUEBERRY_BASE};
    text-decoration: underline;
    &:hover {
      color: ${COLOR_PALETTE.BLUEBERRY_BASE};
    }
    ${Icon} {
      color: ${COLOR_PALETTE.BLUEBERRY_BASE};
    }
  }
`;

function BillingEntityRow({ title, value }) {
  return (
    <Row>
      <Cell>{title}</Cell>
      <Cell>{value}</Cell>
    </Row>
  );
}

function BillingEntityFirstRow({ title, value, billingEntityId }) {
  const fileUrl = useSelector(billingEntityFileUrlSelector);
  const hasFileUploaded = useSelector(billingEntityHasFileUploadedSelector);
  return (
    <Row>
      <Cell>{title}</Cell>

      <TwoColumnCell>
        <InnerCell>
          {value}
          {hasFileUploaded && (
            <Icon
              glyph="pdf"
              $size={ICON_SIZE.SMALL}
              $color={COLOR_PALETTE.BLUEBERRY_BASE}
              onClick={() =>
                dispatch({
                  id: BILLING_ENTITY_FILE_DOWNLOAD,
                  payload: { billingEntityId },
                })
              }
            />
          )}
        </InnerCell>
        {fileUrl && (
          <FlatButton
            forwardedAs="a"
            href={fileUrl}
            target="_blank"
            $size={ICON_SIZE.SMALL}
            icon={<Icon glyph="launch" $color={COLOR_PALETTE.BLUEBERRY_BASE} />}
            $iconPosition={BUTTON_ICON_POSITION.START}
          >
            SAT
          </FlatButton>
        )}
      </TwoColumnCell>
    </Row>
  );
}

function ViewMode({ closeDialog }) {
  const billingEntity = useSelector(displayedBillingEntityDataSelector);
  if (!billingEntity) {
    return null;
  }
  return (
    <>
      <Dialog.Header onClose={closeDialog}>
        Associated billing entity
      </Dialog.Header>
      <Dialog.Content>
        <Wrapper>
          <div>
            <BillingEntityFirstRow
              title="Tax ID"
              value={billingEntity.taxId}
              billingEntityId={billingEntity.id}
            />
            <BillingEntityRow
              title="Name or Company Name"
              value={billingEntity.name}
            />
            {billingEntity.typeOfPerson !== null && (
              <BillingEntityRow
                title="Type of Person"
                value={billingEntity.typeOfPerson}
              />
            )}
            <BillingEntityRow title="Email" value={billingEntity.email} />
            <BillingEntityRow title="Address" value={billingEntity.address} />
            <BillingEntityRow title="City" value={billingEntity.city} />
            <BillingEntityRow title="Province" value={billingEntity.province} />
            {billingEntity.postalCode && (
              <BillingEntityRow
                title="Postal Code"
                value={billingEntity.postalCode}
              />
            )}
            <BillingEntityRow title="Phone" value={billingEntity.phone} />
            {billingEntity.taxRegime && (
              <BillingEntityRow
                title="Tax regime"
                value={billingEntity.taxRegime}
              />
            )}
            {billingEntity.country === 'CO' && (
              <>
                <BillingEntityRow
                  title="Retefuente Discount (4%)"
                  value={billingEntity.retefuente ? 'Yes' : 'No'}
                />
                <BillingEntityRow
                  title="ReteICA Discount (0,966%)"
                  value={billingEntity.reteIca ? 'Yes' : 'No'}
                />
                <BillingEntityRow
                  title="ReteIVA Discount (15%)"
                  value={billingEntity.reteIva ? 'Yes' : 'No'}
                />
              </>
            )}
          </div>
          <FlatButton
            icon={<Icon glyph="pencil" />}
            onClick={() => dispatch(BILLING_ENTITY_DIALOG_START_EDITING)}
            $iconPosition={BUTTON_ICON_POSITION.START}
            $size={BUTTON_SIZE.SMALL}
          >
            Edit billing entity
          </FlatButton>
        </Wrapper>
      </Dialog.Content>
      <Dialog.Footer>
        <OutlineButton onClick={closeDialog} $size={BUTTON_SIZE.SMALL}>
          Close
        </OutlineButton>
      </Dialog.Footer>
    </>
  );
}

export function BillingEntityModal({ onSuccessEvent }) {
  const { open, closeDialog } = useDialog(BILLING_ENTITY_DIALOG);
  const isInEditMode = useSelector(isEditingBillingEntitySelector);

  return (
    <Dialog open={open}>
      {isInEditMode ? (
        <EditMode onSuccessEvent={onSuccessEvent} />
      ) : (
        <ViewMode closeDialog={closeDialog} />
      )}
    </Dialog>
  );
}
