import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import configValidationToYup from './configValidationToYup';
import useInternationalization from '../../hooks/useInternationalization';
import { formDataFromBillingEntityData } from './formDataFromBillingEntityData';

export function setUpForm(
  billingEntityData,
  countryCode,
  configFieldValidations
) {
  const intl = useInternationalization();
  const validationSchema = useMemo(
    () => configValidationToYup(configFieldValidations, intl),
    [configFieldValidations]
  );
  const defaultValues = formDataFromBillingEntityData(
    billingEntityData,
    countryCode
  );

  return useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
}
