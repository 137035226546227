import { effects, registerEventHandler } from 'reffects';
import { http, queryParams } from 'reffects-batteries';
import { state } from 'reffects-store';
import { environment } from '../../coeffects/environment';
import { DEFAULT_PAGE, DEFAULT_ROW_PER_PAGE } from '../Tables/constants';
import {
  buildTablePropertiesSearchSuggestionTypeFilter,
  buildTableUnitStatusFilter,
  buildTableUnitTypeFilter,
} from './partials/Filters/utils';
import {
  UNITS_FILTER_STATUS_URL_PARAM,
  UNITS_FILTER_UNIT_TYPE_URL_PARAM,
  UNITS_SEARCH_TYPE_URL_PARAM,
  UNITS_SEARCH_URL_PARAM,
} from './partials/Filters/constants';
import updateTable from '../Tables/updateTable';
import { UNITS_TABLE_PAGE_CHANGED } from './partials/UnitsTable/events';
import { redirectTo } from '../../effects/routing';
import { PROPERTIES } from '../../constants/routes';

export const UNITS_TABLE_BULK_CHECKBOX_CHANGED =
  'UNITS_TABLE_BULK_CHECKBOX_CHANGED';

export const UNITS_PAGE_MOUNTED = 'UNITS_PAGE_MOUNTED';
export const UNITS_REQUESTED_SUCCESSFULLY = 'UNITS_REQUESTED_SUCCESSFULLY';
export const UNITS_REQUESTED_WRONGLY = 'UNITS_REQUESTED_WRONGLY';
export const UPDATE_UNIT_STATUS = 'UPDATE_UNIT_STATUS';
export const UPDATE_UNIT_STATUS_SUCCEED = 'UPDATE_UNIT_STATUS_SUCCEED';
export const UPDATE_UNIT_STATUS_FAILED = 'UPDATE_UNIT_STATUS_FAILED';

registerEventHandler(
  UNITS_PAGE_MOUNTED,
  ({
    environment: { apiUrl },
    state: { isDeveloper },
    queryParams: {
      rowsPerPage,
      page,
      order,
      orderBy,
      [UNITS_FILTER_STATUS_URL_PARAM]: statusFilter,
      [UNITS_FILTER_UNIT_TYPE_URL_PARAM]: unitTypeFilter,
      [UNITS_SEARCH_URL_PARAM]: searchSuggestionText,
      [UNITS_SEARCH_TYPE_URL_PARAM]: searchSuggestionType,
    } = {},
  }) => {
    if (!isDeveloper) {
      return redirectTo(PROPERTIES);
    }
    const pagination = {
      currentPage: Number(page) || DEFAULT_PAGE,
      rowsPerPage: Number(rowsPerPage) || DEFAULT_ROW_PER_PAGE,
    };
    const sorting = {
      field: orderBy ?? 'date',
      direction: order ?? 'desc',
    };

    const filters = {
      ...buildTableUnitStatusFilter(statusFilter),
      ...buildTableUnitTypeFilter(unitTypeFilter),
      ...buildTablePropertiesSearchSuggestionTypeFilter(
        searchSuggestionType,
        searchSuggestionText
      ),
    };

    return {
      ...state.set({
        unitStatusFilter: statusFilter,
        unitTypeFilter,
        'units:suggesterFilterValue': searchSuggestionText,
        unitsTable: {
          isLoading: true,
          pagination,
          sorting,
          filters,
          rows: [],
        },
      }),
      ...http.get({
        url: `${apiUrl}/units`,
        body: {},
        successEvent: UNITS_REQUESTED_SUCCESSFULLY,
        errorEvent: UNITS_REQUESTED_WRONGLY,
      }),
    };
  },
  [
    environment(),
    state.get({ isDeveloper: 'publisher.isDeveloper' }),
    queryParams.get([
      'rowsPerPage',
      'page',
      'order',
      'orderBy',
      UNITS_FILTER_STATUS_URL_PARAM,
      UNITS_FILTER_UNIT_TYPE_URL_PARAM,
      UNITS_SEARCH_URL_PARAM,
      UNITS_SEARCH_TYPE_URL_PARAM,
    ]),
  ]
);

registerEventHandler(
  UNITS_REQUESTED_SUCCESSFULLY,
  ({ state: { pagination, sorting, filters } }, [{ data }]) => {
    const { rows, totalRows } = updateTable(data, filters, sorting, pagination);

    return state.set({
      unitsById: data,
      unitsTable: {
        isLoading: false,
        rows,
        pagination: {
          ...pagination,
          totalRows,
        },
        sorting,
        filters,
      },
    });
  },
  [
    state.get({
      pagination: 'unitsTable.pagination',
      sorting: 'unitsTable.sorting',
      filters: 'unitsTable.filters',
    }),
  ]
);

registerEventHandler(UNITS_REQUESTED_WRONGLY, () => ({
  ...state.set({
    'unitsTable.loading': false,
  }),
}));

registerEventHandler(
  UPDATE_UNIT_STATUS,
  ({ environment: { apiUrl } }, { unitId }) =>
    http.get({
      url: `${apiUrl}/properties/${unitId}`,
      successEvent: UPDATE_UNIT_STATUS_SUCCEED,
      errorEvent: UPDATE_UNIT_STATUS_FAILED,
    }),
  [environment()]
);

registerEventHandler(
  UPDATE_UNIT_STATUS_SUCCEED,
  ({ state: { units } }, [response]) => {
    const { id, published } = response.data;

    return {
      ...state.set({
        [`unitsById.${id}`]: {
          ...units[id],
          published,
        },
      }),
      ...effects.dispatch({ id: UNITS_TABLE_PAGE_CHANGED, payload: 1 }),
    };
  },
  [
    state.get({
      units: 'unitsById',
    }),
  ]
);

registerEventHandler(UPDATE_UNIT_STATUS_FAILED, () =>
  console.error('No Operation - failed to update unit status')
);
