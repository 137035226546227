import styled from 'styled-components';
import Table from 'design-system/components/Table';
import Tooltip from 'design-system/components/Tooltip';
import Anchor from 'design-system/components/Anchor';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { useColumn } from '../../../../../../partials/DataTable/hooks/useColumn';
import { showSubscriptionChangeRequestStatus } from './showSubscriptionChangeRequestStatus';

const NoColorAnchor = styled(Anchor)`
  ${color.text(COLOR_PALETTE.NEUTRAL_A80)}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    ${spacing.stack(SPACING_SIZE.S)}
  }
`;

export function BankReceiptColumn(props) {
  const {
    value: { subscriptionChangeRequests },
  } = useColumn(props);

  return (
    <Table.Cell>
      <Wrapper>
        {subscriptionChangeRequests
          .filter(
            ({ status, billingStatus, bankReceiptUrl }) =>
              showSubscriptionChangeRequestStatus(status, billingStatus) &&
              bankReceiptUrl != null
          )
          .map(({ bankReceiptUrl }) => (
            <Tooltip title="Download voucher" key={bankReceiptUrl}>
              <NoColorAnchor href={bankReceiptUrl} target="_blank">
                <Icon glyph="receipt" $size={ICON_SIZE.BASE} />
              </NoColorAnchor>
            </Tooltip>
          ))}
      </Wrapper>
    </Table.Cell>
  );
}
