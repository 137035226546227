import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import styled from 'styled-components';
import Table from 'design-system/components/Table';
import Tag, { TAG_VARIANT } from 'design-system/components/Tag';
import { useColumn } from '../../../../partials/DataTable/hooks/useColumn';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { ERROR_TAGS } from './constants';

const CellWrapper = styled(Table.Cell)`
  ${Tag} {
    ${spacing.inline(SPACING_SIZE.S)}
  }
`;

export function FieldsColumn(props) {
  const { value } = useColumn(props);
  if (!value) {
    return null;
  }
  return (
    <CellWrapper>
      {value.map((field) => (
        <Tag key={field} variant={TAG_VARIANT.SECONDARY}>
          <FormattedMessageWithValidation
            id={ERROR_TAGS[field] ?? 'internal-error'}
          />
        </Tag>
      ))}
    </CellWrapper>
  );
}
