import { useSelector } from 'reffects-store';
import { DropdownFilter } from '../../../partials/DataTable';
import { operationTypeFilterOptionsSelector } from './selectors';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

export function OperationFilter() {
  const options = useSelector(operationTypeFilterOptionsSelector);
  return (
    <DropdownFilter
      label={
        <FormattedMessageWithValidation id="properties_list_filter_operation_type" />
      }
      source="operationType"
      options={options.map(({ tag, ...option }) => ({
        ...option,
        label: <FormattedMessageWithValidation id={tag} />,
      }))}
    />
  );
}
