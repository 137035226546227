import Dialog from 'design-system/components/Dialog/Dialog';
import {
  FlatButton,
  SecondaryButton,
} from 'design-system/components/Button/presets';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

export default function ConfirmDeleteImageDialog({
  open,
  onConfirm,
  onCancel,
}) {
  return (
    <Dialog open={open}>
      <Dialog.Header>
        <FormattedMessageWithValidation id="newprop_pics_remove_header" />
      </Dialog.Header>
      <Dialog.Content>
        <Dialog.Text>
          <FormattedMessageWithValidation id="newprop_pics_remove_body" />
        </Dialog.Text>
      </Dialog.Content>
      <Dialog.Footer>
        <FlatButton onClick={onCancel}>
          <FormattedMessageWithValidation id="newprop_pics_remove_no" />
        </FlatButton>
        <SecondaryButton onClick={onConfirm}>
          <FormattedMessageWithValidation id="newprop_pics_remove_yes" />
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}
