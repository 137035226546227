/* eslint-disable no-use-before-define */
import styled, { css } from 'styled-components';
import { useSelector } from 'reffects-store';
import AreaChart from 'design-system/components/AreaChart/AreaChart';
import { FONT_SIZE } from 'design-system/styles/typography';
import { impressionsByDaySelector } from '../../selectors';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import FormattedDate from '../../../../partials/FormattedDate/FormattedDate';

const Wrapper = styled.div`
  ${({ $width, $height }) => modSize($width, $height)}
`;

const modSize = (width = '100%', height = '100%') => css`
  width: ${width};
  height: ${height};
`;

function Tooltip({ date, impressions }) {
  if (!date) {
    return null;
  }
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <p style={{ fontSize: `${FONT_SIZE.S}px`, margin: 0 }}>
        <FormattedDate
          value={date}
          weekday="short"
          day="2-digit"
          month="short"
          year="numeric"
        />
      </p>
      <p style={{ fontSize: `${FONT_SIZE.S}px`, margin: 0 }}>
        <FormattedMessageWithValidation id="overview_key_results_chart_tooltip_displays" />
        : {impressions}
      </p>
    </div>
  );
}

function ImpressionsChart(props) {
  const impressionsByDay = useSelector(impressionsByDaySelector);
  if (impressionsByDay.length === 0) return null;

  return (
    <Wrapper {...props}>
      <AreaChart
        dataKey="impressions"
        data={impressionsByDay}
        renderTooltipContent={({ payload }) => (
          <Tooltip date={payload?.date} impressions={payload?.impressions} />
        )}
        renderXTickText={({ value }) => (
          <FormattedDate value={value} month="short" day="numeric" />
        )}
        renderYTickText={({ value }) => value}
      />
    </Wrapper>
  );
}

export default styled(ImpressionsChart)``;
