import InputText from 'design-system/components/InputText';
import { EMAIL_VALIDATION_REGEX } from '../../../constants';

export default function EmailInput({
  name,
  value,
  label,
  className,
  required,
  disabled,
  onChange,
  data,
}) {
  return (
    <InputText
      name={name}
      value={value}
      label={label}
      className={className}
      pattern={EMAIL_VALIDATION_REGEX}
      required={required}
      disabled={disabled}
      onChange={onChange}
      data={data}
      data-lpignore="true"
    />
  );
}
