import * as React from "react";
const SvgPuntoPropiedadLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={111}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#PuntoPropiedadLogo_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.272 13.215c0-.886-.656-1.544-1.549-1.544s-1.548.658-1.548 1.544.656 1.544 1.548 1.544c.887 0 1.549-.658 1.549-1.544m2.398 0c0 2.102-1.513 3.602-3.628 3.602-.626 0-1.198-.16-1.686-.448v2.657H0V9.843h1.62l.299.535c.569-.48 1.3-.757 2.123-.757 2.115 0 3.628 1.493 3.628 3.595M8.674 13.695v-3.85h2.383v3.75c0 .71.393 1.13 1.03 1.13.636 0 1.021-.426 1.021-1.13v-3.75h2.383v3.85c0 1.888-1.361 3.118-3.404 3.118s-3.413-1.23-3.413-3.118m15.063-1.217v4.114h-2.383v-3.793q-.001-1.177-.901-1.175c-.744 0-1.17.506-1.17 1.365v3.603h-2.384V9.844h1.672l.327.696c.515-.56 1.283-.895 2.178-.895 1.606 0 2.661 1.113 2.661 2.833m6.04 2.078v2.036h-1.64c-1.53 0-2.46-.912-2.46-2.412v-2.546h-1.266v-.543l3.1-3.22H28v1.973h1.738v1.79H28.06v2.147c0 .484.3.775.802.775zm17.674-1.304c0-.882-.653-1.536-1.54-1.536-.889 0-1.541.654-1.541 1.536s.652 1.537 1.54 1.537c.883 0 1.541-.655 1.541-1.537m2.385 0c0 2.092-1.504 3.584-3.609 3.584a3.3 3.3 0 0 1-1.677-.446v2.644h-2.344V9.896h1.613l.296.533a3.24 3.24 0 0 1 2.112-.753c2.105 0 3.61 1.485 3.61 3.577m5.568-3.388v2.116h-.92c-.84 0-1.192.359-1.192 1.215v3.414h-2.37V9.896h1.589l.37.745c.484-.547 1.076-.777 1.895-.777zm4.24 4.888c.864 0 1.483-.612 1.483-1.5 0-.882-.62-1.499-1.484-1.499s-1.485.612-1.485 1.5c0 .887.62 1.499 1.485 1.499m-3.86-1.5c0-2.08 1.616-3.576 3.86-3.576 2.236 0 3.858 1.495 3.858 3.577 0 2.073-1.622 3.576-3.859 3.576-2.244 0-3.859-1.503-3.859-3.576m14.056 0c0-.882-.652-1.536-1.54-1.536s-1.54.654-1.54 1.536.652 1.537 1.54 1.537c.882 0 1.54-.655 1.54-1.537m2.385 0c0 2.092-1.504 3.584-3.608 3.584-.623 0-1.192-.16-1.678-.446v2.644h-2.343V9.896h1.612l.297.533a3.24 3.24 0 0 1 2.112-.753c2.104 0 3.608 1.485 3.608 3.577"
        clipRule="evenodd"
      />
      <mask
        id="PuntoPropiedadLogo_svg__b"
        width={111}
        height={13}
        x={0}
        y={7}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "alpha",
        }}
      >
        <path fill="#fff" d="M0 19.031h110.182V7.091H0z" />
      </mask>
      <g mask="url(#PuntoPropiedadLogo_svg__b)">
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M73.33 9.053h2.334V7.09H73.33zm-.018 7.553h2.37V9.893h-2.37zm5.835-4.088h2.782c-.207-.67-.703-1.033-1.419-1.033-.69 0-1.171.366-1.363 1.033m5.117.668q-.002.303-.048.612h-5.11c.154.77.656 1.169 1.404 1.169.554 0 1.017-.237 1.244-.63h2.36c-.485 1.532-1.877 2.489-3.604 2.489-2.147 0-3.738-1.531-3.738-3.582 0-2.058 1.578-3.57 3.738-3.57 2.218 0 3.755 1.543 3.755 3.512m6.15.064c0-.877-.652-1.537-1.54-1.537s-1.541.66-1.541 1.537.652 1.536 1.541 1.536 1.54-.66 1.54-1.536m2.196-5.78v9.136h-1.754l-.224-.498c-.56.459-1.274.725-2.076.725-2.111 0-3.615-1.497-3.615-3.583s1.504-3.577 3.615-3.577c.636 0 1.217.167 1.71.466v-2.67zm6.557 5.778c0-.871-.653-1.526-1.54-1.526-.894 0-1.541.655-1.541 1.526 0 .876.652 1.53 1.54 1.53s1.54-.654 1.54-1.53m2.255-3.362V16.6h-1.833l-.203-.475a3.3 3.3 0 0 1-2.077.702c-2.104 0-3.608-1.498-3.608-3.584 0-2.078 1.504-3.558 3.608-3.558.81 0 1.535.264 2.103.72l.243-.518zm6.565 3.364c0-.877-.652-1.537-1.54-1.537-.889 0-1.541.66-1.541 1.537s.652 1.536 1.541 1.536 1.54-.66 1.54-1.536m2.196-5.78v9.136h-1.754l-.224-.498a3.23 3.23 0 0 1-2.076.725c-2.111 0-3.615-1.497-3.615-3.583s1.504-3.577 3.615-3.577c.636 0 1.217.167 1.71.466v-2.67zm-74.157 7.579c-1.155 0-2.091-.914-2.091-2.04 0-1.127.936-2.041 2.091-2.041s2.093.914 2.093 2.04c0 1.127-.937 2.041-2.093 2.041m4.619-5.047-4.084-2.21a1.13 1.13 0 0 0-1.07 0l-4.084 2.21a1.06 1.06 0 0 0-.428 1.463c.256.45.794.65 1.28.508a3.7 3.7 0 0 0-.15 1.036c0 2.11 1.753 3.821 3.918 3.821 2.163 0 3.918-1.71 3.918-3.821 0-.36-.055-.707-.15-1.038q.156.046.314.048c.388 0 .764-.2.965-.554a1.06 1.06 0 0 0-.429-1.462"
          clipRule="evenodd"
        />
      </g>
    </g>
    <defs>
      <clipPath id="PuntoPropiedadLogo_svg__a">
        <path fill="#fff" d="M0 0h110.182v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPuntoPropiedadLogo;
