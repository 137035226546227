import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import styled from 'styled-components';
import { ROWS_PER_PAGE } from 'design-system/components/Table';
import Layout from 'design-system/components/Layout';
import SubscriptionLayout from '../partials/Layout/Layout';
import Header from '../partials/Layout/Header';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import ContactSupport from '../partials/ContactSupport';
import BankTransferPaymentInformation from '../partials/PaymentInformation/BankTransferPaymentInformation';
import { PromotedProjectsTable } from './PromotedProjectsTable/PromotedProjectsTable';
import { CreateNewProjectBanner } from './CreateNewProjectBanner';
import DeveloperBenefitsTable from './DeveloperBenefitsTable';
import { useDataTable } from '../../../partials/DataTable/hooks/useDataTable';
import createPromotedProjectsAdapter from './PromotedProjectsTable/promotedProjectsAdapter';
import { DataTableProvider } from '../../../partials/DataTable';
import CurrentPlanBanner from './CurrentPlanBanner';

const Wrapper = styled(SubscriptionLayout)`
  ${Layout.Padded} {
    > * {
      ${spacing.stack(SPACING_SIZE.L)}
    }
  }
`;

export default function DeveloperSubscription() {
  const context = {
    collectionName: 'promotedProjects',
    initialItemsPerPage: ROWS_PER_PAGE.TEN,
    adapter: createPromotedProjectsAdapter(),
  };

  return (
    <Wrapper
      sidebar={
        <Layout.Padded>
          <BankTransferPaymentInformation />
          <ContactSupport />
        </Layout.Padded>
      }
    >
      <Layout.Padded>
        <Header>
          <FormattedMessageWithValidation id="subscription_developer_title" />
        </Header>
        <DataTableProvider {...context}>
          <Content />
        </DataTableProvider>
      </Layout.Padded>
    </Wrapper>
  );
}

function Content() {
  const { isEmpty } = useDataTable('promotedProjects');

  return isEmpty ? (
    <DeveloperBenefitsTable hasPromotedProjects={false} />
  ) : (
    <>
      <CurrentPlanBanner />
      <PromotedProjectsTable />
      <CreateNewProjectBanner />
    </>
  );
}
