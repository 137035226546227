import { FormattedNumber } from 'react-intl';
import { TextColumn } from './TextColumn';

export function NumberColumn({ ...props }) {
  return (
    <TextColumn
      renderField={(value) => <FormattedNumber value={value} />}
      {...props}
    />
  );
}
