import styled from 'styled-components';
import DarkLogo from './DarkLogo';
import LightLogo from './LightLogo';

function Logo({ variant, ...rest }) {
  if (variant === 'dark') {
    return <DarkLogo {...rest} />;
  }

  return <LightLogo {...rest} />;
}

export default styled(Logo)``;
