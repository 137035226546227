import styled, { css } from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import SkeletonBone from 'design-system/components/SkeletonBone';
import { border } from 'design-system/styles/border';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import CheckIcon from './CheckIcon';
import IndeterminateIcon from './IndeterminateIcon';
import { withStylesAndInnerRef } from '../../utils';

const HiddenInput = styled.input.attrs({
  type: 'checkbox',
})`
  display: none;
`;

const Input = styled.span`
  width: 16px;
  height: 16px;
  display: inline-block;
  cursor: pointer;
  ${radius.regular(RADIUS_SIZE.BASE)}
  ${border.inner({ size: 1, color: COLOR_PALETTE.NEUTRAL_A20 })}
  ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
  ${color.background(COLOR_PALETTE.NEUTRAL_A00)}

  &:hover,
  &:active,
  &:focus {
    ${border.inner({
      size: 1,
      color: COLOR_PALETTE.NEUTRAL_A80,
    })}
  }
`;

const modIndeterminate = () => css`
  ${Input} {
    ${border.inner({
      size: 1,
      color: COLOR_PALETTE.NEUTRAL_A80,
    })}
    ${color.background(COLOR_PALETTE.NEUTRAL_A80)}
  }

  ${HiddenInput}:disabled ~ ${Input} {
    ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
  }

  ${CheckIcon} {
    display: none;
  }

  ${IndeterminateIcon} {
    display: block;
  }
`;

const modChecked = () => css`
  ${HiddenInput}:checked ~ ${Input} {
    ${border.inner({
      size: 1,
      color: COLOR_PALETTE.NEUTRAL_A80,
    })}
    ${color.background(COLOR_PALETTE.NEUTRAL_A80)}

    ${CheckIcon} {
      display: block;
    }

    ${IndeterminateIcon} {
      display: none;
    }
  }

  ${HiddenInput}:not(:checked) ~ ${Input} {
    ${CheckIcon} {
      display: none;
    }
  }
`;

const modDisabled = () => css`
  ${HiddenInput}:disabled ~ ${Input} {
    cursor: not-allowed;

    ${border.inner({
      size: 1,
      color: COLOR_PALETTE.NEUTRAL_A20,
    })}
    ${color.background(COLOR_PALETTE.NEUTRAL_A20)}
  }
`;

const Wrapper = styled.label`
  display: inline-flex;

  ${IndeterminateIcon} {
    display: none;
  }

  ${({ indeterminate }) => indeterminate && modIndeterminate()}
  ${() => modChecked()}
  ${() => modDisabled()}
`;

const Label = styled.label`
  display: flex;
  flex-direction: row;
  background: none;
  cursor: pointer;

  ${Wrapper} {
    ${spacing.inline(SPACING_SIZE.M)}
  }
`;

function Checkbox({ className, style, innerRef, ...rest }) {
  const { indeterminate } = rest;

  return (
    <Wrapper indeterminate={indeterminate} className={className} style={style}>
      <HiddenInput {...rest} ref={innerRef} />
      <Input>
        <CheckIcon />
        <IndeterminateIcon />
      </Input>
    </Wrapper>
  );
}

Checkbox.Skeleton = () => <SkeletonBone $width="16px" $height="16px" />;
Checkbox.Label = Label;

export default withStylesAndInnerRef(Checkbox);
