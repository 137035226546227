/* eslint-disable no-use-before-define */

import styled, { css } from 'styled-components';
import { useSelector } from 'reffects-store';
import Tip from 'design-system/components/Tip';
import { bindClassname } from 'design-system/utils';
import {
  OutlineButton,
  PrimaryButton,
} from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import {
  formIsBeingSubmittedSelector,
  isPublicationEnabledSelector,
} from '../selectors';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import PublisherSites from '../../../partials/PublisherSites';
import { showSitesToPublishSelector } from '../../../App/selectors';
import { isDeveloperSelector } from '../../../selectors/publisher';

const Wrapper = styled.div`
  display: flex;

  ${({ $layout }) => $layout && modLayout($layout)}
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > * {
    ${spacing.inline(SPACING_SIZE.M)}
  }
`;

const PublishSitesTip = styled(Tip)`
  ${Body2} {
    display: block;
    ${spacing.stack(SPACING_SIZE.XS)}
  }
`;

const modLayoutInline = () => css`
  flex-direction: row;

  ${PublishSitesTip} {
    ${color.text(COLOR_PALETTE.NEUTRAL_A80)}
    ${color.background(COLOR_PALETTE.NONE)}
  }

  ${Buttons} {
    ${spacing.inline(SPACING_SIZE.L)}
  }

  > * {
    ${spacing.inline(SPACING_SIZE.M)}
  }

  ${PublisherSites} {
    ${color.text(COLOR_PALETTE.SECONDARY_A60)}
  }
`;

const PublisherSitesLogos = styled(PublisherSites)`
  display: flex;
  flex-wrap: wrap;

  ${spacing.inline(SPACING_SIZE.M)}

  > * {
    ${spacing.inline(SPACING_SIZE.M)}
  }
`;

const modLayoutStack = () => css`
  flex-direction: column;

  > * {
    ${spacing.stack(SPACING_SIZE.M)}
  }
`;

const modLayout = (layout) =>
  ({
    inline: modLayoutInline,
    stack: modLayoutStack,
  }[layout]);

function PublishBanner({
  networkBrandsMessage,
  onDraftSubmit,
  onPublishSubmit,
  $layout = 'stack',
  ...rest
}) {
  const submitting = useSelector(formIsBeingSubmittedSelector);
  const publicationEnabled = useSelector(isPublicationEnabledSelector);
  const isDeveloper = useSelector(isDeveloperSelector);
  const showSites = useSelector(showSitesToPublishSelector);
  return (
    <Wrapper $layout={$layout} {...rest}>
      <Buttons>
        <OutlineButton
          $size={BUTTON_SIZE.LARGE}
          onClick={onDraftSubmit}
          disabled={submitting}
          data-tag={`draft_button_${$layout}`}
        >
          <FormattedMessageWithValidation id="newprop_draft_cta" />
        </OutlineButton>
        <PrimaryButton
          type="submit"
          $size={BUTTON_SIZE.LARGE}
          onClick={onPublishSubmit}
          disabled={submitting || !publicationEnabled}
          data-tag={`publish_button_${$layout}`}
        >
          <FormattedMessageWithValidation id="v2_newprop_publish_cta" />
        </PrimaryButton>
      </Buttons>
      {showSites && publicationEnabled && (
        <PublishSitesTip>
          <Body2 $color={COLOR_PALETTE.NEUTRAL_A60}>
            {networkBrandsMessage}
          </Body2>
          <PublisherSitesLogos />
        </PublishSitesTip>
      )}
      {!publicationEnabled && (
        <PublishSitesTip>
          <Body2 $color={COLOR_PALETTE.NEUTRAL_A80}>
            <FormattedMessageWithValidation
              id={
                isDeveloper
                  ? 'newprop_publish_disabled_free_developer'
                  : 'newprop_publish_disabled_free_agency'
              }
            />
          </Body2>
        </PublishSitesTip>
      )}
    </Wrapper>
  );
}

export default bindClassname(PublishBanner, Wrapper);
