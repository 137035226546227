import { memoize } from 'lodash';
import { dispatch } from 'reffects';
import { TRACK_CLICK } from '../../Tracking/events';

function buildTableRowEventsWithTracking(
  events,
  tracking,
  dispatchEvent = dispatch
) {
  const handleRowClick = (itemId, e) => {
    dispatchEvent({ id: TRACK_CLICK, payload: tracking });
    if (events.onClick) events.onClick(itemId, e);
  };

  const handleRowAuxClick = (itemId, e) => {
    dispatchEvent({ id: TRACK_CLICK, payload: tracking });
    if (events.onAuxClick) events.onAuxClick(itemId, e);
  };

  return {
    onClick: handleRowClick,
    onAuxClick: handleRowAuxClick,
  };
}

export default memoize(buildTableRowEventsWithTracking);
