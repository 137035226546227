import styled from 'styled-components';
import Table, { TABLE_ALIGNMENT } from 'design-system/components/Table';
import Checkbox from 'design-system/components/Checkbox/Checkbox';
import SkeletonBone from 'design-system/components/SkeletonBone';
import {
  DataTable,
  DateRelativeColumn,
  SnippetColumn,
  TextColumn,
} from '../../partials/DataTable';
import { OperationsColumn } from './columns/OperationsColumn';
import { OperationPricesColumn } from './columns/OperationPricesColumn';
import { KpiColumn } from './columns/KpiColumn';
import { ActionsColumn } from './columns/ActionsColumn';
import { Menu } from './menu/Menu';
import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import buildTableRowEventsWithTracking from '../../partials/DataTable/utils/buildTableRowEventsWithTracking';
import buildTableRowEventsRedirectingTo from '../../partials/DataTable/utils/buildTableRowEventsRedirectingTo';
import { editPropertyForm } from '../../utils/proppitWebRouter';
import PropertySnippet from '../../partials/PropertySnippet';

const CheckboxTableHead = styled.th`
  width: 32px;
  text-align: left;
  padding: 12px 0 9px 16px;
`;

const CheckboxCell = styled(Table.Cell)`
  padding-right: 0;
`;

export function PropertiesTable() {
  return (
    <DataTable
      menu={<Menu />}
      noItemsMessage={
        <FormattedMessageWithValidation id="propspage_table_empty_results" />
      }
      allowPagination
      clickableRows
      rowEvents={buildTableRowEventsWithTracking(
        buildTableRowEventsRedirectingTo(editPropertyForm),
        {
          eventName: 'edit-listing',
          sectionName: 'listings-table-item',
        }
      )}
      loadingComponent={<TableSkeleton />}
    >
      <SnippetColumn
        id="snippet"
        title={<FormattedMessageWithValidation id="propspage_table_property" />}
        sortingField="address"
        source={{
          title: 'title',
          address: 'address',
          image: 'mainImage',
        }}
        maxWidth={225}
      />
      <TextColumn
        id="referenceId"
        data-tag="referenceId"
        sortable
        title={
          <FormattedMessageWithValidation id="propspage_table_reference_id" />
        }
        maxWidth={200}
      />
      <OperationsColumn
        id="operations"
        title={
          <FormattedMessageWithValidation id="properties_list_operation_type_header" />
        }
        sortable
      />
      <OperationPricesColumn
        id="prices"
        data-tag="price"
        title={<FormattedMessageWithValidation id="propspage_table_price" />}
        source="operations"
        sortable
      />
      <KpiColumn
        id="impressions"
        tooltip={<FormattedMessageWithValidation id="proppage_table_stat_1" />}
        sortable
        title={
          <FormattedMessageWithValidation id="propspage_table_stats_displays" />
        }
      />
      <KpiColumn
        id="clicks"
        tooltip={<FormattedMessageWithValidation id="proppage_table_stat_2" />}
        sortable
        title={
          <FormattedMessageWithValidation id="propspage_table_stats_views" />
        }
      />
      <KpiColumn
        id="enquiries"
        tooltip={<FormattedMessageWithValidation id="proppage_table_stat_3" />}
        sortable
        title={
          <FormattedMessageWithValidation id="propspage_table_stats_leads" />
        }
      />
      <DateRelativeColumn
        id="date"
        sortable
        title={<FormattedMessageWithValidation id="propspage_table_date" />}
      />
      <ActionsColumn
        id="actions"
        title={<FormattedMessageWithValidation id="propspage_table_actions" />}
        source={{
          published: 'isActive',
          boosted: 'isBoosted',
          superboosted: 'isSuperboosted',
          id: 'id',
        }}
      />
    </DataTable>
  );
}

function TableSkeleton() {
  return (
    <Table.Skeleton
      rows={10}
      columns={[
        {
          id: 'checkbox',
          content: <Checkbox.Skeleton />,
          CellWrapper: ({ content }) => (
            <CheckboxTableHead>{content}</CheckboxTableHead>
          ),
        },
        {
          id: 'snippet',
          content: <SkeletonBone $width="88px" $height="16px" />,
        },
        {
          id: 'referenceId',
          content: <SkeletonBone $width="64px" $height="16px" />,
        },
        {
          id: 'operations',
          content: <SkeletonBone $width="53px" $height="16px" />,
        },
        {
          id: 'prices',
          content: <SkeletonBone $width="59px" $height="16px" />,
        },
        {
          id: 'impressions',
          content: <SkeletonBone $width="62px" $height="16px" />,
          alignment: TABLE_ALIGNMENT.CENTER,
        },
        {
          id: 'clicks',
          content: <SkeletonBone $width="62px" $height="16px" />,
          alignment: TABLE_ALIGNMENT.CENTER,
        },
        {
          id: 'enquiries',
          content: <SkeletonBone $width="62px" $height="16px" />,
          alignment: TABLE_ALIGNMENT.CENTER,
        },
        {
          id: 'date',
          content: <SkeletonBone $width="85px" $height="16px" />,
        },
        {
          id: 'actions',
          content: <SkeletonBone $width="87px" $height="16px" />,
        },
      ].filter(Boolean)}
    >
      <Table.Row>
        <CheckboxCell>
          <Checkbox.Skeleton />
        </CheckboxCell>
        <Table.Cell>
          <PropertySnippet.Skeleton />
        </Table.Cell>
        <Table.Cell>
          <SkeletonBone $width="60px" $height="14px" randomWidth />
        </Table.Cell>
        <Table.Cell>
          <SkeletonBone $width="60px" $height="14px" randomWidth />
        </Table.Cell>
        <Table.Cell>
          <SkeletonBone $width="60px" $height="14px" randomWidth />
        </Table.Cell>
        <Table.KpiCell>
          <SkeletonBone $width="38px" $height="14px" />
        </Table.KpiCell>
        <Table.KpiCell>
          <SkeletonBone $width="38px" $height="14px" />
        </Table.KpiCell>
        <Table.KpiCell>
          <SkeletonBone $width="38px" $height="14px" />
        </Table.KpiCell>
        <Table.Cell>
          <SkeletonBone $width="60px" $height="14px" randomWidth />
        </Table.Cell>
        <Table.Cell>
          <SkeletonBone $width="180px" $height="14px" />
        </Table.Cell>
      </Table.Row>
    </Table.Skeleton>
  );
}
