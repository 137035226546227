import styled, { css } from 'styled-components';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { withStylesAndInnerRef } from 'design-system/utils';
import { radius } from 'design-system/styles/radius';

const HiddenInput = styled.input.attrs({
  type: 'checkbox',
})`
  display: none;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${COLOR_PALETTE.SECONDARY_A20};
  ${radius.pill()}
  transition: 0.2s;

  &:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    ${radius.pill()}
    left: 2px;
    bottom: 2px;
    background-color: ${COLOR_PALETTE.NEUTRAL_A00};
    transition: 0.2s;
  }
`;

const modChecked = () => css`
  ${HiddenInput}:checked ~ ${Slider} {
    background-color: ${COLOR_PALETTE.SECONDARY_BASE};

    &:before {
      transform: translateX(16px);
    }
  }
`;

const modDisabled = () => css`
  ${HiddenInput}:disabled ~ ${Slider} {
    cursor: not-allowed;
    background-color: ${COLOR_PALETTE.SECONDARY_A10};

    &:before {
      background-color: ${COLOR_PALETTE.SECONDARY_A05};
    }
  }
`;

const Wrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  cursor: pointer;

  ${() => modChecked()}
  ${() => modDisabled()}
`;

function Switch({ className, style, innerRef, ...rest }) {
  return (
    <Wrapper className={className}>
      <HiddenInput {...rest} ref={innerRef} />
      <Slider className="slider" />
    </Wrapper>
  );
}

export default withStylesAndInnerRef(Switch);
