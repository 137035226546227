export function adSelector(state) {
  return state.adEditor?.ad ?? {};
}
export function adSavedSelector(state) {
  return state.adEditor?.saved ?? false;
}
export function adFailedSelector(state) {
  return state.adEditor?.failed ?? false;
}

export function adEditorFormCouldNotLoadAdSelector(state) {
  return state.adEditor?.error ?? false;
}
