export function isToastVisibleSelector(state) {
  return state.toast ? state.toast.visible : false;
}

export function toastTextSelector(state) {
  return state?.toast?.text || 'Toast has undefined value';
}

export function toastTextValuesSelector(state) {
  return state?.toast?.values ?? {};
}

export const TOAST_BOTTOM_POSITION = 'bottom';
export const TOAST_TOP_POSITION = 'top';
export const TOAST_CENTER_POSITION = 'center';
export function toastPositionSelector(state) {
  return (
    state?.toast?.position || {
      vertical: TOAST_BOTTOM_POSITION,
      horizontal: TOAST_CENTER_POSITION,
    }
  );
}
