import {
  planUsagePercentageSelector,
  showNotUsingYourWholePlanBannerSelector,
} from '../partials/NotUsingYourWholePlanBanner/selectors';
import {
  couldShowBannerSelector,
  showLeadQualityAwarenessBannerSelector,
} from '../partials/LeadQualityAwareness/selectors';

export const BANNERS = {
  notUsingYourWholePlan: Symbol('not-using-your-whole-plan'),
  leadQualityAwareness: Symbol('lead-quality-awareness'),
};

export function showNotUsingYourWholePlanBannerOrLeadQualityAwarenessSelector(
  state,
  now
) {
  const showNotUsingYourWholePlanBanner =
    showNotUsingYourWholePlanBannerSelector(state, now);
  const planUsagePercent = planUsagePercentageSelector(state);
  const showLeadQualityAwareness =
    showLeadQualityAwarenessBannerSelector(state);

  const couldShowBanner = couldShowBannerSelector(state);

  if (
    couldShowBanner &&
    showNotUsingYourWholePlanBanner &&
    planUsagePercent < 40
  ) {
    return BANNERS.notUsingYourWholePlan;
  }

  return showLeadQualityAwareness
    ? BANNERS.leadQualityAwareness
    : BANNERS.notUsingYourWholePlan;
}
