import { useMount } from 'react-use';
import { dispatchMany } from 'reffects';
import { useSelector } from 'reffects-store';
import { DEFAULT_CONTACT_REQUESTED } from '../events';

import { CREATE_AD_FORM_MOUNTED } from './events';

import AdForm from './AdForm';

function CreateAdForm() {
  useMount(() => {
    dispatchMany([CREATE_AD_FORM_MOUNTED, DEFAULT_CONTACT_REQUESTED]);
  });

  const isAdFormInitialized = useSelector(
    (state) => state['adForm:isInitialized']
  );

  return isAdFormInitialized && <AdForm />;
}

export default CreateAdForm;
