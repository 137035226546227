import styled from 'styled-components';
import Anchor from 'design-system/components/Anchor';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const BottomBanner = styled.div`
  ${spacing.inset(SPACING_SIZE.M)}
  ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
  ${radius.regular(RADIUS_SIZE.LARGE)}
  ${Body2} {
    display: block;
    ${typography.weight(FONT_WEIGHT.LIGHT)}
    ${spacing.stack(SPACING_SIZE.M)}
  }
`;

export default function BankTransferPaymentNotice({ supportEmail }) {
  return (
    <BottomBanner>
      <Body2>
        <FormattedMessageWithValidation id="subscription_bank_transfer_details_notice_text_1" />
      </Body2>
      <Body2>
        <FormattedMessageWithValidation
          id="subscription_bank_transfer_details_notice_text_2"
          values={{
            supportEmail: (
              <Anchor href={`mailto:${supportEmail}`}>{supportEmail}</Anchor>
            ),
          }}
        />
      </Body2>
    </BottomBanner>
  );
}
