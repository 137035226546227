import styled from 'styled-components';
import { SPACING_SIZE } from 'design-system/styles/spacing';
import Item from './Item';

const Wrapper = styled.div`
  display: grid;
  grid-row-gap: ${SPACING_SIZE.S}px;
  grid-column-gap: ${SPACING_SIZE.M}px;
  grid-template-columns: 1fr 1fr;
`;

function AmenityGrid({ children }) {
  return <Wrapper>{children}</Wrapper>;
}

AmenityGrid.Item = Item;

export default AmenityGrid;
