import * as yup from 'yup';

import FacebookMessengerField from '../../../../partials/fields/FacebookMessengerField';

import GenericDetailForm from './GenericDetailForm';
import useInternationalization from '../../../../hooks/useInternationalization';

function FacebookDetailForm({ detail, onSubmit, onCancel }) {
  const intl = useInternationalization();

  const handleFormSubmit = ({ contactFacebookMessenger }) =>
    onSubmit(contactFacebookMessenger);

  return (
    <GenericDetailForm
      defaultValues={{
        contactFacebookMessenger: detail.value,
      }}
      validationSchema={{
        contactFacebookMessenger: yup.string().required(
          intl.formatMessageWithValidation({
            id: 'form_validations_required_field',
          })
        ),
      }}
      onSubmit={handleFormSubmit}
      onCancel={onCancel}
    >
      <FacebookMessengerField name="contactFacebookMessenger" />
    </GenericDetailForm>
  );
}

export default FacebookDetailForm;
