import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import InputTip from 'design-system/components/InputTip';
import InputSectionLabel from '../InputSectionLabel';

import Card from './Card';

const Wrapper = styled.div`
  ${spacing.stack(SPACING_SIZE.XL)}
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(100px, 1fr));

  gap: ${spacing.value(SPACING_SIZE.M)};

  ${spacing.stack(SPACING_SIZE.S)};

  @media (min-width: ${1280 + 133}px) {
    grid-template-columns: repeat(6, minmax(100px, 1fr));
  }

  @media (min-width: ${1680 - 133}px) {
    grid-template-columns: repeat(7, minmax(100px, 1fr));
  }
`;

function InputCards({ label, children, tip, error, required, ...rest }) {
  return (
    <Wrapper {...rest}>
      <InputSectionLabel error={error} required={required}>
        {label}
      </InputSectionLabel>
      <Cards>{children}</Cards>
      <InputTip error={error}>{tip}</InputTip>
    </Wrapper>
  );
}

InputCards.Card = Card;

export default styled(InputCards)``;
