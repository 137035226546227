import styled from 'styled-components';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { Heading2 } from 'design-system/components/Typography/presets/Heading2';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import ContactSalesButton from './ContactSalesButton';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const SideSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${Heading2} {
    ${spacing.stack(SPACING_SIZE.M)}
  }

  ${Icon} {
    ${spacing.stack(SPACING_SIZE.M)}
  }

  ${Body1} {
    ${spacing.stack(SPACING_SIZE.L)}
  }
`;

function BenefitsContact({ title, description }) {
  return (
    <SideSection>
      <Heading2>
        <FormattedMessageWithValidation id={title} />
      </Heading2>
      <Icon
        $size={ICON_SIZE.BASE}
        $color={COLOR_PALETTE.PRIMARY_BASE}
        glyph="subscriptions"
      />
      <Body1 $weight={FONT_WEIGHT.LIGHT}>
        <FormattedMessageWithValidation id={description} />
      </Body1>
      <ContactSalesButton />
    </SideSection>
  );
}

export default BenefitsContact;
