import styled from 'styled-components';
import DropdownMenu from 'design-system/components/DropdownMenu';
import { DROPDOWN_POSITION } from 'design-system/components/Dropdown';
import { OutlineButton } from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import FilterDatepicker from '../../../partials/FilterDatepicker';
import CustomFormattedDate from '../../../partials/FormattedDate/CustomFormattedDate';

function DateRangeFilter({
  selectedRange: {
    translation,
    period: { startDate, endDate },
  },
  onRangeSelected,
  ...props
}) {
  return (
    <DropdownMenu
      position={DROPDOWN_POSITION.BOTTOM_RIGHT}
      button={
        <OutlineButton icon={<Icon glyph="chevronDown" />}>
          {translation ? (
            <FormattedMessageWithValidation id={translation} />
          ) : (
            <>
              <CustomFormattedDate value={startDate} />
              {' - '}
              <CustomFormattedDate value={endDate} />
            </>
          )}
        </OutlineButton>
      }
      onConfirm={onRangeSelected}
      {...props}
    >
      <FilterDatepicker start={startDate} end={endDate} maxDate={new Date()} />
    </DropdownMenu>
  );
}

export default styled(DateRangeFilter)``;
