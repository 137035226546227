import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useCallback } from 'react';

function onlyDirtyData(dirtyValuesMap, data) {
  const dirtyValuesDataEntries = Object.entries(data).filter(
    ([dataKey]) => dirtyValuesMap[dataKey]
  );

  return Object.fromEntries(dirtyValuesDataEntries);
}

export function Form({
  defaultValues,
  validationSchema,
  children,
  submitOnlyDirtyFields,
  onSubmit,
}) {
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object(validationSchema).required()),
  });

  const { handleSubmit, formState } = methods;

  const handleFormSubmit = useCallback(
    (data) => {
      if (submitOnlyDirtyFields) {
        const dirtyData = onlyDirtyData(formState.dirtyFields, data);
        onSubmit(dirtyData);
      } else {
        onSubmit(data);
      }
    },
    [submitOnlyDirtyFields, formState.dirtyFields]
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>{children}</form>
    </FormProvider>
  );
}
