import { useEffect, useState } from 'react';
import { Circle, GoogleMap, Marker } from '@react-google-maps/api';
import styled from 'styled-components';
import { COLOR_PALETTE } from 'design-system/styles/color';
import MapMarkerSvg from '../assets/illustrations/MapMarker.svg';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const containerStyle = {
  width: 'inherit',
  height: 'inherit',
};

function ProppitCircle({ center, radius }) {
  return (
    <Circle
      center={center}
      radius={radius}
      options={{
        strokeColor: COLOR_PALETTE.PRIMARY_BASE,
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: COLOR_PALETTE.PRIMARY_BASE,
        fillOpacity: 0.4,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        zIndex: 1,
      }}
    />
  );
}

const CIRCLES_RADIUS = 500;
const HEADING_NORTH = 0;
const HEADING_EAST = 90;
const HEADING_SOUTH = 180;
const HEADING_WEST = -90;
const HEADINGS = [HEADING_WEST, HEADING_NORTH, HEADING_EAST, HEADING_SOUTH];

function Map({
  coordinates,
  zoom = 15,
  circles = [],
  showMarker = false,
  showCircle = false,
  draggable = false,
  draggableMarker = false,
  zoomControls = false,
  zoomWithWheel = false,
  onDragMarker,
  circleFill = COLOR_PALETTE.PRIMARY_BASE,
  circleStroke = COLOR_PALETTE.PRIMARY_BASE,
  ...rest
}) {
  const center = coordinates
    ? { lat: coordinates.latitude, lng: coordinates.longitude }
    : undefined;
  const [map, setMap] = useState(null);
  useEffect(() => {
    const geometry = window.google.maps.geometry.spherical;
    if (coordinates || !map) return;
    const bounds = new window.google.maps.LatLngBounds();
    circles.forEach((item) => {
      const latLng = new window.google.maps.LatLng(
        item.latitude,
        item.longitude
      );
      HEADINGS.forEach((heading) =>
        bounds.extend(
          geometry.computeOffset(latLng, CIRCLES_RADIUS / 10, heading)
        )
      );
    });
    map.fitBounds(bounds);
  }, [map, circles]);
  return (
    <Wrapper {...rest}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        options={{
          mapTypeControl: false,
          panControl: false,
          rotateControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          draggable,
          gestureHandling: zoomWithWheel ? 'auto' : 'cooperative',
          zoomControl: zoomControls,
        }}
        onLoad={setMap}
        onUnmount={() => setMap(null)}
      >
        {showMarker && (
          <Marker
            cursor={draggableMarker ? 'grab' : 'default'}
            icon={{ url: MapMarkerSvg }}
            draggable={draggableMarker}
            onDragEnd={onDragMarker}
            position={center}
          />
        )}
        {showCircle && (
          <ProppitCircle
            center={center}
            radius={450}
            fill={circleFill}
            stroke={circleStroke}
          />
        )}
        {/* eslint-disable-next-line no-shadow */}
        {circles.map((center) => (
          <ProppitCircle
            center={{ lat: center.latitude, lng: center.longitude }}
            radius={CIRCLES_RADIUS}
            key={`${center.latitude}-${center.longitude}`}
          />
        ))}
      </GoogleMap>
    </Wrapper>
  );
}

export default styled(Map)``;
