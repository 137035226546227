import * as React from "react";
const SvgWelcome = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={280}
    height={222}
    fill="none"
    {...props}
  >
    <circle cx={142} cy={111} r={111} fill="#E7FAF3" />
    <path fill="#fff" stroke="#9DEBCF" strokeWidth={2} d="M1 86h51v41.578H1z" />
    <path
      stroke="#9DEBCF"
      strokeLinecap="round"
      strokeWidth={2}
      d="M1.066 91.856H51.6"
    />
    <path
      fill="#fff"
      stroke="#9DEBCF"
      strokeWidth={2}
      d="M234 87.867h29v23.489h-29z"
    />
    <path
      stroke="#9DEBCF"
      strokeLinecap="round"
      strokeWidth={2}
      d="M235 92h27"
    />
    <path
      fill="#fff"
      stroke="#9DEBCF"
      strokeWidth={2}
      d="M248 126h29v23.489h-29z"
    />
    <path
      stroke="#9DEBCF"
      strokeLinecap="round"
      strokeWidth={2}
      d="M249 130.133h27"
    />
    <path
      fill="#fff"
      stroke="#9DEBCF"
      strokeWidth={2}
      d="M228 8h51v41.578h-51z"
    />
    <path
      stroke="#9DEBCF"
      strokeLinecap="round"
      strokeWidth={2}
      d="M228.066 13.856h50.535"
    />
    <path
      fill="#fff"
      stroke="#9DEBCF"
      strokeWidth={2}
      d="M20.257 143h32.486v26.356H20.257z"
    />
    <path
      stroke="#9DEBCF"
      strokeLinecap="round"
      strokeWidth={2}
      d="M20.5 147h32"
    />
    <path
      stroke="#9DEBCF"
      strokeDasharray="4 4"
      strokeWidth={2}
      d="m227 29-38.294 39.707M233.293 100 202 113M249 138h-45M88 149l-35 6M90.5 126.5 52 110M103 102 84 77"
    />
    <mask
      id="Welcome_svg__a"
      width={220}
      height={220}
      x={32}
      y={2}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <circle cx={142} cy={112} r={110} fill="#C4C4C4" />
    </mask>
    <g mask="url(#Welcome_svg__a)">
      <path
        fill="#F8F8F9"
        fillRule="evenodd"
        d="M110.458 102.44c.805 0 1.534.327 2.062.855s.854 1.256.854 2.061v124.409H97.823V105.356c0-.805.326-1.533.854-2.061a2.9 2.9 0 0 1 2.062-.855z"
        clipRule="evenodd"
      />
      <path
        fill="#0ACC86"
        fillRule="evenodd"
        d="M100.739 103.413c-.537 0-1.021.216-1.374.569s-.57.838-.57 1.375v123.437h13.607V105.357c0-.537-.216-1.022-.569-1.375a1.94 1.94 0 0 0-1.375-.569zm-2.749-.805a3.88 3.88 0 0 1 2.749-1.139h9.719c1.073 0 2.047.436 2.749 1.139a3.88 3.88 0 0 1 1.139 2.749v125.38H96.851v-125.38c0-1.074.437-2.047 1.14-2.749"
        clipRule="evenodd"
      />
      <path
        fill="#E7FAF3"
        fillRule="evenodd"
        d="M120.178 110.216c.805 0 1.534.326 2.062.854a2.9 2.9 0 0 1 .854 2.062v124.409h-34.99V113.132c0-.805.327-1.534.854-2.062a2.9 2.9 0 0 1 2.062-.854z"
        clipRule="evenodd"
      />
      <path
        fill="#0ACC86"
        fillRule="evenodd"
        d="M91.02 111.188c-.536 0-1.021.217-1.374.57s-.57.838-.57 1.374v123.437h33.046V113.132c0-.536-.216-1.021-.569-1.374a1.94 1.94 0 0 0-1.375-.57zm-2.749-.805a3.88 3.88 0 0 1 2.75-1.139h29.157c1.074 0 2.047.437 2.749 1.139a3.88 3.88 0 0 1 1.139 2.749v125.381H87.132V113.132a3.88 3.88 0 0 1 1.14-2.749"
        clipRule="evenodd"
      />
      <path
        fill="#0ACC86"
        fillRule="evenodd"
        d="M91.02 109.244h3.888v129.269h-7.776V113.132a3.887 3.887 0 0 1 3.888-3.888m21.383 31.102h3.888v98.167h-7.776v-94.279a3.887 3.887 0 0 1 3.888-3.888"
        clipRule="evenodd"
        opacity={0.3}
      />
      <path
        fill="#0ACC86"
        d="M123.094 124.795H89.076a.972.972 0 1 0 0 1.944h34.018a.972.972 0 0 0 0-1.944M123.093 129.654H89.075a.972.972 0 1 0 0 1.944h34.018a.972.972 0 1 0 0-1.944M123.093 134.515H89.075a.972.972 0 1 0 0 1.944h34.018a.972.972 0 0 0 0-1.944M123.093 139.375H89.075a.972.972 0 1 0 0 1.944h34.018a.972.972 0 1 0 0-1.944M123.093 144.233H89.075a.973.973 0 0 0 0 1.944h34.018a.972.972 0 1 0 0-1.944M123.093 149.094H89.075a.972.972 0 1 0 0 1.944h34.018a.972.972 0 0 0 0-1.944M123.093 153.953H89.075a.972.972 0 1 0 0 1.944h34.018a.972.972 0 1 0 0-1.944M123.093 158.813H89.075a.973.973 0 0 0 0 1.944h34.018a.972.972 0 1 0 0-1.944M123.093 163.673H89.075a.972.972 0 1 0 0 1.944h34.018a.972.972 0 1 0 0-1.944M123.093 168.533H89.075a.972.972 0 1 0 0 1.944h34.018a.972.972 0 1 0 0-1.944M123.093 173.393H89.075a.972.972 0 1 0 0 1.943h34.018a.972.972 0 1 0 0-1.943M123.093 178.252H89.075a.972.972 0 1 0 0 1.944h34.018a.972.972 0 1 0 0-1.944M123.093 183.111H89.075a.972.972 0 1 0 0 1.944h34.018a.972.972 0 1 0 0-1.944M123.093 187.972H89.075a.972.972 0 1 0 0 1.944h34.018a.972.972 0 0 0 0-1.944M123.093 192.831H89.075a.972.972 0 1 0 0 1.944h34.018a.972.972 0 1 0 0-1.944M123.093 197.691H89.075a.973.973 0 0 0 0 1.944h34.018a.972.972 0 1 0 0-1.944M123.093 202.551H89.075a.972.972 0 1 0 0 1.944h34.018a.972.972 0 1 0 0-1.944M123.093 207.41H89.075a.972.972 0 1 0 0 1.944h34.018a.972.972 0 1 0 0-1.944M123.093 212.271H89.075a.972.972 0 1 0 0 1.943h34.018a.972.972 0 1 0 0-1.943M123.093 217.13H89.075a.972.972 0 1 0 0 1.944h34.018a.972.972 0 1 0 0-1.944M123.093 221.989H89.075a.972.972 0 1 0 0 1.944h34.018a.972.972 0 1 0 0-1.944"
      />
      <path
        fill="#E7FAF3"
        fillRule="evenodd"
        d="M91.992 154.926c.804 0 1.533.326 2.061.854s.854 1.257.854 2.062v79.699H78.384v-79.699c0-.805.327-1.534.855-2.062a2.9 2.9 0 0 1 2.061-.854z"
        clipRule="evenodd"
      />
      <path
        fill="#0ACC86"
        fillRule="evenodd"
        d="M81.3 155.897c-.537 0-1.021.217-1.374.57s-.57.838-.57 1.374v78.728h14.58v-78.728c0-.536-.217-1.021-.57-1.374a1.94 1.94 0 0 0-1.375-.57zm-2.749-.805a3.88 3.88 0 0 1 2.749-1.139H91.99a3.88 3.88 0 0 1 2.749 1.139 3.88 3.88 0 0 1 1.14 2.749v80.671H77.411v-80.671a3.88 3.88 0 0 1 1.14-2.749"
        clipRule="evenodd"
      />
      <path
        fill="#0ACC86"
        d="M88.104 159.785h-8.747a.972.972 0 1 0 0 1.944h8.747a.972.972 0 1 0 0-1.944M88.104 164.645h-8.748a.972.972 0 1 0 0 1.943h8.748a.972.972 0 1 0 0-1.943M88.104 169.505h-8.748a.972.972 0 1 0 0 1.944h8.748a.972.972 0 1 0 0-1.944M88.104 174.364h-8.748a.972.972 0 1 0 0 1.944h8.748a.972.972 0 1 0 0-1.944M88.104 179.224h-8.748a.972.972 0 1 0 0 1.944h8.748a.973.973 0 0 0 0-1.944M88.104 184.084h-8.748a.972.972 0 1 0 0 1.944h8.748a.972.972 0 1 0 0-1.944M88.104 188.943h-8.748a.972.972 0 0 0 0 1.944h8.748a.972.972 0 1 0 0-1.944M88.104 193.804h-8.748a.972.972 0 1 0 0 1.944h8.748a.973.973 0 0 0 0-1.944"
      />
      <path
        fill="#0ACC86"
        fillRule="evenodd"
        d="M179.953 161.729V38.292a.972.972 0 0 1 1.944 0V161.73a.972.972 0 0 1-1.944 0"
        clipRule="evenodd"
      />
      <path
        fill="#E7FAF3"
        fillRule="evenodd"
        d="M186.27 63.644c.805 0 1.534.326 2.062.854a2.9 2.9 0 0 1 .854 2.061v106.753h-17.495V66.559c0-.804.326-1.533.854-2.061a2.9 2.9 0 0 1 2.062-.854z"
        clipRule="evenodd"
      />
      <path
        fill="#0ACC86"
        fillRule="evenodd"
        d="M174.607 64.616c-.536 0-1.021.216-1.374.57-.353.353-.57.837-.57 1.374v105.78h15.551V66.56c0-.537-.216-1.021-.569-1.375a1.94 1.94 0 0 0-1.375-.57zm-2.749-.805a3.88 3.88 0 0 1 2.749-1.14h11.663c1.074 0 2.047.438 2.749 1.14a3.88 3.88 0 0 1 1.139 2.749v107.724h-19.439V66.56a3.88 3.88 0 0 1 1.139-2.75"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M194.045 79.114c.805 0 1.534.327 2.061.855.528.527.855 1.256.855 2.061v149.68h-33.046V82.03c0-.805.326-1.534.854-2.061a2.9 2.9 0 0 1 2.061-.855z"
        clipRule="evenodd"
      />
      <path
        fill="#0ACC86"
        fillRule="evenodd"
        d="M166.831 80.086c-.537 0-1.021.216-1.375.57a1.94 1.94 0 0 0-.569 1.373v148.708h31.102V82.029c0-.536-.217-1.02-.57-1.374a1.93 1.93 0 0 0-1.374-.57zm-2.749-.805a3.88 3.88 0 0 1 2.749-1.14h27.214a3.88 3.88 0 0 1 2.749 1.14 3.88 3.88 0 0 1 1.139 2.748v150.652h-34.99V82.029c0-1.073.436-2.046 1.139-2.748"
        clipRule="evenodd"
      />
      <path
        fill="#0ACC86"
        fillRule="evenodd"
        d="M166.831 78.142h27.214a3.887 3.887 0 0 1 3.888 3.887v150.652h-34.99V82.029a3.887 3.887 0 0 1 3.888-3.887"
        clipRule="evenodd"
        opacity={0.2}
      />
      <path
        fill="#E7FAF3"
        fillRule="evenodd"
        d="M160.999 84.945c-.804 0-1.533.327-2.061.855a2.9 2.9 0 0 0-.855 2.061v149.68h44.71V87.861a2.9 2.9 0 0 0-.854-2.061 2.9 2.9 0 0 0-2.062-.855z"
        clipRule="evenodd"
      />
      <path
        fill="#0ACC86"
        fillRule="evenodd"
        d="M158.25 85.113a3.88 3.88 0 0 1 2.749-1.14h38.878c1.073 0 2.046.437 2.749 1.14a3.88 3.88 0 0 1 1.139 2.748v150.652h-46.654V87.861a3.88 3.88 0 0 1 1.139-2.748m2.749.805c-.536 0-1.021.216-1.374.57-.353.352-.57.837-.57 1.373V236.57h42.766V87.861c0-.536-.217-1.02-.57-1.374a1.93 1.93 0 0 0-1.374-.57z"
        clipRule="evenodd"
      />
      <path
        fill="#0ACC86"
        fillRule="evenodd"
        d="M199.877 83.974h-2.916v154.539h6.804V87.861a3.887 3.887 0 0 0-3.888-3.887"
        clipRule="evenodd"
        opacity={0.3}
      />
      <path
        fill="#0ACC86"
        fillRule="evenodd"
        d="M162.943 222.962V99.525a.972.972 0 0 1 1.944 0v123.437a.973.973 0 0 1-1.944 0M167.802 222.962V99.525a.973.973 0 0 1 1.944 0v123.437a.972.972 0 0 1-1.944 0M172.662 222.962V99.525a.972.972 0 0 1 1.944 0v123.437a.973.973 0 0 1-1.944 0M177.522 222.962V99.525a.973.973 0 0 1 1.944 0v123.437a.972.972 0 0 1-1.944 0M182.381 222.962V99.525a.973.973 0 0 1 1.944 0v123.437a.972.972 0 0 1-1.944 0M187.241 222.962V99.525a.973.973 0 0 1 1.944 0v123.437a.972.972 0 0 1-1.944 0M192.101 222.962V99.525a.972.972 0 0 1 1.943 0v123.437a.972.972 0 0 1-1.943 0M196.961 222.962V99.525a.972.972 0 0 1 1.944 0v123.437a.973.973 0 0 1-1.944 0"
        clipRule="evenodd"
      />
      <path
        fill="#E7FAF3"
        fillRule="evenodd"
        d="M165.86 135.486c.804 0 1.533.327 2.061.855.528.527.854 1.256.854 2.061v99.139h-55.4v-99.139c0-.805.326-1.534.854-2.061a2.9 2.9 0 0 1 2.061-.855z"
        clipRule="evenodd"
      />
      <path
        fill="#0ACC86"
        fillRule="evenodd"
        d="M116.29 136.459c-.537 0-1.021.216-1.375.569a1.94 1.94 0 0 0-.569 1.374v98.167h53.457v-98.167c0-.536-.217-1.021-.57-1.374a1.94 1.94 0 0 0-1.374-.569zm-2.749-.805a3.88 3.88 0 0 1 2.749-1.139h49.569c1.073 0 2.046.436 2.749 1.139a3.88 3.88 0 0 1 1.139 2.748v100.111h-57.345V138.402c0-1.073.436-2.046 1.139-2.748"
        clipRule="evenodd"
      />
      <path
        fill="#0ACC86"
        d="M159.055 142.29h-35.962a.972.972 0 1 0 0 1.944h35.962a.972.972 0 1 0 0-1.944M159.055 147.149h-35.962a.972.972 0 0 0 0 1.944h35.962a.972.972 0 0 0 0-1.944M159.055 152.009h-35.962a.972.972 0 1 0 0 1.944h35.962a.972.972 0 0 0 0-1.944M159.055 156.869h-35.962a.972.972 0 1 0 0 1.944h35.962a.972.972 0 0 0 0-1.944M159.055 161.729h-35.962a.972.972 0 1 0 0 1.943h35.962a.972.972 0 0 0 0-1.943M159.055 166.589h-35.962a.972.972 0 1 0 0 1.944h35.962a.972.972 0 0 0 0-1.944M159.055 171.448h-35.962a.972.972 0 1 0 0 1.944h35.962a.972.972 0 0 0 0-1.944M159.055 176.309h-35.962a.972.972 0 1 0 0 1.943h35.962a.971.971 0 0 0 0-1.943M159.055 181.168h-35.962a.972.972 0 1 0 0 1.944h35.962a.972.972 0 0 0 0-1.944M159.055 186.027h-35.962a.972.972 0 0 0 0 1.944h35.962a.972.972 0 0 0 0-1.944M159.055 190.888h-35.962a.972.972 0 1 0 0 1.944h35.962a.972.972 0 0 0 0-1.944M159.055 195.747h-35.962a.972.972 0 1 0 0 1.944h35.962a.972.972 0 0 0 0-1.944M159.055 200.606h-35.962a.972.972 0 0 0 0 1.944h35.962a.972.972 0 0 0 0-1.944M159.055 205.467h-35.962a.972.972 0 1 0 0 1.944h35.962a.972.972 0 0 0 0-1.944M159.055 210.326h-35.962a.972.972 0 1 0 0 1.944h35.962a.972.972 0 0 0 0-1.944M159.055 215.187h-35.962a.972.972 0 1 0 0 1.943h35.962a.972.972 0 0 0 0-1.943"
      />
      <path
        fill="#E7FAF3"
        stroke="#9DEBCF"
        strokeWidth={2}
        d="M69 53h29.622v24H69z"
      />
      <path
        stroke="#9DEBCF"
        strokeLinecap="round"
        strokeWidth={2}
        d="M69.636 57.09h29"
      />
    </g>
  </svg>
);
export default SvgWelcome;
