import styled, { css } from 'styled-components';
import { withStylesAndInnerRef } from 'design-system/utils';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { border, BORDER_SIZE } from 'design-system/styles/border';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import {
  color,
  COLOR_PALETTE,
  COLOR_SEMANTIC,
} from 'design-system/styles/color';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  transition: background 300ms, color 300ms;

  ${radius.regular(RADIUS_SIZE.LARGE)}
  ${spacing.insetStretch(SPACING_SIZE.M)}
  ${color.background(COLOR_SEMANTIC.PAPER)}
  ${border.outer({
    size: BORDER_SIZE.BASE,
    color: COLOR_PALETTE.NEUTRAL_A10,
  })}

  ${Icon} {
    transition: color 300ms;

    ${Icon.mods.size(ICON_SIZE.BASE)}
    ${spacing.stack(SPACING_SIZE.XS)}
    ${color.text(COLOR_PALETTE.NEUTRAL_A40)}
  }

  ${Body1} {
    display: flex;
    align-items: center;
    height: 4ex;
    text-align: center;
  }

  :hover {
    ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
  }
`;

const Input = styled.input.attrs({
  type: 'checkbox',
})`
  display: none;
`;

const modHighlightWhenChecked = () => css`
  ${Input}:checked ~ ${Content} {
    ${Icon} {
      ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
    }

    ${Body1} {
      ${Body1.mods.color(COLOR_PALETTE.NEUTRAL_A00)}
    }

    ${color.background(COLOR_PALETTE.SECONDARY_BASE)}
    ${border.inner({
      size: BORDER_SIZE.NONE,
    })}
  }
`;

const Wrapper = styled.label`
  position: relative;
  display: inline-block;

  cursor: pointer;
  user-select: none;

  ${() => modHighlightWhenChecked()}
`;

function Card({ icon, className, label, innerRef, ...rest }) {
  return (
    <Wrapper className={className}>
      <Input ref={innerRef} {...rest} />
      <Content>
        {icon}
        <Body1 $weight={FONT_WEIGHT.LIGHT}>{label}</Body1>
      </Content>
    </Wrapper>
  );
}

export default withStylesAndInnerRef(Card);
