import styled from 'styled-components';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import Image from 'design-system/components/Image/Image';
import Thumbnail from 'design-system/components/Thumbnail';
import SkeletonBone from 'design-system/components/SkeletonBone';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import {
  FONT_FAMILY,
  FONT_SIZE,
  typography,
} from 'design-system/styles/typography';
import { Subtitle2 } from 'design-system/components/Typography/presets/Subtitle2';
import Typography from 'design-system/components/Typography/Typography';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import OperationType from '../../../../../partials/OperationType';
import Price from '../../../../../partials/Price/Price';
import LinkButton from '../../../../Overview/partials/LinkButton';
import { OPEN_PROPERTY_DETAIL } from '../../EnquiredProperties/events';
import { showEnquiredPropertiesListSelector } from '../../EnquiredProperties/PropertyDetail/selectors';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Wrapper = styled.div`
  ${spacing.inset(SPACING_SIZE.S)}
  ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
  ${radius.regular(RADIUS_SIZE.LARGE)}
  ${typography.font(FONT_FAMILY.PRIMARY)}
  ${({ $clickable }) => $clickable && 'cursor: pointer;'}
  display: flex;
  flex-direction: row;
  max-width: 320px;

  ${Thumbnail}, ${Image.Skeleton} {
    ${Thumbnail.mods.size(102, 68)}
    ${spacing.inline(SPACING_SIZE.M)}
  }

  ${SkeletonBone} {
    display: block;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const LinkButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const ProjectName = styled(Subtitle2)`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${Icon} {
    ${spacing.inline(SPACING_SIZE.XS, false)}
  }
  ${Typography} {
    ${Typography.mods.ellipse()}
    ${Typography.mods.size(FONT_SIZE.S)}
    max-width: 160px;
  }
`;

const OperationTypeWrapper = styled(Subtitle2)`
  ${typography.size(FONT_SIZE.S)}
`;

function PropertyInfo({ property }) {
  return (
    <>
      <Subtitle2>
        <OperationType operationType={property.operationType} />
      </Subtitle2>
      {property.price.amount != null && (
        <Subtitle2>
          <Price {...property.price} />
        </Subtitle2>
      )}
      <Body2>{property.address}</Body2>
    </>
  );
}

function UnitInfo({ property, project }) {
  return (
    <>
      {property.bedrooms != null && (
        <Body2>
          {property.bedrooms}{' '}
          <FormattedMessageWithValidation id="newprop_rooms_emptystate" />
        </Body2>
      )}
      {property.bathrooms != null && (
        <Body2>
          {property.bathrooms}{' '}
          <FormattedMessageWithValidation id="newprop_bathrooms_emptystate" />
        </Body2>
      )}
      <OperationTypeWrapper>
        <OperationType operationType={property.operationType} />
        {property.price.amount != null && (
          <>
            <span> - </span>
            <Price {...property.price} />
          </>
        )}
      </OperationTypeWrapper>
      <ProjectName>
        <Icon glyph="building" $size={ICON_SIZE.XSMALL} />
        <Typography>{project.title}</Typography>
      </ProjectName>
    </>
  );
}

function PropertySnippet({
  property,
  project,
  openPropertyDetail = true,
  ...rest
}) {
  const showEnquiredPropertiesList = useSelector(
    showEnquiredPropertiesListSelector
  );
  if (!property) return null;

  return (
    <Wrapper
      {...rest}
      $clickable={showEnquiredPropertiesList}
      onClick={() => {
        if (showEnquiredPropertiesList) {
          dispatch({
            id: OPEN_PROPERTY_DETAIL,
            payload: { propertyId: property.id },
          });
        }
      }}
    >
      <Thumbnail source={property.image} />
      <Info>
        {project ? (
          <UnitInfo property={property} project={project} />
        ) : (
          <PropertyInfo property={property} />
        )}
        {openPropertyDetail && showEnquiredPropertiesList && (
          <LinkButtonWrapper>
            <LinkButton />
          </LinkButtonWrapper>
        )}
      </Info>
    </Wrapper>
  );
}

function PropertySkeleton(props) {
  return (
    <Wrapper {...props}>
      <Image.Skeleton width="102px" height="68px" />
      <Info>
        <SkeletonBone $width="86px" $height="12px" />
        <SkeletonBone $width="98px" $height="12px" />
        <SkeletonBone $width="84px" $height="12px" />
        <SkeletonBone $width="171px" $height="12px" />
      </Info>
    </Wrapper>
  );
}

PropertySnippet.Skeleton = PropertySkeleton;

export default PropertySnippet;
