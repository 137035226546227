import { createSelector } from 'reselect';
import { collectionSelector } from './collection';

const defaultSortingParameter = (_, { defaultSorting }) => defaultSorting;

export const sortingSelector = createSelector(
  collectionSelector,
  defaultSortingParameter,
  (collection, defaultSorting = {}) => {
    const sorting = collection?.sorting ?? defaultSorting;

    const { field = null, direction = null } = sorting;

    return {
      field,
      direction,
    };
  }
);
