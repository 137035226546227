import { effects as reffects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { environment } from '../../coeffects/environment';
import { DEFAULT_PAGE, DEFAULT_ROW_PER_PAGE } from '../Tables/constants';
import { PROJECT_UNITS_LOAD } from './partials/Units/events';

export const LOAD_PROJECT = 'LOAD_PROJECT';
export const LOAD_PROJECT_SUCCESS = 'LOAD_PROJECT_SUCCESS';

registerEventHandler(
  LOAD_PROJECT,
  ({ environment: { apiUrl } }, { projectId }) => {
    const pagination = {
      currentPage: DEFAULT_PAGE,
      rowsPerPage: DEFAULT_ROW_PER_PAGE,
    };

    return {
      ...state.set({
        projectUnitsTable: {
          isLoading: true,
          rows: [],
          pagination,
          sorting: null,
          filters: null,
        },
      }),
      ...http.get({
        url: `${apiUrl}/developers/me/projects/${projectId}`,
        successEvent: LOAD_PROJECT_SUCCESS,
      }),
    };
  },
  [environment()]
);

registerEventHandler(
  LOAD_PROJECT_SUCCESS,
  (_, [{ data }]) => ({
    ...state.set({
      project: data,
    }),
    ...reffects.dispatch({
      id: PROJECT_UNITS_LOAD,
      payload: { projectId: data.id },
    }),
  }),
  [environment()]
);
