import styled, { css } from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { useSelector } from 'reffects-store';
import Card from './Card';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { propertyTypesTranslationTags } from '../../../../constants/ad';
import Section from '../Section';
import { isPublisherFromSEASelector } from '../../../../selectors/publisher';

const hasDescription = (p) => Boolean(p.description);
const DescriptionText = styled(({ project, ...props }) =>
  hasDescription(project) ? (
    // eslint-disable-next-line react/no-danger
    <p {...props} dangerouslySetInnerHTML={{ __html: project.description }} />
  ) : null
)``;

const hasFeatures = (p) => p.buildings || p.floors || p.propertyType;
const FeaturesWrapper = styled.div`
  display: grid;
  ${({ inline }) =>
    inline
      ? css`
          width: 100%;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: ${spacing.value(SPACING_SIZE.XL)};
          ${Card} {
            width: 100%;
            height: 60px;
          }
        `
      : css`
          grid-template-rows: repeat(3, 1fr);
          ${Card} {
            height: 67px;
          }
        `}
  ${Card} {
    ${spacing.stack(SPACING_SIZE.S)}
  }
`;
const Features = styled(({ project, ...props }) =>
  hasFeatures(project) ? (
    <FeaturesWrapper {...props}>
      {project.buildings && (
        <Card glyph="building" fullWidth={!project.description}>
          <FormattedMessageWithValidation
            id="project_detail_buildings"
            values={{ count: project.buildings }}
          />
        </Card>
      )}
      {project.floors && (
        <Card glyph="lift" fullWidth={!project.description}>
          <FormattedMessageWithValidation
            id="project_detail_floors"
            values={{ count: project.floors }}
          />
        </Card>
      )}
      {project.propertyType && (
        <Card glyph="apartment" fullWidth={!project.description}>
          <FormattedMessageWithValidation
            id={propertyTypesTranslationTags.get(project.propertyType)}
          />
        </Card>
      )}
    </FeaturesWrapper>
  ) : null
)``;

const Wrapper = styled(Section)`
  width: 100%;
  ${spacing.inline(SPACING_SIZE.XXXL)}

  ${DescriptionText} {
    white-space: pre-wrap;
  }
`;

const LatamWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: ${spacing.value(SPACING_SIZE.XXXL)};
`;

function Description({ project, ...props }) {
  const isFromSEA = useSelector(isPublisherFromSEASelector);
  if (!hasDescription(project) && !hasFeatures(project)) {
    return null;
  }

  return (
    <Wrapper
      title={
        <FormattedMessageWithValidation id="project_detail_description_title" />
      }
      {...props}
    >
      {isFromSEA ? (
        <>
          <Features project={project} inline />
          <DescriptionText project={project} />
        </>
      ) : (
        <LatamWrapper>
          <DescriptionText project={project} />
          <Features project={project} inline={!hasDescription(project)} />
        </LatamWrapper>
      )}
    </Wrapper>
  );
}

export default styled(Description)``;
