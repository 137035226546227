import { dispatch } from 'reffects';
import Dialog from 'design-system/components/Dialog/Dialog';
import {
  FlatButton,
  SecondaryButton,
} from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import { USER_DELETION_CONFIRMED } from './events';
import { useDialog } from '../../../hooks/useDialog';
import { DELETE_USER_CONFIRMATION_MODAL } from './constants';

export default function DeleteUserConfirmationDialog({ onSuccessEvent }) {
  const { open, closeDialog, parameters } = useDialog(
    DELETE_USER_CONFIRMATION_MODAL
  );
  const user = parameters?.user;
  return (
    <Dialog open={open}>
      <Dialog.Header
        onClose={closeDialog}
      >{`Are you sure you want to delete the user ${user?.email} ?`}</Dialog.Header>
      <Dialog.Footer>
        <FlatButton onClick={closeDialog} $size={BUTTON_SIZE.SMALL}>
          Cancel
        </FlatButton>
        <SecondaryButton
          onClick={() =>
            dispatch({
              id: USER_DELETION_CONFIRMED,
              payload: { userId: user.id, onSuccessEvent },
            })
          }
          $size={BUTTON_SIZE.SMALL}
        >
          Confirm
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}
