import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import InputTip from 'design-system/components/InputTip';
import InputSectionLabel from '../InputSectionLabel';

import Option from './Option';

const Wrapper = styled.div`
  ${spacing.stack(SPACING_SIZE.XL)}
`;

const Options = styled.div`
  ${spacing.stack(SPACING_SIZE.S)}
`;

function InputCards({ label, children, tip, error, required, ...rest }) {
  return (
    <Wrapper {...rest}>
      <InputSectionLabel error={error} required={required}>
        {label}
      </InputSectionLabel>
      <Options>{children}</Options>
      <InputTip error={error}>{tip}</InputTip>
    </Wrapper>
  );
}

InputCards.Option = Option;

export default styled(InputCards)``;
