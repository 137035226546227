import styled from 'styled-components';

import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { divider, DIVIDER_SIZE } from 'design-system/styles/divider';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import NoIconWidth from './BaseItem';
import { noop, withoutEventPropagation } from '../../../utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing.value(SPACING_SIZE.S)};

  ${divider.top(DIVIDER_SIZE.THIN)}
  ${spacing.insetSquish(SPACING_SIZE.M)}
  ${color.background(COLOR_PALETTE.PUMPKIN_A10)}
  ${Icon} {
    ${Icon.mods.size(ICON_SIZE.BASE)}
    ${color.text(COLOR_PALETTE.PUMPKIN_BASE)}
  }
`;

function Highlight({ children, icon }) {
  return (
    <Wrapper onClick={withoutEventPropagation(noop)}>
      {icon || (
        <NoIconWidth>
          <Icon glyph="noIcon" />
        </NoIconWidth>
      )}
      {children}
    </Wrapper>
  );
}

export default Highlight;
