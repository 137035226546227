import styled from 'styled-components';
import { SPACING_SIZE } from 'design-system/styles/spacing';
import Cell from './Cell';

const Wrapper = styled.div`
  display: grid;
  grid-row-gap: ${SPACING_SIZE.S}px;
  grid-template-columns: 1fr 1fr;
`;

function FeatureTable({ children }) {
  return <Wrapper>{children}</Wrapper>;
}

FeatureTable.Cell = Cell;

export default FeatureTable;
