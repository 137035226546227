import { useMatch } from 'react-router-dom';
import { useSelector } from 'reffects-store';
import { useIsPublicRoute } from '../../App/router';
import DeveloperCampaignsBanner from '../DeveloperCampaignsBanner';
import PendingSubscriptionChangeRequestBanner from '../PendingSubscriptionChangeRequestBanner';
import NotUsingYourWholePlanBanner from '../NotUsingYourWholePlanBanner/NotUsingYourWholePlanBanner';
import {
  BANNERS,
  showNotUsingYourWholePlanBannerOrLeadQualityAwarenessSelector,
} from '../../selectors/banners';
import {
  ENQUIRIES,
  OVERVIEW,
  UPLOAD_CSV,
  UPLOAD_CSV_FINAL,
} from '../../constants/routes';

function Banners() {
  const isPublicRoute = useIsPublicRoute();
  const isCreatingProperty = useMatch('/properties/new-property');
  const isEditingProperty = useMatch('/properties/:propertyId/edit');
  const isOverview = useMatch(OVERVIEW);
  const isInbox = useMatch(ENQUIRIES);
  const isUploadCSV = useMatch(UPLOAD_CSV);
  const isUploadCSVFinal = useMatch(UPLOAD_CSV_FINAL);
  const banner = useSelector((state) =>
    showNotUsingYourWholePlanBannerOrLeadQualityAwarenessSelector(
      state,
      new Date()
    )
  );

  if (isPublicRoute || isCreatingProperty || isEditingProperty) {
    return null;
  }

  return (
    <>
      <DeveloperCampaignsBanner />
      <PendingSubscriptionChangeRequestBanner />
      {!isOverview &&
        !isUploadCSV &&
        !isUploadCSVFinal &&
        (!isInbox || banner === BANNERS.notUsingYourWholePlan) && (
          <NotUsingYourWholePlanBanner />
        )}
    </>
  );
}

export default Banners;
