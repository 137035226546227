import { createSelector } from 'reselect';
import { projectSuggestionsSelector } from '../../partials/ProjectNameAutocomplete/selectors';
import { CREATE_PUBLISHER_PAGE } from '../constants';
import { publisherFormSubmitEnabled } from '../selectors';

export function developerProjectsSelector(state) {
  return Object.values(indexedDeveloperProjectsSelector(state));
}

export const developerCreationSubmitEnabledSelector = createSelector(
  [developerProjectsSelector, publisherFormSubmitEnabled],
  (projects, submitEnabled) => submitEnabled && projects.length > 0
);

export function projectSuggestionsNotAssignedSelector(state) {
  const projectSuggestions = projectSuggestionsSelector(state);
  const assignedProjectsIds = Object.keys(
    indexedDeveloperProjectsSelector(state)
  );

  return projectSuggestions
    .filter(
      (suggestion) =>
        !assignedProjectsIds.some((projectId) => projectId === suggestion.id)
    )
    .map((project) => ({ ...project, text: project.name }));
}

function indexedDeveloperProjectsSelector(state) {
  return state[CREATE_PUBLISHER_PAGE]?.selectedProjects ?? {};
}
