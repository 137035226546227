import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import { geoLevelsListSelector } from './selectors';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Wrapper = styled.div`
  ${radius.regular(RADIUS_SIZE.LARGE)}
  ${color.background(COLOR_PALETTE.PRIMARY_A05)}
  ${spacing.inset(SPACING_SIZE.M)}
`;

export function GeoLevels() {
  const geoLevelsList = useSelector(geoLevelsListSelector);

  if (!geoLevelsList) return null;

  return (
    <Wrapper>
      <Subtitle3 as="p" $color={COLOR_PALETTE.PRIMARY_A120}>
        <FormattedMessageWithValidation id="geo_helper_address_match" />
      </Subtitle3>
      <Body1
        as="p"
        $color={COLOR_PALETTE.NEUTRAL_A80}
        $weight={FONT_WEIGHT.LIGHT}
      >
        {geoLevelsList}
      </Body1>
    </Wrapper>
  );
}
