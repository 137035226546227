import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import Table from 'design-system/components/Table';
import { useColumn } from '../../../../partials/DataTable/hooks/useColumn';
import VerifiedPhoneBadge from '../../../../partials/VerifiedPhoneBadge';

const Wrapper = styled(Table.Cell)`
  ${Body1} {
    ${spacing.inline(SPACING_SIZE.S, false)}
  }
`;

export default function PhoneColumn({ itemId, ...props }) {
  const { value } = useColumn({ itemId, ...props });
  if (!value) {
    return null;
  }
  return (
    <Wrapper>
      <Body1>{value.phone}</Body1>
      {value.hasVerifiedPhone && <VerifiedPhoneBadge />}
    </Wrapper>
  );
}
