import { UNITS_STATUS_FILTER_OPTION_PUBLISHED } from './constants';
import { buildFullTextFilter, buildTermFilter } from '../../../Tables/filters';

export function buildTableUnitStatusFilter(value) {
  if (value === UNITS_STATUS_FILTER_OPTION_PUBLISHED) {
    return { published: buildTermFilter('published', true) };
  }

  if (value === 'unpublished') {
    return { published: buildTermFilter('published', false) };
  }

  return {};
}

export function buildTableUnitTypeFilter(value) {
  const newFilter = {};
  if (value !== undefined && value !== null) {
    newFilter.unitType = buildTermFilter('unitType', value);
  }

  return newFilter;
}

const queryParamToFieldName = (fieldName) => {
  if (fieldName === 'address') {
    return 'project.address';
  }

  if (fieldName === 'projectName') {
    return 'project.name';
  }

  return fieldName;
};

export function buildTablePropertiesSearchSuggestionTypeFilter(type, value) {
  const newFilter = {};
  if (!type || !value) {
    return newFilter;
  }

  if (type !== 'fullSearchValue') {
    const canonicalFieldName = queryParamToFieldName(type);
    newFilter.search = buildTermFilter(canonicalFieldName, value);
  } else {
    newFilter.search = buildFullTextFilter(
      ['referenceId', 'project.address', 'project.name'],
      value
    );
  }

  return newFilter;
}
