import styled from 'styled-components';
import BaseMenu from 'design-system/components/Menu/partials/BaseMenu';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import Typography from 'design-system/components/Typography/Typography';
import useInternationalization from '../../../../hooks/useInternationalization';

const Wrapper = styled(BaseMenu.BaseItem)`
  outline: none;
  align-items: flex-start;
  ${spacing.inset(SPACING_SIZE.NONE)}

  &:hover {
    ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
  }
  &:first-of-type:hover,
  &[aria-selected='true'] {
    ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
  }
`;

const Title = styled.div`
  display: flex;
  padding: 10px 10px 10px 0;
  overflow: hidden;

  ${Body1}:first-of-type {
    margin-bottom: 0;
    ${spacing.inline(3)}
    white-space: nowrap;
  }
  ${Body1}:last-of-type {
    ${Typography.mods.ellipse()}
  }
`;

const IconWrapper = styled.span`
  position: relative;
  ${spacing.inset(SPACING_SIZE.S)}

  ${Icon} {
    ${Icon.mods.size(ICON_SIZE.BASE)}
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
  }
`;

export default function QueryOption({ query, ...props }) {
  const intl = useInternationalization();

  return (
    <Wrapper key="query" {...props}>
      <IconWrapper>
        <Icon glyph="magnifierLens" />
      </IconWrapper>
      <Title>
        <Body1>{query}</Body1>{' '}
        <Body1 $color={COLOR_PALETTE.NEUTRAL_A40}>
          -{' '}
          {intl.formatMessageWithValidation({
            id: 'properties_list_full_text_search',
          })}
        </Body1>
      </Title>
    </Wrapper>
  );
}
