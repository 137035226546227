import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { isEmpty } from 'lodash';
import { PUBLISHER_PATCH_ATTRIBUTE } from '../events';
import { optimizeImages } from '../../../effects/optimizeImages';
import { addArray, addObject } from '../../../utils/formDataUtils';
import { environment } from '../../../coeffects/environment';
import { uuid } from '../../../coeffects/uuid';
import { analytics } from '../../../effects/analytics';

export const PUBLISHER_SAVE_DETAILS = 'PUBLISHER_SAVE_DETAILS';

export const PUBLISHER_SAVE_COMPANY_LOGO = 'PUBLISHER_SAVE_COMPANY_LOGO';
export const PUBLISHER_UPLOAD_OPTIMIZED_COMPANY_LOGO =
  'PUBLISHER_UPLOAD_OPTIMIZED_COMPANY_LOGO';
export const PUBLISHER_SAVE_COMPANY_LOGO_SUCCEEDED =
  'PUBLISHER_SAVE_COMPANY_LOGO_SUCCEEDED';
export const PUBLISHER_SAVE_COMPANY_LOGO_FAILED =
  'PUBLISHER_SAVE_COMPANY_LOGO_FAILED';
export const PUBLISHER_REMOVE_COMPANY_LOGO = 'PUBLISHER_REMOVE_COMPANY_LOGO';
export const PUBLISHER_REMOVE_COMPANY_LOGO_SUCCEEDED =
  'PUBLISHER_REMOVE_COMPANY_LOGO_SUCCEEDED';

export const PUBLISHER_SAVE_BACKGROUND = 'PUBLISHER_SAVE_BACKGROUND';
export const PUBLISHER_UPLOAD_OPTIMIZED_BACKGROUND =
  'PUBLISHER_UPLOAD_OPTIMIZED_BACKGROUND';
export const PUBLISHER_SAVE_BACKGROUND_SUCCEEDED =
  'PUBLISHER_SAVE_BACKGROUND_SUCCEEDED';
export const PUBLISHER_SAVE_BACKGROUND_FAILED =
  'PUBLISHER_SAVE_BACKGROUND_FAILED';
export const PUBLISHER_REMOVE_BACKGROUND = 'PUBLISHER_REMOVE_BACKGROUND';
export const PUBLISHER_REMOVE_BACKGROUND_SUCCEEDED =
  'PUBLISHER_REMOVE_BACKGROUND_SUCCEEDED';

function mapMultilangValues(values) {
  return Object.entries(values).map(([locale, text]) => ({
    locale,
    text: text ?? '',
  }));
}

registerEventHandler(PUBLISHER_SAVE_DETAILS, (_, payload) => {
  if (isEmpty(payload)) return {};

  const { companyLogo, companyBackground, ...details } = payload;

  if (!isEmpty(details)) {
    return effects.dispatch(publisherPatchAttributeEvent(payload));
  }
  if (companyLogo !== undefined) {
    return effects.dispatch(saveCompanyLogoEvent(payload));
  }
  if (companyBackground !== undefined) {
    return effects.dispatch(saveCompanyBackgroundEvent(payload));
  }
  return {};
});

function publisherPatchAttributeEvent({
  companyLogo,
  companyBackground,
  ...details
}) {
  const payload = {
    details: {
      names: mapMultilangValues(details.names),
      addresses: mapMultilangValues(details.addresses),
      descriptions: mapMultilangValues(details.descriptions),
      url: details.url,
    },
  };
  if (companyLogo !== undefined) {
    payload.successEvent = saveCompanyLogoEvent({
      companyLogo,
      companyBackground,
    });
  } else if (companyBackground !== undefined) {
    payload.successEvent = saveCompanyBackgroundEvent({ companyBackground });
  }
  return { id: PUBLISHER_PATCH_ATTRIBUTE, payload };
}

function saveCompanyLogoEvent({ companyLogo, companyBackground }) {
  const payload = { companyLogo };
  if (companyBackground !== undefined) {
    payload.successEvent = saveCompanyBackgroundEvent({ companyBackground });
  }
  return { id: PUBLISHER_SAVE_COMPANY_LOGO, payload };
}

function saveCompanyBackgroundEvent({ companyBackground }) {
  return { id: PUBLISHER_SAVE_BACKGROUND, payload: { companyBackground } };
}

registerEventHandler(
  PUBLISHER_SAVE_COMPANY_LOGO,
  (_, { companyLogo, successEvent }) => {
    if (!companyLogo)
      return effects.dispatch({
        id: PUBLISHER_REMOVE_COMPANY_LOGO,
        payload: { successEvent },
      });
    return optimizeImages({
      images: [companyLogo],
      successEvent: {
        id: PUBLISHER_UPLOAD_OPTIMIZED_COMPANY_LOGO,
        payload: { successEvent },
      },
      errorEvent: PUBLISHER_SAVE_COMPANY_LOGO_FAILED,
      options: {
        maxWidth: 200,
        maxHeight: 200,
        mimeType: 'image/jpeg',
      },
    });
  }
);

registerEventHandler(
  PUBLISHER_UPLOAD_OPTIMIZED_COMPANY_LOGO,
  ({ environment: { apiUrl } }, [optimizedImages, { successEvent }]) => {
    const [optimizedCompanyLogo] = optimizedImages;
    const formData = addArray(optimizedImages, 'images', addObject({}));

    return http.post({
      url: `${apiUrl}/publisher/logo`,
      body: formData,
      successEvent: {
        id: PUBLISHER_SAVE_COMPANY_LOGO_SUCCEEDED,
        payload: { companyLogo: optimizedCompanyLogo, successEvent },
      },
      errorEvent: PUBLISHER_SAVE_COMPANY_LOGO_FAILED,
    });
  },
  [environment()]
);
registerEventHandler(
  PUBLISHER_SAVE_COMPANY_LOGO_SUCCEEDED,
  ({ uuid: { newUuid } }, [, { companyLogo, successEvent }]) => ({
    ...state.set({
      'publisher.logo': companyLogo,
      'publisher.logoVersion': newUuid,
    }),
    ...(successEvent ? effects.dispatch(successEvent) : {}),
    ...analytics.trackClick('publisher-image-changed', 'company-profile-image'),
  }),
  [uuid()]
);
registerEventHandler(
  PUBLISHER_REMOVE_COMPANY_LOGO,
  ({ environment: { apiUrl } }, payload) =>
    http.delete({
      url: `${apiUrl}/publisher/logo`,
      successEvent: { id: PUBLISHER_REMOVE_COMPANY_LOGO_SUCCEEDED, payload },
      errorEvent: PUBLISHER_SAVE_COMPANY_LOGO_FAILED,
    }),
  [environment()]
);
registerEventHandler(
  PUBLISHER_REMOVE_COMPANY_LOGO_SUCCEEDED,
  (_, [__, { successEvent }]) => ({
    ...state.set({
      'publisher.logo': null,
      'publisher.logoVersion': null,
    }),
    ...(successEvent ? effects.dispatch(successEvent) : {}),
    ...analytics.trackClick('publisher-image-changed', 'company-profile-image'),
  })
);
registerEventHandler(PUBLISHER_SAVE_COMPANY_LOGO_FAILED, () => {});

registerEventHandler(PUBLISHER_SAVE_BACKGROUND, (_, { companyBackground }) => {
  if (!companyBackground) return effects.dispatch(PUBLISHER_REMOVE_BACKGROUND);
  return optimizeImages({
    images: [companyBackground],
    successEvent: PUBLISHER_UPLOAD_OPTIMIZED_BACKGROUND,
    errorEvent: PUBLISHER_SAVE_BACKGROUND_FAILED,
    options: {
      maxWidth: 1080,
      maxHeight: 300,
      mimeType: 'image/jpeg',
    },
  });
});
registerEventHandler(
  PUBLISHER_UPLOAD_OPTIMIZED_BACKGROUND,
  ({ environment: { apiUrl } }, [optimizedImages]) => {
    const [optimizedBackground] = optimizedImages;
    const formData = addArray(optimizedImages, 'images', addObject({}));

    return http.post({
      url: `${apiUrl}/publisher/background-image`,
      body: formData,
      successEvent: {
        id: PUBLISHER_SAVE_BACKGROUND_SUCCEEDED,
        payload: optimizedBackground,
      },
      errorEvent: PUBLISHER_SAVE_BACKGROUND_FAILED,
    });
  },
  [environment()]
);
registerEventHandler(
  PUBLISHER_SAVE_BACKGROUND_SUCCEEDED,
  ({ uuid: { newUuid } }, [, optimizedCompanyLogo]) =>
    state.set({
      'publisher.backgroundImage': optimizedCompanyLogo,
      'publisher.backgroundImageVersion': newUuid,
    }),
  [uuid()]
);
registerEventHandler(
  PUBLISHER_REMOVE_BACKGROUND,
  ({ environment: { apiUrl } }) =>
    http.delete({
      url: `${apiUrl}/publisher/background-image`,
      successEvent: PUBLISHER_REMOVE_BACKGROUND_SUCCEEDED,
      errorEvent: PUBLISHER_SAVE_BACKGROUND_FAILED,
    }),
  [environment()]
);
registerEventHandler(PUBLISHER_REMOVE_BACKGROUND_SUCCEEDED, () =>
  state.set({
    'publisher.backgroundImage': null,
    'publisher.backgroundImageVersion': null,
  })
);
registerEventHandler(PUBLISHER_SAVE_BACKGROUND_FAILED, () => {});
