import { useSelector } from 'reffects-store';
import { Children, cloneElement, memo, useMemo } from 'react';
import { mapValues } from 'lodash';
import Table from 'design-system/components/Table';
import { useDatatableContext } from '../hooks/useDatatableContext';
import { visibleItemsIdListSelector } from '../selectors/collection';
import { CheckboxColumn } from '../components';
import { emptyObject } from '../../../utils/emptyObject';
import { useDataTable } from '../hooks/useDataTable';
import EmptyRow from './EmptyRow';

function NotMemoizedRows({
  columns,
  events = emptyObject,
  clickableRows,
  noItemsMessage,
}) {
  const { collectionName } = useDatatableContext();
  const { hasNoItems } = useDataTable();

  const visibleItemIdList = useSelector((state) =>
    visibleItemsIdListSelector(state, { collectionName })
  );

  if (hasNoItems) {
    return <EmptyRow colSpan={columns.length}>{noItemsMessage}</EmptyRow>;
  }

  return visibleItemIdList.map((itemId) => (
    <Row
      key={itemId}
      itemId={itemId}
      columns={columns}
      events={events}
      clickable={clickableRows}
    />
  ));
}

function Row({ itemId, columns, events = emptyObject, clickable }) {
  const { updateEvents } = useDatatableContext();

  const showBulkCheckboxColumn = Boolean(updateEvents.bulk);

  const rowEventsWithItemId = useMemo(
    () => mapValues(events, (eventCallback) => (e) => eventCallback(itemId, e)),
    [itemId, events]
  );

  const childrenColumns = useMemo(
    () =>
      Children.map(columns, (column) => {
        const { id, source } = column.props;

        return cloneElement(column, {
          key: id,
          itemId,
          source: source ?? id,
        });
      }),
    [columns]
  );

  return (
    <Table.Row clickable={clickable} {...rowEventsWithItemId}>
      {showBulkCheckboxColumn && <CheckboxColumn itemId={itemId} />}
      {childrenColumns}
    </Table.Row>
  );
}

export const Rows = memo(NotMemoizedRows);
