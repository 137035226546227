import { PhoneNumberUtil } from 'google-libphonenumber';
import {
  ADDRESS_INPUT_NAME,
  COORDINATES_INPUT_NAME,
  GEO_LEVELS_INPUT_NAME,
  LOCATION_VISIBILITY_INPUT_NAME,
  PROJECT_INPUT_NAME,
  SUITABLE_FOR_CREDIT_NAME,
} from './constants';

export function prepareDataToSubmit({ formData, now, newUuid, published }) {
  const contactDetails = {
    contactPhone: composePhoneNumber(formData.contactPhone),
    contactWhatsApp: composePhoneNumber(formData.contactWhatsApp),
  };

  const defaultContact = {
    contactEmails: formData.contactEmails,
    contactLine: valueWhenNotNullOrUndefinedOrEmpty(formData.contactLine),
    contactViber: valueWhenNotNullOrUndefinedOrEmpty(formData.contactViber),
    contactFacebookMessenger: valueWhenNotNullOrUndefinedOrEmpty(
      formData.contactFacebookMessenger
    ),
    ...contactDetails,
  };
  const imagesToUpload = [];
  const propertyImages = [];
  (formData.propertyImages ?? []).forEach((image, idx) => {
    if (typeof image.file === 'string') {
      propertyImages.push({
        url: image.file,
        isProjectImage: image.isProjectImage,
      });
    } else {
      imagesToUpload.push({
        name: `propertyImagesToBeUploaded[${idx}]`,
        file: image.file,
      });
      propertyImages.push({
        ref: idx,
        isProjectImage: false,
      });
    }
  });
  const floorPlans = [];
  (formData.floorPlans ?? []).forEach((image, idx) => {
    if (typeof image.file === 'string') {
      floorPlans.push({ url: image.file });
    } else {
      imagesToUpload.push({
        name: `floorPlansToBeUploaded[${idx}]`,
        file: image.file,
      });
      floorPlans.push({ ref: idx });
    }
  });

  const ad = {
    address: formData[ADDRESS_INPUT_NAME],
    coordinates: formData[COORDINATES_INPUT_NAME],
    geoLevels: formData[GEO_LEVELS_INPUT_NAME],
    locationVisibility: formData[LOCATION_VISIBILITY_INPUT_NAME],
    floorPlans,
    propertyImages,
    mainImageIndex: (formData.propertyImages ?? []).findIndex(
      ({ isMain }) => isMain
    ),
    project: formData[PROJECT_INPUT_NAME],

    titleMultiLanguage: serializeMultiLanguageField(formData, 'title'),
    descriptionMultiLanguage: serializeMultiLanguageField(
      formData,
      'description'
    ),

    id: formData.id || newUuid,
    referenceId: valueWhenNotNullOrUndefinedOrEmpty(formData.referenceId),
    creationDate: formData.creationDate || now,
    published,

    spaces: formData.spaces ?? undefined,
    bathrooms: formData.bathrooms ?? undefined,
    bedrooms: formData.bedrooms ?? undefined,
    toilets: formData.toilets ?? undefined,
    bankProperty: formData.bankProperty ?? undefined,
    parkingSpaces: formData.parkingSpaces ?? undefined,
    orientation: formData.orientation ?? undefined,
    condition: formData.condition ?? undefined,
    efficiency:
      formData.energyCertificate === 'exempt' ||
      formData.energyCertificate === 'in process'
        ? formData.energyCertificate
        : formData.efficiency,
    efficiencyValue: formData.efficiencyValue ?? undefined,
    emissions: formData.emissions ?? undefined,
    emissionsValue: formData.emissionsValue ?? undefined,
    floor: formData.floor ?? undefined,
    furnished: formData.furnished ?? undefined,
    constructionYear: formData.constructionYear ?? undefined,
    stratum: formData.stratum ?? undefined,
    communityFeesAmount: formData.communityFees ?? undefined,
    communityFeesCurrency: formData.communityFeesCurrency,

    floorArea: formData.floorArea ?? undefined,
    floorAreaUnit: formData.floorAreaUnit ?? undefined,
    usableArea: formData.usableArea ?? undefined,
    usableAreaUnit: formData.usableAreaUnit ?? undefined,
    plotArea: serializePlotArea(formData),

    amenities: Array.isArray(formData.amenities)
      ? formData.amenities
      : undefined,
    rules: Array.isArray(formData.rules) ? formData.rules : undefined,
    nearbyLocations: Array.isArray(formData.nearby)
      ? formData.nearby
      : undefined,

    contactEmails: defaultContact.contactEmails,
    contactWhatsApp: defaultContact.contactWhatsApp,
    contactPhone: defaultContact.contactPhone,
    contactLine: defaultContact.contactLine,
    contactViber: defaultContact.contactViber,
    contactFacebookMessenger: defaultContact.contactFacebookMessenger,

    video: valueWhenNotNullOrUndefinedOrEmpty(formData.video),
    virtualTours: formData.virtualTours ? [formData.virtualTours] : [],

    propertyType: formData.propertyType,
    operations: serializeOperations(formData),
    ownership: formData.ownership ?? undefined,
    tenureInYears: formData.tenureInYears ?? undefined,
    suitableForCredit: valueWhenNotNullOrUndefinedOrEmpty(
      formData[SUITABLE_FOR_CREDIT_NAME]
    ),
  };

  return { ad, imagesToUpload, defaultContact };
}

function serializeOperations(formData) {
  const operations = [];
  if (
    formData.operationType === 'rent' ||
    formData.operationType === 'sell-and-rent'
  ) {
    operations.push({
      type: 'rent',
      price: {
        amount: formData.rentPrice ?? undefined,
        currency: formData.rentPriceCurrency,
      },
    });
  }
  if (
    formData.operationType === 'sell' ||
    formData.operationType === 'sell-and-rent'
  ) {
    operations.push({
      type: 'sell',
      price: {
        amount: formData.sellPrice ?? undefined,
        currency: formData.sellPriceCurrency,
      },
    });
  }
  return operations;
}

function serializePlotArea(formData) {
  const plotArea = [];
  if (formData.plotAreaUnit === 'sqm' && formData.plotAreaSqm) {
    plotArea.push({ value: formData.plotAreaSqm, unit: 'm2' });
  } else if (formData.plotAreaUnit === 'sf' && formData.plotAreaSf) {
    plotArea.push({ value: formData.plotAreaSf, unit: 'sf' });
  } else {
    if (formData.plotAreaRai) {
      plotArea.push({ value: formData.plotAreaRai, unit: 'rai' });
    }
    if (formData.plotAreaNgan) {
      plotArea.push({ value: formData.plotAreaNgan, unit: 'ngan' });
    }
    if (formData.plotAreaSqw) {
      plotArea.push({ value: formData.plotAreaSqw, unit: 'sqw' });
    }
  }
  return plotArea;
}

function serializeMultiLanguageField(formData, field) {
  return formData.propertyDescriptionLanguages
    .filter((locale) => formData[`${field}_${locale}`])
    .map((locale) => ({
      text: formData[`${field}_${locale}`],
      locale,
    }));
}

function valueWhenNotNullOrUndefinedOrEmpty(value) {
  if (value !== null && value !== undefined && value !== '') {
    return value;
  }
  return undefined;
}

function composePhoneNumber(phone) {
  const phoneUtil = PhoneNumberUtil.getInstance();
  let alreadyHasCountryCode = false;
  let parsedNumber;
  try {
    parsedNumber = phoneUtil.parse(String(phone.phoneNumber), 'ES');
    alreadyHasCountryCode = parsedNumber.getCountryCode() !== 0;
  } catch (err) {
    return undefined;
  }

  if (phone && phone.phoneNumber) {
    if (alreadyHasCountryCode) {
      return `${phone.dialCode}${parsedNumber.getNationalNumber().toString()}`;
    }

    return `${phone.dialCode}${phone.phoneNumber}`;
  }

  return undefined;
}
