import { uniqueId } from 'lodash';
import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { COLLECTION_NAME } from '../Campaigns/constants';
import { InMemoryTable } from '../../../partials/DataTable/adapters/inMemoryTableAdapter/InMemoryTable';
import { environment } from '../../../coeffects/environment';
import { table } from '../../../partials/DataTable/effects/table';

export function createUsersTableAdapter() {
  const inMemoryTable = InMemoryTable.create({
    filterStrategies: [
      {
        id: 'email',
        matchFn: (value, { email }) =>
          email.toLowerCase().includes(value.toLowerCase()),
      },
      { id: 'country', matchFn: (value, { country }) => value === country },
      {
        id: 'publisher',
        matchFn: (value, { publisherName, publisherId }) =>
          publisherId.toLowerCase().includes(value.toLowerCase()) ||
          publisherName.toLowerCase().includes(value.toLowerCase()),
      },
    ],
  });
  const eventSuffix = uniqueId(COLLECTION_NAME);

  const UPDATE_ITEMS_TABLE_DATA = `UPDATE_ITEMS_TABLE_DATA_${eventSuffix}`;
  const UPDATE_ITEMS_TABLE_DATA_SUCCESS = `UPDATE_ITEMS_TABLE_DATA_SUCCESS_${eventSuffix}`;

  registerEventHandler(
    UPDATE_ITEMS_TABLE_DATA,
    ({ environment: { apiUrl } }, context) =>
      http.get({
        url: `${apiUrl}/admin/users`,
        successEvent: [UPDATE_ITEMS_TABLE_DATA_SUCCESS, context],
      }),
    [environment()]
  );

  registerEventHandler(
    UPDATE_ITEMS_TABLE_DATA_SUCCESS,
    ({ select }, [response, context]) => {
      const campaigns = response.data;

      const { visibleRowsIdList, totalRows } = inMemoryTable
        .setCollection(campaigns)
        .setFilters(select.filters(context))
        .setSorting(select.sorting(context))
        .get(select.pagination(context));

      return table.update(context.collectionName, {
        collection: campaigns,
        totalItems: totalRows,
        visibleItemsIdList: visibleRowsIdList,
      });
    },
    [table.select()]
  );

  function registerEvents() {
    return {
      collection: UPDATE_ITEMS_TABLE_DATA,
      events: {
        UPDATE_ITEMS_TABLE_DATA,
        UPDATE_ITEMS_TABLE_DATA_SUCCESS,
      },
    };
  }

  return {
    registerEvents,
  };
}
