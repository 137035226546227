import Table from 'design-system/components/Table';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { useColumn } from '../../../../../../partials/DataTable/hooks/useColumn';

export function PaymentMethodColumn(props) {
  const { value: paymentMethod } = useColumn(props);

  return (
    <Table.Cell>
      <Body1>{paymentMethod}</Body1>
    </Table.Cell>
  );
}
