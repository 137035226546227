import { DropdownFilter } from '../../../../../../partials/DataTable';
import { NO_FILTER_OPTION } from '../../../../partials/Filters/constants';
import {
  daysAgo,
  firstMomentOf,
  monthRange,
  toUTCDate,
} from '../../../../../../utils/dates';
import dateFilterRenderLabel from '../../../../../../partials/DataTable/components/filters/dateFilterRenderLabel';

const [currentMonthStart, currentMonthEnd] = monthRange();

const now = new Date();
const OPTIONS = [
  {
    id: NO_FILTER_OPTION,
    value: null,
    label: 'All',
  },
  {
    id: 'last-7-days',
    value: {
      startDate: toUTCDate(daysAgo(7, now)),
      endDate: toUTCDate(firstMomentOf(now)),
    },
    label: 'Last 7 days',
  },
  {
    id: 'last-15-days',
    value: {
      startDate: toUTCDate(daysAgo(15, now)),
      endDate: toUTCDate(firstMomentOf(now)),
    },
    label: 'Last 15 days',
  },
  {
    id: 'last-30-days',
    value: {
      startDate: toUTCDate(daysAgo(30, now)),
      endDate: toUTCDate(firstMomentOf(now)),
    },
    label: 'Last 30 days',
  },
  {
    id: 'more-than-30-days',
    value: {
      startDate: null,
      endDate: toUTCDate(firstMomentOf(now)),
    },
    label: '+30 days',
  },
  {
    id: 'this-month',
    value: {
      startDate: toUTCDate(currentMonthStart),
      endDate: toUTCDate(currentMonthEnd),
    },
    label: 'This month',
  },
];

export default function FreeFilter() {
  return (
    <DropdownFilter
      label="Free"
      source="free"
      options={OPTIONS}
      renderLabel={dateFilterRenderLabel}
    />
  );
}
