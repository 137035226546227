import { dispatch } from 'reffects';
import { useMount } from 'react-use';
import Layout from 'design-system/components/Layout';
import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Navigation } from '../../partials';
import { OVERVIEW_PAGE_MOUNTED } from './events';
import Publisher from './partials/Publisher/Publisher';
import Divider from './partials/Divider';
import LeadsSummary from './partials/LeadsSummary';
import { ProjectsTableProvider } from './partials/ProjectsOverview/LastProjectsTable/ProjectsTableProvider';
import { DeveloperStats } from './partials/DeveloperStats';
import { DeveloperPublishCards } from './partials/DeveloperPublishCards';
import {
  BANNERS,
  showNotUsingYourWholePlanBannerOrLeadQualityAwarenessSelector,
} from '../../selectors/banners';
import LeadQualityAwareness from '../../partials/LeadQualityAwareness/LeadQualityAwareness';

const LayoutPadded = styled(Layout.Padded)`
  display: flex;
  gap: ${spacing.value(SPACING_SIZE.XL)};
  flex-direction: column;
`;

function DeveloperOverview() {
  useMount(() => dispatch(OVERVIEW_PAGE_MOUNTED));

  const banner = useSelector((state) =>
    showNotUsingYourWholePlanBannerOrLeadQualityAwarenessSelector(
      state,
      new Date()
    )
  );
  return (
    <Layout>
      <Layout.Header>
        <Navigation />
      </Layout.Header>
      <Layout.Content>
        <LayoutPadded>
          <Publisher />
          {banner !== BANNERS.notUsingYourWholePlan && (
            <LeadQualityAwareness section="overview" showLink />
          )}
          <LeadsSummary />
        </LayoutPadded>
        <Divider />
        <Layout.Padded>
          <ProjectsTableProvider>
            <DeveloperStats />
            <DeveloperPublishCards />
          </ProjectsTableProvider>
        </Layout.Padded>
      </Layout.Content>
    </Layout>
  );
}

export default DeveloperOverview;
