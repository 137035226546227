import Dialog from 'design-system/components/Dialog/Dialog';
import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { useMount } from 'react-use';
import { dispatch } from 'reffects';
import { BrandLogoForAnonymousUsers } from '../../partials/BrandLogo';
import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { LEAD_AWARENESS_THANKS_LOADED } from './events';

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${spacing.value(SPACING_SIZE.XL)} 0px 0px;
`;

const CenteredHeader = styled(Dialog.Header)`
  display: flex;
  justify-content: center;
`;

const CenterText = styled.div`
  display: flex;
  justify-content: center;
  font-size: 18px;
`;

export default function LeadAwarenessThanks() {
  useMount(() => {
    dispatch(LEAD_AWARENESS_THANKS_LOADED);
  });

  return (
    <Dialog open>
      <LogoWrapper>
        <BrandLogoForAnonymousUsers />
      </LogoWrapper>
      <CenteredHeader>
        <FormattedMessageWithValidation id="lead_awareness_modal_thanks" />
      </CenteredHeader>
      <Dialog.Content>
        <CenterText>
          <FormattedMessageWithValidation id="lead_awareness_modal_thanks_message" />
        </CenterText>
      </Dialog.Content>
      <Dialog.Footer />
    </Dialog>
  );
}
