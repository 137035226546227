import { useInputFocus } from './useInputFocus';

export function useInputBase({
  label,
  icon,
  tip,
  disabled,
  error,
  required,
  className,
  style,
  onFocus,
  onBlur,
  onIconClick,
  ...rest
}) {
  const [focused, inputHandlers] = useInputFocus({ onFocus, onBlur });

  return {
    inputBaseProps: {
      label,
      tip,
      focused,
      disabled,
      error,
      required,
      className,
      style,
    },
    boxProps: {
      icon,
      onIconClick,
    },
    inputProps: {
      disabled,
      required,
      ...rest,
      ...inputHandlers,
    },
  };
}
