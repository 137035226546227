import { uniqueId } from 'lodash';
import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { COLLECTION_NAME } from './constants';
import { InMemoryTable } from '../../../partials/DataTable/adapters/inMemoryTableAdapter/InMemoryTable';
import { environment } from '../../../coeffects/environment';
import { table } from '../../../partials/DataTable/effects/table';
import { developerFilter } from './filterStrategies';
import { countryFilter } from '../partials/Filters/strategies';

export function createDevelopersTableAdapter() {
  const inMemoryTable = InMemoryTable.create({
    filterStrategies: [
      {
        id: 'developer',
        matchFn: developerFilter,
      },
      {
        id: 'country',
        matchFn: countryFilter,
      },
    ],
  });
  const eventSuffix = uniqueId(COLLECTION_NAME);

  const UPDATE_ITEMS_TABLE_DATA = `UPDATE_ITEMS_TABLE_DATA_${eventSuffix}`;
  const UPDATE_ITEMS_TABLE_DATA_SUCCESS = `UPDATE_ITEMS_TABLE_DATA_SUCCESS_${eventSuffix}`;

  registerEventHandler(
    UPDATE_ITEMS_TABLE_DATA,
    ({ environment: { apiUrl } }, context) =>
      http.get({
        url: `${apiUrl}/admin/developers`,
        successEvent: [UPDATE_ITEMS_TABLE_DATA_SUCCESS, context],
      }),
    [environment()]
  );

  registerEventHandler(
    UPDATE_ITEMS_TABLE_DATA_SUCCESS,
    ({ select }, [response, context]) => {
      const developers = response.data;

      const { visibleRowsIdList, totalRows } = inMemoryTable
        .setCollection(developers)
        .setFilters(select.filters(context))
        .setSorting(select.sorting(context))
        .get(select.pagination(context));

      return table.update(context.collectionName, {
        collection: developers,
        totalItems: totalRows,
        visibleItemsIdList: visibleRowsIdList,
      });
    },
    [table.select()]
  );

  function registerEvents() {
    return {
      collection: UPDATE_ITEMS_TABLE_DATA,
      events: {
        UPDATE_ITEMS_TABLE_DATA,
        UPDATE_ITEMS_TABLE_DATA_SUCCESS,
      },
    };
  }

  return {
    registerEvents,
  };
}
