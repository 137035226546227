import { withoutEventPropagation } from 'design-system/utils/withoutEventPropagation';
import { useCallback } from 'react';
import { dispatch } from 'reffects';
import Menu from 'design-system/components/Menu/Menu';
import { PUBLISHER_EDIT_LISTING_RULES_MODAL_OPENED } from '../Modals/EditListingRulesModal/events';

export function EditListingRulesAction({ publisher }) {
  const showModal = useCallback(() => {
    dispatch({
      id: PUBLISHER_EDIT_LISTING_RULES_MODAL_OPENED,
      payload: {
        publisherId: publisher.id,
        countryCode: publisher.country,
      },
    });
  }, [publisher]);

  return (
    <Menu.Item
      label="Edit Listing Rules"
      onClick={withoutEventPropagation(showModal)}
    />
  );
}
