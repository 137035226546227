import * as yup from 'yup';

import ViberField from '../../../../partials/fields/ViberField';
import GenericDetailForm from './GenericDetailForm';
import useInternationalization from '../../../../hooks/useInternationalization';

function ViberDetailForm({ detail, onSubmit, onCancel }) {
  const intl = useInternationalization();

  const handleFormSubmit = ({ contactViber }) => onSubmit(contactViber);

  return (
    <GenericDetailForm
      defaultValues={{
        contactViber: detail.value,
      }}
      validationSchema={{
        contactViber: yup.string().required(
          intl.formatMessageWithValidation({
            id: 'form_validations_required_field',
          })
        ),
      }}
      onSubmit={handleFormSubmit}
      onCancel={onCancel}
    >
      <ViberField name="contactViber" />
    </GenericDetailForm>
  );
}

export default ViberDetailForm;
