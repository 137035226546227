import { GenericModals } from './generic';
import ChurnModal from './churn/ChurnModal';

export default function Modals() {
  return (
    <>
      <GenericModals />
      <ChurnModal />
    </>
  );
}
