import { css } from 'styled-components';

import { radius as radiusTokens } from '../tokens/radius';

export const RADIUS_SIZE = {
  NONE: 0,
  SMALL: radiusTokens.small,
  BASE: radiusTokens.base,
  LARGE: radiusTokens.large,
};

const regular = (size) => css`
  border-radius: ${size}px;
`;

const pill = () => css`
  border-radius: 99999px;
`;

export const radius = {
  regular,
  pill,
};
