import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import useInternationalization from '../../../../../hooks/useInternationalization';

function TimelineFormProvider({
  children,
  defaultValues,
  onSubmit,
  messageMaxLength,
}) {
  const intl = useInternationalization();
  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(createValidationSchema(intl, messageMaxLength)),
  });
  const { handleSubmit, reset } = methods;
  const submit = (data) => {
    onSubmit(data);
    reset();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submit)}>{children}</form>
    </FormProvider>
  );
}

function createValidationSchema(intl, messageMaxLength) {
  return yup
    .object({
      message: yup.string().max(messageMaxLength, ({ value, max }) =>
        intl.formatMessageWithValidation({
          id: 'enquiry_detail_note_error_length',
          values: {
            max,
            current: value.length,
          },
        })
      ),
    })
    .required();
}

export default TimelineFormProvider;
