import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import { useSelector } from 'reffects-store';
import { ROWS_PER_PAGE } from 'design-system/components/Table';
import Layout from 'design-system/components/Layout';
import { Navigation } from '../../partials';
import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { DataTableProvider } from '../../partials/DataTable';
import Table from './Table';
import { createProjectsTableAdapter } from './projectsTableAdapter';
import { COLLECTION_NAME } from './constants';

import { Kpis } from './Kpis/Kpis';
import { DateRangeFilter } from './DateRangeFilter';
import {
  publisherSelector,
  startDateSelector,
} from '../../selectors/publisher';

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  ${spacing.stack(SPACING_SIZE.M)}
`;

function Projects() {
  const publisher = useSelector(publisherSelector);
  const startDate = useSelector(startDateSelector);

  if (!publisher || publisher.loading) return null;

  const context = {
    collectionName: COLLECTION_NAME,
    defaultSorting: {
      field: 'date',
      direction: 'desc',
    },
    defaultFilters: {
      startDate: format(startDate ?? thirtyDaysAgo()),
      endDate: format(new Date()),
    },
    initialItemsPerPage: ROWS_PER_PAGE.TEN,
    adapter: createProjectsTableAdapter(),
  };

  return (
    <Layout>
      <Layout.Header>
        <Navigation />
      </Layout.Header>
      <Layout.Content>
        <Layout.Padded>
          <DataTableProvider {...context}>
            <Header>
              <Heading1>
                <FormattedMessageWithValidation id="projects_page_header" />
              </Heading1>
              <DateRangeFilter />
            </Header>
            <Kpis />
            <Table />
          </DataTableProvider>
        </Layout.Padded>
      </Layout.Content>
    </Layout>
  );
}

function thirtyDaysAgo() {
  const date = new Date();
  date.setDate(date.getDate() - 30);

  return date;
}

function format(date) {
  return date.toISOString().split('T')[0];
}

export default Projects;
