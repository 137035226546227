import styled from 'styled-components';
import InputText from 'design-system/components/InputText';
import { convertToISOStringDate, toUTCDate } from '../../../utils/dates';

export const CampaignDateInput = styled(
  ({
    name,
    label,
    minDate = lastWeek(),
    value,
    required,
    onChange,
    ...props
  }) => (
    <InputText
      label={label}
      type="date"
      value={value}
      name={name}
      minDate={minDate}
      shrink
      required={required}
      onChange={(e) => onChange(e.target.value)}
      {...props}
    />
  )
)``;

function lastWeek() {
  const today = new Date();
  const oneWeekInDays = 7;
  return convertToISOStringDate(
    toUTCDate(
      new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - oneWeekInDays
      )
    )
  );
}
