import { TYPE_OF_PERSON } from '../../../../../../../constants/billingEntity';

export const BILLING_MODAL_STATE = 'billingEntityModal';
export const BILLING_ENTITY_BEING_EDITED_STATE = 'billingEntity';
export const BILLING_ENTITY_BEING_DISPLAYED_STATE = 'billingEntityViewMode';
export const INITIAL_BILLING_ENTITY_DATA = {
  id: null,
  address: '',
  city: '',
  email: '',
  name: '',
  province: '',
  typeOfPerson: TYPE_OF_PERSON.NATURAL,
  taxId: '',
  postalCode: '',
  phone: '',
  reteIca: false,
  reteIva: false,
  retefuente: false,
  publishersAssigned: [],
};
