import styled from 'styled-components';
import Thumbnail from 'design-system/components/Thumbnail';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import Subtitle from './Subtitle';
import Section from './Section';
import Description from './Description';

const SectionWrapper = styled.div`
  display: flex;
  gap: ${SPACING_SIZE.S}px;

  ${Thumbnail} {
    max-width: 57px;
  }
`;

const Title = styled.div`
  ${spacing.stack(SPACING_SIZE.S)}
`;
const Address = styled(Body1)`
  ${color.text(COLOR_PALETTE.NEUTRAL_A40)}
`;

function ProjectInfo({ project }) {
  const { name, image, address, description } = project;

  return (
    <>
      <Section>
        <Subtitle>
          <FormattedMessageWithValidation id="enqdetail_property_detail_project_section_subtitle" />
        </Subtitle>
      </Section>
      <Section highlight>
        <SectionWrapper>
          <Thumbnail source={image} />
          <div>
            <Title>{name}</Title>
            <Address>{address}</Address>
          </div>
        </SectionWrapper>
      </Section>
      <Section>
        <Description description={description} />
      </Section>
    </>
  );
}

export default ProjectInfo;
