import { dispatch, dispatchMany } from 'reffects';
import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { useSelector } from 'reffects-store';
import { useMount } from 'react-use';
import Button from 'design-system/components/Button';
import {
  CREATE_PUBLISHER_ATTEMPTED,
  CREATE_PUBLISHER_FORM_LOADED,
  CREATE_PUBLISHER_FORM_VALUE_CHANGED,
} from './events';
import { PUBLISHERS_REQUESTED } from '../events';
import Layout from '../partials/Layout/Layout';
import Section from './Section';
import PublisherDetails from './PublisherDetails';
import PlanAndBillingDetails from './BillingDetails/BillingDetails';
import {
  currentStepSelector,
  isCreatingDeveloperSelector,
  isNewClientSelector,
  publisherFormSubmitEnabled,
} from './selectors';
import { CREATION_STEP } from './constants';
import AssignProjects from './AssignProjects/AssignProjects';
import { ImageUploader } from './ImageUploader/ImageUploader';
import PublisherMapping from './PublisherMapping';
import Deals from './Deals/Deals';

const Form = styled.form`
  margin: 1rem auto;
  width: 75vw;

  ${Section} {
    ${spacing.stack(SPACING_SIZE.L, false)};
  }
`;

function CreatePublisherForm() {
  const isDeveloper = useSelector(isCreatingDeveloperSelector);
  const isNewClient = useSelector(isNewClientSelector);
  const submitEnabled = useSelector(publisherFormSubmitEnabled);
  return (
    <Form onSubmit={onSubmit}>
      <h2>{`Create Publisher${isDeveloper ? ' 1/2' : ''}`}</h2>
      <Deals />
      <PublisherDetails
        onInputChange={onInputChange}
        onCheckboxChange={onCheckboxChange}
      />
      {!isNewClient && <PublisherMapping />}
      <PlanAndBillingDetails
        onInputChange={onInputChange}
        onCheckboxChange={onCheckboxChange}
      />
      <ImageUploader />
      <Button type="submit" disabled={!submitEnabled}>
        {isDeveloper ? 'Continue' : 'Save'}
      </Button>
    </Form>
  );
}

function CreatePublisher() {
  useMount(() =>
    dispatchMany([CREATE_PUBLISHER_FORM_LOADED, PUBLISHERS_REQUESTED])
  );

  const step = useSelector(currentStepSelector);

  return (
    <Layout>
      {step === CREATION_STEP.FORM ? (
        <CreatePublisherForm />
      ) : (
        <AssignProjects />
      )}
    </Layout>
  );
}

export default CreatePublisher;

function onSubmit(event) {
  event.preventDefault();
  dispatch(CREATE_PUBLISHER_ATTEMPTED);
}

function onInputChange(event) {
  const { name, value } = event.target;
  dispatch({
    id: CREATE_PUBLISHER_FORM_VALUE_CHANGED,
    payload: { name, value },
  });
}

function onCheckboxChange(event) {
  const { name, checked: value } = event.target;
  dispatch({
    id: CREATE_PUBLISHER_FORM_VALUE_CHANGED,
    payload: { name, value },
  });
}
