import { Body1 } from 'design-system/components/Typography/presets/Body1';
import Price from './Price/Price';

function PriceRange({ maxPrice, minPrice, emptyComponent }) {
  if (minPrice == null || maxPrice == null) return emptyComponent ?? null;

  return (
    <Body1>
      <Price amount={minPrice.amount} currency={minPrice.currency} />
      {' - '}
      <Price amount={maxPrice.amount} currency={maxPrice.currency} />
    </Body1>
  );
}

export default PriceRange;
