import { useState } from 'react';
import { noop } from '../utils';

export function useInputFocus({ onFocus = noop, onBlur = noop }) {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = (e) => {
    setIsFocused(true);
    onFocus(e);
  };

  const handleBlur = (e) => {
    setIsFocused(false);
    onBlur(e);
  };

  const handlers = {
    onFocus: handleFocus,
    onBlur: handleBlur,
  };

  return [isFocused, handlers];
}
