import { Children, cloneElement, useState } from 'react';
import styled from 'styled-components';

import ScrollableArea from 'design-system/components/ScrollableArea/ScrollableArea';
import { noop } from '../../utils';

import BaseMenu from './partials/BaseMenu';
import Item from './Item';
import Divider from './partials/Divider';
import Highlight from './partials/Highlight';

const Wrapper = styled(BaseMenu)`
  ${BaseMenu} {
    ${BaseMenu.mods.isSubmenu()}
  }
`;

function Menu({
  scrollable,
  onConfirm = noop,
  onCancel = noop,
  children,
  ...rest
}) {
  const [activeItemId, setActiveItemId] = useState(null);

  const resetState = () => {
    setActiveItemId(null);
  };

  const isActiveItem = (item) => item.props.id === activeItemId;

  const shouldRenderItem = (item) => {
    const isSomeItemActive = activeItemId !== null;
    const isActive = isActiveItem(item);

    return !isSomeItemActive || isActive;
  };

  const handleItemActivation = (activatedItem) => {
    setActiveItemId(activatedItem.id);
  };

  const handleItemConfirmation = ({ id, value }) => {
    onConfirm({ id, value });
    resetState();
  };

  const handleItemCancellation = () => {
    onCancel();
    resetState();
  };

  return (
    <Wrapper {...rest}>
      <WithScrollableArea scrollable={scrollable}>
        {Children.map(children, (item) => {
          if (item == null) {
            return null;
          }

          const isActive = isActiveItem(item);

          if (!shouldRenderItem(item)) {
            return null;
          }

          if (item.type === Divider) {
            return item;
          }

          return cloneElement(item, {
            isActive,
            onActivate: handleItemActivation,
            onConfirm: handleItemConfirmation,
            onCancel: handleItemCancellation,
          });
        })}
      </WithScrollableArea>
    </Wrapper>
  );
}

function WithScrollableArea({ scrollable, children }) {
  if (!scrollable) {
    return children;
  }

  return <ScrollableArea>{children}</ScrollableArea>;
}

Menu.Item = Item;
Menu.Divider = Divider;
Menu.Highlight = Highlight;

export default styled(Menu)``;
