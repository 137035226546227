import {
  isPublisherFromSEASelector,
  publisherCountrySelector,
} from './publisher';
import {
  OPERATION_TYPE_RENT,
  OPERATION_TYPE_SELL,
  OPERATION_TYPE_SELL_AND_RENT,
  propertyTypesTranslationTags,
} from '../constants/ad';
import { countryConfigSelector } from './config';
import { isUnitedArabEmirates } from '../utils/countries';

export function callingCodeSelector(state) {
  return countryConfigSelector(state).phoneNumber?.callingCode ?? '';
}

export function numberWithoutCallingCodeSelector(state, number) {
  const callingCode = callingCodeSelector(state);
  return (
    number?.slice(number.startsWith(callingCode) ? callingCode.length : 0) ??
    null
  );
}

export function currencySelector(state) {
  return countryConfigSelector(state).currencies?.propertyPrice ?? [];
}

export function operationTypesByCountrySelector(state) {
  const operationTypes = [OPERATION_TYPE_SELL, OPERATION_TYPE_RENT];
  if (
    isPublisherFromSEASelector(state) ||
    isUnitedArabEmirates(publisherCountrySelector(state))
  ) {
    operationTypes.push(OPERATION_TYPE_SELL_AND_RENT);
  }
  return operationTypes;
}

export function propertyTypesByCountrySelector(state) {
  const countryConfig = countryConfigSelector(state);
  const { adFormRules = {} } = countryConfig;
  return Object.keys(adFormRules);
}

export function propertyTypesWithTranslationTagsSelector(state) {
  return propertyTypesByCountrySelector(state).reduce(
    (options, propertyType) => ({
      ...options,
      [propertyType]: propertyTypesTranslationTags.get(propertyType),
    }),
    {}
  );
}
