import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import Dialog from 'design-system/components/Dialog/Dialog';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { SecondaryButton } from 'design-system/components/Button/presets';
import FormattedMessageWithValidation from '../../../../FormattedMessageWithValidation/FormattedMessageWithValidation';
import { SITES_PUBLISHED_ON_MODAL } from '../../events';
import { useDialog } from '../../../../../hooks/useDialog';
import SitePublicationStepper from '../../partials/SitePublicationStepper/SitePublicationStepper';
import { sitesWherePublishingWithBannedInfoSelector } from '../../../../../selectors/config';
import { shouldShowEmphasisSelector } from './selectors';

const Content = styled.div`
  display: flex;
  justify-content: center;
  ${spacing.insetSandwich(SPACING_SIZE.M)}
`;

const EmphasisWithLogo = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing.value(SPACING_SIZE.S)};

  ${spacing.insetSandwich(SPACING_SIZE.S)}
  ${Icon} {
    ${Icon.mods.size(ICON_SIZE.BASE)}
    ${color.text(COLOR_PALETTE.PUMPKIN_BASE)}
  }
`;

export function SitesPublishedOnModal() {
  const {
    open,
    parameters: { isBoosted } = {},
    closeDialog,
  } = useDialog(SITES_PUBLISHED_ON_MODAL);
  const sites = useSelector((state) =>
    sitesWherePublishingWithBannedInfoSelector(state, isBoosted)
  );
  const shouldShowEmphasis = useSelector((state) =>
    shouldShowEmphasisSelector(state, isBoosted)
  );

  return (
    <Dialog open={open}>
      <Dialog.Header onClose={closeDialog}>
        <FormattedMessageWithValidation id="sites_published_on_modal_title" />
      </Dialog.Header>
      <Dialog.Content>
        <Content>{open && <SitePublicationStepper sites={sites} />}</Content>
      </Dialog.Content>
      {shouldShowEmphasis && (
        <Dialog.Emphasis>
          <EmphasisWithLogo>
            <Icon glyph="error" />
            <FormattedMessageWithValidation id="site_publication_steper_message_not_published" />
          </EmphasisWithLogo>
        </Dialog.Emphasis>
      )}
      <Dialog.Footer>
        <SecondaryButton onClick={closeDialog}>
          <FormattedMessageWithValidation id="modal_button_close" />
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}
