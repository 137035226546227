import * as React from "react";
const SvgMigration = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={123}
    height={60}
    fill="none"
    {...props}
  >
    <path
      stroke="#0ACC86"
      strokeLinecap="round"
      strokeWidth={0.672}
      d="M51.187 13.781h11.545M45.431 19.517h5.789M55.263 19.517h29.906M80.968 25.252h-5.79M71.136 25.252H41.229M80.968 42.458h-5.79M71.136 42.458H41.229M75.337 48.193H45.43M77.564 53.929H47.657M82.335 31.66H38.433M81.304 37.395H37.4M111.583 11.466l1.426 1.426M111.298 12.892l1.426-1.426M23.18 51.466l1.426 1.426M22.894 52.892l1.426-1.426M95.26 2.353v3.361M93.445 3.899h3.361M31.731 2.689V6.05M29.916 4.235h3.361"
    />
    <circle
      cx={11.428}
      cy={55.126}
      r={1.008}
      stroke="#0ACC86"
      strokeLinecap="round"
      strokeWidth={0.672}
    />
    <circle
      cx={16.47}
      cy={12.101}
      r={1.008}
      stroke="#0ACC86"
      strokeLinecap="round"
      strokeWidth={0.672}
    />
    <circle
      cx={24.874}
      cy={19.496}
      r={1.008}
      stroke="#0ACC86"
      strokeLinecap="round"
      strokeWidth={0.672}
    />
    <circle
      cx={104.202}
      cy={13.109}
      r={1.008}
      stroke="#0ACC86"
      strokeLinecap="round"
      strokeWidth={0.672}
    />
    <path
      fill="#fff"
      d="M99.85 30.242a14.8 14.8 0 0 1 7.816-2.214c8.226 0 14.894 6.668 14.894 14.894 0 7.992-6.295 14.513-14.197 14.877v.016h-42.59v-.001l-.201.001c-7.254 0-13.135-5.88-13.135-13.135 0-5.9 3.89-10.891 9.245-12.55C64.08 23.675 71.86 17.48 81.086 17.48c8.524 0 15.813 5.288 18.765 12.763"
    />
    <path
      fill="#CEF5E7"
      d="M120.619 50.278c-2.454 4.312-6.999 7.28-12.256 7.521v.016h-42.59v-.001l-.2.001a13.11 13.11 0 0 1-10.353-5.049 12.6 12.6 0 0 1-1.062-5.08 12.61 12.61 0 0 1 8.847-12.047c2.297-8.117 9.742-14.064 18.571-14.064 8.158 0 15.134 5.076 17.96 12.25a14.16 14.16 0 0 1 7.479-2.123c7.873 0 14.254 6.4 14.254 14.296 0 1.49-.227 2.929-.65 4.28"
    />
    <path
      stroke="#0ACC86"
      strokeWidth={0.672}
      d="m99.538 30.365.148.373.341-.21a14.5 14.5 0 0 1 7.639-2.163c8.04 0 14.558 6.517 14.558 14.557 0 7.811-6.153 14.186-13.876 14.541l-.321.015v.001H66.109v-.007l-.341.005q-.097.003-.196.002c-7.069 0-12.799-5.73-12.799-12.799 0-5.748 3.79-10.612 9.008-12.228l.174-.054.05-.176c2.36-8.316 10.01-14.407 19.08-14.407 8.382 0 15.55 5.2 18.453 12.55Z"
    />
    <rect
      width={30.147}
      height={22.9}
      x={1.449}
      y={26.995}
      fill="#E7FAF3"
      rx={1.959}
    />
    <rect
      width={29.475}
      height={22.228}
      x={0.336}
      y={25.882}
      fill="#fff"
      stroke="#0ACC86"
      strokeWidth={0.672}
      rx={0.889}
    />
    <path
      fill="#0ACC86"
      fillRule="evenodd"
      d="M2.353 27.43c0-.24.195-.434.435-.434h.29a.435.435 0 0 1 0 .87h-.29a.435.435 0 0 1-.435-.436M4.092 27.43c0-.24.195-.434.435-.434h.29a.435.435 0 0 1 0 .87h-.29a.435.435 0 0 1-.435-.436M5.832 27.43c0-.24.194-.434.434-.434h.29a.435.435 0 0 1 0 .87h-.29a.435.435 0 0 1-.434-.436"
      clipRule="evenodd"
    />
    <path
      fill="#E7FAF3"
      d="M20.84 41.009v-4.874l3.026 4.874zM23.866 41.008v-4.201L27.226 40l-.167.84-.504.168z"
    />
    <path
      stroke="#0ACC86"
      strokeWidth={0.672}
      d="m16.135 40.672 4.407-4.407a.336.336 0 0 1 .522.06l1.181 1.889c.13.207.43.211.565.008l.828-1.242a.336.336 0 0 1 .517-.051L27.228 40"
    />
    <rect
      width={11.092}
      height={9.076}
      x={16.134}
      y={31.933}
      stroke="#0ACC86"
      strokeWidth={0.672}
      rx={0.889}
    />
    <path
      fill="#0ACC86"
      d="M.336 28.572h29.58v.672H.336zM3.361 31.933h7.395v.672H3.361zM3.361 33.277h10.084v.672H3.361zM3.361 34.622h10.084v.672H3.361zM3.361 35.966h10.084v.672H3.361zM3.361 37.311h10.084v.672H3.361zM3.361 38.656h10.084v.672H3.361zM3.361 40h6.05v.672h-6.05z"
    />
    <circle
      cx={24.538}
      cy={34.286}
      r={1.008}
      fill="#fff"
      stroke="#0ACC86"
      strokeWidth={0.672}
    />
    <path
      fill="#0ACC86"
      fillRule="evenodd"
      d="M63.193 1.345q-.818 0-1.627.036a.672.672 0 0 1-.061-1.343 37 37 0 0 1 3.516.007.672.672 0 1 1-.066 1.343 36 36 0 0 0-1.762-.043m-4.29-.426a.67.67 0 0 1-.575.758q-1.623.225-3.197.592A.672.672 0 1 1 54.825.96a37 37 0 0 1 3.32-.615.67.67 0 0 1 .757.574m8.991.051a.67.67 0 0 1 .764-.565q1.824.273 3.584.721a.672.672 0 0 1-.331 1.303 35 35 0 0 0-3.451-.694.67.67 0 0 1-.566-.765M52.43 2.304a.67.67 0 0 1-.425.85 35 35 0 0 0-3.032 1.173.672.672 0 0 1-.543-1.23 36 36 0 0 1 3.149-1.217.67.67 0 0 1 .85.424m22.445.3a.67.67 0 0 1 .861-.4q1.728.629 3.368 1.422a.672.672 0 0 1-.584 1.21 35 35 0 0 0-3.244-1.37.67.67 0 0 1-.401-.861M46.32 4.86a.67.67 0 0 1-.26.914 35 35 0 0 0-2.766 1.71.672.672 0 0 1-.759-1.11 37 37 0 0 1 2.87-1.775.67.67 0 0 1 .915.261m35.066.74a.67.67 0 0 1 .925-.222 37 37 0 0 1 3.017 2.062.672.672 0 0 1-.813 1.071 35 35 0 0 0-2.907-1.987.67.67 0 0 1-.222-.924m-40.6 2.894a.67.67 0 0 1-.089.947 36 36 0 0 0-2.407 2.186.672.672 0 0 1-.948-.953 37 37 0 0 1 2.498-2.269.67.67 0 0 1 .946.09m46.39 1.333a.67.67 0 0 1 .95-.035q1.34 1.246 2.55 2.618a.672.672 0 1 1-1.008.89 36 36 0 0 0-2.457-2.523.67.67 0 0 1-.035-.95m-51.167 3.25a.67.67 0 0 1 .084.947 36 36 0 0 0-1.968 2.589.672.672 0 0 1-1.106-.764 37 37 0 0 1 2.043-2.687.67.67 0 0 1 .947-.085m-3.862 5.378a.67.67 0 0 1 .256.916q-.398.708-.763 1.435a.672.672 0 1 1-1.202-.604q.38-.755.794-1.49a.67.67 0 0 1 .915-.257"
      clipRule="evenodd"
    />
    <path
      fill="#0ACC86"
      d="M94.08 17.284a.67.67 0 0 1-.799.516l-5.915-1.27a.672.672 0 1 1 .282-1.316l4.935 1.03a.336.336 0 0 0 .397-.257l1.056-4.9a.672.672 0 1 1 1.315.282z"
    />
  </svg>
);
export default SvgMigration;
