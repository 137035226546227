import { CREATE_PUBLISHER_PAGE } from '../../../constants';
import { userManagedCountriesSelector } from '../../../../../../selectors/user';

export function suggestedSiblingPublishersSelector(state) {
  const suggestedSiblingPublishers = state.suggestedSiblingPublishers ?? [];
  const managedCountries = userManagedCountriesSelector(state);
  return managedCountries.length === 0
    ? suggestedSiblingPublishers
    : suggestedSiblingPublishers.filter(({ countryCode }) =>
        managedCountries.includes(countryCode)
      );
}

export const displayableSiblingPublisherSelector = (state) =>
  state[CREATE_PUBLISHER_PAGE]?.displayableSiblingPublisher ?? '';
