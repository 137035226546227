import styled from 'styled-components';
import { COLOR_PALETTE } from '../../../styles/color';
import Typography, { TYPOGRAPHY_VARIANT } from '../Typography';

import BasePresetTypography from './BasePresetTypography';

export const Subtitle2 = styled(BasePresetTypography)`
  ${Typography.mods.variant(TYPOGRAPHY_VARIANT.SUBTITLE_2)}
`;

Subtitle2.defaultProps = {
  $color: COLOR_PALETTE.NEUTRAL_BASE,
};
