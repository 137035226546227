import { useController, useFormContext } from 'react-hook-form';

import { useIntl } from 'react-intl';
import styled from 'styled-components';
import InputNumber, {
  INPUT_NUMBER_TYPE,
  INPUT_NUMBER_UNIT_POSITION,
} from 'design-system/components/InputNumber';
import { useRegisterFieldErrors } from '../../pages/publication/partials/FieldGroup';

export const NUMBER_FIELD_UNIT_POSITION = INPUT_NUMBER_UNIT_POSITION;

export function useCurrencyPosition(currency) {
  const intl = useIntl();
  const parts = intl.formatNumberToParts(99, { style: 'currency', currency });
  const currencyIndex = parts.findIndex(({ type }) => type === 'currency');

  if (currencyIndex === 0) {
    return NUMBER_FIELD_UNIT_POSITION.BEFORE;
  }
  return NUMBER_FIELD_UNIT_POSITION.AFTER;
}

function NumberField({ name, type = INPUT_NUMBER_TYPE.DECIMAL, tip, ...rest }) {
  const { control } = useFormContext();
  const {
    fieldState: { error },
    field: { onChange, ref, ...inputProps },
  } = useController({ name, control });

  useRegisterFieldErrors({ name, errors: error });

  const handleChange = (e) => {
    if (e.target.rawValue === '') {
      onChange(0);
      return;
    }
    onChange(parseFloat(e.target.rawValue));
  };

  return (
    <InputNumber
      htmlRef={ref}
      type={type}
      onChange={handleChange}
      error={Boolean(error)}
      tip={error?.message ?? tip}
      {...rest}
      {...inputProps}
    />
  );
}

export default styled(NumberField)``;
