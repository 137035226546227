import { uniqueId } from 'lodash';
import { registerEventHandler } from 'reffects';
import queryString from 'query-string';
import { http } from 'reffects-batteries';
import { table } from '../../../../partials/DataTable/effects/table';
import { environment } from '../../../../coeffects/environment';

export default function createInvalidPropertiesAdapter() {
  const eventSuffix = uniqueId('invalidProperties');

  const UPDATE_ITEMS_TABLE_DATA = `UPDATE_ITEMS_TABLE_DATA_${eventSuffix}`;
  const UPDATE_ITEMS_TABLE_DATA_SUCCESS = `UPDATE_ITEMS_TABLE_DATA_SUCCESS_${eventSuffix}`;

  registerEventHandler(
    UPDATE_ITEMS_TABLE_DATA,
    ({ select, environment: { apiUrl } }, context) => {
      const { currentPage, itemsPerPage } = select.pagination(context);
      const filters = select.filters(context);

      const query = queryString.stringify(
        {
          ...filters,
          _page: currentPage,
          _limit: itemsPerPage,
        },
        { skipNull: true }
      );

      return http.get({
        url: `${apiUrl}/publishers/me/invalid-properties${
          query && `?${query}`
        }`,
        successEvent: [UPDATE_ITEMS_TABLE_DATA_SUCCESS, context],
      });
    },
    [table.select(), environment()]
  );

  registerEventHandler(
    UPDATE_ITEMS_TABLE_DATA_SUCCESS,
    (_, [response, context]) => {
      const { rows, totalRows, totalFilteredRows } = response.data;
      const visibleItemsIdList = rows.map(({ id }) => id);

      return table.update(context.collectionName, {
        collection: rows,
        totalItems: totalFilteredRows,
        isEmpty: totalRows === 0,
        visibleItemsIdList,
      });
    }
  );

  function registerEvents() {
    return {
      collection: UPDATE_ITEMS_TABLE_DATA,
      events: {
        UPDATE_ITEMS_TABLE_DATA,
        UPDATE_ITEMS_TABLE_DATA_SUCCESS,
      },
    };
  }

  return {
    registerEvents,
  };
}
