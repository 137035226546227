import styled from 'styled-components';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { border } from 'design-system/styles/border';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import FileInput from '../../../../partials/FileInput';
import DropZone from '../../../../partials/DropZone';

const Wrapper = styled(DropZone)`
  ${spacing.inset(SPACING_SIZE.L)}
  ${border.outer({ size: 1, color: COLOR_PALETTE.SECONDARY_A80 })}
  border-style: dashed;
  ${radius.regular(RADIUS_SIZE.BASE)}
  cursor: pointer;
  &:hover {
    ${color.background(COLOR_PALETTE.SECONDARY_A05)}
    ${border.outer({ size: 1, color: COLOR_PALETTE.SECONDARY_BASE })}
    border-style: dashed;
  }
  ${Body1} {
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
    margin-bottom: ${SPACING_SIZE.XS}px;
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    ${spacing.stack(SPACING_SIZE.M)}
  }
`;

export default function WithoutImages({ id, onImagesUploaded }) {
  return (
    <Wrapper onDrop={onImagesUploaded}>
      <FileInput
        id={id}
        onUpload={onImagesUploaded}
        multiple
        accept=".jpg, .jpeg, .png"
      >
        <Block>
          <Icon
            glyph="photoLibrary"
            $color={COLOR_PALETTE.NEUTRAL_A20}
            $size={ICON_SIZE.XLARGE}
          />
          <Body1>
            <FormattedMessageWithValidation id="newprop_pics_drag_drop" />
          </Body1>
        </Block>
      </FileInput>
    </Wrapper>
  );
}
