import {FormattedRelativeTime} from 'react-intl';
import CustomFormattedDate from './CustomFormattedDate';

function isSameDay(aDate, anotherDate) {
  return (
    aDate.getFullYear() === anotherDate.getFullYear() &&
    aDate.getMonth() === anotherDate.getMonth() &&
    aDate.getDate() === anotherDate.getDate()
  );
}

export function calculateFormattingDate(date, now) {
  const differenceInMilliseconds = now - date;
  const relativeSeconds = differenceInMilliseconds / 1000;
  const oneDayInSeconds = 3600 * 24;
  return {
    formatAsRelative: () => {
      const yesterday = new Date(now.getTime() - oneDayInSeconds * 1000);

      return isSameDay(now, date) || isSameDay(yesterday, date);
    },
    relativeSeconds: () => -relativeSeconds,
    date: () => date,
  };
}

function FormattedRelativeDate({ date, now }) {
  const formattingDate = calculateFormattingDate(date, now);
  if (formattingDate.formatAsRelative()) {
    return (
      <FormattedRelativeTime
        value={formattingDate.relativeSeconds()}
        numeric="auto"
        updateIntervalInSeconds={60}
      />
    );
  }

  return <CustomFormattedDate value={formattingDate.date()} />;
}

export default FormattedRelativeDate;
