import { registerEventHandler } from 'reffects';
import query from 'query-string';
import { location } from '../../../coeffects/location';
import { table } from '../effects/table';
import { navigateTo } from '../../../effects/routing';
import filterSerializer from '../utils/queryParamsSerializer';
import { excludeKeys } from '../utils/excludeKeys';

export const DATA_TABLE_UPDATE_URI = 'DATA_TABLE_UPDATE_FILTERS_URI';

registerEventHandler(
  DATA_TABLE_UPDATE_URI,
  (
    { select, location: { path, queryString } },
    { collectionName, registeredFilters = [] }
  ) => {
    const filters = select.filters({ collectionName });
    const tableSorting = select.sorting({ collectionName });
    const tablePagination = select.pagination({ collectionName });

    const sorting = mapTableSortingToQueryString(tableSorting);
    const pagination = mapTablePaginationToQueryString(tablePagination);

    const existingQueryParsed = query.parse(queryString);
    const foreignQueryParamsParsed = excludeKeys(
      existingQueryParsed,
      registeredFilters.flatMap((f) => [f, `${f}{}`])
    );

    const newQueryString = query.stringify(
      {
        ...foreignQueryParamsParsed,
        ...filterSerializer.serialize(filters),
        ...sorting,
        ...pagination,
      },
      { skipNull: true }
    );

    const newUrl = [path, newQueryString].filter(Boolean).join('?');

    return navigateTo(newUrl);
  },
  [table.select(), location()]
);

function mapTablePaginationToQueryString({ currentPage, itemsPerPage }) {
  return {
    page: currentPage,
    rowsPerPage: itemsPerPage,
  };
}

function mapTableSortingToQueryString({ direction, field }) {
  return {
    order: direction,
    orderBy: field,
  };
}
