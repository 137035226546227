import { useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { subscribe, useSelector } from 'reffects-store';
import { chunk } from 'lodash';
import styled from 'styled-components';
import Section from 'design-system/components/Section';
import InputRow from 'design-system/components/InputRow';
import NumberField from '../../../partials/fields/NumberField';
import {
  detailsOfPropertyTypeSelector,
  floorAreaUnitsSelector,
  plotAreaUnitChoicesSelector,
  plotAreaUnitsSelector,
  usableAreaUnitChoicesSelector,
  usableAreaUnitsSelector,
} from '../selectors';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import SelectField from '../../../partials/fields/SelectField';
import HiddenField from '../../../partials/fields/HiddenField';
import { areaUnitsLabels } from '../../../constants/ad';

const reverseChunk = (array, size) =>
  chunk(array.reverse(), size)
    .reverse()
    .map((a) => a.reverse());

const NoLabelNumberFiled = styled(NumberField)`
  span:first-of-type {
    white-space: pre;
    line-height: 15px;
  }
`;

function AreaInformationRows({
  floorAreaFields,
  usableAreaFields,
  plotAreaFields,
  rowSize,
}) {
  if (rowSize === 3) {
    return (
      <>
        {reverseChunk(floorAreaFields, 3).map((fields, index) => (
          <InputRow key={index} arrangement={fields.length === 1 && [3]}>
            {fields.map((field) => field)}
          </InputRow>
        ))}
        {reverseChunk(usableAreaFields, 3).map((fields, index) => (
          <InputRow key={index} arrangement={fields.length === 1 && [3]}>
            {fields.map((field) => field)}
          </InputRow>
        ))}
        {reverseChunk(plotAreaFields, 3).map((fields, index) => (
          <InputRow key={index} arrangement={fields.length === 1 && [3]}>
            {fields.map((field) => field)}
          </InputRow>
        ))}
      </>
    );
  }

  const allFields = [
    ...floorAreaFields,
    ...usableAreaFields,
    ...plotAreaFields,
  ];

  return chunk(allFields, 2).map((fields, index) => (
    <InputRow key={index} arrangement={fields.length === 1 && [3]}>
      {fields.map((field) => field)}
    </InputRow>
  ));
}

function AreaInformationSection({
  floorAreaUnits,
  usableAreaUnits,
  usableAreaUnitChoices,
  plotAreaUnits,
  plotAreaUnitChoices,
}) {
  const intl = useIntl();
  const { watch } = useFormContext();
  const [
    propertyTypeChoice,
    floorAreaUnit,
    usableAreaUnitChoice,
    plotAreaUnitChoice,
  ] = watch([
    'propertyType',
    'floorAreaUnit',
    'usableAreaUnit',
    'plotAreaUnit',
  ]);

  const details = useSelector((state) =>
    detailsOfPropertyTypeSelector(state, propertyTypeChoice)
  );
  const floorAreaFields = [
    details.includes('floorArea') && (
      <NumberField
        key="floorArea"
        name="floorArea"
        label={
          <FormattedMessageWithValidation id="newprop_area_total_emptystate" />
        }
        unit={floorAreaUnits[floorAreaUnit]}
      />
    ),
  ].filter(Boolean);

  const hasMultipleUsableAreaUnitChoices = usableAreaUnitChoices.length > 1;
  const usableAreaFields = [
    details.includes('usableArea') && hasMultipleUsableAreaUnitChoices && (
      <SelectField
        label={<FormattedMessageWithValidation id="ad_form_usable_area_unit" />}
        key="usableAreaUnit"
        name="usableAreaUnit"
        placeholder={intl.formatMessage({
          id: 'newprop_furnished_emptystate',
        })}
        choices={usableAreaUnitChoices.map(({ name }) => ({
          value: name,
          label: intl.formatMessage({
            id: areaUnitsLabels.get(name),
          }),
        }))}
      />
    ),
    details.includes('usableArea') && (
      <NumberField
        key="usableArea"
        name="usableArea"
        label={
          <FormattedMessageWithValidation id="newprop_area_usable_emptystate" />
        }
        unit={usableAreaUnits[usableAreaUnitChoice]}
      />
    ),
  ].filter(Boolean);

  const hasMultiplePlotAreaUnitChoices = plotAreaUnitChoices.length > 1;
  const plotAreaUnitDefaultOrNotSet =
    plotAreaUnitChoice == null || plotAreaUnitChoice === 'sqm';
  const plotAreaFields = [
    details.includes('plotArea') && hasMultiplePlotAreaUnitChoices && (
      <SelectField
        key="plotAreaUnit"
        name="plotAreaUnit"
        label={<FormattedMessageWithValidation id="ad_form_usable_area_unit" />}
        placeholder={intl.formatMessage({
          id: 'newprop_furnished_emptystate',
        })}
        choices={plotAreaUnitChoices.map(({ name }) => ({
          value: name,
          label: intl.formatMessage({
            id: areaUnitsLabels.get(name),
          }),
        }))}
      />
    ),
    details.includes('plotArea') && plotAreaUnitDefaultOrNotSet && (
      <NumberField
        key="plotAreaSqm"
        name="plotAreaSqm"
        label={<FormattedMessageWithValidation id="land_area" />}
        unit={plotAreaUnits[plotAreaUnitChoice]}
      />
    ),
    details.includes('plotArea') && plotAreaUnitChoice === 'sf' && (
      <NumberField
        key="plotAreaSf"
        name="plotAreaSf"
        label={<FormattedMessageWithValidation id="land_area" />}
        unit={plotAreaUnits[plotAreaUnitChoice]}
      />
    ),
    ...(details.includes('plotArea') && plotAreaUnitChoice === 'rai/ngan/sqw'
      ? [
          <NumberField
            key="plotAreaRai"
            name="plotAreaRai"
            label={<FormattedMessageWithValidation id="land_area" />}
            unit="rai"
          />,
          <NoLabelNumberFiled
            key="plotAreaNgan"
            name="plotAreaNgan"
            label={' '}
            unit="ngan"
          />,
          <NoLabelNumberFiled
            key="plotAreaSqw"
            name="plotAreaSqw"
            label={' '}
            unit="sqwa"
          />,
        ]
      : []),
  ].filter(Boolean);

  if (
    !floorAreaFields.length &&
    !usableAreaFields.length &&
    !plotAreaFields.length
  )
    return null;

  return (
    <Section
      title={
        <FormattedMessageWithValidation id="ad_form_section_area_information" />
      }
    >
      <AreaInformationRows
        rowSize={'rai/ngan/sqw' in plotAreaUnits ? 3 : 2}
        floorAreaFields={floorAreaFields}
        usableAreaFields={usableAreaFields}
        plotAreaFields={plotAreaFields}
      />
      {floorAreaFields && <HiddenField name="floorAreaUnit" />}
      {!hasMultiplePlotAreaUnitChoices && <HiddenField name="usableAreaUnit" />}
      {!hasMultipleUsableAreaUnitChoices && <HiddenField name="plotAreaUnit" />}
    </Section>
  );
}

export default subscribe(AreaInformationSection, (state) => ({
  floorAreaUnits: floorAreaUnitsSelector(state),
  usableAreaUnits: usableAreaUnitsSelector(state),
  usableAreaUnitChoices: usableAreaUnitChoicesSelector(state),
  plotAreaUnits: plotAreaUnitsSelector(state),
  plotAreaUnitChoices: plotAreaUnitChoicesSelector(state),
}));
