import TabLink from '../TabLink';

export function CompanyProfileTabLink({ children }) {
  return (
    <TabLink
      to="/company-profile"
      eventName="view-publisher-profile-page"
      elementName="publisher-profile-link"
      sectionName="side-menu-top-publisher-profile-link"
    >
      {children}
    </TabLink>
  );
}
