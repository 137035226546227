import { adDataSelector } from '../../selectors';

export function imagesSelector(state, field) {
  return adDataSelector(state)?.[field] ?? [];
}

export function isDisabledSelector(state, field, maxNumberOfImages) {
  return imagesSelector(state, field).length >= maxNumberOfImages;
}

export function showDeleteImageDialogSelector(state, field) {
  return uploaderState(state, field).imageIdToDelete != null;
}

export function errorSelector(state, field) {
  return uploaderState(state, field).error;
}

function uploaderState(state, field) {
  return state.imageUploader?.[field] ?? {};
}
