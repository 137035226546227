import styled from 'styled-components';
import Tag, { TAG_VARIANT } from 'design-system/components/Tag';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import BaseRow from './BaseRow';

const DiscountTag = styled(Tag)`
  ${Tag.mods.variant(TAG_VARIANT.PRIMARY)}

  ${spacing.inline(SPACING_SIZE.S)}
`;

function Row({ title, price, discount, ...rest }) {
  return (
    <BaseRow
      {...rest}
      leftChild={
        <>
          {discount && <DiscountTag>{discount}</DiscountTag>}
          <Subtitle3 $color={COLOR_PALETTE.PRIMARY_A120}>{title}</Subtitle3>
        </>
      }
      rightChild={
        <Body1 $weight={FONT_WEIGHT.LIGHT} $color={COLOR_PALETTE.PRIMARY_A120}>
          {price}
        </Body1>
      }
    />
  );
}

export default styled(Row)``;
