import Icon from 'design-system/components/Icon';

export default function IconMultiLanguage({ locale }) {
  const icons = {
    'th-TH': <Icon glyph="flagTh" />,
    'en-US': <Icon glyph="flagGb" />,
    'id-ID': <Icon glyph="flagId" />,
    'vi-VN': <Icon glyph="flagVn" />,
  };

  return icons[locale];
}
