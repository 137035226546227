import styled from 'styled-components';
import Table from 'design-system/components/Table';
import Anchor from 'design-system/components/Anchor';
import Button from 'design-system/components/Button';
import { FONT_SIZE, typography } from 'design-system/styles/typography';
import { useColumn } from '../../../../partials/DataTable/hooks/useColumn';
import { useDialog } from '../../../../hooks/useDialog';
import { DEVELOPER_PROMOTED_PROJECT_DETAILS_DIALOG } from './constants';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Wrapper = styled(Table.Cell)`
  ${Button} {
    ${typography.size(FONT_SIZE.S)}
    text-decoration: underline;
  }
`;

export function ViewDetailsColumn(props) {
  const {
    value: { title, price, totalPrice, tax, billingDate, billingCycleInMonths },
  } = useColumn(props);
  const { openDialog } = useDialog(DEVELOPER_PROMOTED_PROJECT_DETAILS_DIALOG);

  return (
    <Wrapper>
      <Button
        as={Anchor}
        onClick={() =>
          openDialog({
            title,
            price,
            totalPrice,
            tax,
            billingDate,
            billingCycleInMonths,
          })
        }
      >
        <FormattedMessageWithValidation id="subscription_payment_information_view_details" />
      </Button>
    </Wrapper>
  );
}
