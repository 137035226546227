import styled from 'styled-components';
import { dispatch } from 'reffects';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'reffects-store';
import Chip from 'design-system/components/Chip';
import { PrimaryButton } from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import TimelineFormProvider from './TimelineFormProvider';
import useInternationalization from '../../../../../hooks/useInternationalization';
import { LEAD_EMAIL_SENDING_TRIGGERED } from '../events';
import {
  REPLY_EMAIL_MAX_CHARACTERS_LENGTH,
  REPLY_EMAIL_MESSAGE_FIELD_NAME,
} from '../constants';
import InputField from './InputField';
import { leadSelector } from '../../../selectors';
import useTracking from '../../../../../hooks/useTracking';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Recipient = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid ${COLOR_PALETTE.NEUTRAL_A10};
  border-bottom: 1px solid ${COLOR_PALETTE.NEUTRAL_A10};
  ${spacing.insetSquish(SPACING_SIZE.M)}

  ${Body1} {
    ${spacing.inline(SPACING_SIZE.S)}
  }
`;

const EmailContentBox = styled.div`
  ${spacing.insetSquish(SPACING_SIZE.M)}
  padding-bottom: ${spacing.value(SPACING_SIZE.M)};
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
`;

const MarkupTools = styled.div`
  display: flex;
  align-items: center;
`;

function SendReplyEmail() {
  const lead = useSelector(leadSelector);
  const intl = useInternationalization();

  const handleSubmit = ({ message }) => {
    dispatch({
      id: LEAD_EMAIL_SENDING_TRIGGERED,
      payload: { message, leadId: lead.id },
    });
  };

  return (
    <Wrapper>
      <Recipient>
        <Body1 $color={COLOR_PALETTE.NEUTRAL_A60}>
          <FormattedMessageWithValidation id="enquiry_detail_email_to" />
        </Body1>
        <Chip>{lead.email}</Chip>
      </Recipient>
      <EmailContentBox>
        <TimelineFormProvider
          defaultValues={{
            [REPLY_EMAIL_MESSAGE_FIELD_NAME]: '',
          }}
          onSubmit={handleSubmit}
          messageMaxLength={REPLY_EMAIL_MAX_CHARACTERS_LENGTH}
        >
          <InputField
            name={REPLY_EMAIL_MESSAGE_FIELD_NAME}
            maxRows={3}
            autoResize
            placeholder={intl.formatMessageWithValidation({
              id: 'enquiry_detail_email_reply_empty',
            })}
          />
          <Actions>
            <MarkupTools>{/* Add here the markup tools */}</MarkupTools>
            <SubmitButton />
          </Actions>
        </TimelineFormProvider>
      </EmailContentBox>
    </Wrapper>
  );
}

function SubmitButton() {
  const { watch } = useFormContext();
  const [message] = watch([REPLY_EMAIL_MESSAGE_FIELD_NAME]);
  const isSubmitDisabled =
    message.trim().length === 0 ||
    message.trim().length > REPLY_EMAIL_MAX_CHARACTERS_LENGTH;

  const trackingRef = useTracking({
    trackClick: {
      eventName: 'mail-replied',
    },
    sectionName: 'enquiry-activity-timeline-bottom-input',
  });

  return (
    <PrimaryButton
      type="submit"
      $size={BUTTON_SIZE.SMALL}
      disabled={isSubmitDisabled}
      ref={trackingRef}
    >
      <FormattedMessageWithValidation id="enquiry_detail_email_reply_send" />
    </PrimaryButton>
  );
}

export default SendReplyEmail;
