import { createGlobalStyle } from 'styled-components';
import normalize from 'styled-normalize';
import { iconography } from '../tokens/iconography';

export const GlobalStyles = createGlobalStyle`
  ${normalize}

  * {
    box-sizing: border-box;

    &::before,
    &::after {
      box-sizing: border-box;
    }
  }

  :root {
    font-size: 16px;
  }
`;

export function IconsSprite() {
  return (
    <span style={{ display: 'none' }}>
      {Object.entries(iconography.glyphs).map(([name, Glyph]) => (
        <Glyph id={`ic-${name}`} key={name} />
      ))}
    </span>
  );
}

export default IconsSprite;
