import { PROPERTY_TYPE_LAND } from '../../../../../constants/ad';

function nonEmptyObject(value) {
  return value && Object.keys(value).length > 0 && value.value;
}

function nonEmptyArray(value) {
  return value && value.length > 0;
}

export function propertyForLeadSelector(state) {
  const leadEnquiredProperties = state.leadEnquiredProperties ?? [];
  const uniqueProperties = new Set(leadEnquiredProperties.map((x) => x.id));
  let adData;
  if (uniqueProperties.size === 1) {
    [adData] = leadEnquiredProperties;
  } else if (state.selectedPropertyId) {
    adData = leadEnquiredProperties.find(
      ({ id }) => id === state.selectedPropertyId
    );
  }

  if (!adData) {
    return undefined;
  }
  const { usableArea, floorArea, plotArea, communityFees, ...rest } = adData;
  return {
    ...(nonEmptyObject(usableArea) ? { usableArea: [usableArea] } : {}),
    ...(nonEmptyObject(floorArea) ? { floorArea: [floorArea] } : {}),
    ...(nonEmptyArray(plotArea) ? { plotArea } : {}),
    ...(nonEmptyObject(communityFees) ? { communityFees } : {}),
    ...rest,
  };
}

export function propertySpacesSelector(state) {
  const property = propertyForLeadSelector(state);
  if (!property) return undefined;
  const { bedrooms, bathrooms, usableArea, plotArea, propertyType } = property;
  const area = propertyType === PROPERTY_TYPE_LAND ? plotArea : usableArea;
  const areaTag =
    propertyType === PROPERTY_TYPE_LAND
      ? 'land_area'
      : 'newprop_area_usable_emptystate';
  return {
    ...(bedrooms != null ? { bedrooms } : {}),
    ...(bathrooms != null ? { bathrooms } : {}),
    ...(area != null ? { area } : {}),
    ...(area != null ? { areaTag } : {}),
  };
}

export function enquiredPropertiesListSelector(state) {
  const timeline = state.activityTimeline ?? [];
  return timeline.reduce((acc, activity) => {
    if (activity.ad === null && activity.project === null) return acc;
    const property = activity.ad
      ? { ...activity.ad, project: activity.project, type: 'ad' }
      : { ...activity.project, type: 'project' };
    if (!acc.some(({ id }) => id === property.id)) {
      acc.push(property);
    }
    return acc;
  }, []);
}

export function newEnquiredPropertiesListSelector(state) {
  return state.enquiredPropertiesList ?? [];
}

export function selectedPropertyDetailSelector(state) {
  const enquiredProperties = newEnquiredPropertiesListSelector(state);
  return (
    enquiredProperties?.length === 1 || state.selectedPropertyId !== undefined
  );
}

export function showEnquiredPropertiesListSelector(state) {
  const enquiredProperties = newEnquiredPropertiesListSelector(state);
  return enquiredProperties?.length > 1;
}

export function doesPropertyForLeadBelongToProjectSelector(state) {
  const property = propertyForLeadSelector(state);
  return (property?.project ?? null) !== null;
}
