import { useCallback } from 'react';
import { dispatch } from 'reffects';
import Menu from 'design-system/components/Menu/Menu';
import AssociateModal from '../../Modals/AssociatePublisherModal/AssociateModal';
import { OPEN_ASSOCIATE_PUBLISHER_MODAL } from '../../Modals/AssociatePublisherModal/events';

export function AssociatePublisherToMultiaccount({ publisher }) {
  const handleOnClick = useCallback(
    () => dispatch(OPEN_ASSOCIATE_PUBLISHER_MODAL),
    []
  );

  return (
    <>
      <Menu.Item label="Associate to multiaccount" onClick={handleOnClick} />
      <AssociateModal
        publisherId={publisher.id}
        countryCode={publisher.country}
      />
    </>
  );
}
