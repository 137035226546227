import styled, { css } from 'styled-components';
import { divider, DIVIDER_SIZE } from 'design-system/styles/divider';
import { layout } from 'design-system/tokens/layout';

const modDelimitedColumn = () => css`
  @media only screen and (min-width: ${layout.maxWidth}px) {
    ${divider.right(DIVIDER_SIZE.THIN)}
  }
`;

const RightSidebar = styled.div`
  --layout-columns: 1;

  grid-area: sidebar_right;
  width: var(--layout-sidebar-width);
  z-index: 2;

  ${divider.left(DIVIDER_SIZE.THIN)}

  ${({ delimitedColumn }) => delimitedColumn && modDelimitedColumn()}
`;

export default RightSidebar;
