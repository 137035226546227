import { forwardRef } from 'react';
import styled from 'styled-components';
import { useInputFocus } from 'design-system/hooks/useInputFocus';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { border, BORDER_SIZE } from 'design-system/styles/border';
import { radius } from 'design-system/styles/radius';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import {
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';

const TextBox = styled.div`
  transition: background 300ms;

  ${border.inner({
    color: COLOR_PALETTE.NEUTRAL_A20,
    size: BORDER_SIZE.BASE,
  })}
  ${radius.pill()}
  ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
  ${spacing.inset(SPACING_SIZE.NONE)};

  input {
    width: 100%;
    outline: none;
    border: none;
    line-height: 36px;
    padding: ${spacing.value(SPACING_SIZE.NONE)} ${spacing.value(40)};

    &::placeholder,
    &::-webkit-input-placeholder,
    &:placeholder-shown {
      opacity: 1;
      ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
    }

    ${typography.font(FONT_FAMILY.PRIMARY)}
    ${typography.weight(FONT_WEIGHT.NORMAL)}
    ${typography.size(FONT_SIZE.M)}
    ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
    ${color.background(COLOR_PALETTE.NONE)}
  }
`;

const HiddenPlaceholder = styled.div`
  height: 0;
  visibility: hidden;
  display: block;
  padding: 0 40px;
  ${typography.size(FONT_SIZE.M)}
  ${typography.font(FONT_FAMILY.PRIMARY)}
  ${typography.weight(FONT_WEIGHT.NORMAL)}
`;

const Wrapper = styled.div`
  display: block;
  cursor: text;
  position: relative;

  &:hover {
    ${TextBox} {
      ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
    }
  }

  :active,
  &[data-focus] {
    ${TextBox} {
      ${border.inner({
        color: COLOR_PALETTE.NEUTRAL_A80,
        size: BORDER_SIZE.LARGE,
      })}
    }
  }

  ${Icon} {
    position: absolute;
    top: ${spacing.value(6)};
    ${Icon.mods.size(ICON_SIZE.BASE)}
  }

  ${Icon}:first-of-type {
    left: ${spacing.value(SPACING_SIZE.S)};
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
  }

  ${Icon}:last-of-type {
    cursor: pointer;
    right: ${spacing.value(SPACING_SIZE.S)};
  }
`;

function SearchField(
  { onFocus, onBlur, onRemove, value, placeholder, ...rest },
  ref
) {
  const [isFocused, inputHandlers] = useInputFocus({ onFocus, onBlur });

  return (
    <Wrapper data-focus={isFocused || undefined}>
      <TextBox>
        <HiddenPlaceholder>{placeholder}</HiddenPlaceholder>
        <input
          {...inputHandlers}
          ref={ref}
          value={value}
          placeholder={placeholder}
          data-tag="search_input_properties"
          {...rest}
        />
      </TextBox>
      <Icon glyph="magnifierLens" />
      {value && <Icon glyph="cross" onClick={onRemove} />}
    </Wrapper>
  );
}

export default forwardRef(SearchField);
