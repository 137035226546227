import styled from 'styled-components';
import SkeletonBone from 'design-system/components/SkeletonBone';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import Section from './Section';

const Wrapper = styled(Section)`
  display: flex;
  flex-direction: row;
`;

const Item = styled.div`
  min-width: 100px;
  display: flex;
  flex-direction: column;
  ${spacing.inline(SPACING_SIZE.S)}

  ${SkeletonBone} {
    ${spacing.stack(SPACING_SIZE.S)}
  }
`;

const Datum = styled.div`
  display: flex;
  align-items: center;

  ${Icon} {
    ${color.text(COLOR_PALETTE.PRIMARY_BASE)}
    ${Icon.mods.size(ICON_SIZE.BASE)}
    ${spacing.inline(SPACING_SIZE.XS)}
  }
`;

const Label = styled(Body1)`
  ${color.text(COLOR_PALETTE.NEUTRAL_A40)}
`;

function Space({ value, tag, glyph, children }) {
  if (value == null && !children) {
    return null;
  }
  return (
    <Item>
      <Datum>
        <Icon glyph={glyph} />
        {value != null && <Heading1>{value}</Heading1>}
        {children}
      </Datum>
      <Label>
        <FormattedMessageWithValidation id={tag} />
      </Label>
    </Item>
  );
}

function SpaceSkeleton() {
  return (
    <Item>
      <SkeletonBone $width="39px" $height="20px" />
      <SkeletonBone $width="72px" $height="14px" />
    </Item>
  );
}

function PropertySpaces({ children }) {
  return <Wrapper>{children}</Wrapper>;
}

function Skeleton() {
  return (
    <Wrapper>
      <SpaceSkeleton />
      <SpaceSkeleton />
      <SpaceSkeleton />
    </Wrapper>
  );
}

PropertySpaces.Space = Space;
PropertySpaces.Skeleton = Skeleton;

export default PropertySpaces;
