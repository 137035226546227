import styled from 'styled-components';
import Group from 'design-system/components/Group';
import { SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const SpacedDiv = styled.div`
  margin-top: ${SPACING_SIZE.M}px;
  margin-bottom: ${SPACING_SIZE.L}px;
`;

const BlockBody1 = styled(Body1)`
  display: block;
  margin-top: ${SPACING_SIZE.XS}px;
  ${typography.weight(FONT_WEIGHT.LIGHT)}
`;

function CashPaymentInformation() {
  return (
    <Group
      title={
        <FormattedMessageWithValidation id="subscription_payment_information_title" />
      }
    >
      <SpacedDiv>
        <Subtitle3>
          <FormattedMessageWithValidation id="agencyprofile_payments_method" />
        </Subtitle3>
        <BlockBody1>
          <FormattedMessageWithValidation id="payment_method_name_cash" />
        </BlockBody1>
      </SpacedDiv>
    </Group>
  );
}

export default CashPaymentInformation;
