import { Children, cloneElement } from 'react';
import styled from 'styled-components';
import { radius } from 'design-system/styles/radius';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Typography from 'design-system/components/Typography/Typography';
import {
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';

const Wrapper = styled.div`
  display: inline-block;

  ${radius.pill()}
  ${color.background(COLOR_PALETTE.NEUTRAL_A05)}
  ${spacing.inset(SPACING_SIZE.XS)}
`;

const Text = styled(Typography)`
  cursor: pointer;
  opacity: 0.8;
  line-height: 16px;

  ${radius.pill()}
  ${typography.size(FONT_SIZE.S)};
  ${typography.font(FONT_FAMILY.PRIMARY)};
  ${typography.weight(FONT_WEIGHT.NORMAL)};
  ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
  ${spacing.insetSquish(SPACING_SIZE.M)}
`;

const Radio = styled.input`
  display: none;
`;

const Label = styled.label`
  display: inline-flex;

  ${spacing.inline(SPACING_SIZE.XS)}

  &:hover ${Text} {
    ${color.background(COLOR_PALETTE.NEUTRAL_A10)}
  }

  ${Radio}:checked ~ ${Text} {
    ${color.background(COLOR_PALETTE.SECONDARY_BASE)}
  }

  ${Radio}:checked ~ ${Text} {
    ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
    opacity: 1;
  }
`;

function SegmentedControl({
  children,
  name,
  selectedValue,
  defaultValue,
  onChange,
  ...props
}) {
  const value = !selectedValue ? defaultValue : selectedValue;

  return (
    <Wrapper {...props}>
      {Children.map(children, (child) =>
        child
          ? cloneElement(child, {
              name,
              selectedValue: value,
              onChange,
            })
          : null
      )}
    </Wrapper>
  );
}

SegmentedControl.Item = function SegmentedControlItem({
  children,
  value,
  name,
  selectedValue,
  onChange,
}) {
  return (
    <Label>
      <Radio
        type="radio"
        value={value}
        name={name}
        checked={value === selectedValue}
        onChange={() => onChange({ name, value })}
      />
      <Text>{children}</Text>
    </Label>
  );
};

export default styled(SegmentedControl)``;
