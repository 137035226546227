import {
  INVALID_PASSWORD,
  LOGIN_HAS_FAILED,
  MISMATCHED_PASSWORDS,
  SERVER_ERROR,
} from './constants';

export default function loginHasFailedSelector(state) {
  return state[LOGIN_HAS_FAILED] != null ? state[LOGIN_HAS_FAILED] : false;
}

export function passwordRecoveryMailIsSentSelector(state) {
  return state['forgotPassword:mailSent'] ?? false;
}

function resetPasswordErrorSelector(state) {
  return state['resetPassword:error'];
}

export function passwordErrorSelector(state) {
  const error = resetPasswordErrorSelector(state);
  if (error === INVALID_PASSWORD) {
    return 'changepwdpage_error_length';
  }

  if (error === SERVER_ERROR) {
    return 'An error occurred. Please try again later.';
  }

  return null;
}

export function confirmedPasswordErrorSelector(state) {
  const error = resetPasswordErrorSelector(state);

  if (error === MISMATCHED_PASSWORDS) {
    return 'changepwdpage_error_match';
  }

  return null;
}

export function passwordResetSelector(state) {
  return state['resetPassword:reset'] ?? false;
}
