import styled from 'styled-components';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  ${Icon} {
    ${color.text(COLOR_PALETTE.NEUTRAL_A80)}
    ${spacing.inline(SPACING_SIZE.S)}
    ${Icon.mods.size(ICON_SIZE.BASE)}
  }
`;

const Value = styled(Body1)`
  ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
`;

function Item({ children, icon }) {
  return (
    <Wrapper>
      {icon}{' '}
      <Value $weight={FONT_WEIGHT.LIGHT} $color={COLOR_PALETTE.NEUTRAL_A80}>
        {children}
      </Value>
    </Wrapper>
  );
}

export default Item;
