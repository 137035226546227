import { INVOICES_TAB } from './partials/BillingSwitch/events';
import {
  hasSubscriptionChangeRequestForSuperboostSelector,
  publisherCountrySelector,
  publisherHasSuperboostSubscriptionSelector,
  publisherSubscriptionPlanIsFreeSelector,
} from '../../selectors/publisher';
import { publishedPropertiesSelector } from '../../selectors/propertiesSummary';
import { COUNTRY_CODE } from '../../constants/country';
import { getFeatureSelector } from '../../utils/featuresFlags/selectors';

export function subscriptionPageShowInvoicesTabSelector(state) {
  return state['subscriptionPage:showTab'] === INVOICES_TAB;
}

export function showSuperboostBannerSelector(state) {
  return (
    publisherHasSuperboostSubscriptionSelector(state) ||
    hasSubscriptionChangeRequestForSuperboostSelector(state)
  );
}

export function pendingSCRsSelector(state) {
  return state.publisher?.pendingSCRs ?? [];
}

export function hasCancelRequestSelector(state) {
  return ['pending', 'accepted'].includes(
    autoRenewalCancellationStatusSelector(state)
  );
}

export function autoRenewalCancellationStatusSelector(state) {
  return state.requestAutoRenewalCancellation?.status;
}

function showOffersEnabledByCountry(state) {
  const NEW_PLANS_IN_OFFERS_4135 = getFeatureSelector(
    state,
    'NEW_PLANS_IN_OFFERS_4135'
  );
  const availableCountries = [
    COUNTRY_CODE.MEXICO,
    COUNTRY_CODE.ARGENTINA,
    COUNTRY_CODE.PERU,
    COUNTRY_CODE.CHILE,
    COUNTRY_CODE.COLOMBIA,
  ];
  const country = publisherCountrySelector(state);

  if (NEW_PLANS_IN_OFFERS_4135) {
    return availableCountries.includes(country);
  }

  return country === COUNTRY_CODE.MEXICO;
}

export function shouldShowOffersSelector(state) {
  return (
    showOffersEnabledByCountry(state) &&
    publisherSubscriptionPlanIsFreeSelector(state) &&
    publishedPropertiesSelector(state) <= 10
  );
}
