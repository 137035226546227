import TabLink from '../TabLink';
import { homeRoute } from '../../../utils/proppitWebRouter';
import FormattedMessageWithValidation from '../../FormattedMessageWithValidation/FormattedMessageWithValidation';

export function OverviewTabLink() {
  return (
    <TabLink
      to={homeRoute()}
      eventName="view-overview-page"
      elementName="overview-link"
      sectionName="side-menu-top-overview-link"
    >
      <FormattedMessageWithValidation id="menu_overview" />
    </TabLink>
  );
}
