import Layout from 'design-system/components/Layout';
import CheckoutStepper from './CheckoutStepper/CheckoutStepper';

export default function Sidebar({ currentStep, currentStepStatus }) {
  return (
    <Layout.Padded>
      <CheckoutStepper
        currentStep={currentStep}
        currentStepStatus={currentStepStatus}
      />
    </Layout.Padded>
  );
}
