import { useSelector } from 'reffects-store';
import { useMount } from 'react-use';
import { dispatch } from 'reffects';
import styled from 'styled-components';
import TasksStepper, {
  TASKS_STEPPER_ITEM_STATUS,
} from 'design-system/components/TasksStepper';
import useInternationalization from '../../../../../hooks/useInternationalization';
import { sitesPublishingSelector } from './selectors';
import { PUBLICATION_STEPPER_ANIMATION_TRIGGERED } from './events';

const Wrapper = styled.div`
  min-width: 175px;
`;

function SitePublicationStepper({ sites }) {
  const intl = useInternationalization();

  useMount(() => {
    dispatch({
      id: PUBLICATION_STEPPER_ANIMATION_TRIGGERED,
      payload: { sites },
    });
  });
  const siteItems = useSelector(sitesPublishingSelector) ?? [];

  return (
    <Wrapper>
      <TasksStepper>
        {siteItems.map(({ site, status }) => {
          const subtitleTagFromStatus = getSubtitleTagFromStatus(status);
          return (
            <TasksStepper.Item
              status={getStepperStatusFromStatus(status)}
              title={site}
              subtitle={
                subtitleTagFromStatus &&
                intl.formatMessageWithValidation({
                  id: subtitleTagFromStatus,
                })
              }
            />
          );
        })}
      </TasksStepper>
    </Wrapper>
  );
}

export default SitePublicationStepper;

function getStepperStatusFromStatus(status) {
  return {
    pending: TASKS_STEPPER_ITEM_STATUS.PENDING,
    current: TASKS_STEPPER_ITEM_STATUS.CURRENT,
    done: TASKS_STEPPER_ITEM_STATUS.DONE,
    failed: TASKS_STEPPER_ITEM_STATUS.FAILED,
  }[status];
}

function getSubtitleTagFromStatus(status) {
  return {
    failed: 'site_publication_steper_status_not_published',
    done: 'site_publication_steper_status_published',
  }[status];
}
