import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { COLUMN_SORTING } from './constants';

const ICON_GLYPH_TO_SORTING_MAP = {
  [COLUMN_SORTING.ASCENDING]: 'caretUp',
  [COLUMN_SORTING.DESCENDING]: 'caretDown',
  [COLUMN_SORTING.INDETERMINATE]: 'caretUpDown',
  [COLUMN_SORTING.NOT_SORTABLE]: 'noIcon',
};

const ICON_COLOR_TO_SORTING_MAP = {
  [COLUMN_SORTING.ASCENDING]: '#333333',
  [COLUMN_SORTING.DESCENDING]: '#333333',
  [COLUMN_SORTING.INDETERMINATE]: '#CCCCCC',
  [COLUMN_SORTING.NOT_SORTABLE]: 'transparent',
};

export function SortingIcon({ sortingDirection }) {
  return (
    <Icon
      $size={ICON_SIZE.BASE}
      $color={ICON_COLOR_TO_SORTING_MAP[sortingDirection]}
      glyph={ICON_GLYPH_TO_SORTING_MAP[sortingDirection]}
    />
  );
}
