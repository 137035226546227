import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { environment } from '../../../coeffects/environment';
import { OPEN_DIALOG } from '../../../events/dialogs';
import { BULK_BOOST_NOT_ALLOWED_DIALOG } from './constants';

export const BULK_BOOST_ACTION_SELECTED = 'BULK_BOOST_ACTION_SELECTED_v33';
export const BULK_BOOST_ACTION_SELECTED_SUCCESS =
  'BULK_BOOST_ACTION_SELECTED_SUCCESS_v33';
export const BULK_BOOST_ACTION_LIMIT_REACHED =
  'BULK_BOOST_ACTION_LIMIT_REACHED_v33';

registerEventHandler(
  BULK_BOOST_ACTION_SELECTED,
  ({ environment: { apiUrl } }, { isPaidPublisher, ...context }) => {
    if (!isPaidPublisher) {
      return effects.dispatch({
        id: OPEN_DIALOG,
        payload: {
          id: BULK_BOOST_NOT_ALLOWED_DIALOG,
        },
      });
    }

    return http.get({
      url: `${apiUrl}/can-boost`,
      successEvent: {
        id: BULK_BOOST_ACTION_SELECTED_SUCCESS,
        payload: context,
      },
      errorEvent: BULK_BOOST_ACTION_LIMIT_REACHED,
    });
  },
  [environment()]
);

registerEventHandler(BULK_BOOST_ACTION_SELECTED_SUCCESS, (_, [, context]) => {
  const { updateEvents } = context;

  return effects.dispatch({
    id: updateEvents.bulk,
    payload: {
      ...context,
      action: 'boost',
    },
  });
});

registerEventHandler(BULK_BOOST_ACTION_LIMIT_REACHED, () =>
  effects.dispatch({
    id: OPEN_DIALOG,
    payload: {
      id: BULK_BOOST_NOT_ALLOWED_DIALOG,
    },
  })
);
