import { coeffect, registerCoeffectHandler } from 'reffects';

export function registerCalendarCoeffectHandler(clock) {
  registerCoeffectHandler('calendar', () => ({
    calendar: {
      now: clock.now(),
      timestamp: clock.timestamp(),
    },
  }));
}

export function calendar() {
  return coeffect('calendar');
}
