import styled from 'styled-components';

const IdWrapper = styled.a`
  background-color: #fcf8e3;
  font-family: monospace;
`;
export function Id({ id }) {
  return <IdWrapper href={`#${id}`}>{id}</IdWrapper>;
}

const IdMarkWrapper = styled.span`
  margin-left: 2rem;
`;

export function IdMark({ id }) {
  return (
    id && (
      <IdMarkWrapper>
        ID: <Id id={id} />
      </IdMarkWrapper>
    )
  );
}
