import { useMount } from 'react-use';
import { dispatch, dispatchMany } from 'reffects';
import { useSelector } from 'reffects-store';
import { useCallback } from 'react';
import Grid from 'design-system/components/Grid';
import Layout from 'design-system/components/Layout';
import { Navigation } from '../../../partials';
import Sidebar from '../partials/Sidebar';
import { CHECKOUT_STEPS } from '../partials/CheckoutStepper/CheckoutStepper';
import Form from './partials/Form/Form';
import Summary from '../partials/Summary/Summary';
import {
  CHECKOUT_BILLING_SUMMARY_REQUESTED,
  CHECKOUT_PUBLISHER_DATA_REQUESTED,
} from '../events';
import { isFormLoadedSelector } from './partials/Form/selectors';
import {
  BILLING_INFORMATION_FORM_ERROR,
  BILLING_INFORMATION_SUBMITTED,
} from './partials/Form/events';
import { checkoutPageSCRIdSelector } from '../selectors';
import PublisherData from '../PublisherData/PublisherData';
import { useFeatureSelector } from '../../../utils/featuresFlags/selectors';

export default function BillingInformation() {
  const CHECKOUT_RESPONSIVE_4151 = useFeatureSelector(
    'CHECKOUT_RESPONSIVE_4151'
  );
  const subscriptionChangeRequestId = useSelector(checkoutPageSCRIdSelector);
  useMount(() => {
    dispatchMany([
      {
        id: CHECKOUT_PUBLISHER_DATA_REQUESTED,
        payload: { subscriptionChangeRequestId },
      },
      {
        id: CHECKOUT_BILLING_SUMMARY_REQUESTED,
        payload: { subscriptionChangeRequestId },
      },
    ]);
  });

  const formLoaded = useSelector(isFormLoadedSelector);

  const handleSubmit = useCallback(
    (data) => {
      dispatch({
        id: BILLING_INFORMATION_SUBMITTED,
        payload: {
          subscriptionChangeRequestId,
          ...data,
        },
      });
    },
    [subscriptionChangeRequestId]
  );

  const handleError = useCallback(() => {
    dispatch({
      id: BILLING_INFORMATION_FORM_ERROR,
      payload: [undefined, subscriptionChangeRequestId],
    });
  }, []);

  return (
    <Layout independentScroll supportsMobile={CHECKOUT_RESPONSIVE_4151}>
      <Layout.Header>
        <Navigation title={<PublisherData />} hideLinks hideAppBanner />
      </Layout.Header>
      <Layout.LeftSidebar supportsMobile={CHECKOUT_RESPONSIVE_4151}>
        <Sidebar currentStep={CHECKOUT_STEPS.BILLING} />
      </Layout.LeftSidebar>
      <Layout.Content>
        <Layout.Padded>
          <Grid
            areas={({ repeat }) => `
            "${repeat(5, 'form')} ${repeat(3, 'summary')} ."
          `}
          >
            <Grid.Area $id="form">
              {formLoaded && (
                <Form
                  onSubmit={handleSubmit}
                  onError={handleError}
                  subscriptionChangeRequestId={subscriptionChangeRequestId}
                />
              )}
            </Grid.Area>
            <Grid.Area $id="summary">
              <Summary />
            </Grid.Area>
          </Grid>
        </Layout.Padded>
      </Layout.Content>
    </Layout>
  );
}
