import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { environment } from '../../coeffects/environment';

export const VERIFIED_LEADS_STATS_REQUESTED = 'VERIFIED_LEADS_STATS_REQUESTED';
export const VERIFIED_LEADS_STATS_LOADED = 'VERIFIED_LEADS_STATS_LOADED';

registerEventHandler(
  VERIFIED_LEADS_STATS_REQUESTED,
  ({ environment: { apiUrl } }) =>
    http.get({
      url: `${apiUrl}/publishers/me/verified-leads-stats`,
      successEvent: VERIFIED_LEADS_STATS_LOADED,
    }),
  [environment()]
);

registerEventHandler(VERIFIED_LEADS_STATS_LOADED, (_, [{ data }]) =>
  state.set({ verifiedLeadsStats: data })
);
