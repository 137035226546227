import styled from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import Table from './Table';
import Row from './Row';
import { KpiCell } from './presets';

const Wrapper = styled(Table)`
  ${Row} {
    ${Row.mods.disableHover()}

    &:hover ${KpiCell} {
      ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
    }
  }

  ${KpiCell} {
    ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
  }
`;

export function Skeleton({ children, rows, columns }) {
  return (
    <Wrapper columns={columns}>
      {Array.from({ length: rows }).map(() => children)}
    </Wrapper>
  );
}
