import { createCachedSelector } from 're-reselect';
import { calculatePagination } from './updateTable';

export const tableStateSelector = createCachedSelector(
  (state) => state,
  (_, tableName) => tableName,
  (state, tableName) => state[tableName] ?? {}
)((_, tableName) => tableName);

export const tableSelector = createCachedSelector(
  tableStateSelector,
  ({ pagination, sorting }) => ({
    pagination: {
      ...pagination,
      ...calculatePagination(pagination),
    },
    sorting,
  })
)((_, tableName) => tableName);

export const tableIsLoadingSelector = createCachedSelector(
  tableStateSelector,
  ({ isLoading }) => isLoading ?? false
)((_, tableName) => tableName);

export const tableRowsSelector = createCachedSelector(
  tableStateSelector,
  ({ rows = [] }) => rows
)((_, tableName) => tableName);

export const tableFiltersSelector = createCachedSelector(
  tableStateSelector,
  ({ filters }) => filters
)((_, tableName) => tableName);

export const tableHasNoDataSelector = createCachedSelector(
  tableRowsSelector,
  tableFiltersSelector,
  (rows, filters = {}) => {
    const hasRows = rows.length > 0;
    const isFiltering = Object.entries(filters).length > 0;
    return hasRows || isFiltering;
  }
)((_, tableName) => tableName);

export const tableHasFiltersAppliedSelector = createCachedSelector(
  tableFiltersSelector,
  (filters = {}) =>
    Object.entries(filters).filter(([_, filter]) => Boolean(filter)).length > 0
)((_, tableName) => tableName);
