import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import Thumbnail from 'design-system/components/Thumbnail';
import Illustration from 'design-system/components/Illustration/Illustration';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Heading2 } from 'design-system/components/Typography/presets/Heading2';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import { COLOR_PALETTE } from 'design-system/styles/color';
import PublisherSites from '../../../../partials/PublisherSites';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  publisherLogoSelector,
  publisherNameByUserLocaleSelector,
} from '../../../../selectors/publisher';
import SubscriptionDisclaimer from './SubscriptionDisclaimer';
import { showSubscriptionDisclaimerSelector } from './selectors';
import { isWhiteLabelSelector } from '../../../../App/selectors';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${Thumbnail} {
    ${Thumbnail.mods.maxWidthRatio(60, 1.5)}
    ${spacing.inline(SPACING_SIZE.M, false)}
  }
`;

const Header = styled.header`
  display: flex;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  > ${Heading2} {
    ${spacing.stack(SPACING_SIZE.S)}
  }
`;

const SitesDisclaimer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > *,
  ${PublisherSites} > * {
    ${spacing.inline(SPACING_SIZE.S)}
  }

  ${PublisherSites} {
    display: flex;
    align-items: center;
  }
`;

function Publisher() {
  const publisherName = useSelector(publisherNameByUserLocaleSelector);
  const publisherLogo = useSelector(publisherLogoSelector);
  const showSubscriptionDisclaimer = useSelector(
    showSubscriptionDisclaimerSelector
  );
  const isWhiteLabel = useSelector(isWhiteLabelSelector);

  return (
    <Wrapper>
      <Header>
        <Thumbnail
          source={publisherLogo}
          placeholder={<Illustration name="agencyAvatar" />}
        />
        <Info>
          <Heading2>
            <FormattedMessageWithValidation
              id="overview_welcome_header"
              values={{ name: publisherName }}
            />
          </Heading2>
          <SitesDisclaimer>
            <Body2 $color={COLOR_PALETTE.NEUTRAL_A60}>
              <FormattedMessageWithValidation id="overview_welcome_body" />
            </Body2>
            {!isWhiteLabel && <PublisherSites />}
          </SitesDisclaimer>
        </Info>
      </Header>
      {showSubscriptionDisclaimer && <SubscriptionDisclaimer />}
    </Wrapper>
  );
}

export default Publisher;
