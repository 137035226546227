import { useSelector } from 'reffects-store';
import { isDeveloperSelector } from '../../../selectors/publisher';
import NavigationLinksForDevelopers from './NavigationLinksForDevelopers';
import NavigationLinksForAgencies from './NavigationLinksForAgencies';

export function NavigationLinks() {
  const isDeveloper = useSelector(isDeveloperSelector);

  return isDeveloper ? (
    <NavigationLinksForDevelopers />
  ) : (
    <NavigationLinksForAgencies />
  );
}
