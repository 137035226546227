import Group from 'design-system/components/Group';
import Anchor from 'design-system/components/Anchor';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

export default function ContactSupport({ supportEmail }) {
  return (
    <Group
      title={
        <FormattedMessageWithValidation id="subscription_support_header" />
      }
    >
      <Body1 $weight={FONT_WEIGHT.LIGHT}>
        <FormattedMessageWithValidation
          id="subscription_support_body"
          values={{
            email: (
              <Anchor href={`mailto:${supportEmail}`}>{supportEmail}</Anchor>
            ),
          }}
        />
      </Body1>
    </Group>
  );
}
