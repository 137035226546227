import styled from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { border } from 'design-system/styles/border';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { useSelector } from 'reffects-store';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { supportEmailSelector } from '../../../selectors/config';

const CONFIG_BY_STATUS = {
  pending: {
    background: COLOR_PALETTE.PUMPKIN_A05,
    border: COLOR_PALETTE.PUMPKIN_A20,
    header: 'cancellation_request_pending_header',
    body: 'cancellation_request_pending_body',
  },
  accepted: {
    background: COLOR_PALETTE.STRAWBERRY_A05,
    border: COLOR_PALETTE.STRAWBERRY_A20,
    header: 'cancellation_request_accepted_header',
    body: 'cancellation_request_accepted_body',
  },
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: ${spacing.value(SPACING_SIZE.L)};

  ${({ $palette }) => color.background($palette.background)}
  ${({ $palette }) => border.outer({ color: $palette.border })}
  ${radius.regular(RADIUS_SIZE.LARGE)}
  ${spacing.inset(SPACING_SIZE.M)}
  
  ${Subtitle3} {
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
  }
  ${Body1} {
    ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
  }
`;

function CancellationRequestStatusBanner({ status, ...props }) {
  if (!CONFIG_BY_STATUS[status]) {
    return null;
  }
  const supportEmail = useSelector(supportEmailSelector);
  const { header, body, ...config } = CONFIG_BY_STATUS[status];
  return (
    <Wrapper $palette={config} {...props}>
      <Subtitle3>
        <FormattedMessageWithValidation id={header} />
      </Subtitle3>
      <Body1>
        <FormattedMessageWithValidation id={body} values={{ supportEmail }} />
      </Body1>
    </Wrapper>
  );
}

export default styled(CancellationRequestStatusBanner)``;
