import pipe from './pipe';

export function composePathWithSearchParams(href, params) {
  const url = new URL(href);
  const search = composeSearchParams(href, params);

  return `${url.pathname}${search}`;
}

export function composeSearchParams(href, params) {
  const url = new URL(href);

  Object.entries(params).forEach(([key, value]) => {
    setParam(url, key, value);
  });

  return url.search;
}

function setParam(url, key, value) {
  if (value != null) {
    url.searchParams.set(key, value);
  } else {
    url.searchParams.delete(key);
  }
}

export function decodeStringFromUrl(value) {
  return pipe(decodeURIComponent, replacePlusSignForEmptySpace)(value);
}

function replacePlusSignForEmptySpace(value) {
  return value.replace(/\+/g, ' ');
}
