import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import { environment } from '../../coeffects/environment';
import { loadCountryConfig } from '../../effects/loadCountryConfig';
import { COUNTRY_CONFIG_STATE_REGION } from '../../config';
import { navigateTo } from '../../effects/routing';
import {
  checkoutAlreadyActiveRoute,
  checkoutPaymentCancelledRoute,
  checkoutPaymentForbiddenRoute,
  loginRoute,
} from '../../utils/proppitWebRouter';
import { isBackofficeRole } from '../../constants/user';

export const CHECKOUT_PUBLISHER_DATA_REQUESTED =
  'CHECKOUT_PUBLISHER_DATA_REQUESTED';
export const CHECKOUT_PUBLISHER_DATA_RETRIEVED =
  'CHECKOUT_PUBLISHER_DATA_RETRIEVED';
export const CHECKOUT_BILLING_SUMMARY_REQUESTED =
  'CHECKOUT_BILLING_SUMMARY_REQUESTED';
export const CHECKOUT_BILLING_SUMMARY_RETRIEVED =
  'CHECKOUT_BILLING_SUMMARY_RETRIEVED';
export const CHECKOUT_COUNTRY_CONFIG_RETRIEVED =
  'CHECKOUT_COUNTRY_CONFIG_RETRIEVED';
export const CHECKOUT_PUBLISHER_DATA_RETRIEVAL_FAILED =
  'CHECKOUT_PUBLISHER_DATA_RETRIEVAL_FAILED';

registerEventHandler(
  CHECKOUT_PUBLISHER_DATA_REQUESTED,
  (
    { environment: { apiUrl }, state: { billingEntity } },
    { subscriptionChangeRequestId }
  ) => ({
    ...state.merge({
      form: { loading: billingEntity == null },
    }),
    ...http.get({
      url: `${apiUrl}/subscription-change-requests/${subscriptionChangeRequestId}/publisher`,
      successEvent: {
        id: CHECKOUT_PUBLISHER_DATA_RETRIEVED,
        payload: { subscriptionChangeRequestId },
      },
      errorEvent: CHECKOUT_PUBLISHER_DATA_RETRIEVAL_FAILED,
    }),
  }),
  [environment(), state.get({ billingEntity: 'publisher.billingEntity' })]
);

registerEventHandler(CHECKOUT_PUBLISHER_DATA_RETRIEVAL_FAILED, () =>
  navigateTo(loginRoute())
);

registerEventHandler(
  CHECKOUT_PUBLISHER_DATA_RETRIEVED,
  (
    { environment: { apiUrl }, state: { subRoute, subscriptionId, role } },
    [{ data: publisher }, { subscriptionChangeRequestId }]
  ) => {
    if (
      publisher.subscriptionChangeRequestActivated === true ||
      publisher.subscriptionChangeRequestPaid === true
    ) {
      return navigateTo(
        checkoutAlreadyActiveRoute({ subRoute, subscriptionChangeRequestId })
      );
    }
    if (publisher.subscriptionChangeRequestCancelled === true) {
      return navigateTo(
        checkoutPaymentCancelledRoute({
          subRoute,
          subscriptionChangeRequestId,
        })
      );
    }
    if (
      subscriptionId &&
      subscriptionId !== publisher.subscriptionId &&
      !isBackofficeRole(role)
    ) {
      return navigateTo(
        checkoutPaymentForbiddenRoute({
          subRoute,
          subscriptionChangeRequestId,
        })
      );
    }
    return {
      ...state.merge({
        publisher,
      }),
      ...loadCountryConfig({
        apiUrl,
        country: publisher.countryCode,
        successEvent: CHECKOUT_COUNTRY_CONFIG_RETRIEVED,
      }),
    };
  },
  [
    environment(),
    state.get({
      subRoute: 'checkoutConfig.subRoute',
      subscriptionId: 'publisher.subscriptionId',
      role: 'user.role',
    }),
  ]
);

registerEventHandler(CHECKOUT_COUNTRY_CONFIG_RETRIEVED, (_, [countryConfig]) =>
  state.merge({
    [COUNTRY_CONFIG_STATE_REGION]: countryConfig,
    form: {
      loading: false,
    },
  })
);

registerEventHandler(
  CHECKOUT_BILLING_SUMMARY_REQUESTED,
  (
    { environment: { apiUrl }, state: { summary } },
    { subscriptionChangeRequestId }
  ) => ({
    ...state.merge({
      summary: { loading: summary == null },
    }),
    ...http.get({
      url: `${apiUrl}/subscription-change-requests/${subscriptionChangeRequestId}/summary`,
      successEvent: CHECKOUT_BILLING_SUMMARY_RETRIEVED,
    }),
  }),
  [environment(), state.get({ summary: 'summary' })]
);

registerEventHandler(CHECKOUT_BILLING_SUMMARY_RETRIEVED, (_, [{ data }]) => ({
  ...state.set({
    summary: { ...data, loading: false },
  }),
}));
