import styled from 'styled-components';
import { useCallback } from 'react';
import { useSelector } from 'reffects-store';
import { TextButton } from 'design-system/components/Button/presets';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Section from '../Section';
import EmptyState from './EmptyState';
import { useDialog } from '../../../hooks/useDialog';
import { PUBLISHER_PROFILE_EDIT_DIALOG } from '../constants';
import { publisherSubscriptionPlanIsFreeSelector } from '../../../selectors/publisher';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { FreePublisher } from './FreePublisher';
import {
  publisherDescriptionByCurrentLocaleSelector,
  publisherFormDataSelector,
} from '../selectors';

const Description = styled(Body1)`
  white-space: pre-line;

  ${Body1.mods.weight(FONT_WEIGHT.NORMAL)}
  ${spacing.stack(SPACING_SIZE.XL, false)}
`;

function DescriptionSection() {
  const { openDialog } = useDialog(PUBLISHER_PROFILE_EDIT_DIALOG);
  const publisherFormData = useSelector(publisherFormDataSelector);
  const handleEditClick = useCallback(
    () =>
      openDialog({ ...publisherFormData, autoFocusDescriptionByLocale: true }),
    [publisherFormData]
  );
  const description = useSelector(publisherDescriptionByCurrentLocaleSelector);
  const isFree = useSelector(publisherSubscriptionPlanIsFreeSelector);

  return (
    <>
      {isFree && <FreePublisher />}
      {!description && <EmptyState onEditDescription={handleEditClick} />}
      {description && (
        <Section
          title={
            <FormattedMessageWithValidation id="company_profile_edit_company_description_section_title" />
          }
          cta={
            <TextButton onClick={handleEditClick}>
              <FormattedMessageWithValidation id="company_profile_edit_company_name" />
            </TextButton>
          }
        >
          <Description>{description}</Description>
        </Section>
      )}
    </>
  );
}

export default DescriptionSection;
