import styled from 'styled-components';
import { dispatch } from 'reffects';
import * as yup from 'yup';
import { number, string } from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'reffects-store';
import InputText from 'design-system/components/InputText';
import { PrimaryButton } from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Checkbox from 'design-system/components/Checkbox/Checkbox';
import Anchor from 'design-system/components/Anchor';
import {
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { useLocation } from 'react-router-dom';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { useEffect } from 'react';
import { SELFSERVICE_CREATE_SUBMITTED } from './events';
import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { EMAIL_VALIDATION_REGEX } from '../../constants';
import { TOGGLE_TERMS_AND_CONDITIONS_ACCEPTANCE } from '../Checkout/PaymentMethod/partials/Form/events';
import { termsAndConditionsAcceptedSelector } from '../Checkout/PaymentMethod/partials/Form/selectors';
import { legalLinksSelector } from '../../selectors/config';
import { ItemTwoColumns } from '../../partials/SimpleFormLayout';
import PhoneField from '../../partials/fields/PhoneField';
import useTracking from '../../hooks/useTracking';
import { COUNTRY_LOCALE } from '../../constants/country';
import { FORCE_LOCALE } from '../../App/events';

const Wrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  ${InputText},
  ${Heading1},
    ${Body1} {
    ${spacing.stack(SPACING_SIZE.M)}
  }

  ${Body1} {
    text-align: center;
  }
`;

const TermsAndConditions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${typography.font(FONT_FAMILY.PRIMARY)}
  gap: ${spacing.value(SPACING_SIZE.M)};

  ${typography.size(FONT_SIZE.S)}
  ${typography.weight(FONT_WEIGHT.NORMAL)}
    ${typography.lineHeight(20)}
    ${Anchor} {
    ${typography.size(FONT_SIZE.S)}
    ${typography.weight(FONT_WEIGHT.MEDIUM)}
  }

  label {
    cursor: pointer;
  }
`;

const Inputs = styled.div`
  width: 100%;
  ${spacing.stack(SPACING_SIZE.M)}
`;

const Actions = styled.div`
  display: flex;
  padding-top: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const CustomPrimaryButton = styled(PrimaryButton)`
  display: flex;
  padding: 12px ${spacing.value(SPACING_SIZE.L)}px;
  justify-content: center;
  align-items: center;
  gap: ${spacing.value(SPACING_SIZE.S)};
  flex: 1 0 0;
  align-self: stretch;
`;

const TermsAndConditionsWrapper = styled.div`
  ${({ hasError }) =>
    hasError &&
    `
    color: red;
  `}
`;

const schema = yup
  .object({
    email: yup
      .string()
      .email(
        <FormattedMessageWithValidation id="selfservice_create_error_email_invalid" />
      )
      .matches(EMAIL_VALIDATION_REGEX, {
        message: (
          <FormattedMessageWithValidation id="selfservice_create_error_email_invalid" />
        ),
      })
      .required(
        <FormattedMessageWithValidation id="selfservice_create_error_email_required" />
      ),
    companyName: yup
      .string()
      .min(
        3,
        <FormattedMessageWithValidation id="selfservice_create_error_company_name_required" />
      )
      .required(
        <FormattedMessageWithValidation id="selfservice_create_error_company_name_required" />
      ),
    phone: yup
      .object({
        phoneNumber: number().required(
          <FormattedMessageWithValidation id="selfservice_create_error_phone_required" />
        ),
        countryCode: string().required(),
      })
      .test({
        name: 'contactPhone',
        message: (
          <FormattedMessageWithValidation id="form_validations_invalid_phone_number" />
        ),
        test: (value) => {
          try {
            const phoneUtil = PhoneNumberUtil.getInstance();
            const phoneWithPrefix = `${value.dialCode}${value.phoneNumber}`;
            return phoneUtil.isValidNumber(
              phoneUtil.parseAndKeepRawInput(phoneWithPrefix, value.countryCode)
            );
          } catch (e) {
            return false;
          }
        },
      }),
    acceptTermsAndConditions: yup
      .boolean()
      .oneOf([true], {
        message: (
          <FormattedMessageWithValidation id="selfservice_create_error_terms_required" />
        ),
      })
      .required(),
  })
  .required();

function SelfServiceCreateForm() {
  const { termsAndConditions, privacyPolicy } = useSelector(legalLinksSelector);
  const termsAndConditionsAccepted = useSelector(
    termsAndConditionsAcceptedSelector
  );
  const searchParams = new URLSearchParams(useLocation().search);
  const countryCode = searchParams.get('countryCode');

  useEffect(() => {
    if (
      countryCode &&
      COUNTRY_LOCALE[countryCode] &&
      !localStorage.getItem('localeForced')
    ) {
      dispatch({
        id: FORCE_LOCALE,
        payload: {
          locale: COUNTRY_LOCALE[countryCode],
        },
      });
      localStorage.setItem('localeForced', 'true');
    }
  }, [countryCode]);

  useEffect(() => {
    localStorage.removeItem('localeForced');
  }, []);

  const trackImpression = useTracking({
    trackImpression: {
      elementName: `self-service-create-show-${countryCode}`,
    },
    sectionName: 'self-service-create-form',
  });

  const methods = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      phone: {
        dialCode: '',
        phoneNumber: '',
        countryCode,
      },
      acceptTermsAndConditions: termsAndConditionsAccepted,
    },
  });

  function onSubmit(data) {
    dispatch({
      id: SELFSERVICE_CREATE_SUBMITTED,
      payload: {
        companyName: data.companyName,
        email: data.email,
        phone: data.phone,
      },
    });
  }

  return (
    <FormProvider {...methods}>
      <Wrapper onSubmit={methods.handleSubmit(onSubmit)} ref={trackImpression}>
        <Heading1>
          <FormattedMessageWithValidation id="self_service_create_header" />
        </Heading1>
        <Inputs>
          <InputText
            name="companyName"
            type="text"
            label={
              <FormattedMessageWithValidation id="self_service_create_company_name" />
            }
            error={Boolean(methods.formState.errors.companyName)}
            tip={methods.formState.errors.companyName?.message}
            {...methods.register('companyName')}
          />
          <InputText
            name="email"
            label={
              <FormattedMessageWithValidation id="self_service_create_email" />
            }
            error={Boolean(methods.formState.errors.email)}
            tip={methods.formState.errors.email?.message}
            {...methods.register('email')}
          />
          <ItemTwoColumns>
            <PhoneField
              name="phone"
              label={
                <FormattedMessageWithValidation id="self_service_create_phone_label" />
              }
              error={Boolean(methods.formState.errors.phone)}
              tip={methods.formState.errors.phone?.message}
            />
          </ItemTwoColumns>
        </Inputs>
        <TermsAndConditionsWrapper
          hasError={Boolean(methods.formState.errors.acceptTermsAndConditions)}
        >
          <TermsAndConditions>
            <Checkbox
              id="acceptTermsAndConditions"
              name="acceptTermsAndConditions"
              checked={termsAndConditionsAccepted}
              onChange={(e) => {
                dispatch({
                  id: TOGGLE_TERMS_AND_CONDITIONS_ACCEPTANCE,
                  payload: e.target.checked,
                });
                methods.setValue('acceptTermsAndConditions', e.target.checked);
              }}
            />
            <label htmlFor="acceptTermsAndConditions">
              <FormattedMessageWithValidation
                id="checkout_terms_agreement"
                values={{
                  privacyPolicy: (
                    <Anchor href={privacyPolicy}>
                      <FormattedMessageWithValidation id="checkout_terms_agreement_privacy" />
                    </Anchor>
                  ),
                  termsConditions: (
                    <Anchor href={termsAndConditions}>
                      <FormattedMessageWithValidation id="checkout_terms_agreement_terms" />
                    </Anchor>
                  ),
                }}
              />
            </label>
          </TermsAndConditions>
        </TermsAndConditionsWrapper>
        <Actions>
          <CustomPrimaryButton type="submit" $size={BUTTON_SIZE.LARGE}>
            <FormattedMessageWithValidation id="self_service_continue" />
          </CustomPrimaryButton>
        </Actions>
      </Wrapper>
    </FormProvider>
  );
}

export default SelfServiceCreateForm;
