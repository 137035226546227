import { effects, registerEventHandler } from 'reffects';
import { DATA_TABLE_UPDATE_URI } from './uri';
import { table } from '../effects/table';

export const DATA_TABLE_UPDATE_ITEMS_PER_PAGE =
  'DATA_TABLE_UPDATE_ITEMS_PER_PAGE';
export const DATA_TABLE_UPDATE_CURRENT_PAGE = 'DATA_TABLE_UPDATE_CURRENT_PAGE';

registerEventHandler(
  DATA_TABLE_UPDATE_ITEMS_PER_PAGE,
  (_, { itemsPerPage, ...context }) => {
    const { collectionName, updateEvents } = context;

    return {
      ...table.setPagination(collectionName, { itemsPerPage }),
      ...effects.dispatchMany([
        {
          id: updateEvents.collection,
          payload: context,
        },
        { id: DATA_TABLE_UPDATE_URI, payload: { collectionName } },
      ]),
    };
  }
);

registerEventHandler(
  DATA_TABLE_UPDATE_CURRENT_PAGE,
  (_, { currentPage, ...context }) => {
    const { collectionName, updateEvents } = context;

    return {
      ...table.setPagination(collectionName, { currentPage }),
      ...effects.dispatchMany([
        {
          id: updateEvents.collection,
          payload: context,
        },
        { id: DATA_TABLE_UPDATE_URI, payload: { collectionName } },
      ]),
    };
  }
);
