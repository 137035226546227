import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { OVERVIEW } from '../constants/routes';
import { goBack, navigateTo, openNewTab } from '../effects/routing';
import { location } from '../coeffects/location';

export const GO_BACK = 'GO_BACK';
export const NAVIGATE_TO = 'NAVIGATE_TO';

registerEventHandler(
  GO_BACK,
  (
    { state: { referrer }, location: { origin } },
    { fallback = OVERVIEW } = {}
  ) => {
    if (!referrer) {
      return navigateTo(fallback);
    }

    if (!referrer.startsWith(origin)) {
      return navigateTo(fallback);
    }

    return goBack();
  },
  [state.get({ referrer: 'userNavigation.referrerUrl' }), location()]
);

registerEventHandler(NAVIGATE_TO, (_, { path, newTab }) => {
  if (!path) return {};
  if (newTab) return openNewTab(path);

  return navigateTo(path);
});
