import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import updateTable from '../../../Tables/updateTable';
import { environment } from '../../../../coeffects/environment';
import {
  tablePageChanged,
  tableRowsPerPageChanged,
} from '../../../Tables/events';
import { PROJECT_UNITS_TABLE } from './constants';

export const PROJECT_UNITS_LOAD = 'PROJECT_UNITS_LOAD';
export const PROJECT_UNITS_LOADED = 'PROJECT_UNITS_LOADED';
export const PROJECT_UNITS_LOAD_FAILED = 'PROJECT_UNITS_LOAD_FAILED';
export const PROJECT_UNITS_TABLE_PAGE_CHANGED =
  'PROJECT_UNITS_TABLE_PAGE_CHANGED';
export const PROJECT_UNITS_TABLE_ROWS_PER_PAGE_CHANGED =
  'PROJECT_UNITS_TABLE_ROWS_PER_PAGE_CHANGED';

registerEventHandler(
  PROJECT_UNITS_LOAD,
  ({ environment: { apiUrl } }, { projectId }) => ({
    ...http.get({
      url: `${apiUrl}/developers/me/projects/${projectId}/units`,
      successEvent: PROJECT_UNITS_LOADED,
      errorEvent: PROJECT_UNITS_LOAD_FAILED,
    }),
  }),
  [environment()]
);

registerEventHandler(
  PROJECT_UNITS_LOADED,
  ({ state: { pagination } }, [{ data }]) => {
    const { rows, totalRows } = updateTable(data, null, null, pagination);

    return {
      ...state.set({
        projectUnitsById: data,
        projectUnitsTable: {
          isLoading: false,
          rows,
          pagination: { ...pagination, totalRows },
          sorting: null,
          filters: {},
        },
      }),
    };
  },
  [
    state.get({
      pagination: 'projectUnitsTable.pagination',
    }),
  ]
);

registerEventHandler(PROJECT_UNITS_LOAD_FAILED, () => ({}));

registerEventHandler(
  PROJECT_UNITS_TABLE_PAGE_CHANGED,
  tablePageChanged(PROJECT_UNITS_TABLE),
  [
    state.get({
      collectionById: 'projectUnitsById',
      filters: 'projectUnitsTable.filters',
      pagination: 'projectUnitsTable.pagination',
      sorting: 'projectUnitsTable.sorting',
    }),
  ]
);

registerEventHandler(
  PROJECT_UNITS_TABLE_ROWS_PER_PAGE_CHANGED,
  tableRowsPerPageChanged(PROJECT_UNITS_TABLE),
  [
    state.get({
      collectionById: 'projectUnitsById',
      filters: 'projectUnitsTable.filters',
      pagination: 'projectUnitsTable.pagination',
      sorting: 'projectUnitsTable.sorting',
    }),
  ]
);
