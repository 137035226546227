import styled from 'styled-components';
import Thumbnail from 'design-system/components/Thumbnail';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle1 } from 'design-system/components/Typography/presets/Subtitle1';
import Typography from 'design-system/components/Typography/Typography';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { Body2 } from 'design-system/components/Typography/presets/Body2';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;

  ${Thumbnail} {
    ${Thumbnail.mods.size(115, 77)}
    ${spacing.inline(SPACING_SIZE.M)}
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

const Name = styled(Subtitle1)`
  ${Typography.mods.size(18)}
  ${spacing.stack(SPACING_SIZE.M)}
`;

const Address = styled(Body1)`
  ${Typography.mods.size(14)}
  ${Typography.mods.weight(FONT_WEIGHT.NORMAL)}
  ${Typography.mods.color(COLOR_PALETTE.NEUTRAL_A80)}
  ${spacing.stack(SPACING_SIZE.XS)}
`;
const GeoLevels = styled(Body2)`
  ${Typography.mods.size(12)}
  ${Typography.mods.weight(FONT_WEIGHT.LIGHT)}
  ${Typography.mods.color(COLOR_PALETTE.NEUTRAL_A80)}
}`;

function ProjectSnippet({ name, address, geoLevels, image, ...rest }) {
  const geoLevelsString = geoLevels.map((level) => level.name).join(', ');

  return (
    <Wrapper {...rest}>
      <Thumbnail source={image} />
      <Info>
        <Name title={name} $weight={FONT_WEIGHT.NORMAL}>
          {name}
        </Name>
        <Address title={address} $weight={FONT_WEIGHT.MEDIUM} $ellipse>
          {address}
        </Address>
        <GeoLevels
          title={geoLevelsString}
          $weight={FONT_WEIGHT.MEDIUM}
          $ellipse
        >
          {geoLevelsString}
        </GeoLevels>
      </Info>
    </Wrapper>
  );
}

export default styled(ProjectSnippet)``;
