import { createCachedSelector } from 're-reselect';

export const getUnitsByIdSelector = ({ unitsById }) => unitsById;

const unitIdParameter = (_, unitId) => unitId;

export const getUnitSelector = createCachedSelector(
  getUnitsByIdSelector,
  unitIdParameter,
  (unitsById, unitId) => unitsById[unitId]
)(unitIdParameter);
