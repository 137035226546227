export const CUSTOMER_ROLES = {
  CLIENT: 'client',
  PUBLISHER_COLLABORATOR: 'publisher-collaborator',
};

export const BACKOFFICE_ROLES = {
  SUPER_ADMIN: 'superadmin',
  FINANCE: 'finance',
  FINANCE_MANAGER: 'finance-manager',
  SUPPORT: 'support',
  SALES: 'sales',
  SALES_MANAGER: 'sales-manager',
};

export const USER_ROLES = {
  ...CUSTOMER_ROLES,
  ...BACKOFFICE_ROLES,
};

export function isBackofficeRole(role) {
  return Object.values(BACKOFFICE_ROLES).includes(role);
}
