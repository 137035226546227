import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import { useWindowSize } from 'react-use';
import { ROWS_PER_PAGE } from 'design-system/components/Table';
import Layout from 'design-system/components/Layout';
import Header from '../partials/Layout/Header';
import { DataTableProvider } from '../../../partials/DataTable';
import { COLLECTION_NAME, FILTERS } from './constants';
import { createAdapter } from './partials/AgenciesTable/adapter';
import AgenciesTable from './partials/AgenciesTable/AgenciesTable';
import { TableHeader } from './partials/AgenciesTable/TableHeader';
import Modals from './partials/AgenciesTable/Modals/Modals';
import { publisherCountrySelector } from '../../../selectors/publisher';

const AgenciesLayout = styled(Layout)`
  // This may be contained in a mod or find an alternative if we need to reuse it
  --layout-max-width: ${({ width }) => width}px;
`;

function Agencies() {
  const defaultCountry = useSelector(publisherCountrySelector);
  const { width } = useWindowSize();

  if (defaultCountry == null) {
    return null;
  }

  const context = {
    collectionName: COLLECTION_NAME,
    defaultSorting: {},
    defaultFilters: {
      [FILTERS.COUNTRY]: defaultCountry,
    },
    initialItemsPerPage: ROWS_PER_PAGE.HUNDRED,
    adapter: createAdapter(),
  };

  return (
    <AgenciesLayout width={width}>
      <Layout.Header>
        <Header />
      </Layout.Header>
      <DataTableProvider {...context}>
        <Layout.Content>
          <Layout.Padded>
            <TableHeader />
            <AgenciesTable />
            <Modals />
          </Layout.Padded>
        </Layout.Content>
      </DataTableProvider>
    </AgenciesLayout>
  );
}

export default Agencies;
