import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Tile from 'design-system/components/Tile';
import { Heading2 } from 'design-system/components/Typography/presets/Heading2';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import useTracking from '../hooks/useTracking';
import LinkButton from '../pages/Overview/partials/LinkButton';

const Wrapper = styled(Tile)`
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  ${Heading2} {
    ${spacing.stack(SPACING_SIZE.XS, false)}
    display: flex;
    flex-direction: row;
    align-items: center;

    > * {
      ${spacing.inline(SPACING_SIZE.S)}
    }
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const LinkTo = styled(Link)`
  color: initial;
  text-decoration: none;
`;

function Kpi({ children, amount, message, linkTo, track, ...props }) {
  const [wrapperProps, linkToProps] = linkTo ? [{}, props] : [props, {}];
  const Component = (
    <Wrapper {...wrapperProps}>
      <Info>
        <Heading2>
          <span>{amount}</span>
          {linkTo && <LinkButton />}
        </Heading2>
        {message && (
          <Body2
            $color={COLOR_PALETTE.NEUTRAL_A60}
            $weight={FONT_WEIGHT.MEDIUM}
          >
            {message}
          </Body2>
        )}
        {children}
      </Info>
    </Wrapper>
  );

  if (!linkTo) {
    return Component;
  }

  return (
    <LinkTo to={linkTo} ref={track && useTracking(track)} {...linkToProps}>
      {Component}
    </LinkTo>
  );
}

export default styled(Kpi)``;
