import * as React from "react";
const SvgPuntoPropiedadLogoColored = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 101 24"
    {...props}
  >
    <path
      fill="#03045E"
      d="M69.358 8.844h-2.14V7h2.14zM69.375 15.942H67.2V9.633h2.174zM27.294 14.013v1.912h-1.503c-1.402 0-2.254-.857-2.254-2.266v-2.392h-1.161v-.511l2.841-3.026h.449v1.855h1.593v1.682h-1.538v2.017c0 .455.274.729.735.729z"
    />
    <path
      fill="#03045E"
      fillRule="evenodd"
      d="M7.03 12.755c0 1.975-1.386 3.385-3.325 3.385-.573 0-1.098-.15-1.546-.421v2.497H0V9.585h1.485l.274.503a2.94 2.94 0 0 1 1.946-.711c1.94 0 3.325 1.403 3.325 3.378m-2.198 0c0-.833-.6-1.451-1.419-1.451-.818 0-1.42.618-1.42 1.451s.602 1.45 1.42 1.45c.813 0 1.42-.617 1.42-1.45"
      clipRule="evenodd"
    />
    <path
      fill="#03045E"
      d="M7.95 13.204v-3.62h2.184v3.525c0 .668.36 1.062.944 1.062s.937-.4.937-1.062V9.585h2.184v3.619c0 1.773-1.248 2.93-3.12 2.93-1.874 0-3.129-1.157-3.129-2.93M21.758 15.925V12.06c0-1.615-.967-2.662-2.44-2.662-.82 0-1.524.315-1.996.842l-.3-.654H15.49v6.34h2.184V12.54c0-.807.392-1.283 1.073-1.283q.826-.001.827 1.104v3.564z"
    />
    <path
      fill="#03045E"
      fillRule="evenodd"
      d="M42.374 16.154c1.929 0 3.308-1.402 3.308-3.367s-1.38-3.36-3.308-3.36c-.75 0-1.418.26-1.936.707l-.272-.5h-1.478v8.586h2.148v-2.484a2.95 2.95 0 0 0 1.538.418m-.29-4.81c.813 0 1.412.615 1.412 1.443s-.604 1.444-1.413 1.444c-.814 0-1.412-.615-1.412-1.444 0-.828.598-1.443 1.412-1.443"
      clipRule="evenodd"
    />
    <path
      fill="#03045E"
      d="M50.785 9.603v1.989h-.843c-.77 0-1.092.337-1.092 1.142v3.208h-2.172V9.634h1.456l.338.7c.445-.515.987-.73 1.738-.73z"
    />
    <path
      fill="#03045E"
      fillRule="evenodd"
      d="M54.672 9.426c-2.057 0-3.538 1.406-3.538 3.361 0 1.95 1.481 3.36 3.538 3.36 2.05 0 3.537-1.41 3.537-3.36 0-1.955-1.487-3.36-3.537-3.36m1.36 3.361c0 .834-.568 1.41-1.36 1.41s-1.361-.576-1.361-1.41c0-.833.568-1.408 1.36-1.408s1.361.58 1.361 1.409M66.205 12.788c0 1.964-1.379 3.366-3.307 3.366a2.95 2.95 0 0 1-1.538-.418v2.484h-2.148V9.634h1.478l.272.5a2.92 2.92 0 0 1 1.936-.708c1.928 0 3.307 1.396 3.307 3.361m-2.186 0c0-.83-.598-1.444-1.412-1.444s-1.412.615-1.412 1.443.598 1.444 1.412 1.444c.809 0 1.412-.615 1.412-1.444M77.241 12.728q-.001.284-.044.575h-4.685c.142.723.602 1.098 1.288 1.098.508 0 .931-.223 1.14-.592h2.163c-.444 1.44-1.72 2.339-3.303 2.339-1.968 0-3.427-1.438-3.427-3.365 0-1.934 1.447-3.357 3.427-3.357 2.033 0 3.442 1.451 3.442 3.302zm-4.69-.628h2.55c-.19-.63-.645-.97-1.301-.97-.634 0-1.074.343-1.25.97M84.891 15.942V7.356h-2.148v2.509a2.95 2.95 0 0 0-1.567-.439c-1.936 0-3.315 1.401-3.315 3.361s1.379 3.367 3.315 3.367c.735 0 1.39-.25 1.903-.681l.204.469zm-3.424-4.598c.813 0 1.412.62 1.412 1.444 0 .823-.599 1.443-1.412 1.443-.815 0-1.413-.62-1.413-1.444 0-.823.598-1.443 1.413-1.443M92.969 9.627v6.308h-1.68l-.186-.446a3 3 0 0 1-1.904.659c-1.929 0-3.308-1.407-3.308-3.367 0-1.953 1.38-3.344 3.308-3.344.743 0 1.407.248 1.927.677l.223-.487zm-2.067 3.159c0-.82-.598-1.434-1.412-1.434-.82 0-1.412.615-1.412 1.434 0 .823.598 1.438 1.412 1.438s1.412-.615 1.412-1.438M101 15.942V7.356h-2.149v2.509a2.95 2.95 0 0 0-1.567-.439c-1.936 0-3.314 1.401-3.314 3.361s1.378 3.367 3.314 3.367c.735 0 1.39-.25 1.903-.681l.205.469zm-3.425-4.598c.814 0 1.412.62 1.412 1.444 0 .823-.598 1.443-1.412 1.443s-1.413-.62-1.413-1.444c0-.823.599-1.443 1.413-1.443"
      clipRule="evenodd"
    />
    <path
      fill="#EF476F"
      fillRule="evenodd"
      d="M33.023 14.478a1.917 1.917 0 1 1 0-3.834 1.917 1.917 0 0 1 0 3.834m4.234-4.742-3.744-2.078a1.01 1.01 0 0 0-.98 0l-3.744 2.078a1.01 1.01 0 0 0 .78 1.851c-.087.31-.138.636-.138.974a3.591 3.591 0 1 0 7.046-.975q.143.043.289.044a1.01 1.01 0 0 0 .49-1.894"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPuntoPropiedadLogoColored;
