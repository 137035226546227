import { withoutEventPropagation } from 'design-system/utils/withoutEventPropagation';
import { useCallback } from 'react';
import { dispatch, dispatchMany } from 'reffects';
import Menu from 'design-system/components/Menu/Menu';
import {
  PLANS_PER_COUNTRY_REQUESTED,
  WASI_PLANS_REQUESTED,
} from '../../../../events';
import { SUBSCRIPTION_CHANGE_REQUEST_ACTION_REQUESTED } from '../Modals/RequestSubscriptionChangeModal/events';
import {
  CHANGE_REQUEST_STATUS,
  SUBSCRIPTION_CHANGE_TYPE,
} from '../../../../../constants/subscription';
import { OPEN_DIALOG } from '../../../../../../events/dialogs';
import { DISALLOWED_ACTION_MODAL_ID } from '../Modals/constants';

const REQUEST_PAID_MESSAGE =
  'Request change is not possible due to there is a product already paid but not activated yet. Wait until the product is activated to request another change';
const REQUEST_ACTIVATED_NOT_CONCILIATED_MESSAGE =
  'Last activated product is pending to be conciliated in BusinessCentral. Ask finance to conciliate it to be able to request another change.';

export function RequestSubscriptionChangeAction({ publisher }) {
  const {
    country: countryCode,
    publishesInThailandProperty,
    planType,
    subscriptionChangeRequests,
  } = publisher;
  const { status, type, paymentNotified } = subscriptionChangeRequests[0] ?? {};

  const showModal = useCallback(() => {
    dispatchMany([
      {
        id: PLANS_PER_COUNTRY_REQUESTED,
        payload: {
          countryCode,
          publishInThailandProperty: publishesInThailandProperty,
        },
      },
      {
        id: WASI_PLANS_REQUESTED,
        payload: {
          countryCode,
        },
      },
      {
        id: SUBSCRIPTION_CHANGE_REQUEST_ACTION_REQUESTED,
        payload: {
          publisherId: publisher.id,
          paymentMethod: publisher.paymentMethod,
        },
      },
    ]);
  }, [publisher]);
  const actionDisallowed = useCallback(
    (text) => () =>
      dispatch({
        id: OPEN_DIALOG,
        payload: {
          id: DISALLOWED_ACTION_MODAL_ID,
          parameters: {
            title: "Can't request change",
            text,
          },
        },
      }),
    []
  );

  const label =
    planType === 'free'
      ? 'Request new subscription'
      : 'Request change on subscription';
  let action = showModal;

  if (status === CHANGE_REQUEST_STATUS.PAID) {
    action = actionDisallowed(REQUEST_PAID_MESSAGE);
  } else if (status === CHANGE_REQUEST_STATUS.ACTIVATED && !paymentNotified) {
    action = actionDisallowed(REQUEST_ACTIVATED_NOT_CONCILIATED_MESSAGE);
  }

  if (
    status === CHANGE_REQUEST_STATUS.PAID &&
    type === SUBSCRIPTION_CHANGE_TYPE.UPSELL_NEXT_CYCLE &&
    planType !== 'free'
  ) {
    action = showModal;
  }

  return <Menu.Item label={label} onClick={withoutEventPropagation(action)} />;
}
