import { subscribe, useSelector } from 'reffects-store';
import { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import Section from 'design-system/components/Section';
import InputRow from 'design-system/components/InputRow';
import InputBase from 'design-system/components/InputBase/InputBase';
import TextField from '../../../partials/fields/TextField';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { FieldGroup } from './FieldGroup';
import HiddenField from '../../../partials/fields/HiddenField';
import TextareaField from '../../../partials/fields/TextareaField';
import { propertySupportedLanguagesSelector } from '../../../selectors/config';
import IconMultiLanguage from '../../../partials/IconMultiLanguage';
import { detailsOfPropertyTypeSelector } from '../selectors';
import EnergyCertificateSection from './EnergyCertificateSection';

const useMultiLangRequiredFields = (locales) => {
  const { watch } = useFormContext();

  return locales.map((locale) => {
    const [title, description] = watch([
      `title_${locale}`,
      `description_${locale}`,
    ]);

    return {
      locale,
      isRequired: Boolean(title) || Boolean(description),
    };
  });
};

const DescriptionField = styled(TextareaField)`
  ${InputBase.Box} {
    height: 270px;
  }
`;

function PropertyDescriptionGroup({
  propertySupportedLanguages,
  order,
  fieldGroupTitle,
  sectionTitle,
}) {
  const showMultiLanguageIcon = propertySupportedLanguages.length > 1;
  const { watch } = useFormContext();
  const [propertyType] = watch(['propertyType']);
  const details = useSelector((state) =>
    detailsOfPropertyTypeSelector(state, propertyType)
  );
  const multiLangFields = useMultiLangRequiredFields(
    propertySupportedLanguages
  );
  return (
    <FieldGroup id="property-description" title={fieldGroupTitle} order={order}>
      <Section title={sectionTitle}>
        <InputRow arrangement={[2]}>
          <TextField
            label={
              <FormattedMessageWithValidation id="newprop_ref_emptystate" />
            }
            name="referenceId"
            tip={
              <FormattedMessageWithValidation id="newprop_ref_description" />
            }
            data-tag="property_referenceId"
          />
        </InputRow>
      </Section>
      <Section
        title={
          <>
            <FormattedMessageWithValidation id="ad_form_section_property_description" />
            *
          </>
        }
      >
        {multiLangFields.map(({ locale, isRequired }) => (
          <Fragment key={locale}>
            <InputRow>
              <TextField
                label={
                  <>
                    <FormattedMessageWithValidation id="newprop_title_emptystate" />
                    {isRequired && '*'}
                  </>
                }
                name={`title_${locale}`}
                icon={
                  showMultiLanguageIcon && <IconMultiLanguage locale={locale} />
                }
                tip={
                  <FormattedMessageWithValidation id="newprop_title_description" />
                }
                data-tag="property_title"
                maxLength={150}
              />
            </InputRow>
            <InputRow>
              <DescriptionField
                label={
                  <>
                    <FormattedMessageWithValidation id="newprop_description_emptystate" />
                    {isRequired && '*'}
                  </>
                }
                name={`description_${locale}`}
                icon={
                  showMultiLanguageIcon && <IconMultiLanguage locale={locale} />
                }
                tip={
                  <FormattedMessageWithValidation id="newprop_description_description" />
                }
                maxLength={5000}
                data-tag="property_description"
              />
            </InputRow>
          </Fragment>
        ))}
      </Section>
      <HiddenField name="propertyDescriptionLanguages" />
      {details.includes('energyCertificate') && <EnergyCertificateSection />}
    </FieldGroup>
  );
}

export default subscribe(PropertyDescriptionGroup, (state) => ({
  propertySupportedLanguages: propertySupportedLanguagesSelector(state),
}));
