// originally extracted from https://stackoverflow.com/a/32623832
function getAbsolutePosition(domNode, doc = document) {
  let { offsetBase } = getAbsolutePosition;

  if (!offsetBase) {
    // eslint-disable-next-line no-multi-assign
    offsetBase = getAbsolutePosition.offsetBase = doc.createElement('div');
    offsetBase.style.cssText = 'position:absolute;left:0;top:0';
    doc.body.appendChild(offsetBase);
  }

  const boundingRect = domNode.getBoundingClientRect();
  const baseRect = offsetBase.getBoundingClientRect();
  const left = boundingRect.left - baseRect.left;
  const top = boundingRect.top - baseRect.top;
  const width = boundingRect.right - boundingRect.left;
  const height = boundingRect.bottom - boundingRect.top;

  return {
    left,
    top,
    width,
    height,
    right: left + width,
    bottom: top + height,
  };
}

export default getAbsolutePosition;
