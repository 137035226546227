import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import { debounce } from 'lodash';
import Autocomplete from 'design-system/components/Autocomplete/Autocomplete';
import Icon from 'design-system/components/Icon';
import SearchField from './SearchField';
import useInternationalization from '../../../../hooks/useInternationalization';
import { PROPERTIES_SUGGESTIONS_SEARCHED } from './events';
import QueryOption from './QueryOption';
import { propertiesSuggestionsSelector } from './selectors';
import { DEFAULT_AUTOCOMPLETE_DEBOUNCE } from '../../../../constants';

const iconMap = new Map([
  ['referenceId', 'tag'],
  ['title', 'textFormat'],
  ['address', 'dropPin'],
  ['projectName', 'building'],
]);

const EMPTY_TEXT = '';

const fetchPropertiesSuggestions = (q, filters) =>
  dispatch({
    id: PROPERTIES_SUGGESTIONS_SEARCHED,
    payload: {
      q,
      ...filters,
    },
  });

const fetchPropertiesSuggestionsDebounced = debounce(
  fetchPropertiesSuggestions,
  DEFAULT_AUTOCOMPLETE_DEBOUNCE
);

function PropertiesSuggestions({
  query,
  filters,
  onQueryChange,
  onSuggestionSelected,
  onClearSelection,
}) {
  const intl = useInternationalization();
  const suggestions = useSelector(propertiesSuggestionsSelector);

  return (
    <Autocomplete
      menuWrapperDataTag="search_suggestions"
      value={query}
      onValueChange={(q) => {
        onQueryChange(q);
        fetchPropertiesSuggestionsDebounced(q, filters);
      }}
      onOptionSelected={onSuggestionSelected}
      options={suggestions}
      optionToString={(option) => (option === undefined ? '' : option.text)}
      renderInput={({ value, getInputProps, onClear }) => (
        <SearchField
          {...getInputProps()}
          value={value || ''}
          placeholder={intl.formatMessageWithValidation({
            id: 'properties_list_full_text_search',
          })}
          onRemove={() => {
            onClear();
            onClearSelection();
            onQueryChange(EMPTY_TEXT);
          }}
        />
      )}
      renderOptions={({ options, getOptionProps }) =>
        options.map((option, index) => {
          if (option.type === 'query') {
            return (
              <QueryOption
                key="query"
                query={query}
                {...getOptionProps({
                  index,
                  option,
                  refKey: 'innerRef',
                })}
              />
            );
          }

          return (
            <Autocomplete.Option
              key={`${option.type}_${option.id}`}
              title={option.text}
              subtitle={option.address}
              icon={<Icon glyph={iconMap.get(option.type)} />}
              {...getOptionProps({
                index,
                option,
                refKey: 'innerRef',
              })}
            />
          );
        })
      }
    />
  );
}

export default PropertiesSuggestions;
