import { useSelector } from 'reffects-store';
import { useMount } from 'react-use';
import { dispatch } from 'reffects';

import { COMPANY_CONTACTS_DATA_REQUESTED } from './events';
import { availableContactTypesSelector } from './selectors';

import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

import Section from '../Section';
import ContactDetailsTable from './ContactDetailsTable';
import EditDetailDialog from './EditDetailDialog';

function ContactDetailsSection() {
  useMount(() => {
    dispatch(COMPANY_CONTACTS_DATA_REQUESTED);
  });
  const availableContactTypes = useSelector(availableContactTypesSelector);
  if (availableContactTypes.length === 0) {
    return null;
  }

  return (
    <>
      <Section
        title={
          <FormattedMessageWithValidation id="hover_bulk_contact_detail_title" />
        }
      >
        {availableContactTypes.map((contactType) => (
          <ContactDetailsTable key={contactType} contactType={contactType} />
        ))}
      </Section>
      <EditDetailDialog />
    </>
  );
}

export default ContactDetailsSection;
