import { Body1 } from 'design-system/components/Typography/presets/Body1';
import styled from 'styled-components';
import { dispatch } from 'reffects';
import DropdownMenu from 'design-system/components/DropdownMenu';
import Menu from 'design-system/components/Menu/Menu';
import Icon from 'design-system/components/Icon';
import Button, {
  BUTTON_SIZE,
  BUTTON_VARIANT,
} from 'design-system/components/Button';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import {
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import {
  DOWNLOAD_ENQUIRIES_CSV_REQUESTED,
  DOWNLOAD_ENQUIRIES_EXCEL_REQUESTED,
} from './events';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import useTracking from '../../../../hooks/useTracking';
import { useFilters } from '../../../../partials/DataTable/hooks/useFilter';

const DropdownButton = styled(Button)`
  ${Button.mods.variant(BUTTON_VARIANT.OUTLINE)}
  ${Button.mods.size(BUTTON_SIZE.BASE)}
`;
const IconResized = styled(Icon)`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  ${color.text(COLOR_PALETTE.PUMPKIN_BASE)};
`;
const Label = styled.div`
  display: flex;
  width: 100%;
  ${spacing.inset(SPACING_SIZE.M)};
  gap: ${spacing.value(SPACING_SIZE.S)};
  ${color.text(COLOR_PALETTE.NEUTRAL_BASE)};
  ${typography.size(FONT_SIZE.M)} ${typography.weight(FONT_WEIGHT.NORMAL)};
  ${typography.lineHeight(20)} ${typography.font(FONT_FAMILY.PRIMARY)};
  align-items: center;
  ${color.background(COLOR_PALETTE.PUMPKIN_A10)}
  margin-bottom: -8px;
`;

function ExportOptions() {
  const ref = useTracking({
    trackClick: {
      eventName: 'export-leads-data',
    },
    trackImpression: {
      elementName: 'export-leads-btn',
    },
    sectionName: 'leads-table',
  });
  const { filters } = useFilters();
  return (
    <DropdownMenu
      button={
        <DropdownButton icon={<Icon glyph="download" />} ref={ref}>
          <FormattedMessageWithValidation id="excel_button_export_data" />
        </DropdownButton>
      }
      onConfirm={({ value }) => dispatch({ id: value, payload: { filters } })}
    >
      <Menu>
        <Menu.Item
          id="download-excel"
          value={DOWNLOAD_ENQUIRIES_EXCEL_REQUESTED}
          label={
            <Body1 $noWrap>
              <FormattedMessageWithValidation id="excel_button_download_excel" />
            </Body1>
          }
        />
        <Menu.Item
          id="download-csv"
          value={DOWNLOAD_ENQUIRIES_CSV_REQUESTED}
          label={
            <Body1 $noWrap>
              <FormattedMessageWithValidation id="excel_button_download_csv" />
            </Body1>
          }
        />
        {isStartDateMoreThanOneYear(filters) && (
          <Label>
            <IconResized glyph="info" />
            <FormattedMessageWithValidation id="excel_button_download_only_last_year" />
          </Label>
        )}
      </Menu>
    </DropdownMenu>
  );
}
function isStartDateMoreThanOneYear(filters) {
  if (
    filters.lastActivityDateRange &&
    filters.lastActivityDateRange.startDate
  ) {
    const startDate = new Date(filters.lastActivityDateRange.startDate);
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
    return startDate < oneYearAgo;
  }
  return true;
}
export default ExportOptions;
