import styled from 'styled-components';
import SkeletonBone from 'design-system/components/SkeletonBone';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import { typography } from 'design-system/styles/typography';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Datum from './Datum';

const Wrapper = styled.div`
  ${Heading1} {
    display: inline-block;
    ${typography.lineHeight(24)};
    ${spacing.stack(SPACING_SIZE.M, false)}
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ContactData = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  ${({ $width }) => `max-width: ${$width}px`};

  ${SkeletonBone} {
    ${spacing.stack(SPACING_SIZE.S)}
  }
`;

function Skeleton({ qr, $width }) {
  return (
    <Wrapper>
      <Heading1>
        <SkeletonBone $width="209px" $height="22px" />
      </Heading1>
      <Content>
        <ContactData $width={$width}>
          <SkeletonBone $width="183px" $height="14px" />
          <SkeletonBone $width="120px" $height="14px" />
          <SkeletonBone $width="146px" $height="14px" />
          <SkeletonBone $width="154px" $height="14px" />
        </ContactData>
        <div>{qr}</div>
      </Content>
    </Wrapper>
  );
}

function ContactCard({ name, qr, children, $width }) {
  return (
    <Wrapper>
      <Heading1>{name}</Heading1>
      <Content>
        <ContactData $width={$width}>{children}</ContactData>
        <div>{qr}</div>
      </Content>
    </Wrapper>
  );
}

ContactCard.Datum = Datum;
ContactCard.Skeleton = Skeleton;

export default ContactCard;
