import { useFormContext } from 'react-hook-form';
import { FieldGroup } from '../../partials/FieldGroup';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import AreaInformationSection from '../../partials/AreaInformationSection';
import DetailsSection from '../../partials/DetailsSection';

export function PropertyDetailsGroup({ order }) {
  const { watch } = useFormContext();
  const [propertyType] = watch(['propertyType']);

  if (!propertyType) return null;

  return (
    <FieldGroup
      id="property-detail"
      title={
        <FormattedMessageWithValidation id="ad_form_group_property_detail" />
      }
      order={order}
    >
      <AreaInformationSection />
      <DetailsSection />
    </FieldGroup>
  );
}
