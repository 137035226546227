import { dispatch, dispatchMany } from 'reffects';
import { useSelector } from 'reffects-store';
import { useCallback } from 'react';
import Autocomplete from 'design-system/components/Autocomplete/Autocomplete';
import InputText from 'design-system/components/InputText';
import Icon from 'design-system/components/Icon';
import {
  DEAL_REMOVED,
  DEAL_SELECTED,
  DEAL_TEXT_INTRODUCED,
  DEALS_FILTERED,
} from './events';
import { dealsSelector } from './selectors';

export default function DealsAutocomplete({ stateRegion, displayableValue }) {
  const options = useSelector(dealsSelector);

  const onClear = useCallback(
    () =>
      dispatchMany([
        {
          id: DEALS_FILTERED,
          payload: { filterText: undefined },
        },
        {
          id: DEAL_REMOVED,
          payload: { stateRegion },
        },
      ]),
    [stateRegion]
  );

  const onQueryChange = useCallback(
    (inputValue) => {
      dispatchMany([
        {
          id: DEALS_FILTERED,
          payload: { filterText: inputValue },
        },
        {
          id: DEAL_TEXT_INTRODUCED,
          payload: { text: inputValue, stateRegion },
        },
      ]);
    },
    [stateRegion]
  );

  return (
    <Autocomplete
      value={displayableValue}
      options={options}
      onValueChange={onQueryChange}
      onOptionSelected={(data) =>
        dispatch({
          id: DEAL_SELECTED,
          payload: { deal: data, stateRegion },
        })
      }
      optionToString={(option) => (option === undefined ? '' : option.name)}
      isLoading={false}
      minCharactersToOpen={4}
      renderInput={({ value, getInputProps, onClear: onClearInput }) => (
        <InputText
          required
          label="Deal name"
          icon={<Icon glyph="cross" />}
          onIconClick={() => {
            onClearInput();
            onClear();
          }}
          {...getInputProps({ value, autoComplete: 'off' })}
        />
      )}
      renderOptions={({ options: publisherOptions, getOptionProps }) =>
        publisherOptions.map((option, index) => (
          <Autocomplete.Option
            key={option.id}
            title={option.name}
            subtitle={option.id}
            icon={<Icon glyph="building" />}
            {...getOptionProps({
              index,
              option,
            })}
          />
        ))
      }
      emptyStateMessage="No options"
      loadingStateMessage="Loading..."
    />
  );
}
