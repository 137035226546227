import { COUNTRY_CODE } from '../../../constants/country';

export const importCsvFieldGroupId = 'importCsv';
export const selectHeadersFieldGroupId = 'selectHeaders';

const selectOperationType = 'selectOperationType';
const selectPropertyType = 'selectPropertyType';
const selectAmenities = 'selectAmenities';
const selectRules = 'selectRules';
const selectStratum = 'selectStratum';
const selectNearbyLocations = 'selectNearbyLocations';
const selectFurnished = 'selectFurnished';
const selectCondition = 'selectCondition';

export const UPLOAD_CSV_MODAL = 'UPLOAD_CSV_MODAL';

export const FINAL_STEP_ORDER = 20;

export const GROUP_CHILDRENS = [
  {
    id: selectOperationType,
    title: 'upload_csv_field_operation_type',
    order: 4,
    fieldName: 'operationType',
    required: true,
  },
  {
    id: selectPropertyType,
    title: 'upload_csv_field_property_type',
    order: 5,
    fieldName: 'propertyType',
    required: true,
  },
  {
    id: selectAmenities,
    title: 'upload_csv_value_mapping_amenities',
    order: 6,
    fieldName: 'amenities',
    required: false,
  },
  {
    id: selectRules,
    title: 'upload_csv_value_mapping_rules',
    order: 7,
    fieldName: 'rules',
    required: false,
  },
  {
    id: selectStratum,
    title: 'upload_csv_value_mapping_stratum',
    order: 8,
    fieldName: 'stratum',
    required: false,
  },
  {
    id: selectNearbyLocations,
    title: 'upload_csv_value_mapping_near_by_locations',
    order: 9,
    fieldName: 'nearbyLocations',
    required: false,
  },
  {
    id: selectFurnished,
    title: 'upload_csv_value_mapping_furnished',
    order: 10,
    fieldName: 'furnished',
    required: false,
  },
  {
    id: selectCondition,
    title: 'upload_csv_value_mapping_condition',
    order: 11,
    fieldName: 'condition',
    required: false,
  },
];

export const GROUPS = [
  { id: importCsvFieldGroupId, title: 'Importar CSV', order: 1 },
  {
    id: selectHeadersFieldGroupId,
    title: 'upload_csv_select_columns',
    order: 2,
  },
  {
    id: 'selectValues',
    title: 'upload_csv_select_values',
    order: 3,
    children: GROUP_CHILDRENS,
  },
  {
    id: 'final',
    title: 'upload_csv_final_step_title',
    order: FINAL_STEP_ORDER,
    isBelowSpecialBullet: true,
  },
];

export const STEP_BY_ID = {
  [selectOperationType]: 4,
  [selectPropertyType]: 5,
  [selectAmenities]: 6,
  [selectRules]: 7,
  [selectStratum]: 8,
  [selectNearbyLocations]: 9,
  [selectFurnished]: 10,
  [selectCondition]: 11,
};

export const EMPTY_ERRORS_STATE = {
  [importCsvFieldGroupId]: [],
  [selectHeadersFieldGroupId]: [],
  selectValues: [],
  [selectOperationType]: [],
  [selectPropertyType]: [],
  [selectAmenities]: [],
  [selectRules]: [],
  [selectStratum]: [],
  [selectNearbyLocations]: [],
  [selectFurnished]: [],
  [selectCondition]: [],
  final: [],
};

export const HEADER_ID_BY_STEP = {
  1: importCsvFieldGroupId,
  2: selectHeadersFieldGroupId,
  3: 'selectValues',
  4: selectOperationType,
  5: selectPropertyType,
  6: selectAmenities,
  7: selectRules,
  8: selectStratum,
  9: selectNearbyLocations,
  10: selectFurnished,
  11: selectCondition,
  [FINAL_STEP_ORDER]: 'final',
};

export const VALID_COUNTRIES = [COUNTRY_CODE.COLOMBIA, COUNTRY_CODE.MEXICO];

export const HEADERS_SCHEMA = {
  column1: {
    key: 'proppitColumns',
    translationId: 'upload_csv_column_proppit',
    width: '30',
  },
  column2: {
    key: 'yourColumns',
    translationId: 'upload_csv_column_your_values',
    width: '50',
  },
  column3: {
    key: 'type',
    translationId: 'upload_csv_column_type',
    width: '10',
  },
};

export const VALUES_SCHEMA = {
  column1: {
    key: 'proppitValues',
    translationId: 'upload_csv_values_your_values',
    width: '30',
  },
  column2: {
    key: 'yourValues',
    translationId: 'upload_csv_values_proppit',
    width: '50',
  },
};
