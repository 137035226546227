import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';

const Block = styled.div`
  ${spacing.stack(SPACING_SIZE.L)}
`;

export default function Header({ children, ...props }) {
  return (
    <Block {...props}>
      <Heading1>{children}</Heading1>
    </Block>
  );
}
