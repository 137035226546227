import { useMount } from 'react-use';
import { dispatchMany } from 'reffects';
import { useSelector } from 'reffects-store';
import { DEFAULT_CONTACT_REQUESTED } from '../events';
import { CREATE_UNIT_FORM_MOUNTED, PROJECTS_REQUESTED } from './events';
import UnitForm from './UnitForm';
import { isCreateUnitFormInitializedSelector } from './selectors';

function CreateUnitForm() {
  useMount(() => {
    dispatchMany([
      CREATE_UNIT_FORM_MOUNTED,
      DEFAULT_CONTACT_REQUESTED,
      PROJECTS_REQUESTED,
    ]);
  });

  const isAdFormInitialized = useSelector(isCreateUnitFormInitializedSelector);

  return isAdFormInitialized && <UnitForm />;
}

export default CreateUnitForm;
