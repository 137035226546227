import { Parser } from 'json2csv';

function parseJsonToCsv(data) {
  const dataForParser = data.map((lead) =>
    lead.reduce((acc, { header, value }) => {
      acc[header] = value;
      return acc;
    }, {})
  );
  const fields = Object.keys(dataForParser[0]);
  const options = {
    delimiter: ';',
    encoding: 'utf8',
    withBOM: true,
    fields,
  };
  const parser = new Parser(options);
  return parser.parse(dataForParser);
}

function downloadCsv(content, filename) {
  const type = 'text/csv';
  const blob = new Blob(['', content], { type });
  const dataURI = `data:${type};charset=utf-8,${content}`;
  const URL = window.URL || window.webkitURL;
  const url =
    typeof URL.createObjectURL === 'undefined'
      ? dataURI
      : URL.createObjectURL(blob);
  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.download = filename;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

export { downloadCsv, parseJsonToCsv };
