export function sendWasiPropertiesButtonDisabled(state) {
  return state?.wasiApiPublisher?.pendingToSynchronize > 0;
}

export function getWasiApiPublisher(state) {
  return state?.wasiApiPublisher;
}

export function getDisableForm(state) {
  return state?.wasiApiPublisher?.disableForm;
}
