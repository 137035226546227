import React from 'react';
import { isObject } from 'lodash';
import Table from 'design-system/components/Table';
import { useColumn } from '../../hooks/useColumn';
import { MediaLink } from '../../../MediaLink';

const MediaLinkColumn = React.forwardRef(({ glyph, text, ...props }, ref) => {
  const { value } = useColumn(props);

  return isObject(value) ? (
    <Table.Cell />
  ) : (
    <Table.Cell>
      <div ref={ref}>
        <MediaLink href={value} glyph={glyph}>
          {text}
        </MediaLink>
      </div>
    </Table.Cell>
  );
});

export { MediaLinkColumn };
