export const DECIMAL_SEPARATOR = 'decimal';
export const THOUSAND_SEPARATOR = 'group';

export function localeFromNavigatorLanguage(navigatorLanguage) {
  const fallbacks = {
    es: 'es-ES',
    en: 'en-GB',
    'es-419': 'es-CL',
    vi: 'vi-VN',
    th: 'th-TH',
    fil: 'en-PH',
  };

  function isSupportedLanguage(lang) {
    return Boolean(fallbacks[lang]);
  }

  function notALocale(navigatorLang) {
    return !(navigatorLang.indexOf('-') > -1);
  }

  if (isSupportedLanguage(navigatorLanguage)) {
    return fallbacks[navigatorLanguage];
  }

  if (notALocale(navigatorLanguage)) {
    return fallbacks.en;
  }

  const [language, country] = navigatorLanguage.split('-');

  return `${language}-${country.toUpperCase()}`;
}

export function getNumberSeparator(locale, separatorType) {
  return Intl.NumberFormat(locale)
    .formatToParts(10000.1)
    .find((element) => element.type === separatorType).value;
}

export function convertToNumber(locale, number) {
  const numberAsString = String(number);
  const numberWithoutThousandSeparator = removeThousandSeparator(
    locale,
    numberAsString
  );
  return Number(
    usePointAsDecimalSeparator(locale, numberWithoutThousandSeparator)
  );
}

function removeThousandSeparator(locale, numberAsString) {
  const thousandSeparator = getNumberSeparator(locale, THOUSAND_SEPARATOR);
  const thousandSeparatorRegEx = RegExp(`\\${thousandSeparator}`, 'g');
  return numberAsString.replace(thousandSeparatorRegEx, '');
}

function usePointAsDecimalSeparator(locale, numberAsString) {
  const decimalSeparator = getNumberSeparator(locale, DECIMAL_SEPARATOR);
  const decimalSeparatorRegEx = RegExp(`\\${decimalSeparator}`, 'g');
  return numberAsString.replace(decimalSeparatorRegEx, '.');
}
