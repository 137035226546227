export const MX_PLAN_OFFERS = [
  {
    planId: '01932a1f-2ab6-7302-b0bf-89c5e712bad6',
    offerName: 'b1-s1-m1',
  },
  {
    planId: '019320ed-afd7-7ed8-af59-3ffde6df8dd4',
    offerName: 'b10-s5-m1',
  },
  {
    planId: '01932a1f-81ff-72f3-b487-49237a8c096a',
    offerName: 'b10-s5-m3',
  },
];
