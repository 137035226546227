import * as React from "react";
const SvgStar = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={45}
    height={33}
    fill="none"
    {...props}
  >
    <path
      fill="#E0E0E0"
      d="M21.96 5.876a1 1 0 0 1 1.713 0l4.191 6.964a1 1 0 0 0 .631.459l7.919 1.834a1 1 0 0 1 .53 1.63L31.615 22.9a1 1 0 0 0-.241.742l.703 8.098a1 1 0 0 1-1.386 1.007l-7.485-3.17a1 1 0 0 0-.78 0l-7.485 3.17a1 1 0 0 1-1.386-1.007l.703-8.098a1 1 0 0 0-.241-.742l-5.329-6.138a1 1 0 0 1 .53-1.63l7.919-1.834a1 1 0 0 0 .63-.459z"
    />
    <path
      fill="#E0E0E0"
      d="m22.816 15.452 1.646 2.735 3.11.72-2.093 2.41.276 3.18-2.94-1.245-2.938 1.245.276-3.18-2.092-2.41 3.11-.72z"
    />
    <path
      fill="#E0E0E0"
      d="m22.816 15.452 1.646 2.735 3.11.72-2.093 2.41.276 3.18-2.94-1.245-2.938 1.245.276-3.18-2.092-2.41 3.11-.72z"
    />
    <path
      fill="#E0E0E0"
      d="m22.816 15.452 1.646 2.735 3.11.72-2.093 2.41.276 3.18-2.94-1.245-2.938 1.245.276-3.18-2.092-2.41 3.11-.72z"
    />
    <path
      fill="#E0E0E0"
      d="m22.816 15.452 1.646 2.735 3.11.72-2.093 2.41.276 3.18-2.94-1.245-2.938 1.245.276-3.18-2.092-2.41 3.11-.72z"
    />
    <path
      fill="#E0E0E0"
      d="m22.816 15.452 1.646 2.735 3.11.72-2.093 2.41.276 3.18-2.94-1.245-2.938 1.245.276-3.18-2.092-2.41 3.11-.72z"
    />
  </svg>
);
export default SvgStar;
