import styled, { css } from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Divider from './Divider';

import Item from './Item';
import BaseItem from './BaseItem';
import Highlight from './Highlight';

const Wrapper = styled.div`
  ${spacing.insetSandwich(SPACING_SIZE.S)}
`;

const modIsSubmenu = () => css`
  ${spacing.insetSandwich(SPACING_SIZE.NONE)}
`;

function BaseMenu({ children, ...rest }) {
  return <Wrapper {...rest}>{children}</Wrapper>;
}

BaseMenu.mods = {
  isSubmenu: modIsSubmenu,
};

BaseMenu.BaseItem = BaseItem;
BaseMenu.Divider = Divider;
BaseMenu.Item = Item;
BaseMenu.Highlight = Highlight;

export default styled(BaseMenu)``;
