import Table from 'design-system/components/Table';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { useColumn } from '../../../../../../partials/DataTable/hooks/useColumn';
import { countryNameByCountryCode } from '../../../../../../utils/countries';

export default function CountryColumn(props) {
  const { value } = useColumn(props);
  if (!value) {
    return null;
  }

  return (
    <Table.Cell>
      <Body1>{countryNameByCountryCode(value)}</Body1>
    </Table.Cell>
  );
}
