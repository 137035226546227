import { operationTypesByCountrySelector } from '../../../selectors/country';
import {
  OPERATION_TYPE_RENT,
  OPERATION_TYPE_SELL,
  OPERATION_TYPE_SELL_AND_RENT,
} from '../../../constants/ad';
import { propertyTypesWithTranslationTagsSelector } from '../../../selectors/config';

const operationTypeCaptions = new Map([
  [OPERATION_TYPE_SELL, 'properties_list_filter_operation_type_sell'],
  [OPERATION_TYPE_RENT, 'properties_list_filter_operation_type_rent'],
  [
    OPERATION_TYPE_SELL_AND_RENT,
    'properties_list_filter_operation_type_sell_and_rent',
  ],
]);

export function operationTypeFilterOptionsSelector(state) {
  const options = operationTypesByCountrySelector(state).map(
    (operationType) => ({
      id: operationType,
      value: operationType,
      tag: operationTypeCaptions.get(operationType),
    })
  );
  return [
    {
      id: 'all',
      value: null,
      tag: 'filter_properties_status_all',
    },
    ...options,
  ];
}

export function propertyTypeFilterOptionsSelector(state) {
  const options = Object.entries(
    propertyTypesWithTranslationTagsSelector(state)
  ).map(([id, tag]) => ({ id, value: id, tag }));
  return [
    {
      id: 'all',
      value: null,
      tag: 'properties_list_filter_operation_type_all',
    },
    ...options,
  ];
}
