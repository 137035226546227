import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { useFormContext } from 'react-hook-form';
import Switch from 'design-system/components/Switch';

const Wrapper = styled.label`
  display: flex;
  flex-direction: row;
  background: none;

  ${Switch} {
    ${spacing.inline(SPACING_SIZE.M)}
  }
`;

function SwitchField({
  id,
  name,
  disabled,
  defaultChecked,
  children,
  ...rest
}) {
  const { register } = useFormContext();
  return (
    <Wrapper {...rest}>
      <Switch
        id={id}
        disabled={disabled}
        defaultChecked={defaultChecked}
        {...register(name)}
      />
      <Body1>{children}</Body1>
    </Wrapper>
  );
}

export default styled(SwitchField)``;
