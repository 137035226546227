import styled from 'styled-components';

function IndeterminateIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={className}
    >
      <path fill="currentColor" d="M4 7H12V9H4z" />
    </svg>
  );
}

export default styled(IndeterminateIcon)``;
