import styled from 'styled-components';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import DropdownMenu from 'design-system/components/DropdownMenu';
import { DROPDOWN_POSITION } from 'design-system/components/Dropdown';
import Menu from 'design-system/components/Menu/Menu';
import { OutlineButton } from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { UNITS_STATUS_FILTER_OPTION_PUBLISHED } from './constants';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { UNITS_FILTERED_BY_STATUS } from './events';
import { unitStatusFilterLabelSelector } from './selectors';

const options = [
  {
    value: UNITS_STATUS_FILTER_OPTION_PUBLISHED,
    label: 'filter_units_status_published',
  },
  {
    value: 'unpublished',
    label: 'filter_units_status_not_published',
  },
];

const NestedMenuItem = styled(Menu.Item)`
  ::before {
    content: '';
    width: 12px;
    height: 1px;
    ${color.background(COLOR_PALETTE.NEUTRAL_A10)}
    ${spacing.inline(SPACING_SIZE.S)}
  }
`;

function StatusFilter() {
  const selectedOptionLabel = useSelector((state) =>
    unitStatusFilterLabelSelector(state, options)
  );

  return (
    <DropdownMenu
      position={DROPDOWN_POSITION.BOTTOM_LEFT}
      active={selectedOptionLabel !== 'filter_units_status_default'}
      button={
        <OutlineButton icon={<Icon glyph="chevronDown" />}>
          <FormattedMessageWithValidation id={selectedOptionLabel} />
        </OutlineButton>
      }
      onConfirm={({ value }) =>
        dispatch({
          id: UNITS_FILTERED_BY_STATUS,
          payload: { value },
        })
      }
    >
      <Menu>
        <Menu.Item
          id="no_filter_by_status"
          label={
            <Body1 $noWrap>
              <FormattedMessageWithValidation id="filter_units_status_all" />
            </Body1>
          }
        />
        {options.map(({ label, value, nested }) => {
          const props = {
            value,
            key: value,
            id: value,
            label: (
              <Body1 $noWrap>
                <FormattedMessageWithValidation id={label} />
              </Body1>
            ),
          };

          return nested ? (
            <NestedMenuItem {...props} />
          ) : (
            <Menu.Item {...props} />
          );
        })}
      </Menu>
    </DropdownMenu>
  );
}

export default StatusFilter;
