import { publisherSelector } from '../../../../../selectors/publisher';

export function paymentGatewayIframeSrcSelector(state) {
  return state['profile:paymentsUrl'];
}

export function paymentMethodIsAvailableSelector(state, paymentMethod) {
  const availableMethods = state.checkoutPage?.availablePaymentMethods ?? [];
  return availableMethods.some(({ id }) => id === paymentMethod);
}

export function selectedPaymentMethodSelector(state) {
  return state.selectedPaymentMethod;
}

export function bankTransferReceiptSelector(state) {
  return state.bankTransferReceipt;
}

export function bankTransferReceiptFileSizeLimitExceededErrorSelector(state) {
  return state.bankTransferReceiptFileSizeLimitExceededError ?? false;
}

export function termsAndConditionsAcceptedSelector(state) {
  return Boolean(state.termsAndConditionsAccepted);
}

export function canSubmitFormSelector(state) {
  if (!termsAndConditionsAcceptedSelector(state)) {
    return false;
  }
  const paymentMethod = selectedPaymentMethodSelector(state);
  if (!paymentMethod) {
    return false;
  }
  if (paymentMethod === 'bank-transfer') {
    return Boolean(bankTransferReceiptSelector(state));
  }
  return true;
}

export function showBackButtonSelector(state) {
  return Boolean(state.showBackButton);
}

export function formSubmittingSelector(state) {
  return Boolean(state.form?.submitting);
}

export function bankTransferConceptSelector(state) {
  const publisher = publisherSelector(state);
  if (!publisher?.billingEntity) {
    return null;
  }
  const {
    billingEntity: { billRequired, legalName },
    name,
  } = publisher;
  return billRequired ? legalName : name;
}
