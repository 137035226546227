import styled from 'styled-components';
import { divider, DIVIDER_SIZE } from 'design-system/styles/divider';
import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import { useParams } from 'react-router-dom';
import { useMount } from 'react-use';
import GlobalizaLogo from 'design-system/components/GlobalizaLogo';
import LogoDotPropertyGroup from 'design-system/components/LogoDotPropertyGroup';
import LogoLamudi from 'design-system/components/LogoLamudi';
import Anchor from 'design-system/components/Anchor';
import Logo from 'design-system/components/Logo/Logo';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import { Subtitle1 } from 'design-system/components/Typography/presets/Subtitle1';
import {
  isLoadingLinksSelector,
  propertyLinksHaveExpiredSelector,
  propertyLinksSelector,
} from './selectors';
import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { PROPERTY_LINKS_REQUESTED } from './events';
import { BrandLogoForAnonymousUsers } from '../../partials/BrandLogo';
import { BRANDS } from '../../constants';

const Wrapper = styled.div`
  ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
  ${spacing.inset(SPACING_SIZE.XXXL)}
  display: flex;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
`;

const Content = styled.div`
  ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
  width: 600px;
  height: fit-content;
  ${spacing.inset(SPACING_SIZE.XL)}
  ${Logo}, ${LogoDotPropertyGroup}, ${LogoLamudi}, ${GlobalizaLogo} {
    display: flex;
    margin: 0 auto;
    ${spacing.stack(SPACING_SIZE.XL, false)}
  }
  ${Heading1}, ${Subtitle1} {
    display: block;
    text-align: center;
    ${spacing.stack(SPACING_SIZE.M, false)}
  }
`;

const Links = styled.ul`
  margin: 0;
  padding: 0;
`;
const SiteLink = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  ${divider.bottom(DIVIDER_SIZE.THIN)}
  ${spacing.insetSandwich(SPACING_SIZE.S)}
  ${Icon} {
    ${Icon.mods.size(ICON_SIZE.XSMALL)}
  }
  > * {
    ${spacing.inline(SPACING_SIZE.M)}
  }
`;

const siteToIconNameMap = {
  nuroa: 'nuroa',
  trovit: 'trovit',
  mitula: 'mitula',
  nestoria: 'nestoria',
  laencontre: 'laencontre',
  icasas: 'icasas',
  dotproperty: 'dotProperty',
  puntopropiedad: 'puntoPropiedad',
  hipflat: 'hipFlat',
  [BRANDS.THAILAND_PROPERTY]: 'thailandProperty',
  properati: 'properati',
  lamudi: 'lamudi',
};

export function PropertyLinks() {
  const { adId } = useParams();
  useMount(() => dispatch({ id: PROPERTY_LINKS_REQUESTED, payload: { adId } }));
  const isLoading = useSelector(isLoadingLinksSelector);
  const propertyLinks = useSelector(propertyLinksSelector);
  const expired = useSelector(propertyLinksHaveExpiredSelector);
  return isLoading ? null : (
    <Wrapper>
      <Content>
        <BrandLogoForAnonymousUsers />
        <Heading1>
          {expired ? (
            <FormattedMessageWithValidation id="property_links_expired_header" />
          ) : (
            <FormattedMessageWithValidation id="property_links_header" />
          )}
        </Heading1>
        {expired ? (
          <Subtitle1>
            <FormattedMessageWithValidation id="property_links_expired_body" />
          </Subtitle1>
        ) : (
          <Links>
            {propertyLinks.map(({ site, link }) => (
              <SiteLink key={site}>
                <Icon glyph={siteToIconNameMap[site.toLowerCase()]} />
                <Anchor href={link} target="_blank">
                  {link}
                </Anchor>
              </SiteLink>
            ))}
          </Links>
        )}
      </Content>
    </Wrapper>
  );
}
