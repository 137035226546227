import { Link } from 'react-router-dom';
import { useCallback, useState } from 'react';
import Tip from 'design-system/components/Tip';
import Anchor from 'design-system/components/Anchor';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { newUnitForm } from '../../../../utils/proppitWebRouter';

function StatisticsDisclaimer() {
  const [linkElement, setLinkElement] = useState(null);
  const ref = useCallback((node) => {
    if (node != null && linkElement !== node) setLinkElement(node);
  }, []);

  return (
    <Tip>
      <Body2>
        <FormattedMessageWithValidation id="overview_key_results_publish_first_unit_banner_text" />{' '}
        <Anchor as={Link} to={newUnitForm()} ref={ref}>
          <FormattedMessageWithValidation id="overview_key_results_publish_first_unit_banner_link_text" />
        </Anchor>
      </Body2>
    </Tip>
  );
}

export default StatisticsDisclaimer;
