import { FormattedTime } from 'react-intl';
import CustomFormattedDate from './CustomFormattedDate';

function CustomFormattedDateTime({ value }) {
  return (
    <>
      <CustomFormattedDate value={value} />{' '}
      <FormattedTime value={value} timeStyle="short" />
    </>
  );
}

export default CustomFormattedDateTime;
