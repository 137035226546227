export function createDefaultDateRange(now) {
  return lastMonth(now);
}

function lastMonth(now) {
  const aMonthAgo = new Date(now);
  aMonthAgo.setDate(aMonthAgo.getDate() - 30);
  return buildPeriod(aMonthAgo, now, 'overview_date_month_last');
}

function buildPeriod(startDate, endDate, translation) {
  return {
    translation,
    period: {
      startDate,
      endDate,
    },
  };
}
