import { Body1 } from 'design-system/components/Typography/presets/Body1';
import Table from 'design-system/components/Table';
import { useColumn } from '../../../../partials/DataTable/hooks/useColumn';
import CustomFormattedDate from '../../../../partials/FormattedDate/CustomFormattedDate';

export function BillingDateColumn(props) {
  const { value: date } = useColumn(props);

  return (
    <Table.Cell>
      <Body1 $noWrap>
        <CustomFormattedDate value={new Date(date)} timeZone="utc" />
      </Body1>
    </Table.Cell>
  );
}
