import * as yup from 'yup';

import TextField from '../../../../partials/fields/TextField';

import GenericDetailForm from './GenericDetailForm';
import useInternationalization from '../../../../hooks/useInternationalization';
import { EMAIL_VALIDATION_REGEX } from '../../../../constants';

function EmailDetailForm({ detail, onSubmit, onCancel }) {
  const intl = useInternationalization();

  const handleFormSubmit = ({ contactEmail }) => onSubmit(contactEmail);

  return (
    <GenericDetailForm
      defaultValues={{
        contactEmail: detail.value,
      }}
      validationSchema={{
        contactEmail: yup
          .string()
          .required(
            intl.formatMessageWithValidation({
              id: 'form_validations_required_field',
            })
          )
          .email(
            intl.formatMessageWithValidation({
              id: 'form_validations_invalid_email',
            })
          )
          .matches(
            EMAIL_VALIDATION_REGEX,
            intl.formatMessageWithValidation({
              id: 'form_validations_invalid_email',
            })
          ),
      }}
      onSubmit={handleFormSubmit}
      onCancel={onCancel}
    >
      <TextField name="contactEmail" />
    </GenericDetailForm>
  );
}

export default EmailDetailForm;
