import styled from 'styled-components';
import { BORDER_SIZE } from 'design-system/styles/border';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import Typography from 'design-system/components/Typography/Typography';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import FormattedDate from '../../../../../partials/FormattedDate/FormattedDate';
import { propertyTypesTranslationTags } from '../../../../../constants/ad';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Item = styled.div`
  display: flex;
  align-items: center;
  padding-right: 12px;
  border-right: ${BORDER_SIZE.BASE}px solid ${COLOR_PALETTE.NEUTRAL_A10};

  ${Body1} {
    ${Typography.mods.weight(FONT_WEIGHT.SEMIBOLD)}
  }

  &:last-child {
    padding-right: initial;
    border-right: initial;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 18px;
  grid-gap: 12px;
`;

function ProjectStatus({ project }) {
  const { propertyType, currentPhase, endDate } = project;
  if (!propertyType && !currentPhase) {
    return null;
  }

  return (
    <Wrapper>
      {currentPhase && (
        <Item>
          <Body1>
            {currentPhase === 'off-plan' && (
              <FormattedMessageWithValidation id="project_detail_phase_off_plan" />
            )}
            {currentPhase === 'in-construction' && (
              <FormattedMessageWithValidation id="project_detail_phase_construction" />
            )}
            {currentPhase === 'ready-release' && (
              <FormattedMessageWithValidation id="project_detail_phase_ready_release" />
            )}
            {currentPhase === 'release' && (
              <FormattedMessageWithValidation
                id="project_detail_phase_release"
                values={{
                  endDate: (
                    <FormattedDate
                      value={new Date(endDate)}
                      year="numeric"
                      month="numeric"
                    />
                  ),
                }}
              />
            )}
          </Body1>
        </Item>
      )}
      {propertyType && (
        <Item>
          <Body1>
            <FormattedMessageWithValidation
              id={propertyTypesTranslationTags.get(propertyType)}
            />
          </Body1>
        </Item>
      )}
    </Wrapper>
  );
}

export default ProjectStatus;
