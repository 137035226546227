import { useMount } from 'react-use';
import { dispatch } from 'reffects';
import Layout from 'design-system/components/Layout';
import LoginCard from './partials/LoginCard';
import { AUTHENTICATION_PAGE_MOUNTED } from './events';

function BaseAuthenticationPage({ children }) {
  useMount(() => dispatch(AUTHENTICATION_PAGE_MOUNTED));
  return (
    <Layout>
      <Layout.Content>
        <LoginCard>{children}</LoginCard>
      </Layout.Content>
    </Layout>
  );
}

export default BaseAuthenticationPage;
