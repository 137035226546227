import styled from 'styled-components';

const Header = styled.header`
  grid-area: header;
  background: palevioletred;

  display: grid;
  justify-items: center;

  position: sticky;
  top: 0;
  z-index: 3;
`;

export default Header;
