import styled from 'styled-components';
import { FlatButton } from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';
import {
  BUTTON_ICON_POSITION,
  BUTTON_SIZE,
} from 'design-system/components/Button';
import { color, COLOR_SEMANTIC } from 'design-system/styles/color';
import noop from '../../../../utils/noop';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Divider = styled.span`
  height: inherit;
  width: 1px;
  ${color.background(COLOR_SEMANTIC.DIVIDER)}
`;

function ClearFiltersButton({ onClick = noop }) {
  return (
    <>
      <Divider />
      <FlatButton
        icon={<Icon glyph="cross" />}
        $size={BUTTON_SIZE.BASE}
        $iconPosition={BUTTON_ICON_POSITION.START}
        onClick={onClick}
      >
        <FormattedMessageWithValidation id="clear_filters_button" />
      </FlatButton>
    </>
  );
}

export default ClearFiltersButton;
