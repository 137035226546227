import {
  hasSubscriptionChangeRequestForSuperboostSelector,
  paymentMethodIsCreditCardSelector,
  subscriptionChangeRequestSelector,
} from '../../selectors/publisher';
import { hasCreditCardSavedSelector } from '../../selectors/payment';

export function shouldShowPendingSCRBannerSelector(state) {
  const { type, status, hasPaymentRequest } =
    subscriptionChangeRequestSelector(state) ?? {};
  if (status !== 'pending') {
    return false;
  }
  const paysByCard = paymentMethodIsCreditCardSelector(state);
  const hasCreditCardSaved = hasCreditCardSavedSelector(state);
  return !(
    type === 'renew' &&
    paysByCard &&
    hasPaymentRequest &&
    hasCreditCardSaved
  );
}

export function shouldShowSuperboostBannerSelector(state) {
  const { type } = subscriptionChangeRequestSelector(state) ?? {};

  return (
    type === 'renew' && hasSubscriptionChangeRequestForSuperboostSelector(state)
  );
}
