import styled, { css } from 'styled-components';
import { useSelector } from 'reffects-store';
import Anchor from 'design-system/components/Anchor';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Overline } from 'design-system/components/Typography/presets/Overline';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { COLOR_PALETTE } from 'design-system/styles/color';
import {
  appleAppStoreLogoUrlSelector,
  googlePlayStoreLogoUrlSelector,
} from '../../Interstitial/selectors';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import useTracking from '../../../hooks/useTracking';
import useClickTracking from '../../../hooks/useClickTracking';
import {
  androidAppDownloadLink,
  iosAppDownloadLink,
} from '../../../utils/proppitWebRouter';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  ${spacing.stack(SPACING_SIZE.S, false)}
`;

const AppStores = styled.div`
  display: flex;
  ${({ $fullWidth }) =>
    $fullWidth
      ? css`
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        `
      : css`
          flex-direction: column;
          > * {
            ${spacing.stack(SPACING_SIZE.M)}
          }
        `}
  ${Anchor} {
    ${spacing.inline(SPACING_SIZE.M)}
  }
`;

function MobileApp({ fullWidth }) {
  const ref = useTracking({
    trackImpression: {
      elementName: 'download-apps-banner',
    },
    sectionName: 'overview-page-footer',
  });
  const { trackClick } = useClickTracking({
    sectionName: 'overview-footer-banner',
  });
  const googlePlayStoreLogoUrl = useSelector(googlePlayStoreLogoUrlSelector);
  const appleAppStoreLogoUrl = useSelector(appleAppStoreLogoUrlSelector);
  return (
    <Wrapper ref={ref}>
      {fullWidth && (
        <Header>
          <Overline>
            <FormattedMessageWithValidation id="overview_mobile_app_section_title" />
          </Overline>
        </Header>
      )}
      <AppStores $fullWidth={fullWidth}>
        <Body1 $color={COLOR_PALETTE.NEUTRAL_A80}>
          <FormattedMessageWithValidation id="overview_mobile_app_section_description" />
        </Body1>
        <div>
          <Anchor
            href={iosAppDownloadLink()}
            target="_blank"
            onClick={() =>
              trackClick({
                eventName: 'download-ios-app',
              })
            }
          >
            <img src={appleAppStoreLogoUrl} alt="App Store" />
          </Anchor>
          <Anchor
            href={androidAppDownloadLink()}
            target="_blank"
            onClick={() =>
              trackClick({
                eventName: 'download-android-app',
              })
            }
          >
            <img src={googlePlayStoreLogoUrl} alt="Play Store" />
          </Anchor>
        </div>
      </AppStores>
    </Wrapper>
  );
}

export default MobileApp;
