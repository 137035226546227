import { css } from 'styled-components';
import { withStylesAndInnerRef } from 'design-system/utils';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';
import TextAndIconButton from './TextAndIconButton';
import IconOnlyButton from './IconOnlyButton';
import BaseButton, { BUTTON_SIZE } from './BaseButton';

export * from './BaseButton';
export * from './TextAndIconButton';

const modSizeSmall = () => css`
  ${typography.weight(FONT_WEIGHT.MEDIUM)}

  &${IconOnlyButton} {
    ${IconOnlyButton.mods.size(BUTTON_SIZE.SMALL)}
  }

  &${TextAndIconButton} {
    ${TextAndIconButton.mods.size(BUTTON_SIZE.SMALL)}
  }
`;

const modSizeBase = () => css`
  ${typography.weight(FONT_WEIGHT.NORMAL)}

  &${IconOnlyButton} {
    ${IconOnlyButton.mods.size(BUTTON_SIZE.BASE)}
  }

  &${TextAndIconButton} {
    ${TextAndIconButton.mods.size(BUTTON_SIZE.BASE)}
  }
`;

const modSizeLarge = () => css`
  ${typography.weight(FONT_WEIGHT.NORMAL)}

  &${TextAndIconButton} {
    ${TextAndIconButton.mods.size(BUTTON_SIZE.LARGE)}
  }
`;

const modSize = (sizeName) =>
  ({
    [BUTTON_SIZE.SMALL]: modSizeSmall,
    [BUTTON_SIZE.BASE]: modSizeBase,
    [BUTTON_SIZE.LARGE]: modSizeLarge,
  }[sizeName]);

function Button({ children, icon, as, innerRef, ...rest }) {
  if (!rest.type) {
    // eslint-disable-next-line no-param-reassign
    rest.type = 'button';
  }
  if (!children && icon) {
    return (
      <IconOnlyButton {...rest} forwardedAs={as} icon={icon} ref={innerRef} />
    );
  }

  return (
    <TextAndIconButton {...rest} forwardedAs={as} icon={icon} ref={innerRef}>
      {children}
    </TextAndIconButton>
  );
}

Button.mods = {
  size: modSize,
  variant: BaseButton.mods.variant,
  iconPosition: TextAndIconButton.mods.iconPosition,
};

export default withStylesAndInnerRef(Button);
