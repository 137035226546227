import { useIntl } from 'react-intl';
import { useSelector } from 'reffects-store';

export default function useInternationalization(intl = useIntl()) {
  const translations = useSelector((state) => state.translations);

  return {
    formatMessageWithValidation: ({ id, values }) => {
      if (!id) {
        console.warn(`Translation id is not a valid value`);
        return null;
      }

      if (translations?.[id] != null) {
        return intl.formatMessage({ id }, values);
      }

      console.warn(`Translation '${id}' does not exist`);
      return id;
    },
    formatDate: (date) => intl.formatDate(date),
  };
}
