import * as React from "react";
const SvgHelpCenter = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={107}
    height={107}
    fill="none"
    {...props}
  >
    <path
      fill="#0ACC86"
      fillRule="evenodd"
      d="M64.5 10a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 1 0v9a.5.5 0 0 1-.5.5"
      clipRule="evenodd"
    />
    <path
      fill="#0ACC86"
      fillRule="evenodd"
      d="M67.906 10.25h-6.68c-.614 0-1.113.486-1.113 1.086v59.1h8.906v-59.1c0-.6-.499-1.086-1.113-1.086m-6.68-1.086c-1.23 0-2.226.973-2.226 2.172v60.186h11.132V11.336c0-1.2-.997-2.172-2.226-2.172z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      stroke="#0ACC86"
      d="M55.5 21.808a3.5 3.5 0 0 1 3.5-3.5h12.038a3.5 3.5 0 0 1 3.5 3.5v81.841H55.5z"
    />
    <path
      fill="#0ACC86"
      d="M55 21.5c0-1.195.834-3 2-3h14.5c1.166 0 2.5 1.305 2.5 2.5v83H55z"
      opacity={0.2}
    />
    <path
      fill="#E7FAF3"
      d="M52 22.935a1 1 0 0 1 1-1h22.781a1 1 0 0 1 1 1v42a1 1 0 0 1-1 1H53a1 1 0 0 1-1-1z"
    />
    <path
      fill="#0ACC86"
      fillRule="evenodd"
      d="M54.167 22.07h21.666c.599 0 1.084.478 1.084 1.068v81.793H53.083V23.138c0-.59.485-1.069 1.084-1.069M75.833 21C77.03 21 78 21.957 78 23.138V106H52V23.138c0-1.18.97-2.138 2.167-2.138z"
      clipRule="evenodd"
    />
    <path fill="#0ACC86" d="M78 25a4 4 0 0 0-4-4v86h4z" opacity={0.3} />
    <path
      fill="#0ACC86"
      fillRule="evenodd"
      d="M55.5 99a.5.5 0 0 1-.5-.5v-69a.5.5 0 0 1 1 0v69a.5.5 0 0 1-.5.5M58.5 99a.5.5 0 0 1-.5-.5v-69a.5.5 0 0 1 1 0v69a.5.5 0 0 1-.5.5M61.5 99a.5.5 0 0 1-.5-.5v-69a.5.5 0 0 1 1 0v69a.5.5 0 0 1-.5.5M64.5 99a.5.5 0 0 1-.5-.5v-69a.5.5 0 0 1 1 0v69a.5.5 0 0 1-.5.5M67.5 99a.5.5 0 0 1-.5-.5v-69a.5.5 0 0 1 1 0v69a.5.5 0 0 1-.5.5M70.5 99a.5.5 0 0 1-.5-.5v-69a.5.5 0 0 1 1 0v69a.5.5 0 0 1-.5.5M73.5 99a.5.5 0 0 1-.5-.5v-69a.5.5 0 0 1 1 0v69a.5.5 0 0 1-.5.5"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      stroke="#0ACC86"
      d="M17.5 57a1.5 1.5 0 0 1 1.5-1.5h68a1.5 1.5 0 0 1 1.5 1.5v45.5h-71z"
    />
    <path stroke="#0ACC86" d="M20.5 58.5h65v41h-65z" />
    <path
      fill="#fff"
      stroke="#0ACC86"
      strokeLinecap="round"
      d="M101.5 103v3a.5.5 0 0 1-.5.5H7a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5h94a.5.5 0 0 1 .5.5Z"
    />
    <path
      fill="#CEF5E7"
      d="M50.065 85.078h3.86v-.257c.053-3.103.944-4.493 3.238-5.91 2.415-1.457 3.9-3.509 3.9-6.545 0-4.385-3.32-7.354-8.272-7.354-4.548 0-8.191 2.658-8.367 7.516h4.102c.162-2.86 2.2-4.116 4.265-4.116 2.294 0 4.156 1.525 4.156 3.914 0 2.01-1.255 3.427-2.861 4.426-2.51 1.538-3.994 3.063-4.021 8.07zm2.037 8.204a2.707 2.707 0 0 0 2.7-2.698c0-1.471-1.229-2.686-2.7-2.686-1.484 0-2.698 1.215-2.698 2.686a2.7 2.7 0 0 0 2.698 2.698"
    />
    <path
      fill="#CEF5E7"
      fillRule="evenodd"
      d="M46.645 66.567c1.608-1.363 3.777-2.055 6.145-2.055 2.575 0 4.77.772 6.326 2.159 1.562 1.39 2.447 3.368 2.447 5.695 0 3.244-1.607 5.442-4.14 6.972-1.11.686-1.827 1.333-2.285 2.142-.458.81-.687 1.839-.714 3.346v.752h-4.86v-.76c.014-2.545.398-4.276 1.147-5.58.75-1.31 1.836-2.13 3.112-2.912 1.534-.954 2.624-2.236 2.624-4 0-2.066-1.59-3.414-3.657-3.414-.934 0-1.839.285-2.52.866-.674.574-1.17 1.47-1.245 2.778l-.026.472h-5.093l.018-.518c.093-2.56 1.105-4.574 2.72-5.943m-1.688 5.461h3.114c.173-1.318.742-2.323 1.55-3.011.893-.761 2.04-1.105 3.17-1.105 2.52 0 4.656 1.703 4.656 4.414 0 2.256-1.42 3.807-3.097 4.85l-.003.002c-1.233.756-2.144 1.468-2.77 2.557-.606 1.057-.974 2.525-1.01 4.843h2.863c.047-1.48.293-2.627.838-3.59.575-1.016 1.45-1.772 2.632-2.502l.004-.003c2.297-1.386 3.658-3.29 3.658-6.117 0-2.059-.775-3.758-2.11-4.948-1.342-1.195-3.284-1.906-5.661-1.906-2.18 0-4.106.636-5.5 1.818-1.28 1.084-2.144 2.654-2.334 4.698m3.946 18.555c0-1.748 1.44-3.185 3.2-3.185 1.743 0 3.198 1.435 3.198 3.186a3.198 3.198 0 1 1-6.398 0m3.2-2.185c-1.21 0-2.2.992-2.2 2.186a2.198 2.198 0 1 0 4.398 0c0-1.192-1-2.186-2.199-2.186"
      clipRule="evenodd"
    />
    <path
      fill="#CEF5E7"
      d="M51.22 85.078h3.86v-.257c.054-3.103.945-4.493 3.239-5.91 2.415-1.457 3.9-3.509 3.9-6.545 0-4.385-3.32-7.354-8.272-7.354-4.548 0-8.191 2.658-8.367 7.516h4.102c.162-2.86 2.2-4.116 4.265-4.116 2.294 0 4.156 1.525 4.156 3.914 0 2.01-1.255 3.427-2.86 4.426-2.51 1.538-3.995 3.063-4.022 8.07zm2.038 8.204a2.707 2.707 0 0 0 2.7-2.698c0-1.471-1.229-2.686-2.7-2.686-1.484 0-2.698 1.215-2.698 2.686a2.7 2.7 0 0 0 2.698 2.698"
    />
    <path
      fill="#0ACC86"
      fillRule="evenodd"
      d="M47.8 66.567c1.609-1.363 3.779-2.055 6.147-2.055 2.575 0 4.768.772 6.325 2.159 1.562 1.39 2.447 3.368 2.447 5.695 0 3.244-1.607 5.442-4.14 6.972-1.11.686-1.827 1.333-2.284 2.142-.459.81-.688 1.839-.715 3.346v.752h-4.86v-.76c.015-2.545.398-4.276 1.147-5.58.75-1.31 1.836-2.13 3.113-2.912 1.533-.954 2.623-2.236 2.623-4 0-2.066-1.59-3.414-3.656-3.414-.935 0-1.84.285-2.522.866-.673.574-1.17 1.47-1.243 2.778l-.027.472h-5.093l.019-.518c.092-2.56 1.104-4.574 2.72-5.943m-1.687 5.461h3.114c.173-1.318.742-2.323 1.55-3.011.893-.761 2.04-1.105 3.17-1.105 2.521 0 4.656 1.703 4.656 4.414 0 2.256-1.42 3.807-3.097 4.85l-.002.002c-1.233.756-2.145 1.468-2.77 2.557-.606 1.057-.974 2.525-1.01 4.843h2.862c.047-1.48.293-2.627.838-3.59.575-1.016 1.45-1.772 2.632-2.502l.005-.003c2.296-1.386 3.658-3.29 3.658-6.117 0-2.059-.776-3.758-2.112-4.948-1.34-1.195-3.283-1.906-5.66-1.906-2.18 0-4.106.636-5.5 1.818-1.28 1.084-2.144 2.654-2.334 4.698m3.947 18.555c0-1.748 1.44-3.185 3.198-3.185 1.744 0 3.2 1.435 3.2 3.186a3.207 3.207 0 0 1-3.2 3.198 3.2 3.2 0 0 1-3.198-3.198m3.198-2.185c-1.21 0-2.198.992-2.198 2.186a2.198 2.198 0 1 0 4.397 0c0-1.192-1-2.186-2.199-2.186"
      clipRule="evenodd"
    />
    <mask id="HelpCenter_svg__a" fill="#fff">
      <path
        fillRule="evenodd"
        d="M5 22a5 5 0 0 0-5 5v11.999a5 5 0 0 0 5 5h22.758l5.243 5.284V39.11h-.003V27a5 5 0 0 0-5-5z"
        clipRule="evenodd"
      />
    </mask>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M5 22a5 5 0 0 0-5 5v11.999a5 5 0 0 0 5 5h22.758l5.243 5.284V39.11h-.003V27a5 5 0 0 0-5-5z"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      d="m27.758 43.999.71-.705-.293-.295h-.417zm5.243 5.284-.71.704 1.71 1.723v-2.427zm0-10.173h1v-1h-1zm-.003 0h-1v1h1zM1 27a4 4 0 0 1 4-4v-2a6 6 0 0 0-6 6zm0 11.999V27h-2v11.999zm4 4a4 4 0 0 1-4-4h-2a6 6 0 0 0 6 6zm22.758 0H5v2h22.758zm5.953 5.58-5.243-5.285-1.42 1.41 5.243 5.283zm-1.71-9.469v10.173h2V39.11zm.997 1h.003v-2h-.003zm-1-13.11v12.11h2V27zm-4-4a4 4 0 0 1 4 4h2a6 6 0 0 0-6-6zM5 23h22.998v-2H5z"
      mask="url(#HelpCenter_svg__a)"
    />
    <path
      fill="#0ACC86"
      d="m27.758 43.999.71-.705-.293-.295h-.417zm5.243 5.284-.71.704 1.71 1.723v-2.427zm0-10.173h1v-1h-1zm-.003 0h-1v1h1zM1 27a4 4 0 0 1 4-4v-2a6 6 0 0 0-6 6zm0 11.999V27h-2v11.999zm4 4a4 4 0 0 1-4-4h-2a6 6 0 0 0 6 6zm22.758 0H5v2h22.758zm5.953 5.58-5.243-5.285-1.42 1.41 5.243 5.283zm-1.71-9.469v10.173h2V39.11zm.997 1h.003v-2h-.003zm-1-13.11v12.11h2V27zm-4-4a4 4 0 0 1 4 4h2a6 6 0 0 0-6-6zM5 23h22.998v-2H5z"
      mask="url(#HelpCenter_svg__a)"
    />
    <circle cx={10} cy={33} r={1.5} stroke="#0ACC86" />
    <circle cx={16} cy={33} r={1.5} stroke="#0ACC86" />
    <circle cx={22} cy={33} r={1.5} stroke="#0ACC86" />
    <mask id="HelpCenter_svg__b" fill="#fff">
      <path
        fillRule="evenodd"
        d="M84.98 71a5 5 0 0 0-5 5v8a5 5 0 0 0 5 5h17.712l4.29 4.323V85h-.002v-9a5 5 0 0 0-5-5z"
        clipRule="evenodd"
      />
    </mask>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M84.98 71a5 5 0 0 0-5 5v8a5 5 0 0 0 5 5h17.712l4.29 4.323V85h-.002v-9a5 5 0 0 0-5-5z"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      d="m102.692 89 .709-.704-.293-.296h-.416zm4.29 4.323-.71.705 1.71 1.723v-2.428zm0-8.323h1v-1h-1zm-.002 0h-1v1h1zm-26-9a4 4 0 0 1 4-4v-2a6 6 0 0 0-6 6zm0 8v-8h-2v8zm4 4a4 4 0 0 1-4-4h-2a6 6 0 0 0 6 6zm17.712 0H84.981v2h17.711zm4.999 4.62-4.29-4.324-1.419 1.408 4.29 4.324zM105.982 85v8.323h2V85zm.998 1h.002v-2h-.002zm-1-10v9h2v-9zm-4-4a4 4 0 0 1 4 4h2a6 6 0 0 0-6-6zm-17 0h17v-2h-17z"
      mask="url(#HelpCenter_svg__b)"
    />
    <path
      fill="#0ACC86"
      d="m102.692 89 .709-.704-.293-.296h-.416zm4.29 4.323-.71.705 1.71 1.723v-2.428zm0-8.323h1v-1h-1zm-.002 0h-1v1h1zm-26-9a4 4 0 0 1 4-4v-2a6 6 0 0 0-6 6zm0 8v-8h-2v8zm4 4a4 4 0 0 1-4-4h-2a6 6 0 0 0 6 6zm17.712 0H84.981v2h17.711zm4.999 4.62-4.29-4.324-1.419 1.408 4.29 4.324zM105.982 85v8.323h2V85zm.998 1h.002v-2h-.002zm-1-10v9h2v-9zm-4-4a4 4 0 0 1 4 4h2a6 6 0 0 0-6-6zm-17 0h17v-2h-17z"
      mask="url(#HelpCenter_svg__b)"
    />
    <path fill="#9DEBCF" d="M80.982 72h13v4a7 7 0 0 1-7 7h-6z" />
    <path
      stroke="#0ACC86"
      strokeLinecap="round"
      d="M102.482 77.5h-18M102.482 80.5h-18M102.482 83.5h-18"
    />
    <mask id="HelpCenter_svg__c" fill="#fff">
      <path
        fillRule="evenodd"
        d="M86.981 66a5 5 0 0 1 5 5v5.32a5 5 0 0 1-5 5h-14.33L69 85V71a5 5 0 0 1 5-5z"
        clipRule="evenodd"
      />
    </mask>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M86.981 66a5 5 0 0 1 5 5v5.32a5 5 0 0 1-5 5h-14.33L69 85V71a5 5 0 0 1 5-5z"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      d="m72.651 81.32-.71-.704.294-.296h.416zM69 85l.71.704L68 87.428V85zm0-7.084h-1v-1h1zm0 0h1v1h-1zM90.982 71a4 4 0 0 0-4-4v-2a6 6 0 0 1 6 6zm0 5.32V71h2v5.32zm-4 4a4 4 0 0 0 4-4h2a6 6 0 0 1-6 6zm-14.33 0h14.33v2h-14.33zm-4.36 3.976 3.65-3.68 1.42 1.409-3.651 3.68zM70 77.916V85h-2v-7.084zm-1 1v-2zM70 71v6.916h-2V71zm4-4a4 4 0 0 0-4 4h-2a6 6 0 0 1 6-6zm12.981 0h-12.98v-2h12.98z"
      mask="url(#HelpCenter_svg__c)"
    />
    <path
      fill="#0ACC86"
      d="m72.651 81.32-.71-.704.294-.296h.416zM69 85l.71.704L68 87.428V85zm0-7.084h-1v-1h1zm0 0h1v1h-1zM90.982 71a4 4 0 0 0-4-4v-2a6 6 0 0 1 6 6zm0 5.32V71h2v5.32zm-4 4a4 4 0 0 0 4-4h2a6 6 0 0 1-6 6zm-14.33 0h14.33v2h-14.33zm-4.36 3.976 3.65-3.68 1.42 1.409-3.651 3.68zM70 77.916V85h-2v-7.084zm-1 1v-2zM70 71v6.916h-2V71zm4-4a4 4 0 0 0-4 4h-2a6 6 0 0 1 6-6zm12.981 0h-12.98v-2h12.98z"
      mask="url(#HelpCenter_svg__c)"
    />
    <path
      stroke="#0ACC86"
      strokeLinecap="round"
      d="M72.5 71.5h8M72.905 74.011h15.172M72.905 76.564h15.172"
    />
  </svg>
);
export default SvgHelpCenter;
