import { isUserLoggedInSelector } from '../../../App/selectors';
import { homeRoute, loginRoute } from '../../../utils/proppitWebRouter';
import { userEmailSelector, userRoleSelector } from '../../../selectors/user';
import {
  publisherCountrySelector,
  publisherSubscriptionIdSelector,
} from '../../../selectors/publisher';

export function continueLinkSelector(state) {
  return isUserLoggedInSelector(state) ? homeRoute() : loginRoute();
}

export function segmentUserDataSelector(state) {
  return {
    email: userEmailSelector(state),
    role: userRoleSelector(state) ?? 'not-logged',
    subscriptionId: publisherSubscriptionIdSelector(state),
    countryCode: publisherCountrySelector(state),
  };
}
