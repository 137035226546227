import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Anchor from 'design-system/components/Anchor';
import Layout from 'design-system/components/Layout';
import Illustration from 'design-system/components/Illustration/Illustration';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { subscriptionRoute } from '../../../utils/proppitWebRouter';
import { Navigation } from '../../../partials';
import PublisherData from '../PublisherData/PublisherData';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${Illustration} {
    ${spacing.stack(SPACING_SIZE.L, false)}
  }
`;

export default function CheckoutCancelled() {
  return (
    <>
      <Layout>
        <Layout.Header>
          <Navigation title={<PublisherData />} hideLinks hideAppBanner />
        </Layout.Header>
        <Layout.Content>
          <Layout.Padded>
            <Content>
              <Illustration name="welcome" />
              <Body1>
                <FormattedMessageWithValidation
                  id="checkout_expired_caption_general"
                  values={{
                    my_subscription: (
                      <Anchor
                        $weight={FONT_WEIGHT.BOLD}
                        forwardedAs={Link}
                        to={subscriptionRoute()}
                      >
                        <FormattedMessageWithValidation id="my_subscription" />
                      </Anchor>
                    ),
                  }}
                />
              </Body1>
            </Content>
          </Layout.Padded>
        </Layout.Content>
      </Layout>
    </>
  );
}
