import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';

export const INIT_PUBLISHER_AUTOCOMPLETE = 'INIT_PUBLISHER_AUTOCOMPLETE';
export const PUBLISHER_SUGGESTER_TEXT_INTRODUCED =
  'PUBLISHER_SUGGESTER_TEXT_INTRODUCED';

registerEventHandler(INIT_PUBLISHER_AUTOCOMPLETE, () =>
  state.set({
    'admin:publisher-autocomplete:suggestions': [],
    'admin:publisher-autocomplete:displayableText': undefined,
  })
);

registerEventHandler(
  PUBLISHER_SUGGESTER_TEXT_INTRODUCED,
  (_, { publishers, searchQuery, countryCode }) => {
    if (!searchQuery || searchQuery.length < 2 || !publishers || !countryCode) {
      return state.set({
        'admin:publisher-autocomplete:displayableText': searchQuery,
      });
    }

    const lowerCaseSearchQuery = searchQuery.toLowerCase();
    const filteredPublishers = publishers.filter(
      (publisher) =>
        publisher.countryCode === countryCode &&
        (publisher.name.toLowerCase().includes(lowerCaseSearchQuery) ||
          publisher.id.toLowerCase() === lowerCaseSearchQuery)
    );

    return state.set({
      'admin:publisher-autocomplete:suggestions': filteredPublishers,
      'admin:publisher-autocomplete:displayableText': searchQuery,
    });
  }
);
