import * as React from "react";
const SvgPageNotFound = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={212}
    height={221}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#0ACC86"
      strokeWidth={2}
      d="M42 84a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v128H42z"
    />
    <path
      fill="#fff"
      stroke="#0ACC86"
      strokeWidth={2}
      d="M32 92a3 3 0 0 1 3-3h30a3 3 0 0 1 3 3v128H32z"
    />
    <path fill="#0ACC86" d="M31 92a4 4 0 0 1 4-4h4v133h-8z" opacity={0.3} />
    <rect width={37} height={2} x={32} y={104} fill="#0ACC86" rx={1} />
    <rect width={37} height={2} x={32} y={109} fill="#0ACC86" rx={1} />
    <rect width={37} height={2} x={32} y={114} fill="#0ACC86" rx={1} />
    <rect width={37} height={2} x={32} y={119} fill="#0ACC86" rx={1} />
    <rect width={37} height={2} x={32} y={124} fill="#0ACC86" rx={1} />
    <rect width={37} height={2} x={32} y={129} fill="#0ACC86" rx={1} />
    <rect width={37} height={2} x={32} y={134} fill="#0ACC86" rx={1} />
    <rect width={37} height={2} x={32} y={139} fill="#0ACC86" rx={1} />
    <rect width={37} height={2} x={32} y={144} fill="#0ACC86" rx={1} />
    <rect width={37} height={2} x={32} y={149} fill="#0ACC86" rx={1} />
    <rect width={37} height={2} x={32} y={154} fill="#0ACC86" rx={1} />
    <rect width={37} height={2} x={32} y={159} fill="#0ACC86" rx={1} />
    <rect width={37} height={2} x={32} y={164} fill="#0ACC86" rx={1} />
    <rect width={37} height={2} x={32} y={169} fill="#0ACC86" rx={1} />
    <rect width={37} height={2} x={32} y={174} fill="#0ACC86" rx={1} />
    <rect width={37} height={2} x={32} y={179} fill="#0ACC86" rx={1} />
    <rect width={37} height={2} x={32} y={184} fill="#0ACC86" rx={1} />
    <rect width={37} height={2} x={32} y={189} fill="#0ACC86" rx={1} />
    <rect width={37} height={2} x={32} y={194} fill="#0ACC86" rx={1} />
    <rect width={37} height={2} x={32} y={199} fill="#0ACC86" rx={1} />
    <rect width={37} height={2} x={32} y={204} fill="#0ACC86" rx={1} />
    <rect width={37} height={2} x={32} y={209} fill="#0ACC86" rx={1} />
    <path
      fill="#fff"
      stroke="#0ACC86"
      strokeWidth={2}
      d="M22 138a3 3 0 0 1 3-3h11a3 3 0 0 1 3 3v82H22z"
    />
    <rect width={11} height={2} x={22} y={140} fill="#0ACC86" rx={1} />
    <rect width={11} height={2} x={22} y={145} fill="#0ACC86" rx={1} />
    <rect width={11} height={2} x={22} y={150} fill="#0ACC86" rx={1} />
    <rect width={11} height={2} x={22} y={155} fill="#0ACC86" rx={1} />
    <rect width={11} height={2} x={22} y={160} fill="#0ACC86" rx={1} />
    <rect width={11} height={2} x={22} y={165} fill="#0ACC86" rx={1} />
    <rect width={11} height={2} x={22} y={170} fill="#0ACC86" rx={1} />
    <rect width={11} height={2} x={22} y={175} fill="#0ACC86" rx={1} />
    <path
      fill="#0ACC86"
      fillRule="evenodd"
      d="M157.5 143a1 1 0 0 1-1-1V15a1 1 0 0 1 2 0v127a1 1 0 0 1-1 1"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      stroke="#0ACC86"
      strokeWidth={2}
      d="M148 44.084a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v109.833h-18z"
    />
    <path
      fill="#fff"
      stroke="#0ACC86"
      strokeWidth={2}
      d="M140 60a3 3 0 0 1 3-3h28a3 3 0 0 1 3 3v154h-34z"
    />
    <path
      fill="#0ACC86"
      d="M139 60a4 4 0 0 1 4-4h28a4 4 0 0 1 4 4v155h-36z"
      opacity={0.2}
    />
    <path
      fill="#fff"
      stroke="#0ACC86"
      strokeWidth={2}
      d="M180 66a3 3 0 0 0-3-3h-40a3 3 0 0 0-3 3v154h46z"
    />
    <path fill="#0ACC86" d="M181 66a4 4 0 0 0-4-4h-3v159h7z" opacity={0.3} />
    <path
      fill="#0ACC86"
      fillRule="evenodd"
      d="M140 206a1 1 0 0 1-1-1V78a1 1 0 0 1 2 0v127a1 1 0 0 1-1 1M145 206a1 1 0 0 1-1-1V78a1 1 0 0 1 2 0v127a1 1 0 0 1-1 1M150 206a1 1 0 0 1-1-1V78a1 1 0 0 1 2 0v127a1 1 0 0 1-1 1M155 206a1 1 0 0 1-1-1V78a1 1 0 0 1 2 0v127a1 1 0 0 1-1 1M160 206a1 1 0 0 1-1-1V78a1 1 0 0 1 2 0v127a1 1 0 0 1-1 1M165 206a1 1 0 0 1-1-1V78a1 1 0 0 1 2 0v127a1 1 0 0 1-1 1M170 206a1 1 0 0 1-1-1V78a1 1 0 0 1 2 0v127a1 1 0 0 1-1 1M175 206a1 1 0 0 1-1-1V78a1 1 0 0 1 2 0v127a1 1 0 0 1-1 1"
      clipRule="evenodd"
    />
    <path
      fill="#0ACC86"
      d="M86.802 36.91 52 219.5h99.5L116.657 36.691c-4.684.749-10.013 1.17-15.657 1.17-5.066 0-9.879-.34-14.198-.95"
      opacity={0.2}
    />
    <path
      stroke="#0ACC86"
      strokeLinecap="round"
      strokeWidth={2}
      d="M124.442 20c5.909 1.788 9.558 4.249 9.558 6.965 0 5.466-14.775 9.896-33 9.896s-33-4.43-33-9.896c0-2.716 3.65-5.177 9.558-6.965"
    />
    <path
      fill="#0ACC86"
      fillRule="evenodd"
      d="M83.607 77.607a1 1 0 0 1-1.415 0l-5.656-5.657a1 1 0 1 1 1.414-1.414l5.656 5.657a1 1 0 0 1 0 1.414"
      clipRule="evenodd"
    />
    <path
      fill="#0ACC86"
      fillRule="evenodd"
      d="M76.536 77.607a1 1 0 0 1 0-1.414l5.657-5.657a1 1 0 0 1 1.414 1.414l-5.657 5.657a1 1 0 0 1-1.414 0M125 59a1 1 0 0 1-1-1v-8a1 1 0 0 1 2 0v8a1 1 0 0 1-1 1"
      clipRule="evenodd"
    />
    <path
      fill="#0ACC86"
      fillRule="evenodd"
      d="M120 54a1 1 0 0 1 1-1h8a1 1 0 0 1 0 2h-8a1 1 0 0 1-1-1"
      clipRule="evenodd"
    />
    <path
      fill="#0ACC86"
      d="M78.567 20.233c-2.723 1.026-4.304 2.251-4.304 3.566 0 3.618 11.97 6.551 26.737 6.551s26.737-2.933 26.737-6.55c0-1.316-1.582-2.54-4.304-3.567C117.186 23.765 109.418 23.8 101 23.8s-16.186-.034-22.433-3.566"
      opacity={0.3}
    />
    <circle cx={110} cy={89} r={3} stroke="#0ACC86" strokeWidth={2} />
    <path
      fill="#fff"
      stroke="#0ACC86"
      strokeWidth={2}
      d="M100.759 25.496c-8.18 0-15.84-1.723-22.43-4.726C79.724 9.624 89.235 1 100.76 1s21.036 8.624 22.43 19.77c-6.589 3.003-14.249 4.726-22.43 4.726Z"
    />
    <path
      fill="#0ACC86"
      d="M106.429 5.085a1 1 0 1 0-.807 1.83zm9.763 11.407a1 1 0 1 0 1.74-.984zm-10.57-9.577a22.7 22.7 0 0 1 10.57 9.577l1.74-.984a24.7 24.7 0 0 0-11.503-10.423zM0 219h212v2H0z"
    />
  </svg>
);
export default SvgPageNotFound;
