import { Body1 } from 'design-system/components/Typography/presets/Body1';
import Table from 'design-system/components/Table';
import { useColumn } from '../../hooks/useColumn';
import EmptyCellContent from '../EmptyCellContent';

export function TextColumn({ renderField, ellipse, ...props }) {
  const { value } = useColumn(props);

  if (value == null) {
    return (
      <Table.Cell {...props}>
        <EmptyCellContent />
      </Table.Cell>
    );
  }

  return (
    <Table.Cell {...props}>
      <Body1 $ellipse={ellipse}>
        {renderField ? renderField(value) : value}
      </Body1>
    </Table.Cell>
  );
}
