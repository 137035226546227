import { SecondaryButton } from 'design-system/components/Button/presets';
import Dialog from 'design-system/components/Dialog/Dialog';
import FormattedMessageWithValidation from '../../../FormattedMessageWithValidation/FormattedMessageWithValidation';
import { NOT_PUBLISHED_MODAL } from '../events';
import { useDialog } from '../../../../hooks/useDialog';

export function NotPublishedModal() {
  const { open, closeDialog } = useDialog(NOT_PUBLISHED_MODAL);
  return (
    <Dialog open={open}>
      <Dialog.Header>
        <FormattedMessageWithValidation id="not_published_modal_title" />
      </Dialog.Header>
      <Dialog.Content>
        <Dialog.Text>
          <FormattedMessageWithValidation id="not_published_modal_text" />
        </Dialog.Text>
      </Dialog.Content>
      <Dialog.Footer>
        <SecondaryButton onClick={closeDialog}>
          <FormattedMessageWithValidation id="freelistings_button_continue" />
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}
