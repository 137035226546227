import styled from 'styled-components';
import Image from 'design-system/components/Image/Image';
import Illustration from 'design-system/components/Illustration/Illustration';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle2 } from 'design-system/components/Typography/presets/Subtitle2';

const Wrapper = styled(Image)`
  grid-column-start: span 8;

  width: 100%;
  height: 230px;
  object-fit: cover;
  border-radius: 0 0 4px 4px;
`;
const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${color.background(COLOR_PALETTE.NEUTRAL_A05)}

  ${Illustration} {
    ${spacing.stack(SPACING_SIZE.S)}
  }
`;

export function BackgroundImage({ source, placeholderText }) {
  return (
    <Wrapper
      placeholder={
        <Placeholder>
          <Illustration name="buildingsGrey" />
          <Subtitle2 $color={COLOR_PALETTE.NEUTRAL_A40}>
            {placeholderText}
          </Subtitle2>
        </Placeholder>
      }
      source={source}
    />
  );
}
