import { useCombobox } from 'downshift';
import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Popover from 'design-system/components/Popover';
import BaseMenu from 'design-system/components/Menu/partials/BaseMenu';
import Option from './Option';

const EMPTY_TEXT = '';

const Wrapper = styled.div`
  position: relative;

  ${Popover} {
    width: 100%;
    margin-top: ${spacing.value(SPACING_SIZE.S)};
  }
`;

const MenuWrapper = styled.div`
  position: absolute;
  z-index: 5;
  min-width: 100%;
  max-width: calc(100% + 40px);
`;

function Autocomplete({
  value,
  onValueChange,
  renderInput,
  onOptionSelected,
  renderOptions,
  options,
  optionToString,
  isLoading,
  emptyStateMessage,
  loadingStateMessage,
  minCharactersToOpen = 1,
  ...props
}) {
  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    reset,
  } = useCombobox({
    defaultHighlightedIndex: 0,
    items: options,
    itemToString: optionToString,
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem && optionToString(selectedItem) !== EMPTY_TEXT) {
        onValueChange(selectedItem.text);
        onOptionSelected(selectedItem);
      }
    },
    onInputValueChange: ({ inputValue }) => {
      onValueChange(inputValue);
    },
  });

  const showMenu = value && value.length >= minCharactersToOpen && isOpen;
  const showEmptyState = !isLoading && options.length === 0;
  const showOptions = !isLoading;
  let wrapperProps = {};
  if (props.menuWrapperDataTag) {
    wrapperProps = {
      'data-tag': props.menuWrapperDataTag,
    };
  }

  return (
    <Wrapper {...props}>
      <div {...getComboboxProps()}>
        {renderInput({
          value,
          onClear: reset,
          getInputProps,
        })}
      </div>
      <MenuWrapper {...getMenuProps(wrapperProps)}>
        {showMenu && (
          <Popover>
            <BaseMenu>
              {isLoading && (
                <Option title={loadingStateMessage} noInteractive />
              )}
              {showEmptyState && (
                <Option title={emptyStateMessage} noInteractive />
              )}
              {showOptions &&
                renderOptions({
                  options,
                  getOptionProps: getItemProps,
                })}
            </BaseMenu>
          </Popover>
        )}
      </MenuWrapper>
    </Wrapper>
  );
}

Autocomplete.Option = Option;

export default styled(Autocomplete)``;
