import { state } from 'reffects-store';

export function putSubscriptionChangeRequestDetailsInStateEffect(
  data,
  additionalState
) {
  return state.merge({
    subscriptionChangeRequest: {
      requestDetails: {
        id: data.id,
        products: data.products.map(
          ({ boostableProperties, ...productData }) => ({
            maxBoostableProperties: boostableProperties,
            ...productData,
          })
        ),
        taxRetentions: data.taxRetentions,
        vat: data.vat,
        total: data.total,
        status: data.status,
        type: data.type,
        billingCycleInMonths: data.billingCycleInMonths,
        startDate: data.startDate ? new Date(data.startDate) : null,
        nextBillingDate: data.nextBillingDate
          ? new Date(data.nextBillingDate)
          : null,
        paymentNotified: data.paymentNotified,
        billingStatus: data.billingStatus,
        wasi: data.wasi,
        paymentMethod: data.usedPaymentMethod ?? data.suggestedPaymentMethod,
      },
      paymentDetails: data.paymentRequest,
      suggestedPaymentMethod: data.suggestedPaymentMethod,
      usedPaymentMethod: data.usedPaymentMethod,
    },
    ...additionalState,
  });
}
