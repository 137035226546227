import { registerEventHandler } from 'reffects';
import { BACKOFFICE_ROLES } from '../../constants/user';
import { redirectTo } from '../../effects/routing';
import { homeRoute } from '../../utils/proppitWebRouter';

export const CHECK_USER_IS_BACKOFFICE_ROLE = 'CHECK_USER_IS_BACKOFFICE_ROLE';

registerEventHandler(CHECK_USER_IS_BACKOFFICE_ROLE, (_, { role }) =>
  Object.values(BACKOFFICE_ROLES).includes(role) ? {} : redirectTo(homeRoute())
);
