import * as React from "react";
const SvgBlog = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <g clipPath="url(#Blog_svg__a)">
      <rect
        width={31.3}
        height={31.3}
        x={0.35}
        y={0.35}
        stroke="#111B3C"
        strokeWidth={0.7}
        rx={3.65}
      />
      <path
        fill="#000"
        d="M10.18 24a2.18 2.18 0 1 0 0-4.36 2.18 2.18 0 0 0 0 4.36"
      />
      <path
        fill="#000"
        d="M8 8.44v2.83c7.03 0 12.73 5.7 12.73 12.73h2.83c0-8.59-6.97-15.56-15.56-15.56m0 5.66v2.83c3.9 0 7.07 3.17 7.07 7.07h2.83c0-5.47-4.43-9.9-9.9-9.9"
      />
    </g>
    <defs>
      <clipPath id="Blog_svg__a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgBlog;
