import styled from 'styled-components';

import Badge, {
  BADGE_SIZE,
  BADGE_VARIANT,
} from 'design-system/components/Badge/Badge';
import Tooltip, { TOOLTIP_PLACEMENT } from 'design-system/components/Tooltip';
import Icon from 'design-system/components/Icon';
import FormattedMessageWithValidation from './FormattedMessageWithValidation/FormattedMessageWithValidation';

const Wrapper = styled.span`
  display: inline-flex;

  ${Badge} {
    ${Badge.mods.variant(BADGE_VARIANT.PRIMARY)}
    ${Badge.mods.size(BADGE_SIZE.MEDIUM)}
  }
`;

export default function VerifiedEmailBadge() {
  return (
    <Wrapper>
      <Tooltip
        $placement={TOOLTIP_PLACEMENT.TOP}
        title={
          <FormattedMessageWithValidation id="enqpage_table_verified_email" />
        }
      >
        <Badge icon={<Icon glyph="check" />} />
      </Tooltip>
    </Wrapper>
  );
}
