import { Link } from 'react-router-dom';
import Tag, { TAG_VARIANT } from 'design-system/components/Tag';
import { PrimaryButton } from 'design-system/components/Button/presets';
import { useSelector } from 'reffects-store';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { checkoutBillingInformationRoute } from '../../../../utils/proppitWebRouter';
import { UPSELL_SUBROUTE } from '../../../Checkout/configuration/constants';
import { hasAutoRenewalSelector } from '../../../../selectors/publisher';
import { paymentInformationIsLoadingSelector } from '../../../../selectors/payment';

export default function SubscriptionChangeRequestStatusAction({ scr }) {
  const paymentInformationIsLoading = useSelector(
    paymentInformationIsLoadingSelector
  );
  const isAutoRenewalPublisher = useSelector(hasAutoRenewalSelector);

  if (!scr || paymentInformationIsLoading) {
    return null;
  }

  if (scr.status === 'paid') {
    return (
      <Tag variant={TAG_VARIANT.COMPLEMENTARY_SKYBLUE}>
        <FormattedMessageWithValidation id="subcription_upcoming_plan_already_paid" />
      </Tag>
    );
  }

  if (scr.type === 'renew' && isAutoRenewalPublisher) {
    return null;
  }

  return (
    <PrimaryButton
      forwardedAs={Link}
      to={checkoutBillingInformationRoute({
        subscriptionChangeRequestId: scr.id,
        subRoute: UPSELL_SUBROUTE,
      })}
    >
      <FormattedMessageWithValidation id="subscription_pay_now" />
    </PrimaryButton>
  );
}
