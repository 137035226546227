import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import Tooltip, { TOOLTIP_PLACEMENT } from 'design-system/components/Tooltip';
import Anchor from 'design-system/components/Anchor';
import Illustration from 'design-system/components/Illustration/Illustration';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { isPublisherFromLATAMSelector } from '../../../selectors/publisher';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    ${spacing.stack(SPACING_SIZE.M)}
  }
`;

const Sites = styled.div`
  display: flex;
  flex-direction: row;
  ${Anchor} {
    ${spacing.inline(SPACING_SIZE.M)}
  }
`;

function SocialMedia() {
  const showBlog = useSelector(isPublisherFromLATAMSelector);
  return (
    <Wrapper>
      <Body1 $color={COLOR_PALETTE.NEUTRAL_A80}>
        <FormattedMessageWithValidation id="overview_social_media_header" />
      </Body1>
      <Sites>
        <Anchor href="https://www.facebook.com/ProppitLatam/" target="_blank">
          <Illustration name="facebook" />
        </Anchor>
        <Anchor href="https://twitter.com/ProppitLatam" target="_blank">
          <Illustration name="twitter" />
        </Anchor>
        <Anchor
          href="https://www.linkedin.com/showcase/proppit/"
          target="_blank"
        >
          <Illustration name="linkedIn" />
        </Anchor>
        <Anchor href="https://www.instagram.com/proppit_latam/" target="_blank">
          <Illustration name="instagram" />
        </Anchor>
        {showBlog && (
          <Tooltip
            title={<FormattedMessageWithValidation id="blog" />}
            $placement={TOOLTIP_PLACEMENT.RIGHT}
          >
            <Anchor href="https://blog.proppit.com/" target="_blank">
              <Illustration name="blog" />
            </Anchor>
          </Tooltip>
        )}
      </Sites>
    </Wrapper>
  );
}

export default SocialMedia;
