import { CHANGE_REQUEST_STATUS } from '../../../../../constants/subscription';

const STATUSES = [CHANGE_REQUEST_STATUS.PENDING, CHANGE_REQUEST_STATUS.PAID];

export function showSubscriptionChangeRequestStatus(status, billingStatus) {
  return Boolean(
    STATUSES.includes(status) ||
      (status === CHANGE_REQUEST_STATUS.ACTIVATED &&
        billingStatus !== 'conciliated')
  );
}
