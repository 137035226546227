import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import styled from 'styled-components';
import Table from 'design-system/components/Table';
import { useColumn } from '../../../../../partials/DataTable/hooks/useColumn';

const AdCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

const AdCell = styled.div`
  ${spacing.stack(SPACING_SIZE.XL)}
  min-width: 0;
`;

function AdFieldsColumn({ renderField, renderAccumulatedField, ...props }) {
  const source = { ads: 'ads', accumulatedAds: 'accumulatedAds' };
  const {
    value: { ads = [], accumulatedAds },
  } = useColumn({ ...props, source });

  return (
    <Table.Cell maxWidth={225}>
      <AdCellWrapper>
        {ads.map((ad) => (
          <AdCell key={`${ad.id}_${Object.keys(source).join('_')}`}>
            {renderField(ad)}
          </AdCell>
        ))}
        {accumulatedAds && (
          <AdCell>{renderAccumulatedField(accumulatedAds)}</AdCell>
        )}
      </AdCellWrapper>
    </Table.Cell>
  );
}

export default AdFieldsColumn;
