import styled from 'styled-components';

const Wrapper = styled.div`
  input {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    white-space: nowrap;
    width: 1px;
  }
  label {
    ${({ $disabled }) => !$disabled && 'cursor: pointer;'}
    width: 100%;
  }
`;

function FileInput({
  id,
  children,
  multiple,
  accept,
  disabled,
  onUpload,
  onClick,
  ...rest
}) {
  return (
    <Wrapper $disabled={disabled} {...rest}>
      <input
        type="file"
        id={id}
        onChange={(event) => {
          onUpload(Array.from(event.target.files));
          // eslint-disable-next-line no-param-reassign
          event.target.value = null;
        }}
        multiple={multiple}
        accept={accept}
        disabled={disabled}
        onClick={onClick}
        aria-disabled={disabled}
      />
      <label htmlFor={id}>{children}</label>
    </Wrapper>
  );
}

export default styled(FileInput)``;
