import styled, { css } from 'styled-components';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { COLOR_PALETTE, COLOR_SEMANTIC } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';

const modDisabled = () => css`
  ${Subtitle3.mods.color(COLOR_PALETTE.NEUTRAL_A20)}
`;

const modError = () => css`
  ${Subtitle3.mods.color(COLOR_SEMANTIC.STATUS_ERROR)}
`;

const Wrapper = styled(Subtitle3)`
  display: block;

  ${spacing.stack(SPACING_SIZE.S)}

  ${({ error }) => error && modError()}
  ${({ disabled }) => disabled && modDisabled()}
`;

function InputLabel({ children, required, ...rest }) {
  return (
    <Wrapper {...rest}>
      {children}
      {required && '*'}
    </Wrapper>
  );
}

export default styled(InputLabel)``;
