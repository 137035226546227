import {
  developerActivePromotedProjectsSelector,
  isDeveloperSelector,
  publisherSubscriptionPlanIsFreeSelector,
} from '../../../../selectors/publisher';

export function showSubscriptionDisclaimerSelector(state) {
  if (!isDeveloperSelector(state)) {
    return publisherSubscriptionPlanIsFreeSelector(state);
  }
  return developerActivePromotedProjectsSelector(state).length === 0;
}
