import { effects, registerEventHandler } from 'reffects';
import { queryParams } from 'reffects-batteries';
import { TRACK_CLICK } from '../../partials/Tracking/events';

export const LEAD_AWARENESS_THANKS_LOADED = 'LEAD_AWARENESS_THANKS_LOADED';

registerEventHandler(
  LEAD_AWARENESS_THANKS_LOADED,
  ({
    queryParams: {
      publisherId,
      publisherType,
      subscriptionId,
      hasPaidSubscription,
      rating,
    },
  }) =>
    effects.dispatch({
      id: TRACK_CLICK,
      payload: {
        eventName: 'lead-awareness-rating-from-email',
        sectionName: 'lead-awareness',
        payload: {
          publisherId,
          publisherType,
          subscriptionId,
          hasPaidSubscription: Boolean(hasPaidSubscription),
          rating,
        },
      },
    }),
  [
    queryParams.get([
      'publisherId',
      'subscriptionId',
      'rating',
      'publisherType',
      'hasPaidSubscription',
    ]),
  ]
);
