import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import styled from 'styled-components';
import { dispatch } from 'reffects';
import { subscribe } from 'reffects-store';
import SegmentedControl from 'design-system/components/SegmentedControl';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { SWITCH_BUTTON_CLICKED } from './events';
import { subscriptionPageCurrentTabSelector } from './selectors';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  ${spacing.stack(SPACING_SIZE.L)}
`;

function BillingSwitch({ currentTab, link }) {
  return (
    <Wrapper>
      <SegmentedControl
        selectedValue={currentTab}
        defaultValue="subscription"
        onChange={() =>
          dispatch({
            id: SWITCH_BUTTON_CLICKED,
            payload: { link, currentTab },
          })
        }
      >
        <SegmentedControl.Item key="subscription" value="subscription">
          <FormattedMessageWithValidation id="subscription_current_subscription" />
        </SegmentedControl.Item>
        <SegmentedControl.Item key="invoices" value="invoices">
          <FormattedMessageWithValidation id="subscription_invoices" />
        </SegmentedControl.Item>
      </SegmentedControl>
    </Wrapper>
  );
}

export default subscribe(BillingSwitch, (state) => ({
  currentTab: subscriptionPageCurrentTabSelector(state),
}));
