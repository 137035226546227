import { dispatch } from 'reffects';
import Anchor from 'design-system/components/Anchor';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import Price from '../../../../partials/Price/Price';
import { OPEN_DIALOG } from '../../../../events/dialogs';
import { SUBSCRIPTION_DETAILS_DIALOG } from '../SubscriptionDetailsModal';
import SubscriptionChangeRequestStatusAction from '../SubscriptionChangeRequestStatusAction/SubscriptionChangeRequestStatusAction';
import RenewalDetails from './RenewalDetails';
import {
  Column,
  Content,
  PriceSection,
  Row,
  TwoColumns,
  Wrapper,
} from './partials';
import CustomFormattedDate from '../../../../partials/FormattedDate/CustomFormattedDate';
import {
  getBaseSubscriptionProductAsSubscription,
  isForBaseSubscription,
} from '../../../../utils/subscriptionChangeRequest';

export default function PendingPlanChangeBanner({ scr, isLoading }) {
  const hasSubscriptionChangeRequestForBaseSubscription =
    isForBaseSubscription(scr);
  const baseSubscriptionProduct = getBaseSubscriptionProductAsSubscription(scr);

  if (isLoading) {
    return null;
  }
  if (!hasSubscriptionChangeRequestForBaseSubscription) {
    return <RenewalDetails />;
  }
  if (!scr) {
    return null;
  }

  const showStartDate = scr.type === 'upsell-upgrade' && scr.startDate;

  return (
    <Wrapper>
      <Content>
        <Subtitle3 $color={COLOR_PALETTE.NEUTRAL_BASE}>
          <FormattedMessageWithValidation id="subscription_pending_plan_change_details" />
        </Subtitle3>
        <Row>
          <TwoColumns>
            <Column>
              <div>
                <Heading1>
                  <FormattedMessageWithValidation
                    id={baseSubscriptionProduct.nameTag}
                  />
                </Heading1>
                <Heading1>
                  {' '}
                  {baseSubscriptionProduct.boostableProperties}{' '}
                  <FormattedMessageWithValidation id="subscription_boosted_properties" />
                </Heading1>
              </div>
              <Body1 $weight={FONT_WEIGHT.LIGHT}>
                <FormattedMessageWithValidation
                  id="subscription_summary_contract_type_value"
                  values={{
                    duration: scr.billingCycleInMonths,
                  }}
                />
              </Body1>
            </Column>
            <Column>
              {!showStartDate && (
                <PriceSection>
                  <Heading1>
                    <Price
                      amount={baseSubscriptionProduct.totalPrice.amount}
                      currency={baseSubscriptionProduct.totalPrice.currency}
                    />
                    <SubscriptionChangeRequestStatusAction scr={scr} />
                  </Heading1>
                  <Body1>
                    {scr.status === 'paid' ? (
                      <div>Total</div>
                    ) : (
                      <FormattedMessageWithValidation id="subscription_pending_plan_new_plan_price" />
                    )}
                  </Body1>
                </PriceSection>
              )}
            </Column>
          </TwoColumns>
        </Row>
        {showStartDate && (
          <Row>
            <TwoColumns>
              <Column>
                <Heading1>
                  <CustomFormattedDate value={scr.startDate} timeZone="utc" />
                </Heading1>
                <Body1>
                  <FormattedMessageWithValidation id="subscription_pending_plan_start_date" />
                </Body1>
              </Column>
              <Column>
                <PriceSection>
                  <Heading1>
                    <Price
                      amount={baseSubscriptionProduct.totalPrice.amount}
                      currency={baseSubscriptionProduct.totalPrice.currency}
                    />
                    <SubscriptionChangeRequestStatusAction scr={scr} />
                  </Heading1>
                  <Body1>
                    {scr.status === 'paid' ? (
                      <div>Total</div>
                    ) : (
                      <FormattedMessageWithValidation id="subscription_pending_plan_new_plan_price" />
                    )}
                  </Body1>
                </PriceSection>
              </Column>
            </TwoColumns>
          </Row>
        )}
        <Anchor
          href="#"
          onClick={() =>
            dispatch({
              id: OPEN_DIALOG,
              payload: {
                id: SUBSCRIPTION_DETAILS_DIALOG,
                parameters: {
                  title: 'subscription_pending_plan_change_details',
                  subscription: baseSubscriptionProduct,
                },
              },
            })
          }
        >
          <FormattedMessageWithValidation id="subscription_view_details" />
        </Anchor>
      </Content>
    </Wrapper>
  );
}
