import { http } from 'reffects-batteries';
import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { environment } from '../../../coeffects/environment';

export const BACKOFFICE_PROJECT_REQUESTED = 'BACKOFFICE_PROJECT_REQUESTED';
export const BACKOFFICE_PROJECT_LOADED = 'BACKOFFICE_PROJECT_LOADED';
export const BACKOFFICE_PROJECT_GEOLEVELS_CHANGED =
  'BACKOFFICE_PROJECT_FORM_VALUE_CHANGED';
export const BACKOFFICE_PROJECT_FORM_ADDRESS_CHANGED =
  'BACKOFFICE_PROJECT_FORM_ADDRESS_CHANGED';
export const BACKOFFICE_PROJECT_FORM_SUBMITTED =
  'BACKOFFICE_PROJECT_FORM_SUBMITTED';
export const BACKOFFICE_PROJECT_FORM_SAVED = 'BACKOFFICE_PROJECT_FORM_SAVED';
export const BACKOFFICE_PROJECT_FORM_FAILED = 'BACKOFFICE_PROJECT_FORM_FAILED';

registerEventHandler(
  BACKOFFICE_PROJECT_REQUESTED,
  ({ environment: { apiUrl } }, { projectId }) => ({
    ...http.get({
      url: `${apiUrl}/admin/project/${projectId}`,
      successEvent: BACKOFFICE_PROJECT_LOADED,
    }),
  }),
  [environment()]
);

registerEventHandler(BACKOFFICE_PROJECT_LOADED, (_, [{ data }]) => ({
  ...state.set({
    projectEditor: { project: data },
  }),
}));

registerEventHandler(BACKOFFICE_PROJECT_GEOLEVELS_CHANGED, (_, { value }) => ({
  ...state.set({
    [`projectEditor.project.geoLevels`]: JSON.parse(value),
  }),
}));
registerEventHandler(
  BACKOFFICE_PROJECT_FORM_ADDRESS_CHANGED,
  ({ state: { project } }, { name, value }) => {
    // eslint-disable-next-line no-unused-vars
    const [_, locale] = name.split('_');

    const newAddresses = project.address.map((address) => {
      if (address.locale === locale) {
        return { address: value, locale };
      }
      return address;
    });

    return {
      ...state.set({
        [`projectEditor.project.address`]: newAddresses,
      }),
    };
  },
  [state.get({ project: 'projectEditor.project' })]
);

registerEventHandler(
  BACKOFFICE_PROJECT_FORM_SUBMITTED,
  ({ state: { project }, environment: { apiUrl } }, _) => ({
    ...state.set({
      'projectEditor.saved': false,
      'projectEditor.failed': false,
    }),
    ...http.patch({
      url: `${apiUrl}/admin/projects/${project.id}/address`,
      body: {
        address: project.address,
        geolevels: project.geoLevels,
        locale: 'en_US', // project.locale
      },
      successEvent: BACKOFFICE_PROJECT_FORM_SAVED,
      errorEvent: BACKOFFICE_PROJECT_FORM_FAILED,
    }),
  }),
  [state.get({ project: 'projectEditor.project' }), environment()]
);

registerEventHandler(BACKOFFICE_PROJECT_FORM_SAVED, () => ({
  ...state.set({
    'projectEditor.saved': true,
  }),
}));

registerEventHandler(BACKOFFICE_PROJECT_FORM_FAILED, () => ({
  ...state.set({
    'projectEditor.failed': true,
  }),
}));
