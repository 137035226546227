import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

function RowsLabel({ currentRowRange, totalRows }) {
  return (
    <>
      {currentRowRange.join('-')}{' '}
      <FormattedMessageWithValidation id="enqpage_table_page" /> {totalRows}
    </>
  );
}

export default RowsLabel;
