import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';

export const ItemFullWidth = styled.div`
  flex: 1 100%;
`;

export const ItemTwoColumns = styled.div`
  flex: 1 100%;
`;

export const ItemTwoColumnsResponsive = styled.div`
  flex: 1 33.33%;
`;

export const FormWrapper = styled.div`
  margin: -${({ formFieldMargins = SPACING_SIZE.S }) => spacing.value(formFieldMargins)};
  display: flex;
  flex-wrap: wrap;

  ${ItemFullWidth}, ${ItemTwoColumns}, ${ItemTwoColumnsResponsive} {
    margin: ${({ formFieldMargins = SPACING_SIZE.S }) =>
      spacing.value(formFieldMargins)};
  }

  ${ItemTwoColumns} {
    max-width: ${({ formFieldMargins = SPACING_SIZE.S }) =>
      `calc(50% - ${formFieldMargins * 2}px)`};
  }
`;
