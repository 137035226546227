import styled, { css } from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';

const modNoVerticalSpacing = () => css`
  padding-top: initial;
  padding-bottom: initial;
`;

const modContrast = () => css`
  ${color.background(COLOR_PALETTE.PRIMARY_A05)}
`;

const Padded = styled.div`
  ${spacing.inset(SPACING_SIZE.XL)}

  ${({ noVerticalSpacing }) => noVerticalSpacing && modNoVerticalSpacing()}
    ${({ $contrast }) => $contrast && modContrast()}
`;

export default Padded;
