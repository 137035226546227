import styled from 'styled-components';
import Group from 'design-system/components/Group';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Content = styled.div`
  > ${Body1} {
    display: block;
    ${spacing.stack(SPACING_SIZE.L)}
  }
`;

function WithoutCreditCard() {
  return (
    <Group
      title={
        <FormattedMessageWithValidation id="subscription_payment_information_title" />
      }
    >
      <Content>
        <Body1 $weight={FONT_WEIGHT.LIGHT}>
          <FormattedMessageWithValidation id="subscription_payment_information_no_bank_card_body" />
        </Body1>
      </Content>
    </Group>
  );
}

export default WithoutCreditCard;
