import { countriesSortedByCountryName } from '../../utils/countries';
import { userManagedCountriesSelector } from '../../selectors/user';

export function availableCountriesForBackofficeUserSelector(state) {
  const managedCountries = userManagedCountriesSelector(state);
  return Object.entries(countriesSortedByCountryName())
    .filter(
      ([code]) =>
        managedCountries.length === 0 || managedCountries.includes(code)
    )
    .map(([code, name]) => ({
      code,
      name,
    }));
}
