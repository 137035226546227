import styled from 'styled-components';
import { dispatch } from 'reffects';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from 'react-router-dom';
import { useSelector } from 'reffects-store';
import Anchor from 'design-system/components/Anchor';
import InputText from 'design-system/components/InputText';
import {
  OutlinePrimaryButton,
  PrimaryButton,
} from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Heading2 } from 'design-system/components/Typography/presets/Heading2';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { FORGOT_PASSWORD } from '../../../constants/routes';
import { LOG_IN_SUBMITTED } from '../events';
import loginHasFailedSelector from '../selectors';
import { EMAIL_VALIDATION_REGEX } from '../../../constants';
import { userFromLamudiSelector } from '../../../selectors/publisher';

const Wrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${InputText},
  ${Heading1},
  ${Body1} {
    ${spacing.stack(SPACING_SIZE.M)}
  }

  ${Body1} {
    text-align: center;
  }
`;

const Inputs = styled.div`
  width: 100%;
  ${spacing.stack(SPACING_SIZE.M)}
`;

const Actions = styled.div`
  width: 100%;
  display: flex;
  ${({ $userFromLamudi }) =>
    `justify-content: ${$userFromLamudi ? 'flex-end' : 'space-between'};`}
  align-items: center;

  ${spacing.insetSandwich(SPACING_SIZE.M)}
`;

const RecoverPasswordBlock = styled.div`
  margin-top: ${spacing.value(SPACING_SIZE.M)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > * {
    ${spacing.stack(SPACING_SIZE.M)}
  }

  ${Body1} {
    text-align: center;
  }
`;

const schema = yup
  .object({
    email: yup
      .string()
      .email(
        <FormattedMessageWithValidation id="loginpage_error_email_invalid" />
      )
      .matches(
        EMAIL_VALIDATION_REGEX,
        <FormattedMessageWithValidation id="loginpage_error_email_invalid" />
      )
      .required(
        <FormattedMessageWithValidation id="loginpage_error_email_required" />
      ),
    password: yup
      .string()
      .required(
        <FormattedMessageWithValidation id="loginpage_error_password_required" />
      ),
  })
  .required();

function LoginForm({ ...rest }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });
  const loginHasFailed = useSelector(loginHasFailedSelector);
  const userFromLamudi = useSelector(userFromLamudiSelector);

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)} {...rest}>
      {!userFromLamudi && (
        <Heading1>
          <FormattedMessageWithValidation id="loginpage_header" />
        </Heading1>
      )}
      {userFromLamudi && (
        <Body1>
          <FormattedMessageWithValidation id="loginpage_text_lamudi" />
        </Body1>
      )}
      <Inputs>
        <InputText
          label={<FormattedMessageWithValidation id="loginpage_email" />}
          error={Boolean(errors.email)}
          tip={errors.email?.message}
          {...register('email')}
        />
        <InputText
          type="password"
          label={<FormattedMessageWithValidation id="loginpage_password" />}
          error={Boolean(errors.password) || loginHasFailed}
          {...register('password')}
          tip={
            errors.password?.message ||
            (loginHasFailed && (
              <FormattedMessageWithValidation id="loginpage_error_wrong" />
            ))
          }
        />
      </Inputs>
      <Actions $userFromLamudi={userFromLamudi}>
        {!userFromLamudi && (
          <Anchor as={Link} to={FORGOT_PASSWORD}>
            <FormattedMessageWithValidation id="loginpage_forget_cta" />
          </Anchor>
        )}
        <PrimaryButton type="submit" $size={BUTTON_SIZE.LARGE}>
          <FormattedMessageWithValidation id="loginpage_cta" />
        </PrimaryButton>
      </Actions>
      {userFromLamudi && (
        <RecoverPasswordBlock>
          <Heading2>
            <FormattedMessageWithValidation id="loginpage_reset_password_title_lamudi" />
          </Heading2>
          <Body1>
            <FormattedMessageWithValidation id="loginpage_reset_password_text_lamudi" />
          </Body1>
          <OutlinePrimaryButton
            forwardedAs={Link}
            to={`${FORGOT_PASSWORD}?user-from-lamudi=true`}
          >
            <FormattedMessageWithValidation id="loginpage_forget_cta_lamudi" />
          </OutlinePrimaryButton>
        </RecoverPasswordBlock>
      )}
    </Wrapper>
  );
}

export default LoginForm;

function onSubmit({ email, password }) {
  dispatch({
    id: LOG_IN_SUBMITTED,
    payload: {
      email,
      password,
    },
  });
}
