import {
  effects as effectsBuilder,
  effects,
  registerEventHandler,
} from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import { environment } from '../../../coeffects/environment';
import { toast } from '../../../effects/toast';
import {
  COLLECTION_NAME,
  CREATE_USER_DIALOG,
  UPDATE_USER_DIALOG,
} from './constants';
import { getLocaleByCountry } from './localesMapping';
import { uuid } from '../../../coeffects/uuid';
import { CLOSE_DIALOG, OPEN_DIALOG } from '../../../events/dialogs';
import { CUSTOMER_ROLES, isBackofficeRole } from '../../../constants/user';
import { table } from '../../../partials/DataTable/effects/table';

export const USER_FIELD_CHANGED = 'USER_FIELD_CHANGED';
export const USER_TO_MODIFY_SELECTED = 'USER_TO_MODIFY_SELECTED';
export const USER_TO_MODIFY_UNSELECTED = 'USER_TO_MODIFY_UNSELECTED';
export const USER_MODIFICATION_REQUESTED = 'USER_MODIFICATION_REQUESTED';
export const USER_MODIFICATION_SUCCESS = 'USER_MODIFICATION_SUCCESS';
export const USER_MODIFICATION_ERROR = 'USER_MODIFICATION_ERROR';
export const USER_DELETION_CONFIRMED = 'USER_DELETION_CONFIRMED';
export const USER_DELETION_SUCCEED = 'USER_DELETION_SUCCEED';
export const RESEND_WELCOME_EMAIL_REQUESTED = 'RESEND_WELCOME_EMAIL_REQUESTED';
export const RESEND_WELCOME_EMAIL_SUCCESS = 'RESEND_WELCOME_EMAIL_SUCCESS';
export const RESEND_WELCOME_EMAIL_ERROR = 'RESEND_WELCOME_EMAIL_ERROR';
export const OPEN_CREATE_USER_MODAL = 'OPEN_CREATE_USER_MODAL';
export const CREATE_USER_MODAL_COUNTRY_SELECTED =
  'CREATE_USER_MODAL_COUNTRY_SELECTED';
export const CREATE_USER_MODAL_FIELD_CHANGED =
  'CREATE_USER_MODAL_FIELD_CHANGED';
export const CREATE_USER_MODAL_REQUEST_CREATE_USER =
  'CREATE_USER_MODAL_REQUEST_CREATE_USER';
export const USER_MODAL_FORM_SUBMITTED = 'USER_MODAL_FORM_SUBMITTED';
export const CREATE_USER_MODAL_REQUEST_CREATE_USER_SUCCESS =
  'CREATE_USER_MODAL_REQUEST_CREATE_USER_SUCCESS';
export const CREATE_USER_MODAL_REQUEST_CREATE_USER_ERROR =
  'CREATE_USER_MODAL_REQUEST_CREATE_USER_ERROR';

export default function registerUsersListEvents() {
  registerEventHandler(
    USER_TO_MODIFY_SELECTED,
    ({ select }, { id }) => {
      const user = select.itemsById({ collectionName: COLLECTION_NAME })[id];
      return {
        ...state.merge({
          'admin:users:selectedUser': user,
          'admin:users:form': {
            error: null,
          },
        }),
        ...effectsBuilder.dispatch({
          id: OPEN_DIALOG,
          payload: { id: UPDATE_USER_DIALOG },
        }),
      };
    },
    [table.select()]
  );

  registerEventHandler(USER_TO_MODIFY_UNSELECTED, () => ({
    ...state.set({
      'admin:users:selectedUser': null,
    }),
    ...effectsBuilder.dispatch(CLOSE_DIALOG),
  }));

  registerEventHandler(
    USER_FIELD_CHANGED,
    ({ state: { user, publishers } }, { field, value }) => {
      if (!publishers) {
        return {};
      }
      const updatedUser = { ...user, [field]: value };
      const publisher = publishers.find(
        ({ id }) => id === updatedUser.publisherId
      );
      if (!publisher) {
        return {};
      }
      return state.set({
        'admin:users:selectedUser': {
          ...updatedUser,
          publisherName: publisher.name,
        },
      });
    },
    [
      state.get({
        user: 'admin:users:selectedUser',
        publishers: 'admin:publishers',
      }),
    ]
  );

  registerEventHandler(
    USER_MODIFICATION_REQUESTED,
    ({ environment: { apiUrl }, state: { user } }, { onSuccessEvent }) =>
      http.put({
        url: `${apiUrl}/backoffice/users/${user.id}`,
        body: user,
        successEvent: {
          id: USER_MODIFICATION_SUCCESS,
          payload: { onSuccessEvent },
        },
        errorEvent: USER_MODIFICATION_ERROR,
      }),
    [environment(), state.get({ user: 'admin:users:selectedUser' })]
  );

  registerEventHandler(
    USER_MODIFICATION_SUCCESS,
    (_, [__, { onSuccessEvent }]) => ({
      ...state.set({
        'admin:users:selectedUser': null,
      }),
      ...effectsBuilder.dispatchMany([onSuccessEvent, CLOSE_DIALOG]),
      ...toast.show({ text: 'User modified successfully' }),
    }),
    [state.get({ user: 'admin:users:selectedUser' }), table.select()]
  );

  registerEventHandler(
    USER_MODIFICATION_ERROR,
    (
      _,
      [
        {
          data: { error },
        },
      ]
    ) => toast.show({ text: error })
  );

  registerEventHandler(OPEN_CREATE_USER_MODAL, () => ({
    ...effects.dispatch({
      id: OPEN_DIALOG,
      payload: { id: CREATE_USER_DIALOG },
    }),
    ...state.merge({
      'admin:users:userBeingCreated': {
        id: '',
        country: '',
        publisherId: '',
        locale: '',
        email: '',
        role: CUSTOMER_ROLES.CLIENT,
      },
      'admin:users:form': {
        error: null,
      },
    }),
  }));

  registerEventHandler(
    CREATE_USER_MODAL_COUNTRY_SELECTED,
    ({ uuid: { newUuid } }, { country }) =>
      state.merge({
        'admin:users:userBeingCreated': {
          id: newUuid,
          country,
          publisherId: '',
          locale: getLocaleByCountry(country)[0],
        },
      }),
    [uuid()]
  );

  registerEventHandler(CREATE_USER_MODAL_FIELD_CHANGED, (_, { field, value }) =>
    state.merge({
      'admin:users:userBeingCreated': { [field]: value },
    })
  );

  registerEventHandler(
    USER_MODAL_FORM_SUBMITTED,
    (_, { user, submitEventId, onSuccessEvent }) => {
      const { role, email } = user;
      if (isBackofficeRole(role) && !isCompanyEmail(email)) {
        return state.set({
          'admin:users:form': {
            error: 'This role can only be assigned to a company email.',
          },
        });
      }

      return effects.dispatch({
        id: submitEventId,
        payload: { user, onSuccessEvent },
      });
    }
  );

  registerEventHandler(
    CREATE_USER_MODAL_REQUEST_CREATE_USER,
    ({ environment: { apiUrl } }, { user, onSuccessEvent }) => ({
      ...state.set({
        'admin:users:submittingUserCreation': true,
        'admin:users:form': null,
      }),
      ...http.post({
        url: `${apiUrl}/admin/user`,
        body: user,
        successEvent: {
          id: CREATE_USER_MODAL_REQUEST_CREATE_USER_SUCCESS,
          payload: { onSuccessEvent },
        },
        errorEvent: CREATE_USER_MODAL_REQUEST_CREATE_USER_ERROR,
      }),
    }),
    [environment()]
  );

  registerEventHandler(
    CREATE_USER_MODAL_REQUEST_CREATE_USER_SUCCESS,
    (_, [__, { onSuccessEvent }]) => ({
      ...state.set({ 'admin:users:submittingUserCreation': undefined }),
      ...effectsBuilder.dispatchMany([onSuccessEvent, CLOSE_DIALOG]),
      ...toast.show({ text: 'User created successfully' }),
    })
  );

  registerEventHandler(
    CREATE_USER_MODAL_REQUEST_CREATE_USER_ERROR,
    (
      _,
      [
        {
          data: { error },
        },
      ]
    ) => ({
      ...state.set({ 'admin:users:submittingUserCreation': undefined }),
      ...toast.show({
        text: error,
      }),
    })
  );

  registerEventHandler(
    RESEND_WELCOME_EMAIL_REQUESTED,
    ({ environment: { apiUrl } }, { id }) =>
      http.post({
        url: `${apiUrl}/backoffice/users/resend-welcome-email`,
        body: { userId: id },
        successEvent: RESEND_WELCOME_EMAIL_SUCCESS,
        errorEvent: RESEND_WELCOME_EMAIL_ERROR,
      }),
    [environment()]
  );

  registerEventHandler(RESEND_WELCOME_EMAIL_SUCCESS, () =>
    toast.show({ text: 'Welcome email sent' })
  );

  registerEventHandler(RESEND_WELCOME_EMAIL_ERROR, () =>
    toast.show({ text: 'There was an error sending the email' })
  );

  registerEventHandler(
    USER_DELETION_CONFIRMED,
    ({ environment: { apiUrl } }, { userId, onSuccessEvent }) =>
      http.delete({
        url: `${apiUrl}/backoffice/users/${userId}`,
        successEvent: {
          id: USER_DELETION_SUCCEED,
          payload: { onSuccessEvent },
        },
      }),
    [environment()]
  );

  registerEventHandler(
    USER_DELETION_SUCCEED,
    (_, [__, { onSuccessEvent }]) => ({
      ...effectsBuilder.dispatchMany([onSuccessEvent, CLOSE_DIALOG]),
      ...toast.show({ text: 'User has been deleted successfully' }),
    })
  );
}

function isCompanyEmail(email) {
  return (
    email.endsWith('@lifullconnect.com') ||
    (email.startsWith('lifulltester') && email.endsWith('@gmail.com'))
  );
}
