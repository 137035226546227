import * as React from "react";
const SvgCreditCardLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={288}
    height={150}
    fill="none"
    {...props}
  >
    <rect
      width={203}
      height={114}
      x={33.5}
      y={18}
      fill="#6CE0B6"
      stroke="#0ACC86"
      strokeWidth={2}
      rx={10}
    />
    <circle
      cx={205.655}
      cy={100.472}
      r={12}
      fill="#C4C4C4"
      transform="rotate(-10 205.655 100.472)"
    />
    <rect
      width={203}
      height={114}
      x={43.663}
      y={36.409}
      fill="#E7FAF3"
      stroke="#0ACC86"
      strokeWidth={2}
      rx={10}
      transform="rotate(-10 43.663 36.41)"
    />
    <path
      fill="#0ACC86"
      d="m73.113 114.704 59.088-10.419.695 3.94-59.088 10.418zM75.544 128.491l84.693-14.934.695 3.94L76.24 132.43z"
    />
    <circle
      cx={215.504}
      cy={98.736}
      r={11}
      fill="#0ACC86"
      stroke="#0ACC86"
      strokeWidth={2}
      transform="rotate(-10 215.504 98.736)"
    />
    <circle
      cx={232.245}
      cy={95.784}
      r={11}
      fill="#fff"
      stroke="#0ACC86"
      strokeWidth={2}
      transform="rotate(-10 232.245 95.784)"
    />
    <mask id="CreditCardLogo_svg__a" fill="#fff">
      <path
        fillRule="evenodd"
        d="M225.345 105.601a11.96 11.96 0 0 0 1.976-8.95 11.96 11.96 0 0 0-4.918-7.733 11.96 11.96 0 0 0-1.976 8.95 11.96 11.96 0 0 0 4.918 7.733"
        clipRule="evenodd"
      />
    </mask>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M225.345 105.601a11.96 11.96 0 0 0 1.976-8.95 11.96 11.96 0 0 0-4.918-7.733 11.96 11.96 0 0 0-1.976 8.95 11.96 11.96 0 0 0 4.918 7.733"
      clipRule="evenodd"
    />
    <path
      fill="#0ACC86"
      d="m225.345 105.601-1.15 1.637 1.642 1.154 1.148-1.646zm-2.942-16.683 1.15-1.637-1.642-1.154-1.148 1.646zm2.949 8.081a9.96 9.96 0 0 1-1.647 7.458l3.28 2.289a13.97 13.97 0 0 0 2.306-10.441zm-4.099-6.445A9.96 9.96 0 0 1 225.352 97l3.939-.694a13.96 13.96 0 0 0-5.738-9.024zm1.144 6.966a9.96 9.96 0 0 1 1.647-7.458l-3.281-2.289a13.96 13.96 0 0 0-2.305 10.442zm4.098 6.445a9.97 9.97 0 0 1-4.098-6.445l-3.939.695a13.96 13.96 0 0 0 5.737 9.023z"
      mask="url(#CreditCardLogo_svg__a)"
    />
    <rect
      width={32}
      height={21}
      x={62.985}
      y={51.503}
      fill="#fff"
      stroke="#0ACC86"
      strokeWidth={2}
      rx={7}
      transform="rotate(-10 62.985 51.503)"
    />
    <path
      stroke="#0ACC86"
      strokeLinecap="round"
      strokeWidth={2}
      d="M279.699 42v14M272.5 48.8h14"
    />
    <circle cx={255.5} cy={132} r={3} stroke="#0ACC86" strokeWidth={2} />
    <circle cx={114.5} cy={4} r={3} stroke="#0ACC86" strokeWidth={2} />
    <circle cx={19.5} cy={46} r={3} stroke="#0ACC86" strokeWidth={2} />
    <path
      stroke="#0ACC86"
      strokeLinecap="round"
      strokeWidth={2}
      d="m6.369 81.445 8.485 8.485M5.945 89.93l8.485-8.485"
    />
  </svg>
);
export default SvgCreditCardLogo;
