import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { getSymbolByCurrency } from '../../utils/currencies';

export default styled(({ amount, currency = 'USD', ...props }) => {
  const intl = useIntl();
  const price = formatPrice(intl, amount, currency);

  return <span {...props}>{price}</span>;
})``;

export function formatPrice(intl, amount, currency) {
  if (amount == null) {
    return null;
  }
  const formattedNumber = intl.formatNumber(amount, {
    style: 'currency',
    currency,
    minimumFractionDigits: getFractionDigits(amount),
    maximumFractionDigits: getFractionDigits(amount),
  });

  return formattedNumber.replace(currency, getSymbolByCurrency(currency));
}

function getFractionDigits(amount) {
  return amount % 1 !== 0.0 ? 2 : 0;
}
