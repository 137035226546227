import { effects as reffects, effects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import { environment } from '../../../../../../../coeffects/environment';
import { loadCountryConfig } from '../../../../../../../effects/loadCountryConfig';
import { CLOSE_DIALOG, OPEN_DIALOG } from '../../../../../../../events/dialogs';
import { toast } from '../../../../../../../effects/toast';
import {
  BILLING_ENTITY_BEING_DISPLAYED_STATE,
  BILLING_ENTITY_BEING_EDITED_STATE,
  BILLING_MODAL_STATE,
  INITIAL_BILLING_ENTITY_DATA,
} from './constants';
import {
  BILLING_ENTITY_FILE,
  BILLING_ENTITY_FILE_DATA,
  BILLING_ENTITY_FILE_IS_LOADED,
  BILLING_ENTITY_FILE_LOADING_ERROR,
} from '../../../../../../../pages/Checkout/BillingInformation/constants';
import { addItem } from '../../../../../../../utils/formDataUtils';
import { redirectTo } from '../../../../../../../effects/routing';

export const BILLING_ENTITY_DIALOG = 'BILLING_ENTITY_DIALOG';
export const OPEN_BILLING_ENTITY_DIALOG = 'OPEN_BILLING_ENTITY_DIALOG';
export const OPEN_BILLING_ENTITY_DIALOG_FOR_NO_ASSIGNED =
  'OPEN_BILLING_ENTITY_DIALOG_FOR_NO_ASSIGNED';
export const BILLING_ENTITY_DATA_LOADED = 'BILLING_ENTITY_DATA_LOADED';
export const BILLING_ENTITY_BY_PUBLISHER_ID_LOADED =
  'BILLING_ENTITY_BY_PUBLISHER_ID_LOADED';
export const BILLING_ENTITY_TAX_RETENTIONS_LOADED =
  'BILLING_ENTITY_TAX_RETENTIONS_LOADED';
export const BILLING_ENTITY_HAS_PENDING_CHANGE_REQUESTS_LOADED =
  'BILLING_ENTITY_HAS_PENDING_CHANGE_REQUESTS_LOADED';
export const BILLING_ENTITY_DIALOG_START_EDITING =
  'BILLING_ENTITY_DIALOG_START_EDITING';
export const BILLING_ENTITY_COUNTRY_CONFIG_LOADED =
  'BILLING_ENTITY_COUNTRY_CONFIG_LOADED';
export const BILLING_ENTITY_DIALOG_CANCEL_EDITING =
  'BILLING_ENTITY_DIALOG_CANCEL_EDITING';
export const BILLING_ENTITY_DIALOG_SAVE_EDIT =
  'BILLING_ENTITY_DIALOG_SAVE_EDIT';
export const BILLING_ENTITY_DIALOG_SAVED = 'BILLING_ENTITY_DIALOG_SAVED';
export const REQUEST_BILLING_ENTITY_DATA = 'REQUEST_BILLING_ENTITY_DATA';
export const ERROR_WHILE_SAVING_BILLING_ENTITY =
  'ERROR_WHILE_SAVING_BILLING_ENTITY';
export const CURRENT_BILLING_ENTITY_LOADED = 'CURRENT_BILLING_ENTITY_LOADED';
export const BILLING_ENTITY_BY_TAX_ID_LOADED =
  'BILLING_ENTITY_BY_TAX_ID_LOADED';
export const BILLING_ENTITY_FILE_DOWNLOAD = 'BILLING_ENTITY_FILE_DOWNLOAD';
export const BILLING_ENTITY_FILE_URL_REDIRECT =
  'BILLING_ENTITY_FILE_URL_REDIRECT';

registerEventHandler(
  OPEN_BILLING_ENTITY_DIALOG,
  ({ environment: { apiUrl } }, { publisherId, countryCode }) => ({
    ...state.merge({
      [BILLING_MODAL_STATE]: {
        publisherId,
        countryCode,
        isLoading: true,
      },
      [BILLING_ENTITY_BEING_DISPLAYED_STATE]: null,
      [BILLING_ENTITY_BEING_EDITED_STATE]: billingEntityInitialState({
        country: countryCode,
      }),
    }),
    ...http.get({
      url: `${apiUrl}/backoffice/publishers/${publisherId}/billing-entity`,
      successEvent: BILLING_ENTITY_BY_PUBLISHER_ID_LOADED,
    }),
  }),
  [environment()]
);

registerEventHandler(
  REQUEST_BILLING_ENTITY_DATA,
  ({ environment: { apiUrl } }, { taxId, countryCode }) => ({
    ...state.merge({
      [BILLING_ENTITY_BEING_EDITED_STATE]: {
        taxId,
      },
      [BILLING_MODAL_STATE]: {
        isLoading: true,
        taxIdSearchText: taxId,
      },
    }),
    ...http.get({
      url: `${apiUrl}/backoffice/billing-entity?taxId=${taxId}&countryCode=${countryCode}&excludeGeneric=true`,
      successEvent: BILLING_ENTITY_DATA_LOADED,
    }),
  }),

  [environment()]
);

registerEventHandler(
  BILLING_ENTITY_DATA_LOADED,
  ({ environment: { apiUrl } }, [{ data: billingEntity }]) => {
    if (billingEntity == null) {
      return state.merge({
        [BILLING_MODAL_STATE]: {
          isLoading: false,
        },
        [BILLING_ENTITY_BEING_EDITED_STATE]: {
          id: null,
        },
      });
    }
    return effectForBillingEntityLoaded(
      billingEntity,
      apiUrl,
      BILLING_ENTITY_BY_TAX_ID_LOADED
    );
  },
  [environment()]
);

registerEventHandler(
  BILLING_ENTITY_BY_PUBLISHER_ID_LOADED,
  ({ environment: { apiUrl } }, [{ data: billingEntity }]) =>
    effectForBillingEntityLoaded(
      billingEntity,
      apiUrl,
      CURRENT_BILLING_ENTITY_LOADED
    ),
  [environment()]
);

registerEventHandler(
  BILLING_ENTITY_HAS_PENDING_CHANGE_REQUESTS_LOADED,
  (_, [{ data }, { billingEntity: billingEntityData, onSuccessEventId }]) =>
    reffects.dispatch({
      id: onSuccessEventId,
      payload: {
        ...billingEntityData,
        hasPendingChangeRequests: data.length !== 0,
      },
    })
);

registerEventHandler(CURRENT_BILLING_ENTITY_LOADED, (_, billingEntity) => ({
  ...state.merge({
    [BILLING_ENTITY_BEING_EDITED_STATE]: billingEntity,
    [BILLING_ENTITY_BEING_DISPLAYED_STATE]: billingEntity,
    [BILLING_MODAL_STATE]: {
      isLoading: false,
    },
  }),
  ...effects.dispatch({
    id: OPEN_DIALOG,
    payload: {
      id: BILLING_ENTITY_DIALOG,
    },
  }),
}));

registerEventHandler(BILLING_ENTITY_BY_TAX_ID_LOADED, (_, billingEntity) =>
  state.merge({
    [BILLING_ENTITY_BEING_EDITED_STATE]: billingEntity,
    [BILLING_MODAL_STATE]: {
      isLoading: false,
    },
  })
);

registerEventHandler(
  BILLING_ENTITY_DIALOG_START_EDITING,
  ({ environment: { apiUrl }, state: { country } }) =>
    loadCountryConfig({
      apiUrl,
      country,
      successEvent: BILLING_ENTITY_COUNTRY_CONFIG_LOADED,
    }),
  [
    environment(),
    state.get({ country: `${BILLING_ENTITY_BEING_EDITED_STATE}.country` }),
  ]
);

registerEventHandler(
  OPEN_BILLING_ENTITY_DIALOG_FOR_NO_ASSIGNED,
  ({ environment: { apiUrl } }, { publisherId, countryCode }) => ({
    ...state.merge({
      [BILLING_MODAL_STATE]: {
        publisherId,
        countryCode,
        isLoading: false,
        taxIdSearchText: '',
      },
      [BILLING_ENTITY_BEING_DISPLAYED_STATE]: null,
      [BILLING_ENTITY_BEING_EDITED_STATE]: billingEntityInitialState({
        country: countryCode,
      }),
    }),
    ...loadCountryConfig({
      apiUrl,
      country: countryCode,
      successEvent: BILLING_ENTITY_COUNTRY_CONFIG_LOADED,
    }),
    ...effects.dispatch({
      id: OPEN_DIALOG,
      payload: {
        id: BILLING_ENTITY_DIALOG,
      },
    }),
  }),
  [environment()]
);

registerEventHandler(
  BILLING_ENTITY_COUNTRY_CONFIG_LOADED,
  (_, [{ billingDataValidation, taxId }]) =>
    state.set({
      'admin:billingEntityValidations': billingDataValidation,
      'admin:billingEntityTaxId': taxId,
      'admin:billingEntityDialogMode': 'edit',
    })
);

registerEventHandler(
  BILLING_ENTITY_DIALOG_CANCEL_EDITING,
  ({ state: { currentlyAssignedBillingEntityId } }) => {
    let closeDialogEffect = {};
    const notAssignedBillingEntity = !currentlyAssignedBillingEntityId;
    if (notAssignedBillingEntity) {
      closeDialogEffect = reffects.dispatch(CLOSE_DIALOG);
    }

    return {
      ...closeDialogEffect,
      ...state.merge(defaultTaxState()),
    };
  },
  [
    state.get({
      currentlyAssignedBillingEntityId: `${BILLING_ENTITY_BEING_DISPLAYED_STATE}.id`,
    }),
  ]
);

registerEventHandler(
  BILLING_ENTITY_DIALOG_SAVE_EDIT,
  (
    { environment: { apiUrl }, state: { publisherId } },
    { billingEntity, billingEntityFile, onSuccessEvent }
  ) => {
    const data = { publisherId, ...billingEntity };
    const body =
      billingEntity.billRequired === false
        ? onlySendBillRequirementBody(data)
        : sendAllBillingEntityDataBody(data);
    return saveBillingEntityEffects(
      apiUrl,
      billingEntity.id,
      body,
      billingEntityFile,
      onSuccessEvent
    );
  },
  [
    environment(),
    state.get({ publisherId: `${BILLING_MODAL_STATE}.publisherId` }),
  ]
);

registerEventHandler(
  BILLING_ENTITY_DIALOG_SAVED,
  (
    _,
    [__, { onSuccessEvent, billingEntityFile, saveBillingEntityFileUrl }]
  ) => ({
    ...state.merge({
      editBillingEntityFormSubmitted: false,
      ...defaultTaxState(),
    }),
    ...(billingEntityFile
      ? http.post({
          url: saveBillingEntityFileUrl,
          body: addItem('billingEntityFile', billingEntityFile),
        })
      : {}),
    ...toast.show({ text: 'Billing entity saved' }),
    ...reffects.dispatchMany([CLOSE_DIALOG, onSuccessEvent]),
  })
);

registerEventHandler(
  BILLING_ENTITY_FILE_DOWNLOAD,
  ({ environment: { apiUrl } }, { billingEntityId }) =>
    redirectTo(`${apiUrl}/backoffice/billing-entities/${billingEntityId}/file`),
  [environment()]
);

registerEventHandler(
  BILLING_ENTITY_FILE_URL_REDIRECT,
  (_, { url }) => redirectTo(url),
  [environment()]
);

registerEventHandler(ERROR_WHILE_SAVING_BILLING_ENTITY, () => ({
  ...state.set({ editBillingEntityFormSubmitted: false }),

  ...toast.show({
    text: 'Error while creating billing entity',
  }),
}));

function effectForBillingEntityLoaded(billingEntity, apiUrl, onSuccessEventId) {
  if (billingEntity.billRequired === false) {
    return reffects.dispatch({
      id: onSuccessEventId,
      payload: {
        ...billingEntity,
        hasPendingChangeRequests: false,
      },
    });
  }

  return http.get({
    url: `${apiUrl}/backoffice/billing-entity/${billingEntity.id}/pending-subscription-change-requests`,
    successEvent: {
      id: BILLING_ENTITY_HAS_PENDING_CHANGE_REQUESTS_LOADED,
      payload: {
        billingEntity,
        onSuccessEventId,
      },
    },
  });
}

function onlySendBillRequirementBody({ publisherId, billRequired }) {
  return { publisherId, billRequired };
}

function sendAllBillingEntityDataBody(data) {
  const { phone, ...rest } = data;
  return {
    ...rest,
    phone:
      phone?.dialCode && phone?.phoneNumber
        ? `${phone.dialCode}${phone.phoneNumber}`
        : undefined,
  };
}

function saveBillingEntityEffects(
  apiUrl,
  billingEntityId,
  body,
  billingEntityFile,
  onSuccessEvent
) {
  const editBillingEntityUrl = `${apiUrl}/backoffice/billing-entity/${billingEntityId}`;
  const saveBillingEntityFileUrl = `${apiUrl}/billing-entities/${billingEntityId}/file`;
  return {
    ...state.set({
      editBillingEntityFormSubmitted: true,
      hasHaciendaRequestFailed: false,
    }),
    ...http.post({
      url: editBillingEntityUrl,
      body,
      successEvent: {
        id: BILLING_ENTITY_DIALOG_SAVED,
        payload: {
          onSuccessEvent,
          billingEntityFile,
          saveBillingEntityFileUrl,
        },
      },
      errorEvent: ERROR_WHILE_SAVING_BILLING_ENTITY,
    }),
  };
}

function billingEntityInitialState({ country }) {
  return {
    ...INITIAL_BILLING_ENTITY_DATA,
    billRequired: true,
    country,
  };
}

function defaultTaxState() {
  return {
    'admin:billingEntityValidations': undefined,
    'admin:billingEntityTaxId': undefined,
    'admin:billingEntityDialogMode': 'view',
    [BILLING_ENTITY_FILE_DATA]: undefined,
    [BILLING_ENTITY_FILE_IS_LOADED]: undefined,
    [BILLING_ENTITY_FILE]: undefined,
    [BILLING_ENTITY_FILE_LOADING_ERROR]: undefined,
    billingInformationFormBillRequired: true,
    hasHaciendaRequestFailed: false,
  };
}
