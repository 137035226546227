import { useSelector } from 'reffects-store';
import { collectionItemsByIdSelector } from '../selectors/collection';
import { useDatatableContext } from './useDatatableContext';

export default function useTableState(collectionName) {
  const context = useDatatableContext();
  const collection = useSelector((state) =>
    collectionItemsByIdSelector(state, {
      collectionName: collectionName ?? context.collectionName,
    })
  );

  return { collection: Object.values(collection) };
}
