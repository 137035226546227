import { siteDetailLinksSelector } from '../../../../selectors/config';

export function unitSitesLinkSelector(state) {
  const sitesLink = siteDetailLinksSelector(state);

  return ({ id }) => sitesLink({ id, isBoosted: true });
}

export function isBulkUnpublishConfirmModalSelector(state) {
  return state['bulkV2:actionSelected:unpublish'] ?? false;
}
