import { DataTableProvider } from '../../../../../partials/DataTable';
import createLastProjectsAdapter from './lastProjectsAdapter';

export function ProjectsTableProvider({ children }) {
  const context = {
    adapter: createLastProjectsAdapter(),
    collectionName: 'last-projects',
  };

  return <DataTableProvider {...context}>{children}</DataTableProvider>;
}
