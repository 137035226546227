import { createIntl, createIntlCache } from 'react-intl';

// This is optional but highly recommended
// since it prevents memory leak
export default function create(locale, messages, timeZone, onError) {
  const cache = createIntlCache();

  return createIntl(
    {
      locale,
      messages,
      timeZone,
      onError,
    },
    cache
  );
}
