import PricingPlansTable from 'design-system/components/PricingPlansTable/PricingPlansTable';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { createAgencyBenefits, createHeadingCell } from '../benefits';

export default function PlansTable({
  plansHeader,
  benefitsByPlan,
  showLogoOnWebsiteBenefit,
  websWithoutTranslatedAnd,
  sideSection,
}) {
  return (
    <PricingPlansTable
      title={
        <FormattedMessageWithValidation id="subscription_benefits_table_header" />
      }
      plans={plansHeader.map(createHeadingCell)}
      benefits={createAgencyBenefits(
        benefitsByPlan,
        websWithoutTranslatedAnd,
        showLogoOnWebsiteBenefit
      )}
      sideSection={sideSection}
    />
  );
}
