import { useCallback } from 'react';
import { dispatch } from 'reffects';
import { useFormContext } from 'react-hook-form';
import Dialog from 'design-system/components/Dialog/Dialog';
import {
  FlatButton,
  SecondaryButton,
} from 'design-system/components/Button/presets';
import { LEAD_NOTE_EDITED } from '../events';
import { useDialog } from '../../../../../hooks/useDialog';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import TextareaField from '../../../../../partials/fields/TextareaField';
import {
  ENQUIRY_DETAIL_EDIT_NOTE_DIALOG,
  NOTE_MAX_CHARACTERS_LENGTH,
  NOTE_MESSAGE_FIELD_NAME,
} from '../constants';
import TimelineFormProvider from '../Actions/TimelineFormProvider';
import useTracking from '../../../../../hooks/useTracking';

function EditNoteDialog() {
  const { open, parameters, closeDialog } = useDialog(
    ENQUIRY_DETAIL_EDIT_NOTE_DIALOG
  );
  const handleSubmit = useCallback(
    ({ message }) => {
      dispatch({
        id: LEAD_NOTE_EDITED,
        payload: {
          message,
          leadId: parameters.leadId,
          noteId: parameters.noteId,
        },
      });
      closeDialog();
    },
    [closeDialog, parameters]
  );

  return (
    <Dialog open={open}>
      <TimelineFormProvider
        defaultValues={{
          message: parameters?.message,
        }}
        onSubmit={handleSubmit}
        messageMaxLength={NOTE_MAX_CHARACTERS_LENGTH}
      >
        <Dialog.Header>
          <FormattedMessageWithValidation id="enquiry_detail_edit_note_title" />
        </Dialog.Header>
        <Dialog.Content>
          <TextareaField name="message" autoResize autoFocus />
        </Dialog.Content>
        <Dialog.Footer>
          <FlatButton onClick={closeDialog}>
            <FormattedMessageWithValidation id="enquiry_detail_note_cancel" />
          </FlatButton>
          <SubmitButton />
        </Dialog.Footer>
      </TimelineFormProvider>
    </Dialog>
  );
}

function SubmitButton() {
  const { watch } = useFormContext();
  const [message] = watch([NOTE_MESSAGE_FIELD_NAME]);
  const isSubmitDisabled =
    message.length === 0 || message.length > NOTE_MAX_CHARACTERS_LENGTH;

  const trackingRef = useTracking({
    trackClick: {
      eventName: 'note-updated',
    },
    trackImpression: {
      elementName: 'edit-note-dialog',
    },
    sectionName: 'enquiry-activity-timeline-edit-note-dialog',
  });

  return (
    <SecondaryButton
      type="submit"
      disabled={isSubmitDisabled}
      ref={trackingRef}
    >
      <FormattedMessageWithValidation id="enquiry_detail_note_update" />
    </SecondaryButton>
  );
}

export default EditNoteDialog;
