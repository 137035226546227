function leadsStatsSelector(state) {
  return (
    state.leadsStats ?? {
      leads: 0,
      enquiries: 0,
      byStatus: {
        newLead: 0,
        contacted: 0,
        visit: 0,
        proposal: 0,
        won: 0,
        lost: 0,
      },
    }
  );
}

export function leadsReceivedSelector(state) {
  return leadsStatsSelector(state).leads;
}

export function enquiriesReceivedSelector(state) {
  return leadsStatsSelector(state).enquiries;
}

export function leadsByStatusSelector(state) {
  return leadsStatsSelector(state).byStatus;
}

export function leadsByStatusLoadingSelector({ leadsStatsLoading }) {
  return leadsStatsLoading ?? true;
}
