export function parseTimeline(timeline) {
  return timeline.reduce((acc, activity) => {
    if (activity.ad == null && activity.project == null) return acc;
    const property = activity.ad
      ? { ...activity.ad, project: activity.project, type: 'ad' }
      : { ...activity.project, type: 'project' };
    if (!acc.some(({ id }) => id === property.id)) {
      acc.push(property);
    }
    return acc;
  }, []);
}
