import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { environment } from '../../../coeffects/environment';
import { putSubscriptionChangeRequestDetailsInStateEffect } from './partials/AgenciesTable/Modals/putSubscriptionChangeRequestDetailsInStateEffect';
import { toast } from '../../../effects/toast';
import { SUBSCRIPTION_CHANGE_REQUEST_MODAL_ID } from './partials/AgenciesTable/Modals/constants';
import { OPEN_DIALOG } from '../../../events/dialogs';

export const SUBSCRIPTION_CHANGE_REQUEST_DATA_REQUESTED =
  'SUBSCRIPTION_CHANGE_REQUEST_DATA_REQUESTED';
export const SUBSCRIPTION_CHANGE_REQUEST_DATA_REQUEST_SUCCEEDED =
  'SUBSCRIPTION_CHANGE_REQUEST_DATA_REQUEST_SUCCEEDED';
export const SUBSCRIPTION_CHANGE_REQUEST_DATA_REQUEST_FAILED =
  'SUBSCRIPTION_CHANGE_REQUEST_DATA_REQUEST_FAILED';

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_DATA_REQUESTED,
  ({ environment: { apiUrl } }, payload) =>
    http.get({
      url: `${apiUrl}/admin/subscription-change-request/${payload}`,
      successEvent: SUBSCRIPTION_CHANGE_REQUEST_DATA_REQUEST_SUCCEEDED,
      errorEvent: SUBSCRIPTION_CHANGE_REQUEST_DATA_REQUEST_FAILED,
    }),
  [environment()]
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_DATA_REQUEST_SUCCEEDED,
  (_, [{ data }]) => ({
    ...putSubscriptionChangeRequestDetailsInStateEffect(data),
    ...effects.dispatch({
      id: OPEN_DIALOG,
      payload: {
        id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_ID,
      },
    }),
  })
);

registerEventHandler(SUBSCRIPTION_CHANGE_REQUEST_DATA_REQUEST_FAILED, () =>
  toast.show({
    text: 'An error occurred while retrieving subscription change request data',
  })
);
