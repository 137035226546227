import Menu from 'design-system/components/Menu/Menu';
import { dispatch } from 'reffects';
import MarkAsFreeFromBeginningModal from '../../Modals/MarkAsFreeFromBeginningModal/MarkAsFreeFromBeginningModal';
import { OPEN_DIALOG } from '../../../../../../../events/dialogs';
import { MARK_AS_FREE_FROM_BEGINNING_MODAL } from '../../Modals/MarkAsFreeFromBeginningModal/constants';

export function MarkAsFreeFromBeginningAction({ publisher }) {
  return (
    <>
      <Menu.Item
        label="Mark as free from beginning"
        onClick={() => {
          dispatch({
            id: OPEN_DIALOG,
            payload: {
              id: MARK_AS_FREE_FROM_BEGINNING_MODAL,
              parameters: { publisherId: publisher.id },
            },
          });
        }}
      />
      <MarkAsFreeFromBeginningModal />
    </>
  );
}
