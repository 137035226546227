import BreakdownTable from 'design-system/components/BreakdownTable/BreakdownTable';
import { useMemo } from 'react';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  DISCOUNT_NAMES,
  REFUND_DISCOUNTS,
} from '../../../../constants/subscription';
import Price from '../../../../partials/Price/Price';

export default function Discounts({
  subscription: { billingCycleInMonths, nextBillingDate, products, discounts },
}) {
  const { unusedPreviousSubscriptionDays, unusedCurrentSubscriptionDays } =
    products[0];
  const discountTitles = useMemo(
    () => ({
      [DISCOUNT_NAMES.UPFRONT]: (
        <FormattedMessageWithValidation
          id="subscription_summary_discount_upfrontPayment"
          values={{ monthsDuration: billingCycleInMonths }}
        />
      ),
      [DISCOUNT_NAMES.CUSTOM]: (
        <FormattedMessageWithValidation id="subscription_summary_discount_custom" />
      ),
      [DISCOUNT_NAMES.PREVIOUS_SUBSCRIPTION]: (
        <FormattedMessageWithValidation
          id="checkout_upsell_discount_unused_days"
          values={{
            currentSubscriptionRemainingDays: unusedPreviousSubscriptionDays,
          }}
        />
      ),
      [DISCOUNT_NAMES.CURRENT_SUBSCRIPTION]: (
        <FormattedMessageWithValidation
          id="checkout_upsell_discount_remaining_days"
          values={{
            newSubscriptionRemainingDays: unusedCurrentSubscriptionDays,
          }}
        />
      ),
      [DISCOUNT_NAMES.PROPORTIONAL_SUPERBOOST]: (
        <FormattedMessageWithValidation id="subscription_summary_discount_proportionalSuperboost" />
      ),
    }),
    [
      billingCycleInMonths,
      nextBillingDate,
      unusedPreviousSubscriptionDays,
      unusedCurrentSubscriptionDays,
    ]
  );

  return (
    <>
      {Object.values(discounts).map(
        ({ name, amount, currency, percentage }) => (
          <BreakdownTable.DiscountRow
            key={name}
            title={discountTitles[name]}
            price={<Price amount={amount * -1} currency={currency} />}
            discount={
              !REFUND_DISCOUNTS.includes(name) && (
                <FormattedMessageWithValidation
                  id="subscription_summary_price_discount_v2"
                  values={{
                    percentage,
                  }}
                />
              )
            }
          />
        )
      )}
    </>
  );
}
