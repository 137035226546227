import { useSelector } from 'reffects-store';
import { FormattedList } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PrimaryButton } from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import Illustration from 'design-system/components/Illustration/Illustration';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle2 } from 'design-system/components/Typography/presets/Subtitle2';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import FormattedMessageWithSuffixCountry from '../../../../partials/FormattedMessageWithSuffixCountry/FormattedMessageWithSuffixCountry';
import { siteWherePublishingNamesSelector } from '../../../../selectors/config';
import { isDeveloperSelector } from '../../../../selectors/publisher';
import { subscriptionRoute } from '../../../../utils/proppitWebRouter';

const Wrapper = styled.div`
  display: flex;
  ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
  ${radius.regular(RADIUS_SIZE.LARGE)}
  ${spacing.inset(SPACING_SIZE.XL)}
  margin-top: ${spacing.value(SPACING_SIZE.L)};
`;

const Title = styled(Subtitle2).attrs({ as: 'h1' })`
  ${spacing.stack(SPACING_SIZE.M, false)}
`;

const SubscriptionIllustration = styled(Illustration)`
  ${spacing.inline(SPACING_SIZE.L, false)}
`;

const Content = styled.div``;

const List = styled.ul`
  padding-left: 24px;
  margin: 0;
  ${spacing.stack(SPACING_SIZE.L, false)}
`;

const ListItem = styled(Body2).attrs({
  as: 'li',
})`
  line-height: 15px;
  ${color.text(COLOR_PALETTE.NEUTRAL_A80)}
`;

function AgencyContent() {
  const webs = useSelector(siteWherePublishingNamesSelector);
  return (
    <Content>
      <Title>
        <FormattedMessageWithValidation
          id="overview_subscription_banner_header_text"
          values={{
            country: (
              <FormattedMessageWithSuffixCountry id="overview_subscription_banner_header" />
            ),
          }}
        />
      </Title>
      <List>
        <ListItem>
          <FormattedMessageWithValidation
            id="overview_subscription_banner_l1"
            values={{
              networkSites: <FormattedList type="conjunction" value={webs} />,
            }}
          />
        </ListItem>
        <ListItem>
          <FormattedMessageWithValidation id="overview_subscription_banner_l2" />
        </ListItem>
        <ListItem>
          <FormattedMessageWithValidation id="overview_subscription_banner_l3" />
        </ListItem>
      </List>
      <PrimaryButton
        $size={BUTTON_SIZE.SMALL}
        forwardedAs={Link}
        to={subscriptionRoute()}
      >
        <FormattedMessageWithValidation id="overview_subscription_banner_cta" />
      </PrimaryButton>
    </Content>
  );
}

function DeveloperContent() {
  const webs = useSelector(siteWherePublishingNamesSelector);
  return (
    <Content>
      <Title>
        <FormattedMessageWithValidation
          id="overview_developer_subscription_banner_header_text"
          values={{
            country: (
              <FormattedMessageWithSuffixCountry id="overview_subscription_banner_header" />
            ),
          }}
        />
      </Title>
      <List>
        <ListItem>
          <FormattedMessageWithValidation
            id="overview_developer_subscription_banner_l1"
            values={{
              networkSites: <FormattedList type="conjunction" value={webs} />,
            }}
          />
        </ListItem>
        <ListItem>
          <FormattedMessageWithValidation id="overview_developer_subscription_banner_l2" />
        </ListItem>
        <ListItem>
          <FormattedMessageWithValidation id="overview_developer_subscription_banner_l3" />
        </ListItem>
      </List>
      <PrimaryButton
        $size={BUTTON_SIZE.SMALL}
        forwardedAs={Link}
        to={subscriptionRoute()}
      >
        <FormattedMessageWithValidation id="overview_developer_subscription_banner_cta" />
      </PrimaryButton>
    </Content>
  );
}

function SubscriptionDisclaimer() {
  const isDeveloper = useSelector(isDeveloperSelector);
  return (
    <Wrapper>
      <SubscriptionIllustration name="subscription" />
      {isDeveloper ? <DeveloperContent /> : <AgencyContent />}
    </Wrapper>
  );
}

export default SubscriptionDisclaimer;
