import { useFormContext } from 'react-hook-form';

import InputCards from 'design-system/components/InputCards/InputCards';
import Icon from 'design-system/components/Icon';
import { useRegisterFieldErrors } from '../../pages/publication/partials/FieldGroup';

function CardsField({ name, choices, tip, ...rest }) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const inputProps = register(name);

  useRegisterFieldErrors({ name, errors: errors[name] });

  return (
    <InputCards
      error={Boolean(errors[name])}
      tip={errors[name]?.message ?? tip}
      {...rest}
    >
      {choices.map(({ icon, label, value }) => (
        <InputCards.Card
          key={value}
          icon={<Icon glyph={icon} />}
          label={label}
          value={value}
          {...inputProps}
        />
      ))}
    </InputCards>
  );
}

export default CardsField;
