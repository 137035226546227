import styled from 'styled-components';
import { dispatch, dispatchMany } from 'reffects';
import { useSelector } from 'reffects-store';
import { useEffect } from 'react';
import Checkbox from 'design-system/components/Checkbox/Checkbox';
import InputText from 'design-system/components/InputText';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import Section from './Section';
import {
  CREATE_PUBLISHER_FORM_COUNTRY_SELECTED,
  CREATE_PUBLISHER_FORM_THAILAND_PROPERTY_SELECTED,
  CREATE_PUBLISHER_FORM_VALUE_CHANGED,
  MULTI_ACCOUNT_CLIENT_INFORMATION_CLEARED,
  NEW_CLIENT_CHECKBOX_CHANGED,
  PUBLISHER_TYPE_SELECTED,
} from './events';
import {
  errorInvalidUuidSelector,
  isNewClientSelector,
  publisherFormInfoSelector,
  shouldShowPublishInThailandPropertySelector,
  showLoadingAdsModeSelector,
} from './selectors';
import { PUBLISHER_TYPE } from '../../../constants/publisher';
import { LOADING_ADS_MODE } from './constants';
import { publisherCountrySelector } from '../../../selectors/publisher';
import { availableCountriesForBackofficeUserSelector } from '../../selectors/countries';
import InputSelectWithHtmlValidation from '../partials/InputSelectWithHtmlValidation';

const CheckboxLabel = styled(Checkbox.Label)`
  display: inline-flex;
  ${spacing.inline(SPACING_SIZE.M)}
`;

function PublisherName({ value, onChange }) {
  return (
    <InputText
      name="name"
      label="Name"
      value={value}
      required
      onChange={onChange}
    />
  );
}

function PublisherType({ value, ...rest }) {
  return (
    <InputSelectWithHtmlValidation
      name="type"
      label="Type"
      required
      value={value}
      id="type"
      choices={[
        { label: 'Agency', value: PUBLISHER_TYPE.AGENCY },
        { label: 'Developer', value: PUBLISHER_TYPE.DEVELOPER },
      ]}
      {...rest}
    />
  );
}

function Country({ availableCountries, value }) {
  const countryCode = useSelector(publisherCountrySelector);
  useEffect(
    () =>
      dispatch({
        id: CREATE_PUBLISHER_FORM_COUNTRY_SELECTED,
        payload: countryCode,
      }),
    [countryCode]
  );

  return (
    <InputSelectWithHtmlValidation
      name="country"
      label="Country"
      required
      value={value}
      onSelect={onCountrySelectorChanged}
      choices={availableCountries.map(({ code, name: label }) => ({
        label,
        value: code,
      }))}
    />
  );
}

function LoadingAdsMode({ value, onSelect }) {
  const showLoadingAdsMode = useSelector(showLoadingAdsModeSelector);

  return showLoadingAdsMode ? (
    <InputSelectWithHtmlValidation
      name="loadingAdsMode"
      label="Publication"
      required
      value={value}
      onSelect={onSelect}
      choices={[
        { label: 'CRM', value: LOADING_ADS_MODE.CRM },
        { label: 'XML', value: LOADING_ADS_MODE.XML },
        { label: 'Manual', value: LOADING_ADS_MODE.MANUAL },
      ]}
    />
  ) : (
    <div />
  );
}

function PublisherId({ value, onChange, error, disabled }) {
  return (
    <InputText
      type="text"
      name="id"
      label="Publisher ID"
      value={value}
      onChange={onChange}
      error={error}
      required
      disabled={disabled}
      helperText={
        error &&
        'UUID format is not valid. Ex: 123e4567-e89b-12d3-a456-426614174000'
      }
    />
  );
}

function PublishInThailandProperty({ onChange }) {
  return (
    <CheckboxLabel>
      <Checkbox name="publishInThailandProperty" onChange={onChange} />
      <Body1>Thailand Property Client</Body1>
    </CheckboxLabel>
  );
}

function PublisherDetails({ onInputChange, onCheckboxChange, ...props }) {
  const availableCountries = useSelector(
    availableCountriesForBackofficeUserSelector
  );
  const publisher = useSelector(publisherFormInfoSelector);
  const errorInvalidUUID = useSelector(errorInvalidUuidSelector);
  const isNewPublisher = useSelector(isNewClientSelector);
  const showPublishInThailandProperty = useSelector(
    shouldShowPublishInThailandPropertySelector
  );
  return (
    <Section title="Publisher details" {...props}>
      <Country
        availableCountries={availableCountries}
        value={publisher.country}
      />
      <PublisherType
        value={publisher.type}
        onSelect={({ value }) =>
          dispatch({
            id: PUBLISHER_TYPE_SELECTED,
            payload: value,
          })
        }
      />
      <PublisherName value={publisher.name} onChange={onInputChange} />
      <LoadingAdsMode
        value={publisher.loadingAdsMode}
        onSelect={({ value }) =>
          dispatch({
            id: CREATE_PUBLISHER_FORM_VALUE_CHANGED,
            payload: { name: 'loadingAdsMode', value },
          })
        }
      />
      <InputSelectWithHtmlValidation
        name="type"
        label="New publisher/Existing publisher"
        required
        value={isNewPublisher ? 'new' : 'migrated'}
        id="type"
        onSelect={onNewPublisherSelected}
        choices={[
          { label: 'New publisher', value: 'new' },
          { label: 'Existing publisher', value: 'migrated' },
        ]}
      />
      <PublisherId
        value={publisher.id}
        onChange={onInputChange}
        disabled={isNewPublisher}
        error={errorInvalidUUID}
      />
      <div>
        <CheckboxLabel>
          <Checkbox name="isTestPublisher" onChange={onCheckboxChange} />
          <Body1>Test Publisher</Body1>
        </CheckboxLabel>
        {showPublishInThailandProperty && (
          <PublishInThailandProperty
            onChange={onPublishInThailandPropertyChanged}
          />
        )}
        <CheckboxLabel>
          <Checkbox name="isWasiPublisher" onChange={onWasiPublisherChanged} />
          <Body1>Wasi Publisher (Only for support, don’t use it)</Body1>
        </CheckboxLabel>
      </div>
    </Section>
  );
}

function onNewPublisherSelected({ value }) {
  dispatch({
    id: NEW_CLIENT_CHECKBOX_CHANGED,
    payload: { isNewPublisher: value === 'new' },
  });
}

function onCountrySelectorChanged({ value }) {
  dispatch({
    id: CREATE_PUBLISHER_FORM_COUNTRY_SELECTED,
    payload: value,
  });
}

function onPublishInThailandPropertyChanged(event) {
  dispatch({
    id: CREATE_PUBLISHER_FORM_THAILAND_PROPERTY_SELECTED,
    payload: { publishInThailandProperty: event.target.checked },
  });
}

function onWasiPublisherChanged(event) {
  dispatchMany([
    {
      id: CREATE_PUBLISHER_FORM_VALUE_CHANGED,
      payload: {
        name: 'source',
        value: event.target.checked ? 'wasi' : undefined,
      },
    },
    MULTI_ACCOUNT_CLIENT_INFORMATION_CLEARED,
  ]);
}

export default styled(PublisherDetails)``;
