import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import Stepper, { STEPPER_ITEM_STATUS } from 'design-system/components/Stepper';
import Layout from 'design-system/components/Layout';
import Anchor from 'design-system/components/Anchor';
import { radius } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import Typography, {
  TYPOGRAPHY_VARIANT,
} from 'design-system/components/Typography/Typography';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Overline } from 'design-system/components/Typography/presets/Overline';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  csvFileErrorsByFieldSelector,
  csvFileStepStatusSelector,
  getStepsForSideBarSelector,
} from '../selectors';

const Wrapper = styled.div`
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Header = styled.div`
  ${spacing.stack(SPACING_SIZE.L)}
  ${Heading1} {
    display: block;
    ${spacing.stack(SPACING_SIZE.M)}
  }
`;

const Bullet = styled(Stepper.Item)`
  span > li {
    margin-top: 16px;
  }

  ${(props) =>
    props.isBelowSpecialBullet &&
    `
    ::before{
      height: 100px; 
      z-index: -1;
    }
  `}
  ${Anchor} {
    text-decoration: none;

    ${Typography.mods.variant(TYPOGRAPHY_VARIANT.SUBTITLE_3)}
    ${color.text(COLOR_PALETTE.SECONDARY_BASE)}
        > * {
      ${spacing.inline(SPACING_SIZE.S)}
    }

    ${Overline} {
      ${color.background(COLOR_PALETTE.STRAWBERRY_BASE)}
      ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
      ${radius.pill()}
      padding: 0 ${spacing.value(SPACING_SIZE.S)};
    }
  }
`;

const ChildrenBullets = styled.div`
  margin-left: ${spacing.value(SPACING_SIZE.XL)};
  margin-bottom: ${spacing.value(SPACING_SIZE.M)};
`;

const STEPPER = {
  CURRENT: STEPPER_ITEM_STATUS.CURRENT,
  ERROR: STEPPER_ITEM_STATUS.ERROR,
  COMPLETED: STEPPER_ITEM_STATUS.COMPLETED,
};

function GroupValidationBullet({
  id,
  title,
  order,
  subGroup,
  isBelowSpecialBullet,
}) {
  const errors = useSelector((state) =>
    csvFileErrorsByFieldSelector(state, id)
  );
  const hasErrors = errors.length > 0;
  const status = useSelector((state) =>
    csvFileStepStatusSelector(state, order)
  );
  const showStatus = hasErrors ? STEPPER.ERROR : STEPPER[status];

  return (
    <>
      <Bullet
        key={id}
        status={showStatus}
        isBelowSpecialBullet={isBelowSpecialBullet}
      >
        <Anchor href={`#${id}`}>
          <FormattedMessageWithValidation id={title} />
          {hasErrors && <Overline>{errors.length}</Overline>}
        </Anchor>
      </Bullet>

      {subGroup && (
        <ChildrenBullets>
          {subGroup.map((child) => (
            <GroupValidationBullet
              key={child.id}
              id={child.id}
              title={child.title}
              order={child.order}
            />
          ))}
        </ChildrenBullets>
      )}
    </>
  );
}

function Sidebar({ title, description, children }) {
  const groups = useSelector(getStepsForSideBarSelector);
  return (
    <Wrapper>
      <Layout.Padded>
        <Header>
          <Heading1>{title}</Heading1>
          <Body2 $color={COLOR_PALETTE.NEUTRAL_A60}>{description}</Body2>
        </Header>
        <Stepper>
          {groups.map((group) => (
            <GroupValidationBullet
              key={group.id}
              id={group.id}
              title={group.title}
              order={group.order}
              subGroup={group.children}
              isBelowSpecialBullet={group.isBelowSpecialBullet}
            />
          ))}
        </Stepper>
      </Layout.Padded>
      <Layout.Padded>{children}</Layout.Padded>
    </Wrapper>
  );
}

export default Sidebar;
