import { coeffect, registerCoeffectHandler } from 'reffects';
import { mapValues } from 'lodash';
import { store } from 'reffects-store';

export function select(extractions) {
  return coeffect('select', extractions);
}

registerCoeffectHandler('select', (extractions) => {
  const result = mapValues(
    extractions,
    (selector) =>
      (...args) =>
        selector(store.getState(), ...args)
  );
  return { select: result };
});
