export function isLoadingLinksSelector(state) {
  return (
    state['propertyLinks:value'] == null &&
    state['propertyLinks:failed'] == null
  );
}

export function showByDotPropertyLogoSelector(state) {
  return (propertyLinksSelector(state) ?? []).some(
    ({ site }) => site.toLowerCase() === 'dotproperty'
  );
}

export function propertyLinksSelector(state) {
  return state['propertyLinks:value'];
}

export function propertyLinksHaveExpiredSelector(state) {
  return Boolean(state['propertyLinks:failed']);
}
