import Dialog from 'design-system/components/Dialog/Dialog';
import { useDialog } from '../../../hooks/useDialog';
import { PUBLISHER_PROFILE_EDIT_DIALOG } from '../constants';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import EditPublisherForm from './EditPublisherForm';

export default function EditPublisherModal() {
  const { open, closeDialog } = useDialog(PUBLISHER_PROFILE_EDIT_DIALOG);

  return (
    <Dialog open={open} onClose={closeDialog}>
      <Dialog.Header onClose={closeDialog}>
        <FormattedMessageWithValidation id="company_profile_edit_company_info_dialog_title" />
      </Dialog.Header>

      {open && <EditPublisherForm />}
    </Dialog>
  );
}
