import styled from 'styled-components';
import { COLOR_PALETTE } from '../../../styles/color';
import Typography, { TYPOGRAPHY_VARIANT } from '../Typography';

import BasePresetTypography from './BasePresetTypography';

export const Heading1 = styled(BasePresetTypography)`
  ${Typography.mods.variant(TYPOGRAPHY_VARIANT.HEADING_1)}
`;

Heading1.defaultProps = {
  $color: COLOR_PALETTE.SECONDARY_BASE,
};
