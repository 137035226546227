import { isMexico } from '../../../../../../../utils/countries';
import {
  BILLING_ENTITY_BEING_DISPLAYED_STATE,
  BILLING_ENTITY_BEING_EDITED_STATE,
  BILLING_MODAL_STATE,
} from './constants';
import { BILLING_ENTITY_FILE_DISABLE_SUBMIT } from '../../../../../../../pages/Checkout/BillingInformation/constants';
import {
  billRequiredSelector,
  hasHaciendaRequestFailedSelector,
} from '../../../../../../../pages/Checkout/BillingInformation/partials/Form/selectors';

function billingEntityModalSelector(state) {
  return state[`${BILLING_MODAL_STATE}`];
}

export function billingEntityDataSelector(state) {
  return state[BILLING_ENTITY_BEING_EDITED_STATE];
}

export function displayedBillingEntityDataSelector(state) {
  return state[BILLING_ENTITY_BEING_DISPLAYED_STATE];
}

export function formSubmittedSelector(state) {
  return state.editBillingEntityFormSubmitted ?? false;
}

export function billingEntityValidationsSelector(state) {
  return state['admin:billingEntityValidations'];
}

export function billingEntityTaxIdSelector(state) {
  return state['admin:billingEntityTaxId'];
}

export function isEditingBillingEntitySelector(state) {
  return state['admin:billingEntityDialogMode'] === 'edit';
}

export function billingEntityFileUrlSelector(state) {
  return billingEntityDataSelector(state)?.fileUrl;
}

export function billingEntityHasFileUploadedSelector(state) {
  return billingEntityDataSelector(state)?.hasFileUploaded;
}

export function selectedPublisherIdSelector(state) {
  return billingEntityModalSelector(state)?.publisherId;
}

export function selectedPublisherCountrySelector(state) {
  return billingEntityModalSelector(state)?.countryCode;
}

export function loadingBillingEntitySelector(state) {
  return billingEntityModalSelector(state)?.isLoading;
}

export function submitButtonEnabledSelector(state) {
  return !formSubmittedSelector(state) && !loadingBillingEntitySelector(state);
}

export function hasPendingChangeRequestsSelector(state) {
  return billingEntityDataSelector(state)?.hasPendingChangeRequests;
}

export function emphasisMessageSelector(state) {
  if (loadingBillingEntitySelector(state)) {
    return null;
  }
  const billingEntity = billingEntityDataSelector(state);

  if (!billingEntity.billRequired) {
    return null;
  }

  if (billingEntity.id == null && billingEntity.taxId) {
    return 'A new billing entity will be created';
  }

  const numOfAssignedPublishers = billingEntity.publishersAssigned?.length;

  const assignedPublisherIsCurrentPublisher =
    billingEntity.publishersAssigned.at(0) ===
    selectedPublisherIdSelector(state);
  if (numOfAssignedPublishers === 1 && assignedPublisherIsCurrentPublisher) {
    return null;
  }

  if (numOfAssignedPublishers >= 1) {
    return `This billing entity is already assigned to ${numOfAssignedPublishers} publishers`;
  }

  return null;
}

export function taxIdSearchTextSelector(state) {
  return billingEntityModalSelector(state)?.taxIdSearchText;
}

export function formCountryCodeValueSelector(state) {
  return (
    billingEntityDataSelector(state)?.country ||
    selectedPublisherCountrySelector(state)
  );
}

export function selectedPublisherIsFromMexicoSelector(state) {
  return isMexico(selectedPublisherCountrySelector(state));
}

export function shouldShowFormOnMxEditSelector(state) {
  return billRequiredSelector(state) && hasHaciendaRequestFailedSelector(state);
}

export function shouldShowUploadCedulaFiscalSelector(state) {
  return (
    billRequiredSelector(state) && !hasHaciendaRequestFailedSelector(state)
  );
}

export function billingEntityFileDisableSubmitForEditSelector(state) {
  if (hasHaciendaRequestFailedSelector(state)) {
    return false;
  }
  return (
    (state?.[BILLING_ENTITY_FILE_DISABLE_SUBMIT] ?? true) &&
    isMexico(selectedPublisherCountrySelector(state))
  );
}
