import styled, { css } from 'styled-components';

const repeat = (length, name) => Array.from({ length }).fill(name).join(' ');

const provideGridUtils = (definition) => {
  const utils = {
    repeat,
  };

  return definition(utils);
};

const modAreas = (areas) => css`
  grid-template-areas: ${areas};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(var(--layout-columns), 1fr);
  column-gap: var(--layout-gutter);

  ${({ areas }) => areas && modAreas(provideGridUtils(areas))}
`;

const Area = styled.div`
  ${({ $id }) => css`
    grid-area: ${$id};
  `}
`;

Grid.Area = Area;

export default Grid;
