import { useSelector } from 'reffects-store';
import DesignSystemToast from 'design-system/components/Toast';
import {
  isToastVisibleSelector,
  toastTextSelector,
  toastTextValuesSelector,
} from './selectors';
import FormattedMessageWithValidation from '../FormattedMessageWithValidation/FormattedMessageWithValidation';

function Toast() {
  const open = useSelector(isToastVisibleSelector);
  const messageId = useSelector(toastTextSelector);
  const values = useSelector(toastTextValuesSelector);

  return (
    <DesignSystemToast open={open}>
      <FormattedMessageWithValidation id={messageId} values={values} />
    </DesignSystemToast>
  );
}

export default Toast;
