import { uniqueId } from 'lodash';
import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { InMemoryTable } from '../../../../partials/DataTable/adapters/inMemoryTableAdapter/InMemoryTable';
import { table } from '../../../../partials/DataTable/effects/table';
import { environment } from '../../../../coeffects/environment';

export default function createPromotedProjectsAdapter() {
  const inMemoryTable = InMemoryTable.create();
  const eventSuffix = uniqueId('promotedProjects');

  const UPDATE_ITEMS_TABLE_DATA = `UPDATE_ITEMS_TABLE_DATA_${eventSuffix}`;
  const UPDATE_ITEMS_TABLE_DATA_SUCCESS = `UPDATE_ITEMS_TABLE_DATA_SUCCESS_${eventSuffix}`;

  registerEventHandler(
    UPDATE_ITEMS_TABLE_DATA,
    ({ environment: { apiUrl } }, context) =>
      http.get({
        url: `${apiUrl}/developers/me/promoted-projects`,
        successEvent: [UPDATE_ITEMS_TABLE_DATA_SUCCESS, context],
      }),
    [environment()]
  );

  registerEventHandler(
    UPDATE_ITEMS_TABLE_DATA_SUCCESS,
    (_, [response, context]) => {
      const promotedProjects = response.data;

      const { visibleRowsIdList, totalRows } = inMemoryTable
        .setCollection(promotedProjects)
        .get();

      return table.update(context.collectionName, {
        collection: promotedProjects,
        totalItems: totalRows,
        visibleItemsIdList: visibleRowsIdList,
      });
    }
  );

  function registerEvents() {
    return {
      collection: UPDATE_ITEMS_TABLE_DATA,
      events: {
        UPDATE_ITEMS_TABLE_DATA,
        UPDATE_ITEMS_TABLE_DATA_SUCCESS,
      },
    };
  }

  return {
    registerEvents,
  };
}
