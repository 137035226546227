import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { environment } from '../../../../../../../coeffects/environment';
import { toast } from '../../../../../../../effects/toast';
import { CLOSE_DIALOG, OPEN_DIALOG } from '../../../../../../../events/dialogs';

import { ASSIGN_DEAL_MODAL, ASSIGN_DEAL_MODAL_STATE_REGION } from './constants';

export const ASSIGN_DEAL_MODAL_OPENED = 'ASSIGN_DEAL_MODAL_OPENED';
export const ASSIGN_DEAL_MODAL_SUBMITTED = 'ASSIGN_DEAL_MODAL_SUBMITTED';
export const ASSIGN_DEAL_MODAL_SUBMIT_SUCCESS =
  'ASSIGN_DEAL_MODAL_SUBMIT_SUCCESS';
export const ASSIGN_DEAL_MODAL_SUBMIT_FAILED =
  'ASSIGN_DEAL_MODAL_SUBMIT_FAILED';

registerEventHandler(
  ASSIGN_DEAL_MODAL_OPENED,
  (_, { publisherId, dealId }) => ({
    ...effects.dispatch({
      id: OPEN_DIALOG,
      payload: { id: ASSIGN_DEAL_MODAL, parameters: { publisherId } },
    }),
    ...state.set({
      [ASSIGN_DEAL_MODAL_STATE_REGION]: { previouslySelectedDealId: dealId },
    }),
  })
);

registerEventHandler(
  ASSIGN_DEAL_MODAL_SUBMITTED,
  ({ environment: { apiUrl } }, { publisherId, dealId, onSuccessEvent }) => ({
    ...state.set({
      [`${ASSIGN_DEAL_MODAL_STATE_REGION}.loading`]: true,
    }),
    ...http.put({
      url: `${apiUrl}/backoffice/publishers/${publisherId}/assign-deal`,
      body: {
        dealId,
      },
      successEvent: {
        id: ASSIGN_DEAL_MODAL_SUBMIT_SUCCESS,
        payload: { onSuccessEvent },
      },
      errorEvent: ASSIGN_DEAL_MODAL_SUBMIT_FAILED,
    }),
  }),
  [environment()]
);

registerEventHandler(
  ASSIGN_DEAL_MODAL_SUBMIT_SUCCESS,
  (_, [__, { onSuccessEvent } = {}]) => ({
    ...state.set({
      [`${ASSIGN_DEAL_MODAL_STATE_REGION}.loading`]: false,
    }),
    ...effects.dispatchMany([CLOSE_DIALOG, onSuccessEvent]),
  })
);

registerEventHandler(ASSIGN_DEAL_MODAL_SUBMIT_FAILED, () => ({
  ...state.set({
    [`${ASSIGN_DEAL_MODAL_STATE_REGION}.loading`]: false,
  }),
  ...toast.show({ text: 'Deal association failed!' }),
  ...effects.dispatch(CLOSE_DIALOG),
}));
