import styled from 'styled-components';
import Map from 'design-system/components/Map';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import {
  FONT_FAMILY,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import Section from '../Section';

const Wrapper = styled(Section)`
  ${Map} {
    height: 480px;
  }

  > div > * {
    margin: 0;
    ${spacing.stack(SPACING_SIZE.XS)}
  }
`;

const Address = styled.p`
  ${typography.font(FONT_FAMILY.PRIMARY)}
  ${typography.weight(FONT_WEIGHT.NORMAL)}
  ${typography.size(16)}
  ${typography.lineHeight(20)}
`;

const GeoLevels = styled.p`
  ${typography.font(FONT_FAMILY.PRIMARY)}
  ${typography.weight(FONT_WEIGHT.LIGHT)}
  ${color.text(COLOR_PALETTE.NEUTRAL_A80)}
  ${typography.size(15)}
  ${typography.lineHeight(20)}
`;

function Location({ project, ...props }) {
  const geoLevels = project.geoLevels
    .map(({ name }) => name)
    .reverse()
    .join(', ');
  return (
    <Wrapper
      title={
        <FormattedMessageWithValidation id="project_detail_location_title" />
      }
      {...props}
    >
      <Map
        coordinates={{
          latitude: project.latitude,
          longitude: project.longitude,
        }}
        showMarker
      />
      <div>
        <Address>{project.address}</Address>
        <GeoLevels>{geoLevels}</GeoLevels>
      </div>
    </Wrapper>
  );
}

export default styled(Location)``;
