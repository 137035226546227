import styled from 'styled-components';
import { divider, DIVIDER_SIZE } from 'design-system/styles/divider';
import Table from 'design-system/components/Table';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import {
  color,
  COLOR_PALETTE,
  COLOR_SEMANTIC,
} from 'design-system/styles/color';
import { typography } from 'design-system/styles/typography';
import { Overline } from 'design-system/components/Typography/presets/Overline';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import RowsLabel from '../../Tables/RowsLabel';

const Wrapper = styled.table`
  width: 100%;
  border-collapse: collapse;

  ${spacing.stack(SPACING_SIZE.XL)}
`;

const Head = styled.thead`
  ${color.background(COLOR_PALETTE.NEUTRAL_A05)}
`;

const Body = styled.tbody``;

function Footer({ children }) {
  return (
    <tfoot>
      <tr>
        <td colSpan={2}>{children}</td>
      </tr>
    </tfoot>
  );
}

const HeadRow = styled.tr`
  ${divider.bottom(DIVIDER_SIZE.THIN)}
`;

const Row = styled.tr`
  cursor: pointer;

  ${divider.bottom(DIVIDER_SIZE.THIN)}
  ${color.background(COLOR_SEMANTIC.PAPER)}
    ${Icon} {
    display: none;
  }

  :hover {
    ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
    ${Icon} {
      display: inline-block;
    }
  }
`;

const HeadCell = styled.th`
  ${spacing.insetSandwich(SPACING_SIZE.S)}
  ${typography.noWrap()}
    :first-child {
    width: 60%;
  }

  :last-child {
    width: 40%;
  }
`;

const HeadCellContent = styled.div`
  display: flex;
  align-items: center;

  ${spacing.insetSquish(SPACING_SIZE.M)}
  ${Icon} {
    ${Icon.mods.size(ICON_SIZE.SMALL)}
  }

  > * {
    ${spacing.inline(SPACING_SIZE.M)}
  }
`;

const Cell = styled.td`
  text-align: left;
  ${spacing.inset(SPACING_SIZE.M)}
`;

const EditableValue = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NoIconHeight = styled.div`
  height: 1px;

  ${Icon} {
    transform: translateY(-50%);
  }
`;

const byColumnOrder = (columns) => (detailA, detailB) => {
  const detailAColumnIndex = columns.findIndex(
    ({ id }) => id === detailA.column
  );

  const detailBColumnIndex = columns.findIndex(
    ({ id }) => id === detailB.column
  );

  return detailAColumnIndex - detailBColumnIndex;
};

function DetailTable({ columns, details, pagination, onRowClick, onPaginate }) {
  const handleRowClick = (clickedRow) => {
    onRowClick(clickedRow);
  };

  return (
    <Wrapper>
      <Head>
        <HeadRow>
          {columns.map(({ id, title, icon }) => (
            <HeadCell key={id}>
              <HeadCellContent>
                {icon && <NoIconHeight>{icon}</NoIconHeight>}
                <Overline $color={COLOR_PALETTE.NEUTRAL_A80}>{title}</Overline>
              </HeadCellContent>
            </HeadCell>
          ))}
        </HeadRow>
      </Head>
      <Body>
        {details.map((detailsRow, rowIndex) => (
          <Row key={rowIndex} onClick={() => handleRowClick(detailsRow)}>
            {detailsRow
              .sort(byColumnOrder(columns))
              .map(({ value }, cellIndex) => (
                <Cell key={cellIndex}>
                  <EditableValue>
                    <Body1 $color={COLOR_PALETTE.NEUTRAL_A80}>{value}</Body1>
                    {cellIndex === detailsRow.length - 1 && (
                      <NoIconHeight>
                        <Icon
                          $color={COLOR_PALETTE.NEUTRAL_A80}
                          $size={ICON_SIZE.SMALL}
                          glyph="pencil"
                        />
                      </NoIconHeight>
                    )}
                  </EditableValue>
                </Cell>
              ))}
          </Row>
        ))}
      </Body>
      <Footer>
        <Table.Pagination
          currentPage={pagination.currentPage}
          totalPages={pagination.totalPages}
          currentRowRange={pagination.currentRowRange}
          totalRows={pagination.totalRows}
          rowsPerPage={pagination.rowsPerPage}
          selectableRowsPerPage={false}
          displayRowsLabel={(currentRowRange, totalRows) => (
            <RowsLabel
              currentRowRange={currentRowRange}
              totalRows={totalRows}
            />
          )}
          onPaginate={onPaginate}
          onRowsPerPageChange={() => {}}
        />
      </Footer>
    </Wrapper>
  );
}

export default DetailTable;
