import styled from 'styled-components';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import { useColumnSelection } from '../../hooks/useColumnSelection';
import { BulkCheckbox } from '../../partials/BulkCheckbox';

import FormattedMessageWithValidation from '../../../FormattedMessageWithValidation/FormattedMessageWithValidation';
import { Action } from './Action';

const Wrapper = styled.tr`
  position: absolute;
  width: 100%;
  top: 0;
  height: 42px;
  ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
  display: ${(props) => (props.open ? 'table' : 'none')};
`;

const Banner = styled.td`
  position: absolute;
  width: 100%;
  top: -2px;
  padding: 0;
  display: flex;
  align-items: center;
  box-shadow: 0px -1px 0px #fff inset;

  ${radius.regular(RADIUS_SIZE.LARGE)}
  ${color.background(COLOR_PALETTE.NEUTRAL_A05)}
`;

const Selections = styled.div`
  display: flex;
  align-items: center;
  padding: ${spacing.value(0)} ${spacing.value(16)};

  > * {
    ${spacing.inline(SPACING_SIZE.M, false)}
  }
`;

const Divider = styled.span`
  height: 24px;
  width: 1px;
  opacity: 0.3;
  ${color.background(COLOR_PALETTE.NEUTRAL_A40)}
`;

const Actions = styled.div`
  position: relative;
  padding: ${spacing.value(6)} ${spacing.value(16)};

  > * {
    ${spacing.inline(SPACING_SIZE.S)}
  }
`;

export function ActionsMenu({ children }) {
  const { selectedItemsCount, areSomeSelected } = useColumnSelection();

  return (
    <Wrapper open={areSomeSelected}>
      <Banner>
        <Selections>
          <BulkCheckbox />
          <Body2
            $color={COLOR_PALETTE.NEUTRAL_A60}
            $weight={FONT_WEIGHT.NORMAL}
          >
            {selectedItemsCount}{' '}
            <FormattedMessageWithValidation id="propspage_bulk_n_properties_selected" />
          </Body2>
        </Selections>
        <Divider />
        <Actions>{children}</Actions>
      </Banner>
    </Wrapper>
  );
}

ActionsMenu.Action = Action;
