export default function dateFilterRenderLabel(options, value) {
  if (value == null) {
    return null;
  }

  const currentOption = options.find((option) => {
    if (option.value == null) {
      return false;
    }

    return (
      optionIsEqualToValue(option.value.startDate, value?.startDate) &&
      optionIsEqualToValue(option.value.endDate, value?.endDate)
    );
  });

  return currentOption.label;
}

function optionIsEqualToValue(option, value) {
  return (
    option === value || option?.toUTCString() === new Date(value).toUTCString()
  );
}
