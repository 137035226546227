import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { CLOSE_DIALOG } from '../../../../../../../events/dialogs';
import { environment } from '../../../../../../../coeffects/environment';
import { toast } from '../../../../../../../effects/toast';

export const MARK_AS_FREE_FROM_BEGINNING = 'MARK_AS_FREE_FROM_BEGINNING';
export const MARK_AS_FREE_FROM_BEGINNING_SUCCESS =
  'MARK_AS_FREE_FROM_BEGINNING_SUCCESS';
export const MARK_AS_FREE_FROM_BEGINNING_ERROR =
  'MARK_AS_FREE_FROM_BEGINNING_ERROR';

registerEventHandler(
  MARK_AS_FREE_FROM_BEGINNING,
  ({ environment: { apiUrl } }, { publisherId, reason, updateTableEvent }) =>
    http.put({
      url: `${apiUrl}/backoffice/publishers/${publisherId}/mark-as-free`,
      body: { reason },
      successEvent: {
        id: MARK_AS_FREE_FROM_BEGINNING_SUCCESS,
        payload: { updateTableEvent },
      },
      errorEvent: {
        id: MARK_AS_FREE_FROM_BEGINNING_ERROR,
        payload: { updateTableEvent },
      },
    }),
  [environment()]
);

registerEventHandler(
  MARK_AS_FREE_FROM_BEGINNING_SUCCESS,
  (_, [__, { updateTableEvent }]) => ({
    ...effects.dispatchMany([CLOSE_DIALOG, updateTableEvent]),
    ...toast.show({ text: 'Publisher marked as free' }),
  })
);

registerEventHandler(
  MARK_AS_FREE_FROM_BEGINNING_ERROR,
  (_, [__, { updateTableEvent }]) => ({
    ...effects.dispatchMany([CLOSE_DIALOG, updateTableEvent]),
    ...toast.show({ text: 'Failed to mark publisher as free' }),
  })
);
