import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { divider, DIVIDER_SIZE } from 'design-system/styles/divider';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useMount } from 'react-use';
import Layout from 'design-system/components/Layout';
import { Navigation } from '../../partials';
import { LOAD_PROJECT } from './events';
import Header from './partials/Header';
import Location from './partials/Location';
import Amenities from './partials/Amenities';
import Description from './partials/Description';
import Units from './partials/Units';
import Status from './partials/Status';
import { projectSelector } from './selectors';
import NearbyLocations from './partials/NearbyLocations';
import Media from './partials/Media';
import { tableIsLoadingSelector, tableRowsSelector } from '../Tables/selectors';
import { PROJECT_UNITS_TABLE } from './partials/Units/constants';
import { isPublisherFromSEASelector } from '../../selectors/publisher';

const Wrapper = styled.div`
  margin: 0 auto;
  width: 1216px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Divider = styled.div`
  ${divider.bottom(DIVIDER_SIZE.THIN)}
  ${spacing.stack(SPACING_SIZE.L)}
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

function ProjectDetail() {
  const { projectId } = useParams();
  useMount(() => dispatch({ id: LOAD_PROJECT, payload: { projectId } }));
  const project = useSelector(projectSelector);
  const units = useSelector((state) =>
    tableRowsSelector(state, PROJECT_UNITS_TABLE)
  );
  const isLoading = useSelector((state) =>
    tableIsLoadingSelector(state, PROJECT_UNITS_TABLE)
  );
  const isFromSEA = useSelector(isPublisherFromSEASelector);
  if (!project) {
    return null;
  }
  const hasDescriptionBlock =
    project.description ||
    project.buildings ||
    project.floors ||
    project.propertyType;
  const hasUnitsBlock = !isLoading && units.length > 0;
  return (
    <Layout>
      <Layout.Header>
        <Navigation />
      </Layout.Header>
      <Layout.Content>
        <Layout.Padded>
          <Wrapper>
            <Header project={project} />
            <Divider />
            {!isFromSEA && (
              <>
                <StatusWrapper>
                  <Status project={project} />
                  <Media project={project} />
                </StatusWrapper>
                <Divider />
              </>
            )}
            {(hasDescriptionBlock || hasUnitsBlock) && (
              <>
                {isFromSEA ? (
                  <Row>
                    <Description project={project} />
                    <Media project={project} />
                  </Row>
                ) : (
                  <Description project={project} />
                )}
                <Units project={project} />
                <Divider />
              </>
            )}
            {project.amenities.length > 0 && (
              <>
                <Amenities project={project} />
                <Divider />
              </>
            )}
            <Location project={project} />
            <NearbyLocations project={project} />
            <Divider />
          </Wrapper>
        </Layout.Padded>
      </Layout.Content>
    </Layout>
  );
}

export default ProjectDetail;
