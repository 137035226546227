import Layout from 'design-system/components/Layout';
import styled from 'styled-components';

import { border, BORDER_SIZE } from 'design-system/styles/border';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import Banner, { BANNER_VARIANT } from 'design-system/components/Banner';
import { FONT_SIZE, FONT_WEIGHT } from 'design-system/styles/typography';
import { PrimaryButton } from 'design-system/components/Button/presets';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import LegalLinks from '../../partials/LegalLinks/LegalLinks';
import { BrandLogoForAnonymousUsers } from '../../partials/BrandLogo';
import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { emailSelector } from './selectors';
import { SELFSERVICE_ERROR_GO_BACK } from './events';

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  svg {
    ${spacing.stack(SPACING_SIZE.M)}
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 464px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing.value(SPACING_SIZE.M)};

  > svg {
    margin: 0;
  }

  ${border.inner({
    size: BORDER_SIZE.BASE,
    color: COLOR_PALETTE.NEUTRAL_A10,
  })}
  ${spacing.inset(SPACING_SIZE.XL)}
    ${spacing.stack(SPACING_SIZE.XL)}
    ${radius.regular(RADIUS_SIZE.LARGE)}
`;

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing.value(SPACING_SIZE.M)};

  ${Banner} {
    padding: ${spacing.value(SPACING_SIZE.M)};

    span {
      font-size: ${FONT_SIZE.M}px;
    }
  }
`;

const Footer = styled.footer`
  display: flex;
  justify-content: center;
`;

export default function SelfServiceError() {
  const email = useSelector(emailSelector);

  return (
    <Layout>
      <Layout.Content>
        <Wrapper>
          <Content>
            <Card>
              <BrandLogoForAnonymousUsers />
              <ErrorWrapper>
                <Heading1>
                  <FormattedMessageWithValidation id="self_service_error_header" />
                </Heading1>
                <Banner variant={BANNER_VARIANT.ALERT}>
                  <FormattedMessageWithValidation
                    id="self_service_error_message"
                    values={{
                      email: <b>{email}</b>,
                    }}
                  />
                </Banner>
                <Body2 $weight={FONT_WEIGHT.LIGHT}>
                  <FormattedMessageWithValidation id="self_service_error_message_subtitle" />
                </Body2>
                <PrimaryButton
                  onClick={() => dispatch(SELFSERVICE_ERROR_GO_BACK)}
                >
                  <FormattedMessageWithValidation id="self_service_error_cta" />
                </PrimaryButton>
              </ErrorWrapper>
            </Card>
            <Footer>
              <LegalLinks />
            </Footer>
          </Content>
        </Wrapper>
      </Layout.Content>
    </Layout>
  );
}
