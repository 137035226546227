import { FormProvider } from 'react-hook-form';
import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import { useCallback, useEffect } from 'react';
import { useMount } from 'react-use';
import BreakdownTable from 'design-system/components/BreakdownTable/BreakdownTable';
import Dialog from 'design-system/components/Dialog/Dialog';
import {
  FlatButton,
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';
import {
  BUTTON_ICON_POSITION,
  BUTTON_SIZE,
} from 'design-system/components/Button';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { Subtitle1 } from 'design-system/components/Typography/presets/Subtitle1';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import Typography from 'design-system/components/Typography/Typography';
import { FONT_SIZE, typography } from 'design-system/styles/typography';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  billingEntityDataSelector,
  billingEntityFileDataSelector,
  billingEntityFileDisableSubmitSelector,
  billingEntityFileErrorLoadingSelector,
  billingEntityFileLoadingSelector,
  billingEntityFileSelector,
  billRequiredSelector,
  configFieldValidationsSelector,
  currentBillingEntityRequiresBillSelector,
  formSubmittedSelector,
  hasBillingEntityIdSelector,
  hasHaciendaRequestFailedSelector,
  publisherIsFromMexicoSelector,
  shouldShowEditButtonSelector,
  shouldShowFormSelector,
  shouldShowUploadCedulaFiscalSelector,
  taxIdSelector,
} from './selectors';
import { publisherCountrySelector } from '../../../../../selectors/publisher';
import BillingEntityFields from '../../../../../partials/BillingEntityFields';
import { setUpForm } from '../../../../../partials/BillingEntityFields/setUpForm';
import { FormWrapper } from '../../../../../partials/SimpleFormLayout';
import SwitchField from '../../../../../partials/fields/SwitchField';
import {
  BILL_REQUIRED_SWITCH_CHANGED,
  BILLING_ENTITY_FILE_DELETED,
  BILLING_ENTITY_FILE_SELECTED,
  BILLING_INFORMATION_EDIT_BILLING_BUTTON_CLICKED,
  BILLING_INFORMATION_EDIT_FILE_DATA,
  BILLING_INFORMATION_SUBMITTED,
  INITIALIZE_FORM,
} from './events';
import InformationBlock from '../../../partials/InformationBlock';
import { ACCEPTED_FILE_EXTENSIONS } from '../../../PaymentMethod/partials/Form/constants';
import FileUploader from '../../../PaymentMethod/partials/Form/FileUploader';
import { CEDULA_FIELD_TAGS } from '../../constants';
import { useDialog } from '../../../../../hooks/useDialog';
import { EDIT_CONFIRMATION } from './constants';
import { TYPE_OF_PERSON } from '../../../../../constants/billingEntity';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.M)};

  form {
    display: flex;
    flex-direction: column;
    gap: ${spacing.value(SPACING_SIZE.XL)};
      
    ${SwitchField} {
      ${Body1} {
        ${spacing.stack(SPACING_SIZE.S)}

        ${({ shouldShowForm }) => !shouldShowForm && 'display: block;'}
      }
      ${InformationBlock} {
        ${spacing.stack(SPACING_SIZE.M, false)}
      }
    }
    ${FormWrapper} {
      ${spacing.stack(SPACING_SIZE.S)}
    }
  }
}
`;

const formFieldMargins = SPACING_SIZE.S;

const Title = styled(Subtitle1)`
  ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
`;

const Subtitle = styled(Subtitle3)`
  ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
`;

const BillingEntityTable = styled(BreakdownTable)`
  display: flex;
  ${BreakdownTable.Row} {
    padding: ${SPACING_SIZE.S}px 0;
    td {
      text-align: left;
    }
    td:nth-of-type(1) {
      width: 160px;
    }
  }
`;

const EditButton = styled(TextButton)`
  ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
  font-weight: var(--font-weight-bold);
  line-height: 16px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing.value(SPACING_SIZE.XL)};
  padding-left: 52px;
`;

const BillingInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 52px;
  gap: ${spacing.value(SPACING_SIZE.S)};
`;

const BillingEntityFileErrorLoadingNotice = styled(Typography)`
  display: block;
  ${typography.size(FONT_SIZE.S)}
  ${color.text(COLOR_PALETTE.STRAWBERRY_BASE)}
  margin-bottom: ${spacing.value(SPACING_SIZE.S)};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.M)};
`;

export default function Form({
  subscriptionChangeRequestId,
  onSubmit,
  onError,
}) {
  const configFieldValidations = useSelector(configFieldValidationsSelector);
  const hasHaciendaRequestFailed = useSelector(
    hasHaciendaRequestFailedSelector
  );
  const taxId = useSelector(taxIdSelector);
  const publisherCountryCode = useSelector(publisherCountrySelector);
  const billingEntityData = useSelector(billingEntityDataSelector);
  const hasBillingEntityId = useSelector(hasBillingEntityIdSelector);
  const formSubmitted = useSelector(formSubmittedSelector);
  const shouldShowForm = useSelector(shouldShowFormSelector);
  const shouldShowUploadCedulaFiscal = useSelector(
    shouldShowUploadCedulaFiscalSelector
  );
  const publisherIsFromMexico = useSelector(publisherIsFromMexicoSelector);
  const billRequired = useSelector(billRequiredSelector);
  const currentBillingEntityRequiresBill = useSelector(
    currentBillingEntityRequiresBillSelector
  );

  const billingEntityFile = useSelector(billingEntityFileSelector);
  const billingEntityFileData = useSelector(billingEntityFileDataSelector);
  const billingEntityIsLoading = useSelector(billingEntityFileLoadingSelector);
  const billingEntityFileErrorLoading = useSelector(
    billingEntityFileErrorLoadingSelector
  );
  const submitIsDisabled = useSelector(billingEntityFileDisableSubmitSelector);
  const showEditButton = useSelector(shouldShowEditButtonSelector);
  const { open, closeDialog } = useDialog(EDIT_CONFIRMATION);
  useMount(() => {
    dispatch({
      id: INITIALIZE_FORM,
      payload: { currentBillingEntityRequiresBill },
    });
  });

  const methods = setUpForm(
    billingEntityData,
    publisherCountryCode,
    configFieldValidations
  );

  const { handleSubmit, watch, setValue } = methods;
  const formTypeOfPerson = watch('typeOfPerson');
  const handleSubmitWhenBillingEntityFormIsHidden = useCallback(
    (e) => {
      e.preventDefault();
      dispatch({
        id: BILLING_INFORMATION_SUBMITTED,
        payload: {
          subscriptionChangeRequestId,
          billRequired,
          billingEntityFile,
          ...billingEntityFileData,
        },
      });
    },
    [
      billRequired,
      subscriptionChangeRequestId,
      billingEntityFile,
      billingEntityFileData,
    ]
  );
  useEffect(() => {
    if (formTypeOfPerson === TYPE_OF_PERSON.NATURAL) {
      setValue('retefuente', false);
      setValue('reteIva', false);
      setValue('reteIca', false);
    }
  }, [formTypeOfPerson]);

  return (
    <Wrapper shouldShowForm={shouldShowForm}>
      <Title>
        <FormattedMessageWithValidation id="checkout_billing_title" />
      </Title>
      <FormProvider {...methods}>
        <form
          onSubmit={
            shouldShowForm
              ? handleSubmit(onSubmit, onError)
              : handleSubmitWhenBillingEntityFormIsHidden
          }
        >
          <Content>
            {publisherIsFromMexico && (
              <SwitchField
                name="billRequired"
                defaultChecked={currentBillingEntityRequiresBill}
                disabled={showEditButton}
                onChange={(event) => {
                  dispatch({
                    id: BILL_REQUIRED_SWITCH_CHANGED,
                    payload: event.target.checked,
                  });
                }}
              >
                <Body1>
                  <FormattedMessageWithValidation id="checkout_billing_bill_required_switch_label" />
                </Body1>
                {!billRequired && (
                  <InformationBlock>
                    <Body1>
                      <FormattedMessageWithValidation id="checkout_billing_no_bill_required_message" />
                    </Body1>
                  </InformationBlock>
                )}
              </SwitchField>
            )}
            <BillingInformationWrapper>
              {shouldShowForm && (
                <BillingEntityFields
                  configFields={configFieldValidations}
                  taxId={taxId}
                  disabled={showEditButton}
                  formFieldMargins={formFieldMargins}
                  typeOfPerson={formTypeOfPerson}
                />
              )}
              {shouldShowUploadCedulaFiscal && (
                <>
                  <Subtitle>
                    <FormattedMessageWithValidation id="checkout_file_upload_title" />
                  </Subtitle>
                  <FileUploader
                    id="cedula"
                    value={billingEntityFile}
                    isLoading={billingEntityIsLoading}
                    accept={ACCEPTED_FILE_EXTENSIONS.join(', ')}
                    onFileUploaded={(file) =>
                      dispatch({
                        id: BILLING_ENTITY_FILE_SELECTED,
                        payload: { file },
                      })
                    }
                    onFileDeleted={() => dispatch(BILLING_ENTITY_FILE_DELETED)}
                    inputMessage={
                      <FormattedMessageWithValidation id="checkout_file_placeholder" />
                    }
                  />
                </>
              )}
              {hasBillingEntityId && billRequired && publisherIsFromMexico && (
                <BillingEntityTable>
                  {Object.entries(
                    billingEntityFileData || billingEntityData || {}
                  )?.map((entry) => {
                    const [key, value] = entry;
                    return value !== null &&
                      typeof value !== 'boolean' &&
                      CEDULA_FIELD_TAGS[key] ? (
                      <BreakdownTable.Row
                        title={CEDULA_FIELD_TAGS[key]}
                        price={value}
                        key={key}
                      />
                    ) : (
                      []
                    );
                  })}
                </BillingEntityTable>
              )}
              {billingEntityFileErrorLoading && (
                <BillingEntityFileErrorLoadingNotice>
                  {!hasHaciendaRequestFailed ? (
                    <FormattedMessageWithValidation id="checkout_file_upload_load_error" />
                  ) : (
                    <FormattedMessageWithValidation id="checkout_file_upload_load_external_error" />
                  )}
                </BillingEntityFileErrorLoadingNotice>
              )}
            </BillingInformationWrapper>
          </Content>
          <Actions>
            {showEditButton && (
              <EditButton
                onClick={() =>
                  dispatch(BILLING_INFORMATION_EDIT_BILLING_BUTTON_CLICKED)
                }
                icon={<Icon glyph="pencil" />}
                $iconPosition={BUTTON_ICON_POSITION.START}
              >
                <FormattedMessageWithValidation id="checkout_billing_edit_data" />
              </EditButton>
            )}
            <PrimaryButton
              type="submit"
              disabled={formSubmitted || submitIsDisabled}
            >
              <FormattedMessageWithValidation id="checkout_billing_contact_info_continue" />
            </PrimaryButton>
          </Actions>
        </form>
      </FormProvider>
      <Dialog open={open}>
        <Dialog.Header>
          <FormattedMessageWithValidation id="checkout_billing_edit_dialog_title" />
        </Dialog.Header>
        <Dialog.Content>
          <FormattedMessageWithValidation id="checkout_billing_edit_dialog_content" />
        </Dialog.Content>
        <Dialog.Footer>
          <FlatButton $size={BUTTON_SIZE.SMALL} onClick={closeDialog}>
            <FormattedMessageWithValidation id="generic_cancel" />
          </FlatButton>
          <SecondaryButton
            $size={BUTTON_SIZE.SMALL}
            onClick={() => {
              dispatch(BILLING_INFORMATION_EDIT_FILE_DATA);
            }}
          >
            <FormattedMessageWithValidation id="generic_yes" />
          </SecondaryButton>
        </Dialog.Footer>
      </Dialog>
    </Wrapper>
  );
}
