import { effects, registerEventHandler } from 'reffects';
import { isEmpty, pickBy } from 'lodash';
import { state } from 'reffects-store';
import { queryParamsGetAll } from '../../../coeffects/queryParamsAll';
import { table } from '../effects/table';
import { combineStateSetEffects } from '../utils/combineStateSetEffects';
import queryParamSerializer from '../utils/queryParamsSerializer';

export const DATA_TABLE_INIT = 'DATA_TABLE_INIT';
export const DATA_TABLE_UNMOUNT = 'DATA_TABLE_UNMOUNT';

registerEventHandler(
  DATA_TABLE_INIT,
  ({ queryParamsAll }, context) => {
    const { collectionName, registeredFilters, updateEvents, defaultFilters } =
      context;

    const filters = getRegisteredFiltersWithValuesFromQueryParams(
      registeredFilters,
      queryParamSerializer.parse(queryParamsAll)
    );
    const pagination = mapQueryStringPaginationToTable(queryParamsAll, {
      rowsPerPage: context.initialItemsPerPage,
    });
    const sorting = mapQueryStringSortingToTable(queryParamsAll);

    const filtersWithDefaults = {
      ...defaultFilters,
      ...filters,
    };

    return {
      ...combineStateSetEffects(
        table.setFilters(collectionName, filtersWithDefaults),
        !isEmpty(pagination) && table.setPagination(collectionName, pagination),
        !isEmpty(sorting) && table.setSorting(collectionName, sorting)
      ),
      ...effects.dispatch({ id: updateEvents.collection, payload: context }),
    };
  },
  [queryParamsGetAll()]
);

function getRegisteredFiltersWithValuesFromQueryParams(
  registeredFilters,
  queryParams
) {
  return registeredFilters.reduce((acc, registeredFilter) => {
    const queryParamsFilterValue = queryParams[registeredFilter];

    if (!queryParamsFilterValue) return acc;

    return {
      ...acc,
      [registeredFilter]: queryParamsFilterValue,
    };
  }, {});
}

function mapQueryStringPaginationToTable(
  { page, rowsPerPage },
  initialPagination = {}
) {
  const { rowsPerPage: initialRowsPerPage } = initialPagination;

  return pickBy(
    {
      currentPage: page,
      itemsPerPage: rowsPerPage ?? initialRowsPerPage,
    },
    Boolean
  );
}

function mapQueryStringSortingToTable({ order, orderBy }) {
  return pickBy(
    {
      direction: order,
      field: orderBy,
    },
    Boolean
  );
}

registerEventHandler(DATA_TABLE_UNMOUNT, (_, { collectionName }) =>
  state.set({
    [`dataTables.${collectionName}`]: undefined,
  })
);
