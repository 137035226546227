import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { FlatButton } from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';
import { BUTTON_ICON_POSITION } from 'design-system/components/Button';
import TextFilter from '../partials/Filters/TextFilter';
import CountryFilter from '../partials/Filters/CountryFilter';
import { useFilters } from '../../../partials/DataTable/hooks/useFilter';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing.value(SPACING_SIZE.M)};
  ${TextFilter} {
    width: 18.5rem;
  }
`;

export default function UsersFilters() {
  const { filters, clearFilters } = useFilters();

  return (
    <Wrapper>
      <TextFilter name="email" placeholder="Search by email" />
      <CountryFilter source="country" />
      <TextFilter
        name="publisher"
        placeholder="Search by agency name or uuid"
      />
      {!isEmpty(filters) && (
        <FlatButton
          color="secondary"
          disableElevation
          onClick={clearFilters}
          icon={<Icon glyph="cross" />}
          $iconPosition={BUTTON_ICON_POSITION.START}
        >
          Clear filters
        </FlatButton>
      )}
    </Wrapper>
  );
}
