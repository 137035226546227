import styled from 'styled-components';
import Table from 'design-system/components/Table';
import InputNumber, {
  INPUT_NUMBER_TYPE,
} from 'design-system/components/InputNumber';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { useColumn } from '../../../partials/DataTable/hooks/useColumn';

const NUMBER_OF_DECIMALS = 2;

const Wrapper = styled(Table.Cell)`
  ${({ $hasBeenModified }) =>
    $hasBeenModified
      ? color.background(`${COLOR_PALETTE.PUMPKIN_A40} !important`)
      : ''}
`;

export function PriceInputColumn({ defaultCurrency, ...props }) {
  const {
    value: { amount, currency, planId, planName, hasBeenModified },
  } = useColumn(props);
  const { onChange, onBlur, onClick } = props;

  return (
    <Wrapper width={130} $hasBeenModified={hasBeenModified}>
      <Body1 $noWrap>
        {amount != null && (
          <InputNumber
            label=""
            value={amount}
            min="0"
            max="10000000000"
            maxDecimals={NUMBER_OF_DECIMALS}
            onClick={() => onClick(planId)}
            onBlur={() => onBlur(planId)}
            onChange={(e) => onChange(planName, e)}
            type={INPUT_NUMBER_TYPE.DECIMAL}
            unit={currency ?? defaultCurrency}
          />
        )}
      </Body1>
    </Wrapper>
  );
}
