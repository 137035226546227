import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Image from 'design-system/components/Image/Image';
import Anchor from 'design-system/components/Anchor';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import useTracking from '../../../../hooks/useTracking';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  ${Image} {
    ${spacing.inline(SPACING_SIZE.M)}
    width: 75px;
    height: 50px;
    object-fit: cover;
    ${radius.regular(RADIUS_SIZE.BASE)}
  }

  > div {
    display: flex;
    flex-direction: column;

    p {
      margin: 0;
      ${spacing.stack(SPACING_SIZE.S)}
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;

    li {
      ${spacing.inline(SPACING_SIZE.S)}
    }
  }
`;

function ProjectSites({ project, sites, ...props }) {
  const trackingRef = (site) =>
    useTracking({
      trackClick: {
        eventName: 'click-project-site-link',
        payload: { site },
      },
      trackImpression: {
        elementName: 'project-site-link',
      },
      sectionName: 'project-detail-sites-list',
    });
  return (
    <Wrapper {...props}>
      {project.logo && <Image source={project.logo} />}
      <div>
        <p>
          <FormattedMessageWithValidation id="project_detail_sites_to_publish" />
        </p>
        <ul>
          {sites.map(({ site, link }) => (
            <li key={site}>
              <Anchor href={link} ref={trackingRef(site)}>
                {site}
              </Anchor>
            </li>
          ))}
        </ul>
      </div>
    </Wrapper>
  );
}

export default styled(ProjectSites)``;
