import { subscribe } from 'reffects-store';
import { PublishedButBoostingLimitReachedModal } from './variants/PublishedButBoostingLimitReachedModal';
import { PublishedButBoostingForbiddenModal } from './variants/PublishedButBoostingForbiddenModal';
import { BoostingForbiddenModal } from './variants/BoostingForbiddenModal';
import { BoostingLimitReachedModal } from './variants/BoostingLimitReachedModal';
import { supportEmailSelector } from '../../../selectors/config';
import { SuperboostingLimitReachedModal } from './variants/SuperboostingLimitReachedModal';
import { SuperboostingForbiddenModal } from './variants/SuperboostingForbiddenModal';
import { SuperboostingLimitReachedForBoostingModal } from './variants/SuperboostingLimitReachedForBoostingModal';
import { NotPublishedModal } from './variants/NotPublishedModal';
import { SitesPublishedOnModal } from './variants/SitesPublishedOnModal/SitesPublishedOnModal';
import AdFeedbackModal from './variants/AdFeedbackModal';
import { NotPublishedErrorModal } from './variants/NotPublishedErrorModal';

function AdModals({ supportEmail }) {
  return (
    <>
      <NotPublishedModal />
      <NotPublishedErrorModal />
      <SitesPublishedOnModal supportEmail={supportEmail} />
      <BoostingForbiddenModal supportEmail={supportEmail} />
      <BoostingLimitReachedModal supportEmail={supportEmail} />
      <PublishedButBoostingForbiddenModal supportEmail={supportEmail} />
      <PublishedButBoostingLimitReachedModal supportEmail={supportEmail} />
      <SuperboostingLimitReachedModal supportEmail={supportEmail} />
      <SuperboostingLimitReachedForBoostingModal />
      <SuperboostingForbiddenModal supportEmail={supportEmail} />
      <AdFeedbackModal />
    </>
  );
}

export default subscribe(AdModals, (state) => ({
  supportEmail: supportEmailSelector(state),
}));
