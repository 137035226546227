import { COLOR_PALETTE } from 'design-system/styles/color';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import BaseBubble, { BASE_BUBBLE_ALIGNMENT } from './BaseBubble';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { LEAD_STATUS_TO_TEXT_MAP } from '../constants';

function StateChangeBubble({ newState, ...rest }) {
  return (
    <BaseBubble
      {...rest}
      title={
        <FormattedMessageWithValidation id="enquiry_detail_note_automatic" />
      }
      $alignment={BASE_BUBBLE_ALIGNMENT.RIGHT}
      $colorScheme={{
        title: COLOR_PALETTE.PUMPKIN_BASE,
        background: COLOR_PALETTE.PUMPKIN_A05,
        notch: COLOR_PALETTE.PUMPKIN_BASE,
      }}
      data-test={`status-change-bubble-${newState}`}
    >
      <Body1 $color={COLOR_PALETTE.NEUTRAL_BASE} $weight={FONT_WEIGHT.MEDIUM}>
        <FormattedMessageWithValidation
          id="enquiry_detail_note_automatic_desc"
          values={{ newState: LEAD_STATUS_TO_TEXT_MAP[newState] }}
        />
      </Body1>
    </BaseBubble>
  );
}

export default StateChangeBubble;
