import { get } from 'lodash';
import { SORTING_DIRECTIONS } from './constants';
import pipe from '../../utils/pipe';
import curry from '../../utils/curry';

export function paginate({ rowsPerPage, currentPage }, rows) {
  const start = (currentPage - 1) * rowsPerPage;
  const end = start + rowsPerPage;

  return rows.slice(start, end);
}

export function calculatePagination({ currentPage, rowsPerPage, totalRows }) {
  const rowsPerPageLimit = currentPage * rowsPerPage;
  const rowsLimitDifference =
    rowsPerPageLimit > totalRows ? rowsPerPageLimit - totalRows : 0;
  const firstRowNumber =
    totalRows === 0 ? 0 : (currentPage - 1) * rowsPerPage + 1;
  const lastRowNumber = rowsPerPageLimit - rowsLimitDifference;

  return {
    totalPages: Math.ceil(totalRows / rowsPerPage),
    currentRowRange: [firstRowNumber, lastRowNumber],
  };
}

export function sort(sorting, rows) {
  if (sorting == null) {
    return rows;
  }

  const { field, direction } = sorting;
  const sortedRows = rows.sort((a, b) => {
    if (get(a, field) > get(b, field)) {
      return 1;
    }
    if (get(a, field) < get(b, field)) {
      return -1;
    }
    return 0;
  });

  return direction === SORTING_DIRECTIONS.DESCENDING
    ? sortedRows.reverse()
    : sortedRows;
}

export function filter(filterMap, rows) {
  if (!filterMap) {
    return rows;
  }

  const filters = Object.values(filterMap);
  return rows.filter((row) =>
    filters.filter(Boolean).every((f) => {
      if (f.type === 'term') {
        const rowValue = get(row, f.field);

        if (Array.isArray(rowValue)) {
          return rowValue.indexOf(f.value) !== -1;
        }

        return f.value === rowValue;
      }

      if (f.type === 'range') {
        const value = get(row, f.field);
        return f.value.start <= value && value <= f.value.end;
      }

      if (f.type === 'fullText') {
        return f.field.some((field) => {
          const rowValue = get(row, field);
          return substringContainsIgnoreCase(f.value, rowValue);
        });
      }

      return false;
    })
  );
}

function substringContainsIgnoreCase(needle, haystack) {
  return haystack?.toLowerCase().includes(needle.toLowerCase());
}

export function getIds(rows) {
  return rows.map(({ id }) => id);
}

export default function updateTable(rows, filters, sorting, pagination) {
  const rowsList = Object.values(rows);
  const filteredRows = pipe(
    curry(filter)(filters),
    curry(sort)(sorting),
    getIds
  )(rowsList);

  const paginatedRows = paginate(pagination, filteredRows);

  return {
    rows: paginatedRows,
    totalRows: filteredRows.length,
  };
}
