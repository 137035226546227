import { useCallback, useMemo } from 'react';
import DropdownMenu from 'design-system/components/DropdownMenu';
import { DROPDOWN_POSITION } from 'design-system/components/Dropdown';
import Menu from 'design-system/components/Menu/Menu';
import { OutlineButton } from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';
import { useFilter } from '../../hooks/useFilter';

export function DropdownFilter({
  label,
  source,
  options,
  scrollable,
  renderLabel,
  onSelectOption,
}) {
  const { value, setValue } = useFilter(source);

  const currentLabel = useMemo(() => {
    if (renderLabel != null) {
      return renderLabel(options, value);
    }
    const currentOption = options.find((option) => option.value === value);
    return currentOption?.value != null && currentOption?.label;
  }, [options, value]);

  const handleConfirmation = useCallback((selectedOption) => {
    setValue(selectedOption.value);
    if (onSelectOption) {
      onSelectOption(selectedOption);
    }
  }, []);

  return (
    <DropdownMenu
      position={DROPDOWN_POSITION.BOTTOM_LEFT}
      active={value != null}
      button={
        <OutlineButton icon={<Icon glyph="chevronDown" />}>
          {currentLabel || label}
        </OutlineButton>
      }
      onConfirm={handleConfirmation}
    >
      <Menu scrollable={scrollable}>
        {options.map(({ id, ...rest }) => (
          <Menu.Item key={id} id={id} {...rest} />
        ))}
      </Menu>
    </DropdownMenu>
  );
}
