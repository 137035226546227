import styled from 'styled-components';
import Table from 'design-system/components/Table';
import Tooltip from 'design-system/components/Tooltip';
import Tag, {
  TAG_ICON_POSITION,
  TAG_VARIANT,
} from 'design-system/components/Tag';
import Icon from 'design-system/components/Icon';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { useColumn } from '../../../../partials/DataTable/hooks/useColumn';
import FormattedDateTime from '../../../../partials/FormattedDate/FormattedDateTime';
import FormattedRelativeDate from '../../../../partials/FormattedDate/FormattedRelativeDate';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Wrapper = styled(Table.Cell)`
  padding-right: ${spacing.value(SPACING_SIZE.S)};
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  ${Body1} {
    &:first-letter {
      text-transform: capitalize;
    }
  }
`;

const GreenTag = styled(Tag)`
  ${Tag.mods.iconPosition(TAG_ICON_POSITION.START)}
  ${Tag.mods.variant(TAG_VARIANT.PRIMARY)}
`;

const GreyTag = styled(Tag)`
  ${Tag.mods.iconPosition(TAG_ICON_POSITION.START)}
  ${Tag.mods.variant(TAG_VARIANT.DEFAULT)}
`;

const lastActivityTags = new Map([
  [
    'call',
    <GreenTag icon={<Icon glyph="callIncoming" />}>
      <FormattedMessageWithValidation id="enquiries_call_incoming" />
    </GreenTag>,
  ],
  [
    'phone',
    <GreyTag icon={<Icon glyph="eyeOutlined" />}>
      <FormattedMessageWithValidation id="enqpage_table_activity_phone" />
    </GreyTag>,
  ],
  [
    'whatsapp',
    <GreenTag icon={<Icon glyph="whatsapp" />}>
      <FormattedMessageWithValidation id="enqpage_table_activity_whatsapp" />
    </GreenTag>,
  ],
  [
    'missed',
    <Tag
      variant={TAG_VARIANT.COMPLEMENTARY_STRAWBERRY}
      icon={<Icon glyph="callIncoming" />}
    >
      <FormattedMessageWithValidation id="enquiries_call_missing" />
    </Tag>,
  ],
]);

function LastActivityTag({ type, callStatus }) {
  return lastActivityTags.has(type) ? (
    lastActivityTags.get(callStatus) ?? lastActivityTags.get(type)
  ) : (
    <GreyTag icon={<Icon glyph="email" />}>
      <FormattedMessageWithValidation id="enqpage_table_activity_message" />
    </GreyTag>
  );
}

export function LastActivityColumn(props) {
  const {
    value: { date, type, callStatus },
  } = useColumn(props);
  return (
    <Wrapper>
      <Content>
        <LastActivityTag type={type} callStatus={callStatus} />
        <Tooltip
          title={
            <FormattedDateTime
              value={date}
              dateStyle="full"
              timeStyle="short"
            />
          }
        >
          <Body1 $noWrap>
            <FormattedRelativeDate date={new Date(date)} now={new Date()} />
          </Body1>
        </Tooltip>
      </Content>
    </Wrapper>
  );
}
