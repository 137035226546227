import qs from 'query-string';

export function publisherContactSalesRoute(apiHost) {
  return `${apiHost}/publishers/me/contact-sales`;
}

export function doCreditCardPaymentIframeRoute(
  paymentsApiUrl,
  authorizationId,
  queryString
) {
  const query = qs.stringify(queryString, { skipNull: true });
  return `${paymentsApiUrl}/advanced-payment-methods/${authorizationId}?${query}`;
}
