import { useCallback } from 'react';
import { dispatch } from 'reffects';
import Menu from 'design-system/components/Menu/Menu';
import { OPEN_DISASSOCIATE_MODAL } from '../../Modals/DisassociatePublisherModal/events';
import DisassociateModal from '../../Modals/DisassociatePublisherModal/DisassociateModal';

export function DisassociatePublisherToMultiaccount({ publisher }) {
  const handleOnClick = useCallback(() => {
    dispatch(OPEN_DISASSOCIATE_MODAL);
  }, []);

  return (
    <>
      <Menu.Item
        label="Disassociate from multiaccount"
        onClick={handleOnClick}
      />
      <DisassociateModal publisherId={publisher.id} />
    </>
  );
}
