import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { CUSTOMER_ROLES } from '../../constants/user';
import { redirectTo } from '../../effects/routing';
import { homeRoute } from '../../utils/proppitWebRouter';
import { environment } from '../../coeffects/environment';

export const CHECK_USER_IS_PUBLISHER_COLLABORATOR_ROLE =
  'CHECK_USER_IS_PUBLISHER_COLLABORATOR_ROLE';
export const RETRIEVE_AUTO_RENEWAL_CANCELLATION_STATUS =
  'RETRIEVE_AUTO_RENEWAL_CANCELLATION_STATUS';
export const RETRIEVE_AUTO_RENEWAL_CANCELLATION_STATUS_SUCCESS =
  'RETRIEVE_AUTO_RENEWAL_CANCELLATION_STATUS_SUCCESS';
export const RETRIEVE_AUTO_RENEWAL_CANCELLATION_STATUS_FAILED =
  'RETRIEVE_AUTO_RENEWAL_CANCELLATION_STATUS_FAILED';

registerEventHandler(CHECK_USER_IS_PUBLISHER_COLLABORATOR_ROLE, (_, { role }) =>
  role === CUSTOMER_ROLES.PUBLISHER_COLLABORATOR ? redirectTo(homeRoute()) : {}
);

registerEventHandler(
  RETRIEVE_AUTO_RENEWAL_CANCELLATION_STATUS,
  ({ environment: { apiUrl } }, { hasPaidSubscription }) =>
    hasPaidSubscription
      ? http.get({
          url: `${apiUrl}/subscriptions/me/cancel-request`,
          successEvent: RETRIEVE_AUTO_RENEWAL_CANCELLATION_STATUS_SUCCESS,
          errorEvent: RETRIEVE_AUTO_RENEWAL_CANCELLATION_STATUS_FAILED,
        })
      : {},
  [environment()]
);

registerEventHandler(
  RETRIEVE_AUTO_RENEWAL_CANCELLATION_STATUS_SUCCESS,
  (
    _,
    [
      {
        data: { status },
      },
    ]
  ) => state.set({ requestAutoRenewalCancellation: { status } })
);

registerEventHandler(RETRIEVE_AUTO_RENEWAL_CANCELLATION_STATUS_FAILED, () =>
  state.set({ requestAutoRenewalCancellation: undefined })
);
