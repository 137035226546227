import styled from 'styled-components';
import Layout from 'design-system/components/Layout';
import { dispatch } from 'reffects';
import { useMount, useUnmount } from 'react-use';
import {
  SUBSCRIPTION_PAGE_MOUNTED,
  SUBSCRIPTION_PAGE_UNMOUNTED,
} from './events';
import { Navigation } from '../../../../partials';

function SubscriptionLayout({ sidebar, children, ...props }) {
  useMount(() => dispatch(SUBSCRIPTION_PAGE_MOUNTED));
  useUnmount(() => dispatch(SUBSCRIPTION_PAGE_UNMOUNTED));

  return (
    <Layout {...props}>
      <Layout.Header>
        <Navigation />
      </Layout.Header>
      <Layout.Content>{children}</Layout.Content>
      {sidebar && <Layout.RightSidebar>{sidebar}</Layout.RightSidebar>}
    </Layout>
  );
}

export default styled(SubscriptionLayout)``;
