import styled from 'styled-components';
import Typography from '../Typography';

const BasePresetTypography = styled(Typography)`
  ${({ $color }) => $color && Typography.mods.color($color)}
  ${({ $ellipse }) => $ellipse && Typography.mods.ellipse()}
  ${({ $noWrap }) => $noWrap && Typography.mods.noWrap()}
  ${({ $wrap }) => $wrap && Typography.mods.wrap()}
`;

export default BasePresetTypography;
