import styled from 'styled-components';
import { TextButton } from 'design-system/components/Button/presets';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle1 } from 'design-system/components/Typography/presets/Subtitle1';

const Wrapper = styled.section`
  ${spacing.stack(SPACING_SIZE.XXL)}
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${spacing.stack(SPACING_SIZE.M)}
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  height: 1px;
`;

function Group({ title, actionButton, children, ...rest }) {
  return (
    <Wrapper {...rest}>
      <Head>
        <Subtitle1>{title}</Subtitle1>
        {actionButton && <ButtonContainer>{actionButton}</ButtonContainer>}
      </Head>
      <div>{children}</div>
    </Wrapper>
  );
}

Group.ActionButton = TextButton;

export default styled(Group)``;
