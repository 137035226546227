import {
  userIsSuperadminSelector,
  userIsSupportSelector,
} from '../../../../../../../selectors/user';
import { isSEA } from '../../../../../../../utils/countries';

export function canEditListingRulesSelector(state, countryCode) {
  return (
    userIsSuperadminSelector(state) ||
    (userIsSupportSelector(state) && isSEA(countryCode))
  );
}

export function brandsToPublishNonBoostedListingsToSelector(state) {
  return state?.brandsToPublishNonBoostedListingsTo;
}
