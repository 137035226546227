import styled from 'styled-components';
import DiscountRow from './DiscountRow';

import Row from './Row';
import TextRow from './TextRow';
import TotalRow from './TotalRow';

const Wrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

function BreakdownTable({ children, ...rest }) {
  return (
    <Wrapper {...rest}>
      <tbody>{children}</tbody>
    </Wrapper>
  );
}

BreakdownTable.Row = Row;
BreakdownTable.TextRow = TextRow;
BreakdownTable.DiscountRow = DiscountRow;
BreakdownTable.TotalRow = TotalRow;

export default styled(BreakdownTable)``;
