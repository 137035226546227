import { useIntl } from 'react-intl';
import InputSelect from 'design-system/components/InputSelect';
import { formatPrice } from '../../../../partials/Price/Price';

function WasiPlanSelect({ label, value, onChange, plans, disabled, required }) {
  const intl = useIntl();

  const choices = plans.map(({ id, name, price, currency }) => ({
    id,
    label: `${name} ${price ? formatPrice(intl, price, currency) : null}`,
    value: id,
  }));

  return (
    <InputSelect
      label={label}
      value={value}
      onSelect={onChange}
      choices={choices}
      disabled={disabled}
      required={required}
    />
  );
}
export default WasiPlanSelect;
