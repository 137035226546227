import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useWatch } from 'react-hook-form';
import Section from 'design-system/components/Section';
import InputRow from 'design-system/components/InputRow';
import InputBase from 'design-system/components/InputBase/InputBase';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import SelectField from '../../../partials/fields/SelectField';
import NumberField from '../../../partials/fields/NumberField';

const CertificateInputNumber = styled(NumberField)`
  > ${InputBase.Box} {
    > ${Subtitle3} {
      width: 15em;
      max-width: 15em;
    }
  }
`;

export default function EnergyCertificateSection() {
  const intl = useIntl();
  const selectChoice = (label, value) => ({ label, value });
  const translatedChoice = (label, value) =>
    selectChoice(
      intl.formatMessage({
        id: label,
      }),
      value
    );

  const energyCertificate = useWatch({
    name: 'energyCertificate',
  });

  return (
    <Section
      title={
        <FormattedMessageWithValidation id="ad_form_section_energy_certificate" />
      }
    >
      <SelectField
        label={
          <FormattedMessageWithValidation id="newprop_energy_certificate_question" />
        }
        name="energyCertificate"
        placeholder={intl.formatMessage({
          id: 'newprop_energy_certificate_question_emptystate',
        })}
        choices={[
          translatedChoice('newprop_energy_certificate_yes', 'yes'),
          translatedChoice(
            'newprop_energy_certificate_in_process',
            'in process'
          ),
          translatedChoice('newprop_energy_certificate_exempt', 'exempt'),
        ]}
      />
      {energyCertificate === 'yes' && (
        <>
          <InputRow>
            <SelectField
              label={
                <FormattedMessageWithValidation id="newprop_efficiency_selector_label" />
              }
              name="efficiency"
              placeholder={intl.formatMessage({
                id: 'newprop_efficiency_emptystate',
              })}
              choices={[
                selectChoice('A', 'A'),
                selectChoice('B', 'B'),
                selectChoice('C', 'C'),
                selectChoice('D', 'D'),
                selectChoice('E', 'E'),
                selectChoice('F', 'F'),
                selectChoice('G', 'G'),
              ]}
            />
            <CertificateInputNumber
              key="efficiencyValue"
              name="efficiencyValue"
              label={
                <FormattedMessageWithValidation id="newprop_efficiency_value_label" />
              }
              unit="kW h m²/ año"
            />
          </InputRow>
          <InputRow>
            <SelectField
              label={
                <FormattedMessageWithValidation id="newprop_emissions_selector_label" />
              }
              name="emissions"
              placeholder={intl.formatMessage({
                id: 'newprop_emissions_emptystate',
              })}
              choices={[
                selectChoice('A', 'A'),
                selectChoice('B', 'B'),
                selectChoice('C', 'C'),
                selectChoice('D', 'D'),
                selectChoice('E', 'E'),
                selectChoice('F', 'F'),
                selectChoice('G', 'G'),
              ]}
            />
            <CertificateInputNumber
              key="emissionsValue"
              name="emissionsValue"
              label={
                <FormattedMessageWithValidation id="newprop_emissions_value_label" />
              }
              unit="kg CO2 m²/ año"
            />
          </InputRow>
        </>
      )}
    </Section>
  );
}
