import styled from 'styled-components';
import { subscribe } from 'reffects-store';
import { dispatch } from 'reffects';
import Grid from 'design-system/components/Grid';
import Layout from 'design-system/components/Layout';
import InputRow from 'design-system/components/InputRow';
import InputText from 'design-system/components/InputText';
import { PrimaryButton } from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  INVALID_PASSWORD,
  MISMATCHED_PASSWORDS,
  SERVER_ERROR,
} from './constants';
import { changePasswordHasFailedSelector } from './selectors';
import { CHANGE_PASSWORD_REQUESTED } from './events';

import { Navigation } from '../../partials';

const Header = styled.div`
  ${spacing.stack(SPACING_SIZE.L)}
`;

const Form = styled.form`
  ${InputRow} {
    ${spacing.stack(SPACING_SIZE.M)}
    &:last-of-type {
      ${spacing.stack(SPACING_SIZE.XL)}
    }
  }
`;

function ChangePassword({ errorChangePassword, ...rest }) {
  const errorText = {
    [INVALID_PASSWORD]: (
      <FormattedMessageWithValidation id="changepwdpage_error_length" />
    ),
    [MISMATCHED_PASSWORDS]: (
      <FormattedMessageWithValidation id="changepwdpage_error_match" />
    ),
    [SERVER_ERROR]: 'An error occurred. Please try again later.',
  };

  return (
    <Layout>
      <Layout.Header>
        <Navigation />
      </Layout.Header>
      <Layout.Content>
        <Layout.Padded>
          <Grid
            areas={({ repeat }) => `
            "${repeat(12, 'header')}"
            "${repeat(6, 'form')} ${repeat(6, '.')}"
          `}
          >
            <Grid.Area $id="header">
              <Header>
                <Heading1>
                  <FormattedMessageWithValidation id="changepwdpage_header" />
                </Heading1>
              </Header>
            </Grid.Area>
            <Grid.Area $id="form">
              <Form {...rest}>
                <InputRow
                  tip={
                    <FormattedMessageWithValidation id="changepwdpage_description" />
                  }
                >
                  <InputText
                    type="password"
                    name="password"
                    label={
                      <FormattedMessageWithValidation id="changepwdpage_new" />
                    }
                    tip={
                      <FormattedMessageWithValidation
                        id={
                          errorChangePassword != null
                            ? errorText[errorChangePassword]
                            : 'changepwdpage_error_length'
                        }
                      />
                    }
                    error={errorChangePassword != null}
                  />
                </InputRow>
                <InputRow>
                  <InputText
                    type="password"
                    name="confirm_password"
                    label={
                      <FormattedMessageWithValidation id="changepwdpage_confirm" />
                    }
                    tip={
                      <FormattedMessageWithValidation id="changepwdpage_instruction" />
                    }
                    error={errorChangePassword != null}
                  />
                </InputRow>
                <PrimaryButton $size={BUTTON_SIZE.LARGE} type="submit">
                  <FormattedMessageWithValidation id="changepwdpage_cta" />
                </PrimaryButton>
              </Form>
            </Grid.Area>
          </Grid>
        </Layout.Padded>
      </Layout.Content>
    </Layout>
  );
}

export default subscribe(
  ChangePassword,
  function mapStateToProps(state) {
    return { errorChangePassword: changePasswordHasFailedSelector(state) };
  },
  {
    onSubmit(event) {
      event.preventDefault();
      dispatch({
        id: CHANGE_PASSWORD_REQUESTED,
        payload: {
          newPassword: event.target.password.value,
          confirmedPassword: event.target.confirm_password.value,
        },
      });
    },
  }
);
