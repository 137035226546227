import { effects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import { environment } from '../../../coeffects/environment';
import { toast } from '../../../effects/toast';
import { analytics } from '../../../effects/analytics';
import { paginate } from '../../Tables/updateTable';

export const COMPANY_CONTACTS_DATA_LOADED = 'COMPANY_CONTACTS_DATA_LOADED';
export const COMPANY_CONTACTS_DATA_REQUESTED =
  'COMPANY_CONTACTS_DATA_REQUESTED';
export const CONTACT_DETAIL_EDIT_SUBMITTED = 'CONTACT_DETAIL_EDIT_SUBMITTED';
export const CONTACT_DETAIL_EDIT_SAVED = 'CONTACT_DETAIL_EDIT_SAVED';
export const CONTACT_DETAIL_EDIT_SAVE_FAILED =
  'CONTACT_DETAIL_EDIT_SAVE_FAILED';
export const CONTACT_DETAIL_TABLE_MOUNTED = 'CONTACT_DETAIL_TABLE_MOUNTED';
export const CONTACT_DETAIL_TABLE_PAGE_CHANGED =
  'CONTACT_DETAIL_TABLE_PAGE_CHANGED';

const DEFAULT_ROWS_PER_PAGE = 10;
const DEFAULT_DETAIL_PAGE = 1;

registerEventHandler(
  COMPANY_CONTACTS_DATA_REQUESTED,
  ({ environment: { apiUrl } }) => {
    const url = `${apiUrl}/publishers/me/contacts`;
    return http.get({
      url,
      successEvent: [COMPANY_CONTACTS_DATA_LOADED],
    });
  },
  [environment()]
);

registerEventHandler(
  COMPANY_CONTACTS_DATA_LOADED,
  ({ state: { contactsByType } }, [{ data }]) => {
    const mutations = {};

    Object.keys(data).forEach((contactType) => {
      const { pagination } = contactsByType[contactType];
      const totalRows = data[contactType].length;
      const totalPages = Math.ceil(totalRows / pagination.rowsPerPage);
      const currentPage = pagination.currentPage ?? 1;
      const newPage = currentPage > totalPages ? totalPages : currentPage;

      mutations[`companyProfile:contacts.${contactType}.data`] =
        data[contactType];
      mutations[
        `companyProfile:contacts.${contactType}.pagination.currentPage`
      ] = newPage;
      mutations[`companyProfile:contacts.${contactType}.pagination.totalRows`] =
        totalRows;
      mutations[`companyProfile:contacts.${contactType}.rows`] = paginate(
        { rowsPerPage: DEFAULT_ROWS_PER_PAGE, currentPage: newPage },
        data[contactType]
      );
    });

    return {
      ...state.set(mutations),
    };
  },
  [state.get({ contactsByType: 'companyProfile:contacts' })]
);

registerEventHandler(
  CONTACT_DETAIL_EDIT_SUBMITTED,
  ({ environment: { apiUrl } }, { contactDetailType, oldValue, newValue }) => {
    const pathEndpoint = {
      emails: '/publishers/me/contacts/email',
      phones: '/publishers/me/contacts/phone',
      whatsapp: '/publishers/me/contacts/whatsapp',
      viber: '/publishers/me/contacts/viber',
      line: '/publishers/me/contacts/line',
      facebook: '/publishers/me/contacts/facebook',
    };

    return http.put({
      url: `${apiUrl}${pathEndpoint[contactDetailType]}`,
      body: {
        oldValue,
        newValue,
      },
      successEvent: [CONTACT_DETAIL_EDIT_SAVED, contactDetailType],
      errorEvent: CONTACT_DETAIL_EDIT_SAVE_FAILED,
    });
  },
  [environment()]
);

registerEventHandler(CONTACT_DETAIL_EDIT_SAVED, (_, [, contactDetailType]) => ({
  ...toast.show({
    text: 'confirmation_edit_contact_details',
  }),
  ...effects.dispatchMany([COMPANY_CONTACTS_DATA_REQUESTED]),
  ...analytics.trackClick(
    `contact-${contactDetailType}-changed`,
    `company-profile-contact-details-${contactDetailType}`
  ),
}));

registerEventHandler(CONTACT_DETAIL_EDIT_SAVE_FAILED, () => ({}));

registerEventHandler(
  CONTACT_DETAIL_TABLE_MOUNTED,
  (_, { contactType, fullContactList }) => ({
    ...state.set({
      [`companyProfile:contacts.${contactType}.pagination`]: {
        currentPage: DEFAULT_DETAIL_PAGE,
        rowsPerPage: DEFAULT_ROWS_PER_PAGE,
        totalRows: Object.entries(fullContactList).length,
      },
    }),
  })
);

registerEventHandler(
  CONTACT_DETAIL_TABLE_PAGE_CHANGED,
  ({ state: { contactDetails } }, { contactType, page }) => {
    const pagination = {
      ...contactDetails[contactType].pagination,
      currentPage: page,
    };

    const visibleEntries = paginate(
      pagination,
      contactDetails[contactType].data
    );

    return state.set({
      [`companyProfile:contacts.${contactType}.rows`]: visibleEntries,
      [`companyProfile:contacts.${contactType}.pagination.currentPage`]:
        pagination.currentPage,
    });
  },
  [
    state.get({
      contactDetails: 'companyProfile:contacts',
      currentPagination: 'propertiesTable.pagination',
    }),
  ]
);
