import styled from 'styled-components';
import Button, { BUTTON_SIZE, BUTTON_ICON_POSITION } from '..';

const BasePresetButton = styled(Button)`
  ${({ $size }) => Button.mods.size($size)}
  ${({ $iconPosition }) => Button.mods.iconPosition($iconPosition)}
`;

BasePresetButton.defaultProps = {
  $size: BUTTON_SIZE.BASE,
  $iconPosition: BUTTON_ICON_POSITION.END,
};

export default BasePresetButton;
