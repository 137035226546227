import { DropdownFilter } from '../../../../../../partials/DataTable';
import { billingCycleFilterOptions } from '../../../../../utils/billingCycleOptions';

const OPTIONS = billingCycleFilterOptions();

export default function BillingCycleFilter() {
  return (
    <DropdownFilter
      label="Billing cycle"
      source="billingCycle"
      options={OPTIONS}
    />
  );
}
