import { TABLE_ALIGNMENT } from 'design-system/components/Table';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { useDataTable } from '../../../../../partials/DataTable/hooks/useDataTable';
import { DataTable, SnippetColumn } from '../../../../../partials/DataTable';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { ActionsColumn } from '../../../../../partials/DataTable/components/columns/ActionsColumn';
import { TableEmptyState } from './TableEmptyState';
import buildTableRowEventsRedirectingTo from '../../../../../partials/DataTable/utils/buildTableRowEventsRedirectingTo';
import OperationFieldColumn from '../../../../../partials/DataTable/components/columns/OperationFieldColumn';
import { operationTypeTranslationsTags } from '../../../../../constants/ad';
import PriceRange from '../../../../../partials/PriceRange';
import EmptyCellContent from '../../../../../partials/DataTable/components/EmptyCellContent';
import buildTableRowEventsWithTracking from '../../../../../partials/DataTable/utils/buildTableRowEventsWithTracking';

export function LastProjectsTable() {
  const { isEmpty } = useDataTable();

  if (isEmpty) {
    return <TableEmptyState />;
  }

  return (
    <DataTable
      clickableRows
      rowEvents={buildTableRowEventsWithTracking(
        buildTableRowEventsRedirectingTo((itemId) => `/project/${itemId}`),
        {
          eventName: 'click-project-link',
          sectionName: 'overview-last-projects-table',
        }
      )}
    >
      <SnippetColumn
        id="snippet"
        title={
          <FormattedMessageWithValidation id="overview_developer_projects_summary_table_last_projects" />
        }
        source={{
          title: 'name',
          address: 'address',
          image: 'mainImage',
        }}
        maxWidth={225}
      />
      <OperationFieldColumn
        id="operationType"
        title={
          <FormattedMessageWithValidation id="projects_list_operation_type_header" />
        }
        renderField={({ type }) => (
          <ProjectOperationType operationType={type} />
        )}
      />
      <OperationFieldColumn
        id="units"
        title={
          <FormattedMessageWithValidation id="projects_list_units_header" />
        }
        renderField={({ units }) => <ProjectText text={units} />}
      />
      <OperationFieldColumn
        id="prices"
        title={
          <FormattedMessageWithValidation id="projects_list_prices_header" />
        }
        renderField={({ prices }) => <ProjectPrices prices={prices} />}
      />
      <ActionsColumn
        id="actions"
        section="overview-last-projects-table"
        title={
          <FormattedMessageWithValidation id="overview_developer_projects_summary_table_actions" />
        }
        alignment={TABLE_ALIGNMENT.RIGHT}
      />
    </DataTable>
  );
}

function ProjectText({ text }) {
  return <Body1>{text}</Body1>;
}

function ProjectOperationType({ operationType }) {
  return (
    <Body1>
      <FormattedMessageWithValidation
        id={operationTypeTranslationsTags.get(operationType)}
      />
    </Body1>
  );
}

function ProjectPrices({ prices }) {
  const [minPrice, maxPrice] = prices ?? [];
  return (
    <PriceRange
      minPrice={minPrice}
      maxPrice={maxPrice}
      emptyComponent={<EmptyCellContent />}
    />
  );
}
