import { WidgetBlock } from '../WidgetBlock';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { ProjectsStats } from './ProjectsStats';
import { LastProjectsTable } from './LastProjectsTable/LastProjectsTable';

export function ProjectsOverview() {
  return (
    <WidgetBlock
      title={
        <FormattedMessageWithValidation id="overview_developer_projects_summary_title" />
      }
      withLeftDivider
    >
      <ProjectsStats />
      <LastProjectsTable />
    </WidgetBlock>
  );
}
