import { cloneElement, useState } from 'react';
import styled from 'styled-components';
import Dropdown from './Dropdown';
import noop from '../../utils/noop';

function DropdownMenu({ onConfirm = noop, children, ...rest }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenChange = (open) => {
    setIsOpen(open);
  };

  const handleItemConfirmation = (value) => {
    onConfirm(value);
    setIsOpen(false);
  };

  const handleItemCancelation = () => {
    setIsOpen(false);
  };

  return (
    <Dropdown open={isOpen} onOpenChange={handleOpenChange} {...rest}>
      {cloneElement(children, {
        onConfirm: handleItemConfirmation,
        onCancel: handleItemCancelation,
      })}
    </Dropdown>
  );
}

export default styled(DropdownMenu)``;
