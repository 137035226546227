import InputNumber, {
  INPUT_NUMBER_TYPE,
} from 'design-system/components/InputNumber';

export default function ExtraUsersInput({ value, onChange, isDisabled }) {
  return (
    <InputNumber
      label="Extra users"
      value={value ?? ''}
      type={INPUT_NUMBER_TYPE.INTEGER}
      maxDecimals={0}
      onChange={onChange}
      disabled={isDisabled}
    />
  );
}
