import { useCallback } from 'react';
import { useSelector } from 'reffects-store';
import DropdownMenu from 'design-system/components/DropdownMenu';
import Tooltip from 'design-system/components/Tooltip';
import Datepicker from 'design-system/components/Datepicker/Datepicker';
import { OutlineButton } from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';
import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import FormattedDate from '../../partials/FormattedDate/FormattedDate';
import { useFilter } from '../../partials/DataTable/hooks/useFilter';
import { startDateSelector } from '../../selectors/publisher';
import CustomFormattedDate from '../../partials/FormattedDate/CustomFormattedDate';

export function DateRangeFilter() {
  const { value: startDate, setValue: setStartDate } = useFilter('startDate');
  const { value: endDate, setValue: setEndDate } = useFilter('endDate');
  const developerPlanStartDate = useSelector(startDateSelector);

  const handleConfirmation = useCallback((dateRange) => {
    setStartDate(format(dateRange.startDate));
    setEndDate(format(dateRange.endDate));
  }, []);

  const dropdown = (
    <DropdownMenu
      button={
        <OutlineButton icon={<Icon glyph="chevronDown" />}>
          <CustomFormattedDate value={startDate} />
          {' - '}
          <CustomFormattedDate value={endDate} />
        </OutlineButton>
      }
      onConfirm={handleConfirmation}
    >
      <Datepicker
        start={new Date(startDate)}
        end={new Date(endDate)}
        maxDate={new Date()}
        submitButtonText={
          <FormattedMessageWithValidation id="enquiries_filter_custom_date_datepicker_apply" />
        }
        cancelButtonText={
          <FormattedMessageWithValidation id="enquiries_filter_custom_date_datepicker_cancel" />
        }
        formatWeekday={(date) => <FormattedDate value={date} weekday="short" />}
        formatMonth={(date) => (
          <FormattedDate value={date} month="long" year="numeric" />
        )}
      />
    </DropdownMenu>
  );

  if (
    developerPlanStartDate &&
    startDate === format(developerPlanStartDate) &&
    endDate === format(new Date())
  ) {
    return (
      <Tooltip
        title={
          <FormattedMessageWithValidation id="projects_list_current_subscription_tooltip" />
        }
      >
        {dropdown}
      </Tooltip>
    );
  }

  return dropdown;
}

function format(date) {
  return date.toISOString().split('T')[0];
}
