import styled, { css } from 'styled-components';
import { radius } from 'design-system/styles/radius';
import {
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';

export const COUNTER_VARIANT = {
  PRIMARY: Symbol('COUNTER_VARIANT_PRIMARY'),
  SECONDARY: Symbol('COUNTER_VARIANT_SECONDARY'),
  ERROR: Symbol('COUNTER_VARIANT_ERROR'),
};

const Text = styled.span`
  white-space: nowrap;
  font-size: ${FONT_SIZE.S}px;
  line-height: 15px;

  ${typography.font(FONT_FAMILY.PRIMARY)};
  ${typography.weight(FONT_WEIGHT.MEDIUM)};

  ${spacing.inline(SPACING_SIZE.S)}
`;

const modPrimaryVariant = () => css`
  ${color.background(COLOR_PALETTE.PRIMARY_BASE)}

  ${Text} {
    ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
  }
`;

const modSecondaryVariant = () => css`
  ${color.background(COLOR_PALETTE.NEUTRAL_A10)}

  ${Text} {
    ${color.text(COLOR_PALETTE.SECONDARY_BASE)}
  }
`;

const modErrorVariant = () => css`
  ${color.background(COLOR_PALETTE.STRAWBERRY_BASE)}

  ${Text} {
    ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
  }
`;

const modVariant = (variantName) =>
  ({
    [COUNTER_VARIANT.PRIMARY]: modPrimaryVariant,
    [COUNTER_VARIANT.SECONDARY]: modSecondaryVariant,
    [COUNTER_VARIANT.ERROR]: modErrorVariant,
  }[variantName]);

const Wrapper = styled.span`
  width: fit-content;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  ${radius.pill()}
  text-decoration: none;
  padding: 0 ${SPACING_SIZE.S}px;

  ${() => modVariant(COUNTER_VARIANT.PRIMARY)}
`;

function Counter({ children, ...rest }) {
  return (
    <Wrapper {...rest}>
      <Text>{children}</Text>
    </Wrapper>
  );
}

Counter.mods = {
  variant: modVariant,
};

export default styled(Counter)``;
