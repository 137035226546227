import styled from 'styled-components';
import { useCallback } from 'react';
import { debounce } from 'lodash';
import Tip from 'design-system/components/Tip';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { FONT_SIZE, typography } from 'design-system/styles/typography';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import TextField from '../fields/TextField';
import FormattedMessageWithValidation from '../FormattedMessageWithValidation/FormattedMessageWithValidation';
import SelectField from '../fields/SelectField';
import { TYPE_OF_PERSON } from '../../constants/billingEntity';
import PhoneField from '../fields/PhoneField';
import HiddenField from '../fields/HiddenField';
import useInternationalization from '../../hooks/useInternationalization';
import {
  FormWrapper,
  ItemFullWidth,
  ItemTwoColumns,
  ItemTwoColumnsResponsive,
} from '../SimpleFormLayout';
import Retentions from './Retentions/Retentions';

const Wrapper = styled(FormWrapper)`
  ${Tip} {
    ${spacing.stack(SPACING_SIZE.M, false)}
    ${typography.size(FONT_SIZE.S)}
    ${color.background(COLOR_PALETTE.PUMPKIN_A10)}
  }
`;

function BillingEntityFields({
  configFields,
  taxId,
  disabled,
  disableRetentions,
  onChangeTaxId,
  typeOfPerson,
  ...props
}) {
  const debouncedOnChangeTaxId = useCallback(
    onChangeTaxId != null ? debounce(onChangeTaxId, 1000) : undefined,
    [onChangeTaxId]
  );
  const intl = useInternationalization();
  const configFieldsNames = Object.keys(configFields);
  return (
    <Wrapper {...props}>
      {configFieldsNames.includes('typeOfPerson') && (
        <ItemTwoColumnsResponsive>
          <SelectField
            name="typeOfPerson"
            label={intl.formatMessageWithValidation({
              id: 'checkout_billing_type_of_person_label',
            })}
            defaultValue={TYPE_OF_PERSON.NATURAL}
            choices={[
              {
                value: TYPE_OF_PERSON.NATURAL,
                label: intl.formatMessageWithValidation({
                  id: 'checkout_billing_type_of_person_natural_option',
                }),
              },
              {
                value: TYPE_OF_PERSON.JURIDIC,
                label: intl.formatMessageWithValidation({
                  id: 'checkout_billing_type_of_person_juridic_option',
                }),
              },
            ]}
            disabled={disabled}
          />
        </ItemTwoColumnsResponsive>
      )}

      {configFieldsNames.includes('taxId') && (
        <ItemTwoColumnsResponsive>
          <TextField
            name="taxId"
            label={taxId}
            disabled={disabled}
            onChange={debouncedOnChangeTaxId}
            placeholder={configFields.taxId.placeholder}
          />
        </ItemTwoColumnsResponsive>
      )}

      {configFieldsNames.includes('legalName') && (
        <ItemFullWidth>
          <TextField
            name="legalName"
            label={
              <FormattedMessageWithValidation id="checkout_billing_company_name_label" />
            }
            disabled={disabled}
          />
        </ItemFullWidth>
      )}

      {configFieldsNames.includes('email') && (
        <ItemFullWidth>
          <TextField
            name="email"
            label={
              <FormattedMessageWithValidation id="checkout_billing_contact_info_email_label" />
            }
            disabled={disabled}
          />
        </ItemFullWidth>
      )}

      {configFieldsNames.includes('address') && (
        <ItemFullWidth>
          <TextField
            name="address"
            label={
              <FormattedMessageWithValidation id="checkout_billing_tax_address_label" />
            }
            disabled={disabled}
          />
        </ItemFullWidth>
      )}

      {configFieldsNames.includes('city') && (
        <ItemTwoColumns>
          <TextField
            name="city"
            label={
              <FormattedMessageWithValidation id="checkout_billing_city_label" />
            }
            disabled={disabled}
          />
        </ItemTwoColumns>
      )}

      {configFieldsNames.includes('province') && (
        <ItemTwoColumns>
          <TextField
            name="province"
            label={
              <FormattedMessageWithValidation id="checkout_billing_province_label" />
            }
            disabled={disabled}
          />
        </ItemTwoColumns>
      )}

      {configFieldsNames.includes('postalCode') && (
        <ItemTwoColumns>
          <TextField
            name="postalCode"
            label={
              <FormattedMessageWithValidation id="checkout_billing_postal_code_label" />
            }
            disabled={disabled}
          />
        </ItemTwoColumns>
      )}

      {configFieldsNames.includes('phone') && (
        <ItemTwoColumns>
          <PhoneField
            name="phone"
            label={
              <FormattedMessageWithValidation id="checkout_billing_contact_info_phone_label" />
            }
            disabled={disabled}
          />
        </ItemTwoColumns>
      )}

      <Retentions
        configFields={configFieldsNames}
        disabled={disabled}
        disableRetentions={disableRetentions}
        typeOfPerson={typeOfPerson}
      />

      <HiddenField name="country" />
      <HiddenField name="id" />
    </Wrapper>
  );
}

export default styled(BillingEntityFields)``;
