export function checkoutTranslationTagSelector(state, name) {
  return checkoutConfigurationSelector(state).textTranslations[name];
}

export function checkoutSubRouteSelector(state) {
  return checkoutConfigurationSelector(state)?.subRoute;
}

function checkoutConfigurationSelector(state) {
  return state.checkoutConfig;
}
