import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import { PrimaryButton } from 'design-system/components/Button/presets';
import styled from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { border, BORDER_SIZE } from 'design-system/styles/border';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import Price from '../../../partials/Price/Price';
import { useFeatureSelector } from '../../../utils/featuresFlags/selectors';

const Offer = styled.div`
  padding: 40px 10px;
  display: flex;
  width: 280px;
  flex-direction: column;
  align-items: center;
  gap: ${spacing.value(SPACING_SIZE.L)};
  flex-shrink: 0;
  ${radius.regular(RADIUS_SIZE.LARGE)}
  ${border.outer({
    size: BORDER_SIZE.LARGE,
    color: COLOR_PALETTE.PRIMARY_BASE,
  })}
  ${color.background(COLOR_PALETTE.NEUTRAL_A00)};
`;

const OfferContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${spacing.value(SPACING_SIZE.XS)};
`;

const OfferTest = styled.div`
  display: flex;
  padding: 0 16px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  text-align: center;
`;

const OfferDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const OfferDetailsItem1 = styled.div`
  display: flex;
  flex-direction: column;

  span {
    color: #111b3c;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
  }

  span:last-child {
    font-size: 18px;
  }
`;

const Prices = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const OfferPrice = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${Price} {
    padding: 4px 8px;
    ${color.text(COLOR_PALETTE.PRIMARY_A120)}
    text-align: center;
    font-size: 32px;
    font-style: normal;
    ${typography.weight(FONT_WEIGHT.SEMIBOLD)}
    line-height: normal;
    letter-spacing: -1px;
  }
  > span {
    font-size: 32px;
    ${color.text(COLOR_PALETTE.PRIMARY_A120)}
    ${typography.weight(FONT_WEIGHT.SEMIBOLD)}
  }
`;

const OriginalPrice = styled.div`
  ${Price} {
    color: #000;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-decoration: line-through;
  }
`;

const OfferDiscount = styled.div`
  ${color.text(COLOR_PALETTE.PRIMARY_A120)}
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

const OfferDuration = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  span {
    ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }

  span:last-child {
    font-size: 12px;
  }
`;

const Action = styled.div``;

export default function OfferCard({ offerData, onClick }) {
  const NEW_PLANS_IN_OFFERS_4135 = useFeatureSelector(
    'NEW_PLANS_IN_OFFERS_4135'
  );
  let originalPrice = offerData.totalUndiscountedPrice;
  let offerPrice = offerData.baseSubscriptionPrice + offerData.superboostPrice;

  if (NEW_PLANS_IN_OFFERS_4135) {
    originalPrice = offerData.totalPriceVatExcluded;
    offerPrice = offerData.totalDiscountedPriceVatExcluded;
  }

  return (
    <Offer>
      <OfferContent>
        <OfferTest>
          <Heading1>
            <FormattedMessageWithValidation id="subscription_landing_agency_pack" />
          </Heading1>
          <br />
          <OfferDetails>
            <OfferDetailsItem1>
              {offerData.maxBoostableProperties === 1 ? (
                <FormattedMessageWithValidation id="subscription_landing_plan_base_single" />
              ) : (
                <FormattedMessageWithValidation
                  id="subscription_landing_plan_base"
                  values={{
                    amount: offerData.maxBoostableProperties,
                  }}
                />
              )}

              {offerData.maxSuperboostableProperties === 1 ? (
                <FormattedMessageWithValidation id="subscription_landing_plan_superboost_single" />
              ) : (
                <FormattedMessageWithValidation
                  id="subscription_landing_plan_superboost"
                  values={{
                    amount: offerData.maxSuperboostableProperties,
                  }}
                />
              )}
            </OfferDetailsItem1>
            <Prices>
              <OriginalPrice>
                {offerData.totalDiscountPercentage > 0 ? (
                  <Price amount={originalPrice} currency={offerData.currency} />
                ) : (
                  <br />
                )}
              </OriginalPrice>
              <OfferPrice>
                <Price amount={offerPrice} currency={offerData.currency} />
                {NEW_PLANS_IN_OFFERS_4135 && <span>*</span>}
              </OfferPrice>
              <OfferDiscount>
                {offerData.totalDiscountPercentage > 0 ? (
                  <FormattedMessageWithValidation
                    id="subscription_landing_plan_discount"
                    values={{
                      discount: offerData.totalDiscountPercentage,
                    }}
                  />
                ) : (
                  <br />
                )}
              </OfferDiscount>
            </Prices>
          </OfferDetails>
        </OfferTest>
        <OfferDuration>
          {offerData.billingCycleInMonths === 1 ? (
            <FormattedMessageWithValidation id="subscription_landing_plan_duration_monthly" />
          ) : (
            <FormattedMessageWithValidation id="subscription_landing_plan3_duration" />
          )}
          <FormattedMessageWithValidation id="subscription_landing_plan_automatic_renewal" />
        </OfferDuration>
        {NEW_PLANS_IN_OFFERS_4135 && (
          <Body2>
            <FormattedMessageWithValidation id="subscription_landing_plan_vat_notice" />
          </Body2>
        )}
      </OfferContent>
      <Action>
        <PrimaryButton onClick={onClick}>
          <FormattedMessageWithValidation id="subscription_landing_plan_cta" />
        </PrimaryButton>
      </Action>
    </Offer>
  );
}
